import InfoIcon from '@bill/cashflow.assets/info';
import InfoTooltip from '@/components/common/InfoTooltip';
import { FORECAST, PENDING } from '@/constants/actuals';
import { classNames } from '@/helpers';
import './MonthColumnHeaderGroupRenderer.scss';

const PENDING_HEADER_TOOLTIP =
  'You are currently seeing forecast values for this month. ' +
  'Your actuals will display after the set closing date. ' +
  'Learn more about setting your closing date in the help center.';

const MonthColumnHeaderGroupRenderer = ({ headerName, indicator }) => {
  const isForecast = indicator === FORECAST;
  const isPending = indicator === PENDING;

  return (
    <div className="MonthColumn">
      <div
        className={classNames(
          'MonthColumn_HeaderName',
          isForecast && 'MonthColumn_HeaderName-forecast',
          isPending && 'MonthColumn_ActualIndicator-pending',
        )}
      >
        {headerName}
      </div>
      {indicator && (
        <div
          className={classNames(
            'MonthColumn_ActualIndicator',
            isForecast && 'MonthColumn_ActualIndicator-forecast',
            isPending && 'MonthColumn_ActualIndicator-pending',
          )}
        >
          {indicator}
          {isPending && (
            <InfoTooltip
              data-testid="cash-grid-pending-tooltip"
              placement="right"
              icon={<InfoIcon className="InfoIcon" aria-hidden="true" />}
            >
              {PENDING_HEADER_TOOLTIP}
            </InfoTooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default MonthColumnHeaderGroupRenderer;
