/**
 * Generates Random 32 Character SessionId
 *
 * @type {() => string}
 */
const generateSessionId = () => {
  if (typeof crypto.randomUUID === 'function') {
    return crypto.randomUUID().replace('-', '');
  }
  const values =
    '0123456789abcdefghiklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXTZ';
  let sessionId = '';

  for (let i = 0; i < 32; i++) {
    const randomIndex =
      crypto.getRandomValues(new Uint32Array(1))[0] % values.length;
    sessionId += values.charAt(randomIndex);
  }
  return sessionId;
};

export default generateSessionId;
