// @ts-check
import './ClockIcon.scss';

/**
 * Render a clock icon with moving hour and minute hands
 *
 * @example
 *   <ClockIcon />;
 *
 * @type {() => React.ReactElement}
 */
function ClockIcon() {
  return <div className="ClockIcon" />;
}

export default ClockIcon;
