// eslint-disable-next-line import/no-extraneous-dependencies -- predates description requirement
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '@/reducers';

const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'shared', 'scenario', 'companies'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

/**
 * @typedef {ReturnType<persistedReducer>} State
 * @type {import('@reduxjs/toolkit').ConfigureStoreOptions<
 *   State,
 *   import('@reduxjs/toolkit').AnyAction,
 *   import('@reduxjs/toolkit').MiddlewareArray<
 *     [
 *       import('@reduxjs/toolkit/dist/getDefaultMiddleware').ThunkMiddlewareFor<State>,
 *     ]
 *   >,
 *   [import('@reduxjs/toolkit').StoreEnhancer]
 * >}
 */
export const storeConfig = {
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'production') {
      return getDefaultMiddleware();
    }
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
  },
};

const store = configureStore(storeConfig);

/**
 * @typedef {ReturnType<typeof store.getState>} RootState
 *
 * @typedef {typeof store.dispatch} AppDispatch
 */

const persistor = persistStore(store);
export { persistor, store, history };
