// @ts-check
import { ApiV2 } from './Api';
import getByDateRange from './getByDateRange';

export const getCustomerAcquisitionCosts = getByDateRange(
  '/customerAcquisition/cac',
);
export const getGrossMargins = getByDateRange(
  '/customerAcquisition/grossMargin',
);
export const getCashFlowChartData = getByDateRange('/dashboard/cashFlow', {
  useApiV2: false,
});
/**
 * @type {(
 *   params: import('./getByDateRange').GetByDateRangeProps<{
 *     companyId: number;
 *   }>,
 * ) => Promise<
 *   import('@/types/api').AxiosApiResponse<{
 *     data: import('@/types/services/backend').CashBalanceResponse[];
 *   }>
 * >}
 */
export const getCashBalanceData = getByDateRange('/cash-in-out/cash-balance', {
  useApiV2: false,
});
export const getTotalMRR = getByDateRange('/dashboard/totalMrr', {
  useApiV2: false,
});
export const getChurnedMRR = getByDateRange('/dashboard/churnMrr', {
  useApiV2: false,
});
export const getAverageRevenuePerAccount = getByDateRange(
  '/dashboard/average-revenue-per-account',
  { useApiV2: false },
);
export const getBurnRate = getByDateRange('/dashboard/burnRate', {
  useApiV2: false,
});
export const getCacPayback = getByDateRange('/customerAcquisition/cacPayback');
export const getLogoChurn = getByDateRange('/dashboard/logoChurn', {
  useApiV2: false,
});
export const getNewMRR = getByDateRange('/dashboard/newMrr', {
  useApiV2: false,
});
export const getCustomerLTV = getByDateRange(
  '/customerAcquisition/customer-lifetime-value',
);

/**
 * @type {(
 *   params: import('@/services/getByDateRange').GetByDateRangeProps<{
 *     signal: AbortSignal;
 *     variables: string[];
 *   }>,
 * ) => Promise<any>}
 */
export const getTimePeriodValuesForVariables = getByDateRange(
  '/charts/multiple-variables/valuesTimePeriod',
);

/**
 * Creates a chart of the custom Variable
 *
 * @param {number} scenarioId ID of the scenario in which to create the chart of
 *   custom variable
 * @param {string} dashboardLayoutId ID of the dashboard in which to create the
 *   chart of custom variable
 * @param {Object} data Properties of the custom variable Chart
 * @returns {Promise} API response
 */
export const createChart = (scenarioId, dashboardLayoutId, data) =>
  ApiV2.post('/charts', data, { params: { scenarioId, dashboardLayoutId } });

/**
 * Get Specific Chart Values
 *
 * @param {string} startDate Date to fetch value from
 * @param {string} endDate Date to fetch value till
 * @param {number} scenarioId ID of the scenario of which to get the chart value
 * @param {string} chartId ID of the chart to fetch values for
 * @returns {Promise} API response
 */
export const getChartValues = (startDate, endDate, scenarioId, chartId) =>
  ApiV2.get(`/charts/${chartId}`, {
    params: { scenarioId, startDate, endDate },
  });

/**
 * Delete custom chart
 *
 * @param {number} scenarioId ID of the scenario in which chart is present
 * @param {string} chartId ID of the chart to delete
 * @returns {Promise} API response
 */
export const deleteCustomChart = (scenarioId, chartId) =>
  ApiV2.delete(`/charts/${chartId}`, {
    params: { scenarioId },
  });

/**
 * Retrieves all dashboards for the given scenario
 *
 * @param {number} scenarioId ID of the scenario containing the dashboards
 * @returns {Promise} API response
 */
export const getAllDashboardLayouts = (scenarioId) =>
  ApiV2.get('/dashboard-layouts/all', { params: { scenarioId } });

/**
 * Saves the dashboard layout for the given scenario
 *
 * @param {number} scenarioId ID of the scenario containing the dashboard
 * @param {Object} dashboard Items and name to show on the dashboard, their size
 *   and positions
 * @returns {Promise} API response
 */
export const setDashboardLayout = (scenarioId, dashboard) =>
  ApiV2.put('/dashboard-layouts', dashboard, { params: { scenarioId } });

/**
 * Delete custom dashboard
 *
 * @param {number} scenarioId ID of the scenario in which chart is present
 * @param {string} name Name of the dashboard to delete
 * @returns {Promise} API response
 */
export const deleteDashboard = (scenarioId, name) =>
  ApiV2.delete('/dashboard-layouts', {
    params: { scenarioId, name },
  });

/**
 * Get all custom charts with multiple variables
 *
 * @param {number} scenarioId ID of the scenario containing the charts
 * @returns {Promise} API response
 */
export const getChartMultiVariable = (scenarioId) =>
  ApiV2.get('/charts/multiple-variables', { params: { scenarioId } });

/**
 * Creates a custom chart with multiple variables
 *
 * @param {number} scenarioId ID of the scenario in which to create the chart of
 *   custom variable
 * @param {string} dashboardLayoutId ID of the dashboard in which to create the
 *   chart of custom variable
 * @param {Object} data Properties of the custom variable Chart
 * @returns {Promise} API response
 */
export const createChartMultiVariable = (scenarioId, dashboardLayoutId, data) =>
  ApiV2.post('/charts/multiple-variables', data, {
    params: { scenarioId, dashboardLayoutId },
  });

/**
 * Update a custom chart with multiple variables
 *
 * @param {number} scenarioId ID of the scenario in which chart is present
 * @param {Object} data Properties of the custom variable Chart
 * @returns {Promise} API response
 */
export const updateChartMultiVariable = (scenarioId, data) =>
  ApiV2.put('/charts/multiple-variables', data, { params: { scenarioId } });

/**
 * @type {(
 *   params: import('./getByDateRange').GetByDateRangeProps<{
 *     companyId: number;
 *   }>,
 * ) => Promise<
 *   import('@/types/api').AxiosApiResponse<{
 *     data: import('@/types/dashboard').CashFlowChartDto[];
 *   }>
 * >}
 */
export const getNetCashFlow = getByDateRange('/cash-in-out/cash-flow', {
  useApiV2: false,
});

/**
 * @type {(
 *   params: import('./getByDateRange').GetByDateRangeProps<{
 *     companyIds: number;
 *     filter: import('@/types/services/backend').CashInOutGrid['cashFlowType'];
 *   }>,
 * ) => Promise<
 *   import('@/types/api').AxiosApiResponse<{
 *     data: import('@/types/services/backend').CashInOutChartItem[];
 *   }>
 * >}
 */
export const getCashIn = getByDateRange('cash-in-out/chart', {
  useApiV2: false,
});
