import { useState } from 'react';
import { connect } from 'react-redux';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';
import Modal from '@/components/common/Modal';
import Table from '@/components/common/Table';
import { formatMonthDayYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import './TheMatchEmployeeRecordModal.scss';

const MatchEmployeeRecordContent = ({
  unLinkedEmployeesList,
  onSave,
  onClose,
  mappedEmployeeIds,
}) => {
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const handleChange = (id) => {
    if (selectedEmployee === id) {
      setSelectedEmployee(null);
    } else {
      setSelectedEmployee(id);
    }
  };

  const handleCancel = () => {
    setSelectedEmployee(null);
    onClose();
  };

  return (
    <>
      <header>
        <h2 className="MatchEmployeeRecordModal_Heading">
          Match an Employee Record
        </h2>
      </header>
      <Table data-testid="unlinked-only-employees">
        <thead>
          <tr className="Table_HeadRow">
            <td aria-label="Select Record" />
            <Table.ColHead>Department</Table.ColHead>
            <Table.ColHead>Position</Table.ColHead>
            <Table.ColHead>Name</Table.ColHead>
            <Table.ColHead>Start Date</Table.ColHead>
            <Table.ColHead>Salary</Table.ColHead>
          </tr>
        </thead>
        <tbody>
          {unLinkedEmployeesList.map(
            ({ id, departmentName, titleLabel, name, startDate, salary }) => (
              <tr className="Table_Row" key={id}>
                <td>
                  <Checkbox
                    disabled={mappedEmployeeIds.includes(id)}
                    name="isChecked"
                    checked={
                      mappedEmployeeIds.includes(id) || selectedEmployee === id
                    }
                    id={String(id)}
                    onChange={() => handleChange(id)}
                    aria-label="Select"
                  />
                </td>
                <td className="Table_Cell">{departmentName}</td>
                <td className="Table_Cell">{titleLabel}</td>
                <td className="Table_Cell">{name}</td>
                <td className="Table_Cell">{formatMonthDayYear(startDate)}</td>
                <td className="Table_Cell">{formatMonetary(salary)}</td>
              </tr>
            ),
          )}
        </tbody>
      </Table>
      <footer className="ModalBase_Footer">
        <Button
          className="Button ModalConfirmation_Button Button-cancelLink"
          onClick={handleCancel}
          data-testid="match-employee-records-cancel-button"
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedEmployee}
          onClick={() => {
            onSave(selectedEmployee);
            setSelectedEmployee(null);
          }}
          data-testid="match-employee-records-action-button"
        >
          Confirm
        </Button>
      </footer>
    </>
  );
};

const TheMatchEmployeeRecordModal = ({
  open,
  onClose,
  unLinkedEmployeesList,
  onSave,
  mappedEmployeeIds,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      data-testid="match-employee-modal"
      className="MatchEmployeeRecordModal"
    >
      <MatchEmployeeRecordContent
        onClose={onClose}
        unLinkedEmployeesList={unLinkedEmployeesList}
        onSave={onSave}
        mappedEmployeeIds={mappedEmployeeIds}
      />
    </Modal>
  );
};

const mapSateToProps = ({ employees: { unLinkedEmployeesList } }) => ({
  unLinkedEmployeesList,
});

export default connect(mapSateToProps)(TheMatchEmployeeRecordModal);
