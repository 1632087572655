import getSelectedCompany from '@/selectors/getSelectedCompany';
import { store } from '@/store';

export const Currencies = [
  { value: 'USD', name: 'America (United States) Dollars – USD', symbol: '$' },
  { value: 'AFN', name: 'Afghanistan Afghanis – AFN', symbol: '؋' },
  { value: 'ALL', name: 'Albania Leke – ALL', symbol: 'Lek' },
  { value: 'DZD', name: 'Algeria Dinars – DZD' },
  { value: 'ARS', name: 'Argentina Pesos – ARS' },
  { value: 'AUD', name: 'Australia Dollars – AUD' },
  { value: 'ATS', name: 'Austria Schillings – ATS' },
  { value: 'BSD', name: 'Bahamas Dollars – BSD' },
  { value: 'BHD', name: 'Bahrain Dinars – BHD' },
  { value: 'BDT', name: 'Bangladesh Taka – BDT' },
  { value: 'BBD', name: 'Barbados Dollars – BBD' },
  { value: 'BEF', name: 'Belgium Francs – BEF' },
  { value: 'BMD', name: 'Bermuda Dollars – BMD' },
  { value: 'BRL', name: 'Brazil Reais – BRL' },
  { value: 'BGN', name: 'Bulgaria Leva – BGN', symbol: 'лв' },
  { value: 'CAD', name: 'Canada Dollars – CAD' },
  { value: 'XOF', name: 'CFA BCEAO Francs – XOF' },
  { value: 'XAF', name: 'CFA BEAC Francs – XAF' },
  { value: 'CLP', name: 'Chile Pesos – CLP' },
  { value: 'CNY', name: 'China Yuan Renminbi – CNY' },
  { value: 'CNY', name: 'RMB (China Yuan Renminbi) – CNY' },
  { value: 'COP', name: 'Colombia Pesos – COP' },
  { value: 'XPF', name: 'CFP Francs – XPF' },
  { value: 'CRC', name: 'Costa Rica Colones – CRC' },
  { value: 'HRK', name: 'Croatia Kuna – HRK' },
  { value: 'CYP', name: 'Cyprus Pounds – CYP' },
  { value: 'CZK', name: 'Czech Republic Koruny – CZK' },
  { value: 'DKK', name: 'Denmark Kroner – DKK' },
  { value: 'DEM', name: 'Deutsche (Germany) Marks – DEM' },
  { value: 'DOP', name: 'Dominican Republic Pesos – DOP' },
  { value: 'NLG', name: 'Dutch (Netherlands) Guilders – NLG' },
  { value: 'XCD', name: 'Eastern Caribbean Dollars – XCD' },
  { value: 'EGP', name: 'Egypt Pounds – EGP' },
  { value: 'EEK', name: 'Estonia Krooni – EEK' },
  { value: 'EUR', name: 'Euro – EUR', symbol: '€' },
  { value: 'FJD', name: 'Fiji Dollars – FJD' },
  { value: 'FIM', name: 'Finland Markkaa – FIM' },
  { value: 'FRF', name: 'France Francs – FRF' },
  { value: 'GEL', name: 'Georgian Lari – GEL', symbol: '₾' },
  { value: 'DEM', name: 'Germany Deutsche Marks – DEM' },
  { value: 'XAU', name: 'Gold Ounces – XAU' },
  { value: 'GRD', name: 'Greece Drachmae – GRD' },
  { value: 'GTQ', name: 'Guatemalan Quetzal – GTQ' },
  { value: 'NLG', name: 'Holland (Netherlands) Guilders – NLG' },
  { value: 'HKD', name: 'Hong Kong Dollars – HKD' },
  { value: 'HUF', name: 'Hungary Forint – HUF' },
  { value: 'ISK', name: 'Iceland Kronur – ISK' },
  { value: 'XDR', name: 'IMF Special Drawing Right – XDR' },
  { value: 'INR', name: 'India Rupees – INR', symbol: '₹' },
  { value: 'IDR', name: 'Indonesia Rupiahs – IDR', symbol: 'Rp' },
  { value: 'IRR', name: 'Iran Rials – IRR' },
  { value: 'IQD', name: 'Iraq Dinars – IQD' },
  { value: 'IEP', name: 'Ireland Pounds – IEP' },
  { value: 'ILS', name: 'Israel New Shekels – ILS', symbol: '₪' },
  { value: 'ITL', name: 'Italy Lire – ITL' },
  { value: 'JMD', name: 'Jamaica Dollars – JMD', symbol: 'J$' },
  { value: 'JPY', name: 'Japan Yen – JPY', symbol: '¥' },
  { value: 'JOD', name: 'Jordan Dinars – JOD' },
  { value: 'KES', name: 'Kenya Shillings – KES' },
  { value: 'KWD', name: 'Kuwait Dinars – KWD' },
  { value: 'LBP', name: 'Lebanon Pounds – LBP' },
  { value: 'LUF', name: 'Luxembourg Francs – LUF' },
  { value: 'MYR', name: 'Malaysia Ringgits – MYR', symbol: 'RM' },
  { value: 'MTL', name: 'Malta Liri – MTL' },
  { value: 'MUR', name: 'Mauritius Rupees – MUR' },
  { value: 'MXN', name: 'Mexico Pesos – MXN' },
  { value: 'MAD', name: 'Morocco Dirhams – MAD' },
  { value: 'NLG', name: 'Netherlands Guilders – NLG', symbol: 'ƒ' },
  { value: 'NZD', name: 'New Zealand Dollars – NZD' },
  { value: 'NGN', name: 'Nigerian Naira – NGN', symbol: '₦' },
  { value: 'NOK', name: 'Norway Kroner – NOK', symbol: 'kr' },
  { value: 'OMR', name: 'Oman Rials – OMR' },
  { value: 'PKR', name: 'Pakistan Rupees – PKR', symbol: '₨' },
  { value: 'XPD', name: 'Palladium Ounces – XPD' },
  { value: 'PEN', name: 'Peru Nuevos Soles – PEN', symbol: 'S/.' },
  { value: 'PHP', name: 'Philippines Pesos – PHP', symbol: '₱' },
  { value: 'XPT', name: 'Platinum Ounces – XPT' },
  { value: 'PLN', name: 'Poland Zlotych – PLN', symbol: 'zł' },
  { value: 'PTE', name: 'Portugal Escudos – PTE' },
  { value: 'QAR', name: 'Qatar Riyals – QAR' },
  { value: 'ROL', name: 'Romania Lei – ROL', symbol: 'lei' },
  { value: 'RUB', name: 'Russia Rubles – RUB', symbol: '₽' },
  { value: 'SAR', name: 'Saudi Arabia Riyals – SAR', symbol: '﷼' },
  { value: 'XAG', name: 'Silver Ounces – XAG' },
  { value: 'SGD', name: 'Singapore Dollars – SGD' },
  { value: 'SKK', name: 'Slovakia Koruny – SKK' },
  { value: 'SIT', name: 'Slovenia Tolars – SIT' },
  { value: 'ZAR', name: 'South Africa Rand – ZAR', symbol: 'R' },
  { value: 'KRW', name: 'South Korea Won – KRW', symbol: '₩' },
  { value: 'ESP', name: 'Spain Pesetas – ESP' },
  { value: 'XDR', name: 'Special Drawing Rights (IMF) – XDR' },
  { value: 'LKR', name: 'Sri Lanka Rupees – LKR' },
  { value: 'SDD', name: 'Sudan Dinars – SDD' },
  { value: 'SEK', name: 'Sweden Kronor – SEK' },
  { value: 'CHF', name: 'Switzerland Francs – CHF' },
  { value: 'TWD', name: 'Taiwan New Dollars – TWD', symbol: 'NT$' },
  { value: 'THB', name: 'Thailand Baht – THB', symbol: '฿' },
  { value: 'TTD', name: 'Trinidad and Tobago Dollars – TTD', symbol: 'TT$' },
  { value: 'TND', name: 'Tunisia Dinars – TND' },
  { value: 'TRY', name: 'Turkey New Lira – TRY' },
  { value: 'AED', name: 'United Arab Emirates Dirhams – AED' },
  { value: 'GBP', name: 'United Kingdom Pounds – GBP', symbol: '£' },
  { value: 'VEB', name: 'Venezuela Bolivares – VEB', symbol: 'Bs' },
  { value: 'VND', name: 'Vietnam Dong – VND' },
  { value: 'ZMK', name: 'Zambia Kwacha – ZMK' },
];

export const getCurrencyLocale = () => {
  const state = store.getState();
  const selectedCompany = getSelectedCompany(state);
  if (!selectedCompany) return '';

  return selectedCompany.currency;
};

export const findCurrencySymbol = () => {
  const currency = getCurrencyLocale();
  const selectedCurrency = Currencies.find((x) => x.value === currency);
  // eslint-disable-next-line no-prototype-builtins -- predates description requirement
  return selectedCurrency && selectedCurrency.hasOwnProperty('symbol')
    ? selectedCurrency.symbol
    : currency;
};
