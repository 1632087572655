import { useReducer } from 'react';

/**
 * Returns a function that forces the parent component to rerender when called.
 * Mostly used when integrating with 3rd-party libraries outside React's render
 * cycle.
 *
 * @returns {Function}
 */
export default function useForceUpdate() {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  return forceUpdate;
}
