// @ts-check
import Modal from '@/components/common/Modal';
import './CompanyAddressValidation.scss';

const SUGGESTED_ADDRESS_FOUND_TEXT =
  'We could not find an exact match for the address you entered. Would you like to use this one instead?';

const SUGGESTED_ADDRESS_NOT_FOUND_TEXT =
  'We could not find a match for the address you entered. Would you like to continue with the address you have entered?';

/**
 * @typedef {{
 *   'data-testid': string;
 *   'onClose': () => void;
 *   'open': boolean;
 *   'onAddressSelection': (
 *     useOriginalAddress: boolean,
 *     validationAddress?: import('./types').AddressUs,
 *   ) => void;
 *   'suggestedAddress': null | import('./types').AddressUs;
 *   'originalAddress': import('./types').AddressBase;
 * }} CompanyAddressValidationModalProps
 */

/**
 * @type {(
 *   props: CompanyAddressValidationModalProps,
 * ) => React.ReactElement}
 */

const CompanyAddressValidationModal = ({
  open,
  onClose,
  originalAddress,
  suggestedAddress,
  onAddressSelection,
}) => {
  return (
    <Modal
      open={open}
      className="AddressValidation_Modal"
      data-testid="addrValidationModal"
      onClose={onClose}
    >
      <a
        href="#"
        className="AddressValidation_Link AddressValidation_Link-back"
        onClick={onClose}
      >
        Go Back
      </a>
      <h2 className="AddressValidation_Heading">Address Validation</h2>
      <p className="AddressValidation_SubHeading">
        {suggestedAddress
          ? SUGGESTED_ADDRESS_FOUND_TEXT
          : SUGGESTED_ADDRESS_NOT_FOUND_TEXT}
      </p>
      {suggestedAddress && (
        <div className="AddressValidation_SectionWrapper">
          <div className="AddressValidation_SectionTitle AddressValidation_SectionTitle-suggested">
            closest match
          </div>
          <p className="AddressValidation_AddressText">
            {suggestedAddress.street}
          </p>
          {suggestedAddress.streetLineTwo && (
            <p className="AddressValidation_AddressText">
              {suggestedAddress.streetLineTwo}
            </p>
          )}
          <p className="AddressValidation_AddressText">
            {suggestedAddress.city}
          </p>
          <p className="AddressValidation_AddressText">
            {suggestedAddress.zipFiveDigit}
          </p>
          <p className="AddressValidation_AddressText">
            {suggestedAddress.country}
          </p>
          <a
            href="#"
            className="AddressValidation_Link AddressValidation_Link-forward"
            onClick={() => onAddressSelection(false, suggestedAddress)}
            data-testid="suggested-address-selection"
          >
            Use this address
          </a>
        </div>
      )}
      <div className="AddressValidation_SectionWrapper">
        <div className="AddressValidation_SectionTitle">original address</div>
        <p className="AddressValidation_AddressText">
          {originalAddress.street}
        </p>
        {originalAddress.streetLineTwo && (
          <p className="AddressValidation_AddressText">
            {originalAddress.streetLineTwo}
          </p>
        )}

        <p className="AddressValidation_AddressText">{originalAddress.city}</p>
        <p className="AddressValidation_AddressText">
          {originalAddress.zipCode}
        </p>
        <p className="AddressValidation_AddressText">
          {originalAddress.country}
        </p>
        <a
          href="#"
          className="AddressValidation_Link AddressValidation_Link-forward"
          onClick={() => onAddressSelection(true)}
          data-testid="original-address-selection"
        >
          Use this address
        </a>
      </div>
    </Modal>
  );
};

export default CompanyAddressValidationModal;
