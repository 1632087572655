import { useCallback, useRef, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import TogglePersistent from '@/components/common/TogglePersistent';
import { formatPercent } from '@/helpers';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import useChartPreferences from '@/hooks/useChartPreferences';
import useDashboardPath from '@/hooks/useDashboardPath';
import NewRevenueChart, {
  NEW_REVENUE_TOGGLE_ID,
} from './charts/NewRevenueChart';

const CHART_TITLE = 'New Revenue';

function NewRevenue() {
  const { startDate, endDate } = useSelector(({ shared }) => shared);
  const showPercentage = useChartPreferences(NEW_REVENUE_TOGGLE_ID);
  const [newRevenue, setNewRevenue] = useState();
  const chartRef = useRef();
  const dashboardPath = useDashboardPath();
  const onQueryStateChange = useCallback(({ data, isLoading }) => {
    return !isLoading && setNewRevenue(data.mainMetric);
  }, []);

  return (
    <section className="Panel Panel-toEdge">
      <ChartHeader back={dashboardPath} title={CHART_TITLE}>
        <div className="ChartHeader_KPIValue">
          {showPercentage
            ? formatPercent(newRevenue)
            : formatMonetary(newRevenue)}
        </div>
        <div className="ChartHeader_KPI">
          {showPercentage ? (
            <span className="ChartHeader_Subtitle">Average for:</span>
          ) : (
            <br />
          )}
          {formatRangeWithShortYear(startDate, endDate)}
        </div>
      </ChartHeader>
      <ChartToolbar>
        <TogglePersistent
          id={NEW_REVENUE_TOGGLE_ID}
          options={[
            {
              label: 'Currency',
              value: false,
            },
            {
              label: 'Percent',
              value: true,
            },
          ]}
          value={false}
        />
        <ChartControls
          chartRef={chartRef}
          title={CHART_TITLE}
          metricValue={
            showPercentage
              ? formatPercent(newRevenue)
              : formatMonetary(newRevenue)
          }
          label={
            showPercentage
              ? `Average for: ${formatRangeWithShortYear(startDate, endDate)}`
              : formatRangeWithShortYear(startDate, endDate)
          }
          data-testid="newRevenue-chartControls"
        />
      </ChartToolbar>
      <NewRevenueChart
        className="ExpandedView_ChartWrapper"
        exportBtn={chartRef}
        onQueryStateChange={onQueryStateChange}
        showPercent={showPercentage}
      />
    </section>
  );
}

export default NewRevenue;
