import { useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { METRICS_LIST } from '@/cacheKeys';
import PageHeader from '@/components/Layouts/PageHeader';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';
import SearchField from '@/components/common/SearchField';
import { METRICS_PATH } from '@/constants/pages';
import MetricsList from '@/pages/Metrics/MetricsList';
import TagsPanel from '@/pages/Metrics/TagsPanel';
import { getMetricsList } from '@/services/metricsService';

const ERROR_BOUNDARY_TEXT =
  'An error occurred while trying to retrieve the metrics.';

const enrichMetricsWithSearch = (metrics) => {
  return metrics.map(({ metric, tags }) => {
    const { name, summary } = metric;
    const tagsString = tags.reduce((string, tag) => {
      return `${string} ${tag.name.toLowerCase()} `;
    }, '');
    const searchString = tagsString.concat(name, summary).toLowerCase();
    return { metric, tags, searchString };
  });
};

const Metrics = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [tagsSelected, setTagsSelected] = useState([]);
  const [typeSelected, setTypeSelected] = useState([]);

  const { data, isLoading } = useQuery(
    [METRICS_LIST],
    async () => {
      const result = await getMetricsList();
      return enrichMetricsWithSearch(result.data.data?.metrics ?? []);
    },
    { staleTime: 60000 },
  );

  const tagsMapWithCount = useMemo(() => {
    return data?.reduce((mapObj, metric) => {
      metric.tags.forEach(({ name }) => {
        // eslint-disable-next-line no-param-reassign -- predates description requirement
        mapObj[name] = mapObj[name] ? mapObj[name] + 1 : 1;
      });

      const metricUnit = metric.metric.unit;
      // eslint-disable-next-line no-param-reassign -- predates description requirement
      mapObj[metricUnit] = mapObj[metricUnit] ? mapObj[metricUnit] + 1 : 1;

      return mapObj;
    }, {});
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, data);

  const metricsList = useMemo(() => {
    const trimmedSearchTerm = searchTerm.trim();
    const noFilterSelected =
      !trimmedSearchTerm &&
      !Object.keys(tagsSelected).length &&
      !typeSelected.length;

    if (noFilterSelected) {
      return data;
    }
    const searchArray = searchTerm.trim().toLowerCase().split(' ');

    let metricsData = data;
    if (typeSelected.length) {
      metricsData = data.filter((metric) =>
        typeSelected.includes(metric.metric.unit),
      );
    }

    return trimmedSearchTerm || tagsSelected.length
      ? metricsData.filter(
          (metric) =>
            searchArray.every((term) => metric.searchString.includes(term)) &&
            tagsSelected.every((selected) =>
              metric.tags.map(({ id }) => id).includes(selected),
            ),
        )
      : metricsData;
  }, [searchTerm, data, tagsSelected, typeSelected]);

  return (
    <>
      <PageHeader page={METRICS_PATH} includeDatepicker={false} />
      <CommonErrorBoundary text={ERROR_BOUNDARY_TEXT}>
        <div className="Metrics_Wrapper">
          <TagsPanel
            tagsSelected={tagsSelected}
            onTagSelection={setTagsSelected}
            typeSelected={typeSelected}
            onTypeSelection={setTypeSelected}
            tagsMap={tagsMapWithCount}
          />
          <div className="Metrics_MetricListWrapper">
            <SearchField
              id="search-metrics"
              name="search-metrics"
              value={searchTerm}
              onChange={setSearchTerm}
              placeholder="Search by word, metric name, or tag..."
              className="Metrics_SearchField"
            />
            <MetricsList metricsList={metricsList} isLoading={isLoading} />
          </div>
        </div>
      </CommonErrorBoundary>
    </>
  );
};

export default Metrics;
