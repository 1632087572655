import {
  LOGOUT,
  SET_DATE,
  SET_WEBSOCKET_FAILED,
  RESET_TIME_PERIOD,
} from '@/actionTypes/shared';
import { MONTHLY } from '@/constants/dateTime';
import wsApi from '@/services/WsApi';

export const clearReducers = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
};

export const setDate = (startDate, endDate, timePeriod) => {
  return {
    type: SET_DATE,
    payload: { startDate, endDate, timePeriod },
  };
};

export const resetTimePeriodAction = () => {
  return (dispatch) =>
    dispatch({
      type: RESET_TIME_PERIOD,
      payload: MONTHLY,
    });
};

/**
 * Signals that a websocket connection failure has occurred, so that an error
 * message can be displayed to the user
 *
 * @type {(
 *   hasFailed: boolean,
 * ) => import('@/types/extend-redux-toolkit').AppThunk<void>}
 */
export const setWebSocketFailedAction = (hasFailed) => {
  return (dispatch) =>
    dispatch({
      type: SET_WEBSOCKET_FAILED,
      payload: hasFailed,
    });
};

/**
 * Establishes a WebSocket subscription to the given topic and executes the
 * callback when messages received
 *
 * @param {string} topic A valid WebSocket topic to subscribe to
 * @param {Function} callback Function to run when messages are received
 * @returns {import('@/types/extend-redux-toolkit').AppThunk<
 *   Promise<import('@stomp/stompjs').StompSubscription>
 * >}
 *   Dispatchable action
 */
export const subscribeToTopic = (topic, callback) => {
  return async (_, getState) => {
    const { auth } = getState();
    await wsApi.connect({ Authorization: auth.identityToken });
    return wsApi.subscribe(`/topic/${topic}`, callback);
  };
};

/**
 * Creates a WebSocket subscription for a given topic using an optional
 * temporary identity token (when onboarding a Bill user for the first time) or
 * a Finmark identity token for existing users
 *
 * @type {(
 *   dataProps: { topic: string; tempIdentityToken: string | null },
 *   callback: (payload: unknown) => void,
 * ) => import('@/types/extend-redux-toolkit').AppThunk<
 *   Promise<import('@stomp/stompjs').StompSubscription>
 * >}
 */
export const subscribeToTopicWithTempToken = (
  { topic, tempIdentityToken },
  callback,
) => {
  return async (_, getState) => {
    const { auth } = getState();
    await wsApi.connect({
      Authorization: auth.identityToken || tempIdentityToken,
    });
    return wsApi.subscribe(`/topic/${topic}`, callback);
  };
};

/**
 * Publishes a WebSocket message to the given topic
 *
 * @type {(
 *   topic: string,
 *   message: unknown,
 * ) => import('@/types/extend-redux-toolkit').AppThunk<Promise<void>>}
 */
export const publishToTopic = (topic, message) => {
  return async (_, getState) => {
    const { auth } = getState();
    await wsApi.connect({ Authorization: auth.identityToken });
    return wsApi.publish(`/app/topic/${topic}`, JSON.stringify(message));
  };
};

/**
 * Established a WebSocket subscription for receiving monthly value updates.
 * Messages outside the current global date range will be filtered out.
 *
 * @param {string} topic A valid WebSocket topic to subscribe to
 * @param {string} actionType Action to dispatch with each message payload
 * @returns {Function} Dispatchable action
 */
export const subscribeToMonthlyValues = (topic, actionType) => {
  return (dispatch, getState) => {
    return dispatch(
      subscribeToTopic(topic, (payload) => {
        // Filter messages outside of the current date range
        const { startDate, endDate } = getState().shared;
        const startMs = new Date(startDate).getTime();
        const endMs = new Date(endDate).getTime();
        const msgDateMs = new Date(payload.month).getTime();
        if (msgDateMs >= startMs && msgDateMs <= endMs) {
          dispatch({
            type: actionType,
            payload,
          });
        }
      }),
    );
  };
};

/**
 * actionWithPermission is an action that validates if the current user has
 * access to perform that action on a given subject.
 *
 * @param {'Write' | 'Read' | 'ReadWrite','Manage'} action - Permission to check
 *   against subject
 * @param {string} subject - Subject to be checked
 * @param {'Write' | 'Read' | 'ReadWrite','Manage'} scenarioAction - Scenario
 *   Permission to check against subject
 * @param {string} scenarioSubject - Scenario Subject to be checked
 * @param {Function} callback - Action to dispatch if user has permissions
 * @returns {Function} dispatchable action
 */
export const actionWithPermission = (
  { action, subject, scenarioAction, scenarioSubject },
  callback,
) => {
  return async (dispatch, getState) => {
    const {
      auth: { permissions, scenarioPermissions },
      scenario: { scenarioId },
    } = getState();
    const permissionRequired = Boolean(action && subject);
    const scenarioPermissionRequired = Boolean(
      scenarioAction && scenarioSubject,
    );
    let hasPermission;
    if (scenarioPermissionRequired) {
      hasPermission = scenarioPermissions[scenarioId]?.permissions.find(
        (permission) =>
          permission.action === scenarioAction &&
          permission.subject === scenarioSubject,
      );
    }

    if (permissionRequired && hasPermission !== false) {
      hasPermission = permissions.find(
        (permission) =>
          permission.action === action && permission.subject === subject,
      );
    }

    if (hasPermission) {
      dispatch(callback);
    }
  };
};
