// @ts-check
import { useCallback, useMemo, useReducer } from 'react';
import CrossIcon from '@bill/cashflow.assets/cross';
import { useMutation } from '@tanstack/react-query';
import { getRevenueStream } from '@/components/Revenue/DataMapping/helpers';
import Button from '@/components/common/Button';
import DateField from '@/components/common/DateField';
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';
import NumberField from '@/components/common/NumberField';
import Select from '@/components/common/Select';
import Sidebar from '@/components/common/Sidebar';
import {
  REVENUE_METRICS,
  columnNames,
  actionTypes,
} from '@/constants/dataMapping';
import { classNames, slugify } from '@/helpers';
import { getISODate } from '@/helpers/dateFormatter';
import { isEmptyOrNull } from '@/helpers/validators';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import {
  addRevenueIntegrationEntry,
  updateRevenueIntegrationEntry,
} from '@/services/revenueService';
import './AddIntegrationRecordSidebar.scss';

const { SET_REVENUE_STREAM } = actionTypes;

const {
  CUSTOMER_ID,
  DEAL_ID,
  DATE,
  CUSTOMER_NAME,
  AMOUNT,
  DEAL_NAME,
  REVENUE_DRIVER,
  PRODUCT_PRICING_PLAN,
  PRICING_PLAN,
  REVENUE_STREAM,
  CUSTOMER_METRIC,
  REVENUE_METRIC,
} = columnNames;

const SET_FIELD_VALUE = 'SET_FIELD_VALUE';
const REVENUE_STREAM_ID = SET_REVENUE_STREAM;
const PRICING_PLAN_ID = 'productPricingPlanId';
const REVENUE_METRIC_ID = 'revenueMetric';
const CUSTOMER_METRIC_ID = 'customerMetric';

const SYSTEM_DEFINED_FIELDS = [
  CUSTOMER_ID,
  DATE,
  AMOUNT,
  CUSTOMER_NAME,
  DEAL_NAME,
  DEAL_ID,
  REVENUE_DRIVER,
  PRODUCT_PRICING_PLAN,
  CUSTOMER_METRIC,
  REVENUE_METRIC,
  REVENUE_STREAM,
  PRICING_PLAN,
];

/** @typedef {(typeof SYSTEM_DEFINED_FIELDS)[number]} SystemDefinedField */

/**
 * @param {any} fieldName - The field name check
 * @returns {fieldName is SystemDefinedField}
 */
const isSystemDefined = (fieldName) => {
  return SYSTEM_DEFINED_FIELDS.includes(fieldName);
};

/**
 * @type {(
 *   streamType?: string,
 * ) => import('@/constants/dataMapping').MetricType[]}
 */
const getRevenueMetrics = (streamType) => {
  return REVENUE_METRICS[streamType]?.revenueMetric ?? [];
};

/**
 * @type {(
 *   streamType?: string,
 * ) => import('@/constants/dataMapping').MetricType[]}
 */
const getCustomerMetrics = (streamType) => {
  return REVENUE_METRICS[streamType]?.customerMetric ?? [];
};

const REQUIRED_FIELDS = [DATE, CUSTOMER_NAME, AMOUNT];

/**
 * @typedef {{
 *   [CUSTOMER_ID]: string;
 *   [DATE]: string;
 *   [AMOUNT]: string;
 *   [CUSTOMER_NAME]: string;
 *   [DEAL_NAME]: string;
 *   [REVENUE_STREAM_ID]: string;
 *   [PRICING_PLAN_ID]: string;
 *   [REVENUE_METRIC_ID]: string;
 *   [CUSTOMER_METRIC_ID]: string;
 *   pricingPlans: import('@/services/revenueService').PricingPlans;
 *   revenuerMetricsList: import('@/constants/dataMapping').MetricType[];
 *   customerMetricsList: import('@/constants/dataMapping').MetricType[];
 * }} FormState
 */

/** @type {FormState} */
const INITIAL_STATE = {
  [CUSTOMER_ID]: '',
  [DATE]: '',
  [AMOUNT]: '',
  [CUSTOMER_NAME]: '',
  [DEAL_NAME]: '',
  [REVENUE_STREAM_ID]: '',
  [PRICING_PLAN_ID]: '',
  [REVENUE_METRIC_ID]: '',
  [CUSTOMER_METRIC_ID]: '',
  pricingPlans: [],
  revenuerMetricsList: [],
  customerMetricsList: [],
};

/**
 * @typedef {{
 *   pricingPlans: import('@/services/revenueService').PricingPlans;
 *   revenuerMetricsList: import('@/constants/dataMapping').MetricType[];
 *   customerMetricsList: import('@/constants/dataMapping').MetricType[];
 * }} handleRevenueStreamReturn
 */

/**
 * @type {(
 *   revenueStreamId: string,
 *   revenueStreams: import('@/services/revenueService').RevenueStreamsWithPricingPlan[],
 * ) => handleRevenueStreamReturn}
 */
const getMetricsWithPricingPlans = (revenueStreamId, revenueStreams) => {
  const revenueStream = getRevenueStream(
    Number(revenueStreamId),
    revenueStreams,
  );
  return {
    pricingPlans: revenueStream?.pricingPlans ?? [],
    revenuerMetricsList: getRevenueMetrics(revenueStream?.streamType),
    customerMetricsList: getCustomerMetrics(revenueStream?.streamType),
  };
};

/**
 * @type {(
 *   editRecord: import('@/services/revenueService').RevenueIntegrationEntry,
 *   revenueStreams: import('@/services/revenueService').RevenueStreamsWithPricingPlan[],
 * ) => FormState}
 */
const getState = (editRecord, revenueStreams) => {
  if (!editRecord) {
    return INITIAL_STATE;
  }

  const state = { ...INITIAL_STATE };
  editRecord.fields.forEach((field) => {
    switch (field.name) {
      case DEAL_ID:
        break;
      case REVENUE_STREAM: {
        const streamID = field.id ? String(field.id) : '';
        const { pricingPlans, revenuerMetricsList, customerMetricsList } =
          getMetricsWithPricingPlans(streamID, revenueStreams);
        state[REVENUE_STREAM_ID] = streamID;
        state.pricingPlans = pricingPlans;
        state.revenuerMetricsList = revenuerMetricsList;
        state.customerMetricsList = customerMetricsList;
        break;
      }
      case PRICING_PLAN:
        state[PRICING_PLAN_ID] = field.id ? String(field.id) : '';
        break;
      case REVENUE_METRIC:
        state[REVENUE_METRIC_ID] = field.id ? String(field.id) : '';
        break;
      case CUSTOMER_METRIC:
        state[CUSTOMER_METRIC_ID] = field.id ? String(field.id) : '';
        break;
      default:
        state[field.name] = field.value;
        break;
    }
  });

  return state;
};

/**
 * @typedef {{
 *       type: 'SET_FIELD_VALUE';
 *       name: string;
 *       value: string;
 *     }
 *   | {
 *       type: import('@/constants/dataMapping').ActionTypes['SET_REVENUE_STREAM'];
 *       [REVENUE_STREAM_ID]: string;
 *       revenueStreams: import('@/services/revenueService').RevenueStreamsWithPricingPlan[];
 *     }} SetFieldValueAction
 */

/** @type {(state: FormState, action: SetFieldValueAction) => FormState} */
const reducer = (state, action) => {
  switch (action.type) {
    case SET_FIELD_VALUE:
      return { ...state, [action.name]: action.value };
    case SET_REVENUE_STREAM: {
      const { revenueStreamId, revenueStreams } = action;
      const { pricingPlans, revenuerMetricsList, customerMetricsList } =
        getMetricsWithPricingPlans(revenueStreamId, revenueStreams);
      return {
        ...state,
        revenueStreamId,
        pricingPlans,
        revenuerMetricsList,
        customerMetricsList,
        [PRICING_PLAN_ID]: '',
        [CUSTOMER_METRIC_ID]: '',
        [REVENUE_METRIC_ID]: '',
      };
    }
    default:
      return state;
  }
};

/**
 * @typedef {{
 *   fields: import('@/services/revenueService').RevenueIntegrationMetadataResponseData[];
 *   revenueStreams: import('@/services/revenueService').RevenueStreamsWithPricingPlan[];
 *   onClose: () => void;
 *   editRecord: import('@/services/revenueService').RevenueIntegrationEntry;
 *   columnNameMap: import('./types').RevenueIntegrationColumnMap;
 * }} AddIntegrationRecordFormProps
 */

/** @type {(props: AddIntegrationRecordFormProps) => React.ReactElement} */
const AddIntegrationRecordForm = ({
  fields,
  revenueStreams,
  editRecord,
  columnNameMap,
  onClose,
}) => {
  const [formState, setFormState] = useReducer(
    reducer,
    getState(editRecord, revenueStreams),
  );

  const [scenarioId] = useSelectedScenarioIds();

  const mutationOptions = { onSuccess: onClose };

  const { mutate: createEntry, isLoading } = useMutation(
    addRevenueIntegrationEntry,
    mutationOptions,
  );

  const { mutate: updateEntry, isLoading: isLoadingUpdateRecord } = useMutation(
    updateRevenueIntegrationEntry,
    mutationOptions,
  );

  const customerDefinedFields = useMemo(
    () => fields.filter((field) => !isSystemDefined(field.name)),
    [fields],
  );

  const handleSubmit = useCallback(() => {
    const {
      revenueStreamId,
      productPricingPlanId,
      customerMetric,
      revenueMetric,
      pricingPlans,
      revenuerMetricsList,
      customerMetricsList,
      ...formFields
    } = formState;

    const data = Object.entries(formFields)
      .map(([name, value]) => {
        /** @type {import('@/services/revenueService').RevenueIntegrationRecord} */
        const result = {
          name,
          value,
        };
        return result;
      })
      .filter(({ value }) => !isEmptyOrNull(value));

    /** @type {import('@/services/revenueService').RevenueIntegrationPayload} */
    const payload = {
      fields: data,
      overrides: {
        [REVENUE_STREAM]: revenueStreamId ? Number(revenueStreamId) : null,
        [PRICING_PLAN]: productPricingPlanId
          ? Number(productPricingPlanId)
          : null,
        [REVENUE_METRIC]: revenueMetric || null,
        [CUSTOMER_METRIC]: customerMetric || null,
      },
    };

    if (editRecord) {
      updateEntry({
        scenarioId,
        revenueDealEntryId: editRecord.id,
        data: payload,
      });
      return;
    }
    createEntry({ scenarioId, data: payload });
  }, [formState, createEntry, scenarioId, editRecord, updateEntry]);

  const hasRevenueStream = useMemo(
    () => isEmptyOrNull(formState.revenueStreamId),
    [formState.revenueStreamId],
  );

  const isPricingPlansVisible = useMemo(
    () => hasRevenueStream || formState.pricingPlans.length > 0,
    [hasRevenueStream, formState.pricingPlans],
  );

  const isCustomerMetricsVisible = useMemo(
    () => hasRevenueStream || formState.customerMetricsList.length > 0,
    [hasRevenueStream, formState.customerMetricsList],
  );

  const entryDate = formState[DATE]
    ? new Date(formState[DATE]).getTime()
    : null;

  const handleDateChange = useCallback((date) => {
    setFormState({
      type: SET_FIELD_VALUE,
      name: DATE,
      value: date ? getISODate(date) : '',
    });
  }, []);

  const validateDate = useCallback(
    () => (!formState[DATE] ? `${DATE} is required` : ''),
    [formState],
  );

  return (
    <div className="RevenueEntryForm_Panel">
      <header className="Sidebar_Header">
        <h3 className="Sidebar_Title">
          {editRecord ? 'Edit Entry' : 'Add New Entry'}
        </h3>
        <button
          className="Sidebar_CloseBtn"
          onClick={onClose}
          aria-label="Close"
        >
          <CrossIcon className="CloseIcon" />
        </button>
      </header>
      <div className="RevenueEntryForm_Content">
        <div className="Form">
          <div className="Form_Group">
            <FormLabel
              htmlFor="customer-id"
              text={columnNameMap[CUSTOMER_ID].name}
              optional
            />
            <FormField
              id="customer-id"
              name={CUSTOMER_ID}
              data-testid="customer-id"
              maxLength={500}
              value={formState[CUSTOMER_ID]}
              onChange={
                /** @type {React.ChangeEventHandler<HTMLInputElement>} */ ({
                  target,
                }) => {
                  setFormState({
                    type: SET_FIELD_VALUE,
                    name: CUSTOMER_ID,
                    value: target.value,
                  });
                }
              }
            />
          </div>
          <div className="Form_Group">
            <FormLabel
              htmlFor="customer-name"
              text={columnNameMap[CUSTOMER_NAME].name}
            />
            <FormField
              id="customer-name"
              name={CUSTOMER_NAME}
              data-testid="customer-name"
              maxLength={500}
              value={formState[CUSTOMER_NAME]}
              validate={() =>
                !formState[CUSTOMER_NAME] ? `${CUSTOMER_NAME} is required` : ''
              }
              onChange={
                /** @type {React.ChangeEventHandler<HTMLInputElement>} */ ({
                  target,
                }) => {
                  setFormState({
                    type: SET_FIELD_VALUE,
                    name: CUSTOMER_NAME,
                    value: target.value,
                  });
                }
              }
            />
          </div>
          <div className="Form_Group">
            <FormLabel htmlFor="date" text={columnNameMap[DATE].name} />
            <DateField
              id="date"
              name={DATE}
              value={entryDate}
              validate={validateDate}
              onChange={handleDateChange}
            />
          </div>
          <div className="Form_Group">
            <FormLabel htmlFor="amount" text={columnNameMap[AMOUNT].name} />
            <NumberField
              id="amount"
              name={AMOUNT}
              data-testid="amount"
              className="RevenueEntryForm_Amount"
              value={formState[AMOUNT]}
              validate={() =>
                !formState[AMOUNT] ? `${AMOUNT} is required` : ''
              }
              onChange={
                /** @type {React.ChangeEventHandler<HTMLInputElement>} */ ({
                  target,
                }) => {
                  const maxLength = 500;
                  setFormState({
                    type: SET_FIELD_VALUE,
                    name: AMOUNT,
                    value: target.value.slice(0, maxLength),
                  });
                }
              }
            />
          </div>
          <div className="Form_Group">
            <FormLabel
              htmlFor="deal-name"
              text={columnNameMap[DEAL_NAME].label}
              optional
            />
            <FormField
              id="deal-name"
              name={DEAL_NAME}
              data-testid="deal-name"
              maxLength={500}
              value={formState[DEAL_NAME]}
              onChange={
                /** @type {React.ChangeEventHandler<HTMLInputElement>} */ ({
                  target,
                }) => {
                  setFormState({
                    type: SET_FIELD_VALUE,
                    name: DEAL_NAME,
                    value: target.value,
                  });
                }
              }
            />
          </div>
          <div className="Form_Group">
            <FormLabel
              htmlFor="revenue_stream"
              text={columnNameMap[REVENUE_STREAM].name}
              optional
            />
            <Select
              id="revenue-stream"
              name={REVENUE_STREAM_ID}
              data-testid="revenue_stream"
              value={formState[REVENUE_STREAM_ID]}
              onChange={
                /** @type {React.ChangeEventHandler<HTMLSelectElement>} */ ({
                  target,
                }) => {
                  setFormState({
                    type: SET_REVENUE_STREAM,
                    [REVENUE_STREAM_ID]: target.value,
                    revenueStreams,
                  });
                }
              }
            >
              <option value="" key="empty-stream">
                Select Revenue Stream
              </option>
              {revenueStreams.map(({ streamId, streamName }) => (
                <option key={streamId} value={streamId}>
                  {streamName}
                </option>
              ))}
            </Select>
          </div>

          {isPricingPlansVisible && (
            <div className="Form_Group">
              <FormLabel
                htmlFor="pricing-plan"
                text={columnNameMap[PRICING_PLAN].name}
                optional
              />
              <Select
                id="pricing-plan"
                name={PRICING_PLAN_ID}
                data-testid="pricing_plan"
                value={formState[PRICING_PLAN_ID]}
                onChange={
                  /** @type {React.ChangeEventHandler<HTMLSelectElement>} */ ({
                    target,
                  }) => {
                    setFormState({
                      type: SET_FIELD_VALUE,
                      name: PRICING_PLAN_ID,
                      value: target.value,
                    });
                  }
                }
              >
                <option value="" key="empty-pricing-plan">
                  Select Pricing Plan
                </option>
                {formState.pricingPlans.map(({ planId, planName }) => (
                  <option key={planId} value={planId}>
                    {planName}
                  </option>
                ))}
              </Select>
            </div>
          )}

          <div className="Form_Group">
            <FormLabel
              htmlFor="revenue-metric"
              text={columnNameMap[REVENUE_METRIC].name}
              optional
            />
            <Select
              id="revenue-metric"
              name={REVENUE_METRIC_ID}
              data-testid="revenue-metric"
              value={formState[REVENUE_METRIC_ID]}
              onChange={
                /** @type {React.ChangeEventHandler<HTMLSelectElement>} */ ({
                  target,
                }) => {
                  setFormState({
                    type: SET_FIELD_VALUE,
                    name: REVENUE_METRIC_ID,
                    value: target.value,
                  });
                }
              }
            >
              <option value="">Select Revenue Metric</option>
              {formState.revenuerMetricsList.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
          </div>

          {isCustomerMetricsVisible && (
            <div className="Form_Group">
              <FormLabel
                htmlFor="customer-metric"
                text={columnNameMap[CUSTOMER_METRIC].name}
                optional
              />
              <Select
                id="customer-metric"
                name={CUSTOMER_METRIC_ID}
                data-testid="customer-metric"
                value={formState[CUSTOMER_METRIC_ID]}
                onChange={
                  /** @type {React.ChangeEventHandler<HTMLSelectElement>} */ ({
                    target,
                  }) => {
                    setFormState({
                      type: SET_FIELD_VALUE,
                      name: CUSTOMER_METRIC_ID,
                      value: target.value,
                    });
                  }
                }
              >
                <option value="">Select Customer Metric</option>
                {formState.customerMetricsList.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
            </div>
          )}

          {customerDefinedFields.length > 0 && (
            <div
              className={classNames('Form', 'RevenueEntryForm_CustomerFields')}
            >
              {customerDefinedFields.map(({ name }) => {
                const fieldId = slugify(name);
                return (
                  <div className="Form_Group" key={name}>
                    <FormLabel htmlFor={fieldId} text={name} optional />
                    <FormField
                      id={fieldId}
                      name={name}
                      data-testid={fieldId}
                      value={formState[name] ?? ''}
                      maxLength={500}
                      onChange={
                        /** @type {React.ChangeEventHandler<HTMLInputElement>} */ ({
                          target,
                        }) => {
                          setFormState({
                            type: SET_FIELD_VALUE,
                            name,
                            value: target.value,
                          });
                        }
                      }
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <Button
          className="Button-primary RevenueEntryForm_SaveButton"
          data-testid="entry-save-button"
          loading={isLoading || isLoadingUpdateRecord}
          disabled={
            REQUIRED_FIELDS.some((name) => !formState[name]) ||
            (formState.pricingPlans.length > 0 &&
              isEmptyOrNull(formState[PRICING_PLAN_ID]))
          }
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

/**
 * @typedef {{
 *   fields: import('@/services/revenueService').RevenueIntegrationMetadataResponseData[];
 *   revenueStreams: import('@/services/revenueService').RevenueStreamsWithPricingPlan[];
 *   open: boolean;
 *   onClose: () => void;
 *   editRecord: import('@/services/revenueService').RevenueIntegrationEntry;
 *   columnNameMap: import('./types').RevenueIntegrationColumnMap;
 * }} AddIntegrationRecordSidebarProps
 */

/** @type {(props: AddIntegrationRecordSidebarProps) => React.ReactElement} */
const AddIntegrationRecordSidebar = ({
  fields,
  revenueStreams,
  editRecord,
  columnNameMap,
  open,
  onClose,
}) => {
  return (
    <Sidebar className="RevenueEntrySidebar" open={open} onClose={onClose}>
      {open && (
        <AddIntegrationRecordForm
          fields={fields}
          revenueStreams={revenueStreams}
          editRecord={editRecord}
          columnNameMap={columnNameMap}
          onClose={onClose}
        />
      )}
    </Sidebar>
  );
};

export default AddIntegrationRecordSidebar;
