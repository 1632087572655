import { useMemo } from 'react';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import useTypedSelector from '@/hooks/useTypedSelector';
import useEffectOnUpdate from './useEffectOnUpdate';
import useScenarioQueryReducer from './useScenarioQueryReducer';
import useSelectedScenarios from './useSelectedScenarios';

const DEPS_DEFAULT = [];
const QUERY_PARAMS_DEFAULT = {};

/**
 * @typedef {{
 *   accounts: import('@/types/dashboard').StackChartPayload[];
 * }} ReducerProps
 *
 *
 * @typedef {{
 *   overallTotal: number;
 *   data: import('@/types/dashboard').ChartMonthlyData[];
 * }} ReducerResult
 *
 *
 * @typedef {{
 *   filter: import('@/types/services/backend').CashInOutGrid['cashFlowType'];
 * }} QueryParams
 */

/**
 * A hook for populating charts. Calls the given service for multiple selected
 * scenarios, passing an array of scenarios to the service, caches the response,
 * and passes it to the given reducer to be transformed for consumption.
 *
 * @type {(
 *   cacheKey: string,
 *   service: (
 *     context: import('@/services/getByDateRange').GetByDateRangeProps<any>,
 *   ) => Promise<
 *     import('@/types/api').AxiosApiResponse<{
 *       data: import('@/types/services/backend').CashInOutChartItem[];
 *     }>
 *   >,
 *   reducer: (props: ReducerProps) => ReducerResult,
 *   compareReducer: (props: ReducerProps) => ReducerResult,
 *   options?: {
 *     dependencies?: any[];
 *     queryParams?: QueryParams;
 *   },
 * ) => import('@/hooks/useScenarioQueryReducer').ScenarioQueryResult[]}
 */
export default function useCashFlowChartQuery(
  cacheKey,
  service,
  reducer,
  compareReducer,
  options = {},
) {
  const { dependencies = DEPS_DEFAULT, queryParams = QUERY_PARAMS_DEFAULT } =
    options;
  const queryClient = useQueryClient();

  const scenarios = useSelectedScenarios();
  const [base, compare] = scenarios;
  const { startDate, endDate, timePeriod } = useTypedSelector(
    ({ shared }) => shared,
  );
  /** @type {number[]} */
  const scenarioIds = [];
  /**
   * Call the API service for each selected scenario
   *
   * @see https://react-query.tanstack.com/guides/parallel-queries#dynamic-parallel-queries-with-usequeries
   */
  const [baseQuery, compareQuery] = useQueries({
    queries: scenarios.map(({ scenarioId }) => {
      scenarioIds.push(scenarioId);
      return {
        queryKey: [
          cacheKey,
          scenarioIds,
          startDate,
          endDate,
          timePeriod,
          queryParams,
        ],
        queryFn: ({ signal }) =>
          service({
            startDate,
            endDate,
            scenarioIds,
            timePeriod,
            signal,
            ...queryParams,
          }),
        staleTime: 30000,
      };
    }),
  });

  // After the initial fetch of any dependencies, subsequent updates should
  // invalidate the cache.
  useEffectOnUpdate(
    () => base && queryClient.invalidateQueries([cacheKey, base.scenarioId]),
    dependencies,
  );
  // We don't reduce the data inside the queryFn so that we're caching the raw
  // API response. Multiple charts may rely on different reductions of the same
  // response, e.g. /totalMrr.
  const queryParamValues = Object.values(queryParams).join(',');
  const baseReduced = useScenarioQueryReducer(baseQuery, base, reducer, [
    queryParamValues,
    ...dependencies,
  ]);
  const compareReduced = useScenarioQueryReducer(
    compareQuery,
    compare,
    compareReducer ?? reducer,
    [queryParamValues, ...dependencies],
  );

  return useMemo(
    () => [baseReduced, compareReduced].filter((query) => query),
    [baseReduced, compareReduced],
  );
}
