import { forwardRef, Fragment, useState } from 'react';
import MoreInfoIcon from '@bill/cashflow.assets/more-info';
import PropTypes from 'prop-types';
import Permissions from '@/components/common/Permissions';
import WithPopover from '@/components/common/WithPopover';
import { childrenOf } from '@/helpers';
import ContextMenuOption from './ContextMenuOption';
import './ContextMenu.scss';

const ContextMenuBtn = forwardRef(({ Icon = MoreInfoIcon, ...props }, ref) => {
  return (
    <button ref={ref} type="button" className="ContextMenu_Btn" {...props}>
      <Icon className="ContextMenu_Icon" aria-label="Actions" />
    </button>
  );
});

/**
 * @typedef {{
 *   'children': React.ReactNode;
 *   'data-testid'?: string;
 *   'placement'?: string;
 *   'disabled'?: boolean;
 *   'Icon'?: React.ReactElement;
 *   'isHiddenOnClick'?: boolean;
 * }} ContextMenuProps
 */

/**
 * Renders a button that triggers a menu of context options when clicked, such
 * as 'edit' and 'delete'
 *
 * @example
 *   <ContextMenu>
 *     <ContextMenu.Option onClick={() => setEdit(foo)}>
 *       Edit
 *     </ContextMenu.Option>
 *     <ContextMenu.Option onClick={() => setDelete(foo)}>
 *       Delete
 *     </ContextMenu.Option>
 *   </ContextMenu>;
 *
 * @type {(props: ContextMenuProps) => React.ReactElement}
 */
function ContextMenu({
  children,
  'data-testid': dataTestId,
  disabled,
  Icon,
  placement = 'bottom-end',
  isHiddenOnClick = true,
  ...props
}) {
  const [isVisible, setVisible] = useState(false);

  return !children ? (
    <ContextMenuBtn disabled={disabled} data-testid={dataTestId} Icon={Icon} />
  ) : (
    <WithPopover
      content={
        <div
          role="presentation"
          onClick={() => isHiddenOnClick && setVisible(false)}
        >
          {children}
        </div>
      }
      className="Popover-toEdge"
      data-testid={`${dataTestId}-menu`}
      placement={placement}
      visible={isVisible}
      onClose={() => setVisible(false)}
      {...props}
    >
      <ContextMenuBtn
        disabled={disabled}
        data-testid={dataTestId}
        Icon={Icon}
        onClick={(event) => {
          event.stopPropagation();
          setVisible(!isVisible);
        }}
      />
    </WithPopover>
  );
}

ContextMenu.propTypes = {
  /** Options for the menu */
  'children': childrenOf([ContextMenuOption, Permissions, Fragment]),
  /** Unique identifier for selecting the menu in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Whether or not the trigger button should be disabled */
  'disabled': PropTypes.bool,
  /** Alternative icon for the trigger button */
  'Icon': PropTypes.elementType,
  /** Popover Placement */
  'placement': PropTypes.string,
  /**
   * Close the context menu when the user clicks on the content of the menu.
   * When `false`, the menu will remain open allowing the user to continue
   * interacting with the content.
   */
  'isHiddenOnClick': PropTypes.bool,
};

ContextMenu.Option = ContextMenuOption;

export default ContextMenu;
