import { EMPTY_CELL_VALUE } from '@/components/common/Spreadsheet/constants';
import metricFormatters from '@/helpers/metricFormatters';
import { isEmptyOrNull } from '@/helpers/validators';
import type { ExpenseResponseDto } from '@/types/services/backend';

// eslint-disable-next-line import/prefer-default-export -- exporting single function
export const amountFormatter = ({
  data,
}: {
  data: ExpenseResponseDto & { isUnsaved?: boolean };
}): string => {
  const { minAmount, maxAmount, customFormula, expenseAmount, isUnsaved } =
    data;

  if (isUnsaved) {
    return isEmptyOrNull(customFormula) && isEmptyOrNull(expenseAmount)
      ? EMPTY_CELL_VALUE
      : metricFormatters.monetary(customFormula ?? expenseAmount);
  }

  if (isEmptyOrNull(minAmount) && isEmptyOrNull(maxAmount)) {
    return EMPTY_CELL_VALUE;
  }

  if (!isEmptyOrNull(maxAmount) && minAmount !== maxAmount) {
    return `${metricFormatters.monetary(
      minAmount,
    )} - ${metricFormatters.monetary(maxAmount)}`;
  }

  return metricFormatters.monetary(minAmount);
};
