import { useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import ExpensesEmptyIcon from '@bill/cashflow.assets/expenses-empty';
import { subscribeToActualsUpdateAction } from '@/actions/actuals';
import { REVENUE_BY_PRODUCTS } from '@/cacheKeys';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartLegend from '@/components/Charts/ChartLegend';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import PercentBarWithMetrics from '@/components/Charts/PercentBarWithMetrics';
import EmptyData from '@/components/common/EmptyData';
import { actualsFamily } from '@/constants/actuals';
import { CATEGORY_COLUMN } from '@/constants/employees';
import useChartQuery from '@/hooks/useChartQuery';
import useWsSubscription from '@/hooks/useWsSubscription';
import { getRevenueListData } from '@/services/revenueService';

const CHART_TITLE = 'Revenue by Product';

const reducer = ({ products }) =>
  products.map(({ name, totalMrr }) => {
    const productTotal = totalMrr.reduce(
      (total, { value }) => total + value,
      0,
    );
    return {
      name,
      data: [productTotal],
    };
  });

function RevenueByProductChart() {
  const [chart, setChart] = useState();
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);

  const revenueStreamsList = useSelector(
    ({ revenues }) => revenues.revenueStreamsList,
  );

  const scenarioQueries = useChartQuery(
    REVENUE_BY_PRODUCTS,
    getRevenueListData,
    reducer,
    { dependencies: [revenueStreamsList] },
  );
  const [base] = scenarioQueries;

  useWsSubscription(
    () =>
      dispatch(
        subscribeToActualsUpdateAction(scenarioId, actualsFamily.REVENUE, () =>
          base.refetch(),
        ),
      ),
    [scenarioId],
  );

  const chartData = useMemo(
    () =>
      scenarioQueries.map((query) =>
        query.data.map((series) => ({
          ...series,
          scenario: query.scenario,
        })),
      ),
    [scenarioQueries],
  );
  const hasData = scenarioQueries.some(
    ({ data, isLoading }) => isLoading || data.length,
  );

  return (
    <section className="ChartPanel">
      <ChartHeader title={CHART_TITLE} />
      <ChartToolbar className="Chart_Toolbar-withLegend">
        <ChartLegend chart={chart} data-testid="revenueByProduct-legend" />
        <ChartControls
          chartRef={chart}
          title={CHART_TITLE}
          data-testid="revenueByProduct-export"
          disabled={!hasData}
          csvColumnFilter={CATEGORY_COLUMN}
        />
      </ChartToolbar>
      {hasData ? (
        <PercentBarWithMetrics
          data={chartData}
          data-testid="revenueByProduct"
          onChartCreated={setChart}
        />
      ) : (
        <EmptyData Icon={ExpensesEmptyIcon}>
          Add Expenses for this chart to populate
        </EmptyData>
      )}
    </section>
  );
}

export default RevenueByProductChart;
