// @ts-check
import { Alert } from 'react-bootstrap';

/**
 * Render error messages using bootstrap alert
 *
 * @type {(
 *   props: {
 *     success?: boolean;
 *     error: string | import('@/types/services/backend').ErrorDto;
 *   } & import('react-bootstrap').AlertProps,
 * ) => React.ReactElement}
 */

const ErrorMessages = ({ success, error, ...props }) => {
  if (error && !success) {
    const errorMessages =
      typeof error === 'string'
        ? error
        : error.childErrors.map((e, i) => {
            // eslint-disable-next-line react/no-array-index-key -- no unique identifier available
            return <li key={i}>{e.errorDetails}</li>;
          });
    return (
      <Alert variant="danger" {...props}>
        {errorMessages &&
        typeof error !== 'string' &&
        errorMessages.length > 0 ? (
          <ul>{errorMessages}</ul>
        ) : (
          (typeof error === 'object' && error.errorMessage) || error
        )}
      </Alert>
    );
  }
  return null;
};

export default ErrorMessages;
