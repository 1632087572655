// @ts-check

/**
 * @type {(
 *   revenueStreamId: import('./types').RuleFormState['revenueStreamId'],
 *   revenue: import('@/services/revenueService').RevenueStreamsWithPricingPlan[],
 * ) =>
 *   | import('@/services/revenueService').RevenueStreamsWithPricingPlan
 *   | undefined}
 */
// eslint-disable-next-line import/prefer-default-export -- predates description requirement
export const getRevenueStream = (revenueStreamId, revenue) =>
  revenue.find(({ streamId }) => streamId === revenueStreamId);
