import { iconTypes } from '@/constants/actuals';
import useIsFullFpnaProduct from '@/hooks/useIsFullFpnaProduct';
import './SpreadsheetLegendContent.scss';

/**
 * Passed as children to SpreadsheetLegend explaining the different icons used
 * in AgGrid MonthlySpreadsheet
 *
 * @example
 *   <SpreadsheetLegend>
 *     <SpreadsheetLegendContent
 *       content={actualsLegendCopy}
 *       entityText="Values"
 *     />
 *   </SpreadsheetLegend>;
 */
export default function SpreadsheetLegendContent({
  content,
  entityText = 'Actuals',
  userEnteredTextOverride = '',
}) {
  const isFullFpnaProduct = useIsFullFpnaProduct();
  return (
    <dl className="SpreadsheetLegend_Icons">
      <dt className="SpreadsheetLegend_IconLabel SpreadsheetLegend_IconLabel-external">
        Externally Created {entityText}
      </dt>
      <dd>{content[iconTypes.EXTERNAL_SOURCE_GENERATED]}</dd>
      {isFullFpnaProduct && (
        <>
          <dt className="SpreadsheetLegend_IconLabel SpreadsheetLegend_IconLabel-system">
            System Created {entityText}
          </dt>

          <dd>{content[iconTypes.SYSTEM_GENERATED]}</dd>
        </>
      )}
      <dt className="SpreadsheetLegend_IconLabel SpreadsheetLegend_IconLabel-user">
        {userEnteredTextOverride || `User Created ${entityText}`}
      </dt>
      <dd>{content[iconTypes.USER_ENTERED]}</dd>
    </dl>
  );
}
