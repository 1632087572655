// @ts-check
import Api, { ApiV2 } from '@/services/Api';

/**
 * @typedef {{
 *   notifications: (
 *     | import('@/components/NotificationCenter/NotificationCenter').ImportantNotification
 *     | import('@/components/NotificationCenter/NotificationCenter').NormalNotification
 *   )[];
 * }} GetNotificationsResponse
 */

/**
 * Get notifications
 *
 * @type {(
 *   scenarioId: number,
 *   daysLimit?: number,
 * ) => Promise<
 *   import('@/types/api').AxiosApiResponse<GetNotificationsResponse>
 * >}
 */
const getNotifications = (scenarioId, daysLimit = 30) => {
  return ApiV2.get(`/notifications`, {
    params: { scenarioId, daysLimit },
  });
};

/**
 * Delete a notification by id
 *
 * @type {(params: {
 *   id: string;
 *   scenarioId: number;
 * }) => Promise<import('@/types/api').AxiosApiResponse<void>>}
 */
export const dismissNotification = ({ id, scenarioId }) => {
  return ApiV2.delete(`/notifications/${id}`, {
    params: { scenarioId },
  });
};

/**
 * Delete all notifications
 *
 * @type {(
 *   scenarioId: number,
 * ) => Promise<import('@/types/api').AxiosApiResponse<void>>}
 */
export const dismissNotifications = (scenarioId) => {
  return ApiV2.delete('/notifications', {
    params: { scenarioId },
  });
};

/**
 * Gets the calculation progress for the given scenario
 *
 * @type {(
 *   scenarioId: number,
 * ) => Promise<
 *   import('axios').AxiosResponse<
 *     import('@/types/api').ApiResponse<
 *       import('@/types/services/backend').CalculationProgressDto
 *     >
 *   >
 * >}
 */
export const getCalculationProgress = (scenarioId) => {
  return Api.get('/calculations', {
    params: { scenarioId },
  });
};

export default getNotifications;
