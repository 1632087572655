// @ts-check
import { useCallback, useEffect, useMemo, useState } from 'react';
import ResetIcon from '@bill/cashflow.assets/reset';
import { useMutation } from '@tanstack/react-query';
import CashGridGuideModal from '@/components/CashInOut/CashGridGuideModal';
import EditForecastMethodModal from '@/components/CashInOut/EditForecastMethodModal';
import LinkCellRenderer from '@/components/CashInOut/LinkCellRenderer';
import TooltipCellRenderer from '@/components/CashInOut/TooltipCellRenderer';
import Checkbox from '@/components/common/Checkbox';
import Modal from '@/components/common/Modal';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import {
  formulaMonthRendererSelector,
  handleAgGridPaste,
} from '@/components/common/MonthlySpreadsheet/helpers';
import useIconClassRules from '@/components/common/MonthlySpreadsheet/useIconClassRules';
import ColumnToggle from '@/components/common/Spreadsheet/ColumnToggle';
import GroupingToggle from '@/components/common/Spreadsheet/GroupingToggle';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetStatusBar from '@/components/common/Spreadsheet/SpreadsheetStatusBar';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import {
  EMPTY_CELL_VALUE,
  READ_ONLY_CELL_WIDTH,
} from '@/components/common/Spreadsheet/constants';
import FormulaEditor from '@/components/common/Spreadsheet/editors/FormulaEditor';
import useRangeSelection from '@/components/common/Spreadsheet/hooks/useRangeSelection';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import useUpdateQueue from '@/components/common/Spreadsheet/useUpdateQueue';
import {
  ACTUAL_ENDING_CASH,
  actualsFamily,
  BEGINNING_CASH,
  iconTypes,
  RESET_ACTUAL_LABEL,
} from '@/constants/actuals';
import {
  ACCOUNT_TITLE,
  CASH_IO_XL_PARAMS,
  DEFAULT_ROWS,
  INFO_ICON_ROWS,
  SPREADSHEET_ID,
  TOTAL_ROWS,
} from '@/constants/cashInOut';
import { MONTHLY } from '@/constants/dateTime';
import { registeredFeatureFlags } from '@/constants/features';
import { units, varianceText } from '@/constants/variables';
import useForecastFormulas from '@/containers/CashInOut/useForecastFormulas';
import useForecastMethods from '@/containers/CashInOut/useForecastMethods';
import { isActualMonth, toTitleCase } from '@/helpers';
import { customSortComparator } from '@/helpers/cashGrid';
import detectCircularRef from '@/helpers/circularReference';
import { isEmptyOrNull } from '@/helpers/validators';
import { zeroFilter } from '@/helpers/zeroFilter';
import useColumnHidden from '@/hooks/useColumnHidden';
import useComparisonScenarioId from '@/hooks/useComparisonScenarioId';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useFeatureFlagsService from '@/hooks/useFeatureFlagsService';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import useScenarioId from '@/hooks/useScenarioId';
import useTypedSelector from '@/hooks/useTypedSelector';
import ExternalDetailsModal from '@/pages/Actuals/ExternalDetailsModal';
import LegendWarning from '@/pages/Actuals/LegendWarning';
import { valueFormatter, valueGetter } from '@/pages/Actuals/helpers';
import { updateForecast } from '@/services/cashInOut';
import CashGridLegend from './CashGridLegend';
import './CashInOutGrid.scss';

const COMBINED_CASH_FLOW_MIN_WIDTH = 250;
const NAME = 'name';
const ACCOUNT_NUMBER = 'accountNumber';
const FORECAST_METHOD = 'forecastMethod';
const EXPAND_ALL = -1;

/**
 * @typedef {{
 *   name: string;
 *   details: import('@/types/services/backend').ExpenseGroupActualDetails[];
 *   family: (typeof import('@/constants/actuals').actualsFamily)['EXPENSE'];
 * }} ExternalDetails
 */

const editorParams = ({ column, data }) => {
  return {
    'data-testid': `${data.id}-${column.colId}`,
    'unit': units.CURRENCY,
  };
};

/**
 * @type {(props: {
 *   data: import('@/types/services/backend').CashInOutGrid;
 * }) => boolean}
 */
const passesZeroFilter = ({ data: monthlyData }) =>
  DEFAULT_ROWS.has(monthlyData.id) || zeroFilter(monthlyData);

/**
 * @typedef {{
 *   gridApi: React.MutableRefObject<
 *     | null
 *     | import('ag-grid-react').AgGridReact<
 *         import('@/types/services/backend').CashInOutGrid[]
 *       >
 *   >;
 *   isInteractive?: boolean;
 *   hideZeroRows?: boolean;
 *   showVarianceAmount: boolean;
 *   showVariancePercentage: boolean;
 *   setShowVarianceAmount?: React.Dispatch<React.SetStateAction<boolean>>;
 *   setShowVariancePercentage?: React.Dispatch<React.SetStateAction<boolean>>;
 *   rowData:
 *     | import('@/types/services/backend').CashInOutGrid[]
 *     | import('@/hooks/useReportData').ReportsData[];
 *   isLoading?: boolean;
 *   shouldRenderAccountNumber: boolean;
 * }} CashInOutGridProps
 */

/** @type {React.FC<CashInOutGridProps>} */

const CashInOutGrid = ({
  gridApi,
  isInteractive = true,
  hideZeroRows = false,
  showVarianceAmount,
  showVariancePercentage,
  setShowVarianceAmount = () => {},
  setShowVariancePercentage = () => {},
  rowData,
  isLoading = false,
  shouldRenderAccountNumber,
}) => {
  const scenarioId = useScenarioId();
  const comparisonId = useComparisonScenarioId();
  const isColumnHidden = useColumnHidden(SPREADSHEET_ID);
  const hasWritePermission = useNonDashboardWritePermission();
  const timePeriod = useTypedSelector(({ shared }) => shared.timePeriod);
  const endDate = useTypedSelector(({ shared }) => shared.endDate);
  const startDate = useTypedSelector(({ shared }) => shared.startDate);

  const iconClassRules = useIconClassRules(timePeriod);

  /**
   * @type {ReturnType<
   *   typeof useState<
   *     import('@/types/cashInOutGrid').CashInOutGridColumns | null
   *   >
   * >}
   */
  const [forecastModalData, setForecastModalData] = useState(null);
  const [selectedCells, setSelectedCells] = useState([]);
  const [cellCount, setCellCount] = useState(0);
  const [cellSum, setCellSum] = useState(0);
  const [cellUnit, setCellUnit] = useState('');

  const [isHelperModalOpen, setIsHelperModalOpen] = useState(false);

  const [forecastMethods, onSetForecastMethod] = useForecastMethods(rowData);
  const [formulas, onSetForecastFormula] = useForecastFormulas(rowData);

  const handleRangeSelection = useRangeSelection(
    setSelectedCells,
    setCellCount,
    setCellUnit,
    setCellSum,
  );

  /** @type {ReturnType<typeof useState<ExternalDetails | null>>} */
  const [externalDetails, setExternalDetails] = useState(null);

  const hasComparison = comparisonId > 0 && comparisonId !== scenarioId;

  const isShowPercentVarianceEnabled = useFeatureFlags(
    registeredFeatureFlags.SHOW_VARIANCE_PERCENT,
  );
  const { flags } = useFeatureFlagsService();
  const isGroupViewToggleEnabled = useMemo(() => {
    return flags['cash-grid-grouped-view'];
  }, [flags]);

  const { mutate: updateForecastMutation } = useMutation({
    mutationFn: updateForecast,
    onSuccess: () => {
      const { api } = gridApi.current;
      const { context } = gridApi.current;
      const { loadingCells } = context;
      const columns = [...new Set(Object.values(loadingCells).flat())];
      const rowNodes = Object.keys(loadingCells)
        .map((rowId) => api.getRowNode(rowId))
        .filter(Boolean);
      context.loadingCells = {};
      api.refreshCells({ columns, rowNodes });
    },
  });
  /**
   * @type {(
   *   monthValue: import('@/types/services/backend').CashInOutGridItem,
   *   title: string,
   * ) => void}
   */
  const handleClickTooltip = useCallback((monthValue, title) => {
    setExternalDetails({
      name: title,
      details: monthValue.details,
      family: actualsFamily.EXPENSE,
    });
  }, []);

  const handleModalClose = useCallback(() => {
    setExternalDetails(null);
  }, []);

  const columnDefs = useMemo(() => {
    const forecastMethodInitialHide = isColumnHidden(FORECAST_METHOD, {
      isHiddenByDefault: false,
    });
    /**
     * @type {import('ag-grid-community').ColDef<
     *   import('@/types/cashInOutGrid').CashInOutGridColumns
     * >}
     */
    const accountColumn = {
      field: ACCOUNT_NUMBER,
      headerName: 'Account #',
      editable: false,
      initialHide: isColumnHidden(ACCOUNT_NUMBER, {
        isHiddenByDefault: !shouldRenderAccountNumber,
      }),
      sortable: true,
      valueFormatter: (params) => {
        return params.value ?? EMPTY_CELL_VALUE;
      },
      cellClass: (params) => {
        const classes = [
          'Spreadsheet_Cell',
          'CashInOutSpreadsheetSection_Cell',
        ];
        let { parent } = params.node;
        let indent = 0;
        while (parent?.parent) {
          indent += 1;
          parent = parent.parent;
        }
        if (indent > 0) classes.push(`Spreadsheet_Cell-indent${indent}`);
        return classes;
      },
      comparator: customSortComparator,
    };
    /**
     * @type {import('ag-grid-community').ColDef<
     *   import('@/types/cashInOutGrid').CashInOutGridColumns
     * >}
     */
    const forecastMethodColumn = {
      headerName: 'Forecast Method',
      field: FORECAST_METHOD,
      hide: forecastMethodInitialHide || comparisonId > 0,
      sortable: true,
      editable: false,
      initialHide: forecastMethodInitialHide,
      headerComponent: HeaderRenderer,
      headerComponentParams: {
        tooltip: 'Double-click into a cell to change the forecasting method',
      },
      comparator: customSortComparator,
      cellClass:
        'Spreadsheet_Cell Spreadsheet_Cell-label CashInOutSpreadsheetSection_Cell',
      cellStyle: (params) => {
        if (!params?.data?.isParentRow) {
          return {
            cursor: 'pointer',
          };
        }
        return {};
      },
      /**
       * @type {(
       *   event: import('ag-grid-community').CellDoubleClickedEvent<
       *     import('@/types/cashInOutGrid').CashInOutGridColumns
       *   >,
       * ) => void}
       */
      onCellDoubleClicked: (event) => {
        if (isInteractive && !event.data?.isParentRow) {
          setForecastModalData({
            ...event.data,
          });
        }
      },
      valueGetter: ({ data }) => {
        const forecastMethod = forecastMethods[data.id];
        if (forecastMethod) {
          return forecastMethod;
        }
        return data.forecastMethod;
      },
      /**
       * @type {import('ag-grid-community').ValueFormatterFunc<
       *   import('@/types/cashInOutGrid').CashInOutGridColumns
       * >}
       */
      valueFormatter: (params) => {
        if (params?.data?.isParentRow) return EMPTY_CELL_VALUE;
        return /** @type {string} */ (params.value);
      },
    };
    /**
     * @type {import('ag-grid-community').ColDef<
     *   import('@/types/cashInOutGrid').CashInOutGridColumns
     * >[]}
     */
    const cols = [
      {
        field: NAME,
        headerName: 'Name Of Account',
        sortable: true,
        editable: false,
        initialHide: isColumnHidden(NAME, {
          isHiddenByDefault: false,
        }),
        cellClass:
          'Spreadsheet_Cell Spreadsheet_Cell-label CashInOutSpreadsheetSection_Cell',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          suppressCount: true,
          innerRendererSelector: ({ data }) =>
            isInteractive && INFO_ICON_ROWS.has(data?.id)
              ? {
                  component: LinkCellRenderer,
                }
              : null,
        },

        headerComponent: HeaderRenderer,
        headerComponentParams: {
          enableExpandAll: isInteractive,
        },
        showRowGroup: true,
        minWidth: isInteractive
          ? COMBINED_CASH_FLOW_MIN_WIDTH
          : READ_ONLY_CELL_WIDTH,
        comparator: customSortComparator,
      },
    ];

    if (isInteractive) {
      cols.unshift(accountColumn);
      cols.push(forecastMethodColumn);
    }
    return cols;
  }, [
    isColumnHidden,
    shouldRenderAccountNumber,
    comparisonId,
    forecastMethods,
    isInteractive,
  ]);

  /**
   * @type {(
   *   params: import('ag-grid-community').CellKeyDownEvent<
   *     import('@/types/cashInOutGrid').CashInOutGridColumns
   *   >,
   * ) => void}
   */
  const handleCellKeyDown = useCallback(
    (params) => {
      const element = /** @type {Element} */ (params.event.target);
      /** @type {import('ag-grid-community').Column} */
      const col = params.column;
      const keyboardEvent = /** @type {KeyboardEvent} */ (params.event);
      const isForecastMethodColumnCell =
        col.getId() === 'forecastMethod' &&
        params.data.hierarchy.length > 1 &&
        element.textContent !== EMPTY_CELL_VALUE;
      if (isForecastMethodColumnCell && keyboardEvent?.key === 'Enter') {
        setForecastModalData({
          ...params.data,
        });
      }
    },
    [setForecastModalData],
  );

  /**
   * @type {import('ag-grid-community').CellRendererSelectorFunc<
   *   import('@/types/services/backend').CashInOutGrid
   * >}
   */
  const cellRendererSelector = useCallback(
    (params) => {
      const { colDef, column, data } = params;
      const month = column.getParent().getGroupId();
      const monthValue = data.months.find(({ date }) => {
        return date === month;
      });

      if (isEmptyOrNull(monthValue)) {
        return undefined;
      }

      const scenarioEntry = monthValue.value.find((val) => {
        return val.scenarioId === Number(colDef.field);
      });

      if (isEmptyOrNull(scenarioEntry)) {
        return undefined;
      }
      const { type } = scenarioEntry;
      const isCircularRef = detectCircularRef({
        faulted: scenarioEntry.faulted,
        variableValueFaultType: scenarioEntry.variableValueFaultType,
      });
      const shouldUseClickHandler =
        Array.isArray(monthValue?.details) && monthValue.details.length > 0;
      const tooltipClick = () => {
        handleClickTooltip(
          monthValue,
          toTitleCase(data.name.replace(/_/g, ' ')),
        );
      };

      if (!isCircularRef && type === iconTypes.USER_ENTERED) {
        return formulaMonthRendererSelector(params);
      }

      if (!isCircularRef && !isActualMonth(month)) {
        return undefined;
      }

      if (!isCircularRef && type === iconTypes.SYSTEM_GENERATED) {
        return undefined;
      }

      return {
        component: TooltipCellRenderer,
        params: {
          iconType: isCircularRef ? iconTypes.CIRCULAR_REFERENCE : type,
          onClick: shouldUseClickHandler ? tooltipClick : undefined,
        },
      };
    },
    [handleClickTooltip],
  );

  /**
   * @type {import('ag-grid-community').EditableCallback<
   *   import('@/types/cashInOutGrid').CashInOutGridColumns
   * >}
   */
  const isEditable = useCallback(
    ({ colDef, column, data, node }) => {
      if (!isInteractive || !hasWritePermission || DEFAULT_ROWS.has(data?.id)) {
        return false;
      }

      const month = column.getParent().getGroupId();
      const scenarioEntry = data?.months
        .find(({ date }) => date === month)
        ?.value.find((val) => val.scenarioId === Number(colDef.field));

      if (
        !scenarioEntry ||
        scenarioEntry.value === null ||
        data.name.toLowerCase() === ACCOUNT_TITLE.NET_CASHFLOW
      )
        return false;
      return (
        timePeriod === MONTHLY &&
        !node?.allChildrenCount &&
        (isActualMonth(month) ||
          data.family === actualsFamily.EXPENSE ||
          data.family === actualsFamily.REVENUE)
      );
    },
    [timePeriod, hasWritePermission, isInteractive],
  );

  const addValueToQueue = useCallback(
    (queue, editRowData) => {
      const { column, data, newValue } = editRowData;
      const { displayFormula, fillRight } = newValue;

      const colScenarioId = Number(column.getColDef().field);
      const isCompareScenarioId = comparisonId === colScenarioId;

      const payload = {
        month: column.getParent().getGroupId(),
        scenarioId: colScenarioId,
        displayFormula,
        fillRight: fillRight ?? false,
        cashAccountId: isCompareScenarioId ? data.comparisonId : data.id,
      };

      return [...queue, payload];
    },
    [comparisonId],
  );

  const updateBulkActuals = useCallback(
    async (queue) => {
      const scenarios =
        comparisonId > 0 ? [scenarioId, comparisonId] : [scenarioId];
      await updateForecastMutation({ scenarioId: scenarios, forecast: queue });
    },
    [updateForecastMutation, scenarioId, comparisonId],
  );

  const handleMonthValueChange = useUpdateQueue(
    addValueToQueue,
    updateBulkActuals,
  );

  const rendererParams = useCallback(
    (params) => {
      return {
        'data-testid': `${params.data.name}-${params.colDef.colId}`,
        'onFillRightClick': () => {
          const paramsCopy = {
            ...params,
            newValue: {
              ...params.value,
              fillRight: true,
            },
          };
          const payload = addValueToQueue([], paramsCopy);
          updateBulkActuals(payload).catch((error) => {
            console.error(error);
          });
        },
      };
    },
    [updateBulkActuals, addValueToQueue],
  );

  let Legend = CashGridLegend;
  if (timePeriod !== MONTHLY) {
    Legend = LegendWarning;
  }

  const handleResetActuals = useCallback(() => {
    selectedCells.forEach((cell) => {
      cell.node.setDataValue(cell.colDef.colId, {
        displayFormula: null,
        value: null,
      });
    });
    setSelectedCells([]);
    setCellCount(0);
  }, [selectedCells]);

  useEffect(() => {
    setCellCount(0);
    setSelectedCells([]);
  }, [scenarioId, startDate, endDate, timePeriod]);

  return (
    <>
      {isInteractive && (
        <SpreadsheetToolbar
          Legend={Legend}
          onLegendClick={() => setIsHelperModalOpen(true)}
        >
          {isGroupViewToggleEnabled ? (
            <GroupingToggle
              spreadsheetId={SPREADSHEET_ID}
              label="By category"
            />
          ) : null}
          <div
            className="SpreadsheetToolbar_ControlGroup"
            data-testid="cash-grid-toolbar-control-group"
          >
            Options:
            {!isLoading && (
              <ColumnToggle ref={gridApi} spreadsheetId={SPREADSHEET_ID} />
            )}
            <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
          </div>
          {hasComparison && (
            <>
              <Checkbox
                id="cash-grid-variance-toggle"
                checked={showVarianceAmount}
                className="SpreadsheetToolbar_ControlGroup"
                onChange={() =>
                  setShowVarianceAmount((prevState) => !prevState)
                }
              >
                {varianceText.SHOW_VARIANCE_AMOUNT}
              </Checkbox>
              {isShowPercentVarianceEnabled && (
                <Checkbox
                  id="cash-grid-variance-toggle"
                  checked={showVariancePercentage}
                  className="SpreadsheetToolbar_ControlGroup"
                  onChange={() =>
                    setShowVariancePercentage((prevState) => !prevState)
                  }
                >
                  {varianceText.SHOW_VARIANCE_PERCENTAGE}
                </Checkbox>
              )}
            </>
          )}
        </SpreadsheetToolbar>
      )}
      <MonthlySpreadsheet
        ref={gridApi}
        isInteractive={isInteractive}
        onCellKeyDown={handleCellKeyDown}
        enableComparison
        treeData
        showForecastIndicator
        groupDefaultExpanded={EXPAND_ALL}
        editable={isEditable}
        rendererSelector={cellRendererSelector}
        cellClassRules={iconClassRules}
        showVarianceAmount={showVarianceAmount}
        showVariancePercentage={showVariancePercentage}
        data-testid={SPREADSHEET_ID}
        columnDefs={columnDefs}
        loading={isLoading}
        data={rowData}
        passesZeroFilter={passesZeroFilter}
        editor={FormulaEditor}
        comparator={customSortComparator}
        editorParams={editorParams}
        valueFormatter={valueFormatter}
        valueGetter={valueGetter}
        onRangeSelectionChanged={handleRangeSelection}
        onMonthValueChange={handleMonthValueChange}
        rendererParams={rendererParams}
        groupDisplayType="groupRows"
        getDataPath={(data) => {
          return data.hierarchy;
        }}
        excelExportParams={CASH_IO_XL_PARAMS}
        rowClassRules={{
          'Spreadsheet_Row-total': ({ data }) => {
            return TOTAL_ROWS.has(data?.name);
          },
          'CashInOutRowCellNonEditable': ({ data }) => {
            return (
              data?.name === BEGINNING_CASH || data?.name === ACTUAL_ENDING_CASH
            );
          },
        }}
        cellClass="Spreadsheet_Cell Spreadsheet_Cell-numeric CashInOutSpreadsheetSection_Cell"
        processCellFromClipboard={handleAgGridPaste}
        hideZeroRowsByDefault={hideZeroRows}
      />
      {externalDetails && (
        <Modal
          open={!!externalDetails}
          className="Modal-flex"
          onClose={handleModalClose}
          data-testid="fpa-expense-or-payroll-details"
        >
          <ExternalDetailsModal
            entry={externalDetails}
            onClose={handleModalClose}
          />
        </Modal>
      )}
      {isInteractive && cellCount > 0 && (
        <SpreadsheetStatusBar
          cellCount={cellCount}
          cellSum={cellSum}
          cellUnit={cellUnit}
        >
          {!!selectedCells.length && hasWritePermission && (
            <button
              type="button"
              onClick={handleResetActuals}
              className="ResetContainer"
            >
              <ResetIcon className="ResetIcon" />
              {RESET_ACTUAL_LABEL}
            </button>
          )}
        </SpreadsheetStatusBar>
      )}
      <CashGridGuideModal
        data-testid="cash-grid-guide-modal"
        open={isHelperModalOpen}
        onClose={() => setIsHelperModalOpen(false)}
      />
      {forecastModalData && (
        <EditForecastMethodModal
          open={!!forecastModalData}
          scenarioId={scenarioId}
          gridData={forecastModalData}
          onSetForecastMethod={onSetForecastMethod}
          onSetForecastFormula={onSetForecastFormula}
          forecastMethods={forecastMethods}
          customFormulas={formulas}
          onClose={() => {
            setForecastModalData(null);
          }}
        />
      )}
    </>
  );
};

export default CashInOutGrid;
