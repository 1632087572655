import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import './FormRadio.scss';

/**
 * Creates a standard radio button input
 *
 * @example
 *   <FormRadio
 *    onChange={(event) => setBar(event.target.value)}
 *    label="Foo"
 *    checked=true
 *    name="foo"
 *    id="foo"
 *    value="foo"
 *    labelClass="foo"
 *    disabled=true
 *   />
 */
function FormRadio({
  onChange,
  label,
  checked,
  name,
  id,
  value,
  labelClass,
  disabled,
  className,
  'data-testid': dataTestId,
}) {
  const testId = dataTestId ?? id;
  return (
    <div className={classNames('FormRadio', className)}>
      <label htmlFor={id} data-testid={testId} className="FormRadio_Label">
        <input
          type="radio"
          id={id}
          name={name}
          checked={checked}
          className="FormRadio_Input"
          onChange={onChange}
          disabled={disabled}
          value={value}
          data-testid={`${testId}-input`}
        />
        <span className="FormRadio_Checkmark" aria-hidden="true" />
        <span className={classNames('FormRadio_LabelText', labelClass)}>
          {label}
        </span>
      </label>
    </div>
  );
}

FormRadio.propTypes = {
  /** A name for radio button and it is mandatory */
  'name': PropTypes.string.isRequired,
  /** The ID of the input element, corresponding to the label's htmlFor attribute */
  'id': PropTypes.string.isRequired,
  /** A function that will trigger on value change */
  'onChange': PropTypes.func,
  /** The label for the radio button */
  'label': PropTypes.string,
  /** The value of the radio is true or false */
  'checked': PropTypes.bool.isRequired,
  /** The value of the radio input */
  'value': PropTypes.string.isRequired,
  /** The class for label of radio button */
  'labelClass': PropTypes.string,
  /** A check to enable or disable the radio button */
  'disabled': PropTypes.bool,
  /** Class(es) to apply to the element */
  'className': PropTypes.string,
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string,
};

export default FormRadio;
