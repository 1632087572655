// @ts-check
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { connect, useDispatch } from 'react-redux';
import {
  createStripeSessionAction,
  getPaymentPlansAction,
  updatePaymentPlanAction,
  selectCurrentPaymentPlanAction,
  subscribeToBillingSubscriptionAction,
} from '@/actions/settings';
import { isInternationalAddress } from '@/components/Settings/Address/helpers';
import CardWrapper from '@/components/Settings/CardWrapper';
import useCompanyDetailsQuery from '@/components/Settings/useCompanyDetailsQuery';
import Button from '@/components/common/Button';
import Select from '@/components/common/Select';
import { stripSessionIdUrlParam } from '@/helpers';
import redirectToStripe from '@/helpers/stripe';
import useWsSubscription from '@/hooks/useWsSubscription';
import './Billing.scss';

/** @type {(props: BillingProps) => React.ReactElement} */
const Billing = ({
  createStripeSession,
  success,
  setShowReferFriendModal,
  error = '',
  paymentPlan: {
    paymentPlans,
    selectedPaymentPlanId,
    currentSelectedPaymentPlanId,
    subscriptionId: subId,
  },
  updatePaymentPlan,
  selectCurrentPaymentPlan,
  getPaymentPlans,
  companyId,
}) => {
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(error);
  const [subscriptionId, setSubscriptionId] = useState('');

  const { data: companyMetadata } = useCompanyDetailsQuery(companyId);

  const addressDoesNotExist = !companyMetadata?.address;
  const industryDoesNotExist = !companyMetadata?.industryId;
  const isInternational =
    !addressDoesNotExist && isInternationalAddress(companyMetadata?.address);

  const isSubscriptionDisabled =
    (addressDoesNotExist && !selectedPaymentPlanId) ||
    (isInternational && !selectedPaymentPlanId) ||
    industryDoesNotExist;
  let subscribeDisabledText = '';
  if ((addressDoesNotExist && !selectedPaymentPlanId) || industryDoesNotExist) {
    subscribeDisabledText =
      'Please complete your company information to subscribe';
  } else if (isInternational && !selectedPaymentPlanId) {
    subscribeDisabledText =
      'Subscriptions are currently available for US customers only';
  }

  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();

  useWsSubscription(
    () =>
      dispatch(
        subscribeToBillingSubscriptionAction(
          companyId,
          ({ subscriptionId: sId }) => {
            setSubscriptionId(sId);
          },
        ),
      ),
    [companyId],
  );

  useEffect(() => {
    getPaymentPlans();
  }, [getPaymentPlans]);

  useEffect(() => {
    setSubscriptionId(subId);
  }, [subId]);

  useEffect(() => {
    stripSessionIdUrlParam(() => {
      setShowReferFriendModal(true);
      setShowSuccess(true);
    });
  }, [setShowReferFriendModal]);

  useEffect(() => {
    selectCurrentPaymentPlan(selectedPaymentPlanId);
  }, [selectCurrentPaymentPlan, selectedPaymentPlanId]);

  useEffect(() => {
    setLoading(false);
    if (success) {
      if (success.url) {
        window.location = success.url;
      } else if (success.sessionId) {
        const stripeRedirectError = redirectToStripe(success.sessionId);

        setErrorMessage(stripeRedirectError.message);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
      }
    }

    if (error) {
      setErrorMessage(error);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setLoading(false);
    }
  }, [success, error]);

  const handleSubscribeNow = async () => {
    setLoading(true);
    setErrorMessage('');
    updatePaymentPlan({
      selectedPaymentPlanId:
        currentSelectedPaymentPlanId ?? selectedPaymentPlanId,
    });
  };

  return (
    <CardWrapper>
      {showSuccess && (
        <Alert variant="success">Your Payment was successful</Alert>
      )}
      {errorMessage && <Alert variant="success">{errorMessage}</Alert>}
      <header className="Billing_Header">
        <h4 className="Header_Text">Billing</h4>
        {!!subscriptionId && (
          <Button id="cancel-subscription" className="Button-primaryLink">
            Cancel Subscription
          </Button>
        )}
      </header>
      <div className="PaymentPlan_Container">
        <div className="PaymentPlan_SelectContainer">
          <label htmlFor="paymentPlanId" className="Label">
            Current Annual Revenue
          </label>
          <Select
            id="paymentPlanId"
            name="paymentPlanId"
            value={currentSelectedPaymentPlanId ?? selectedPaymentPlanId}
            onChange={({ target: { value } }) => {
              const valueAsNumber = Number(value);
              selectCurrentPaymentPlan(
                Number.isFinite(valueAsNumber) ? valueAsNumber : null,
              );
            }}
            disabled={!!subscriptionId || isSubscriptionDisabled}
          >
            <option key="" value="">
              Select your revenue level
            </option>
            {paymentPlans
              .filter(
                (plan) => plan.isActive || plan.id === selectedPaymentPlanId,
              )
              .map((plan) => (
                <option key={plan.id} value={plan.id} disabled={!plan.isActive}>
                  {plan.displayRange}
                </option>
              ))}
          </Select>
        </div>

        {subscriptionId ? (
          <Button
            data-testid="Billing_EditDetailButton"
            onClick={() => {
              setLoading(true);
              createStripeSession();
            }}
            loading={loading}
            className="Button Billing_Button"
            disabled={isSubscriptionDisabled}
          >
            Edit Details
          </Button>
        ) : (
          <div>
            <Button
              data-testid="Billing_Button"
              onClick={handleSubscribeNow}
              className="Button Billing_Button"
              loading={loading}
              disabled={!currentSelectedPaymentPlanId || isSubscriptionDisabled}
            >
              Subscribe Now
            </Button>
          </div>
        )}
      </div>
      {subscribeDisabledText && (
        <div className="PaymentPlan_DisabledText">{subscribeDisabledText}</div>
      )}
    </CardWrapper>
  );
};

const mapStateToProps = ({
  settings: { stripeSessionSuccess, stripeSessionError, paymentPlan },
  companies: { selectedCompanyId },
}) => ({
  success: stripeSessionSuccess,
  error: stripeSessionError,
  paymentPlan,
  companyId: selectedCompanyId,
});

export default connect(mapStateToProps, {
  createStripeSession: createStripeSessionAction,
  updatePaymentPlan: updatePaymentPlanAction,
  selectCurrentPaymentPlan: selectCurrentPaymentPlanAction,
  getPaymentPlans: getPaymentPlansAction,
})(Billing);
