import {
  CODAT_QUICKBOOKS,
  CODAT_WAVE,
  CODAT_XERO,
  CODAT_ZOHO,
  CODAT_NETSUITE,
  FINCH_ADP,
  FINCH_GUSTO,
  FINCH_JUSTWORKS,
  FINCH_RIPPLING,
  FINCH_BAMBOOHR,
  FINCH_ZENEFITS,
  REVENUE_GSHEETS,
  REVENUE_GSHEETS_PAYMENT_PROCESSOR,
  REVENUE_STRIPE,
  BIPAAS_QUICKBOOKS,
} from '@/constants/integrations';
import { classNames } from '@/helpers';
import { ReactComponent as ADPLogo } from '@/assets/images/adp_logo.svg';
import { ReactComponent as BambooLogo } from '@/assets/images/bamboo_logo.svg';
import { ReactComponent as GsheetsIcon } from '@/assets/images/gsheets_logo.svg';
import { ReactComponent as GustoLogo } from '@/assets/images/gusto_logo.svg';
import { ReactComponent as JustWorksLogo } from '@/assets/images/justworks_logo.svg';
import { ReactComponent as NetsuiteLogo } from '@/assets/images/netsuite.svg';
import { ReactComponent as QuickbooksIcon } from '@/assets/images/quickbooks-no-text.svg';
import { ReactComponent as RipplingLogo } from '@/assets/images/rippling-rs.svg';
import { ReactComponent as StripeLogo } from '@/assets/images/stripe_logo.svg';
import { ReactComponent as WaveIcon } from '@/assets/images/wave-logo-stacked.svg';
import { ReactComponent as XeroIcon } from '@/assets/images/xero.svg';
import { ReactComponent as ZenefitsLogo } from '@/assets/images/zenefits_logo.svg';
import { ReactComponent as ZohoIcon } from '@/assets/images/zoho-books.svg';

const IntegrationButtonContent = ({ integration, hasLabel = false }) => {
  switch (integration) {
    case CODAT_QUICKBOOKS:
      return <QuickbooksIcon className="SettingsIntegrationIcon" />;
    case BIPAAS_QUICKBOOKS:
      return <QuickbooksIcon className="SettingsIntegrationIcon" />;
    case CODAT_WAVE:
      return <WaveIcon className="SettingsIntegrationIcon" />;
    case CODAT_XERO:
      return <XeroIcon className="SettingsIntegrationIcon" />;
    case CODAT_ZOHO:
      return <ZohoIcon className="SettingsIntegrationIcon" />;
    case CODAT_NETSUITE:
      return <NetsuiteLogo className="SettingsIntegrationIcon-wideIcon" />;
    case FINCH_GUSTO:
      return <GustoLogo className="SettingsIntegrationIcon-wideIcon" />;
    case FINCH_ZENEFITS:
      return <ZenefitsLogo className="SettingsIntegrationIcon-wideIcon" />;
    case FINCH_JUSTWORKS:
      return <JustWorksLogo className="SettingsIntegrationIcon-wideIcon" />;
    case FINCH_ADP:
      return <ADPLogo className="SettingsIntegrationIcon" />;
    case FINCH_RIPPLING:
      return <RipplingLogo className="SettingsIntegrationIcon" />;
    case FINCH_BAMBOOHR:
      return <BambooLogo className="SettingsIntegrationIcon-wideIcon" />;
    case REVENUE_STRIPE:
      return <StripeLogo className="SettingsIntegrationIcon-wideIcon" />;
    case REVENUE_GSHEETS:
      return (
        <>
          <GsheetsIcon
            className={classNames(
              'SettingsIntegrationIcon',
              hasLabel
                ? 'SettingsIntegrationIcon-smallIcon'
                : 'SettingsIntegrationIcon-tallIcon',
            )}
          />
          {hasLabel && (
            <span className="SettingsIntegrationLabel">CRM - Relative</span>
          )}
        </>
      );
    case REVENUE_GSHEETS_PAYMENT_PROCESSOR:
      return (
        <>
          <GsheetsIcon
            className={classNames(
              'SettingsIntegrationIcon',
              hasLabel
                ? 'SettingsIntegrationIcon-smallIcon'
                : 'SettingsIntegrationIcon-tallIcon',
            )}
          />
          {hasLabel && (
            <span className="SettingsIntegrationLabel">Payment Processor</span>
          )}
        </>
      );
    default:
      return null;
  }
};

export default IntegrationButtonContent;
