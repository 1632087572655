import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import PlusIcon from '@bill/cashflow.assets/plus';
import {
  addOrUpdateCollectionTermAction,
  addOrUpdatePaymentTermAction,
} from '@/actions/settings';
import Button from '@/components/common/Button';
import FormField from '@/components/common/FormField';
import InfoTooltip from '@/components/common/InfoTooltip';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';
import MonthSpreadFields from '@/components/common/MonthSpreadFields';
import { ACCOUNTS_PAYABLE } from '@/constants/settings';
import { isEmptyOrNull } from '@/helpers/validators';
import PACTermText from './PACTermText';
import './TheAddEditPACTermModal.scss';

const initialStates = {
  name: '',
  monthPercentages: [100],
};

const TheAddEditPACTermModal = ({
  onClose,
  selectedTerm,
  activeView,
  companyId,
  scenarioId,
  addOrUpdateCollectionTerm,
  addOrUpdatePaymentTerm,
}) => {
  const isEditMode = !!selectedTerm?.id;
  const [formValues, setFormValues] = useState(selectedTerm || initialStates);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isPercentageBoxValid, setIsPercentageBoxValid] = useState(true);

  const handleChange = (monthPercentages) => {
    const sum = monthPercentages.reduce((acc, curr) => {
      return acc + curr;
    }, 0);
    setIsPercentageBoxValid(!(sum > 100 || sum < 100));
    setFormValues({
      ...formValues,
      monthPercentages: [...monthPercentages],
    });
  };

  const handleBoxAddition = () => {
    const { monthPercentages } = formValues;
    if (monthPercentages.length !== 18) {
      const addition = [...monthPercentages, 0];
      handleChange(addition);
    }
  };

  const handleAddUpdate = async () => {
    setIsLoading(true);
    const { monthPercentages } = formValues;

    const lastNonZeroIndex = monthPercentages.reduce((acc, curr, index) => {
      if (curr !== 0) {
        return index;
      }
      return acc;
    }, null);
    monthPercentages.length =
      lastNonZeroIndex !== null
        ? lastNonZeroIndex + 1
        : monthPercentages.length;

    try {
      const addOrUpdateTerm =
        activeView === ACCOUNTS_PAYABLE
          ? addOrUpdatePaymentTerm
          : addOrUpdateCollectionTerm;
      await addOrUpdateTerm(
        { ...formValues, monthPercentages },
        companyId,
        scenarioId,
      );
      setIsLoading(false);
      onClose();
    } catch (e) {
      setIsLoading(false);
      setErrorMsg(e.response?.data?.error?.errorMessage || e.message);
    }
  };

  return (
    <ModalBase
      id="add-edit-pac-term-modalbase"
      onCancel={onClose}
      header={
        <h2 className="ModalBase_Heading">
          {isEditMode ? 'Edit' : 'Add'}{' '}
          {activeView === ACCOUNTS_PAYABLE ? (
            <PACTermText location="accountsPayable.headerText" />
          ) : (
            <PACTermText location="accountsReceivable.headerText" />
          )}
        </h2>
      }
      footer={
        <>
          <Button
            className="Button-cancelLink"
            data-testid="pac-term-cancel-button"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="pac-term-add-edit-button"
            loading={isLoading}
            disabled={isEmptyOrNull(formValues.name) || !isPercentageBoxValid}
            onClick={handleAddUpdate}
          >
            {isEditMode ? 'Finish Edit' : 'Add'}
          </Button>
        </>
      }
    >
      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
      <div className="Form">
        <div className="Form_Group">
          <label htmlFor="pac-term-name" className="Label">
            {activeView === ACCOUNTS_PAYABLE ? (
              <PACTermText location="accountsPayable.termLabel" />
            ) : (
              <PACTermText location="accountsReceivable.termLabel" />
            )}
          </label>
          <FormField
            id="pac-term-name"
            name="pacTermName"
            value={formValues.name}
            maxLength={50}
            validate={(newName) => !newName && 'Please enter a name'}
            onChange={({ target }) =>
              setFormValues({ ...formValues, name: target.value })
            }
            placeholder="Enter name (e.g. Net 30)"
          />
        </div>

        <div className="Form_Group">
          <label htmlFor="pac-term-fields" className="Label">
            {activeView === ACCOUNTS_PAYABLE ? (
              <PACTermText location="accountsPayable.percentageLabel" />
            ) : (
              <PACTermText location="accountsReceivable.percentageLabel" />
            )}

            <InfoTooltip
              id="pac-term-fields"
              data-testid="monthly-pac-term-data"
            >
              {activeView === ACCOUNTS_PAYABLE ? (
                <PACTermText location="accountsPayable.percentageTooltipMsg" />
              ) : (
                <PACTermText location="accountsReceivable.percentageTooltipMsg" />
              )}
            </InfoTooltip>
          </label>

          <MonthSpreadFields
            id="pac-term-boxes"
            className="PACTerm_Wrapper"
            values={formValues.monthPercentages}
            firstBoxLabel="Month of Invoice"
            isValid={isPercentageBoxValid}
            errorMessage="Sum of percentages must equal 100%"
            onChange={handleChange}
          >
            <PlusIcon
              onClick={handleBoxAddition}
              className="PACTerm_AddFieldBtn"
              aria-hidden="true"
            />
          </MonthSpreadFields>
        </div>
      </div>
    </ModalBase>
  );
};

const mapStateToProps = (state) => ({
  companyId: state.companies.selectedCompanyId,
  scenarioId: state.scenario.scenarioId,
});

export default connect(mapStateToProps, {
  addOrUpdateCollectionTerm: addOrUpdateCollectionTermAction,
  addOrUpdatePaymentTerm: addOrUpdatePaymentTermAction,
})(TheAddEditPACTermModal);
