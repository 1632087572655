import { useEffect } from 'react';
import { hasEndDate } from '@/components/Revenue/helpers';
import { SUBSCRIPTION } from '@/constants/revenueStream';
import { isEndDateBeforeStartDate } from '@/helpers/validators';
import DriverDateRange from './components/DriverDateRange';
import DriverPricingPlanList from './components/DriverPricingPlanList';
import './MonthlyGrowthDriverValues.scss';

const MonthlyGrowthDriverValues = ({
  revenue,
  revenueStream,
  plansAssociated,
  updatePlan,
  handleStreamDataChange,
  setIsStreamValid,
  showPricingPlanInPercentage = false,
}) => {
  const allPlansHaveCustomers = plansAssociated.every(
    (plan) => plan.estimatedValue > 0,
  );

  const { startDate, endDate, noEndDate, revenueType } = revenueStream;

  const pricingPlanListHeading =
    revenueType === SUBSCRIPTION
      ? 'How many paying subscribers do you expect to acquire in that initial month across each pricing plan?'
      : 'How many purchases do you expect to acquire in that initial month within your selected product?';

  useEffect(() => {
    if (!setIsStreamValid) return;
    setIsStreamValid(
      !!startDate &&
        hasEndDate(noEndDate, endDate) &&
        !isEndDateBeforeStartDate(startDate, endDate) &&
        allPlansHaveCustomers,
    );
  }, [startDate, endDate, noEndDate, setIsStreamValid, allPlansHaveCustomers]);

  return (
    <div>
      <DriverDateRange
        revenue={revenue}
        revenueStream={revenueStream}
        handleStreamDataChange={handleStreamDataChange}
      />
      <DriverPricingPlanList
        plansAssociated={plansAssociated}
        revenue={revenue}
        showPricingPlanInPercentage={showPricingPlanInPercentage}
        updatePlan={updatePlan}
        heading={pricingPlanListHeading}
      />
    </div>
  );
};

export default MonthlyGrowthDriverValues;
