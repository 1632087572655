import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { connect, useDispatch } from 'react-redux';
import ExpensesEmptyIcon from '@bill/cashflow.assets/expenses-empty';
import { v4 as uuidv4 } from 'uuid';
import { subscribeToActualsUpdateAction } from '@/actions/actuals';
import { subscribeToEmployeeUpdateAction } from '@/actions/employees';
import {
  getExpensesClassesAction,
  getExpensesListAction,
} from '@/actions/expenses';
import { getPaymentTermsAction } from '@/actions/settings';
import { MONTHLY_EXPENSES } from '@/cacheKeys';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import PageHeader from '@/components/Layouts/PageHeader';
import AddMultipleModal from '@/components/common/AddMultipleModal';
import ButtonAdd from '@/components/common/ButtonAdd';
import { acceptedFileExtensionTypes } from '@/components/common/DocumentUploader/constants';
import DropdownOption from '@/components/common/DropdownOption';
import EmptyData from '@/components/common/EmptyData';
import Permissions from '@/components/common/Permissions';
import { DEPARTMENT_ID } from '@/components/common/Spreadsheet/constants';
import { actualsFamily } from '@/constants/actuals';
import { getInitialState } from '@/constants/expenses';
import { registeredFeatureFlags } from '@/constants/features';
import { EXPENSES_PATH } from '@/constants/pages';
import { actions, subjects } from '@/constants/permissions';
import { debounce } from '@/helpers';
import generateDownload from '@/helpers/fileDownload';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import useChartQuery from '@/hooks/useChartQuery';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useWsSubscription from '@/hooks/useWsSubscription';
import ExpenseDelete from '@/pages/Expenses/ExpenseDelete';
import ExpensesByClassChart from '@/pages/Expenses/ExpensesByClassChart';
import ExpensesList from '@/pages/Expenses/List';
import Organize from '@/pages/Expenses/Organize';
import TheAddExpenseModal from '@/pages/Expenses/TheAddExpenseModal';
import {
  getMonthlyChartData,
  uploadMultipleExpenses,
  getExpenseTemplate,
} from '@/services/expensesService';
import './Expenses.scss';

const { WRITE } = actions;
const { NON_DASHBOARD } = subjects;
const CHART_TITLE = 'Monthly Expenses';
const MAIN_METRIC = 'Total Expenses';
const EXPENSE_TYPE = 'Expenses';
const TEMPLATE_FILE_TYPE = 'xls';
const FILE_TYPES = {
  ...acceptedFileExtensionTypes.XLS,
  ...acceptedFileExtensionTypes.XLSX,
};
const TEMPLATE_DOWNLOAD_CONTAINER = 'template-download-container';

const metrics = [
  {
    name: MAIN_METRIC,
    isMainMetric: true,
    key: 'y',
  },
];

function chartReducer({ monthlyExpenses }) {
  return mapMonthlyData(monthlyExpenses, 'totalExpenses');
}

const Expenses = ({
  scenarioId,
  startDate,
  endDate,
  companyId,
  expensesList,
  getPaymentTerms,
  subscribeToActualsUpdate,
  subscribeToEmployeeUpdate,
}) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();

  useEffect(() => {
    getPaymentTerms(companyId, scenarioId);
  }, [scenarioId, startDate, endDate, companyId, getPaymentTerms]);

  const [showExpensesForm, setShowExpensesForm] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [previewEntries, setPreviewEntries] = useState(null);
  const [showOrganize, setShowOrganize] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [unsavedExpenses, setUnsavedExpenses] = useState([]);
  const [cellFocus, setCellFocus] = useState(null);
  const [showMultipleExpenseModal, setShowMultipleExpenseModal] =
    useState(false);
  const [progressLoaded, setProgressLoaded] = useState(0);
  const [progressTotal, setProgressTotal] = useState(0);
  const expenses = useMemo(
    () => [...expensesList, ...unsavedExpenses],
    [expensesList, unsavedExpenses],
  );

  const isBulkUploadEnabled = useFeatureFlags(
    registeredFeatureFlags.BULK_UPLOAD_EXPENSES,
  );

  const onUploadProgress = ({ loaded, total }) => {
    setProgressLoaded(loaded);
    setProgressTotal(total);
  };

  const addExpense = useCallback(() => {
    const newExpense = getInitialState();
    setUnsavedExpenses((prevState) => {
      const id = uuidv4();
      return [...prevState, { ...newExpense, id, hierarchy: [id] }];
    });
    setCellFocus(DEPARTMENT_ID);
  }, [setUnsavedExpenses, setCellFocus]);

  const removeUnsavedExpenses = (ids = []) => {
    setUnsavedExpenses((prevState) => {
      return prevState.filter((expense) => !ids.includes(expense.id));
    });
  };

  const scenarioQueries = useChartQuery(
    MONTHLY_EXPENSES,
    getMonthlyChartData,
    chartReducer,
    { dependencies: [expensesList] },
  );
  const [baseQuery] = scenarioQueries;

  const handleClose = () => {
    setCurrentRecord(undefined);
    setPreviewEntries(null);
    setShowExpensesForm(false);
  };

  const handleMultipleExpenseFinish = () => {
    dispatch(getExpensesListAction(scenarioId, startDate, endDate));
    dispatch(getExpensesClassesAction(scenarioId));
    setShowMultipleExpenseModal(false);
  };

  const handleMultipleExpensesUpload = ({ file }) => {
    setProgressLoaded(0);
    setProgressTotal(0);
    return uploadMultipleExpenses({
      file,
      onUploadProgress,
      params: { scenarioId },
    });
  };

  const refetchChartData = useMemo(
    () => baseQuery?.refetch && debounce(baseQuery.refetch, 5000),
    [baseQuery?.refetch],
  );

  useWsSubscription(
    () =>
      refetchChartData &&
      subscribeToActualsUpdate(
        scenarioId,
        actualsFamily.EXPENSE,
        refetchChartData,
      ),
    [refetchChartData, scenarioId],
  );

  useWsSubscription(
    () =>
      refetchChartData &&
      subscribeToEmployeeUpdate(scenarioId, refetchChartData),
    [refetchChartData, scenarioId],
  );

  const handleTemplateDownload = async () => {
    try {
      const { data } = await getExpenseTemplate(scenarioId);
      const parentElement = document.getElementById(
        TEMPLATE_DOWNLOAD_CONTAINER,
      );
      generateDownload({
        title: 'MultipleExpensesTemplate',
        documentBlob: new Blob([data]),
        fileType: TEMPLATE_FILE_TYPE,
        parentElement,
      });
    } catch (e) {
      // eslint-disable-next-line no-console -- predates description requirement
      console.error('e', e);
    }
  };

  const chartRef = useRef();
  const hasData = !!expenses?.length;

  return (
    <>
      <PageHeader
        page={EXPENSES_PATH}
        addBtnSlot={[
          <Permissions
            action={WRITE}
            subject={NON_DASHBOARD}
            scenarioPermissionRequired
          >
            <ButtonAdd id="add-expense-button" direction="right">
              <DropdownOption
                id="add-expense-item"
                className="pendo-AddExpense"
                onClick={() => setShowExpensesForm(true)}
              >
                Expense
              </DropdownOption>
              {isBulkUploadEnabled && (
                <DropdownOption
                  id="import-multiple-expense-items"
                  onClick={() => {
                    setShowMultipleExpenseModal(true);
                  }}
                >
                  Import Multiple Expenses
                </DropdownOption>
              )}
            </ButtonAdd>
          </Permissions>,
        ]}
      />
      <div className="PageLayout">
        <section className="ChartPanel">
          <ChartHeader title={CHART_TITLE} />
          <ChartToolbar>
            <ChartControls
              chartRef={chartRef}
              data-testid="monthly-expenses-export"
              title={CHART_TITLE}
              disabled={!hasData}
            />
          </ChartToolbar>
          {hasData ? (
            <DateChart
              id="expense-monthly"
              className="ExpandedView_ChartWrapper"
              data-testid="monthly-expenses-chart"
              loading={scenarioQueries.every(({ isLoading }) => isLoading)}
              ref={chartRef}
              startDate={startDate}
              endDate={endDate}
              tooltip={<ChartTooltip metrics={metrics} />}
            >
              {scenarioQueries.map(
                ({ data, scenario }, idx) =>
                  data && (
                    <DateChart.Series
                      key={scenario.scenarioId}
                      data={data.data}
                      index={idx}
                      metric={MAIN_METRIC}
                      scenario={scenario}
                      isComparison={idx === 1}
                    />
                  ),
              )}
            </DateChart>
          ) : (
            <EmptyData Icon={ExpensesEmptyIcon}>
              Add Expenses for this Chart to Populate
            </EmptyData>
          )}
        </section>
        <ExpensesByClassChart />
        <ExpensesList
          onOrganize={setShowOrganize}
          onEdit={setShowExpensesForm}
          onDelete={setShowDelete}
          setCurrentRecord={setCurrentRecord}
          setPreviewEntries={setPreviewEntries}
          expenses={expenses}
          addExpense={addExpense}
          cellFocus={cellFocus}
          setCellFocus={setCellFocus}
          removeUnsavedExpenses={removeUnsavedExpenses}
        />
      </div>

      {showExpensesForm && (
        <TheAddExpenseModal
          onClose={handleClose}
          currentRecord={currentRecord}
          currentPreviewEntries={previewEntries}
        />
      )}

      {showMultipleExpenseModal && (
        <AddMultipleModal
          onClose={() => {
            setShowMultipleExpenseModal(false);
          }}
          onFinish={handleMultipleExpenseFinish}
          onUpload={handleMultipleExpensesUpload}
          type={EXPENSE_TYPE}
          fileExtensions={FILE_TYPES}
          progressLoaded={progressLoaded}
          progressTotal={progressTotal}
        >
          <>
            <div id={TEMPLATE_DOWNLOAD_CONTAINER} />
            <p>
              Download{' '}
              <button
                className="Button Button-primaryLink Expense_DownloadLink"
                onClick={handleTemplateDownload}
              >
                this spreadsheet
              </button>{' '}
              to enter new expenses or to make changes to existing expenses.
              Once you’re done, save your changes and upload the spreadsheet.
            </p>
          </>
        </AddMultipleModal>
      )}

      <Organize
        show={showOrganize}
        setShow={setShowOrganize}
        expensesList={expensesList}
        data-testid="expenses-organize-modal"
      />
      <ExpenseDelete
        showDelete={showDelete}
        setShowDelete={setShowDelete}
        isExpenseGroupCriteria={currentRecord?.expenseGroupCriteria}
        expenseId={currentRecord?.id}
        setCurrentRecord={setCurrentRecord}
      />
    </>
  );
};

const mapStateToProps = ({ expenses, scenario, shared, companies }) => {
  return {
    expensesList: expenses.expensesList.expenses,
    scenarioId: scenario.scenarioId,
    startDate: shared.startDate,
    endDate: shared.endDate,
    companyId: companies.selectedCompanyId,
  };
};

export default connect(mapStateToProps, {
  getPaymentTerms: getPaymentTermsAction,
  subscribeToActualsUpdate: subscribeToActualsUpdateAction,
  subscribeToEmployeeUpdate: subscribeToEmployeeUpdateAction,
})(Expenses);
