import { useState } from 'react';
import { connect } from 'react-redux';
import { getExpensesListAction } from '@/actions/expenses';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import useSelectedScenarios from '@/hooks/useSelectedScenarios';
import { deleteExpense } from '@/services/expensesService';

const ExpenseDelete = ({
  showDelete,
  isExpenseGroupCriteria,
  expenseId,
  setShowDelete,
  setCurrentRecord,
  getExpensesList,
  startDate,
  endDate,
  isActualsOrigin = false,
  onDelete,
}) => {
  const scenarios = useSelectedScenarios();
  const scenarioIds = scenarios.map(({ scenarioId: id }) => id);
  const [scenarioId] = scenarioIds;

  const [deleteError, setDeleteError] = useState('');

  const handleCancel = () => {
    setShowDelete(null);
    setCurrentRecord(null);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteExpense(scenarioId, expenseId);

      if (response.data.success) {
        if (isActualsOrigin) {
          onDelete?.();
        }
        getExpensesList(scenarioId, startDate, endDate);
      }
    } catch (e) {
      setDeleteError(e.response?.data?.error?.errorMessage || e.message);
    } finally {
      setShowDelete(null);
      setCurrentRecord(null);
    }
  };
  return (
    <>
      {showDelete && (
        <ModalConfirmation
          id="modal-expense-item-delete"
          onCancel={handleCancel}
          onAction={handleDelete}
          title="Delete Expense"
        >
          {isExpenseGroupCriteria
            ? `Deleting this expense will remove it from your expense forecast.
          Historical actuals for this expense will be moved over to your
          chart of accounts unless vendors or items under this expense match
          an existing linked expense. `
            : `Deleting this expense will remove it from your expense forecast
          and your actuals. `}
          Do you want to proceed?
        </ModalConfirmation>
      )}
      {deleteError && (
        <ModalConfirmation
          id="modal-expense-item-cannot-delete"
          onAction={() => setDeleteError('')}
          title="Cannot delete the expense"
          actionBtnTxt="I understand"
        >
          {deleteError}
        </ModalConfirmation>
      )}
    </>
  );
};
const mapStateToProps = ({ shared }) => {
  return {
    startDate: shared.startDate,
    endDate: shared.endDate,
    timePeriod: shared.timePeriod,
  };
};

export default connect(mapStateToProps, {
  getExpensesList: getExpensesListAction,
})(ExpenseDelete);
