// @ts-check
import { m as motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import './LetterIcon.scss';

/**
 * @typedef {{
 *   'color': string;
 *   'className'?: string;
 *   'data-testid': string;
 *   'string': string;
 *   'letter'?: string;
 *   [key: string]: any;
 * }} LetterIconProps
 */

/**
 * Creates a square icon with the given background color and the first letter of
 * the given string.
 *
 * @example
 *   <LetterIcon string="Foo" color="#f00" data-testid="foo" />;
 *
 * @type {(params: LetterIconProps) => React.ReactElement}
 */
function LetterIcon({
  color,
  'data-testid': dataTestId,
  string,
  className,
  ...props
}) {
  return (
    <motion.div
      className={classNames('LetterIcon', className)}
      style={{ backgroundColor: color }}
      data-testid={dataTestId}
      aria-hidden="true"
      {...props}
    >
      {string.charAt(0)}
    </motion.div>
  );
}

LetterIcon.propTypes = {
  /** CSS color value for the icon background */
  'color': PropTypes.string.isRequired,
  /** A unique identifier to locate the element in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** String from which the first letter will populate the icon */
  'string': PropTypes.string.isRequired,
  /** Additional class(es) to apply to the Letter Icon */
  'className': PropTypes.string,
  /** Letter override */
  'letter': PropTypes.string,
};

export default LetterIcon;
