// @ts-check
import Api from './Api';
import getByDateRange from './getByDateRange';

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const getPagedEmployees = ({
  scenarioId,
  startDate,
  endDate,
  unlinkedOnly,
}) => {
  return Api.get('/employee/list', {
    params: { scenarioId, startDate, termDate: endDate, unlinkedOnly },
  });
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const postEmployeeTitle = (employeeTitle, scenarioId) => {
  return Api.post('/employee/title', employeeTitle, { params: { scenarioId } });
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const postEmployeeCustomTitle = (employeeTitle, scenarioId) => {
  return Api.post('/employee/custom/title', employeeTitle, {
    params: { scenarioId },
  });
};

export const getEmployeesByDepartment = getByDateRange(
  '/employee/company/department',
  { useApiV2: false },
);

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const getDepartments = () => {
  return Api.get('/departments');
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const deleteEmployeeTitle = (titleId, scenarioId) => {
  return Api.delete(`/employee/title/${titleId}`, { params: { scenarioId } });
};

/**
 * Delete a list of employee ids
 *
 * @param {Object} data Data object
 * @param {Array} data.employeesIds Array of ids for selected employees to bulk
 *   delete
 * @param {number} scenarioId ID of the scenario for the selected employees
 * @param {number} companyId ID of the company for the selected employees
 * @returns {Promise} API response
 */
export const deleteEmployees = (data, scenarioId, companyId) => {
  return Api.post(`/employee/delete`, data, {
    params: { scenarioId, companyId },
  });
};
/**
 * Gets the titles for all current and future employees in departments within
 * the Sales & Marketing class.
 *
 * @param {number} scenarioId ID of the scenario containing the roles
 * @returns {Promise} API response
 */
export const getCurrentEmployeeSalesRoles = (scenarioId) => {
  return Api.get('/employee/employee-roles', { params: { scenarioId } });
};

/**
 * Call the POST API to add multiple employees record
 *
 * @param {Object} args
 * @param {File} args.file File that is uploaded
 * @param {Object} args.params { scenarioId, companyId, updateFlag: false }
 * @param {(progressEvent: any) => void} args.onUploadProgress Progress of the
 *   uploaded file
 * @returns {Promise} API response
 */
export const uploadMultipleEmployees = ({
  file,
  params = {},
  onUploadProgress,
}) => {
  const formData = new FormData();
  formData.append('file', file);
  const controller = new AbortController();
  const promise = Api.post('/employee/upload', formData, {
    params,
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress,
    signal: controller.signal,
  });
  // @ts-ignore
  promise.cancel = () => controller.abort();
  // @ts-ignore
  promise.signal = controller.signal;
  return promise;
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const getExistingEmployeesCsv = (scenarioId, companyId) => {
  return Api.get('/employee/csv', { params: { scenarioId, companyId } });
};

/**
 * Call the PUT API to update the existing employees record
 *
 * @param {Object} args
 * @param {File} args.file File that is uploaded
 * @param {Object} args.params { scenarioId, companyId, updateFlag: false }
 * @param {(progressEvent: any) => void} args.onUploadProgress Progress of the
 *   uploaded file
 * @param {Function} args.getCancelToken If upload need to be cancelled
 * @returns {Promise} API response
 */
export const editMultipleEmployees = ({
  file,
  params = {},
  onUploadProgress,
  getCancelToken,
}) => {
  const formData = new FormData();
  formData.append('file', file);
  return Api.put('/employee/existing', formData, {
    params,
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress,
    cancelToken: getCancelToken(),
  });
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const getCurrentEmployeeInfo = (id, scenarioId) => {
  return Api.get(`/employee/${id}`, { params: { scenarioId } });
};

/**
 * Get the pending employees from payroll platform
 *
 * @param {number} companyId The ID of the company where integration was
 *   connected
 * @param {number} scenarioId The ID of the scenario where integration was
 *   connected
 * @returns {Promise} API response
 */
export const getPendingEmployees = (companyId, scenarioId) => {
  return Api.get('/employee/pending-records', {
    params: { companyId, scenarioId },
  });
};

/**
 * Call the POST API to match finmark employees with external employees
 *
 * @param {number} companyId The ID of the company with the hiring driver
 * @param {number} scenarioId The ID of the scenario with the hiring driver
 * @param {Object} mappedEmployees API Payload
 * @returns {Promise} API response
 */
export const matchExternalEmployees = (
  companyId,
  scenarioId,
  mappedEmployees,
) => {
  return Api.post('/employee/match', mappedEmployees, {
    params: { companyId, scenarioId },
  });
};

/**
 * Creates or updates one or more employees in the given scenario
 *
 * @type {(
 *   scenarioId: number,
 *   employees: import('@/types/services/backend').EmployeeDto[],
 * ) => Promise<
 *   import('axios').AxiosResponse<
 *     import('@/types/services/backend').RestResponseDtoEmployeeBulkUpsertResponse
 *   >
 * >}
 */
export const updateEmployees = (scenarioId, employees) =>
  Api.post('/employee/list', employees, { params: { scenarioId } });

/**
 * Get all job titles
 *
 * @param {number} scenarioId The id of the scenario
 * @returns {Promise} API response
 */
export const getAllJobTitles = (scenarioId) => {
  return Api.get('/employee/employeeTitles', {
    params: { scenarioId },
  });
};
