import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import InfoTooltip from './InfoTooltip';
import './FormLabel.scss';

/**
 * Renders a label for a form field
 *
 * @example
 *   <FormLabel htmlFor="foo" text="Foo" />
 *   <FormField id="foo" />
 */
function FormLabel({ className, htmlFor, optional, text, tooltip }) {
  return (
    <label htmlFor={htmlFor} className={classNames('Label', className)}>
      {text}
      {optional && <span className="Label_OptionalTag"> (Optional)</span>}
      {tooltip && (
        <InfoTooltip data-testid={`${htmlFor}-tooltip`}>{tooltip}</InfoTooltip>
      )}
    </label>
  );
}

FormLabel.propTypes = {
  /** Additional class(es) to apply to the label */
  className: PropTypes.string,
  /** ID of the input with which the label should be associated */
  htmlFor: PropTypes.string.isRequired,
  /** Whether or not the label describes an optional field */
  optional: PropTypes.bool,
  /** Text content for the label */
  text: PropTypes.string.isRequired,
  /**
   * Additional information about the field, to be placed in a tooltip next to
   * the label
   */
  tooltip: PropTypes.string,
};

export default FormLabel;
