import { useState } from 'react';
import { AnimatePresence, m as motion } from 'framer-motion';
import { units } from '@/constants/variables';
import { classNames } from '@/helpers';
import './UnitSelector.scss';

const ANIM_CLOSED = { x: '-100%' };

/**
 * Options to select unit type
 *
 * @example
 *   <UnitSelector
 *     data-testid="id-unitSelector"
 *     unit={unit}
 *     onChange={(newUnit) => {
 *       setUnit(newUnit);
 *       input.current.focus();
 *     }}
 *     unitMap={unitMap}
 *   />;
 */
export default function UnitSelector({
  'data-testid': dataTestId,
  unit,
  onChange,
  unitMap,
}) {
  const [isExpanded, setExpanded] = useState(false);

  const { UnitSymbol, label } = unitMap[unit];

  return (
    <div className="UnitSelector">
      <button
        type="button"
        className="UnitSelector_Trigger"
        aria-expanded={isExpanded}
        aria-haspopup="listbox"
        aria-label="Variable Unit"
        data-testid={dataTestId}
        onClick={() => setExpanded(!isExpanded)}
      >
        <UnitSymbol
          className={classNames(
            'UnitSelector_Icon',
            label === units.CURRENCY && 'UnitSelector_Icon-currency',
          )}
          aria-hidden="true"
        />
      </button>
      <AnimatePresence>
        {isExpanded && (
          <motion.ul
            animate={{ x: 0 }}
            exit={ANIM_CLOSED}
            initial={ANIM_CLOSED}
            transition={{
              type: 'spring',
              mass: 0.3,
              stiffness: 150,
            }}
            className="UnitSelector_List"
            role="listbox"
            tabIndex="-1"
          >
            {Object.entries(unitMap).map(
              ([
                unitKey,
                { label: optionLabel, UnitSymbol: TypeOptionSymbol },
              ]) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events -- predates description requirement
                <li
                  key={unitKey}
                  className="UnitSelector_Option"
                  role="option"
                  aria-label={optionLabel}
                  aria-selected={unit === unitKey}
                  data-testid={`${dataTestId}-${unitKey}`}
                  onClick={() => {
                    setExpanded(false);
                    onChange(unitKey);
                  }}
                >
                  <TypeOptionSymbol
                    className={classNames(
                      'UnitSelector_Icon',
                      optionLabel === units.CURRENCY &&
                        'UnitSelector_Icon-currency',
                    )}
                    aria-hidden="true"
                  />
                </li>
              ),
            )}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
}
