// @ts-check
import { useCallback } from 'react';
import useTypedSelector from '@/hooks/useTypedSelector';

/**
 * @typedef {{
 *   subject: import('@/constants/permissions').PermissionSubject;
 *   action: import('@/constants/permissions').PermissionAction;
 *   scenarioPermissionRequired?: boolean;
 * }} PermissionParam
 */

/**
 * useIsPermitted is a hook which returns a callback.
 *
 * @type {() => (params: PermissionParam) => boolean}
 */
const useIsPermitted = () => {
  const permissions = useTypedSelector((state) => state.auth.permissions);

  const scenarioPermissions = useTypedSelector(
    (state) => state.auth.scenarioPermissions,
  );
  const scenarioId = useTypedSelector((state) => state.scenario.scenarioId);

  return useCallback(
    ({ action, subject, scenarioPermissionRequired }) => {
      if (scenarioPermissionRequired) {
        return Boolean(
          scenarioPermissions?.[scenarioId]?.permissions.find(
            (permission) =>
              permission.action === action && permission.subject === subject,
          ),
        );
      }

      return Boolean(
        permissions.find(
          (permission) =>
            permission.action === action && permission.subject === subject,
        ),
      );
    },
    [permissions, scenarioPermissions, scenarioId],
  );
};

export default useIsPermitted;
