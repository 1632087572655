import { useMemo } from 'react';
import BackArrowIcon from '@bill/cashflow.assets/back-arrow';
import PropTypes from 'prop-types';
import Link from '@/components/common/Link';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import { registeredFeatureFlags } from '@/constants/features';
import PAGES from '@/constants/pages';
import { classNames } from '@/helpers';
import isBillDomain from '@/helpers/isBillDomain';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { ReactComponent as BillBackIcon } from '@/assets/icons/svg/bill_back_arrow.svg';
import './ChartHeader.scss';

/**
 * The header for the primary chart(s) on a page.
 *
 * @example
 *   <ChartHeader title="Total Foo" />;
 */
function ChartHeader({
  back,
  children,
  title,
  className,
  Icon,
  withTooltip = false,
}) {
  const { titleText } = PAGES[back] ?? {};
  const isOneColorThemeEnabled = useFeatureFlags(
    registeredFeatureFlags.ONE_COLOR_CSS_THEME,
  );

  const isRemoteFeaturesEnabled = useMemo(
    () => isBillDomain() || isOneColorThemeEnabled,
    [isOneColorThemeEnabled],
  );

  return (
    <header
      className={classNames(
        'ChartHeader',
        className,
        !isRemoteFeaturesEnabled && 'ChartHeader-finmark',
      )}
    >
      {back && (
        <Link
          to={back}
          className={classNames(
            'ChartHeader_BackBtn',
            !isRemoteFeaturesEnabled && 'ChartHeader_BackBtn-finmark',
          )}
          aria-label={`Back to ${titleText}`}
          data-testid="chartHeader-backBtn"
        >
          {isRemoteFeaturesEnabled ? (
            <BillBackIcon className="ChartHeader_BackIcon-onecolor" />
          ) : (
            <BackArrowIcon className="ChartHeader_BackIcon" />
          )}
        </Link>
      )}
      {Icon && <Icon className="ChartHeader_TitleIcon" />}
      {title &&
        (withTooltip ? (
          <TooltipTextOverflow
            className="ChartHeader_Title"
            label={title}
            placement="top"
            data-testid="chart-header-title"
          />
        ) : (
          <h2 className="ChartHeader_Title">{title}</h2>
        ))}

      {children}
    </header>
  );
}

ChartHeader.propTypes = {
  /** Relative URL for the back button. Will not display if undefined. */
  back: PropTypes.string,
  /** Optional KPI to include in the header */
  children: PropTypes.node,
  /** The title to display in the header */
  title: PropTypes.string,
  /** Additional class(es) to apply to the component */
  className: PropTypes.string,
  /** Icon placed before header title */
  Icon: PropTypes.elementType,
  /** Option to truncate the title with a tooltip for long chart titles */
  withTooltip: PropTypes.bool,
};

export default ChartHeader;
