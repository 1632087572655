import { useEffect } from 'react';

/**
 * Focuses and sets the caret position on an input at the start of editing
 *
 * @param {Object} inputRef
 */
export default function useFocusOnEdit(inputRef) {
  useEffect(() => {
    const { current } = inputRef;
    if (!current) return;

    // Number inputs do not support setSelectionRange
    if (current.type.includes('text')) {
      const end = current.value.length;
      current.setSelectionRange(end, end);
    }
    current.focus();
  }, [inputRef]);
}
