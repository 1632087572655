// @ts-check
import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import {
  billAuthenticationStartedAction,
  billAuthenticationSucceededAction,
  billAuthenticationFailedAction,
} from '@/actions/billOnboarding';
import isBillDomain from '@/helpers/isBillDomain';
import { authenticateOnboardedUser } from '@/services/onboarding.service';

/**
 * Authenticates the onboarded user to FP&A
 *
 * @type {() => () => Promise<
 *   import('@/types/services/backend').JwtResponse
 * >}
 */
const useBillAuthentication = () => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();

  return useCallback(async () => {
    if (isBillDomain()) {
      dispatch(billAuthenticationStartedAction());
      try {
        const { data } = await authenticateOnboardedUser();
        dispatch(billAuthenticationSucceededAction(data));
        return data;
      } catch (error) {
        dispatch(billAuthenticationFailedAction());
      }
    }
    return null;
  }, [dispatch]);
};

export default useBillAuthentication;
