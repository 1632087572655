import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getHiringDriversAction,
  getHiringDriverRecordsAction,
  deleteHiringDriverAction,
} from '@/actions/employees';
import ContextMenu from '@/components/common/ContextMenu/ContextMenu';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import PlusButton from '@/components/common/PlusButton';
import Table from '@/components/common/Table';
import { salaryIntervals } from '@/constants/employees';
import metricFormatters from '@/helpers/metricFormatters';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import TheHiringDriverRecordsModal from '@/pages/Employee/TheHiringDriverRecordsModal';
import './HiringDrivers.scss';

const VIEW_RECORDS_OPTION = 'VIEW_RECORDS_OPTION';
const HIRING_DRIVER_DELETE = 'HIRING_DRIVER_DELETE';
const HIRING_DRIVER_EDIT = 'HIRING_DRIVER_EDIT';

const TablePanel = ({ children, count, onAdd, hasWritePermission }) => {
  return (
    <section className="Panel Panel-toEdge TablePanel">
      <header className="TablePanelHeader">
        <span className="TablePanel_Title">Hiring Drivers</span>
        <span className="HiringDrivers_Count">
          ({count ?? 0} driver
          {count !== 1 && 's'})
        </span>
        {hasWritePermission && (
          <PlusButton onAdd={onAdd}>Add Hiring Driver</PlusButton>
        )}
      </header>
      {children}
    </section>
  );
};

const DriverRow = ({ driver, handleContextMenu, hasWritePermission }) => {
  const {
    departmentName,
    titleLabel,
    hiringTriggerFormula,
    employmentType,
    annualSalary,
    salaryVariableId,
  } = driver;

  const salary = annualSalary;
  const interval = salaryVariableId
    ? salaryIntervals.MONTHLY
    : salaryIntervals.ANNUALLY;

  return (
    <tr className="Table_Row">
      <td className="Table_Cell">{departmentName}</td>
      <td className="Table_Cell">{titleLabel}</td>
      <td className="Table_Cell">{hiringTriggerFormula}</td>
      <td className="Table_Cell">{employmentType}</td>
      <td className="Table_Cell">
        {`${metricFormatters.monetary(salary)}${interval}`}
      </td>

      <td className="Table_Cell">
        <ContextMenu data-testid="hiring-driver-record-action">
          <ContextMenu.Option
            data-testid="view-driver-records"
            className="pendo-ViewDriverRecords"
            onClick={() => handleContextMenu(VIEW_RECORDS_OPTION)}
          >
            View Driver Records
          </ContextMenu.Option>
          <ContextMenu.Option
            data-testid="edit-hiring-driver"
            className="pendo-EditHiringDriver"
            onClick={() => handleContextMenu(HIRING_DRIVER_EDIT)}
          >
            {hasWritePermission ? 'Edit Driver' : 'View Details'}
          </ContextMenu.Option>
          {hasWritePermission && (
            <ContextMenu.Option
              danger
              data-testid="delete-hiring-driver"
              className="pendo-DeleteHiringDriver"
              onClick={() => handleContextMenu(HIRING_DRIVER_DELETE)}
            >
              {' '}
              Delete Driver
            </ContextMenu.Option>
          )}
        </ContextMenu>
      </td>
    </tr>
  );
};

const EmptyDriverRow = ({ onAdd }) => {
  return (
    <div className="EmptyDriverRow">
      <button className="EmptyDriverRow_Button" onClick={onAdd}>
        Click here to create your first driver, or click the '+' button instead
      </button>
    </div>
  );
};

const HiringDrivers = ({
  scenarioId,
  companyId,
  startDate,
  endDate,
  hiringDrivers,
  hiringDriverRecords,
  hiringDriversLoading,
  onAddEdit,
  getAllHiringDrivers,
  getHiringDriverRecords,
  deleteHiringDriver,
}) => {
  const [showDriverRecordsModal, setShowDriverRecordsModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [driverId, setDriverId] = useState('');
  const [deleteInFlight, setDeleteInFlight] = useState(false);

  const hasWritePermission = useNonDashboardWritePermission();

  useEffect(() => {
    getAllHiringDrivers(scenarioId, companyId);
  }, [scenarioId, companyId, getAllHiringDrivers]);

  const handleContextMenu = (driver) => async (option) => {
    if (option === VIEW_RECORDS_OPTION) {
      await getHiringDriverRecords(
        driver.id,
        scenarioId,
        companyId,
        startDate,
        endDate,
      );
      setShowDriverRecordsModal(true);
    } else if (option === HIRING_DRIVER_DELETE) {
      setDriverId(driver.id);
      setShowDeleteModal(true);
    } else if (option === HIRING_DRIVER_EDIT) {
      onAddEdit(driver);
    }
  };

  const handleDelete = async () => {
    setDeleteInFlight(true);
    try {
      const data = await deleteHiringDriver(driverId, scenarioId, companyId);
      if (data.success) {
        setShowDeleteModal(false);
      }
    } catch (e) {
      // eslint-disable-next-line no-console -- predates description requirement
      console.error(e);
    } finally {
      setDeleteInFlight(false);
    }
  };

  return (
    <>
      <TablePanel
        count={hiringDrivers.length}
        onAdd={() => onAddEdit()}
        hasWritePermission={hasWritePermission}
      >
        {hiringDriversLoading ? (
          <div className="HiringDrivers_LoadingContainer">
            <LoadingSpinner className="HiringDrivers_LoadingSpinner" />
          </div>
        ) : (
          <>
            <Table
              className="HiringDriversTable"
              data-testid="hiring-drivers-table"
            >
              <thead>
                <tr className="Table_HeadRow">
                  <Table.ColHead>Department</Table.ColHead>
                  <Table.ColHead>Driver Job Title</Table.ColHead>
                  <Table.ColHead>Hiring Formula</Table.ColHead>
                  <Table.ColHead>Role Type</Table.ColHead>
                  <Table.ColHead>Salary</Table.ColHead>
                </tr>
              </thead>
              <tbody>
                {hiringDrivers.length > 0 &&
                  hiringDrivers.map((driver) => (
                    <DriverRow
                      driver={driver}
                      key={driver.id}
                      handleContextMenu={handleContextMenu(driver)}
                      hasWritePermission={hasWritePermission}
                    />
                  ))}
              </tbody>
            </Table>
            {hiringDrivers.length === 0 && hasWritePermission && (
              <EmptyDriverRow onAdd={() => onAddEdit()} />
            )}
          </>
        )}
      </TablePanel>
      <TheHiringDriverRecordsModal
        isOpen={showDriverRecordsModal}
        onClose={() => setShowDriverRecordsModal(false)}
        records={hiringDriverRecords}
      />
      {showDeleteModal && (
        <ModalConfirmation
          id="delete-hiring-driver-modal"
          title="Delete Hiring Driver"
          onAction={handleDelete}
          onCancel={() => setShowDeleteModal(false)}
          loading={deleteInFlight}
        >
          Are you sure that you want to delete this hiring driver? All
          forecasted hires created by this driver will be removed.
        </ModalConfirmation>
      )}
    </>
  );
};

const mapStateToProps = ({
  employees,
  scenario,
  companies: { selectedCompanyId },
  componentLoading,
  shared,
}) => ({
  hiringDrivers: employees.hiringDriversList,
  hiringDriversLoading: componentLoading.hiringDrivers,
  hiringDriverRecords: employees.hiringDriverRecords,
  scenarioId: scenario.scenarioId,
  companyId: selectedCompanyId,
  startDate: shared.startDate,
  endDate: shared.endDate,
});

export default connect(mapStateToProps, {
  getAllHiringDrivers: getHiringDriversAction,
  getHiringDriverRecords: getHiringDriverRecordsAction,
  deleteHiringDriver: deleteHiringDriverAction,
})(HiringDrivers);
