// @ts-check
import { useMemo } from 'react';
import useBillSession from '@/hooks/useBillSession';
import { BILL_APP_URL01, BILL_APP_URL02 } from '@/runtimeConfig';

/**
 * @private
 * @type {(orgId: string, podUrls: string[]) => string}
 */
const getBillBaseUrl = (orgId, podUrls) => {
  const bdcPodId = orgId.substring(3, 5);
  return podUrls.find((url) => url.includes(bdcPodId)) ?? '';
};

/**
 * Get the correct billBaseUrl depending on the user's organizationId
 *
 * @type {() => string}
 */
const useBillBaseUrl = () => {
  const { isSessionLoading, sessionInfo } = useBillSession();
  return useMemo(
    () =>
      isSessionLoading || !sessionInfo
        ? ''
        : getBillBaseUrl(sessionInfo.organizationId, [
            BILL_APP_URL01,
            BILL_APP_URL02,
          ]),
    [isSessionLoading, sessionInfo],
  );
};

export default useBillBaseUrl;
