// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { TOTAL_PAYROLL } from '@/cacheKeys';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import useChartQuery from '@/hooks/useChartQuery';
import { getPayroll } from '@/services/expensesService';

const mapByDepartment = ({ name, y }) => ({
  name,
  data: [y],
});

const totalPayrollReducer = (payload) => {
  let {
    averageHeadcountByDepartment,
    payrollExpenseByDepartment,
    totalHeadcountPerMonth,
    totalPayrollPerMonth,
  } = payload;

  averageHeadcountByDepartment = averageHeadcountByDepartment.map(
    ({ name, value }) => ({ name, y: value }),
  );
  payrollExpenseByDepartment = payrollExpenseByDepartment.map(
    ({ name, value }) => ({ name, y: value }),
  );
  if (payload.totalAdjustment !== 0) {
    payrollExpenseByDepartment.push({
      name: 'Adjustments To Total Payroll',
      y: payload.totalAdjustment,
      tooltipValue: payload.totalAdjustment,
    });
  }
  const payrollByDept = {
    data: payrollExpenseByDepartment.map(mapByDepartment),
  };
  const employeesByDept = {
    data: averageHeadcountByDepartment.map(mapByDepartment),
  };

  // We use totalPayrollPerMonth here, instead of totalHeadcountPerMonth,
  // because it is possible for a company to have payroll data but no
  // employees, as in the case of a new customer that has linked an
  // accounting integration but not a payroll integration (or manually
  // entered employees).
  const payrollAndHeadcountPerMonth = totalPayrollPerMonth.map(
    ({ month, value }, idx) => ({
      month,
      headcount: totalHeadcountPerMonth[idx]?.value ?? 0,
      payroll: value,
    }),
  );

  ({ data: totalHeadcountPerMonth } = mapMonthlyData(
    payrollAndHeadcountPerMonth,
    'headcount',
  ));

  ({ data: totalPayrollPerMonth } = mapMonthlyData(
    payrollAndHeadcountPerMonth,
    'payroll',
  ));

  const hasEmployees = totalHeadcountPerMonth.some(
    ({ headcount }) => headcount > 0,
  );

  return {
    ...payload,
    averageHeadcountByDepartment,
    payrollExpenseByDepartment,
    totalHeadcountPerMonth,
    totalPayrollPerMonth,
    payrollByDept,
    employeesByDept,
    hasEmployees,
  };
};

/**
 * API hook to populate the Total Payroll and Payroll by Department charts
 *
 * @returns {import('@/hooks/useScenarioQueryReducer').ScenarioQueryResult[]}
 */
const useTotalPayrollQuery = () => {
  const employeesList = useSelector(({ employees }) => employees.employeesList);

  const payrollExpenses = useSelector(
    ({ expenses }) => expenses.payrollExpenses,
  );

  return useChartQuery(TOTAL_PAYROLL, getPayroll, totalPayrollReducer, {
    dependencies: [employeesList, payrollExpenses],
  });
};

export default useTotalPayrollQuery;
