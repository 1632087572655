import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';

/**
 * A list of companies the user is authorized to access
 *
 * @returns {number[]}
 */
const useAuthorizedCompanies = () => {
  const accessibleCompanies = useSelector(
    ({ auth }) => auth.accessibleCompanies,
  );
  const authorizedCompanies = useMemo(
    () => Object.keys(accessibleCompanies ?? {}).map(Number),
    [accessibleCompanies],
  );
  return authorizedCompanies;
};

export default useAuthorizedCompanies;
