/**
 * Generates an ID for a custom variable, given its display name
 *
 * @example
 *   getVariableIdFromName('my variable') -> Custom.MyVariable
 *
 * @param {string} displayName User-entered name of a custom variable
 * @returns {string} Generated ID for the given name
 */
export default function getVariableIdFromName(displayName) {
  const displayNameAsId = displayName
    .split(' ')
    .map((word) => word.trim().replace(/^(\w)/, (_, $1) => $1.toUpperCase()))
    .join('');
  return `Custom.${displayNameAsId}`;
}
