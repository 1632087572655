import Checkbox from '@/components/common/Checkbox';
import FormRadio from '@/components/common/FormRadio';
import { classNames } from '@/helpers';
import PlanName from './PlanName';
import './PlanNameWithInput.scss';

const WithRadio = ({
  children,
  'data-testid': dataTestId,
  value,
  onChange,
  ...props
}) => {
  return (
    <div
      aria-hidden="true"
      className="PlanNameWithRadio_Row"
      onClick={onChange}
      data-testid={`${dataTestId}-wrapper`}
    >
      <FormRadio value={value.toString()} onChange={onChange} {...props} />
      {children}
    </div>
  );
};
const PlanNameWithInput = ({
  id,
  value,
  product,
  isDisabled = false,
  isChecked,
  className,
  onChange,
  asRadio = false,
}) => {
  const Input = asRadio ? WithRadio : Checkbox;
  const classes = classNames(
    asRadio ? 'PlanNameWithRadio_Label' : '',
    className,
  );
  return (
    <Input
      name="isChecked"
      id={`${id}`}
      onChange={onChange}
      disabled={isDisabled}
      checked={isChecked}
      value={value}
      data-testid="radio-button"
    >
      <PlanName product={product} className={classes} />
    </Input>
  );
};
export default PlanNameWithInput;
