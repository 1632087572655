// @ts-check
import useGlobalTopNavContext from '@/contexts/useGlobalTopNavContext';

export default function GlobalTopNavMarginWrapper({ children }) {
  const { margin } = useGlobalTopNavContext();
  return (
    <div style={{ marginTop: margin, minHeight: `calc(100vh - ${margin}px)` }}>
      {children}
    </div>
  );
}
