// @ts-check
/**
 * Constants for product types. These are used for filtering functionality based
 * on what product type (`productTypes` in the Redux store) each company has.
 *
 * @file
 */

/**
 * This product type is indicative of a company that has the full Finmark
 * access, and typically is a customer that has _not_ signed up via Bill
 */
export const FPA_FULL = 'FPA_FULL';

/**
 * `FPA_LITE` product type is indicative of a company that has a more limited
 * set of features and is typically a customer that has signed up via Bill and
 * is being offered with their BDC subscription without any upgrades
 */
export const FPA_LITE = 'FPA_LITE';
