import { useCallback } from 'react';
import { TOTAL_EXPENSES } from '@/cacheKeys';
import { stackChartDataReducer } from '@/components/Charts/helpers';
import { EXPENSE_DEPT } from '@/constants/expenses';
import {
  TOTAL_EXPENSES_SWITCH_ID,
  TOTAL_EXPENSES_TOGGLE_ID,
} from '@/constants/ui';
import useChartPreferences from '@/hooks/useChartPreferences';
import useChartQuery from '@/hooks/useChartQuery';
import { getExpenses } from '@/services/expensesService';

/**
 * API hook to populate the Total Expenses and Total Expenses Breakdown by
 * Percentage charts
 *
 * @param {Function} [extraReducer] Function to further reduce the API response
 * @returns {import('@/hooks/useScenarioQueryReducer').ScenarioQueryResult[]}
 */
function useTotalExpensesQuery(extraReducer) {
  const includePayroll = useChartPreferences(TOTAL_EXPENSES_SWITCH_ID) ?? true;
  const expensesByType = useChartPreferences(TOTAL_EXPENSES_TOGGLE_ID);

  const reducer = useCallback(
    ({ adjustments, expensesByCategory, expensesByDepartment }) => {
      const reduced = stackChartDataReducer({
        payload:
          expensesByType === EXPENSE_DEPT
            ? expensesByDepartment
            : expensesByCategory,
        adjustments,
      });
      return extraReducer ? extraReducer(reduced) : reduced;
    },
    [expensesByType, extraReducer],
  );
  return useChartQuery(TOTAL_EXPENSES, getExpenses, reducer, {
    queryParams: { includePayroll },
  });
}

export default useTotalExpensesQuery;
