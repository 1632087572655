// @ts-check
export const BEGINNING_CASH = 'Beginning Cash';
export const CASH_PROFIT_OR_LOSS = 'Cash Profit or Loss';
export const FINANCING = 'Financing';
export const ADJUSTMENTS_TO_CASH = 'Adjustments to Cash';
export const ACTUAL_ENDING_CASH = 'Ending Cash';
export const ACTUAL_CASH_COLLECTIONS = 'Cash Collection';
export const ACTUAL_CASH_PAYMENTS = 'Cash Payments';
export const EDITABLE_CASH_METRICS = /** @type {const} */ ([
  FINANCING,
  ACTUAL_ENDING_CASH,
  ACTUAL_CASH_COLLECTIONS,
  ACTUAL_CASH_PAYMENTS,
]);

export const cashActualsMetrics = /** @type {const} */ ({
  ACTUAL_CASH_COLLECTIONS,
  ACTUAL_CASH_PAYMENTS,
  ACTUAL_ENDING_CASH,
  ADJUSTMENTS_TO_CASH,
  BEGINNING_CASH,
  FINANCING,
});
/** @typedef {(typeof cashActualsMetrics)[keyof typeof cashActualsMetrics]} CashActualsMetrics */

export const EDITABLE_FUTURE_CASH_METRICS = /** @type {const} */ ([
  ACTUAL_ENDING_CASH,
]);

export const FORMATTED_NUM_REGEX = /^[(-]?\D?[\d,]+(?:.\d+)?\)?$/;

export const iconTypes = /** @type {const} */ ({
  SYSTEM_GENERATED: 'SYSTEM_GENERATED',
  USER_ENTERED: 'USER_GENERATED_FINMARK',
  EXTERNAL_SOURCE_GENERATED: 'EXTERNAL_GENERATED',
  CIRCULAR_REFERENCE: 'CIRCULAR_REFERENCE',
});
/** @typedef {(typeof iconTypes)[keyof typeof iconTypes]} IconTypes */

export const actualsFamily = /** @type {const} */ ({
  CASH: 'CASH',
  COMBINE: undefined,
  REVENUE: 'REVENUE',
  EXPENSE: 'EXPENSE',
  PAYROLL: 'PAYROLL',
});

export const actualsTitle = /** @type {const} */ ({
  BEGINNING_MRR: 'beginning_mrr',
  ENDING_MRR: 'ending_mrr',
  EXPANSION_MRR: 'expansion_mrr',
  CONTRACTION_MRR: 'contraction_mrr',
  ADJUSTMENT_MRR: 'adjustment_mrr',
  BEGINNING_CUSTOMER: 'beginning_customer',
  ENDING_CUSTOMER: 'ending_customer',
  EXPANSION_CUSTOMER: 'expansion_customer',
  CONTRACTION_CUSTOMER: 'contraction_customer',
  ADJUSTMENT_CUSTOMER: 'adjustment_customer',
  TOTAL_REVENUE: 'total revenue',
  ADJUSTMENT_TO_REVENUE: 'adjustments to revenue',
  NET_INCOME: 'net income',
  TOTAL_NON_PAYROLL_EXPENSE: 'total non-payroll expenses',
  TOTAL_PAYROLL: 'total payroll',
  ADJUSTMENT_TO_PAYROLL: 'adjustments to payroll',
});

/** @typedef {(typeof actualsTitle)[keyof typeof actualsTitle]} ActualsTitle */

export const actualsText = /** @type {const} */ ({
  [actualsFamily.COMBINE]: 'Combined Actuals',
  [actualsFamily.REVENUE]: 'Revenue Only',
  [actualsFamily.EXPENSE]: 'Non-Payroll Expenses Only',
  [actualsFamily.PAYROLL]: 'Payroll Only',
});
/** @typedef {(typeof actualsText)[keyof typeof actualsText]} ActualsText */

export const actualsLegendCopy = /** @type {const} */ ({
  [iconTypes.EXTERNAL_SOURCE_GENERATED]:
    'These actuals have been pulled from an external integrated platform. You can click on the icon to see the breakdown.',
  [iconTypes.SYSTEM_GENERATED]:
    'These actuals have been created by Finmark based on certain assumptions.',
  [iconTypes.USER_ENTERED]:
    'These values have been manually adjusted by the user.',
});
/** @typedef {(typeof actualsLegendCopy)[keyof typeof actualsLegendCopy]} ActualsLegendCopy */

export const cashLegendCopy = /** @type {const} */ ({
  [iconTypes.EXTERNAL_SOURCE_GENERATED]:
    'These actuals have been pulled from an external integrated platform.',
  [iconTypes.SYSTEM_GENERATED]:
    'These actuals have been created by Finmark based on your financing forecast.',
  [iconTypes.USER_ENTERED]:
    'These values have been manually adjusted by the user.',
});
/** @typedef {(typeof cashLegendCopy)[keyof typeof cashLegendCopy]} CashLegendCopy */

export const FORECAST = 'Forecast';
export const ACTUAL = 'Actual';
export const PENDING = 'Pending';

export const RESET_ACTUAL_LABEL = 'Reset Manual Adjustments';
