// eslint-disable-next-line no-restricted-imports -- we are defining the useTypedSelector hook here
import { useSelector } from 'react-redux';

/**
 * A custom hook to select data from the Redux store with proper typings.
 *
 * @type {import('react-redux').TypedUseSelectorHook<
 *   import('@/store').RootState
 * >}
 */
const useTypedSelector = useSelector;

export default useTypedSelector;
