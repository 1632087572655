import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

const ComponentLoader = ({
  loadingComponent,
  loadingComponents,
  paddingTop,
}) => {
  if (!loadingComponents[loadingComponent]) return null;
  return (
    <div className="component-loader" style={{ paddingTop }}>
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loadingComponents: state.componentLoading,
});

export default connect(mapStateToProps)(ComponentLoader);
