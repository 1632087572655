import Api from './Api';

/**
 * Authenticates a user's login credentials and returns the JWT token
 *
 * @type {(
 *   credentials: import('@/types/services/backend').JwtRequest,
 * ) => import('axios').AxiosResponse<
 *   import('@/types/services/backend').JwtResponse
 * >}
 */
export const loginService = (credentials) => {
  return Api.post('/authenticate', credentials, { headers: false });
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const refreshPermissions = () => {
  return Api.get('/authenticate/refresh');
};
