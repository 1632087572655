// @ts-check
import { subscribeToTopic } from '@/actions/shared';

/**
 * @typedef {{
 *   scenarioId: number;
 *   month: import('@/types/services/backend').CashInOutGridItem['date'];
 *   value: number;
 * }} CashGridWebSocketPayload
 */

/**
 * Subscribe to websocket updates that are pushed when the net cash flow is
 * updated
 *
 * @type {(
 *   scenarioId: number,
 *   callback: (CashGridWebSocketPayload) => void,
 * ) => import('@reduxjs/toolkit').ThunkAction<any, any, any, any>}
 */
export const subscribeToNetCashFlowUpdatesAction = (scenarioId, callback) => {
  return (dispatch) => {
    return dispatch(
      subscribeToTopic(`net-cashflow-updated/${scenarioId}`, (payload) =>
        callback(payload),
      ),
    );
  };
};

/**
 * Subscribe to websocket updates that are pushed when adjustments are updated
 *
 * @type {(
 *   scenarioId: number,
 *   callback: (CashGridWebSocketPayload) => void,
 * ) => import('@reduxjs/toolkit').ThunkAction<any, any, any, any>}
 */
export const subscribeToAdjustmentUpdatesAction = (scenarioId, callback) => {
  return (dispatch) => {
    return dispatch(
      subscribeToTopic(`adjustment-updated/${scenarioId}`, (payload) =>
        callback(payload),
      ),
    );
  };
};

/**
 * @typedef {{
 *   month: string;
 *   value: number;
 *   type: import('@/types/services/backend').CashInOutGridItemValue['type'];
 *   faulted: boolean;
 *   displayFormula: null | string;
 *   variableValueFaultType: import('@/types/services/backend').CashInOutGridItemValue['variableValueFaultType'];
 * }} CashAccountActualsUpdatedValuesObject
 */

/**
 * @typedef {{
 *   cashAccountId: string;
 *   scenarioId: number;
 *   faulted: boolean;
 *   variableValueFaultType: string | null;
 *   values: CashAccountActualsUpdatedValuesObject[];
 * }} CashAccountActualsUpdatedPayload
 */

/**
 * Subscribe to websocket updates that are pushed when adjustments are updated
 *
 * @type {(
 *   scenarioId: number,
 *   callback: (payload: CashAccountActualsUpdatedPayload) => void,
 * ) => ReturnType<typeof subscribeToTopic>}
 */
export const subscribeToCashAccountActualsUpdated = (scenarioId, callback) => {
  return (dispatch) => {
    return dispatch(
      subscribeToTopic(
        `cash-account-actuals-updated/${scenarioId}`,
        (payload) => callback(payload),
      ),
    );
  };
};
