import { forwardRef, useEffect } from 'react';
import ViewColumnToggleIcon from '@bill/cashflow.assets/view-column-toggle';
import { userPreferencesAction } from '@/actions/auth';
import Checkbox from '@/components/common/Checkbox';
import ControlDropdown from '@/components/common/ControlDropdown';
import useForceUpdate from '@/hooks/useForceUpdate';
import useTypedDispatch from '@/hooks/useTypedDispatch';
import './ColumnToggle.scss';

const ColumnToggleIcon = () => (
  <ViewColumnToggleIcon className="ColumnToggleIcon" />
);

const ColumnToggleOptions = ({ apiRef, spreadsheetId }) => {
  const dispatch = useTypedDispatch();
  const columns = apiRef.current?.columnApi.getColumns();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const { current } = apiRef;
    // We're listening to AG Grid's 'columnVisible' event and forcing the
    // component to re-render so the checkbox states remain in sync with the
    // grid.
    current.api.addEventListener('columnVisible', forceUpdate);
    return () => current.api.removeEventListener('columnVisible', forceUpdate);
  }, [apiRef, forceUpdate]);

  return (
    <div className="ColumnToggle_Options">
      {columns
        .filter(({ colDef: { lockVisible } }) => !lockVisible)
        .map(({ colId, colDef: { field, headerName }, visible }) => (
          <Checkbox
            key={field}
            id={field}
            checked={visible}
            onChange={({ target }) => {
              apiRef.current?.columnApi.setColumnVisible(colId, target.checked);
              const colIds = columns
                .filter((col) => col.visible)
                .map((col) => col.colId);
              dispatch(
                userPreferencesAction({
                  [`${spreadsheetId}-columns`]: colIds,
                }),
              );
            }}
          >
            {headerName}
          </Checkbox>
        ))}
    </div>
  );
};

/**
 * A control for toggling the visibility of columns in a grid
 *
 * @example
 *   <SpreadsheetToolbar>
 *     <ColumnToggle ref={apiRef} spreadsheetId={SPREADSHEET_ID} />
 *   </SpreadsheetToolbar>;
 */
const ColumnToggle = ({ spreadsheetId }, ref) => {
  return (
    <ControlDropdown id="column-toggle" Icon={ColumnToggleIcon}>
      <ColumnToggleOptions apiRef={ref} spreadsheetId={spreadsheetId} />
    </ControlDropdown>
  );
};

export default forwardRef(ColumnToggle);
