// @ts-check
import { FPA_LITE } from '@/constants/productTypes';
import useProductTypes from './useProductTypes';

/**
 * Hook to check if the product type for current company is FP&A Lite. This is
 * the product type default for users that come from BDC.
 *
 * @type {() => boolean}
 */
const useIsFPALite = () => {
  const productTypes = useProductTypes();
  return productTypes.has(FPA_LITE);
};

export default useIsFPALite;
