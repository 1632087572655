import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import Button from './Button';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from './ModalBase';
import './ModalConfirmation.scss';

const DELETE = 'Delete';
const DELETE_ACTION_WORDS = [DELETE, 'Remove'];

/**
 * A confirmation modal used to convey information to the user prior to
 * completing an action. Most often used to confirm a delete operation.
 *
 * @example
 *   <ModalConfirmation
 *     onCancel={() => setShowDelete(false)}
 *     onAction={handleDelete}
 *     actionBtnTxt="Delete"
 *   >
 *     Deleting this thing will remove it.
 *   </ModalConfirmation>;
 */
const ModalConfirmation = ({
  cancelBtnTxt = 'Cancel',
  children,
  actionBtnTxt = DELETE,
  id,
  open,
  onCancel,
  onAction,
  title,
  className,
  loading,
  danger = false,
}) => {
  const [isLoading, setLoading] = useState(loading ?? false);
  const isConfirmDelete =
    danger || DELETE_ACTION_WORDS.some((word) => actionBtnTxt.includes(word));

  useEffect(() => {
    setLoading(false);
  }, [open]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <ModalBase
      id={id}
      className={classNames('ModalConfirmation', className)}
      enableOutsideClose={false}
      header={
        <h2
          className={`ModalConfirmation_Heading ${
            isConfirmDelete && 'ModalConfirmation_Heading-delete'
          }`}
        >
          {title}
        </h2>
      }
      footer={
        <>
          {onCancel && (
            <Button
              className="Button Button-cancelLink"
              onClick={onCancel}
              data-testid="modal-cancel-button"
              disabled={isLoading}
            >
              {cancelBtnTxt}
            </Button>
          )}
          <Button
            className={isConfirmDelete ? 'Button-delete' : 'Button-primary'}
            onClick={() => {
              setLoading(true);
              onAction();
            }}
            data-testid="modal-action-button"
            loading={isLoading}
          >
            {actionBtnTxt}
          </Button>
        </>
      }
      show={open}
      data-testid={id}
    >
      <div className="ModalConfirmation_Text">{children}</div>
    </ModalBase>
  );
};

ModalConfirmation.propTypes = {
  /** The label of the cancel button */
  cancelBtnTxt: PropTypes.string,
  /** The content inside the modal */
  children: PropTypes.node,
  /**
   * The label of the action button. A value of 'Delete' will display red
   * heading text and button as opposed to finmark-blue
   */
  actionBtnTxt: PropTypes.string,
  /** Unique ID for selecting the element in unit/integration tests */
  id: PropTypes.string.isRequired,
  /** Callback function for closing the modal */
  onCancel: PropTypes.func,
  /** Whether or not the modal is displayed */
  open: PropTypes.bool,
  /** Callback function for the main modal action */
  onAction: PropTypes.func.isRequired,
  /** Title of the modal, typically "Delete <Object>" */
  title: PropTypes.string.isRequired,
  /** Class to add to modal heading */
  className: PropTypes.string,
  /** Allow passing of loading state from parent */
  loading: PropTypes.bool,
  /** If set to true, would highlight the headings/action button in red color */
  danger: PropTypes.bool,
};

export default ModalConfirmation;
