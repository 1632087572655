import TotalExpenses from '@/containers/TotalExpenses';
import AverageRevenuePerAccountGlance from '@/pages/Dashboard/AverageRevenuePerAccountGlance';
import BurnRateGlance from '@/pages/Dashboard/BurnRateGlance';
import CacPaybackGlance from '@/pages/Dashboard/CacPaybackGlance';
import CashBalanceGlance from '@/pages/Dashboard/CashBalanceGlance';
import CashInChartGlance from '@/pages/Dashboard/CashInChartGlance';
import CashOutChartGlance from '@/pages/Dashboard/CashOutChartGlance';
import ChurnedMrrGlance from '@/pages/Dashboard/ChurnedMrrGlance';
import CustomerAcquisitionCostGlance from '@/pages/Dashboard/CustomerAcquisitionCostGlance';
import CustomerLTVGlance from '@/pages/Dashboard/CustomerLTVGlance';
import GrossMarginGlance from '@/pages/Dashboard/GrossMarginGlance';
import LogoChurnGlance from '@/pages/Dashboard/LogoChurnGlance';
import NetCashFlowGlance from '@/pages/Dashboard/NetCashFlowGlance';
import NewCustomersGlance from '@/pages/Dashboard/NewCustomersGlance';
import NewMrrGlance from '@/pages/Dashboard/NewMrrGlance';
import NewRevenueGlance from '@/pages/Dashboard/NewRevenueGlance';
import RunwayGlance from '@/pages/Dashboard/RunwayGlance';
import TotalCustomersGlance from '@/pages/Dashboard/TotalCustomersGlance';
import TotalMrrGlance from '@/pages/Dashboard/TotalMrrGlance';
import TotalPayrollGlance from '@/pages/Dashboard/TotalPayrollGlance';
import TotalRevenueGlance from '@/pages/Dashboard/TotalRevenueGlance';

export const chartKeys = {
  RUNWAY: 'runway',
  BURN_RATE: 'burnRate',
  NET_CASHFLOW: 'netCashFlow',
  TOTAL_REVENUE: 'totalRevenue',
  NEW_REVENUE: 'newRevenue',
  TOTAL_MRR: 'totalMrr',
  NEW_MRR: 'newMrr',
  CHURN_MRR: 'churnMrr',
  NEW_CUSTOMERS: 'newCustomers',
  LOGO_CHURN: 'logoChurn',
  TOTAL_EXPENSES: 'totalExpenses',
  TOTAL_PAYROLL: 'totalPayroll',
  ARPA: 'ARPA',
  CASH_IN: 'cashIn',
  CASH_BALANCE: 'cashBalance',
  CASH_OUT: 'cashOut',
  TOTAL_CUSTOMERS: 'totalCustomers',
  GROSS_MARGIN: 'grossMargin',
  CUSTOMER_LTV: 'customerLTV',
  CAC: 'CAC',
  CAC_PAYBACK: 'CACPayback',
};

export default {
  [chartKeys.RUNWAY]: RunwayGlance,
  [chartKeys.BURN_RATE]: BurnRateGlance,
  [chartKeys.NET_CASHFLOW]: NetCashFlowGlance,
  [chartKeys.TOTAL_REVENUE]: TotalRevenueGlance,
  [chartKeys.NEW_REVENUE]: NewRevenueGlance,
  [chartKeys.TOTAL_MRR]: TotalMrrGlance,
  [chartKeys.NEW_MRR]: NewMrrGlance,
  [chartKeys.CHURN_MRR]: ChurnedMrrGlance,
  [chartKeys.NEW_CUSTOMERS]: NewCustomersGlance,
  [chartKeys.LOGO_CHURN]: LogoChurnGlance,
  [chartKeys.TOTAL_EXPENSES]: TotalExpenses,
  [chartKeys.TOTAL_PAYROLL]: TotalPayrollGlance,
  [chartKeys.ARPA]: AverageRevenuePerAccountGlance,
  [chartKeys.CASH_IN]: CashInChartGlance,
  [chartKeys.CASH_BALANCE]: CashBalanceGlance,
  [chartKeys.CASH_OUT]: CashOutChartGlance,
  [chartKeys.TOTAL_CUSTOMERS]: TotalCustomersGlance,
  [chartKeys.GROSS_MARGIN]: GrossMarginGlance,
  [chartKeys.CUSTOMER_LTV]: CustomerLTVGlance,
  [chartKeys.CAC]: CustomerAcquisitionCostGlance,
  [chartKeys.CAC_PAYBACK]: CacPaybackGlance,
};
