import {
  STRIPE_SESSION_SUCCESS,
  STRIPE_SESSION_ERROR,
  HANDLE_DATA_CHANGE,
  SET_LOADING,
  SET_CONNECTION_ERROR,
  SET_COMPANY_SETTINGS_SUCCESS,
  SET_COMPANY_SETTINGS_ERROR,
  SET_USER_SETTINGS_SUCCESS,
  SET_USER_SETTINGS_ERROR,
  SET_INTEGRATIONS_STATUS_SUCCESS,
  SET_INTEGRATIONS_STATUS_ERROR,
  LOAD_MULTIPLIER_ERROR,
  SET_USER_SETTINGS_LOADING,
  SET_COMPANY_SETTINGS_LOADING,
  CHANGE_USER_PASSWORD_LOADING,
  LOAD_MULTIPLIER_LOADING,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_ERROR,
  SET_SHARE_COMPANY_ERROR,
  CHANGE_SHARE_COMPANY_LOADING,
  RESET_SHARE_COMPANY_ERROR,
  MEMBER_LIST_SUCCESS,
  MEMBER_REMOVE_SUCCESS,
  MEMBER_REMOVE_LOADING,
  MEMBER_DISABLE_INVITE_SUCCESS,
  MEMBER_DISABLE_INVITE_LOADING,
  MEMBER_DISABLE_INVITE_ERROR,
  MEMBER_DISABLE_INVITE_RESET,
  MEMBER_INFO_SUCCESS,
  SET_SHARE_COMPANY_SUCCESS,
  MEMBER_INFO_RESET,
  SET_PAYMENT_PLANS,
  COLLECTION_TERMS_SUCCESS,
  COLLECTION_TERMS_ERROR,
  PAYMENT_TERMS_SUCCESS,
  PAYMENT_TERMS_ERROR,
  INTEGRATION_DATA_SYNC_STARTED,
  INTEGRATION_DATA_SYNC_COMPLETED,
} from '@/actionTypes/settings';
import { LOGOUT } from '@/actionTypes/shared';
import {
  integrationFamily,
  integrationManualSyncMap,
} from '@/constants/integrations';
import { DEFAULT_COMPANY_SETTINGS } from '@/constants/settings';
import {
  integrationStatusesFromPayload,
  enrichIntegrations,
} from '@/helpers/integrations';

const {
  INTEGRATION_FAMILY_CODAT,
  INTEGRATION_FAMILY_FINCH,
  INTEGRATION_FAMILY_REVENUE,
} = integrationFamily;

const INITIAL_STATE = {
  companySettingsError: null,
  error: null,
  integrations: [],
  integrationsAllowed: [],
  integrationsError: null,
  integrationsUnauthorized: [],
  integrationsConnected: [],
  loading: false,
  userSettings: {
    emailAddress: '',
    lastName: '',
    firstName: '',
  },
  connectionError: null,
  loadMultiplier: null,
  loadMultiplierError: null,
  salaryIncreaseError: null,
  loadMultiplierLoading: false,
  changeUserPasswordSuccess: null,
  changeUserPasswordError: null,
  changeUserPasswordLoading: false,
  userSettingsError: null,
  companySettings: {
    ...DEFAULT_COMPANY_SETTINGS,
    loading: false,
  },
  shareCompany: {
    success: false,
    loading: false,
    error: false,
  },
  memberInfo: null,
  members: {
    companyUsers: [],
    total: 0,
  },
  removeMember: {
    loading: false,
    success: false,
  },
  memberInviteDisable: {
    success: false,
    loading: false,
    error: '',
  },
  paymentPlan: {
    paymentPlans: [],
    selectedPaymentPlanId: null,
    currentSelectedPaymentPlanId: null,
    subscriptionId: '',
  },
  collectionTerms: {
    collectionTerms: [],
    error: false,
    success: false,
  },
  paymentTerms: {
    data: [],
    error: false,
    success: false,
  },
};

const getSystemTypeKey = (systemType) => {
  if (systemType === INTEGRATION_FAMILY_CODAT) {
    return integrationManualSyncMap[INTEGRATION_FAMILY_CODAT];
  }
  if (systemType === INTEGRATION_FAMILY_FINCH) {
    return integrationManualSyncMap[INTEGRATION_FAMILY_FINCH];
  }
  return integrationManualSyncMap[INTEGRATION_FAMILY_REVENUE];
};

const setting = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COMPANY_SETTINGS_SUCCESS:
      return {
        ...state,
        companySettings: { ...state.companySettings, ...action.payload },
        companySettingsError: null,
      };
    case SET_COMPANY_SETTINGS_ERROR:
      return {
        ...state,
        companySettingsError: action.payload,
      };
    case SET_COMPANY_SETTINGS_LOADING:
      return {
        ...state,
        companySettings: {
          ...state.companySettings,
          ...{ loading: action.payload },
        },
      };
    case INTEGRATION_DATA_SYNC_STARTED: {
      const { systemType } = action.payload;
      return {
        ...state,
        companySettings: {
          ...state.companySettings,
          [getSystemTypeKey(systemType)]: true,
        },
      };
    }
    case INTEGRATION_DATA_SYNC_COMPLETED: {
      const { systemType, status, type } = action.payload;
      const updatedIntegrations = state.integrations.map((integration) => {
        if (
          integration.systemType === systemType &&
          integration.type === type
        ) {
          const updatedIntegration = { ...integration, status };
          return updatedIntegration;
        }
        return integration;
      });
      const [
        integrationsUnauthorized,
        integrationsConnected,
        integrationsAllowed,
      ] = integrationStatusesFromPayload(updatedIntegrations);

      return {
        ...state,
        integrations: updatedIntegrations,
        integrationsConnected,
        integrationsUnauthorized,
        integrationsAllowed,
        companySettings: {
          ...state.companySettings,
          [getSystemTypeKey(systemType)]: false,
        },
      };
    }
    case SET_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        userSettings: { ...state.userSettings, ...action.payload },
        userSettingsError: null,
      };
    case SET_USER_SETTINGS_LOADING:
      return {
        ...state,
        userSettings: { ...state.userSettings, ...{ loading: action.payload } },
      };
    case SET_USER_SETTINGS_ERROR:
      return {
        ...state,
        userSettingsError: action.payload,
      };
    case HANDLE_DATA_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
        error: null,
      };
    case SET_CONNECTION_ERROR:
      return { ...state, connectionError: action.payload };
    case LOGOUT:
      return { ...INITIAL_STATE };
    case SET_LOADING:
      return { ...state, ...action.payload };
    case SET_INTEGRATIONS_STATUS_SUCCESS: {
      const integrations = enrichIntegrations(action.payload);
      const [
        integrationsUnauthorized,
        integrationsConnected,
        integrationsAllowed,
      ] = integrationStatusesFromPayload(integrations);

      return {
        ...state,
        integrations,
        integrationsConnected,
        integrationsUnauthorized,
        integrationsAllowed,
      };
    }
    case SET_INTEGRATIONS_STATUS_ERROR:
      return { ...state, integrationsError: action.payload };
    case CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        changeUserPasswordSuccess: action.payload,
        changeUserPasswordError: null,
      };
    case CHANGE_USER_PASSWORD_LOADING:
      return { ...state, changeUserPasswordLoading: action.payload };
    case CHANGE_USER_PASSWORD_ERROR:
      return {
        ...state,
        changeUserPasswordSuccess: null,
        changeUserPasswordError: action.payload,
      };
    case STRIPE_SESSION_SUCCESS:
      return {
        ...state,
        stripeSessionSuccess: action.payload,
        stripeSessionError: null,
      };
    case STRIPE_SESSION_ERROR:
      return {
        ...state,
        stripeSessionSuccess: null,
        stripeSessionError: action.payload,
      };
    case LOAD_MULTIPLIER_LOADING:
      return { ...state, loadMultiplierLoading: action.payload };
    case LOAD_MULTIPLIER_ERROR:
      return {
        ...state,
        loadMultiplier: null,
        stripeSessionError: action.payload,
      };
    case MEMBER_LIST_SUCCESS:
      return {
        ...state,
        members: action.payload,
      };
    case MEMBER_INFO_SUCCESS:
      return {
        ...state,
        memberInfo: action.payload,
      };

    case MEMBER_INFO_RESET:
      return {
        ...state,
        memberInfo: null,
      };

    case CHANGE_SHARE_COMPANY_LOADING:
      return {
        ...state,
        shareCompany: { ...state.shareCompany, loading: action.payload },
      };
    case SET_SHARE_COMPANY_SUCCESS:
      return {
        ...state,
        shareCompany: {
          ...state.shareCompany,
          success: action.payload,
          error: false,
        },
      };
    case SET_SHARE_COMPANY_ERROR:
      return {
        ...state,
        shareCompany: {
          ...state.shareCompany,
          error: action.payload,
          success: false,
        },
      };
    case RESET_SHARE_COMPANY_ERROR:
      return {
        ...state,
        shareCompany: { ...INITIAL_STATE.shareCompany },
      };
    case MEMBER_REMOVE_LOADING:
      return {
        ...state,
        removeMember: { ...state.removeMember, loading: action.payload },
      };
    case MEMBER_REMOVE_SUCCESS:
      return {
        ...state,
        removeMember: { ...state.removeMember, success: true },
      };

    case MEMBER_DISABLE_INVITE_LOADING:
      return {
        ...state,
        memberInviteDisable: {
          ...state.memberInviteDisable,
          loading: action.payload,
        },
      };
    case MEMBER_DISABLE_INVITE_SUCCESS:
      return {
        ...state,
        memberInviteDisable: {
          ...state.memberInviteDisable,
          success: true,
          error: false,
        },
      };
    case MEMBER_DISABLE_INVITE_ERROR:
      return {
        ...state,
        memberInviteDisable: {
          ...state.memberInviteDisable,
          error: action.payload,
          success: false,
        },
      };
    case MEMBER_DISABLE_INVITE_RESET:
      return {
        ...state,
        memberInviteDisable: {
          ...INITIAL_STATE.memberInviteDisable,
        },
      };
    case SET_PAYMENT_PLANS: {
      return {
        ...state,
        paymentPlan: { ...state.paymentPlan, ...action.payload },
      };
    }
    case COLLECTION_TERMS_SUCCESS:
      return {
        ...state,
        collectionTerms: {
          collectionTerms: action.payload.map((term, index) => ({
            ...term,
            isDefault: index === 0,
          })),
          success: true,
          error: false,
        },
      };
    case COLLECTION_TERMS_ERROR:
      return {
        ...state,
        collectionTerms: {
          ...state.collectionTerms,
          error: action.payload,
          success: false,
        },
      };
    case PAYMENT_TERMS_SUCCESS:
      return {
        ...state,
        paymentTerms: {
          data: action.payload.map((term, index) => ({
            ...term,
            isDefault: index === 0,
          })),
          success: true,
          error: false,
        },
      };
    case PAYMENT_TERMS_ERROR:
      return {
        ...state,
        paymentTerms: {
          ...state.paymentTerms,
          error: action.payload,
          success: false,
        },
      };
    default:
      return state;
  }
};
export default setting;
