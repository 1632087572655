// @ts-check
import { integrationFamily } from '@/constants/integrations';
import { naturalSortComparator } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';

const {
  INTEGRATION_FAMILY_CODAT,
  INTEGRATION_FAMILY_FINCH,
  INTEGRATION_FAMILY_REVENUE,
} = integrationFamily;

/**
 * Filter integrations by system type and returns a object with name and
 * integration type
 *
 * @type {(integrations: import('@/reducers/companies').Integration[]) => {
 *   accounting: string;
 *   payroll: string;
 *   revenue: string;
 * }}
 */
const getIntegrationsByType = (integrations) => {
  return integrations.reduce(
    (acc, { systemType, type }) => {
      switch (systemType) {
        case INTEGRATION_FAMILY_CODAT:
          return { ...acc, accounting: type };
        case INTEGRATION_FAMILY_FINCH:
          return { ...acc, payroll: type };
        case INTEGRATION_FAMILY_REVENUE:
          return { ...acc, revenue: type };
        default:
          return acc;
      }
    },
    { accounting: 'y', payroll: 'x', revenue: 'z' },
  );
};

/**
 * Perform a natural sort on integrations alphabetically with the following
 * priority
 *
 * 1. Accounting
 * 2. Payroll
 * 3. Revenue
 *
 * @type {import('ag-grid-community').ColDef<
 *   import('@/reducers/companies').Company
 * >['comparator']}
 */
export const integrationComparator = (valA, valB, nodeA, nodeB, isInverse) => {
  const integrationA = getIntegrationsByType(
    nodeA.data.enabledIntegrations ?? [],
  );
  const integrationB = getIntegrationsByType(
    nodeB.data.enabledIntegrations ?? [],
  );
  const accounting = naturalSortComparator(
    integrationA.accounting,
    integrationB.accounting,
  );
  const payroll = naturalSortComparator(
    integrationA.payroll,
    integrationB.payroll,
  );
  const revenue = naturalSortComparator(
    integrationA.revenue,
    integrationB.revenue,
  );
  const order = accounting || payroll || revenue;
  return isInverse && (isEmptyOrNull(valA) || isEmptyOrNull(valB))
    ? -order
    : order;
};

/**
 * Returns the type of integration as value
 *
 * @type {(
 *   params: import('ag-grid-community').ValueGetterParams<
 *     import('@/reducers/companies').Company
 *   >,
 * ) => string}
 */
export const integrationValueGetter = ({ data }) => {
  if (!data.enabledIntegrations) return null;
  const sortedIntegrations = [...data.enabledIntegrations].sort(
    (compA, compB) => naturalSortComparator(compA.systemType, compB.systemType),
  );
  const value = sortedIntegrations.reduce((acc, curr) => {
    let str = acc;
    if (acc !== '') {
      str = `${acc} `;
    }
    return curr.type.split(' ').length > 1
      ? `${str}${curr.type.split(' ')[1]}`
      : `${str}${curr.type}`;
  }, '');
  return value;
};
