import React, { createContext, useMemo } from 'react';
import { createWebTracker } from '@bill/cashflow.web-tracking';

declare const process: {
  env: {
    VERSION: string;
  };
};

const PRODUCT_FRONTEND_GITLAB_ID = 6072;
const webTracker = createWebTracker(
  'forecasting',
  process.env.VERSION,
  PRODUCT_FRONTEND_GITLAB_ID,
);
export const WebTrackingContext = createContext<{
  webTracker: typeof webTracker;
}>({
  webTracker,
});

/**
 * Provider for convenient use of bill's web-tracking
 * @example <WebTrackingProvider>...</WebTrackingProvider
 *
 * @example const { webTracker } = useContext(WebTrackingContext);
 *          webTracker.track()
 */
const WebTrackingProvider = ({ children }: { children: React.ReactNode }) => {
  const value = useMemo(() => ({ webTracker }), []);
  return (
    <WebTrackingContext.Provider value={value}>
      {children}
    </WebTrackingContext.Provider>
  );
};

export default WebTrackingProvider;
