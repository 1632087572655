import {
  SET_ERROR,
  SET_CASH_FLOW_ACTUALS,
  SET_EXTERNAL_PROFIT_AND_LOSS,
  SET_EXTERNAL_CASH_FLOW,
  SET_CASH_FLOW_VALUES,
} from '@/actionTypes/actuals';
import { subscribeToMonthlyValues, subscribeToTopic } from '@/actions/shared';
import {
  ACTUAL_CASH_COLLECTIONS,
  ACTUAL_CASH_PAYMENTS,
  FINANCING,
} from '@/constants/actuals';
import { MONTHLY } from '@/constants/dateTime';
import { NO_COMPARISON } from '@/constants/scenario';
import {
  patchActualFinancing,
  patchActualEndingCash,
  patchActualCashCollections,
  patchActualCashPayments,
  getCashFlowActuals,
  updateActuals,
  getExternalProfitAndLoss,
  getExternalCashFlow,
} from '@/services/actuals.service';

/**
 * Action to fetch profit and loss from external spreadsheets.
 *
 * @param {string} startDate First month of report to be retrieved
 * @param {string} endDate Last month of report to be retrieved
 * @param {number} scenarioId The id of the scenario
 * @returns {Function} Dispatchable action
 */
export const getExternalProfitAndLossAction = (
  startDate,
  endDate,
  scenarioId,
) => {
  return async (dispatch) => {
    try {
      const {
        data: { data },
      } = await getExternalProfitAndLoss({ startDate, endDate, scenarioId });
      dispatch({
        type: SET_EXTERNAL_PROFIT_AND_LOSS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: SET_ERROR,
        payload: e.response?.data?.error?.errorMessage || e.message,
      });
    }
  };
};

/**
 * Updates actuals from the combined actuals and cash actuals views, according
 * to their type
 *
 * @param {number[]} scenarioIds IDs of the scenarios containing the actuals to
 *   update
 * @param {Object[]} payload New parameters for the actuals
 * @returns {Function} Dispatchable action
 */
export const updateActualsAction = (scenarioIds, payload) => {
  return async (dispatch) => {
    try {
      await updateActuals({ scenarioIds, actuals: payload });
    } catch (e) {
      dispatch({
        type: SET_ERROR,
        payload: e.response?.data?.error?.errorMessage || e.message,
      });
    }
  };
};

/**
 * Action to fetch cash flow from external spreadsheets.
 *
 * @param {string} startDate First month of report to be retrieved
 * @param {string} endDate Last month of report to be retrieved
 * @param {number} scenarioId The id of the scenario
 * @returns {Function} Dispatchable action
 */
export const getExternalCashFlowAction = (startDate, endDate, scenarioId) => {
  return async (dispatch) => {
    try {
      const {
        data: { data },
      } = await getExternalCashFlow({ startDate, endDate, scenarioId });
      dispatch({
        type: SET_EXTERNAL_CASH_FLOW,
        payload: data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console -- predates description requirement
      console.error(e);
    }
  };
};

/**
 * Gets the cash actuals for the given scenarios within the given date range
 *
 * @param {Object} params Param object
 * @param {string} params.startDate First month of actuals to be retrieved
 * @param {string} params.endDate Last month of actuals to be retrieved
 * @param {number[]} params.scenarioIds IDs of one or more scenarios containing
 *   the desired actuals
 * @param {string} [params.timePeriod] Period over which to aggregate actuals
 *   values, e.g. MONTHLY, QUARTERLY, ANNUALLY
 * @returns {Function} Dispatchable action
 */
export const getCashFlowActualsAction = ({
  startDate,
  endDate,
  scenarioIds,
  timePeriod = MONTHLY,
}) => {
  return async (dispatch) => {
    try {
      const {
        data: { data },
      } = await getCashFlowActuals({
        startDate,
        endDate,
        scenarioIds,
        timePeriod,
      });
      dispatch({
        type: SET_CASH_FLOW_ACTUALS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: SET_ERROR,
        payload: e.response?.data?.error?.errorMessage || e.message,
      });
    }
  };
};

export const updateActualCashAction = ({
  updateMetric,
  payload,
  scenarioId,
  startDate,
  endDate,
}) => {
  return async (dispatch, getState) => {
    try {
      const { scenario } = getState();
      const { scenarioId: baseScenario, compareScenarioId } = scenario;
      const scenarioIds = [baseScenario];
      if (compareScenarioId !== NO_COMPARISON) {
        scenarioIds.push(compareScenarioId);
      }
      let response;

      if (updateMetric === FINANCING.toLowerCase()) {
        response = await patchActualFinancing(payload, scenarioId);
      } else if (updateMetric === ACTUAL_CASH_COLLECTIONS.toLowerCase()) {
        response = await patchActualCashCollections(payload, scenarioId);
      } else if (updateMetric === ACTUAL_CASH_PAYMENTS.toLowerCase()) {
        response = await patchActualCashPayments(payload, scenarioId);
      } else {
        response = await patchActualEndingCash(payload, scenarioId);
      }

      const {
        data: { success, error },
      } = response;

      if (success) {
        dispatch(getCashFlowActualsAction({ startDate, endDate, scenarioIds }));
      } else {
        dispatch({
          type: SET_ERROR,
          payload: error.errorMessage,
        });
      }
    } catch (e) {
      dispatch({
        type: SET_ERROR,
        payload: e.response?.data?.error?.errorMessage || e.message,
      });
    }
  };
};

/**
 * Subscribes to actuals update notifications
 *
 * @param {number} scenarioId ID of the scenario containing the actuals
 * @param {string} family The type of actual to monitor for updates
 * @param {Function} callback Message handler for when a notification is
 *   received
 * @returns {Function} Dispatchable action
 */
export const subscribeToActualsUpdateAction = (
  scenarioId,
  family,
  callback,
) => {
  return (dispatch) => {
    return dispatch(
      subscribeToTopic(`actuals-updated/${scenarioId}`, ({ actualsType }) => {
        if (actualsType.toLowerCase() === family.toLowerCase()) {
          callback();
        }
      }),
    );
  };
};

/**
 * Subscribes to expense actuals update notifications
 *
 * @param {number} scenarioId ID of the scenario containing the actuals
 * @param {Function} callback Message handler for when a notification is
 *   received
 * @returns {Function} Dispatchable action
 */
export const subscribeToExpenseActualsUpdateAction = (scenarioId, callback) => {
  return (dispatch) => {
    return dispatch(
      subscribeToTopic(`expense-actuals-updated/${scenarioId}`, (payload) => {
        callback(payload);
      }),
    );
  };
};

/**
 * @typedef {{
 *   scenarioId: number;
 *   type: string;
 *   month: import('@/types/services/backend').CashInOutGridItem['date'];
 *   value: number;
 *   valueType: import('@/types/services/backend').CashInOutGridItemValue['type'];
 *   displayFormula?: string;
 *   faulted: boolean;
 *   variableValueFaultType?: import('@/types/services/backend').CashInOutGridItemValue['variableValueFaultType'];
 * }} CashActualsValuesPayload
 */

/**
 * Action to subscribe to value updates for cash actuals
 *
 * @type {(
 *   scenarioId: number,
 *   callback: (CashActualsValuesPayload) => void,
 * ) => ReturnType<typeof subscribeToTopic>}
 */
export const subscribeToCashActualsValuesAction = (scenarioId, callback) => {
  const topic = `aggregated-scenario-values/${scenarioId}`;
  return (dispatch) => {
    if (!callback) {
      return dispatch(subscribeToMonthlyValues(topic, SET_CASH_FLOW_VALUES));
    }
    return dispatch(subscribeToTopic(topic, (payload) => callback(payload)));
  };
};
