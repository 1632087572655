import { useMemo } from 'react';

const reduceData = (query, reducer) =>
  query.isSuccess ? reducer(query.data.data.data) : [];

/** @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult */
/** @typedef {import('@/types/services/backend').ScenarioInfoDto} ScenarioInfoDto */
/**
 * @typedef {UseQueryResult & {
 *   scenario: ScenarioInfoDto;
 * }} ScenarioQueryResult
 */

/**
 * Takes a React Query object and passes its response through the given reducer
 *
 * @param {UseQueryResult} query A React Query object
 * @param {ScenarioInfoDto} scenario Scenario associated with the query
 * @param {Function} reducer Transforms the query response for consumption by
 *   the component
 * @param {any[]} [dependencies] Additional dependencies for rerunning the
 *   reducer
 * @returns {ScenarioQueryResult} Modified React Query object
 */
export default function useScenarioQueryReducer(
  query,
  scenario,
  reducer,
  dependencies = [],
) {
  return useMemo(
    () =>
      query
        ? {
            ...query,
            data: reduceData(query, reducer),
            scenario,
          }
        : null,
    /**
     * React Query objects do not have referential integrity, so we use the
     * query status as the dependency
     *
     * @see https://github.com/tannerlinsley/react-query/issues/3049
     */
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [
      scenario,
      query?.dataUpdatedAt,
      query?.isFetching,
      reducer,
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
      ...dependencies,
    ],
  );
}
