// @ts-check
import { useContext } from 'react';
import { FFaaSContext } from '@/contexts/FFaaSProvider';

/**
 * Use this hook to get the feature flags from the FFaaS provider.
 *
 * @type {() => import('../contexts/FFaaSProvider').FFaaSObject}
 */
const useFeatureFlagsService = () => {
  return useContext(FFaaSContext);
};

export default useFeatureFlagsService;
