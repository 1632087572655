import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import GoBackIcon from '@bill/cashflow.assets/go-back';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';

const RevenueStreamWizard = ({
  step,
  children,
  previousStep,
  mode,
  revenueName,
  isViewOnly,
}) => {
  // eslint-disable-next-line consistent-return -- predates description requirement
  const getSubtitle = () => {
    if (step !== 1) {
      return revenueName;
    }
  };

  const formHeading = useMemo(() => {
    if (isViewOnly) {
      return 'Revenue Stream Details';
    }

    return `${mode === 'add' ? 'Add' : 'Edit'} Revenue Stream`;
  }, [isViewOnly, mode]);

  return (
    <div className="px-0">
      <div className="px-2">
        {step > 1 && (
          <Button variant="link" className="BackLink" onClick={previousStep}>
            <GoBackIcon className="RevenueStream_GoBack" />
          </Button>
        )}
        <div className="d-flex justify-content-between align-items-end">
          <h2 className="header-text">{formHeading}</h2>
        </div>
        <TooltipTextOverflow
          className="RevenueStream_Name"
          placement="right"
          label={getSubtitle()}
          data-testid="RevenueStreamWizard-tooltip"
        />
      </div>
      {children}
    </div>
  );
};

export default RevenueStreamWizard;
