import {
  SET_EMPLOYEES_LIST,
  SET_EMPLOYEES_ERROR,
  SET_EMPLOYEES_BY_DEPARTMENT,
  SET_DEPARTMENTS,
  SET_HIRING_DRIVERS_LIST,
  SET_HIRING_DRIVER_RECORDS,
  SET_PENDING_EMPLOYEES,
  SET_SHOW_REVIEW_EMPLOYEE_MODAL,
  SET_UNLINKED_EMPLOYEES_LIST,
  SET_ALL_JOB_TITLES,
  DELETE_CUSTOM_JOB_TITLE,
  ADD_CUSTOM_JOB_TITLE,
  UPDATE_EMPLOYEE,
} from '@/actionTypes/employees';
import { actions, subjects } from '@/constants/permissions';
import {
  getFormattedDateFromTimeStamp,
  MAX_DATE,
  MIN_DATE,
} from '@/helpers/dateFormatter';
import { formatValueForSave } from '@/helpers/percentageFormulaFormatter';
import {
  getEmployeesByDepartment,
  getPagedEmployees,
  getDepartments,
  deleteEmployees,
  getPendingEmployees,
  matchExternalEmployees,
  getAllJobTitles,
  deleteEmployeeTitle,
  postEmployeeCustomTitle,
} from '@/services/employee.service';
import {
  getHiringDriverRecords,
  getHiringDrivers,
  createHiringDriver,
  updateHiringDriver,
  deleteHiringDriver,
} from '@/services/hiringdrivers.service';
import { changeLoadingState } from './componentLoading';
import { actionWithPermission, subscribeToTopic } from './shared';

/**
 * Format an employee's compensation formula for the backend
 *
 * @param {Object} employee - The employee to update
 * @returns {Object} the formatted compensation formula or an empty object
 */
export const getCompensationFormula = (employee) => {
  return employee.compensationFormula
    ? {
        [employee.compensationType]: formatValueForSave(
          employee.compensationFormula,
          employee.unit,
        ),
        unit: employee.unit,
      }
    : {};
};

const listSuccess = (data) => {
  return {
    type: SET_EMPLOYEES_LIST,
    payload: data,
  };
};

const setError = (error) => {
  return {
    type: SET_EMPLOYEES_ERROR,
    payload: error,
  };
};

const setEmployeesByDepartment = (departmentChart) => {
  return {
    type: SET_EMPLOYEES_BY_DEPARTMENT,
    payload: departmentChart,
  };
};

export const getEmployees = (scenarioId, startDate, endDate) => {
  return actionWithPermission(
    {
      action: actions.READ_WRITE,
      subject: subjects.EMPLOYEE_SETTINGS,
      scenarioAction: actions.READ,
      scenarioSubject: subjects.NON_DASHBOARD,
    },
    async (dispatch) => {
      dispatch(setError(''));
      try {
        const response = await getPagedEmployees({
          scenarioId,
          startDate,
          endDate,
        });
        if (response.data.success) {
          dispatch(listSuccess(response.data.data));
        } else {
          dispatch(setError(response.data.error.errorMessage));
        }
      } catch (e) {
        dispatch(setError(e.response?.data?.error?.errorMessage || e.message));
      }
      dispatch(changeLoadingState('employeesList', false));
    },
  );
};

/**
 * Action to fetch employees who haven't been linked with external employee from
 * payroll platform
 *
 * @param {number} scenarioId ID of the scenario for the selected employees
 * @returns {Function} Dispatchable action
 */
export const getUnlinkedEmployeesAction = (scenarioId) => {
  return async (dispatch) => {
    try {
      const {
        data: { data, success, error },
      } = await getPagedEmployees({
        scenarioId,
        startDate: getFormattedDateFromTimeStamp(MIN_DATE),
        endDate: getFormattedDateFromTimeStamp(MAX_DATE),
        unlinkedOnly: true,
      });
      if (success) {
        dispatch({
          type: SET_UNLINKED_EMPLOYEES_LIST,
          payload: data,
        });
      } else {
        // eslint-disable-next-line no-console -- predates description requirement
        console.error(error.errorMessage);
      }
    } catch (e) {
      dispatch(setError(e.response?.data?.error?.errorMessage || e.message));
    }
  };
};

export const employeesByDepartment = (startDate, endDate, scenarioId) => {
  return async (dispatch) => {
    dispatch(setError(''));
    try {
      const response = await getEmployeesByDepartment({
        startDate,
        endDate,
        scenarioId,
      });
      if (response.data.success) {
        dispatch(
          setEmployeesByDepartment(response.data.data.expenseClassExpense),
        );
      } else {
        dispatch(setError(response.data.error.errorMessage));
      }
    } catch (e) {
      dispatch(setError(e.response?.data?.error?.errorMessage || e.message));
    }
  };
};

/**
 * Fetch the list of employees and list of employees by department
 *
 * @param {number} scenarioId
 */
export const refreshEmployeeDataAction = (scenarioId) => {
  return (dispatch, getStore) => {
    const { shared } = getStore();
    const { startDate, endDate } = shared;
    dispatch(getEmployees(scenarioId, startDate, endDate));
    dispatch(employeesByDepartment(startDate, endDate, scenarioId));
  };
};

/**
 * Get all job titles
 *
 * @param {number} scenarioId The id of the scenario
 * @returns {Function} Dispatchable action
 */
export const getAllJobTitlesAction = (scenarioId) => {
  return async (dispatch) => {
    const { data } = await getAllJobTitles(scenarioId);
    if (data.success) {
      dispatch({ type: SET_ALL_JOB_TITLES, payload: data.data });
    }
  };
};

export const getAllDepartments = () => {
  return async (dispatch) => {
    dispatch(setError(''));
    try {
      const response = await getDepartments();
      if (response.data.success) {
        dispatch({
          type: SET_DEPARTMENTS,
          payload: response.data.data,
        });
      } else {
        dispatch(setError(response.data.error.errorMessage));
      }
    } catch (e) {
      dispatch(setError(e.response?.data?.error?.errorMessage || e.message));
    }
  };
};

/**
 * Action to fetch pending employees imported from a payroll integration
 * service.
 *
 * @param {number} scenarioId The id of the scenario
 * @param {number} companyId The id of the company
 * @returns {Function} Dispatchable action
 */
export const getPendingEmployeeAction = (companyId, scenarioId) => {
  return actionWithPermission(
    {
      action: actions.READ_WRITE,
      subject: subjects.EMPLOYEE_SETTINGS,
      scenarioAction: actions.READ,
      scenarioSubject: subjects.NON_DASHBOARD,
    },
    async (dispatch) => {
      try {
        const {
          data: { data },
        } = await getPendingEmployees(companyId, scenarioId);
        dispatch({
          type: SET_PENDING_EMPLOYEES,
          payload: data,
        });
      } catch (e) {
        dispatch(setError(e.response?.data?.error?.errorMessage || e.message));
      }
    },
  );
};

/**
 * Action that deletes a list of given employee(s)
 *
 * @param {Array} employeesIds Array of ids for selected employees to delete
 * @param {string} startDate First month of desired employee data, in the format
 *   YYYY-MM
 * @param {string} endDate Last month of desired employee data, in the format
 *   YYYY-MM
 * @param {number} scenarioId ID of the scenario for the selected employees
 * @param {number} companyId ID of the company for the selected employees
 * @returns {Promise} API response
 */
export const deleteEmployeesAction = ({
  employeesIds,
  scenarioId,
  companyId,
}) => {
  return async (dispatch) => {
    dispatch(setError(''));
    try {
      const response = await deleteEmployees(
        { employeesIds },
        scenarioId,
        companyId,
      );
      if (response.data.success) {
        dispatch(refreshEmployeeDataAction(scenarioId));
        dispatch(getPendingEmployeeAction(companyId, scenarioId));
      } else {
        dispatch(setError(response.data.error.errorMessage));
      }
    } catch (e) {
      dispatch(setError(e.response?.data?.error?.errorMessage || e.message));
    }
  };
};

/**
 * Get a list of hiring drivers
 *
 * @param {number} scenarioId The id of the scenario containing the hiring
 *   driver
 * @param {number} companyId The id of the company
 * @returns {Function} Dispatchable action
 */
export const getHiringDriversAction = (scenarioId, companyId) => {
  return async (dispatch) => {
    dispatch(setError(''));
    dispatch(changeLoadingState('hiringDrivers', true));
    try {
      const { data } = await getHiringDrivers(scenarioId, companyId);
      if (data.success) {
        dispatch({ type: SET_HIRING_DRIVERS_LIST, payload: data.data });
      } else {
        dispatch(setError(data.error.errorMessage));
      }
    } catch (e) {
      dispatch(setError(e.response?.data?.error?.errorMessage || e.message));
    } finally {
      dispatch(changeLoadingState('hiringDrivers', false));
    }
  };
};

/**
 * Action that creates a hiring driver
 *
 * @param {number} scenarioId ID of the scenario containing the hiring driver
 * @param {Object} driver Driver details
 * @returns {Function} Dispatchable action
 */
export const addHiringDriverAction = (driver, scenarioId, companyId) => {
  return async (dispatch) => {
    dispatch(setError(''));
    try {
      const { data } = await createHiringDriver(driver, {
        scenarioId,
        companyId,
      });
      if (data.success) {
        dispatch(getHiringDriversAction(scenarioId, companyId));
      } else {
        dispatch(setError(data.error.errorMessage));
      }
    } catch (e) {
      dispatch(setError(e.response?.data?.error?.errorMessage || e.message));
    }
  };
};

/**
 * Get employee records for a hiring driver
 *
 * @param {string} driverId The id of the driver whose records we want to fetch
 * @param {number} scenarioId The id of the scenario containing the hiring
 *   driver
 * @param {number} companyId The id of the company
 * @returns {Function} Dispatchable action
 */
export const getHiringDriverRecordsAction = (
  driverId,
  scenarioId,
  companyId,
  startDate,
  endDate,
) => {
  return async (dispatch) => {
    dispatch(setError(''));
    try {
      const { data } = await getHiringDriverRecords(
        driverId,
        scenarioId,
        companyId,
        startDate,
        endDate,
      );
      if (data.success) {
        dispatch({ type: SET_HIRING_DRIVER_RECORDS, payload: data.data });
      } else {
        dispatch(setError(data.error.errorMessage));
      }
    } catch (e) {
      dispatch(setError(e.response?.data?.error?.errorMessage || e.message));
    }
  };
};

/**
 * Delete a hiring driver, and all employee records associated with it
 *
 * @param {string} driverId The id of the driver whose records we want to fetch
 * @param {number} scenarioId The id of the scenario containing the hiring
 *   driver
 * @param {number} companyId The id of the company
 * @returns {Function} Dispatchable action
 */
export const deleteHiringDriverAction = (driverId, scenarioId, companyId) => {
  return async (dispatch) => {
    const { data } = await deleteHiringDriver(driverId, scenarioId, companyId);
    if (data.success) {
      dispatch(getHiringDriversAction(scenarioId, companyId));
    }
    return data;
  };
};

/**
 * Subscribes to hiring driver reforecast notifications in order to refetch
 * payroll data
 *
 * @param {number} scenarioId ID of the scenario containing the hiring drivers
 * @returns {Function} Dispatchable action
 */
export const subscribeToHiringDriverForecastAction = (scenarioId, callback) => {
  return (dispatch) => {
    return dispatch(
      subscribeToTopic(`hiring-driver-reforecasted/${scenarioId}`, () => {
        callback();
      }),
    );
  };
};

/**
 * Action that updates an existing hiring driver
 *
 * @param {string} id ID of the hiring driver we want to update
 * @param {Object} driver Updated driver details
 * @param {Object} params
 * @param {number} params.scenarioId The id of the scenario containing the
 *   hiring driver
 * @param {number} params.companyId The id of the company
 * @returns {Function} Dispatchable action
 */
export const updateHiringDriverAction = (
  id,
  driver,
  { scenarioId, companyId },
) => {
  return async (dispatch) => {
    dispatch(setError(''));
    try {
      await updateHiringDriver(id, driver, { scenarioId, companyId });
      dispatch(getHiringDriversAction(scenarioId, companyId));
    } catch (e) {
      dispatch(setError(e.response?.data?.error?.errorMessage || e.message));
    }
  };
};

export const setShowReviewEmployeeModalAction = (payload) => ({
  type: SET_SHOW_REVIEW_EMPLOYEE_MODAL,
  payload,
});

/**
 * Action that matches external employees from payroll platform
 *
 * @param {number} companyId The id of the company
 * @param {number} scenarioId The id of the scenario
 * @param {string} startDate Date to fetch value from
 * @param {string} endDate Date to fetch value till
 * @param {Object} mappedData Matched Employees details
 * @returns {Function} Dispatchable action
 */
export const matchExternalEmployeeAction = (
  companyId,
  scenarioId,
  mappedData,
) => {
  return async (dispatch) => {
    await matchExternalEmployees(companyId, scenarioId, mappedData);
    dispatch(getPendingEmployeeAction(companyId, scenarioId));
    dispatch(setShowReviewEmployeeModalAction(false));
  };
};

/**
 * Subscribe to employee update socket event
 *
 * @param {number} scenarioId ID of the scenario with the employee update
 * @param {Function} callback Message handler for when a notification is
 *   received
 * @returns {Function} Dispatchable action
 */
export const subscribeToEmployeeUpdateAction = (scenarioId, callback) => {
  return (dispatch) => {
    return dispatch(
      subscribeToTopic(
        `employees-updated/${scenarioId}`,
        ({ scenarioId: value }) => {
          if (value) {
            callback();
          }
        },
      ),
    );
  };
};

/**
 * Adds a custom job title with the given params to the given scenario
 *
 * @param {number} scenarioId ID of the scenario that should contain the title
 * @param {Object} params Parameters of the new title
 * @returns {Function} Dispatchable action
 */
export const addCustomJobTitleAction = (scenarioId, params) => {
  return async (dispatch) => {
    const { data } = await postEmployeeCustomTitle(params, scenarioId);
    if (data.success) {
      dispatch({ type: ADD_CUSTOM_JOB_TITLE, payload: data.data });
    }
    return data.data;
  };
};

/**
 * Deletes the custom job title with the given ID
 *
 * @param {number} scenarioId ID of the scenario containing the title
 * @param {number} titleId ID of the title to delete
 * @returns {Function} Dispatchable action
 */
export const deleteCustomJobTitleAction = (scenarioId, titleId) => {
  return async (dispatch) => {
    const { data } = await deleteEmployeeTitle(titleId, scenarioId);
    if (data.success) {
      dispatch({ type: DELETE_CUSTOM_JOB_TITLE, payload: titleId });
    }
  };
};

/**
 * @typedef {{
 *   employeeId: string;
 *   faulted: boolean;
 *   scenarioId: number;
 *   variableValueFaultType: string;
 * }} EmployeeFaultedPayload
 */

/**
 * Subscribe to employee actuals update notifications
 *
 * @type {(
 *   params: { scenarioId: number },
 *   callback?: (payload: EmployeeFaultedPayload) => void,
 * ) => ReturnType<typeof subscribeToTopic>}
 */
export const subscribeToEmployeeFaultedUpdateAction = (
  { scenarioId },
  callback,
) => {
  return (dispatch) => {
    return dispatch(
      subscribeToTopic(
        `employee-faulted/${scenarioId}`,
        (/** @type {EmployeeFaultedPayload} */ websocketData) => {
          dispatch({
            type: UPDATE_EMPLOYEE,
            payload: websocketData,
          });
          callback?.(websocketData);
        },
      ),
    );
  };
};
