import { useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import PageHeader from '@/components/Layouts/PageHeader';
import ExportableReportButton from '@/components/Reports/ExportableReport/ExportableReportButton';
import ExternalReport from '@/components/Reports/ExternalReport';
import TableExportButton from '@/components/TableExportButton';
import Tabs from '@/components/common/Tabs';
import {
  exportableReportTypes,
  BALANCE_SHEET_SLUG,
  CASH_FLOW_STATEMENT_SLUG,
  reportTypes,
  externalReportNames,
} from '@/constants/reports';
import { slugify } from '@/helpers';
import { hasAccountingIntegration } from '@/helpers/integrations';
import useReportData from '@/hooks/useReportData';
import useReportsInfo from '@/hooks/useReportsInfo';
import CustomReportsGrid from '@/pages/Reports/CustomReportsGrid';
import ProfitAndLoss from '@/pages/Reports/ProfitAndLoss';
import UserReportsGrid from '@/pages/Reports/UserReportsGrid';
import VendorSpend from '@/pages/Reports/VendorSpend';
import getBaseScenario from '@/selectors/getBaseScenario';
import './Reports.scss';

const PROFIT_AND_LOSS = '';
const CASH_FLOW_SUMMARY = '/cash-flow-summary';
const BALANCE_SHEET = '/balance-sheet';
const VENDOR_SPEND = '/vendor-spend';
const CASH_FLOW_STATEMENT = '/cash-flow-statement';

const reportTypeMap = /** @type {const} */ {
  [PROFIT_AND_LOSS]: reportTypes.PROFIT_AND_LOSS,
  [CASH_FLOW_STATEMENT]: reportTypes.CASH_FLOW_STATEMENT,
  [BALANCE_SHEET]: reportTypes.BALANCE_SHEET,
};

const Reports = ({ selectedScenario, scenarioId }) => {
  const gridApi = useRef(null);

  const [showVarianceAmount, setShowVarianceAmount] = useState(true);
  const [showVariancePercentage, setShowVariancePercentage] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();
  const activePath = location.pathname.replace(path, '');
  const { data: reportsInfo, isLoading } = useReportsInfo(scenarioId);

  const isAccountingIntegrationEnabled =
    hasAccountingIntegration(selectedScenario);

  const {
    data: reportData,
    isLoading: isReportDataLoading,
    refetch,
  } = useReportData(
    reportTypeMap[activePath],
    { ...reportsInfo, isAccountingIntegrationEnabled },
    !isLoading,
  );

  const exportCsv = () => {
    const { api } = gridApi.current ?? {};
    if (activePath === VENDOR_SPEND || activePath.includes('custom')) {
      api.exportDataAsExcel();
      return;
    }
    const fileName =
      activePath === PROFIT_AND_LOSS
        ? 'ProfitAndLoss'
        : activePath.replace('/', '');
    if (api) {
      api.exportDataAsExcel({ fileName });
    }
  };

  const conditionalTabs = useMemo(() => {
    const tabs = {};
    const defaultComponentParams = {
      apiRef: gridApi,
    };
    if (reportsInfo?.isExternalCashFlow) {
      tabs[CASH_FLOW_STATEMENT_SLUG] = {
        path: CASH_FLOW_SUMMARY,
        title: 'Cash Flow Summary',
        Component: ExternalReport,
        componentParams: {
          ...defaultComponentParams,
          'data-testid': 'cashflow-summary',
          'name': externalReportNames.CASH_FLOW_STATEMENT,
          showVarianceAmount,
          setShowVarianceAmount,
          showVariancePercentage,
          setShowVariancePercentage,
        },
      };
    }

    if (isAccountingIntegrationEnabled && reportsInfo?.isExternalBalanceSheet) {
      tabs[BALANCE_SHEET_SLUG] = {
        path: BALANCE_SHEET,
        title: 'Balance Sheet',
        Component: ExternalReport,
        componentParams: {
          ...defaultComponentParams,
          'data-testid': 'balance-sheet',
          'name': externalReportNames.BALANCE_SHEET,
          showVarianceAmount,
          setShowVarianceAmount,
          showVariancePercentage,
          setShowVariancePercentage,
        },
      };
    }

    reportsInfo?.userReports
      .sort((x, y) => x.name.localeCompare(y.name))
      .forEach((userReport) => {
        const name = slugify(userReport.name);
        if (tabs[name]) return;
        tabs[name] = {
          path: `/${name}`,
          title: userReport.name,
          Component: UserReportsGrid,
          componentParams: {
            ...defaultComponentParams,
            reportId: userReport.id,
            name: userReport.name,
            showVarianceAmount,
            setShowVarianceAmount,
            showVariancePercentage,
            setShowVariancePercentage,
          },
        };
      });

    return tabs;
  }, [
    isAccountingIntegrationEnabled,
    reportsInfo,
    showVarianceAmount,
    showVariancePercentage,
  ]);

  return (
    <>
      <PageHeader
        page="/reports"
        addBtnSlot={[
          <ExportableReportButton
            type={exportableReportTypes.FINANCIAL_SUMMARY}
          />,
        ]}
      />
      <div className="PageLayout">
        <section className="Panel Panel-toEdge">
          <Tabs
            controls={<TableExportButton onClick={exportCsv} />}
            fallback={() => (reportsInfo ? history.replace(path) : undefined)}
          >
            <Tabs.Panel path={path} label="Profit & Loss">
              {reportsInfo?.isExternalProfitAndLoss ? (
                <ExternalReport
                  name={externalReportNames.PROFIT_AND_LOSS}
                  data={reportData}
                  isLoading={isReportDataLoading}
                  apiRef={gridApi}
                  data-testid="profit-and-loss"
                />
              ) : (
                <ProfitAndLoss
                  apiRef={gridApi}
                  showVarianceAmount={showVarianceAmount}
                  setShowVarianceAmount={setShowVarianceAmount}
                  showVariancePercentage={showVariancePercentage}
                  setShowVariancePercentage={setShowVariancePercentage}
                  data={reportData}
                  isLoading={isReportDataLoading}
                  refetch={refetch}
                />
              )}
            </Tabs.Panel>
            {Object.values(conditionalTabs).map(
              ({ path: tabPath, title, Component, componentParams }) => (
                <Tabs.Panel
                  key={title}
                  path={`${path}${tabPath}`}
                  label={title}
                >
                  <Component
                    {...componentParams}
                    data={reportData}
                    isLoading={isReportDataLoading}
                  />
                </Tabs.Panel>
              ),
            )}
            {isAccountingIntegrationEnabled &&
              reportsInfo?.isVendorAvailable && (
                <Tabs.Panel
                  path={`${path}${VENDOR_SPEND}`}
                  label="Vendor Spend"
                >
                  <VendorSpend
                    apiRef={gridApi}
                    showVarianceAmount={showVarianceAmount}
                    showVariancePercentage={showVariancePercentage}
                    setShowVarianceAmount={setShowVarianceAmount}
                    setShowVariancePercentage={setShowVariancePercentage}
                  />
                </Tabs.Panel>
              )}
            {reportsInfo?.customReports.map((report) => (
              <Tabs.Panel
                key={report.id}
                path={`${path}/custom/${report.id}`}
                label={report.name}
              >
                <CustomReportsGrid
                  apiRef={gridApi}
                  title={report.name}
                  showVarianceAmount={showVarianceAmount}
                  setShowVarianceAmount={setShowVarianceAmount}
                  showVariancePercentage={showVariancePercentage}
                  setShowVariancePercentage={setShowVariancePercentage}
                />
              </Tabs.Panel>
            ))}
          </Tabs>
        </section>
      </div>
    </>
  );
};

function mapStateToProps({ scenario }) {
  return {
    scenarioId: scenario.scenarioId,
    selectedScenario: getBaseScenario({ scenario }),
  };
}

export default connect(mapStateToProps)(Reports);
