import { ApiV2 } from './Api';

/**
 * Get the hiring drivers for a given scenario
 *
 * @param {number} scenarioId The ID of the scenario with the hiring driver
 * @param {number} companyId The ID of the company with the hiring driver
 * @returns {Promise} API response
 */
export const getHiringDrivers = (scenarioId, companyId) => {
  return ApiV2.get('/hiring-drivers', {
    params: { scenarioId, companyId },
  });
};

/**
 * Create a new hiring driver
 *
 * @param {Object} driver The hiring driver to create
 * @param {Object} queryParams Query params to pass to the POST request
 * @returns {Promise} API response
 */
export const createHiringDriver = (driver, queryParams) => {
  return ApiV2.post(`/hiring-drivers`, driver, { params: queryParams });
};

/**
 * Get the employee records for a scenario's hiring driver
 *
 * @param {string} driverId The ID of the driver whose records we want to fetch
 * @param {number} scenarioId The ID of the scenario with the hiring driver
 * @param {number} companyId The ID of the company with the hiring driver
 * @param {string} startDate First month of desired record range
 * @param {string} endDate Last month of disired record range
 * @returns {Promise} API response
 */
export const getHiringDriverRecords = (
  driverId,
  scenarioId,
  companyId,
  startDate,
  endDate,
) => {
  return ApiV2.get(`/hiring-drivers/${driverId}/employees`, {
    params: { scenarioId, companyId, startDate, endDate },
  });
};

/**
 * Delete a hiring driver, and all employee records associated with it
 *
 * @param {string} driverId ID of the hiring driver to delete
 * @param {number} scenarioId The ID of the scenario with the hiring driver
 * @param {number} companyId The ID of the company with the hiring driver
 * @returns {Promise} API response
 */
export const deleteHiringDriver = (driverId, scenarioId, companyId) => {
  return ApiV2.delete(`/hiring-drivers/${driverId}`, {
    params: { scenarioId, companyId },
  });
};

/**
 * Update a hiring driver
 *
 * @param {string} driverId The ID of the driver whose records we want to fetch
 * @param {Object} updatedDriver The hiring driver to create
 * @param {Object} queryParams Query params to pass to the POST request
 * @returns {Promise} API response
 */
export const updateHiringDriver = (driverId, updatedDriver, queryParams) => {
  return ApiV2.put(`/hiring-drivers/${driverId}`, updatedDriver, {
    params: queryParams,
  });
};
