// @ts-check
import { useEffect, useRef } from 'react';

/**
 * Returns true if the component has unmounted. Typically used to guard against
 * setting state when events fire after the component has unmounted.
 *
 * @type {() => React.MutableRefObject<boolean>}
 */
export default function useIsUnmounted() {
  /** @type {React.MutableRefObject<boolean>} */
  const isUnmounted = useRef(false);
  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    [],
  );
  return isUnmounted;
}
