import { useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import InfoTooltip from '@/components/common/InfoTooltip';
import TogglePersistent from '@/components/common/TogglePersistent';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import useChartPreferences from '@/hooks/useChartPreferences';
import useDashboardPath from '@/hooks/useDashboardPath';
import NewMrrChart, { NEW_MRR_TOGGLE_ID } from './charts/NewMrrChart';

const CHART_TITLE = 'New MRR';

const NewMrr = ({ startDate, endDate }) => {
  const [newMrr, setNewMrr] = useState({});
  const chartRef = useRef();
  const showPercentage = useChartPreferences(NEW_MRR_TOGGLE_ID);
  const dashboardPath = useDashboardPath();
  const onQueryStateChange = useCallback(({ data, isLoading }) => {
    setNewMrr({ ...data, isLoading });
  }, []);

  return (
    <section className="Panel Panel-toEdge">
      <ChartHeader back={dashboardPath} title={CHART_TITLE}>
        <div className="ChartHeader_KPIValue">
          {newMrr.isLoading ? (
            ''
          ) : (
            <>
              {newMrr.metric}{' '}
              {newMrr.tooltipText && (
                <InfoTooltip data-testid="new-mrr-tooltip">
                  {newMrr.tooltipText}
                </InfoTooltip>
              )}
            </>
          )}
        </div>
        <div className="ChartHeader_KPI">
          {showPercentage ? (
            <span className="ChartHeader_Subtitle">Average for:</span>
          ) : (
            <br />
          )}
          {formatRangeWithShortYear(startDate, endDate)}
        </div>
      </ChartHeader>
      <ChartToolbar>
        <TogglePersistent
          id={NEW_MRR_TOGGLE_ID}
          options={[
            {
              label: 'Currency',
              value: false,
            },
            {
              label: 'Percent',
              value: true,
            },
          ]}
          value={false}
        />
        <ChartControls
          chartRef={chartRef}
          title={CHART_TITLE}
          metricValue={newMrr.metric}
          label={
            showPercentage
              ? `Average for: ${formatRangeWithShortYear(startDate, endDate)}`
              : formatRangeWithShortYear(startDate, endDate)
          }
          data-testid="new-mrr-chart-export"
        />
      </ChartToolbar>
      <NewMrrChart
        className="ExpandedView_ChartWrapper"
        exportBtn={chartRef}
        showPercentage={showPercentage}
        onQueryStateChange={onQueryStateChange}
      />
    </section>
  );
};

function mapStateToProps({ shared }) {
  return {
    startDate: shared.startDate,
    endDate: shared.endDate,
  };
}

export default connect(mapStateToProps)(NewMrr);
