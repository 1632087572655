import { useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import RevenueEmptyIcon from '@bill/cashflow.assets/revenue-empty';
import { getProductRevenuesList } from '@/actions/revenue';
import ContextMenuRenderer from '@/components/Revenue/ContextMenuRenderer';
import RevenueTitleBar from '@/components/Revenue/List/RevenueTitleBar';
import EmptyData from '@/components/common/EmptyData';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import { revenueFamily } from '@/constants/revenue';
import useCallbackRef from '@/hooks/useCallbackRef';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import { GRID_OPTIONS } from '@/pages/Reports/constants';

const SPREADSHEET_ID = 'revenueProductGrid';

const formatData = (productsRevenueList) => {
  const hierarchy = ['total'];
  const data = [
    {
      id: 'total',
      name: 'Total',
      isEditable: false,
      months: productsRevenueList.total,
      hierarchy,
    },
  ];
  productsRevenueList.products.forEach((product) => {
    const productId = product.id;
    const productRow = {
      id: productId,
      name: product.name,
      isEditable: true,
      hierarchy: [...hierarchy, productId],
    };

    const pricingPlanRows = product.pricingPlanRevenueSummary.map(
      (pprs, idx) => {
        if (idx === 0) {
          productRow.months = pprs.months.map((month) => ({ ...month }));
        } else {
          pprs.months.forEach((month, index) => {
            productRow.months[index].value += month.value;
          });
        }
        const pricingPlanId = `${productId}-${pprs.productPricingPlanId}`;
        return {
          id: pricingPlanId,
          name: pprs.productPricingPlanName,
          isEditable: false,
          months: pprs.months,
          hierarchy: [...productRow.hierarchy, pricingPlanId],
        };
      },
    );
    data.push(productRow, ...pricingPlanRows);
  });

  return data;
};

const ProductsTable = ({ onEdit }) => {
  const [gridApi, ref] = useCallbackRef();
  const hasWritePermission = useNonDashboardWritePermission();
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector(({ shared }) => shared);
  const { scenarioId } = useSelector(({ scenario }) => scenario);
  const { productsRevenueList } = useSelector(({ revenues }) => revenues);

  useEffect(() => {
    dispatch(getProductRevenuesList(scenarioId, startDate, endDate));
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [getProductRevenuesList, scenarioId, startDate, endDate]);

  const data = useMemo(
    () => productsRevenueList && formatData(productsRevenueList),
    [productsRevenueList],
  );

  const hasData = Boolean(data);

  const colDefs = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Products',
        headerComponent: HeaderRenderer,
        headerComponentParams: { enableExpandAll: true },
        cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: { suppressCount: true },
      },
      {
        colId: 'actions',
        type: 'actions',
        cellRendererSelector: ({ data: rowData }) => {
          if (!rowData.isEditable) return undefined;
          return {
            component: ContextMenuRenderer,
            params: {
              onEdit,
              hasWritePermission,
              family: revenueFamily.PRODUCT,
            },
          };
        },
        lockVisible: true,
      },
    ];
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [data]);

  return (
    <>
      <RevenueTitleBar gridApi={gridApi} heading="Products" />
      {hasData ? (
        <>
          <MonthlySpreadsheet
            {...GRID_OPTIONS}
            ref={ref}
            columnDefs={colDefs}
            data-testid={SPREADSHEET_ID}
            data={data}
          />
        </>
      ) : (
        <EmptyData className="EmptyData-tab" Icon={RevenueEmptyIcon}>
          Add Products for this table to populate.
        </EmptyData>
      )}
    </>
  );
};

export default ProductsTable;
