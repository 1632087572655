export const CHART_TITLE = 'Total Revenue';

export const TOTAL_REVENUE_METRICS = [
  {
    name: 'Recurring Revenue',
    key: 'totalMrr',
  },

  {
    name: 'Other Revenue',
    key: 'otherRevenue',
  },
  {
    name: 'Adjustments to Total Revenue',
    key: 'adjustments',
  },
  {
    name: CHART_TITLE,
    isMainMetric: true,
    key: 'y',
  },
];
