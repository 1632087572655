const CASH_BALANCE_METRICS = [
  {
    key: 'y',
    name: 'Cash.Ending.Total',
    unit: 'Currency',
    isMainMetric: true,
  },
];

export default CASH_BALANCE_METRICS;
