export const SECTION = 'Section';
export const SUBSECTION = 'Subsection';
export const ITEM = 'Item';
export const BALANCE_SHEET_SLUG = 'balance-sheet';
export const CASH_FLOW_STATEMENT_SLUG = 'cash-flow-statement';

/**
 * The approximate max number of letters (based on the `W` character, which is
 * often the widest letter in an English language font) that could fill up a
 * single, unwrapped row
 */
export const MAX_LETTERS_IN_ROW = 16;

/**
 * @typedef {FINANCIAL_SUMMARY | CASH_REPORT} ReportTypes
 *
 * @typedef {{
 *   title: string;
 *   key: ReportTypes;
 *   path: string;
 *   buttonText: string;
 *   isNew: boolean;
 * }} ExportableReportType
 * @type {{
 *   FINANCIAL_SUMMARY: ExportableReportType;
 *   CASH_REPORT: ExportableReportType;
 * }}
 */
export const exportableReportTypes = {
  FINANCIAL_SUMMARY: {
    title: 'Financial Summary Report',
    key: 'FINANCIAL_SUMMARY',
    path: '/financial-summary',
    buttonText: 'Summary Report',
    isNew: true,
  },
  CASH_REPORT: {
    title: 'Cash Report',
    key: 'CASH_REPORT',
    path: '/cash-report',
    buttonText: 'Cash Report',
    isNew: true,
  },
};

export const reportTypes = /** @type {const} */ ({
  BALANCE_SHEET: 'balance_sheet',
  CASH_FLOW_STATEMENT: 'cashflow_statement',
  PROFIT_AND_LOSS: 'profit_loss',
  CASH_IN_OUT: 'cash_in_out',
});

/** @typedef {(typeof reportTypes)[keyof typeof reportTypes]} ReportType */

export const columnTruncationLimits = {
  NO_COMPARISON_LIMIT: 12,
  WITH_COMPARISON_LIMIT: 6,
  WITH_VARIANCE_LIMIT: 4,
};

export const externalReportNames = /** @type {const} */ ({
  PROFIT_AND_LOSS: 'Profit & Loss Breakdown',
  CASH_FLOW_STATEMENT: 'Cash Flow Breakdown',
  BALANCE_SHEET: 'Balance Sheet',
});
/**
 * @typedef {(typeof externalReportNames)[keyof typeof externalReportNames]}
 *   ExternalReportNames
 */

export const maxRowsPerPageLimits = /** @type {const} */ ({
  FULL_PAGE: 12,
  HALF_PAGE: 4,
});
