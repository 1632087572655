import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import './DropdownOption.scss';

/**
 * For use with the Dropdown component, and anything that uses it.
 *
 * @example
 *   <DropdownOption
 *     id="some-option"
 *     disabled={false}
 *     className="DropdownOption-some-option"
 *   >
 *     Some Option
 *   </DropdownOption>;
 */
const DropdownOption = ({
  id,
  'data-testid': dataTestId,
  disabled,
  className,
  children,
  ...props
}) => {
  return (
    <button
      id={id}
      className={classNames('Dropdown_Option', className)}
      disabled={disabled}
      data-testid={dataTestId || id}
      {...props}
    >
      <span className="DropdownOption_ChildrenWrapper">{children}</span>
    </button>
  );
};

DropdownOption.propTypes = {
  /** The label or other contents of the button */
  'children': PropTypes.node.isRequired,
  /** Class(es) to apply to the dropdown option button */
  'className': PropTypes.string,
  /** Whether or not the dropdown option button is disabled */
  'disabled': PropTypes.bool,
  /** The ID for the dropdown option button */
  'id': PropTypes.string.isRequired,
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string,
};

export default DropdownOption;
