import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { subscribeToEmployeeUpdateAction } from '@/actions/employees';
import { BURN_RATE } from '@/cacheKeys';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import { chartTypes } from '@/components/Charts/constants';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import useChartQuery from '@/hooks/useChartQuery';
import useWsSubscription from '@/hooks/useWsSubscription';
import {
  BURN_RATE_METRICS,
  CHART_TITLE,
} from '@/pages/Dashboard/constants/burnRate';
import { getBurnRate } from '@/services/dashboard.service';

const reducer = ({ burnRates, totalBurnRate }) => {
  return {
    totalBurnRate,
    ...mapMonthlyData(burnRates, 'burnAmount'),
  };
};

/**
 * Fetches and renders a column chart showing monthly burn rate
 *
 * @example
 *   <BurnRateChart exportBtn={exportBtn} />;
 */
function BurnRateChart({
  className,
  exportBtn,
  plotOptions,
  axisStyles,
  tooltipOptions,
  onQueryStateChange,
}) {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );

  const scenarioQueries = useChartQuery(BURN_RATE, getBurnRate, reducer);

  const [base] = scenarioQueries;

  useEffect(
    () => {
      if (scenarioQueries.length) onQueryStateChange?.(scenarioQueries);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
    [scenarioQueries],
  );

  useWsSubscription(
    () =>
      dispatch(
        subscribeToEmployeeUpdateAction(scenarioId, () => {
          base.refetch();
        }),
      ),
    [scenarioId],
  );

  return (
    <DateChart
      className={className}
      data-testid="burnRateChart"
      loading={scenarioQueries.every(({ isLoading }) => isLoading)}
      plotOptions={plotOptions}
      axisStyles={axisStyles}
      tooltip={
        <ChartTooltip
          metrics={BURN_RATE_METRICS}
          timePeriod={timePeriod}
          endDate={endDate}
          {...tooltipOptions}
        />
      }
      ref={exportBtn}
      timePeriod={timePeriod}
      startDate={startDate}
      endDate={endDate}
    >
      {scenarioQueries.map(
        ({ data, scenario }, idx) =>
          data && (
            <DateChart.Series
              key={scenario.scenarioId}
              type={chartTypes.COLUMN}
              data={data.data}
              index={idx}
              isComparison={scenario?.scenarioId !== scenarioId}
              metric={CHART_TITLE}
              scenario={scenario}
            />
          ),
      )}
    </DateChart>
  );
}

export default BurnRateChart;
