import { useCallback, useEffect, useMemo, useState } from 'react';
import useIsFullFpnaProduct from '@/hooks/useIsFullFpnaProduct';
import useCustomChartsQuery from '@/pages/Dashboard/charts/useCustomChartsQuery';
import DEFAULT_LAYOUT, {
  CASHFLOW_LAYOUT,
} from '@/pages/Dashboard/defaultLayout';
import { CUSTOM_CHART, SYSTEM_CHART } from './constants';

/** @typedef {import('@/components/common/ChartList/types').Chart} Chart */
/** @typedef {import('@/components/common/ChartList/types').ChartTypes} ChartTypes */

/** @type {(chartList: Chart[], chartListType: ChartTypes) => Chart[]} */
const getChartList = (chartList = [], chartListType) =>
  chartList.map((chartInfo) => ({
    name: chartListType === SYSTEM_CHART ? chartInfo.name : chartInfo.title,
    key: chartInfo.key ?? chartInfo.id,
    chartInfo,
    type: chartListType,
  }));

const CHART_TYPES = [SYSTEM_CHART, CUSTOM_CHART];

const defaultSelectAll = {
  [SYSTEM_CHART]: false,
  [CUSTOM_CHART]: false,
};

const isSelectAllCharts = (charts, selectedCharts, chartType) => {
  return charts
    .filter(({ type }) => type === chartType)
    .every(({ key }) => Object.keys(selectedCharts).includes(key));
};

const useChartList = (savedCharts = []) => {
  const isFullFpnaProduct = useIsFullFpnaProduct();
  /** @type {ReturnType<typeof useState<Record<string, Chart>>>} */
  const [selectedCharts, setSelectedCharts] = useState({});
  const [isSelectAll, setIsSelectAll] = useState(defaultSelectAll);

  const systemCharts = useMemo(
    () =>
      getChartList(
        isFullFpnaProduct ? DEFAULT_LAYOUT : CASHFLOW_LAYOUT,
        SYSTEM_CHART,
      ),
    [isFullFpnaProduct],
  );
  /**
   * @type {(
   *   chartList: Chart,
   *   chartListType: ChartTypes,
   * ) => Record<string, Chart>}
   */
  const getSelectedCharts = useCallback(
    (chartList, chartListType) => {
      const savedChartsKeys = savedCharts.map(({ key }) => key);
      chartList.sort(
        (a, b) =>
          savedChartsKeys.indexOf(a.key) - savedChartsKeys.indexOf(b.key),
      );

      return chartList.reduce((accum, chartInfo) => {
        const { key } = chartInfo;
        const index = savedCharts.findIndex(
          (layoutItem) => key === layoutItem.key,
        );
        if (index === -1) {
          return accum;
        }
        const layoutItem = savedCharts[index];
        const item = {
          name: chartInfo.name ?? chartInfo.title,
          key,
          ...layoutItem,
          chartInfo,
          type: chartListType,
        };
        return { ...accum, [layoutItem.key]: item };
      }, {});
    },
    [savedCharts],
  );

  const chartsQuery = useCustomChartsQuery();

  const customCharts = useMemo(
    () => getChartList(chartsQuery.data, CUSTOM_CHART),
    [chartsQuery.data],
  );
  const charts = useMemo(
    () => [...customCharts, ...systemCharts],
    [customCharts, systemCharts],
  );

  useEffect(() => {
    const selectedSystemCharts = getSelectedCharts(systemCharts, SYSTEM_CHART);
    const selectedCustomCharts = getSelectedCharts(customCharts, CUSTOM_CHART);
    const allSelectedCharts = {
      ...selectedSystemCharts,
      ...selectedCustomCharts,
    };
    const isSelectAllObj = CHART_TYPES.reduce((accum, chartType) => {
      const isAllSelected = isSelectAllCharts(
        charts,
        allSelectedCharts,
        chartType,
      );
      accum[chartType] = isAllSelected;
      return accum;
    }, {});

    setSelectedCharts(allSelectedCharts);
    setIsSelectAll(isSelectAllObj);
  }, [charts, customCharts, systemCharts, getSelectedCharts]);
  return {
    charts,
    selectedCharts,
    isSelectAll,
    isLoading: chartsQuery.isLoading,
  };
};

export default useChartList;
