import InfoTooltip from '@/components/common/InfoTooltip';
import './RevenueDriverTooltip.scss';

const RevenueDriverTooltip = ({ isRevOnly = false, formulaForecastItem }) => (
  <InfoTooltip data-testid="revenueStreamDriverTooltip">
    <h6 className="RevenueStreamDriver_TooltipTitle">MoM Growth</h6>
    <p className="RevenueStreamDriver_TooltipText">
      Allows you to forecast month-over-month growth from a starting base
      amount.
    </p>
    <h6 className="RevenueStreamDriver_TooltipTitle">Custom Amount</h6>
    <p className="RevenueStreamDriver_TooltipText">
      Allows you to enter custom forecast values for each month.
    </p>
    {!isRevOnly && (
      <>
        <h6 className="RevenueStreamDriver_TooltipTitle">Marketing Spend</h6>
        <p className="RevenueStreamDriver_TooltipText">
          Allows you to forecast revenue based on conversions from marketing
          expense.
        </p>
        <h6 className="RevenueStreamDriver_TooltipTitle">Sales Conversion</h6>
        <p className="RevenueStreamDriver_TooltipText">
          Allows you to forecast revenue based on sales quotas.
        </p>
      </>
    )}
    <>
      <h6 className="RevenueStreamDriver_TooltipTitle">Custom Formula</h6>
      <p className="RevenueStreamDriver_TooltipText">
        This allows you to create a custom formula to forecast{' '}
        {formulaForecastItem}.
      </p>
    </>
  </InfoTooltip>
);

export default RevenueDriverTooltip;
