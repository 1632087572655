import Button from '@/components/common/Button';
import './Upsell.scss';

/**
 * @type {(props: {
 *   title: string;
 *   description: string;
 *   image: React.FC<React.SVGProps<SVGSVGElement>>;
 *   onActionClick?: () => void;
 * }) => React.ReactElement}
 */
const Upsell = ({ title, description, image: Image, onActionClick }) => {
  return (
    <div className="Upsell">
      <div className="Upsell_Wrapper">
        <div className="Upsell_Container">
          <div className="Upsell_Image" data-testid="upsell-image">
            <Image />
          </div>
          <div className="Upsell_Content">
            <h1 className="Upsell_Title" data-testid="upsell-title">
              {title}
            </h1>
            <p className="Upsell_Text" data-testid="upsell-description">
              {description}
            </p>
            {onActionClick && (
              <Button
                className="Upsell_ActionButton"
                data-testid="upsell-action-button"
                onClick={onActionClick}
              >
                Upgrade
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upsell;
