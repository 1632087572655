import {
  SET_EXPENSES_LIST,
  SET_EXPENSES_CLASSES,
  SET_ERROR,
  SET_ORGANIZE_EXPENSE_RESPONSE,
  SET_EXPENSES_BY_DEPT_CODE,
  SET_PAYROLL_EXPENSES,
  UPDATE_EXPENSE,
} from '@/actionTypes/expenses';
import { LOGOUT } from '@/actionTypes/shared';
import transformDataForGrid from '@/reducers/helpers/transformDataForGrid';

/**
 * @typedef {{
 *   payrollExpenses: import('@/pages/Employee/PayrollExpensesTable').ExpensePayroll[];
 *   hasAccountNumbers: boolean;
 *   error: string;
 *   expensesList: { expenses: unknown[] };
 *   expensesClasses: unknown[];
 *   departments: unknown[];
 *   saveOrganizeExpenseResponse: unknown;
 *   expensesByDepartCode: unknown[];
 *   expensesByClass: unknown[];
 * }} ExpenseState
 */

/** @type {ExpenseState} */
const INITIAL_STATE = {
  error: '',
  expensesList: { expenses: [] },
  expensesClasses: [],
  departments: [],
  saveOrganizeExpenseResponse: {},
  expensesByDepartCode: [],
  expensesByClass: [],
  hasAccountNumbers: false,
  payrollExpenses: [],
};

const getExpensesByClass = (expenses) => {
  let hasAccountNumbers = false;
  const expensesByClassName = expenses.reduce((accum, expense) => {
    const { expenseClassName } = expense;
    if (expense.expenseAccountNum) {
      hasAccountNumbers = true;
    }
    const result = {
      ...accum,
      [expenseClassName]: accum[expenseClassName]
        ? accum[expenseClassName].concat(expense)
        : [expense],
    };
    return result;
  }, {});
  return [expensesByClassName, hasAccountNumbers];
};

const sortAlphabeticallyByName = (x, y) => x.name.localeCompare(y.name);

const sortClassesAndDepts = (expenseClasses) => {
  return [...expenseClasses]
    .sort(sortAlphabeticallyByName)
    .map((expenseClass) => {
      const departments = [...expenseClass.departments].sort(
        sortAlphabeticallyByName,
      );
      return { ...expenseClass, departments };
    });
};

const flattenAndSortDepts = (expenseClasses) => {
  // Enrich departments to include its expenseClassId, which reduces the
  // amount of mapping / finding necessary in Expenses/Organize and
  // TheAddExpenseModal
  return expenseClasses
    .flatMap(({ id, departments }) =>
      departments.map((department) => ({ ...department, expenseClassId: id })),
    )
    .sort(sortAlphabeticallyByName);
};

/**
 * @type {(
 *   state: ExpenseState,
 *   action: { payload: unknown; type: string },
 * ) => ExpenseState}
 */
const expenses = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case SET_EXPENSES_LIST: {
      const flatExpenses = transformDataForGrid(payload.expenses);
      const [expensesByClass, hasAccountNumbers] =
        getExpensesByClass(flatExpenses);
      return {
        ...state,
        expensesList: { expenses: flatExpenses },
        expensesByClass,
        hasAccountNumbers,
      };
    }
    case SET_EXPENSES_CLASSES:
      return {
        ...state,
        expensesClasses: sortClassesAndDepts(payload),
        departments: flattenAndSortDepts(payload),
      };
    case SET_ERROR:
      return { ...state, error: /** @type {string} */ (payload) };
    case LOGOUT:
      return { ...INITIAL_STATE };
    case SET_ORGANIZE_EXPENSE_RESPONSE:
      return { ...state, saveOrganizeExpenseResponse: payload };
    case SET_EXPENSES_BY_DEPT_CODE:
      return {
        ...state,
        expensesByDepartCode: payload,
      };
    case SET_PAYROLL_EXPENSES:
      // eslint-disable-next-line no-case-declarations -- predates description requirement
      const payrollExpenses =
        /** @type {import('@/pages/Employee/PayrollExpensesTable').ExpensePayroll[]} */ (
          payload
        );
      return {
        ...state,
        payrollExpenses,
        hasAccountNumbers: payrollExpenses.some(
          (expense) => expense.expenseAccountNum,
        ),
      };
    case UPDATE_EXPENSE: {
      const list = [...state.expensesList.expenses];
      const expenseIdx = list.findIndex(({ id }) => id === payload.id);
      if (expenseIdx === -1 || list[expenseIdx].isUnsaved) return state;

      const { faulted, variableValueFaultType } = payload;
      list[expenseIdx] = {
        ...list[expenseIdx],
        faulted,
        variableValueFaultType,
      };

      return {
        ...state,
        expensesList: {
          expenses: list,
        },
      };
    }
    default:
      return state;
  }
};

export default expenses;
