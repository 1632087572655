import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { CUSTOM_REPORTS } from '@/cacheKeys';
import Checkbox from '@/components/common/Checkbox';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import {
  getExternalCellStyle,
  getStylesForExternalSpreadsheet,
} from '@/components/common/MonthlySpreadsheet/helpers';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import { excelCellClass } from '@/components/common/Spreadsheet/defaults';
import { getUnitFormatter } from '@/components/common/Spreadsheet/helpers';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import { registeredFeatureFlags } from '@/constants/features';
import { varianceText } from '@/constants/variables';
import { isEmptyOrNull } from '@/helpers/validators';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import transformDataForGrid from '@/reducers/helpers/transformDataForGrid';
import { getCustomReportByName } from '@/services/reports.service';
import cellRendererSelector from './cellRendererSelector';
import { GRID_OPTIONS } from './constants';

const CUSTOM_TITLE = 'title';
const SPREADSHEET_ID = 'custom-report';

const getCellStyle = ({ data, colDef, column }) => {
  const month = column.getParent().getGroupId();
  return colDef.field !== CUSTOM_TITLE
    ? data.months.find(({ date }) => date === month)?.style
    : undefined;
};

const cellClassRules = {
  [excelCellClass.BOLD]: (props) => {
    const style = getCellStyle(props);
    return style?.bold ?? props.data.style.bold;
  },
  [excelCellClass.UNDERLINED]: (props) => {
    const style = getCellStyle(props);
    return style?.underlined ?? props.data.style.underlined;
  },
  [excelCellClass.BORDER_BOTTOM]: (props) => {
    const style = getCellStyle(props);
    return style?.borderBottom ?? props.data.style.borderBottom;
  },
  [excelCellClass.ITALIC]: (props) => {
    const style = getCellStyle(props);
    return style ? style.italic : props.data.style.italic;
  },
  [excelCellClass.STRIKE_THROUGH]: (props) => {
    const style = getCellStyle(props);
    return style?.strikethrough ?? props.data.style.strikethrough;
  },
};

const valueFormatter = ({ data: { unit }, value }) =>
  !isEmptyOrNull(value) ? getUnitFormatter(unit)(value) : null;

const CustomReportsGrid = ({
  apiRef,
  title,
  showVarianceAmount,
  setShowVariancePercentage,
  showVariancePercentage,
  setShowVarianceAmount,
}) => {
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );
  const scenarioIds = useSelectedScenarioIds();

  const isShowPercentVarianceEnabled = useFeatureFlags(
    registeredFeatureFlags.SHOW_VARIANCE_PERCENT,
  );

  const { data: customReport, isLoading } = useQuery(
    [CUSTOM_REPORTS, title, scenarioIds, startDate, endDate, timePeriod],
    async () => {
      const result = await getCustomReportByName(
        startDate,
        endDate,
        scenarioIds,
        title,
        timePeriod,
      );
      return transformDataForGrid(result.data.data.children);
    },
    { staleTime: 60000 },
  );

  const colDef = useMemo(
    () => [
      {
        headerName: title,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: { suppressCount: true },
        field: CUSTOM_TITLE,
        headerComponent: HeaderRenderer,
        headerComponentParams: { enableExpandAll: true },
        minWidth: 300,
        showRowGroup: true,
        cellClassRules,
        cellStyle: ({ data: { style } }) => {
          return getStylesForExternalSpreadsheet(style);
        },
      },
    ],
    [title],
  );

  const hasComparison = scenarioIds.length > 1;
  return (
    <>
      <SpreadsheetToolbar editable={false}>
        <div className="SpreadsheetToolbar_ControlGroup">
          Options: <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
        </div>
        {hasComparison && (
          <>
            <Checkbox
              id="variance-toggle"
              checked={showVarianceAmount}
              className="SpreadsheetToolbar_ControlGroup"
              onChange={() => setShowVarianceAmount((prevState) => !prevState)}
            >
              {varianceText.SHOW_VARIANCE_AMOUNT}
            </Checkbox>
            {isShowPercentVarianceEnabled && (
              <Checkbox
                id="variance-toggle"
                checked={showVariancePercentage}
                className="SpreadsheetToolbar_ControlGroup"
                onChange={() =>
                  setShowVariancePercentage((prevState) => !prevState)
                }
              >
                {varianceText.SHOW_VARIANCE_PERCENTAGE}
              </Checkbox>
            )}
          </>
        )}
      </SpreadsheetToolbar>
      <div className="ReportsGrid">
        <MonthlySpreadsheet
          {...GRID_OPTIONS}
          ref={apiRef}
          columnDefs={colDef}
          data-testid={SPREADSHEET_ID}
          data={customReport}
          cellStyle={getExternalCellStyle}
          valueFormatter={valueFormatter}
          rendererSelector={cellRendererSelector}
          cellClassRules={cellClassRules}
          loading={isLoading}
          showVarianceAmount={showVarianceAmount}
          showVariancePercentage={showVariancePercentage}
          enableComparison
        />
      </div>
    </>
  );
};

export default CustomReportsGrid;
