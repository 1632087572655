// @ts-check
import { iconTypes, ACTUAL_ENDING_CASH, BEGINNING_CASH } from './actuals';

const FORECAST_MODAL_BASE_DATA_TEST_ID = 'cio-forecast-modal';
export const SPREADSHEET_ID = 'cash-in-out-grid';
export const DATA_TEST_ID_MODAL_BASE = `${FORECAST_MODAL_BASE_DATA_TEST_ID}-base`;
export const DATA_TEST_ID_SAVE_BTN = `${FORECAST_MODAL_BASE_DATA_TEST_ID}-saveBtn`;
export const DATA_TEST_ID_CANCEL_BTN = `${FORECAST_MODAL_BASE_DATA_TEST_ID}-cancelBtn`;
export const DATA_TEST_ID_3MTH_BTN = `${FORECAST_MODAL_BASE_DATA_TEST_ID}-3mthBtn`;
export const DATA_TEST_ID_6MTH_BTN = `${FORECAST_MODAL_BASE_DATA_TEST_ID}-6mthBtn`;
export const DATA_TEST_ID_12MTH_BTN = `${FORECAST_MODAL_BASE_DATA_TEST_ID}-12mthBtn`;
export const DATA_TEST_ID_CUSTOM_BTN = `${FORECAST_MODAL_BASE_DATA_TEST_ID}-customBtn`;
export const DATA_TEST_ID_CLOSE_BTN = `${FORECAST_MODAL_BASE_DATA_TEST_ID}-closeBtn`;
export const ID_APPLY_TO_ALL_CHECKBOX = `${FORECAST_MODAL_BASE_DATA_TEST_ID}-apply-to-all-checkbox`;
export const ID_FORMULA_FIELD = `${FORECAST_MODAL_BASE_DATA_TEST_ID}-formula-field`;
export const INVALID_FORMULA_MSG = 'There was an error validating the formula';
export const AUTO_FORECAST = 'DEFAULT';
export const CUSTOM_FORMULA_TYPE = 'CUSTOM';
export const SIX_MONTH_FORMULA_TYPE = 'MONTHS_6';
export const THREE_MONTH_FORMULA_TYPE = 'MONTHS_3';
export const TWELVE_MONTH_FORMULA_TYPE = 'MONTHS_12';
export const CASH_IN = 'Cash In';
export const CASH_OUT = 'Cash Out';
export const NET_CASHFLOW = 'Net Cashflow';
export const CASH_IN_TYPE = 'Inflow';
export const CASH_OUT_TYPE = 'Outflow';
export const ADJUSTMENTS = 'Adjustments';

const BEGINNING_CASH_ID = 'BeginningCash';
const NET_CASHFLOW_ID = 'NetCashflow';
const ENDING_CASH_ID = 'EndingCash';
export const TOTAL_CASH_IN_ID = 'TotalCashIn';
export const TOTAL_CASH_OUT_ID = 'TotalCashOut';

const DEFAULT_FORECAST_METHOD_TYPE = 'Auto Forecast';
const THREE_MONTH_FORECAST_METHOD_TYPE = '3 months rolling avg';
const SIX_MONTH_FORECAST_METHOD_TYPE = '6 months rolling avg';
const TWELVE_MONTH_FORECAST_METHOD_TYPE = '12 months rolling avg';
const CUSTOM_FORECAST_METHOD_TYPE = 'Custom';

export const ACCOUNT_TITLE = /** @type {const} */ ({
  NET_CASHFLOW: 'net cashflow',
});

/** @type {import('ag-grid-community').ExcelExportParams} */
export const CASH_IO_XL_PARAMS = {
  exportMode: 'xlsx',
  sheetName: 'Cash In & Out',
  fileName: 'cash-in-out.xlsx',
  columnKeys: ['accountNumber', 'name'],
};

export const TOTAL_ROWS = new Set([
  CASH_IN,
  CASH_OUT,
  ADJUSTMENTS,
  BEGINNING_CASH,
  ACTUAL_ENDING_CASH,
  NET_CASHFLOW,
]);

export const INFO_ICON_ROWS = new Set([ADJUSTMENTS, ENDING_CASH_ID]);

export const DEFAULT_ROWS = new Set([
  BEGINNING_CASH_ID,
  NET_CASHFLOW_ID,
  ENDING_CASH_ID,
  TOTAL_CASH_IN_ID,
  TOTAL_CASH_OUT_ID,
  ADJUSTMENTS,
]);

/**
 * enumeration of forecast methods as returned from the API, maps the backend
 * API value to the display text. They are based on the formula types
 */
export const FORECAST_METHODS = /** @type {const} */ ({
  DEFAULT: DEFAULT_FORECAST_METHOD_TYPE,
  [THREE_MONTH_FORMULA_TYPE]: THREE_MONTH_FORECAST_METHOD_TYPE,
  [SIX_MONTH_FORMULA_TYPE]: SIX_MONTH_FORECAST_METHOD_TYPE,
  [TWELVE_MONTH_FORMULA_TYPE]: TWELVE_MONTH_FORECAST_METHOD_TYPE,
  [CUSTOM_FORMULA_TYPE]: CUSTOM_FORECAST_METHOD_TYPE,
});

export const FORECAST_METHOD_TYPES = /** @type {const} */ ({
  [DEFAULT_FORECAST_METHOD_TYPE]: 'DEFAULT',
  [THREE_MONTH_FORECAST_METHOD_TYPE]: THREE_MONTH_FORMULA_TYPE,
  [SIX_MONTH_FORECAST_METHOD_TYPE]: SIX_MONTH_FORMULA_TYPE,
  [TWELVE_MONTH_FORECAST_METHOD_TYPE]: TWELVE_MONTH_FORMULA_TYPE,
  [CUSTOM_FORECAST_METHOD_TYPE]: CUSTOM_FORMULA_TYPE,
});

export const cashGridLegendCopy = /** @type {const} */ ({
  [iconTypes.EXTERNAL_SOURCE_GENERATED]:
    'These actuals have been pulled from your accounting integration. You can click on the icon to see the breakdown.',
  [iconTypes.USER_ENTERED]:
    'These values have been manually adjusted by the user.',
});

export const cashInOutMetrics = /** @type {const} */ ({
  ACTUAL_ENDING_CASH: ENDING_CASH_ID,
  ACTUAL_CASH_COLLECTIONS: TOTAL_CASH_IN_ID,
  ACTUAL_CASH_PAYMENTS: TOTAL_CASH_OUT_ID,
  BEGINNING_CASH: BEGINNING_CASH_ID,
  ACTUAL_NET_CASHFLOW: NET_CASHFLOW_ID,
  ACTUAL_ADJUSTMENTS: ADJUSTMENTS,
});
