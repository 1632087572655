// @ts-check
import Modal from '@/components/common/Modal';
import ChangeCompanyLink from './ChangeCompanyLink';
import './ModalLockout.scss';

/**
 * @typedef {{
 *   header: React.ReactElement;
 *   children: React.ReactNode;
 *   footer?: React.ReactElement;
 * }} ModalLockoutProps
 */

/** @type {(props: ModalLockoutProps) => React.ReactElement} */
const ModalLockout = ({ header, children, footer }) => {
  return (
    <Modal open data-testid="lockout-modal" className="ModalLockout">
      <div className="ModalLockout_ActionHeader">
        <ChangeCompanyLink />
      </div>
      <header className="ModalBase_Header">
        <h2 className="ModalLockout_Heading">{header}</h2>
      </header>
      <div className="ModalBase_Content">{children}</div>
      {footer && <footer className="ModalBase_Footer">{footer}</footer>}
    </Modal>
  );
};
export default ModalLockout;
