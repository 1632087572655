import {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CrossIcon from '@bill/cashflow.assets/cross';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AnimatePresence, m as motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import {
  copilotConfirmationTypes,
  setAiConversationMetadata,
  setCopilotWarningModal,
} from '@/actions/aiConversation';
import {
  publishMessage,
  subscribeToNewConversationTopic,
} from '@/actions/copilot';
import { setScenarioIdAction, swapScenarios } from '@/actions/scenario';
import { USER_PREFERENCE } from '@/cacheKeys';
import { messageTypes } from '@/components/Copilot/constants';
import PermissionModal from '@/components/Reports/ExportableReport/PermissionModal';
import AiFeedbackModal from '@/components/common/Feedback/AiFeedbackModal';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import { actions, subjects } from '@/constants/permissions';
import useNotificationContext from '@/contexts/useNotificationContext';
import { classNames } from '@/helpers';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import useOneColor from '@/hooks/useOneColor';
import usePermissions from '@/hooks/usePermissions';
import useTypedSelector from '@/hooks/useTypedSelector';
import useWsSubscription from '@/hooks/useWsSubscription';
import {
  sendUserFeedbackOnAISummary,
  getChatGPTPromptPermission,
  setChatGPTPromptPermission,
} from '@/services/reports.service';
import ChatContainer from './ChatContainer';
import ChatError from './ChatError';
import CopilotTextarea from './CopilotTextarea';
import { ReactComponent as ThumbsDown } from '@/assets/icons/svg/thumbs_down.svg';
import { ReactComponent as ThumbsUp } from '@/assets/icons/svg/thumbs_up.svg';
import './CopilotSidebar.scss';

const ANIM_TRANSITION = window.Cypress
  ? { duration: 0 }
  : {
      type: 'spring',
      mass: 0.35,
    };

const INTRODUCTORY_MESSAGE =
  'Hi. I’m your Finance CoPilot. I can give you insights on your expenses, revenue, metrics, and other financial data. How can I help? Please note that I’m currently experimental.';

/**
 * @typedef {{
 *   message?: string;
 *   isNewConversation: boolean;
 *   logTrailId?: string;
 *   error?: string;
 * }} ChatWebSocketDto
 */

/**
 * @typedef {ChatWebSocketDto & {
 *   messageType: import('@/components/Copilot/constants').MessageTypes;
 *   isLoading?: boolean;
 *   isFeedbackProvided?: boolean;
 *   messageRef: import('react').RefObject<HTMLDivElement>;
 * }} CopilotMessage
 */
/**
 * @type {(
 *   payload: ChatWebSocketDto,
 *   messageType: import('@/components/Copilot/constants').MessageTypes,
 *   isLoading?: boolean,
 * ) => CopilotMessage}
 */
const transformMessage = (payload, type, isLoading = false) => {
  return {
    ...payload,
    logTrailId: payload.logTrailId ?? uuidv4(),
    messageType: type,
    messageRef: createRef(),
    isLoading,
  };
};

/** @typedef {{ onClose: () => void; open: boolean; navExpanded: boolean }} CopilotSidebarParams */

/**
 * Renders the copilot sidebar
 *
 * @example
 *   <CopilotSidebar open={true} onClose={onCloseFn} navExpanded={true} />;
 *
 * @type {(params: CopilotSidebarParams) => React.ReactElement}
 */
const CopilotSidebar = ({ open, onClose, navExpanded }) => {
  const scenarioId = useTypedSelector(({ scenario }) => scenario.scenarioId);
  const userId = useTypedSelector(({ auth }) => auth.userInfo.userId);
  const companyId = useTypedSelector(
    ({ companies }) => companies.selectedCompanyId,
  );
  const conversationId = useTypedSelector(
    ({ aiConversation }) => aiConversation.copilot?.conversationId,
  );
  const showWarningModal = useTypedSelector(
    ({ aiConversation }) => aiConversation.copilot?.showWarningModal,
  );
  const warningModalConfirmationAction = useTypedSelector(
    ({ aiConversation }) =>
      aiConversation.copilot?.warningModalConfirmationAction,
  );
  const pendingScenarioId = useTypedSelector(
    ({ aiConversation }) => aiConversation.copilot?.pendingScenarioId,
  );
  const pendingCompanySwitcherLink = useTypedSelector(
    ({ aiConversation }) => aiConversation.copilot?.pendingCompanySwitcherLink,
  );

  const { pathname } = useLocation();
  const scenarioIdRef = useRef(scenarioId);
  const history = useHistory();

  const { READ, READ_WRITE } = actions;
  const { NON_DASHBOARD, EMPLOYEE_SETTINGS } = subjects;
  const nonDashboardPermissions = usePermissions(READ, NON_DASHBOARD, true);
  const employeePermissions = usePermissions(READ_WRITE, EMPLOYEE_SETTINGS);
  const copilotPermission = useMemo(
    () => nonDashboardPermissions && employeePermissions,
    [nonDashboardPermissions, employeePermissions],
  );

  const [
    isStartNewConversationButtonDisabled,
    setIsStartNewConversationButtonDisabled,
  ] = useState(false);
  const [error, setError] = useState('');
  const [showPermission, setShowPermission] = useState(false);

  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();

  /** @type {ReturnType<typeof useState<CopilotMessage[]>>} */
  const [messages, setMessages] = useState([]);

  const isRemoteSideNavEnabled = useOneColor();

  useBeforeUnload(!!conversationId);
  /**
   * @type ReturnType<typeof
   *   useState<import('./ChatContainer').FeedbackRating>>
   */
  const [feedbackRating, setFeedbackRating] = useState();
  const [feedbackText, setFeedbackText] = useState('');
  /** @type ReturnType<typeof useState<number>> */
  const [feedbackChatIndex, setFeedbackChatIndex] = useState();

  /** @type {import('./ChatContainer').OnFeedbackFunc} */
  const handleFeedback = useCallback((index, rating) => {
    setFeedbackRating(rating);
    setFeedbackChatIndex(index);
  }, []);

  const clearFeedback = useCallback(() => {
    setFeedbackRating(null);
    setFeedbackChatIndex(null);
    setFeedbackText('');
  }, []);

  const { data: userPreference, refetch: fetchUserPreference } = useQuery({
    queryKey: [USER_PREFERENCE, companyId],
    queryFn: async () => {
      const { data } = await getChatGPTPromptPermission(companyId);
      return data.data.preference;
    },
    staleTime: 30000,
  });

  const { mutate: sendFeedback, isLoading: isFeedbackLoading } = useMutation({
    mutationFn: sendUserFeedbackOnAISummary,
    /** @type {(params: import('axios').AxiosError<import('@/types/api').ApiResponse>>) => void} */
    onError: (err) => {
      clearFeedback();
      setError(err.response?.data?.error?.errorMessage ?? err.message);
    },
    onSuccess: () => {
      setMessages((prevMessages) => {
        prevMessages[feedbackChatIndex] = {
          ...prevMessages[feedbackChatIndex],
          isFeedbackProvided: true,
        };
        return [...prevMessages];
      });
      clearFeedback();
    },
  });

  const onStartNewConversation = useCallback(() => {
    dispatch(
      setAiConversationMetadata('copilot', {
        conversationId: uuidv4(),
      }),
    );
    setMessages([
      {
        logTrailId: uuidv4(),
        message: INTRODUCTORY_MESSAGE,
        isLoading: false,
        messageType: messageTypes.ASSISTANT,
        isNewConversation: true,
        messageRef: createRef(),
      },
    ]);
  }, [dispatch]);

  const startNewConversation = useCallback(() => {
    if (!userPreference?.chatGPTPromptPermissionGranted) {
      setShowPermission(true);
      return;
    }
    setError('');
    onStartNewConversation();
  }, [userPreference, onStartNewConversation]);

  const {
    mutate: setChatGPTPromptPermissionMutation,
    isLoading: isUserPreferenceLoading,
  } = useMutation(setChatGPTPromptPermission, {
    onSuccess: async () => {
      setShowPermission(false);
      onStartNewConversation();
      fetchUserPreference();
    },
  });

  /**
   * @type {(params: {
   *   preference: import('@/services/reports.service').getChatGPTPromptPermissionResponse;
   * }) => void}
   */
  const handleChatGPTPromptPermission = useCallback(
    (data) => {
      setChatGPTPromptPermissionMutation({ companyId, data });
    },
    [setChatGPTPromptPermissionMutation, companyId],
  );

  const handleStartNewConversation = useCallback(() => {
    if (conversationId) {
      dispatch(
        setCopilotWarningModal({
          showWarningModal: true,
          warningModalConfirmationAction:
            copilotConfirmationTypes.START_NEW_CONVERSATION,
        }),
      );
    } else {
      startNewConversation();
    }
  }, [conversationId, startNewConversation, dispatch]);

  const onCopilotWarningCancel = useCallback(() => {
    dispatch(setCopilotWarningModal({ showWarningModal: false }));
  }, [dispatch]);

  const onCopilotWarningConfirm = useCallback(() => {
    dispatch(
      setAiConversationMetadata('copilot', {
        conversationId: null,
        showWarningModal: false,
      }),
    );
    switch (warningModalConfirmationAction) {
      case 'CHANGE_SCENARIO_ID': {
        dispatch(setScenarioIdAction(pendingScenarioId));
        break;
      }
      case 'SWAP_SCENARIO_ID': {
        dispatch(swapScenarios());
        break;
      }
      case 'COMPANY_SWITCHER': {
        history.push(pendingCompanySwitcherLink);
        break;
      }
      default:
    }
  }, [
    dispatch,
    pendingScenarioId,
    pendingCompanySwitcherLink,
    history,
    warningModalConfirmationAction,
  ]);

  /**
   * This is checking for scenario changes. For the first time, we are setting
   * the scenarioId in the scenarioRef. Afterwards, if the selectedScenario
   * changes, we are updating the scenarioRef and clearing the chat history
   */
  useEffect(() => {
    if (!scenarioIdRef.current) {
      scenarioIdRef.current = scenarioId;
      return;
    }
    if (scenarioIdRef.current !== scenarioId) {
      scenarioIdRef.current = scenarioId;
      setMessages([]);
      dispatch(
        setAiConversationMetadata('copilot', {
          conversationId: null,
        }),
      );
    }
  }, [scenarioId, conversationId, dispatch]);

  const messageHandler = useCallback(
    (/** @type {ChatWebSocketDto} */ payload) => {
      setIsStartNewConversationButtonDisabled(false);

      if (payload.error) {
        setMessages((prevMessages) => {
          const lastMessage = prevMessages.at(-1);
          if (lastMessage?.isLoading) {
            prevMessages.pop();
          }
          return [...prevMessages];
        });
        setError(payload.error);
        return;
      }

      if (payload.message) {
        setMessages((prevMessages) => {
          const newMessage = transformMessage(payload, messageTypes.ASSISTANT);
          const lastMessage = prevMessages.at(-1);
          if (lastMessage?.isLoading) {
            prevMessages.pop();
          }
          /**
           * This is a hack to hide the chat if the AI response is being
           * generated and the user switches the scenario.
           */
          if (prevMessages.length === 0) {
            return [];
          }
          return prevMessages.concat([newMessage]);
        });
      }
    },
    [],
  );

  useWsSubscription(() => {
    if (conversationId) {
      dispatch(subscribeToNewConversationTopic(conversationId, messageHandler));
    }
  }, [conversationId]);

  // Get notification bar height and set it as margin-top for CopilotSidebar
  const { margin } = useNotificationContext();

  const feedbackVariantIcon = useMemo(
    () => (
      <span className="CopilotSidebar_FeedbackIcon">
        {feedbackRating === 'VeryUseful' ? <ThumbsUp /> : <ThumbsDown />}
      </span>
    ),
    [feedbackRating],
  );

  /** @type {(text: string, retry?: boolean) => void} */
  const handleSend = useCallback(
    (text, retry = false) => {
      dispatch(
        publishMessage(conversationId, {
          companyId,
          userId,
          scenarioId,
          message: text,
          isRetry: retry,
        }),
      );
      setMessages((prevMessages) => [
        ...prevMessages,
        transformMessage(
          {
            message: text,
            isNewConversation: false,
          },
          messageTypes.USER,
        ),
        transformMessage(
          {
            isNewConversation: false,
          },
          messageTypes.ASSISTANT,
          true,
        ),
      ]);
    },
    [setMessages, dispatch, conversationId, userId, companyId, scenarioId],
  );

  const submitFeedback = useCallback(async () => {
    await sendFeedback({
      rating: feedbackRating,
      comment: feedbackText,
      promptLogTrailId: messages[feedbackChatIndex].logTrailId,
      scenarioId,
      companyId,
    });
  }, [
    feedbackChatIndex,
    companyId,
    scenarioId,
    feedbackRating,
    feedbackText,
    messages,
    sendFeedback,
  ]);

  const lastMessage = messages.at(-1);

  useEffect(() => {
    onClose();
  }, [pathname, onClose]);

  useEffect(() => {
    if (open && !conversationId && copilotPermission) {
      handleStartNewConversation();
    }
  }, [open, conversationId, copilotPermission, handleStartNewConversation]);

  useEffect(() => {
    if (open && !copilotPermission) {
      onClose();
    }
  }, [copilotPermission, onClose, open]);

  const handleRetry = useCallback(() => {
    setError('');
    const lastUserMessage = messages.pop();
    setMessages([...messages]);
    handleSend(lastUserMessage?.message, true);
  }, [handleSend, messages]);

  return (
    <AnimatePresence>
      {open && (
        <>
          <motion.div
            className={classNames(
              'CopilotSidebar',
              isRemoteSideNavEnabled && 'RemoteSideNav-enabled',
              navExpanded && 'MainNavigation-expanded',
            )}
            aria-modal="true"
            transition={ANIM_TRANSITION}
            style={{ marginTop: margin }}
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
          >
            <header className="CopilotSidebar_Header">
              <div />
              <button
                className="CopilotSidebar_StartConversationButton"
                onClick={handleStartNewConversation}
                disabled={isStartNewConversationButtonDisabled}
              >
                Start a new conversation
              </button>
              <button
                className="Sidebar_CloseBtn"
                data-testid="copilot-sidebar-close-btn"
                onClick={onClose}
                aria-label="Close"
              >
                <CrossIcon className="CloseIcon" />
              </button>
            </header>
            <div className="CopilotSidebar_Container">
              <div className="CopilotSidebar_Content">
                <ChatContainer chat={messages} onFeedback={handleFeedback} />
                {error && (
                  <ChatError
                    onRetry={
                      lastMessage?.messageType === 'USER'
                        ? handleRetry
                        : handleStartNewConversation
                    }
                    startNewConversation={handleStartNewConversation}
                  />
                )}
              </div>
              <div className="CopilotSidebar_Footer">
                <div className="Form_Group">
                  <CopilotTextarea
                    disabled={!conversationId || error}
                    onSend={handleSend}
                    isLoading={lastMessage?.isLoading}
                  />
                </div>
                <div className="CopilotSidebar_Disclaimer">
                  Responses in this chat are generated with the assistance of AI
                  using third party services; this may display inaccurate or
                  offensive information that do not represent BILL’s view.
                </div>
              </div>
            </div>

            <AiFeedbackModal
              variant={feedbackRating}
              variantIcon={feedbackVariantIcon}
              open={!!feedbackRating}
              onClose={() => clearFeedback()}
              onChange={({ target }) => setFeedbackText(target.value)}
              text={feedbackText}
              onSave={submitFeedback}
              loading={isFeedbackLoading}
            />
          </motion.div>
          <PermissionModal
            onSave={handleChatGPTPromptPermission}
            isLoading={isUserPreferenceLoading}
            open={showPermission}
            onClose={() => setShowPermission(false)}
          />
        </>
      )}
      {showWarningModal && (
        <ModalConfirmation
          id="copilot-chat-end-confirmation-modal"
          title=""
          onCancel={onCopilotWarningCancel}
          onAction={onCopilotWarningConfirm}
          actionBtnTxt="Yes"
          cancelBtnTxt="No"
        >
          This will end this conversation and you won’t be able to retrieve the
          chat history. Continue?
        </ModalConfirmation>
      )}
    </AnimatePresence>
  );
};

export default CopilotSidebar;
