// @ts-check
export const AUTHENTICATION_SUCCEEDED = 'auth/AUTHENTICATION_SUCCEEDED ';
export const SET_USER_INFO = 'auth/SET_USER_INFO';
export const START_SCENARIO_USER_CREATION = 'auth/START_SCENARIO_USER_CREATION';
export const SET_IS_REFRESHING_TOKEN = 'auth/SET_IS_REFRESHING_TOKEN';
export const PERSIST_STATEFUL_ROUTE = 'auth/PERSIST_STATEFUL_ROUTE';
export const UNSET_STATEFUL_ROUTE = 'auth/UNSET_STATEFUL_ROUTE';
export const DISMISS_UNAUTHORIZED = 'auth/DISMISS_UNAUTHORIZED';
export const PERMISSIONS_CHANGED = 'auth/PERMISSIONS_CHANGED';
export const SET_TMX_SESSION_ID = 'auth/SET_TMX_SESSION_ID';
export const AUTHENTICATION_DECLINED_BY_TMX =
  'auth/AUTHENTICATION_DECLINED_BY_TMX ';
export const BILL_AUTHENTICATION_STARTED = 'auth/BILL_AUTHENTICATION_STARTED';
export const BILL_AUTHENTICATION_SUCCEEDED =
  'auth/BILL_AUTHENTICATION_SUCCEEDED';
export const BILL_AUTHENTICATION_FAILED = 'auth/BILL_AUTHENTICATION_FAILED';
