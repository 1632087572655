// @ts-check
import { useMemo } from 'react';
import NavLink from '@/components/common/NavLink';
import './NotificationPending.scss';

/**
 * Creates the notification type
 *
 * @example
 *   <NotificationPendingField
 *     text={'notification "link"'}
 *     linkPath="/settings"
 *     linkDelimiter="'"
 *   />;
 *
 * @type {(props: {
 *   text: string;
 *   linkPath: string;
 *   linkDelimiter?: string;
 * }) => React.ReactElement}
 */
const NotificationPendingField = ({ text, linkPath, linkDelimiter = '"' }) => {
  const [initialText, linkText, lastText] = useMemo(
    () => text.split(linkDelimiter),
    [text, linkDelimiter],
  );

  return (
    <>
      {initialText}
      <NavLink
        to={linkPath}
        className="NotificationPending_Link"
        data-testid="notification-navigation-link"
      >
        {linkText}
      </NavLink>
      {lastText}
    </>
  );
};

export default NotificationPendingField;
