import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  addCustomDepartmentAction,
  editCustomDepartmentAction,
} from '@/actions/settings';
import Button from '@/components/common/Button';
import FormField from '@/components/common/FormField';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';
import Select from '@/components/common/Select';
import { expenseClassTypes } from '@/constants/expenses';

const GENERAL_ADMINISTRATIVE = 1;

const TheAddEditDepartmentModal = ({
  expenseClasses,
  companyId,
  scenarioId,
  onClose,
  department,
  addCustomDepartment,
  editCustomDepartment,
}) => {
  const isEditMode = !!department.name;
  const [departmentName, setDepartmentName] = useState(department.name ?? '');
  const [selectedExpenseClass, setSelectedExpenseClass] = useState(
    department.expenseClassId ?? GENERAL_ADMINISTRATIVE,
  );
  const [errorMsg, setErrorMsg] = useState('');
  const [showWarningMsg, setShowWarningMsg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      if (isEditMode) {
        await editCustomDepartment(
          selectedExpenseClass,
          departmentName,
          companyId,
          scenarioId,
          department.id,
        );
      } else {
        await addCustomDepartment(
          selectedExpenseClass,
          departmentName,
          companyId,
          scenarioId,
        );
      }
      onClose();
    } catch (e) {
      setErrorMsg(e.response?.data?.error?.errorMessage || e.message);
      setIsLoading(false);
    }
  };

  return (
    <ModalBase
      id="add-custom-department-modal"
      onCancel={() => onClose()}
      header={
        <h2 className="ModalBase_Heading">
          {isEditMode ? 'Edit Custom Department' : 'Add Custom Department'}
        </h2>
      }
      footer={
        <>
          <Button
            className="Button Button-cancelLink"
            onClick={() => onClose()}
            data-testid="custom-department-cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            loading={isLoading}
            data-testid="custom-department-add-button"
            disabled={!departmentName}
          >
            {isEditMode ? 'Finish Edit' : 'Add'}
          </Button>
        </>
      }
    >
      <div>
        {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
        {!isEditMode && (
          <Alert variant="primary">
            Once you have created this custom department, you will be able to
            edit or delete it, as long as it has not been applied to a specific
            expense or employee at the time, either current or historic.
          </Alert>
        )}

        {showWarningMsg && (
          <Alert variant="warning">
            You will not be able to add employees to the department once you
            move it to the new category. Any existing employee will remain as
            is, but they will continue to be accounted for in different payroll
            calculations.
          </Alert>
        )}
        <div className="Form">
          <div className="Form_Group">
            <label htmlFor="select-expense-class" className="Label">
              Name of Category
            </label>
            <Select
              id="select-expense-class"
              name="expenseClass"
              value={selectedExpenseClass}
              onChange={({ target }) => {
                setSelectedExpenseClass(Number(target.value));
                if (isEditMode)
                  setShowWarningMsg(
                    expenseClasses.find(
                      (expenseClass) =>
                        expenseClass.id === Number(target.value),
                    ).type === expenseClassTypes.incomeStmtRelated,
                  );
              }}
            >
              {expenseClasses.map((expenseClass) => (
                <option value={expenseClass.id} key={expenseClass.id}>
                  {expenseClass.name}
                </option>
              ))}
            </Select>
          </div>
          <div className="Form_Group">
            <label htmlFor="department-name" className="Label">
              Name of Department
            </label>
            <FormField
              id="department-name"
              name="departmentName"
              value={departmentName}
              maxLength={50}
              onChange={({ target }) =>
                setDepartmentName(target.value.trimStart())
              }
            />
          </div>
        </div>
      </div>
    </ModalBase>
  );
};

const mapStateToProps = (state) => ({
  scenarioId: state.scenario.scenarioId,
  expenseClasses: state.expenses.expensesClasses,
  companyId: state.companies.selectedCompanyId,
});

export default connect(mapStateToProps, {
  addCustomDepartment: addCustomDepartmentAction,
  editCustomDepartment: editCustomDepartmentAction,
})(TheAddEditDepartmentModal);
