import {
  SET_PRICE_PLANS_LIST,
  SET_REVENUE_STREAM_TOTAL,
  SET_REVENUE_ERROR,
  SET_REVENUE_MODAL_ERROR,
  SET_FINANCING_SOURCE_LIST,
  SET_FINANCING_MODAL_ERROR,
  UPDATE_PLAN,
  HANDLE_STREAM_DATA_CHANGE,
  UPDATE_PRODUCTS_LIST,
  UPDATE_PLANS,
  CLEAR_STREAM,
  UPDATE_CUSTOM_REVENUE,
  SET_PRODUCT_REVENUE_LIST,
  SET_REVENUE_DETAILS_LIST,
  SET_REVENUE_STREAMS_LIST,
  ADD_NEW_PRICING_PLAN,
  ADD_NEW_PRODUCT,
  SET_PRODUCT_RECORD_TO_BE_EDIT,
  UPDATE_PRICING_PLAN,
  REMOVE_PRICING_PLAN_BY_INDEX,
  SET_REVENUE_STREAM_RECORD_TO_BE_EDIT,
  SET_ERROR,
  SET_REVENUE_STREAM_WITH_PLANS,
  SET_REFRESH_ACTUALS_ONLY,
} from '@/actionTypes/revenue';
import { subscribeToTopic } from '@/actions/shared';
// eslint-disable-next-line import/no-cycle -- predates description requirement
import {
  getProductRevenues,
  createFinancing,
  getFinancingSourceList,
  getProducts,
  getRevenueDetails,
  getRevenueStreamsList,
  getRevenueStreamTotal,
  updateFinancing,
  deleteFinancing,
  createOrUpdateRevenueStreamForSalesLed,
  createOrUpdateCustomFormulaRevenueStream,
  setExternalForTotalRevenue,
  refreshData,
} from '@/services/revenueService';
import { changeLoadingState } from './componentLoading';
import { getScenarioAction } from './scenario';

export const setProductPricingPlans = (productsList) => {
  const productPricingPlans = productsList.flatMap((p) =>
    p.pricingPlans.map((pricingPlan) => ({
      ...pricingPlan,
      productId: p.id,
      productName: p.name,
    })),
  );

  return {
    type: SET_PRICE_PLANS_LIST,
    payload: productPricingPlans,
  };
};

export const setRevenueSourceRecordToBeEdit = (revenueSourceRecord) => {
  return {
    type: SET_REVENUE_STREAM_RECORD_TO_BE_EDIT,
    payload: revenueSourceRecord,
  };
};

export const setRevenueStreamTotal = (revenueStreamTotal) => {
  return (dispatch) => {
    dispatch({
      type: SET_REVENUE_STREAM_TOTAL,
      payload: revenueStreamTotal,
    });
  };
};

export const setRevenueDetailsList = (revenueDetailsList) => {
  return {
    type: SET_REVENUE_DETAILS_LIST,
    payload: revenueDetailsList,
  };
};

export const setRevenueStreamsList = (revenueStreamsList) => {
  return {
    type: SET_REVENUE_STREAMS_LIST,
    payload: { revenueStreamsList },
  };
};

export const setRevenueModalError = (error) => {
  return {
    type: SET_REVENUE_MODAL_ERROR,
    payload: error,
  };
};

export const setFinancingModalErrorAction = (error) => {
  return {
    type: SET_FINANCING_MODAL_ERROR,
    payload: error,
  };
};

export const setRevenueError = (error) => {
  return {
    type: SET_REVENUE_ERROR,
    payload: error,
  };
};

export const getTotalRevenueAction = (startDate, endDate, scenarioId) => {
  return async (dispatch) => {
    dispatch(changeLoadingState('revenueList', true));
    try {
      const { data } = await getRevenueStreamTotal({
        startDate,
        endDate,
        scenarioId,
      });
      dispatch(setRevenueStreamTotal(data.data.total));
    } catch ({ response, message }) {
      dispatch(setRevenueError(response?.data?.error?.errorMessage || message));
    }
    dispatch(changeLoadingState('revenueList', false));
  };
};

export const getProductsAction = (scenarioId) => {
  return async (dispatch) => {
    try {
      const { data } = await getProducts(scenarioId);
      dispatch(setProductPricingPlans(data.data));
    } catch ({ response, message }) {
      dispatch(setRevenueError(response?.data?.error?.errorMessage || message));
    }
  };
};

export const getRevenueDetailsAction = (startDate, endDate, scenarioId) => {
  return async (dispatch) => {
    try {
      const { data } = await getRevenueDetails({
        startDate,
        endDate,
        scenarioId,
      });
      dispatch(setRevenueDetailsList(data.data));
    } catch ({ response, message }) {
      dispatch(setRevenueError(response?.data?.error?.errorMessage || message));
    }
  };
};

export const handleStreamDataChangeAction = (name, value) => {
  return {
    type: HANDLE_STREAM_DATA_CHANGE,
    payload: { name, value },
  };
};

export const updatePlanAction = (name, value, index) => {
  return {
    type: UPDATE_PLAN,
    payload: {
      name,
      value,
      index,
    },
  };
};

export const updateCustomRevenueAction = (value) => {
  return {
    type: UPDATE_CUSTOM_REVENUE,
    payload: value,
  };
};

export const updateProductsList = (product) => {
  const pricingPlans = product.pricingPlans.map((plan) => ({
    ...plan,
    productName: product.name,
  }));

  return {
    type: UPDATE_PRODUCTS_LIST,
    payload: {
      product,
      pricingPlans,
    },
  };
};

export const updatePlansAction = (plans) => {
  return {
    type: UPDATE_PLANS,
    payload: plans,
  };
};

export const clearStream = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_STREAM });
  };
};

export const getProductRevenuesList = (scenarioId, startDate, endDate) => {
  return async (dispatch) => {
    dispatch(changeLoadingState('productsRevenueList', true));
    try {
      const response = await getProductRevenues({
        startDate,
        endDate,
        scenarioId,
      });
      dispatch({
        type: SET_PRODUCT_REVENUE_LIST,
        payload: response.data.data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console -- predates description requirement
      console.log(e);
    } finally {
      dispatch(changeLoadingState('productsRevenueList', false));
    }
  };
};

/**
 * Retrieves a list of revenue streams for the given scenario with
 * forecast/actuals for the given date range
 *
 * @param {Object} params
 * @param {number} params.scenarioId ID of the scenario to which the revenue
 *   streams belong
 * @param {string} params.startDate First month of desired revenue stream data,
 *   in the format YYYY-MM
 * @param {string} params.endDate Last month of desired revenue stream data, in
 *   the format YYYY-MM
 * @param {number} params.lastUpdatedRevenueId ID of the last updated revenue
 *   stream object
 * @returns {Function} Dispatchable action
 */
export const getRevenueStreamsAction = ({
  scenarioId,
  startDate,
  endDate,
  lastUpdatedRevenueId,
}) => {
  return async (dispatch) => {
    dispatch(changeLoadingState('revenueList', true));
    try {
      const { data } = await getRevenueStreamsList({
        scenarioId,
        startDate,
        endDate,
      });
      dispatch({
        type: SET_REVENUE_STREAMS_LIST,
        payload: { revenueStreamsList: data.data, lastUpdatedRevenueId },
      });
    } finally {
      dispatch(changeLoadingState('revenueList', false));
    }
  };
};

export const addNewPricingPlan = (value) => {
  return {
    type: ADD_NEW_PRICING_PLAN,
    payload: value,
  };
};
export const handleNewProductDataChange = (name, value) => {
  return {
    type: ADD_NEW_PRODUCT,
    payload: { name, value },
  };
};

export const setProductRecordToBeEdit = (product) => {
  return {
    type: SET_PRODUCT_RECORD_TO_BE_EDIT,
    payload: product,
  };
};

export const updatePricingPlan = (index, pricingPlan) => {
  return {
    type: UPDATE_PRICING_PLAN,
    payload: { index, pricingPlan },
  };
};
export const removePricingPlanByIndex = (index) => {
  return {
    type: REMOVE_PRICING_PLAN_BY_INDEX,
    payload: { index },
  };
};

export const setError = (error) => {
  return {
    type: SET_ERROR,
    payload: error,
  };
};

export const getFinancingSourceListAction = ({
  scenarioId,
  startDate,
  endDate,
  updatedFinancingId,
}) => {
  return async (dispatch) => {
    try {
      const response = await getFinancingSourceList({
        startDate,
        endDate,
        scenarioId,
      });
      dispatch({
        type: SET_FINANCING_SOURCE_LIST,
        payload: {
          financingSourceList: response.data.data,
          lastUpdatedFinancingId: updatedFinancingId,
        },
      });
    } catch (e) {
      dispatch(setError(e.response?.data?.error?.errorMessage || e.message));
    }
  };
};

const setFinancing = (scenarioId, startDate, endDate, updatedFinancingId) => {
  return async (dispatch) => {
    dispatch(
      getFinancingSourceListAction({
        scenarioId,
        startDate,
        endDate,
        updatedFinancingId,
      }),
    );
    dispatch(getTotalRevenueAction(startDate, endDate, scenarioId));
  };
};

/**
 * Action that creates a new financing
 *
 * @param {Object} financing The financing record to be created
 * @param {number} scenarioId ID of the scenario to which the financing belongs
 * @returns {Function} dispatchable action
 */
export const createFinancingAction = (financing, scenarioId) => {
  return async (dispatch, getState) => {
    const { startDate, endDate } = getState().shared;
    await createFinancing(financing, scenarioId);
    dispatch(setFinancing(scenarioId, startDate, endDate));
  };
};

/**
 * Action that deletes the given financing
 *
 * @param {Object} financing New properties for the financing
 * @param {Object} params Param Object
 * @param {string} params.id ID of the financing
 * @param {number} params.scenarioId ID of the scenario to which the financing
 *   belongs
 * @returns {Function} dispatchable action
 */
export const updateFinancingAction = (financing, { id, scenarioId }) => {
  return async (dispatch, getState) => {
    const { startDate, endDate } = getState().shared;
    const response = await updateFinancing(financing, { id, scenarioId });
    dispatch(
      setFinancing(scenarioId, startDate, endDate, response.data.data?.id),
    );
  };
};

export const setRevenueStreamWithPlansAction = (revenueStream) => {
  return {
    type: SET_REVENUE_STREAM_WITH_PLANS,
    payload: revenueStream,
  };
};

/**
 * Action that deletes the given financing
 *
 * @param {string} id ID of the financing
 * @param {number} scenarioId ID of the scenario to which the financing belongs
 * @returns {Function} dispatchable action
 */
export const deleteFinancingAction = (scenarioId, id) => {
  return async (dispatch, getState) => {
    const { startDate, endDate } = getState().shared;
    await deleteFinancing(scenarioId, id);
    dispatch(getFinancingSourceListAction({ scenarioId, startDate, endDate }));
  };
};

/**
 * Creates or updates a revenue stream with a sales-led conversions driver
 *
 * @param {number} scenarioId ID of the scenario to which the revenue stream
 *   belongs
 * @param {Object} params Revenue stream to create or update
 * @returns {Function} dispatchable action
 */
export const createOrUpdateRevenueStreamForSalesLedAction = (
  scenarioId,
  params,
) => {
  return async (_dispatch, getState) => {
    const { startDate, endDate } = getState().shared;
    await createOrUpdateRevenueStreamForSalesLed(scenarioId, params);
    refreshData(scenarioId, startDate, endDate, true);
  };
};

export const createOrUpdateCustomFormulaRevenueStreamAction = (
  scenarioId,
  params,
) => {
  return async (_dispatch, getState) => {
    const { startDate, endDate } = getState().shared;
    await createOrUpdateCustomFormulaRevenueStream(scenarioId, params);
    refreshData(scenarioId, startDate, endDate, true);
  };
};

export const setExternalForTotalRevenueAction =
  (value) => async (dispatch, getState) => {
    const { scenario, shared } = getState();
    const { scenarioId } = scenario;
    const { startDate, endDate } = shared;
    try {
      await setExternalForTotalRevenue(value, scenarioId);
      dispatch(getScenarioAction());
      dispatch(getTotalRevenueAction(startDate, endDate, scenarioId));
    } catch ({ message, response }) {
      // eslint-disable-next-line no-console -- predates description requirement
      console.error(response?.data?.error?.errorMessage || message);
    }
  };

/**
 * Action that sets a flag to only update actuals after successful edit or
 * delete of a revenue stream
 *
 * @param {boolean} onlyActuals Whether only the actuals should be updated after
 *   updating a particular revenue stream
 * @returns {Function} dispatchable action
 */
export const updateActualsOnlyRevFlagAction = (onlyActuals) => {
  return (dispatch) => {
    dispatch({
      type: SET_REFRESH_ACTUALS_ONLY,
      payload: onlyActuals,
    });
  };
};

/**
 * Subscribe to revenue deal entries update socket event,to fetch updated
 * revenue entries
 *
 * @type {(
 *   scenarioId: number,
 *   callback: () => void,
 * ) => import('@reduxjs/toolkit').ThunkAction<any, any, any, any>}
 */

export const subscribeToFetchRevenueDealMappingAction = (
  scenarioId,
  callback,
) => {
  return (dispatch) => {
    return dispatch(
      subscribeToTopic(`revenue-deal-entries-updated/${scenarioId}`, () =>
        callback(),
      ),
    );
  };
};
