import MoreInfoIcon from '@bill/cashflow.assets/more-info';
import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import WithPopover from './WithPopover';
import './ControlDropdown.scss';

const MoreInfoBtn = ({ className }) => {
  return <MoreInfoIcon className={classNames('MoreInfoIcon', className)} />;
};

/**
 * Creates a dropdown for controls
 *
 * @example
 *   <ControlDropdown id="foo">
 *     <RadioGroupPersistent
 *       id="foo"
 *       value={123}
 *       options={[
 *         {
 *           Icon: SomeIcon,
 *           label: 'Option 1',
 *           value: 123,
 *         },
 *         {
 *           Icon: SomeOtherIcon,
 *           label: 'Option 2',
 *           value: 456,
 *         },
 *       ]}
 *     />
 *   </ControlDropdown>;
 */
function ControlDropdown({
  id,
  'data-testid': dataTestId,
  children,
  placement = 'bottom-start',
  Icon = MoreInfoBtn,
  'aria-label': ariaLabel,
}) {
  const testId = dataTestId || id;

  return (
    <div className="ControlDropdown" id={id}>
      <WithPopover
        className="Popover-toEdge"
        content={children}
        data-testid={testId}
        placement={placement}
      >
        <button
          className="ControlDropdown_Button"
          data-testid={`${testId}-dropdownButton`}
          type="button"
          aria-label={ariaLabel}
        >
          <Icon />
        </button>
      </WithPopover>
    </div>
  );
}

ControlDropdown.propTypes = {
  'aria-label': PropTypes.string,
  /** A unique ID */
  'id': PropTypes.string.isRequired,
  /** A unique ID for selecting elements in unit/integration tests */
  'data-testid': PropTypes.string,
  /** The elements to put inside the popover */
  'children': PropTypes.node.isRequired,
  /**
   * Placement of the popover.
   *
   * @see https://react-bootstrap.github.io/components/overlays/#popover-props
   */
  'placement': PropTypes.string,
  /** Icon to display on Widget Control */
  'Icon': PropTypes.func,
};

export default ControlDropdown;
