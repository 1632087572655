import { useState } from 'react';

const MAX_ITEMS = 3;

const VariableDependencySection = ({ section, dependencies }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="DependencySection">
      <p className="DependencySection_Title">{section}</p>
      <ul className="DependencySection_List">
        {dependencies
          .slice(0, showMore ? dependencies.length : MAX_ITEMS)
          .map((detail) => (
            <li key={detail} className="DependencySection_Detail">
              {detail}
            </li>
          ))}
        {!showMore && dependencies.length > MAX_ITEMS && (
          <button
            type="button"
            className="PreviewSection_MoreLink"
            data-testid={`${section}-showMore`}
            onClick={() => setShowMore(true)}
          >
            See more <strong>({dependencies.length - MAX_ITEMS})</strong>
          </button>
        )}
      </ul>
    </div>
  );
};

export default VariableDependencySection;
