// @ts-check
import Api, { ApiV2 } from './Api';

/**
 * @typedef {{
 *       scenarioId: number;
 *       scenarioIds?: never;
 *     }
 *   | {
 *       scenarioId?: never;
 *       scenarioIds: number[];
 *     }} ScenarioIdProp
 */

/**
 * @template T
 * @typedef {ScenarioIdProp & {
 *   startDate: string;
 *   endDate: string;
 *   timePeriod?: string;
 *   signal?: AbortSignal;
 * } & T} GetByDateRangeProps<T>
 */

/** @typedef {GetByDateRangeProps<{ [key: string]: any }>} GetByDateRangeDefault */

/**
 * Returns a service for making GET requests to the given URL for data within a
 * given range and scenario.
 *
 * @typedef {{ useApiV2?: boolean }} Options
 * @type {(
 *   path: string,
 *   options?: Options,
 * ) => (params: GetByDateRangeDefault) => Promise}
 */
export default function getByDateRange(path, { useApiV2 = true } = {}) {
  return ({ signal, ...params }) => {
    const ApiObject = useApiV2 ? ApiV2 : Api;
    return ApiObject.get(path, { signal, params });
  };
}
