import Highcharts from 'highcharts';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsPatternFill from 'highcharts/modules/pattern-fill';
import { white } from '@/components/Charts/colors';
import COLORS from '@/constants/colorPalette';
import { chartFonts } from './constants';
import { defaultTooltipPositioner } from './tooltips';

HighchartsAccessibility(Highcharts);
HighchartsPatternFill(Highcharts);

export const LABEL_STYLES = {
  color: COLORS.softBlack,
  fontFamily: chartFonts.FAMILY_NAME,
  fontWeight: 500,
  fontSize: '14px',
};

// Prevents data mutation when toggling between two data sets
// with the same length and x values
export const JSX_OPTIONS = { updatePoints: false };

export const DEFAULT_PIE_SIZE = '85%';

export const DEFAULT_FUNNEL_WIDTH = '40%';
export const DEFAULT_FUNNEL_NECK_WIDTH = '15%';

// Disable chart animation in CI
const animation = !window.Cypress;

export default function getHighchartsDefaults() {
  return {
    chart: {
      animation,
      backgroundColor: 'transparent',
      events: {
        render() {
          const { series } = this;
          series.forEach((aSeries) => {
            const { id } = aSeries.options;
            const seriesDataAttrs = aSeries.group.element.dataset;
            if (id && !seriesDataAttrs.testid) {
              seriesDataAttrs.testid = id;
            }
          });
        },
      },
      reflow: false,
      spacing: [0, 0, 0, 0],
    },
    exporting: {
      pdfFont: {
        normal: chartFonts.FAMILY_PATH,
        bold: chartFonts.FAMILY_PATH_BOLD,
      },
    },
    plotOptions: {
      area: {
        fillColor: {},
        zIndex: 1,
      },
      column: {
        borderColor: '#fff',
        groupPadding: 0.1,
        maxPointWidth: 36,
        pointPadding: 0.075,
        stacking: 'normal',
        states: {
          hover: { enabled: false },
          inactive: { enabled: false },
        },
      },
      funnel: {
        borderWidth: 0,
        neckWidth: DEFAULT_FUNNEL_NECK_WIDTH,
        states: {
          hover: { enabled: false },
          inactive: { enabled: false },
        },
        width: DEFAULT_FUNNEL_WIDTH,
      },
      bar: {
        states: {
          hover: { enabled: false },
          inactive: { enabled: false },
        },
      },
      pie: {
        borderColor: '#fff',
        dataLabels: {
          distance: 13,
        },
        size: DEFAULT_PIE_SIZE,
        states: {
          hover: { enabled: false },
          inactive: { enabled: false },
        },
      },
      series: {
        animation,
        clip: false,
        dataLabels: {
          padding: 2,
          softConnector: false,
          style: {
            ...LABEL_STYLES,
            width: '500px', // set an arbitrary high width to avoid overlap of labels with the pie chart
            direction: 'rtl',
            textOutline: 'none',
            textOverflow: 'ellipsis',
          },
          useHTML: true,
        },
        marker: {
          symbol: 'circle',
        },
      },
    },
    tooltip: {
      className: 'ChartTooltip',
      distance: 20,
      backgroundColor: 'transparent',
      borderWidth: 0,
      hideDelay: 250,
      outside: true,
      padding: 0,
      positioner: defaultTooltipPositioner,
      shadow: false,
      useHTML: true,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    xAxis: {
      labels: {
        style: LABEL_STYLES,
        useHTML: true,
      },
      maxPadding: 0.02,
    },
    yAxis: {
      gridLineColor: Highcharts.Color(COLORS.raisinBlack).tweenTo(white, 0.88),
      reversedStacks: false,
      labels: {
        style: LABEL_STYLES,
      },
      title: {
        margin: 22,
        style: {
          color: COLORS.raisinBlack,
        },
      },
    },
  };
}
