export function getRedirectUrl({
  token,
  name,
  id,
  integrationType,
  redirectTo,
}) {
  return `/api/v2/setup/add-company-integration/redirect?token=${token}&companyName=${name}&platformCompanyId=${id}&integrationType=${integrationType}&redirectTo=${redirectTo}`;
}

const integrationsRedirect =
  ({ selectedCompany, redirectTo, identityToken }) =>
  (integrationType) => {
    const { name, id } = selectedCompany;
    const formattedIntegrationType = integrationType.replace(/ /g, '_');
    const url = getRedirectUrl({
      token: identityToken,
      name,
      id,
      integrationType: formattedIntegrationType,
      redirectTo,
    });
    window.location.replace(url);
  };

export default integrationsRedirect;
