// @ts-check
import Checkbox from '@/components/common/Checkbox';
import Chip from '@/components/common/Chip';
import COLORS from '@/constants/colorPalette';
import { classNames } from '@/helpers';
import { TYPE_CATEGORY } from './constanst';

/**
 * @typedef {{
 *   tags: import('@/pages/Metrics/types').Tags[];
 *   category: string;
 *   onTagSelection: (prevState) => [string];
 *   tagsSelected: [string];
 *   tagsMap: import('@/pages/Metrics/types').TagsMap;
 *   typeSelected: [string];
 *   onTypeSelection: (prevState) => [string];
 * }} TagsListProps
 */

/** @type {(props: TagsListProps) => import('react').ReactElement} */
const TagsList = ({
  tags,
  category,
  onTagSelection,
  tagsSelected,
  tagsMap,
  typeSelected,
  onTypeSelection,
}) => {
  return (
    <>
      <h5 className="TagsList_Category">{category}</h5>
      {tags.map(({ id, name, UnitSymbol }) => (
        <Checkbox
          key={id}
          id={id}
          checked={
            category === TYPE_CATEGORY
              ? typeSelected.includes(name)
              : tagsSelected.includes(id)
          }
          onChange={({ target }) => {
            const onChangeAction =
              category === TYPE_CATEGORY ? onTypeSelection : onTagSelection;
            const item = category === TYPE_CATEGORY ? name : id;
            if (target.checked) {
              onChangeAction((prevState) => [...prevState, item]);
            } else {
              onChangeAction((prevState) =>
                prevState.filter((stateItem) => stateItem !== item),
              );
            }
          }}
        >
          {UnitSymbol && (
            <div className="TagsList_UnitWrapper">
              <UnitSymbol
                className={classNames('TagsList_Unit')}
                aria-label={name}
                data-testid={`${name}-unit`}
              />
            </div>
          )}
          {name}
          <Chip
            color={COLORS.grey6}
            className="TagsList_Chip"
            data-testid="metrics-tag-count"
          >
            {tagsMap?.[name] ?? 0}
          </Chip>
        </Checkbox>
      ))}
    </>
  );
};

export default TagsList;
