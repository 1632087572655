import { useCallback, useEffect } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getExpensesListByDepartmentCodeAction } from '@/actions/expenses';
import {
  clearStream,
  handleStreamDataChangeAction,
  setRevenueStreamWithPlansAction,
} from '@/actions/revenue';
import RevenueStreamFooter from '@/components/Revenue/RevenueStream/RevenueStreamFooter';
import FormField from '@/components/common/FormField';
import { FORMATTED_MONTHS } from '@/constants/Months';
import { REVENUE_API_NAME_MAX_LENGTH } from '@/constants/revenue';
import {
  DRIVER,
  ONE_TIME,
  ONE_TIME_CUSTOM_AMOUNT,
  ONE_TIME_MARKETING_SPEND_GROWTH,
  ONE_TIME_MOM_GROWTH,
  ONE_TIME_SALES_LED_GROWTH,
  ONE_TIME_CUSTOM_FORMULA,
  REVENUE_CUSTOM_AMOUNT,
  SUBSCRIPTION_REVENUE_CUSTOM_FORMULA,
  REVENUE_MOM_GROWTH,
  REVENUE_ONLY,
  REVENUE_ONLY_CUSTOM_FORMULA,
  SUBSCRIPTION,
  SUBSCRIPTION_CUSTOM_AMOUNT,
  SUBSCRIPTION_MARKETING_SPEND_GROWTH,
  SUBSCRIPTION_MOM_GROWTH,
  SUBSCRIPTION_SALES_LED_GROWTH,
  SUBSCRIPTION_ADVANCED_GROWTH,
  ADVANCED_SUBSCRIPTION,
} from '@/constants/revenueStream';
import { getFormattedDateFromTimeStamp } from '@/helpers/dateFormatter';
import { getRevenueSourceByID } from '@/services/revenueService';
import Type from './Type';

const getAssociatedPlans = (
  { revenueStreamProductsPricingPlanDtos },
  pricePlansList,
) => {
  return revenueStreamProductsPricingPlanDtos.map(
    ({ productPricingPlan, estimatedValue, id: planId }) => {
      const fetchedPricingPlan = pricePlansList.find(
        ({ id }) => Number(id) === Number(productPricingPlan.id),
      );
      return {
        ...productPricingPlan,
        estimatedValue,
        planId,
        isUsedInRevenueStream: fetchedPricingPlan
          ? fetchedPricingPlan.isUsedInRevenueStream
          : false,
        name: productPricingPlan.name,
      };
    },
  );
};

const StepOne = ({
  revenueStream,
  handleStreamDataChange,
  mode,
  recordIdForEdit,
  pricePlansList,
  scenarioId,
  setStep,
  step,
  saving,
  handleClose,
  showAddProductNotice,
  onAddProduct,
  setRevenueStreamWithPlans,
  revenueStreamRecordToBeEdit,
  getExpensesList,
}) => {
  useEffect(() => {
    if (mode === 'edit') {
      getRevenueSourceByID(recordIdForEdit, scenarioId);
    }
  }, [mode, recordIdForEdit, scenarioId]);

  const getAdvancedSubscriptionGrowthRevenue = useCallback(
    (baseRevenueStream) => {
      const { forecastStartDate, forecastEndDate, spec } =
        revenueStreamRecordToBeEdit;
      return {
        ...baseRevenueStream,
        ...spec,
        revenueType: ADVANCED_SUBSCRIPTION,
        startDate: new Date(forecastStartDate).getTime(),
        endDate: forecastEndDate ? new Date(forecastEndDate).getTime() : null,
        plansAssociated: getAssociatedPlans(
          revenueStreamRecordToBeEdit,
          pricePlansList,
        ),
      };
    },
    [pricePlansList, revenueStreamRecordToBeEdit],
  );

  const getSubscriptionMomGrowthRevenue = useCallback(
    (baseRevenueStream) => {
      return {
        ...baseRevenueStream,
        revenueType: SUBSCRIPTION,
        driverType: DRIVER.MONTHLY_GROWTH,
        plansAssociated: getAssociatedPlans(
          revenueStreamRecordToBeEdit,
          pricePlansList,
        ),
      };
    },
    [pricePlansList, revenueStreamRecordToBeEdit],
  );

  const getSubscriptionCustomRevenue = useCallback(
    (baseRevenueStream) => {
      return {
        ...baseRevenueStream,
        revenueType: SUBSCRIPTION,
        driverType: DRIVER.CUSTOM_GROWTH,
        plansAssociated: revenueStreamRecordToBeEdit.customValues.map(
          (plan) => {
            return { ...plan, id: plan.productPricingPlanId };
          },
        ),
      };
    },
    [revenueStreamRecordToBeEdit],
  );

  const getOneTimeMomRevenue = useCallback(
    (baseRevenueStream) => {
      return {
        ...baseRevenueStream,
        revenueType: ONE_TIME,
        driverType: DRIVER.MONTHLY_GROWTH,
        plansAssociated: getAssociatedPlans(
          revenueStreamRecordToBeEdit,
          pricePlansList,
        ),
      };
    },
    [revenueStreamRecordToBeEdit, pricePlansList],
  );

  const getRevenueOnlyMomRevenue = useCallback(
    (baseRevenueStream) => {
      return {
        ...baseRevenueStream,
        revenueType: REVENUE_ONLY,
        driverType: DRIVER.MONTHLY_GROWTH,
        monthlyGrowthRate:
          revenueStreamRecordToBeEdit.revenueOnlyMoMGrowth.monthlyGrowthRate,
        initialRevenueAmount:
          revenueStreamRecordToBeEdit.revenueOnlyMoMGrowth.initialRevenueAmount,
        startDate: revenueStreamRecordToBeEdit.revenueOnlyMoMGrowth.startDate,
        endDate:
          revenueStreamRecordToBeEdit.revenueOnlyMoMGrowth.endDate || null,
      };
    },
    [revenueStreamRecordToBeEdit],
  );

  const getRevenueOnlyCustomRevenue = useCallback(
    (baseRevenueStream) => {
      return {
        ...baseRevenueStream,
        revenueType: REVENUE_ONLY,
        driverType: DRIVER.CUSTOM_GROWTH,
        customRevenue: revenueStreamRecordToBeEdit.customRevenues.map(
          (year) => {
            return {
              ...year,
              months: year.months.map((month) => ({
                ...month,
                ...FORMATTED_MONTHS.find(
                  (monthObj) => monthObj.value === month.month.split('-')[1],
                ),
              })),
            };
          },
        ),
      };
    },
    [revenueStreamRecordToBeEdit],
  );

  const getOneTimeCustomRevenue = useCallback(
    (baseRevenueStream) => {
      return {
        ...baseRevenueStream,
        revenueType: ONE_TIME,
        driverType: DRIVER.CUSTOM_GROWTH,
        plansAssociated: revenueStreamRecordToBeEdit.customValues.map(
          (plan) => {
            return { ...plan, id: plan.productPricingPlanId };
          },
        ),
        acquisitionGrowthRate: revenueStreamRecordToBeEdit.monthlyGrowthRate,
        churnRate: revenueStreamRecordToBeEdit.annualChurnRate,
        startDate: getFormattedDateFromTimeStamp(
          revenueStreamRecordToBeEdit.forecastStartDate,
        ),
        endDate: revenueStreamRecordToBeEdit.forecastEndDate
          ? getFormattedDateFromTimeStamp(
              revenueStreamRecordToBeEdit.forecastEndDate,
            )
          : null,
      };
    },
    [revenueStreamRecordToBeEdit],
  );

  const getMarketingGrowth = useCallback(
    (baseRevenueStream, revenueType) => {
      getExpensesList({
        scenarioId,
        departmentCode: 'MARKETING',
        startDate: baseRevenueStream.startDate,
        endDate: baseRevenueStream.endDate,
      });
      return {
        ...baseRevenueStream,
        revenueType,
        driverType: DRIVER.MARKETING_SPEND,
        expenseGroupId:
          revenueStreamRecordToBeEdit.marketingSpendPayload.expenseGroupId,
        costPerLead:
          revenueStreamRecordToBeEdit.marketingSpendPayload.costPerLead,
        leadConversionRate:
          revenueStreamRecordToBeEdit.marketingSpendPayload.leadConversionRate,
        conversionTimeInMonth:
          revenueStreamRecordToBeEdit.marketingSpendPayload
            .conversionTimeInMonth,
        conversionOverTime:
          revenueStreamRecordToBeEdit.marketingSpendPayload.conversionOverTime,
        plansAssociated: getAssociatedPlans(
          revenueStreamRecordToBeEdit,
          pricePlansList,
        ),
      };
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [revenueStreamRecordToBeEdit, pricePlansList],
  );

  const getSalesLedGrowth = (baseRevenueStream, revenueType) => ({
    ...baseRevenueStream,
    ...revenueStreamRecordToBeEdit.salesDriverPayload,
    revenueType,
    driverType: DRIVER.SALES_LED,
    plansAssociated: getAssociatedPlans(
      revenueStreamRecordToBeEdit,
      pricePlansList,
    ),
  });

  const getRevenueStreamForCustomFormula = useCallback(
    (baseRevenueStream, revenueType) => ({
      ...baseRevenueStream,
      ...revenueStreamRecordToBeEdit.customFormulaPayload,
      revenueType,
      driverType: DRIVER.CUSTOM_FORMULA,
      plansAssociated:
        revenueType !== REVENUE_ONLY
          ? getAssociatedPlans(revenueStreamRecordToBeEdit, pricePlansList)
          : [],
    }),
    [revenueStreamRecordToBeEdit, pricePlansList],
  );

  const prepareRecordForEdit = useCallback(() => {
    if (revenueStreamRecordToBeEdit) {
      const baseRevenueStream = {
        id: revenueStreamRecordToBeEdit.id,
        revenueName: revenueStreamRecordToBeEdit.name,
        acquisitionGrowthRate: revenueStreamRecordToBeEdit.monthlyGrowthRate,
        churnRate: revenueStreamRecordToBeEdit.annualChurnRate,
      };

      if (
        revenueStreamRecordToBeEdit.revenueAttributionType !==
        REVENUE_MOM_GROWTH
      ) {
        baseRevenueStream.startDate = getFormattedDateFromTimeStamp(
          revenueStreamRecordToBeEdit.forecastStartDate,
        );
        baseRevenueStream.endDate = revenueStreamRecordToBeEdit.forecastEndDate
          ? getFormattedDateFromTimeStamp(
              revenueStreamRecordToBeEdit.forecastEndDate,
            )
          : null;
      }
      switch (revenueStreamRecordToBeEdit.revenueAttributionType) {
        case SUBSCRIPTION_ADVANCED_GROWTH:
          setRevenueStreamWithPlans(
            getAdvancedSubscriptionGrowthRevenue(baseRevenueStream),
          );
          break;
        case SUBSCRIPTION_MOM_GROWTH:
          setRevenueStreamWithPlans(
            getSubscriptionMomGrowthRevenue(baseRevenueStream),
          );
          break;
        case SUBSCRIPTION_CUSTOM_AMOUNT:
          setRevenueStreamWithPlans(
            getSubscriptionCustomRevenue(baseRevenueStream),
          );
          break;
        case ONE_TIME_MOM_GROWTH:
          setRevenueStreamWithPlans(getOneTimeMomRevenue(baseRevenueStream));
          break;
        case REVENUE_MOM_GROWTH:
          setRevenueStreamWithPlans(
            getRevenueOnlyMomRevenue(baseRevenueStream),
          );
          break;
        case REVENUE_CUSTOM_AMOUNT:
          setRevenueStreamWithPlans(
            getRevenueOnlyCustomRevenue(baseRevenueStream),
          );
          break;
        case ONE_TIME_CUSTOM_AMOUNT:
          setRevenueStreamWithPlans(getOneTimeCustomRevenue(baseRevenueStream));
          break;
        case ONE_TIME_MARKETING_SPEND_GROWTH:
          setRevenueStreamWithPlans(
            getMarketingGrowth(baseRevenueStream, ONE_TIME),
          );
          break;
        case ONE_TIME_SALES_LED_GROWTH:
          setRevenueStreamWithPlans(
            getSalesLedGrowth(baseRevenueStream, ONE_TIME),
          );
          break;
        case SUBSCRIPTION_MARKETING_SPEND_GROWTH:
          setRevenueStreamWithPlans(
            getMarketingGrowth(baseRevenueStream, SUBSCRIPTION),
          );
          break;
        case SUBSCRIPTION_SALES_LED_GROWTH:
          setRevenueStreamWithPlans(
            getSalesLedGrowth(baseRevenueStream, SUBSCRIPTION),
          );
          break;
        case SUBSCRIPTION_REVENUE_CUSTOM_FORMULA:
          setRevenueStreamWithPlans(
            getRevenueStreamForCustomFormula(baseRevenueStream, SUBSCRIPTION),
          );
          break;
        case ONE_TIME_CUSTOM_FORMULA:
          setRevenueStreamWithPlans(
            getRevenueStreamForCustomFormula(baseRevenueStream, ONE_TIME),
          );
          break;
        case REVENUE_ONLY_CUSTOM_FORMULA:
          setRevenueStreamWithPlans(
            getRevenueStreamForCustomFormula(baseRevenueStream, REVENUE_ONLY),
          );
          break;
        default:
          // eslint-disable-next-line no-console -- predates description requirement
          console.log('Invalid Subscription type');
      }
    }
    return false;
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [
    getMarketingGrowth,
    getOneTimeCustomRevenue,
    getOneTimeMomRevenue,
    getRevenueOnlyCustomRevenue,
    getRevenueOnlyMomRevenue,
    getSubscriptionCustomRevenue,
    getSubscriptionMomGrowthRevenue,
    getAdvancedSubscriptionGrowthRevenue,
    revenueStreamRecordToBeEdit,
    setRevenueStreamWithPlans,
    getRevenueStreamForCustomFormula,
  ]);

  useEffect(() => {
    if (mode === 'edit') {
      prepareRecordForEdit();
    }
  }, [mode, prepareRecordForEdit, revenueStreamRecordToBeEdit]);

  return (
    <Container fluid className="px-0 pt-2">
      <div className="inner-box rounded">
        <Row className="px-2">
          <Col className="text-left">
            <Row>
              <Col md={12}>
                <Form.Group className="">
                  <Form.Label className="Label">
                    Give your revenue stream (driver) a name
                  </Form.Label>
                  <Form.Group className="form-group-inline ml-0">
                    <FormField
                      type="text"
                      name="revenueName"
                      maxLength={REVENUE_API_NAME_MAX_LENGTH}
                      value={revenueStream.revenueName}
                      onChange={(e) => {
                        handleStreamDataChange(e.target.name, e.target.value);
                      }}
                      validate={() => {
                        return (
                          !revenueStream.revenueName &&
                          'Field should contain a valid name!'
                        );
                      }}
                      id="add-revenue-stream-name"
                      data-testid="add-revenue-stream-name"
                    />
                  </Form.Group>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {showAddProductNotice ? (
        <p className="ModalInfo_Text">
          Before you can continue, you must add a product which will drive this
          new revenue stream (driver).{' '}
          {[SUBSCRIPTION, ADVANCED_SUBSCRIPTION].includes(
            revenueStream.revenueType,
          )
            ? 'The product must have a recurring pricing plan.'
            : 'The product must have a pricing plan with a “One-time“ frequency.'}{' '}
          Please click the button below to be taken to the product creation
          screen.
        </p>
      ) : (
        <Type mode={mode} />
      )}
      <RevenueStreamFooter
        setStep={setStep}
        step={step}
        nextLoading={saving}
        disablePrevious
        revenueStream={revenueStream}
        handleClose={() => handleClose()}
        disableNext={!revenueStream.revenueName || !revenueStream.revenueType}
        nextText={showAddProductNotice && 'Add Product'}
        handleNext={revenueStream.revenueType !== REVENUE_ONLY && onAddProduct}
      />
    </Container>
  );
};
const mapStateToProps = (state) => ({
  revenueStream: state.revenues.revenueStream,
  pricePlansList: state.revenues.pricePlansList,
  scenarioId: state.scenario.scenarioId,
  revenueStreamRecordToBeEdit: state.revenues.revenueStreamRecordToBeEdit,
});

export default connect(mapStateToProps, {
  handleStreamDataChange: handleStreamDataChangeAction,
  setRevenueStreamWithPlans: setRevenueStreamWithPlansAction,
  getExpensesList: getExpensesListByDepartmentCodeAction,
  clearStream,
})(StepOne);
