import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';

/**
 * Returns the connected integrations
 *
 * @returns {Object[]} Array of connected integrations
 */
function useIntegrationsConnected() {
  const integrations = useSelector(({ settings }) => settings.integrations);
  return useMemo(
    () => integrations.filter(({ connected }) => connected),
    [integrations],
  );
}

export default useIntegrationsConnected;
