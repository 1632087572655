import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import QuestionMarkIcon from '@bill/cashflow.assets/question-mark';
import {
  handleStreamDataChangeAction,
  updatePlanAction,
} from '@/actions/revenue';
import {
  isAccumulatedPlanValuesValid,
  hasEndDate,
} from '@/components/Revenue/helpers';
import Checkbox from '@/components/common/Checkbox';
import FormLabel from '@/components/common/FormLabel';
import FormulaField from '@/components/common/FormulaField';
import FormulaValidationMessageWithLink from '@/components/common/FormulaValidationMessageWithLink';
import NumberField from '@/components/common/NumberField';
import VALID_FORMULA_MSG from '@/constants/formulas';
import { REVENUE_ONLY } from '@/constants/revenueStream';
import { isEmptyOrNull, isEndDateBeforeStartDate } from '@/helpers/validators';
import validateCustomFormula from '@/services/formula.service';
import DriverDateRange from './components/DriverDateRange';
import DriverPricingPlanList from './components/DriverPricingPlanList';
import './CustomFormulaDriverValues.scss';

const CustomFormulaDriverValues = ({
  revenue,
  revenueStream,
  onValid,
  handleStreamDataChange,
  updatePlan,
  pricingPlanItem,
  mode,
  onFormulaGuideClick,
  scenarioId,
}) => {
  const { plansAssociated, revenueType } = revenueStream;
  const [validationMsg, setValidationMsg] = useState('');
  const [isInitialValueToggled, setInitialValueToggled] = useState(
    !isEmptyOrNull(revenueStream.baseValue),
  );

  useEffect(() => {
    const isInitialBaseValueValid =
      (isInitialValueToggled && !isEmptyOrNull(revenueStream.baseValue)) ||
      (!isInitialValueToggled && isEmptyOrNull(revenueStream.baseValue));

    const isValid =
      revenueStream.startDate &&
      hasEndDate(revenueStream.noEndDate, revenueStream.endDate) &&
      !isEndDateBeforeStartDate(
        revenueStream.startDate,
        revenueStream.endDate,
      ) &&
      !isEmptyOrNull(revenueStream.formula) &&
      revenueStream.isFormulaValid &&
      isAccumulatedPlanValuesValid(revenueStream) &&
      isInitialBaseValueValid;
    onValid(isValid);
  }, [onValid, revenueStream, isInitialValueToggled]);

  useEffect(() => {
    if (mode !== 'edit') {
      handleStreamDataChange(
        'plansAssociated',
        plansAssociated.map((plan) => {
          return { ...plan, estimatedValue: 0 };
        }),
      );
      updatePlan('estimatedValue', 100, 0);
    }

    if (mode === 'edit') {
      // When a user opens the driver in "edit" mode, the formula is valid
      // because only valid formulas can be created in the first place and they
      // cannot become invalid between creation and "edit" mode
      handleStreamDataChange('isFormulaValid', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, []);
  return (
    <div>
      <DriverDateRange
        revenue={revenue}
        revenueStream={revenueStream}
        handleStreamDataChange={handleStreamDataChange}
      />

      <div className="Form_Group">
        <FormLabel
          htmlFor="initial-new-sub"
          text={`Initial ${
            revenueType === REVENUE_ONLY
              ? 'Revenue Amount'
              : `New ${pricingPlanItem}`
          }`}
          tooltip="Using an initial value will override the value generated by the
            formula for the first month of the revenue stream (driver)."
          optional
        />
        <Checkbox
          id="initial-new-sub"
          className="CustomFormulaDriverValues_NewSubCheckbox"
          checked={isInitialValueToggled}
          onChange={() => {
            if (isInitialValueToggled) {
              setInitialValueToggled(false);
              handleStreamDataChange('baseValue', null);
            } else {
              setInitialValueToggled(true);
            }
          }}
        >
          <NumberField
            min={0}
            id="base-value-field"
            placeholder={0}
            className="CustomFormulaDriverValues_NewSub"
            disabled={!isInitialValueToggled}
            allowNegativeValues={false}
            value={revenueStream.baseValue}
            onChange={(_, value) => handleStreamDataChange('baseValue', value)}
          />
        </Checkbox>
      </div>

      <div className="Form_Group">
        <div className="CustomFormulaDriver_Label">
          <label className="Label" htmlFor="formula-field">
            Custom Formula -{' '}
            {revenueType === REVENUE_ONLY
              ? 'Revenue Only'
              : `New ${pricingPlanItem}`}
          </label>
          <button
            type="button"
            className="Button-iconOnly HelpIconButtonWrapper"
            aria-label="Help"
            onClick={onFormulaGuideClick}
          >
            <QuestionMarkIcon className="HelpIcon" aria-hidden="true" />
          </button>
        </div>
        <div className="RevenueStream_Alert">
          {revenueType === REVENUE_ONLY
            ? `Custom formulas may generate positive or negative 
          ${pricingPlanItem.toLowerCase()} values.`
            : `All custom formulas created here will be used to generate new 
          ${pricingPlanItem.toLowerCase()}.`}
        </div>
        <FormulaField
          id="formula-field"
          value={revenueStream.formula}
          onChange={(value) => handleStreamDataChange('formula', value)}
          onBlur={async () => {
            const {
              data: { data },
            } = await validateCustomFormula(scenarioId, revenueStream.formula);
            setValidationMsg(data?.valid ? VALID_FORMULA_MSG : data.error);
            handleStreamDataChange('isFormulaValid', data?.valid);
          }}
          onFocus={() => {
            setValidationMsg('');
            handleStreamDataChange('isFormulaValid', false);
          }}
        />
        <FormulaValidationMessageWithLink message={validationMsg} />
      </div>
      {revenueType !== REVENUE_ONLY && (
        <DriverPricingPlanList
          plansAssociated={plansAssociated}
          revenue={revenue}
          updatePlan={updatePlan}
          heading={`How would you like to distribute your new ${pricingPlanItem.toLowerCase()} across pricing plans?`}
          showPricingPlanInPercentage
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ revenues, scenario }) => ({
  revenueStream: revenues.revenueStream,
  scenarioId: scenario.scenarioId,
});

export default connect(mapStateToProps, {
  handleStreamDataChange: handleStreamDataChangeAction,
  updatePlan: updatePlanAction,
})(CustomFormulaDriverValues);
