import CombinedActualsIcon from '@bill/cashflow.assets/combined-actuals';
import ExpenseOnlyIcon from '@bill/cashflow.assets/expense-only';
import PayrollOnlyIcon from '@bill/cashflow.assets/payroll-only';
import RevenueOnlyIcon from '@bill/cashflow.assets/revenue-only';
import IconButton from '@/components/common/IconButton';
import { actualsFamily, actualsText } from '@/constants/actuals';

function FilterButton({ family, onClick, ...props }) {
  return (
    <IconButton
      label={actualsText[family]}
      onClick={() => onClick(family)}
      {...props}
    />
  );
}

function FilterButtons({ filter, onFilterClick }) {
  return (
    <div className="SpreadsheetToolbar_ControlGroup">
      <span className="SpreadsheetToolbar_Label">View:</span>
      <FilterButton
        active={filter === actualsFamily.COMBINE}
        family={actualsFamily.COMBINE}
        Icon={CombinedActualsIcon}
        data-testid="combined-actuals"
        onClick={onFilterClick}
      />
      <FilterButton
        active={filter === actualsFamily.REVENUE}
        family={actualsFamily.REVENUE}
        Icon={RevenueOnlyIcon}
        data-testid="revenue-only-actuals"
        onClick={onFilterClick}
      />
      <FilterButton
        active={filter === actualsFamily.EXPENSE}
        family={actualsFamily.EXPENSE}
        Icon={ExpenseOnlyIcon}
        data-testid="expense-only-actuals"
        onClick={onFilterClick}
      />
      <FilterButton
        active={filter === actualsFamily.PAYROLL}
        family={actualsFamily.PAYROLL}
        Icon={PayrollOnlyIcon}
        data-testid="payroll-only-actuals"
        onClick={onFilterClick}
      />
    </div>
  );
}

export default FilterButtons;
