// @ts-check
import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import SwapIcon from '@bill/cashflow.assets/swap';
import {
  copilotConfirmationTypes,
  setCopilotWarningModal,
} from '@/actions/aiConversation';
import { toggleEditMode } from '@/actions/dashboard';
import {
  setScenarioIdAction,
  setCompareScenarioIdAction,
  swapScenarios,
} from '@/actions/scenario';
import ScenarioSelect from '@/components/Scenarios/ScenarioSelect';
import { NO_COMPARISON, SCENARIO_ID } from '@/constants/scenario';
import { classNames } from '@/helpers';
import './ScenarioSelect.scss';

/**
 * Renders the scenario select options in the page header
 *
 * @example
 *   <SecnarioSelection />;
 *
 * @type {() => React.ReactElement}
 */
const ScenarioSelection = () => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();

  const { scenarios, scenarioId, compareScenarioId, scenarioLoader } =
    useSelector(
      (/** @type {import('@/store').RootState} */ { scenario }) => scenario,
    );

  const copilotConversationId = useSelector(
    (/** @type {import('@/store').RootState} */ { aiConversation }) =>
      aiConversation.copilot?.conversationId,
  );

  const history = useHistory();
  const location = useLocation();

  const disableSwap =
    scenarioId === compareScenarioId || compareScenarioId === NO_COMPARISON;

  /** @type {(scenarioId) => void} */
  const handleScenarioChange = useCallback(
    (selectedScenarioId) => {
      if (copilotConversationId) {
        dispatch(
          setCopilotWarningModal({
            showWarningModal: true,
            warningModalConfirmationAction:
              copilotConfirmationTypes.CHANGE_SCENARIO_ID,
            pendingScenarioId: selectedScenarioId,
          }),
        );
      } else {
        dispatch(setScenarioIdAction(selectedScenarioId));
      }
    },
    [copilotConversationId, dispatch],
  );

  const handleSwapScenario = useCallback(() => {
    if (copilotConversationId) {
      setCopilotWarningModal({
        showWarningModal: true,
        warningModalConfirmationAction:
          copilotConfirmationTypes.SWAP_SCENARIO_ID,
      });
    } else {
      dispatch(swapScenarios());
    }
  }, [copilotConversationId, dispatch]);

  return (
    <>
      <div>
        <p className="ScenarioSelect_Header">scenarios</p>
      </div>
      <div className="ScenarioSelect_Container">
        <ScenarioSelect
          id="scenario-selector"
          scenarios={scenarios}
          selectedScenarioId={scenarioId}
          setScenarioId={handleScenarioChange}
          scenarioLoader={scenarioLoader}
          scenarioSelectType="base"
        />
        <div className="ScenarioSwap_Wrapper">
          <SwapIcon
            className={classNames(
              'ScenarioSwap_Icon',
              disableSwap && 'ScenarioSwap_Icon-disabled',
            )}
            onClick={() => {
              const queryParams = new URLSearchParams(location.search);
              queryParams.set(SCENARIO_ID, compareScenarioId.toString());
              history.replace({ search: `?${queryParams.toString()}` });
              handleSwapScenario();
              dispatch(toggleEditMode(false));
            }}
          />
        </div>
        <ScenarioSelect
          id="scenario-comparison-selector"
          scenarios={scenarios}
          selectedScenarioId={compareScenarioId}
          setScenarioId={(selectedCompareId) =>
            dispatch(setCompareScenarioIdAction(selectedCompareId))
          }
          scenarioLoader={scenarioLoader}
          scenarioSelectType="compare"
        />
      </div>
    </>
  );
};

export default ScenarioSelection;
