// @ts-check
import { useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import PositionIcon from '@bill/cashflow.assets/position';
import { FloatingFocusManager, useFloating } from '@floating-ui/react';
import { AnimatePresence, m as motion } from 'framer-motion';
import { userPreferencesAction } from '@/actions/auth';
import ModalPortal from '@/components/common/ModalPortal';
import useGlobalTopNavContext from '@/contexts/useGlobalTopNavContext';
import { classNames } from '@/helpers';
import './Sidebar.scss';

const ANIM_TRANSITION = window.Cypress
  ? { duration: 0 }
  : {
      type: 'spring',
      mass: 0.1,
    };

const CHART_SIDEBAR_ALIGNMENT = 'ChartBuilder-alignment';
const RIGHT = 'right';
const LEFT = 'left';
const TAB_INDEX = -1;

/**
 * @type {(props: {
 *   open: boolean;
 *   onClose: () => void;
 *   children: React.ReactNode;
 *   className: string;
 *   style?: React.CSSProperties;
 * }) => React.ReactElement}
 */
const Sidebar = ({ open, onClose, children, className, style }) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const userPreferences = useSelector(({ auth }) => auth.preferences);
  const panelPosition = userPreferences[CHART_SIDEBAR_ALIGNMENT] ?? RIGHT;

  const animatePosition = useMemo(
    () => (panelPosition === LEFT ? { x: '-100%' } : { x: '100%' }),
    [panelPosition],
  );

  const { context, floating } = useFloating();

  // Get global top nav bar height and set it as margin-top for Sidebar
  const { margin } = useGlobalTopNavContext();

  useEffect(() => {
    if (open) {
      document.body.classList.add('Body-hasModal');
    }
    return () => document.body.classList.remove('Body-hasModal');
  }, [open]);

  return (
    <ModalPortal>
      <AnimatePresence>
        {open && (
          <motion.div
            className={classNames(
              className,
              'Sidebar_Container',
              panelPosition === LEFT && 'Sidebar_Container-alignLeft',
            )}
            aria-modal="true"
            transition={ANIM_TRANSITION}
            style={{ ...style, marginTop: margin }}
          >
            <FloatingFocusManager context={context} returnFocus={false}>
              <motion.div
                ref={floating}
                id="Sidebar-dialog"
                className="Sidebar_Dialog"
                style={{ height: `calc(100vh - ${margin}px)` }}
                transition={ANIM_TRANSITION}
                animate={{ x: 0 }}
                initial={animatePosition}
                exit={animatePosition}
                onKeyUp={({ key }) => key === 'Escape' && onClose()}
                tabIndex={TAB_INDEX}
              >
                <div className="SidebarPositioner_Container">
                  <button
                    className="SidebarPositioner_Button"
                    onClick={() => {
                      dispatch(
                        userPreferencesAction({
                          [CHART_SIDEBAR_ALIGNMENT]:
                            panelPosition === RIGHT ? LEFT : RIGHT,
                        }),
                      );
                    }}
                    aria-label={`Move sidebar to ${
                      panelPosition === RIGHT ? 'left' : 'right'
                    } side`}
                  >
                    <PositionIcon className="SidebarPositioner_Icon" />
                  </button>
                </div>
                {children}
              </motion.div>
            </FloatingFocusManager>
          </motion.div>
        )}
      </AnimatePresence>
    </ModalPortal>
  );
};

export default Sidebar;
