import { useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  createOrUpdateCustomFormulaRevenueStreamAction,
  createOrUpdateRevenueStreamForSalesLedAction,
  handleStreamDataChangeAction,
  updatePlanAction,
  updatePlansAction,
} from '@/actions/revenue';
import RevenueStreamFooter from '@/components/Revenue/RevenueStream//RevenueStreamFooter';
import RevenueDriverTooltip from '@/components/Revenue/RevenueStream/RevenueDriverTooltip';
import Select from '@/components/common/Select';
import {
  ONE_TIME,
  STREAMS,
  SUBSCRIPTION,
  DRIVER,
} from '@/constants/revenueStream';
import { getNextText } from '@/helpers';
import {
  createCustomRevenueSourceForOneTime,
  createCustomRevenueSourceForSubscription,
  createCustomRevenueSourceForMarketingSpend,
  updateCustomRevenueSourceForOneTime,
  updateCustomRevenueSourceForSubscription,
  updateCustomRevenueSourceForMarketingSpend,
  createOneTimeSourceForMarketingSpend,
  updateOneTimeSourceForMarketingSpend,
} from '@/services/revenueService';
import CustomFormulaDriverValues from './Drivers/CustomFormulaDriverValues';
import CustomGrowthDriverValues from './Drivers/CustomGrowthDriverValues';
import MarketingLedDriverValues from './Drivers/MarketingLedDriverValues';
import MonthlyGrowthDriverValues from './Drivers/MonthlyGrowthDriverValues';
import SalesLedDriverValues from './Drivers/SalesLedDriverValues';

const mapDrivers = (driver) => (
  <option value={driver.type} key={driver.id}>
    {driver.label}
  </option>
);

const StepThree = ({
  createOrUpdateRevenueStreamForSalesLed,
  createOrUpdateCustomFormulaRevenueStream,
  handleStreamDataChange,
  updatePlan,
  updatePlans,
  mode,
  setStep,
  step,
  handleClose,
  revenue,
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
  scenario,
  onFormulaGuideClick,
  onSaved,
}) => {
  const { plansAssociated, driverType, startDate, endDate } = revenueStream;
  const [saving, setSaving] = useState(false);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [error, setError] = useState('');

  const {
    heading: revenueTypeHeading,
    drivers: revenueTypeDrivers,
    pricingPlanItem,
  } = STREAMS[revenueStream.revenueType];

  const handleMarketingLed = (params) => {
    switch (true) {
      case revenueStream.revenueType === SUBSCRIPTION && mode === 'edit':
        return updateCustomRevenueSourceForMarketingSpend(params);
      case revenueStream.revenueType === SUBSCRIPTION:
        return createCustomRevenueSourceForMarketingSpend(params);
      case revenueStream.revenueType === ONE_TIME && mode === 'edit':
        return updateOneTimeSourceForMarketingSpend(params);
      case revenueStream.revenueType === ONE_TIME:
        return createOneTimeSourceForMarketingSpend(params);
      default:
        throw new Error('Invalid Marketing Led Revenue Type');
    }
  };

  const handleSubmit = async () => {
    const params = {
      scenarioId,
      revenueStream,
      globalStartDate,
      globalEndDate,
    };
    try {
      setSaving(true);
      setError('');
      switch (true) {
        case revenueStream.driverType === DRIVER.MARKETING_SPEND:
          await handleMarketingLed(params);
          break;
        case revenueStream.driverType === DRIVER.SALES_LED:
          await createOrUpdateRevenueStreamForSalesLed(scenarioId, params);
          break;
        case revenueStream.driverType === DRIVER.CUSTOM_FORMULA:
          await createOrUpdateCustomFormulaRevenueStream(scenarioId, params);
          break;
        case revenueStream.revenueType === SUBSCRIPTION && mode === 'edit':
          await updateCustomRevenueSourceForSubscription(params);
          break;
        case revenueStream.revenueType === SUBSCRIPTION:
          await createCustomRevenueSourceForSubscription(params);
          break;

        case revenueStream.revenueType === ONE_TIME && mode === 'edit':
          await updateCustomRevenueSourceForOneTime(params);
          break;

        case revenueStream.revenueType === ONE_TIME:
          await createCustomRevenueSourceForOneTime(params);
          break;
        default:
      }
      onSaved?.();
      handleClose();
    } catch (e) {
      setError(e.response?.data?.error?.errorMessage || e.message);
    } finally {
      setSaving(false);
    }
  };

  const renderDriverValues = (driverValue) => {
    switch (driverValue) {
      case DRIVER.MONTHLY_GROWTH:
        return (
          <MonthlyGrowthDriverValues
            revenue={revenue}
            revenueStream={revenueStream}
            startDate={startDate}
            endDate={endDate}
            handleStreamDataChange={handleStreamDataChange}
            plansAssociated={plansAssociated}
            updatePlan={updatePlan}
            setIsStreamValid={setIsNextEnabled}
          />
        );
      case DRIVER.CUSTOM_GROWTH:
        return (
          <CustomGrowthDriverValues
            label={
              revenueStream.revenueType === ONE_TIME
                ? 'Number of Purchases'
                : 'Number of Customers'
            }
            products={plansAssociated}
            updateProduct={updatePlan}
            updateProducts={updatePlans}
            scenario={scenario}
            scenarioId={scenarioId}
            setIsStreamValid={setIsNextEnabled}
          />
        );
      case DRIVER.MARKETING_SPEND:
        return (
          <MarketingLedDriverValues
            revenue={revenue}
            setIsStreamValid={setIsNextEnabled}
          />
        );
      case DRIVER.SALES_LED:
        return (
          <SalesLedDriverValues
            revenueStream={revenueStream}
            onValid={setIsNextEnabled}
          />
        );
      case DRIVER.CUSTOM_FORMULA:
        return (
          <CustomFormulaDriverValues
            pricingPlanItem={pricingPlanItem}
            mode={mode}
            revenue={revenue}
            onFormulaGuideClick={onFormulaGuideClick}
            onValid={setIsNextEnabled}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container fluid className="px-2 pt-3">
      {error && (
        <p className="SubscriptionRevenue_ErrorAlert">
          <b>Error </b> - {error}
        </p>
      )}
      <div className="Form">
        <div className="Form_Group">
          <p className="Form_Desc">{revenueTypeHeading}</p>
          <label htmlFor="driverType" className="Label">
            Forecast Method
            <RevenueDriverTooltip
              formulaForecastItem={
                revenueStream.revenueType === SUBSCRIPTION
                  ? 'new subscribers'
                  : 'values'
              }
            />
          </label>
          <Select
            id="driverType"
            name="driverType"
            value={driverType}
            onChange={(event) =>
              handleStreamDataChange(event.target.name, event.target.value)
            }
            disabled={mode === 'edit'}
          >
            {revenueTypeDrivers.map(mapDrivers)}
          </Select>
        </div>
        <div className="Form_Group">{renderDriverValues(driverType)}</div>
      </div>
      <RevenueStreamFooter
        setStep={setStep}
        step={step}
        nextLoading={saving}
        disablePrevious
        disableNext={!isNextEnabled}
        revenueStream={revenueStream}
        handleClose={() => handleClose()}
        handleNext={driverType !== DRIVER.MONTHLY_GROWTH && handleSubmit}
        nextText={driverType !== DRIVER.MONTHLY_GROWTH && getNextText(mode)}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  revenueStream: state.revenues.revenueStream,
  scenarioId: state.scenario.scenarioId,
  globalStartDate: state.shared.startDate,
  globalEndDate: state.shared.endDate,
  scenario: state.scenario,
});

export default connect(mapStateToProps, {
  createOrUpdateRevenueStreamForSalesLed:
    createOrUpdateRevenueStreamForSalesLedAction,
  createOrUpdateCustomFormulaRevenueStream:
    createOrUpdateCustomFormulaRevenueStreamAction,
  handleStreamDataChange: handleStreamDataChangeAction,
  updatePlan: updatePlanAction,
  updatePlans: updatePlansAction,
})(StepThree);
