import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Chart, HighchartsChart, XAxis, YAxis } from 'react-jsx-highcharts';
import PropTypes from 'prop-types';
import ChartRefContainer from '@/components/Charts/ChartRefContainer';
import { getXAxisConfig, getYAxisConfig } from '@/components/Charts/helpers';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { ERROR_BOUNDARY_TEXT } from '@/constants/charts';
import { classNames } from '@/helpers';
import { getTimePeriodsInRange } from '@/helpers/dateFormatter';
import useElementSize from '@/hooks/useElementSize';
import useOneColor from '@/hooks/useOneColor';
import './DateChart.scss';

/** @type {Highcharts.PlotLineOptions} */
const LINE_PLOT_OPTIONS = {
  marker: {
    lineColor: undefined,
    states: {
      hover: {
        fillColor: '#fff',
        lineWidth: 2,
        radiusPlus: 0,
      },
    },
  },
  states: {
    hover: {
      halo: {
        size: 13,
      },
    },
  },
};

/** @type {Highcharts.ChartOptions} */
const CHART_OPTIONS = {
  marginTop: 15,
  spacing: [10, 8, 0, 8],
};

/** @type {Highcharts.ExportingOptions} */
const EXPORT_OPTIONS = {
  csv: {
    // Scenario comparison will result in two columns for each series,
    // so include the scenario name to distinguish the two
    columnHeaderFormatter: ({ options }) =>
      options.isX
        ? 'Date'
        : `${options.custom.scenario.name} - ${options.name}`,
  },
};

/** @type {Highcharts.PlotOptions} */
const PLOT_OPTIONS = {
  line: LINE_PLOT_OPTIONS,
  area: LINE_PLOT_OPTIONS,
};

const AXIS_STYLES_DEFAULT = {};

/** @type {Highcharts.XAxisOptions} */
export const XAXIS_DEFAULTS = {
  type: 'datetime',
  dateTimeLabelFormats: {
    year: "%b '%y",
  },
  offset: 12,
  tickPositioner() {
    if (!this?.chart.series.length) return null;

    const [{ min, max }] = this.chart.xAxis;
    const periods = getTimePeriodsInRange(
      min,
      max,
      this.userOptions.timePeriod,
    );
    const idxQuotient = Math.ceil(periods.length / this.tickPositions.length);
    /** @type {Highcharts.AxisTickPositionsArray} */
    const ticks = periods
      .filter((_, idx) => !(idx % idxQuotient))
      .map(([start]) => start);
    /**
     * Required to keep label formatting
     *
     * @see https://github.com/highcharts/highcharts/issues/6467
     */
    ticks.info = this.tickPositions.info;
    return ticks;
  },
  lineWidth: 0,
  tickWidth: 0,
};

const YAXIS_DEFAULTS = getYAxisConfig();

/**
 * @typedef {{
 *   'children': React.ReactElement;
 *   'className'?: string;
 *   'data-testid': string;
 *   'loading'?: boolean;
 *   'plotOptions'?: Highcharts.PlotOptions;
 *   'axisStyles'?: Highcharts.CSSObject | {};
 *   'onChartCreated'?: (chart: Highcharts.Chart) => void;
 *   'tooltip'?: React.ReactNode;
 *   'timePeriod'?: import('@/constants/dateTime').timePeriods;
 *   'startDate': string;
 *   'endDate': string;
 * }} DateChartProps
 */

/**
 * Renders an XY chart with an x-axis by date
 *
 * @example
 *   <DateChart data-testid="foo">
 *     <ColumnSeries data={data} />
 *   </DateChart>;
 *
 * @type {React.ForwardRefRenderFunction<Highcharts.Chart, DateChartProps>}
 */
const DateChartFn = (
  {
    children,
    className,
    'data-testid': dataTestId,
    loading,
    plotOptions = PLOT_OPTIONS,
    axisStyles = AXIS_STYLES_DEFAULT,
    onChartCreated,
    tooltip,
    timePeriod,
    startDate,
    endDate,
    ...props
  },
  ref,
) => {
  /** @type {[Highcharts.Chart, React.Dispatch<Highcharts.Chart>]} */
  const [chartApi, setChartApi] = useState();
  const isOneColorEnabled = useOneColor();
  useImperativeHandle(ref, () => chartApi);

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const chartRef = useRef(null);
  const includeXAxis = useMemo(
    () =>
      React.Children.toArray(children).every(
        (child) => React.isValidElement(child) && child.type !== XAxis,
      ),
    [children],
  );
  const includeYAxis = useMemo(
    () =>
      React.Children.toArray(children).every(
        (child) => React.isValidElement(child) && child.type !== YAxis,
      ),
    [children],
  );

  const { contentRect } = useElementSize(chartRef);
  useEffect(() => {
    if (!chartApi || !chartApi.xAxis || !contentRect) return;

    const { width, height } = contentRect;
    // Nothing has changed
    if (chartApi.chartWidth === width && chartApi.chartHeight === height) {
      return;
    }

    const showTrend = height < 100;
    chartApi.update(
      {
        chart: {
          spacing: showTrend ? [0, 0, 5, 0] : CHART_OPTIONS.spacing,
        },
        xAxis: chartApi.xAxis.map(() => ({ visible: !showTrend })),
        yAxis: [{ visible: !showTrend }],
      },
      false,
    );

    chartApi.setSize(null, null, false);
  }, [chartApi, contentRect]);

  const mergedXAxisConfigs = useMemo(
    () =>
      getXAxisConfig({
        startDate,
        endDate,
        axisStyles,
        timePeriod,
        isOneColorEnabled,
      }),
    [axisStyles, startDate, endDate, timePeriod, isOneColorEnabled],
  );

  const yAxisConfigs = useMemo(
    () => ({
      ...YAXIS_DEFAULTS,
      labels: {
        ...YAXIS_DEFAULTS.labels,
        style: axisStyles,
      },
    }),
    [axisStyles],
  );

  return (
    <div
      className={classNames('DateChart_Wrapper DateChart', className)}
      ref={chartRef}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CommonErrorBoundary text={ERROR_BOUNDARY_TEXT}>
          <HighchartsChart
            containerProps={{ 'data-testid': dataTestId, ...props }}
            exporting={EXPORT_OPTIONS}
            plotOptions={plotOptions}
          >
            <ChartRefContainer
              onChartCreated={(chart) => {
                setChartApi(chart);
                onChartCreated?.(chart);
              }}
            />
            <Chart {...CHART_OPTIONS} />
            {tooltip}
            {includeXAxis && <XAxis {...mergedXAxisConfigs} />}
            {includeYAxis ? (
              <YAxis {...yAxisConfigs}>{children}</YAxis>
            ) : (
              children
            )}
          </HighchartsChart>
        </CommonErrorBoundary>
      )}
    </div>
  );
};

const DateChart = forwardRef(DateChartFn);

DateChart.propTypes = {
  /**
   * Series components to populate the chart
   *
   * @see https://github.com/whawker/react-jsx-highcharts/wiki/Series
   */
  'children': PropTypes.node.isRequired,
  /** Additional class(es) to apply to the container */
  'className': PropTypes.string,
  /** A unique ID for selecting the container in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Whether or not a loading indicator should be displayed */
  'loading': PropTypes.bool,
  /**
   * Callback for accessing the chart object from the parent, to pass to
   * dependent components such as the legend or export button
   *
   * @param {Highcharts.Chart} chart
   */
  'onChartCreated': PropTypes.func,
  /** Highcharts plot configuration */
  'plotOptions': PropTypes.objectOf(PropTypes.any),
  /** Axis Label style configuration */
  'axisStyles': PropTypes.objectOf(PropTypes.any),
  /** Interval of the months */
  'timePeriod': PropTypes.string,
  /** Component to render the tooltip */
  'tooltip': PropTypes.node,
  /** Start date of the chart */
  'startDate': PropTypes.string.isRequired,
  /** End date of the chart */
  'endDate': PropTypes.string.isRequired,
};

export default DateChart;
