import { useEffect, useRef, useState } from 'react';
import CrossIcon from '@bill/cashflow.assets/cross';
import EditDefaultIcon from '@bill/cashflow.assets/edit-default';
import Button from '@/components/common/Button';
import ColorPicker from '@/components/common/ColorPicker';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import WithPopover from '@/components/common/WithPopover';
import { CHART_COLORS } from '@/constants/charts';
import './DataSourceSelected.scss';

const DataSourceSelected = ({
  color,
  'data-testid': dataTestId,
  onColorSelect,
  onRemove,
  label,
  onLabelChange,
  onEditCancel,
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [initialLabel] = useState(label);
  const inputRef = useRef(null);

  const onClick = () => {
    if (onLabelChange) {
      onLabelChange();
      setIsEditing(true);
    }
  };

  // whenever isEditing gets set to true, focus the on the input
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing, inputRef]);

  return (
    <div className="DataSourceSelected" data-testid={dataTestId}>
      {onLabelChange && isEditing ? (
        <input
          ref={inputRef}
          className="DataSourceSelected_Input"
          placeholder="Add label"
          value={label}
          onChange={onLabelChange}
          maxLength={50}
          onKeyUp={(event) => {
            event.stopPropagation(); // Prevent sidebar from closing
            if (event.key === 'Escape') {
              setIsEditing(false);
              onEditCancel(initialLabel);
            }
          }}
        />
      ) : (
        <button onClick={onClick} className="DataSourceSelected_EditButton">
          <TooltipTextOverflow
            label={label}
            className="DataSourceSelected_Text"
            data-testid={`${dataTestId}-tooltip`}
          />
        </button>
      )}
      <div className="DataSourceSelected_Option">
        <WithPopover
          content={
            <ColorPicker
              className="ColorPicker-popover"
              colors={CHART_COLORS}
              selectedColor={color}
              onClick={(selectedColor) => {
                onColorSelect(selectedColor);
                setShowColorPicker(false);
              }}
            />
          }
          data-testid={`${dataTestId}-colorPicker`}
          onClose={() => setShowColorPicker(false)}
          visible={showColorPicker}
        >
          <button
            className="DataSourceSelected_Color"
            aria-label="Select Color"
            style={{ backgroundColor: color }}
            onClick={() => setShowColorPicker(!showColorPicker)}
          />
        </WithPopover>
      </div>
      {onRemove && (
        <div className="DataSourceSelected_Option">
          <button className="DataSourceSelected_CloseBtn" onClick={onRemove}>
            <CrossIcon
              className="DataSourceSelected_CloseIcon"
              aria-label="Remove"
            />
          </button>
        </div>
      )}

      {onLabelChange && !isEditing && (
        <Button
          onClick={onClick}
          className="DataSourceSelected_EditIcon"
          data-testid={`${dataTestId}-edit-button`}
        >
          <EditDefaultIcon className="EditIcon" />
        </Button>
      )}
    </div>
  );
};

export default DataSourceSelected;
