import { useEffect } from 'react';

/**
 * Adds the beforeunload event listener when the param is true, removes the
 * listener on unmount
 *
 * @param {boolean} setUnload If true will trigger the unload event warning
 *   users on reload/tab close/window close
 */
const useBeforeUnload = (setUnload) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (setUnload) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign -- predates description requirement
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [setUnload]);
};

export default useBeforeUnload;
