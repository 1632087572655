import { useEffect, useReducer, useState, useMemo } from 'react';
import CrossIcon from '@bill/cashflow.assets/cross';
import DataSourceSelected from '@/components/ChartBuilder/DataSourceSelected';
import Button from '@/components/common/Button';
import ChartsList from '@/components/common/ChartList/ChartsList';
import chartReducer from '@/components/common/ChartList/chartReducer';
import {
  ON_LOAD,
  TOGGLE_CHART,
  CUSTOM_CHART,
  SYSTEM_CHART,
  SET_NAME,
} from '@/components/common/ChartList/constants';
import useChartList from '@/components/common/ChartList/useChartList';
import FormField from '@/components/common/FormField';
import Sidebar from '@/components/common/Sidebar';
import { classNames } from '@/helpers';
import './AddUpdateDashboardSidebar.scss';

const INITIAL_STATE = {
  dashboardName: '',
  charts: [],
  selectedCharts: {},
  selectedChartIds: [],
  filterText: '',
  isSelectAll: {
    [SYSTEM_CHART]: false,
    [CUSTOM_CHART]: false,
  },
};

const ChartSidebarContent = ({
  onClose,
  dashboard,
  onApply,
  isExpanded,
  onExpandClick,
  error,
  setError,
  isLoading,
}) => {
  const [state, setState] = useReducer(chartReducer, INITIAL_STATE);

  const layoutItems = useMemo(() => {
    return dashboard?.items ?? [];
  }, [dashboard]);

  const { charts, selectedCharts, isSelectAll } = useChartList(layoutItems);

  useEffect(() => {
    setState({
      type: ON_LOAD,
      onLoad: {
        charts,
        selectedCharts,
        selectedChartIds: Object.keys(selectedCharts),
        isSelectAll,
        dashboardName: dashboard?.name,
      },
    });
  }, [charts, selectedCharts, isSelectAll, dashboard?.name]);

  return (
    <div className="ChartToggleSidebar_Container">
      <div className="ChartToggleSidebar_Panel">
        <header className="Sidebar_Header">
          <h3 className="Sidebar_Title">
            {dashboard?.id ? 'Edit' : 'Add'} Dashboard
          </h3>
          <button
            className="Sidebar_CloseBtn"
            onClick={() => {
              setError('');
              onClose();
            }}
            aria-label="Close"
          >
            <CrossIcon className="CloseIcon" />
          </button>
        </header>
        <div className="ChartToggleSidebar_DashboardName">
          Name of Dashboard
          <FormField
            id="dashboard-name"
            name="dashboard-name"
            placeholder="Enter name"
            data-testid="dashboard-name-input"
            value={state.dashboardName}
            maxLength={50}
            validate={() => {
              if (error) {
                return error;
              }

              if (!state.dashboardName) {
                return 'Dashboard name is required';
              }
              return null;
            }}
            onChange={({ target }) =>
              setState({ type: SET_NAME, setName: target.value })
            }
          />
        </div>
        <div className="ChartToggleSidebar_CheckedListContainer">
          <div className="ChartToggleSidebar_CheckedListHeader">
            Selected Charts
            <Button
              data-testid="show-hide-chart-list"
              onClick={() => onExpandClick((prevState) => !prevState)}
              className="Button-primaryLink"
            >
              ({isExpanded ? 'Close' : 'Show'} List)
            </Button>
          </div>
          {!state.selectedChartIds.length && (
            <span className="ChartToggleSidebar_EmptyListPlaceholder">
              Your selected charts will show here.
            </span>
          )}
        </div>
        <div className="ChartToggleSidebar_CheckedList">
          {state.selectedChartIds.map((chartId) => {
            const { key, name } = state.selectedCharts[chartId];
            return (
              <DataSourceSelected
                onRemove={() =>
                  setState({ type: TOGGLE_CHART, toggledChart: key })
                }
                key={key}
                label={name}
              />
            );
          })}
        </div>
        {Boolean(state.selectedChartIds.length) && (
          <Button
            className="Button-primary ChartToggleSidebar_AddChartBtn"
            data-testid="toggle-charts-apply"
            disabled={!state.dashboardName}
            onClick={() =>
              onApply({
                selectedCharts: state.selectedCharts,
                name: state.dashboardName,
                id: dashboard?.id,
                isDefault: dashboard?.isDefault,
              })
            }
            loading={isLoading}
          >
            Save
          </Button>
        )}
      </div>
      {isExpanded && (
        <div className="ChartToggleSidebar_Panel ChartToggleSidebar_Panel-secondary">
          <ChartsList state={state} setState={setState} charts={charts} />
        </div>
      )}
    </div>
  );
};

const AddUpdateDashboardSidebar = ({
  open,
  onClose,
  dashboard,
  onApply,
  setError,
  error,
  isLoading,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (open) setIsExpanded(true);
  }, [open]);

  return (
    <Sidebar
      className={classNames(
        'ChartToggleSidebar',
        isExpanded && 'ChartToggleSidebar-expanded',
      )}
      open={open}
      onClose={onClose}
    >
      {open && (
        <ChartSidebarContent
          open={open}
          onClose={onClose}
          dashboard={dashboard}
          onApply={onApply}
          isExpanded={isExpanded}
          onExpandClick={setIsExpanded}
          error={error}
          setError={setError}
          isLoading={isLoading}
        />
      )}
    </Sidebar>
  );
};

export default AddUpdateDashboardSidebar;
