import axios from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs';
import { PERSIST_STATEFUL_ROUTE } from '@/actionTypes/auth';
import { LOGIN_PATH, LOGOUT_PATH } from '@/constants/pages';
import isBillDomain from '@/helpers/isBillDomain';
import { REACT_APP_BILL_API_GATEWAY } from '@/runtimeConfig';
import { store } from '@/store';

const paramsSerializer = (params) =>
  qs.stringify(params, { arrayFormat: 'comma' });

const baseURL = isBillDomain() ? REACT_APP_BILL_API_GATEWAY : '';

const Api = axios.create({
  baseURL: `${baseURL}/api/v1`,
  paramsSerializer,
});

export const ApiV2 = axios.create({
  baseURL: `${baseURL}/api/v2`,
  paramsSerializer,
});

[Api, ApiV2].forEach((api) => {
  api.interceptors.request.use(
    (config) => {
      const updatedConfig = { ...config };
      // Skip auth headers for unauthenticated API requests
      if (config.headers !== false) {
        const { auth, companies } = store.getState();
        // The Bill's authorization header (IAT Token) is overridden by our header
        // The Identity token would only exist if we login to finmark directly and are not coming from the Bill's domain.
        if (auth.identityToken && !isBillDomain()) {
          const token = `Bearer ${auth.identityToken}`;
          updatedConfig.headers = {
            ...config.headers,
            Authorization: token,
          };
        }

        // Bill's API gateway requires the X-Csrftoken to route the APIs
        if (isBillDomain()) {
          updatedConfig.headers = {
            ...updatedConfig.headers,
            'X-Csrftoken': Cookies.get('auth_un_csrf'),
          };
          updatedConfig.withCredentials = true;
        }

        // the current selectedCompanyId is being overridden in the GET/POST/PATCH/PUT request
        // This can occur when taking actions on a company that differs from the one currently selected
        if (config.params?.companyId !== undefined) {
          return updatedConfig;
        }

        // if the companyId has not been overridden from the config check above
        // pass the selectedCompanyId as a default if it exists
        if (companies.selectedCompanyId) {
          updatedConfig.params = {
            ...config.params,
            companyId: companies.selectedCompanyId,
          };
        }
      }
      return updatedConfig;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const { auth, companies, scenario } = store.getState();
      if (
        error.response?.status === 401 &&
        !auth.isRefreshingToken &&
        window.location.pathname !== LOGIN_PATH
      ) {
        store.dispatch({
          type: PERSIST_STATEFUL_ROUTE,
          payload: {
            route: window.location.pathname,
            fullPath: window.location.href,
            companyId: companies.selectedCompanyId,
            scenarioId: scenario.scenarioId,
          },
        });
        window.location.replace(LOGOUT_PATH);
      }
      return Promise.reject(error);
    },
  );
});

export default Api;
