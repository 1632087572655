// @ts-check
import { ApiV2 } from './Api';
import getByDateRange from './getByDateRange';

/**
 * Creates a custom variable with the given properties in the given scenario
 *
 * @param {number} scenarioId ID of the scenario in which to create the variable
 * @param {Object} data Properties of the custom variable
 * @returns {Promise} API response
 */
export const createCustomVariable = (scenarioId, data) =>
  ApiV2.post('/variables/custom-variables', data, { params: { scenarioId } });

/**
 * Deletes the custom variable with the given ID
 *
 * @param {number} scenarioId ID of the scenario containing the variable
 * @param {string} id ID of the variable to be deleted
 * @returns {Promise} API response
 */
export const deleteCustomVariable = (scenarioId, id) =>
  ApiV2.delete(`/variables/custom-variables/${id}`, { params: { scenarioId } });

/**
 * Updates the custom variable with the given ID with the given properties
 *
 * @param {number} scenarioId ID of the scenario containing the variable
 * @param {string} id ID of the variable to update
 * @param {Object} data New properties for the variable
 * @returns {Promise} API response
 */
export const updateCustomVariable = (scenarioId, id, data) =>
  ApiV2.put(`/variables/custom-variables/${id}`, data, {
    params: { scenarioId },
  });

/**
 * Retrieves all the system variables in the given scenario, with their values
 * for the given date range
 *
 * @param {string} startDate First month of desired variable values, in the
 *   format YYYY-MM
 * @param {string} endDate Last month of desired variable values, in the format
 *   YYYY-MM
 * @param {number} scenarioId ID of the scenario containing the variables
 * @returns {Promise} API response
 */
export const getSystemVariables = getByDateRange('/variables/system-variables');

/**
 * Retrieves all custom variables in the given scenario, with their values for
 * the given date range
 *
 * @param {string} startDate First month of desired variable values, in the
 *   format YYYY-MM
 * @param {string} endDate Last month of desired variable values, in the format
 *   YYYY-MM
 * @param {number} scenarioId ID of the scenario containing the variables
 * @returns {Promise} API response
 */
export const getCustomVariables = getByDateRange('/variables/custom-variables');

/**
 * Creates a new custom variable section in the given scenario
 *
 * @param {number} scenarioId ID of the scenario in which to create the section
 * @param {Object} data Properties of the new section
 * @returns {Promise} API response
 */
export const createCustomVariableSection = async (scenarioId, data) =>
  ApiV2.post('/variables/custom-variable-sections', data, {
    params: { scenarioId },
  });

/**
 * Retrieves all the user-defined sections for custom variables in the given
 * scenario
 *
 * @param {number} scenarioId ID of the scenario containing the sections
 * @returns {Promise} API response
 */
export const getCustomVariableSections = async (scenarioId) =>
  ApiV2.get('/variables/custom-variable-sections', { params: { scenarioId } });

/**
 * Updates the custom variable section with the given ID with the given
 * properties
 *
 * @param {number} scenarioId ID of the scenario containing the section
 * @param {string} id ID of the section to update
 * @param {Object} data New properties for the section
 * @returns {Promise} API response
 */
export const updateCustomVariableSection = async (scenarioId, id, data) =>
  ApiV2.put(`/variables/custom-variable-sections/${id}`, data, {
    params: { scenarioId },
  });

/**
 * Deletes the section with the given ID
 *
 * @param {number} scenarioId ID of the scenario containing the section
 * @param {string} id ID of the section to be deleted
 * @returns {Promise} API response
 */
export const deleteCustomVariableSection = async (scenarioId, id) =>
  ApiV2.delete(`/variables/custom-variable-sections/${id}`, {
    params: { scenarioId },
  });

/**
 * Sets the order of the custom variables
 *
 * @param {number} scenarioId ID of the scenario containing the variables
 * @param {Object[]} data Contains the ID, index and sectionId of each variable
 * @returns {Promise} API response
 */
export const setCustomVariableOrder = (scenarioId, data) =>
  ApiV2.put('/variables/custom-variables', data, { params: { scenarioId } });

/**
 * Updates one or more custom variable values in the given scenario
 *
 * @param {number} scenarioId ID of the scenario containing the variable(s)
 * @param {Object[]} data Values to update
 * @returns {Promise} API response
 */
export const setCustomVariableValues = (scenarioId, data) =>
  ApiV2.post('/variables/custom-variables/values', data, {
    params: { scenarioId },
  });

/**
 * Retrieve a list of all variables, custom and system, for a given scenario
 *
 * @param {number} scenarioId ID of the scenario containing the variables
 * @returns {Promise} API response
 */
export const getVariables = (scenarioId) =>
  ApiV2.get('/variables', { params: { scenarioId } });

/**
 * Retrieve a list of all dependencies for a given custom variable and scenario
 *
 * @param {number} scenarioId ID of the scenario the custom variable belongs to
 * @param {string} customVariableId ID of the custom variable
 * @returns {Promise} API response
 */
export const getVariableDependencies = async (scenarioId, customVariableId) =>
  ApiV2.get('/variables/custom-variables/get-variable-dependencies', {
    params: { scenarioId, customVariableId },
  });

/**
 * Bulk create custom variables from Excel spreadsheet
 *
 * @param {Object} args
 * @param {File} args.file File that is uploaded
 * @param {Object} args.params { scenarioId, tabName }
 * @param {(progressEvent: any) => void} args.onUploadProgress Progress of the
 *   uploaded file
 * @returns {Promise} API response
 */
export const createBulkCustomVariables = ({
  file,
  params = {},
  onUploadProgress,
}) => {
  const formData = new FormData();
  formData.append('file', file);
  const controller = new AbortController();
  const promise = ApiV2.post('/variables/custom-variables/excel', formData, {
    params,
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress,
    signal: controller.signal,
  });
  // @ts-ignore
  promise.cancel = () => controller.abort();
  // @ts-ignore
  promise.signal = controller.signal;
  return promise;
};
