// @ts-check
import { useMemo } from 'react';
import { actualsFamily, iconTypes } from '@/constants/actuals';
import { MONTHLY } from '@/constants/dateTime';
import { ITEM } from '@/constants/reports';
import { isActualMonth } from '@/helpers';
import detectCircularRef from '@/helpers/circularReference';
import useIsFullFpnaProduct from '@/hooks/useIsFullFpnaProduct';

/**
 * Get the current scenario value for a cell
 *
 * @type {(
 *   context: import('ag-grid-community').CellClassParams<any, any>,
 * ) => Object}
 */
function getScenarioCellValue({ data, colDef, column }) {
  const month = column.getParent().getGroupId();
  const monthValue = data?.months.find(({ date }) => date === month);
  return monthValue?.value.find(
    (val) => val.scenarioId === Number(colDef.field),
  );
}

/**
 * Check if a cell value belongs to actual's family
 *
 * @type {(
 *   type: string,
 *   context: import('ag-grid-community').CellClassParams<any, any>,
 * ) => boolean}
 */
function isActualType(type, context) {
  const scenarioEntry = getScenarioCellValue(context);
  return scenarioEntry?.type === type || scenarioEntry?.actualType === type;
}

/**
 * Check if a cell has a circular reference
 *
 * @type {(
 *   context: import('ag-grid-community').CellClassParams<any, any>,
 * ) => boolean}
 */
function isCircularRef(context) {
  const scenarioEntry = getScenarioCellValue(context);
  if (!scenarioEntry) return false;
  return detectCircularRef(scenarioEntry);
}

/**
 * Functions for determining the application of classes to ag-Grid cells in
 * Actuals
 *
 * @type {(
 *   timePeriod: string,
 * ) => import('ag-grid-community').CellClassRules}
 */
export default function useIconClassRules(timePeriod) {
  const isFullFpnaProduct = useIsFullFpnaProduct();
  return useMemo(
    () => ({
      'Spreadsheet_IconCell': () => timePeriod === MONTHLY,
      'Spreadsheet_IconCell-external': (context) => {
        const { column, data } = context;
        const month = column.getParent().getGroupId();
        return (
          data &&
          !isCircularRef(context) &&
          isActualMonth(month) &&
          (data.family === actualsFamily.CASH || data.type === ITEM) &&
          isActualType(iconTypes.EXTERNAL_SOURCE_GENERATED, context)
        );
      },
      'Spreadsheet_IconCell-system': (context) => {
        const month = context.column.getParent().getGroupId();
        return (
          isFullFpnaProduct &&
          !isCircularRef(context) &&
          isActualMonth(month) &&
          isActualType(iconTypes.SYSTEM_GENERATED, context)
        );
      },
      'Spreadsheet_IconCell-user': (context) => {
        return (
          !isCircularRef(context) &&
          isActualType(iconTypes.USER_ENTERED, context)
        );
      },
    }),
    [timePeriod, isFullFpnaProduct],
  );
}
