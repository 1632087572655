export const SUBSCRIPTION_MOM_GROWTH = 0;
export const SUBSCRIPTION_CUSTOM_AMOUNT = 1;
export const REVENUE_MOM_GROWTH = 2;
export const REVENUE_CUSTOM_AMOUNT = 3;
export const ONE_TIME_MOM_GROWTH = 4;
export const ONE_TIME_CUSTOM_AMOUNT = 5;
export const SUBSCRIPTION_MARKETING_SPEND_GROWTH = 6;
export const ONE_TIME_MARKETING_SPEND_GROWTH = 7;
export const SUBSCRIPTION_SALES_LED_GROWTH = 8;
export const ONE_TIME_SALES_LED_GROWTH = 9;
export const SUBSCRIPTION_REVENUE_CUSTOM_FORMULA = 10;
export const ONE_TIME_CUSTOM_FORMULA = 11;
export const REVENUE_ONLY_CUSTOM_FORMULA = 12;

export const SUBSCRIPTION_ADVANCED_GROWTH = 13;
export const SUBSCRIPTION_ADVANCED = 'ADVANCED';

export const SUBSCRIPTION = 'subscriptionCustomer';
export const ADVANCED_SUBSCRIPTION = 'advancedSubscription';
export const REVENUE_ONLY = 'revenueOnly';
export const ONE_TIME = 'oneTimePurchases';

export const DRIVER = {
  MONTHLY_GROWTH: 'monthlyGrowth',
  CUSTOM_GROWTH: 'customGrowth',
  MARKETING_SPEND: 'marketingSpend',
  SALES_LED: 'salesLed',
  CUSTOM_FORMULA: 'customFormula',
};

export const STREAMS = {
  subscriptionCustomer: {
    id: 0,
    heading: 'How would you like to forecast your new subscribers?',
    revenueGenerationDateLabel:
      'When do you plan to start acquiring subscribers?',
    revenueGenerationStartDateLabel: 'Acquisition Start Date',
    revenueGenerationEndDateLabel: 'Acquisition End Date',
    pricingPlanInfo:
      'Calculations of the total number of subscribers for this revenue stream (driver) only, excluding churn.',
    pricingPlanItem: 'Subscribers',
    drivers: [
      {
        id: 0,
        type: DRIVER.MONTHLY_GROWTH,
        label: 'Base subscribers with monthly growth',
      },
      {
        id: 1,
        type: DRIVER.CUSTOM_GROWTH,
        label: 'Custom number of subscribers over time',
      },
      {
        id: 2,
        type: DRIVER.MARKETING_SPEND,
        label: 'Marketing-led conversions',
      },
      {
        id: 3,
        type: DRIVER.SALES_LED,
        label: 'Sales-led conversions',
      },
      {
        id: 4,
        type: DRIVER.CUSTOM_FORMULA,
        label: 'Custom Formula',
      },
    ],
  },

  oneTimePurchases: {
    id: 1,
    heading: 'How much will you make from this revenue stream (driver)?',
    revenueGenerationDateLabel: 'When do you plan to start generating revenue?',
    revenueGenerationStartDateLabel: 'Start Date',
    revenueGenerationEndDateLabel: 'End Date',
    pricingPlanInfo:
      'Calculations of the total number of purchases for this revenue stream (driver) only',
    pricingPlanItem: 'Purchases',

    drivers: [
      {
        id: 0,
        type: DRIVER.MONTHLY_GROWTH,
        label: 'Initial number of purchases with MoM Growth',
      },
      {
        id: 1,
        type: DRIVER.CUSTOM_GROWTH,
        label: 'Custom amount of new purchases over time',
      },
      {
        id: 2,
        type: DRIVER.MARKETING_SPEND,
        label: 'Marketing-led conversions',
      },
      {
        id: 3,
        type: DRIVER.SALES_LED,
        label: 'Sales-led conversions',
      },
      {
        id: 4,
        type: DRIVER.CUSTOM_FORMULA,
        label: 'Custom Formula',
      },
    ],
  },

  revenueOnly: {
    id: 2,
    heading: 'When do you plan to start generating revenue?',
    revenueGenerationDateLabel: 'When do you plan to start generating revenue?',
    revenueGenerationStartDateLabel: 'Start Date',
    revenueGenerationEndDateLabel: 'End Date',
    pricingPlanInfo:
      'Calculations of the total number of subscribers for this revenue stream (driver) only, excluding churn.',
    pricingPlanItem: 'Revenue',

    drivers: [
      {
        id: 0,
        type: DRIVER.MONTHLY_GROWTH,
        label: 'Base revenue amount with MoM growth',
      },
      {
        id: 1,
        type: DRIVER.CUSTOM_GROWTH,
        label: 'Custom revenue amounts over time',
      },
      {
        id: 2,
        type: DRIVER.CUSTOM_FORMULA,
        label: 'Custom Formula',
      },
    ],
  },
};
