// @ts-check
import { useEffect, useState } from 'react';

const observed = new Map();
let io;

/**
 * Returns whether the given element is currently scrolled within the viewport.
 *
 * @type {(
 *   ref: React.MutableRefObject,
 *   options?: IntersectionObserverInit,
 * ) => boolean}
 */
export default function useIntersection(ref, options) {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const { current } = ref;
    if (!current) return undefined;

    if (!io) {
      io = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const callback = observed.get(entry.target);
          callback?.(entry);
        });
      }, options);
    }

    observed.set(current, ({ isIntersecting }) => {
      setVisible(isIntersecting);
    });
    io.observe(current);

    return () => {
      observed.delete(current);
      if (!observed.size) {
        io.disconnect();
        io = null;
      } else {
        io.unobserve(current);
      }
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [ref]);

  return isVisible;
}
