// @ts-check
import { useMemo } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ApolloProvider } from '@apollo/client/react';
import Cookies from 'js-cookie';
import getBillLoginUrl from '@/helpers/getBillLoginUrl';
import isBillDomain from '@/helpers/isBillDomain';
import isServerError from '@/helpers/isServerError';
import useLogout from '@/hooks/useLogout';
import { SUPER_GRAPH_URL } from '@/runtimeConfig';

const cache = new InMemoryCache();
/** Apollo HTTPLink For Connecting To SuperGraph */
const httpLink = createHttpLink({
  uri: SUPER_GRAPH_URL,
  credentials: 'include',
});

/** Apollo Authlink used to set the CSRF Token header on requests */
const authLink = setContext(() => ({
  headers: {
    'X-Csrftoken': Cookies.get('auth_un_csrf'),
  },
}));

const ApolloClientProvider = ({ children }) => {
  const logout = useLogout();
  const client = useMemo(() => {
    const errorLink = onError(({ networkError }) => {
      if (
        isServerError(networkError) &&
        Reflect.get(networkError, 'statusCode') === 401 &&
        isBillDomain()
      ) {
        logout();
        window.location.href = getBillLoginUrl(window.location.href);
      }
    });
    return new ApolloClient({
      link: from([authLink, errorLink, httpLink]),
      cache,
    });
  }, [logout]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
