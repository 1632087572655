import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { subscribeToEmployeeUpdateAction } from '@/actions/employees';
import { getExpensesListAction } from '@/actions/expenses';
import ExpensesGrid from '@/components/Expenses/ExpensesGrid';
import TableExportButton from '@/components/TableExportButton';
import Tabs from '@/components/common/Tabs';
import { actualsFamily } from '@/constants/actuals';
import useWsSubscription from '@/hooks/useWsSubscription';
import ActualsGrid from '@/pages/Actuals/ActualsGrid';
import './List.scss';

const EXPENSE_ACTUALS = '/actuals';

const ExpensesList = ({
  onOrganize,
  onEdit,
  onDelete,
  setCurrentRecord,
  setPreviewEntries,
  getExpensesList,
  scenarioId,
  startDate,
  endDate,
  subscribeToEmployeeUpdate,
  expenses,
  addExpense,
  cellFocus,
  setCellFocus,
  removeUnsavedExpenses,
}) => {
  const gridApi = useRef(null);
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const getExpensesListCallback = () => {
    getExpensesList(scenarioId, startDate, endDate);
  };

  useEffect(getExpensesListCallback, [
    scenarioId,
    startDate,
    endDate,
    getExpensesList,
  ]);

  useWsSubscription(
    () => subscribeToEmployeeUpdate(scenarioId, getExpensesListCallback),
    [scenarioId],
  );

  return (
    <section className="Panel Panel-toEdge">
      <Tabs
        controls={
          pathname === `${path}${EXPENSE_ACTUALS}` && (
            <TableExportButton
              onClick={() => gridApi.current.api.exportDataAsExcel()}
            />
          )
        }
      >
        <Tabs.Panel path={path} label="Expense Summary">
          <ExpensesGrid
            ref={gridApi}
            onOrganize={onOrganize}
            onEdit={onEdit}
            onDelete={onDelete}
            setCurrentRecord={setCurrentRecord}
            setPreviewEntries={setPreviewEntries}
            expenses={expenses}
            onAddExpense={addExpense}
            cellFocus={cellFocus}
            setCellFocus={setCellFocus}
            onDeleteUnsaved={removeUnsavedExpenses}
          />
        </Tabs.Panel>
        <Tabs.Panel path={`${path}${EXPENSE_ACTUALS}`} label="Expense Details">
          <ActualsGrid actualFamily={actualsFamily.EXPENSE} apiRef={gridApi} />
        </Tabs.Panel>
      </Tabs>
    </section>
  );
};

const mapStateToProps = ({ scenario, shared }) => ({
  scenarioId: scenario.scenarioId,
  startDate: shared.startDate,
  endDate: shared.endDate,
});

export default connect(mapStateToProps, {
  getExpensesList: getExpensesListAction,
  subscribeToEmployeeUpdate: subscribeToEmployeeUpdateAction,
})(ExpensesList);
