import { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { setPercentKpi } from '@/components/Charts/helpers';
import TrendArrow from '@/components/common/TrendArrow';
import './WidgetKPI.scss';

/**
 * Renders a KPI for a dashboard widget, with an optional label.
 *
 * @example
 *   <WidgetKPI label="New Revenue" value={formatMonetary(newRev)} />;
 */
function WidgetKPI({ label, value, tooltip, secondaryMetric }) {
  const secondaryMetricValue = secondaryMetric?.value;
  const colors = secondaryMetric?.colors;

  return (
    <div className="WidgetKPI_Wrapper">
      <div className="WidgetKPI">
        <p className="WidgetKPI_Value">
          {
            // Insert a zero-width space to reserve the line height
            value || String.fromCharCode(8203)
          }
          {isValidElement(tooltip) && tooltip}
        </p>
        <span className="WidgetKPI_Label">
          {label || String.fromCharCode(8203)}
        </span>
      </div>
      {secondaryMetric && (
        <div className="WidgetKPI">
          <div className="WidgetKPI_SecondaryMetricWrapper">
            <TrendArrow value={secondaryMetricValue} colors={colors} />
            <div className="WidgetKPI_SecondaryValueWrapper">
              <p className="WidgetKPI_Value WidgetKPI_Value-secondary">
                {setPercentKpi(secondaryMetricValue)}
              </p>
              <span className="WidgetKPI_SecondaryLabel">
                {secondaryMetric.label}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

WidgetKPI.propTypes = {
  /** Title or date range describing the value */
  label: PropTypes.string,
  /** The value to emphasize */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** A tooltip to display next to the KPI value */
  tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  /** Object which contains value, label and colors of secondary metric */
  secondaryMetric: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
    colors: PropTypes.shape({
      secondaryMetricIncreaseColor: PropTypes.string,
      secondaryMetricDecreaseColor: PropTypes.string,
      secondaryMetricNoChangeColor: PropTypes.string,
    }),
  }),
};

export default WidgetKPI;
