// @ts-check
import { useHistory } from 'react-router-dom';
import ExportableReportIcon from '@bill/cashflow.assets/exportable-reports';
import ReportBuilderIcon from '@bill/cashflow.assets/report-builder';
import Chip from '@/components/common/Chip';
import COLORS from '@/constants/colorPalette';
import useOneColor from '@/hooks/useOneColor';
import './ExportableReportButton.scss';

/**
 * A Button which redirects to Exportable report
 *
 * @example
 *   <PageHeader page="/reports" addBtnSlot={[<ExportableReportButton />]} />;
 *
 * @typedef {{ type: import('@/constants/reports.js').ExportableReportType }} ExportableReportButtonProps
 * @type {(props: ExportableReportButtonProps) => React.ReactElement}
 */
const ExportableReportButton = ({ type }) => {
  const history = useHistory();
  const isOneColorThemeEnabled = useOneColor();

  return (
    <button
      className="ExportableReportButton"
      onClick={() => history.replace(type.path)}
    >
      {isOneColorThemeEnabled ? (
        <ReportBuilderIcon className="ExportableReportIcon" />
      ) : (
        <ExportableReportIcon className="ExportableReportIcon" />
      )}

      <div>
        {type.isNew && !isOneColorThemeEnabled && (
          <Chip
            color={COLORS.sunrise}
            className="ExportableReportButton_Chip"
            data-testid="exportable-report-button-chip"
          >
            NEW
          </Chip>
        )}
        {type.buttonText}
      </div>
    </button>
  );
};

export default ExportableReportButton;
