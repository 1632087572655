import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { SET_CHART_PREFERENCES } from '@/actionTypes/dashboard';
import { classNames } from '@/helpers';
import useChartPreferences from '@/hooks/useChartPreferences';
import useOneColor from '@/hooks/useOneColor';
import './Switch.scss';

/**
 * Creates an on/off switch
 *
 * @example
 *   <Switch id="foo" on={true} onSwitch={(on) => setFoo(on)} />;
 */
function Switch({ id, label, className = '', on = false, ...props }) {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const switchValueFromState = useChartPreferences(id);
  const switchValue = switchValueFromState ?? on;
  const isOneColorEnabled = useOneColor();

  useEffect(() => {
    if (!switchValueFromState) {
      dispatch({ type: SET_CHART_PREFERENCES, payload: { [id]: on } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [id, on]);

  const onSwitch = (switchPosition) => {
    dispatch({
      type: SET_CHART_PREFERENCES,
      payload: { [id]: switchPosition },
    });
  };

  return (
    <div className={classNames('Switch', className)}>
      {label && (
        <label
          htmlFor={id}
          className={classNames(
            'Switch_Label',
            isOneColorEnabled && 'Switch_Label-forecasting',
          )}
        >
          {label}
        </label>
      )}
      <button
        type="button"
        id={id}
        className="Switch_Button"
        data-testid={id}
        aria-pressed={switchValue}
        onClick={() => onSwitch(!on)}
        {...props}
      />
    </div>
  );
}

Switch.propTypes = {
  /** Classes to apply to the switch button */
  className: PropTypes.string,
  /**
   * A globally unique ID for storing switch state between views. It is also
   * used for selecting the switch in unit/integration tests
   */
  id: PropTypes.string.isRequired,
  /** A text label for the switch */
  label: PropTypes.string,
  /** Whether the switch is on or off */
  on: PropTypes.bool,
};

export default Switch;
