// @ts-check
import { useMemo } from 'react';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import {
  CASH_IN,
  CASH_OUT,
  TOTAL_CASH_IN_ID,
  TOTAL_CASH_OUT_ID,
} from '@/constants/cashInOut';
import { ASC } from '@/constants/tables';
import { zeroFilter } from '@/helpers/zeroFilter';
import useCallbackRef from '@/hooks/useCallbackRef';
import useCashInOutGridData from '@/hooks/useCashInOutGridData';
import { valueFormatter } from '@/pages/Actuals/helpers';
import { cashFlowComparator } from '@/pages/Dashboard/helpers';

const TOTAL_CASH_IN = 'Total Cash In';
const TOTAL_CASH_OUT = 'Total Cash Out';
const total = {
  [CASH_IN]: TOTAL_CASH_IN,
  [CASH_OUT]: TOTAL_CASH_OUT,
};

/**
 * @type {(props: {
 *   data: import('@/types/services/backend').CashInOutGrid;
 * }) => boolean}
 */
const passesZeroFilter = ({ data: monthlyData }) =>
  [TOTAL_CASH_IN_ID, TOTAL_CASH_OUT_ID].includes(monthlyData.id) ||
  zeroFilter(monthlyData);
/**
 * @typedef {{
 *   'title': typeof CASH_IN | typeof CASH_OUT;
 *   'filter': import('@/types/services/backend').CashInOutGrid['cashFlowType'];
 *   'data-testid': string;
 * }} CashInOutExpandedSpreadsheetProps
 */

/** @type {(props: CashInOutExpandedSpreadsheetProps) => React.ReactElement} */
const CashInOutExpandedSpreadsheet = ({
  title,
  filter,
  'data-testid': dataTestId,
}) => {
  const [gridApi, gridRef] = useCallbackRef();
  /**
   * @type {import('ag-grid-community').ColDef<
   *   import('@/types/cashInOutGrid').CashInOutGridColumns
   * >[]}
   */
  const colDefs = useMemo(
    () => [
      {
        field: 'name',
        headerName: title,
        valueFormatter: ({ value }) => (value === title ? total[title] : value),
        sort: ASC,
        comparator: cashFlowComparator(title),
      },
    ],
    [title],
  );
  const { rowData: data, isLoading } = useCashInOutGridData(filter, false);

  return (
    <section className="Panel Panel-toEdge">
      <SpreadsheetToolbar editable={false} gridApi={gridApi}>
        Options:
        <OptionsToggle spreadsheetId={dataTestId} />
      </SpreadsheetToolbar>
      <MonthlySpreadsheet
        ref={gridRef}
        columnDefs={colDefs}
        loading={isLoading}
        data={data}
        data-testid={dataTestId}
        editable={false}
        enableComparison
        valueFormatter={valueFormatter}
        passesZeroFilter={passesZeroFilter}
      />
    </section>
  );
};

export default CashInOutExpandedSpreadsheet;
