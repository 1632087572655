import jwtDecode from 'jwt-decode';
import {
  SET_IS_REFRESHING_TOKEN,
  PERMISSIONS_CHANGED,
  AUTHENTICATION_SUCCEEDED,
  SET_TMX_SESSION_ID,
  AUTHENTICATION_DECLINED_BY_TMX,
} from '@/actionTypes/auth';
import { SET_USER_PREFERENCES } from '@/actionTypes/shared';
import { subscribeToTopic } from '@/actions/shared';
import { TIMEOUT_ERROR_MESSAGE } from '@/constants/errors';
import { DECLINED_ERROR_CODE, ORG_ID } from '@/constants/threatmetrix';
import { getQueryParamsFromPath, isNumber } from '@/helpers';
import generateSessionId from '@/helpers/generateSessionId';
import { isEmptyOrNull } from '@/helpers/validators';
import { refreshPermissions, loginService } from '@/services/authService';
import { getCompanies } from '@/services/companies';
import { getScenario } from '@/services/scenario.service';
import registerDevice from '@/services/tmx.service';

/**
 * An action creator for persisting user preferences in the store
 *
 * @param {Object} preferences - A user preference object
 * @returns {Object} action
 */
export const userPreferencesAction = (preferences) => {
  return {
    type: SET_USER_PREFERENCES,
    payload: preferences,
  };
};

/**
 * Action representing a successful user login
 *
 * @type {(
 *   payload: import('@/types/services/backend').JwtResponse,
 * ) => import('@/types/extend-redux-toolkit').AppThunk}
 */
export const authSucceeded = (payload) => {
  return (dispatch, getState) => {
    const { auth } = getState();
    const { statefulRoute } = auth;
    const { companyId: companyIdParam, scenarioId: scenarioIdParam } =
      getQueryParamsFromPath(statefulRoute?.search);
    const targetCompanyId =
      isNumber(companyIdParam ?? '') && Number(companyIdParam);
    const targetScenarioId =
      isNumber(scenarioIdParam ?? '') && Number(scenarioIdParam);
    const tokenData = jwtDecode(payload.jwtToken);
    const action = {
      type: AUTHENTICATION_SUCCEEDED,
      payload: {
        ...payload,
        ...tokenData,
        selectedCompanyId: targetCompanyId || payload.selectedCompanyId,
      },
    };
    if (targetScenarioId) {
      action.payload.scenarioId = targetScenarioId;
    }
    dispatch(action);
  };
};

/**
 * @typedef {{
 *   type: string;
 *   payload: boolean;
 * }} setUnauthorizedByTMXAction
 */

/** @type {(arg?: boolean) => setUnauthorizedByTMXAction} */
export const setUnauthorizedByTMXAction = (isUnauthorized = true) => {
  return { type: AUTHENTICATION_DECLINED_BY_TMX, payload: isUnauthorized };
};

/**
 * Listen for websocket messages triggered by user permission changes
 *
 * @param {number} userId - The user's unique ID
 * @returns {ReturnType<typeof subscribeToTopic>} Dispatchable action
 */
export const subscribeToUserPermissionChangeAction =
  (userId) => (dispatch, getState) => {
    return dispatch(
      subscribeToTopic(`user-permissions-changed/${userId}`, async () => {
        dispatch({
          type: SET_IS_REFRESHING_TOKEN,
          payload: true,
        });
        try {
          const { selectedCompanyId } = getState().companies;
          const { data } = await refreshPermissions();
          dispatch(authSucceeded({ ...data, selectedCompanyId }));

          const { companies: jwtCompanies } = jwtDecode(data.jwtToken);

          const serviceRequests = [getCompanies()];

          if (!isEmptyOrNull(jwtCompanies[selectedCompanyId])) {
            serviceRequests.push(getScenario());
          }

          const promises = await Promise.all(serviceRequests);
          const [companyData, scenarioData] = promises;
          const companies = companyData?.data?.data ?? [];
          const scenarios = scenarioData?.data?.data ?? [];
          dispatch({
            type: PERMISSIONS_CHANGED,
            payload: {
              scenarios,
              companies,
            },
          });
        } catch (e) {
          // eslint-disable-next-line no-console -- predates description requirement
          console.error(e);
        } finally {
          dispatch({
            type: SET_IS_REFRESHING_TOKEN,
            payload: false,
          });
        }
      }),
    );
  };

/**
 * @type {(
 *   credentials: import('@/types/services/backend').JwtRequest,
 * ) => import('@/types/extend-redux-toolkit').AppThunk}
 */
export const loginAction = (credentials) => {
  return async (dispatch) => {
    try {
      const { data } = await loginService(credentials);
      if (!data.jwtToken) {
        throw new Error('JWT is required');
      }
      dispatch(authSucceeded(data));
      return data;
    } catch (error) {
      if (error.response?.data.error?.errorCode === DECLINED_ERROR_CODE) {
        dispatch(setUnauthorizedByTMXAction());
      }

      throw new Error(
        !error.response || error.response.status === 504
          ? TIMEOUT_ERROR_MESSAGE
          : error.response?.data.error.errorMessage || error.message,
      );
    }
  };
};

/**
 * Action that Set Devise Session into the reducer on Page Load
 *
 * @returns {import('@/types/extend-redux-toolkit').AppThunk} Dispatchable
 *   action
 */
export const setTMXSessionId = () => {
  return async (dispatch) => {
    const sessionId = generateSessionId();
    // Calling this Function here for UI Profiling
    await registerDevice(ORG_ID, sessionId);
    dispatch({
      type: SET_TMX_SESSION_ID,
      payload: sessionId,
    });
  };
};

/** @type {() => import('@/types/extend-redux-toolkit').AppThunk} */
export const refreshToken = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_IS_REFRESHING_TOKEN,
      payload: true,
    });
    const { selectedCompanyId } = getState().companies;
    const { data } = await refreshPermissions();
    dispatch(authSucceeded({ ...data, selectedCompanyId }));

    dispatch({
      type: SET_IS_REFRESHING_TOKEN,
      payload: false,
    });
  };
};
