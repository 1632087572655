export const registeredFeatureFlags = /** @type {const} */ ({
  BULK_UPLOAD_CUSTOM_VARS: 'BULK_UPLOAD_CUSTOM_VARS',
  BULK_UPLOAD_EXPENSES: 'BULK_UPLOAD_EXPENSES',
  EXPENSE_LINK_CRITERIA: 'EXPENSE_LINK_CRITERIA',
  GSHEETS_REVENUE_INTEGRATION: 'GSHEETS_REVENUE_INTEGRATION',
  DEFAULT_DASHBOARD_SELECTION: 'DEFAULT_DASHBOARD_SELECTION',
  ONE_COLOR_CSS_THEME: 'ONE_COLOR_CSS_THEME',
  SHOW_VARIANCE_PERCENT: 'SHOW_VARIANCE_PERCENT',
});

export const eligibleEmailAccounts = [
  '@finmark.com',
  '@dev.com',
  '@10pearls.com',
  'deepak.khandelwal@hq.bill.com',
  'anima.sahoo@hq.bill.com',
  'karan.shah@hq.bill.com',
  'mikhail.vnukov@hq.bill.com',
  'brendan.mcsweeney@hq.bill.com',
  'scott.vanderbeek@hq.bill.com',
  'rosemary.chen@hq.bill.com',
  'robert.kenney@hq.bill.com',
  'robert.ziegler@hq.bill.com',
  'derrick.lowder@hq.bill.com',
  'ursalan.mansoor@hq.bill.com',
  'waheed.ahmed@hq.bill.com',
  'saad.muhammad@hq.bill.com',
  'rutaba.ashraf@hq.bill.com',
  'faisal.aslam@hq.bill.com',
  'muhammad.ali@hq.bill.com',
  'areeba.fatima@hq.bill.com',
  'adeel.ansari@hq.bill.com',
  'ali.gillani@hq.bill.com',
  'muhammad.naveed@hq.bill.com',
  'jeewan.kumar@hq.bill.com',
  'haris.mirza@hq.bill.com',
  'ifrah.asif@hq.bill.com',
  'hamza.najeeb@hq.bill.com',
  'humna.jawed@hq.bill.com',
  'faiza.aslam@hq.bill.com',
  'tayyab.javed@hq.bill.com',
  'sabieh.ahmed@hq.bill.com',
  'ahmed.mujahid@hq.bill.com',
  'muhammad.atif@hq.bill.com',
  'jason.dsouza@hq.bill.com',
  'ariz.siddiqui@hq.bill.com',
  'maryam.aslam@hq.bill.com',
  'namra.kanwal@hq.bill.com',
  'jamie.andelman@hq.bill.com',
  'john.kramer@hq.bill.com',
  'rzych@hq.bill.com',
  'cody.titmus@divvypay.com',
  'mark.malek@hq.bill.com',
  'athatte@hq.bill.com',
  'sarah.phoenix@hq.bill.com',
  'cwelton@hq.bill.com',
  'cody.titmus@hq.bill.com',
  'ckolbeson@hq.bill.com',
  'dajala@hq.bill.com',
  'emelton@hq.bill.com',
  'rishi.rana@hq.bill.com',
  'sveera@hq.bill.com',
  'akathuria@hq.bill.com',
  'skapalavai@hq.bill.com',
  'mnapier@hq.bill.com',
  'sneupane@hq.bill.com',
  'vtubati@hq.bill.com',
  'wjanzen@hq.bill.com',
];

export const betaFeatures = {
  BAMBOO_HR: 'BAMBOO_HR',
  ZENEFITS: 'ZENEFITS',
};

export const betaEmailAccounts = [
  '@finmark.com',
  '@dev.com',
  '@10pearls.com',
  '@provencfo.com',
  'deepak.khandelwal@hq.bill.com',
  'anima.sahoo@hq.bill.com',
  'karan.shah@hq.bill.com',
  'mikhail.vnukov@hq.bill.com',
  'brendan.mcsweeney@hq.bill.com',
  'scott.vanderbeek@hq.bill.com',
  '@growie.io', // Remove when Bamboo goes live
  'pablo.alonso@hotstats.com', // Remove when Bamboo goes live
  'lea.roth@busbud.com', // Remove when Bamboo goes live
  'finance@olympe.io', // Remove when Bamboo goes live
  'pamela.curva@busbud.com', // Remove when Bamboo goes live
  'marc-andre.cote@busbud.com', // Remove when Bamboo goes live
  'andy@logixboard.com', // Remove when Zenefits goes live
  'alexis@logixboard.com', // Remove when Zenefits goes live
  'sanjay@chekhub.com', // Remove when Zenefits goes live
  'sebastian@scalr.com', // Remove when Bamboo goes live
  'andrew@founderinstitute.com', // Remove when Zenefits goes live
  'amber@trestle.co', // Remove when Bamboo goes live
  'lhench@murdockmartell.com', // Remove when Zenefits goes live
  'khandy@qualiti.ai', // Remove when Bamboo goes live
  'jandelman@kelloggalumni.northwestern.edu', // Remove when Stripe integration goes live
  'murat.yilmaz@inooster.com', // Remove when Stripe integration goes live
  'csmith@acaciagroup.com', // Remove when Stripe integration goes live
  'csmith@asmsports.co', // Remove when Stripe integration goes live
  'meda@block.green', // Remove when Stripe integration goes live
  'simeon.gow@sortit.ai', // Remove when Stripe integration goes live
  'murat.yilmaz@inooster.com', // Remove when Stripe integration goes live
  'menusha@growmofo.com', // Remove when Stripe integration goes live
  'jazmine@musestorytelling.com', // Remove when Stripe integration goes live
];

export const glassHalfBetaFeatures = {
  ...betaFeatures,
};

export const glassHalfBetaEmails = [];
