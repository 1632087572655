// @ts-check
import { memo } from 'react';
import { FPA_FULL } from '@/constants/productTypes';
import useProductTypes from '@/hooks/useProductTypes';

/** @typedef {React.PropsWithChildren<{ productType?: string }>} ProductTypeFilterProps */

/**
 * Wrapper component For Filtering Rendering Trees By Product Type. This is used
 * as a bridge between refactoring functionality based on the product type(s)
 * companies can be assigned and the current need to filter functionality based
 * on the product type(s).
 *
 * @example
 *   ```jsx
 *   import { FPA_LITE } from '@/constants/productTypes';
 *
 *    <ProductTypeFilter productType={FPA_LITE}>
 *      <div>...</div>
 *    </ProductTypeFilter>
 *    // will render children if productType "FPA_LITE" is part of company's productTypes, otherwise will not render children
 *   ```;
 *
 * @type {React.NamedExoticComponent<ProductTypeFilterProps>}
 */
const ProductTypeFilter = memo(({ children, productType = FPA_FULL }) => {
  const productTypes = useProductTypes();
  if (productTypes.has(productType)) {
    return <>{children}</>;
  }
  return null;
});

export default ProductTypeFilter;
