import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { subscribeToEmployeeUpdateAction } from '@/actions/employees';
import Checkbox from '@/components/common/Checkbox';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import { registeredFeatureFlags } from '@/constants/features';
import { varianceText } from '@/constants/variables';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import useWsSubscription from '@/hooks/useWsSubscription';
import { GRID_OPTIONS } from '@/pages/Reports/constants';
import cellRendererSelector from './cellRendererSelector';

const SPREADSHEET_ID = 'profitAndLoss';

const ProfitAndLoss = ({
  apiRef,
  isInteractive = true,
  hideZeroRows = false,
  showVarianceAmount,
  showVariancePercentage,
  setShowVarianceAmount = () => {},
  setShowVariancePercentage = () => {},
  data,
  isLoading = false,
  refetch = () => {},
}) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const scenarioIds = useSelectedScenarioIds();

  const isShowPercentVarianceEnabled = useFeatureFlags(
    registeredFeatureFlags.SHOW_VARIANCE_PERCENT,
  );

  const [baseScenarioId] = scenarioIds;
  useWsSubscription(
    () => dispatch(subscribeToEmployeeUpdateAction(baseScenarioId, refetch)),
    [baseScenarioId],
  );

  const colDefs = useMemo(
    () => [
      {
        headerName: 'Profit & Loss Breakdown',
        cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: { suppressCount: true },
        field: 'title',
        headerComponent: HeaderRenderer,
        headerComponentParams: { enableExpandAll: isInteractive },
        minWidth: 300,
        showRowGroup: true,
      },
    ],
    [isInteractive],
  );

  const hasComparison = scenarioIds.length > 1;

  return (
    <>
      {isInteractive && (
        <SpreadsheetToolbar editable={false}>
          <div className="SpreadsheetToolbar_ControlGroup">
            Options: <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
          </div>
          {hasComparison && (
            <>
              <Checkbox
                id="variance-toggle"
                checked={showVarianceAmount}
                className="SpreadsheetToolbar_ControlGroup"
                onChange={() =>
                  setShowVarianceAmount((prevState) => !prevState)
                }
              >
                {varianceText.SHOW_VARIANCE_AMOUNT}
              </Checkbox>
              {isShowPercentVarianceEnabled && (
                <Checkbox
                  id="variance-toggle"
                  checked={showVariancePercentage}
                  className="SpreadsheetToolbar_ControlGroup"
                  onChange={() =>
                    setShowVariancePercentage((prevState) => !prevState)
                  }
                >
                  {varianceText.SHOW_VARIANCE_PERCENTAGE}
                </Checkbox>
              )}
            </>
          )}
        </SpreadsheetToolbar>
      )}
      <div className="ReportsGrid">
        <MonthlySpreadsheet
          {...GRID_OPTIONS}
          ref={apiRef}
          columnDefs={colDefs}
          rendererSelector={cellRendererSelector}
          data={data}
          data-testid={SPREADSHEET_ID}
          enableComparison
          loading={isLoading}
          showVarianceAmount={showVarianceAmount}
          showVariancePercentage={showVariancePercentage}
          isInteractive={isInteractive}
          hideZeroRowsByDefault={hideZeroRows}
        />
      </div>
    </>
  );
};

export default ProfitAndLoss;
