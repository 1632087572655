import PropTypes from 'prop-types';
import { findCurrencySymbol } from '@/constants/currencies';
import NumberField from './NumberField';
import './CurrencyField.scss';

/**
 * Creates an input for monetary numbers in the user's configured currency.
 *
 * @example
 *   <CurrencyField
 *     id="foo"
 *     value={bar}
 *     onChange={(event, number) => setBar(number)}
 *   />;
 */
function CurrencyField({ currencySymbol, className = '', ...props }) {
  return (
    <NumberField
      prefix={currencySymbol || findCurrencySymbol()}
      className={`FormField-currency ${className}`}
      {...props}
    />
  );
}

CurrencyField.propTypes = {
  /** Additional class(es) to apply to the input element */
  className: PropTypes.string,

  /** override default currency */
  currencySymbol: PropTypes.string,
};

export default CurrencyField;
