// @ts-check
import { useEffect } from 'react';

/**
 * Refreshes the grid's particular row and column
 *
 * @type {{
 *   (
 *     params: {
 *       gridApi: GridSpreadsheet<any>;
 *       rowId: string;
 *       columnId: string;
 *     },
 *     deps: any[],
 *   ): void;
 * }}
 */
const useGridRefreshCells = ({ gridApi, rowId, columnId }, [...deps]) => {
  useEffect(() => {
    if (gridApi?.api && rowId) {
      const { api, columnApi } = gridApi;
      const node = api.getRowNode(rowId);
      const column = columnApi.getColumn(columnId);
      api.refreshCells({
        columns: [column],
        rowNodes: [node],
        force: true,
        suppressFlash: true,
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, deps);
};

export default useGridRefreshCells;
