import AddIcon from '@bill/cashflow.assets/add';
import PlusIcon from '@bill/cashflow.assets/plus';
import PropTypes from 'prop-types';
import useOneColor from '@/hooks/useOneColor';
import Dropdown from './Dropdown';
import './ButtonAdd.scss';

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
const ButtonAdd = ({ id, children, 'data-testid': dataTestId, direction }) => {
  const isOneColorThemeEnabled = useOneColor();
  const Icon = isOneColorThemeEnabled ? AddIcon : PlusIcon;
  return (
    <Dropdown
      id={id}
      buttonSlot={
        <>
          <Icon className="ButtonAdd_Icon" aria-hidden="true" />
          Add
        </>
      }
      buttonClass="Button-add"
      className="ButtonAdd"
      data-testid={dataTestId || id}
      direction={direction}
    >
      {children}
    </Dropdown>
  );
};

ButtonAdd.propTypes = {
  /** The ID for the Button that triggers the dropdown */
  'id': PropTypes.string.isRequired,
  /** The Dropdown options */
  'children': PropTypes.node.isRequired,
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string,
  /** Where to align the Dropdown Menu. Defaults to "left" */
  'direction': PropTypes.oneOf(['left', 'right']),
};

export default ButtonAdd;
