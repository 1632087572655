// @ts-check
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';

/**
 * Check whether the management console is enabled
 *
 * @type {() => boolean}
 */
const useManagementConsole = () => {
  const isPartner = useSelector(({ auth }) => auth.isPartner);
  return !!isPartner;
};

export default useManagementConsole;
