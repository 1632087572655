import { useState, useRef, useCallback } from 'react';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import { formatDateWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import useDashboardPath from '@/hooks/useDashboardPath';
import CustomerLTVChart from './charts/CustomerLTVChart';
import { getMetricValue } from './helpers';

const CHART_TITLE = 'Customer Lifetime Value';

const CustomerLTV = () => {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState({});
  const chartRef = useRef();
  const kpiValue = getMetricValue(total.currentMonthValue, formatMonetary);
  const dashboardPath = useDashboardPath();
  const onQueryStateChange = useCallback(({ data, isLoading }) => {
    setLoading(isLoading);
    setTotal({
      currentMonthValue: data.currentMonthValue,
      currentMonth: data.currentMonth,
    });
  }, []);

  return (
    <section className="Panel Panel-toEdge">
      <ChartHeader back={dashboardPath} title={CHART_TITLE}>
        <div className="ChartHeader_KPIValue">{loading ? '' : kpiValue}</div>
        <div className="ChartHeader_KPI">
          <br />
          {`${formatDateWithShortYear(total.currentMonth)} LTV`}
        </div>
      </ChartHeader>
      <ChartToolbar>
        <ChartControls
          chartRef={chartRef}
          title={CHART_TITLE}
          metricValue={kpiValue}
          label={`${formatDateWithShortYear(total.currentMonth)} LTV`}
          data-testid="customer-ltv-chart-export"
        />
      </ChartToolbar>
      <CustomerLTVChart
        className="ExpandedView_ChartWrapper"
        exportBtn={chartRef}
        onQueryStateChange={onQueryStateChange}
      />
    </section>
  );
};

export default CustomerLTV;
