// @ts-check

export const chartTypes = /** @type {const} */ ({
  AREA: 'area',
  COLUMN: 'column',
  FUNNEL: 'funnel',
  LINE: 'line',
  PIE: 'pie',
  PERCENT_AREA: 'percent_area',
});

export const timePeriodLabel = /** @type {const} */ ({
  MONTHLY: 'Month',
  QUARTERLY: 'Quarter',
  ANNUALLY: 'Year',
});

export const chartFonts = /** @type {const} */ ({
  FAMILY_NAME: 'Sohne, sans-serif',
  FAMILY_PATH: '/Söhne-Buch.ttf',
  FAMILY_PATH_BOLD: '/Söhne-Halbfett.ttf',
});

export const GRAPH_FORECAST_COLOR = '#ff5a0a';
