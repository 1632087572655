import ArrowDecreaseIcon from '@bill/cashflow.assets/arrow-decrease';
import ArrowIncreaseIcon from '@bill/cashflow.assets/arrow-increase';
import NoChangeIcon from '@bill/cashflow.assets/no-change';
import './TrendArrow.scss';

/**
 * A component to render Trend Arrow(Increase, Decrease or No Change)
 *
 * @example
 *   <TrendArrow value={123} colors={secondaryMetricColors} />;
 */
const TrendArrow = ({ value, colors }) => {
  let Icon = NoChangeIcon;
  let color = colors?.secondaryMetricNoChangeColor;
  if (value > 0) {
    Icon = ArrowIncreaseIcon;
    color = colors?.secondaryMetricIncreaseColor;
  } else if (value < 0) {
    Icon = ArrowDecreaseIcon;
    color = colors?.secondaryMetricDecreaseColor;
  }
  return <Icon style={{ color }} className="TrendArrow" />;
};

export default TrendArrow;
