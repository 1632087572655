import { formatMonthDayYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import formatWholeNumber from '@/helpers/formatWholeNumber';

function formatNumber(value, options = {}, locale = {}) {
  if (!Number.isFinite(Number.parseFloat(value))) return null;

  const config = {
    style: 'decimal',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    ...options,
  };

  const formatter = new Intl.NumberFormat(locale, config);
  return formatter.format(value);
}

export default {
  count: formatNumber,
  monetary: formatMonetary,
  months: (value) => {
    if (Number.isNaN(Number(value))) {
      return `${value} Months`;
    }
    const formattedValue = Math.round(value) === 0 ? 0 : formatNumber(value);
    return `${formattedValue} ${
      Math.abs(formattedValue) === 1 ? 'Month' : 'Months'
    }`;
  },
  /** @deprecated */
  percentLegacy: (value, options) =>
    formatNumber(value / 100, { style: 'percent', ...options }),
  percent: (value, options) =>
    formatNumber(value, { style: 'percent', ...options }),
  dateMonthDayYear: formatMonthDayYear,
  wholeNumber: formatWholeNumber,
};
