// @ts-check

/** @type {{ [key: string]: import('ag-grid-community').RowModelType }} */
const gridRowModels = {
  CLIENT_SIDE: 'clientSide',
  SERVER_SIDE: 'serverSide',
};

module.exports = {
  HEADER_HEIGHT: 48,
  ROW_HEIGHT: 40,
  EMPTY_CELL_VALUE: '--',
  DEPARTMENT_ID: 'departmentId',
  FILTER_ROW_HEIGHT: 64,
  gridRowModels,
  ROW_PINNED_BOTTOM: 'bottom',
  READ_ONLY_CELL_WIDTH: 64,
  READ_ONLY_TITLE_WIDTH: 209,
  READ_ONLY_CELL_PADDING: 8,
  READ_ONLY_INDENT_PADDING: 16,
  READ_ONLY_ROW_HEIGHT: 48,
  READ_ONLY_LINE_HEIGHT: 15,
};
