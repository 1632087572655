// @ts-check
import { classNames } from '@/helpers';
import './ChartToolbar.scss';

/** @typedef {{ className?: string }} ChartToolbarProps */

/**
 * @type {(
 *   props: React.PropsWithChildren<ChartToolbarProps>,
 * ) => React.ReactElement}
 */
const ChartToolbar = ({ className, children }) => {
  return (
    <div className={classNames('Chart_Toolbar', className)}>{children}</div>
  );
};

export default ChartToolbar;
