export const MONTHS_HEADING = 'Monthly Breakdown';
export const ADJUSTMENTS_TO_ASSETS = 'Adjustments To Assets';
export const MAX_CHARTS_PER_ROW = 2;
export const MAX_CHART_PER_PAGE = 4;

export const GRID_OPTIONS = {
  getDataPath: (data) => data.hierarchy,
  editable: false,
  groupDisplayType: 'custom',
  treeData: true,
};
