import { FullStory } from '@fullstory/browser';
import isBillDomain from '@/helpers/isBillDomain';
import {
  REACT_APP_ENABLE_ANALYTICS,
  REACT_APP_INTERCOM_ID,
} from '@/runtimeConfig';

/**
 * Initializes the Intercom messaging service for the given user
 *
 * @param {Object} userData User for whom to initialize the service
 * @param {string} userData.email User's email address
 * @param {string} userData.fullName User's first and last name
 * @param {number} userData.createdAt Date the user registered, as a timestamp
 *   in ms
 * @throws If the Intercom script is not loaded
 */
export function initIntercom({ email, fullName, createdAt }) {
  if (REACT_APP_ENABLE_ANALYTICS !== 'true') return;

  const isFinmarkHost = window.location.hostname.includes('finmark.com');
  if (isFinmarkHost && !window.Intercom) {
    throw new Error('Intercom not initialized');
  } else if (isFinmarkHost && window.Intercom) {
    window.Intercom('boot', {
      app_id: REACT_APP_INTERCOM_ID,
      name: fullName,
      created_at: Math.round(createdAt / 1000),
      email,
    });
  }
}

/**
 * Initializes Pendo for the given user. May need to be called again if the
 * parameters change (e.g. switching companies).
 *
 * @param {Object} userData User for whom to initialize the service
 * @param {number | string} userData.userId User's ID
 * @param {string} userData.email User's email address
 * @param {string} userData.fullName User's first and last name
 * @param {string} userData.userRole Role of the user within the active company
 * @param {Object} company The user's active company
 * @param {number | string} company.id ID of the user's active company
 * @param {string} company.name Name of the user's active company
 * @param {boolean} company.isEnabledAccountingIntegration Whether the user has
 *   enabled an accounting integration, e.g. Quickbooks, Xero, etc., during
 *   registration
 * @param {boolean} company.isEnabledPayrollIntegration Whether the user has
 *   enabled an payroll integration, e.g. Gusto etc., during registration
 * @param {boolean} company.isEnabledRevenueIntegration Whether the user has
 *   enable a reenue integration e.g stripe
 * @throws If the Pendo script is not loaded
 */
export function initPendo(
  { userId, email, fullName, userRole },
  {
    id: companyId,
    name: companyName,
    isEnabledAccountingIntegration,
    isEnabledPayrollIntegration,
    isEnabledRevenueIntegration,
  },
) {
  if (REACT_APP_ENABLE_ANALYTICS !== 'true') return;
  if (!window.pendo) throw new Error('Pendo not initialized');

  const account = {
    id: companyId,
    name: companyName,
  };

  /**
   * 'account.accounting_connected_during_registration' and
   * 'account.commerce_connected_during_registration' should only be set to
   * 'true' once when a user is redirected back from enabling an integration
   * during the registration process.
   *
   * We do this to show users segmented Pendo guides when they first load the
   * dashboard.
   */
  if (isEnabledAccountingIntegration) {
    account.accounting_connected_during_registration =
      isEnabledAccountingIntegration;
  }

  if (isEnabledPayrollIntegration) {
    account.payroll_connected_during_registration = isEnabledPayrollIntegration;
  }

  if (isEnabledRevenueIntegration) {
    account.revenue_connected_during_registration = isEnabledPayrollIntegration;
  }

  window.pendo.initialize({
    visitor: {
      email,
      role: userRole,
      id: userId,
      full_name: fullName,
    },
    account,
  });
}

/**
 * Initializes all enabled analytics services
 *
 * @param {Object} userData User for whom to initialize the services
 * @param {number} userData.userId User's ID
 * @param {string} userData.email User's email address
 * @param {string} userData.fullName User's first and last name
 * @param {string} userData.userRole Role of the user within the active company
 * @param {number} userData.createdAt Date the user registered, as a timestamp
 *   in ms
 * @param {Object} company The user's active company
 */
export function initAnalytics(
  { userId, email, fullName, userRole, createdAt },
  company,
) {
  initPendo(
    {
      userId,
      email,
      fullName,
      userRole,
    },
    {
      id: company.id,
      name: company.companyName,
      isEnabledAccountingIntegration: company.isEnabledAccountingIntegration,
      isEnabledPayrollIntegration: company.isEnabledPayrollIntegration,
      isEnabledRevenueIntegration: company.isEnabledRevenueIntegration,
    },
  );
  initIntercom({ email, fullName, createdAt });
}

/**
 * Initialize Bill Analytics Service
 *
 * @type {(sessionInfo: import('@/types/sessionInfo').sessionInfo) => void}
 */
export function initBillAnalytics(sessionInfo) {
  if (!sessionInfo || REACT_APP_ENABLE_ANALYTICS !== 'true') return;

  const { organization, organizationId, user, userId } = sessionInfo;

  initPendo(
    {
      userId: user.loginId,
      email: user.email,
      fullName: `${user.firstName} ${user.lastName}`,
      userRole: sessionInfo.profile.name,
    },
    {
      id: organizationId,
      name: organization.displayName,
      isEnabledAccountingIntegration: !!organization.accountingSoftware,
      isEnabledPayrollIntegration: false,
      isEnabledRevenueIntegration: false,
    },
  );

  if (isBillDomain()) {
    FullStory('setIdentity', {
      uid: userId,
      properties: {
        loginId: user.loginId,
        orgId: organizationId,
        userId,
      },
    });
  }
}

/**
 * Clears analytics data from persistent stores. Primarily used when switching
 * Pendo users, such as during assisted onboarding.
 */
export function resetAnalytics() {
  Object.keys(localStorage).forEach((key) => {
    if (key.includes('pendo_')) {
      localStorage.removeItem(key);
    }
  });
  Object.keys(sessionStorage).forEach((key) => {
    if (key.includes('pendo_')) {
      sessionStorage.removeItem(key);
    }
  });
}

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const pendoTrackEvent = (event, metadata) => {
  if (REACT_APP_ENABLE_ANALYTICS !== 'true') return;
  if (!window.pendo) throw new Error('Pendo not initialized');
  if (window.pendo.isReady?.()) {
    window.pendo.track(event, metadata);
  }
};
