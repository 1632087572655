import { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setUiStateAction } from '@/actions/ui';
import { classNames } from '@/helpers';
import FormRadio from './FormRadio';
import './RadioGroupPersistent.scss';

/**
 * Radio buttons that control the data is show in a chart
 *
 * @example
 *   <RadioGroupPersistent
 *     id="someId"
 *     options={[
 *       {
 *         label: 'Foo',
 *         value: false,
 *       },
 *       {
 *         label: 'Bar',
 *         value: true,
 *       },
 *     ]}
 *     value={false}
 *   />;
 */
const RadioGroupPersistent = ({
  id,
  className,
  ui,
  value,
  setUiState,
  options,
}) => {
  const valueFromState = ui[id];
  const selectedValue = valueFromState ?? value;

  useEffect(() => {
    if (!valueFromState) {
      setUiState({
        [id]: value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [setUiState, id, value]);

  const handleChange = ({ target }) => {
    if (['false', 'true'].includes(target.value)) {
      setUiState({ [id]: target.value === 'true' });
    } else {
      setUiState({ [id]: target.value });
    }
  };

  return (
    <div id={id} className={classNames('RadioGroupPersistent', className)}>
      {options.map((option) => {
        const stringValue = String(option.value);
        return (
          <FormRadio
            key={stringValue}
            label={option.label}
            id={`${id}-${stringValue}`}
            value={stringValue}
            name={`${id}-control`}
            checked={option.value === selectedValue}
            onChange={handleChange}
            labelClass="RadioGroupPersistent_Label"
          />
        );
      })}
    </div>
  );
};

RadioGroupPersistent.propTypes = {
  /** The UI state object */
  ui: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  ),
  /** Set toggle state in global store */
  setUiState: PropTypes.func.isRequired,
  /**
   * A globally unique ID for storing toggle state between views. It is also
   * used for selecting the toggle in unit/integration tests
   */
  id: PropTypes.string.isRequired,
  /** Classes to apply on the component */
  className: PropTypes.string,
  /** The option (by option.value) to be selected */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  /**
   * An array of objects describing the states to choose from. Each object must
   * contain an label and value.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = ({ ui }) => ({ ui });

export default connect(mapStateToProps, { setUiState: setUiStateAction })(
  RadioGroupPersistent,
);
