import jwtDecode from 'jwt-decode';
import {
  AUTHENTICATION_SUCCEEDED,
  START_SCENARIO_USER_CREATION,
} from '@/actionTypes/auth';
import {
  IS_AJAXING,
  HANDLE_DATA_CHANGE,
  SET_FORGOT_PASSWORD_RESPONSE,
  SET_RESET_PASSWORD_RESPONSE,
} from '@/actionTypes/signUp';
import { authSucceeded, setUnauthorizedByTMXAction } from '@/actions/auth';
import { USER_ROLES } from '@/constants/permissions';
import { DECLINED_ERROR_CODE } from '@/constants/threatmetrix';
import { initAnalytics } from '@/services/analyticsService';
import {
  createFromScenario,
  verifyScenario,
  createAccountWithoutVerification,
  forgotPasswordService,
  resetPasswordService,
} from '@/services/signUpService';
import { setCompaniesOnLoginAction, setSelectedCompany } from './companies';

export const handleDataChange = (name, value) => {
  return {
    type: HANDLE_DATA_CHANGE,
    payload: { name, value },
  };
};

export const setForgotPasswordResponse = (forgotPasswordResponse) => {
  return {
    type: SET_FORGOT_PASSWORD_RESPONSE,
    payload: forgotPasswordResponse,
  };
};

export const setResetPasswordResponse = (resetPasswordResponse) => {
  return {
    type: SET_RESET_PASSWORD_RESPONSE,
    payload: resetPasswordResponse,
  };
};

export const setAjaxing = (ajaxing) => {
  return {
    type: IS_AJAXING,
    payload: ajaxing,
  };
};

export const forgotPasswordAction = (email) => {
  return async (dispatch) => {
    try {
      const { data } = await forgotPasswordService(email);
      dispatch(setForgotPasswordResponse(data));
    } catch (error) {
      throw new Error(error.response?.data.error.errorMessage || error.message);
    }
  };
};

export const resetPasswordAction = (
  resetPasswordToken,
  temporaryPassword,
  newPassword,
  confirmNewPassword,
) => {
  return async (dispatch) => {
    try {
      const { data } = await resetPasswordService(
        resetPasswordToken,
        temporaryPassword,
        newPassword,
        confirmNewPassword,
      );
      dispatch(setResetPasswordResponse(data));
    } catch (error) {
      throw new Error(error.response?.data.error.errorMessage || error.message);
    }
  };
};

export const verifyScenarioAction = (userId, companyId, verificationToken) => {
  return async (dispatch) => {
    try {
      const { data } = await verifyScenario({
        userId,
        companyId,
        verificationToken,
      });
      if (!data.data.passwordCreated) {
        dispatch({
          type: START_SCENARIO_USER_CREATION,
          payload: { verificationToken, signUpResponse: data.data },
        });
      } else {
        dispatch(authSucceeded(data.data));
      }
    } catch (error) {
      throw new Error(error.response?.data.error.errorMessage || error.message);
    }
  };
};

/**
 * @type {(
 *   params: import('@/types/services/backend').ShareScenarioPasswordDto & {
 *     userId: string;
 *   },
 * ) => import('@/types/extend-redux-toolkit').AppThunk}
 */
export const createFromScenarioAction = (shareScenarioData) => {
  return async (dispatch) => {
    try {
      const { data } = await createFromScenario(shareScenarioData);
      dispatch(
        authSucceeded({
          ...data.data,
          ...{ userEmail: data.data.emailAddress },
        }),
      );
      dispatch(setCompaniesOnLoginAction());
      dispatch(setSelectedCompany(shareScenarioData.companyId));
    } catch (error) {
      if (error.response.data.error.errorCode === DECLINED_ERROR_CODE) {
        dispatch(setUnauthorizedByTMXAction());
      }
      throw new Error(error.response?.data.error.errorMessage || error.message);
    }
  };
};

/**
 * Action to create an account without requiring users to verify their email
 * address
 *
 * @param {Object} params
 * @param {string} params.email - A user's email address
 * @param {string} params.password - A password
 * @param {string} params.confirmPassword - The password typed again
 * @param {string} params.firstName - The user's first name
 * @param {string} params.lastName - The user's last name
 * @param {string} params.companyName - The user's company name
 * @param {string} params.deviceSessionId - The UI profiling device session id
 * @param {string} [params.currency] - The currency to display monetary amounts
 * @param {string} [params.country] - country of user residence
 */
export function createAccountWithoutVerificationAction({
  email,
  password,
  confirmPassword,
  firstName,
  lastName,
  companyName,
  currency,
  country,
  deviceSessionId,
}) {
  return async (dispatch) => {
    const {
      data: { error, data: payload },
    } = await createAccountWithoutVerification({
      emailAddress: email,
      password,
      confirmPassword,
      firstName,
      lastName,
      companyName,
      currency,
      country,
      deviceSessionId,
    });

    if (error) {
      throw new Error(`Error creating your password: ${error}`);
    }

    const { jwtToken, companyId } = payload;
    const tokenData = /**
     * @type {{
     *   userId: Number;
     *   companies: (import('@/reducers/companies').Company & {
     *     role: import('@/constants/permissions').UserRoles;
     *   })[];
     * }}
     */ (jwtDecode(jwtToken));

    dispatch({
      type: AUTHENTICATION_SUCCEEDED,
      payload: {
        ...payload,
        ...tokenData,
        country,
      },
    });

    initAnalytics(
      {
        userId: tokenData.userId,
        email: payload.emailAddress,
        fullName: payload.fullName,
        userRole: USER_ROLES[tokenData.companies[companyId].role],
        createdAt: Date.now(),
      },
      { id: companyId },
    );
  };
}
