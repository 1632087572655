// @ts-check
import { useMemo, useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import CheckmarkSmallIcon from '@bill/cashflow.assets/checkmark-small';
import FeedbackInfoIcon from '@bill/cashflow.assets/feedback-info';
import NotesIcon from '@bill/cashflow.assets/notes';
import WarningIcon from '@bill/cashflow.assets/warning';
import {
  deleteScenarioAction,
  setDefaultScenarioAction,
  duplicateScenarioAction,
  getScenarioAction,
} from '@/actions/scenario';
import ScenarioShareModal from '@/components/Dashboard/ScenarioShareModal';
import ProductTypeFilter from '@/components/ProductTypeFilter/ProductTypeFilter';
import Button from '@/components/common/Button';
import ContextMenu from '@/components/common/ContextMenu';
import InfoTooltip from '@/components/common/InfoTooltip';
import LetterIcon from '@/components/common/LetterIcon';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import NotificationBanner, {
  notificationTypes,
} from '@/components/common/NotificationBanner';
import Permissions from '@/components/common/Permissions';
import { EMPTY_CELL_VALUE } from '@/components/common/Spreadsheet/constants';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import COLORS from '@/constants/colorPalette';
import { actions, subjects } from '@/constants/permissions';
import {
  ADD,
  EDIT,
  DUPLICATE,
  scenarioTypes,
  scenarioTypesText,
  CREATED,
  BUDGET,
} from '@/constants/scenario';
import { classNames } from '@/helpers';
import { getFormattedMonthDayYear } from '@/helpers/dateFormatter';
import useIsFPALite from '@/hooks/useIsFPALite';
import useQBOUnauthorizedIntegrations from '@/hooks/useQBOUnauthorizedIntegrations';
import TheCreateEditDuplicateScenarioModal from './TheCreateEditDuplicateScenarioModal';
import getScenarioText from './helpers';
import './TheManageScenariosModal.scss';

const { MANAGE } = actions;
const { SCENARIOS } = subjects;

const SCENARIO_STATUS_ACTIVE = 'ACTIVE';
const SCENARIO_STATUS_PENDING = 'PENDING';
const SCENARIO_STATUS_FAILED = 'FAILED';

export const SCENARIO_CREATION_LIMIT = 5;

/** @typedef {import('@/reducers/companies').Integration} Integration */

/**
 * @type {(props: {
 *   deleteScenario: (params: number) => void;
 *   duplicateScenario: (
 *     params: import('@/types/scenario').Scenario,
 *   ) => Promise;
 *   onClose: () => void;
 *   scenarioId: number;
 *   scenarios: import('@/types/scenario').Scenario[];
 *   setDefaultScenario: (params: import('@/types/scenario').Scenario) => void;
 *   companyId: number;
 *   getScenario: () => void;
 *   integrations: Integration[];
 * }) => React.ReactElement}
 */
function TheManageScenariosModal({
  deleteScenario,
  duplicateScenario,
  onClose,
  scenarioId,
  scenarios,
  setDefaultScenario,
  companyId,
  getScenario,
  integrations,
}) {
  /** @type {import('@/types/scenario').Scenario} */
  const scenarioInitialState = {
    name: '',
    color: COLORS.finmarkBlue,
    isDefault: false,
    enabledIntegrations: [],
    companyId,
    scenarioId: null,
    isTemplate: false,
    useExternalsForTotalRevenue: false,
    status: null,
    type: scenarioTypes.SCENARIO,
    createdBy: null,
    baseScenarioType: scenarioTypes.SCENARIO,
  };
  const sortedScenarios = useMemo(() => {
    const defaultScenario = scenarios.find((scenario) => scenario.isDefault);
    const nonDefaultScenarios = scenarios
      .filter((scenario) => !scenario.isDefault)
      .sort(
        (a, b) =>
          new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf(),
      );

    return [defaultScenario, ...nonDefaultScenarios];
  }, [scenarios]);

  /**
   * @type {[
   *   import('@/types/scenario').Scenario,
   *   React.Dispatch<import('@/types/scenario').Scenario>,
   * ]}
   */
  const [scenarioToDelete, setScenarioToDelete] = useState(null);
  /**
   * @type {[
   *   import('@/constants/scenario').ScenarioMode,
   *   React.Dispatch<import('@/constants/scenario').ScenarioMode>,
   * ]}
   */
  const [cedModalMode, setCedModalMode] = useState(
    /** @type {import('@/constants/scenario').ScenarioMode} */ (ADD),
  );
  const [showCedModal, setShowCedModal] = useState(false);
  const [scenario, setScenario] = useState(scenarioInitialState);
  const [showShareModal, setShowShareModal] = useState(false);
  /**
   * @type {[
   *   import('@/types/scenario').Scenario,
   *   React.Dispatch<import('@/types/scenario').Scenario>,
   * ]}
   */
  const [scenarioToDuplicate, setscenarioToDuplicate] = useState(null);
  const isLiteProduct = useIsFPALite();
  const createScenarioDisabled =
    isLiteProduct && sortedScenarios.length >= SCENARIO_CREATION_LIMIT;

  const scenarioTypeText = scenarioTypesText[scenarioToDelete?.type];

  const [scenarioNotify, setScenarioNotify] = useState(null);
  const [isScenarioActive, setIsScenarioActive] = useState(false);
  const isQBOUnauthorizedIntegrations = useQBOUnauthorizedIntegrations();

  useEffect(() => {
    if (sortedScenarios) {
      setIsScenarioActive(
        sortedScenarios.every(({ status }) => status === 'ACTIVE'),
      );
    }
  }, [sortedScenarios]);

  useEffect(() => {
    if (companyId) {
      getScenario();
    }
  }, [getScenario, companyId]);

  const handleClose = () => {
    setShowShareModal(false);
  };

  const handleAction = () => {
    setShowShareModal(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleDeleteConfirm = async () => {
    setScenarioNotify(null);
    await deleteScenario(scenarioToDelete.scenarioId);
    setScenarioToDelete(null);
  };

  const showManageScenariosModal = () => {
    return !(scenarioToDelete || showCedModal);
  };

  /** @type {(params: import('@/types/scenario').Scenario) => Promise<void>} */
  const handleDuplicate = async (scenarioToRetryDuplication) => {
    await duplicateScenario(scenarioToRetryDuplication);
    setscenarioToDuplicate(null);
  };

  /** @type {(params: number) => Promise<void>} */
  const handleDuplicateCancel = async (canceledScenarioId) => {
    await deleteScenario(canceledScenarioId);
    setscenarioToDuplicate(null);
  };

  /**
   * @type {(
   *   params: import('@/types/scenario').Scenario,
   * ) => React.ReactElement}
   */
  const scenarioStatus = ({ status, type, duplicatedFrom }) => {
    const baseScenario = scenarios.find(
      (sc) => sc.scenarioId === duplicatedFrom,
    );

    const isDuplication =
      (baseScenario?.type === scenarioTypes.BUDGET &&
        type === scenarioTypes.BUDGET) ||
      (baseScenario?.type === scenarioTypes.SCENARIO &&
        type === scenarioTypes.SCENARIO);

    let pendingStatusMessage;

    if (isDuplication) {
      pendingStatusMessage = 'Duplicating...';
    } else if (type === scenarioTypes.BUDGET) {
      pendingStatusMessage = 'Creating your budget...';
    } else {
      pendingStatusMessage = 'Creating...';
    }

    switch (status) {
      case SCENARIO_STATUS_ACTIVE:
        return <span>Active</span>;
      case SCENARIO_STATUS_PENDING:
        return (
          <span className="ScenariosTable_DuplicatingText">
            {pendingStatusMessage}
          </span>
        );
      case SCENARIO_STATUS_FAILED:
        return (
          <span className="ScenariosTable_FailedText">
            {duplicatedFrom ? 'Duplication' : 'Creation'} Failed
          </span>
        );
      default:
        throw new Error('Unknown Status');
    }
  };

  /** @type {(params: import('@/types/scenario').Scenario) => Promise<void>} */
  const handleCreateBudget = async (scenarioObj) => {
    /** @type {import('@/types/scenario').Scenario} */
    const budget = {
      ...scenarioObj,
      name: `Budget from ${scenarioObj.name}`,
      duplicatedFrom: scenarioObj.scenarioId,
      scenarioId: null,
      isDefault: false,
      type: scenarioTypes.BUDGET,
      enabledIntegrations: [],
      hasAccountingIntegration: false,
      useExternalsForTotalRevenue: false,
    };

    duplicateScenario(budget).then(() => {
      setScenarioNotify({ name: BUDGET, createdType: CREATED });
    });
  };

  /**
   * @type {(
   *   scenarioObj: import('@/types/scenario').Scenario,
   *   scenarioType: import('@/constants/scenario').scenarioTypes,
   * ) => void}
   */
  const handleDuplicateScenario = (scenarioObj, scenarioType) => {
    setCedModalMode(DUPLICATE);
    setShowCedModal(true);
    setScenario({
      ...scenarioObj,
      name: `Copy of ${scenarioObj.name}`,
      duplicatedFrom: scenarioObj.scenarioId,
      scenarioId: null,
      isDefault: false,
      createdBy: null,
      type: scenarioType,
      baseScenarioType: scenarioObj.type,
    });
  };

  return (
    <>
      {scenarioToDelete && (
        <ModalConfirmation
          id="modal-scenario-delete"
          title={`Delete ${scenarioTypeText} "${scenarioToDelete.name}"`}
          actionBtnTxt={`Delete ${getScenarioText(scenarioToDelete?.type)}`}
          onCancel={() => setScenarioToDelete(null)}
          onAction={handleDeleteConfirm}
        >
          Are you sure you would like to delete this{' '}
          {scenarioTypeText.toLowerCase()}?
          <br />
          Once this action has been completed, it cannot be undone and all users
          with access to this {scenarioTypeText.toLowerCase()} will lose it.
          Please confirm below.
        </ModalConfirmation>
      )}
      <TheCreateEditDuplicateScenarioModal
        open={showCedModal}
        id="modal-create-edit-duplicate"
        data-testid="modal-create-edit-duplicate"
        mode={cedModalMode}
        onClose={() => setShowCedModal(false)}
        scenario={scenario}
        onScenarioNotify={(scenarioObj) => setScenarioNotify(scenarioObj)}
      />
      {showManageScenariosModal() && (
        <ModalBase
          id="manage-scenarios"
          onCancel={onClose}
          header={<h2 className="ModalBase_Heading">Manage Scenarios</h2>}
          footer={
            <Button onClick={onClose} data-testid="manage-scenarios-finish">
              Finish
            </Button>
          }
          className="TheManageScenariosModal"
        >
          {isScenarioActive && scenarioNotify && (
            <NotificationBanner
              onCloseClick={() => setScenarioNotify(null)}
              type={notificationTypes.INFO}
            >
              Your {scenarioNotify.name} is {scenarioNotify.createdType}.
            </NotificationBanner>
          )}
          <h3 className="ModalBase_SubHeading">Active Plans</h3>
          <table className="ScenariosTable" data-testid="scenarios-table">
            <thead className="ScenariosTable_Header">
              <tr>
                <td
                  className="ScenariosTable_ColHead ScenariosTable_ColHead-hasBtn"
                  aria-hidden
                />
                <th className="ScenariosTable_ColHead ScenariosTable_ColHead-fixWidth">
                  Scenario Name
                </th>
                <th className="ScenariosTable_ColHead ScenariosTable_ColHead-fixWidth">
                  Type
                </th>
                <th className="ScenariosTable_ColHead ScenariosTable_ColHead-fixWidth">
                  Created By
                </th>
                <th className="ScenariosTable_ColHead ScenariosTable_ColHead-fixWidth">
                  Created On
                </th>
                <th className="ScenariosTable_ColHead ScenariosTable_ColHead-fixWidth">
                  Status
                </th>
                <td className="ScenariosTable_ColHead" aria-label="Notes" />
                <td
                  className="ScenariosTable_ColHead ScenariosTable_ColHead-hasBtn"
                  aria-label="Actions"
                />
              </tr>
            </thead>
            <tbody>
              {sortedScenarios &&
                sortedScenarios.map((scenarioObj) => (
                  <Fragment key={scenarioObj.scenarioId}>
                    <tr data-testid={scenarioObj.name}>
                      <td className="ScenariosTable_Cell" aria-hidden>
                        <LetterIcon
                          string={scenarioObj.name}
                          color={
                            scenarioObj.status === SCENARIO_STATUS_ACTIVE
                              ? scenarioObj.color
                              : '#e5e5e5'
                          }
                          data-testid={`letter-icon-${scenarioObj.scenarioId}`}
                          transition={{ duration: 0 }}
                          disabled={
                            scenarioObj.status !== SCENARIO_STATUS_ACTIVE
                          }
                        />
                      </td>
                      <td className="ScenariosTable_Cell">
                        <div className="d-flex">
                          <TooltipTextOverflow
                            label={
                              <>
                                {scenarioObj.name}
                                {scenarioObj.isDefault && (
                                  <CheckmarkSmallIcon
                                    className="ScenariosTable_DefaultIcon"
                                    aria-label="Default"
                                    data-testid="default-scenario-icon"
                                  />
                                )}
                              </>
                            }
                            data-testid="ScenariosTable_Name"
                            className={classNames(
                              'ScenariosTable_Name',
                              scenarioObj.status !== SCENARIO_STATUS_ACTIVE &&
                                'ScenariosTable_DisabledText',
                            )}
                          />
                        </div>
                      </td>
                      <td className="ScenariosTable_Cell">
                        {scenarioTypesText[scenarioObj.type]}
                      </td>
                      <td className="ScenariosTable_Cell">
                        {scenarioObj.createdBy ?? EMPTY_CELL_VALUE}
                      </td>
                      <td className="ScenariosTable_Cell">
                        {getFormattedMonthDayYear(scenarioObj.createdDate) ??
                          EMPTY_CELL_VALUE}
                      </td>
                      <td className="ScenariosTable_Cell">
                        {scenarioStatus(scenarioObj)}
                      </td>
                      <td className="ScenariosTable_Cell">
                        {scenarioObj.notes && (
                          <InfoTooltip
                            data-testid="TheManageScenariosModal-tooltip"
                            placement="right"
                            icon={
                              <NotesIcon
                                className="NotesIcon"
                                aria-hidden="true"
                              />
                            }
                          >
                            {scenarioObj.notes}
                          </InfoTooltip>
                        )}
                      </td>
                      <td className="ScenariosTable_Cell">
                        <ContextMenu
                          data-testid={`scenario-action-${scenarioObj.scenarioId}`}
                          disabled={
                            scenarioObj.status !== SCENARIO_STATUS_ACTIVE
                          }
                        >
                          {!scenarioObj.isDefault && (
                            <ContextMenu.Option
                              onClick={() => setDefaultScenario(scenarioObj)}
                              data-testid="set-default"
                            >
                              Set as Default
                            </ContextMenu.Option>
                          )}
                          {scenarioObj.type === scenarioTypes.SCENARIO ? (
                            <ContextMenu.Option
                              onClick={() => handleCreateBudget(scenarioObj)}
                              data-testid="create-budget"
                              disabled={createScenarioDisabled}
                            >
                              Create a budget
                            </ContextMenu.Option>
                          ) : null}

                          {scenarioObj.type === scenarioTypes.BUDGET && (
                            <ContextMenu.Option
                              onClick={() =>
                                handleDuplicateScenario(
                                  scenarioObj,
                                  scenarioTypes.SCENARIO,
                                )
                              }
                              data-testid="create-budget"
                              disabled={
                                isQBOUnauthorizedIntegrations ||
                                createScenarioDisabled
                              }
                            >
                              Create a scenario
                            </ContextMenu.Option>
                          )}
                          <ContextMenu.Option
                            onClick={() => {
                              setCedModalMode(EDIT);
                              setShowCedModal(true);
                              setScenario(scenarioObj);
                            }}
                            data-testid="edit-scenario"
                          >
                            Edit
                          </ContextMenu.Option>
                          <ProductTypeFilter>
                            <Permissions action={MANAGE} subject={SCENARIOS}>
                              <ContextMenu.Option
                                onClick={() => {
                                  setScenario(scenarioObj);
                                  setShowShareModal(true);
                                }}
                                data-testid="share-scenario"
                              >
                                Share
                              </ContextMenu.Option>
                            </Permissions>
                          </ProductTypeFilter>
                          <ContextMenu.Option
                            onClick={() =>
                              handleDuplicateScenario(
                                scenarioObj,
                                scenarioObj.type,
                              )
                            }
                            data-testid="duplicate-scenario"
                            disabled={createScenarioDisabled}
                          >
                            Duplicate
                          </ContextMenu.Option>
                          <ContextMenu.Option
                            onClick={() => setScenarioToDelete(scenarioObj)}
                            danger
                            disabled={scenarioObj.isDefault}
                            data-testid="delete-scenario"
                          >
                            Delete
                          </ContextMenu.Option>
                        </ContextMenu>
                      </td>
                    </tr>
                    {scenarioObj.status === SCENARIO_STATUS_FAILED && (
                      <tr>
                        <td aria-hidden />
                        <td aria-hidden />
                        <td>
                          <div className="ScenariosTable_FailOptionsContainer">
                            <Button
                              className="Button-cancelLink"
                              data-testid="cancel-failed-scenario"
                              disabled={
                                scenarioObj.scenarioId ===
                                scenarioToDuplicate?.scenarioId
                              }
                              onClick={() => {
                                setscenarioToDuplicate(scenarioObj);
                                handleDuplicateCancel(scenarioObj.scenarioId);
                              }}
                            >
                              Cancel
                            </Button>
                            {scenarioObj.duplicatedFrom && (
                              <Button
                                className="Button-primaryLink"
                                data-testid="try-again-failed-scenario"
                                disabled={
                                  scenarioObj.scenarioId ===
                                  scenarioToDuplicate?.scenarioId
                                }
                                onClick={() => {
                                  setscenarioToDuplicate(scenarioObj);
                                  handleDuplicate(scenarioObj);
                                }}
                              >
                                Try Again
                              </Button>
                            )}
                          </div>
                        </td>
                        <td aria-hidden />
                        <td aria-hidden />
                      </tr>
                    )}
                  </Fragment>
                ))}
            </tbody>
          </table>
          <Button
            disabled={isQBOUnauthorizedIntegrations || createScenarioDisabled}
            data-testid="add-new-scenario-btn"
            className="Button-primaryLink"
            onClick={() => {
              setScenario(scenarioInitialState);
              setCedModalMode(ADD);
              setShowCedModal(true);
            }}
          >
            + Add a new scenario
          </Button>
          {createScenarioDisabled && (
            <div className="ScenariosButton">
              <WarningIcon
                className="ScenariosButton_WarningIcon"
                aria-label="Warning"
                data-testid="warning-scenario-icon"
              />{' '}
              <p
                className="ScenariosButton_WarningText"
                data-testid="add-scenario-warning-text"
              >
                You can create only up to {SCENARIO_CREATION_LIMIT}{' '}
                scenario/budget plans. To create additional scenarios/budget,
                please delete existing ones
              </p>
            </div>
          )}
          {isQBOUnauthorizedIntegrations && (
            <div className="ScenariosButton">
              <FeedbackInfoIcon
                className="ScenariosButton_DangerIcon"
                aria-label="Danger"
                data-testid="danger-scenario-icon"
              />
              <p
                className="ScenariosButton_DangerText"
                data-testid="deauthorized-scenario-error-text"
              >
                Creating new scenario has been disabled because your integration
                is deauthorized. Please reconnect/reauthorize your integration
                to create new scenario.
              </p>
            </div>
          )}
        </ModalBase>
      )}
      {showShareModal && (
        <ScenarioShareModal
          handleClose={handleClose}
          handleAction={handleAction}
          scenarios={scenarios}
          selectedScenarioId={scenario.scenarioId}
        />
      )}
    </>
  );
}

/**
 * @type {(params: import('@/store').RootState) => {
 *   scenarios: import('@/types/scenario').Scenario[];
 *   scenarioId: number;
 *   companyId: number;
 *   integrations: Integration[];
 * }}
 */
function mapStateToProps({ scenario, companies, settings }) {
  return {
    scenarios: scenario.scenarios,
    scenarioId: scenario.scenarioId,
    companyId: companies.selectedCompanyId,
    integrations: settings.integrations,
  };
}

export default connect(mapStateToProps, {
  deleteScenario: deleteScenarioAction,
  setDefaultScenario: setDefaultScenarioAction,
  duplicateScenario: duplicateScenarioAction,
  getScenario: getScenarioAction,
})(TheManageScenariosModal);
