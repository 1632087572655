// @ts-check
import FacebookIcon from '@bill/cashflow.assets/facebook';
import LinkedinIcon from '@bill/cashflow.assets/linkedin';
import TwitterIcon from '@bill/cashflow.assets/twitter';
import CoBranding from '@/components/Landing/CoBranding';
import { classNames, getDomainAndResourceHost } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';
import Header from '@/pages/Landing/Header';
import WelcomeMessage from '@/pages/Landing/WelcomeMessage';

const Message = () => <>Welcome.</>;
/**
 * Landing Page Container
 *
 * @typedef {{
 *   className?: string;
 *   children: React.ReactNode;
 * }} LandingProps
 * @type {(props: LandingProps) => React.ReactElement}
 */

const Landing = ({ className, children }) => {
  const { subDomain, resourceHost } = getDomainAndResourceHost();
  const showPartnerUi = !isEmptyOrNull(subDomain);
  return (
    <div
      className={classNames(
        'Landing_Container',
        showPartnerUi && 'Landing_Container-partnerUi',
        className,
      )}
    >
      <Header />
      <main className="landing-Content">
        <div className="landing-Content_Container">
          <div className="Landing_Sidebar">
            {showPartnerUi && (
              <CoBranding subDomain={subDomain} resourceHost={resourceHost} />
            )}
            <WelcomeMessage Heading={showPartnerUi ? Message : undefined} />
          </div>
          {children}
        </div>
      </main>
      <footer className="landing-Footer">
        <div className="landing-Footer_Container">
          <a href="https://finmark.com/" className="landing-Footer_LogoLink">
            <img
              src="https://finmark.com/wp-content/themes/finmark-wp/assets/images/logo-solid-white.svg"
              className="landing-Footer_Logo"
              alt="Finmark"
            />
          </a>
          <nav className="landing-Footer_Nav">
            <a
              href="https://finmark.com/pricing/"
              className="landing-Footer_NavItem"
            >
              Pricing
            </a>
            <a
              href="https://finmark.com/blog/"
              className="landing-Footer_NavItem"
            >
              Blog
            </a>
            <a
              href="https://finmark.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
              className="landing-Footer_NavItem"
            >
              Privacy Notice
            </a>
            <a
              href="https://finmark.com/terms-conditions/"
              target="_blank"
              rel="noopener noreferrer"
              className="landing-Footer_NavItem"
            >
              Terms of Service
            </a>
            <a
              href="https://finmark.com/do-not-sell-my-personal-information/"
              target="_blank"
              rel="noopener noreferrer"
              className="landing-Footer_NavItem"
            >
              Do Not Sell My Personal Information
            </a>
          </nav>
          <div className="landing-Footer_Social">
            <a
              href="https://www.facebook.com/finmarkhq"
              className="landing-Footer_SocialItem landing-Footer_SocialItem-facebook"
            >
              <FacebookIcon
                className="landing-Footer_SocialIcon"
                aria-label="Facebook"
              />
            </a>
            <a
              href="https://twitter.com/finmarkhq"
              className="landing-Footer_SocialItem"
            >
              <TwitterIcon
                className="landing-Footer_SocialIcon"
                aria-label="Twitter"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/finmark/"
              className="landing-Footer_SocialItem"
            >
              <LinkedinIcon
                className="landing-Footer_SocialIcon"
                aria-label="LinkedIn"
              />
            </a>
          </div>
        </div>
        <p className="landing-Footer_Copyright">
          Copyright &copy;2022 Finmark - All Rights Reserved
        </p>
      </footer>
    </div>
  );
};

export default Landing;
