// @ts-check
import {
  LOGOUT,
  SET_DATE,
  SET_WEBSOCKET_FAILED,
  RESET_TIME_PERIOD,
} from '@/actionTypes/shared';
import { MONTHLY } from '@/constants/dateTime';
import {
  getDateOffsetByMonths,
  getFormattedDateFromTimeStamp,
} from '@/helpers/dateFormatter';

/**
 * @typedef {{
 *   timePeriod:
 *     | MONTHLY
 *     | import('@/constants/dateTime').QUARTERLY
 *     | import('@/constants/dateTime').ANNUALLY;
 *   webSocketFailed: boolean;
 * }} SharedSessionState
 */

/**
 * @typedef {{
 *   startDate: string;
 *   endDate: string;
 * } & SharedSessionState} SharedState
 */

// SESSION_STATE and INITIAL_STATE are separated so that items in
// INITIAL_STATE, but not SESSION_STATE, will be persisted via Redux Persist
// in localStorage even if the user logs out of the application.
/** @type {SharedSessionState} */
const SESSION_STATE = {
  timePeriod: MONTHLY,
  webSocketFailed: false,
};

/** @type {SharedState} */
const INITIAL_STATE = {
  startDate: getFormattedDateFromTimeStamp(
    getDateOffsetByMonths(new Date(), -1),
  ),
  endDate: getFormattedDateFromTimeStamp(getDateOffsetByMonths(new Date(), 12)),
  ...SESSION_STATE,
};

/**
 * @type {(
 *   state: SharedState,
 *   action: { payload: unknown; type: string },
 * ) => SharedState}
 */
const shared = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return { ...state, ...SESSION_STATE };
    case SET_DATE: {
      const { payload } = /** @type {{ payload: SharedState }} */ (action);
      return {
        ...state,
        startDate: payload.startDate,
        endDate: payload.endDate,
        timePeriod: payload.timePeriod,
      };
    }
    case RESET_TIME_PERIOD:
      return { ...state, timePeriod: MONTHLY };
    case SET_WEBSOCKET_FAILED:
      return {
        ...state,
        webSocketFailed: /** @type {boolean} */ (action.payload),
      };

    default:
      return state;
  }
};
export default shared;
