import PropTypes from 'prop-types';
import ErrorBoundary from '@/components/common/ErrorBoundary';
import Button from './Button';
import { ReactComponent as FailureImage } from '@/assets/images/failure.svg';
import './CommonErrorBoundary.scss';

function CommonError({ onReset, text, onClose }) {
  return (
    <>
      <div className="CommonErrorBoundary">
        <FailureImage className="CommonErrorBoundary_Image" onClick={onReset} />
        <p className="CommonErrorBoundary_Text">{text}</p>
        <button className="CommonErrorBoundary_TryAgain" onClick={onReset}>
          Try again
        </button>
      </div>
      {onClose && (
        <Button className="CommonErrorBoundary_CloseButton" onClick={onClose}>
          Close
        </Button>
      )}
    </>
  );
}

/**
 * Renders a user-friendly error message when the given children fail to render.
 * Used for charts, tables and other common components.
 *
 * @example
 *   <CommonErrorBoundary text="An error occurred">
 *     <Component />
 *   </CommonErrorBoundary>;
 */
function CommonErrorBoundary({ children, text, onClose }) {
  return (
    <ErrorBoundary
      FallbackComponent={CommonError}
      text={text}
      onClose={onClose}
    >
      {children}
    </ErrorBoundary>
  );
}

CommonErrorBoundary.propTypes = {
  /** Component(s) from which to catch errors */
  children: PropTypes.node,
  /** Error message to display */
  text: PropTypes.string.isRequired,
  /**
   * Event handler for when the user clicks the close button. If no handler is
   * provided, the button will not appear.
   */
  onClose: PropTypes.func,
};

export default CommonErrorBoundary;
