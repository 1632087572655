// @ts-check
import { useRef, useMemo, createContext, useEffect, useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import {
  createNotificationAction,
  deleteNotificationAction,
} from '@/actions/notifications';
import { notificationIds } from '@/components/common/Notifications/constants';
import TheNotificationBar from '@/components/common/TheNotificationBar';
import isBillDomain from '@/helpers/isBillDomain';
import useElementSize from '@/hooks/useElementSize';
import useGlobalTopNavContext from './useGlobalTopNavContext';

export const NotificationContext = createContext({ margin: 0 });

/** @type {(props: { children: React.ReactNode }) => React.ReactElement} */
const NotificationProvider = ({ children }) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const { margin } = useGlobalTopNavContext();
  const notificationBar = useRef(null);
  const notificationBarObserver = useElementSize(notificationBar);

  const { notifications } = useSelector(
    (/** @type {import('@/store').RootState} */ state) => state.notifications,
  );
  const country = useSelector(
    (/** @type {import('@/store').RootState} */ state) =>
      state.auth.userInfo?.country,
  );

  const checkUserCountryNotification = useCallback(() => {
    const { PENDING_USER_COUNTRY } = notificationIds;
    const pendingUserCountryNotification = notifications[PENDING_USER_COUNTRY];
    if (!country && !pendingUserCountryNotification && !isBillDomain()) {
      dispatch(createNotificationAction({ id: PENDING_USER_COUNTRY }));
    } else if (country && pendingUserCountryNotification) {
      dispatch(deleteNotificationAction({ id: PENDING_USER_COUNTRY }));
    }
  }, [notifications, country, dispatch]);

  useEffect(() => {
    checkUserCountryNotification();
  }, [checkUserCountryNotification]);

  const value = useMemo(
    () => ({
      margin: (notificationBarObserver.contentRect?.height ?? 0) + margin,
    }),
    [notificationBarObserver, margin],
  );

  return (
    <>
      <TheNotificationBar ref={notificationBar} style={{ marginTop: margin }} />
      <NotificationContext.Provider value={value}>
        {children}
      </NotificationContext.Provider>
    </>
  );
};

export default NotificationProvider;
