import { Route } from 'react-router-dom';
import { m as motion } from 'framer-motion';
import PropTypes from 'prop-types';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';

const ERROR_BOUNDARY_TEXT =
  'An error occured while trying to display this tab.';

/**
 * A panel with an associated tab, activated by clicking the tab or routing to
 * the given URL.
 *
 * @example
 *   <Tabs>
 *     <Tabs.Panel path="/foo" label="Foo">
 *       ...
 *     </Tabs.Panel>
 *   </Tabs>;
 */
function TabsPanel({ children, path, ...props }) {
  return (
    <Route exact path={path}>
      <CommonErrorBoundary text={ERROR_BOUNDARY_TEXT}>
        <motion.div id={path} role="tabpanel" {...props}>
          {children}
        </motion.div>
      </CommonErrorBoundary>
    </Route>
  );
}

TabsPanel.displayName = 'Tabs.Panel';
TabsPanel.propTypes = {
  /** Content to populate the panel */
  children: PropTypes.node,
  /** Label for the panel's tab in the tab bar */
  // eslint-disable-next-line react/no-unused-prop-types -- predates description requirement
  label: PropTypes.string.isRequired,
  /** Relative URL which will activate the panel */
  path: PropTypes.string.isRequired,
};

export default TabsPanel;
