import { useState, useRef, useMemo, useCallback } from 'react';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import { getEndDateMetric } from '@/components/Charts/helpers';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import formatMonetary from '@/helpers/formatMonetary';
import useCallbackRef from '@/hooks/useCallbackRef';
import useChartTableData from '@/hooks/useChartTableData';
import useDashboardPath from '@/hooks/useDashboardPath';
import useTypedSelector from '@/hooks/useTypedSelector';
import { valueFormatter } from '@/pages/Actuals/helpers';
import CashBalanceChart from '@/pages/Dashboard/charts/CashBalanceChart';
import CASH_BALANCE_METRICS from '@/pages/Dashboard/constants/cashBalance';

/**
 * @typedef {{
 *   field: string;
 *   headerName: string;
 * }} CashBalanceTableEntry
 */

/** @typedef {import('ag-grid-community').ColDef<CashBalanceTableEntry>[]} CashFlowColumnDefs */

/** @type {CashFlowColumnDefs} */

const CHART_TITLE = 'Cash Balance';
const SPREADSHEET_ID = 'cash-balance-chart-table';

const CashBalance = () => {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState();
  const [chartData, setChartData] = useState([]);
  const [endDate, setEndDate] = useState('');
  const chartRef = useRef();
  const [gridApi, gridRef] = useCallbackRef();
  const dashboardPath = useDashboardPath();
  const timePeriod = useTypedSelector(({ shared }) => shared.timePeriod);

  const colDefs = useMemo(
    () => [
      {
        field: 'name',
        headerName: CHART_TITLE,
      },
    ],
    [],
  );

  const tableData = useChartTableData(chartData, CASH_BALANCE_METRICS);
  const onQueryStateChange = useCallback(
    (data) => {
      const [
        {
          isLoading,
          data: { previousPeriodTotal, previousPeriodLabel },
        },
      ] = data;
      setLoading(isLoading);
      setTotal(previousPeriodTotal);
      setChartData(data);
      setEndDate(getEndDateMetric(timePeriod, previousPeriodLabel));
    },
    [timePeriod],
  );
  return (
    <>
      <section className="Panel Panel-toEdge CashBalance">
        <ChartHeader back={dashboardPath} title={CHART_TITLE}>
          <div className="ChartHeader_KPIValue">
            {loading ? '' : formatMonetary(total)}
          </div>
          <div className="ChartHeader_KPI">
            <span className="ChartHeader_Timespan">{endDate}</span>
          </div>
        </ChartHeader>
        <ChartToolbar>
          <ChartControls
            chartRef={chartRef}
            data-testid="cashBalance-chartControls"
            title={CHART_TITLE}
            metricValue={formatMonetary(total)}
            label={endDate}
          />
        </ChartToolbar>
        <CashBalanceChart
          className="ExpandedView_ChartWrapper"
          exportBtn={chartRef}
          onQueryStateChange={onQueryStateChange}
        />
      </section>
      <section className="Panel Panel-toEdge">
        <SpreadsheetToolbar editable={false} gridApi={gridApi}>
          Options:
          <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
        </SpreadsheetToolbar>
        <MonthlySpreadsheet
          ref={gridRef}
          columnDefs={colDefs}
          data={tableData}
          data-testid={SPREADSHEET_ID}
          editable={false}
          enableComparison
          getRowId={({ data }) => data.name}
          valueFormatter={valueFormatter}
        />
      </section>
    </>
  );
};

export default CashBalance;
