import { formulaMonthRendererSelector } from '@/components/common/MonthlySpreadsheet/helpers';
import CircularRefCellRenderer from '@/components/common/Spreadsheet/renderers/CircularRefCellRenderer';
import { iconTypes } from '@/constants/actuals';
import detectCircularRef from '@/helpers/circularReference';
import { isEmptyOrNull } from '@/helpers/validators';

/**
 * @typedef {(params: import('ag-grid-community').ICellRendererParams) => {
 *   component: undefined | React.ReactNode;
 * }} CellRendererSelectorFn
 */

/** @type {CellRendererSelectorFn} */
const defaultRenderer = (_params) => ({ component: undefined });

/**
 * A cellRendererSelector for monthly values for showing circular reference
 * errors
 *
 * @type {(params: import('ag-grid-community').ICellRendererParams) => {
 *   component: undefined | React.ReactNode;
 * }}
 */
const getCellRendererSelector =
  (renderer = defaultRenderer) =>
  (context) => {
    const { data, colDef } = context;
    const month = data?.months?.find(({ date }) => {
      return `${date}_${colDef.field}` === colDef.colId;
    });
    if (!month) return renderer(context);
    const value = month.value.find(({ scenarioId }) => {
      return scenarioId === Number(colDef.field);
    });
    if (value?.type === iconTypes.EXTERNAL_SOURCE_GENERATED) {
      return defaultRenderer(context);
    }
    if (isEmptyOrNull(value) || !detectCircularRef(value))
      return renderer(context);

    return {
      component: CircularRefCellRenderer,
    };
  };

/**
 * A cellRendererSelector for monthly values for showing circular reference
 * errors
 *
 * @type {CellRendererSelectorFn}
 */
const cellRendererSelector = getCellRendererSelector();

/**
 * A cellRendererSelector for monthly values which accept formulas and displays
 * circular reference errors
 *
 * @type {CellRendererSelectorFn}
 */
export const formulaCircularRefCellRenderSelector = getCellRendererSelector(
  formulaMonthRendererSelector,
);

export default cellRendererSelector;
