import { connect } from 'react-redux';
import { setShowReviewEmployeeModalAction } from '@/actions/employees';
import ReviewEmployeeRecords from '@/components/Employee/PendingEmployees/ReviewEmployeeRecords';
import Modal from '@/components/common/Modal';
import './TheReviewEmployeeRecordsModal.scss';

const TheReviewEmployeeRecordsModal = ({
  showReviewEmployeeModal,
  setShowReviewEmployeeModal,
}) => {
  const handleCloseReviewEmployeeModal = () => {
    setShowReviewEmployeeModal(false);
  };

  return (
    <Modal
      open={showReviewEmployeeModal}
      onClose={handleCloseReviewEmployeeModal}
      className="TheReviewEmployeeRecordsModal"
      data-testid="review-employee-modal"
    >
      <ReviewEmployeeRecords onClose={handleCloseReviewEmployeeModal} />
    </Modal>
  );
};

const mapStateToProps = ({ employees }) => ({
  showReviewEmployeeModal: employees.showReviewEmployeeModal,
});

export default connect(mapStateToProps, {
  setShowReviewEmployeeModal: setShowReviewEmployeeModalAction,
})(TheReviewEmployeeRecordsModal);
