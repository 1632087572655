import { useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import ComponentLoader from '@/components/common/ComponentLoader';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import useDashboardPath from '@/hooks/useDashboardPath';
import AverageRevenuePerAccountChart from './charts/AverageRevenuePerAccountChart';
import { getMetricValue } from './helpers';

const CHART_TITLE = 'Average Revenue Per Account';

function AverageRevenuePerAccountExpanded({ startDate, endDate }) {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState();
  const dateChartRef = useRef();
  const dashboardPath = useDashboardPath();

  const kpiValue = getMetricValue(total, formatMonetary);
  const onQueryStateChange = useCallback(({ data, isLoading }) => {
    setLoading(isLoading);
    setTotal(data.overallAverageValue);
  }, []);

  return (
    <section className="Panel Panel-toEdge">
      <ChartHeader back={dashboardPath} title={CHART_TITLE}>
        <div className="ChartHeader_KPIValue">{loading ? '' : kpiValue}</div>
        <div className="ChartHeader_KPI">
          <br />
          {formatRangeWithShortYear(startDate, endDate)}
        </div>
      </ChartHeader>
      <ComponentLoader loadingComponent="arpa" paddingTop="0%" />
      <ChartToolbar>
        <ChartControls
          chartRef={dateChartRef}
          title={CHART_TITLE}
          metricValue={formatMonetary(total)}
          label={formatRangeWithShortYear(startDate, endDate)}
        />
      </ChartToolbar>
      <div className="ExpandedView_ChartWrapper">
        <AverageRevenuePerAccountChart
          exportBtn={dateChartRef}
          onQueryStateChange={onQueryStateChange}
        />
      </div>
    </section>
  );
}

function mapStateToProps({ shared }) {
  return {
    startDate: shared.startDate,
    endDate: shared.endDate,
  };
}

export default connect(mapStateToProps)(AverageRevenuePerAccountExpanded);
