import CircularReferenceIcon from '@bill/cashflow.assets/circular-reference';
import PropTypes from 'prop-types';
import {
  CIRCULAR_REFERENCE_HEADING,
  CIRCULAR_REFERENCE_WARNING,
} from '@/constants/circularReference';
import WithTooltip from './WithTooltip';
import './CircularReference.scss';

/**
 * A component for showing Circular Reference
 *
 * @example
 *   <Table>
 *     ...
 *     <tr>
 *       <td>
 *         <CircularReference />
 *         Foo
 *       </td>
 *       ...
 *     </tr>
 *   </Table>;
 */
const CircularReference = ({ 'data-testid': dataTestId }) => (
  <WithTooltip
    content={
      <>
        <div
          className="InfoTooltip_Title"
          data-testid="revenue-table-tooltip-heading"
        >
          {CIRCULAR_REFERENCE_HEADING}
        </div>
        <span
          className="InfoTooltip_Text"
          data-testid="date-table-tooltip-info"
        >
          {CIRCULAR_REFERENCE_WARNING}
        </span>
      </>
    }
  >
    <span className="CircularReference" data-testid={dataTestId}>
      <CircularReferenceIcon />
    </span>
  </WithTooltip>
);

CircularReference.propTypes = {
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string,
};

export default CircularReference;
