import { gql } from '@apollo/client';

/**
 * Query to get session info from GQL service. Most often used in other queries
 * for the organization ID returned from this query
 */
export const GET_BILL_SESSION_INFO = gql`
  query GetSessionInfo {
    sessionInfo {
      organizationId
      userId
      organization {
        displayName
        accountingSoftware
      }
      profile {
        type
        name
      }
      user {
        loginId
        firstName
        lastName
        email
        createdTime
      }
      neoFunctionality {
        OrgFeatures {
          isPlanningAvailable
          isPlanningAvailableForUpgrade
          isInsightsAccessibleToUser
          isPlanningAccessibleToUser
        }
      }
    }
  }
`;

/**
 * Query to get feature flags from FFaaS GQL Service. Requires usage of the
 * organizationId returned from the GET_BILL_SESSION_INFO query
 */
export const GET_FEATURE_FLAGS = gql`
  query GetFlag(
    $orgId: String!
    $orgProps: FfaasOrgProperties!
    $project: String
  ) {
    getFlags(orgId: $orgId, orgProps: $orgProps, project: $project) {
      feature_flags {
        data
      }
    }
  }
`;
