import { useState } from 'react';
import DropdownArrowIcon from '@bill/cashflow.assets/dropdown-arrow';
import { AnimatePresence, m as motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import useOneColor from '@/hooks/useOneColor';
import './Accordion.scss';

const ANIM_TRANSITION = window.Cypress
  ? { duration: 0 }
  : {
      type: 'spring',
      mass: 0.2,
    };

const ANIM_VARIANTS_OPEN = 'open';
const ANIM_VARIANTS_COLLAPSED = 'collapsed';

const ANIM_VARIANTS = {
  [ANIM_VARIANTS_OPEN]: { opacity: 1, height: 'auto' },
  [ANIM_VARIANTS_COLLAPSED]: { opacity: 0, height: 0 },
};
/**
 * Creates an expandable/collapsible Accordion
 *
 * @example
 *   <Accordion defaultExpanded={true} label="My Accordion">
 *     Inner content of the Accordion
 *   </Accordion>;
 */
const Accordion = ({ defaultExpanded = false, label, children }) => {
  const [isOpen, setIsOpen] = useState(defaultExpanded);
  const isOneColorEnabled = useOneColor();

  return (
    <div className="Accordion">
      <div className="Accordion_Title">
        <button
          className="Accordion_Toggle"
          aria-expanded={isOpen}
          onClick={() => setIsOpen(!isOpen)}
          aria-label={isOpen ? 'Collapsed' : 'Expanded'}
        >
          <span
            className={classNames('expand-collapse-icon', isOpen && 'expanded')}
          />
          {isOneColorEnabled && (
            <DropdownArrowIcon className="Accordion_DownArrowIcon" />
          )}
          {label}
        </button>
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            className="Accordion_Content"
            initial={ANIM_VARIANTS_COLLAPSED}
            animate={ANIM_VARIANTS_OPEN}
            exit={ANIM_VARIANTS_COLLAPSED}
            variants={ANIM_VARIANTS}
            transition={ANIM_TRANSITION}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

Accordion.propTypes = {
  /** Whether the Accordion should be expanded initially. */
  defaultExpanded: PropTypes.bool,
  /** The heading text of the accordion */
  label: PropTypes.string.isRequired,
  /** Contents of the Accordion */
  children: PropTypes.node,
};

export default Accordion;
