import { useEffect, useCallback } from 'react';
import { YAxis } from 'react-jsx-highcharts';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { CHURNED_MRR } from '@/cacheKeys';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import { LABEL_STYLES } from '@/components/Charts/chartDefaults';
import {
  getYAxisConfig,
  getYAxisConfigPercent,
} from '@/components/Charts/helpers';
import { INVALID_POP_VALUE_TOOLTIP_TEXT } from '@/constants/charts';
import { formatPercent } from '@/helpers';
import formatMonetary from '@/helpers/formatMonetary';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import metricFormatters from '@/helpers/metricFormatters';
import { isEmptyOrNull } from '@/helpers/validators';
import useChartQuery from '@/hooks/useChartQuery';
import { getMetricValue } from '@/pages/Dashboard/helpers';
import { getChurnedMRR } from '@/services/dashboard.service';

export const CHURNED_MRR_TOGGLE_ID = 'churnedMrr-toggle';

const MAIN_METRIC = 'Churned MRR';
const PERCENTAGE_MAIN_METRIC = 'Churned %';

const CHURNED_MRR_CURRENCY_TOOLTIP_METRICS = [
  {
    name: 'Beginning MRR',
    key: 'beginningMrr',
  },
  {
    name: 'New MRR',
    key: 'newMrr',
  },
  {
    name: MAIN_METRIC,
    isMainMetric: true,
    key: 'y',
  },
  {
    name: 'Ending MRR',
    key: 'endingMrr',
  },
];

const CHURNED_MRR_PERCENTAGE_TOOLTIP_METRICS = [
  {
    name: 'Current Churned MRR',
    key: 'churnMrr',
    formatter: metricFormatters.monetary,
  },
  {
    name: 'Prior Ending MRR',
    key: 'beginningMrr',
    formatter: metricFormatters.monetary,
  },
  {
    name: PERCENTAGE_MAIN_METRIC,
    isMainMetric: true,
    key: 'y',
    formatter: metricFormatters.percentLegacy,
  },
];

/**
 * Fetches and renders a line chart showing churned Mrr
 *
 * @example
 *   <ChurnedMrrChart exportBtn={exportBtn} />;
 */
function ChurnedMrrChart({
  className,
  exportBtn,
  plotOptions,
  axisStyles,
  tooltipOptions,
  showPercentage,
  onQueryStateChange,
}) {
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );

  const reducer = useCallback(
    ({ churnMrrData, totalChurnMrr, compoundPeriodGrowthRate }) => {
      const data = showPercentage
        ? mapMonthlyData(churnMrrData, 'periodOverPeriodPercentage')
        : mapMonthlyData(churnMrrData, 'churnMrr');
      const metric = showPercentage
        ? getMetricValue(compoundPeriodGrowthRate, formatPercent)
        : getMetricValue(totalChurnMrr, formatMonetary);
      return {
        ...data,
        metric,
        tooltipText:
          showPercentage &&
          isEmptyOrNull(compoundPeriodGrowthRate) &&
          INVALID_POP_VALUE_TOOLTIP_TEXT,
      };
    },
    [showPercentage],
  );

  const scenarioQueries = useChartQuery(CHURNED_MRR, getChurnedMRR, reducer);

  const [base] = scenarioQueries;

  useEffect(() => {
    if (base) onQueryStateChange?.(base);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [base]);

  const YAXIS_DEFAULTS = showPercentage
    ? getYAxisConfigPercent()
    : getYAxisConfig();
  const yAxisConfigs = {
    ...YAXIS_DEFAULTS,
    labels: {
      ...YAXIS_DEFAULTS.labels,
      style: axisStyles || LABEL_STYLES,
    },
  };

  return (
    <DateChart
      className={className}
      data-testid="churnedMRRChart"
      loading={scenarioQueries.every(({ isLoading }) => isLoading)}
      tooltip={
        <ChartTooltip
          metrics={
            showPercentage
              ? CHURNED_MRR_PERCENTAGE_TOOLTIP_METRICS
              : CHURNED_MRR_CURRENCY_TOOLTIP_METRICS
          }
          valueFormatter={
            showPercentage
              ? metricFormatters.percentLegacy
              : metricFormatters.monetary
          }
          endDate={endDate}
          timePeriod={timePeriod}
          {...tooltipOptions}
        />
      }
      plotOptions={plotOptions}
      axisStyles={axisStyles}
      ref={exportBtn}
      startDate={startDate}
      endDate={endDate}
      timePeriod={timePeriod}
    >
      <YAxis {...yAxisConfigs}>
        {scenarioQueries.map(
          ({ data, scenario }, idx) =>
            data && (
              <DateChart.Series
                key={scenario.scenarioId}
                data={data.data}
                index={idx}
                metric={MAIN_METRIC}
                scenario={scenario}
                isComparison={idx === 1}
              />
            ),
        )}
      </YAxis>
    </DateChart>
  );
}

export default ChurnedMrrChart;
