import { forwardRef, useEffect } from 'react';
import { m as motion } from 'framer-motion';
import PropTypes from 'prop-types';
import keyConstants from '@/constants/keys';
import { classNames } from '@/helpers';
import './DropdownMenu.scss';

function handleKeyUp({ currentTarget, key, target }) {
  let offset;
  if (key === keyConstants.ARROW_UP) {
    offset = -1;
  } else if (key === keyConstants.ARROW_DOWN) {
    offset = 1;
  } else {
    return;
  }
  const menuItems = [...currentTarget.children];
  const index = menuItems.indexOf(target) + offset;
  if (index > -1 && index < menuItems.length) {
    menuItems[index].focus();
  }
}

/**
 * Renders a menu to be triggered as a dropdown. Must be contained inside a
 * parent with relative positioning.
 *
 * @example
 *   <DropdownMenu id="foo" expanded={isExpanded}>
 *     <DropdownOption>Option 1</DropdownOption>
 *     <DropdownOption>Option 2</DropdownOption>
 *     <DropdownOption>Option 3</DropdownOption>
 *   </DropdownMenu>;
 */
const DropdownMenu = forwardRef(
  (
    { align = 'left', children, className, expanded = false, id, ...props },
    ref,
  ) => {
    useEffect(() => {
      if (expanded) {
        const [firstItem] = ref.current.children;
        if (firstItem) firstItem.focus();
      }
    }, [expanded, ref]);
    return (
      <motion.div
        id={id}
        className={classNames(
          'DropdownMenu',
          `DropdownMenu-${align}`,
          className,
        )}
        onKeyUp={handleKeyUp}
        hidden={!expanded}
        data-testid={id}
        ref={ref}
        role="menu"
        tabIndex="-1"
        {...props}
      >
        {children}
      </motion.div>
    );
  },
);

DropdownMenu.propTypes = {
  /** The side of the trigger on which the menu should be aligned */
  align: PropTypes.oneOf(['left', 'right']),
  /** Items to populate the menu */
  children: PropTypes.node,
  /** Additional class(es) to apply to the container */
  className: PropTypes.string,
  /** Whether or not the menu is visible */
  expanded: PropTypes.bool,
  /**
   * ID of the container, for ARIA references and selecting in unit/integration
   * tests
   */
  id: PropTypes.string.isRequired,
};

export default DropdownMenu;
