import { useEffect, useState } from 'react';
import { Tabs, Tab, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  deleteCollectionTermsAction,
  getCollectionTermsAction,
  getPaymentTermsAction,
  deletePaymentTermsAction,
} from '@/actions/settings';
import CardWrapper from '@/components/Settings/CardWrapper';
import Header from '@/components/Settings/Header';
import Button from '@/components/common/Button';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import { ACCOUNTS_PAYABLE, ACCOUNTS_RECEIVABLE } from '@/constants/settings';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import PACTermText from './PACTermText';
import PaymentAndCollectionItem from './PaymentAndCollectionItem';
import TheAddEditPACTermModal from './TheAddEditPACTermModal';
import './PaymentAndCollection.scss';

const PaymentAndCollection = ({
  companyId,
  scenarioId,
  collectionTerms,
  paymentTerms,
  collectionTermError,
  getCollectionTerms,
  deleteCollectionTerm,
  getPaymentTerms,
  deletePaymentTerm,
  paymentTermError,
}) => {
  const [hasErrorOnDelete, setHasErrorOnDelete] = useState(false);
  const [activeView, setActiveView] = useState(ACCOUNTS_PAYABLE);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [termsInFocus, setTermsInFocus] = useState([]);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [error, setError] = useState('');

  const hasWritePermission = useNonDashboardWritePermission();

  useEffect(() => {
    getCollectionTerms(companyId, scenarioId);
    getPaymentTerms(companyId, scenarioId);
  }, [companyId, scenarioId, getCollectionTerms, getPaymentTerms]);

  useEffect(() => {
    if (activeView === ACCOUNTS_PAYABLE) {
      setTermsInFocus(paymentTerms);
    } else setTermsInFocus(collectionTerms);
  }, [paymentTerms, collectionTerms, activeView]);

  useEffect(() => {
    if (activeView === ACCOUNTS_PAYABLE && paymentTermError) {
      setError(paymentTermError);
    } else if (activeView === ACCOUNTS_RECEIVABLE && collectionTermError) {
      setError(collectionTermError);
    }
  }, [collectionTermError, paymentTermError, activeView]);

  const handleDelete = async () => {
    try {
      const deleteTerm =
        activeView === ACCOUNTS_PAYABLE
          ? deletePaymentTerm
          : deleteCollectionTerm;
      await deleteTerm(companyId, selectedTerm.id, scenarioId);
      setShowDeleteConfirmation(false);
    } catch (e) {
      setHasErrorOnDelete(true);
    }
  };

  return (
    <CardWrapper>
      {error && <Alert variant="danger">{error}</Alert>}
      <Header mode="read" title="Payment and Collection Terms" />

      <Tabs
        defaultActiveKey={activeView}
        id="payment-collection-terms-tabs"
        activeKey={activeView}
        onSelect={(eventKey) => {
          setActiveView(eventKey);
          setTermsInFocus(
            eventKey === ACCOUNTS_PAYABLE ? paymentTerms : collectionTerms,
          );
        }}
        className="PC_Tabs"
      >
        <Tab eventKey={ACCOUNTS_PAYABLE} title={ACCOUNTS_PAYABLE} />
        <Tab eventKey={ACCOUNTS_RECEIVABLE} title={ACCOUNTS_RECEIVABLE} />
      </Tabs>

      {termsInFocus?.map((term) => (
        <PaymentAndCollectionItem
          key={term.id}
          term={term}
          showDropDown={hasWritePermission && term.isEditable}
          onDelete={() => {
            setSelectedTerm(term);
            setShowDeleteConfirmation(true);
          }}
          onEdit={() => {
            setShowAddEditModal(true);
            setSelectedTerm(term);
          }}
        />
      ))}

      {hasWritePermission && (
        <Button
          className="Button-primaryLink"
          data-testid="button-add-new-term"
          onClick={() => setShowAddEditModal(true)}
        >
          {activeView === ACCOUNTS_PAYABLE ? (
            <PACTermText location="accountsPayable.addBtn" />
          ) : (
            <PACTermText location="accountsReceivable.addBtn" />
          )}
        </Button>
      )}

      {showAddEditModal && (
        <TheAddEditPACTermModal
          onClose={() => {
            setSelectedTerm(null);
            setShowAddEditModal(false);
          }}
          selectedTerm={selectedTerm}
          activeView={activeView}
        />
      )}

      {showDeleteConfirmation && !hasErrorOnDelete && (
        <ModalConfirmation
          id="remove-pac-term-modal"
          title="Are you sure?"
          actionBtnTxt="Confirm"
          onCancel={() => setShowDeleteConfirmation(false)}
          onAction={() => {
            setSelectedTerm(null);
            handleDelete();
          }}
        >
          {activeView === ACCOUNTS_PAYABLE ? (
            <PACTermText location="accountsPayable.deleteConfirmation" />
          ) : (
            <PACTermText location="accountsReceivable.deleteConfirmation" />
          )}
        </ModalConfirmation>
      )}

      {hasErrorOnDelete && (
        <ModalConfirmation
          id="remove-pac-term-error-modal"
          title={
            activeView === ACCOUNTS_PAYABLE ? (
              <PACTermText location="accountsPayable.deleteErrorLabel" />
            ) : (
              <PACTermText location="accountsReceivable.deleteErrorLabel" />
            )
          }
          actionBtnTxt="I Understand"
          onAction={() => {
            setHasErrorOnDelete(false);
            setShowDeleteConfirmation(false);
          }}
        >
          {activeView === ACCOUNTS_PAYABLE ? (
            <PACTermText location="accountsPayable.deleteErrorMsg" />
          ) : (
            <PACTermText location="accountsReceivable.deleteErrorMsg" />
          )}
        </ModalConfirmation>
      )}
    </CardWrapper>
  );
};

const mapStateToProps = (state) => ({
  companyId: state.companies.selectedCompanyId,
  scenarioId: state.scenario.scenarioId,
  paymentTerms: state.settings.paymentTerms.data,
  collectionTerms: state.settings.collectionTerms.collectionTerms,
  collectionTermError: state.settings.collectionTerms.error,
  paymentTermError: state.settings.paymentTerms.error,
});

export default connect(mapStateToProps, {
  getCollectionTerms: getCollectionTermsAction,
  getPaymentTerms: getPaymentTermsAction,
  deleteCollectionTerm: deleteCollectionTermsAction,
  deletePaymentTerm: deletePaymentTermsAction,
})(PaymentAndCollection);
