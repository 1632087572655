import './LoadingSpinner.scss';

/**
 * Renders a loading spinner, such as that used on async buttons
 *
 * @example
 *   <LoadingSpinner />;
 */
function LoadingSpinner(props) {
  return (
    <ul aria-label="Loading" className="LoadingSpinner" {...props}>
      <li className="LoadingSpinner_Bullet" />
      <li className="LoadingSpinner_Bullet" />
      <li className="LoadingSpinner_Bullet" />
      <li className="LoadingSpinner_Bullet" />
    </ul>
  );
}

export default LoadingSpinner;
