// @ts-check
import { createCachedSelector, FlatMapCache } from 're-reselect';
import getSelectedCompanyId from './shared/getSelectedCompanyId';

/**
 * @typedef {{
 *   companies: import('../reducers/companies').CompanyState;
 * }} CompanyStateTree
 */

/**
 * @type {(
 *   state: CompanyStateTree,
 * ) => import('../reducers/companies').Company[]}
 */
const getCompanies = (state) => {
  return state.companies?.companies ?? [];
};

/**
 * @type {(
 *   selectedCompanyId: number | null,
 *   companies: import('../reducers/companies').Company[],
 * ) => import('../reducers/companies').Company | null}
 */
const combiner = (selectedCompanyId, companies) => {
  return (
    companies.find((company) => {
      return company.id === selectedCompanyId;
    }) ?? null
  );
};

/**
 * Creates a factory function for a re-reselect selector that caches the
 * selected result.
 */
const createSelectedCompanySelector = createCachedSelector(
  getSelectedCompanyId,
  getCompanies,
  combiner,
);

/** Returns the company state for the currently selected active company. */
const getSelectedCompany = createSelectedCompanySelector({
  keySelector: (state) => {
    return state.companies.selectedCompanyId;
  },
  cacheObject: new FlatMapCache(),
});

export default getSelectedCompany;
