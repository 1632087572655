import { useState } from 'react';
import CrossIcon from '@bill/cashflow.assets/cross';
import RadioSelectedIcon from '@bill/cashflow.assets/radio-selected';
import RoundCrossIcon from '@bill/cashflow.assets/round-cross';
import WarningIcon from '@bill/cashflow.assets/warning';
import Button from '@/components/common/Button';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import { classNames } from '@/helpers';
import './LinkPreview.scss';

const MAX_ITEMS = 3;
const NOT_INCLUDED = 'Not Included';
const ENTRIES_DEFAULT = [];

function PreviewEntry({
  currentExpenseName,
  currentCriteriaTypeWithValues,
  newExpenseName,
}) {
  return (
    <>
      <div className="PreviewSection_TableCell">
        {currentCriteriaTypeWithValues.map((matchItem) => {
          return (
            <div
              key={matchItem.criteriaTypeValue}
              className="MatchedItemWrapper"
            >
              <TooltipTextOverflow
                label={matchItem.criteriaTypeValue}
                placement="right"
                data-testid={matchItem.criteriaTypeValue}
              />
              <div className="MatchedItem_Criteria">{matchItem.criteria}</div>
            </div>
          );
        })}
      </div>
      <div className="PreviewSection_TableCell">{currentExpenseName}</div>
      <div className="PreviewSection_TableCell">{newExpenseName} </div>
    </>
  );
}

function NotIncludedEntry({ currentCriteriaTypeValue, currentExpenseName }) {
  return (
    <>
      {currentCriteriaTypeValue}
      <CrossIcon className="NotIncludedEntry_Icon" aria-hidden="true" />
      {currentExpenseName}
    </>
  );
}

function PreviewSection({
  children,
  className,
  'data-testid': dataTestId,
  entries = ENTRIES_DEFAULT,
  Entry = PreviewEntry,
  expenseName,
  Icon,
  title,
}) {
  const [showMore, setShowMore] = useState(false);

  return (
    <section
      className={classNames('PreviewSection', className)}
      data-testid={dataTestId}
    >
      <h3 className="PreviewSection_Title">
        <Icon className="PreviewSection_Icon" aria-hidden="true" />
        {title}
      </h3>
      {children}
      {title === NOT_INCLUDED ? (
        <ul className="PreviewSection_Entries">
          {entries
            .slice(0, showMore ? entries.length : MAX_ITEMS)
            .map((entry) => (
              <li key={entry.currentCriteriaTypeValue}>
                <Entry {...entry} newExpenseName={expenseName} />
              </li>
            ))}
          {!showMore && entries.length > MAX_ITEMS && (
            <button
              type="button"
              className="PreviewSection_MoreLink"
              data-testid={`${dataTestId}-showMore`}
              onClick={() => setShowMore(true)}
            >
              See more <strong>({entries.length - MAX_ITEMS})</strong>
            </button>
          )}
        </ul>
      ) : (
        <div className="PreviewSection_Table">
          <div className="PreviewSection_TableHeader PreviewSection_TableCell">
            Identified Matches
          </div>
          <div className="PreviewSection_TableHeader PreviewSection_TableCell">
            Currently Included In
          </div>
          <div className="PreviewSection_TableHeader PreviewSection_TableCell">
            Moving To
          </div>
          {entries.map((entry) => (
            <Entry
              key={entry.currentCriteriaTypeValue}
              {...entry}
              newExpenseName={expenseName}
            />
          ))}
        </div>
      )}
    </section>
  );
}

export default function LinkPreview({ entries, state, onCancel, onSubmit }) {
  const [loading, setLoading] = useState(false);

  const { advisoryList, includedList, notIncludedList } = entries;
  const resultNoun =
    state.expenseGroupCriteria.type === 'Vendor' ? 'vendors' : 'entries';

  return (
    <div className="LinkPreview">
      <a
        href="#"
        className={classNames('LinkPreview_BackLink', 'link')}
        onClick={onCancel}
        onKeyPress={onCancel}
      >
        Refine your criteria
      </a>
      <h2 className="ModalBase_Heading">Preview</h2>
      <p className="LinkPreview_Copy">
        The following {resultNoun} from within your accounting platform will be
        attached to your new expense. Please be advised that any future{' '}
        {resultNoun} matching this criteria will also be added to this expense.
      </p>
      {Boolean(notIncludedList.length) && (
        <PreviewSection
          className="PreviewSection-notIncluded"
          Icon={RoundCrossIcon}
          title={NOT_INCLUDED}
          Entry={NotIncludedEntry}
          entries={notIncludedList}
          expenseName={state.name}
        >
          <p>
            The following cannot be included as they conflict with other linked
            expenses.
          </p>
        </PreviewSection>
      )}
      {Boolean(advisoryList.length) && (
        <PreviewSection
          className="PreviewSection-advisory"
          Icon={WarningIcon}
          title="To Be Included - Advisory"
          entries={advisoryList}
          expenseName={state.name}
        >
          <p>
            The following matches are part of an existing linked expense but
            will be moved to this expense once you create it.
          </p>
        </PreviewSection>
      )}
      {Boolean(includedList.length) && (
        <PreviewSection
          Icon={RadioSelectedIcon}
          title="To Be Included"
          entries={includedList}
          expenseName={state.name}
        >
          <p>The following matches will be moved into the new expense.</p>
        </PreviewSection>
      )}
      {!includedList.length && !advisoryList.length && (
        <PreviewSection Icon={RadioSelectedIcon} title="To Be Included">
          <p>
            No matches were found in your accounting platform that matches your
            filtering criteria. You can still create the expense, and any future
            entries matching your filtering criteria will be added to this
            expense.
          </p>
        </PreviewSection>
      )}
      <div className="ModalBase_Footer">
        <Button
          loading={loading}
          data-testid="expense-link-preview-submit"
          onClick={() => {
            setLoading(true);
            onSubmit();
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
}
