// @ts-check
import { timePeriodLabel } from '@/components/Charts/constants';
import { CUSTOM_CHART } from '@/components/common/ChartList/constants';
import { MONTHLY } from '@/constants/dateTime';
import { naturalSortComparator } from '@/helpers';
import { DEFAULT_CHART_META } from '@/helpers/customCharts';
import { isEmptyOrNull } from '@/helpers/validators';
import { layoutConstraints } from '@/pages/Dashboard/constants/gridConstants';

/**
 * Get either 'N/A' or a formatted metric
 *
 * @type {(
 *   metric: number | string,
 *   formatter: import('@/types/helpers').Formatter,
 *   precision?: number,
 * ) => string}
 */
export const getMetricValue = (metric, formatter, precision) => {
  if (isEmptyOrNull(metric)) {
    return 'N/A';
  }

  if (formatter) {
    return !isEmptyOrNull(precision)
      ? `${formatter(metric, {
          maximumFractionDigits: precision,
          minimumFractionDigits: precision,
        })}`
      : `${formatter(metric)}`;
  }

  return `${metric}`;
};

/**
 * Get the singular or plural version of 'Month', 'Quarter' or 'Year'
 *
 * @param {number | string} metric The metric on which to base pluralization
 * @returns {string}
 */
export const getTimePeriodLabel = (metric, timePeriod = MONTHLY) =>
  `${timePeriodLabel[timePeriod]}${metric === 1 ? '' : 's'}`;

/**
 * Get the metric with pluaralized month, unless the metric is 'N/A'
 *
 * @param {number | string} metric The metric on which to base pluralization
 * @returns {string}
 */
export const getLabeledMetric = (metric, timePeriod = MONTHLY) => {
  if (metric === 'N/A' || isEmptyOrNull(metric)) {
    return `${metric}`;
  }
  return `${metric} ${getTimePeriodLabel(metric, timePeriod)}`;
};

/**
 * Transforms chart items to layout items
 *
 * @type {(
 *   charts: import('@/components/common/ChartList/types').ChartInfo[],
 * ) => import('@/types/dashboard').LayoutItem[]}
 */
export const transformToLayoutItems = (charts) =>
  charts.map(({ key, meta }) => ({
    i: key,
    ...meta,
    minW: layoutConstraints.MIN_W,
    minH: layoutConstraints.MIN_H,
  }));

/**
 * Determine if a chart can fit on an existing row and calculate the x offset
 *
 * @type {(
 *   prevItemMeta?: import('@/types/dashboard').DashboardItemMeta,
 * ) => number}
 */
export const getXOffset = (prevItemMeta) => {
  if (!prevItemMeta) return DEFAULT_CHART_META.x;

  const usedSpace = prevItemMeta.x + prevItemMeta.w;

  if (usedSpace <= DEFAULT_CHART_META.w) {
    return usedSpace;
  }

  return DEFAULT_CHART_META.x;
};

/**
 * Compute a dashboard layout item (chart) position based on the previous item's
 * position
 *
 * @type {(
 *   prevItemMeta?: import('@/types/dashboard').DashboardItemMeta,
 * ) => import('@/types/dashboard').DashboardItemMeta}
 */
export const computeMetadata = (prevItemMeta) => {
  const xOffset = getXOffset(prevItemMeta);
  const meta = { ...DEFAULT_CHART_META };

  if (xOffset) {
    meta.x = xOffset;
    meta.y = prevItemMeta?.y;
  }

  return meta;
};

/**
 * Compare function for sorting dashboard items by meta based on meta.y then by
 * meta.x if necessary.
 *
 * @type {(
 *   itemA: import('@/types/dashboard').DashboardItem,
 *   itemB: import('@/types/dashboard').DashboardItem,
 * ) => number}
 */
export const compareDashboardItemsByMeta = ({ meta: a }, { meta: b }) => {
  if (!a) return DEFAULT_CHART_META.y;
  if (!b) return -1 * DEFAULT_CHART_META.y;

  const difference = a.y - b.y;
  if (difference !== 0) {
    return difference;
  }
  return a.x - b.x;
};

/**
 * Returns a sorted user layout derived from dashboardItems and customCharts
 *
 * @typedef {import('@/types/dashboard').DashboardItem
 *   | import('@/components/common/ChartList/types').ChartInfo} DashboardChartItem
 *
 *
 * @typedef {DashboardChartItem & ChartInfoProps} DashboardChartInfoItem
 *
 * @typedef {{
 *   chartInfo?: unknown;
 * }} ChartInfoProps
 * @param {DashboardChartItem[]} dashboardItems
 * @param {unknown[]} customCharts
 * @returns {DashboardChartInfoItem[]} computedChartLayout
 */
export const getSortedChartLayoutWithComputedMeta = (
  dashboardItems = [],
  customCharts = [],
) => {
  const sortedDashboardItems = [...dashboardItems].sort(
    compareDashboardItemsByMeta,
  );

  return sortedDashboardItems.reduce((acc, dashboardItem) => {
    /** @type {DashboardChartItem & ChartInfoProps} */
    const item = { ...dashboardItem };

    // compute meta if meta is empty or null
    if (!Object.keys(item.meta ?? {}).length) {
      const [prevItem] = acc.slice(-1);
      item.meta = computeMetadata(prevItem?.meta);
    }

    if ('type' in item && item.type === CUSTOM_CHART) {
      item.chartInfo = customCharts.find(({ id }) => id === item.key);
    }

    acc.push(item);
    return acc;
  }, []);
};

/**
 * Comparator function prioritizes strategic placement of the total row, like
 * "Total Cash In," ensuring optimal sorting in the cash flow grid.
 *
 * @type {(key: string) => (a: string, b: string) => number}
 */
export const cashFlowComparator = (key) => {
  return (a, b) => {
    if (a === key) return 1;
    if (b === key) return -1;
    return naturalSortComparator(a, b);
  };
};
