import { forwardRef, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import ChartRadioGroupPersistent from '@/components/common/ChartRadioGroupPersistent';
import InfoTooltip from '@/components/common/InfoTooltip';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import useChartPreferences from '@/hooks/useChartPreferences';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import CustomersNewChart, {
  CUSTOMERS_NEW_TOGGLE_ID,
} from '@/pages/Dashboard/charts/CustomersNewChart';

const NewCustomersGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [newCustomers, setNewCustomers] = useState({});
    const { startDate, endDate } = useSelector(({ shared }) => shared);
    const showPercentage = useChartPreferences(CUSTOMERS_NEW_TOGGLE_ID);
    const onQueryStateChange = useCallback(
      ({ data, isLoading }) => !isLoading && setNewCustomers(data),
      [],
    );

    return (
      <ChartWidget
        ref={ref}
        title="New Customers"
        data-testid="customersNewWidget"
        url="/dashboard/new-customers"
        metricValue={
          showPercentage
            ? newCustomers.forecastTermValuePoP
            : newCustomers.currentValue
        }
        label={formatRangeWithShortYear(startDate, endDate)}
        controls={
          <ChartRadioGroupPersistent
            id={CUSTOMERS_NEW_TOGGLE_ID}
            value={false}
            options={[
              { label: 'Customers', value: false },
              { label: 'Percentage', value: true },
            ]}
            className="ControlDropdown_Child"
          />
        }
        {...props}
      >
        {(childProps) => {
          return (
            <>
              <WidgetKPI
                value={
                  showPercentage
                    ? newCustomers.forecastTermValuePoP
                    : newCustomers.currentValue
                }
                label={formatRangeWithShortYear(startDate, endDate)}
                tooltip={
                  showPercentage &&
                  newCustomers.tooltipText && (
                    <InfoTooltip data-testid="customers-new-kpi-tooltip">
                      {newCustomers.tooltipText}
                    </InfoTooltip>
                  )
                }
              />
              <CustomersNewChart
                {...childProps}
                showPercentage={showPercentage}
                onQueryStateChange={onQueryStateChange}
              />
              {children}
            </>
          );
        }}
      </ChartWidget>
    );
  },
);

export default NewCustomersGlance;
