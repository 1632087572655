import { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import InfoTooltip from '@/components/common/InfoTooltip';
import TogglePersistent from '@/components/common/TogglePersistent';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import useChartPreferences from '@/hooks/useChartPreferences';
import useDashboardPath from '@/hooks/useDashboardPath';
import LogoChurnChart, { LOGO_CHURN_TOGGLE_ID } from './charts/LogoChurnChart';

const CHART_TITLE = 'Logo Churn';

const LogoChurn = ({ startDate, endDate }) => {
  const chartRef = useRef();
  const [logoChurn, setLogoChurn] = useState({});
  const showPercentage = useChartPreferences(LOGO_CHURN_TOGGLE_ID);
  const dashboardPath = useDashboardPath();
  const onQueryStateChange = useCallback(
    ({ data: { mainMetric, tooltipText }, isLoading }) => {
      return (
        !isLoading &&
        setLogoChurn({
          mainMetric,
          tooltipText,
        })
      );
    },
    [],
  );
  return (
    <section className="Panel Panel-toEdge">
      <ChartHeader back={dashboardPath} title={CHART_TITLE}>
        <div className="ChartHeader_KPIValue">
          {logoChurn.mainMetric}
          {logoChurn.tooltipText && (
            <InfoTooltip data-testid="logo-churn-kpi-tooltip">
              {logoChurn.tooltipText}
            </InfoTooltip>
          )}
        </div>
        <div className="ChartHeader_KPI">
          {showPercentage ? (
            <span className="ChartHeader_Subtitle">Average for:</span>
          ) : (
            <br />
          )}
          {formatRangeWithShortYear(startDate, endDate)}
        </div>
      </ChartHeader>
      <ChartToolbar>
        <TogglePersistent
          id={LOGO_CHURN_TOGGLE_ID}
          options={[
            {
              label: 'Customers',
              value: false,
            },
            {
              label: 'Percent',
              value: true,
            },
          ]}
          value={false}
        />
        <ChartControls
          chartRef={chartRef}
          title={CHART_TITLE}
          metricValue={logoChurn.mainMetric}
          label={
            showPercentage
              ? `Average for: ${formatRangeWithShortYear(startDate, endDate)}`
              : formatRangeWithShortYear(startDate, endDate)
          }
          data-testid="logo-churn-chart-export"
        />
      </ChartToolbar>
      <LogoChurnChart
        className="ExpandedView_ChartWrapper"
        exportBtn={chartRef}
        showPercent={showPercentage}
        onQueryStateChange={onQueryStateChange}
      />
    </section>
  );
};

function mapStateToProps({ shared }) {
  return {
    startDate: shared.startDate,
    endDate: shared.endDate,
  };
}

export default connect(mapStateToProps)(LogoChurn);
