/**
 * Create a default forecast method for a given category based around the
 * category total, e.g. `Expenses.Accounting.Total`. This will create a default
 * formula that correctly reflects dividing by `n` months. e.g.
 * `createDefaultFormula('Expenses.Accounting.Total', 3)` will produce the
 * following formula: `(Expenses.Accounting.Total[-1] +
 * Expenses.Accounting.Total[-2] + Expenses.Accounting.Total[-3]) / 3`
 *
 * @type {(systemVariableName: string, months: number) => string}
 */
const createDefaultForecastFormula = (systemVariableName, months) => {
  const strs = [];
  let i = 0;
  for (i; i < months; i += 1) {
    strs.push(`${systemVariableName}[-${i + 1}]`);
  }
  return `(${strs.join(' + ')}) / ${months}`;
};

export default createDefaultForecastFormula;
