import { useEffect } from 'react';
import { YAxis } from 'react-jsx-highcharts';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { subscribeToEmployeeUpdateAction } from '@/actions/employees';
import { GROSS_MARGIN } from '@/cacheKeys';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import { LABEL_STYLES } from '@/components/Charts/chartDefaults';
import { getYAxisConfigPercent } from '@/components/Charts/helpers';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import metricFormatters from '@/helpers/metricFormatters';
import useChartQuery from '@/hooks/useChartQuery';
import useWsSubscription from '@/hooks/useWsSubscription';
import { getGrossMargins } from '@/services/dashboard.service';

const MAIN_METRIC = 'Gross Margin';

const metrics = [
  {
    key: 'revenue',
    name: 'Revenue',
    formatter: metricFormatters.monetary,
  },
  {
    key: 'cogs',
    name: 'COGS',
    formatter: metricFormatters.monetary,
  },
  {
    key: 'y',
    isMainMetric: true,
    name: MAIN_METRIC,
    formatter: metricFormatters.percentLegacy,
  },
];

const YAXIS_DEFAULTS = getYAxisConfigPercent();

const reducer = ({ grossMarginData, averageGM }) => {
  return {
    ...mapMonthlyData(grossMarginData, 'grossMargin'),
    averageGM,
  };
};

/**
 * Fetches and renders a line chart showing Gross Margin Chart tooltipTitleText
 *
 * @example
 *   <GrossMarginChart exportBtn={exportBtn} />;
 */
function GrossMarginChart({
  className,
  exportBtn,
  plotOptions,
  axisStyles,
  tooltipOptions,
  onQueryStateChange,
}) {
  const yAxisConfigs = {
    ...YAXIS_DEFAULTS,
    labels: {
      ...YAXIS_DEFAULTS.labels,
      style: axisStyles || LABEL_STYLES,
    },
  };

  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );

  const scenarioQueries = useChartQuery(GROSS_MARGIN, getGrossMargins, reducer);

  const [base] = scenarioQueries;

  useEffect(() => {
    if (base) onQueryStateChange?.(base);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [base]);

  useWsSubscription(
    () =>
      dispatch(
        subscribeToEmployeeUpdateAction(scenarioId, () => {
          base.refetch();
        }),
      ),
    [base, scenarioId],
  );

  return (
    <DateChart
      className={className}
      data-testid="grossMarginChart"
      loading={scenarioQueries.every(({ isLoading }) => isLoading)}
      tooltip={
        <ChartTooltip
          metrics={metrics}
          valueFormatter={metricFormatters.percentLegacy}
          endDate={endDate}
          timePeriod={timePeriod}
          {...tooltipOptions}
        />
      }
      plotOptions={plotOptions}
      axisStyles={axisStyles}
      ref={exportBtn}
      startDate={startDate}
      endDate={endDate}
      timePeriod={timePeriod}
    >
      <YAxis {...yAxisConfigs}>
        {scenarioQueries.map(
          ({ data, scenario }, idx) =>
            data && (
              <DateChart.Series
                key={scenario.scenarioId}
                data={data.data}
                index={idx}
                metric={MAIN_METRIC}
                scenario={scenario}
                isComparison={idx === 1}
              />
            ),
        )}
      </YAxis>
    </DateChart>
  );
}

export default GrossMarginChart;
