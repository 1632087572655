// @ts-check
import { ReactComponent as FinmarkLogo } from '@/assets/images/bill_finmark_logo.svg';
import './CoBranding.scss';

/**
 * @typedef {{
 *   subDomain: string;
 *   resourceHost: string;
 *   logoSize: 'small' | 'large';
 * }} CoBrandingProps
 */

/**
 * Renders the finmark co-branding logo with powered by finmark text
 *
 * @example
 *   <CoBranding subDomain="foo" resourceHost="bar" logoSize="small" />;
 *
 * @type {(params: CoBrandingProps) => import('react').ReactElement}
 */
const CoBranding = ({ subDomain, resourceHost, logoSize = 'large' }) => {
  const url = `https://${resourceHost}.finmark.com/${subDomain}/${logoSize}`;
  return (
    <>
      <div className="PartnerContainer">
        <div
          className="PartnerLogo"
          style={{
            backgroundImage: `url("${url}.svg"), url("${url}.png")`,
          }}
        />
      </div>
      <div className="PoweredBy_Wrapper">
        <span className="PoweredBy_Text">Powered By</span>
        <FinmarkLogo className="PoweredBy_FinmarkLogo" />
      </div>
    </>
  );
};

export default CoBranding;
