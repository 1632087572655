import { useState } from 'react';
import { ReactComponent as FinmarkLogo } from '@/assets/images/bill_finmark_logo.svg';

function Header() {
  const [isMobileNavVisible, setMobileNavVisible] = useState(false);

  return (
    <header className="landing-Header">
      <a href="https://finmark.com/">
        <FinmarkLogo
          className="landing-Header_Logo"
          aria-label="Finmark by Bill"
        />
      </a>
      <button
        type="button"
        className="landing-Header_NavToggle"
        aria-label="Open Navigation"
        aria-pressed={isMobileNavVisible}
        onClick={() => setMobileNavVisible(!isMobileNavVisible)}
      />
      {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props -- predates description requirement */}
      <nav className="landing-Nav" aria-expanded={isMobileNavVisible}>
        <a href="https://finmark.com/pricing/" className="landing-Nav_Item">
          Pricing
        </a>
        <a href="https://finmark.com/blog/" className="landing-Nav_Item">
          Blog
        </a>
      </nav>
    </header>
  );
}

export default Header;
