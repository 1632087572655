// @ts-check
import { useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { updateUserSettingAction } from '@/actions/settings';
import CountryField from '@/components/Settings/Address/CountryField';
import { US_CODE } from '@/components/Settings/Address/constants';
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';
import './CountryRequiredModal.scss';

/**
 * Render a modal with country of residence field required
 *
 * @type {(params: { onSuccess: () => void }) => React.ReactElement}
 */
export default function CountryRequiredModal({ onSuccess }) {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const [country, setCountry] = useState(US_CODE);
  const [loading, setLoading] = useState(false);
  const { fullName } = useSelector(({ auth }) => auth.userInfo);

  const onContinue = async () => {
    const [firstName, lastName] = fullName.split(' ');
    setLoading(true);
    await dispatch(
      updateUserSettingAction({
        firstName,
        lastName,
        country,
      }),
    );
    onSuccess();
    setLoading(false);
  };

  return (
    <Modal
      open
      data-testid="country-required-modal"
      className="CountryRequiredModal"
    >
      <div className="ModalBase_Content">
        <p className="CountryRequiredModal_Text">
          Before you continue, you must provide your country of residence.
          Please choose from the options below:
        </p>
        <CountryField
          value={country}
          onChange={({ target }) => setCountry(target.value)}
        />
        <Button
          id="submit"
          type="submit"
          data-testid="submit"
          disabled={!country}
          className="Button Button-success"
          onClick={onContinue}
          loading={loading}
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
}
