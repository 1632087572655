import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';

/* eslint-disable-next-line no-restricted-imports -- predates description requirement */
import { Link, useParams } from 'react-router-dom';
import MailIcon from '@bill/cashflow.assets/mail';
import { handleDataChange, resetPasswordAction } from '@/actions/signUp';
import Button from '@/components/common/Button';
import ErrorMessages from '@/components/common/ErrorMessages';
import PasswordField from '@/components/common/PasswordField';
import Landing from '@/containers/Landing';

const ResetPasswordForm = ({
  temporaryPassword,
  newPassword,
  confirmNewPassword,
  // eslint-disable-next-line no-shadow -- predates description requirement
  handleDataChange,
  resetPasswordResponse,
  resetPassword,
}) => {
  const { resetPasswordToken } = useParams();
  // eslint-disable-next-line no-shadow -- predates description requirement
  const submitPassword = (e, resetPasswordToken) => {
    e.preventDefault();
    resetPassword(
      resetPasswordToken,
      temporaryPassword,
      newPassword,
      confirmNewPassword,
    );
  };

  useEffect(() => {
    document.title = 'Password Reset';
  }, []);

  const { success, error } = resetPasswordResponse;

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  const isConfirmPasswordCorrect = () => {
    return newPassword === confirmNewPassword;
  };

  return (
    <Landing>
      <form className="landing-Form">
        <h1 className="Panel_Title">Password Reset</h1>

        <ErrorMessages success={success} error={error} />

        <p>Please setup your new password</p>
        <div className="landing-Form_Group">
          <label htmlFor="tempPasswd">Temporary Password</label>
          <PasswordField
            required
            id="tempPasswd"
            name="temporaryPassword"
            value={temporaryPassword}
            onChange={(e) => handleDataChange(e.target.name, e.target.value)}
            validate={(value) =>
              !value && 'Temporary Password must not be empty'
            }
          />
        </div>
        <div className="landing-Form_Group">
          <label htmlFor="newPasswd">New Password</label>
          <PasswordField
            required
            id="newPasswd"
            name="newPassword"
            value={newPassword}
            onChange={(e) => handleDataChange(e.target.name, e.target.value)}
          />
        </div>
        <div className="landing-Form_Group">
          <label htmlFor="confirmPasswd">Confirm Password</label>
          <PasswordField
            required
            id="confirmPasswd"
            name="confirmNewPassword"
            value={confirmNewPassword}
            onChange={(e) => handleDataChange(e.target.name, e.target.value)}
            validate={() =>
              !isConfirmPasswordCorrect() && 'Password does not match'
            }
          />
        </div>
        <Button
          type="submit"
          className="landing-Form_Submit"
          disabled={
            !temporaryPassword ||
            !newPassword ||
            !confirmNewPassword ||
            !isConfirmPasswordCorrect()
          }
          onClick={(e) => submitPassword(e, resetPasswordToken)}
        >
          Save Password
        </Button>
      </form>

      {success && (
        <div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header className="min-header" />
            <Modal.Body className="text-center pt-1 pb-5">
              <MailIcon className="MailIcon" />
              <p className="mb-1">
                Your password has been changed successfully.
              </p>
              <Link to="/" className="link">
                <strong> Click to continue </strong>
              </Link>
              <span className="d-block py-2" />
            </Modal.Body>
          </Modal>
        </div>
      )}
    </Landing>
  );
};

const mapStateToProps = (state) => ({
  temporaryPassword: state.signUp.temporaryPassword,
  newPassword: state.signUp.newPassword,
  confirmNewPassword: state.signUp.confirmNewPassword,
  resetPasswordResponse: state.signUp.resetPasswordResponse,
});

export default connect(mapStateToProps, {
  handleDataChange,
  resetPassword: resetPasswordAction,
})(ResetPasswordForm);
