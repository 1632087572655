import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch, useStore } from 'react-redux';
import {
  createNotificationAction,
  deleteNotificationAction,
} from '@/actions/notifications';
import CompanyInfoRequiredModal from '@/components/TrialModal/CompanyInfoRequiredModal';
import { notificationIds } from '@/components/common/Notifications/constants';
import isBillDomain from '@/helpers/isBillDomain';
import useCompanyStatusQuery from '@/hooks/useCompanyStatusQuery';
import useIsAdminUser from '@/hooks/useIsAdminUser';

const CompanyStatusBannerWithLockoutPopup = () => {
  /** @type {import('redux').Store<import('@/store').RootState>} */
  const store = useStore();

  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const isAdmin = useIsAdminUser();

  const { data: companyStatus, isLoading } = useCompanyStatusQuery({});

  useEffect(() => {
    const rootState = store.getState();
    const { notifications } = rootState.notifications;

    const hasAddressAndIndustryId =
      companyStatus?.isLocked || !companyStatus?.isInformationMissing;

    if (!isLoading && isAdmin && !hasAddressAndIndustryId && !isBillDomain()) {
      dispatch(
        createNotificationAction({
          id: notificationIds.PENDING_COMPANY_ADDRESS,
        }),
      );
    }
    if (
      !isLoading &&
      hasAddressAndIndustryId &&
      notifications[notificationIds.PENDING_COMPANY_ADDRESS]
    ) {
      dispatch(
        deleteNotificationAction({
          id: notificationIds.PENDING_COMPANY_ADDRESS,
        }),
      );
    }
  }, [companyStatus, isAdmin, isLoading, dispatch, store]);

  return !isLoading && companyStatus?.isLocked ? (
    <CompanyInfoRequiredModal />
  ) : null;
};

export default CompanyStatusBannerWithLockoutPopup;
