import CoBrandingLogo from '@/components/common/CoBranding';
import './CoBranding.scss';

const CoBranding = ({ subDomain, resourceHost }) => {
  return (
    <div className="CoBranding">
      <div className="CoBranding_Panel">
        <CoBrandingLogo subDomain={subDomain} resourceHost={resourceHost} />
      </div>
    </div>
  );
};

export default CoBranding;
