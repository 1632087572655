import { forwardRef, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import CACChart from '@/pages/Dashboard/charts/CACChart';
import { getMetricValue } from '@/pages/Dashboard/helpers';

const CustomerAcquisitionCostGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [cac, setCac] = useState({});
    const { startDate, endDate } = useSelector(({ shared }) => shared);
    const onQueryStateChange = useCallback((cacData) => {
      setCac(cacData);
    }, []);
    return (
      <ChartWidget
        ref={ref}
        title="Customer Acquisition Cost"
        data-testid="cacWidget"
        metricValue={
          cac.isLoading
            ? ''
            : getMetricValue(cac.data?.customerAcquisitionCost, formatMonetary)
        }
        label={formatRangeWithShortYear(startDate, endDate)}
        url="/dashboard/customer-acquisition-cost"
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              value={
                cac.isLoading
                  ? ''
                  : getMetricValue(
                      cac.data?.customerAcquisitionCost,
                      formatMonetary,
                    )
              }
              label={formatRangeWithShortYear(startDate, endDate)}
            />
            <CACChart {...childProps} onQueryStateChange={onQueryStateChange} />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default CustomerAcquisitionCostGlance;
