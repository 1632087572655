import { useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import useOneColor from '@/hooks/useOneColor';
import {
  GUTTER,
  NUM_COLS,
  ROW_HEIGHT,
} from '@/pages/Dashboard/constants/gridConstants';

/**
 * Creates a SVG image of grid cell placeholders based on the given width, for
 * use as a CSS background
 *
 * @param {number} width Width of the grid container
 * @returns {string} CSS background property value
 */
function useGridBackground(width) {
  const isOneColorEnabled = useOneColor();
  return useMemo(() => {
    if (!width) return null;

    const cellWidth =
      Math.round(((width - GUTTER * (NUM_COLS - 1)) / NUM_COLS) * 10) / 10;
    const svg = renderToStaticMarkup(
      <svg
        viewBox={`0 0 ${width} ${ROW_HEIGHT + GUTTER}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {Array(NUM_COLS)
          .fill()
          .map((_, idx) => (
            <rect
              // eslint-disable-next-line react/no-array-index-key -- no unique identifier available
              key={idx}
              x={0.5 + (cellWidth + GUTTER) * idx}
              y="0.5"
              width={cellWidth - 1.5}
              height={ROW_HEIGHT - 1}
              rx="8"
              fill={isOneColorEnabled ? '#e4e2e6' : '#E6EBFA'}
              stroke={isOneColorEnabled ? '#c8c6ca' : '#CCDAFF'}
            />
          ))}
      </svg>,
    );

    return `url('data:image/svg+xml;utf8,${encodeURIComponent(svg)}') repeat-y`;
  }, [width, isOneColorEnabled]);
}

export default useGridBackground;
