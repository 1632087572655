import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { CUSTOM_CHART_INFO } from '@/cacheKeys';
import { getChartMultiVariable } from '@/services/dashboard.service';

/**
 * Retrieves a list of custom charts defined for the selected scenario(s), for
 * populating the dashboard.
 *
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export default function useCustomChartsQuery() {
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);

  const query = useQuery(
    [CUSTOM_CHART_INFO, scenarioId],
    async () => {
      const { data } = await getChartMultiVariable(scenarioId);
      return data.data;
    },
    { staleTime: 30000 },
  );

  return useMemo(
    () => {
      const data = query.data?.map((datum) => {
        const { id } = datum;

        return {
          ...datum,
          displayName: id,
          name: id,
        };
      });

      return {
        ...query,
        data,
      };
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [query?.isFetching, scenarioId],
  );
}
