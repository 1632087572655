// @ts-check
import detectCircularRef from '@/helpers/circularReference';
import CircularRefCellRenderer from './CircularRefCellRenderer';

/**
 * @typedef {{
 *   id: string;
 *   name: string;
 *   variableValueFaultType: import('@/helpers/circularReference').ValueFaultTypes;
 *   faulted: boolean;
 * }} CircularRefCellRendererData
 */

/**
 * A CellRendererSelector for displaying the circular reference icon when found
 *
 * @example
 *   columnDefs={[
 *    {
 *      ...,
 *      cellRendererSelector: CircularRefCellRendererSelector
 *    }
 *   ]}
 *
 * @type {import('ag-grid-community').CellRendererSelectorFunc<CircularRefCellRendererData>}
 */
const CircularRefCellRendererSelector = ({ data }) => {
  const isCircularRef = detectCircularRef(data);
  return isCircularRef ? { component: CircularRefCellRenderer } : undefined;
};

export default CircularRefCellRendererSelector;
