import Api from './Api';

/**
 * Get the list of scenarios for a company
 *
 * @returns {Promise} API response
 */
export const getScenario = () => {
  return Api.get('/scenarios');
};

/**
 * Shares a scenario with an email
 *
 * @param {Object} params
 * @param {number} params.scenarioId - The specific scenario that needs to be
 *   shared
 * @param {number} params.inviterId - The user that is sharing the scenario to a
 *   user
 * @param {string} params.email - Email of the reciever of the invite
 * @param {DASHBOARD_ONLY | VIEWER | EDITOR} params.scenarioRole - Specific
 *   assigned role
 * @returns {Promise} API response
 */
export const shareScenario = (params) => {
  return Api.post('/scenario/share/scenario', params);
};

/**
 * Deletes the scenario with the given ID
 *
 * @param {number} id
 * @returns {Promise} API response
 */
export const deleteScenario = (id) => {
  return Api.delete(`/scenario/${id}`);
};

/**
 * Create a new scenario
 *
 * @param {Object} scenario The POST body necessary to create a scenario
 * @returns {Promise} API response
 */
export const createScenario = (scenario) => {
  const { notes } = scenario;
  return Api.post('/scenario', { ...scenario, notes: notes?.trim() });
};

/**
 * Edit an existing scenario
 *
 * @param {Object} scenario The PUT body necessary to edit a scenario
 * @returns {Promise} API response
 */
export const updateScenario = (scenario) => {
  const { scenarioId, notes } = scenario;
  return Api.put(
    '/scenario',
    { ...scenario, notes: notes?.trim() },
    { params: { scenarioId } },
  );
};

/**
 * Get users of a scenario
 *
 * @param {string} scenarioId The URL param of scenarioId
 * @returns {Promise} API response
 */
export const getScenarioUsers = (scenarioId) => {
  return Api.get(`scenario/getUsers/${scenarioId}`);
};

/**
 * Revoke access of user from a scenario
 *
 * @param {number} userId
 * @param {number} scenarioId
 * @returns {Promise} API response
 */
export const revokeUserAccess = (userId, scenarioId) => {
  return Api.delete(`/scenario/revoke/access/${userId}/${scenarioId}`);
};

/**
 * Duplicate a scenario
 *
 * @param {Object} scenario - The POST body necessary to create a scenario
 * @returns {Promise} API response
 */
export const duplicateScenario = (scenario) => {
  const { duplicatedFrom } = scenario;
  return Api.post('/scenario/copyScenario', scenario, {
    params: { scenarioId: duplicatedFrom },
  });
};
