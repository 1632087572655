// @ts-check
import { useContext } from 'react';
import { BillSessionContext } from '@/contexts/BillSessionProvider';

/**
 * Custom hook to get the Bill Session Info
 *
 * @type {() => import('@/contexts/BillSessionProvider').BillSessionContextType}
 */
const useBillSession = () => {
  return useContext(BillSessionContext);
};

export default useBillSession;
