import { Fragment } from 'react';
import { EMPTY_CELL_VALUE } from '@/components/common/Spreadsheet/constants';
import './ExpenseCriteriaRenderer.scss';

const ExpenseCriteriaRenderer = ({ value }) => {
  const splittedValue = value?.split(' AND ');

  return splittedValue ? (
    <div className="ExpenseCriteriaRenderer">
      {splittedValue.map((val, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key -- no unique identifier available
          <Fragment key={index}>
            {val}
            {index < splittedValue.length - 1 && (
              <div
                className="ExpenseCriteriaRenderer_Operator"
                data-testid="expense-criteria-renderer"
              >
                AND
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  ) : (
    <span>{EMPTY_CELL_VALUE}</span>
  );
};

export default ExpenseCriteriaRenderer;
