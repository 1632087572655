import { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import FormLabel from '@/components/common/FormLabel';
import FormulaField from '@/components/common/FormulaField';
import FormulaValidationMessageWithLink from '@/components/common/FormulaValidationMessageWithLink';
import InfoTooltip from '@/components/common/InfoTooltip';
import Select from '@/components/common/Select';
import { findCurrencySymbol } from '@/constants/currencies';
import {
  SET_COMPENSATION_UNIT,
  SET_COMPENSATION_FORMULA,
} from '@/constants/employees';
import {
  DECIMAL_PLACES_ERROR,
  MAX_LIMIT_ERROR,
  MIN_LIMIT_ERROR,
} from '@/constants/errors';
import VALID_FORMULA_MSG from '@/constants/formulas';
import { symbols, units } from '@/constants/variables';
import { compose, isNumber } from '@/helpers/index';
import {
  validateDecimalPlaces,
  validateLowerBound,
  validateUpperBound,
} from '@/helpers/validators';
import validateCustomFormula from '@/services/formula.service';
import './EditForm.scss';

const BONUS_TOOLTIP_MESSAGE =
  'The bonus/commission field allows you to use formula and variables to drive the employee bonus/commission calculation. It can be calculated as a percent of the employee annual salary or an actual bonus/commission amount.';
const compensationTypes = {
  BONUS_FORMULA: 'bonusFormula',
};

const validateStaticNumber = (
  { compensationFormula, compensationType },
  { isPercentageValue },
) => {
  const validate = compose(
    validateDecimalPlaces,
    validateUpperBound,
    validateLowerBound,
  );

  const { msg } = validate({
    value: compensationFormula,
    minLimit: 0,
    maxLimit:
      isPercentageValue && compensationType === compensationTypes.BONUS_FORMULA
        ? 100
        : null,
    maxDecimals: 2,
    msg: '',
  });

  switch (msg) {
    case MIN_LIMIT_ERROR:
      return { isValid: false, msg: `Value can't be negative` };
    case MAX_LIMIT_ERROR:
      return { isValid: false, msg: 'Bonus can not be more than 100 percent' };
    case DECIMAL_PLACES_ERROR:
      return {
        isValid: false,
        msg: `Decimal inputs are allowed up to 2 decimal places.`,
      };
    default:
      return { isValid: true, msg: VALID_FORMULA_MSG };
  }
};

const FormulaBanner = () => {
  return (
    <div className="alert alert-primary">
      The formula will be used to generate monthly bonus/commission and a floor
      of zero will be applied (i.e., must be greater than or equal to zero)
    </div>
  );
};

export const CompensationPlan = ({
  scenarioId,
  currentRecord,
  setIsFormulaValid,
  setCurrentRecord,
}) => {
  const [validationMsg, setValidationMsg] = useState('');

  const autocompleteOptions = useSelector(
    ({ variables }) => variables.autocompleteOptions,
  );

  const isPercentageValue = currentRecord.unit === units.PERCENTAGE;

  const handleValidation = useCallback(async () => {
    if (!currentRecord.compensationFormula?.trim()) {
      setIsFormulaValid(true);
      setValidationMsg('');
      return;
    }
    if (isNumber(currentRecord.compensationFormula)) {
      const { isValid, msg } = validateStaticNumber(currentRecord, {
        isPercentageValue,
      });
      setValidationMsg(msg);
      setIsFormulaValid(isValid);
    } else {
      const {
        data: { data },
      } = await validateCustomFormula(
        scenarioId,
        currentRecord.compensationFormula,
      );
      setValidationMsg(data.valid ? VALID_FORMULA_MSG : data?.error);
      setIsFormulaValid(data.valid);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [autocompleteOptions, currentRecord.compensationFormula]);

  useEffect(() => {
    handleValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [currentRecord.unit, autocompleteOptions]);

  return (
    <>
      <div className="Form_Group SelectBonusOrCommissions_Container">
        <div className="EmployeeOptions_Container">
          <FormLabel htmlFor="formula-field" text="Bonus/Commission" optional />
        </div>
        <InfoTooltip data-testid="load-multiplier-tooltip">
          {BONUS_TOOLTIP_MESSAGE}
        </InfoTooltip>
      </div>
      <div className="Form_Group">
        <FormulaBanner />
        <div className="FormulaField_WithUnit">
          <Select
            id="compensationFormatsDropdown"
            name="compensationFormatsDropdown"
            value={
              isPercentageValue ? symbols.PERCENTAGE : findCurrencySymbol()
            }
            className="CompensationFormula_FormatOptions"
            onChange={({ target }) => {
              setCurrentRecord({
                type: SET_COMPENSATION_UNIT,
                payload:
                  target.value === symbols.PERCENTAGE
                    ? units.PERCENTAGE
                    : units.NUMBER,
              });
            }}
          >
            {[symbols.PERCENTAGE, findCurrencySymbol()].map((format) => (
              <option key={format} value={format}>
                {format}
              </option>
            ))}
          </Select>
          <FormulaField
            id="formula-field"
            value={currentRecord.compensationFormula}
            onChange={(value) => {
              setIsFormulaValid(false);
              setCurrentRecord({
                type: SET_COMPENSATION_FORMULA,
                payload: value,
              });
            }}
            onBlur={handleValidation}
            onFocus={() => {
              setValidationMsg('');
            }}
          />
          <FormulaValidationMessageWithLink message={validationMsg} />
        </div>
      </div>
    </>
  );
};

export default CompensationPlan;
