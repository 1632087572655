import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getExpensesListByDepartmentCodeAction } from '@/actions/expenses';
import {
  handleStreamDataChangeAction,
  updatePlanAction,
} from '@/actions/revenue';
import {
  isAccumulatedPlanValuesValid,
  hasEndDate,
} from '@/components/Revenue/helpers';
import CurrencyField from '@/components/common/CurrencyField';
import InfoTooltip from '@/components/common/InfoTooltip';
import MonthSpreadFields from '@/components/common/MonthSpreadFields';
import NumberField from '@/components/common/NumberField';
import Select from '@/components/common/Select';
import { SUBSCRIPTION } from '@/constants/revenueStream';
import formatDecimalNumber from '@/helpers/formatDecimalNumber';
import {
  isEmptyOrNull,
  isPercentageValid,
  isEndDateBeforeStartDate,
} from '@/helpers/validators';
import DriverDateRange from './components/DriverDateRange';
import DriverPricingPlanList from './components/DriverPricingPlanList';

const MarketingLedDriverValues = ({
  revenue,
  revenueStream,
  handleStreamDataChange,
  getExpensesList,
  expensesList,
  setIsStreamValid,
  updatePlan,
  scenarioId,
  editRevenueStream,
}) => {
  const {
    plansAssociated,
    expenseGroupId,
    costPerLead,
    leadConversionRate,
    conversionOverTime,
    startDate,
    endDate,
    noEndDate,
    revenueType,
    conversionTimeInMonth,
  } = revenueStream;
  const [isConversionOverTimeValid, setIsConversionOverTimeValid] =
    useState(true);
  useEffect(() => {
    if (!editRevenueStream) {
      handleStreamDataChange(
        'plansAssociated',
        plansAssociated.map((plan) => {
          return { ...plan, estimatedValue: 0 };
        }),
      );
      updatePlan('estimatedValue', 100, 0);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [updatePlan, editRevenueStream]);

  useEffect(() => {
    const isInputValuesValid =
      expenseGroupId &&
      Number(costPerLead) > 0 &&
      leadConversionRate > 0 &&
      isPercentageValid(leadConversionRate);

    const isAccumulatedPercentageValid =
      conversionOverTime.reduce((acc, current) => acc + Number(current), 0) ===
      100;

    const isDateValid =
      !!startDate &&
      hasEndDate(noEndDate, endDate) &&
      !isEndDateBeforeStartDate(startDate, endDate);
    setIsConversionOverTimeValid(isAccumulatedPercentageValid);
    if (
      isAccumulatedPercentageValid &&
      isInputValuesValid &&
      isDateValid &&
      isAccumulatedPlanValuesValid(revenueStream)
    ) {
      setIsStreamValid(true);
    } else {
      setIsStreamValid(false);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [revenueStream, setIsStreamValid]);

  useEffect(() => {
    if (revenueStream.startDate)
      getExpensesList({
        scenarioId,
        departmentCode: 'MARKETING',
        startDate: revenueStream.startDate,
        endDate: revenueStream.endDate,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [revenueStream.startDate, revenueStream.endDate]);

  useEffect(() => {
    if (expensesList.length === 0)
      handleStreamDataChange('expenseGroupId', null);
  }, [handleStreamDataChange, expensesList]);

  const pricingPlanListHeading =
    revenueType === SUBSCRIPTION
      ? 'How would you like to distribute your new subscribers across pricing plans?'
      : 'How would you like to distribute your new purchases across pricing plans?';

  return (
    <>
      <DriverDateRange
        revenue={revenue}
        revenueStream={revenueStream}
        handleStreamDataChange={handleStreamDataChange}
      />
      <div className="Form">
        <div className="Form_Group-halfWidth">
          <label htmlFor="expenseGroupId" className="Label">
            Expense
          </label>
          <Select
            id="expenseGroupId"
            name="expenseGroupId"
            value={expenseGroupId}
            validate={() => {
              if (expensesList.length === 0) {
                return 'Expense does not exist between the selected date range';
              }

              return isEmptyOrNull(expenseGroupId) && 'Field is required!';
            }}
            onChange={({ target: { name, value } }) =>
              handleStreamDataChange(name, value)
            }
            disabled={!revenueStream.startDate}
          >
            <option key="" value="">
              Select an expense
            </option>
            {expensesList &&
              expensesList.map((expense) => (
                <option key={expense.id} value={expense.id}>
                  {expense.name}
                </option>
              ))}
          </Select>
        </div>

        <div className="Form Form_Group">
          <div className="Form_Group-halfWidth">
            <label htmlFor="costPerLead" className="Label">
              Cost Per Lead
            </label>
            <CurrencyField
              id="costPerLead"
              name="costPerLead"
              value={costPerLead}
              onChange={({ target: { name } }, value) =>
                handleStreamDataChange(name, formatDecimalNumber(value))
              }
              validate={() =>
                Number(costPerLead) <= 0 &&
                'Cost Per Lead must be greater then 0'
              }
            />
          </div>
          <div className="Form_Group-halfWidth">
            <label htmlFor="leadConversionRate" className="Label">
              Lead Conversion %
            </label>
            <NumberField
              prefix="%"
              id="leadConversionRate"
              name="leadConversionRate"
              value={leadConversionRate}
              onChange={({ target: { name } }, value) =>
                handleStreamDataChange(name, formatDecimalNumber(value))
              }
              validate={() =>
                !isPercentageValid(leadConversionRate) &&
                'Please enter a valid percentage'
              }
            />
          </div>
        </div>

        <div className="Form_Group-halfWidth">
          <label htmlFor="conversionTimeInMonth" className="Label">
            Conversion Time
          </label>
          <Select
            id="conversionTimeInMonth"
            name="conversionTimeInMonth"
            value={conversionTimeInMonth}
            onChange={({ target: { value } }) => {
              const conversionOverTimeValues = Array(Number(value)).fill(0);
              conversionOverTimeValues[0] = 100;
              handleStreamDataChange('conversionTimeInMonth', value);
              handleStreamDataChange(
                'conversionOverTime',
                conversionOverTimeValues,
              );
            }}
          >
            <option value={1}>Same Month</option>
            {[2, 3, 4, 5, 6].map((month) => (
              <option key={month} value={month}>{`+${
                month - 1
              } Months`}</option>
            ))}
          </Select>
        </div>

        <div className="Form_Group">
          <label htmlFor="conversionOverTime" className="Label">
            % Conversion Over Time
            <InfoTooltip data-testid="conversionOverTimeTooltip">
              Estimate how quickly leads will convert to customers. Values must
              sum to 100%.
            </InfoTooltip>
          </label>
          <MonthSpreadFields
            id="conversionOverTime"
            className="AddRevenueStream_PctOverTime"
            values={conversionOverTime}
            onChange={(conversionOverTimeNew) => {
              handleStreamDataChange(
                'conversionOverTime',
                conversionOverTimeNew,
              );
            }}
            isValid={isConversionOverTimeValid}
            errorMessage="Values must sum to 100% across your selected conversion time"
          />
        </div>
        <DriverPricingPlanList
          plansAssociated={plansAssociated}
          revenue={revenue}
          showPricingPlanInPercentage
          updatePlan={updatePlan}
          heading={pricingPlanListHeading}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  revenueStream: state.revenues.revenueStream,
  expensesList: state.expenses.expensesByDepartCode,
  scenarioId: state.scenario.scenarioId,
  editRevenueStream: state.revenues.revenueStreamRecordToBeEdit,
});

export default connect(mapStateToProps, {
  handleStreamDataChange: handleStreamDataChangeAction,
  getExpensesList: getExpensesListByDepartmentCodeAction,
  updatePlan: updatePlanAction,
})(MarketingLedDriverValues);
