import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  updateOrganizeExpenseAction,
  getExpensesClassesAction,
} from '@/actions/expenses';
import ErrorMessages from '@/components/common/ErrorMessages';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import Select from '@/components/common/Select';
import { slugify } from '@/helpers';

const OrganizeExpenses = ({
  scenarioId,
  show,
  setShow,
  expensesList,
  departments,
  updateOrganizeExpense,
  getExpensesClasses,
  'data-testid': dataTestId,
}) => {
  const [loading, setLoading] = useState(false);
  const [expensesChangeSet, setExpensesChangeSet] = useState({});
  const [error, setError] = useState(false);

  const handleClose = useCallback(() => {
    setShow(false);
  }, [setShow]);

  useEffect(() => {
    setLoading(false);
    setExpensesChangeSet({});
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    setLoading(false);
    setError(false);
    setExpensesChangeSet({});
  }, [show]);

  useEffect(() => {
    getExpensesClasses(scenarioId);
  }, [getExpensesClasses, scenarioId]);

  const handleDataChange = (name, categoryId, expenseId) => {
    setExpensesChangeSet({ ...expensesChangeSet, [expenseId]: categoryId });
  };

  const handleSave = async () => {
    setError(false);
    setLoading(true);
    const params = [];
    // eslint-disable-next-line no-restricted-syntax -- predates description requirement
    for (const [expenseId, category] of Object.entries(expensesChangeSet)) {
      const catWithSubCat = category.split('_');
      // eslint-disable-next-line radix -- predates description requirement
      const expenseClassId = parseInt(catWithSubCat[0].split('-')[1]);

      const subCat =
        catWithSubCat.length === 2 ? catWithSubCat[1].split('-') : null;
      // eslint-disable-next-line radix -- predates description requirement
      const departmentId = subCat ? parseInt(subCat[1]) : null;

      if (departmentId) {
        params.push({
          expenseGroupId: expenseId,
          expenseClassId,
          departmentId,
        });
      }
    }

    if (params.length) {
      try {
        await updateOrganizeExpense(params, scenarioId, true);
        setLoading(false);
        handleClose();
      } catch (e) {
        setError(e.response?.data?.error?.errorMessage || e.message);
        setLoading(false);
      }
    } else {
      setLoading(false);
      handleClose();
    }
  };

  const getSelectedCategoryId = (expense) => {
    // eslint-disable-next-line no-nested-ternary, no-prototype-builtins -- predates description requirement
    return expensesChangeSet.hasOwnProperty(expense.id)
      ? expensesChangeSet[expense.id]
      : expense.departmentId
        ? `expCls-${expense.expenseClassId}_depart-${expense.departmentId}`
        : `expCls-${expense.expenseClassId}`;
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="innerspace"
      data-testid={dataTestId}
    >
      <Modal.Header className="min-header">
        <h2>Organize</h2>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <ErrorMessages error={error} />
        <Form>
          <Form.Row>
            <Col sm={5} md={5} className="d-md-block d-none">
              <Form.Label className="align-field-text pl-0 mb-auto mb-sm-3">
                Expenses
              </Form.Label>
            </Col>
            <Col sm={7} md={7}>
              <Form.Label className="align-field-text pl-0 mb-3">
                Departments
              </Form.Label>
            </Col>
            <Col md={12}>
              <div className="scrollbar scroll-modal pt-0">
                <div style={{ maxHeight: '500px' }}>
                  {expensesList &&
                    expensesList.map((expense) => {
                      const selectId = `${slugify(expense.name)}-select`;
                      return (
                        <Form.Row
                          key={expense.id}
                          className="mb-2 align-items-center"
                        >
                          <Col md={5}>{expense.name}</Col>
                          <Col md={7}>
                            <Select
                              name="expenseCategory"
                              value={getSelectedCategoryId(expense)}
                              onChange={(e) =>
                                handleDataChange(
                                  e.target.name,
                                  e.target.value,
                                  expense.id,
                                )
                              }
                              id={selectId}
                              data-testid={selectId}
                              disabled={!!expense.parentId}
                            >
                              <option defaultValue={null} hidden>
                                --
                              </option>
                              {departments.map(
                                ({ expenseClassId, name, id }) => (
                                  <option
                                    value={`expCls-${expenseClassId}_depart-${id}`}
                                    key={id}
                                  >
                                    {name}
                                  </option>
                                ),
                              )}
                            </Select>
                          </Col>
                        </Form.Row>
                      );
                    })}
                </div>
              </div>
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Row className="w-100 mb-4">
          <Col md={6} sm={12} className="pl-0 pr-0 pr-sm-2 mb-2">
            <Button
              className="btn-block btn-md m-sm-0 m-auto"
              variant="cancel"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Col>
          <Col md={6} sm={12} className="pr-0 pl-0 pl-sm-2">
            <Button
              className="btn-block btn-md m-sm-0 m-auto float-sm-right float-none"
              variant="primary"
              onClick={handleSave}
              disabled={loading}
              data-testid="expense-organize-save"
            >
              {loading ? <LoadingSpinner /> : 'Save'}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = ({ expenses, scenario }) => ({
  scenarioId: scenario.scenarioId,
  departments: expenses.departments,
});

export default connect(mapStateToProps, {
  updateOrganizeExpense: updateOrganizeExpenseAction,
  getExpensesClasses: getExpensesClassesAction,
})(OrganizeExpenses);
