// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';

/**
 * Retrieves the scenario id from `state.compareScenarioId` in redux store
 *
 * @type {() => number}
 */
const useComparisonScenarioId = () => {
  return useSelector((state) => {
    return state.scenario.compareScenarioId;
  });
};

export default useComparisonScenarioId;
