// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
const ErrorDiv = ({ error }) => {
  return (
    <div
      style={{
        background: '#FF9494',
        margin: '10px   0px',
        borderRadius: 5,
      }}
    >
      <p className="text-center m-0 text-white">{error}</p>
    </div>
  );
};
export default ErrorDiv;
