export const ADD_CUSTOM_JOB_TITLE = 'employees/ADD_CUSTOM_JOB_TITLE';
export const DELETE_CUSTOM_JOB_TITLE = 'employees/DELETE_CUSTOM_JOB_TITLE';
export const SET_EMPLOYEES_LIST = 'employees/SET_EMPLOYEES_LIST';
export const SET_UNLINKED_EMPLOYEES_LIST =
  'employees/SET_UNLINKED_EMPLOYEES_LIST';
export const SET_EMPLOYEES_ERROR = 'employees/SET_EMPLOYEES_ERROR';
export const SET_EMPLOYEES_BY_DEPARTMENT =
  'employees/SET_EMPLOYEES_BY_DEPARTMENT';
export const SET_DEPARTMENTS = 'employees/SET_DEPARTMENTS';
export const SET_PENDING_EMPLOYEES = 'employees/SET_PENDING_EMPLOYEES';
export const SET_SHOW_REVIEW_EMPLOYEE_MODAL =
  'employees/SET_SHOW_REVIEW_EMPLOYEE_MODAL';
export const SET_ALL_JOB_TITLES = 'employees/SET_ALL_JOB_TITLES';
export const UPDATE_EMPLOYEE = 'employees/UPDATE_EMPLOYEE';
export const SET_HIRING_DRIVERS_LIST = 'hiringDrivers/SET_HIRING_DRIVERS_LIST';
export const SET_HIRING_DRIVER_RECORDS =
  'hiringDrivers/SET_HIRING_DRIVER_RECORDS';
