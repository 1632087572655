// @ts-check
import { useCallback, useState } from 'react';
import CrossIcon from '@bill/cashflow.assets/cross';
import { useMutation } from '@tanstack/react-query';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';
import FormulaField from '@/components/common/FormulaField';
import FormulaValidationMessage from '@/components/common/FormulaValidationMessage';
import Modal from '@/components/common/Modal';
import {
  SIX_MONTH_FORMULA_TYPE,
  TWELVE_MONTH_FORMULA_TYPE,
  CUSTOM_FORMULA_TYPE,
  THREE_MONTH_FORMULA_TYPE,
  DATA_TEST_ID_6MTH_BTN,
  DATA_TEST_ID_12MTH_BTN,
  DATA_TEST_ID_CANCEL_BTN,
  DATA_TEST_ID_SAVE_BTN,
  DATA_TEST_ID_CLOSE_BTN,
  DATA_TEST_ID_CUSTOM_BTN,
  DATA_TEST_ID_3MTH_BTN,
  INVALID_FORMULA_MSG,
  ID_APPLY_TO_ALL_CHECKBOX,
  ID_FORMULA_FIELD,
  DATA_TEST_ID_MODAL_BASE,
  AUTO_FORECAST,
  FORECAST_METHODS,
  FORECAST_METHOD_TYPES,
} from '@/constants/cashInOut';
import VALID_FORMULA_MSG from '@/constants/formulas';
import {
  deleteForecastMethod,
  updateForecastMethod,
} from '@/services/cashInOut';
import validateCustomFormula from '@/services/formula.service';
import createDefaultForecastFormula from './createDefaultForecastFormula';
import './EditForecastMethodModal.scss';

const BILL_HELP_URL = 'https://help.bill.com/direct/s/article/000003443';
/** @type {(customFormulaType: string) => boolean} */
const isAutoForecast = (customFormulaType) => {
  return customFormulaType === AUTO_FORECAST;
};

/**
 * @typedef {{
 *   onClose: () => void;
 *   open: boolean;
 *   scenarioId: number;
 *   gridData: import('../../types/cashInOutGrid').CashInOutGridColumns;
 *   onSetForecastMethod: (id: string, forecastMethod: string) => void;
 *   onSetForecastFormula: (id: string, forecastFormula: string) => void;
 *   forecastMethods: Record<string, string>;
 *   customFormulas: Record<string, string>;
 * }} ForecastModalProps
 */

/**
 * Modal for editing the forecast method column in the Cash In/Out grid.
 *
 * @type {(props: ForecastModalProps) => React.ReactElement}
 */
const EditForecastMethodModal = ({
  onClose,
  open,
  scenarioId,
  gridData,
  onSetForecastMethod,
  onSetForecastFormula,
  forecastMethods,
  customFormulas,
}) => {
  const { id, systemVariableName } = gridData;
  const formulaType = forecastMethods[id];
  const [applyToAllManualAdjustments, setApplyToAllManualAdjustments] =
    useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [customFormulaType, setCustomFormulaType] = useState(
    FORECAST_METHOD_TYPES[formulaType],
  );

  const [customFormula, setCustomFormula] = useState(customFormulas[id]);

  const handleCheckboxOnChange = useCallback(() => {
    setApplyToAllManualAdjustments((prev) => {
      return !prev;
    });
  }, []);

  const handleCloseModal = useCallback(() => {
    setCustomFormula('');
    setCustomFormulaType('');
    setValidationMessage('');
    setApplyToAllManualAdjustments(false);
    onClose();
  }, [onClose]);

  const mutation = useMutation({
    mutationFn: (params) => {
      return isAutoForecast(customFormulaType)
        ? deleteForecastMethod(params)
        : updateForecastMethod(params);
    },
    onSuccess: () => {
      onSetForecastMethod(gridData.id, FORECAST_METHODS[customFormulaType]);
      onSetForecastFormula(gridData.id, customFormula);
      handleCloseModal();
    },
    onError: console.error,
  });

  const submit = async (params) => {
    try {
      const {
        data: { data },
      } = await validateCustomFormula(scenarioId, customFormula);
      const msg = data?.valid ? VALID_FORMULA_MSG : data.error;
      if (msg === VALID_FORMULA_MSG) {
        mutation.mutate(params);
      }
      setValidationMessage(msg);
    } catch (error) {
      console.error(error);
      setValidationMessage(INVALID_FORMULA_MSG);
    }
  };

  const handleFormulaFieldChange = useCallback((newValue) => {
    setCustomFormula(newValue);
    setCustomFormulaType(CUSTOM_FORMULA_TYPE);
  }, []);

  const handleForecastOptionClick = useCallback(
    (months, formulaOptionType) => {
      setCustomFormulaType(formulaOptionType);
      setCustomFormula(
        createDefaultForecastFormula(systemVariableName, months),
      );
    },
    [systemVariableName],
  );

  return (
    <Modal
      onClose={handleCloseModal}
      open={open}
      data-testid={DATA_TEST_ID_MODAL_BASE}
      className="EditForecastMethod_Dialog"
    >
      <header className="ModalBase_Header">
        <h2 className="ModalBase_Heading EditForecastMethodModal_Heading">
          Forecast Method
        </h2>
        <Button
          aria-label="Close Modal"
          className="EditForecastMethodModal_CloseBtn"
          onClick={handleCloseModal}
          data-testid={DATA_TEST_ID_CLOSE_BTN}
        >
          <CrossIcon
            focusable={false}
            aria-hidden
            className="EditForecastMethodModal_CloseIcon"
          />
        </Button>
      </header>
      <section className="EditForecastMethodModal_Options">
        <Button
          className="EditForecastMethodModal_ForecastOption"
          aria-pressed={isAutoForecast(customFormulaType)}
          data-testid={AUTO_FORECAST}
          onClick={() => {
            setCustomFormula(null);
            setCustomFormulaType(AUTO_FORECAST);
          }}
        >
          Auto Forecast
        </Button>
        <Button
          className="EditForecastMethodModal_ForecastOption"
          aria-pressed={customFormulaType === THREE_MONTH_FORMULA_TYPE}
          data-testid={DATA_TEST_ID_3MTH_BTN}
          onClick={() => {
            handleForecastOptionClick(3, THREE_MONTH_FORMULA_TYPE);
          }}
        >
          3mth rolling avg
        </Button>
        <Button
          className="EditForecastMethodModal_ForecastOption"
          aria-pressed={customFormulaType === SIX_MONTH_FORMULA_TYPE}
          data-testid={DATA_TEST_ID_6MTH_BTN}
          onClick={() => {
            handleForecastOptionClick(6, SIX_MONTH_FORMULA_TYPE);
          }}
        >
          6mth rolling avg
        </Button>
        <Button
          className="EditForecastMethodModal_ForecastOption"
          aria-pressed={customFormulaType === TWELVE_MONTH_FORMULA_TYPE}
          data-testid={DATA_TEST_ID_12MTH_BTN}
          onClick={() => {
            handleForecastOptionClick(12, TWELVE_MONTH_FORMULA_TYPE);
          }}
        >
          12mth rolling avg
        </Button>
        <Button
          className="EditForecastMethodModal_ForecastOption"
          aria-pressed={customFormulaType === CUSTOM_FORMULA_TYPE}
          data-testid={DATA_TEST_ID_CUSTOM_BTN}
          onClick={() => {
            setCustomFormula('');
            setCustomFormulaType(CUSTOM_FORMULA_TYPE);
          }}
        >
          Custom
        </Button>
      </section>
      <section className="EditForecastMethodModal_FormulaFieldContainer">
        {isAutoForecast(customFormulaType) ? (
          <div
            className="EditForecastMethodModal_AutoForecasting"
            data-testid="auto-forecasting"
          >
            <span className="EditForecastMethodModal-description">
              BILL Cash Flow Forecasting uses a statistical model to build an
              auto forecast by looking at your past trends. Learn more about how
              Auto Forecasting works{' '}
              <a
                className="link"
                href={BILL_HELP_URL}
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </span>
          </div>
        ) : (
          <>
            <FormulaField
              id={ID_FORMULA_FIELD}
              data-testid={ID_FORMULA_FIELD}
              value={customFormula}
              onBlur={async (event) => {
                const {
                  data: { data },
                } = await validateCustomFormula(scenarioId, event.target.value);
                setValidationMessage(
                  data?.valid ? VALID_FORMULA_MSG : data.error,
                );
              }}
              onChange={handleFormulaFieldChange}
            />
            <FormulaValidationMessage message={validationMessage} />
          </>
        )}
      </section>
      <footer className="ModalBase_Footer EditForecastMethodModal_Footer">
        <Checkbox
          onChange={handleCheckboxOnChange}
          id={ID_APPLY_TO_ALL_CHECKBOX}
          checked={applyToAllManualAdjustments}
          data-testid={ID_APPLY_TO_ALL_CHECKBOX}
        >
          Apply this formula to all manual adjustments
        </Checkbox>
        <div className="EditForecastMethodModal_Footer-Buttons">
          <Button
            className="Button-cancelLink EditForecastMethodModal_CancelBtn"
            data-testid={DATA_TEST_ID_CANCEL_BTN}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            loading={mutation.isLoading}
            disabled={mutation.isLoading}
            onClick={async () => {
              const params = {
                scenarioId,
                cashAccountId: gridData.id.replace('-EXPENSE', ''),
                forecastFormula: customFormula,
                clearFutureUserActuals: applyToAllManualAdjustments,
              };
              if (isAutoForecast(customFormulaType)) {
                mutation.mutate(params);

                return;
              }
              await submit(params);
            }}
            data-testid={DATA_TEST_ID_SAVE_BTN}
            className="EditForecastMethodModal_Btn"
          >
            Save
          </Button>
        </div>
      </footer>
    </Modal>
  );
};

export default EditForecastMethodModal;
