import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useFocusOnEdit from '@/components/common/Spreadsheet/editors/useFocusOnEdit';
import { getSizeForInput } from '@/helpers';

/**
 * A text cell editor for use in ag-Grid
 *
 * @example
 *   columnDefs={[
 *    {
 *      ...
 *      cellEditor: TextEditor,
 *      editable: true
 *    }
 *   ]}
 *
 * @see https://www.ag-grid.com/react-grid/component-cell-editor/
 */
const TextEditor = forwardRef(
  ({ charPress, colDef, node, value, placeholder = '' }, ref) => {
    const input = useRef(null);
    const [localValue, setLocalValue] = useState(charPress ?? value ?? '');
    const size = getSizeForInput(localValue);

    useImperativeHandle(ref, () => ({
      getValue: () => localValue,
    }));

    useFocusOnEdit(input);

    return (
      <div className="Spreadsheet_CellEditor">
        <input
          ref={input}
          type="text"
          className="Spreadsheet_Input"
          size={size}
          placeholder={placeholder}
          name={colDef.field}
          value={localValue}
          data-testid={`${node.id}-${colDef.field}-input`}
          onChange={({ target }) => setLocalValue(target.value)}
        />
      </div>
    );
  },
);

TextEditor.propTypes = {
  /** Character that triggered editing on the cell, if any */
  charPress: PropTypes.string,
  /** The column definition, provided by ag-Grid */
  colDef: PropTypes.objectOf(PropTypes.any).isRequired,
  /** The row context, provided by ag-Grid */
  node: PropTypes.objectOf(PropTypes.any).isRequired,
  /** Value of the cell */
  value: PropTypes.string,
  /** Placeholder of the cell when there is no value */
  placeholder: PropTypes.string,
};

export default TextEditor;
