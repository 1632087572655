import PlanName from '@/components/Revenue/PlanName';
import IncrementableField from '@/components/common/IncrementableField';
import InfoTooltip from '@/components/common/InfoTooltip';
import NumberField from '@/components/common/NumberField';
import { REVENUE_STRIPE } from '@/constants/integrations';
import { isPercentageValid } from '@/helpers/validators';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';

const DriverPricingPlanList = ({
  plansAssociated,
  revenue,
  showPricingPlanInPercentage,
  updatePlan,
  heading,
  tooltip,
}) => {
  const isFromStripe = revenue?.externalSource === REVENUE_STRIPE;
  const hasWritePermission = useNonDashboardWritePermission();

  return (
    <div className="Form_Group">
      <p className="Form_Desc">
        {heading}
        {showPricingPlanInPercentage && (
          <InfoTooltip data-testid="pricingPlanPercentageTooltip">
            The distribution across your pricing plans has to add up to 100
          </InfoTooltip>
        )}
      </p>
      <div>
        {plansAssociated.map((plan, index) => {
          return (
            <div className="MonthlyGrowthDriverValues_Plan" key={plan.id}>
              <PlanName
                product={plan}
                className="MonthlyGrowthDriverValues_PlanName"
                tooltip={tooltip}
              />
              <div className="MonthlyGrowthDriverValues_PlanValue">
                {showPricingPlanInPercentage ? (
                  <NumberField
                    prefix="%"
                    id={`estimatedValue-${plan.id}`}
                    name={`estimatedValue-${index}`}
                    value={plan.estimatedValue}
                    onChange={(_, value) =>
                      updatePlan('estimatedValue', value, index)
                    }
                    validate={(val) =>
                      !isPercentageValid(val) &&
                      'Please enter a valid percentage'
                    }
                    disabled={isFromStripe}
                  />
                ) : (
                  <IncrementableField
                    id={`estimatedValue-${plan.id}`}
                    value={plan.estimatedValue}
                    onUpdate={(number) =>
                      updatePlan('estimatedValue', number, index)
                    }
                    disabled={isFromStripe || !hasWritePermission}
                    minValue={1}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DriverPricingPlanList;
