import { useMemo } from 'react';

/**
 * Generate data suitable for MonthlySpreadsheet from chart queries
 *
 * @param {Array} queries - Chart queries
 */
const useTotalExpensesTableData = (queries) => {
  return useMemo(() => {
    if (!queries.length) return [];
    const metricsArray = queries.reduce(
      (metrics, { data: { data }, scenario }) => {
        if (!data) return metrics;
        data.forEach(({ data: monthlyData, name }) => {
          const existingMetricData = metrics.find(
            (metric) => metric.name === name,
          );
          if (!existingMetricData) {
            const months = monthlyData.map(({ month, value, totalY }) => ({
              month,
              value: [
                {
                  scenarioId: scenario.scenarioId,
                  value,
                  totalY,
                },
              ],
            }));
            metrics.push({ months, name });
            return;
          }

          existingMetricData.months = existingMetricData.months.map(
            ({ value, ...props }, idx) => ({
              ...props,
              value: [
                ...value,
                {
                  scenarioId: scenario.scenarioId,
                  value: monthlyData[idx].value,
                  totalY: monthlyData[idx].totalY,
                },
              ],
            }),
          );
        });
        return metrics;
      },
      [],
    );
    if (!metricsArray.length) return metricsArray;

    const [{ months }] = metricsArray;
    const additionalMetricMonthValue = months.map(({ value, ...prop }) => {
      return {
        ...prop,
        value: value.map(({ scenarioId, totalY }) => ({
          scenarioId,
          value: totalY,
        })),
      };
    });

    const additionalMetric = {
      months: additionalMetricMonthValue,
      name: 'Total Expenses',
    };

    return [...metricsArray].reverse().concat(additionalMetric);
  }, [queries]);
};

export default useTotalExpensesTableData;
