import SET_EXTERNAL_BALANCE_SHEET from '@/actionTypes/reports';
import transformDataForGrid from './helpers/transformDataForGrid';

const INITIAL_STATE = {
  balanceSheet: {
    asset: [],
    liabilities: [],
    equity: [],
  },
  externalBalanceSheet: [],
};

const reports = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EXTERNAL_BALANCE_SHEET:
      return {
        ...state,
        externalBalanceSheet: transformDataForGrid(action.payload.children),
      };
    default:
      return state;
  }
};

export default reports;
