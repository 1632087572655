// @ts-check
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client/react';
import { FederatedParcel } from '@bill/cashflow.mfe-support';
import { INSIGHTS_REMOTE } from '@/components/Remote/Insights/remoteModule';
import GlobalLoader from '@/components/common/GlobalLoader';
import { NO_ACCESS_PATH } from '@/constants/pages';
import { BillProfileType } from '@/contexts/BillSessionProvider';
import loadRemoteModule from '@/helpers/loadRemoteModule';
import useBillSession from '@/hooks/useBillSession';
import useInsightsBaseUrl from '@/hooks/useInsightsBaseUrl';
import './Insights.scss';

/** @typedef {{ props: import('@/types/insights').InsightsProps }} InsightsProps */

const Insights = () => {
  const client = useApolloClient();
  const baseUrl = useInsightsBaseUrl();
  const { isSessionLoading, sessionInfo } = useBillSession();
  const history = useHistory();
  const loaderFn = useCallback(() => loadRemoteModule(INSIGHTS_REMOTE), []);
  const isInsightsAvailable = useMemo(
    () =>
      sessionInfo?.profile.type !== BillProfileType.Approver &&
      sessionInfo?.neoFunctionality.OrgFeatures.isInsightsAccessibleToUser,
    [sessionInfo],
  );
  if (!isInsightsAvailable && !isSessionLoading) {
    history.push(NO_ACCESS_PATH);
  }

  /** @type {InsightsProps} */
  const customProps = useMemo(() => {
    return {
      props: {
        mfeName: 'Insights',
        apolloClient: client,
        baseUrl,
        orgId: sessionInfo?.organizationId,
      },
    };
  }, [client, baseUrl, sessionInfo]);

  return isSessionLoading ? (
    <GlobalLoader />
  ) : (
    <div className="InsightsRoot" data-testid="insights-mfe">
      <FederatedParcel loaderFn={loaderFn} customProps={customProps} />
    </div>
  );
};

export default Insights;
