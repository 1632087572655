import PropTypes from 'prop-types';
import TableExportButton from '@/components/TableExportButton';

/**
 * Displays a toolbar for the MonthlySpreadsheet component, containing a table
 * heading and any an option to export the table data to CSV.
 *
 * @example
 *   <RevenueTitleBar gridApi={apiRef} heading="Products" />;
 */
const RevenueTitleBar = ({ gridApi, heading }) => {
  return (
    <div className="Revenue_TitleBar">
      {heading}
      {gridApi && (
        <TableExportButton
          className="ExportButton"
          data-testid={`revenue-${heading}-export`}
          onClick={() => gridApi.api.exportDataAsExcel()}
        />
      )}
    </div>
  );
};

RevenueTitleBar.propTypes = {
  /**
   * AG Grid's api
   *
   * @see https://ag-grid.com/react-data-grid/grid-api/
   */
  gridApi: PropTypes.objectOf(PropTypes.any),
  /** The heading of the table */
  heading: PropTypes.string,
};

export default RevenueTitleBar;
