/**
 * ThreatMetrix UI Profiling Integrate Tags.js CDN This Function load tags.js
 * Script with ORG_ID & Session ID, to determine user is allowed to access
 * Finmark Application.
 *
 * @type {(org: string, document: HTMLDocument, window: Window) => void}
 */
export default function registerDevice(org, sessionId) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `https://tm.bdc-cdn.com/fp/tags.js?org_id=${org}&session_id=${sessionId}`;
  script.async = true;
  script.tabIndex = -1;
  script.setAttribute('nonce', 'BDC_CSP_NONCE');
  document.body.appendChild(script);
}
