// @ts-check
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import Link from '@/components/common/Link';
import {
  MANAGEMENT_CONSOLE_PATH,
  SWITCH_COMPANIES_PATH,
} from '@/constants/pages';
import useManagementConsole from '@/hooks/useManagementConsole';
import './ChangeCompanyLink.scss';

/**
 * Change CompanyLinkButton to show on LockoutModal header to enable users to
 * switch the company
 *
 * @example
 *   <ChangeCompanyLink />;
 */
export default function ChangeCompanyButton() {
  const hasMultipleCompaniesAccess =
    useSelector(({ companies: { companies } }) => companies).length > 1;

  const isConsoleEnabled = useManagementConsole();

  return (
    hasMultipleCompaniesAccess && (
      <Link
        to={isConsoleEnabled ? MANAGEMENT_CONSOLE_PATH : SWITCH_COMPANIES_PATH}
        className="ChangeCompanyLink"
      >
        Change company
      </Link>
    )
  );
}
