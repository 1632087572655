/**
 * Values for the Finmark color palette. Do NOT add, remove or otherwise alter
 * these values without checking with the design team.
 *
 * @see https://www.figma.com/file/KEM6uvU9B0pSKKkVslC06I/UI-Elements?node-id=76%3A2
 */
const finmarkColors = {
  raisinBlack: '#2e2e3a',
  white: '#ffffff',
  softBlack: '#5e5e62',

  grey: '#4d4d4d',
  grey2: '#808080',
  grey3: '#a6a6a6',
  grey4: '#cccccc',
  grey5: '#e6e6e6',
  grey6: '#f5f5f5',
  spanishGrey: '#989898',

  finmarkBlue: '#316bf5',
  finmarkBlue2: '#0093ff',
  finmarkBlue3: '#00b4ff',
  finmarkBlue4: '#03d1ff',

  cornflower: '#628ff8',
  cornflower2: '#92a9fb',
  cornflower3: '#bac5fd',
  cornflower4: '#dde2fe',

  crayola: '#72ddf7',
  crayola2: '#9de6f9',
  crayola3: '#c0eefb',
  crayola4: '#e1f7fd',

  turf: '#4daa57',
  turf2: '#7ec080',
  turf3: '#aad5a9',
  turf4: '#d4ead3',

  yellow: '#ffc759',
  yellow2: '#ffd584',
  yellow3: '#ffe2ad',
  yellow4: '#fff1d6',

  sunrise: '#ff8200',
  sunrise2: '#ffa253',
  sunrise3: '#ffc18c',
  sunrise4: '#ffe0c5',

  cinnabar: '#ef3e36',
  cinnabar2: '#fc7765',
  cinnabar3: '#ffa696',
  cinnabar4: '#ffd3c9',

  pink: '#eda2f2',
  pink2: '#f3baf6',
  pink3: '#f8d1f9',
  pink4: '#fce8fc',

  plum: '#ab49a3',
  plum2: '#c278ba',
  plum3: '#d8a5d1',
  plum4: '#ecd2e8',

  slate: '#7678ed',
  slate2: '#9e98f2',
  slate3: '#c0b9f7',
  slate4: '#e0dcfb',
  chartDanger: '#B32820',
  chartSuccess: '#00A557',
};

module.exports = {
  ...finmarkColors,

  /**
   * Maps Bill's OneColor naming convention to the Finmark color palette so that
   * it has the tokens necessary to create the custom CSS properties
   */
  colors: {
    // Links
    linkColorTextDefaultPrimary: finmarkColors.finmarkBlue,
    linkColorTextDefaultSecondary: finmarkColors.turf4,

    // Button
    interactiveBackgroundActionNormalInverse: finmarkColors.finmarkBlue, // btn-primary
    interactiveBackgroundActionHoverInverse: finmarkColors.cornflower, // btn-primary-hover
    interactiveBackgroundActionPressedInverse: finmarkColors.finmarkBlue2, // btn-primary-click
    buttonColorTextDefaultPrimary: finmarkColors.white, // btn-color

    buttonColorBorderDefaultSecondary: finmarkColors.turf2,
    buttonColorStateDefaultPrimary: finmarkColors.cornflower,
    buttonColorStateDisabledPrimary: finmarkColors.grey4,
    buttonColorStateDisabledTertiary: finmarkColors.cornflower4,
    buttonColorStateHoverSecondary: finmarkColors.turf,
    buttonColorTextDefaultSecondary: finmarkColors.turf,
    buttonColorTextDefaultTertiary: '#cbdaff',

    // Icons
    colorBorderTertiary: '#ccdaff',
    feedbackColorIconInfo: '#a6c0ff',
    fillInfoInverse: finmarkColors.raisinBlack,
    iconInfo: '#a6c0ff',
    iconPrimary: finmarkColors.cornflower,
    iconSecondary: '#989898',
    interactiveFocus: finmarkColors.finmarkBlue,
    fillTertiaryInverse: finmarkColors.finmarkBlue,
    iconDanger: finmarkColors.cinnabar,

    // Input Fields
    inputColorBorderFocus: finmarkColors.finmarkBlue,
    inputColorText: finmarkColors.finmarkBlue,
    interactiveForegroundNeutralDisabled: finmarkColors.grey4,
    interactiveForegroundNeutralDisabledInverse: finmarkColors.cornflower2,
    interactiveForegroundNeutralNormal: '#a6c0ff',
    radioButtonColorCircleBackground: finmarkColors.finmarkBlue,
    selectColorText: finmarkColors.cornflower,
    switchColorBoxBackgroundSelected: finmarkColors.cornflower,

    // Dropdown Menus
    listItemColorBackgroundHover: '#e4e9ff',
    listItemColorText: finmarkColors.finmarkBlue,

    // Tabs
    colorBorderInverse: '#ccdaff',
    tabItemColorText: finmarkColors.cornflower,
    tabItemColorTextHover: finmarkColors.raisinBlack,
    tabItemColorTextSelected: finmarkColors.raisinBlack,
    tabItemColorBorderBottomSelectedHover: finmarkColors.raisinBlack,

    // Banners and Alerts
    bannerColorBackgroundError: '#fffafa',
    bannerColorBackgroundInfo: '#fafbff',
    bannerColorBackgroundWarning: '#fffcfa',
    bannerColorBorderError: '#fc7765',
    bannerColorBorderInfo: '#a6c0ff',
    bannerColorBorderSuccess: finmarkColors.turf2,
    bannerColorBorderWarning: finmarkColors.sunrise2,
    bannerColorTextTitleError: finmarkColors.cinnabar,
    bannerColorTextTitleInfo: finmarkColors.cornflower,
    bannerColorTextTitleSuccess: finmarkColors.turf,
    bannerColorTextTitleWarning: finmarkColors.sunrise,

    // Loading Indicator
    dataVizSecondary: finmarkColors.cornflower4,

    // Misc
    colorBorderPrimary: '#a6c0ff',
    colorBorderSecondary: '#ccdaff',
    colorFeedbackErrorTint: '#fffafa',
    fillAccentPrimary: finmarkColors.finmarkBlue,
    fillAccentSecondary: finmarkColors.finmarkBlue,
    fillSecondary: '#e3e9fa',
    fillTertiary: '#f0f4ff',
    fillPrimary: '#f0f4ff',
    strokePrimary: '#a6c0ff',
    textPrimary: finmarkColors.finmarkBlue,
    textSecondary: finmarkColors.finmarkBlue,
    textTertiary: '#989898',
    interactiveForegroundNeutralHoverInverse: '#f0f4ff',
    feedbackColorTextInfo: finmarkColors.turf2,
    listItemColorBorderFocus: finmarkColors.cornflower2,
    iconSuccessInverse: finmarkColors.turf,
    interactiveForegroundNeutralHover: '#ccdaff',
    fillWarning: finmarkColors.yellow2,
    fillDangerInverse: finmarkColors.cinnabar2,
    textDanger: '#f95b4c',
    radioButtonColorText: finmarkColors.cornflower,
  },

  typography: {
    fontFamilyBase: '#{"Inter", sans-serif}',
    globalLetterSpacing: '-0.02em',
  },
};
