import Loader from './Loader';
import './GlobalLoader.scss';

const GlobalLoader = () => {
  return (
    <div className="GlobalLoader">
      <Loader />
    </div>
  );
};

export default GlobalLoader;
