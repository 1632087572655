import InfoDefaultIcon from '@bill/cashflow.assets/info-default';
import PropTypes from 'prop-types';
import WithTooltip from './WithTooltip';
import './InfoTooltip.scss';

/**
 * Renders a hoverable info element for providing context-sensitive help.
 *
 * @example
 *   <InfoTooltip data-testid="unique-id">
 *     This text appears in the tooltip
 *   </InfoTooltip>;
 */
const InfoTooltip = ({
  className,
  'data-testid': dataTestId,
  placement,
  children,
  icon,
}) => {
  return (
    <WithTooltip
      className={className}
      data-testid={dataTestId}
      placement={placement}
      content={children}
    >
      <span className="InfoTooltip" data-testid={`${dataTestId}-trigger`}>
        {icon || <InfoDefaultIcon />}
      </span>
    </WithTooltip>
  );
};

InfoTooltip.propTypes = {
  /** The className to apply to the component */
  'className': PropTypes.string,
  /** Unique ID for selecting the component in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Where to place the tooltip vis-a-vis the trigger */
  'placement': PropTypes.string,
  /** A injected icon component that replaces the default icon */
  'icon': PropTypes.element,
  /** The text to display in the tooltip */
  'children': PropTypes.node,
};

export default InfoTooltip;
