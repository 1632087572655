// @ts-check
import { createContext } from 'react';

/** @typedef {{ showVariance: boolean }} ExportableReportsPreferences */

/**
 * @type {React.Dispatch<
 *   React.SetStateAction<ExportableReportsPreferences>
 * >}
 */
const setPreferencesDefault = () => {};

/**
 * @type {React.Dispatch<
 *   React.SetStateAction<
 *     import('@/types/exportableReport').ExportableReportAction
 *   >
 * >}
 */
const setReportStateDefault = (_params) => {};

/** @type {React.Dispatch<React.SetStateAction<boolean>>} */
const setIsEditControlVisibleDefault = () => {};

/** @typedef {{ [key: string]: boolean | undefined }} EditableFields */

/** @type {EditableFields} */
const editableFields = {};

/**
 * @type {React.Dispatch<
 *   React.SetStateAction<import('@/contexts/ExportableReport').EditableFields>
 * >}
 */
const setEditableFieldsDefault = () => {};

const ER_CONTEXT_DEFAULTS = {
  exportableReportPreferences: {
    showVariance: true,
  },
  setPreferences: setPreferencesDefault,
  /**
   * @type {Partial<
   *   import('@/types/exportableReport').ExportableReportBaseState
   * >}
   */
  reportState: {},
  setReportState: setReportStateDefault,
  isEditControlVisible: true,
  setIsEditControlVisible: setIsEditControlVisibleDefault,
  isExporting: false,
  editableFields,
  setEditableFields: setEditableFieldsDefault,
};

/** @typedef {typeof ER_CONTEXT_DEFAULTS} ExportableReportContextType */
const ExportableReportContext = createContext(ER_CONTEXT_DEFAULTS);

export default ExportableReportContext;
