// @ts-check
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { NO_COMPARISON } from '@/constants/scenario';

const getSelectedScenarioIds = createSelector(
  [
    ({ scenario }) => scenario.scenarioId,
    ({ scenario }) => scenario.compareScenarioId,
  ],
  (scenarioId, compareScenarioId) =>
    [...new Set([scenarioId, compareScenarioId])].filter(
      (id) => id !== NO_COMPARISON,
    ),
);

/**
 * Returns the current selected base and comparison scenarios
 *
 * @type {() => number[]}
 */
function useSelectedScenarioIds() {
  return useSelector(getSelectedScenarioIds);
}

export default useSelectedScenarioIds;
