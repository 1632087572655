// @ts-check
import { useCallback, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import RevenueEmptyIcon from '@bill/cashflow.assets/revenue-empty';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { subscribeToManualSyncAction } from '@/actions/settings';
import {
  REVENUE_INTEGRATION_COLUMNS,
  REVENUE_INTEGRATION_RULES,
  REVENUE_INTEGRATION_REVENUES,
  UNMAPPED_REVENUE_ENTRIES_COUNT,
} from '@/cacheKeys';
import AddIntegrationRecordSidebar from '@/components/Revenue/DataMapping/AddIntegrationRecordSidebar';
import AddPreviewRulesSidebar from '@/components/Revenue/DataMapping/AddPreviewRulesSidebar';
import DataMappingGrid from '@/components/Revenue/DataMapping/DataMappingGrid';
import IntegrationRecordDeleteModal from '@/components/Revenue/DataMapping/IntegrationRecordDeleteModal';
import EmptyData from '@/components/common/EmptyData';
import Pagination from '@/components/common/Pagination';
import { EMPTY_CELL_VALUE } from '@/components/common/Spreadsheet/constants';
import {
  columnTypes,
  DEFAULT_ITEMS_PER_PAGE,
  NUMBER_FILTER_OPTIONS,
  DATE_FILTER_OPTIONS,
  TEXT_FILTER_OPTONS,
  filterTypes,
  columnNames,
} from '@/constants/dataMapping';
import { integrationFamily } from '@/constants/integrations';
import { REVENUE_MAPPING_PATH } from '@/constants/pages';
import { ASC } from '@/constants/tables';
import { getISODate, getUTCDayTimestamp } from '@/helpers/dateFormatter';
import { assertNoCase, isEmptyOrNull } from '@/helpers/validators';
import useCallbackRef from '@/hooks/useCallbackRef';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import useWsSubscription from '@/hooks/useWsSubscription';
import {
  getRevenueIntegrationData,
  getRevenueIntegrationMetadata,
  getRevenueIntegrationRules,
  getRevenueStreamsWithPricingPlans,
  getUnmappedEntriesCount,
} from '@/services/revenueService';
import './DataMappingTab.scss';

/** @typedef {import('@/services/revenueService').RevenueIntegrationEntriesParams['filter']} RevenueIntegrationFilter */

/** @type {import('./types').DataMappingColumnDefs} */
const EMPTY_COLUMN_DEFS = [];
/** @type {import('@/services/revenueService').RevenueStreamsWithPricingPlan[]} */
const DEFAULT_REVENUE_STREAMS = [];
const DEFAULT_PAGE_NUMBER = 1;
/** @type {import('@/services/revenueService').RevenueIntegrationMetadataResponseData[]} */
const EMPTY_GRID_COLUMNS = [];

/**
 * @type {{
 *   [key in import('@/constants/dataMapping').NumberFilterModel]: import('@/services/revenueService').FilterOperators;
 * }}
 */
const numberOperatorMap = {
  equals: 'EQUALS',
  notEqual: 'NOT_EQUALS',
  greaterThan: 'GREATER_THAN',
  greaterThanOrEqual: 'GREATER_THAN_OR_EQUALS',
  lessThan: 'LESS_THAN',
  lessThanOrEqual: 'LESS_THAN_OR_EQUALS',
};

/**
 * @type {{
 *   [key in import('@/constants/dataMapping').DateFilterModel]: import('@/services/revenueService').FilterOperators;
 * }}
 */
const dateOperatorMap = {
  equals: 'EQUALS',
  notEqual: 'NOT_EQUALS',
};

/**
 * @type {{
 *   [key in import('@/constants/dataMapping').TextFilterModel]: import('@/services/revenueService').FilterOperators;
 * }}
 */
const textOperatorMap = {
  contains: 'CONTAINS',
  equals: 'EQUALS',
  notEqual: 'NOT_EQUALS',
};

/**
 * @typedef {[
 *   import('./types').RevenueIntegrationColumn,
 *   import('@/constants/dataMapping').FilterCondition,
 * ]} FilterEntry
 */

/**
 * @type {(
 *   arg: import('@/constants/dataMapping').FilterCondition,
 * ) => arg is import('ag-grid-community').ICombinedSimpleModel}
 */
const isFilterDoubleCondition = (arg) => {
  return (
    /** @type {import('ag-grid-community').ICombinedSimpleModel} */ (arg)
      .operator !== undefined
  );
};

/**
 * @type {(
 *   accum: RevenueIntegrationFilter,
 *   current: FilterEntry,
 * ) => RevenueIntegrationFilter}
 */
const filterListReducer = (accum, [key, value]) => {
  // Double condition filtering is not yet supported
  if (isFilterDoubleCondition(value)) {
    return accum;
  }
  switch (value.filterType) {
    case filterTypes.date: {
      const utcDate = getUTCDayTimestamp(value.dateFrom);
      accum.push({
        fieldName: key,
        operator: dateOperatorMap[value.type],
        value: getISODate(utcDate),
      });
      break;
    }
    case filterTypes.number:
      accum.push({
        fieldName: key,
        operator: numberOperatorMap[value.type],
        value: value.filter,
      });
      break;
    case filterTypes.text:
      accum.push({
        fieldName: key,
        operator: textOperatorMap[value.type],
        value: value.filter,
      });
      break;
    default:
      assertNoCase(value);
  }
  return accum;
};

/** @type {(filters: FilterEntry[]) => RevenueIntegrationFilter} */
const getFilterParam = (filters) => {
  /** @type {RevenueIntegrationFilter} */
  const filterList = [];
  return filters.reduce(filterListReducer, filterList);
};

/**
 * Maps revenue metadata to column definitions for AG Grid
 *
 * @type {(
 *   column: import('@/services/revenueService').RevenueIntegrationMetadataResponseData,
 * ) => import('./types').DataMappingColumnDef}
 */
const gridColumnMapper = (column) => {
  /** @type {import('./types').DataMappingColumnDef} */
  const defaults = {
    field: column.name,
    headerName: column.label ?? column.name,
    editable: column.gridEditable,
    valueGetter({ data }) {
      const { value } = data.fields.find(({ name }) => name === column.name);
      return value;
    },
    valueFormatter({ value, colDef }) {
      if (colDef.headerName !== columnNames.CUSTOMER_METRIC) return value;
      return isEmptyOrNull(value) ? EMPTY_CELL_VALUE : value;
    },
  };
  switch (column.type) {
    case columnTypes.NUMBER: {
      return {
        ...defaults,
        valueGetter({ data }) {
          const { value } = data.fields.find(
            ({ name }) => name === column.name,
          );
          if (isEmptyOrNull(value)) return value;
          const valueAsNumber = Number(value);
          return !Number.isNaN(valueAsNumber) ? valueAsNumber : null;
        },
        type: /**
         * @type {Lowercase<
         *   import('@/constants/dataMapping').RevenueIntegrationColumnType
         * >}
         */ (columnTypes.NUMBER.toLowerCase()),
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: NUMBER_FILTER_OPTIONS,
          debounceMs: 500,
        },
      };
    }
    case columnTypes.DATE: {
      return {
        ...defaults,
        type: /**
         * @type {Lowercase<
         *   import('@/constants/dataMapping').RevenueIntegrationColumnType
         * >}
         */ (columnTypes.DATE.toLowerCase()),
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: DATE_FILTER_OPTIONS,
        },
      };
    }
    case columnTypes.STRING: {
      return {
        ...defaults,
        filterParams: {
          suppressAndOrCondition: true,
          filterOptions: TEXT_FILTER_OPTONS,
        },
      };
    }
    default:
      throw new Error(`Unknown column type ${column.type}`);
  }
};

/**
 * The Data Mapping screen
 *
 * @returns {import('react').ReactNode}
 */
const DataMapping = () => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const [toggleRulesSidebar, setToggleRulesSidebar] = useState(false);
  const [toggleEntrySidebar, setToggleEntrySidebar] = useState(false);
  const [editIntegrationRecord, setEditIntegrationRecord] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentRecordId, setCurrentRecordId] = useState(null);
  const [isRecordDeleted, setIsRecordDeleted] = useState(false);
  const { path } = useRouteMatch();
  const [isLoadingRows, setIsLoadingRows] = useState(true);
  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const selectedCompanyId = useSelector(
    ({ companies }) => companies.selectedCompanyId,
  );
  const queryClient = useQueryClient();

  /**
   * @type {import('@/hooks/useCallbackRef').CallbackRef<
   *   import('ag-grid-react').AgGridReact<
   *     import('@/services/revenueService').RevenueIntegrationEntry
   *   >
   * >}
   */
  const [gridApi, setGridApi] = useCallbackRef();

  /** @type {[null | number, React.Dispatch<null | number>]} */
  const [entryCount, setEntryCount] = useState(null);

  /** @type {number[]} */
  const [scenarioId] = useSelectedScenarioIds();

  /**
   * @type {import('@tanstack/react-query').UseQueryResult<
   *   import('@/services/revenueService').RevenueIntegrationMetadataResponseData[]
   * >} GridColumns
   */
  const gridColumns = useQuery(
    [REVENUE_INTEGRATION_COLUMNS, scenarioId],
    async () => {
      const { data } = await getRevenueIntegrationMetadata({
        scenarioId,
      });
      return data.data;
    },
    // We don't need to refetch this query because it's only updated once a
    // day. If the user initiates a sync from Settings, we should refetch via
    // websockets
    { staleTime: Infinity, refetchOnWindowFocus: false },
  );

  /**
   * @type {import('@tanstack/react-query').UseQueryResult<
   *   import('@/services/revenueService').RevenueIntegrationRule[]
   * >}
   */
  const rulesQuery = useQuery(
    [REVENUE_INTEGRATION_RULES, scenarioId],
    async () => {
      const { data } = await getRevenueIntegrationRules({
        scenarioId,
      });
      return data.data;
    },
    { staleTime: 30000 },
  );

  /**
   * @type {import('@tanstack/react-query').UseQueryResult<
   *   import('@/services/revenueService').RevenueStreamsWithPricingPlan[]
   * >}
   */
  const revenueStreamsQuery = useQuery(
    [REVENUE_INTEGRATION_REVENUES, scenarioId],
    async () => {
      const { data } = await getRevenueStreamsWithPricingPlans({
        scenarioId,
      });
      return data.data;
    },
    { staleTime: 30000 },
  );

  const unmappedEntriesCount = useQuery(
    [UNMAPPED_REVENUE_ENTRIES_COUNT, scenarioId],
    async () => {
      const { data } = await getUnmappedEntriesCount({
        scenarioId,
      });
      return data.data;
    },
    { staleTime: 30000, enabled: !isLoadingRows },
  );

  useWsSubscription(
    () =>
      dispatch(
        subscribeToManualSyncAction(
          selectedCompanyId,
          ({ value: isSyncing, systemType }) => {
            if (
              systemType === integrationFamily.INTEGRATION_FAMILY_REVENUE &&
              !isSyncing
            ) {
              queryClient.invalidateQueries({
                queryKey: [REVENUE_INTEGRATION_COLUMNS],
              });
            }
          },
        ),
      ),
    [selectedCompanyId, queryClient],
  );

  /** @type {import('./types').DataMappingColumnDefs} */
  const colDefs = useMemo(
    () =>
      gridColumns.isLoading
        ? EMPTY_COLUMN_DEFS
        : gridColumns.data.map(gridColumnMapper),
    [gridColumns.data, gridColumns.isLoading],
  );

  /** @type {import('./types').RevenueIntegrationColumnMap} */
  const columnNameMap = useMemo(
    () =>
      gridColumns.isLoading
        ? {}
        : gridColumns.data.reduce((accum, { name, label, excludeFromRule }) => {
            accum[name] = { label: label ?? name, name, excludeFromRule };
            return accum;
          }, {}),
    [gridColumns.data, gridColumns.isLoading],
  );

  /** @type {import('./types').RevenueIntegrationColumn} */
  const columnName = useMemo(
    () =>
      Object.values(columnNameMap).filter(
        ({ excludeFromRule }) => !excludeFromRule,
      ),
    [columnNameMap],
  );

  /**
   * @type {(
   *   integrationRecord: import('@/services/revenueService').RevenueIntegrationEntry,
   * ) => void}
   */
  const handleEditIntegrationRecord = useCallback((integrationRecord) => {
    setEditIntegrationRecord(integrationRecord);
    setToggleEntrySidebar(true);
  }, []);

  /** @type {import('ag-grid-community').GridOptions['cacheBlockSize']} */
  const cacheBlockSize = useMemo(
    () => Math.max(itemsPerPage * 2, 100),
    [itemsPerPage],
  );

  /** @type {import('ag-grid-community').IServerSideDatasource} */
  const datasource = useMemo(() => {
    return {
      /** @type {import('ag-grid-community').IServerSideDatasource['getRows']} */
      async getRows(params) {
        const { request, success, fail } = params;
        const { startRow, sortModel, filterModel } = request;
        const [sortColumn] = sortModel;
        const filters = /** @type {FilterEntry[]} */ (
          /** @type {unknown} */ (Object.entries(filterModel))
        );
        setIsLoadingRows(true);
        try {
          const { data } = await getRevenueIntegrationData({
            scenarioId,
            offset: startRow,
            limit: cacheBlockSize,
            sortField: sortColumn?.colId,
            sortDirectionAsc: !isEmptyOrNull(sortColumn)
              ? sortColumn.sort === ASC
              : undefined,
            filter: filters.length > 0 ? getFilterParam(filters) : undefined,
          });
          const { entries, total } = data.data;
          setEntryCount(total);
          success({ rowData: entries, rowCount: total });
          queryClient.invalidateQueries([UNMAPPED_REVENUE_ENTRIES_COUNT]);
        } catch (e) {
          fail();
        } finally {
          setIsLoadingRows(false);
        }
      },
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [
    setIsLoadingRows,
    setEntryCount,
    scenarioId,
    isRecordDeleted,
    cacheBlockSize,
  ]);

  const totalPages = useMemo(
    () => Math.max(Math.ceil(entryCount / itemsPerPage), 1),
    [entryCount, itemsPerPage],
  );

  /** @type {import('@/components/common/Pagination').PaginationProps['onNavigate']} */
  const handlePageNavigation = ({ page: pageNumber }) => {
    setPage(pageNumber);
    // Grid pagination is zero-index
    gridApi.api.paginationGoToPage(pageNumber - 1);
  };

  /** @type {import('@/components/common/Pagination').PaginationProps['handleItemsPerPageChanged']} */
  const handleItemsPerPageChanged = (itemPerPage) => {
    setItemsPerPage(itemPerPage);
    gridApi.api.paginationSetPageSize(itemPerPage);
    handlePageNavigation({ page: 1 });
  };

  /** @type {(param: string) => void} */
  const handleDeleteRecord = useCallback(
    (entryId) => {
      setCurrentRecordId(entryId);
      setShowDeleteModal(true);
    },
    [setCurrentRecordId, setShowDeleteModal],
  );

  return (
    <>
      <div className="PageLayout PageLayout-dataMapping">
        <AddPreviewRulesSidebar
          rules={rulesQuery.data ?? []}
          onSave={rulesQuery.refetch}
          columns={columnName}
          revenue={revenueStreamsQuery.data ?? []}
          open={toggleRulesSidebar}
          onClose={() => setToggleRulesSidebar(false)}
          isLoading={rulesQuery.isLoading && revenueStreamsQuery.isLoading}
        />
        <AddIntegrationRecordSidebar
          fields={gridColumns.data ?? EMPTY_GRID_COLUMNS}
          revenueStreams={revenueStreamsQuery.data ?? DEFAULT_REVENUE_STREAMS}
          columnNameMap={columnNameMap}
          editRecord={editIntegrationRecord}
          open={toggleEntrySidebar}
          onClose={() => {
            setToggleEntrySidebar(false);
            setEditIntegrationRecord(null);
          }}
        />

        <section className="DataMappingTab">
          {/* @ts-ignore */}

          {!gridColumns.isLoading && !colDefs.length ? (
            <EmptyData Icon={RevenueEmptyIcon} className="EmptyData-tab">
              Add data to the revenue integration for this table to populate
            </EmptyData>
          ) : (
            <DataMappingGrid
              colDefs={colDefs}
              datasource={datasource}
              unmappedEntriesCount={unmappedEntriesCount.data}
              cacheBlockSize={cacheBlockSize}
              isLoading={isLoadingRows}
              gridApi={gridApi}
              ref={setGridApi}
              revenueStreams={
                revenueStreamsQuery.data || DEFAULT_REVENUE_STREAMS
              }
              onEditIntegrationRecord={handleEditIntegrationRecord}
              onAddRecord={() => setToggleEntrySidebar(true)}
              onAddRules={() => setToggleRulesSidebar(true)}
              onDeleteRecord={handleDeleteRecord}
            />
          )}
          {/* @ts-ignore */}
        </section>

        {path === REVENUE_MAPPING_PATH && !isLoadingRows && (
          <Pagination
            data-testid="data-mapping-pagination"
            totalPages={totalPages}
            currentPage={page}
            onNavigate={handlePageNavigation}
            itemsPerPage={gridApi.api.paginationGetPageSize()}
            handleItemsPerPageChanged={handleItemsPerPageChanged}
          />
        )}
      </div>

      {showDeleteModal && (
        <IntegrationRecordDeleteModal
          setIsRecordDeleted={setIsRecordDeleted}
          setShowDeleteModal={setShowDeleteModal}
          currentRecordId={currentRecordId}
          setCurrentRecordId={setCurrentRecordId}
        />
      )}
    </>
  );
};

export default DataMapping;
