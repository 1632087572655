import {
  SET_AI_CONVERSATION_EXPORTABLE_REPORT_METADATA,
  SET_AI_CONVERSATION_COPILOT_METADATA,
  SET_AI_CONVERSATION_COPILOT_WARNING_MODAL,
} from '@/actionTypes/aiConversation';

export const copilotConfirmationTypes = /** @type {const} */ ({
  CHANGE_SCENARIO_ID: 'CHANGE_SCENARIO_ID',
  SWAP_SCENARIO_ID: 'SWAP_SCENARIO_ID',
  COMPANY_SWITCHER: 'COMPANY_SWITCHER',
  START_NEW_CONVERSATION: 'START_NEW_CONVERSATION',
});

/** @typedef {keyof typeof copilotConfirmationTypes} CopilotConfirmationType */

/**
 * Sets the AI Conversation Metadata
 *
 * @type {(
 *   type: import('@/reducers/aiConversation').AiConversationType,
 *   payload:
 *     | import('@/reducers/aiConversation').ExportableReportAiConversationMetadata
 *     | import('@/reducers/aiConversation').CopilotAiConversationMetadata,
 * ) => import('@reduxjs/toolkit').ThunkAction<any, any, any, any>}
 */
export const setAiConversationMetadata = (type, payload) => {
  return (dispatch) =>
    dispatch({
      type:
        type === 'exportableReport'
          ? SET_AI_CONVERSATION_EXPORTABLE_REPORT_METADATA
          : SET_AI_CONVERSATION_COPILOT_METADATA,
      payload: {
        conversationType: type,
        ...payload,
      },
    });
};

/**
 * @type {(
 *   payload: import('@/reducers/aiConversation').CopilotWarningModalPayload,
 * ) => import('@reduxjs/toolkit').ThunkAction<any, any, any, any>}
 */
export const setCopilotWarningModal = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_AI_CONVERSATION_COPILOT_WARNING_MODAL,
      payload,
    });
  };
};
