import ContextMenu from '@/components/common/ContextMenu';
import { expenseGroupTypes } from '@/constants/expenses';

export default function ContextMenuRenderer({
  data,
  onEdit,
  onDelete,
  onPreview,
  onExcludeFromModel,
  hasWritePermission,
}) {
  const { id, type, accountDeleted, isParentExpense, expenseGroupCriteria } =
    data;

  return (
    <ContextMenu data-testid={`${id}-expense-action`}>
      {!accountDeleted && (
        <ContextMenu.Option
          data-testid="edit-expense"
          className="pendo-EditExpense"
          onClick={() => onEdit(data)}
        >
          {hasWritePermission ? 'Edit All Options' : 'View Details'}
        </ContextMenu.Option>
      )}
      {expenseGroupCriteria?.length > 0 && (
        <ContextMenu.Option
          data-testid="preview-link-criteria-expense"
          className="pendo-PreviewLinkCriteria"
          onClick={() => onPreview(data)}
        >
          View Matched Items
        </ContextMenu.Option>
      )}
      {hasWritePermission && (
        <>
          <ContextMenu.Option
            data-testid="include-exclude-from-model"
            className="pendo-IncludeExcludeExpense"
            onClick={() => onExcludeFromModel(data)}
          >
            {`${!data.active ? 'Include in' : 'Exclude from'} model`}
          </ContextMenu.Option>

          {(type !== expenseGroupTypes.ACCOUNT || accountDeleted) &&
            !isParentExpense && (
              <ContextMenu.Option
                data-testid="delete-expense"
                className="pendo-DeleteExpense"
                danger
                onClick={() => onDelete(data)}
              >
                Delete Expense
              </ContextMenu.Option>
            )}
        </>
      )}
    </ContextMenu>
  );
}
