import type { INavItem } from '@/types/side-nav';

/**
 * @type {import('@/types/side-nav').SideNavProps['onNavigation']}
 */
// eslint-disable-next-line import/prefer-default-export -- exporting single function
export function handleOnboardingNavigation(
  to: string,
  items: INavItem[],
): INavItem[] {
  return items.reduce((acc: INavItem[], parent: INavItem) => {
    const { subNavItems } = parent;
    const basePath = to.split('/')[2];
    const isParentSelected = parent.to === to && !subNavItems;
    const isSubItemSelected =
      subNavItems !== null &&
      subNavItems?.some((navItem) => navItem.to.includes(basePath));
    return [
      ...acc,
      {
        ...parent,
        isSelected: isParentSelected || isSubItemSelected,
        subNavItems: isSubItemSelected
          ? subNavItems.map((subNavItem) => {
              return {
                ...subNavItem,
                isSelected: subNavItem.to.includes(basePath),
              };
            })
          : undefined,
      },
    ];
  }, []);
}

/**
 * @type {import('@/types/side-nav').SideNavProps['onNavigation']}
 */
export function handleMainLayoutNavigation(
  to: string,
  items: INavItem[],
): INavItem[] {
  return items.reduce((acc: INavItem[], parent: INavItem) => {
    const { subNavItems } = parent;
    const basePath = to.split('/')[1];
    const isParentSelected = parent.to === to && !subNavItems;
    const isSubItemSelected =
      parent.subNavItems !== null &&
      parent.subNavItems?.some((navItem) => navItem.to.includes(basePath));
    return [
      ...acc,
      {
        ...parent,
        isSelected: isParentSelected || isSubItemSelected,
        subNavItems: isSubItemSelected
          ? subNavItems?.map((subNavItem) => {
              return {
                ...subNavItem,
                isSelected: subNavItem.to.includes(basePath),
              };
            })
          : undefined,
      },
    ];
  }, []);
}
