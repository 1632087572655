import { useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import ExpensesEmptyIcon from '@bill/cashflow.assets/expenses-empty';
import { subscribeToActualsUpdateAction } from '@/actions/actuals';
import { subscribeToEmployeeUpdateAction } from '@/actions/employees';
import { EXPENSES_BY_CLASS } from '@/cacheKeys';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartLegend from '@/components/Charts/ChartLegend';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import PercentBarWithMetrics from '@/components/Charts/PercentBarWithMetrics';
import EmptyData from '@/components/common/EmptyData';
import { actualsFamily } from '@/constants/actuals';
import { CATEGORY_COLUMN } from '@/constants/employees';
import { debounce } from '@/helpers';
import useChartQuery from '@/hooks/useChartQuery';
import useWsSubscription from '@/hooks/useWsSubscription';
import { getCategoryChartData } from '@/services/expensesService';

const CHART_TITLE = 'Expense by Category';

const reducer = ({ expenseClassExpense }) =>
  expenseClassExpense.map((series) => ({
    ...series,
    data: [series.total],
  }));

function ExpensesByClassChart() {
  const [chart, setChart] = useState();
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);
  const expensesList = useSelector(
    ({ expenses }) => expenses.expensesList.expenses,
  );

  const scenarioQueries = useChartQuery(
    EXPENSES_BY_CLASS,
    getCategoryChartData,
    reducer,
    { dependencies: [expensesList] },
  );
  const [base] = scenarioQueries;

  const refetch = useMemo(
    () => base?.refetch && debounce(base.refetch, 5000),
    [base?.refetch],
  );

  useWsSubscription(
    () =>
      refetch &&
      dispatch(
        subscribeToActualsUpdateAction(
          scenarioId,
          actualsFamily.EXPENSE,
          refetch,
        ),
      ),
    [refetch, scenarioId],
  );

  useWsSubscription(
    () =>
      refetch && dispatch(subscribeToEmployeeUpdateAction(scenarioId, refetch)),
    [refetch, scenarioId],
  );

  const chartData = useMemo(
    () =>
      scenarioQueries.map((query) =>
        query.data.map((series) => ({
          ...series,
          scenario: query.scenario,
        })),
      ),
    [scenarioQueries],
  );

  const hasData = scenarioQueries.some(
    ({ data, isLoading }) => isLoading || data.length,
  );

  return (
    <section className="ChartPanel">
      <ChartHeader title={CHART_TITLE} />
      <ChartToolbar className="Chart_Toolbar-withLegend">
        <ChartLegend chart={chart} data-testid="expensesByClass-legend" />
        <ChartControls
          chartRef={chart}
          title={CHART_TITLE}
          data-testid="expensesByClass-export"
          disabled={!hasData}
          csvColumnFilter={CATEGORY_COLUMN}
        />
      </ChartToolbar>
      {hasData ? (
        <PercentBarWithMetrics
          data={chartData}
          data-testid="expensesByClass"
          onChartCreated={setChart}
        />
      ) : (
        <EmptyData Icon={ExpensesEmptyIcon}>
          Add Expenses for this chart to populate
        </EmptyData>
      )}
    </section>
  );
}

export default ExpensesByClassChart;
