import { useEffect, useState } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ProductTypeFilter from '@/components/ProductTypeFilter/ProductTypeFilter';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';
import FormField from '@/components/common/FormField';
import LetterIcon from '@/components/common/LetterIcon';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import Select from '@/components/common/Select';
import UserLetterIcon from '@/components/common/UserLetterIcon';
import WithTooltip from '@/components/common/WithTooltip';
import { ADMIN, USER } from '@/constants/roles';
import { ScenarioRoles, ScenarioRolesText } from '@/constants/scenario';
import { slugify } from '@/helpers';
import { validateEmail } from '@/helpers/validators';
import './AddMemberModal.scss';

const Header = ({ memberData }) => {
  if (memberData) {
    const { name, id, email } = memberData;

    return (
      <h2 className="ModalBase_Heading">
        <div className="ModalBase_AddMember_Heading">
          {name}
          <div className="AddMember_LetterIcon">
            <UserLetterIcon name={name} id={id} />
          </div>
        </div>
        <div className="ModalBase_AddMember_Email">{email}</div>
      </h2>
    );
  }

  return <h2 className="ModalBase_Heading">Invite New User</h2>;
};

const AddMemberModal = ({
  scenarios,
  addMember,
  error,
  loading,
  memberData = null,
  resetErrorMessage,
  showInviteEmail,
}) => {
  const [email, setEmail] = useState('');
  const [selectedCompanyAccess, setSelectedCompanyAccess] = useState(USER);
  const [scenariosWithAccess, setScenarioWithAccess] = useState([]);
  const [currentMemberScenarios, setCurrentMemberScenarios] = useState();
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [hasEmployeeAccess, setHasEmployeeAccess] = useState(false);
  const [isAdminSelected, setIsAdminSelected] = useState(false);

  useEffect(() => {
    if (scenarios.length) {
      setScenarioWithAccess(
        scenarios.map((scenario) => {
          const foundScenario = memberData
            ? memberData.scenarios.find(
                (s) => s.scenarioId === scenario.scenarioId,
              )
            : null;

          return {
            scenarioId: scenario.scenarioId,
            scenarioRole: foundScenario ? foundScenario.code : '',
            name: scenario.name,
            color: scenario.color,
          };
        }),
      );
    }

    if (memberData) {
      setHasEmployeeAccess(memberData.hasEmployeeAccess);
      setCurrentMemberScenarios(memberData.scenarios.slice());
      setSelectedCompanyAccess(memberData.companyRole);
      setIsAdminSelected(memberData.companyRole === ADMIN);
    }
  }, [scenarios, memberData]);

  // eslint-disable-next-line unicorn/consistent-function-scoping -- predates description requirement
  const checkValid = (typedEmail) => {
    const isValid = validateEmail(typedEmail);
    return !isValid && 'Please enter a valid email!';
  };

  const changeScenarioName = (value, scenarioId) => {
    const updatedScenarios = [...scenariosWithAccess];
    const index = updatedScenarios.findIndex(
      (s) => s.scenarioId === scenarioId,
    );

    const updatedScenario = {
      ...updatedScenarios[index],
      scenarioRole: value,
    };
    updatedScenarios[index] = updatedScenario;
    setScenarioWithAccess(updatedScenarios);
  };

  const changeScenarioAccess = (role) => {
    const updatedScenarios = [...scenariosWithAccess];
    let scenarioRole = '';
    if (role === USER) {
      setIsAdminSelected(false);
    } else if (role === ADMIN) {
      scenarioRole = ScenarioRoles.EDITOR;
      setIsAdminSelected(true);
    }

    const updatedAccess = updatedScenarios.map((scenario) => {
      return {
        ...scenario,
        scenarioRole,
      };
    });
    setScenarioWithAccess(updatedAccess);
  };

  const saveMember = () => {
    addMember({
      id: memberData?.id,
      email,
      hasEmployeeAccess,
      companyRole: selectedCompanyAccess,
      scenarios: scenariosWithAccess
        .filter((s) => s.scenarioRole !== '')
        .map((s) => ({
          scenarioId: s.scenarioId,
          scenarioRole: s.scenarioRole,
        })),
    });
  };

  const handleFinishClick = () => {
    resetErrorMessage();
    if (memberData) {
      const foundNoAccessScenario = currentMemberScenarios.some(
        (currentScenario) => {
          const isScenarioChangedToNoAccess = (s) =>
            s.scenarioId === currentScenario.scenarioId &&
            !s.scenarioRole &&
            s.scenarioRole !== currentScenario.code;

          return scenariosWithAccess.find(isScenarioChangedToNoAccess);
        },
      );

      if (foundNoAccessScenario) {
        setShowRevokeModal(true);
        return;
      }
    }

    saveMember();
  };

  const isSaveDisabled = () => {
    if (memberData) return false;
    const hasNoAccessibleScenarios = scenariosWithAccess.every(
      (val) => val.scenarioRole === '',
    );

    return (
      !validateEmail(email) ||
      !selectedCompanyAccess ||
      hasNoAccessibleScenarios
    );
  };

  const isCompanySelectionAllowed = (event) => {
    return !event && !memberData;
  };

  return (
    <ModalBase
      id="invite-member"
      onCancel={showInviteEmail}
      header={<Header memberData={memberData} />}
      footer={
        <>
          <Button
            className="Button ShareScenario_Button Button-cancelLink"
            onClick={showInviteEmail}
            data-testid="share-scenario-cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={handleFinishClick}
            data-testid="manage-scenarios-finish"
            loading={loading}
            disabled={isSaveDisabled()}
          >
            Finish
          </Button>
        </>
      }
    >
      <div>
        {error && <Alert variant="danger">{error}</Alert>}
        {!memberData && (
          <Form.Group>
            <label htmlFor="email" className="Label">
              Email Address
            </label>
            <FormField
              id="email"
              type="email"
              name="email"
              maxLength="50"
              validate={checkValid}
              placeholder="Invite someone..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        )}
        <Row>
          <Col md={5} className="pl-2">
            <Form.Group>
              <label htmlFor="company-access" className="Label">
                Company Access Level
              </label>
              <Select
                id="companyAccessSelect"
                name="companyAccessSelect"
                validate={(e) => !e && 'Please select company access'}
                value={selectedCompanyAccess}
                onChange={(e) => {
                  setSelectedCompanyAccess(e.target.value);
                  changeScenarioAccess(e.target.value);
                }}
              >
                <option value={USER}>Member</option>
                <option value={ADMIN}>Admin</option>
              </Select>
            </Form.Group>
          </Col>
        </Row>

        <ProductTypeFilter>
          <Form.Group>
            <label
              htmlFor="toggle-employee-data-access-checkbox"
              className="Label"
            >
              Access to Employee Data
            </label>
            <div className="EmployeeDataAccess_CheckmarkWrapper">
              <Checkbox
                id="toggle-employee-data-access-checkbox"
                checked={isAdminSelected ? true : hasEmployeeAccess}
                disabled={isAdminSelected}
                onChange={() => setHasEmployeeAccess(!hasEmployeeAccess)}
              >
                Select this option to allow this user to view and/or edit data
                related to employees, including salary, start and end dates, and
                load multiplier for benefits and taxes.
              </Checkbox>
            </div>
          </Form.Group>
        </ProductTypeFilter>

        <label htmlFor="email" className="Label my-3">
          Scenario Access Level
        </label>
        <Alert variant="info">
          Giving access to unshared scenarios with this user will trigger an
          email notification to them. Revoking their access will not trigger an
          email.
        </Alert>
        <div className="ScenarioList_Wrapper">
          {scenariosWithAccess.map((scenario) => {
            return (
              <Row className="ScenarioAccess_Row" key={scenario.scenarioId}>
                <Col md={7}>
                  <div className="ScenarioOption_Wrapper">
                    <LetterIcon
                      string={scenario.name}
                      color={scenario.color}
                      data-testid={`letter-icon-${slugify(scenario.name)}`}
                    />
                    <WithTooltip
                      placement="right"
                      content={scenario.name}
                      data-testid={String(scenario.scenarioId)}
                    >
                      <span className="Scenario_Label">{scenario.name}</span>
                    </WithTooltip>
                  </div>
                </Col>
                <Col md={5}>
                  <Select
                    id="companyAccessSelect"
                    name="companyAccessSelect"
                    validate={(e) =>
                      isCompanySelectionAllowed(e) &&
                      'Please select company access'
                    }
                    value={scenario.scenarioRole}
                    onChange={(e) =>
                      changeScenarioName(e.target.value, scenario.scenarioId)
                    }
                    disabled={isAdminSelected}
                  >
                    <option value="">No Access</option>
                    {Object.keys(ScenarioRoles).map((key) => (
                      <option key={key} value={key}>
                        {ScenarioRolesText[key]}
                      </option>
                    ))}
                  </Select>
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
      {showRevokeModal && (
        <ModalConfirmation
          id="modal-revoke-user"
          title="Revoke Access"
          actionBtnTxt="Revoke Access"
          onCancel={() => setShowRevokeModal(false)}
          onAction={saveMember}
        >
          <p className="RevokeAccess_Email">{memberData.email}</p>
          <p>
            This action will remove the user’s access to the scenarios set as
            “No Access”. Are you sure you want to proceed?
          </p>
        </ModalConfirmation>
      )}
    </ModalBase>
  );
};

export default AddMemberModal;

AddMemberModal.propTypes = {
  /** An array of Scenarios */
  scenarios: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      companyId: PropTypes.number.isRequired,
      isDefault: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  /** A function to call an API from service layer */
  addMember: PropTypes.func.isRequired,
  /** A string to display error if company already shared */
  error: PropTypes.string,
  /** A loader for button component */
  loading: PropTypes.bool,
  /** A function to reset error */
  resetErrorMessage: PropTypes.func,
  /** A function to show success banner on email sent */
  showInviteEmail: PropTypes.func,
  /** An object which contains information of user to be edited */
  memberData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    companyRole: PropTypes.string.isRequired,
    scenarios: PropTypes.arrayOf(
      PropTypes.shape({
        scenarioId: PropTypes.number.isRequired,
      }),
    ),
  }),
};
