import { LABEL_STYLES } from '@/components/Charts/chartDefaults';

export const AXIS_STYLES = {
  ...LABEL_STYLES,
  fontSize: '10px',
};

const LINE_OPTIONS = {
  marker: {
    lineColor: undefined,
    radius: 0.5,
    states: {
      hover: {
        radius: 4,
      },
    },
  },
  states: {
    hover: {
      halo: {
        size: 0,
      },
    },
  },
};

export const PLOT_OPTIONS = {
  area: LINE_OPTIONS,
  line: LINE_OPTIONS,
  series: {
    custom: {
      // Used by DateChart to insert breaks in the data to prevent
      // overcrowding points on the dashboard
      isGlance: true,
    },
    dataLabels: {
      style: {
        fontSize: 12,
        fontWeight: 500,
      },
    },
  },
};

export const TOOLTIP_OPTIONS = {
  className: 'ChartTooltip ChartTooltip-dark',
  distance: 14,
};
