import { Children, useEffect, useRef, useState } from 'react';
import { HighchartsChart } from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import ChartLegend from '@/components/Charts/ChartLegend';
import ChartRefContainer from '@/components/Charts/ChartRefContainer';
import {
  DEFAULT_FUNNEL_NECK_WIDTH,
  DEFAULT_FUNNEL_WIDTH,
  DEFAULT_PIE_SIZE,
} from '@/components/Charts/chartDefaults';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';
import LetterIcon from '@/components/common/LetterIcon';
import Link from '@/components/common/Link';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { ERROR_BOUNDARY_TEXT } from '@/constants/charts';
import { classNames } from '@/helpers';
import useElementSize from '@/hooks/useElementSize';
import useIsUnmounted from '@/hooks/useIsUnmounted';
import './PartToWholeChart.scss';

const PLOT_OPTIONS_DEFAULT = {};
const SCENARIO_LABEL_HEIGHT = 34;
const LEGEND_ITEM_HEIGHT = 22.8;

/**
 * Chart wrapper component for series that represent parts of a whole, such as
 * pie or funnel
 *
 * @example
 *   <PartToWholeChart data-testid="foo">
 *     <PieSeries data={data} />
 *   </PartToWholeChart>;
 */
function PartToWholeChart({
  activeSeries,
  children,
  className = '',
  'data-testid': dataTestId,
  loading,
  onChartCreated,
  onResize,
  plotOptions = PLOT_OPTIONS_DEFAULT,
  showScenario,
  url,
  valueFormatter,
  ...props
}) {
  const [chartApi, setChartApi] = useState();
  const [showLegend, setShowLegend] = useState(false);
  const [isPortrait, setPortrait] = useState(false);
  const [maxLegendItems, setMaxLegendItems] = useState();
  const container = useRef(null);
  const isUnmounted = useIsUnmounted();

  const { contentRect } = useElementSize(container);
  const isTrend = contentRect?.height < 65;

  useEffect(() => {
    if (!contentRect) return;

    const { height, width } = contentRect;
    const aspectRatio = width / height;
    const shouldShowLegend = width < 550 || (width < 800 && aspectRatio > 2.5);
    setShowLegend(shouldShowLegend);
    const shouldBePortrait = shouldShowLegend && width < 315;
    setPortrait(shouldBePortrait);

    if (chartApi?.series.length) {
      chartApi.series.forEach((series) => {
        series.update(
          {
            dataLabels: { enabled: !shouldShowLegend },
            neckWidth: shouldShowLegend ? '37%' : DEFAULT_FUNNEL_NECK_WIDTH,
            size: shouldShowLegend ? '100%' : DEFAULT_PIE_SIZE,
            width: shouldShowLegend ? '100%' : DEFAULT_FUNNEL_WIDTH,
          },
          false,
        );
      });

      // Make sure the container has finished rerendering before we resize the
      // chart
      requestAnimationFrame(() => {
        if (isUnmounted.current) return;
        chartApi.setSize(null, null, false);
        onResize?.(chartApi, contentRect);
      });
    }
  }, [chartApi, contentRect, isUnmounted, onResize]);

  const infoContainer = useRef(null);
  const { contentRect: infoRect } = useElementSize(infoContainer);
  useEffect(() => {
    if (!infoRect) return;
    const availHeight = showScenario
      ? infoRect.height - SCENARIO_LABEL_HEIGHT
      : infoRect.height;
    setMaxLegendItems(Math.floor(availHeight / LEGEND_ITEM_HEIGHT) - 1);
  }, [infoRect, showScenario]);

  const childArray = Children.toArray(children).filter(({ type }) =>
    type.name?.includes('Series'),
  );

  let showMoreLink = false;
  if (url && chartApi?.series?.length) {
    const { options, points } = activeSeries ?? chartApi.series[0];
    showMoreLink = points.length > maxLegendItems && options.custom?.isGlance;
  }

  return (
    <figure
      className={classNames(
        'PartToWholeChart',
        showLegend && 'PartToWholeChart-withLegend',
        isPortrait && 'PartToWholeChart-portrait',
        className,
      )}
      ref={container}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CommonErrorBoundary text={ERROR_BOUNDARY_TEXT}>
          <HighchartsChart
            containerProps={{ 'data-testid': dataTestId, ...props }}
            plotOptions={plotOptions}
          >
            <ChartRefContainer
              onChartCreated={(chart) => {
                setChartApi(chart);
                onChartCreated?.(chart);
              }}
            />
            {children}
          </HighchartsChart>
          <div className="PartToWholeChart_Info" ref={infoContainer}>
            {showScenario &&
              childArray
                .filter(({ props: { name } }, idx) => {
                  return (
                    !showLegend ||
                    (activeSeries ? name === activeSeries.name : !idx)
                  );
                })
                .map(({ props: { data } }) => {
                  const [{ scenario }] = data;
                  return (
                    <figcaption
                      key={scenario.name}
                      className="PartToWholeChart_Scenario"
                    >
                      <LetterIcon
                        color={scenario.color}
                        string={scenario.name}
                        data-testid={`${dataTestId}-scenarioIcon`}
                      />
                      {scenario.name}
                    </figcaption>
                  );
                })}
            {showLegend && !isTrend && (
              <>
                <ChartLegend
                  activeSeries={activeSeries}
                  chart={chartApi}
                  data-testid={`${dataTestId}-legend`}
                  maxItems={maxLegendItems}
                  valueFormatter={valueFormatter}
                />
                {showMoreLink && (
                  <Link to={url} className="PartToWholeChart_MoreLink">
                    Show more
                  </Link>
                )}
              </>
            )}
          </div>
        </CommonErrorBoundary>
      )}
    </figure>
  );
}

PartToWholeChart.propTypes = {
  /**
   * Series for which to show the legend and other supplementary info. Typically
   * set via a click or hover event.
   */
  'activeSeries': PropTypes.instanceOf(Highcharts.Series),
  /**
   * Series components to populate the chart
   *
   * @see https://github.com/whawker/react-jsx-highcharts/wiki/Series
   */
  'children': PropTypes.node,
  /** Additional class(es) to apply to the chart container */
  'className': PropTypes.string,
  /** ID for selecting the chart container in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Whether or not a loading indicator should be displayed */
  'loading': PropTypes.bool,
  /**
   * Callback for accessing the chart object from the parent, to pass to
   * dependent components such as the legend or export button
   *
   * @param {Highcharts.Chart} chart
   */
  'onChartCreated': PropTypes.func,
  /**
   * Event handler for when the chart is resized
   *
   * @param {Highcharts.Chart} chart
   * @param {DOMRectReadOnly} contentRect Size of the chart container
   */
  'onResize': PropTypes.func,
  /** Highcharts plot configuration */
  'plotOptions': PropTypes.objectOf(PropTypes.any),
  /** Whether to show a label for the scenario name */
  'showScenario': PropTypes.bool,
  /** Relative URL for the expanded view of the chart */
  'url': PropTypes.string,
  /** Formats the values for the slice labels */
  'valueFormatter': PropTypes.func,
};

export default PartToWholeChart;
