// @ts-check
import { useReducer } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import RightArrowIcon from '@bill/cashflow.assets/right-arrow';
import {
  JOIN_ADDITIONAL_INFO_PATH,
  JOIN_INTEGRATIONS_PATH,
} from '@/constants/pages';
import AdditionalInformation from '@/pages/Registration/AdditionalInformation';
import Integrations from '@/pages/Registration/Integrations';
import Login from '@/pages/Registration/Login';
import Password from '@/pages/Registration/Password';
import RedirectToExternal from '@/pages/Registration/RedirectToExternal';
import SourceRoutes from '@/pages/Registration/SourceRoutes';
import { actions, steps, XERO } from '@/pages/Registration/constants';
import { ReactComponent as FinmarkLogo } from '@/assets/images/bill_finmark_logo.svg';
import { ReactComponent as FinmarkRoundLogo } from '@/assets/images/finmark-logo-round.svg';
import { ReactComponent as XeroLogo } from '@/assets/images/xero.svg';
import './Registration.scss';

/** @type {(params: { source: string }) => React.ReactElement} */
const RegistrationLogos = ({ source }) => {
  if (source === XERO) {
    return (
      <div className="RegistrationIntegration_LogosContainer">
        <XeroLogo
          className="RegistrationIntegration_Logo"
          data-testid="xero-logo"
        />
        <RightArrowIcon className="RegistrationArrow" />
        <FinmarkRoundLogo className="RegistrationIntegration_Logo RegistrationIntegration_Logo-finmark" />
      </div>
    );
  }
  return (
    <div className="RegistrationIntegration_LogosContainer RegistrationIntegration_LogosContainer-single">
      <FinmarkRoundLogo className="RegistrationIntegration_Logo RegistrationIntegration_Logo-finmark" />
    </div>
  );
};

/**
 * @typedef {{
 *   email: string;
 *   password: string;
 *   confirmPassword: string;
 *   confirmAcceptTerms: boolean;
 *   step: keyof import('@/pages/Registration/constants').RegistrationStepsType;
 *   loginPassword: string;
 *   source: string;
 *   firstName: string;
 *   lastName: string;
 *   companyName: string;
 *   currency: string;
 *   country: string;
 * }} RegistrationState
 */
const INITIAL_STATE = /** @type {RegistrationState} */ ({
  email: '',
  password: '',
  confirmPassword: '',
  confirmAcceptTerms: false,
  step: steps.EMAIL,
  loginPassword: '',
  source: '',
  firstName: '',
  lastName: '',
  companyName: '',
  currency: 'USD',
  country: '',
});

/**
 * @typedef {import('@/pages/Registration/constants').ActionTypes} ActionTypes
 *
 * @typedef {{
 *       type:
 *         | ActionTypes['SET_EMAIL']
 *         | ActionTypes['SET_PASSWORD']
 *         | ActionTypes['SET_PASSWORD_CONFIRM']
 *         | ActionTypes['SET_PASSWORD_LOGIN']
 *         | ActionTypes['SET_COUNTRY'];
 *       payload: string;
 *     }
 *   | {
 *       type: ActionTypes['SET_STEP'];
 *       payload: keyof import('@/pages/Registration/constants').RegistrationStepsType;
 *     }
 *   | { type: ActionTypes['SET_TOS']; payload: boolean }
 *   | { type: ActionTypes['SET_USER_DATA']; payload: RegistrationState }} RegistrationDispatchType
 * @type {(
 *   state: RegistrationState,
 *   action: RegistrationDispatchType,
 * ) => RegistrationState}
 */
const reducer = (state, { type, payload }) => {
  switch (type) {
    case actions.SET_EMAIL:
      return { ...state, email: payload };
    case actions.SET_PASSWORD:
      return { ...state, password: payload };
    case actions.SET_PASSWORD_CONFIRM:
      return { ...state, confirmPassword: payload };
    case actions.SET_TOS:
      return { ...state, confirmAcceptTerms: payload };
    case actions.SET_STEP:
      return { ...state, step: payload };
    case actions.SET_PASSWORD_LOGIN:
      return { ...state, loginPassword: payload };
    case actions.SET_COUNTRY:
      return { ...state, country: payload };
    case actions.SET_USER_DATA:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

/** @type {(props: { authToken: string | null }) => React.ReactElement} */
const Registration = ({ authToken }) => {
  const [state, handleChange] = useReducer(reducer, INITIAL_STATE);
  const { email, loginPassword, source } = state;

  return (
    <div id="registration" className="Registration">
      <div className="RegistrationContainer">
        <div className="RegistrationHeader">
          <FinmarkLogo className="RegistrationHeader_Logo" />
        </div>
        <div className="RegistrationContent_Container">
          <div className="RegistrationContent">
            <RegistrationLogos source={source} />
            <Switch>
              <Route exact path="/join/login">
                <Login
                  onChange={handleChange}
                  email={email}
                  loginPassword={loginPassword}
                />
              </Route>
              <Route exact path="/join/password">
                <Password onChange={handleChange} {...state} />
              </Route>
              <Route exact path={JOIN_ADDITIONAL_INFO_PATH}>
                <AdditionalInformation authToken={authToken} />
              </Route>
              <Route exact path={JOIN_INTEGRATIONS_PATH}>
                <Integrations authToken={authToken} />
              </Route>
              <Route exact path="/join/:source">
                <SourceRoutes onChange={handleChange} />
              </Route>
              <Route exact path="/join">
                <RedirectToExternal url="http://finmark.com/get-started" />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  authToken: auth.token,
});
export default connect(mapStateToProps)(Registration);
