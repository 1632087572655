import { useDropzone } from 'react-dropzone';
import UploadIcon from '@bill/cashflow.assets/upload';
import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import './Dropzone.scss';

/**
 * A component to handle dragging or browsing for a file to upload
 *
 * @example
 *   <Dropzone id="dropzone" config={{
 *     accept: '.csv',
 *     maxFiles: 1,
 *     maxSize: ONE_MB,
 *     onDrop: console.log,
 *     onDropRejected: console.log
 *   }}>{Drop a file}</Dropzone>
 */
const Dropzone = ({
  id,
  className,
  children,
  config,
  'data-testid': dataTestId,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone(config);
  const content = children;
  return (
    <div
      {...getRootProps()}
      id={id}
      data-testid={dataTestId ?? id}
      className={classNames('Dropzone_Container', className)}
    >
      <input {...getInputProps()} />
      {isDragActive ? <UploadIcon className="Dropzone_Icon" /> : content}
    </div>
  );
};

Dropzone.propTypes = {
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string,
  /** Unique ID for the element */
  'id': PropTypes.string.isRequired,
  /** Class(es) to apply to the element */
  'className': PropTypes.string,
  /** Content to display inside the Dropzone */
  'children': PropTypes.node,
  /** A configuration object to pass to the useDropzone hook */
  'config': PropTypes.objectOf(PropTypes.any),
  /** Is Drop Zone for bulk edit or not */
  'editMultiple': PropTypes.bool,
};

export default Dropzone;
