import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  handleStreamDataChangeAction,
  updatePlanAction,
} from '@/actions/revenue';
import {
  isAccumulatedPlanValuesValid,
  hasEndDate,
} from '@/components/Revenue/helpers';
import CurrencyField from '@/components/common/CurrencyField';
import InfoTooltip from '@/components/common/InfoTooltip';
import MonthSpreadFields from '@/components/common/MonthSpreadFields';
import NumberField from '@/components/common/NumberField';
import Select from '@/components/common/Select';
import { SUBSCRIPTION } from '@/constants/revenueStream';
import formatDecimalNumber from '@/helpers/formatDecimalNumber';
import { isEmptyOrNull, isEndDateBeforeStartDate } from '@/helpers/validators';
import { getCurrentEmployeeSalesRoles } from '@/services/employee.service';
import DriverDateRange from './components/DriverDateRange';
import DriverPricingPlanList from './components/DriverPricingPlanList';

function SalesLedConversion({
  handleStreamDataChange,
  onValid,
  revenue,
  revenueStream,
  scenarioId,
  updatePlan,
  editRevenueStream,
}) {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (!editRevenueStream) {
      handleStreamDataChange(
        'plansAssociated',
        revenueStream.plansAssociated.map((plan) => {
          return { ...plan, estimatedValue: 0 };
        }),
      );

      updatePlan('estimatedValue', 100, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [updatePlan, editRevenueStream]);

  useEffect(() => {
    async function setEmployeeRoles() {
      const {
        data: { data },
      } = await getCurrentEmployeeSalesRoles(scenarioId);
      setRoles(data);

      if (!revenueStream.id) {
        const [mostPopulousRole] = data;
        handleStreamDataChange('employeeTitleId', mostPopulousRole?.id);
      }
    }
    setEmployeeRoles();
  }, [handleStreamDataChange, revenueStream.id, scenarioId, setRoles]);

  useEffect(() => {
    const isAttainmentRampValid = revenueStream.attainmentDuringRamp.every(
      (value) => Number.isFinite(value),
    );

    const isValid =
      revenueStream.startDate &&
      hasEndDate(revenueStream.noEndDate, revenueStream.endDate) &&
      !isEndDateBeforeStartDate(
        revenueStream.startDate,
        revenueStream.endDate,
      ) &&
      !isEmptyOrNull(revenueStream.annualQuota) &&
      !isEmptyOrNull(revenueStream.quotaAttainment) &&
      !isEmptyOrNull(revenueStream.employeeTitleId) &&
      isAttainmentRampValid &&
      isAccumulatedPlanValuesValid(revenueStream);
    onValid(isValid);
  }, [onValid, revenueStream]);

  const handleNumberFieldChange = ({ target }, number) =>
    handleStreamDataChange(target.name, number);

  const pricingPlanListHeading =
    revenueStream.revenueType === SUBSCRIPTION
      ? 'How would you like to distribute your new subscribers across pricing plans?'
      : 'How would you like to distribute your new purchases across pricing plans?';

  return (
    <div className="Form">
      <div className="Form_Group">
        <DriverDateRange
          revenue={revenue}
          revenueStream={revenueStream}
          handleStreamDataChange={handleStreamDataChange}
        />
      </div>
      <div className="Form_Group Form_Group-halfWidth">
        <label className="Label" htmlFor="employeeTitleId">
          Assign to Role
        </label>
        <Select
          id="employeeTitleId"
          name="employeeTitleId"
          value={revenueStream.employeeTitleId}
          onChange={({ target }) =>
            handleStreamDataChange(target.name, Number(target.value))
          }
        >
          {roles.map(({ id, jobTitle }) => (
            <option key={id} value={id}>
              {jobTitle}
            </option>
          ))}
        </Select>
      </div>
      <div className="Form_Group Form_Group-halfWidth">
        <label className="Label" htmlFor="annualQuota">
          Annual Quota
        </label>
        <CurrencyField
          id="annualQuota"
          name="annualQuota"
          value={revenueStream.annualQuota}
          allowNegativeValues={false}
          precision={0}
          onChange={handleNumberFieldChange}
          validate={(value) =>
            value <= 0 && 'Please enter a number greater than 0.'
          }
        />
      </div>
      <div className="Form_Group Form_Group-halfWidth">
        <label className="Label" htmlFor="salesRepRampTime">
          Sales Rep Ramp Time
        </label>
        <Select
          id="salesRepRampTime"
          value={revenueStream.salesRepRampTime}
          onChange={({ target }) => {
            const months = Number(target.value);
            const attainmentDuringRamp = Array(months)
              .fill()
              .map((_, idx) =>
                Math.ceil(formatDecimalNumber((100 / months) * (idx + 1), 1)),
              );
            handleStreamDataChange('salesRepRampTime', months);
            handleStreamDataChange(
              'attainmentDuringRamp',
              attainmentDuringRamp,
            );
          }}
        >
          <option value={1}>Same Month</option>
          {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
            <option key={month} value={month}>{`+${month - 1} Months`}</option>
          ))}
        </Select>
      </div>
      <div className="Form_Group Form_Group-halfWidth">
        <label className="Label" htmlFor="quotaAttainment">
          Quota Attainment
          <InfoTooltip placement="right" data-testid="quotaAttainment-tooltip">
            The expected % of quota attainment for each employee in the assigned
            role
          </InfoTooltip>
        </label>
        <NumberField
          id="quotaAttainment"
          name="quotaAttainment"
          prefix="%"
          value={revenueStream.quotaAttainment}
          allowNegativeValues={false}
          precision={1}
          onChange={handleNumberFieldChange}
          validate={(value) =>
            value > 999 && 'Please enter a percentage between 0 and 999.'
          }
        />
      </div>
      <div className="Form_Group">
        <label className="Label" htmlFor="attainmentDuringRamp">
          % Attainment During Ramp
          <InfoTooltip data-testid="attainmentDuringRamp-tooltip">
            Estimate how quickly new hires in this role will be fully
            productive.
          </InfoTooltip>
        </label>
        <MonthSpreadFields
          id="attainmentDuringRamp"
          className="AddRevenueStream_PctOverTime"
          values={revenueStream.attainmentDuringRamp}
          onChange={(attainmentDuringRampNew) => {
            handleStreamDataChange(
              'attainmentDuringRamp',
              attainmentDuringRampNew,
            );
          }}
        />
      </div>
      <DriverPricingPlanList
        heading={pricingPlanListHeading}
        plansAssociated={revenueStream.plansAssociated}
        revenue={revenue}
        showPricingPlanInPercentage
        updatePlan={updatePlan}
        tooltip={
          <InfoTooltip data-testid="pricingPlanPercentageTooltip">
            If the product price is 0 for any month, there will be no new
            customers that month.
          </InfoTooltip>
        }
      />
    </div>
  );
}

function mapStateToProps({ scenario, revenues }) {
  return {
    scenarioId: scenario.scenarioId,
    editRevenueStream: revenues.revenueStreamRecordToBeEdit,
  };
}

export default connect(mapStateToProps, {
  handleStreamDataChange: handleStreamDataChangeAction,
  updatePlan: updatePlanAction,
})(SalesLedConversion);
