// @ts-check
import { useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { UNSET_STATEFUL_ROUTE } from '@/actionTypes/auth';
import { subscribeToUserPermissionChangeAction } from '@/actions/auth';
import { getScenarioAction } from '@/actions/scenario';
import {
  getCompanySettingAction,
  getIntegrationsStatusAction,
} from '@/actions/settings';
import { getExpiredStatusAction } from '@/actions/subscriptions';
import {
  DASHBOARD_PATH,
  JOIN_ADDITIONAL_INFO_PATH,
  LOGIN_PATH,
  MANAGEMENT_CONSOLE_PATH,
  NO_ACCESS_PATH,
  SETUP_PATH,
  SWITCH_COMPANIES_PATH,
  UNAUTHORIZED_PATH,
} from '@/constants/pages';
import useBillAuthentication from '@/containers/BillOnboarding/useBillAuthentication';
import useOnboardStatus from '@/containers/BillOnboarding/useOnboardStatus';
import { stripSessionIdUrlParam } from '@/helpers';
import isBillDomain from '@/helpers/isBillDomain';
import { isEmptyOrNull } from '@/helpers/validators';
import useBillBaseUrl from '@/hooks/useBillBaseUrl';
import useBillSession from '@/hooks/useBillSession';
import useFeatureFlagsService from '@/hooks/useFeatureFlagsService';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import useAuthorizedCompanies from './useAuthorizedCompanies';
import useAuthorizedScenarios from './useAuthorizedScenarios';
import useCompanyStatusQuery from './useCompanyStatusQuery';
import useManagementConsole from './useManagementConsole';
import useTypedSelector from './useTypedSelector';
import useWsSubscription from './useWsSubscription';

export const companyRegistrationStatus = /** @type {const} */ ({
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING',
});

/** @typedef {{ isLoading: boolean; pathname: string; url?: string }} AuthRedirect */

/** @typedef {{ condition?: boolean }} AuthRedirectParams */

/** @type {AuthRedirect} */
const authRedirectResult = { isLoading: false, pathname: '' };

/**
 * A hook that returns the redirection path based on user auth state.
 *
 * @type {(options: AuthRedirectParams) => AuthRedirect}
 */
const useAuthRedirect = ({ condition } = { condition: undefined }) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const token = useTypedSelector(({ auth }) => auth.token);

  const selectedCompanyId = useTypedSelector(
    ({ companies: userCompanies }) => userCompanies.selectedCompanyId,
  );
  const scenarioId = useTypedSelector(({ scenario }) => scenario.scenarioId);
  const statefulRoute = useTypedSelector(({ auth }) => auth.statefulRoute);
  const isRefreshingToken = useTypedSelector(
    ({ auth }) => auth.isRefreshingToken,
  );
  const userId = useTypedSelector(({ auth }) => auth.userInfo.userId);
  const selectedCompany = useTypedSelector(getSelectedCompany);
  const companies = useTypedSelector(
    ({ companies: userCompanies }) => userCompanies.companies,
  );
  const isAuthenticating = useTypedSelector(
    ({ auth }) => auth.isAuthenticating,
  );
  const isLoggingOut = useTypedSelector(({ auth }) => auth.isLoggingout);

  const authorizedCompanies = useAuthorizedCompanies();
  const authorizedScenarios = useAuthorizedScenarios();

  const isConsoleEnabled = useManagementConsole();

  const location = useLocation();

  const authenticate = useBillAuthentication();
  const { isSessionLoading, sessionInfo } = useBillSession();
  const { flags, isFeatureFlagLoading } = useFeatureFlagsService();
  const billBaseUrl = useBillBaseUrl();

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  const {
    data: companyOnboardingStatus,
    isFetching: isOnboardingStatusFetching,
  } = useOnboardStatus();

  const isBillLoadingState = useMemo(
    () =>
      isBillDomain() &&
      (isAuthenticating ||
        isLoggingOut ||
        isOnboardingStatusFetching ||
        isSessionLoading ||
        isFeatureFlagLoading),
    [
      isAuthenticating,
      isLoggingOut,
      isOnboardingStatusFetching,
      isSessionLoading,
      isFeatureFlagLoading,
    ],
  );
  const isCompanyOnboarded = useMemo(
    () =>
      !isBillDomain() ||
      (token &&
        companyOnboardingStatus?.onboardingStatus === 'completed' &&
        !isEmptyOrNull(selectedCompanyId)),
    [token, companyOnboardingStatus, selectedCompanyId],
  );

  const gotoSwitchCompany =
    !selectedCompanyId &&
    ![SWITCH_COMPANIES_PATH, MANAGEMENT_CONSOLE_PATH].includes(
      location.pathname,
    );

  useEffect(() => {
    if (token && scenarioId && selectedCompanyId) {
      dispatch(getIntegrationsStatusAction());
      dispatch(getCompanySettingAction());
    }
  }, [dispatch, token, selectedCompanyId, scenarioId]);

  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(getScenarioAction());
      dispatch(getExpiredStatusAction());
    }
    stripSessionIdUrlParam();
  }, [dispatch, selectedCompanyId, scenarioId]);

  useWsSubscription(() => {
    if (isBillLoadingState || !userId) return null;
    return dispatch(subscribeToUserPermissionChangeAction(userId));
  }, [userId]);

  const hasNoneAuthorized =
    authorizedScenarios.length === 0 || authorizedCompanies.length === 0;

  const isAccessingUnauthorized =
    selectedCompanyId &&
    /**
     * Checking for the selected company's default scenario ID. If the user does
     * not have access to any scenarios in the selected company, the scenarioId
     * and the selected company's defaultScenarioId both would be null.
     */
    (scenarioId || (selectedCompany && !selectedCompany.defaultScenarioId)) &&
    (!authorizedCompanies.includes(selectedCompanyId) ||
      !authorizedScenarios.includes(scenarioId));

  const { data: companyStatus, isLoading: companyStatusLoading } =
    useCompanyStatusQuery();

  if (isBillLoadingState) {
    return { ...authRedirectResult, isLoading: true };
  }

  /**
   * Check if fpa-enabled feature flag is turned on, redirect AP otherwise (This
   * will work as kill switch) OR Check if planning is available for user,
   * redirect to AP otherwise
   */
  if (
    isBillDomain() &&
    (!Reflect.get(flags, 'fpa-enabled') ||
      !sessionInfo?.neoFunctionality.OrgFeatures.isPlanningAccessibleToUser)
  ) {
    return { ...authRedirectResult, url: billBaseUrl };
  }

  if (!isCompanyOnboarded) {
    return { ...authRedirectResult, pathname: SETUP_PATH };
  }

  if (!token) {
    return {
      ...authRedirectResult,
      pathname: isBillDomain() ? SETUP_PATH : LOGIN_PATH,
    };
  }

  if (
    !companyStatusLoading &&
    companyStatus?.isInformationMissing &&
    selectedCompany?.registrationStatus === companyRegistrationStatus.PENDING &&
    companies?.length === 1
  ) {
    return { ...authRedirectResult, pathname: JOIN_ADDITIONAL_INFO_PATH };
  }

  if (gotoSwitchCompany) {
    return {
      ...authRedirectResult,
      pathname: isConsoleEnabled
        ? MANAGEMENT_CONSOLE_PATH
        : SWITCH_COMPANIES_PATH,
    };
  }

  if (!isRefreshingToken && (hasNoneAuthorized || isAccessingUnauthorized)) {
    return {
      ...authRedirectResult,
      pathname: isBillDomain() ? NO_ACCESS_PATH : UNAUTHORIZED_PATH,
    };
  }

  if (condition === false) {
    return { ...authRedirectResult, pathname: DASHBOARD_PATH };
  }

  if (statefulRoute) {
    const { pathname } = /** @type {import('history').Location} */ (
      statefulRoute
    );
    // When the user's token expires, this code may execute before they are
    // redirected to logout. Since statefulRoute and the current location are
    // the same, this kicks off a redirect loop.
    if (pathname !== location.pathname) {
      dispatch({ type: UNSET_STATEFUL_ROUTE });
      return { ...authRedirectResult, pathname };
    }
  }

  return authRedirectResult;
};

export default useAuthRedirect;
