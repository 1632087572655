import { forwardRef, useCallback, useEffect, useState } from 'react';
import CalendarMonthSelectedIcon from '@bill/cashflow.assets/calendar-month-selected';
import PropTypes from 'prop-types';
import Calendar from '@/components/common/Calendar/Calendar';
import WithPopover from '@/components/common/WithPopover';
import { childrenOf } from '@/helpers';
import { getUTCDayTimestamp } from '@/helpers/dateFormatter';
import MaskedFormField from './MaskedFormField';

function formatTimestamp(timestamp) {
  return timestamp
    ? new Date(timestamp).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        timeZone: 'UTC',
      })
    : null;
}

const MASK_OPTIONS = {
  alias: 'datetime',
  inputFormat: 'mm/dd/yyyy',
};

const prefix = <CalendarMonthSelectedIcon className="FormField_PrefixIcon" />;

/**
 * @typedef {Override<
 *   import('@/components/common/FormField').FormFieldProps,
 *   {
 *     onChange: (selectedDate: number | null) => void;
 *     appendTo?: HTMLElement;
 *     value: number | null;
 *     max?: number;
 *     min?: number;
 *     validate?: (dateStr: string) => string | null;
 *   }
 * >} DateFieldProps
 */

/**
 * Renders an input field for accepting dates in the format MM/DD/YYYY, with a
 * popover calendar for easy selection.
 *
 * @example
 *   <DateField
 *     id="foo"
 *     value={date}
 *     onChange={(newDate) => setDate(newDate)}
 *   />;
 *
 * @type {import('react').ForwardRefRenderFunction<
 *   HTMLInputElement,
 *   DateFieldProps
 * >}
 */
const DateFieldFn = (
  {
    appendTo,
    'data-testid': dataTestId,
    value,
    max,
    min,
    id,
    onChange,
    className,
    validate,
    ...props
  },
  ref,
) => {
  const [show, setShow] = useState(false);
  const [calendarValue, setCalendarValue] = useState(value);

  useEffect(() => {
    setCalendarValue(value);
  }, [value]);

  const selectDate = (newDate) => {
    setShow(false);
    // Prevents onChange logic from delaying closing of the calendar
    requestAnimationFrame(() => onChange(newDate));
  };

  const handleChange = useCallback(
    (newValue) => onChange(newValue ? getUTCDayTimestamp(newValue) : null),
    [onChange],
  );

  const handleFocus = useCallback(() => setShow(true), []);

  const handleKeyDown = useCallback(
    ({ code }) => code === 'Tab' && setShow(false),
    [],
  );

  const handleValidate = useCallback(
    (dateStr) => {
      if (dateStr) {
        const newDateMs = getUTCDayTimestamp(dateStr);
        if (Number.isNaN(newDateMs)) {
          return 'Please enter a valid date';
        }
        if (min && newDateMs < min) {
          return `Date must be on or after ${formatTimestamp(min)}`;
        }
        if (max && newDateMs > max) {
          return `Date must be on or before ${formatTimestamp(max)}`;
        }
      }
      return validate?.(dateStr);
    },
    [max, min, validate],
  );

  const inputValue =
    value?.toString().length > 1 ? formatTimestamp(value) : value?.toString();

  const testId = dataTestId ?? id;

  return (
    <div className={className}>
      <WithPopover
        className="Popover-toEdge"
        content={
          <Calendar
            value={calendarValue}
            onChange={selectDate}
            min={min}
            max={max}
            data-testid={`${testId}-calendar`}
          />
        }
        onClose={() => setShow(false)}
        placement="bottom"
        visible={show}
        data-testid={`${testId}-popover`}
      >
        <div>
          <MaskedFormField
            ref={ref}
            data-testid={dataTestId}
            prefix={prefix}
            id={id}
            value={inputValue}
            maskOptions={MASK_OPTIONS}
            onChange={handleChange}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            validate={handleValidate}
            {...props}
          />
        </div>
      </WithPopover>
    </div>
  );
};

const DateField = forwardRef(DateFieldFn);

DateField.propTypes = {
  /**
   * Element within which the calendar popover should be inserted. Defaults to
   * the field's parent.
   */
  'appendTo': childrenOf([HTMLElement]),
  /** Additional class(es) to apply to the wrapper element */
  'className': PropTypes.string,
  'data-testid': PropTypes.string,
  /**
   * A unique ID to associate the field with its label, and to select it in
   * unit/integration tests
   */
  'id': PropTypes.string.isRequired,
  /**
   * The furthest date which should be selectable in the calendar, as a
   * timestamp
   */
  'max': PropTypes.number,
  /**
   * The earliest date which should be selectable in the calendar, as a
   * timestamp
   */
  'min': PropTypes.number,
  /**
   * Event handler called when the user selects a date from the calendar or
   * types a complete date the input field
   *
   * @param {number} selectedDate
   */
  'onChange': PropTypes.func.isRequired,
  /**
   * Additional validation to perform on the input
   *
   * @param {string} dateStr
   * @returns {string} An error message, or undefined
   */
  'validate': PropTypes.func,
  /** The current value of the field, as a timestamp */
  'value': PropTypes.number,
};

export default DateField;
