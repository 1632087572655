// @ts-check
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { SET_ERROR } from '@/actionTypes/actuals';
import { ACTUALS } from '@/cacheKeys';
import { EMPTY_CELL_VALUE } from '@/components/common/Spreadsheet/constants';
import { getUnitFormatter } from '@/components/common/Spreadsheet/helpers';
import { iconTypes, actualsFamily } from '@/constants/actuals';
import {
  BEGINNING_MRR,
  CHURNED_CUSTOMERS,
  CHURNED_MRR,
  ENDING_MRR,
  NEW_CUSTOMERS,
  NEW_MRR,
  ONE_TIME_PURCHASES,
  ONE_TIME_REVENUE,
  OTHER_REVENUE,
  BEGINNING_CUSTOMER,
  ENDING_CUSTOMER,
  EXPANSION_CUSTOMER,
  CONTRACTION_CUSTOMER,
  EXPANSION_MRR,
  CONTRACTION_MRR,
  ADJUSTMENT_CUSTOMER,
  ADJUSTMENT_MRR,
} from '@/constants/metricTitles';
import { NO_COMPARISON } from '@/constants/scenario';
import { units } from '@/constants/variables';
import { isNumber } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';
import transformDataForGrid from '@/reducers/helpers/transformDataForGrid';
import { getCombinedActuals as getActuals } from '@/services/actuals.service';
import { store } from '@/store';

const REVENUES = {
  BEGINNING_MRR,
  NEW_CUSTOMER: NEW_CUSTOMERS,
  NEW_MRR,
  CHURNED_CUSTOMER: CHURNED_CUSTOMERS,
  CHURNED_MRR,
  ENDING_MRR,
  ONE_TIME_PURCHASE: ONE_TIME_PURCHASES,
  ONE_TIME_REVENUE,
  OTHER_REVENUE,
  BEGINNING_CUSTOMER,
  ENDING_CUSTOMER,
  EXPANSION_CUSTOMER,
  CONTRACTION_CUSTOMER,
  ADJUSTMENT_CUSTOMER,
  EXPANSION_MRR,
  CONTRACTION_MRR,
  ADJUSTMENT_MRR,
};
const countRegex = /\(([^)]+)\)/;

/**
 * Select a range of cells in a grid
 *
 * @param {Function} setSelectedCells - Setter for storing the selected cells
 * @param {Function} setCellCount - Setter for storing the number of cells
 *   selected
 * @param {Function} setCellUnit - Setter for storing the unit of cells selected
 * @param {Function} setCellSum - Setter for storing the sum of cells selected
 * @param {Object} api - The AG-Grid API
 * @param {string[]} excludedColumnsIds - IDs of columns that are not part of
 *   rangeSelection when a selecting a single cell
 */
export const rangeSelection = (
  setSelectedCells,
  setCellCount,
  setCellUnit,
  setCellSum,
  api,
  excludedColumnsIds = [],
) => {
  const cellRanges = api.getCellRanges();

  let columns;
  let endRow;
  let startRow;

  if (!cellRanges?.length) {
    const focusedCell = api.getFocusedCell();
    if (!focusedCell) return;
    const { column, rowIndex } = focusedCell;
    if (excludedColumnsIds.includes(column.colId)) return;
    columns = [column];
    endRow = { rowIndex };
    startRow = { rowIndex };
  } else {
    [{ columns, endRow, startRow }] = cellRanges;
  }

  const start = Math.min(startRow.rowIndex, endRow.rowIndex);
  const end = Math.max(startRow.rowIndex, endRow.rowIndex);

  let currentCount = 0;
  let currentSum = 0;
  let unit = '';
  const cellsToSelect = columns.reduce((cells, col, colIdx) => {
    for (let rowIndex = start; rowIndex <= end; rowIndex += 1) {
      const node = api.getDisplayedRowAtIndex(rowIndex);
      const { data } = node;
      const { colDef } = col;
      currentCount += 1;
      if (colIdx === 0 && startRow.rowIndex === rowIndex) {
        unit = data.unit;
      }

      const month = col.getParent().getGroupId();
      const scenarioId = Number(colDef.field);
      const monthData = data.months.find(({ date }) => date === month);
      if (monthData) {
        const oldValue = monthData.value.find(
          (val) => val.scenarioId === scenarioId,
        );
        if (!oldValue) return cells;

        const { value } = oldValue;
        if (!isEmptyOrNull(value) && !Number.isNaN(value)) {
          currentSum += Number(value);
        }

        if (
          oldValue.type === iconTypes.USER_ENTERED ||
          oldValue.actualType === iconTypes.USER_ENTERED
        ) {
          cells.push({
            data,
            colDef,
            oldValue,
            newValue: null,
            node,
            scenarioId,
          });
        }
      }
    }
    return cells;
  }, []);
  setCellUnit(unit);
  setCellCount(currentCount);
  setCellSum(currentSum);
  setSelectedCells(cellsToSelect);
};

export const useActualsQuery = ({
  family,
  startDate,
  endDate,
  timePeriod,
  scenarioIds,
  hasComparison,
}) => {
  const compareScenarioId = hasComparison ? scenarioIds[1] : NO_COMPARISON;
  const baseScenarioId = scenarioIds[0];
  const familyKey = !family ? 'combined' : family;

  return useQuery(
    [
      ACTUALS,
      familyKey,
      startDate,
      endDate,
      timePeriod,
      baseScenarioId,
      compareScenarioId,
    ],
    async () => {
      const result = await getActuals({
        startDate,
        endDate,
        scenarioIds,
        timePeriod,
        actualFamily: family,
      });
      return transformDataForGrid(result.data.data.children);
    },
    { staleTime: 60000 },
  );
};

/**
 * @type {(
 *   mutationFn: import('@/services/actuals.service').UpdateActualsService,
 *   setUpdatedExpense: React.Dispatch<
 *     import('./ActualsGrid').ExpenseActualsUpdate[]
 *   >,
 * ) => import('@tanstack/react-query').UseMutationResult}
 */
export const useActualsMutation = (mutationFn, setUpdatedExpense) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  return useMutation({
    mutationFn,
    onSuccess: ({ data }) => {
      const { scenarioId } = store.getState().scenario;

      /** @type {import('./ActualsGrid').ExpenseActualsUpdate[]} */
      const expensePayload = data.data
        .filter((payload) => payload.family === actualsFamily.EXPENSE)
        .map((payload) => ({
          id: payload.id,
          scenarioId,
          type: payload.family,
          title: payload.title,
          values: payload.months.map((month) => {
            const value = month.value[0];
            return {
              ...value,
              month: month.date,
            };
          }),
        }));

      setUpdatedExpense(expensePayload);
    },
    onError: (/** @type {import('axios').AxiosError} */ e) => {
      dispatch({
        type: SET_ERROR,
        payload: e.response?.data?.error?.errorMessage || e.message,
      });
    },
  });
};

/**
 * If value is an object, then format and return the `value` inside it.
 *
 * @type {import('ag-grid-community').ValueFormatterFunc}
 */
export const valueFormatter = ({ data: { unit }, value }) => {
  const formatter = getUnitFormatter(unit ?? units.CURRENCY);
  const displayVal = typeof value === 'object' ? value?.value : value;
  if (!isEmptyOrNull(displayVal)) return formatter(displayVal);

  // If the value is a formula that has not yet been calculated,
  // show the formula while loading.
  const formula = value?.displayFormula;
  if (!isEmptyOrNull(formula)) {
    return isNumber(formula) ? formatter(formula) : formula;
  }
  return EMPTY_CELL_VALUE;
};

/** @type {import('ag-grid-community').ValueGetterFunc} */
export const valueGetter = ({ colDef, column, data: rowData }) => {
  if (!rowData?.months || isEmptyOrNull(rowData.months)) return null;

  const monthEntry = rowData.months.find(
    ({ date }) => date === column.getParent().getGroupId(),
  );
  if (!monthEntry) return null;

  const value = monthEntry.value.find(
    ({ scenarioId }) => scenarioId === Number(colDef.field),
  );
  return value;
};

export const CLASS_PREFIX_KEY = 'CLASS_';

/**
 * Gets the employee map key
 *
 * @type {(id: string, title: string) => string}
 */
const getEmployeeMapKey = (id, title) => {
  return id.startsWith('Class-') ? `${CLASS_PREFIX_KEY}${title}` : title;
};

/**
 * Appends the count to the row title if the value is an employee departmentName
 * or employee expenseClassName
 *
 * @type {import('ag-grid-community').ValueFormatterFunc}
 */
export const groupRowValueFormatter = (params) => {
  if (params.data.family === actualsFamily.REVENUE)
    return REVENUES[params.data.title];

  const {
    data: { id, title } = {},
    colDef: { cellRendererParams: { employeeMap = {} } = {} } = {},
  } = params;

  const key = getEmployeeMapKey(id, title);

  if (!employeeMap[key]) return title;

  let count = 0;
  try {
    const arrays = Array.isArray(employeeMap[key])
      ? [employeeMap[key]]
      : Object.values(employeeMap[key]);

    for (const arr of arrays) {
      count += arr.length;
    }
  } catch (e) {
    return title;
  }
  return `${title} (${count})`;
};

/**
 * Formats the count within the tooltip
 *
 * @type {(params: import('ag-grid-community').ITooltipParams) => string}
 */
export const tooltipCountFormatter = (params) => {
  const { value, valueFormatted, colDef } = params;

  if (value === valueFormatted || !('cellRendererParams' in colDef)) {
    return valueFormatted;
  }

  const employeeMap = colDef.cellRendererParams.employeeMap ?? {};
  if (valueFormatted && employeeMap[value]) {
    const matches = countRegex.exec(valueFormatted.replace(value, '')) ?? [];
    const count = matches[1];

    return `${value} (${count} employee${count === '1' ? '' : 's'})`;
  }

  return valueFormatted;
};
