// @ts-check
import { useEffect, useCallback } from 'react';
import { setWebSocketFailedAction } from '@/actions/shared';
import useTypedDispatch from '@/hooks/useTypedDispatch';

/** @typedef {Omit<import('@stomp/stompjs').StompSubscription, 'id'>} WsSubscription */

/**
 * Unsubscribes from a websocket subscription. If an error occurs during
 * unsubscription, it will retry after a delay.
 *
 * @type {(wsSubscription: WsSubscription) => void}
 */
function unsubscribeFromWebsocket(wsSubscription) {
  try {
    wsSubscription.unsubscribe();
  } catch (e) {
    setTimeout(() => {
      unsubscribeFromWebsocket(wsSubscription);
    }, 500);
  }
}

/**
 * Calls the given subscription action and ensures it is unsubscribed when the
 * component is unmounted
 *
 * @type {(
 *   action: () => Promise<import('@stomp/stompjs').StompSubscription>,
 *   deps: React.DependencyList,
 * ) => void}
 */
function useWsSubscription(action, dependencies) {
  const dispatch = useTypedDispatch();
  /* eslint-disable-next-line react-hooks/exhaustive-deps -- action is designed to be anonymous here */
  const memoizedAction = useCallback(action, dependencies);

  useEffect(() => {
    /** @type {WsSubscription} */
    const wsSubscription = { unsubscribe: null };
    async function callAction() {
      try {
        wsSubscription.unsubscribe = (await memoizedAction())?.unsubscribe;
        dispatch(setWebSocketFailedAction(false));
      } catch (err) {
        dispatch(setWebSocketFailedAction(true));
      }
    }
    if (memoizedAction) callAction();

    return () => {
      unsubscribeFromWebsocket(wsSubscription);
    };
  }, [dispatch, memoizedAction]);
}

export default useWsSubscription;
