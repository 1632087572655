import { SET_SUBSCRIPTION_EXPIRED } from '@/actionTypes/subscriptions';

const INITIAL_STATE = {
  isExpired: false,
};

const subscriptions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SUBSCRIPTION_EXPIRED:
      return { ...state, isExpired: action.payload };
    default:
      return state;
  }
};

export default subscriptions;
