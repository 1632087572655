import Highcharts from 'highcharts';
import { GRAPH_FORECAST_COLOR } from '@/components/Charts/constants';
import COLORS from '@/constants/colorPalette';
import { MONTHLY, timePeriodLength, timePeriods } from '@/constants/dateTime';
import { units } from '@/constants/variables';
import { formatPercent } from '@/helpers';
import convertDecimalToPercent from '@/helpers/convertDecimalToPercent';
import {
  formatDateWithShortYear,
  getCurrentMonthTimestamp,
  getDateOffsetByMonths,
  getDatesInRange,
  getTimePeriodsInRange,
} from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import metricFormatters from '@/helpers/metricFormatters';
import { isEmptyOrNull } from '@/helpers/validators';
import { getBandedGradient, white } from './colors';

const now = new Date();
const currentMonthTimestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth());
const COMPACT_NOTATION_THRESHOLD = 1000;

const compactNumberFormatter = (options = {}) => {
  return new Intl.NumberFormat([], {
    notation: 'compact',
    compactDisplay: 'short',
    ...options,
  });
};

const percentageFormatterWithPrecision = (value, options) => {
  const convertedDecimalNumber = convertDecimalToPercent(value);
  return formatPercent(convertedDecimalNumber, options);
};

const getCurrentLabelHtml = (label, isBold) =>
  `<span style="font-weight: ${isBold ? 'bold' : 'unset'};  color: ${
    isBold && COLORS.raisinBlack
  }">${label}</span>`;

/** @type {(ctx: Highcharts.AxisLabelsFormatterContextObject) => boolean} */
const showDecimalsForLabel = ({ axis, value }) => {
  if (axis.options.allowDecimals) return true;

  // Because the Y-axis labels use compact notation, we can still have
  // 'decimals' with large integers, e.g. $2.5M.
  const [secondLast, max] = axis.tickPositions.slice(-2);
  const interval = max - secondLast;
  return (
    interval < 1 ||
    (Number(value) >= COMPACT_NOTATION_THRESHOLD &&
      interval !== max / Number(String(max)[0]))
  );
};

/**
 * Get a configuration object for YAxis Highcharts component.
 * {@link https://api.highcharts.com/highcharts/yAxis}
 *
 * @param {Highcharts.YAxisOptions} [axisConfig] - A configuration object for
 *   the Yaxis
 * @returns {Highcharts.YAxisOptions}
 */
export function getYAxisConfig(axisConfig = {}) {
  return {
    allowDecimals: true,
    plotLines: [
      {
        id: 'zeroYAxis',
        color: new Highcharts.Color(COLORS.softBlack)
          .tweenTo(white, 0.5)
          .toString(),
        value: 0,
        width: 1,
        zIndex: 3,
      },
    ],
    labels: {
      formatter() {
        const fractionDigits = showDecimalsForLabel(this) ? 1 : 0;
        return formatMonetary(this.value, {
          notation: 'compact',
          compactDisplay: 'short',
          maximumFractionDigits: fractionDigits,
          minimumFractionDigits: fractionDigits,
        });
      },
    },
    ...axisConfig,
  };
}

/**
 * Get a configuration object for a Highcharts Y-axis formatted as a whole
 * number
 *
 * @param {Highcharts.YAxisOptions} [axisConfig] - A configuration object for
 *   the Yaxis
 * @returns {Highcharts.YAxisOptions}
 */
export function getYAxisConfigNumber(axisConfig = {}) {
  return getYAxisConfig({
    labels: {
      formatter() {
        const fractionDigits = showDecimalsForLabel(this) ? 1 : 0;
        const formatter = compactNumberFormatter({
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
        });
        return formatter.format(Number(this.value));
      },
    },
    ...axisConfig,
  });
}

/**
 * Get a configuration object for YAxis Highcharts component formatted as a
 * percentage
 *
 * To restrict the yAxis to 100%, you must pass 'ceiling: 100'.
 *
 * {@link https://api.highcharts.com/highcharts/yAxis}
 * {@link https://api.highcharts.com/highcharts/yAxis.ceiling}
 *
 * @param {Highcharts.YAxisOptions} [axisConfig] - A configuration object for
 *   the Yaxis
 * @param {Object} [options] - An options object for the Yaxis
 * @returns {Highcharts.YAxisOptions}
 */
export function getYAxisConfigPercent(axisConfig = {}, options = {}) {
  const { treatValueAsDecimal = false } = options;
  return getYAxisConfig({
    labels: {
      formatter() {
        // @ts-ignore
        const isPercent = this.axis.stacking.usePercentage;
        return treatValueAsDecimal && !isPercent
          ? percentageFormatterWithPrecision(this.value)
          : formatPercent(Number(this.value));
      },
    },
    ...axisConfig,
  });
}

/** Determine if a value is invalid for calculating period-over-period change. */
export const isInvalidValueForPoP = (value) => {
  return !Number.isFinite(Number.parseFloat(value));
};

/**
 * Properly set a Percent KPIs text
 *
 * @param {number} kpi The KPI to format as a percentage
 * @returns {string}
 */
export const setPercentKpi = (kpi) => {
  return isInvalidValueForPoP(kpi) ? 'N/A' : formatPercent(kpi);
};

/**
 * Get Customer metric value in HTML string, to be used in chart exports
 *
 * @param {number} currentValue Value Number to format
 * @param {number | null} forecastTermValue Forecast Term End value to format
 * @returns {string}
 */
export const getCustomerMetric = (currentValue, forecastTermValue) => {
  let customerMetric = `${currentValue} <span style="font-size: 0.75rem; color: ${COLORS.raisinBlack}; font-weight: 400">Current Month</span> `;
  if (forecastTermValue) {
    customerMetric += `${forecastTermValue} <span style="font-size: 0.75rem; color: ${COLORS.raisinBlack}; font-weight: 400">Forecast Term End</span>`;
  }
  return customerMetric;
};

/**
 * Returns the metrics for populating a scenario comparison table accompanying a
 * stacked series chart, such as PercentBarChart.
 *
 * @param {Array[]} data Data to populate the chart
 * @param {Object} [options] Additional metric options
 * @param {Function} [options.formatter] Used to format the value for each
 *   metric
 * @param {Object} [options.colors] Custom colors for each segment in the stack,
 *   if defined
 * @returns {Object[]} Metric definitions
 */
export const getStackedChartMetrics = (data, options = {}) => {
  const { formatter = metricFormatters.monetary, colors = {} } = options;
  return data.reduce((accum, series) => {
    if (!series.length) return accum;

    const [{ scenario }] = series;
    const steppedColors = getBandedGradient(scenario.color, series.length);

    series.forEach(({ data: [value], name, id }, idx) => {
      if (isEmptyOrNull(value)) return;

      let metric = accum.find((m) => m.name === name);
      const itemId = id ?? name;
      const color = colors?.[itemId] ?? steppedColors[idx];
      if (!metric) {
        metric = {
          name,
          colors: {},
          getValue: {},
          formatter,
        };
        accum.push(metric);
      }
      metric.colors[scenario.name] = color;
      metric.getValue[scenario.name] = () => value;
      metric.formatter = formatter;
    });

    return accum;
  }, []);
};

/**
 * Returns ordered chart series data
 *
 * @param {string[]} seriesOrder Order of metric names for sorting the series
 *   data
 * @param {Object[]} [seriesData] Data used for the chart series
 * @returns {Object[]} ordered series data
 */
export const setOrderedSeries = (seriesOrder, seriesData) => {
  if (!seriesData?.length) {
    return [];
  }
  const orderedData = [...seriesData].sort(
    (a, b) => seriesOrder.indexOf(a.name) - seriesOrder.indexOf(b.name),
  );

  return orderedData;
};

/**
 * Returns null if one series has PERCENTAGE type and other is not PERCENTAGE.
 * Return the same series if both the series are CURRENCY AND NUMBER.
 *
 * @param {Object} chartSeries Compare chart series
 * @param {string} unit Base chart unit
 * @param {Object} matched Chart Properties
 */
export const getCustomChartCompareSeries = (chartSeries, unit, matched) => {
  if (
    (matched.unit === units.PERCENTAGE || unit === units.PERCENTAGE) &&
    matched.unit !== unit
  ) {
    return null;
  }

  return chartSeries;
};

export const getDateLabel = (value, timePeriod, endDate) => {
  const intervalStartDateTimeStamp = value;

  let intervalEndDateTimestamp = getDateOffsetByMonths(
    value,
    timePeriodLength[timePeriod] - 1,
  ).getTime();

  const endDateTimestamp = new Date(endDate).getTime();

  if (intervalEndDateTimestamp > endDateTimestamp) {
    intervalEndDateTimestamp = endDateTimestamp;
  }

  return [intervalStartDateTimeStamp, intervalEndDateTimestamp];
};

/**
 * Formatter for x-axis labels to handle non-monthly time periods
 *
 * @returns {string} Formatted label
 */
export function xAxisTimePeriodFormatter() {
  const { max, userOptions } = this.axis;
  const period = userOptions.timePeriod;
  if (period && period !== MONTHLY) {
    const [startDateTimeStamp, endDateTimeStamp] = getDateLabel(
      this.value,
      period,
      max,
    );

    const formattedStartDate = formatDateWithShortYear(startDateTimeStamp);
    const formattedEndDate = formatDateWithShortYear(endDateTimeStamp);

    const datesInRange = getDatesInRange(startDateTimeStamp, endDateTimeStamp);

    const isBold =
      startDateTimeStamp === currentMonthTimestamp ||
      datesInRange.includes(currentMonthTimestamp);

    if (startDateTimeStamp === endDateTimeStamp) {
      return getCurrentLabelHtml(formattedStartDate, isBold);
    }

    return `
              <div style="font-weight: ${isBold ? 'bold' : 'unset'};  color: ${
                isBold && COLORS.softBlack
              }">
                <div>${formattedStartDate} - </div> 
                <div>${formattedEndDate}</div>
              </div>`;
  }

  const label = this.axis.defaultLabelFormatter.call(this);
  // Highlight the current month
  if (this.value === currentMonthTimestamp) {
    return getCurrentLabelHtml(label, true);
  }

  return label;
}

/** Default options for X-Axis */
const XAXIS_DEFAULTS = {
  type: 'datetime',
  dateTimeLabelFormats: {
    year: "%b '%y",
  },
  offset: 12,
  tickPositioner() {
    if (!this?.chart.series.length) return null;

    const [{ min, max }] = this.chart.xAxis;
    const periods = getTimePeriodsInRange(
      min,
      max,
      this.userOptions.timePeriod,
    );
    const idxQuotient = Math.ceil(periods.length / this.tickPositions.length);
    /** @type {Highcharts.AxisTickPositionsArray} */
    const ticks = periods
      .filter((_, idx) => !(idx % idxQuotient))
      .map(([start]) => start);
    /**
     * Required to keep label formatting
     *
     * @see https://github.com/highcharts/highcharts/issues/6467
     */
    ticks.info = this.tickPositions.info;
    return ticks;
  },
  lineWidth: 0,
  tickWidth: 0,
};

/**
 * Returns a configuration for a Highcharts plot band representing the forecast
 * period for the given date range.
 *
 * @type {(
 *   startDateMs: number,
 *   endDateMs: number,
 *   isOneColorEnabled?: boolean,
 * ) => Object}
 */
export const getForecastBand = (
  startDateMs,
  endDateMs,
  isOneColorEnabled = false,
) => {
  if (currentMonthTimestamp > endDateMs) return null;

  const from =
    currentMonthTimestamp > startDateMs ? currentMonthTimestamp : startDateMs;
  const defaultForecast = {
    stops: [
      [0, '#f2f0f5'],
      [1, 'rgba(255, 255, 255, 0)'],
    ],
  };
  const liteForecast = {
    stops: [
      [0, 'rgba(255, 90, 10, 0.10)'],
      [1, 'rgba(247, 247, 248, 0.00)'],
    ],
  };

  return {
    from,
    to: endDateMs,
    id: 'forecastBand',
    color: {
      linearGradient: {
        x1: 0,
        x2: 0.85,
        y1: 0,
        y2: 0,
      },
      stops: isOneColorEnabled ? liteForecast.stops : defaultForecast.stops,
    },
    label: {
      align: 'left',
      style: {
        color: isOneColorEnabled ? GRAPH_FORECAST_COLOR : COLORS.softBlack,
        fontFamily: 'Sohne, sans-serif',
        fontSize: '8px',
        fontWeight: 600,
        letterSpacing: 'normal',
      },
      text: 'Forecast',
      useHTML: true,
      x: 6,
      y: 12,
    },
  };
};

/**
 * XAxis Configuration for the DateCharts
 *
 * @type {({
 *   startDate: string,
 *   endDate: string,
 *   timePeriod: string,
 *   isOneColorEnabled: boolean,
 *   isOneColorEnabled: boolean,
 * }) => Highcharts.XAxisOptions}
 */
export const getXAxisConfig = ({
  startDate,
  endDate,
  axisStyles,
  timePeriod,
  isOneColorEnabled,
}) => {
  const startDateMs = startDate && new Date(startDate).getTime();
  const endDateMs = endDate && new Date(endDate).getTime();
  const forecastBand = getForecastBand(
    startDateMs,
    endDateMs,
    isOneColorEnabled,
  );
  return {
    ...XAXIS_DEFAULTS,
    floor: startDateMs,
    min: startDateMs,
    max: endDateMs,
    plotBands: forecastBand && [forecastBand],
    plotLines: forecastBand && [
      {
        color: isOneColorEnabled ? GRAPH_FORECAST_COLOR : COLORS.softBlack,
        value: forecastBand.from,
        width: 1,
      },
    ],
    timePeriod,
    labels: {
      formatter: xAxisTimePeriodFormatter,
      style: axisStyles,
    },
  };
};

export const getChartLabel = (name) => {
  // To handle RTL (Right-to-Left) direction, we use '\u200E' (left-to-right mark).
  // This ensures that variable labels, like 'cash++' and '++cash', are displayed correctly with ellipsis
  // on the left side, preventing unexpected changes in the label due to RTL direction.
  // If the name starts with a special character, prepend '\u200E' to the label to maintain its original order.
  // If the name starts and ends with special character then add '\u202A' in start and end to keep the order correct.
  if (/^\W/.test(name[0]) && /^\W/.test(name[name.length - 1])) {
    return `\u202A ${name} \u202A`;
  }
  if (/^\W/.test(name) && !/\W$/.test(name)) {
    return `\u200E${name}`;
  }
  if (!/^\W/.test(name) && /\W$/.test(name)) {
    return `${name}\u200E`;
  }
  return name;
};

/** @type {(timePeriod: string, previousPeriodLabel: string) => string} */
export const getEndDateMetric = (timePeriod, previousPeriodLabel) => {
  const isMonthlyPeriod =
    timePeriod.toLowerCase() === timePeriods.MONTHLY.toLowerCase();
  return isMonthlyPeriod
    ? formatDateWithShortYear(
        getDateOffsetByMonths(getCurrentMonthTimestamp(), -1),
      )
    : previousPeriodLabel;
};

/**
 * @type {(props: {
 *   payload: import('@/types/dashboard').StackChartPayload[];
 *   adjustments?: import('@/types/dashboard').Adjustments;
 *   valueKey?: string;
 * }) => {
 *   overallTotal: number;
 *   data: import('@/types/dashboard').ChartMonthlyData[];
 * }}
 */
export const stackChartDataReducer = ({ payload, adjustments, valueKey }) => {
  let overallTotal = 0;
  let data = payload.map(({ groupingKey, months }) => {
    const mappedData = mapMonthlyData(months, valueKey);
    overallTotal += mappedData.y;

    return {
      ...groupingKey,
      ...mappedData,
    };
  });

  if (!data.length) {
    return { overallTotal, data };
  }
  const months = data[0].data.map(({ x }) => x);

  const mappedAdjustments = adjustments
    ? mapMonthlyData(adjustments.months)
    : null;

  // The height of each month's column should reflect the total actual
  // expenses, accounting for adjustments. To render this correctly, each
  // category / department's expenses must be adjusted in proportion with its
  // share of the total expense forecast for the month.
  months.forEach((x) => {
    const rawTotal = data.reduce((total, series) => {
      return total + series.data.find((month) => month.x === x).y;
    }, 0);

    const adjustmentAmount = mappedAdjustments
      ? mappedAdjustments.data.find((month) => month.x === x).y
      : 0;

    data = data.map((series) => {
      const monthIdx = series.data.findIndex((month) => month.x === x);
      const entry = series.data[monthIdx];
      const seriesData = [...series.data];
      let newY = null;
      if (entry.y !== null) {
        newY =
          adjustmentAmount > 0
            ? entry.y
            : entry.y + (entry.y / rawTotal) * adjustmentAmount;
      }
      seriesData[monthIdx] = {
        ...entry,
        rawY: entry.y,
        totalY: rawTotal + adjustmentAmount,
        y: newY,
      };
      return {
        ...series,
        data: seriesData,
      };
    });
  });
  if (adjustments) {
    data.push({
      name: adjustments.name,
      removeFromPieChart: true,
      removePct: true,
      ...mappedAdjustments,
    });
    overallTotal += mappedAdjustments.y;
  }
  return { overallTotal, data };
};
