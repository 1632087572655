// @ts-check
import React from 'react';
import FormulaBorderedIcon from '@bill/cashflow.assets/formula-bordered';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import './CellFormulaRenderer.scss';

/**
 * @typedef {{
 *   data: import('@/types/services/backend').ExpenseResponseDto;
 *   valueFormatted: string;
 * }} CellFormulaRendererProps
 */

/**
 * Render cell value with formula icon and tooltip
 *
 * @example
 *   columnDefs={[
 *    {
 *      ...
 *      cellRenderer: { component: CellFormulaRenderer }
 *    }
 *   ]}
 *
 * @type {React.FC<CellFormulaRendererProps>}
 */
const CellFormulaRenderer = ({ data, valueFormatted }) => {
  return (
    <div className="CellFormulaRenderer">
      <div>
        {!!data?.customFormula && (
          <FormulaBorderedIcon className="CellFormulaRenderer_FormulaIcon" />
        )}
      </div>
      <TooltipTextOverflow
        label={valueFormatted}
        placement="top"
        data-testid="cell-formula-renderer"
      />
    </div>
  );
};

export default CellFormulaRenderer;
