// @ts-check
import { US_CODE, COMPARED_ADDRESS_FIELDS } from './constants';

/**
 * @param {import('./types').Address} address
 * @returns {address is import('./types').AddressInternational}
 */
export const isInternationalAddress = (address) => address.country !== US_CODE;

/**
 * @type {(
 *   savedAddress: import('./types').Address | null,
 *   currentAddress: import('./types').AddressBase,
 * ) => boolean}
 */
export const hasAddressChanged = (savedAddress, currentAddress) => {
  if (!savedAddress) {
    return true;
  }
  return COMPARED_ADDRESS_FIELDS.some(
    (field) => savedAddress[field] !== currentAddress[field],
  );
};

/**
 * @type {(
 *   userAddress: import('./types').AddressUs,
 *   validationAddress: import('./types').AddressUs | null,
 * ) => boolean}
 */
export const isSameAddress = (userAddress, validationAddress) => {
  if (!validationAddress) return false;

  const normalizedValidationResponse = {
    ...validationAddress,
    zipCode: validationAddress.zipFiveDigit.toString(),
    streetLineTwo: validationAddress.streetLineTwo ?? '',
  };

  const normalizedUserAddress = {
    ...userAddress,
    streetLineTwo: userAddress.streetLineTwo ?? '',
  };

  return COMPARED_ADDRESS_FIELDS.every(
    (field) =>
      normalizedValidationResponse[field].toUpperCase() ===
      normalizedUserAddress[field].toUpperCase(),
  );
};
