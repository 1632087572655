// @ts-check
import { registeredFeatureFlags } from '@/constants/features';
import isBillDomain from '@/helpers/isBillDomain';
import useFeatureFlags from '@/hooks/useFeatureFlags';

/**
 * Returns true if bill color branding is enabled for bill domains like
 * planning.cashview.com, planning.stage.bill.com or planning.bill.com it will
 * always be enabled.
 *
 * @type {() => boolean}
 */
const useOneColor = () => {
  const isOneColorEnabled = useFeatureFlags(
    registeredFeatureFlags.ONE_COLOR_CSS_THEME,
  );
  return isOneColorEnabled || isBillDomain();
};

export default useOneColor;
