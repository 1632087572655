// @ts-check
import {
  ACCOUNTING_INTEGRATIONS,
  INTEGRATIONS,
  integrationFamily,
} from '@/constants/integrations';
import {
  REFRESH_TOKEN_ACTIVE,
  REFRESH_TOKEN_EXPIRED,
} from '@/constants/settings';

const { INTEGRATION_FAMILY_CODAT } = integrationFamily;

/**
 * @typedef {import('@/services/settingsService').IntegrationStatus & {
 *   name: string;
 *   disabled: boolean;
 * }} EnrichedIntegrationStatus
 */

/**
 * Enrich the list of integrations from the API to include a name and disable
 * flag
 *
 * @type {(
 *   integrations: import('@/services/settingsService').IntegrationStatus[],
 * ) => EnrichedIntegrationStatus[]}
 */
export const enrichIntegrations = (integrations) => {
  const connectedCount = {};
  return integrations
    .sort((a, b) => {
      if (b.connected > a.connected) return 1;
      if (b.connected < a.connected) return -1;
      return 0;
    })
    .map((integration) => {
      const { type, systemType } = integration;
      /** @type {EnrichedIntegrationStatus} */
      const integrationWithDisable = {
        ...integration,
        name:
          systemType === INTEGRATION_FAMILY_CODAT
            ? type.slice(6, type.length).toLowerCase()
            : type.toLowerCase(),
        disabled: connectedCount[systemType] === 1,
      };
      connectedCount[systemType] = integration.connected
        ? 1
        : connectedCount[systemType];
      return integrationWithDisable;
    });
};

/**
 * @typedef {EnrichedIntegrationStatus & {
 *   status: 'REFRESH_TOKEN_EXPIRED';
 * }} IntegrationUnauthorized
 */

/**
 * @typedef {EnrichedIntegrationStatus & {
 *   connected: true;
 *   status: 'REFRESH_TOKEN_ACTIVE';
 * }} IntegrationConnected
 */

/**
 * @typedef {EnrichedIntegrationStatus & {
 *   type: Exclude<EnrichedIntegrationStatus['type'], 'QUICKBOOKS(deprecated)'>;
 * }} IntegrationAllowed
 */

/**
 * @typedef {[
 *   IntegrationUnauthorized[],
 *   IntegrationConnected[],
 *   IntegrationAllowed[],
 * ]} IntegrationsByStatus
 */

/**
 * Get deauthorized and connected integrations
 *
 * @type {(
 *   integrations: EnrichedIntegrationStatus[],
 * ) => IntegrationsByStatus}
 */
export function integrationStatusesFromPayload(integrations) {
  const integrationsAllowed = /** @type {IntegrationAllowed[]} */ (
    integrations.filter(({ type }) => INTEGRATIONS.includes(type))
  );

  const integrationsConnected = /** @type {IntegrationConnected[]} */ (
    integrationsAllowed.filter(
      (integration) =>
        integration.connected && integration.status === REFRESH_TOKEN_ACTIVE,
    )
  );

  let integrationsUnauthorized = /** @type {IntegrationUnauthorized[]} */ (
    integrationsAllowed.filter(({ status }) => status === REFRESH_TOKEN_EXPIRED)
  );

  if (!integrationsUnauthorized.length)
    return [[], integrationsConnected, integrationsAllowed];

  // We need to remove any deauthorized integrations if there is a connected
  // integration of the same system type, i.e. FINCH or CODAT because they may
  // have switched to a new accounting or payroll integration. In that case, we
  // would not want to prompt them to reconnect the deauthorized integration
  integrationsUnauthorized = integrationsUnauthorized.filter(
    (deauthorizedIntegration) => {
      return !integrationsConnected.some(
        (connectedIntegration) =>
          connectedIntegration.systemType ===
          deauthorizedIntegration.systemType,
      );
    },
  );

  return [integrationsUnauthorized, integrationsConnected, integrationsAllowed];
}

/**
 * Check if a scenario has any accounting integration enabled
 *
 * @type {(scenario: import('@/types/scenario').Scenario) => boolean}
 */
export const hasAccountingIntegration = (scenario) => {
  return !!scenario?.enabledIntegrations.some((integration) =>
    ACCOUNTING_INTEGRATIONS.includes(integration),
  );
};
