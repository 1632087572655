import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { deleteCustomVariableAction } from '@/actions/variables';
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';
import { getVariableDependencies } from '@/services/variable.service';
import VariableDependencyModal from './VariableDependencyModal';

const DEPENDENCY_TEXT =
  'This variable cannot be deleted, as it is currently referenced in other areas of the application. You must edit or delete these dependencies before you can continue to delete this variable.';

const DeleteCustomVariableModalContent = ({
  variable,
  onFinish,
  scenarioId,
  deleteCustomVariable,
}) => {
  const [dependencyMap, setDependencyMap] = useState();
  const [dependenciesError, setDependenciesError] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [hasLoadedDeps, setHasLoadedDeps] = useState(false);
  const isSafeToDelete =
    hasLoadedDeps && Object.keys(dependencyMap ?? {}).length === 0;

  const getDependencies = async () => {
    try {
      const {
        data: {
          data: { dependenciesMap },
        },
      } = await getVariableDependencies(scenarioId, variable.id);
      setDependencyMap(dependenciesMap);
    } catch (e) {
      setDependenciesError(e.response?.data?.error?.errorMessage || e.message);
    } finally {
      setHasLoadedDeps(true);
    }
  };

  const handleDelete = useCallback(async () => {
    try {
      await deleteCustomVariable(scenarioId, variable.id);
    } catch (e) {
      // eslint-disable-next-line no-console -- predates description requirement
      console.error(e);
    } finally {
      onFinish();
    }
  }, [deleteCustomVariable, scenarioId, variable.id, onFinish]);

  useEffect(() => {
    // Pre-emptively call getDependencies onMount to improve perceived
    // performance when trying to delete a variable that has many dependencies
    getDependencies();
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, []);

  useEffect(() => {
    if (isDeleting && isSafeToDelete) {
      handleDelete();
    }
  }, [isDeleting, isSafeToDelete, handleDelete]);

  return (
    <>
      <div className="ModalBase_Wrapper">
        <header className="ModalBase_Header">
          <h2 className="ModalConfirmation_Heading ModalConfirmation_Heading-delete">
            Delete Custom Variable
          </h2>
        </header>
        <div className="ModalBase_Content">
          <div className="ModalConfirmation_Text">
            {variable?.chartAssociated ? (
              <>
                This custom variable has an associated custom chart(s) on the
                dashboard. Deleting this custom variable will remove the custom
                variable from the chart. (It will not remove the custom chart.)
              </>
            ) : (
              <>
                Are you sure that you want to delete the variable "
                {variable.displayName}
                "?
                <br />
                This action cannot be undone.
              </>
            )}
          </div>
        </div>
        <footer className="ModalBase_Footer">
          <Button
            className="Button Button-cancelLink"
            onClick={onFinish}
            data-testid="modal-cancel-button"
            disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button
            className="Button-delete"
            onClick={() => setIsDeleting(true)}
            data-testid="modal-action-button"
            loading={isDeleting}
          >
            Delete
          </Button>
        </footer>
      </div>

      {isDeleting && !isSafeToDelete && (
        <VariableDependencyModal
          title="Cannot Delete Variable"
          onFinish={onFinish}
          variableId={variable.id}
          text={DEPENDENCY_TEXT}
          dependencies={dependencyMap}
          errors={dependenciesError}
        />
      )}
    </>
  );
};

function DeleteCustomVariableModal({ variable, ...props }) {
  return (
    <Modal
      data-testid={
        variable?.chartAssociated
          ? 'delete-modal-chart-associated'
          : 'custom-var-delete-modal'
      }
      className="ModalBase ModalConfirmation"
      open={!!variable}
    >
      <DeleteCustomVariableModalContent variable={variable} {...props} />
    </Modal>
  );
}

function mapStateToProps({ scenario }) {
  return {
    scenarioId: scenario.scenarioId,
  };
}

export default connect(mapStateToProps, {
  deleteCustomVariable: deleteCustomVariableAction,
})(DeleteCustomVariableModal);
