import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getIntegrationsStatusAction } from '@/actions/settings';
import Button from '@/components/common/Button';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import { betaFeatures } from '@/constants/features';
import {
  CODAT_QUICKBOOKS,
  CODAT_WAVE,
  CODAT_XERO,
  CODAT_ZOHO,
  CODAT_NETSUITE,
  FINCH_GUSTO,
  FINCH_JUSTWORKS,
  FINCH_ADP,
  FINCH_RIPPLING,
  FINCH_BAMBOOHR,
  FINCH_ZENEFITS,
  REVENUE_STRIPE,
} from '@/constants/integrations';
import { DASHBOARD_PATH, JOIN_INTEGRATIONS_PATH } from '@/constants/pages';
import integrationsRedirect from '@/helpers/integrationsRedirect';
import useBetaAccess from '@/hooks/useBetaAccess';
import { actionTypes } from '@/hooks/useIntegrationStates/actionTypes';
import useIntegrationStatesPersisted from '@/hooks/useIntegrationStates/useIntegrationStatesPersisted';
import withProtectedRoute from '@/pages/Registration/WithProtectedRoute';
import { routeProtections } from '@/pages/Registration/constants';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import { initPendo } from '@/services/analyticsService';
import IntegrationButton from './IntegrationButton';
import { ReactComponent as ADPLogo } from '@/assets/images/adp_logo.svg';
import { ReactComponent as BambooLogo } from '@/assets/images/bamboo_logo.svg';
import { ReactComponent as GustoLogo } from '@/assets/images/gusto_logo.svg';
import { ReactComponent as JustWorksLogo } from '@/assets/images/justworks_logo.svg';
import { ReactComponent as NetsuiteLogo } from '@/assets/images/netsuite.svg';
import { ReactComponent as QuickbooksIcon } from '@/assets/images/quickbooks-no-text.svg';
import { ReactComponent as RipplingLogo } from '@/assets/images/rippling-rs.svg';
import { ReactComponent as StripeIcon } from '@/assets/images/stripe_logo.svg';
import { ReactComponent as WaveIcon } from '@/assets/images/wave-logo-stacked.svg';
import { ReactComponent as XeroIcon } from '@/assets/images/xero.svg';
import { ReactComponent as ZenefitsLogo } from '@/assets/images/zenefits_logo.svg';
import { ReactComponent as ZohoIcon } from '@/assets/images/zoho-books.svg';

const Integrations = ({
  selectedCompany,
  userInfo,
  integrationsAllowed,
  getIntegrationsStatus,
  identityToken,
}) => {
  const history = useHistory();
  const { buttonStates, setButtonStates } =
    useIntegrationStatesPersisted(integrationsAllowed);
  const [isModalActive, setModalActive] = useState(false);
  const [hasSeenModal, setHasSeenModal] = useState(false);

  const {
    isRedirecting,
    [CODAT_QUICKBOOKS]: quickbooksState,
    [CODAT_WAVE]: waveState,
    [CODAT_XERO]: xeroState,
    [CODAT_ZOHO]: zohoState,
    [CODAT_NETSUITE]: netsuiteState,
    [FINCH_GUSTO]: gustoState,
    [FINCH_JUSTWORKS]: justworksState,
    [FINCH_ADP]: adpState,
    [FINCH_RIPPLING]: ripplingState,
    [FINCH_BAMBOOHR]: bambooState,
    [FINCH_ZENEFITS]: zenefitsState,
    [REVENUE_STRIPE]: stripeState,
  } = buttonStates;

  const isEnabledAccountingIntegration =
    quickbooksState.isSelected ||
    waveState.isSelected ||
    xeroState.isSelected ||
    zohoState.isSelected ||
    netsuiteState.isSelected;

  // Disabled per BU-8388
  const isADPFeatureEnabled = false;
  const isRipplingFeatureEnabled = false;

  const isBambooFeatureEnabled = useBetaAccess(betaFeatures.BAMBOO_HR);
  const isZenefitsFeatureEnabled = useBetaAccess(betaFeatures.ZENEFITS);

  const isEnabledPayrollIntegration =
    gustoState.isSelected ||
    justworksState.isSelected ||
    adpState.isSelected ||
    ripplingState.isSelected ||
    zenefitsState.isSelected ||
    bambooState.isSelected;

  const isEnabledRevenueIntegration = stripeState.isSelected;
  const handleCodatRedirect = integrationsRedirect({
    selectedCompany,
    redirectTo: JOIN_INTEGRATIONS_PATH,
    identityToken,
  });

  useEffect(() => {
    if (
      isEnabledAccountingIntegration ||
      isEnabledPayrollIntegration ||
      isEnabledRevenueIntegration
    ) {
      initPendo(userInfo, {
        ...selectedCompany,
        // This is the only time isEnabledAccountingIntegration or isEnabledPayrollIntegration gets set when
        // initializing Pendo. See: src/services/analyticsService.js
        isEnabledAccountingIntegration,
        isEnabledPayrollIntegration,
        isEnabledRevenueIntegration,
      });
    }
    // Don't include `userInfo` in the dependency array below because it causes
    // the `initPendo` function to be called one too many times
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [
    isEnabledAccountingIntegration,
    isEnabledPayrollIntegration,
    selectedCompany,
    isEnabledRevenueIntegration,
  ]);

  useEffect(() => {
    getIntegrationsStatus();
  }, [getIntegrationsStatus]);

  const handleClick = (integration) => {
    setButtonStates({
      type: actionTypes.SET_BUTTON_STATE,
      payload: integration,
    });
  };

  return (
    <>
      {isModalActive && (
        <ModalConfirmation
          id="modal-integrations-confirm"
          title="Did you know?"
          cancelBtnTxt="Let's connect!"
          onCancel={() => {
            setModalActive(false);
            setHasSeenModal(true);
          }}
          onAction={() => history.replace(DASHBOARD_PATH)}
          actionBtnTxt="Yes, skip for now"
        >
          By connecting your accounting, revenue data and payroll platforms with
          Finmark, we will automatically reconcile your historical actuals and
          speed up the time it takes to build your financial model. Giving you
          back even more time to focus on budgeting and planning.
          <br />
          <br />
          Note: We do not change any data in your accounting, revenue data or
          payroll platform. The connection is a pull to update data in Finmark.
        </ModalConfirmation>
      )}
      <h1 className="RegistrationHeading">Connect Integrations</h1>
      <p className="RegistrationText">
        Finmark allows you to integrate with your existing financial platforms
        for more accurate reporting. Simply click the '+' within each of the
        integrations to get started, or you can do this later by visiting the
        'Settings' section of the app.
      </p>
      <div className="RegistrationContent">
        <div className="Registration_FieldGroup">
          <IntegrationButton
            disabled={isRedirecting}
            icon={
              <QuickbooksIcon className="RegistrationIntegration_Quickbooks" />
            }
            onClick={() => {
              handleClick(CODAT_QUICKBOOKS);
              setTimeout(() => handleCodatRedirect(CODAT_QUICKBOOKS), 50);
            }}
            {...quickbooksState}
          />
          <IntegrationButton
            className="RegistrationIntegration_IconBtn"
            disabled={isRedirecting}
            icon={<WaveIcon className="RegistrationIntegration_Wave" />}
            onClick={() => {
              handleClick(CODAT_WAVE);
              setTimeout(() => handleCodatRedirect(CODAT_WAVE), 50);
            }}
            {...waveState}
          />
          <IntegrationButton
            className="RegistrationIntegration_IconBtn-xero"
            disabled={isRedirecting}
            icon={<XeroIcon className="RegistrationIntegration_Xero" />}
            onClick={() => {
              handleClick(CODAT_XERO);
              setTimeout(() => handleCodatRedirect(CODAT_XERO), 50);
            }}
            {...xeroState}
          />
          <IntegrationButton
            disabled={isRedirecting}
            icon={<ZohoIcon className="RegistrationIntegration_Zoho" />}
            onClick={() => {
              handleClick(CODAT_ZOHO);
              setTimeout(() => handleCodatRedirect(CODAT_ZOHO), 50);
            }}
            {...zohoState}
          />
          <IntegrationButton
            disabled={isRedirecting}
            icon={<NetsuiteLogo className="RegistrationIntegration_Netsuite" />}
            onClick={() => {
              handleClick(CODAT_NETSUITE);
              setTimeout(() => handleCodatRedirect(CODAT_NETSUITE), 50);
            }}
            {...netsuiteState}
          />
          <IntegrationButton
            disabled={isRedirecting}
            icon={<GustoLogo className="RegistrationIntegration_Gusto" />}
            onClick={() => {
              handleClick(FINCH_GUSTO);
              setTimeout(() => handleCodatRedirect(FINCH_GUSTO), 50);
            }}
            {...gustoState}
          />
          <IntegrationButton
            disabled={isRedirecting}
            icon={
              <JustWorksLogo className="RegistrationIntegration_JustWorks" />
            }
            onClick={() => {
              handleClick(FINCH_JUSTWORKS);
              setTimeout(() => handleCodatRedirect(FINCH_JUSTWORKS), 50);
            }}
            {...justworksState}
          />
          {isADPFeatureEnabled && (
            <IntegrationButton
              disabled={isRedirecting}
              icon={<ADPLogo className="RegistrationIntegration_ADP" />}
              onClick={() => {
                handleClick(FINCH_ADP);
                setTimeout(() => handleCodatRedirect(FINCH_ADP), 50);
              }}
              {...adpState}
            />
          )}
          {isRipplingFeatureEnabled && (
            <IntegrationButton
              className="RegistrationIntegration_IconBtn-rippling"
              disabled={isRedirecting}
              icon={
                <RipplingLogo className="RegistrationIntegration_Rippling" />
              }
              onClick={() => {
                handleClick(FINCH_RIPPLING);
                setTimeout(() => handleCodatRedirect(FINCH_RIPPLING), 50);
              }}
              {...ripplingState}
            />
          )}
          {isZenefitsFeatureEnabled && (
            <IntegrationButton
              disabled={isRedirecting}
              icon={
                <ZenefitsLogo className="RegistrationIntegration_Zenefits" />
              }
              onClick={() => {
                handleClick(FINCH_ZENEFITS);
                setTimeout(() => handleCodatRedirect(FINCH_ZENEFITS), 50);
              }}
              {...zenefitsState}
            />
          )}
          {isBambooFeatureEnabled && (
            <IntegrationButton
              disabled={isRedirecting}
              icon={<BambooLogo className="RegistrationIntegration_Bamboo" />}
              onClick={() => {
                handleClick(FINCH_BAMBOOHR);
                setTimeout(() => handleCodatRedirect(FINCH_BAMBOOHR), 50);
              }}
              {...bambooState}
            />
          )}
          <IntegrationButton
            disabled={isRedirecting}
            icon={<StripeIcon className="RegistrationIntegration_Stripe" />}
            onClick={() => {
              handleClick(REVENUE_STRIPE);
              setTimeout(() => handleCodatRedirect(REVENUE_STRIPE), 50);
            }}
            {...stripeState}
          />
        </div>
        <Button
          id="integrations-next"
          className="Button-registration"
          data-testid="integrations-next"
          onClick={() => {
            if (
              !hasSeenModal &&
              !isEnabledAccountingIntegration &&
              !isEnabledPayrollIntegration &&
              !isEnabledRevenueIntegration
            ) {
              setModalActive(true);
            } else {
              history.replace(DASHBOARD_PATH);
            }
          }}
          disabled={buttonStates.isRedirecting}
        >
          Finish
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = ({ companies, auth, settings }) => ({
  selectedCompany: getSelectedCompany({ companies }),
  userInfo: auth.userInfo,
  integrationsAllowed: settings.integrationsAllowed,
  identityToken: auth.identityToken,
});

const ConnectedIntegrations = connect(mapStateToProps, {
  getIntegrationsStatus: getIntegrationsStatusAction,
})(Integrations);

export default withProtectedRoute(ConnectedIntegrations, {
  protectedBy: routeProtections.AUTH_TOKEN,
});
