import PropTypes from 'prop-types';
import TableExportButton from '@/components/TableExportButton';
import SpreadsheetLegend from '@/components/common/Spreadsheet/SpreadsheetLegend';
import { classNames } from '@/helpers';
import './SpreadsheetToolbar.scss';

/**
 * Displays a toolbar for the MonthlySpreadsheet component, containing a legend
 * and any additional controls.
 *
 * @example
 *   <SpreadsheetToolbar />;
 */
function SpreadsheetToolbar({
  children,
  Legend = SpreadsheetLegend,
  editable = true,
  hideLegend = false,
  onLegendClick = () => {},
  gridApi,
}) {
  return (
    <div
      className={classNames(
        'SpreadsheetToolbar',
        !editable && 'SpreadsheetToolbar-readOnly',
      )}
    >
      {children}{' '}
      <div className="SpreadsheetToolbar_Legend">
        {!hideLegend && (
          <Legend editable={editable} onLegendClick={onLegendClick} />
        )}
        {gridApi && (
          <div className="ExportButton">
            <TableExportButton
              onClick={() => gridApi.api.exportDataAsExcel()}
            />
          </div>
        )}
      </div>
    </div>
  );
}

SpreadsheetToolbar.propTypes = {
  /** Additional controls to populate the toolbar */
  children: PropTypes.node,
  /** Whether the spreadsheet contains any editable cells */
  editable: PropTypes.bool,
  /** The contents of the legend */
  Legend: PropTypes.elementType,
  /** Either to show or hide the legend */
  hideLegend: PropTypes.bool,
  /** The function to be called when the legend is clicked */
  onLegendClick: PropTypes.func,
  /**
   * AG Grid's api
   *
   * @see https://ag-grid.com/react-data-grid/grid-api/
   */
  gridApi: PropTypes.objectOf(PropTypes.any),
};

export default SpreadsheetToolbar;
