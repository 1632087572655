import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { betaEmailAccounts, betaFeatures } from '@/constants/features';

/**
 * Determines whether the user has access to the given beta feature
 *
 * @param {string} feature Flag of the feature
 * @returns {boolean} TRUE if the user has access
 */
function useBetaAccess(feature) {
  const { email } = useSelector(({ auth }) => auth.userInfo);

  return useMemo(
    () =>
      betaEmailAccounts.some((host) => email?.includes(host)) &&
      Object.values(betaFeatures).includes(feature),
    [email, feature],
  );
}

export default useBetaAccess;
