/**
 * Generate a file for download
 *
 * @param {Object} param
 * @param {string} param.title - the title of the download used to build up file
 *   name
 * @param {Blob} param.documentBlob - a blob of data representing the file
 * @param {string} param.fileType - file extension type of download e.g. pdf,
 *   xls, csv, etc
 * @param {HTMLElement} param.parentElement - element to attach the download
 *   link, defaults to the document body
 */
const generateDownload = ({
  title,
  documentBlob,
  fileType,
  parentElement = document.body,
}) => {
  const encodedFile = URL.createObjectURL(documentBlob);
  const link = document.createElement('a');
  link.setAttribute('href', encodedFile);
  link.setAttribute('download', `${title}.${fileType}`);
  link.style.display = 'none';
  parentElement.appendChild(link);
  link.click();
  link.remove();
};

export default generateDownload;
