import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getUnitFormatter } from '@/components/common/Spreadsheet/helpers';
import { units } from '@/constants/variables';

/**
 * Displays a status bar for the Spreadsheet component, containing count and sum
 * metrics and any additional items passed as children.
 *
 * @example
 *   <SpreadsheetStatusBar
 *     cellCount={someNum}
 *     cellSum={sum}
 *     cellUnit={firstCellUnit}
 *   />;
 */
const SpreadsheetStatusBar = ({ cellCount, cellSum, cellUnit, children }) => {
  useEffect(() => {
    document.body.classList.add('SpreadsheetStatusBarVisible');
    return () => document.body.classList.remove('SpreadsheetStatusBarVisible');
  }, []);

  const formatter = getUnitFormatter(cellUnit ?? units.CURRENCY);
  return (
    <div className="SpreadsheetStatusBar">
      {children}
      <div
        className={`SpreadsheetStatusBar_Label ${
          !!children && 'SpreadsheetStatusBar_Label-separator'
        }`}
      >
        Count: <span className="SpreadsheetStatusBar_Metric">{cellCount}</span>
      </div>
      <div className="SpreadsheetStatusBar_Label">
        Sum:{' '}
        <span className="SpreadsheetStatusBar_Metric">
          {formatter(cellSum)}
        </span>
      </div>
    </div>
  );
};

SpreadsheetStatusBar.propTypes = {
  /** Additional items to populate the status bar */
  children: PropTypes.node,
  /** Number of cells selected in the spreadsheet */
  cellCount: PropTypes.number,
  /** Sum of values of cells selected in the spreadsheet */
  cellSum: PropTypes.number,
  /** Unit type of the first selected cell in a range for formatting the sum */
  cellUnit: PropTypes.string,
};

export default SpreadsheetStatusBar;
