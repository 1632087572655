import InfoTooltip from '@/components/common/InfoTooltip';
import { slugify } from '@/helpers';
import './VendorSpendTooltipCellRenderer.scss';

const VendorSpendTooltipCellRenderer = ({ value }) => {
  const testId = slugify(value);
  return (
    <div className="VendorSpend_Cell-withTooltip" data-testid={testId}>
      {value}{' '}
      <InfoTooltip data-testid={`${testId}-tooltip`}>
        This includes expenses that are not associated with a vendor and any
        expenses that you created in Finmark directly.
      </InfoTooltip>
    </div>
  );
};

export default VendorSpendTooltipCellRenderer;
