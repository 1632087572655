import { useCallback, useEffect, useMemo } from 'react';
import { XAxis, YAxis } from 'react-jsx-highcharts';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import WarningIcon from '@bill/cashflow.assets/warning';
import { deleteCustomChartAction } from '@/actions/dashboard';
import CustomChartGlanceTooltip from '@/components/Charts/CustomChartGlanceTooltip';
import DateChart from '@/components/Charts/DateChart';
import FunnelChart from '@/components/Charts/FunnelChart';
import PieChartComparison from '@/components/Charts/PieChartComparison';
import { LABEL_STYLES } from '@/components/Charts/chartDefaults';
import { chartTypes } from '@/components/Charts/constants';
import {
  getXAxisConfig,
  getYAxisConfig,
  getYAxisConfigNumber,
  getYAxisConfigPercent,
} from '@/components/Charts/helpers';
import { getStackedColumnTooltipMetrics } from '@/components/Charts/tooltips';
import EmptyData from '@/components/common/EmptyData';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import { units } from '@/constants/variables';
import {
  UNKNOWN_UNIT,
  getMetricFormatter,
  valueFormatter,
} from '@/helpers/customCharts';
import useOneColor from '@/hooks/useOneColor';
import useSelectedScenarios from '@/hooks/useSelectedScenarios';
import useVariableChartQuery from '@/hooks/useVariableChartQuery';

const AXIS_STYLES_DEFAULT = {};
const META_DEFAULT = {};

/** @type {(unit: keyof units) => Highcharts.YAxisOptions} */
const getMetricYAxis = (unit) => {
  switch (unit) {
    case units.NUMBER:
      return getYAxisConfigNumber();
    case units.PERCENTAGE:
      return getYAxisConfigPercent(null, { treatValueAsDecimal: true });
    case units.CURRENCY:
      return getYAxisConfig();
    default:
      console.warn(UNKNOWN_UNIT, unit);
      return getYAxisConfigNumber();
  }
};

const CustomChart = ({
  id,
  'data-testid': dataTestId,
  exportBtn,
  tooltipOptions,
  meta = META_DEFAULT,
  onCancelDelete,
  onChartCreated,
  onDeleted,
  onQueryStateChange,
  plotOptions,
  axisStyles = AXIS_STYLES_DEFAULT,
  showDeleteModal,
  Tooltip = CustomChartGlanceTooltip,
}) => {
  const { pathname } = useLocation();
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );
  const isOneColorEnabled = useOneColor();

  const [baseScenario, compareScenario] = useSelectedScenarios();
  const { scenarioId: baseScenarioId } = baseScenario ?? {};

  const { unit } = meta;
  const metadata = useMemo(() => meta.metadata, [meta]);
  const chartType = metadata?.chartType?.toLowerCase();

  const variables = useMemo(
    () => meta.variables.map(({ variableDto }) => variableDto),
    [meta],
  );
  const chartPrecision = metadata.chartPrecision ?? 0;
  const chartQuery = useVariableChartQuery(variables, metadata, unit);

  useEffect(
    () => {
      if (chartQuery.isFetched && !chartQuery.isError) {
        onQueryStateChange?.(chartQuery);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
    [chartQuery],
  );
  const isLoading = chartQuery.isLoading && chartQuery.isFetching;

  const yAxisConfigs = useMemo(() => {
    if (!unit) return null;
    const yAxisDefaults = getMetricYAxis(
      chartType === chartTypes.PERCENT_AREA ? units.PERCENTAGE : unit,
    );
    return {
      ...yAxisDefaults,
      allowDecimals: chartPrecision > 0,
      labels: {
        ...yAxisDefaults.labels,
        style: axisStyles || LABEL_STYLES,
      },
    };
  }, [axisStyles, chartPrecision, chartType, unit]);

  const handleDelete = async () => {
    await dispatch(deleteCustomChartAction(baseScenarioId, id));
    onDeleted?.();
  };

  const getValueFormatter = useCallback(
    (context) => {
      let metricUnit;

      switch (chartType) {
        case chartTypes.AREA:
        case chartTypes.COLUMN:
          metricUnit = unit;
          break;
        case chartTypes.PIE:
        case chartTypes.FUNNEL: {
          const { unit: variableUnit } =
            variables.find(({ name }) =>
              [name, metadata?.customVariableNames?.[name]].includes(
                context.name,
              ),
            ) || {};
          metricUnit = variableUnit;
          break;
        }
        default: {
          const { series } = context;
          const { unit: variableUnit } =
            variables.find(
              ({ name }) =>
                name === series.name ||
                metadata?.customVariableNames?.[name] === series.name,
            ) || {};
          metricUnit = variableUnit;
        }
      }

      return getMetricFormatter(metricUnit);
    },
    [variables, unit, chartType, metadata],
  );

  const utilValueFormatter = (value, context) =>
    valueFormatter({
      value,
      precision: chartQuery.data.precision,
      formatter: getValueFormatter(context),
    });

  const getTooltipMetrics = useCallback(
    (context) => {
      const { series } = context;
      if (!series.options.stacking) {
        const { unit: variableUnit } =
          variables.find(
            ({ name }) =>
              name === series.name ||
              metadata?.customVariableNames?.[name] === series.name,
          ) || {};
        return [
          {
            name: series.name,
            isMainMetric: true,
            formatter: getMetricFormatter(variableUnit),
            key: 'y',
          },
        ];
      }
      return getStackedColumnTooltipMetrics(context, {
        variables,
        metadata,
      });
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [variables],
  );

  const isArea =
    chartType === chartTypes.AREA || chartType === chartTypes.PERCENT_AREA;

  const mergedPlotOptions = useMemo(() => {
    if (isArea) {
      return {
        ...plotOptions,
        area: {
          ...plotOptions?.area,
          stacking:
            chartType === chartTypes.PERCENT_AREA ? 'percent' : 'normal',
        },
      };
    }
    return plotOptions;
  }, [chartType, isArea, plotOptions]);

  const xAxisOptions = useMemo(
    () =>
      getXAxisConfig({
        startDate,
        endDate,
        axisStyles,
        timePeriod,
        isOneColorEnabled,
      }),
    [startDate, endDate, axisStyles, timePeriod, isOneColorEnabled],
  );

  const showExtraXAxis = isArea && compareScenario;

  let PartToWholeChart;
  switch (chartType) {
    case chartTypes.FUNNEL:
      PartToWholeChart = FunnelChart;
      break;
    case chartTypes.PIE:
      PartToWholeChart = PieChartComparison;
      break;
    // no default
  }

  return !chartQuery.isError ? (
    <>
      {PartToWholeChart ? (
        <PartToWholeChart
          data={chartQuery.data.series}
          data-testid={dataTestId}
          loading={isLoading}
          onChartCreated={(chart) => {
            // eslint-disable-next-line no-param-reassign -- predates description requirement
            if (exportBtn) exportBtn.current = chart;
            onChartCreated?.(chart);
          }}
          plotOptions={plotOptions}
          url={`${pathname}/custom-chart/${id}`}
          valueFormatter={(value, context) =>
            utilValueFormatter(value, context)
          }
        />
      ) : (
        <DateChart
          loading={isLoading}
          data-testid={dataTestId}
          tooltip={
            !chartQuery.isLoading && (
              <Tooltip
                {...tooltipOptions}
                metrics={getTooltipMetrics}
                valueFormatter={(value, context) =>
                  utilValueFormatter(value, context)
                }
                endDate={endDate}
                timePeriod={timePeriod}
                precision={chartQuery.data.precision}
              />
            )
          }
          onChartCreated={onChartCreated}
          plotOptions={mergedPlotOptions}
          ref={exportBtn}
          axisStyles={axisStyles}
          startDate={startDate}
          endDate={endDate}
          timePeriod={timePeriod}
        >
          <XAxis {...xAxisOptions} width={showExtraXAxis ? '48%' : '100%'} />
          {showExtraXAxis && <XAxis {...xAxisOptions} left="52%" width="48%" />}
          <YAxis {...yAxisConfigs}>
            {chartQuery.data.series?.map(
              ({ color, data, name, scenario, scenarioId }, idx) => {
                const isComparison = scenarioId !== baseScenarioId;
                return (
                  data && (
                    <DateChart.Series
                      key={`${scenarioId}-${name}`}
                      type={
                        chartType === chartTypes.PERCENT_AREA
                          ? chartTypes.AREA
                          : chartType
                      }
                      color={color}
                      data={data}
                      index={idx}
                      isComparison={isComparison}
                      metric={name}
                      scenario={scenario}
                    />
                  )
                );
              },
            )}
          </YAxis>
        </DateChart>
      )}
      {showDeleteModal && (
        <ModalConfirmation
          id="modal-custom-chart-delete"
          onCancel={onCancelDelete}
          onAction={handleDelete}
          title="Delete Custom Chart"
        >
          You are about to delete the custom chart for '{meta.title}
          '. Please be advised that no data will be lost, and can still be
          viewed within the 'Variables' section.
        </ModalConfirmation>
      )}
    </>
  ) : (
    <EmptyData Icon={WarningIcon}>
      {chartQuery.error.response?.status === 404
        ? 'The requested chart could not be found.'
        : chartQuery.error.message}
    </EmptyData>
  );
};

export default CustomChart;
