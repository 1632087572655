// @ts-check
import { cloneElement, forwardRef, useImperativeHandle, useState } from 'react';
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- This component is the approved interface for Overlay
import Overlay from '@/components/common/Overlay';
import { classNames } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';
import './WithPopover.scss';

/**
 * @typedef {{
 *   'children': React.ReactElement;
 *   'data-testid': string;
 *   'content': React.ReactNode;
 *   'className'?: string;
 *   'onClose'?: import('@floating-ui/react').UseFloatingProps['onOpenChange'];
 *   'placement'?: import('@floating-ui/core').Placement;
 *   'visible'?: boolean;
 * }} WithPopoverProps
 */

/**
 * Renders an interactive popover when the given children are clicked
 *
 * @example
 *   <WithPopover
 *     content={
 *       <RadioGroupPersistent
 *         value={bar}
 *         options={[
 *           {
 *             label: 'Currency',
 *             value: false,
 *           },
 *           {
 *             label: 'Percent',
 *             value: true,
 *           },
 *         ]}
 *       />
 *     }
 *     data-testid="foo"
 *   >
 *     <button>Click Me!</button>
 *   </WithPopover>;
 *
 * @type {React.ForwardRefRenderFunction<
 *   import('@floating-ui/react-dom').ReferenceType,
 *   WithPopoverProps
 * >}
 */
const WithPopoverFn = (
  {
    children,
    className,
    content,
    'data-testid': dataTestId,
    onClose,
    placement = 'bottom',
    visible,
    ...props
  },
  ref,
) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);

  const isControlled = !isEmptyOrNull(visible);
  const open = isControlled ? visible : uncontrolledOpen;

  const {
    x,
    y,
    context,
    placement: adjPlacement,
    reference,
    refs,
    floating,
    strategy,
  } = useFloating({
    open,
    onOpenChange: isControlled ? onClose : setUncontrolledOpen,
    placement,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });
  const { getFloatingProps, getReferenceProps } = useInteractions([
    useClick(context, { enabled: !isControlled }),
    useDismiss(context),
  ]);

  useImperativeHandle(ref, () => refs.reference.current);

  return (
    <div className="WithPopover">
      {cloneElement(
        children,
        getReferenceProps({
          'ref': reference,
          'aria-expanded': open,
        }),
      )}
      <Overlay
        ref={floating}
        className={classNames('Popover', className)}
        data-testid={dataTestId}
        open={open}
        placement={adjPlacement}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        }}
        {...getFloatingProps()}
        {...props}
      >
        {content}
      </Overlay>
    </div>
  );
};

const WithPopover = forwardRef(WithPopoverFn);

WithPopover.propTypes = {
  /** Element which will trigger the popover when clicked */
  'children': PropTypes.element.isRequired,
  /** Additional class(es) to apply to the popover */
  'className': PropTypes.string,
  /** Content of the popover */
  'content': PropTypes.node.isRequired,
  /** Unique identifier for selecting the popover in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /**
   * Event handler for when a controlled popover requests to be closed, such as
   * when clicking outside.
   */
  'onClose': PropTypes.func,
  /**
   * Location of the popover relative to its trigger
   *
   * @see https://floating-ui.com/docs/tutorial#placements
   */
  'placement': PropTypes.any,
  /** Force the popover to be visible or hidden */
  'visible': PropTypes.bool,
};

export default WithPopover;
