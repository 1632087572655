import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useQueryParams from '@/hooks/useQueryParams';
import xeroAuthorization from '@/services/xero.service';

const redirectUri = `${window.location.origin}/xero-redirect`;

/** Exchange a code from Xero for information about the user and his/her company. */
const XeroRedirect = () => {
  const history = useHistory();
  const query = useQueryParams();
  const code = query.get('code');

  useEffect(() => {
    if (!code) {
      throw new Error(
        'MissingAuthCode: This error originated with Xero. Xero did not include a "code" in its response.',
      );
    }

    (async function xeroAuth() {
      const {
        data: { data },
      } = await xeroAuthorization({ code, redirectUri });
      const { email, firstName, lastName, companyName, currency } = data;
      const params = new URLSearchParams();
      params.append('email', email);
      params.append('name', firstName);
      params.append('last_name', lastName);
      params.append('company_name', companyName);
      params.append('currency', currency);

      history.push({
        pathname: '/join/xero',
        search: `?${params.toString()}`,
      });
    })();
  }, [code, history]);

  return null;
};

export default XeroRedirect;
