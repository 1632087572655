import { useState, useEffect } from 'react';
import CurrencyField from '@/components/common/CurrencyField';
import { sanitizeValue } from '@/components/common/Spreadsheet/helpers';
import { ALLOW_NEGATIVE_NUM_REGEX } from '@/constants/expenses';
import { isEmptyOrNull } from '@/helpers/validators';

const ExpenseAmount = ({
  label,
  onUpdate,
  defaultExpenseAmount,
  expenseType,
}) => {
  const [expenseAmount, setExpenseAmount] = useState(
    defaultExpenseAmount ?? null,
  );

  useEffect(() => {
    setExpenseAmount(defaultExpenseAmount);
  }, [defaultExpenseAmount]);

  const handleBlur = () => {
    onUpdate({
      type: 'SET_EXPENSE_AMOUNT',
      payload: expenseAmount,
    });
  };

  return (
    <div className="Form_Group Form_Group-halfWidth">
      <label
        className="Label"
        htmlFor="expense-amount"
        data-testid="expense-amount-label"
      >
        {label}
      </label>
      <CurrencyField
        id="expense-amount"
        name="expenseAmount"
        data-testid="expense-amount"
        value={expenseAmount}
        onChange={({ target }) => {
          const { value } = target;
          if (ALLOW_NEGATIVE_NUM_REGEX.test(value))
            setExpenseAmount(sanitizeValue(value));
        }}
        onBlur={handleBlur}
        validate={(value) => {
          const isInvalid =
            isEmptyOrNull(value) || !Number.isFinite(Number(value));
          return isInvalid && 'Must provide an expense amount';
        }}
        disabled={expenseType === null}
      />
    </div>
  );
};

export default ExpenseAmount;
