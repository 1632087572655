import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PlusSmIcon from '@bill/cashflow.assets/plus-sm';
import {
  setCompaniesOnLoginAction,
  resetCompaniesAction,
  setSelectedCompany as setSelectedCompanyAction,
} from '@/actions/companies';
import { scenarioIdPayload as scenarioIdPayloadAction } from '@/actions/scenario';
import CompanyInfoCard from '@/components/CompanySelection/CompanyInfoCard';
import CompanyRegisterCard from '@/components/CompanySelection/CompanyRegisterCard';
import CompanyTermsModal from '@/components/CompanySelection/CompanyTermsModal';
import AddUpdateCompanySidebar from '@/components/ManagementConsole/AddUpdateCompanySidebar';
import ProductTypeFilter from '@/components/ProductTypeFilter/ProductTypeFilter';
import CoBranding from '@/components/common/CoBranding';
import ComponentLoader from '@/components/common/ComponentLoader';
import { DASHBOARD_PATH } from '@/constants/pages';
import useNotificationContext from '@/contexts/useNotificationContext';
import { getDomainAndResourceHost } from '@/helpers';
import useCurrentSelectedCompany from '@/hooks/useCurrentSelectedCompany';
import { ReactComponent as FinmarkLogo } from '@/assets/images/bill_finmark_logo.svg';
import './CompanySelectionPage.scss';

/**
 * Setting the companyId explicitly to null would override the selectedCompany
 * in the API requests
 */
const OVERRIDE_COMPANY_ID = null;

function CompanySelectionPage({
  setSelectedCompany,
  companies,
  loading,
  scenarioIdPayload,
  setCompaniesOnLogin,
}) {
  const history = useHistory();
  const [showTermsModal, setShowTermsModal] = useState(false);
  const toggleShowTermsModals = () => setShowTermsModal(!showTermsModal);
  const [isNoCompanyAvailable, setIsNoCompanyAvailable] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { subDomain, resourceHost } = getDomainAndResourceHost();

  const { margin } = useNotificationContext();
  const currentSelectedCompany = useCurrentSelectedCompany();

  useEffect(() => {
    document.title = 'Switch Company';
    setCompaniesOnLogin();
  }, [setCompaniesOnLogin]);

  useEffect(() => {
    if (!loading && companies.length === 0) {
      setIsNoCompanyAvailable(true);
    }
    if (companies.length > 0) {
      setIsNoCompanyAvailable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [companies]);

  const onCompanySelect = (company) => {
    setSelectedCompany(company.id);
    scenarioIdPayload(company.defaultScenarioId);
    history.push(DASHBOARD_PATH);
  };

  const renderCompanies = () => {
    if (companies.length > 0) {
      return (
        <div className="CompanySelectionPage_CompanyInfoCards">
          {companies.map((company) => (
            <CompanyInfoCard
              key={company.id}
              company={company}
              onSelect={() => onCompanySelect(company)}
            />
          ))}
        </div>
      );
    }

    if (isNoCompanyAvailable) {
      return (
        <div className="CompanySelectionPage_CompanyInfoCards">
          <CompanyRegisterCard onRegister={toggleShowTermsModals} />
        </div>
      );
    }

    return <ComponentLoader loadingComponent="companiesList" paddingTop="0%" />;
  };

  const onConfirm = () => {
    toggleShowTermsModals();
    setIsSidebarOpen(true);
  };

  return (
    <div className="CompanySelectionPage" style={{ marginTop: margin }}>
      <div className="CompanySelectionPage_Content">
        {currentSelectedCompany ? (
          <ProductTypeFilter>
            <span
              className="CompanySelectionPage_AddIcon"
              onClick={toggleShowTermsModals}
              aria-hidden="true"
            >
              {!isNoCompanyAvailable && (
                <>
                  <p className="CompanySelectionPage_AddIcon_Heading">
                    Create a new company
                  </p>
                  <PlusSmIcon
                    className="CompanySelectionPage_AddIcon_Image"
                    aria-hidden="true"
                  />
                </>
              )}
            </span>
          </ProductTypeFilter>
        ) : (
          <span
            className="CompanySelectionPage_AddIcon"
            onClick={toggleShowTermsModals}
            aria-hidden="true"
          >
            {!isNoCompanyAvailable && (
              <>
                <p className="CompanySelectionPage_AddIcon_Heading">
                  Create a new company
                </p>
                <PlusSmIcon
                  className="CompanySelectionPage_AddIcon_Image"
                  aria-hidden="true"
                />
              </>
            )}
          </span>
        )}
        <div className="CompanySelectionPage_FinmarkLogo">
          {subDomain ? (
            <CoBranding resourceHost={resourceHost} subDomain={subDomain} />
          ) : (
            <FinmarkLogo className="FinmarkLogo_Icon" />
          )}
        </div>
        {isNoCompanyAvailable ? (
          <>
            <h2 className="CompanySelectionPage_Heading">Welcome to Finmark</h2>
            <p className="CompanySelectionPage_Info">
              <span>
                You currently do not have access to any companies in Finmark.
              </span>
              <span>
                Register your first company by clicking the ‘Register’ button
                below.
              </span>
            </p>
          </>
        ) : (
          <>
            <h2 className="CompanySelectionPage_Heading">
              Welcome back to Finmark
            </h2>
            <p>Which company would you like to log into?</p>
          </>
        )}
        {renderCompanies()}
      </div>
      <AddUpdateCompanySidebar
        open={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        companyId={OVERRIDE_COMPANY_ID}
      />
      {showTermsModal && (
        <CompanyTermsModal
          onCancel={toggleShowTermsModals}
          onConfirm={onConfirm}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  companies: state.companies.companies,
  loading: state.companies.companiesLoading,
});

export default connect(mapStateToProps, {
  setSelectedCompany: setSelectedCompanyAction,
  setCompaniesOnLogin: setCompaniesOnLoginAction,
  scenarioIdPayload: scenarioIdPayloadAction,
  resetCompanies: resetCompaniesAction,
})(CompanySelectionPage);
