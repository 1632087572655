import {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import DownArrowIcon from '@bill/cashflow.assets/down-arrow';
import RoundCrossIcon from '@bill/cashflow.assets/round-cross';
import {
  addCustomJobTitleAction,
  deleteCustomJobTitleAction,
} from '@/actions/employees';
import {
  CUSTOM_JOB_SELECT,
  MINIMUM_CHARS_IN_JOBTITLE,
  CUSTOM_JOBTITLE_LENGTH_ERROR_MESSAGE,
} from '@/components/Employee/constants';
import Button from '@/components/common/Button';
import IconButton from '@/components/common/IconButton';
import Modal from '@/components/common/Modal';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import WithPopover from '@/components/common/WithPopover';
import { CUSTOM_TITLE_TOOLTIP } from '@/constants/employees';
import { naturalSortComparator } from '@/helpers';
import './JobTitleEditor.scss';

const CustomTitleDialogContent = ({
  departmentId,
  onConfirm,
  scenarioId,
  onClose,
}) => {
  const [customTitle, setCustomTitle] = useState('');
  const [error, setError] = useState(null);
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();

  const handleCustomJobTitle = async () => {
    try {
      const { jobTitleId } = await dispatch(
        addCustomJobTitleAction(scenarioId, {
          departmentId,
          jobTitle: customTitle.trim(),
        }),
      );
      onConfirm(jobTitleId);
    } catch (err) {
      setError(err?.response?.data?.error?.errorMessage);
    }
  };

  const validateCustomJobTitle = (value) => {
    const isMinCharError = value.trim().length < MINIMUM_CHARS_IN_JOBTITLE;
    setError(isMinCharError ? CUSTOM_JOBTITLE_LENGTH_ERROR_MESSAGE : null);
  };

  return (
    <>
      <input
        id="customJobTitle"
        name="customJobTitle"
        onBlur={({ target }) => validateCustomJobTitle(target.value)}
        onChange={({ target }) => setCustomTitle(target.value)}
        placeholder="Enter custom job title here..."
        className="CustomTitleDialog_Field"
      />
      {error && (
        <p
          id="custom-title-error"
          className="FormField_Error"
          data-testid="custom-title-error"
        >
          {error}
        </p>
      )}
      <footer className="CustomTitleDialog_Footer">
        <Button
          className="Button-cancelLink"
          onClick={() => {
            onClose();
          }}
          data-testid="custom-title-cancel-button"
        >
          Cancel
        </Button>
        <Button
          className="Button-primaryLink"
          onClick={() => {
            handleCustomJobTitle();
          }}
          disabled={Boolean(error)}
          data-testid="custom-title-action-button"
        >
          Save
        </Button>
      </footer>
    </>
  );
};

const CustomTitleDialog = ({
  show,
  onClose,
  onConfirm,
  departmentId,
  scenarioId,
}) => {
  return (
    <Modal
      data-testid="custom-title-modal"
      open={show}
      onClose={onClose}
      className="CustomTitleDialog"
    >
      <CustomTitleDialogContent
        onConfirm={onConfirm}
        departmentId={departmentId}
        scenarioId={scenarioId}
        onClose={onClose}
      />
    </Modal>
  );
};
const sortByJobTitle = (a, b) => {
  return naturalSortComparator(a.jobTitle, b.jobTitle);
};
const JobTitleEditor = forwardRef(
  ({ charPress, data, scenarioId, value }, ref) => {
    const input = useRef(null);
    const [selectedValue, setSelectedValue] = useState(value);
    const [show, setShow] = useState(true);
    const [showCustom, setShowCustom] = useState(false);
    /** @type {import('@/store').AppDispatch} */
    const dispatch = useDispatch();
    const titles = useSelector(({ employees }) =>
      employees.allJobTitles
        ?.filter(({ departmentId }) => departmentId === data.departmentId)
        .sort(sortByJobTitle),
    );

    const selectedTitle = useSelector(({ employees }) =>
      employees.allJobTitles?.find(
        ({ jobTitleId }) => jobTitleId === selectedValue,
      ),
    );

    useImperativeHandle(ref, () => ({
      getValue: () => ({
        titleLabel: selectedTitle?.jobTitle,
        titleId: selectedValue ?? null,
      }),
    }));

    useEffect(() => {
      const { current } = input;
      current.focus();

      if (charPress) {
        const title = titles?.find(({ jobTitle }) =>
          jobTitle.toLowerCase().startsWith(charPress.toLowerCase()),
        );
        if (title) setSelectedValue(title.id);
      }
    }, [charPress, titles]);

    const handleCustomTitleDeletion = async (titleId, inUse) => {
      if (!inUse) {
        if (selectedValue === titleId) {
          setSelectedValue(value);
        }
        dispatch(deleteCustomJobTitleAction(scenarioId, titleId));
      }
    };

    return (
      <>
        <WithPopover
          ref={input}
          className="Popover-toEdge"
          visible={show}
          data-testid="jobTitleEditor"
          offset={[-5, 23]}
          placement="bottom-start"
          onClose={() => setShow(false)}
          content={
            <ul className="JobTitleEditor_List">
              {titles?.map(({ jobTitleId, jobTitle, isCustom, isUsed }) => (
                <li key={jobTitleId} className="JobTitleEditor_ListItemWrapper">
                  <button
                    className="JobTitleEditor_ListItem"
                    data-testid={`jobTitleEditor-option${jobTitleId}`}
                    onClick={() => {
                      setSelectedValue(jobTitleId);
                      setShow(false);
                    }}
                  >
                    <TooltipTextOverflow
                      label={jobTitle || 'Select a job title'}
                      placement="top"
                      data-testid="employee-title"
                    />
                  </button>
                  {isCustom && (
                    <>
                      {isUsed ? (
                        <IconButton
                          className="JobTitleEditor_DeleteBtn"
                          disabled
                          label={CUSTOM_TITLE_TOOLTIP}
                          data-testid={`delete-${jobTitleId}`}
                          Icon={RoundCrossIcon}
                        />
                      ) : (
                        <button
                          className="JobTitleEditor_DeleteBtn"
                          onClick={() => {
                            handleCustomTitleDeletion(jobTitleId, isUsed);
                          }}
                          aria-label="Delete"
                        >
                          <RoundCrossIcon aria-hidden="true" />
                        </button>
                      )}
                    </>
                  )}
                </li>
              ))}
              <div
                ref={input}
                className="JobTitleEditor_CustomTitleItem"
                aria-hidden
                onClick={() => {
                  setShowCustom(true);
                  setShow(false);
                }}
              >
                {CUSTOM_JOB_SELECT}
              </div>
            </ul>
          }
        >
          <button
            type="button"
            onClick={() => setShow(true)}
            className="JobTitleEditor"
          >
            <span className="JobTitleEditor_Value">
              {selectedTitle?.jobTitle || 'Select Job Title'}
            </span>
            <DownArrowIcon
              className="FormField_SelectArrow"
              aria-hidden="true"
            />
          </button>
        </WithPopover>
        <CustomTitleDialog
          show={showCustom}
          onConfirm={(titleId) => {
            setShowCustom(false);
            setShow(false);
            setSelectedValue(titleId);
          }}
          onClose={() => {
            setShowCustom(false);
            setShow(true);
          }}
          departmentId={data.departmentId}
          scenarioId={scenarioId}
        />
      </>
    );
  },
);

export default JobTitleEditor;
