// @ts-check
import { useEffect, useMemo } from 'react';
import CircularReference from '@/components/common/CircularReference';
import { cellValueFormatter } from '@/components/common/Spreadsheet/helpers';
import { classNames } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';
import './CircularRefCellRenderer.scss';

/**
 * Render cell value with circular reference icon and tooltip
 *
 * @example
 *   columnDefs={[
 *    {
 *      ...,
 *      cellRenderer: CircularRefCellRenderer
 *    }
 *   ]}
 *
 * @type {(
 *   params: import('ag-grid-community').ICellRendererParams<any, number>,
 * ) => React.ReactElement}
 */
const CircularRefCellRenderer = ({
  value,
  valueFormatted,
  data,
  eGridCell,
}) => {
  useEffect(() => {
    eGridCell.classList.add('Spreadsheet_Cell-invalid');
  }, [eGridCell]);

  const isNested = useMemo(
    () =>
      isEmptyOrNull(data.months) ||
      data.months.some(
        (item) => Array.isArray(item.value) || !!item.value?.value,
      ),
    [data.months],
  );

  let dataValue = cellValueFormatter({ value, data });
  if (!isNested) {
    dataValue = data.name;
  } else if (!isEmptyOrNull(valueFormatted)) {
    dataValue = valueFormatted;
  }

  return (
    <div
      className={classNames(
        'CircularRefCellRenderer',
        !isNested && 'CircularRefCellRenderer_Name',
      )}
    >
      <span className="CircularRefCellRenderer_Value">{dataValue}</span>
      <CircularReference />
    </div>
  );
};

export default CircularRefCellRenderer;
