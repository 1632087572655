// @ts-check
import { useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setInvitedUserAction } from '@/actions/scenario';
import getScenarioText from '@/components/Scenarios/helpers';
import Button from '@/components/common/Button';
import FormField from '@/components/common/FormField';
import InfoTooltip from '@/components/common/InfoTooltip';
import LetterIcon from '@/components/common/LetterIcon';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';
import Select from '@/components/common/Select';
import {
  ScenarioRoles,
  ScenarioRolesText,
  scenarioTypesText,
} from '@/constants/scenario';
import { classNames, slugify } from '@/helpers';
import { validateEmail } from '@/helpers/validators';
import { shareScenario } from '@/services/scenario.service';

/**
 * @type {(
 *   selectedRole: (typeof ScenarioRoles)[keyof typeof ScenarioRoles],
 *   scenarioTypeText: import('@/constants/scenario').scenarioTypesText,
 * ) => string}
 */
const getTooltipText = (selectedRole, scenarioTypeText) => {
  switch (selectedRole) {
    case ScenarioRoles.DASHBOARD_ONLY:
      return `Can only view the dashboard of the ${scenarioTypeText}`;
    case ScenarioRoles.VIEWER:
      return `Can view all areas of the ${scenarioTypeText}`;
    case ScenarioRoles.EDITOR:
      return `Can view and edit all areas of the ${scenarioTypeText}`;
    default:
      throw new Error('Unknown role selected');
  }
};

const ScenarioShareModal = ({
  handleClose,
  selectedScenarioId,
  scenarios,
  handleAction,
  userId,
  setInvitedUserAction: setInvitedUser,
}) => {
  const [selectedRole, setSelectedRole] = useState(
    ScenarioRoles.DASHBOARD_ONLY,
  );
  /** @type {ReturnType<typeof useState<string>>} */
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const selectedScenario = scenarios.find(
    (scenario) => scenario.scenarioId === selectedScenarioId,
  );

  const scenarioTypeText = scenarioTypesText[selectedScenario.type];

  const handleSave = async () => {
    if (!email || !selectedRole) return;
    setLoading(true);
    setError('');
    try {
      await shareScenario({
        scenarioId: selectedScenarioId,
        inviterId: userId,
        email,
        scenarioRole: selectedRole,
      });
      setInvitedUser({ name: selectedScenario.name, email });
      handleAction();
    } catch (e) {
      setError(e.response?.data?.error?.errorMessage || e.message);
    } finally {
      setLoading(false);
    }
  };

  const checkValid = (typedEmail) => {
    const isValid = validateEmail(typedEmail);
    return !isValid && 'Please enter a valid email!';
  };

  return (
    <ModalBase
      id="Share-Modal"
      header={
        <h2 className="ShareScenario_Heading">Share {scenarioTypeText}</h2>
      }
      footer={
        <>
          <Button
            className="Button ShareScenario_Button Button-cancelLink"
            onClick={handleClose}
            data-testid="share-scenario-cancel-button"
          >
            Cancel
          </Button>
          <Button
            className={classNames('Button', 'ShareScenario_Button')}
            onClick={handleSave}
            data-testid="share-scenario-action-button"
            loading={loading}
            disabled={!validateEmail(email) || !selectedRole}
          >
            Share {getScenarioText(selectedScenario.type)}
          </Button>
        </>
      }
    >
      <div>
        <div className="ShareScenario-HeaderContainer">
          <LetterIcon
            string={selectedScenario.name}
            color={selectedScenario.color}
            data-testid={`letter-icon-${slugify(selectedScenario.name)}`}
          />
          <h3 className="ml-2 mb-0 ShareScenario_SubHeading">
            {selectedScenario.name}
          </h3>
        </div>
        <p className="ShareScenario_Text">
          Please enter the email address and permission level for the person you
          would like to share this {scenarioTypeText.toLowerCase()} with.
        </p>
        {error && <Alert variant="warning">{error}</Alert>}
        <Form.Group>
          <label htmlFor="email" className="Label">
            Email Address
          </label>
          <Form.Group className="form-group-inline ml-0">
            <FormField
              id="email"
              type="email"
              name="email"
              maxLength={50}
              validate={checkValid}
              placeholder="Enter email address..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
        </Form.Group>
        <Form.Group className="w-50">
          <label
            htmlFor="roleSelect"
            className="Label d-flex justify-content-between"
          >
            {getScenarioText(selectedScenario.type)} Permissions
            <InfoTooltip data-testid="permissions-tooltip">
              {getTooltipText(selectedRole, scenarioTypeText.toLowerCase())}
            </InfoTooltip>
          </label>
          <Select
            id="roleSelect"
            name="roleSelect"
            validate={(e) => (!e ? 'Please select permissions' : '')}
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
          >
            {Object.keys(ScenarioRoles).map((key) => (
              <option key={key} value={key}>
                {ScenarioRolesText[key]}
              </option>
            ))}
          </Select>
        </Form.Group>
      </div>
    </ModalBase>
  );
};

const mapStateToProps = ({ auth }) => ({
  userId: auth.userInfo.userId,
});

ScenarioShareModal.propTypes = {
  /** A func used to close the modal */
  handleClose: PropTypes.func.isRequired,
  /** A func used to close the modal on action that show the success message */
  handleAction: PropTypes.func.isRequired,
  /** An array of Scenarios */
  scenarios: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      companyId: PropTypes.number.isRequired,
      isDefault: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  /** The selected scenario id */
  selectedScenarioId: PropTypes.number,
  /** Current user id */
  userId: PropTypes.number.isRequired,
  /** function to set invited user information on success */
  setInvitedUserAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setInvitedUserAction })(
  ScenarioShareModal,
);
