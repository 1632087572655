// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { CASH_BALANCE } from '@/cacheKeys';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import useChartQuery from '@/hooks/useChartQuery';
import useScenarioQueries from '@/hooks/useScenarioQueries';
import CASH_BALANCE_METRIC from '@/pages/Dashboard/constants/cashBalance';
import { getCashBalanceData } from '@/services/dashboard.service';

const MAIN_METRIC = 'Cash Balance';

const reducer = ({
  cashBalances,
  previousPeriodTotal,
  previousPeriodLabel,
}) => {
  return {
    previousPeriodTotal,
    previousPeriodLabel,
    ...mapMonthlyData(cashBalances, 'endingCash'),
  };
};

/**
 * @typedef {{
 *   isLoading?: boolean;
 *   data: {
 *     totalCashBalance: number;
 *   };
 * }} QueryStateChangeData
 */

/**
 * @typedef {{
 *   className?: string;
 *   plotOptions?: Highcharts.PlotOptions;
 *   axisStyles?: Highcharts.CSSObject | {};
 *   onQueryStateChange?: (
 *     data: import('@/hooks/useScenarioQueries').QueryStateChangeData[],
 *   ) => void;
 *   tooltipOptions?: Object;
 *   exportBtn: Object;
 * }} CashBalanceChart
 */

/**
 * Fetches and renders a column chart showing monthly NetCash flow
 *
 * @example
 *   <NetCashFlowChart exportBtn={exportBtn} />;
 *
 * @type {(props: NetCashFlowChart) => React.ReactElement}
 */
function CashBalanceChart({
  className,
  exportBtn,
  plotOptions,
  axisStyles,
  onQueryStateChange,
  tooltipOptions,
}) {
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );
  const scenarioQueries = useChartQuery(
    CASH_BALANCE,
    getCashBalanceData,
    reducer,
  );
  useScenarioQueries(scenarioQueries, onQueryStateChange);

  return (
    <DateChart
      className={className}
      data-testid="cashBalanceChart"
      tooltip={
        <ChartTooltip
          metrics={CASH_BALANCE_METRIC}
          endDate={endDate}
          timePeriod={timePeriod}
          {...tooltipOptions}
        />
      }
      loading={scenarioQueries.every(({ isLoading }) => isLoading)}
      plotOptions={plotOptions}
      axisStyles={axisStyles}
      ref={exportBtn}
      startDate={startDate}
      endDate={endDate}
      timePeriod={timePeriod}
    >
      {scenarioQueries.map(
        ({ data, scenario }, idx) =>
          data && (
            <DateChart.Series
              key={scenario.scenarioId}
              data={data.data}
              index={idx}
              metric={MAIN_METRIC}
              scenario={scenario}
              isComparison={idx === 1}
            />
          ),
      )}
    </DateChart>
  );
}

export default CashBalanceChart;
