import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { subscribeToEmployeeUpdateAction } from '@/actions/employees';
import { CUSTOMER_LTV } from '@/cacheKeys';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import metricFormatters from '@/helpers/metricFormatters';
import useChartQuery from '@/hooks/useChartQuery';
import useWsSubscription from '@/hooks/useWsSubscription';
import { getCustomerLTV } from '@/services/dashboard.service';

const MAIN_METRIC = 'LTV';

const CUSTOMER_LTV_CURRENCY_TOOLTIP_METRICS = [
  {
    name: 'Gross Margin',
    key: 'grossMargin',
    formatter: metricFormatters.percentLegacy,
  },
  {
    name: 'Average Customer Lifespan',
    key: 'averageCustomerLifeSpan',
    formatter: metricFormatters.months,
  },
  {
    name: MAIN_METRIC,
    isMainMetric: true,
    key: 'y',
  },
];

const reducer = ({ currentMonth, currentMonthValue, months }) => {
  return {
    currentMonth,
    currentMonthValue,
    ...mapMonthlyData(months, 'customerLTV'),
  };
};

/**
 * Fetches and renders a line chart showing Customerltv
 *
 * @example
 *   <CustomerLTVChart exportBtn={exportBtn} />;
 */
function CustomerLTVChart({
  className,
  exportBtn,
  plotOptions,
  axisStyles,
  tooltipOptions,
  onQueryStateChange,
}) {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );

  const scenarioQueries = useChartQuery(CUSTOMER_LTV, getCustomerLTV, reducer);

  const [base] = scenarioQueries;

  useEffect(() => {
    if (base) onQueryStateChange?.(base);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [base]);

  useWsSubscription(
    () =>
      dispatch(
        subscribeToEmployeeUpdateAction(scenarioId, () => {
          base.refetch();
        }),
      ),
    [scenarioId],
  );

  return (
    <DateChart
      className={className}
      data-testid="customerLTVChart"
      loading={scenarioQueries.every(({ isLoading }) => isLoading)}
      tooltip={
        <ChartTooltip
          metrics={CUSTOMER_LTV_CURRENCY_TOOLTIP_METRICS}
          endDate={endDate}
          timePeriod={timePeriod}
          {...tooltipOptions}
        />
      }
      plotOptions={plotOptions}
      axisStyles={axisStyles}
      ref={exportBtn}
      startDate={startDate}
      endDate={endDate}
      timePeriod={timePeriod}
    >
      {scenarioQueries.map(
        ({ data, scenario }, idx) =>
          data && (
            <DateChart.Series
              key={scenario.scenarioId}
              data={data.data}
              index={idx}
              metric={MAIN_METRIC}
              scenario={scenario}
              isComparison={idx === 1}
            />
          ),
      )}
    </DateChart>
  );
}

export default CustomerLTVChart;
