import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  clearStream as clearStreamAction,
  getProductsAction,
  setRevenueModalError,
} from '@/actions/revenue';
import StepThreeSubscriptionRevenue from '@/components/Revenue/RevenueStream/Steps/StepThreeSubscriptionRevenue';
import FormulaGuideModal from '@/components/common/FormulaGuide/FormulaGuideModal';
import Modal from '@/components/common/Modal';
import { ONE_TIME_INDEX } from '@/constants/revenue';
import {
  DRIVER,
  REVENUE_ONLY,
  SUBSCRIPTION,
  ONE_TIME,
  ADVANCED_SUBSCRIPTION,
} from '@/constants/revenueStream';
import useViewOnlyMode from '@/hooks/useViewOnlyMode';
import RevenueStreamWizard from './RevenueStreamWizard';
import StepFour from './Steps/StepFour';
import StepOne from './Steps/StepOne';
import StepThree from './Steps/StepThree';
import StepTwoPricingPlan from './Steps/StepTwoPricingPlan';
import StepTwoRevenueOnly from './Steps/StepTwoRevenueOnly';
import './AddRevenueStreamModal.scss';

const Steps = ({
  step,
  revenueType,
  showAddProductNotice,
  subscriptionPricingPlans,
  setShowAddProductNotice,
  oneTimePricingPlans,
  onAddProduct,
  ...props
}) => {
  const stepProps = { step, ...props };
  switch (true) {
    case step === 1:
      return (
        <StepOne
          showAddProductNotice={showAddProductNotice}
          subscriptionPricingPlans={subscriptionPricingPlans}
          setShowAddProductNotice={setShowAddProductNotice}
          oneTimePricingPlans={oneTimePricingPlans}
          onAddProduct={onAddProduct}
          {...stepProps}
        />
      );
    case step === 2 && revenueType === REVENUE_ONLY:
      return <StepTwoRevenueOnly {...stepProps} />;
    case step === 2:
      return <StepTwoPricingPlan {...stepProps} />;
    case step === 3 && revenueType === ADVANCED_SUBSCRIPTION:
      return <StepThreeSubscriptionRevenue {...stepProps} />;
    case step === 3:
      return <StepThree {...stepProps} />;
    case step === 4:
      return <StepFour {...stepProps} />;
    default:
      throw new Error('unknown step');
  }
};

/** @type {React.FC<{ [key: string]: any }>} */
const AddRevenueStreamModal = ({
  show,
  mode,
  getProducts,
  handleClose,
  revenueStream,
  clearStream,
  recordIdForEdit,
  revenueStreamsList,
  pricePlansList,
  showAddProductModal,
  handleRevenueStreamOperation,
  addProductFromRevenue,
  setAddProductFromRevenue,
  scenarioId,
  setShowAddProductModal,
  onSaved,
}) => {
  const [step, setStep] = useState(1);
  const [saving, setSaving] = useState(false);

  const [showFormulaGuideModal, setShowFormulaGuideModal] = useState(false);
  const [showAddProductNotice, setShowAddProductNotice] = useState(false);
  const [oneTimePricingPlans, setOneTimePricingPlans] = useState([]);
  const [subscriptionPricingPlans, setSubscriptionPricingPlans] = useState([]);
  const [persistModalData, setPersistModalData] = useState(false);

  const modalBodyRef = useRef(null);
  const isViewOnly = useViewOnlyMode(modalBodyRef);

  useEffect(() => {
    getProducts(scenarioId);
  }, [getProducts, scenarioId]);

  useEffect(() => {
    if (!pricePlansList.length) return;

    setOneTimePricingPlans(
      pricePlansList.filter(
        (pricingPlan) => pricingPlan.frequency === ONE_TIME_INDEX,
      ),
    );
    setSubscriptionPricingPlans(
      pricePlansList.filter(
        (pricingPlan) => pricingPlan.frequency !== ONE_TIME_INDEX,
      ),
    );
  }, [pricePlansList]);

  useEffect(() => {
    setSaving(false);
    if (!persistModalData) {
      setStep(1);
      clearStream();
    }
  }, [clearStream, persistModalData, show]);

  const previousStep = () => {
    setStep(step - 1);
  };

  const { revenueType } = revenueStream;
  const onCloseModal = (emptyPersist = false) => {
    if (!emptyPersist) {
      setPersistModalData(false);
    }
    setShowAddProductNotice(false);
    handleClose();
  };

  const revenue = revenueStreamsList?.products.find(
    ({ id }) => Number(id) === Number(revenueStream.id),
  );

  const getModalSize = () => {
    const isRevenueOnlyStep2 = revenueType === REVENUE_ONLY && step === 2;
    const isStep3 = step === 3;
    if (
      (isRevenueOnlyStep2 || isStep3) &&
      revenueStream.driverType === DRIVER.CUSTOM_GROWTH
    ) {
      return 'RevenueStream_Modal-Large';
    }
    return 'RevenueStream_Modal';
  };

  const stepProps = {
    mode,
    recordIdForEdit,
    revenue,
    step,
    saving,
    setStep,
    isViewOnly,
    handleClose: onCloseModal,
    onFormulaGuideClick: () => setShowFormulaGuideModal(true),
    onSaved,
  };

  const onAddProduct = () => {
    if (showAddProductNotice) {
      setPersistModalData(true);
      onCloseModal(true);
      setShowAddProductModal(true);
      setAddProductFromRevenue(true);

      return;
    }

    if (
      ([SUBSCRIPTION, ADVANCED_SUBSCRIPTION].includes(
        revenueStream.revenueType,
      ) &&
        !subscriptionPricingPlans.length) ||
      (revenueStream.revenueType === ONE_TIME && !oneTimePricingPlans.length)
    ) {
      setShowAddProductNotice(true);
    } else {
      setStep(step + 1);
    }
  };

  useEffect(() => {
    if (
      persistModalData &&
      !showAddProductModal &&
      ((revenueStream.revenueType === SUBSCRIPTION &&
        subscriptionPricingPlans.length) ||
        (revenueStream.revenueType === ONE_TIME &&
          oneTimePricingPlans.length) ||
        addProductFromRevenue)
    ) {
      handleRevenueStreamOperation(mode);
      if (step === 1) {
        onAddProduct();
      }
      setAddProductFromRevenue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [
    show,
    oneTimePricingPlans.length,
    revenueStream.revenueType,
    subscriptionPricingPlans.length,
    showAddProductModal,
    addProductFromRevenue,
  ]);

  return (
    <>
      {[SUBSCRIPTION, ONE_TIME, REVENUE_ONLY].includes(revenueType) && (
        <FormulaGuideModal
          formulaKey={revenueType}
          open={showFormulaGuideModal}
          data-testid={`${revenueType}-guide-modal`}
          onClose={() => setShowFormulaGuideModal(false)}
        />
      )}

      <Modal
        open={show}
        onClose={() => onCloseModal()}
        className={getModalSize()}
        data-testid="add-revenue-stream-modal"
      >
        <div ref={modalBodyRef}>
          <RevenueStreamWizard
            previousStep={previousStep}
            mode={mode}
            step={step}
            revenueType={revenueType}
            driverType={revenueStream.driverType}
            revenueName={revenueStream.revenueName}
            isViewOnly={isViewOnly}
          >
            {/* Name and Type */}
            <Steps
              step={step}
              revenueType={revenueType}
              showAddProductNotice={showAddProductNotice}
              subscriptionPricingPlans={subscriptionPricingPlans}
              setShowAddProductNotice={setShowAddProductNotice}
              oneTimePricingPlans={oneTimePricingPlans}
              onAddProduct={onAddProduct}
              {...stepProps}
            />
          </RevenueStreamWizard>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  pricePlansList: state.revenues.pricePlansList,
  revenueStream: state.revenues.revenueStream,
  revenueStreamsList: state.revenues.revenueStreamsList,
  scenarioId: state.scenario.scenarioId,
  globalStartDate: state.shared.startDate,
  globalEndDate: state.shared.endDate,
});

export default connect(mapStateToProps, {
  clearStream: clearStreamAction,
  getProducts: getProductsAction,
  setRevenueModalError,
})(AddRevenueStreamModal);
