import './ListItem.scss';

/**
 * @typedef {{
 *   Icon: React.FC<React.SVGProps<SVGSVGElement>>;
 *   title: import('@/constants/console').ConsoleSidebarTitle;
 *   count: number;
 * }} ConsoleListItemParams
 */

/**
 * Renders the title and context buttons for the management console
 *
 * @example
 *   <ConsoleListItem Icon={Icon} title={title} />;
 *
 * @type {(params: ConsoleListItemParams) => React.ReactElement}
 */

const ConsoleListItem = ({ Icon, title, count = 0 }) => {
  return (
    <div className="ConsoleListItem">
      <Icon className="ConsoleListItem_Icon" aria-hidden="true" />
      <h5 className="ConsoleListItem_Title">{title}</h5>
      <span className="ConsoleListItem_Count">{count}</span>
    </div>
  );
};

export default ConsoleListItem;
