import { useEffect, useCallback } from 'react';
import { YAxis } from 'react-jsx-highcharts';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { LOGO_CHURN } from '@/cacheKeys';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import { LABEL_STYLES } from '@/components/Charts/chartDefaults';
import {
  getYAxisConfigNumber,
  getYAxisConfigPercent,
} from '@/components/Charts/helpers';
import { INVALID_POP_VALUE_TOOLTIP_TEXT } from '@/constants/charts';
import { formatPercent } from '@/helpers';
import formatWholeNumber from '@/helpers/formatWholeNumber';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import metricFormatters from '@/helpers/metricFormatters';
import { isEmptyOrNull } from '@/helpers/validators';
import useChartQuery from '@/hooks/useChartQuery';
import { getLogoChurn } from '@/services/dashboard.service';

export const LOGO_CHURN_TOGGLE_ID = 'logoChurn-toggle';

const MAIN_METRIC = 'Logo Churn';
const PERCENTAGE_MAIN_METRIC = 'Logo Churn %';

const metrics = [
  {
    key: 'newCustomers',
    name: 'New Customers',
    formatter: metricFormatters.count,
  },
  {
    key: 'netNewCustomers',
    name: 'Net New Customers',
    formatter: metricFormatters.count,
  },
  {
    key: 'y',
    isMainMetric: true,
    name: MAIN_METRIC,
    formatter: metricFormatters.count,
  },
];

const percentageMetrics = [
  {
    key: 'y',
    isMainMetric: true,
    name: PERCENTAGE_MAIN_METRIC,
    formatter: metricFormatters.percentLegacy,
  },
  {
    key: 'churnedCustomers',
    name: 'Current Logo Churn',
    formatter: metricFormatters.count,
  },
  {
    key: 'beginningCustomers',
    name: 'Prior Total Customers',
    formatter: metricFormatters.count,
  },
];

const getYAxis = (showPercent) => {
  return showPercent ? getYAxisConfigPercent() : getYAxisConfigNumber();
};

/**
 * Fetches and renders a line chart showing logo churn
 *
 * @example
 *   <LogoChurnChart exportBtn={exportBtn} />;
 */
function LogoChurnChart({
  className,
  exportBtn,
  plotOptions,
  axisStyles,
  tooltipOptions,
  showPercent,
  onQueryStateChange,
}) {
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );

  const reducer = useCallback(
    ({ totalChurnCustomers, compoundPeriodGrowthRate, churnCustomersData }) => {
      let mainMetric = 'N/A';
      let tooltipText;

      if (!showPercent) {
        mainMetric = formatWholeNumber(totalChurnCustomers);
      } else if (!isEmptyOrNull(compoundPeriodGrowthRate)) {
        mainMetric = formatPercent(compoundPeriodGrowthRate);
      } else {
        tooltipText = INVALID_POP_VALUE_TOOLTIP_TEXT;
      }

      return {
        mainMetric,
        tooltipText,
        ...mapMonthlyData(
          churnCustomersData,
          showPercent ? 'periodOverPeriodPercentage' : 'churnedCustomers',
        ),
      };
    },
    [showPercent],
  );

  const scenarioQueries = useChartQuery(LOGO_CHURN, getLogoChurn, reducer);

  const [base] = scenarioQueries;

  useEffect(() => {
    if (base) onQueryStateChange?.(base);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [base]);

  const yAxisConfigs = {
    ...getYAxis(showPercent),
    labels: {
      ...getYAxis(showPercent).labels,
      style: axisStyles || LABEL_STYLES,
    },
  };

  return (
    <DateChart
      className={className}
      data-testid="logoChart"
      loading={scenarioQueries.every(({ isLoading }) => isLoading)}
      tooltip={
        <ChartTooltip
          metrics={showPercent ? percentageMetrics : metrics}
          valueFormatter={
            showPercent
              ? metricFormatters.percentLegacy
              : metricFormatters.count
          }
          endDate={endDate}
          timePeriod={timePeriod}
          {...tooltipOptions}
        />
      }
      plotOptions={plotOptions}
      axisStyles={axisStyles}
      ref={exportBtn}
      startDate={startDate}
      endDate={endDate}
      timePeriod={timePeriod}
    >
      <YAxis {...yAxisConfigs}>
        {scenarioQueries.map(
          ({ data, scenario }, idx) =>
            data && (
              <DateChart.Series
                key={scenario.scenarioId}
                data={data.data}
                index={idx}
                metric={MAIN_METRIC}
                scenario={scenario}
                isComparison={idx === 1}
              />
            ),
        )}
      </YAxis>
    </DateChart>
  );
}

export default LogoChurnChart;
