import {
  useEffect,
  useState,
  forwardRef,
  useMemo,
  useRef,
  useLayoutEffect,
} from 'react';
import { connect } from 'react-redux';
import { getSystemVariablesAction } from '@/actions/variables';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import { EMPTY_CELL_VALUE } from '@/components/common/Spreadsheet/constants';
import { expandFirstGroup } from '@/components/common/Spreadsheet/helpers';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import { units } from '@/constants/variables';
import { debounce } from '@/helpers';
import metricFormatters from '@/helpers/metricFormatters';
import { isEmptyOrNull } from '@/helpers/validators';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import ContextMenuRenderer from '@/pages/Variables/renderers/ContextMenuRenderer';
import { createChart as createCustomVariableChart } from '@/services/dashboard.service';
import './SystemVariablesList.scss';

const SPREADSHEET_ID = 'system-variables-list';

const DEFAULT_METRIC = 'AverageOfDateRange';

const filterParams = { debounceMs: 250 };

function handleFirstRender({ api, columnApi }) {
  expandFirstGroup(api);

  const variableCol = columnApi
    .getColumns()
    .find((col) => col.colId === 'variable');
  columnApi.autoSizeColumn(variableCol);
}

function valueFormatter({ value, data: { variable } }) {
  if (isEmptyOrNull(value)) {
    return EMPTY_CELL_VALUE;
  }

  return variable.unit === units.NUMBER
    ? metricFormatters.count(value)
    : metricFormatters.monetary(value);
}

const SystemVariablesList = forwardRef(
  (
    {
      dashboardLayoutId,
      scenarioId,
      startDate,
      endDate,
      systemVariables,
      getSystemVariables,
      systemVariablesLoading,
      onChartCreated,
      onChartError,
    },
    ref,
  ) => {
    const systemVariablesRef = useRef();
    const [hasBeenLoaded, setHasBeenLoaded] = useState(false);
    const [height, setHeight] = useState(null);

    const handleResize = useMemo(
      () =>
        debounce(() => {
          const elemRect = systemVariablesRef.current.getBoundingClientRect();
          const bodyRect = document.body.getBoundingClientRect();
          const fromTop = window.pageYOffset + elemRect.top;
          const fromBottom = bodyRect.height - fromTop - elemRect.height;
          setHeight(`calc(100vh - ${fromTop}px - ${fromBottom}px)`);
        }, 100),
      [],
    );

    const hasWritePermission = useNonDashboardWritePermission();

    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
    useLayoutEffect(() => handleResize(), []);

    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    useEffect(() => {
      if (systemVariables.length > 0) {
        setHasBeenLoaded(true);
      }
      getSystemVariables(startDate, endDate, scenarioId);
      // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
    }, [scenarioId, startDate, endDate, getSystemVariables, setHasBeenLoaded]);

    const colDefs = useMemo(
      () => {
        const columns = [
          {
            field: 'variable',
            headerName: 'Name',
            valueGetter: ({ data }) => data?.variable.name,
            filterParams,
            headerComponent: HeaderRenderer,
            headerComponentParams: {
              enableExpandAll: true,
            },
            width: 450,
          },
        ];
        if (hasWritePermission) {
          columns.push({
            colId: 'actions',
            type: 'actions',
            cellRenderer: ContextMenuRenderer,
            cellRendererParams: {
              onCreateClick: async ({ id }, chartType) => {
                try {
                  const { data } = await createCustomVariableChart(
                    scenarioId,
                    dashboardLayoutId,
                    {
                      systemVariableId: id,
                      scenarioId,
                      metadata: {
                        chartType,
                        mainMetric: DEFAULT_METRIC,
                      },
                    },
                  );
                  onChartCreated(data);
                } catch (e) {
                  onChartError(e);
                }
              },
            },
          });
        }
        return columns;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
      [scenarioId, hasWritePermission],
    );

    return (
      <div
        data-testid="system-variables-table"
        className="SystemVariablesList"
        ref={systemVariablesRef}
        style={{ height }}
      >
        <SpreadsheetToolbar editable={false}>
          Options: <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
        </SpreadsheetToolbar>
        <MonthlySpreadsheet
          ref={ref}
          columnDefs={colDefs}
          domLayout="normal"
          data-testid={SPREADSHEET_ID}
          data={systemVariables}
          getRowId={({ data }) => data.variable.id}
          editable={false}
          groupBy={({ data }) => data.categoryName}
          groupDefaultExpanded={0}
          loading={systemVariablesLoading && !hasBeenLoaded}
          valueFormatter={valueFormatter}
          filterParams={filterParams}
          onFirstDataRendered={handleFirstRender}
          suppressColumnVirtualisation
        />
      </div>
    );
  },
);

const mapStateToProps = ({
  dashboard,
  shared,
  scenario,
  variables,
  componentLoading,
  auth,
}) => ({
  dashboardLayoutId: dashboard.selectedDashboardId,
  scenarioId: scenario.scenarioId,
  startDate: shared.startDate,
  endDate: shared.endDate,
  systemVariables: variables.systemVariables,
  systemVariablesLoading: componentLoading.systemVariables,
  userPreferences: auth.preferences,
});

export default connect(
  mapStateToProps,
  {
    getSystemVariables: getSystemVariablesAction,
  },
  null,
  { forwardRef: true },
)(SystemVariablesList);
