import { useMemo } from 'react';
import PropTypes from 'prop-types';
import DateChart from '@/components/Charts/DateChart/DateChart';
import { getBandedGradient } from '@/components/Charts/colors';
import { chartTypes } from '@/components/Charts/constants';

const DATA_DEFAULT = [];

/**
 * Renders stacked columns in a DateChart for the given scenario
 *
 * @example
 *   <DateChart>
 *     <DateChart.StackedColumnSeries data={data} scenario={scenario} />
 *   </DateChart>;
 */
function DateChartStackedColumnSeries({
  colors,
  data = DATA_DEFAULT,
  scenario,
  ...props
}) {
  const steppedColors = useMemo(
    () => getBandedGradient(scenario?.color, data.length),
    [data.length, scenario],
  );

  return data.map((series, idx) => {
    const id = series.id ?? series.name;
    const color = colors?.[id] ?? steppedColors[idx];

    return (
      <DateChart.Series
        key={series.name}
        type={chartTypes.COLUMN}
        color={color}
        index={idx}
        metric={series.name}
        data={series.data}
        scenario={scenario}
        {...props}
      />
    );
  });
}
DateChartStackedColumnSeries.displayName = 'DateChart.StackedColumnSeries';

DateChartStackedColumnSeries.propTypes = {
  /** Data to populate the series */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number.isRequired,
          y: PropTypes.number,
        }),
      ).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  /** Scenario of the data represented by the series */
  scenario: PropTypes.shape({
    color: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export default DateChartStackedColumnSeries;
