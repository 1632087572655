import WarningIcon from '@bill/cashflow.assets/warning';
import './LegendWarning.scss';

/**
 * Show a warning when users view Actuals in a time period other than monthly
 *
 * @example
 *   <LegendWarning />;
 */
const LegendWarning = () => (
  <>
    <WarningIcon className="SpreadsheetToolbar_LegendReadonlyIcon" />{' '}
    <p
      className="SpreadsheetToolbar_LegendReadonly"
      data-testid="spreadsheet-toolbar_legend-warning"
    >
      Values can only be edited in monthly views.
    </p>
  </>
);

export default LegendWarning;
