// @ts-check
import { useCallback, useEffect, useState } from 'react';
import CalendarMonthSelectedIcon from '@bill/cashflow.assets/calendar-month-selected';
import PropTypes from 'prop-types';
import Calendar from '@/components/common/Calendar/Calendar';
import MaskedFormField from '@/components/common/MaskedFormField';
import WithPopover from '@/components/common/WithPopover';

function formatTimestamp(timestamp) {
  return timestamp
    ? new Date(timestamp).toLocaleDateString('en-US', {
        month: '2-digit',
        year: 'numeric',
        timeZone: 'UTC',
      })
    : null;
}

function createUtcDateFromMonthYear(dateStr) {
  const [month, year] = dateStr.split('/');
  return Date.UTC(year, month - 1);
}

const MASK_OPTIONS = {
  alias: 'datetime',
  inputFormat: 'mm/yyyy',
};

const prefix = <CalendarMonthSelectedIcon className="FormField_PrefixIcon" />;

/**
 * @typedef {Override<
 *   import('@/components/common/FormField').FormFieldProps,
 *   {
 *     onChange: (selectedDate: number | null) => void;
 *     appendTo?: HTMLElement;
 *     value: number | null;
 *     max?: number;
 *     min?: number;
 *     validate?: (dateStr: string) => string;
 *   }
 * >} MonthFieldProps
 */

/**
 * Renders an input field for accepting months in the format MM/YYYY, with a
 * popover calendar for easy selection.
 *
 * @example
 *   <MonthField
 *     id="foo"
 *     value={date}
 *     onChange={(newDate) => setDate(newDate)}
 *   />;
 *
 * @type {(props: MonthFieldProps) => React.ReactElement}
 */
function MonthField({
  className,
  'data-testid': dataTestId,
  value,
  id,
  min,
  max,
  onChange,
  validate,
  ...props
}) {
  const [show, setShow] = useState(false);
  const [calendarValue, setCalendarValue] = useState(value);

  useEffect(() => {
    setCalendarValue(value);
  }, [value]);

  const selectDate = (newDate) => {
    setShow(false);
    onChange(newDate);
  };

  const handleChange = useCallback(
    (newValue) => {
      let newDateMs;
      if (newValue) {
        newDateMs = createUtcDateFromMonthYear(newValue);
      }
      onChange(newDateMs);
    },
    [onChange],
  );

  const handleFocus = useCallback(() => setShow(true), []);

  const handleKeyDown = useCallback(
    ({ code }) => code === 'Tab' && setShow(false),
    [],
  );

  const handleValidate = useCallback(
    (dateStr) => {
      if (dateStr) {
        const newDateMs = createUtcDateFromMonthYear(dateStr);
        if (Number.isNaN(newDateMs)) {
          return 'Please enter a valid date';
        }

        if (min && newDateMs < min) {
          return `Date must be on or after ${formatTimestamp(min)}`;
        }

        if (max && newDateMs > max) {
          return `Date must be on or before ${formatTimestamp(max)}`;
        }
      }

      return validate?.(dateStr);
    },
    [max, min, validate],
  );

  const testId = dataTestId ?? id;

  return (
    <div className={className}>
      <WithPopover
        className="Popover-toEdge"
        content={
          <Calendar
            value={calendarValue}
            view="year"
            onChange={selectDate}
            min={min}
            max={max}
            data-testid={`${testId}-calendar`}
          />
        }
        onClose={() => setShow(false)}
        placement="bottom"
        visible={show}
        data-testid={`${testId}-popover`}
      >
        <div>
          <MaskedFormField
            data-testid={dataTestId}
            id={id}
            prefix={prefix}
            value={value ? formatTimestamp(value) : null}
            maskOptions={MASK_OPTIONS}
            placeholder="Select Month"
            onChange={handleChange}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            validate={handleValidate}
            {...props}
          />
        </div>
      </WithPopover>
    </div>
  );
}

MonthField.propTypes = {
  /** Additional class(es) to apply to the wrapper element */
  'className': PropTypes.string,
  'data-testid': PropTypes.string,
  /**
   * A unique ID to associate the field with its label, and to select it in
   * unit/integration tests
   */
  'id': PropTypes.string.isRequired,
  /** The furthest date that should be selectable in the calendar, as a timestamp */
  'max': PropTypes.number,
  /** The earliest date that should be selectable in the calendar, as a timestamp */
  'min': PropTypes.number,
  /**
   * Event handler called when the user selects a date from the calendar or
   * types a complete date the input field
   *
   * @param {string} selectedDate
   */
  'onChange': PropTypes.func.isRequired,
  /**
   * Additional validation to perform on the input
   *
   * @param {string} dateStr
   * @returns {string} An error message, or undefined
   */
  'validate': PropTypes.func,
  /** The current value of the field, as a timestamp */
  'value': PropTypes.number,
};

export default MonthField;
