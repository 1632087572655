import ContextMenu from '@/components/common/ContextMenu';

export default function ContextMenuRenderer({
  data,
  onEdit,
  family,
  hasWritePermission,
  onDelete,
  isDeleteDisabled,
}) {
  const { id, name } = data;
  return (
    <ContextMenu data-testid={`edit-${family}-${name}-editBtn`}>
      <ContextMenu.Option
        data-testid={`edit-${family}-${name}-action`}
        onClick={() => onEdit(id)}
      >
        {hasWritePermission ? `Edit ${family}` : 'View Details'}
      </ContextMenu.Option>
      {hasWritePermission && onDelete && (
        <ContextMenu.Option
          disabled={isDeleteDisabled}
          danger
          data-testid={`delete-${family}-${name}-action`}
          onClick={() => onDelete(id)}
        >
          {`Delete ${family}`}
        </ContextMenu.Option>
      )}
    </ContextMenu>
  );
}
