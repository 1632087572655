// @ts-check
import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { INDUSTRY_LIST } from '@/cacheKeys';
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';
import WithAutocomplete from '@/components/common/WithAutocomplete';
import { getIndustryCodes } from '@/services/settingsService';

const INDUSTRY_FIELD_ERROR = 'Please select a valid industry';

/** @type {IndustryList} */
const DEFAULT_INDUSTRY_LIST = {
  id: '',
  value: '',
  label: '',
};

/** @typedef {{ id: string; value: string; label: string }} IndustryList */

/**
 * @typedef {{
 *   id: string;
 *   name: string;
 *   industryId: string;
 *   disabled: boolean;
 *   onChange: (value: string) => {};
 *   onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
 *   onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
 *   error: boolean;
 *   setHasError: (param: boolean) => {};
 * }} IndustryNameFieldProps
 */

/**
 * Renders an input field for accepting Industry names in the format of text,
 *
 * @example
 *   <IndustryNameField
 *    id='industry_input_field_id'
 *    name='Industry Name'
 *    industryId='4b379747-19c7-4794-8497-01717c090d16'
 *    disabled={true}
 *    onChange={(value) => onChange(value)}
 *    error={true}
 *    setHasError={setHasError}
 *
 * @type {(props: IndustryNameFieldProps) => React.ReactElement} />
 */

const IndustryNameField = ({
  id,
  name,
  industryId,
  disabled,
  onChange,
  error,
  setHasError,
}) => {
  const ref = useRef(null);
  /** @type {[IndustryList, React.Dispatch<IndustryList>]} */
  const [selectedOption, setSelectedOption] = useState(DEFAULT_INDUSTRY_LIST);
  /** @type {[IndustryList[], React.Dispatch<IndustryList[]>]} */
  const [filteredOptions, setFilteredOptions] = useState([]);
  /** @type {[String, React.Dispatch<String>]} */
  const [inputFieldValue, setInputFieldValue] = useState('');
  /** @type {import('@tanstack/react-query').UseQueryResult<IndustryList[]>} */
  const { data: options } = useQuery(
    [INDUSTRY_LIST],
    async () => {
      const {
        data: { data },
      } = await getIndustryCodes();

      return data.map((industry) => ({
        id: industry.industryId,
        value: industry.industryCode,
        label: industry.industryTitle,
      }));
    },
    { staleTime: 30000 },
  );

  /** @type {(option: IndustryList) => void} */

  const handleOptionSelect = (option) => {
    const { id: optionId } = option;
    onChange(optionId);
    setSelectedOption(option);
    setFilteredOptions([]);
    setHasError(false);
  };
  useEffect(() => {
    if (industryId && options) {
      const newOption = options.find(
        (industryOption) => industryOption.id === industryId,
      );
      setSelectedOption(newOption);
    }
  }, [options, industryId]);

  /** @type {React.ChangeEventHandler<HTMLInputElement>} */
  const handleChange = ({ target: { value } }) => {
    setInputFieldValue(value);

    const matchedOption = options.find(
      (option) => option.label.toLowerCase() === value.toLowerCase(),
    );
    if (matchedOption) {
      setFilteredOptions([]);
      setSelectedOption(matchedOption);
      onChange(matchedOption?.id);
    } else {
      setFilteredOptions(
        options.filter((filter) =>
          value
            ? filter.label.toLowerCase().includes(value.toLowerCase())
            : true,
        ),
      );
      setSelectedOption(DEFAULT_INDUSTRY_LIST);
      onChange('');
    }
  };

  /**
   * @type {(
   *   event: import('@/components/common/WithAutocomplete').FocusInputEvent,
   * ) => void}
   */
  const handleOnBlur = (event) => {
    const { target } = event;
    if (!options) return;
    const matchedOption = options.find(
      (option) => option.label.toLowerCase() === target?.value?.toLowerCase(),
    );
    setHasError(!matchedOption?.id);
  };

  useEffect(() => {
    if (selectedOption?.id) {
      setInputFieldValue(selectedOption.label);
    }
  }, [selectedOption, disabled]);

  return (
    <WithAutocomplete
      className="Industry_field_Name"
      options={filteredOptions ?? []}
      onClick={handleOptionSelect}
      onBlur={handleOnBlur}
      data-testid="industry-field-options"
      inputRef={ref}
    >
      {() => (
        <div className="Industry_Name">
          <FormLabel text="Industry" htmlFor="IndustryName" />
          <FormField
            className="CompanyDetails_Input"
            id={id}
            name={name}
            ref={ref}
            data-testid="Industry_Field_Input"
            value={inputFieldValue}
            onChange={handleChange}
            disabled={disabled}
            autoComplete="off"
            aria-invalid={error ? 'true' : 'false'}
          />
          <p
            id="industry-field-error"
            className="FormField_Error"
            data-testid="industry-field-error"
            hidden={!error || disabled}
          >
            {INDUSTRY_FIELD_ERROR}
          </p>
          <div hidden={disabled}>Type to select from suggested industries</div>
        </div>
      )}
    </WithAutocomplete>
  );
};
export default IndustryNameField;
