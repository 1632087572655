import ActualsIcon from '@bill/cashflow.assets/actuals';
import CashDropIcon from '@bill/cashflow.assets/cash-drop';
import DashboardIcon from '@bill/cashflow.assets/dashboard';
import EmployeesIcon from '@bill/cashflow.assets/employees';
import ExpensesIcon from '@bill/cashflow.assets/expenses';
import MetricsIcon from '@bill/cashflow.assets/metrics';
import ReportsIcon from '@bill/cashflow.assets/reports';
import RevenueIcon from '@bill/cashflow.assets/revenue';
import SettingsIcon from '@bill/cashflow.assets/settings';
import VariablesIcon from '@bill/cashflow.assets/variables';

export const ACTUALS_PATH = '/actuals';
export const REVENUE_MAPPING_PATH = '/revenue/mapping';
export const ACTUALS_CASH_PATH = '/actuals/cash';
export const REPORTS_PATH = '/reports';
export const REPORTS_CASH_FLOW_PATH = '/reports/cash-flow-summary';
export const REPORTS_CASH_FLOW_STATEMENT_PATH = '/reports/cash-flow-statement';
export const REPORTS_BALANCE_SHEET_PATH = '/reports/balance-sheet';
export const REPORTS_VENDOR_SPEND_PATH = '/reports/vendor-spend';
export const CUSTOM_REPORTS_PATH = '/reports/custom';
export const DASHBOARD_PATH = '/dashboard';
export const CHURNED_MRR_PATH = '/dashboard/churned-mrr';
export const LOGIN_PATH = '/';
export const EXPENSES_PATH = '/expenses';
export const UNAUTHORIZED_PATH = '/unauthorized';
export const SWITCH_COMPANIES_PATH = '/switch-company';
export const LOGOUT_PATH = '/logout';
export const METRICS_PATH = '/metrics';
export const VARIABLES_PATH = '/variables';
export const MANAGEMENT_CONSOLE_PATH = '/console';
export const FINANCIAL_SUMMARY_PATH = '/financial-summary';
export const CASH_IN_OUT = '/cash-in-out';
export const JOIN_ADDITIONAL_INFO_PATH = '/join/additional-info';
export const JOIN_INTEGRATIONS_PATH = '/join/integrations';
export const NO_ACCESS_PATH = '/no-access';
export const SETUP_PATH = '/setup';
export const EMPLOYEES_PATH = '/employees';
export const REVENUE_PATH = '/revenue';
export const SETTINGS_PATH = '/settings';
export const INSIGHTS_PATH = '/insights';

export const PAGE_ERROR_BOUNDARY_TEXT =
  'An error occurred while trying to display this page.';

const metricsTitle = () => <>Metrics Library</>;
const expensesTitle = () => (
  <>
    Expenses <span className="SubTitle">(Excluding Payroll)</span>
  </>
);

/**
 * @typedef {{
 *   icon?: (props: SVGProps<SVGElement>) => React.ReactElement;
 *   titleText: string;
 *   title?: () => React.ReactElement;
 * }} Page
 */

/** @type {Record<string, Page>} */
const PAGES = {
  [DASHBOARD_PATH]: {
    icon: DashboardIcon,
    titleText: 'Dashboard',
  },
  [CASH_IN_OUT]: {
    icon: CashDropIcon,
    titleText: 'Cash In & Out',
  },
  [EMPLOYEES_PATH]: {
    icon: EmployeesIcon,
    titleText: 'Employees / Contractors',
  },
  [EXPENSES_PATH]: {
    icon: ExpensesIcon,
    title: expensesTitle,
    titleText: 'Expenses',
  },
  [REVENUE_PATH]: {
    icon: RevenueIcon,
    titleText: 'Revenue',
  },
  [ACTUALS_PATH]: {
    icon: ActualsIcon,
    titleText: 'Actuals',
  },
  [SETTINGS_PATH]: {
    icon: SettingsIcon,
    titleText: 'Settings',
  },
  [REPORTS_PATH]: {
    icon: ReportsIcon,
    titleText: 'Reports',
  },
  [VARIABLES_PATH]: {
    icon: VariablesIcon,
    titleText: 'Variables',
  },
  [METRICS_PATH]: {
    icon: MetricsIcon,
    title: metricsTitle,
    titleText: 'Metrics',
  },
  [MANAGEMENT_CONSOLE_PATH]: {
    titleText: 'Management Console',
  },
  [INSIGHTS_PATH]: {
    titleText: 'Payables Insights',
  },
};

export default PAGES;
