// @ts-check
import { useMemo } from 'react';
import { debounce } from '@/helpers';
import { rangeSelection } from '@/pages/Actuals/helpers';

/**
 * Common hook to implement range selection in Ag-Grid
 *
 * @example
 *   const handleRangeSelection = useRangeSelection(
 *     setSelectedCells,
 *     setCellCount,
 *     setCellUnit,
 *     setCellSum,
 *   );
 *
 * @param {Function} setSelectedCells state function to highlight the selected
 *   cells
 * @param {Function} setCellCount state function to update the cell count
 * @param {Function} setCellUnit state function to set the unit
 * @param {Function} setCellSum state function to update the sum of all selected
 *   cells
 * @param {string[]} [excludedColumnsIds] - IDs of columns that are not part of
 *   rangeSelection when a selecting a single cell
 * @returns {Function} handleRangeSelection function
 */
function useRangeSelection(
  setSelectedCells,
  setCellCount,
  setCellUnit,
  setCellSum,
  excludedColumnsIds,
) {
  return useMemo(() => {
    return debounce(
      ({ api }) =>
        rangeSelection(
          setSelectedCells,
          setCellCount,
          setCellUnit,
          setCellSum,
          api,
          excludedColumnsIds,
        ),
      100,
    );
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [excludedColumnsIds]);
}

export default useRangeSelection;
