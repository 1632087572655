import { PropTypes } from 'prop-types';
import { classNames, showUserRoleText } from '@/helpers';
import UserLetterIcon from './UserLetterIcon';
import './UserInfo.scss';

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
const UserInfo = ({
  member,
  isLoggedInUser = false,
  showPermission = true,
}) => {
  const isDisabled = member.isPendingInvitation || member.isExpiredInvitation;

  return (
    <div className="UserInfo">
      <div className={classNames('UserInfo-Detail', isDisabled && 'Disabled')}>
        <UserLetterIcon name={isDisabled ? '' : member.name} id={member.id} />
        <div>
          <span>{isDisabled ? '--' : member.name}</span>
          {isLoggedInUser && <span className="UserInfo-You">(You)</span>}
        </div>
      </div>
      {showPermission && (
        <span className="UserInfo-Role">
          {showUserRoleText(member.permission)}
        </span>
      )}
    </div>
  );
};

UserInfo.propTypes = {
  /** Details of member */
  member: PropTypes.shape({
    /** ID of member */
    id: PropTypes.number.isRequired,
    /** Email of member */
    email: PropTypes.string,
    /** A flag to show if invitation of member is expired */
    isExpiredInvitation: PropTypes.bool,
    /** A flag to show if invitation of member is pending */
    isPendingInvitation: PropTypes.bool,
    /** Name of member */
    name: PropTypes.string,
    /** Permission of member */
    permission: PropTypes.oneOf(['User', 'Admin']),
  }),
  /** A flag to know if the its a currently logged in user */
  isLoggedInUser: PropTypes.bool,
  /** A flag to allow visibility of permission. True by default */
  showPermission: PropTypes.bool,
};

export default UserInfo;
