// @ts-check
import PropTypes from 'prop-types';
import MaskedFormField from '@/components/common/MaskedFormField';

const MASK_OPTIONS = {
  alias: '(999) 999-9999',
  placeholder: '_',
  noValuePatching: false,
};

/**
 * Checks if the phoneNumber is complete
 *
 * @example
 *   isPhoneValid('(912) 301-5454');
 *
 * @type {(params: string) => boolean}
 */
export const isPhoneValid = (phoneNumber) =>
  !phoneNumber?.includes(MASK_OPTIONS.placeholder);

/**
 * @typedef {{
 *   'className'?: string;
 *   'value'?: string;
 *   'id': string;
 *   'onChange': (value: string) => void;
 *   'data-testid'?: string;
 *   'validate'?: (value: string) => string | null;
 * }} PhoneNumberFieldProps
 */

/**
 * Renders an input field for accepting phone numbers in the format (000)
 * 000-0000,
 *
 * @example
 *   <PhoneNumberField
 *     id="foo"
 *     value={phone}
 *     onChange={(phoneNumber) => setPhoneNumber(phoneNumber)}
 *   />;
 *
 * @type {(params: PhoneNumberFieldProps) => React.ReactElement}
 */
function PhoneNumberField({
  className,
  value,
  id,
  onChange,
  'data-testid': dataTestId,
  validate,
  ...props
}) {
  const testId = dataTestId ?? id;
  return (
    <div className={className}>
      <MaskedFormField
        id={id}
        value={value}
        maskOptions={MASK_OPTIONS}
        placeholder="Phone Number"
        data-testid={testId}
        onChange={onChange}
        validate={validate}
        {...props}
      />
    </div>
  );
}

PhoneNumberField.propTypes = {
  /** Additional class(es) to apply to the wrapper element */
  'className': PropTypes.string,
  /**
   * A unique ID to associate the field with its label, and to select it in
   * unit/integration tests
   */
  'id': PropTypes.string.isRequired,
  /**
   * An optional ID for selecting the input in unit/integration tests, in lieu
   * of 'id'
   */
  'data-testid': PropTypes.string,
  /**
   * Event handler called when the user enters a number in the input field
   *
   * @param {string} newValue
   */
  'onChange': PropTypes.func.isRequired,
  /**
   * Additional validation to perform on the input
   *
   * @param {string} phoneNumber
   * @returns {string} An error message, or undefined
   */
  'validate': PropTypes.func,
  /** The current value of the field */
  'value': PropTypes.string,
};

export default PhoneNumberField;
