import ModalConfirmation from '@/components/common/ModalConfirmation';

export default function ProductDeleteModals({
  showCannotDeleteModal,
  onCloseCannotDeleteModal,
  showConfirmDeleteModal,
  onCancelConfirmDeleteModal,
  onConfirmDeleteModal,
  deleteResponseError,
  removeDeleteResponseError,
}) {
  return (
    <>
      {showCannotDeleteModal && (
        <ModalConfirmation
          id="cannot-delete-modal"
          onAction={onCloseCannotDeleteModal}
          title="Cannot delete plan."
          actionBtnTxt="I understand"
        >
          This product pricing plan is associated with one or more existing
          revenue streams (drivers). Please remove this product pricing plan
          from any existing revenue streams (drivers) before deleting the plan.
        </ModalConfirmation>
      )}
      {showConfirmDeleteModal && (
        <ModalConfirmation
          id="before-you-continue-delete-modal"
          onCancel={onCancelConfirmDeleteModal}
          onAction={onConfirmDeleteModal}
          title="Before you continue."
          actionBtnTxt="I understand"
        >
          This is the <b>only</b> product pricing plan associated with this
          product. Deleting this pricing plan will delete the product as well.
          Historical actuals associated with this product pricing plan will be
          maintained and will be visible in the revenue summary/details pages,
          and in the actuals page.
        </ModalConfirmation>
      )}
      {deleteResponseError && (
        <ModalConfirmation
          id="modal-product-cannot-delete"
          onAction={removeDeleteResponseError}
          title="Cannot delete the product"
          actionBtnTxt="I understand"
        >
          {deleteResponseError}
        </ModalConfirmation>
      )}
    </>
  );
}
