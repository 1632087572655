import { useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import PayrollChart from '@/pages/Dashboard/charts/PayrollChart';

const TotalPayrollExpanded = ({ title, back, startDate, endDate }) => {
  const [{ data, hasData }, setTotalPayrollData] = useState({});
  const exportBtn = useRef();
  const onQueryStateChange = useCallback((payload) => {
    setTotalPayrollData(payload);
  }, []);

  return (
    <section className="ChartPanel">
      <ChartHeader back={back} title={title}>
        <div className="ChartHeader_KPIValue">
          {hasData ? formatMonetary(data.totalPayroll) : 'Awaiting Data'}
        </div>
        <div className="ChartHeader_KPI">
          <br />
          {hasData ? formatRangeWithShortYear(startDate, endDate) : ''}
        </div>
      </ChartHeader>
      <ChartToolbar>
        <ChartControls
          chartRef={exportBtn}
          title={title}
          data-testid="payroll-chart-export"
          metricValue={
            hasData ? formatMonetary(data.totalPayroll) : 'Awaiting Data'
          }
          label={formatRangeWithShortYear(startDate, endDate)}
        />
      </ChartToolbar>
      <PayrollChart
        className="ExpandedView_ChartWrapper"
        exportBtn={exportBtn}
        onQueryStateChange={onQueryStateChange}
      />
    </section>
  );
};

const mapStateToProps = ({ shared }) => ({
  startDate: shared.startDate,
  endDate: shared.endDate,
});

export default connect(mapStateToProps)(TotalPayrollExpanded);
