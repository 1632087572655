// @ts-check
import { useMemo } from 'react';
import { debounce, isNumber } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';

/** @typedef {import('@/services/revenueService').RevenueIntegrationEntry} RevenueIntegrationEntry */

/**
 * @typedef {{
 *   data: RevenueIntegrationEntry;
 *   colDef: import('ag-grid-community').ColDef<RevenueIntegrationEntry>;
 *   context?: import('ag-grid-community').Context;
 *   api?: import('ag-grid-community').GridApi<RevenueIntegrationEntry>;
 *   node: import('ag-grid-community').RowNode<RevenueIntegrationEntry>;
 * }} Cell
 */

/**
 * @typedef {{
 *   setSelectedCells: React.Dispatch<React.SetStateAction<Cell[]>>;
 *   setCellCount: React.Dispatch<React.SetStateAction<number>>;
 *   setCellSum: React.Dispatch<React.SetStateAction<number>>;
 *   excludedColumnsIds?: string[];
 * }} UseRangeSelectionParams
 */

/**
 * @typedef {UseRangeSelectionParams & {
 *   api: import('ag-grid-community').GridApi<RevenueIntegrationEntry>;
 *   context?: import('ag-grid-community').Context;
 * }} RangeSelectionParams
 */

/** @type {(props: RangeSelectionParams) => void} */
const rangeSelection = ({
  setSelectedCells,
  setCellCount,
  setCellSum,
  api,
  excludedColumnsIds = [],
  context,
}) => {
  const cellRanges = api.getCellRanges();
  /** @typedef {Omit<import('ag-grid-community').RowPosition, 'rowPinned'>} IndexedRow */

  /** @type {IndexedRow} */
  let startRow;

  /** @type {IndexedRow} */
  let endRow;

  /** @type {import('ag-grid-community').Column[]} */
  let columns;

  let currentCount = 0;
  let currentSum = 0;

  if (!cellRanges?.length) {
    const focusedCell = api.getFocusedCell();
    if (!focusedCell) return;
    const { column, rowIndex } = focusedCell;
    if (excludedColumnsIds.includes(column.getColId())) {
      setCellCount(currentCount);
      setCellSum(currentSum);
      return;
    }
    columns = [column];
    endRow = { rowIndex };
    startRow = { rowIndex };
  } else {
    [{ columns, endRow, startRow }] = cellRanges;
    columns = columns.filter(
      (col) => !excludedColumnsIds.includes(col.getColId()),
    );
  }

  const start = Math.min(startRow.rowIndex, endRow.rowIndex);
  const end = Math.max(startRow.rowIndex, endRow.rowIndex);

  const cellsToSelect = columns.reduce((cells, col) => {
    for (let rowIndex = start; rowIndex <= end; rowIndex += 1) {
      const node = api.getDisplayedRowAtIndex(rowIndex);
      const { data } = node;
      const colDef = col.getColDef();
      const { value } = data.fields.find(
        (field) => field.name === colDef.field,
      );

      if (!isEmptyOrNull(value) && isNumber(value)) {
        currentSum += Number(value);
      }

      if (!isEmptyOrNull(value)) {
        currentCount += 1;
        cells.push({
          data,
          colDef,
          node,
          api,
          context,
        });
      }
    }
    return cells;
  }, /** @type {Cell[]} */ ([]));

  setCellCount(currentCount);
  setCellSum(currentSum);
  setSelectedCells(cellsToSelect);
};

/** @type {(props: UseRangeSelectionParams) => Function} */
function useSpreadSheetRangeSelection({
  setSelectedCells,
  setCellCount,
  setCellSum,
  excludedColumnsIds = [],
}) {
  return useMemo(() => {
    /** @type {import('ag-grid-community').GridOptions<RevenueIntegrationEntry>['onRangeSelectionChanged']} } */
    return debounce(
      ({ api, context }) =>
        rangeSelection({
          setSelectedCells,
          setCellCount,
          setCellSum,
          api,
          excludedColumnsIds,
          context,
        }),
      100,
    );
  }, [excludedColumnsIds, setSelectedCells, setCellCount, setCellSum]);
}

export default useSpreadSheetRangeSelection;
