// @ts-check
// eslint-disable-next-line no-restricted-imports -- predates description requirement
import { ProgressBar } from 'react-bootstrap';
import { classNames } from '@/helpers';
import './ProgressIndicator.scss';

/** @typedef {'success' | 'danger' | 'warning' | 'info'} ProgressStylePreset */

/**
 * A component for indicating the progress of some action
 *
 * @example
 *   <ProgressIndicator
 *     id="progress-indicator"
 *     progress={50}
 *     progressText="2 seconds remaining"
 *   />;
 *
 * @type {(props: {
 *   'id': string;
 *   'progress': number;
 *   'data-testid': string;
 *   'className'?: string;
 *   'max'?: number;
 *   'min'?: number;
 *   'variant'?: ProgressStylePreset;
 * }) => React.ReactElement}
 */
const ProgressIndicator = ({
  id,
  progress,
  'data-testid': dataTestId,
  className,
  ...props
}) => {
  return (
    <ProgressBar
      id={id}
      className={classNames(className ?? 'ProgressIndicator')}
      data-testid={dataTestId ?? id}
      now={progress}
      {...props}
    />
  );
};
export default ProgressIndicator;
