// @ts-check
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import GlobalLoader from './components/common/GlobalLoader';
import useAuthRedirect from './hooks/useAuthRedirect';

/** @typedef {{ children: React.ReactNode; condition?: boolean }} RequireAuthProps */

/**
 * Creates a route that requires the user to be authenticated, otherwise
 * redirecting them to login.
 *
 * @example
 *   <RequireAuth>
 *     <MyPage />
 *   </RequireAuth>;
 *
 * @type {(props: RequireAuthProps) => React.ReactNode}
 */
function RequireAuth({ children, condition }) {
  const { isLoading, pathname, url } = useAuthRedirect({ condition });

  if (isLoading) {
    return <GlobalLoader />;
  }

  if (pathname) {
    return <Redirect to={{ pathname }} />;
  }

  if (url) {
    window.location.assign(url);
    // eslint-disable-next-line consistent-return -- we don't want to render the route if we get redirect URL
    return;
  }

  return children;
}

RequireAuth.propTypes = {
  /** The component to render if the user is authenticated */
  children: PropTypes.node.isRequired,
  /** Additional condition that must be true to render the component */
  condition: PropTypes.bool,
};

export default RequireAuth;
