import './WelcomeMessage.scss';

const DefaultHeading = () => (
  <span>
    Welcome to <span className="WelcomeMessage_Emphasis">Finmark.</span>
  </span>
);

/**
 * A small component for displaying a welcome message on screens that use the
 * Landing container
 *
 * @example
 *   <WelcomeMessage />;
 */
const WelcomeMessage = ({ Heading = DefaultHeading }) => {
  return (
    <div className="WelcomeMessage">
      <h2 className="WelcomeMessage_Heading">
        <Heading />
      </h2>
      <p className="WelcomeMessage_Text">
        Sign into your account and get immediate access to all of your{' '}
        <span className="WelcomeMessage_WordWithArrow">financials</span>
      </p>
    </div>
  );
};

export default WelcomeMessage;
