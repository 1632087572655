import Button from '@/components/common/Button';
import { statuses } from '@/components/common/DocumentUploader/constants';

const BulkUploadButton = ({
  resetModal,
  handleFinish,
  handleUpload,
  status,
  disabled,
}) => {
  let label = 'Upload';
  if (status === statuses.ERROR) {
    label = 'Back to Upload';
  } else if (status === statuses.UPLOADING_COMPLETE) {
    label = 'Finish';
  }

  const handleClick = () => {
    if (status === statuses.ERROR) {
      resetModal();
    } else if (status === statuses.UPLOADING_COMPLETE) {
      handleFinish();
    } else {
      handleUpload();
    }
  };

  return (
    <Button
      data-testid="bulk-upload-action"
      disabled={
        disabled ||
        [
          statuses.INIT,
          statuses.FILE_SELECTED_ERROR,
          statuses.UPLOADING,
        ].includes(status)
      }
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};

export default BulkUploadButton;
