import { connect } from 'react-redux';
import { handleStreamDataChangeAction } from '@/actions/revenue';
import FormRadio from '@/components/common/FormRadio';
import {
  ONE_TIME,
  REVENUE_ONLY,
  SUBSCRIPTION,
  ADVANCED_SUBSCRIPTION,
} from '@/constants/revenueStream';
import { classNames } from '@/helpers';
import './Type.scss';

const Type = ({ revenueType, handleStreamDataChange, mode }) => {
  const streamDataChangeByEvent = (e) =>
    handleStreamDataChange(e.target.name, e.target.value);

  const isEdit = mode === 'edit';

  return (
    <div className="px-2">
      <p>What type of revenue stream (driver) is this?</p>
      {(!isEdit || revenueType === SUBSCRIPTION) && (
        <ul className="FormRadioList">
          <li
            className={classNames(
              'FormRadioList_Item',
              isEdit && 'FormRadioList_Item-view',
            )}
          >
            <FormRadio
              label="Subscription customers"
              name="revenueType"
              id="subscription"
              disabled={isEdit}
              value={SUBSCRIPTION}
              onChange={streamDataChangeByEvent}
              checked={revenueType === SUBSCRIPTION}
            />
            <p>
              Best for subscriptions, memberships, or other offerings with
              recurring charges.
            </p>
          </li>
        </ul>
      )}
      {(!isEdit || revenueType === ADVANCED_SUBSCRIPTION) && (
        <ul className="FormRadioList">
          <li
            className={classNames(
              'FormRadioList_Item',
              isEdit && 'FormRadioList_Item-view',
            )}
          >
            <FormRadio
              label="Subscription Revenue (Advanced)"
              name="revenueType"
              id="subscription_revenue"
              disabled={isEdit}
              value={ADVANCED_SUBSCRIPTION}
              onChange={streamDataChangeByEvent}
              checked={revenueType === ADVANCED_SUBSCRIPTION}
            />
            <p>
              Provides advanced options for complete configurability of
              subscription revenue streams.
            </p>
          </li>
        </ul>
      )}
      {revenueType !== SUBSCRIPTION && (
        <ul className="FormRadioList">
          {(!isEdit || revenueType === ONE_TIME) && (
            <li
              className={classNames(
                'FormRadioList_Item',
                isEdit && 'FormRadioList_Item-view',
              )}
            >
              <FormRadio
                label="One-time Purchases"
                name="revenueType"
                id="one-time"
                disabled={isEdit}
                value={ONE_TIME}
                onChange={streamDataChangeByEvent}
                checked={revenueType === ONE_TIME}
              />
              <p>
                Best for a series of one-time purchases for specific products.
              </p>
            </li>
          )}
          {(!isEdit || revenueType === REVENUE_ONLY) && (
            <li
              className={classNames(
                'FormRadioList_Item',
                isEdit && 'FormRadioList_Item-view',
              )}
            >
              <FormRadio
                label="Revenue Only"
                name="revenueType"
                id="Revenue_only"
                disabled={isEdit}
                value={REVENUE_ONLY}
                onChange={streamDataChangeByEvent}
                checked={revenueType === REVENUE_ONLY}
              />
              <p>
                If recurring customers is not applicable, or you already have a
                detailed forecast in Excel or elsewhere, choose this option to
                just enter overall revenue values without any details.
              </p>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  revenueType: state.revenues.revenueStream.revenueType,
});

export default connect(mapStateToProps, {
  handleStreamDataChange: handleStreamDataChangeAction,
})(Type);
