import PropTypes from 'prop-types';
import { classNames } from '@/helpers';

/**
 * @typedef {{
 *   (props: {
 *     'id'?: string;
 *     'className'?: string;
 *     'data-testid': string;
 *     'children': React.ReactNode;
 *   }): React.ReactElement;
 *   ColHead?: import('./ColHead').ColHead;
 * }} Table
 */

/**
 * The Table component
 *
 * @example
 *   <Table>
 *     <thead>
 *       <tr>...</tr>
 *     </thead>
 *     <tbody>
 *       <tr>...</tr>
 *     </tbody>
 *   </Table>;
 *
 * @type {Table}
 */
const Table = ({ id, className, 'data-testid': dataTestId, children }) => {
  return (
    <table
      id={id}
      className={classNames('Table', className)}
      data-testid={dataTestId}
    >
      {children}
    </table>
  );
};

Table.propTypes = {
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Unique ID for the element */
  'id': PropTypes.string,
  /** Class(es) to apply to the element */
  'className': PropTypes.string,
  /** Node(s) to display inside the element */
  'children': PropTypes.node.isRequired,
};

export default Table;
