import formatDecimalNumber from '@/helpers/formatDecimalNumber';

/**
 * Change a number to its corresponding percentage, up to 2 decimal places
 *
 * @param {number} value The value to format
 */
export default function convertDecimalToPercent(value) {
  return formatDecimalNumber(Number(value) * 100);
}
