import { forwardRef, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import Widget from './Widget';
import WidgetKPI from './WidgetKPI';
import TotalRevenueChart from './charts/TotalRevenueChart';

const TotalRevenueGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const { startDate, endDate } = useSelector(({ shared }) => shared);
    const [loading, setLoading] = useState(false);
    const [totalRevenue, setTotalRevenue] = useState();
    const onQueryStateChange = useCallback(([data]) => {
      const {
        data: { isLoading, totalRevenue: totalRevenueValue },
      } = data;
      setLoading(isLoading);
      if (!isLoading) {
        setTotalRevenue(totalRevenueValue);
      }
    }, []);

    return (
      <ChartWidget
        ref={ref}
        title="Total Revenue"
        data-testid="totalRevWidget"
        url="/dashboard/total-revenue"
        metricValue={formatMonetary(totalRevenue)}
        label={formatRangeWithShortYear(startDate, endDate)}
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={formatRangeWithShortYear(startDate, endDate)}
              value={!loading ? formatMonetary(totalRevenue) : 'Awaiting Data'}
            />
            <TotalRevenueChart
              onQueryStateChange={onQueryStateChange}
              {...childProps}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default TotalRevenueGlance;
