import ListGroupedIcon from '@bill/cashflow.assets/list-grouped';
import MenuIcon from '@bill/cashflow.assets/menu';
import IconButton from '@/components/common/IconButton';

const VendorSpendFilterButtons = ({ groupByDepartment, onClick }) => {
  return (
    <div className="SpreadsheetToolbar_ControlGroup">
      <span className="SpreadsheetToolbar_Label">View:</span>
      <IconButton
        active={!groupByDepartment}
        Icon={MenuIcon}
        label="Ungrouped"
        onClick={() => onClick(false)}
      />
      <IconButton
        active={groupByDepartment}
        Icon={ListGroupedIcon}
        label="By department"
        onClick={() => onClick(true)}
      />
    </div>
  );
};

export default VendorSpendFilterButtons;
