// @ts-check
import IntegrationButtonContent from '@/components/common/Integrations/IntegrationButtonContent';
import { getLastUpdatedStatusDate } from '@/components/common/Integrations/helpers';
import WithTooltip from '@/components/common/WithTooltip';
import { REFRESH_TOKEN_EXPIRED } from '@/constants/settings';
import { classNames } from '@/helpers';
import './CompanyIntegrationRenderer.scss';

/**
 * Render company integrations in the cell
 *
 * @type {(
 *   params: import('ag-grid-community').ICellRendererParams<
 *     import('@/reducers/companies').Company
 *   > & { enabledIntegrations: import('@/reducers/companies').Integration[] },
 * ) => React.ReactElement}
 */
const CompanyIntegrationRenderer = ({ enabledIntegrations }) => {
  return (
    <>
      {enabledIntegrations.map(
        /**
         * @type {(
         *   params: import('@/reducers/companies').Integration,
         * ) => React.ReactElement}
         */
        (integration) => {
          const isUnauthorized = integration.status === REFRESH_TOKEN_EXPIRED;
          const statusText = !isUnauthorized
            ? 'Connected'
            : 'Integration Deauthorized';
          return (
            <WithTooltip
              content={
                <div className="IntegrationTooltip">
                  <h4
                    className={classNames(
                      'IntegrationTooltipHeading',
                      isUnauthorized &&
                        'IntegrationTooltipHeading-unauthorized',
                    )}
                  >
                    {statusText}
                  </h4>
                  <p className="IntegrationTooltipDate">{`Last updated at: ${getLastUpdatedStatusDate(
                    integration.lastUpdatedAt,
                  )}`}</p>
                  {isUnauthorized && (
                    <p className="IntegrationTooltipReconnect">
                      Go to integrations to reconnect
                    </p>
                  )}
                </div>
              }
              placement="top"
              data-testid="status-tooltip"
            >
              <span
                className={classNames(
                  'ConsoleIntegrationIcon',
                  isUnauthorized && 'ConsoleIntegrationIcon-unauthorized',
                )}
              >
                <IntegrationButtonContent
                  key={integration.externalCompanyId}
                  integration={integration.type}
                />
              </span>
            </WithTooltip>
          );
        },
      )}
    </>
  );
};

export default CompanyIntegrationRenderer;
