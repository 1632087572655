// @ts-check
import { REACT_APP_BILL_DOMAIN } from '@/runtimeConfig';

/**
 * Returns TRUE when we are on the bill domain.
 *
 * @returns {boolean} TRUE when we are on the bill domain
 */
export default function isBillDomain() {
  const { hostname } = window.location;
  return hostname.includes(REACT_APP_BILL_DOMAIN);
}
