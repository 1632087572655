import { useCallback, useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client/react';
import { useQueryClient } from '@tanstack/react-query';
import {
  syncIntegrationAction,
  updateCompanySettingAction,
} from '@/actions/settings';
import { COMPANY_INTEGRATIONS, COMPANY_SETTINGS } from '@/cacheKeys/index';
import IntegrationButton from '@/components/Settings/IntegrationButton';
import ContextMenu from '@/components/common/ContextMenu';
import DayOfMonthPicker from '@/components/common/DayOfMonthPicker';
import ErrorMessages from '@/components/common/ErrorMessages';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { betaFeatures, registeredFeatureFlags } from '@/constants/features';
import {
  ACCOUNTING_INTEGRATIONS,
  FINCH_BAMBOOHR,
  FINCH_RIPPLING,
  FINCH_ZENEFITS,
  integrationFamily,
  INTEGRATION_ERROR_MESSAGES,
  PAYROLL_INTEGRATIONS,
  REVENUE_INTEGRATIONS,
  FINCH_ADP,
  REVENUE_GSHEETS,
  REVENUE_GSHEETS_PAYMENT_PROCESSOR,
} from '@/constants/integrations';
import { FPA_FULL } from '@/constants/productTypes';
import { classNames } from '@/helpers';
import formatOrdinal from '@/helpers/formatOrdinals';
import integrationsRedirect from '@/helpers/integrationsRedirect';
import useBetaAccess from '@/hooks/useBetaAccess';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { actionTypes } from '@/hooks/useIntegrationStates/actionTypes';
import useTypedSelector from '@/hooks/useTypedSelector';
import IntegrationButtonContent from './IntegrationButtonContent';
import { getLastUpdatedStatusDate } from './helpers';
import './Integrations.scss';

const SETTINGS_SCREEN = 'settings';

const {
  INTEGRATION_FAMILY_CODAT,
  INTEGRATION_FAMILY_FINCH,
  INTEGRATION_FAMILY_REVENUE,
} = integrationFamily;

const IntegrationStatus = ({
  buttonStates,
  integration,
  onReauthorize,
  onSync,
  updatedDate,
  onSetSyncDate,
  syncDate,
}) => {
  const { isUnauthorized, isSyncing } = buttonStates;
  let statusText = 'Connected';

  if (isUnauthorized) {
    statusText = 'Integration Deauthorized';
  }

  if (isSyncing) {
    statusText = 'Data sync is in progress';
  }

  const isAccountingIntegration = ACCOUNTING_INTEGRATIONS.includes(integration);

  return (
    <div
      className="IntegrationStatus"
      data-testid={`${integration}-integration`}
    >
      <IntegrationButton {...buttonStates}>
        <IntegrationButtonContent integration={integration} />
      </IntegrationButton>
      <div className="IntegrationStatusBody">
        <h3
          className={classNames(
            'IntegrationStatusHeading',
            isUnauthorized && 'IntegrationStatusHeading-unauthorized',
          )}
        >
          {statusText}
        </h3>
        <p>{updatedDate}</p>
      </div>
      <div className="IntegrationStatus_Menu">
        <ContextMenu data-testid={`${integration}-context-menu`}>
          {isUnauthorized && (
            <ContextMenu.Option
              data-testid={`${integration}-reauthorize`}
              onClick={onReauthorize}
            >
              <div className="IntegrationOption IntegrationOption-unauthorized">
                <div className="IntegrationOption_Action">
                  Reconnect Integration
                </div>
                <div className="IntegrationOption_ActionDesc">
                  Currently Deauthorized
                </div>
              </div>
            </ContextMenu.Option>
          )}
          <ContextMenu.Option
            data-testid={`${integration}-initiate-sync`}
            disabled={isUnauthorized}
            onClick={onSync}
          >
            <div className="IntegrationOption">
              <div className="IntegrationOption_Action">Initiate Sync</div>
              <div className="IntegrationOption_ActionDesc">{updatedDate}</div>
            </div>
          </ContextMenu.Option>
          {isAccountingIntegration && (
            <ContextMenu.Option
              data-testid={`${integration}-sync-date`}
              disabled={isUnauthorized}
              onClick={onSetSyncDate}
            >
              <div className="IntegrationOption">
                <div className="IntegrationOption_Action">
                  Set Books Closing Date
                </div>
                <div className="IntegrationOption_ActionDesc">
                  Previous month's actuals will be displayed on the{' '}
                  {formatOrdinal(syncDate)} of every month
                </div>
              </div>
            </ContextMenu.Option>
          )}
        </ContextMenu>
      </div>
    </div>
  );
};

const IntegrationsButtons = ({ integrations, states, onClick }) => {
  return integrations.map((integration) => (
    <IntegrationButton
      key={integration}
      disabled={states.isRedirecting}
      onClick={() => onClick(integration)}
      {...states[integration]}
    >
      <IntegrationButtonContent integration={integration} hasLabel />
    </IntegrationButton>
  ));
};

/**
 * @typedef {{
 *   selectedCompany: import('@/reducers/companies').Company;
 *   isLoading: boolean;
 *   integrations: import('@/helpers/integrations').EnrichedIntegrationStatus[];
 *   integrationsConnected: import('@/helpers/integrations').IntegrationConnected[];
 *   integrationsUnauthorized: import('@/helpers/integrations').IntegrationUnauthorized[];
 *   redirectTo?: string;
 *   connectionError?: string | null;
 *   companySettings: import('@/services/settingsService').CompanySettings;
 *   buttonStates: unknown;
 *   onChangeBtnStates: React.Dispatch<
 *     import('@/hooks/useIntegrationStates').ButtonStateDispatachType
 *   >;
 * }} IntegrationProps
 */

/**
 * Display a list third-party accounting, payroll, and revenue integrations
 *
 * @type {(props: IntegrationProps) => React.ReactElement}
 */
export default function Integrations({
  isLoading,
  selectedCompany,
  integrations,
  integrationsConnected,
  integrationsUnauthorized,
  companySettings,
  connectionError = null,
  redirectTo = SETTINGS_SCREEN,
  buttonStates,
  onChangeBtnStates,
}) {
  const queryClient = useQueryClient();
  const [showSyncDates, setShowSyncDates] = useState(false);
  const [hasManualSyncStarted, setManualSyncStarted] = useState(false);
  const { identityToken } = useTypedSelector(({ auth }) => auth);

  useEffect(() => {
    setManualSyncStarted(companySettings.manualAccountingSyncInProgress);
  }, [companySettings.manualAccountingSyncInProgress]);

  useEffect(() => {
    if (connectionError?.length) {
      setManualSyncStarted(false);
    }
  }, [connectionError]);

  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const isRevenueIntegrationsEnabled = useFeatureFlags(
    registeredFeatureFlags.GSHEETS_REVENUE_INTEGRATION,
  );

  // Disabled per BU-8388
  const isADPFeatureEnabled = false;
  const isRipplingFeatureEnabled = false;

  const isZenefitsFeatureEnabled = useBetaAccess(betaFeatures.ZENEFITS);
  const isBambooFeatureEnabled = useBetaAccess(betaFeatures.BAMBOO_HR);

  const payrollIntegrations = PAYROLL_INTEGRATIONS.reduce(
    (accum, integration) => {
      if (
        (!isADPFeatureEnabled && integration === FINCH_ADP) ||
        (!isRipplingFeatureEnabled && integration === FINCH_RIPPLING) ||
        (!isZenefitsFeatureEnabled && integration === FINCH_ZENEFITS) ||
        (!isBambooFeatureEnabled && integration === FINCH_BAMBOOHR)
      ) {
        return accum;
      }
      return [...accum, integration];
    },
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  const handleCodatRedirect = useCallback(
    integrationsRedirect({
      selectedCompany,
      redirectTo,
      identityToken,
    }),
    [identityToken, selectedCompany],
  );

  const getError = useCallback(() => {
    if (connectionError) {
      return connectionError;
    }

    const errorMessages = integrationsConnected.reduce(
      (messages, { status }) => {
        if (Object.keys(INTEGRATION_ERROR_MESSAGES).includes(status)) {
          return [
            ...messages,
            { errorDetails: INTEGRATION_ERROR_MESSAGES[status] },
          ];
        }
        return messages;
      },
      [],
    );

    return errorMessages.length ? { childErrors: errorMessages } : '';
  }, [integrationsConnected, connectionError]);

  const handleClick = useCallback(
    (integration) => {
      onChangeBtnStates({
        type: actionTypes.SET_BUTTON_STATE,
        payload: integration,
      });
      setTimeout(() => handleCodatRedirect(integration), 50);
    },
    [onChangeBtnStates, handleCodatRedirect],
  );

  let selectedIntegrationTypes = Object.keys(buttonStates).filter(
    (key) => buttonStates[key].isSelected,
  );

  selectedIntegrationTypes = [
    ...selectedIntegrationTypes,
    ...integrationsUnauthorized.map(({ type }) => type),
  ];

  const selectedIntegrations = integrations.filter(({ type }) =>
    selectedIntegrationTypes.includes(type),
  );
  const selectedAccounting = selectedIntegrations.find(({ type }) =>
    ACCOUNTING_INTEGRATIONS.includes(type),
  );

  const selectedPayroll = selectedIntegrations.find(({ type }) =>
    PAYROLL_INTEGRATIONS.includes(type),
  );

  const accountingIntegrations = ACCOUNTING_INTEGRATIONS.reduce(
    (accum, accountingType) => {
      const integration = integrations.find(
        ({ type, hideIfNotConnected }) =>
          type === accountingType && !hideIfNotConnected,
      );
      if (integration) {
        accum.push(integration.type);
      }
      return accum;
    },
    [],
  );

  const revenueIntegrations = REVENUE_INTEGRATIONS.reduce(
    (accum, revenueIntegration) => {
      if (
        !isRevenueIntegrationsEnabled &&
        (revenueIntegration === REVENUE_GSHEETS ||
          revenueIntegration === REVENUE_GSHEETS_PAYMENT_PROCESSOR)
      ) {
        return [...accum];
      }
      return [...accum, revenueIntegration];
    },
    [],
  );

  const selectedRevenue = useMemo(
    () =>
      selectedIntegrations.find(({ type }) =>
        REVENUE_INTEGRATIONS.includes(type),
      ),
    [selectedIntegrations],
  );

  const { loading: companySettingsLoading } = useSelector(
    ({ settings }) => settings.companySettings,
  );

  const apolloClient = useApolloClient();

  const handleSync = useCallback(
    async (selectedIntegration) => {
      setManualSyncStarted(true);
      await dispatch(
        syncIntegrationAction(
          {
            companyId: selectedIntegration.externalCompanyId,
            systemType: selectedIntegration.systemType,
            platformCompanyId: selectedCompany.id,
            type: selectedIntegration.type,
          },
          apolloClient,
        ),
      );
      await queryClient.invalidateQueries([
        COMPANY_INTEGRATIONS,
        selectedCompany.id,
      ]);
      await queryClient.invalidateQueries([
        COMPANY_SETTINGS,
        selectedCompany.id,
      ]);
    },
    [dispatch, selectedCompany, queryClient, apolloClient],
  );

  const handleManualSync = useCallback(
    async (dayNumber, clearActualsOnSync) => {
      await dispatch(
        updateCompanySettingAction({
          ...companySettings,
          accountingPlatformSyncDay: dayNumber,
          clearAccountingUserActualsOnSync: clearActualsOnSync,
          companyId: selectedCompany.id,
        }),
      );
      queryClient.invalidateQueries([COMPANY_SETTINGS, selectedCompany.id]);
      setShowSyncDates(false);
    },
    [dispatch, companySettings, queryClient, selectedCompany],
  );

  return (
    <>
      <DayOfMonthPicker
        data-testid="integrations-day-month-picker"
        open={showSyncDates}
        monthSize={28}
        onCancel={() => setShowSyncDates(false)}
        onSave={handleManualSync}
        value={companySettings?.accountingPlatformSyncDay}
        clearActualsOnSyncSetting={
          companySettings?.clearAccountingUserActualsOnSync
        }
        loading={companySettingsLoading}
      >
        Select your books closing date that will be used on a monthly basis to
        finalize and display synced external data (actuals & reports) from your
        accounting platform. Learn more about setting your closing date in the{' '}
        <a
          href="https://help.bill.com/direct/s/article/000003458"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          help center
        </a>
        .
      </DayOfMonthPicker>
      <ErrorMessages success={false} error={getError()} />
      {isLoading ? (
        <div className="Integrations_LoadingContainer">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="Integration">
            <h2 className="IntegrationsHeading">Accounting</h2>
            {selectedAccounting ? (
              <IntegrationStatus
                buttonStates={{
                  ...buttonStates[selectedAccounting.type],
                  isUnauthorized: integrationsUnauthorized.some(
                    ({ type }) => selectedAccounting.type === type,
                  ),
                  isSyncing:
                    hasManualSyncStarted &&
                    selectedAccounting.systemType ===
                      INTEGRATION_FAMILY_CODAT &&
                    !buttonStates[selectedAccounting.type].isUnavailable,
                }}
                integration={selectedAccounting.type}
                onSync={() => handleSync(selectedAccounting)}
                onReauthorize={() => {
                  handleClick(selectedAccounting.type);
                }}
                updatedDate={`Last updated at: ${getLastUpdatedStatusDate(
                  selectedAccounting.lastUpdatedAt,
                )}`}
                onSetSyncDate={() => setShowSyncDates(true)}
                syncDate={companySettings.accountingPlatformSyncDay}
              />
            ) : (
              <div className="SettingsIntegrationBtn_Container">
                <IntegrationsButtons
                  integrations={accountingIntegrations}
                  states={buttonStates}
                  onClick={handleClick}
                />
              </div>
            )}
          </div>
          {selectedCompany?.productTypes.includes(FPA_FULL) && (
            <>
              <div className="Integration">
                <h2 className="IntegrationsHeading">Payroll</h2>
                {selectedPayroll ? (
                  <IntegrationStatus
                    buttonStates={{
                      ...buttonStates[selectedPayroll.type],
                      isUnauthorized: integrationsUnauthorized.some(
                        ({ type }) => selectedPayroll.type === type,
                      ),
                      isSyncing:
                        companySettings.manualPayrollSyncInProgress &&
                        selectedPayroll.systemType ===
                          INTEGRATION_FAMILY_FINCH &&
                        !buttonStates[selectedPayroll.type].isUnavailable,
                    }}
                    integration={selectedPayroll.type}
                    onSync={() => handleSync(selectedPayroll)}
                    onReauthorize={() => {
                      handleClick(selectedPayroll.type);
                    }}
                    updatedDate={`Last updated at: ${getLastUpdatedStatusDate(
                      selectedPayroll.lastUpdatedAt,
                    )}`}
                  />
                ) : (
                  <div className="SettingsIntegrationBtn_Container">
                    <IntegrationsButtons
                      integrations={payrollIntegrations}
                      states={buttonStates}
                      onClick={handleClick}
                    />
                  </div>
                )}
              </div>
              {revenueIntegrations.length > 0 && (
                <div className="Integration">
                  <h2 className="IntegrationsHeading">Revenue</h2>
                  {selectedRevenue ? (
                    <IntegrationStatus
                      buttonStates={{
                        ...buttonStates[selectedRevenue.type],
                        isUnauthorized: integrationsUnauthorized.some(
                          ({ type }) => selectedRevenue.type === type,
                        ),
                        isSyncing:
                          companySettings.manualRevenueSyncInProgress &&
                          selectedRevenue.systemType ===
                            INTEGRATION_FAMILY_REVENUE &&
                          !buttonStates[selectedRevenue.type].isUnavailable,
                      }}
                      integration={selectedRevenue.type}
                      onSync={() => handleSync(selectedRevenue)}
                      onReauthorize={() => {
                        handleClick(selectedRevenue.type);
                      }}
                      updatedDate={`Last updated at: ${getLastUpdatedStatusDate(
                        selectedRevenue.lastUpdatedAt,
                      )}`}
                    />
                  ) : (
                    <div className="SettingsIntegrationBtn_Container">
                      <IntegrationsButtons
                        integrations={revenueIntegrations}
                        states={buttonStates}
                        onClick={handleClick}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
