// @ts-check
import { useState } from 'react';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import useSelectedScenarios from '@/hooks/useSelectedScenarios';
import { deleteRevenueIntegrationEntry } from '@/services/revenueService';

/**
 * @typedef {{
 *   setIsRecordDeleted: React.Dispatch<React.SetStateAction<boolean>>;
 *   setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
 *   currentRecordId: string;
 *   setCurrentRecordId: React.Dispatch<React.SetStateAction<string>>;
 * }} IntegrationRecordDeleteModalProps
 */

/** @type {(props: IntegrationRecordDeleteModalProps) => React.ReactElement} */
const IntegrationRecordDeleteModal = ({
  setIsRecordDeleted,
  setShowDeleteModal,
  currentRecordId,
  setCurrentRecordId,
}) => {
  const scenarios = useSelectedScenarios();
  const scenarioIds = scenarios.map(({ scenarioId: id }) => id);
  const [scenarioId] = scenarioIds;
  const [deleteError, setDeleteError] = useState('');

  const handleDelete = async () => {
    try {
      await deleteRevenueIntegrationEntry({
        scenarioId,
        revenueDealEntryId: currentRecordId,
      });
      setShowDeleteModal(false);
      setCurrentRecordId('');
      setIsRecordDeleted(true);
    } catch (e) {
      setDeleteError(e.response?.data?.error?.errorMessage);
    }
  };

  return (
    <>
      <ModalConfirmation
        id="modal-employee-delete"
        onCancel={() => setShowDeleteModal(false)}
        onAction={handleDelete}
        title="Delete Entry"
        data-testid="modal-employee-delete"
      >
        <div>Are you sure you would like to delete this entry?</div>
      </ModalConfirmation>
      {deleteError && (
        <ModalConfirmation
          id="entry-item-cannot-delete"
          onAction={() => setDeleteError('')}
          title="Cannot delete the entry"
          actionBtnTxt="I understand"
        >
          {deleteError}
        </ModalConfirmation>
      )}
    </>
  );
};

export default IntegrationRecordDeleteModal;
