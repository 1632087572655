// @ts-check
import { useCallback, useRef, useState } from 'react';
import { HEADER_HEIGHT, ROW_HEIGHT } from './constants';

/** @typedef {import('ag-grid-community').RowDragEvent} RowDragEvent */
/** @typedef {(event: RowDragEvent) => void} RowDragHandler */
/** @typedef {import('ag-grid-community').RowNode} RowNode */

export const dropPositions = /** @type {const} */ ({
  ABOVE: 'ABOVE',
  BELOW: 'BELOW',
  WITHIN: 'WITHIN',
});

/**
 * Returns the drop position of a dragged row relative to the given row (dragged
 * over) and the mouse Y coordinate. If the cursor is near the top of the row
 * then the drop position is above, if it's in the middle (and nesting is
 * enabled) then the drop position is nested as a child, and if it's near then
 * bottom then the position is below.
 *
 * @param {RowNode} rowNode Hovered row
 * @param {number} y Coordinate of the mouse cursor
 * @param {boolean} [allowNesting] Whether rows can be nested within one another
 * @returns {keyof dropPositions}
 */
export function getDropPosition(rowNode, y, allowNesting) {
  const { ABOVE, BELOW, WITHIN } = dropPositions;
  const { rowHeight, rowTop } = rowNode;
  if (!allowNesting) {
    const placeAfter = y > rowTop + rowHeight / 2;
    return placeAfter ? BELOW : ABOVE;
  }

  const yLocal = (y - rowTop) / rowHeight;
  // Top 3rd: above, middle 3rd: nest within, bottom 3rd: below
  return [ABOVE, WITHIN, BELOW].find((_, idx) => yLocal < (1 / 3) * (idx + 1));
}

const positionDragOverlay = (overlay, y) =>
  requestAnimationFrame(() => {
    if (!overlay) return;

    // Don't let the row go above or below the grid
    const { offsetHeight, offsetTop } = overlay.closest('.ag-root-wrapper');
    const absY = window.scrollY + y;
    if (
      absY - ROW_HEIGHT / 2 > offsetTop + HEADER_HEIGHT &&
      absY + ROW_HEIGHT / 2 < offsetTop + offsetHeight
    ) {
      const newY = absY - offsetTop - ROW_HEIGHT / 2;
      overlay.style.setProperty('transform', `translateY(${newY}px)`);
    }
  });

/**
 * Adds drag-and-drop behavior to a MonthlySpreadsheet
 *
 * @example
 *   const dragHandlers = useRowDragAndDrop({
 *     onRowDragEnd,
 *     onRowDragLeave,
 *     onRowDragMove,
 *   });
 *   return <MonthlySpreadsheet {...dragHandlers} />;
 *
 * @param {Object} params
 * @param {RowDragHandler} params.onRowDragEnd Handler for when dragging ends
 * @param {RowDragHandler} [params.onRowDragEnter] Handler for when dragging
 *   begins
 * @param {RowDragHandler} params.onRowDragLeave Handler for when the cursor
 *   leaves the table while dragging
 * @param {RowDragHandler} [params.onRowDragMove] Handler for movement while
 *   dragging
 * @param {(event: RowDragEvent) => boolean} [params.canBeNested] Returns TRUE
 *   if the dragged row can be nested within the hovered row
 * @returns {Object} Props to pass to MonthlySpreadsheet
 */
function useRowDragAndDrop({
  onRowDragEnd,
  onRowDragEnter,
  onRowDragLeave,
  onRowDragMove,
  canBeNested,
}) {
  /** @type {[boolean, React.Dispatch<boolean>]} */
  const [isDragging, setDragging] = useState(false);
  /** @type {React.MutableRefObject<HTMLElement>} */
  const dragOverlay = useRef(null);
  /** @type {React.MutableRefObject<RowNode>} */
  const dropTarget = useRef(null);

  const handleOverlayMove = useCallback(
    ({ y }) => positionDragOverlay(dragOverlay.current, y),
    [],
  );

  const handleExitDrag = useCallback(() => {
    setDragging(false);
    const overlay = dragOverlay.current;
    if (overlay) overlay.parentNode.removeChild(overlay);
    dragOverlay.current = null;

    const { current } = dropTarget;
    current?.updateData({
      ...current.data,
      dropTarget: null,
    });

    document.removeEventListener('mousemove', handleOverlayMove);
  }, [handleOverlayMove]);

  const handleDragEnd = useCallback(
    /** @type {RowDragHandler} */
    (params) => {
      const { current } = dropTarget;
      current?.updateData({
        ...current.data,
        dropTarget: null,
      });

      onRowDragEnd?.(params);
    },
    [onRowDragEnd],
  );

  const handleDragEnter = useCallback(
    /** @type {RowDragHandler} */
    (params) => {
      if (isDragging) return;

      const {
        api,
        event: { y },
        node,
      } = params;
      setDragging(true);

      // Clone the first cell of the row to hold under the cursor
      // while the user is dragging
      const [{ element }] =
        // @ts-ignore
        node.beans.rowRenderer.allRowCtrls[node.rowIndex].allRowGuis;
      const overlay = document.createElement('div');
      overlay.classList.add('Spreadsheet_DragOverlay');
      const clonedRow = element.cloneNode(true);
      clonedRow.classList.remove('ag-row-dragging');
      overlay.appendChild(clonedRow);
      // @ts-ignore
      api.gridBodyCtrl.eGridBody.appendChild(overlay);
      dragOverlay.current = overlay;
      positionDragOverlay(dragOverlay.current, y);

      if (node.expanded) node.setExpanded(false);

      // We don't use ag-Grid's drag handler because we don't want dragging to
      // stop if the cursor accidentally leaves the grid area
      document.addEventListener('mousemove', handleOverlayMove);
      document.addEventListener('mouseup', handleExitDrag, {
        once: true,
      });

      onRowDragEnter?.(params);
    },
    [handleOverlayMove, handleExitDrag, isDragging, onRowDragEnter],
  );

  const handleDragMove = useCallback(
    (params) => {
      const { overNode, y } = params;

      const oldTarget = dropTarget.current;
      oldTarget?.updateData({
        ...oldTarget.data,
        dropTarget: null,
      });

      // Set a flag on the hovered row indicating whether the dragged row will be
      // placed above, below or within it. Applied in Spreadsheet:rowClassRules.
      const hoverData = {
        ...overNode.data,
        dropTarget: getDropPosition(overNode, y, canBeNested?.(params)),
      };
      overNode.updateData(hoverData);
      dropTarget.current = overNode;

      onRowDragMove?.(params);
    },
    [canBeNested, onRowDragMove],
  );

  return {
    onRowDragEnd: handleDragEnd,
    onRowDragEnter: handleDragEnter,
    onRowDragLeave,
    onRowDragMove: handleDragMove,
    suppressMaintainUnsortedOrder: false,
  };
}

export default useRowDragAndDrop;
