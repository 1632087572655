import { forwardRef, useImperativeHandle, useRef } from 'react';
import MinusIcon from '@bill/cashflow.assets/minus';
import PropTypes from 'prop-types';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';
import IconButton from '@/components/common/IconButton';
import { ERROR_BOUNDARY_TEXT } from '@/constants/charts';
import { AXIS_STYLES, PLOT_OPTIONS, TOOLTIP_OPTIONS } from '@/constants/widget';
import useExportableReportContext from '@/contexts/useExportableReportContext';
import { classNames } from '@/helpers';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import '@/pages/Dashboard/Widget.scss';

/**
 * A wrapper for an exportable reports chart, rendering the container, title,
 * and remove icon
 *
 * @example
 *   <ReportWidget title="Foo" data-testid="foo">
 *    {({ plotOptions, tooltipOptions }) => (
 *      <DateChart exportBtn={exportBtn} plotOptions={plotOptions}>
 *        ...
 *      </DateChart>
 *    )}
 *   </Widget>
 */
const ReportWidget = forwardRef(
  (
    {
      children,
      className,
      'data-testid': dataTestId,
      title,
      style,
      onRemoveChart,
      ...props
    },
    ref,
  ) => {
    const widget = useRef();
    useImperativeHandle(ref, () => widget.current);
    const hasWritePermission = useNonDashboardWritePermission();
    const { isExporting } = useExportableReportContext();

    return (
      <section
        className={classNames('Widget', className)}
        ref={widget}
        data-testid={dataTestId}
        style={style}
        {...props}
      >
        <div className="Widget_Header">
          <h2 className="Widget_Title">{title}</h2>
          {hasWritePermission && !isExporting && (
            <IconButton
              className="ReportCharts_RemoveChartButton"
              onClick={onRemoveChart}
              Icon={MinusIcon}
              label="Remove Chart"
              data-testid="remove-chart"
            />
          )}
        </div>
        <div className="Widget_Content">
          <CommonErrorBoundary text={ERROR_BOUNDARY_TEXT}>
            {children({
              axisStyles: AXIS_STYLES,
              plotOptions: PLOT_OPTIONS,
              tooltipOptions: TOOLTIP_OPTIONS,
            })}
          </CommonErrorBoundary>
        </div>
      </section>
    );
  },
);

ReportWidget.propTypes = {
  /**
   * Content of the widget, as a render prop
   *
   * @param {Object} params Parameters to pass to the widget content
   * @param {Object} params.plotOptions Configuration options for the chart
   * @param {Object} params.tooltipOptions Configuration options for the chart
   *   tooltip
   * @returns content for the widget
   */
  'children': PropTypes.func.isRequired,
  /** Additional class(es) to apply to the widget */
  'className': PropTypes.string,
  /**
   * Identifies the widget container in unit and integration tests, and serves
   * as a prefix for the IDs of its children
   */
  'data-testid': PropTypes.string.isRequired,
  /** Inline styles to apply to the widget, supplied by react-grid-layout */
  'style': PropTypes.objectOf(PropTypes.string),
  /** The title of the widget */
  'title': PropTypes.string,
  /** Function to call when remove icon is clicked */
  'onRemoveChart': PropTypes.func,
};

export default ReportWidget;
