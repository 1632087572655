export const GATEWAY_TIMEOUT_ERROR = 'GATEWAY_TIMEOUT_ERROR';
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
export const REMOTE_CONNECTION_REFUSED = 'REMOTE_CONNECTION_REFUSED';
export const NO_DATA_FOUND = 'NO_DATA_FOUND';
export const SYNC_ERROR = 'SYNC_ERROR';
export const GLANCED_MEMBER_LIST_RECORD_COUNT = 4;
export const ACCOUNTS_RECEIVABLE = 'Account Receivable';
export const ACCOUNTS_PAYABLE = 'Account Payable';
export const REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED';
export const REFRESH_TOKEN_ACTIVE = 'REFRESH_TOKEN_ACTIVE';

export const MODES = {
  READ: 'read',
  EDIT: 'edit',
};

/** @type {import('@/services/settingsService').CompanySettings} */
export const DEFAULT_COMPANY_SETTINGS = {
  currency: '',
  companyName: '',
  manualAccountingSyncInProgress: false,
  manualPayrollSyncInProgress: false,
  manualRevenueSyncInProgress: false,
  accountingPlatformSyncDay: 1,
  clearAccountingUserActualsOnSync: false,
};
