// @ts-check
import { useMemo } from 'react';
import { registeredFeatureFlags } from '@/constants/features';
import useNotificationContext from '@/contexts/useNotificationContext';
import { classNames } from '@/helpers';
import isBillDomain from '@/helpers/isBillDomain';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import './TheLayout.scss';

/**
 * @type {(props: {
 *   children: React.ReactNode;
 *   copilotExpanded?: boolean;
 * }) => React.ReactElement}
 */
const TheLayout = ({ copilotExpanded = false, children }) => {
  const { margin } = useNotificationContext();
  const isOneColorThemeEnabled = useFeatureFlags(
    registeredFeatureFlags.ONE_COLOR_CSS_THEME,
  );

  const isRemoteFeaturesEnabled = useMemo(
    () => isBillDomain() || isOneColorThemeEnabled,
    [isOneColorThemeEnabled],
  );

  return (
    <div
      className={classNames(
        'TheLayout',
        copilotExpanded && 'TheLayout-copilotOpened',
        isRemoteFeaturesEnabled && 'TheLayout-planning',
      )}
      style={{
        marginTop: margin,
        minHeight: `calc(100vh - ${margin}px)`,
      }}
    >
      {children}
    </div>
  );
};

export default TheLayout;
