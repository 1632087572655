// @ts-check
import { forwardRef, useCallback, useState } from 'react';
import { AWAITING_DATA } from '@/constants/charts';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import useTypedSelector from '@/hooks/useTypedSelector';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import CashInChart from '@/pages/Dashboard/charts/CashInChart';
import { CHART_TITLE } from '@/pages/Dashboard/constants/cashIn';
import { getMetricValue } from '@/pages/Dashboard/helpers';

/**
 * @typedef {{
 *   children: React.ReactElement;
 *   ChartWidget?: React.ComponentType<import('@/types/dashboard').WidgetProps>;
 * }} CashInGlanceProps
 */

/**
 * @typedef {{
 *   isLoading: boolean;
 *   total: number;
 * }} CashInData
 */

/**
 * @type {React.ForwardRefExoticComponent<
 *   CashInGlanceProps & React.RefAttributes<HTMLElement>
 * >}
 */
const CashInChartGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    /** @type {ReturnType<typeof useState<CashInData>>} */
    const [chart, setChart] = useState({
      isLoading: true,
      total: 0,
    });
    const { startDate, endDate } = useTypedSelector(({ shared }) => shared);
    const onQueryStateChange = useCallback(([base]) => {
      setChart({ isLoading: base.isLoading, total: base.data.overallTotal });
    }, []);
    return (
      <ChartWidget
        ref={ref}
        title={CHART_TITLE}
        metricValue={formatMonetary(chart.total)}
        label={formatRangeWithShortYear(startDate, endDate)}
        data-testid="cashinWidget"
        url="/dashboard/cash-in"
        tooltipContent="The total amount of cash brought in during each period from different accounts"
        isLoading={chart.isLoading}
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={
                !chart.isLoading
                  ? formatRangeWithShortYear(startDate, endDate)
                  : ''
              }
              value={
                chart.isLoading
                  ? AWAITING_DATA
                  : getMetricValue(chart.total, formatMonetary)
              }
            />
            <CashInChart
              {...childProps}
              onQueryStateChange={onQueryStateChange}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default CashInChartGlance;
