export const expenseTypes = {
  AMOUNT: 0,
  CUSTOM_FORMULA: 1,
};

export const expenseChangeTypes = {
  PERCENTAGE: 0,
  MONETARY: 1,
};

export const SPREADSHEET_ID = 'ExpensesGrid';

export const EXPENSE_FREQUENCY = {
  0: 'Monthly',
  1: 'Quarterly',
  2: 'Semi-Annually',
  3: 'Annually',
  4: 'One-Time',
};

export const expenseGroupTypes = {
  BASIC: 'Basic',
  ACCOUNT: 'Account',
  LINKED: 'Linked',
};

export const expenseClassTypes = {
  standard: 'Standard',
  incomeStmtRelated: 'IncomeStmtRelated',
};

export const invoiceTypes = {
  UPFRONT: 'UPFRONT',
  ARREARS: 'ARREARS',
};

export const SET_CUSTOM_FORMULA = 'SET_CUSTOM_FORMULA';
export const SET_FORMULA_VALIDATION_RESPONSE =
  'SET_FORMULA_VALIDATION_RESPONSE';
export const SET_INITIAL_EXP_AMOUNT = 'SET_INITIAL_EXP_AMOUNT';

export const EXPENSE_CLASS = 'class';
export const EXPENSE_DEPT = 'dept';
export const EXPENSE = 'expense';

export const DEFAULT_PAYMENT_TERM = 'Immediate';
export const NON_CASH_PAYMENT_TERM = 'Non-cash (advanced)';
export const NON_CASH_EXPENSE_CLASS = 'Depreciation & Amortization';

export const DEFAULT_CHANGE_OVER_TIME_STATES = {
  changeOverTime: false,
  changeFrequency: null,
  expenseChangeType: 1,
  changeAmount: null,
};

export const getInitialState = () => ({
  expenseClassId: 1,
  accountId: null,
  departmentId: null,
  name: '',
  expenseAccountNum: '',
  active: true,
  expenseType: expenseTypes.AMOUNT,
  expenseAmount: null,
  startDate: null,
  frequency: null,
  endDate: null,
  expenseGroupCriteria: null,
  type: expenseGroupTypes.BASIC,
  payroll: false,
  invoiceTiming: invoiceTypes.UPFRONT,
  paymentTerm: null,
  paymentTermId: 1,
  customFormula: null,
  formulaValidationMsg: '',
  isFormulaValid: false,
  errorMessage: '',
  initialAmount: null,
  parentId: null,
  isUnsaved: true,
  ...DEFAULT_CHANGE_OVER_TIME_STATES,
});

export const units = {
  CURRENCY: 'Currency',
  FORMULA: 'Formula',
};
export const invoiceTypesMap = {
  UPFRONT: 'Upfront',
  ARREARS: 'Arrears',
};

export const ONE_TIME_EXPENSE = 4;
export const MONTHLY_EXPENSE = 0;

export const NUM_REGEX = /^\d*\.?\d{0,2}$/;
export const ALLOW_NEGATIVE_NUM_REGEX = /^-?\d*\.?\d{0,2}$/;
