import { forwardRef, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import BurnRateChart from '@/pages/Dashboard/charts/BurnRateChart';

const BurnRateGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [burnRate, setBurnRate] = useState({});
    const { startDate, endDate } = useSelector(({ shared }) => shared);
    const onQueryStateChange = useCallback((data) => {
      const [
        {
          isLoading,
          data: { totalBurnRate },
        },
      ] = data;
      setBurnRate({
        totalBurnRate,
        isLoading,
      });
    }, []);

    return (
      <ChartWidget
        ref={ref}
        title="Burn Rate"
        url="/dashboard/burn-rate"
        data-testid="burnRateWidget"
        metricValue={formatMonetary(burnRate.totalBurnRate)}
        label={formatRangeWithShortYear(startDate, endDate)}
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={formatRangeWithShortYear(startDate, endDate)}
              value={
                burnRate.isLoading ? '' : formatMonetary(burnRate.totalBurnRate)
              }
            />
            <BurnRateChart
              {...childProps}
              onQueryStateChange={onQueryStateChange}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default BurnRateGlance;
