import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { SET_CHART_PREFERENCES } from '@/actionTypes/dashboard';
import { classNames } from '@/helpers';
import useChartPreferences from '@/hooks/useChartPreferences';
import useOneColor from '@/hooks/useOneColor';
import FormRadio from './FormRadio';
import './RadioGroupPersistent.scss';

/**
 * Radio buttons that control the data is show in a chart
 *
 * @example
 *   <ChartRadioGroupPersistent
 *     id="someId"
 *     options={[
 *       {
 *         label: 'Foo',
 *         value: false,
 *       },
 *       {
 *         label: 'Bar',
 *         value: true,
 *       },
 *     ]}
 *     value={false}
 *   />;
 */
const ChartRadioGroupPersistent = ({ id, className, value, options }) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const valueFromState = useChartPreferences(id);
  const selectedValue = valueFromState ?? value;
  const isOneColorEnabled = useOneColor();

  useEffect(() => {
    if (!valueFromState) {
      dispatch({ type: SET_CHART_PREFERENCES, payload: { [id]: value } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [id, value]);

  const handleChange = ({ target }) => {
    if (['false', 'true'].includes(target.value)) {
      dispatch({
        type: SET_CHART_PREFERENCES,
        payload: { [id]: target.value === 'true' },
      });
    } else {
      dispatch({
        type: SET_CHART_PREFERENCES,
        payload: { [id]: target.value },
      });
    }
  };

  return (
    <div
      id={id}
      data-testid={id}
      className={classNames('RadioGroupPersistent', className)}
    >
      {options.map((option) => {
        const stringValue = String(option.value);
        return (
          <FormRadio
            key={stringValue}
            label={option.label}
            id={`${id}-${stringValue}`}
            value={stringValue}
            name={`${id}-control`}
            checked={option.value === selectedValue}
            onChange={handleChange}
            className={classNames(isOneColorEnabled && 'FormRadio-forecasting')}
            labelClass={classNames(
              'RadioGroupPersistent_Label',
              isOneColorEnabled && 'RadioGroupPersistent_Label-forecasting',
            )}
          />
        );
      })}
    </div>
  );
};

ChartRadioGroupPersistent.propTypes = {
  /**
   * A globally unique ID for storing toggle state between views. It is also
   * used for selecting the toggle in unit/integration tests
   */
  id: PropTypes.string.isRequired,
  /** Classes to apply on the component */
  className: PropTypes.string,
  /** The option (by option.value) to be selected */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  /**
   * An array of objects describing the states to choose from. Each object must
   * contain an label and value.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    }),
  ).isRequired,
};

export default ChartRadioGroupPersistent;
