// @ts-check
import { useState, useEffect, useCallback, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import TextAreaAutosize from 'react-textarea-autosize';
import CheckmarkSmallIcon from '@bill/cashflow.assets/checkmark-small';
import EditRoundIcon from '@bill/cashflow.assets/edit-round';
import { useMutation, useQuery } from '@tanstack/react-query';
import { sanitize } from 'dompurify';
import { setAiConversationMetadata } from '@/actions/aiConversation';
import { subscribeToGetPromptAiResponseAction } from '@/actions/reports';
import { USER_PREFERENCE } from '@/cacheKeys';
import Footer, {
  FOOTER_ID,
} from '@/components/Reports/ExportableReport/Footer';
import Page from '@/components/Reports/ExportableReport/Page';
import PermissionModal from '@/components/Reports/ExportableReport/PermissionModal';
import SectionHeader from '@/components/Reports/ExportableReport/Sections/SectionHeader';
import useSectionVisibleOnScreen from '@/components/Reports/ExportableReport/Sections/useSectionVisibleOnScreen';
import {
  DEFAULT_AI_DISCLAIMER,
  promptType,
  SET_AI_GENERATED_TEXT,
  sectionCategory,
  SET_TEXT,
  summaryKey,
  CHARACTER_LIMIT,
  MAX_PAGES,
  sectionId as secId,
} from '@/components/Reports/ExportableReport/constants';
import { handleUserAIChange } from '@/components/Reports/helpers';
import Button from '@/components/common/Button';
import AiFeedbackModal from '@/components/common/Feedback/AiFeedbackModal';
import FeedbackFace from '@/components/common/Feedback/FeedbackFace';
import LoadingIndicator from '@/components/common/LoadingIndicator';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import WithTooltip from '@/components/common/WithTooltip';
import useExportableReportContext from '@/contexts/useExportableReportContext';
import { classNames, sanitizeInput } from '@/helpers';
import useIsFullFpnaProduct from '@/hooks/useIsFullFpnaProduct';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import useTypedSelector from '@/hooks/useTypedSelector';
import useWsSubscription from '@/hooks/useWsSubscription';
import { generateAiResponse } from '@/services/exportableReport';
import {
  getChatGPTPromptPermission,
  setChatGPTPromptPermission,
  sendUserFeedbackOnAISummary,
} from '@/services/reports.service';
import './Summary.scss';

/** @typedef {import('@/components/Reports/ExportableReport/constants').SectionId} SectionId */

/** @typedef {import('@/components/Reports/ExportableReport/constants').SummaryKey} SummaryKey */

/** @typedef {import('@/types/exportableReport').SummaryPageContent} SummaryPageContent */

/** @typedef {import('@/types/exportableReport').FeedbackRating} FeedbackRating */

/**
 * @typedef {SummaryPageContent['disclaimer']
 *   | SummaryPageContent['executiveSummary']} SummaryTextContent
 */

/**
 * @type {{
 *   [key in FeedbackRating]: string;
 * }}
 */
const feedbackTextMap = {
  NotUseful: 'Not Useful',
  SlightlyUseful: 'Slightly Useful',
  Neutral: 'Neutral',
  Useful: 'Useful',
  VeryUseful: 'Very Useful',
};

export const feedbackRatings = /** @type {FeedbackRating[]} */ (
  Object.keys(feedbackTextMap)
);

const FeedbackSection = ({ onClick }) => {
  return (
    <div className="SummaryFeedback_Container">
      {feedbackRatings.map((rating) => (
        <WithTooltip
          content={feedbackTextMap[rating]}
          placement="top"
          data-testid={`${rating}-tooltip`}
        >
          <div>
            <FeedbackFace
              key={rating}
              onClick={() => onClick(rating)}
              variant={rating}
            />
          </div>
        </WithTooltip>
      ))}
      <p> Was this AI-generated summary useful?</p>
    </div>
  );
};

const summaryTypes = /** @type {const} */ {
  DISCLAIMER: 'DISCLAIMER',
  EXECUTIVE_SUMMARY: 'EXECUTIVE_SUMMARY',
};

const textFieldInitialRows = /** @type {const} */ {
  DISCLAIMER: 5,
  EXECUTIVE_SUMMARY: 25,
  DEFAULT: 25,
};

/**
 * @type {(
 *   section: import('@/types/exportableReport').Section,
 * ) => SummaryKey}
 */
const getSectionKey = (section) => {
  switch (section.type) {
    case summaryTypes.DISCLAIMER:
      return summaryKey.DISCLAIMER;
    case summaryTypes.EXECUTIVE_SUMMARY:
      return summaryKey.EXECUTIVE_SUMMARY;
    default:
      throw new Error(`Unknown summary type ${section.type}`);
  }
};

/** @type {(content: string) => string} */
const text2html = (content) => {
  const result = sanitize(content)
    .split('\n')
    .map((paraContent) => {
      if (!paraContent) return '<br />';
      // Get a word, any punctuation, and trailing white space and wrap in span
      const words = paraContent.replace(
        /\S+\s?/g,
        '<span class="Token">$&</span>',
      );
      return `<div>${words}</div><br />`;
    })
    .join('');
  return result;
};

/** @type {(html: string, htmlElement: Element) => HTMLDivElement} */
const getTextareaLines = (html, htmlElement) => {
  const linesContainer = document.createElement('div');

  // A temporary container attached to the DOM to measure line width
  const pageDiv = document.createElement('div');
  pageDiv.className = 'ExportableReport_Page ExportableReport_Page-calc';
  pageDiv.innerHTML = html;
  htmlElement.appendChild(pageDiv);
  let id = 1;

  Array.from(pageDiv.children).forEach((para, index, list) => {
    const tempContainer = document.createElement('div');
    let previousY = null;
    let innerHTML = '';

    // If the <br /> is preceded by another <br /> it will act as a new line
    if (para.nodeName === 'BR' && list[index - 1]?.nodeName === 'BR') {
      const newLine = document.createElement('div');
      newLine.id = `${id}`;
      newLine.className = 'Line';
      newLine.innerHTML = '<br />';
      tempContainer.appendChild(newLine);
      id += 1;
    } else {
      Array.from(para.children).forEach((token, idx, arr) => {
        const position = token.getBoundingClientRect();
        const tokenContent = token.textContent;
        const hasPreviousToken = !!arr[idx - 1];
        const newLine = `<div class="Line" id="${id}" data-testid="section-line-${id}">${tokenContent}`;
        switch (true) {
          case arr.length > 1 && arr.length - 1 === idx:
            innerHTML = `${innerHTML}${tokenContent}</div>`;
            break;
          case position.y !== previousY && hasPreviousToken:
            innerHTML = `${innerHTML}</div>${newLine}`;
            id += 1;
            break;
          case position.y !== previousY:
            innerHTML = `${innerHTML}${newLine}`;
            id += 1;
            break;
          default:
            innerHTML = `${innerHTML}${tokenContent}`;
        }
        previousY = position.y;
        tempContainer.innerHTML = innerHTML;
      });
    }
    Array.from(tempContainer.children).forEach((child) =>
      linesContainer.appendChild(child),
    );
    pageDiv.removeChild(para);
  });
  htmlElement.removeChild(pageDiv);
  return linesContainer;
};

/**
 * @type {(props: {
 *   sectionId: SectionId;
 *   onClick: () => void;
 * }) => React.ReactElement}
 */
const EditButton = ({ sectionId, onClick }) => {
  return (
    <button
      className="Summary_Button"
      data-testid={`${sectionId}-edit-text-button`}
      onClick={onClick}
    >
      <EditRoundIcon className="ExportableReport_Icon" />
      Edit Text
    </button>
  );
};

/**
 * @type {(props: {
 *   disabled: boolean;
 *   onClick: () => void;
 * }) => React.ReactElement}
 */
const AiGenerateButton = ({ disabled, onClick }) => {
  return (
    <Button
      data-testid="ai-generate-button"
      disabled={disabled}
      className="ExportableReport_ActionBtn"
      onClick={onClick}
    >
      Generate Summary with AI
    </Button>
  );
};

/**
 * @type {(
 *   pages: HTMLDivElement[],
 *   line: Element,
 *   index: number,
 * ) => HTMLDivElement[]}
 */
const getLinesByPage = (pages, line) => {
  if (Number(line.id) % textFieldInitialRows.DEFAULT === 0) {
    const newPage = document.createElement('div');
    newPage.id = `output-page-${pages.length + 1}`;
    newPage.appendChild(line);
    pages.push(newPage);
  } else {
    pages[pages.length - 1].appendChild(line);
  }
  return pages;
};

/**
 * @typedef {{
 *   documentRef: HTMLDivElement;
 *   id: import('@/types/exportableReport').SummarySectionId;
 *   isFooterDisclaimerInEditMode: boolean;
 *   setIsFooterDisclaimerInEditMode: (value: boolean) => void;
 * }} SummaryPageProps
 */

/**
 * Renders Summary Section of Exportable Reports
 *
 * @example
 *   <Report id={section.id} />;
 *
 * @type {(props: SummaryPageProps) => React.ReactElement}
 */
const Summary = ({
  documentRef,
  id,
  isFooterDisclaimerInEditMode,
  setIsFooterDisclaimerInEditMode,
}) => {
  const isFPAFull = useIsFullFpnaProduct();
  const { reportState, setReportState } = useExportableReportContext();
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );
  const aiConversationMetadata = useTypedSelector(
    ({ aiConversation }) => aiConversation.exportableReport,
  );
  const companyId = useSelector(({ companies }) => companies.selectedCompanyId);
  const [scenarioId] = useSelectedScenarioIds();
  const section = reportState[id];
  const summaryType = getSectionKey(section);
  const content = section.content[summaryType];
  const [pages, setPages] = useState([]);
  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const [isAiSummaryGenerating, setIsAiSummaryGenerating] = useState(false);
  /** @type {number} */
  const initialRows =
    textFieldInitialRows[section.type] ?? textFieldInitialRows.DEFAULT;
  const ref = useSectionVisibleOnScreen(section.id, { root: documentRef });
  const [isEditMode, setIsEditMode] = useState(false);
  const [showPermission, setShowPermission] = useState(false);
  const { isEditControlVisible, setEditableFields } =
    useExportableReportContext();
  /** @type {ReturnType<typeof useState<FeedbackRating>>} */
  const [feedbackRating, setFeedbackRating] = useState(null);
  const [feedbackText, setFeedbackText] = useState('');

  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();

  const isExecutiveSummary = summaryType === summaryKey.EXECUTIVE_SUMMARY;
  const hasNewAiSummary =
    aiConversationMetadata?.logTrailIdWithFeedback !==
      aiConversationMetadata?.logTrailId && section.content.aiText;
  const showFeedbackOptions =
    isExecutiveSummary && hasNewAiSummary && isEditControlVisible;

  const hasActiveReportSection = useMemo(
    () =>
      !!Object.values(reportState).find(
        (sectionType) =>
          sectionType.type === sectionCategory.REPORT && sectionType.active,
      ),
    [reportState],
  );

  const { data: userPreference, refetch: fetchUserPreference } = useQuery({
    queryKey: [USER_PREFERENCE, scenarioId],
    queryFn: async () => {
      const { data } = await getChatGPTPromptPermission(companyId);
      return data.data.preference;
    },
    staleTime: 30000,
  });

  const { mutate: generateAiResponseMutation } = useMutation({
    mutationFn: generateAiResponse,
    /**
     * @type {(
     *   params: import('axios').AxiosError<import('@/types/api').ApiResponse>,
     * ) => void}
     */
    onError: (err) => {
      setError(err.response?.data?.error?.errorMessage || err.message);
      setIsAiSummaryGenerating(false);
    },
    onMutate: () => {
      setIsAiSummaryGenerating(true);
    },
  });

  const { mutate: sendFeedback, isLoading: isFeedbackLoading } = useMutation({
    mutationFn: sendUserFeedbackOnAISummary,
    /** @type {(params: import('axios').AxiosError<>) => void} */
    onError: (err) => {
      setError(err.message);
      setFeedbackText('');
      setFeedbackRating(null);
    },
    onSuccess: () => {
      setFeedbackText('');
      setFeedbackRating(null);
      const { conversationId, logTrailId } = aiConversationMetadata;
      dispatch(
        setAiConversationMetadata('exportableReport', {
          conversationId,
          logTrailId,
          logTrailIdWithFeedback: logTrailId,
        }),
      );
    },
  });

  useWsSubscription(
    () =>
      summaryType === summaryKey.EXECUTIVE_SUMMARY &&
      dispatch(
        subscribeToGetPromptAiResponseAction(
          scenarioId,
          ({ summary, conversationId, logTrailId, error: aiSummaryError }) => {
            if (aiSummaryError) {
              setError(aiSummaryError);
              setIsAiSummaryGenerating(false);
              return;
            }
            dispatch(
              setAiConversationMetadata('exportableReport', {
                conversationId,
                logTrailId,
                logTrailIdWithFeedback:
                  aiConversationMetadata?.logTrailIdWithFeedback ?? null,
              }),
            );
            setReportState({
              type: SET_AI_GENERATED_TEXT,
              setAIGeneratedText: {
                id,
                aiText: summary,
                disclaimerText: DEFAULT_AI_DISCLAIMER,
              },
            });
            setIsAiSummaryGenerating(false);
          },
        ),
      ),
    [scenarioId, content],
  );

  /** @type {() => void} */
  const onErrorModalClose = () => {
    setError('');
  };

  /** @type {(promptKey: string, showPermission: boolean) => void} * */
  const handleAiSummaryGeneration = (promptKey, showPermissionModal = true) => {
    if (
      showPermissionModal &&
      !userPreference?.chatGPTPromptPermissionGranted
    ) {
      setShowPermission(true);
      return;
    }
    /** @type {import('@/types/services/backend').TextGenerationDto} */
    const payload = {
      scenarioId,
      companyId,
      startDate,
      endDate,
      timePeriod,
      conversationId: aiConversationMetadata?.conversationId,
      textGenerationType: promptType[promptKey],
    };
    generateAiResponseMutation(payload);
  };

  const {
    mutate: setChatGPTPromptPermissionMutation,
    isLoading: isUserPreferenceLoading,
  } = useMutation(setChatGPTPromptPermission, {
    onSuccess: (data) => {
      setShowPermission(false);
      fetchUserPreference();
      handleAiSummaryGeneration(summaryKey.EXECUTIVE_SUMMARY, false);
    },
  });

  const handleChatGPTPromptPermission = useCallback(
    (data) => {
      setChatGPTPromptPermissionMutation({ companyId, data });
    },
    [setChatGPTPromptPermissionMutation, companyId],
  );

  const persistText = useCallback(() => {
    const { aiText: oldAIText, disclaimerText: oldDisclaimerText } =
      section.content;
    const { userText, aiText, disclaimerText } = handleUserAIChange({
      newText: sanitizeInput(text),
      oldUserText: content,
      oldAIText,
      oldDisclaimerText,
    });

    setReportState({
      type: SET_TEXT,
      setText: {
        id,
        summaryType,
        userText,
        aiText,
        disclaimerText,
      },
    });
  }, [text, content, section.content, id, summaryType, setReportState]);

  /** @type {React.ChangeEventHandler<HTMLTextAreaElement>} */
  const handleTextChange = useCallback(({ target }) => {
    const newText = target.value;
    setText(newText);
  }, []);

  useEffect(() => {
    const { aiText, disclaimerText } = section.content;
    let subText = aiText ?? '';
    if (content) subText += content;
    if (disclaimerText) subText += disclaimerText;
    setText(subText);
  }, [content, section.content]);

  useEffect(() => {
    if (!isEditMode && text && documentRef) {
      const html = text2html(text);
      const containerDiv = documentRef.querySelector(
        '#export-report-sections-container',
      );
      const textareaLines = getTextareaLines(html, containerDiv);
      const initialPage = document.createElement('div');
      initialPage.id = 'output-page-1';
      const linesByPage = Array.from(
        textareaLines.querySelectorAll('.Line'),
      ).reduce(getLinesByPage, [initialPage]);
      setPages(linesByPage.slice(0, MAX_PAGES));
    }

    if (!text) {
      setPages([]);
    }
  }, [isEditMode, documentRef, section, text]);

  const fieldId = `${section.id}-text-field`;

  useEffect(() => {
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldId]: isEditMode,
      [FOOTER_ID]:
        isFooterDisclaimerInEditMode &&
        !!reportState[secId.DISCLAIMER].content.footerDisclaimer,
    }));
  }, [
    setEditableFields,
    isEditMode,
    fieldId,
    isFooterDisclaimerInEditMode,
    reportState,
  ]);

  const submitFeedback = () => {
    sendFeedback({
      rating: feedbackRating,
      comment: feedbackText,
      promptLogTrailId: aiConversationMetadata.logTrailId,
      scenarioId,
      companyId,
    });
  };

  return (
    <div ref={ref} id={section.id}>
      {pages.length && !isEditMode ? (
        pages.map((page, index) => (
          <>
            <Page id={`${section.id}-page-${index}`} key={page.id}>
              {section.type !== summaryTypes.DISCLAIMER && (
                <SectionHeader section={section} isFirstPage={index === 0} />
              )}
              <div className="Summary_Content">
                {isEditControlVisible && index === 0 && (
                  <EditButton
                    sectionId={section.id}
                    onClick={() => setIsEditMode(true)}
                  />
                )}
                {/* eslint-disable-next-line react/no-danger -- predates description requirement */}
                <div dangerouslySetInnerHTML={{ __html: page.innerHTML }} />
              </div>
              {showFeedbackOptions && index === 0 && (
                <FeedbackSection onClick={setFeedbackRating} />
              )}
              <Footer
                isEditMode={isFooterDisclaimerInEditMode}
                setIsEditMode={setIsFooterDisclaimerInEditMode}
              />
            </Page>
          </>
        ))
      ) : (
        <Page id={`${section.id}-page-1`}>
          {section.type !== summaryTypes.DISCLAIMER && (
            <SectionHeader
              section={section}
              isFirstPage
              headerControl={
                isFPAFull &&
                summaryType === summaryKey.EXECUTIVE_SUMMARY &&
                isEditMode ? (
                  <AiGenerateButton
                    disabled={isAiSummaryGenerating || !hasActiveReportSection}
                    onClick={() =>
                      handleAiSummaryGeneration(
                        summaryKey.EXECUTIVE_SUMMARY,
                        true,
                      )
                    }
                  />
                ) : null
              }
            />
          )}
          <div className="Summary_Content">
            {isEditControlVisible && !isEditMode && (
              <EditButton
                sectionId={section.id}
                onClick={() => setIsEditMode(true)}
              />
            )}
            {isEditMode && (
              <>
                <button
                  className="Summary_Button"
                  data-testid={`${section.id}-save-text-button`}
                  onClick={() => {
                    persistText();
                    setIsEditMode(false);
                  }}
                >
                  <CheckmarkSmallIcon className="ExportableReport_Icon" />
                  Confirm Edit
                </button>
                <div className="Summary_TextContainer">
                  <TextAreaAutosize
                    minRows={initialRows}
                    id={fieldId}
                    value={text}
                    data-testid={`${section.id}-content-field`}
                    className={classNames(
                      'Summary_ContentField',
                      'FormField_Input',
                      'FormField_Input-area',
                    )}
                    onBlur={persistText}
                    onChange={handleTextChange}
                    maxLength={CHARACTER_LIMIT}
                    placeholder={
                      section.type === summaryTypes.DISCLAIMER
                        ? 'Add your disclaimer here..'
                        : 'This is your financial summary report'
                    }
                  />
                  <LoadingIndicator
                    isVisible={isAiSummaryGenerating}
                    position={{ top: '.5rem', left: '.5rem' }}
                    text="Generating..."
                    secondaryText="Please stand by, as this may take 1-2 minutes"
                  />
                </div>
              </>
            )}
          </div>
          {showFeedbackOptions && (
            <FeedbackSection onClick={setFeedbackRating} />
          )}
          <Footer
            isEditMode={isFooterDisclaimerInEditMode}
            setIsEditMode={setIsFooterDisclaimerInEditMode}
          />
        </Page>
      )}
      <PermissionModal
        onSave={handleChatGPTPromptPermission}
        isLoading={isUserPreferenceLoading}
        open={showPermission}
        onClose={() => setShowPermission(false)}
      />
      {error && (
        <ModalConfirmation
          id="generate-summary-error-modal"
          title="Error"
          onAction={onErrorModalClose}
          actionBtnTxt="Continue"
        >
          {error}
        </ModalConfirmation>
      )}

      <AiFeedbackModal
        variant={feedbackRating}
        open={!!feedbackRating}
        onClose={() => setFeedbackRating(null)}
        onChange={({ target }) => setFeedbackText(target.value)}
        text={feedbackText}
        onSave={submitFeedback}
        loading={isFeedbackLoading}
      />
    </div>
  );
};

export default Summary;
