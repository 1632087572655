import {
  DECIMAL_PLACES_ERROR,
  MAX_LIMIT_ERROR,
  MIN_LIMIT_ERROR,
} from '@/constants/errors';

export function isEmptyOrNull(value) {
  return /(null|undefined|^$)/.test(value);
}

/**
 * A deprecated function to check for defined values. Use isEmptyOrNull instead
 *
 * @deprecated
 */
export function isDefined(value) {
  return value === 0 || value === false || !!value;
}

export function isPercentageValid(percentage) {
  return !isEmptyOrNull(percentage) && +percentage <= 100 && +percentage >= 0;
}

export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape -- predates description requirement
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = re.test(String(email).toLowerCase());
  return isValid;
};

export const isEndDateBeforeStartDate = (startDate, endDate) => {
  if (!endDate || !startDate) {
    return false;
  }
  const startDateMs = new Date(startDate).getTime();
  const endDateMs = new Date(endDate).getTime();

  return endDateMs < startDateMs;
};

/**
 * A function for validating the lowerbound of a number
 *
 * @param {Object} params An object containing a value to be validated and its
 *   minLimit
 * @returns {Object} A new object with updated validation message
 */
export const validateLowerBound = (params) => {
  return {
    ...params,
    msg:
      /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
      isDefined(params.minLimit) && Number(params.value) < params.minLimit
        ? MIN_LIMIT_ERROR
        : params.msg,
  };
};

/**
 * A function for validating the upperbound of a number
 *
 * @param {Object} params An object containing a value to be validated and its
 *   maxLimit
 * @returns {Object} A new object with updated validation message
 */
export const validateUpperBound = (params) => {
  return {
    ...params,
    msg:
      !isEmptyOrNull(params.maxLimit) && Number(params.value) > params.maxLimit
        ? MAX_LIMIT_ERROR
        : params.msg,
  };
};

/**
 * A function for validating max decimal places
 *
 * @param {Object} params An object containing a value to be validated,
 *   maxDecimals and default validation msg
 * @returns {Object} A new object instance with updated validation message
 */
export const validateDecimalPlaces = (params) => {
  if (!params.maxDecimals) {
    return params;
  }

  const DECIMAL_NUMBER_REGEX = new RegExp(`^\\d{1,${params.maxDecimals}}$`);
  const decimalPart = params.value.split('.')[1];
  if (decimalPart === undefined) {
    return params;
  }
  return {
    ...params,
    msg: DECIMAL_NUMBER_REGEX.test(decimalPart)
      ? params.msg
      : DECIMAL_PLACES_ERROR,
  };
};

/**
 * A helper for asserting a default case in an exhaustive switch is unreachable
 *
 * @type {(x: never) => never}
 */
export const assertNoCase = (x) => {
  console.warn('Unknown type', x);
  return /** @type {never} */ (null);
};

/**
 * A helper for validating a website url
 *
 * @type {(value: string) => boolean}
 */
export const isValidWebsite = (value) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // validate fragment locator
  return !!urlPattern.test(value);
};
