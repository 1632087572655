// @ts-check
import { useEffect, useMemo, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import NotesIcon from '@bill/cashflow.assets/notes';
import UsersIcon from '@bill/cashflow.assets/users';
import {
  createScenarioAction,
  duplicateScenarioAction,
  updateScenarioAction,
} from '@/actions/scenario';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';
import ColorPicker from '@/components/common/ColorPicker';
import FormField from '@/components/common/FormField';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import { SCENARIO_COLORS } from '@/constants/colors';
import {
  BIPAAS_QUICKBOOKS,
  CODAT_QUICKBOOKS,
  INTEGRATIONS_TEXT,
} from '@/constants/integrations';
import {
  ADD,
  EDIT,
  DUPLICATE,
  scenarioActionType,
  scenarioTypes,
  scenarioTypesText,
  CREATED,
  SCENARIO,
  DUPLICATED,
} from '@/constants/scenario';
import { REFRESH_TOKEN_EXPIRED } from '@/constants/settings';
import { classNames, debounce } from '@/helpers';
import './ScenarioNotes.scss';

const WARNING_MODAL_TITLE = 'Potential Missing Data';
const DELAY = 1000;

/** @typedef {import('@/constants/scenario').ScenarioActionType} ActionTypes */
/** @typedef {import('@/constants/scenario').Mode} Mode */
/** @typedef {import('@/reducers/companies').Integration} Integration */

/**
 * @typedef {{ type: ActionTypes['SET_SCENARIO_NAME']; payload: string }
 *   | { type: ActionTypes['SET_SCENARIO_COLOR']; payload: string }
 *   | { type: ActionTypes['SET_SCENARIO_NOTES']; payload: string }
 *   | { type: ActionTypes['SET_ENABLED_INTEGRATIONS']; payload: string[] }} ScenarioReducerDipatchType
 */
/**
 * @typedef {{
 *   scenarioId: number;
 *   name: string;
 *   color: string;
 *   enabledIntegrations: string[];
 *   notes: string | null;
 *   type: import('@/constants/scenario').scenarioTypes;
 *   baseScenarioType: import('@/constants/scenario').scenarioTypes;
 * }} ScenarioState
 */
/**
 * @type {(
 *   state: ScenarioState,
 *   action: ScenarioReducerDipatchType,
 * ) => ScenarioState}
 */

const scenarioReducer = (state, action) => {
  switch (action.type) {
    case scenarioActionType.SET_SCENARIO_NAME:
      return { ...state, name: action.payload };
    case scenarioActionType.SET_SCENARIO_COLOR:
      return { ...state, color: action.payload };
    case scenarioActionType.SET_SCENARIO_NOTES:
      return { ...state, notes: action.payload };
    case scenarioActionType.SET_ENABLED_INTEGRATIONS:
      return { ...state, enabledIntegrations: action.payload };
    default:
      throw new Error('Unknown state change');
  }
};

/**
 * @typedef {{
 *   name: string;
 *   createdType: string;
 * }} onScenarioNotify
 */

/**
 * @typedef {{
 *   createScenario: (state: ScenarioState) => Promise;
 *   duplicateScenario: (state: ScenarioState) => Promise;
 *   updateScenario: (state: ScenarioState) => void;
 *   integrations: Integration[];
 *   scenario: ScenarioState;
 *   mode: Mode[keyof Mode];
 *   onClose: () => void;
 *   onShare: () => void;
 *   onScenarioNotify: (state: onScenarioNotify) => void;
 * }} CreateEditScenarioFormTypes
 */

/** @type {(props: CreateEditScenarioFormTypes) => React.ReactElement} */
const CreateEditScenarioForm = ({
  createScenario,
  duplicateScenario,
  updateScenario,
  integrations,
  scenario,
  mode,
  onClose,
  onShare,
  onScenarioNotify,
}) => {
  const [updatedScenario, setUpdatedScenario] = useReducer(
    scenarioReducer,
    scenario,
  );
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const companyIntegrations = useMemo(
    () =>
      integrations.filter(({ connected }) => connected).map(({ type }) => type),
    [integrations],
  );
  const isUnauthorizedIntegrations = useMemo(
    () =>
      Boolean(
        integrations.find(({ status }) => status === REFRESH_TOKEN_EXPIRED),
      ),
    [integrations],
  );

  const isBudget = scenario.type === scenarioTypes.BUDGET;
  const scenarioTypeText = scenarioTypesText[scenario.type];

  const handleScenarioNotify = useMemo(
    () =>
      debounce((name, createdType) => {
        onScenarioNotify({
          name,
          createdType,
        });
      }, DELAY),
    [onScenarioNotify],
  );

  useEffect(() => {
    if (!scenario.scenarioId) {
      setUpdatedScenario({
        type: 'SET_ENABLED_INTEGRATIONS',
        payload: companyIntegrations,
      });
    }
  }, [companyIntegrations, scenario]);

  const handleSave = async () => {
    try {
      setError(null);
      setLoading(true);

      switch (mode) {
        case ADD:
          await createScenario(updatedScenario).then(() => {
            handleScenarioNotify(SCENARIO, CREATED);
          });
          break;
        case EDIT:
          await updateScenario(updatedScenario);
          break;
        case DUPLICATE:
          await duplicateScenario(updatedScenario).then(() => {
            handleScenarioNotify(
              updatedScenario.type.toLowerCase(),
              DUPLICATED,
            );
          });

          break;
        default:
      }
      onClose();
    } catch (e) {
      setLoading(false);
      setError(e?.response?.data?.error?.errorMessage || e.message);
    }
  };

  const toggleIntegration = (integration) => {
    const { enabledIntegrations } = updatedScenario;
    const payload = enabledIntegrations.includes(integration)
      ? enabledIntegrations.filter((name) => name !== integration)
      : enabledIntegrations.concat(integration);
    setUpdatedScenario({
      type: 'SET_ENABLED_INTEGRATIONS',
      payload,
    });
  };

  const onWarningConfirmation = () => {
    setShowWarning(false);
    handleSave();
  };

  const isDuplication =
    (scenario.baseScenarioType === scenarioTypes.BUDGET &&
      scenario.type === scenarioTypes.BUDGET) ||
    (scenario.baseScenarioType === scenarioTypes.SCENARIO &&
      scenario.type === scenarioTypes.SCENARIO);

  let heading = `Add New ${scenarioTypeText}`;
  if (mode === EDIT) {
    heading = `Edit ${scenarioTypeText}`;
  } else if (mode === DUPLICATE && isDuplication) {
    heading = scenario.name;
  }

  return (
    <>
      {showWarning && (
        <ModalConfirmation
          open={showWarning}
          title={WARNING_MODAL_TITLE}
          onCancel={() => setShowWarning(false)}
          onAction={onWarningConfirmation}
          actionBtnTxt="Confirm"
        >
          We will not be able to copy all your data to the new{' '}
          {scenarioTypeText.toLowerCase()} if you have a deauthorized
          integration. Please authorize the integration to ensure all data is
          available in the new {scenarioTypeText.toLowerCase()}.
        </ModalConfirmation>
      )}
      <div className="ModalBase_Header">
        <h2
          className="ModalBase_Heading"
          data-testid="create-edit-duplicate-heading"
        >
          {heading}
        </h2>
        {mode === EDIT && (
          <UsersIcon role="button" className="Share_Icon" onClick={onShare} />
        )}
      </div>
      <div className="ModalBase_Content">
        {error && (
          <p className="Scenario_ErrorAlert">
            <span className="Scenario_ErrorAlert-title">Error </span> - {error}
          </p>
        )}
        <label htmlFor="ScenarioName" className="Label">
          {scenarioTypeText} Name
        </label>
        <FormField
          id="ScenarioName"
          onChange={(event) =>
            setUpdatedScenario({
              type: 'SET_SCENARIO_NAME',
              payload: event.target.value,
            })
          }
          validate={(value) =>
            !value.trim().length && `${scenarioTypeText} name is required`
          }
          value={updatedScenario.name}
        />
        <ColorPicker
          className="CreateEditDuplicate_ColorPicker"
          label={`Please choose a color for your ${scenarioTypeText.toLowerCase()}`}
          colors={SCENARIO_COLORS}
          onClick={(colorHex) =>
            setUpdatedScenario({
              type: 'SET_SCENARIO_COLOR',
              payload: colorHex,
            })
          }
          selectedColor={updatedScenario.color}
        />
        {companyIntegrations.length > 0 &&
          scenario.type !== scenarioTypes.BUDGET && (
            <>
              <p
                className={classNames(
                  'IntegrationsEnable_Label',
                  mode === EDIT ? 'IntegrationsEnable_Label-disabled' : '',
                )}
              >
                Please select the integrations from which to receive updates in
                this scenario.
              </p>
              <div className="IntegrationsEnable_CheckboxWrapper">
                {companyIntegrations.map((integration) => {
                  return (
                    <Checkbox
                      key={integration}
                      id={`integration-${integration}`}
                      className="IntegrationsEnable_Checkbox"
                      onChange={() => toggleIntegration(integration)}
                      checked={
                        !isBudget &&
                        updatedScenario.enabledIntegrations.includes(
                          integration,
                        )
                      }
                      disabled={
                        mode === EDIT ||
                        integration === CODAT_QUICKBOOKS ||
                        integration === BIPAAS_QUICKBOOKS
                      }
                    >
                      {INTEGRATIONS_TEXT[integration]}
                    </Checkbox>
                  );
                })}
              </div>
            </>
          )}
        <label htmlFor="scenario-notes-textarea" className="Label">
          Notes
        </label>
        <div className="FormField">
          <textarea
            rows={4}
            id="scenario-notes-textarea"
            value={updatedScenario.notes || ''}
            data-testid="scenario-notes-textarea"
            className="FormField_Input FormField_Input-area"
            onChange={({ target }) => {
              setUpdatedScenario({
                type: 'SET_SCENARIO_NOTES',
                payload: target.value.trimStart(),
              });
            }}
            maxLength={500}
          />
          {updatedScenario.notes && (
            <div className="ScenarioNotes_MessageWrapper">
              <NotesIcon className="NotesIcon" aria-hidden="true" />
              <p
                id="charactersLeftInNotes"
                className="ScenarioNotes_RemainingChars"
                data-testid="charactersLeftInNotes"
              >
                {500 - updatedScenario.notes.length} characters left
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="ModalBase_Footer">
        <Button
          data-testid="cancel-button-modal"
          className="Button-cancelLink"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className="Button-Add"
          data-testid="action-button-modal"
          disabled={!updatedScenario.name.trim().length}
          loading={isLoading}
          onClick={
            isUnauthorizedIntegrations && mode === DUPLICATE
              ? () => setShowWarning(true)
              : handleSave
          }
        >
          {mode === EDIT ? 'Save' : 'Add'}
        </Button>
      </div>
    </>
  );
};

function mapStateToProps({ settings }) {
  return {
    integrations: settings.integrations,
  };
}

export default connect(mapStateToProps, {
  createScenario: createScenarioAction,
  duplicateScenario: duplicateScenarioAction,
  updateScenario: updateScenarioAction,
})(CreateEditScenarioForm);
