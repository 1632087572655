// @ts-check
import { ReactComponent as OfficeDeskIcon } from '@/assets/images/office_desk.svg';
import './OnboardingError.scss';

/** @type {(props: { subText: string }) => React.ReactElement} */
export default function BillOnboardingError({ subText }) {
  return (
    <div className="OnboardingError" data-testid="error-onboarding">
      <h1 className="OnboardingError_Title">Oops, something went wrong</h1>
      <OfficeDeskIcon className="OnboardingError_Image" />
      <p className="OnboardingError_Text">{subText}</p>
    </div>
  );
}
