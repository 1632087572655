// @ts-check
import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector, useDispatch } from 'react-redux';
import { getIntegrationsStatusAction } from '@/actions/settings';
import Integrations from '@/components/common/Integrations';
import useIntegrationStatesPersisted from '@/hooks/useIntegrationStates/useIntegrationStatesPersisted';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import CardWrapper from './CardWrapper';
import Header from './Header';

/** @type {React.FC} */
const IntegrationsSettings = () => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const companySettings = useSelector(
    ({ settings }) => settings.companySettings,
  );
  const selectedCompany = useSelector(getSelectedCompany);

  const connectionError = useSelector(
    ({ settings }) => settings.connectionError,
  );

  useEffect(() => {
    dispatch(getIntegrationsStatusAction());
  }, [dispatch]);

  const integrations = useSelector(({ settings }) => settings.integrations);
  const integrationsConnected = useSelector(
    ({ settings }) => settings.integrationsConnected,
  );
  const integrationsUnauthorized = useSelector(
    ({ settings }) => settings.integrationsUnauthorized,
  );
  const isLoading = useSelector(
    ({ componentLoading }) => componentLoading.integrationStatuses,
  );
  const integrationsAllowed = useSelector(
    ({ settings }) => settings.integrationsAllowed,
  );
  const { buttonStates, setButtonStates } =
    useIntegrationStatesPersisted(integrationsAllowed);

  return (
    <CardWrapper className="IntegrationsPanel">
      <Header title="Integrations" data-testid="integration-edit" />
      <Integrations
        isLoading={isLoading}
        selectedCompany={selectedCompany}
        integrations={integrations}
        integrationsConnected={integrationsConnected}
        integrationsUnauthorized={integrationsUnauthorized}
        companySettings={companySettings}
        connectionError={connectionError}
        buttonStates={buttonStates}
        onChangeBtnStates={setButtonStates}
      />
    </CardWrapper>
  );
};

export default IntegrationsSettings;
