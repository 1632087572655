import { publishToTopic, subscribeToTopic } from '@/actions/shared';

/**
 * Action to subscribe new conversation topic
 *
 * @type {(
 *   conversationId: string,
 *   callback: (
 *     payload: import('@/components/Copilot/CopilotSidebar').ChatWebSocketDto,
 *   ) => void,
 * ) => import('@/types/extend-redux-toolkit').AppThunk<
 *   Promise<import('@stomp/stompjs').StompSubscription>
 * >}
 */
export const subscribeToNewConversationTopic = (conversationId, callback) => {
  const topic = `chat/${conversationId}`;
  return (dispatch) => {
    return dispatch(
      subscribeToTopic(
        topic,
        (
          /** @type {import('@/components/Copilot/CopilotSidebar').ChatWebSocketDto} */ payload,
        ) => {
          callback(payload);
        },
      ),
    );
  };
};

/**
 * @typedef {{
 *   message: string;
 *   userId: number;
 *   companyId: number;
 *   scenarioId: number;
 *   isRetry?: boolean;
 * }} MessagePayload
 */

/**
 * Action to publish message to copilot
 *
 * @type {(
 *   conversationId: string,
 *   message: MessagePayload,
 * ) => import('@/types/extend-redux-toolkit').AppThunk<Promise<void>>}
 */
export const publishMessage = (conversationId, message) => {
  const topic = `chat/${conversationId}`;
  return (dispatch) => {
    return dispatch(publishToTopic(topic, message));
  };
};
