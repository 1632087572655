// @ts-check

/* eslint-disable import/prefer-default-export -- predates description requirement */
import { formatFromNow } from '@/helpers/dateFormatter';

/**
 * Returns a formetted date for integration status
 *
 * @example
 *   getLastUpdatedStatusDate(date);
 *
 * @type {(lastUpdatedDate: Date | string | number) => string}
 */
export const getLastUpdatedStatusDate = (lastUpdatedDate) => {
  return formatFromNow(new Date(lastUpdatedDate).getTime());
};
