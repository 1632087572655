import { PropTypes } from 'prop-types';
import LetterIcon from '@/components/common/LetterIcon';
import { classNames } from '@/helpers';
import getScenarioColor from '@/helpers/getScenarioColor';

/**
 * CompanyLetterIcon component to display initial of company name and set
 * background color wrt to Finmark color palette.
 *
 * @example
 *   <CompanyLetterIcon companyName={company.name} companyId={company.id} />;
 *
 * @param {Object} name company name to display
 * @param {Function} IconElement an icon to display along with company name
 * @param {Function} onSelect A function to select company
 */
export default function CompanyLetterIcon({
  companyName,
  companyId,
  className,
}) {
  return (
    <LetterIcon
      data-testid={`companyLetter-${companyId}`}
      color={getScenarioColor(companyId)}
      string={companyName}
      className={classNames('LetterIcon-circle', className)}
    />
  );
}

CompanyLetterIcon.propTypes = {
  /** Company Name */
  companyName: PropTypes.string.isRequired,
  /** Company ID to set unique color */
  companyId: PropTypes.number.isRequired,
  /** Additional class(es) to apply to the Company Letter Icon */
  className: PropTypes.string,
};
