import { useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import FormulaIcon from '@bill/cashflow.assets/formula';
import PlusIcon from '@bill/cashflow.assets/plus';
import TagIcon from '@bill/cashflow.assets/tag';
import ContextMenu from '@/components/common/ContextMenu';
import DashboardLink from '@/components/common/DashboardLink';
import IconButton from '@/components/common/IconButton';
import Link from '@/components/common/Link';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import NotificationBanner, {
  notificationTypes,
} from '@/components/common/NotificationBanner';
import { VARIABLES_PATH } from '@/constants/pages';
import { units } from '@/constants/variables';
import { classNames } from '@/helpers';
import { getISODate } from '@/helpers/dateFormatter';
import { unitMap } from '@/helpers/units';
import { createMetricVariable } from '@/services/metricsService';

const Tag = ({ tag }) => {
  return (
    <div className="MetricItem_Tag">
      <TagIcon className="MetricItem_TagIcon" />
      <span className="MetricItem_TagName">{tag.name}</span>
    </div>
  );
};

const MetricItem = ({
  id,
  unit,
  name,
  modifiedDate,
  summary,
  tags,
  link,
  displayFormula,
}) => {
  const { UnitSymbol, label } = unitMap[unit];

  const [showFormula, setShowFormula] = useState(false);
  const [error, setError] = useState();
  const [metricNotify, setMetricNotify] = useState(null);
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);

  const createVariable = async ({ createChart }) => {
    setMetricNotify(null);
    try {
      const result = await createMetricVariable(
        scenarioId,
        { metricId: id },
        createChart,
      );
      if (result.data.success) {
        setMetricNotify({ withChart: createChart });
      }
    } catch (e) {
      setError(e.response?.data?.error?.errorMessage || e.message);
    }
  };

  return (
    <>
      <div className="MetricItem_Wrapper">
        <div className="MetricItem_UnitWrapper">
          <UnitSymbol
            className={classNames(
              'MetricItem_Unit',
              label === units.CURRENCY && 'MetricItem_Unit-currency',
            )}
            aria-label={label}
            data-testid={`${name}-unit`}
          />
        </div>
        <div className="MetricItem_Header">
          <h1 className="MetricItem_Name">{name}</h1>
          <ContextMenu
            Icon={PlusIcon}
            placement="bottom-start"
            data-testid="create-metric-variable-chart-menu"
          >
            <ContextMenu.Option
              onClick={() => createVariable({ createChart: false })}
              data-testid="create-metric-variable"
            >
              + Variable only
            </ContextMenu.Option>
            <ContextMenu.Option
              onClick={() => createVariable({ createChart: true })}
              data-testid="create-metric-variable-and-chart"
            >
              + Variable & chart
            </ContextMenu.Option>
          </ContextMenu>
        </div>
        <div className="MetricItem_FormulaDateWrapper">
          <IconButton
            aria-pressed={showFormula}
            data-testid="formula-preview-icon"
            className="MetricItem_FormulaIcon MetricItem_FormulaIconButton"
            onClick={() => setShowFormula(!showFormula)}
            Icon={FormulaIcon}
            label="Show formula"
          />
          <div className="MetricItem_Date">
            Last Updated: {getISODate(modifiedDate)}
          </div>
        </div>
        <div className="MetricItem_Summary">{summary}</div>
        {link && (
          <div className="MetricItem_LinkWrapper">
            <a href={link} className="link" target="_blank" rel="noreferrer">
              Learn more
            </a>
          </div>
        )}
        {showFormula && (
          <div className="MetricItem_FormulaWrapper">
            <FormulaIcon className="MetricItem_FormulaIcon" />
            <div className="MetricItem_FormulaEqualSign">=</div>
            <div>{displayFormula}</div>
          </div>
        )}
        <div className="MetricItem_TagsWrapper">
          {tags.map((tag) => (
            <Tag key={tag.id} tag={tag} />
          ))}
        </div>
      </div>
      {error && (
        <ModalConfirmation
          id="cannot-add-variable-modal"
          onAction={() => setError('')}
          title="Cannot create variable."
          actionBtnTxt="I understand"
        >
          {error}
        </ModalConfirmation>
      )}
      {!!metricNotify && (
        <NotificationBanner
          onCloseClick={() => setMetricNotify(null)}
          type={notificationTypes.SUCCESS}
        >
          {metricNotify.withChart ? (
            <>
              A new chart has been created for the selected metric in the
              'Custom Charts' section of your Dashboard. You can view the chart
              now by clicking{' '}
              <DashboardLink className="NotificationBanner_Link">
                here.
              </DashboardLink>{' '}
              Alternatively, you can view the custom variable now by clicking{' '}
              <Link to={VARIABLES_PATH} className="NotificationBanner_Link">
                here
              </Link>
            </>
          ) : (
            <>
              A new custom variable has been created for the selected metric in
              the variables section. You can view the custom variable now by
              clicking{' '}
              <Link to={VARIABLES_PATH} className="NotificationBanner_Link">
                here
              </Link>
            </>
          )}
        </NotificationBanner>
      )}
    </>
  );
};

export default MetricItem;
