import { useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { VENDOR_SPEND } from '@/cacheKeys';
import Checkbox from '@/components/common/Checkbox';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import { registeredFeatureFlags } from '@/constants/features';
import { varianceText } from '@/constants/variables';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import { GRID_OPTIONS } from '@/pages/Reports/constants';
import transformDataForGrid from '@/reducers/helpers/transformDataForGrid';
import { getVendorSpend } from '@/services/reports.service';
import VendorSpendFilterButtons from './VendorSpendFilterButtons';
import VendorSpendTooltipCellRenderer from './VendorSpendTooltipCellRenderer';
import cellRendererSelector from './cellRendererSelector';

const SPREADSHEET_ID = 'vendor-spend';

const VendorSpend = ({
  apiRef,
  showVarianceAmount,
  setShowVarianceAmount,
  showVariancePercentage,
  setShowVariancePercentage,
}) => {
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );
  const scenarioIds = useSelectedScenarioIds();

  const isShowPercentVarianceEnabled = useFeatureFlags(
    registeredFeatureFlags.SHOW_VARIANCE_PERCENT,
  );

  const [groupByDepartment, setGroupByDepartment] = useState(false);

  const { data, isLoading } = useQuery(
    [
      VENDOR_SPEND,
      scenarioIds,
      startDate,
      endDate,
      groupByDepartment,
      timePeriod,
    ],
    async ({ signal }) => {
      const result = await getVendorSpend({
        signal,
        startDate,
        endDate,
        scenarioIds,
        groupByDepartment,
        timePeriod,
      });
      return transformDataForGrid(result.data.data.children);
    },
    { staleTime: 60000 },
  );

  const colDefs = useMemo(
    () => [
      {
        headerName: 'Vendor',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          suppressCount: true,
          innerRendererSelector: ({ data: rowData }) => ({
            component:
              rowData.id === 'TotalNon-PayrollExpenses'
                ? VendorSpendTooltipCellRenderer
                : undefined,
          }),
        },
        field: 'title',
        headerComponent: HeaderRenderer,
        headerComponentParams: { enableExpandAll: true },
        minWidth: 300,
        showRowGroup: true,
      },
    ],
    [],
  );

  const hasComparison = scenarioIds.length > 1;

  return (
    <>
      <SpreadsheetToolbar>
        <VendorSpendFilterButtons
          groupByDepartment={groupByDepartment}
          onClick={setGroupByDepartment}
        />
        <div className="SpreadsheetToolbar_ControlGroup">
          Options:
          <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
        </div>
        {hasComparison && (
          <>
            <Checkbox
              id="variance-toggle"
              checked={showVarianceAmount}
              className="SpreadsheetToolbar_ControlGroup"
              onChange={() => setShowVarianceAmount((prevState) => !prevState)}
            >
              {varianceText.SHOW_VARIANCE_AMOUNT}
            </Checkbox>
            {isShowPercentVarianceEnabled && (
              <Checkbox
                id="variance-toggle"
                checked={showVariancePercentage}
                className="SpreadsheetToolbar_ControlGroup"
                onChange={() =>
                  setShowVariancePercentage((prevState) => !prevState)
                }
              >
                {varianceText.SHOW_VARIANCE_PERCENTAGE}
              </Checkbox>
            )}
          </>
        )}
      </SpreadsheetToolbar>
      <div className="ReportsGrid">
        <MonthlySpreadsheet
          {...GRID_OPTIONS}
          ref={apiRef}
          columnDefs={colDefs}
          data-testid={SPREADSHEET_ID}
          enableComparison
          loading={isLoading}
          data={data}
          rendererSelector={cellRendererSelector}
          showVarianceAmount={showVarianceAmount}
          showVariancePercentage={showVariancePercentage}
        />
      </div>
    </>
  );
};

export default VendorSpend;
