import AddNewIcon from '@bill/cashflow.assets/add-new';
import { PropTypes } from 'prop-types';
import ProductTypeFilter from '@/components/ProductTypeFilter/ProductTypeFilter';
import Button from '@/components/common/Button';
import './CompanyRegisterCard.scss';

/**
 * Show CompanyRegisterCard to allow user to register for new Company.
 *
 * @example
 *   <CompanyRegisterCard company={company} onSelect={onCompanySelect} />;
 *
 * @param {Function} onRegister A function to start registration process for new
 *   company
 */

export default function CompanyRegisterCard({ onRegister }) {
  return (
    <div className="CompanyRegisterCard">
      <ProductTypeFilter>
        <div className="CompanyRegisterCard_Details">
          <span className="CompanyRegisterCard_Logo">
            <AddNewIcon />
          </span>

          <p className="CompanyRegisterCard_Label">Create a new Company</p>
        </div>
      </ProductTypeFilter>
      <Button
        data-testid="submit"
        className="Button Button-success"
        onClick={onRegister}
      >
        Register
      </Button>
    </div>
  );
}

CompanyRegisterCard.propTypes = {
  /** A function to select company */
  onRegister: PropTypes.func.isRequired,
};
