import {
  ON_LOAD,
  TOGGLE_CHART,
  TOGGLE_ALL_CHART,
  SET_FILTER_TEXT,
  SET_CHARTS,
  SET_NAME,
  RESET_SELECTIONS,
} from './constants';

const isSelectAllCharts = (charts, selectedCharts, chartType) => {
  return charts
    .filter(({ type }) => type === chartType)
    .every(({ key }) => Object.keys(selectedCharts).includes(key));
};

/**
 * @type {(
 *   state: import('./types').ChartListState,
 *   action: import('./types').ChartListAction,
 * ) => import('./types').ChartListState}
 */
const chartReducer = (state, action) => {
  switch (action.type) {
    case ON_LOAD: {
      const { onLoad } = action;
      return {
        ...state,
        charts: onLoad.charts,
        selectedCharts: onLoad.selectedCharts,
        selectedChartIds: onLoad.selectedChartIds,
        isSelectAll: onLoad.isSelectAll,
        dashboardName: onLoad.dashboardName,
      };
    }
    case RESET_SELECTIONS: {
      return {
        ...action.resetSelections,
      };
    }
    case TOGGLE_CHART: {
      const { toggledChart } = action;
      const newSelectedCharts = { ...state.selectedCharts };
      const removeChart = newSelectedCharts[toggledChart];
      const chart = state.charts.find(({ key }) => key === toggledChart);
      const chartType = chart?.type || '';
      if (removeChart) {
        delete newSelectedCharts[toggledChart];
      } else {
        newSelectedCharts[toggledChart] = {
          ...chart,
          meta: null,
        };
      }
      return {
        ...state,
        selectedCharts: newSelectedCharts,
        selectedChartIds: Object.keys(newSelectedCharts),
        isSelectAll: !state.filterText
          ? {
              ...state.isSelectAll,
              [chartType]: isSelectAllCharts(
                state.charts,
                newSelectedCharts,
                chartType,
              ),
            }
          : state.isSelectAll,
      };
    }
    case TOGGLE_ALL_CHART: {
      const { toggleAllChart } = action;
      const { charts, isSelectAll } = state;
      const newSelectedCharts = { ...state.selectedCharts };
      const { chartType } = toggleAllChart;

      if (!isSelectAll[chartType]) {
        charts
          .filter((chart) => chart.type === chartType)
          .forEach((filteredChart) => {
            newSelectedCharts[filteredChart.key] = {
              ...filteredChart,
              meta: null,
            };
          });
      } else {
        charts
          .filter((chart) => chart.type === chartType)
          .forEach(({ key }) => {
            delete newSelectedCharts[key];
          });
      }
      return {
        ...state,
        selectedCharts: newSelectedCharts,
        selectedChartIds: Object.keys(newSelectedCharts),
        isSelectAll: {
          ...state.isSelectAll,
          [chartType]: !state.isSelectAll[chartType],
        },
      };
    }
    case SET_NAME: {
      return {
        ...state,
        dashboardName: action.setName,
      };
    }
    case SET_FILTER_TEXT:
      return { ...state, filterText: action.filterText };
    case SET_CHARTS:
      return { ...state, charts: action.filteredCharts };
    default:
      return state;
  }
};

export default chartReducer;
