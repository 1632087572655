// @ts-check
import { useState, useRef, useCallback } from 'react';
import CashInOutExpandedSpreadsheet from '@/components/Charts/CashInExpandedSpreadsheet';
import ChartControls from '@/components/Charts/ChartControls';
import ChartLegend from '@/components/Charts/ChartLegend';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import ComponentLoader from '@/components/common/ComponentLoader';
import { CASH_IN_TYPE } from '@/constants/cashInOut';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import useDashboardPath from '@/hooks/useDashboardPath';
import useTypedSelector from '@/hooks/useTypedSelector';
import CashInChart from '@/pages/Dashboard/charts/CashInChart';
import { CHART_TITLE } from '@/pages/Dashboard/constants/cashIn';
import { getMetricValue } from '@/pages/Dashboard/helpers';

const SPREADSHEET_ID = 'cashIn-chart-table';

function CashInChartExpanded() {
  const { startDate, endDate } = useTypedSelector(({ shared }) => shared);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const dateChartRef = useRef();

  const dashboardPath = useDashboardPath();
  const kpiValue = getMetricValue(total, formatMonetary);
  /** @type {ReturnType<typeof useState<Highcharts.Chart>>} */
  const [chart, setChart] = useState();

  const onQueryStateChange = useCallback(([base]) => {
    setLoading(base.isLoading);
    setTotal(base.data.overallTotal);
  }, []);

  return (
    <>
      <section className="Panel Panel-toEdge">
        <ChartHeader back={dashboardPath} title={CHART_TITLE}>
          <div className="ChartHeader_KPIValue">
            {loading ? 'Awaiting Data' : kpiValue}
          </div>
          <div className="ChartHeader_KPI">
            <br />
            {formatRangeWithShortYear(startDate, endDate)}
          </div>
        </ChartHeader>
        <ComponentLoader loadingComponent="cashIn" paddingTop="0%" />
        <ChartToolbar className="Chart_Toolbar-withLegend">
          <ChartLegend chart={chart} data-testid="cashInAccounts-legend" />
          <ChartControls
            data-testid="cashin-chart-detail"
            chartRef={dateChartRef}
            title={CHART_TITLE}
            metricValue={formatMonetary(total)}
            label={formatRangeWithShortYear(startDate, endDate)}
          />
        </ChartToolbar>
        <div className="ExpandedView_ChartWrapper">
          <CashInChart
            exportBtn={dateChartRef}
            onChartCreated={setChart}
            onQueryStateChange={onQueryStateChange}
          />
        </div>
      </section>
      <CashInOutExpandedSpreadsheet
        title={CHART_TITLE}
        filter={CASH_IN_TYPE}
        data-testid={SPREADSHEET_ID}
      />
    </>
  );
}

export default CashInChartExpanded;
