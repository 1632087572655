// @ts-check
import ContextMenu from '@/components/common/ContextMenu';

/**
 * @typedef {{
 *   onIntegrationClick: (data: import('@/reducers/companies').Company) => void;
 *   onEdit: (companyId: number) => void;
 *   onRemove: () => void;
 *   data: import('@/reducers/companies').Company;
 * }} CompanyContextMenuRendererParams
 */

/**
 * Renders the company integration content
 *
 * @example
 *   <CompanyContextMenuRenderer onClose={onCloseFn} />;
 *
 * @type {(params: CompanyContextMenuRendererParams) => React.ReactElement}
 */

const CompanyContextMenuRenderer = ({
  data,
  onIntegrationClick,
  onEdit,
  onRemove,
}) => {
  const { id } = data;
  return (
    <ContextMenu data-testid={`${id}-console-contextMenu`}>
      <ContextMenu.Option
        onClick={() => onIntegrationClick(data)}
        data-testid={`${id}-integrate-console`}
      >
        <span className="ContextMenu_Label">Integrations</span>
      </ContextMenu.Option>
      <ContextMenu.Option
        onClick={() => onEdit(id)}
        data-testid={`${id}-edit-company`}
      >
        <span className="ContextMenu_Label">Edit Company</span>
      </ContextMenu.Option>
      <ContextMenu.Option
        danger
        onClick={onRemove}
        data-testid={`${id}-remove-company`}
      >
        <span className="ContextMenu_Label">Remove Company</span>
      </ContextMenu.Option>
    </ContextMenu>
  );
};

export default CompanyContextMenuRenderer;
