// @ts-check
import { useQuery } from '@tanstack/react-query';
import { ONBOARDING_STATUS } from '@/cacheKeys';
import isBillDomain from '@/helpers/isBillDomain';
import { getOnboardingStatus } from '@/services/onboarding.service';

/**
 * Fetches a company's onboarding status in FP&A
 *
 * @type {(props?: {
 *   enabled?: boolean;
 * }) => import('@tanstack/react-query').UseQueryResult<
 *   import('@/types/services/backend').CompanyOnboardingStatusDto
 * >}
 */
const useOnboardStatus = ({ enabled = true } = {}) => {
  return useQuery({
    queryKey: [ONBOARDING_STATUS],
    queryFn: async () => {
      const { data } = await getOnboardingStatus();
      return data.data;
    },
    staleTime: 30000,
    enabled: isBillDomain() && enabled,
    refetchOnWindowFocus: false,
  });
};

export default useOnboardStatus;
