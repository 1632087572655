/* eslint-disable-next-line no-restricted-imports -- predates description requirement */
import { Link as ReactRouterLink } from 'react-router-dom';

/**
 * An adapter for the React Router Link component. It is being preserved to make
 * reimplementing Cord easier
 *
 * @example
 *   <Link to="/">Login</Link>;
 *
 * @type {React.ReactNode<import('react-router-dom').LinkProps>}
 * @param {LinkProps} props - It accepts the same params as the Link component
 *   in React Router v5
 * @see https://v5.reactrouter.com/web/api/Link
 */

const Link = ReactRouterLink;
export default Link;
