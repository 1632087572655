import { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import { getCustomerMetric } from '@/components/Charts/helpers';
import InfoTooltip from '@/components/common/InfoTooltip';
import TogglePersistent from '@/components/common/TogglePersistent';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import useChartPreferences from '@/hooks/useChartPreferences';
import useDashboardPath from '@/hooks/useDashboardPath';
import CustomersNewChart, {
  CUSTOMERS_NEW_TOGGLE_ID,
} from './charts/CustomersNewChart';

const CHART_TITLE = 'New Customers';

const CustomersNew = ({ endDate, startDate }) => {
  const chartRef = useRef();
  const [newCustomers, setNewCustomers] = useState({});
  const showPercentage = useChartPreferences(CUSTOMERS_NEW_TOGGLE_ID);
  const dashboardPath = useDashboardPath();
  const onQueryStateChange = useCallback(({ data, isLoading }) => {
    return !isLoading && setNewCustomers(data);
  }, []);

  return (
    <section className="Panel Panel-toEdge">
      <ChartHeader back={dashboardPath} title={CHART_TITLE}>
        {showPercentage ? (
          <>
            <div className="ChartHeader_KPIValue">
              {newCustomers.forecastTermValuePoP}
              {newCustomers.tooltipText && (
                <InfoTooltip data-testid="customers-new-kpi-tooltip">
                  {newCustomers.tooltipText}
                </InfoTooltip>
              )}
            </div>
            <div className="ChartHeader_KPI">
              <span className="ChartHeader_Subtitle">Average for:</span>
              {formatRangeWithShortYear(startDate, endDate)}
            </div>
          </>
        ) : (
          <>
            <div className="ChartHeader_KPIValue">
              {newCustomers.currentValue}
            </div>
            <div className="ChartHeader_KPI">
              <br />
              {formatRangeWithShortYear(startDate, endDate)}
            </div>
          </>
        )}
      </ChartHeader>
      <ChartToolbar>
        <TogglePersistent
          id={CUSTOMERS_NEW_TOGGLE_ID}
          value={false}
          options={[
            { label: 'Customers', value: false },
            { label: 'Percentage', value: true },
          ]}
          data-testid="newCustomers-toggle"
        />
        <ChartControls
          chartRef={chartRef}
          title={CHART_TITLE}
          metricValue={
            showPercentage
              ? newCustomers.forecastTermValuePoP
              : getCustomerMetric(newCustomers.currentValue)
          }
          label={
            showPercentage
              ? `Average for: ${formatRangeWithShortYear(startDate, endDate)}`
              : ''
          }
          data-testid="new-customers-chart-export"
        />
      </ChartToolbar>
      <CustomersNewChart
        className="ExpandedView_ChartWrapper"
        exportBtn={chartRef}
        showPercentage={showPercentage}
        onQueryStateChange={onQueryStateChange}
      />
    </section>
  );
};

const mapStateToProps = ({ shared }) => {
  const { startDate, endDate } = shared;
  return {
    endDate,
    startDate,
  };
};

export default connect(mapStateToProps)(CustomersNew);
