import ContextMenu from '@/components/common/ContextMenu';
import { SECTION, SUBSECTION } from '@/constants/reports';

export default function ContextMenuRenderer({ data, node, onDeleteRow }) {
  const { type } = data;
  const { allChildrenCount } = node;

  let rowTypeText = type;
  if (rowTypeText === SECTION) {
    rowTypeText = SUBSECTION;
  }
  return (
    <ContextMenu data-testid={`${data.name}-${rowTypeText}-action`}>
      <ContextMenu.Option
        id={`delete-${rowTypeText}`}
        data-testid={`delete-${rowTypeText}`}
        danger
        onClick={() => onDeleteRow(data)}
        disabled={Boolean(allChildrenCount)}
      >
        Delete {rowTypeText}
      </ContextMenu.Option>
    </ContextMenu>
  );
}
