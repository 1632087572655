import { useEffect } from 'react';
import isBillDomain from '@/helpers/isBillDomain';

const BILL_META_DESCRIPTION =
  "Monitor and forecast cash flow with BILL's Planning product";
const FINMARK_META_DESCRIPTION =
  'Financial planning/modeling software for growing startups.';
const BILL_HEAD_TITLE =
  'BILL | Financial Operations platform for Businesses & Firms';
const FINMARK_HEAD_TITLE = 'Finmark';

/**
 * Change Favicon based on Bill domain
 *
 * @type {() => void}
 */
const usePageMetadata = () => {
  useEffect(() => {
    const faviconLarge = document.head.querySelector("link[sizes='32x32']");
    const favicon = document.head.querySelector("link[sizes='16x16']");
    const faviconApple = document.head.querySelector("link[sizes='180x180']");
    const metaDescription = document.head.querySelector(
      "meta[name='description']",
    );
    const title = document.head.querySelector('title');
    const isBill = isBillDomain();

    faviconLarge.href = isBill
      ? '/bill-favicon-32x32.svg'
      : '/favicon-32x32.png';
    favicon.href = isBill ? '/bill-favicon-16x16.svg' : '/favicon-16x16.png';

    faviconApple.href = isBill
      ? '/bill-apple-touch-icon.png'
      : '/apple-touch-icon.png';

    metaDescription.content = isBill
      ? BILL_META_DESCRIPTION
      : FINMARK_META_DESCRIPTION;

    title.innerText = isBill ? BILL_HEAD_TITLE : FINMARK_HEAD_TITLE;
  }, []);
};
export default usePageMetadata;
