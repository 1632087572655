/* eslint-disable no-console -- predates description requirement */
// eslint-disable-next-line import/no-cycle -- predates description requirement
import {
  getProductsAction,
  getProductRevenuesList,
  getTotalRevenueAction,
  getRevenueDetailsAction,
  setRevenueSourceRecordToBeEdit,
  setProductRecordToBeEdit,
  updateProductsList,
  getRevenueStreamsAction,
} from '@/actions/revenue';
import {
  ONE_TIME,
  ONE_TIME_CUSTOM_AMOUNT,
  ONE_TIME_MOM_GROWTH,
  REVENUE_MOM_GROWTH,
  SUBSCRIPTION_CUSTOM_AMOUNT,
  SUBSCRIPTION_MOM_GROWTH,
  REVENUE_CUSTOM_AMOUNT,
  STREAMS,
} from '@/constants/revenueStream';
import { NO_COMPARISON } from '@/constants/scenario';
import {
  getFormattedDateFromTimeStamp,
  getISODate,
} from '@/helpers/dateFormatter';
import { isEmptyOrNull } from '@/helpers/validators';
import { store } from '@/store';
import Api, { ApiV2 } from './Api';
import getByDateRange from './getByDateRange';

const getRevenueStreamPricingPlans = (revenueStream) =>
  revenueStream.plansAssociated.map((plan) => ({
    estimatedValue: plan.estimatedValue,
    productPricingPlanId: plan.id,
    id: plan.planId,
  }));

const getSubscriptionMoMParams = ({
  revenueStream,
  revenueAttributionType,
  estimateType,
}) => {
  return {
    name: revenueStream.revenueName,
    revenueAttributionType,
    monthlyGrowthRate: parseFloat(revenueStream.acquisitionGrowthRate),
    forecastStartDate: getISODate(revenueStream.startDate),
    forecastEndDate: revenueStream.endDate && getISODate(revenueStream.endDate),
    revenueConfiguration: null,
    revenueStreamProductPricingPlans:
      getRevenueStreamPricingPlans(revenueStream),
    estimateType,
  };
};

const getAdvanceRevenueStreamParams = ({
  scenarioId,
  revenueStream,
  revenue,
  revenueAttributionType,
}) => {
  const {
    newMrrDisplayFormula,
    newCustomersDisplayFormula,
    churnedMrrDisplayFormula,
    churnedCustomersDisplayFormula,
    expansionMrrDisplayFormula,
    contractionMrrDisplayFormula,
    adjustmentsToMrrDisplayFormula,
    expansionCustomersDisplayFormula,
    contractionCustomersDisplayFormula,
    adjustmentsToCustomersDisplayFormula,
  } = revenueStream;
  return {
    scenarioId,
    revenueAttributionType,
    name: revenueStream.revenueName,
    forecastStartDate: getFormattedDateFromTimeStamp(revenueStream.startDate),
    forecastEndDate: !isEmptyOrNull(revenueStream.endDate)
      ? getFormattedDateFromTimeStamp(revenueStream.endDate)
      : null,
    revenueStreamProductPricingPlans: revenueStream.plansAssociated.map(
      (plan) => ({
        estimatedValue: 0, // not used in this revenue stream
        productPricingPlanId: plan.id,
      }),
    ),
    externalSource: revenue?.externalSource,
    spec: {
      type: revenueAttributionType,
      newMrrDisplayFormula,
      churnedMrrDisplayFormula,
      newCustomersDisplayFormula,
      churnedCustomersDisplayFormula,
      expansionMrrDisplayFormula,
      contractionMrrDisplayFormula,
      adjustmentsToMrrDisplayFormula,
      expansionCustomersDisplayFormula,
      contractionCustomersDisplayFormula,
      adjustmentsToCustomersDisplayFormula,
    },
  };
};
const getSubscriptionOverTimeParams = ({ revenueStream, type }) => {
  return {
    name: revenueStream.revenueName,
    type,
    customValues: revenueStream.plansAssociated.map((plan) => ({
      productPricingPlanId: plan.id,
      custom: plan.custom,
    })),
  };
};

export const getRevenueListData = getByDateRange('/revenue/list/calculated', {
  useApiV2: false,
});
export const getRevenueStreamsList = getByDateRange('/revenue-stream/revenue', {
  useApiV2: false,
});
export const getRevenueStreamTotal = getByDateRange(
  '/revenue-stream/total-revenue',
  {
    useApiV2: false,
  },
);
export const getProductRevenues = getByDateRange('/revenue-stream/product', {
  useApiV2: false,
});
export const getRevenueDetails = getByDateRange(
  '/revenue-stream/revenue-details',
  {
    useApiV2: false,
  },
);

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const getProducts = (scenarioId) => {
  return Api.get('/products', {
    params: { scenarioId },
  });
};

// Revenue Source/Stream
// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const getRevenueSourceByID = (revenueSourceID, scenarioId) => {
  Api.get(`/revenue-stream/${revenueSourceID}`, {
    params: {
      scenarioId,
    },
  })
    .then((response) => {
      if (response.data.success) {
        store.dispatch(setRevenueSourceRecordToBeEdit(response.data.data));
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const refreshData = (
  scenarioId,
  startDate,
  endDate,
  refreshProducts = true,
) => {
  const { dispatch, getState } = store;
  const {
    revenues: { refreshActualsOnly, revenueStreamRecordToBeEdit },
    scenario: { compareScenarioId },
  } = getState();
  const scenarioIds = [scenarioId];
  if (![scenarioId, NO_COMPARISON].includes(compareScenarioId)) {
    scenarioIds.push(compareScenarioId);
  }
  if (!refreshActualsOnly) {
    dispatch(getTotalRevenueAction(startDate, endDate, scenarioId));
    dispatch(getRevenueDetailsAction(startDate, endDate, scenarioId));
    dispatch(
      getRevenueStreamsAction({
        startDate,
        endDate,
        scenarioId,
        lastUpdatedRevenueId: revenueStreamRecordToBeEdit?.id,
      }),
    );
    dispatch(getProductsAction(scenarioId));
    if (refreshProducts) {
      dispatch(getProductRevenuesList(scenarioId, startDate, endDate));
    }
  }
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const createRevenueSource = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = getSubscriptionMoMParams({
    revenueStream,
    revenueAttributionType: SUBSCRIPTION_MOM_GROWTH,
    estimateType: 'CUSTOMER',
  });
  return Api.post('/revenue-stream/subscription/mom', params, {
    params: { scenarioId },
  }).then((response) => {
    if (response.data.success) {
      refreshData(scenarioId, globalStartDate, globalEndDate);
    }
  });
};

/**
 * Creates or updates an advance revenue stream
 *
 * @param {Date} globalStartDate Start Date for revenue data to be fetched
 * @param {Date} globalEndDate End Date for revenue data to be fetched
 * @param {number} params.scenarioId ID of the scenario to add the revenue
 *   stream
 * @param {Object} params.revenueStream Properties of the revenue stream to be
 *   created/updated
 * @param {Object} params Additional Properties of the revenue
 * @returns {Promise} API response
 */
export const createOrUpdateAdvanceRevenueStream = ({
  globalStartDate,
  globalEndDate,
  ...params
}) => {
  const { id } = params.revenueStream;
  const url = '/revenue-streams/the-one';

  return Api.request({
    url: id ? `${url}/${id}` : url,
    params: { scenarioId: params.scenarioId },
    data: getAdvanceRevenueStreamParams(params),
    method: id ? 'PUT' : 'POST',
  }).then((response) => {
    if (response.data.success) {
      refreshData(params.scenarioId, globalStartDate, globalEndDate);
    }
  });
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const createRevenueSourceForMOMGrowth = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = {
    name: revenueStream.revenueName,
    type: REVENUE_MOM_GROWTH,
    monthlyGrowthRate: Number(revenueStream.monthlyGrowthRate),
    initialRevenueAmount: revenueStream.initialRevenueAmount,
    startDate: getISODate(revenueStream.startDate),
    endDate: revenueStream.endDate ? getISODate(revenueStream.endDate) : null,
  };
  return (
    Api.post('/revenue-stream/revenue-only/mom-growth', params, {
      params: { scenarioId },
    })
      // eslint-disable-next-line sonarjs/no-identical-functions -- predates description requirement
      .then((response) => {
        if (response.data.success) {
          refreshData(scenarioId, globalStartDate, globalEndDate);
        }
      })
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const updateRevenueSourceForMOMGrowth = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = {
    name: revenueStream.revenueName,
    type: REVENUE_MOM_GROWTH,
    monthlyGrowthRate: Number(revenueStream.monthlyGrowthRate),
    initialRevenueAmount: revenueStream.initialRevenueAmount,
    startDate: getISODate(revenueStream.startDate),
    endDate: revenueStream.endDate ? getISODate(revenueStream.endDate) : null,
  };
  return (
    Api.put(
      `/revenue-stream/revenue-only/mom-growth/${revenueStream.id}`,
      params,
      {
        params: { scenarioId },
      },
    )
      // eslint-disable-next-line sonarjs/no-identical-functions -- predates description requirement
      .then((response) => {
        if (response.data.success) {
          refreshData(scenarioId, globalStartDate, globalEndDate);
        }
      })
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const createCustomRevenueSourceForSubscription = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = getSubscriptionOverTimeParams({
    revenueStream,
    type: SUBSCRIPTION_CUSTOM_AMOUNT,
  });
  return (
    Api.post('/revenue-stream/subscription/custom', params, {
      params: { scenarioId },
    })
      // eslint-disable-next-line sonarjs/no-identical-functions -- predates description requirement
      .then((response) => {
        if (response.data.success) {
          refreshData(scenarioId, globalStartDate, globalEndDate);
        }
      })
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const updateCustomRevenueSourceForSubscription = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = getSubscriptionOverTimeParams({
    revenueStream,
    type: SUBSCRIPTION_CUSTOM_AMOUNT,
  });
  return (
    Api.put(`/revenue-stream/subscription/custom/${revenueStream.id}`, params, {
      params: { scenarioId },
    })
      // eslint-disable-next-line sonarjs/no-identical-functions -- predates description requirement
      .then((response) => {
        if (response.data.success) {
          refreshData(scenarioId, globalStartDate, globalEndDate);
        }
      })
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const updateRevenueSource = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = getSubscriptionMoMParams({
    revenueStream,
    revenueAttributionType: SUBSCRIPTION_MOM_GROWTH,
    estimateType: 'CUSTOMER',
  });
  return (
    Api.put(`/revenue-stream/subscription/mom/${revenueStream.id}`, params, {
      params: { scenarioId },
    })
      // eslint-disable-next-line sonarjs/no-identical-functions -- predates description requirement
      .then((response) => {
        if (response.data.success) {
          refreshData(scenarioId, globalStartDate, globalEndDate);
        }
      })
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const createCustomRevenueSourceForRevenueOnly = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = {
    name: revenueStream.revenueName,
    type: REVENUE_CUSTOM_AMOUNT,
    customRevenues: revenueStream.customRevenue,
  };
  return Api.post('/revenue-stream/revenue-only/custom', params, {
    params: { scenarioId },
  }).then((response) => {
    if (response.data.success) {
      refreshData(scenarioId, globalStartDate, globalEndDate, false);
    }
  });
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const updateCustomRevenueSourceForRevenueOnly = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = {
    name: revenueStream.revenueName,
    type: REVENUE_CUSTOM_AMOUNT,
    customRevenues: revenueStream.customRevenue,
  };
  return (
    Api.put(`/revenue-stream/revenue-only/custom/${revenueStream.id}`, params, {
      params: { scenarioId },
    })
      // eslint-disable-next-line sonarjs/no-identical-functions -- predates description requirement
      .then((response) => {
        if (response.data.success) {
          refreshData(scenarioId, globalStartDate, globalEndDate, false);
        }
      })
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const createMOMGrowthRevenueSourceForOneTime = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = getSubscriptionMoMParams({
    revenueStream,
    revenueAttributionType: ONE_TIME_MOM_GROWTH,
    estimateType: 'CUSTOMER',
  });
  return (
    Api.post('/revenue-stream/one-time/mom-growth', params, {
      params: { scenarioId },
    })
      // eslint-disable-next-line sonarjs/no-identical-functions -- predates description requirement
      .then((response) => {
        if (response.data.success) {
          refreshData(scenarioId, globalStartDate, globalEndDate);
        }
      })
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const updateMOMGrowthRevenueSourceForOneTime = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = getSubscriptionMoMParams({
    revenueStream,
    revenueAttributionType: ONE_TIME_MOM_GROWTH,
    estimateType: 'CUSTOMER',
  });
  return (
    Api.put(`/revenue-stream/one-time/mom-growth/${revenueStream.id}`, params, {
      params: { scenarioId },
    })
      // eslint-disable-next-line sonarjs/no-identical-functions -- predates description requirement
      .then((response) => {
        if (response.data.success) {
          refreshData(scenarioId, globalStartDate, globalEndDate);
        }
      })
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const createCustomRevenueSourceForOneTime = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = getSubscriptionOverTimeParams({
    revenueStream,
    type: ONE_TIME_CUSTOM_AMOUNT,
  });
  return (
    Api.post('/revenue-stream/one-time/custom', params, {
      params: { scenarioId },
    })
      // eslint-disable-next-line sonarjs/no-identical-functions -- predates description requirement
      .then((response) => {
        if (response.data.success) {
          refreshData(scenarioId, globalStartDate, globalEndDate);
        }
      })
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const updateCustomRevenueSourceForOneTime = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = getSubscriptionOverTimeParams({
    revenueStream,
    type: ONE_TIME_CUSTOM_AMOUNT,
  });
  return (
    Api.put(`/revenue-stream/one-time/custom/${revenueStream.id}`, params, {
      params: { scenarioId },
    })
      // eslint-disable-next-line sonarjs/no-identical-functions -- predates description requirement
      .then((response) => {
        if (response.data.success) {
          refreshData(scenarioId, globalStartDate, globalEndDate);
        }
      })
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const deleteRevenueStreamByID = (
  scenarioId,
  sourceId,
  startDate,
  endDate,
  forceDeletion,
) => {
  return Api.delete(
    `/revenue-stream/${sourceId}?forceDeletion=${forceDeletion}`,
    {
      params: { scenarioId },
    },
  ).then((response) => {
    if (response.data.success && response.data.data !== 'ActualsFound') {
      refreshData(scenarioId, startDate, endDate);
      return response.data;
    }
    return response.data;
  });
};

// Product Configuration

// Add and Update
// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const getProductByID = (scenarioId, productId) => {
  Api.get(`/products/${productId}`, { params: { scenarioId } })
    .then((response) => {
      if (response.data.success) {
        const productData = response.data.data;
        const productToBeEdit = {
          id: productData.id,
          name: productData.name,
          externalSource: productData.externalSource,
          pricingPlans: [],
        };
        productData.pricingPlans.forEach((pp) => {
          productToBeEdit.pricingPlans.push({
            id: pp.id,
            frequency: pp.frequency,
            frequencyMonths: pp.frequencyMonths,
            name: pp.name,
            price: parseFloat(pp.price),
            priceFormula: pp.priceFormula,
            churnFormula: pp.churnFormula,
            churnType: pp.churnType,
            isUsed: pp.isUsedInRevenueStream,
            isUsedInStripe: pp.isUsedInStripe,
            stripeCurrency: pp.stripeCurrency,
            isActualPresent: pp.isActualPresent,
            collectionTermId: pp.collectionTermId,
            collectionTermName: pp.collectionTermName,
            invoiceTiming: pp.invoiceTiming,
          });
        });

        store.dispatch(setProductRecordToBeEdit(productToBeEdit));
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const addProduct = (scenarioId, params, startDate, endDate) => {
  return Api.post('/products', params, {
    params: { scenarioId },
  }).then((response) => {
    if (response.data.success) {
      refreshData(scenarioId, startDate, endDate);
      store.dispatch(updateProductsList(response.data.data));
    }
    return response.data.success;
  });
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const updateProduct = (
  scenarioId,
  productId,
  params,
  startDate,
  endDate,
) => {
  return Api.put(`/products/${productId}`, params, {
    params: { scenarioId },
  }).then((response) => {
    if (response.data.success) {
      refreshData(scenarioId, startDate, endDate);
    }
    return response.data.success;
  });
};

const getMarketingSpendParams = ({ revenueStream }) => {
  const driverFound = STREAMS[revenueStream.revenueType].drivers.find(
    (driver) => driver.type === revenueStream.driverType,
  );
  return {
    name: revenueStream.revenueName,
    revenueStreamType: STREAMS[revenueStream.revenueType].id,
    revenueDriverType: driverFound.id,
    forecastStartDate: getISODate(revenueStream.startDate),
    forecastEndDate: revenueStream.endDate && getISODate(revenueStream.endDate),
    revenueStreamPricingPlans: getRevenueStreamPricingPlans(revenueStream),
    payload: {
      expenseGroupId: revenueStream.expenseGroupId,
      costPerLead: revenueStream.costPerLead,
      leadConversionRate: revenueStream.leadConversionRate,
      conversionTimeInMonth: revenueStream.conversionTimeInMonth,
      conversionOverTime: revenueStream.conversionOverTime,
    },
  };
};

const createOrUpdateRevenueStream = async (
  url,
  revenueStreamId,
  params,
  scenarioId,
  globalStartDate,
  globalEndDate,
) => {
  let response;
  if (revenueStreamId) {
    response = await Api.put(`${url}/${revenueStreamId}`, params, {
      params: { scenarioId },
    });
  } else {
    response = await Api.post(url, params, {
      params: { scenarioId },
    });
  }

  const {
    data: { success },
  } = response;
  if (success) {
    refreshData(scenarioId, globalStartDate, globalEndDate);
  }
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const createCustomRevenueSourceForMarketingSpend = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = getMarketingSpendParams({
    revenueStream,
  });
  return createOrUpdateRevenueStream(
    '/revenue-stream/subscription/marketing-spend',
    null,
    params,
    scenarioId,
    globalStartDate,
    globalEndDate,
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const updateCustomRevenueSourceForMarketingSpend = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = getMarketingSpendParams({
    revenueStream,
  });
  return createOrUpdateRevenueStream(
    `/revenue-stream/subscription/marketing-spend`,
    revenueStream.id,
    params,
    scenarioId,
    globalStartDate,
    globalEndDate,
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const createOneTimeSourceForMarketingSpend = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = getMarketingSpendParams({
    revenueStream,
  });
  return createOrUpdateRevenueStream(
    '/revenue-stream/one-time/marketing-spend',
    null,
    params,
    scenarioId,
    globalStartDate,
    globalEndDate,
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const updateOneTimeSourceForMarketingSpend = ({
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
}) => {
  const params = getMarketingSpendParams({
    revenueStream,
  });
  return createOrUpdateRevenueStream(
    `/revenue-stream/one-time/marketing-spend`,
    revenueStream.id,
    params,
    scenarioId,
    globalStartDate,
    globalEndDate,
  );
};

/**
 * Service to call API to get financing
 *
 * @param {number} scenarioId ID of the scenario containing the Financing
 * @param {Date} startDate First month of desired financing values, in the
 *   format YYYY_MM
 * @param {Date} endDate Last month of desired financing values, in the format
 *   YYYY_MM
 * @returns {Promise} API response
 */
export const getFinancingSourceList = getByDateRange('/financing', {
  useApiV2: false,
});

/**
 * Service to call API for fetching detail of single financing record
 *
 * @param {string} id ID of the financing
 * @param {number} scenarioId ID of the scenario containing the Financing
 * @returns {Promise} API response
 */
export const getFinanceDetail = (id, scenarioId) => {
  return Api.get(`/financing/${id}`, {
    params: { scenarioId },
  });
};

/**
 * Service to call API for adding financing
 *
 * @param {Object} financing The financing record to be created
 * @param {number} scenarioId ID of the scenario containing the Financing
 * @returns {Promise} API response
 */
export const createFinancing = (financing, scenarioId) => {
  return Api.post('/financing', financing, {
    params: { scenarioId },
  });
};

/**
 * Service to call API for updating financing
 *
 * @param {Object} financing New properties for the financing
 * @param {Object} params Param Object
 * @param {string} params.id ID of the financing
 * @param {number} params.scenarioId ID of the scenario to which the financing
 *   belongs
 * @returns {Promise} API response
 */
export const updateFinancing = (financing, { id, scenarioId }) => {
  return Api.put(`/financing/${id}`, financing, {
    params: { scenarioId },
  });
};

/**
 * Deletes the financing with the given ID
 *
 * @param {number} scenarioId ID of the scenario containing the Financing
 * @param {number} id ID of the financing to be deleted
 * @returns {Promise} API response
 */
export const deleteFinancing = (scenarioId, id) => {
  return Api.delete(`/financing/${id}`, {
    params: { scenarioId },
  });
};

const getSalesLedParams = ({ revenueStream }) => {
  const driverFound = STREAMS[revenueStream.revenueType].drivers.find(
    (driver) => driver.type === revenueStream.driverType,
  );
  const {
    annualQuota,
    attainmentDuringRamp,
    employeeTitleId,
    salesRepRampTime,
    quotaAttainment,
  } = revenueStream;
  return {
    name: revenueStream.revenueName,
    revenueStreamType: STREAMS[revenueStream.revenueType].id,
    revenueDriverType: driverFound.id,
    forecastStartDate: getISODate(revenueStream.startDate),
    forecastEndDate: revenueStream.endDate && getISODate(revenueStream.endDate),
    // TODO Revenue param mapping needs major cleanup. SonarJS is bike-shedding.
    // eslint-disable-next-line sonarjs/no-identical-functions -- predates description requirement
    revenueStreamPricingPlans: revenueStream.plansAssociated.map((plan) => ({
      estimatedValue: plan.estimatedValue,
      productPricingPlanId: plan.id,
      id: plan.planId,
    })),
    payload: {
      annualQuota,
      attainmentDuringRamp,
      employeeTitleId,
      salesRepRampTime,
      quotaAttainment,
    },
  };
};

/**
 * Creates or updates a revenue stream with a sales-led conversions driver
 *
 * @param {number} scenarioId ID of the scenario to add the revenue stream
 * @param {Object} params Properties of the revenue stream to be created/updated
 * @returns {Promise} API response
 */
export const createOrUpdateRevenueStreamForSalesLed = (scenarioId, params) => {
  const { id, revenueType } = params.revenueStream;
  const isOneTime = revenueType === ONE_TIME;
  const data = getSalesLedParams(params);
  let url = `/revenue-stream/${
    isOneTime ? 'one-time' : 'subscription'
  }/sales-led`;
  if (id) url += `/${id}`;

  return Api.request({
    url,
    data,
    method: id ? 'PUT' : 'POST',
    params: {
      scenarioId,
    },
  });
};

/**
 * Creates or updates a revenue stream with a custom formula driver
 *
 * @param {number} scenarioId ID of the scenario to add the revenue stream
 * @param {Object} params Properties of the revenue stream to be created/updated
 * @returns {Promise} API response
 */
export const createOrUpdateCustomFormulaRevenueStream = (
  scenarioId,
  params,
) => {
  const { revenueStream } = params;
  const { id, revenueType, driverType, startDate, endDate, revenueName } =
    revenueStream;

  const isOneTime = revenueType === ONE_TIME;
  const url = `/revenue-stream/${
    isOneTime ? 'one-time' : 'subscription'
  }/custom-formula`;
  const driverFound = STREAMS[revenueType].drivers.find(
    (driver) => driver.type === driverType,
  );

  const data = {
    name: revenueName,
    id,
    revenueStreamType: STREAMS[revenueType].id,
    revenueDriverType: driverFound.id,
    forecastStartDate: getISODate(startDate),
    forecastEndDate: endDate && getISODate(endDate),
    revenueStreamPricingPlans: getRevenueStreamPricingPlans(revenueStream),
    payload: {
      baseValue: revenueStream.baseValue,
      formula: revenueStream.formula,
    },
  };

  return Api.request({
    url: id ? `${url}/${id}` : url,
    data,
    method: revenueStream.id ? 'PUT' : 'POST',
    params: {
      scenarioId,
    },
  });
};

/**
 * Creates or updates a Revenue Only revenue stream with a custom formula driver
 *
 * @param {Object} params Properties of the revenue stream to be created/updated
 * @param {number} params.scenarioId ID of the scenario to add the revenue
 *   stream
 * @param {Object} params.revenueStream Revenue stream data
 * @param {Date} params.globalStartDate Start Date for revenue data to be
 *   fetched
 * @param {Date} params.globalEndDate End Date for revenue data to be fetched
 */
export const createOrUpdateCustomFormulaRevenueStreamRevenueOnly = async (
  params,
) => {
  const { revenueStream, scenarioId, globalStartDate, globalEndDate } = params;
  const { id, startDate, endDate, revenueName, baseValue, formula } =
    revenueStream;

  const url = `/revenue-stream/revenue-only/custom-formula`;
  const data = {
    baseValue,
    formula,
    name: revenueName,
    startDate,
    endDate,
  };

  const response = await Api.request({
    url: id ? `${url}/${id}` : url,
    data,
    method: id ? 'PUT' : 'POST',
    params: {
      scenarioId,
    },
  });

  const {
    data: { success },
  } = response;
  if (success) {
    refreshData(scenarioId, globalStartDate, globalEndDate);
  }
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const setExternalForTotalRevenue = async (value, scenarioId) => {
  return Api.patch(
    '/scenario/setExternalsForTotalRevenue',
    { value },
    {
      params: { scenarioId },
    },
  );
};

/** @typedef {{ scenarioId: number }} RevenueIntegrationParams */

/**
 * @typedef {'EQUALS'
 *   | 'NOT_EQUALS'
 *   | 'GREATER_THAN'
 *   | 'GREATER_THAN_OR_EQUALS'
 *   | 'LESS_THAN'
 *   | 'LESS_THAN_OR_EQUALS'
 *   | 'CONTAINS'} FilterOperators
 */

/**
 * @typedef {{
 *   fieldName: import('@/components/Revenue/DataMapping/types').RevenueIntegrationColumn;
 *   operator: FilterOperators;
 *   value: number | string;
 * }} Filter
 */

/**
 * @typedef {RevenueIntegrationParams & {
 *   limit?: number;
 *   offset?: number;
 *   sortField?: string;
 *   sortDirectionAsc?: boolean;
 *   filter?: Filter[];
 * }} RevenueIntegrationEntriesParams
 */

/**
 * @typedef {{
 *   name: string;
 *   type: import('src/constants/dataMapping').RevenueIntegrationColumnType;
 *   value?: string;
 *   id?: number | string;
 *   revenueType: import('@/constants/actuals').IconTypes;
 * }} RevenueIntegrationField
 */

/**
 * @typedef {{
 *   id: string;
 *   fields: RevenueIntegrationField[];
 *   external: boolean;
 *   error?: string;
 * }} RevenueIntegrationEntry
 */

/**
 * @typedef {{
 *   total: number;
 *   entries: RevenueIntegrationEntry[];
 * }} RevenueIntegrationEntriesResponseData
 */

/**
 * @typedef {{
 *   data: RevenueIntegrationEntriesResponseData;
 * }} RevenueIntegrationEntriesResponse
 */

/**
 * Get data from a revenue integration
 *
 * @type {(
 *   params: RevenueIntegrationEntriesParams,
 * ) => Promise<
 *   import('axios').AxiosResponse<RevenueIntegrationEntriesResponse>
 * >}
 */
export const getRevenueIntegrationData = ({
  scenarioId,
  limit,
  offset,
  sortField,
  sortDirectionAsc,
  filter,
}) => {
  return ApiV2.get('/revenue-deals/entries', {
    params: {
      scenarioId,
      limit,
      offset,
      sortFieldName: sortField,
      sortDirectionAsc,
      filter: filter && JSON.stringify(filter),
    },
  });
};

/**
 * @typedef {{
 *   type: import('src/constants/dataMapping').RevenueIntegrationColumnType;
 *   name: string;
 *   label: string | null;
 *   gridEditable: boolean;
 *   excludeFromRule?: boolean;
 * }} RevenueIntegrationMetadataResponseData
 */

/**
 * @typedef {{
 *   data: RevenueIntegrationMetadataResponseData[];
 * }} RevenueIntegrationMetadataResponse
 */

/**
 * Get metadata about a revenue integration
 *
 * @type {(
 *   params: RevenueIntegrationParams,
 * ) => Promise<
 *   import('axios').AxiosResponse<RevenueIntegrationMetadataResponse>
 * >}
 */
export const getRevenueIntegrationMetadata = ({ scenarioId }) => {
  return ApiV2.get('/revenue-deals/metadata', {
    params: { scenarioId },
  });
};

/**
 * @typedef {{
 *   type: string;
 *   operand: string;
 *   operator: string;
 *   value: string;
 * }} Criteria
 *
 *
 * @typedef {{
 *   name: string;
 *   revenueStreamId: number;
 *   pricingPlanId: number;
 *   expression: {
 *     criterion?: Criteria | null;
 *     logicalExpr?: {
 *       operator: string;
 *       criteria: Criteria[];
 *     } | null;
 *   };
 * }} RevenueIntegrationRule
 *   /
 *
 *   /**
 *
 * @typedef {{ data: RevenueIntegrationRule[] }} RevenueIntegrationRulesResponse
 */

/**
 * Get rules list for a scenario
 *
 * @type {(
 *   params: RevenueIntegrationParams,
 * ) => Promise<import('axios').AxiosResponse<RevenueIntegrationRulesResponse>>}
 */
export const getRevenueIntegrationRules = ({ scenarioId }) => {
  return Api.get('/revenue-deal-rule', {
    params: { scenarioId },
  });
};

/**
 * @typedef {{
 *   data: number;
 * }} UnmappedEntriesCountResponse
 */

/**
 * Retrieves the count of unmapped entries.
 *
 * @type {(params: {
 *   scenarioId: number;
 * }) => Promise<import('axios').AxiosResponse<UnmappedEntriesCountResponse>>}
 */
export const getUnmappedEntriesCount = ({ scenarioId }) => {
  return ApiV2.get('/revenue-deals/unmappedcount', {
    params: { scenarioId },
  });
};

/**
 * @typedef {{
 *   planId: number;
 *   planName: string;
 * }} PricingPlan
 */

/**
 * @typedef {{
 *   streamId: number;
 *   streamName: string;
 *   streamType?: string;
 *   pricingPlans: PricingPlan[];
 * }} RevenueStreamsWithPricingPlan
 */

/** @typedef {RevenueStreamsWithPricingPlan['pricingPlans']} PricingPlans */

/** @typedef {{ scenarioId: number }} RevenueStreamsWithPricingPlanParams */

/**
 * Get Revenues Stream list with pricing plans
 *
 * @type {(
 *   params: RevenueStreamsWithPricingPlanParams,
 * ) => Promise<
 *   import('axios').AxiosResponse<{ data: RevenueStreamsWithPricingPlan[] }>
 * >}
 */
export const getRevenueStreamsWithPricingPlans = ({ scenarioId }) => {
  return Api.get('/revenue-stream/list', {
    params: { scenarioId },
  });
};

/**
 * @typedef {{
 *   scenarioId: number;
 *   rules: RevenueIntegrationRule[];
 * }} RevenueStreamsRules
 */

/**
 * Saves rules for the given scenario
 *
 * @type {(
 *   params: RevenueStreamsRules,
 * ) => Promise<import('axios').AxiosResponse<RevenueIntegrationRulesResponse>>}
 */
export const addRevenueStreamsRules = ({ scenarioId, rules }) => {
  return Api.post('/revenue-deal-rule', rules, {
    params: { scenarioId },
  });
};

/**
 * @template [T=unknown] Default is `unknown`
 * @typedef {T} OverwriteRevenueDealEntryRequest
 */

/**
 * Overwrite an external revenue integration entry's data
 *
 * @type {(params: {
 *   scenarioId: number;
 *   revenueDealEntryId: string;
 *   payload: OverwriteRevenueDealEntryRequest;
 * }) => Promise<
 *   import('axios').AxiosResponse<{
 *     data: {};
 *     error: { errorMessage: string };
 *   }>
 * >}
 */
export const overwriteRevenueIntegrationEntry = ({
  scenarioId,
  revenueDealEntryId,
  payload,
}) =>
  ApiV2.patch(`revenue-deals/override/${revenueDealEntryId}`, payload, {
    params: { scenarioId },
  });

/**
 * @typedef {{
 *   name: string;
 *   value: string;
 * }} RevenueIntegrationRecord
 */

/**
 * @typedef {Record<
 *   Extract<
 *     import('@/constants/dataMapping').EditableColumnName,
 *     | typeof import('@/constants/dataMapping').columnNames.AMOUNT
 *     | typeof import('@/constants/dataMapping').columnNames.REVENUE_STREAM
 *     | typeof import('@/constants/dataMapping').columnNames.PRICING_PLAN
 *   >,
 *   number | null
 * > &
 *   Record<
 *     Extract<
 *       import('@/constants/dataMapping').EditableColumnName,
 *       | typeof import('@/constants/dataMapping').columnNames.REVENUE_METRIC
 *       | typeof import('@/constants/dataMapping').columnNames.CUSTOMER_METRIC
 *       | typeof import('@/constants/dataMapping').columnNames.CUSTOMER_NAME
 *       | typeof import('@/constants/dataMapping').columnNames.DATE
 *       | typeof import('@/constants/dataMapping').columnNames.DEAL_NAME
 *     >,
 *     string | null
 *   >} RevenueIntegrationOverRide
 */

/**
 * @typedef {{
 *   [rowId: string]: Partial<RevenueIntegrationOverRide>;
 * }} BulkPayloadObject
 */

/**
 * @typedef {{
 *   fields: RevenueIntegrationRecord[];
 *   overrides: Omit<
 *     RevenueIntegrationOverRide,
 *     'Amount' | 'Customer Name' | 'Date' | 'Deal Name'
 *   >;
 * }} RevenueIntegrationPayload
 */

/**
 * Add a new record to a revenue integration's data
 *
 * @type {(params: {
 *   scenarioId: number;
 *   data: RevenueIntegrationPayload;
 * }) => Promise<import('axios').AxiosResponse<{ data: {} }>>}
 */
export const addRevenueIntegrationEntry = ({ scenarioId, data }) =>
  ApiV2.post('revenue-deals', data, {
    params: { scenarioId },
  });

/**
 * @typedef {{
 *   [key: string]: Partial<
 *     Record<import('@/constants/dataMapping').EditableColumnName, string>
 *   >;
 * }} UpdateBulkRevenueIntegrationFieldErrors
 */

/**
 * Bulk update records in the revenue integration's data
 *
 * @type {(params: {
 *   scenarioId: number;
 *   data: BulkPayloadObject;
 * }) => Promise<
 *   import('axios').AxiosResponse<
 *     import('@/types/api').ApiResponse<UpdateBulkRevenueIntegrationFieldErrors>
 *   >
 * >}
 */
export const updateBulkRevenueIntegrationEntries = ({ scenarioId, data }) => {
  return ApiV2.patch('revenue-deals/override', data, {
    params: { scenarioId },
  });
};

/**
 * @typedef {{
 *   scenarioId: number;
 *   revenueDealEntryId: string;
 *   data: RevenueIntegrationPayload;
 * }} UpdateRevenueIntegrationEntryParams
 */

/**
 * Update revenue integration record
 *
 * @type {(
 *   params: UpdateRevenueIntegrationEntryParams,
 * ) => Promise<
 *   import('axios').AxiosResponse<
 *     { data: RevenueIntegrationRecord[] },
 *     RevenueIntegrationPayload
 *   >
 * >}
 */
export const updateRevenueIntegrationEntry = ({
  scenarioId,
  revenueDealEntryId,
  data,
}) =>
  ApiV2.put(`revenue-deals/${revenueDealEntryId}`, data, {
    params: { scenarioId },
  });

/**
 * Delete revenue integration record
 *
 * @type {(params: {
 *   scenarioId: number;
 *   revenueDealEntryId: string;
 * }) => Promise<import('axios').AxiosResponse<{ data: {} }>>}
 */
export const deleteRevenueIntegrationEntry = ({
  scenarioId,
  revenueDealEntryId,
}) =>
  ApiV2.delete(`revenue-deals/${revenueDealEntryId}`, {
    params: { scenarioId },
  });
