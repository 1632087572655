import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CreateNewRecordIcon from '@bill/cashflow.assets/create-new-record';
import FindRecordIcon from '@bill/cashflow.assets/find-record';
import RoundCrossIcon from '@bill/cashflow.assets/round-cross';
import {
  getUnlinkedEmployeesAction,
  matchExternalEmployeeAction,
} from '@/actions/employees';
import TheMatchEmployeeRecordModal from '@/components/Employee/PendingEmployees/TheMatchEmployeeRecordModal';
import Button from '@/components/common/Button';
import ErrorDiv from '@/components/common/ErrorDiv';
import { classNames } from '@/helpers';
import { formatMonthDayYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';

const CREATE_NEW = 'CREATE_NEW';
const MATCH_TO_EXISITING = 'MATCH';

const ReviewEmployeeRecordRow = ({
  externalId,
  name,
  department,
  title,
  startDate: date,
  salary,
  mappedData,
  handleCreateNewRecord,
  handleFindRecord,
  removeMapping,
}) => {
  const isEmployeeMapped = mappedData[externalId];
  const isNewEmployeeRecord = !mappedData[externalId]?.employeeId;
  const iconClassNames = classNames(
    'ReviewEmployeeRecords_RowIcon',
    isEmployeeMapped && 'ReviewEmployeeRecords_RowIcon-active',
  );
  return (
    <div className="ReviewEmployeeRecords_Row" key={externalId}>
      <p
        className={classNames(
          'ReviewEmployeeRecords_RowTitle',
          mappedData[externalId] && 'ReviewEmployeeRecords_RowTitle-disabled',
        )}
      >{`${name} / ${department} / ${title} / ${formatMonthDayYear(
        date,
      )} / ${formatMonetary(salary)}`}</p>
      <div className="ReviewEmployeeRecords_RowIcons">
        {isEmployeeMapped && (
          <RoundCrossIcon
            onClick={() => removeMapping(externalId)}
            className="ReviewEmployeeRecords_RowIcon ReviewEmployeeRecords_CrossIcon"
          />
        )}
        {(!isEmployeeMapped || isNewEmployeeRecord) && (
          <CreateNewRecordIcon
            onClick={() => handleCreateNewRecord(externalId)}
            className={iconClassNames}
          />
        )}

        {(!isEmployeeMapped || !isNewEmployeeRecord) && (
          <FindRecordIcon
            onClick={() => handleFindRecord(externalId)}
            className={iconClassNames}
          />
        )}
      </div>
    </div>
  );
};

const ReviewEmployeeRecords = ({
  pendingEmployees,
  onClose,
  companyId,
  scenarioId,
  getUnlinkedEmployess,
  matchExternalEmployee,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [openEmployeeRecordModal, setOpenEmployeeRecordModal] = useState(false);
  const [externalIdForMatchingModal, setExternalIdForMatchingModal] =
    useState(null);
  const [mappedData, setMappedData] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    getUnlinkedEmployess(scenarioId);
  }, [getUnlinkedEmployess, scenarioId]);

  const handleFindRecord = (externalId) => {
    setOpenEmployeeRecordModal(true);
    setExternalIdForMatchingModal(externalId);
  };

  const handleCreateNewRecord = (externalEmployeeId) => {
    setMappedData((prevState) => ({
      ...prevState,
      [externalEmployeeId]: {
        action: CREATE_NEW,
        externalEmployeeId,
        employeeId: null,
      },
    }));
  };

  const handleMatchEmployeeRecord = (employeeId) => {
    setMappedData((prevState) => ({
      ...prevState,
      [externalIdForMatchingModal]: {
        action: MATCH_TO_EXISITING,
        externalEmployeeId: externalIdForMatchingModal,
        employeeId,
      },
    }));
    setOpenEmployeeRecordModal(false);
  };

  const handleRemoveMapping = (externalEmployeeId) => {
    const clone = { ...mappedData };
    delete clone[externalEmployeeId];
    setMappedData(clone);
  };

  const handleClose = () => {
    setMappedData({});
    setExternalIdForMatchingModal(null);
    onClose();
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await matchExternalEmployee(companyId, scenarioId, {
        matchingRequests: Object.values(mappedData),
      });
      handleClose();
    } catch (e) {
      setErrorMessage(e.response?.data?.error?.errorMessage || e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {errorMessage && (
        <div className="ReviewEmployeeRecords_Error">
          <ErrorDiv error={errorMessage} />
        </div>
      )}
      <header>
        <div className="ReviewEmployeeRecords_Header">
          <h2 className="ReviewEmployeeRecords_Heading">
            Review Employee Records
          </h2>
          <div className="ReviewEmployeeRecords_Label">
            New Records Imported
          </div>
          <p className="ReviewEmployeeRecords_Description">
            These records have been imported from your external payroll system.
            For each record, you can either create a new employee record or
            match it to an existing employee record. We will remind you to
            resolve outstanding records until you take action.
          </p>
        </div>
      </header>
      <div className="ReviewEmployeeRecords_Content">
        {pendingEmployees.map((employee) => (
          <ReviewEmployeeRecordRow
            key={employee.externalId}
            {...employee}
            mappedData={mappedData}
            handleFindRecord={handleFindRecord}
            handleCreateNewRecord={handleCreateNewRecord}
            removeMapping={handleRemoveMapping}
          />
        ))}
      </div>
      <footer className="ModalBase_Footer">
        <Button
          className="Button ModalConfirmation_Button Button-cancelLink"
          onClick={handleClose}
          data-testid="review-employee-records-cancel-button"
        >
          Cancel
        </Button>
        <Button
          loading={isLoading}
          disabled={!Object.keys(mappedData).length}
          onClick={handleSave}
          data-testid="review-employee-records-action-button"
        >
          Save
        </Button>
      </footer>
      <TheMatchEmployeeRecordModal
        mappedEmployeeIds={Object.values(mappedData)
          .filter((info) => info.action === MATCH_TO_EXISITING)
          .map(({ employeeId }) => employeeId)}
        onSave={handleMatchEmployeeRecord}
        open={openEmployeeRecordModal}
        onClose={() => setOpenEmployeeRecordModal(false)}
      />
    </>
  );
};

const mapStateToProps = ({ employees, companies, scenario }) => ({
  pendingEmployees: employees.pendingEmployees,
  showReviewEmployeeModal: employees.showReviewEmployeeModal,
  companyId: companies.selectedCompanyId,
  scenarioId: scenario.scenarioId,
});

export default connect(mapStateToProps, {
  getUnlinkedEmployess: getUnlinkedEmployeesAction,
  matchExternalEmployee: matchExternalEmployeeAction,
})(ReviewEmployeeRecords);
