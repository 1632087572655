import { useState, useRef, useMemo, useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import useCallbackRef from '@/hooks/useCallbackRef';
import useChartTableData from '@/hooks/useChartTableData';
import useDashboardPath from '@/hooks/useDashboardPath';
import { valueFormatter } from '@/pages/Actuals/helpers';
import RUNWAY_METRICS from '@/pages/Dashboard/constants/runway';
import RunwayChart from './charts/RunwayChart';
import { getLabeledMetric, getTimePeriodLabel } from './helpers';

const CHART_TITLE = 'Runway';
const metrics = RUNWAY_METRICS.filter(({ name }) => name !== 'Financing');
const SPREADSHEET_ID = 'runway-chart-table';

const Runway = () => {
  const [total, setTotal] = useState();
  const [chartData, setChartData] = useState([]);
  const chartRef = useRef();
  const [gridApi, gridRef] = useCallbackRef();
  const dashboardPath = useDashboardPath();

  const timePeriod = useSelector(({ shared }) => shared.timePeriod);
  const colDefs = useMemo(
    () => [
      {
        field: 'name',
        headerName: CHART_TITLE,
      },
    ],
    [],
  );

  const tableData = useChartTableData(chartData, metrics);
  const onQueryStateChange = useCallback((data) => {
    setTotal(data[0].data.runway);
    setChartData(data);
  }, []);

  return (
    <>
      <section className="Panel Panel-toEdge Runway">
        <ChartHeader back={dashboardPath} title={CHART_TITLE}>
          <div className="ChartHeader_KPIValue">{total}</div>
          <div className="ChartHeader_KPI">
            <span className="ChartHeader_Timespan">
              {`${getTimePeriodLabel(total, timePeriod)}`}
            </span>
          </div>
        </ChartHeader>
        <ChartToolbar>
          <ChartControls
            chartRef={chartRef}
            data-testid="runway-chartControls"
            title={CHART_TITLE}
            metricValue={`${getLabeledMetric(total, timePeriod)}`}
          />
        </ChartToolbar>
        <RunwayChart
          className="ExpandedView_ChartWrapper"
          exportBtn={chartRef}
          onQueryStateChange={onQueryStateChange}
        />
      </section>
      <section className="Panel Panel-toEdge">
        <SpreadsheetToolbar editable={false} gridApi={gridApi}>
          Options:
          <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
        </SpreadsheetToolbar>
        <MonthlySpreadsheet
          ref={gridRef}
          columnDefs={colDefs}
          data={tableData}
          data-testid={SPREADSHEET_ID}
          editable={false}
          enableComparison
          getRowId={({ data }) => data.name}
          valueFormatter={valueFormatter}
        />
      </section>
    </>
  );
};

export default Runway;
