// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { USER_ROLES as userRoles } from '@/constants/permissions';

/**
 * A hook for determining if the current use is an admin user
 *
 * @type {() => boolean}
 * @returns {boolean} Whether the user is an admin user
 */
const useIsAdminUser = () => {
  const userRole = useSelector(({ auth }) => auth.userInfo.userRole);
  return userRole === userRoles.ROLE_ADMIN;
};

export default useIsAdminUser;
