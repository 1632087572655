// @ts-check
import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { CASH_FORECAST, PROFIT_AND_LOSS, USER_REPORTS } from '@/cacheKeys';
import { reportTypes } from '@/constants/reports';
import { NO_COMPARISON } from '@/constants/scenario';
import { restructureCashInOutData } from '@/helpers/cashGrid';
import transformDataForGrid from '@/reducers/helpers/transformDataForGrid';
import {
  getExternalCashFlow,
  getExternalProfitAndLoss,
} from '@/services/actuals.service';
import { getCashInOutGrid } from '@/services/cashInOut';
import {
  getExternalBalanceSheet,
  getProfitAndLoss,
  getUserReportData,
} from '@/services/reports.service';

/**
 * @typedef {import('@/hooks/useReportsInfo').ReportsInfoWithExternalReportFlags & {
 *     isAccountingIntegrationEnabled: boolean;
 *   }} ReportInfoWithAccountIntegrationFlag
 */

/**
 * @private
 * @type {(
 *   reportType: import('@/constants/reports').ReportType,
 *   reportInfo: ReportInfoWithAccountIntegrationFlag,
 * ) => Function}
 */
const getQueryFunction = (reportType, reportInfo) => {
  switch (reportType) {
    case reportTypes.CASH_IN_OUT:
      return getCashInOutGrid;
    case reportTypes.PROFIT_AND_LOSS:
      if (reportInfo.isExternalProfitAndLoss) return getExternalProfitAndLoss;
      return getProfitAndLoss;
    case reportTypes.BALANCE_SHEET:
      if (
        reportInfo.isExternalBalanceSheet &&
        reportInfo.isAccountingIntegrationEnabled
      ) {
        return getExternalBalanceSheet;
      }
      return getUserReportData;
    case reportTypes.CASH_FLOW_STATEMENT:
      if (reportInfo.isExternalCashFlow) return getExternalCashFlow;
      return getUserReportData;
    default:
      return null;
  }
};

/**
 * @typedef {{
 *   bold: boolean;
 *   italic: boolean;
 *   underlined: boolean;
 *   strikethrough: boolean;
 *   borderTop: boolean;
 *   borderBottom: boolean;
 *   borderLeft: boolean;
 *   borderRight: boolean;
 * }} ReportStyle
 */

/**
 * @typedef {'SYSTEM_GENERATED'
 *   | 'USER_GENERATED_FINMARK'
 *   | 'EXTERNAL_GENERATED'
 *   | null} ValueType
 */

/** @typedef {'CIRCULAR_REFERENCE' | 'INVALID_FORMULA' | null} VariableValueFaultType */

/**
 * @typedef {{
 *   value: null | number | string;
 *   displayFormula: null | string;
 *   faulted: boolean;
 *   type: ValueType;
 *   variableValueFaultType: VariableValueFaultType;
 *   customVariableId?: null | number | string;
 *   style?: null | ReportStyle;
 * }} MonthValue
 */

/**
 * @typedef {{
 *   date: string;
 *   value: MonthValue[] | number;
 *   style: ReportStyle;
 * }} ReportMonth
 */

/**
 * @typedef {{
 *   scenarioId: number;
 *   key: string;
 * }} Keys
 */

/**
 * @typedef {{
 *   id: string;
 *   key: Keys[];
 *   name?: string;
 *   title?: string;
 *   type?: null | string;
 *   style: ReportStyle;
 *   months: ReportMonth[];
 *   children: ReportsData[];
 *   isEditable: boolean;
 *   index: number;
 *   isAdditional: boolean;
 *   isExternal: boolean;
 *   accountId?: number;
 *   hierarchy: (string | number)[];
 *   parentKeys: Keys[];
 *   childrenIds: string[];
 *   forecastFormula?: string;
 *   forecastMethod?:
 *     | 'CUSTOM'
 *     | 'DEFAULT'
 *     | 'MONTHS_12'
 *     | 'MONTHS_3'
 *     | 'MONTHS_6';
 * }} ReportsData
 */

/**
 * A custom hook fetch report data based on the report type
 *
 * @type {(
 *   reportType: import('@/constants/reports').ReportType,
 *   reportsInfo: ReportInfoWithAccountIntegrationFlag,
 *   enabled: boolean,
 * ) => import('@tanstack/react-query').UseQueryResult<ReportsData[]>}
 */
const useReportData = (reportType, reportsInfo, enabled = true) => {
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );

  const { compareScenarioId, scenarioId } = useSelector(
    ({ scenario }) => scenario,
  );

  /**
   * @type {{
   *   key: string[] | number[];
   *   params?: any;
   *   externalParams?: any;
   * }}
   */
  const queryConfig = useMemo(() => {
    const key = [startDate, endDate, timePeriod, scenarioId, compareScenarioId];
    const params = { startDate, endDate, timePeriod };
    const scenarioIds = [scenarioId];
    if (compareScenarioId !== NO_COMPARISON)
      scenarioIds.push(compareScenarioId);

    if (
      (reportType === reportTypes.PROFIT_AND_LOSS &&
        reportsInfo?.isExternalProfitAndLoss) ||
      (reportType === reportTypes.CASH_FLOW_STATEMENT &&
        reportsInfo?.isExternalCashFlow) ||
      (reportType === reportTypes.BALANCE_SHEET &&
        reportsInfo?.isExternalBalanceSheet &&
        reportsInfo?.isAccountingIntegrationEnabled)
    ) {
      key.unshift(`${reportType.toUpperCase()}_EXTERNAL`);
      return {
        key,
        externalParams: { ...params, scenarioId },
      };
    }
    if (reportType === reportTypes.CASH_IN_OUT) {
      key.unshift(CASH_FORECAST);
      return {
        key,
        params: { ...params, scenarioId },
      };
    }
    if (reportType === reportTypes.PROFIT_AND_LOSS) {
      key.unshift(PROFIT_AND_LOSS);
    } else if (
      reportType === reportTypes.CASH_FLOW_STATEMENT ||
      reportType === reportTypes.BALANCE_SHEET
    ) {
      const userReportName =
        reportType === reportTypes.BALANCE_SHEET
          ? 'Balance Sheet'
          : 'Cash Flow Statement';
      params.reportName = userReportName;
      key.unshift(USER_REPORTS, userReportName);
    }

    return {
      key,
      params: { ...params, scenarioIds },
    };
  }, [
    startDate,
    endDate,
    timePeriod,
    reportType,
    reportsInfo,
    scenarioId,
    compareScenarioId,
  ]);

  return useQuery(
    queryConfig.key,
    async ({ signal }) => {
      const queryFn = getQueryFunction(reportType, reportsInfo);

      const params = queryConfig.externalParams ?? queryConfig.params;
      const result = await queryFn({
        ...params,
        signal,
      });

      const data =
        reportType === reportTypes.CASH_IN_OUT
          ? restructureCashInOutData(result.data.data.cashInOut)
          : result.data.data.children;
      return transformDataForGrid(data);
    },
    { staleTime: 60000, enabled },
  );
};

export default useReportData;
