// @ts-check
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import CompanyDetail, {
  companyDetailFields,
} from '@/components/Settings/CompanyDetails';
import useCompanyDetailsQuery from '@/components/Settings/useCompanyDetailsQuery';
import ModalLockout from '@/components/common/ModalLockout';
import useIsAdminUser from '@/hooks/useIsAdminUser';

/** @type {() => React.ReactElement} */
const CompanyInfoRequiredModal = () => {
  const isAdmin = useIsAdminUser();
  const selectedCompanyId = useSelector(
    ({ companies }) => companies.selectedCompanyId,
  );
  const { data: companyMetaData } = useCompanyDetailsQuery(selectedCompanyId);

  const headingContent = (
    <>
      <div>Information </div>Required!
    </>
  );

  const hiddenFields =
    /** @type {import('@/components/Settings/CompanyDetails').HiddenFields} */ (
      [
        !!companyMetaData?.industryId && companyDetailFields.INDUSTRY_ID,
        !!companyMetaData?.address && companyDetailFields.ADDRESS,
      ].filter(Boolean)
    );

  return (
    <ModalLockout header={headingContent}>
      {isAdmin ? (
        <>
          <p className="ModalLockout_Content">
            In order to continue using Finmark, we will need you to provide the
            following information.
          </p>
          <div className="ModalLockout_AddressContainer">
            <CompanyDetail
              companyId={selectedCompanyId}
              showIndustryAndAddress
              hiddenFields={hiddenFields}
              isLockedModal
              hideStreetLineTwo
              saveButtonText="Save"
            />
          </div>
        </>
      ) : (
        <p className="ModalLockout_Content">
          Your company is missing mandatory industry and/or HQ location
          information. Please request company admins to complete the information
          in company settings to continue using Finmark.
        </p>
      )}
    </ModalLockout>
  );
};

export default CompanyInfoRequiredModal;
