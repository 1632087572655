// @ts-check
export const notificationSeverity = /** @type {const} */ ({
  INFO: 0,
  WARNING: 1,
  DANGER: 2,
});

/**
 * @typedef {(typeof notificationSeverity)[keyof typeof notificationSeverity]}
 *   NotificationSeverity
 */

export const notificationIds = /** @type {const} */ ({
  PENDING_COMPANY_ADDRESS: '60317ef1-c051-4e1e-b194-1f95c2096713',
  PENDING_USER_COUNTRY: '632240ca-5d0e-408e-8cec-eb2ad0e3febf',
});

/**
 * @typedef {(typeof notificationIds)[keyof typeof notificationIds]}
 *   NotificationId
 */

/**
 * @typedef {{
 *   id: NotificationId;
 *   severity: NotificationSeverity;
 *   isDismissed: boolean;
 * }} Notification
 */

/**
 * @typedef {{
 *   [key in notificationIds[keyof typeof notificationIds]]: Notification;
 * }} NotificationType
 */

/** @type {NotificationType} */
export const notificationTypes = {
  [notificationIds.PENDING_COMPANY_ADDRESS]: {
    id: notificationIds.PENDING_COMPANY_ADDRESS,
    severity: notificationSeverity.WARNING,
    isDismissed: false,
  },
  [notificationIds.PENDING_USER_COUNTRY]: {
    id: notificationIds.PENDING_USER_COUNTRY,
    severity: notificationSeverity.DANGER,
    isDismissed: false,
  },
};

export const calculationThresholds = /** @type {const} */ ({
  MAX_THRESHOLD: 600000,
  MIN_THRESHOLD: 2500,
  MIN_PERSIST_TIME: 3500,
  TIME_INTERVAL: 500,
});
