// @ts-check
export const actions = /** @type {const} */ ({
  SET_EMAIL: 'SET_EMAIL',
  SET_PASSWORD: 'SET_PASSWORD',
  SET_PASSWORD_CONFIRM: 'SET_PASSWORD_CONFIRM',
  SET_TOS: 'SET_TOS',
  SET_STEP: 'SET_STEP',
  SET_PASSWORD_LOGIN: 'SET_PASSWORD_LOGIN',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_COUNTRY: 'SET_COUNTRY',
});
export const XERO = 'xero';
export const steps = /** @type {const} */ ({
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  LOGIN: 'LOGIN',
  INTEGRATIONS: 'INTEGRATIONS',
});
export const routeProtections = {
  EMAIL: 'email',
  AUTH_TOKEN: 'authToken',
};

/**
 * @typedef {actions} ActionTypes
 *
 * @typedef {steps} RegistrationStepsType
 */
