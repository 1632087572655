import FormulaDefs from './FormulaDef';
import Offset from './Offset';
import Operator from './Operator';
import Static from './Static';
import Variable from './Variable';

const FormulaGuide = {
  Offset,
  Operator,
  Static,
  Variable,
  Defs: FormulaDefs,
};

export default FormulaGuide;
