// @ts-check
import { useLocation } from 'react-router-dom';
import BellIcon from '@bill/cashflow.assets/bell';
import CheckmarkDoubleIcon from '@bill/cashflow.assets/checkmark-double';
import ConfettiIcon from '@bill/cashflow.assets/confetti-icon';
import DeleteIcon from '@bill/cashflow.assets/delete';
import LightBulbIcon from '@bill/cashflow.assets/light-bulb-icon';
import SyncIcon from '@bill/cashflow.assets/sync';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NOTIFICATIONS } from '@/cacheKeys';
import IconButton from '@/components/common/IconButton';
import NotificationPendingEmployee from '@/components/common/Notifications/NotificationPendingEmployee';
import { INTEGRATIONS_TEXT } from '@/constants/integrations';
import { formatDateFromNow } from '@/helpers/dateFormatter';
import { getRedirectUrl } from '@/helpers/integrationsRedirect';
import useCurrentSelectedCompany from '@/hooks/useCurrentSelectedCompany';
import useIsAdminUser from '@/hooks/useIsAdminUser';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import useTypedSelector from '@/hooks/useTypedSelector';
import {
  dismissNotification,
  dismissNotifications,
} from '@/services/notificationCenterService';
import './NotificationCenter.scss';

const LOADER = 'loader';
export const PENDING_RECORDS = 'pendingRecords';
const integrationNames = Object.values(INTEGRATIONS_TEXT);

// Note: Opening and closing tags are identical, e.g. <b>Bold Text<b>
const BOLD_TAG = '<b>';
const ANCHOR_TAG = '<a>';

/**
 * @typedef {{
 *   type: string;
 *   buttonText: string;
 *   id: number;
 *   name: string;
 *   pathname: string;
 *   identityToken: string;
 * }} IntegrationReconnectLinkParams
 * @type {(params: IntegrationReconnectLinkParams) => JSX.Element}
 */
const getIntegrationReconnectLink = ({
  type,
  buttonText,
  id,
  name,
  pathname,
  identityToken,
}) => {
  const formattedIntegrationType = type.replace(/ /g, '_');

  return (
    <a
      href={getRedirectUrl({
        token: identityToken,
        name,
        id,
        integrationType: formattedIntegrationType,
        redirectTo: pathname.slice(1),
      })}
      key={type}
      className="Deauthorized_Link"
      data-testid={`notification-integration-anchor-${type}`}
    >
      {buttonText}
    </a>
  );
};

/**
 * Add keys when rendering an array of strings and JSX.Element
 *
 * @type {(jsx: (JSX.Element | string)[]) => JSX.Element}
 */
const mapJSX = (jsx) => (
  <>
    {jsx.map((elem, index) => (
      // eslint-disable-next-line react/no-array-index-key -- predates description requirement
      <span key={index}>{elem}</span>
    ))}
  </>
);

/**
 * Custom formats the notification text according to the type of notification.
 * For example: Make bold the integrationPlatform name ('Quickbooks') OR convert
 * text ('here') to a button
 *
 * @typedef {{
 *   linkType: string;
 *   text: string;
 *   identityToken: string;
 * }} FormattedTextProps
 * @type {(props: FormattedTextProps) => JSX.Element}
 */
export const FormattedText = ({ linkType, text, identityToken }) => {
  const isAdmin = useIsAdminUser();
  const { pathname } = useLocation();
  const { name, id } = useCurrentSelectedCompany();

  if (linkType === PENDING_RECORDS) {
    return (
      <NotificationPendingEmployee className="Button Button-primaryLink EmployeeModal_Link" />
    );
  }

  /** @type {(string | JSX.Element)[]} */
  let jsx = [text];

  if (text.includes(BOLD_TAG)) {
    const [start, integrationName, end] = text.split(BOLD_TAG);
    jsx = [start, <b>{integrationName}</b>, end];
  }

  if (!isAdmin || !text.includes(ANCHOR_TAG)) return mapJSX(jsx);

  const element = String(jsx.pop());
  const [start, hyperlinkButtonText, end] = element.split(ANCHOR_TAG);
  const integrationName = integrationNames.find((word) => text.includes(word));
  const integrationType = Object.keys(INTEGRATIONS_TEXT).find(
    (key) => INTEGRATIONS_TEXT[key] === integrationName,
  );
  const integrationReconnectLink = getIntegrationReconnectLink({
    type: integrationType,
    buttonText: hyperlinkButtonText,
    id,
    name,
    pathname,
    identityToken,
  });
  jsx.push(start, integrationReconnectLink, end);
  return mapJSX(jsx);
};

/**
 * Renders the NotificationCenter's Contents which lists all important and
 * normal notifications
 *
 * @typedef {import('@/types/notificationCenter').ImportantNotification} ImportantNotification
 *
 *
 * @typedef {import('@/types/notificationCenter').NormalNotification} NormalNotification
 * @type {(props: {
 *   notifications: [ImportantNotification[], NormalNotification[]];
 * }) => JSX.Element}
 */
const NotificationCenterContent = ({ notifications }) => {
  const { identityToken } = useTypedSelector((state) => state.auth);
  const queryClient = useQueryClient();
  const [scenarioId] = useSelectedScenarioIds();
  const queryKey = [NOTIFICATIONS, scenarioId];
  const [importantNotifications = [], normalNotifications = []] = notifications;

  const { mutate: dismissAll } = useMutation(dismissNotifications, {
    onSuccess: () => {
      queryClient.setQueryData(queryKey, ([impNotifications]) => [
        impNotifications,
        [],
      ]);
    },
  });

  const { mutate: dismissNotificationById } = useMutation(dismissNotification, {
    onSuccess: (_, { id }) => {
      queryClient.setQueryData(
        queryKey,
        ([impNotifications, normNotifications]) => [
          impNotifications,
          normNotifications.filter((notification) => notification.id !== id),
        ],
      );
    },
  });

  return (
    <div className="NotificationCenter_Content">
      <div className="NotificationCenter_Title">
        <div>Notifications</div>
        {!!normalNotifications.length && (
          <button
            type="button"
            className="NotificationCenter_DismissAllButton"
            onClick={() => dismissAll(scenarioId)}
            data-testid="dismiss-all-btn"
          >
            <CheckmarkDoubleIcon
              className="DismissAll_Icon"
              aria-hidden="true"
            />
            <span className="NotificationCenter_DismissAllButtonLabel">
              Dismiss all
            </span>
          </button>
        )}
      </div>
      {!!importantNotifications.length && (
        <>
          <div
            className="NotificationCenter_SectionTitle"
            data-testid="important-notification-title"
          >
            <LightBulbIcon
              className="NotificationCenter_SectionIcon"
              aria-hidden="true"
            />
            <span>Important</span>
          </div>
          {importantNotifications.map(({ id, metadata, text }) => (
            <div
              key={id}
              className="Notification Notification-important"
              data-testid="important-notification-msg"
            >
              <FormattedText
                linkType={metadata.linkType}
                text={text}
                identityToken={identityToken}
              />
              {metadata?.animation === LOADER && (
                <SyncIcon className="SyncIcon" aria-hidden="true" />
              )}
            </div>
          ))}
        </>
      )}
      {!!normalNotifications.length && (
        <>
          <div
            className="NotificationCenter_SectionTitle"
            data-testid="notification-title"
          >
            <BellIcon
              className="NotificationCenter_SectionIcon"
              aria-hidden="true"
            />
            <span>Notifications</span>
          </div>
          <div className="Notifications_Group">
            {normalNotifications.map(({ id, date, metadata, text }) => {
              return (
                <div
                  key={id}
                  className="Notification"
                  data-testid="notification-msg"
                >
                  <FormattedText
                    linkType={metadata.linkType}
                    text={text}
                    identityToken={identityToken}
                  />
                  <div className="Notification_Timestamp">
                    {formatDateFromNow(date)}
                    <IconButton
                      data-testid="notification-delete-button"
                      Icon={DeleteIcon}
                      label="Dismiss"
                      onClick={() => {
                        dismissNotificationById({
                          id,
                          scenarioId,
                        });
                      }}
                      className="DismissButton"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {notifications.every((notificationType) => !notificationType.length) && (
        <div
          className="NotificationCenter_Empty"
          data-testid="notification-center-empty-state"
        >
          <ConfettiIcon className="NotificationCenter_EmptyIcon" />
          <div className="NotificationCenter_EmptyContent">
            <div>Congrats.</div>
            <div>You're all caught up!</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default NotificationCenterContent;
