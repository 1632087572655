// @ts-check
import { forwardRef } from 'react';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';
import { PAGE_ERROR_BOUNDARY_TEXT } from '@/constants/pages';
import { classNames } from '@/helpers';
import './Page.scss';

/**
 * @typedef {{
 *   id?: string;
 *   children: React.ReactNode;
 *   className?: string;
 * }} PageProps
 */

/** @type {React.ForwardRefRenderFunction<HTMLDivElement, PageProps>} */
const PageFn = ({ children, className, id }, ref) => {
  return (
    <div
      className={classNames(
        'ExportableReport_Page',
        'js-ExportableReport_Page',
        className,
      )}
      ref={ref}
      id={id}
    >
      <div className="ExportableReport_Content">
        <CommonErrorBoundary text={PAGE_ERROR_BOUNDARY_TEXT}>
          <>{children}</>
        </CommonErrorBoundary>
      </div>
    </div>
  );
};

const Page = forwardRef(PageFn);

export default Page;
