export const CHART_TITLE = 'Cash Out';
export const TOTAL_CASH_OUT_MAIN_METRIC = 'totalY';

export const CASH_OUT_METRICS = [
  {
    key: 'y',
    name: CHART_TITLE,
    isMainMetric: true,
  },
];
