import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { subscribeToEmployeeUpdateAction } from '@/actions/employees';
import { getExpensesClassesAction } from '@/actions/expenses';
import DepartmentList from '@/components/Settings/Department/DepartmentList';
import TheDepartmentsModal from '@/components/Settings/Department/TheDepartmentsModal';
import useWsSubscription from '@/hooks/useWsSubscription';

const Department = ({
  scenarioId,
  expenseClasses,
  getExpenseClasses,
  subscribeToEmployeeUpdate,
}) => {
  const [showDepartmentsModal, setShowDepartmentsModal] = useState(false);

  const getExpenseClassesCallback = () => {
    getExpenseClasses(scenarioId, false);
  };

  useEffect(getExpenseClassesCallback, [scenarioId, getExpenseClasses]);

  useWsSubscription(
    () => subscribeToEmployeeUpdate(scenarioId, getExpenseClassesCallback),
    [scenarioId],
  );

  return (
    <>
      <DepartmentList
        expenseClasses={expenseClasses}
        onEdit={() => setShowDepartmentsModal(true)}
      />

      {showDepartmentsModal && (
        <TheDepartmentsModal onClose={() => setShowDepartmentsModal(false)} />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  scenarioId: state.scenario.scenarioId,
  companyId: state.companies.selectedCompanyId,
  expenseClasses: state.expenses.expensesClasses,
});

export default connect(mapStateToProps, {
  getExpenseClasses: getExpensesClassesAction,
  subscribeToEmployeeUpdate: subscribeToEmployeeUpdateAction,
})(Department);
