import { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import QuestionMarkIcon from '@bill/cashflow.assets/question-mark';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';
import DateField from '@/components/common/DateField';
import FormLabel from '@/components/common/FormLabel';
import FormRadio from '@/components/common/FormRadio';
import FormulaField from '@/components/common/FormulaField';
import FormulaValidationMessageWithLink from '@/components/common/FormulaValidationMessageWithLink';
import InfoTooltip from '@/components/common/InfoTooltip';
import NumberField from '@/components/common/NumberField';
import Select from '@/components/common/Select';
import {
  EXPENSE_FREQUENCY,
  expenseGroupTypes,
  invoiceTypes,
  SET_FORMULA_VALIDATION_RESPONSE,
  SET_CUSTOM_FORMULA,
  SET_INITIAL_EXP_AMOUNT,
  ONE_TIME_EXPENSE,
  MONTHLY_EXPENSE,
  expenseTypes,
  NON_CASH_PAYMENT_TERM,
  NON_CASH_EXPENSE_CLASS,
} from '@/constants/expenses';
import VALID_FORMULA_MSG, {
  EXPENSE_FORECAST_METHODS,
} from '@/constants/formulas';
import { getISODate, getNextDay } from '@/helpers/dateFormatter';

/* eslint-disable-next-line import/no-deprecated -- predates description requirement */
import { isDefined, isEmptyOrNull } from '@/helpers/validators';
import getBaseScenario from '@/selectors/getBaseScenario';
import { getEligibleParentExpenses } from '@/services/expensesService';
import validateCustomFormula from '@/services/formula.service';
import AmountOfChange from './AmountOfChange';
import ExpenseAccountNumber from './ExpenseAccountNumber';
import ExpenseAmount from './ExpenseAmount';
import ExpenseLink from './ExpenseLink';
import ExpenseName from './ExpenseName';
import ParentExpenseGuideModal from './ParentExpenseGuideModal';
import './Form.scss';

const INITIAL_DEPARTMENT_OPTIONS = [
  <option value={null} key="empty">
    Select a department
  </option>,
];

const ExpenseFrequencyOptions = ({ frequency, isChangeOverTime = false }) =>
  Object.entries(EXPENSE_FREQUENCY).reduce(
    (options, [id, name]) => {
      const numberId = Number(id);

      // Filter out One-Time frequency
      if (numberId === ONE_TIME_EXPENSE) return options;

      return [
        ...options,
        <option
          value={id}
          key={id}
          disabled={isChangeOverTime && numberId < frequency}
        >
          {name}
        </option>,
      ];
    },
    [
      <option value={null} key="empty">
        Select a frequency
      </option>,
    ],
  );

const validateForm = (localFormState) => {
  const {
    frequency,
    departmentId,
    name,
    endDate,
    expenseType,
    expenseAmount,
    startDate,
    changeOverTime,
    changeFrequency,
    expenseGroupCriteria,
    customFormula,
    isFormulaValid,
    changeAmount,
  } = localFormState;
  const requiredFields = [departmentId, name, startDate, expenseType];
  const requiredFieldsRecurring = [...requiredFields, frequency];

  if (endDate && startDate > endDate) {
    return false;
  }

  if (
    expenseGroupCriteria &&
    /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
    !expenseGroupCriteria.every(({ value }) => isDefined(value))
  ) {
    return false;
  }

  /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
  if (isDefined(customFormula) && !isFormulaValid) {
    return false;
  }

  switch (true) {
    case frequency === ONE_TIME_EXPENSE: {
      if (expenseType === expenseTypes.CUSTOM_FORMULA) {
        /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
        return [...requiredFields, customFormula].every(isDefined);
      }
      /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
      return [...requiredFields, expenseAmount].every(isDefined);
    }
    case changeOverTime:
      return [
        ...requiredFieldsRecurring,
        changeFrequency,
        changeAmount,
        expenseAmount,
        /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
      ].every(isDefined);
    case expenseType === expenseTypes.CUSTOM_FORMULA:
      /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
      return [...requiredFieldsRecurring, customFormula].every(isDefined);
    default:
      /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
      return [...requiredFieldsRecurring, expenseAmount].every(isDefined);
  }
};

const validateStartDate = (dateStr) =>
  !dateStr && 'Must provide an expense date';

const ExpenseForm = ({
  departments,
  nonCashExpenseClass,
  paymentTerms,
  scenario,
  scenarioId,
  state,
  onCancel,
  onSubmit,
  onUpdate,
  onFormulaGuideClick,
  hasWritePermission,
}) => {
  const [departmentOptions, setDepartmentOptions] = useState(
    INITIAL_DEPARTMENT_OPTIONS,
  );
  const [eligibleParent, setEligibleParent] = useState([]);
  const [isInitialExpenseToggled, setInitialExpenseToggled] = useState(
    !isEmptyOrNull(state.initialAmount),
  );
  const [loading, setLoading] = useState(false);

  const [parentExpenseGuide, setParentExpenseGuide] = useState(false);

  const isPaymentTermDisabled = useMemo(() => {
    return !!nonCashExpenseClass?.departments.find(
      (department) => department.id === state.departmentId,
    );
  }, [state.departmentId, nonCashExpenseClass?.departments]);

  const isValid = useMemo(() => validateForm(state), [state]);
  const isEditForm = Boolean(state.id);
  const isOneTimeExpense = state.frequency === ONE_TIME_EXPENSE;
  const isChangeOverTimeVisible = state.expenseType === expenseTypes.AMOUNT;
  const isCustomFormulaVisible =
    state.expenseType === expenseTypes.CUSTOM_FORMULA;
  const isChangeOverTimeDisabled = state.changeOverTime === false;
  const isInvoiceTimingDisabled =
    state.payroll ||
    [MONTHLY_EXPENSE, ONE_TIME_EXPENSE].includes(state.frequency);

  const getEligibleParents = useCallback(async () => {
    if (!state.departmentId) return;
    try {
      const {
        data: { data },
      } = await getEligibleParentExpenses(
        scenarioId,
        state.id,
        state.departmentId,
      );
      setEligibleParent(data);
    } catch (e) {
      // eslint-disable-next-line no-console -- predates description requirement
      console.error(e);
    }
  }, [state.departmentId, scenarioId, state.id]);

  useEffect(() => {
    if (
      scenario.hasAccountingIntegration &&
      !state.id &&
      isEmptyOrNull(state.expenseGroupCriteria)
    ) {
      onUpdate({ type: 'ADD_EXPENSE_LINK' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [onUpdate, scenario]);

  useEffect(() => {
    getEligibleParents();
  }, [state.departmentId, getEligibleParents]);

  useEffect(() => {
    setDepartmentOptions([
      ...INITIAL_DEPARTMENT_OPTIONS,
      ...departments.map(({ id, name }) => (
        <option value={id} key={id}>
          {name}
        </option>
      )),
    ]);
  }, [departments]);

  useEffect(() => {
    if (isOneTimeExpense) {
      onUpdate({ type: 'SET_ONE_TIME_EXPENSE' });
    }
  }, [isOneTimeExpense, onUpdate]);

  useEffect(() => {
    if (state.errorMessage) {
      setLoading(false);
    }
  }, [state.errorMessage]);

  const startDateMs = state.startDate
    ? new Date(state.startDate).getTime()
    : null;
  const endDateMs = state.endDate ? new Date(state.endDate).getTime() : null;

  const showLinking =
    scenario?.hasAccountingIntegration &&
    state.type !== expenseGroupTypes.ACCOUNT;

  const showPayroll =
    (isEditForm && state.type === expenseGroupTypes.ACCOUNT) ||
    !!state.expenseGroupCriteria;
  const filteredPaymentTerms = state.payroll
    ? paymentTerms.filter(({ name }) => name !== NON_CASH_PAYMENT_TERM)
    : paymentTerms;

  const filteredPaymentTermsList = useMemo(
    () =>
      hasWritePermission
        ? filteredPaymentTerms
        : [
            {
              id: state.paymentTermId,
              name: state.paymentTermName,
            },
          ],
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [filteredPaymentTerms],
  );

  const actionVerb = isEditForm ? 'Save' : 'Add';
  const submitBtnLabel = state.expenseGroupCriteria
    ? 'Preview'
    : `${actionVerb} Expense`;

  const formHeading = useMemo(() => {
    if (!hasWritePermission) {
      return 'Expense Details';
    }

    return `${isEditForm ? 'Edit' : 'Add'} Expense`;
  }, [hasWritePermission, isEditForm]);

  const handleChangeStartDate = useCallback(
    (date) =>
      onUpdate({
        type: 'SET_START_DATE',
        payload: date ? getISODate(date) : null,
      }),
    [onUpdate],
  );

  const handleChangeEndDate = useCallback(
    (date) =>
      onUpdate({
        type: 'SET_END_DATE',
        payload: date ? getISODate(date) : null,
      }),
    [onUpdate],
  );

  return (
    <>
      <h2 className="ModalBase_Heading">{formHeading}</h2>
      {state.errorMessage && (
        <p className="alert alert-danger">
          <b>Error </b> - {state.errorMessage}
        </p>
      )}
      <div className="Form">
        <div className="Form_Group Form_Group-halfWidth">
          <label className="Label" htmlFor="department">
            Expense Department
          </label>
          <Select
            id="department"
            name="departmentId"
            disabled={!!state.parentId}
            data-testid="add-expense-department"
            value={state.departmentId}
            onChange={({ target }) => {
              const value = Number(target.value);
              onUpdate({
                type: 'SET_DEPARTMENT_ID',
                payload: Number.isFinite(value) ? value : null,
              });
              onUpdate({
                type: 'SET_PARENT_ID',
                payload: null,
              });
            }}
            validate={(value) => value === null && 'Must provide a department'}
          >
            {departmentOptions}
          </Select>
        </div>
        <div className="Form_Group Form_Group-halfWidth">
          <div className="Form_LabelWithButton">
            <FormLabel htmlFor="parent" text="Parent Expense" />
            <button
              type="button"
              className="Button-iconOnly"
              aria-label="Help"
              onClick={() => setParentExpenseGuide(true)}
            >
              <QuestionMarkIcon className="HelpIcon" aria-hidden="true" />
            </button>
          </div>

          <Select
            id="parent"
            name="parentId"
            disabled={!state.departmentId}
            data-testid="add-expense-parent"
            value={state.parentId}
            onChange={({ target }) => {
              onUpdate({
                type: 'SET_PARENT_ID',
                payload: target.value === '0' ? null : target.value,
              });
            }}
          >
            <option value={0} key="empty-parent">
              No Parent Expense
            </option>
            {eligibleParent.map(({ id, name }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
          </Select>
        </div>
        <div className="Form Form_Group">
          <ExpenseAccountNumber
            defaultValue={state.expenseAccountNum}
            onUpdate={onUpdate}
            isDisabled={state.type === expenseGroupTypes.ACCOUNT}
          />
          <ExpenseName
            defaultValue={state.name}
            onUpdate={onUpdate}
            isDisabled={state.type === expenseGroupTypes.ACCOUNT}
          />
        </div>
        <div className="Form_Group">
          <FormRadio
            id="recurring"
            name="rate-of-occurance"
            label="Recurring"
            className="ExpenseRadio"
            data-testid="expense-recurring"
            checked={state.frequency === null ? true : !isOneTimeExpense}
            value="recurring"
            onChange={() =>
              onUpdate({
                type: 'SET_RATE_OF_OCCURANCE',
                payload: null,
              })
            }
          />
          <FormRadio
            id="one-time"
            name="rate-of-occurance"
            label="One-time"
            className="ExpenseRadio"
            data-testid="expense-one-time"
            checked={isOneTimeExpense}
            value="one-time"
            onChange={() =>
              onUpdate({
                type: 'SET_RATE_OF_OCCURANCE',
                payload: ONE_TIME_EXPENSE,
              })
            }
          />
        </div>
        <div className="Form_Group Form_Group-halfWidth">
          <label className="Label" htmlFor="expense-type">
            Forecast Method
          </label>
          <Select
            id="expense-type"
            name="expenseType"
            data-testid="expense-type"
            value={state.expenseType}
            onChange={({ target }) => {
              const value = Number(target.value);
              onUpdate({
                type: 'SET_EXPENSE_TYPE',
                payload: Number.isFinite(value) ? value : null,
              });
            }}
            validate={(value) =>
              value === null && 'Must provide a forecast method'
            }
          >
            <option disabled value={null}>
              Select forecast method
            </option>
            <option value={expenseTypes.AMOUNT}>Amount</option>
            <option value={expenseTypes.CUSTOM_FORMULA}>Custom Formula</option>
          </Select>
        </div>
        {!isCustomFormulaVisible ? (
          <ExpenseAmount
            label="Amount"
            defaultExpenseAmount={state.expenseAmount}
            expenseType={state.expenseType}
            onUpdate={onUpdate}
            key={state.expenseType}
          />
        ) : (
          <div className="Form_Group Form_Group-halfWidth" />
        )}
        <div className="Form_Group Form_Group-halfWidth">
          <label className="Label" htmlFor="expense-date">
            Date
          </label>
          <DateField
            id="expense-date"
            className="ExpenseDatePicker"
            value={startDateMs}
            validate={validateStartDate}
            onChange={handleChangeStartDate}
          />
        </div>
        {!isOneTimeExpense && (
          <>
            <div className="Form_Group Form_Group-halfWidth">
              <label className="Label" htmlFor="frequency">
                Frequency
              </label>
              <Select
                id="frequency"
                name="frequency"
                data-testid="expense-frequency"
                value={state.frequency}
                onChange={({ target }) => {
                  const value = Number(target.value);
                  if (value === MONTHLY_EXPENSE) {
                    onUpdate({
                      type: 'SET_INVOICE_TIMING',
                      payload: invoiceTypes.UPFRONT,
                    });
                  }
                  onUpdate({
                    type: 'SET_FREQUENCY',
                    payload: Number.isFinite(value) ? value : null,
                  });
                }}
                validate={(value) =>
                  value === null && 'Must provide an expense frequency'
                }
              >
                <ExpenseFrequencyOptions frequency={state.frequency} />
              </Select>
            </div>
            <div className="Form_Group Form_Group-halfWidth">
              <label className="Label" htmlFor="expense-end-date">
                End Date
              </label>
              <DateField
                id="expense-end-date"
                className="ExpenseDatePicker"
                value={endDateMs}
                onChange={handleChangeEndDate}
                min={startDateMs ? getNextDay(new Date(startDateMs)) : null}
              />
            </div>
            <div className="Form_Group Form_Group-halfWidth">
              <Checkbox
                id="exclude-end-date"
                name="noEndDate"
                className="ExpenseCheckbox"
                data-testid="exclude-end-date"
                checked={!state.endDate}
                onChange={() => {
                  if (!state.endDate) {
                    const tomorrow = getNextDay(new Date());
                    onUpdate({
                      type: 'SET_END_DATE',
                      payload: getISODate(tomorrow),
                    });
                  } else {
                    onUpdate({
                      type: 'SET_END_DATE',
                      payload: '',
                    });
                  }
                }}
              >
                No End Date
              </Checkbox>
            </div>
            {isChangeOverTimeVisible && (
              <>
                <div
                  className="Form_Group"
                  role="radiogroup"
                  aria-labelledby="expense-change-label"
                >
                  <p id="expense-change-label">
                    Does the amount of the expense change over time?
                  </p>
                  <FormRadio
                    id="change-over-time-yes"
                    name="changeOverTime"
                    label="Yes"
                    className="ExpenseRadio"
                    data-testid="expense-change-over-time-yes"
                    checked={state.changeOverTime}
                    value="true"
                    onChange={() =>
                      onUpdate({
                        type: 'SET_CHANGE_OVER_TIME',
                        payload: true,
                      })
                    }
                  />
                  <FormRadio
                    id="change-over-time-no"
                    name="changeOverTime"
                    label="No"
                    className="ExpenseRadio"
                    data-testid="expense-change-over-time-no"
                    checked={!state.changeOverTime}
                    value="false"
                    onChange={() =>
                      onUpdate({
                        type: 'RESET_CHANGE_OVER_TIME',
                      })
                    }
                  />
                </div>
                <div className="Form_Group Form_Group-halfWidth">
                  <label
                    className="Label"
                    htmlFor="expense-change-frequency-period"
                  >
                    Frequency
                  </label>
                  <Select
                    id="expense-change-frequency-period"
                    name="changeFrequency"
                    data-testid="expense-change-frequency-period"
                    value={state.changeFrequency}
                    onChange={({ target }) => {
                      const value = Number(target.value);
                      onUpdate({
                        type: 'SET_CHANGE_FREQUENCY',
                        payload: Number.isFinite(value) ? value : null,
                      });
                    }}
                    validate={(value) =>
                      value === null && 'Must provide a frequency'
                    }
                    disabled={isChangeOverTimeDisabled}
                    key={state.changeOverTime}
                  >
                    <ExpenseFrequencyOptions
                      frequency={state.frequency}
                      isChangeOverTime
                    />
                  </Select>
                </div>
                <AmountOfChange
                  onUpdate={onUpdate}
                  defaultChangeAmount={state.changeAmount}
                  changeType={state.expenseChangeType}
                  isChangingOverTimeDisabled={isChangeOverTimeDisabled}
                  key={state.changeOverTime}
                />
              </>
            )}
          </>
        )}
        {showPayroll && (
          <div className="Form_Group" role="radiogroup">
            <p id="expense-payroll-label" className="Expenses_PayrollLabel">
              Is this a payroll expense (related to salary, benefits, or taxes)?
              <InfoTooltip
                placement="right"
                data-testid="payroll-expense-tooltip"
              >
                If this account is used for payroll expenses (salary, or
                benefits and taxes) in your external accounting platform then
                mark it as a payroll expense by choosing “Yes”
              </InfoTooltip>
            </p>
            <FormRadio
              id="expense-payroll-yes"
              name="payroll"
              label="Yes"
              className="ExpenseRadio"
              data-testid="expense-payroll-yes"
              checked={state.payroll}
              value="true"
              onChange={() =>
                onUpdate({
                  type: 'SET_PAYROLL_EXPENSE',
                  payload: true,
                })
              }
            />
            <FormRadio
              id="expense-payroll-no"
              name="payroll"
              label="No"
              className="ExpenseRadio"
              data-testid="expense-payroll-no"
              checked={!state.payroll}
              value="false"
              onChange={() =>
                onUpdate({
                  type: 'SET_PAYROLL_EXPENSE',
                  payload: false,
                })
              }
            />
          </div>
        )}
        {isCustomFormulaVisible && (
          <>
            {!isOneTimeExpense && (
              <div className="Form_Group">
                <FormLabel
                  htmlFor="initial-exp-amount"
                  text="Initial Expense Amount"
                  tooltip="Using an initial value will override the value generated by
                    the formula for the first month."
                  optional
                />
                <Checkbox
                  id="initial-exp-amount"
                  className="CustomFormulaDriverValues_NewSubCheckbox"
                  checked={isInitialExpenseToggled}
                  onChange={() => {
                    if (isInitialExpenseToggled) {
                      setInitialExpenseToggled(false);
                      onUpdate({
                        type: SET_INITIAL_EXP_AMOUNT,
                        payload: null,
                      });
                    } else {
                      setInitialExpenseToggled(true);
                    }
                  }}
                >
                  <NumberField
                    id="initial-new-sub-field"
                    placeholder={0}
                    className="CustomFormulaDriverValues_NewSub"
                    disabled={!isInitialExpenseToggled}
                    allowNegativeValues={false}
                    value={state.initialAmount}
                    onChange={(_, value) =>
                      onUpdate({
                        type: SET_INITIAL_EXP_AMOUNT,
                        payload: value,
                      })
                    }
                  />
                </Checkbox>
              </div>
            )}
            <div className="Form Form_Group">
              <div className="Form_Group">
                <div className="CustomFormulaDriver_Label">
                  <label className="Label" htmlFor="formula-field">
                    Custom Formula - Expense Amount
                  </label>
                  <button
                    type="button"
                    className="Button-iconOnly HelpIconButtonWrapper"
                    aria-label="Help"
                    onClick={onFormulaGuideClick}
                  >
                    <QuestionMarkIcon className="HelpIcon" aria-hidden="true" />
                  </button>
                </div>
                <Alert variant="primary">
                  All custom formulas created here will generate expense
                  amounts.
                </Alert>
                <FormulaField
                  id="formula-field"
                  presetFormulasId={EXPENSE_FORECAST_METHODS}
                  value={state.customFormula}
                  onChange={(value) => {
                    onUpdate({
                      type: SET_CUSTOM_FORMULA,
                      payload: value,
                    });
                  }}
                  onBlur={async () => {
                    const {
                      data: { data },
                    } = await validateCustomFormula(
                      scenarioId,
                      state.customFormula,
                    );
                    onUpdate({
                      type: SET_FORMULA_VALIDATION_RESPONSE,
                      payload: {
                        formulaValidationMsg: data?.valid
                          ? VALID_FORMULA_MSG
                          : data.error,
                        isFormulaValid: data?.valid,
                      },
                    });
                  }}
                  onFocus={() => {
                    onUpdate({
                      type: SET_FORMULA_VALIDATION_RESPONSE,
                      payload: {
                        formulaValidationMsg: '',
                        isFormulaValid: false,
                      },
                    });
                  }}
                />
                <FormulaValidationMessageWithLink
                  message={state.formulaValidationMsg}
                />
              </div>
            </div>
          </>
        )}
        <div className="Form Form_Group">
          <div className="Form_Group Form_Group-halfWidth">
            <label className="Label" htmlFor="invoice-timing">
              Invoice Timing
              <InfoTooltip
                placement="right"
                data-testid="invoice-timing-tooltip"
              >
                Select "Upfront" if the invoice is sent on the first month of
                the frequency term. Select "In Arrears" if the invoice is sent
                on the last month of the frequency term. For monthly expense
                frequency, this selection is not available because monthly
                expenses are assumed to be invoiced in the same month.
              </InfoTooltip>
            </label>
            <Select
              id="invoice-timing"
              name="invoiceTiming"
              data-testid="invoice-timing"
              value={state.invoiceTiming}
              onChange={({ target: { value } }) => {
                onUpdate({
                  type: 'SET_INVOICE_TIMING',
                  payload: value,
                });
              }}
              disabled={isInvoiceTimingDisabled}
            >
              <option value={invoiceTypes.UPFRONT}>Upfront</option>
              <option value={invoiceTypes.ARREARS}>In Arrears</option>
            </Select>
          </div>
          <div className="Form_Group Form_Group-halfWidth">
            <label className="Label" htmlFor="paymentTerm">
              Payment Term
              <InfoTooltip data-testid="payment-term-tooltip">
                Payment Term refers to the payment timing of the invoice. The
                default is "Immediate", which means that payment occurs in the
                same month as the invoice. You may add unique payment terms
                (e.g. Net 60) in Settings and they will be displayed here.
                <br />
                Non-cash will show the expense in the forecast but will not
                change cash flow.
              </InfoTooltip>
            </label>
            <Select
              id="paymentTerm"
              name="paymentTerm"
              disabled={isPaymentTermDisabled}
              value={state.paymentTermId}
              onChange={({ target: { value } }) => {
                onUpdate({
                  type: 'SET_PAYMENT_TERM',
                  payload: value,
                });
              }}
            >
              {filteredPaymentTermsList.map((term) => (
                <option key={term.id} value={term.id}>
                  {term.name}
                </option>
              ))}
            </Select>
          </div>
        </div>
        {showLinking && (
          <ExpenseLink
            criteria={state.expenseGroupCriteria}
            onUpdate={onUpdate}
            hasWritePermission={hasWritePermission}
          />
        )}
      </div>
      {state.payroll && (
        <Alert variant="warning" className="ExpenseAlert">
          All historical actuals for this expense will be added to the “Total
          Payroll & Benefits” line in your Employees actuals. Your payroll
          forecast is driven by salary, benefits and taxes of your current and
          future employees.
        </Alert>
      )}
      <div className="ModalBase_Footer">
        {hasWritePermission && (
          <Button
            onClick={onCancel}
            className="Button-cancelLink"
            data-testid="add-expense-cancel"
          >
            Cancel
          </Button>
        )}
        {hasWritePermission ? (
          <Button
            loading={loading}
            onClick={() => {
              setLoading(true);
              onSubmit();
            }}
            data-testid="add-expense-save"
            disabled={!isValid}
          >
            {submitBtnLabel}
          </Button>
        ) : (
          <Button onClick={onCancel}>Close</Button>
        )}
      </div>
      <ParentExpenseGuideModal
        open={parentExpenseGuide}
        data-testid="parent-expense-guide-modal"
        onClose={() => setParentExpenseGuide(false)}
      />
    </>
  );
};

const mapStateToProps = ({ expenses, scenario }) => ({
  departments: expenses.departments,
  scenario: getBaseScenario({ scenario }),
  scenarioId: scenario.scenarioId,
  nonCashExpenseClass: expenses.expensesClasses.find(
    (expenseClass) => expenseClass.name === NON_CASH_EXPENSE_CLASS,
  ),
});

export default connect(mapStateToProps)(ExpenseForm);
