export const MONTHLY = 'MONTHLY';
/** @typedef {typeof MONTHLY} MONTHLY */
export const QUARTERLY = 'QUARTERLY';
/** @typedef {typeof QUARTERLY} QUARTERLY */
export const ANNUALLY = 'ANNUALLY';
/** @typedef {typeof ANNUALLY} ANNUALLY */

export const timePeriods = {
  [MONTHLY]: 'Monthly',
  [QUARTERLY]: 'Quarterly',
  [ANNUALLY]: 'Annually',
};

export const timePeriodLength = {
  [MONTHLY]: 1,
  [QUARTERLY]: 3,
  [ANNUALLY]: 12,
};
