import HashIcon from '@bill/cashflow.assets/hash';
import PercentageIcon from '@bill/cashflow.assets/percentage';
import { findCurrencySymbol } from '@/constants/currencies';
import { units } from '@/constants/variables';

function CurrencySymbol(props) {
  return <span {...props}>{findCurrencySymbol()}</span>;
}
// eslint-disable-next-line import/prefer-default-export -- predates description requirement
export const unitMap = {
  [units.NUMBER]: {
    label: 'Number',
    UnitSymbol: HashIcon,
  },
  [units.PERCENTAGE]: {
    label: 'Percentage',
    UnitSymbol: PercentageIcon,
  },
  [units.CURRENCY]: {
    label: 'Currency',
    UnitSymbol: CurrencySymbol,
  },
};
