import ContextMenu from '@/components/common/ContextMenu';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';

export default function ContextMenuRenderer({
  data,
  onDuplicateEmployee,
  onDeleteEmployee,
  isDuplicateDisabled,
  onEditEmployee,
}) {
  const { id } = data;

  const hasWritePermission = useNonDashboardWritePermission();

  return (
    <ContextMenu data-testid={`${id}-employee-action`}>
      <ContextMenu.Option
        data-testid="edit-employee"
        className="pendo-EditEmployee"
        onClick={() => onEditEmployee(id)}
      >
        {hasWritePermission ? 'Edit Employee' : 'View Details'}
      </ContextMenu.Option>
      {hasWritePermission && (
        <>
          <ContextMenu.Option
            id={`duplicate-employee-${id}`}
            data-testid="duplicate-employee"
            onClick={() => onDuplicateEmployee(id)}
            disabled={isDuplicateDisabled}
          >
            Duplicate Employee
          </ContextMenu.Option>
          <ContextMenu.Option
            id="delete-employee"
            data-testid="delete-employee"
            danger
            className="pendo-DeleteEmployee"
            onClick={() => onDeleteEmployee(data)}
          >
            Delete Employee
          </ContextMenu.Option>
        </>
      )}
    </ContextMenu>
  );
}
