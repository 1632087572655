// @ts-check
import { useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { METRIC_TAGS_LIST } from '@/cacheKeys';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { unitMap } from '@/helpers/units';
import TagsList from '@/pages/Metrics/TagsList';
import { getTagsList } from '@/services/metricsService';
import { TYPE_CATEGORY } from './constanst';
import './TagsPanel.scss';

const addTypeCategory = (tags) => [
  ...tags,
  {
    metricsCategory: { id: uuidv4(), name: TYPE_CATEGORY },
    tags: Object.keys(unitMap).map((label) => ({
      id: uuidv4(),
      name: label,
      UnitSymbol: unitMap[label].UnitSymbol,
    })),
  },
];

/**
 * @typedef {{
 *   onTagSelection: (prevState) => [string];
 *   tagsSelected: [string];
 *   tagsMap: import('@/pages/Metrics/types').TagsMap;
 *   typeSelected: [string];
 *   onTypeSelection: (prevState) => [string];
 * }} TagsPanelProps
 */

/** @type {(props: TagsPanelProps) => import('react').ReactElement} */
const TagsPanel = ({
  onTagSelection,
  tagsSelected,
  tagsMap,
  typeSelected,
  onTypeSelection,
}) => {
  const { data, isLoading } = useQuery(
    [METRIC_TAGS_LIST],
    async () => {
      const { data: tags } = await getTagsList();
      return addTypeCategory(tags.data);
    },
    // Currently tags are hard coded on BE, so no need to refetch
    { staleTime: Infinity },
  );

  return (
    <div className="Panel TagsPanel_Wrapper">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        data.map(({ metricsCategory, tags }) => (
          <TagsList
            key={metricsCategory.name}
            tags={tags}
            category={metricsCategory.name}
            onTagSelection={onTagSelection}
            tagsSelected={tagsSelected}
            tagsMap={tagsMap}
            typeSelected={typeSelected}
            onTypeSelection={onTypeSelection}
          />
        ))
      )}
    </div>
  );
};

export default TagsPanel;
