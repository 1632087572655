import ContextMenu from '@/components/common/ContextMenu';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import './FormatPricingPlanName.scss';

const FormatPricingPlanName = ({
  product,
  isCurrentPlanSelected,
  productName,
  hasWritePermission,
  onEditPlan,
  onDeletePlan,
}) => {
  return (
    <div
      className="FormatPricingPlanName"
      aria-expanded={isCurrentPlanSelected}
    >
      <TooltipTextOverflow
        label={product.name}
        data-testid={`${productName}-tooltip`}
      />
      {!isCurrentPlanSelected && (
        <ContextMenu data-testid="current-plan-action">
          <ContextMenu.Option data-testid="edit-plan" onClick={onEditPlan}>
            {hasWritePermission ? 'Edit Plan' : 'View Details'}
          </ContextMenu.Option>
          {hasWritePermission && (
            <ContextMenu.Option
              data-testid="delete-plan"
              danger
              disabled={product.isUsedInStripe}
              onClick={onDeletePlan}
            >
              Delete Plan
            </ContextMenu.Option>
          )}
        </ContextMenu>
      )}
    </div>
  );
};

export default FormatPricingPlanName;
