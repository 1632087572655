/**
 * Check if the commission formula field should be enabled
 *
 * @param {Array} departments - An array of department objects
 * @param {number} selectedDepartmentId - The department id selected by the user
 */
// eslint-disable-next-line import/prefer-default-export -- predates description requirement
export const isCommissionEnabled = (departments, selectedDepartmentId) => {
  const { commissionEnabled } = departments.find(
    ({ id }) => selectedDepartmentId.toString() === id.toString(),
  );
  return commissionEnabled;
};
