import {
  RESET_FEATURE_FLAGS,
  SET_ALL_FEATURE_FLAGS,
} from '@/actionTypes/features';
import { registeredFeatureFlags } from '@/constants/features';

const INITIAL_STATE = {
  flags: [],
};

function featuresReducer(state = INITIAL_STATE, { type }) {
  switch (type) {
    case SET_ALL_FEATURE_FLAGS:
      return { ...state, flags: Object.values(registeredFeatureFlags) };
    case RESET_FEATURE_FLAGS:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default featuresReducer;
