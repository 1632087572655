// @ts-check
import { useCallback, useRef } from 'react';

const QUEUE_DEBOUNCE_MS = 25;

/** @typedef {import('ag-grid-community').AgGridEvent} AgGridEvent */

/**
 * Batches updates to an API, such as when pasting multiple cells
 *
 * @example
 *   const addToQueue = useCallback(() => ({ foo: 'bar' }), []);
 *   const flushQueue = useCallback((queue) => updateItems(queue), []);
 *   const handleMonthValueChange = useUpdateQueue(addToQueue, flushQueue);
 *   return (
 *     <MonthlySpreadsheet onMonthValueChange={handleMonthValueChange} />
 *   );
 *
 * @param {(queue: Object[], event: AgGridEvent) => Object[]} addToQueue
 *   Returns a new entry for the queue
 * @param {(queue: Object[], event: AgGridEvent) => Promise<any>} flushQueue
 *   Does something with the queue
 * @returns {(event: any) => boolean} Queued update handler
 */
export default function useUpdateQueue(addToQueue, flushQueue) {
  /** @type {React.MutableRefObject<Object[]>} */
  const updateQueue = useRef([]);
  /** @type {React.MutableRefObject<number>} */
  const updateTimer = useRef();

  return useCallback(
    (event) => {
      const queue = addToQueue(updateQueue.current, event);
      if (queue) {
        updateQueue.current = queue;
        clearTimeout(updateTimer.current);
        updateTimer.current = window.setTimeout(async () => {
          if (!queue.length) return;
          await flushQueue(queue, event);
          queue.splice(0);
        }, QUEUE_DEBOUNCE_MS);
      }

      return !!queue;
    },
    [addToQueue, flushQueue],
  );
}
