import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateSalaryIncreaseAction } from '@/actions/companies';
import Footer from '@/components/Settings/Footer';
import DateField from '@/components/common/DateField';
import ErrorMessages from '@/components/common/ErrorMessages';
import FormRadio from '@/components/common/FormRadio';
import NumberField from '@/components/common/NumberField';
import Select from '@/components/common/Select';
import { MODES } from '@/constants/settings';
import {
  getCurrentDayTimestamp,
  getISODate,
  getNextDay,
} from '@/helpers/dateFormatter';
import { isPercentageValid } from '@/helpers/validators';
import './SalaryIncrease.scss';

const FREQUENCY = {
  ANNUALLY: 0,
  SEMI_ANNUALLY: 1,
};

const INCREMENT_BASIS_TYPE = {
  START_DATE: 0,
  CUSTOM_DATE: 1,
};

const initialState = {
  salaryIncreasePercentage: 0,
  frequency: FREQUENCY.ANNUALLY,
  salaryIncrementBasisType: INCREMENT_BASIS_TYPE.START_DATE,
  customDate: null,
};

const SalaryIncrease = ({
  mode,
  setMode,
  salaryIncrease,
  companyId,
  errorMsg,
  updateSalaryIncrease,
}) => {
  const [formValues, setFormValues] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(errorMsg);

  useEffect(() => {
    if (salaryIncrease) {
      setFormValues(salaryIncrease);
    }
  }, [salaryIncrease]);

  const handleSave = async () => {
    setLoading(true);
    try {
      await updateSalaryIncrease(formValues, companyId);
      setMode(MODES.READ);
    } catch (e) {
      setError(e.response?.data?.error?.errorMessage || e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setMode(MODES.READ);
    setFormValues(salaryIncrease || initialState);
  };

  const handleCustomDateChange = useCallback(
    (date) =>
      setFormValues((current) => ({
        ...current,
        customDate: getISODate(date),
      })),
    [],
  );

  return (
    <>
      {error && <ErrorMessages success={null} error={error} />}
      <div className="Form">
        <div className="Form_Group SalaryIncrease_Row">
          <NumberField
            prefix="%"
            id="salaryIncrease"
            name="salaryIncrease"
            precision={2}
            className="SalaryIncrease_Percentage"
            value={formValues.salaryIncreasePercentage}
            onChange={(_, value) => {
              setFormValues({
                ...formValues,
                salaryIncreasePercentage: value,
              });
            }}
            validate={() =>
              !isPercentageValid(formValues.salaryIncreasePercentage) &&
              'Please enter a valid percentage'
            }
            disabled={mode !== MODES.EDIT}
            allowNegativeValues={false}
          />

          <Select
            id="SalaryIncrease_Frequency"
            name="SalaryIncrease_Frequency"
            value={formValues.frequency}
            className="SalaryIncrease_Frequency"
            onChange={({ target }) =>
              setFormValues({ ...formValues, frequency: Number(target.value) })
            }
            disabled={mode !== MODES.EDIT}
          >
            <option value={FREQUENCY.ANNUALLY}>Annually</option>
            <option value={FREQUENCY.SEMI_ANNUALLY}>Semi-Annually</option>
          </Select>
        </div>

        <div className="Form_Group SalaryIncrease_Row">
          <FormRadio
            label="From employee start date"
            id="employee-start-date"
            name="incrementBasisType"
            disabled={mode !== MODES.EDIT}
            value={String(INCREMENT_BASIS_TYPE.START_DATE)}
            checked={
              formValues.salaryIncrementBasisType ===
              INCREMENT_BASIS_TYPE.START_DATE
            }
            onChange={() => {
              setFormValues({
                ...formValues,
                salaryIncrementBasisType: INCREMENT_BASIS_TYPE.START_DATE,
                customDate: null,
              });
            }}
          />
          <FormRadio
            label="On custom date"
            id="employee-custom-date"
            name="incrementBasisType"
            disabled={mode !== MODES.EDIT}
            value={String(INCREMENT_BASIS_TYPE.CUSTOM_DATE)}
            checked={
              formValues.salaryIncrementBasisType ===
              INCREMENT_BASIS_TYPE.CUSTOM_DATE
            }
            onChange={() => {
              setFormValues({
                ...formValues,
                salaryIncrementBasisType: INCREMENT_BASIS_TYPE.CUSTOM_DATE,
                customDate: getISODate(getNextDay(new Date())),
              });
            }}
            labelClass="SalaryIncrease_RadioBtnLabel"
          />
          <DateField
            disabled={
              mode === MODES.READ || !formValues.salaryIncrementBasisType
            }
            value={new Date(formValues.customDate).getTime()}
            min={getCurrentDayTimestamp()}
            id="SalaryIncrease_CustomDate"
            className="SalaryIncrease_CustomDate"
            onChange={handleCustomDateChange}
          />
        </div>
      </div>
      {mode === MODES.EDIT && (
        <Footer
          loading={loading}
          disabledSaveBtn={Boolean(
            !isPercentageValid(formValues.salaryIncreasePercentage) ||
              (formValues.salaryIncrementBasisType &&
                getISODate(formValues.customDate) < getISODate(new Date())),
          )}
          onSaveClick={handleSave}
          onCancelClick={handleCancel}
        />
      )}
    </>
  );
};
const mapStateToProps = ({
  companies: { salaryIncrease, selectedCompanyId },
}) => ({
  salaryIncrease: salaryIncrease?.salaryIncrease,
  companyId: selectedCompanyId,
  errorMsg: salaryIncrease?.error,
});

export default connect(mapStateToProps, {
  updateSalaryIncrease: updateSalaryIncreaseAction,
})(SalaryIncrease);
