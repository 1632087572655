import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import useEffectOnUpdate from './useEffectOnUpdate';
import useScenarioQueryReducer from './useScenarioQueryReducer';
import useSelectedScenarios from './useSelectedScenarios';

const DEPS_DEFAULT = [];
const QUERY_PARAMS_DEFAULT = {};

/**
 * A hook for populating charts. Calls the given API service for each selected
 * scenario, caches the response, and passes it to the given reducer to be
 * transformed for consumption.
 *
 * @param {string} cacheKey A unique key for caching the API response
 * @param {(
 *   context: import('@tanstack/react-query').QueryFunctionContext,
 * ) => Promise<any>} service
 *   Makes an API request for the chart data
 * @param {Function} reducer Transforms the API response to a chart-ready format
 * @param {Object} [options] Additional configuration
 * @param {any[]} [options.dependencies] Will trigger invalidation of the cache,
 *   e.g. employees for payroll chart data
 * @param {Object} [options.queryParams] Additional query params for the API
 *   request
 * @returns {import('@/hooks/useScenarioQueryReducer').ScenarioQueryResult[]}
 * @see https://react-query.tanstack.com/guides/queries#query-basics
 */
export default function useChartQuery(
  cacheKey,
  service,
  reducer,
  options = {},
) {
  const { dependencies = DEPS_DEFAULT, queryParams = QUERY_PARAMS_DEFAULT } =
    options;
  const queryClient = useQueryClient();

  const scenarios = useSelectedScenarios();
  const [base, compare] = scenarios;
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );

  /**
   * Call the API service for each selected scenario
   *
   * @see https://react-query.tanstack.com/guides/parallel-queries#dynamic-parallel-queries-with-usequeries
   */
  const [baseQuery, compareQuery] = useQueries({
    queries: scenarios.map(({ scenarioId }) => ({
      queryKey: [
        cacheKey,
        scenarioId,
        startDate,
        endDate,
        timePeriod,
        queryParams,
      ],
      queryFn: ({ signal }) =>
        service({
          startDate,
          endDate,
          scenarioId,
          timePeriod,
          signal,
          ...queryParams,
        }),
      staleTime: 30000,
    })),
  });

  // After the initial fetch of any dependencies, subsequent updates should
  // invalidate the cache.
  useEffectOnUpdate(
    () => base && queryClient.invalidateQueries([cacheKey, base.scenarioId]),
    dependencies,
  );

  // We don't reduce the data inside the queryFn so that we're caching the raw
  // API response. Multiple charts may rely on different reductions of the same
  // response, e.g. /totalMrr.
  const queryParamValues = Object.values(queryParams).join(',');
  const baseReduced = useScenarioQueryReducer(baseQuery, base, reducer, [
    queryParamValues,
    ...dependencies,
  ]);
  const compareReduced = useScenarioQueryReducer(
    compareQuery,
    compare,
    reducer,
    [queryParamValues, ...dependencies],
  );

  return useMemo(
    () => [baseReduced, compareReduced].filter((query) => query),
    [baseReduced, compareReduced],
  );
}
