import FormulaMonthRenderer from '@/components/common/MonthlySpreadsheet/FormulaMonthRenderer';
import { EMPTY_CELL_VALUE } from '@/components/common/Spreadsheet/constants';
import { getUnitFormatter } from '@/components/common/Spreadsheet/helpers';
import CancelBtnRenderer from '@/components/common/Spreadsheet/renderers/CancelBtnRenderer';
import {
  FORECAST,
  ACTUAL,
  iconTypes,
  FORMATTED_NUM_REGEX,
  PENDING,
} from '@/constants/actuals';
import COLORS from '@/constants/colorPalette';
import { ANNUALLY, QUARTERLY } from '@/constants/dateTime';
import { units } from '@/constants/variables';
import { isActualMonth } from '@/helpers';
import {
  getTimePeriodsInRange,
  getDatesInRange,
  getFormattedDateFromTimeStamp,
  formatRangeWithShortYear,
  formatDateWithShortYear,
} from '@/helpers/dateFormatter';
import { isEmptyOrNull } from '@/helpers/validators';

const isForecastMonth = (dateValue) => !isActualMonth(dateValue);

/**
 * Determines if the headerName represents the current month and year, and if
 * the current date is before the syncDate, indicating a pending status.
 *
 * @type {({dateValue: Date, headerName: string, companySettings: import('@/services/settingsService').CompanySettings}) => string}
 */
export const getMonthlyHeaderIndicator = ({
  dateValue,
  headerName,
  companySettings,
}) => {
  const currentDate = new Date();
  const lastMonth = new Date().setUTCDate(0);
  const previousMonthYear = formatDateWithShortYear(lastMonth);
  const syncDate = companySettings.accountingPlatformSyncDay;
  switch (true) {
    case headerName === previousMonthYear &&
      currentDate.getUTCDate() < syncDate:
      return PENDING;
    case isForecastMonth(dateValue):
      return FORECAST;
    default:
      return ACTUAL;
  }
};

/**
 * Get an array of cell headers, which corresponds to the time period intervals
 * within the provided start and end date
 *
 * @example
 *   const result = getMonthlyHeaderNameByTimePeriod({startDate:2022-01, endDate:2023-01, timePeriod:QUARTERLY});
 *   console.log(result)
 *   [
 *     { "month": "2022-01", "headerName": "Jan ’22 – Mar ’22" },
 *     { "month": "2022-04", "headerName": "Apr ’22 – Jun ’22" },
 *     ...
 *   ]
 *
 * @typedef {{
 *   startDate: Date | string | number;
 *   endDate: Date | string | number;
 *   timePeriod: string;
 *   showForecastIndicator: boolean;
 *   companySettings: import('@/services/settingsService').CompanySettings;
 * }} GetMonthlyHeaderNameParams
 *
 * @type {(GetMonthlyHeaderNameParams) => Object[]}
 */
// eslint-disable-next-line import/prefer-default-export -- predates description requirement
export const getMonthlyHeaderNameByTimePeriod = ({
  startDate,
  endDate,
  timePeriod,
  showForecastIndicator,
  companySettings,
}) => {
  const isNotMonthlyInterval = [ANNUALLY, QUARTERLY].includes(timePeriod);
  const endDateMs = new Date(endDate).getTime();
  const timestamps = isNotMonthlyInterval
    ? getTimePeriodsInRange(startDate, endDate, timePeriod)
    : getDatesInRange(startDate, endDate);

  return timestamps.map((timestamp) => {
    const [startIntervalMs, endIntervalMs] = Array.isArray(timestamp)
      ? timestamp
      : [timestamp];
    const isNotSingleMonthInterval =
      getFormattedDateFromTimeStamp(startIntervalMs) !==
      getFormattedDateFromTimeStamp(endDateMs);
    const month = getFormattedDateFromTimeStamp(startIntervalMs);
    const endMs = endIntervalMs > endDateMs ? endDateMs : endIntervalMs;

    const headerName =
      isNotMonthlyInterval && isNotSingleMonthInterval
        ? formatRangeWithShortYear(
            startIntervalMs,
            Math.min(endIntervalMs, endDateMs),
          )
        : formatDateWithShortYear(startIntervalMs);

    let result = {
      month,
      headerName,
    };
    if (showForecastIndicator) {
      const indicator = getMonthlyHeaderIndicator({
        dateValue: !isEmptyOrNull(endIntervalMs) ? endMs : startIntervalMs,
        headerName,
        companySettings,
      });
      result = { ...result, indicator };
    }
    return result;
  });
};

/**
 * Formats the cell value according to the unit. Used for older APIs that return
 * percentages as whole numbers.
 *
 * @deprecated
 * @param {Object} params
 * @param {Object} params.data Object containing unit which is used to get the
 *   required formatter
 * @param {number} params.value Value which needs to be formatted
 * @returns {string} Formatted value
 */
export const cellValueFormatterLegacy = ({ data: { unit }, value }) => {
  if (isEmptyOrNull(value) || value === '') return EMPTY_CELL_VALUE;

  const formatter = getUnitFormatter(unit ?? units.CURRENCY);
  const val = unit === units.PERCENTAGE ? value / 100 : value;
  return formatter(val);
};

/**
 * Get styles for a spreadsheet cell
 *
 * @example
 *   const result = getStylesForExternalSpreadsheet({
 *     bold: true,
 *     italic: true,
 *     underlined: true,
 *     strikethrough: true,
 *     borderTop: true,
 *     borderBottom: true,
 *     borderLeft: true,
 *     borderRight: true,
 *   });
 *
 * @param {Object} style object received from BE containing a boolean value
 *   against each supported style.
 * @returns {Object}
 */
export const getStylesForExternalSpreadsheet = (style) => {
  if (isEmptyOrNull(style)) return {};

  const blackBorder = `1px solid ${COLORS.raisinBlack}`;
  const { bold, italic, underlined, strikethrough, borderBottom } = style;

  return {
    fontWeight: bold && 'bold',
    fontStyle: italic && 'italic',
    textDecoration: `${underlined ? `underline` : ''} ${
      strikethrough ? `line-through` : ''
    }`,
    borderBottom: borderBottom && blackBorder,
  };
};

/**
 * Get style for a cell from an external spreadsheet
 *
 * @type {import('ag-grid-community').CellStyleFunc<any>}
 */
export const getExternalCellStyle = ({ column, data }) => {
  const month = column.getParent().getGroupId();
  const style = data.months.find(({ date }) => date === month)?.style;
  return getStylesForExternalSpreadsheet(style);
};

/**
 * A cellRendererSelector for monthly values which accept formulas
 *
 * @param {ICellRendererParams} params
 * @returns {Object} renderer definition
 */
export const formulaMonthRendererSelector = ({ eGridCell, valueFormatted }) => {
  // Only use the custom renderer when the cell is focused,
  // to limit the performance impact of too many renderers
  if (valueFormatted === EMPTY_CELL_VALUE) return valueFormatted;
  return document.activeElement === eGridCell ||
    eGridCell.contains(document.activeElement)
    ? { component: FormulaMonthRenderer }
    : { component: undefined };
};

/**
 * Clean the pasted value in Ag-Grid Cells
 *
 * @example
 *   <AgGridReact
 *   ...
 *   processCellFromClipboard={handleAgGridPaste}
 *   />
 *
 * @param {string} value
 * @returns {Object}
 */
export function handleAgGridPaste({ value }) {
  if (FORMATTED_NUM_REGEX.test(value.trim())) {
    const sanitizedValue = value.replace(/^\(/, '-').replace(/[^\d-.]+/g, '');
    return {
      amount: Number(sanitizedValue),
      value: Number(sanitizedValue),
      displayFormula: sanitizedValue,
      type: iconTypes.USER_ENTERED,
    };
  }

  return {
    amount: null,
    value: null,
    displayFormula: value,
    type: iconTypes.USER_ENTERED,
  };
}

/**
 * Get errors for the fields that are empty
 *
 * @param {Array} missingFields array containing fields that are empty
 * @returns {Object}
 */
export const getMissingFieldErrors = (missingFields) => {
  return missingFields.reduce(
    (accum, field) => ({
      ...accum,
      [field]: 'This is a required field',
    }),
    {},
  );
};

/**
 * A cellRendererSelector that includes a cancel button if the column is the
 * left-most in the spreadsheet
 *
 * @param {ICellRendererParams} params
 * @returns {Object} renderer definition
 */
export const cancelBtnSelector = ({ column, columnApi, data }) => {
  const colIdx = columnApi
    .getAllGridColumns()
    .filter(({ visible }) => visible)
    .indexOf(column);
  if (data.isUnsaved && colIdx === 0) {
    return { component: CancelBtnRenderer };
  }

  return undefined;
};

/**
 * Include the Actuals / Forecast label in MonthlySpreadsheet headers when
 * exporting to Excel
 *
 * @param {import('ag-grid-community').ProcessGroupHeaderForExportParams} params
 * @returns {string | undefined} formatted header name
 */
export const exportWithForecastIndicators = ({ columnGroup }) => {
  const colGroupDef = columnGroup.getColGroupDef();
  if (isEmptyOrNull(colGroupDef)) {
    return undefined;
  }
  const { headerGroupComponentParams, headerName } = colGroupDef;
  return headerGroupComponentParams?.indicator
    ? `${headerName}\n${headerGroupComponentParams.indicator}`
    : headerName;
};
