import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  errorCodes,
  statuses,
} from '@/components/common/DocumentUploader/constants';
import Dropzone from '@/components/common/Dropzone';
import {
  UploadProgress,
  UploadProgressText,
} from '@/components/common/UploadProgress';
import { classNames } from '@/helpers';
import './DocumentUploader.scss';

const ONE_MB = 1048576;

const getUploadRemainingTime = (
  uploadedBytes,
  totalBytes,
  uploadingStartDate,
) => {
  if (!uploadedBytes || !totalBytes || !uploadedBytes) {
    return 0;
  }
  const timeElapsed = new Date() - uploadingStartDate;
  const uploadSpeed = uploadedBytes / (timeElapsed / 1000);
  return (totalBytes - uploadedBytes) / uploadSpeed;
};

const getProgressPercentage = (loaded, total) => {
  if (!loaded || !total) {
    return 0;
  }
  return Math.round((100 * loaded) / total);
};

const initialState = {
  progress: 0,
  remainingTime: 0,
  selectedFiles: null,
  selectedFilesErrors: null,
};

const customizeErrorMessages = ({ code, message }) => {
  switch (code) {
    case errorCodes.SIZE_LIMIT:
      return 'File size is too large to upload. Please upload a file less than 1MB.';
    case errorCodes.INVALID_EXTENSION:
      return 'File type uploaded is not compatible. Please upload again with the correct file type.';
    default:
      return message;
  }
};

/**
 * A component for selecting and uploading files
 *
 * @example
 *   <DocumentUploader
 *     id={id}
 *     acceptedFileTypes={acceptedFileTypes}
 *     maxFileSize={maxFileSize}
 *     data-testid={dataTestId}
 *     onFilesSelected={onFilesSelected}
 *     onCancelUpload={onCancelUpload}
 *     uploadState={state}
 *   />;
 */
const DocumentUploader = ({
  id,
  className,
  acceptedFileTypes,
  maxFileSize = ONE_MB,
  error,
  onFilesSelected,
  onSelectedFilesRejected,
  onCancelUpload,
  uploadState,
  'data-testid': dataTestId,
  children,
  DropzoneContent,
}) => {
  const [state, setState] = useState(initialState);
  const [dropzoneContent, setDropzoneContent] = useState(null);

  useEffect(() => {
    if (state.selectedFilesErrors?.length) {
      setDropzoneContent(
        <div>
          {state.selectedFilesErrors.map(({ file, errors }) =>
            errors.map((fileError) => (
              <p className="Dropzone_Error" key={file.name}>
                {customizeErrorMessages(fileError)}
              </p>
            )),
          )}
        </div>,
      );
    } else if (state?.selectedFiles?.length) {
      setDropzoneContent(state.selectedFiles.map((file) => file.name));
    } else {
      setDropzoneContent(DropzoneContent);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [state.selectedFiles, state.selectedFilesErrors]);

  useEffect(() => {
    setState((previousState) => {
      return {
        ...previousState,
        remainingTime: getUploadRemainingTime(
          uploadState.progressLoaded,
          uploadState.progressTotal,
          uploadState.initiatedAt,
        ),
        progress: getProgressPercentage(
          uploadState.progressLoaded,
          uploadState.progressTotal,
        ),
      };
    });
  }, [setState, uploadState]);

  useEffect(() => {
    if (uploadState.status === statuses.INIT) {
      setState(initialState);
    }
  }, [uploadState.status]);

  return (
    <div
      id={id}
      className={classNames('Uploader_Container', className)}
      data-testid={dataTestId}
    >
      {[
        statuses.UPLOADING,
        statuses.UPLOADING_COMPLETE,
        statuses.ERROR,
      ].includes(uploadState.status) ? (
        <UploadProgress
          id="upload-progress"
          error={error}
          remainingTime={state.remainingTime}
          progress={state.progress}
        >
          <UploadProgressText
            id="uploader-progress-text"
            onCancel={() => {
              onCancelUpload();
              setState(initialState);
            }}
            error={error}
            progress={state.progress}
          >
            {children}
          </UploadProgressText>
        </UploadProgress>
      ) : (
        <Dropzone
          id="uploader-dropzone"
          config={{
            accept: acceptedFileTypes,
            maxSize: maxFileSize,
            onDrop: (droppedFiles) => {
              setState((previousState) => ({
                ...previousState,
                selectedFiles: droppedFiles,
                selectedFilesErrors: null,
              }));
              if (onFilesSelected) onFilesSelected(droppedFiles);
            },
            onDropRejected: (rejectedFiles) => {
              setState((previousState) => ({
                ...previousState,
                selectedFiles: null,
                selectedFilesErrors: rejectedFiles,
              }));
              if (onSelectedFilesRejected)
                onSelectedFilesRejected(rejectedFiles);
            },
          }}
        >
          {dropzoneContent}
        </Dropzone>
      )}
    </div>
  );
};

DocumentUploader.propTypes = {
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string,
  /** Unique ID for the element */
  'id': PropTypes.string.isRequired,
  /** Class(es) to apply to the element */
  'className': PropTypes.string,
  /**
   * Function called when files are selected either via drag-and-drop or via
   * file browser modal
   */
  'onFilesSelected': PropTypes.func.isRequired,
  /**
   * Function called when the selected files are rejected, i.e. when too large
   * or incorrect file type
   */
  'onSelectedFilesRejected': PropTypes.func,
  /**
   * File types accepted.
   *
   * @see https://react-dropzone.js.org/
   */
  'acceptedFileTypes': PropTypes.object,
  /** The maximum file size in bytes */
  'maxFileSize': PropTypes.number,
  /** State passed from parent component that initiates an upload */
  'uploadState': PropTypes.shape({
    /** The various statuses this component needs to share with its parent */
    status: PropTypes.oneOf(Object.values(statuses)).isRequired,
    /**
     * The date and time the upload was initiated. Necessary for calculating
     * `remainingTime`
     */
    initiatedAt: PropTypes.instanceOf(Date),
    /**
     * The amount of bytes already sent to the API
     *
     * @see https://github.com/axios/axios#request-config
     */
    progressLoaded: PropTypes.number,
    /**
     * The total amount of bytes to send to the API
     *
     * @see https://github.com/axios/axios#request-config
     */
    progressTotal: PropTypes.number,
  }).isRequired,
  /** Function called when the upload is cancelled */
  'onCancelUpload': PropTypes.func.isRequired,
  /** An error object from the API */
  'error': PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  /** Content to display within the component */
  'children': PropTypes.node,
  /** Content that need to be displayed on dropzone */
  'DropzoneContent': PropTypes.elementType,
};

export default DocumentUploader;
