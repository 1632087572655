import { useEffect, useRef } from 'react';
import MenuMiniIcon from '@bill/cashflow.assets/menu-mini';
import PlusIcon from '@bill/cashflow.assets/plus';
import ContextMenu from '@/components/common/ContextMenu';
import useCellOverflow from '@/components/common/Spreadsheet/useCellOverflow';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import WithTooltip from '@/components/common/WithTooltip';
import { ITEM, SUBSECTION } from '@/constants/reports';
import { classNames } from '@/helpers';
import { ADJUSTMENTS_TO_ASSETS } from '@/pages/Reports/constants';
import './RowNameRenderer.scss';

const INVALID_CLASS = 'Spreadsheet_Cell-invalid';

const RowNameRenderer = ({
  data,
  eGridCell,
  value,
  onAddClick,
  registerRowDragger,
  hasComparison,
}) => {
  const dragBtn = useRef(null);
  // Top-level sections cannot be reordered
  const isDraggable =
    !hasComparison &&
    data.hierarchy.length > 1 &&
    data.name !== ADJUSTMENTS_TO_ASSETS;

  useCellOverflow(eGridCell);

  useEffect(() => {
    if (isDraggable) registerRowDragger(dragBtn.current, 0);
  }, [isDraggable, registerRowDragger]);

  useEffect(() => {
    const { error } = data;
    if (error) {
      eGridCell.classList.add(INVALID_CLASS);
    } else {
      eGridCell.classList.remove(INVALID_CLASS);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [data]);

  return (
    <span
      className={classNames(
        'RowNameRenderer',
        data.hasErrorMsg && 'Spreadsheet_Cell-invalid',
      )}
    >
      {isDraggable && (
        <button
          ref={dragBtn}
          type="button"
          className="Spreadsheet_DragBtn"
          aria-hidden="true"
        >
          <MenuMiniIcon className="Spreadsheet_DragIcon" />
        </button>
      )}

      {data.error ? (
        <WithTooltip content={data.error} placement="bottom">
          <span className="RowNameRenderer">
            <TooltipTextOverflow
              label={value}
              data-testid={`${data.name}-error-tooltip`}
            />
          </span>
        </WithTooltip>
      ) : (
        <TooltipTextOverflow data-testid="RowNameRenderer" label={value} />
      )}

      {data.type !== ITEM && !data.error && (
        <WithTooltip content="Add Subsection/Item" placement="right">
          <span>
            <ContextMenu
              Icon={PlusIcon}
              data-testid={`${data.name}-report-row-actions`}
              placement="bottom-start"
            >
              <ContextMenu.Option
                id="add-subsection"
                data-testid="add-subsection"
                onClick={() => onAddClick(data, SUBSECTION)}
                disabled={data.hierarchy.length >= 10}
              >
                + Subsection
              </ContextMenu.Option>
              <ContextMenu.Option
                id="add-item"
                data-testid="add-item"
                onClick={() => onAddClick(data, ITEM)}
              >
                + Item
              </ContextMenu.Option>
            </ContextMenu>
          </span>
        </WithTooltip>
      )}
    </span>
  );
};

export default RowNameRenderer;
