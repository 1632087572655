// @ts-check
import { useEffect, useState, forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import Page from '@/components/Reports/ExportableReport/Page';
import CoverPage from '@/components/Reports/ExportableReport/Sections/CoverPage';
import Report from '@/components/Reports/ExportableReport/Sections/Report';
import Summary from '@/components/Reports/ExportableReport/Sections/Summary';
import { getColumnLimit } from '@/components/Reports/helpers';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import useExportableReportContext from '@/contexts/useExportableReportContext';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import './ReportContentContainer.scss';

/** @typedef {import('@/types/exportableReport').Section} Section */

const sectionTypes = {
  COVER_PAGE: CoverPage,
  DISCLAIMER: Summary,
  EXECUTIVE_SUMMARY: Summary,
  REPORT: Report,
};

/**
 * @type {(sections: Section[], scenario: Object) => Object} Returns a section
 *   map with Components and their parameters
 */
const getSectionsList = (sections, scenario) => {
  if (!sections) return {};
  const sectionMap = {};

  sections.forEach((section) => {
    sectionMap[section.id] = {
      Component: sectionTypes[section.type],
      componentParams: {
        key: section.id,
        id: section.id,
        section,
        scenario,
      },
    };
  });

  return sectionMap;
};

/**
 * @typedef {{
 *   sections: Section[];
 *   isLoading: boolean;
 *   scenario: Object;
 *   documentRef: HTMLDivElement;
 * }} ReportContentContainerProps
 */

/**
 * Renders Exportable Report Content Container
 *
 * @example
 *   <ReportContentContainer
 *     sections={sections}
 *     scenario={scenario}
 *     isLoading={isLoading}
 *     documentRef={documentRef}
 *     ref={documentRef}
 *   />;
 *
 * @type {React.ForwardRefRenderFunction<
 *   HTMLDivElement,
 *   ReportContentContainerProps
 * >}
 */
const ReportContentContainerFn = (
  { sections, scenario, isLoading, documentRef },
  ref,
) => {
  const sectionMap = getSectionsList(sections, scenario);
  const [showTruncationModal, setShowTruncationModal] = useState(false);
  const [isFooterDisclaimerInEditMode, setIsFooterDisclaimerInEditMode] =
    useState(false);
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );
  const scenarioIds = useSelectedScenarioIds();
  const hasComparison = scenarioIds.length > 1;
  const { exportableReportPreferences } = useExportableReportContext();

  useEffect(() => {
    const { truncated } = getColumnLimit({
      startDate,
      endDate,
      timePeriod,
      hasComparison,
      showVariance: exportableReportPreferences?.showVariance,
    });

    if (truncated) {
      setShowTruncationModal(truncated);
    }
  }, [
    startDate,
    endDate,
    timePeriod,
    hasComparison,
    exportableReportPreferences?.showVariance,
  ]);

  /** @type {() => void} */
  const onTruncationModalClose = () => {
    setShowTruncationModal(false);
  };

  return (
    <div ref={ref} className="ReportContentContainer">
      <div
        id="export-report-sections-container"
        className="ExportableReport_PageContainer"
      >
        {isLoading ? (
          <Page>
            <LoadingSpinner />
          </Page>
        ) : (
          Object.values(sectionMap).map(({ Component, componentParams }) => {
            if (!componentParams.section.active) return null;
            return (
              <Component
                {...componentParams}
                documentRef={documentRef}
                isFooterDisclaimerInEditMode={isFooterDisclaimerInEditMode}
                setIsFooterDisclaimerInEditMode={
                  setIsFooterDisclaimerInEditMode
                }
              />
            );
          })
        )}
      </div>
      {showTruncationModal && (
        <ModalConfirmation
          id="col-truncation-modal"
          title="Date Adjustment"
          onAction={onTruncationModalClose}
          actionBtnTxt="Continue"
        >
          The report can only show a maximum of 12 data columns in the
          statements at the moment
        </ModalConfirmation>
      )}
    </div>
  );
};

const ReportContentContainer = forwardRef(ReportContentContainerFn);

export default ReportContentContainer;
