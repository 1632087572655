import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { authorizeIntegrationAction } from '@/actions/settings';
import useQueryParams from '@/hooks/useQueryParams';
import getSelectedCompany from '@/selectors/getSelectedCompany';

/**
 * A component to handle Codat's callback URL after signing up for a new
 * integration
 *
 * Codat URL example:
 * http://[env].finmark.com/integrationRedirect?integrationId={connectionId}&statusCode={statusCode}&redirectTo={redirectTo}
 *
 * @see https://docs.codat.io/docs/authentication-redirect
 */
const IntegrationRedirect = ({ selectedCompany, authorizeIntegration }) => {
  const query = useQueryParams();
  const statusCode = query.get('statusCode');
  const integrationId = query.get('integrationId');
  const redirectTo = query.get('redirectTo');
  const state = query.get('state');

  useEffect(() => {
    if (statusCode === '200' && integrationId) {
      authorizeIntegration({
        platformCompanyId: selectedCompany.id,
        statusCode: Number(statusCode),
        integrationId,
        syncStartDate: null,
      });
    }
  }, [statusCode, integrationId, authorizeIntegration, selectedCompany]);

  let redirectPath = redirectTo || state || 'settings';
  redirectPath = redirectPath.startsWith('/')
    ? redirectPath
    : `/${redirectPath}`;
  return <Redirect to={`${redirectPath}`} />;
};

const mapStateToProps = (state) => ({
  selectedCompany: getSelectedCompany(state),
});

export default connect(mapStateToProps, {
  authorizeIntegration: authorizeIntegrationAction,
})(IntegrationRedirect);
