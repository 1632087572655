import { useEffect, useMemo, useRef, useState } from 'react';
import MinusFlatIcon from '@bill/cashflow.assets/minus-flat';
import PlusFlatIcon from '@bill/cashflow.assets/plus-flat';
import IconButton from '@/components/common/IconButton';
import './ExpandAllToggle.scss';

const EXPANDED_ALL = 'EXPANDED_ALL';
const COLLAPSED_ALL = 'COLLAPSED_ALL';
const MIXED = 'MIXED';

const getSectionsStates = (sections) => {
  const sectionsValues = Object.values(sections);
  if (sectionsValues.every((expanded) => expanded)) {
    return EXPANDED_ALL;
  }
  if (sectionsValues.every((expanded) => !expanded)) {
    return COLLAPSED_ALL;
  }
  return MIXED;
};

/**
 * Expand/Collapse All toggle for inclusion in ag-Grid header renderers
 *
 * @example
 *   <ExpandAllToggle api={api} />;
 */
function ExpandAllToggle({ api }) {
  const isUnmounted = useRef(false);
  useEffect(() => () => (isUnmounted.current = true), []);

  const [sections, setSections] = useState({});
  const expandedState = useMemo(() => getSectionsStates(sections), [sections]);

  const setAllSections = (payload) => {
    if ([EXPANDED_ALL, COLLAPSED_ALL].includes(payload)) {
      api.forEachNode((node) => {
        if (node.allChildrenCount) {
          node.setExpanded(payload === EXPANDED_ALL);
        }
      });
    }
  };

  useEffect(() => {
    const populateState = () => {
      if (isUnmounted.current) return;

      const sectionStates = {};
      api.forEachNode((node) => {
        if (node.allChildrenCount) sectionStates[node.id] = node.expanded;
      });
      setSections(sectionStates);
    };
    populateState();
    api.addEventListener('modelUpdated', populateState);

    const handleSectionOpened = ({ node }) => {
      if (!node.data?.isGroupPlaceholder) {
        setSections((current) => ({
          ...current,
          [node.id]: node.expanded,
        }));
      }
    };
    api.addEventListener('rowGroupOpened', handleSectionOpened);

    return () => {
      api.removeEventListener('rowGroupOpened', handleSectionOpened);
      api.removeEventListener('modelUpdated', populateState);
    };
  }, [api]);

  return (
    <div className="ExpandCollapse_IconContainer">
      <IconButton
        label="Collapse All"
        data-testid="collapse-all-selection"
        onClick={() => setAllSections(COLLAPSED_ALL)}
        Icon={MinusFlatIcon}
        disabled={expandedState === COLLAPSED_ALL}
      />
      <IconButton
        label="Expand All"
        data-testid="expand-all-selection"
        onClick={() => setAllSections(EXPANDED_ALL)}
        Icon={PlusFlatIcon}
        disabled={expandedState === EXPANDED_ALL}
      />
    </div>
  );
}

export default ExpandAllToggle;
