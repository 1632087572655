import PropTypes from 'prop-types';
import NumberField from '@/components/common/NumberField';
import './MonthSpreadFields.scss';

const UniqueLabelValue = ({ index, firstBoxLabel = '' }) => {
  return index === 0
    ? firstBoxLabel
    : `+${Number(index)} Month${index > 1 ? 's' : ''}`;
};

/**
 * Creates a list of input Boxes
 *
 * @example
 *   <MonthSpreadFields
 *     id="conversionOverTime"
 *     className="AddRevenueStream_PctOverTime"
 *     values={conversionOverTime}
 *     firstBoxLabel="Month of"
 *     onChange={(conversionOverTimeNew) => {
 *       handleStreamDataChange('conversionOverTime', conversionOverTimeNew);
 *     }}
 *     isValid={isConversionOverTimeValid}
 *     errorMessage={
 *       'Values must sum to 100% across your selected conversion time'
 *     }
 *     children={<AddBoxIcon />}
 *   />;
 */
const MonthSpreadFields = ({
  id,
  className,
  values,
  firstBoxLabel = 'Month of',
  onChange,
  isValid = true,
  errorMessage = '',
  children,
}) => {
  return (
    <>
      <div className={className} data-testid={id}>
        {values.map((inputValue, index) => (
          <div key={UniqueLabelValue({ index })}>
            <NumberField
              id={`${id}_${index}`}
              key={`${id}_${index.toString()}`}
              value={inputValue}
              name={id}
              min={0}
              max={100}
              onChange={({ target: { value } }) => {
                const valuesNew = [...values];
                valuesNew[index] = value === '' ? '' : Math.trunc(value);
                onChange(valuesNew);
              }}
              onBlur={() => {
                const valuesNew = [...values];
                if (valuesNew[index] === '') {
                  valuesNew[index] = 0;
                  onChange(valuesNew);
                }
              }}
              className="MonthSpreadFields_Field"
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- Predates requirement */}
            <label
              htmlFor={`MonthSpreadFields_${index}`}
              className="MonthSpreadFields_InputLabel"
              data-testid={`MonthSpreadFieldsLabel_${index}`}
            >
              <UniqueLabelValue index={index} firstBoxLabel={firstBoxLabel} />
            </label>
          </div>
        ))}
        {children}
      </div>
      {!isValid && (
        <p
          id={`${id}Error`}
          className="MonthSpreadFields_ValidationError"
          data-testid={`${id}Error`}
        >
          {errorMessage}
        </p>
      )}
    </>
  );
};

MonthSpreadFields.propTypes = {
  /**
   * A unique ID to associate the field with its label, and to select it in
   * unit/integration tests
   */
  id: PropTypes.string.isRequired,
  /** Class to apply to the main wrapper element */
  className: PropTypes.string,
  /** A list of values to be shown in each box */
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ),
  /** A label for the very first box in the list of MonthSpreadFields */
  firstBoxLabel: PropTypes.oneOfType([PropTypes.string]),
  /**
   * Event handler for when the user changes the value of the field
   *
   * @param {Array} valuesNew - the Updated values
   */
  onChange: PropTypes.func.isRequired,
  /** A flag for showing/hiding the error message to the user */
  isValid: PropTypes.bool,
  /**
   * The error message text to show to the user if the values entered are not
   * valid
   */
  errorMessage: PropTypes.string,
  /** Any icon or button to attach with the list of monthFields */
  children: PropTypes.node,
};

export default MonthSpreadFields;
