import PropTypes from 'prop-types';
import Button from '@/components/common/Button';

const ReferralsPreview = ({ firstName, lastName, referredBy }) => {
  return (
    <>
      Before we refer your friend to Finmark, please review the email below. If
      you are satisfied with the message, please select the ‘OK, Send’ button,
      and we will send it. If not, please select ‘Cancel’.
      <div className="Preview_Container">
        To: {`${firstName} ${lastName}`}
        <br />
        From: Team Finmark
        <br />
        <hr />
        <h2 className="Preview_HeaderText">
          You’ve been invited to use Finmark!
        </h2>
        Hi {firstName},
        <br />
        <br />
        {referredBy} has invited you to try Finmark! Finmark is financial
        modeling made easy, eliminating the need for complex spreadsheets so
        founders can easily create, update, and share their financial plans.
        <br />
        <br />
        Say goodbye to confusing template models and hello to accurate,
        customized financial models that truly reflect your business.
        <br />
        <br />
        <Button
          data-testid="Referral_Button"
          className="Button-primaryLink Referral_Button"
        >
          Get started with your free trial.
        </Button>{' '}
        <br />
        <br />
        If you have any questions or need additional assistance just reply to
        this email or reach out to us at hello@finmark.com.
        <br />
        <br />
        -Team Finmark
      </div>
    </>
  );
};

PropTypes.prototype = {
  /** First name of the person being referred */
  firstName: PropTypes.string,
  /** Last name of the person being referred */
  lastName: PropTypes.string,
  /** Full name of the person being that is referring */
  referredBy: PropTypes.string,
};

export default ReferralsPreview;
