/**
 * Displays a styled variable text which we use in formulaBreakdown
 *
 * @example
 *   <Variable value="Customers.ProductName.New" />;
 */
const Variable = ({ value }) => (
  <var className="FormulaBreakdown_Item FormulaBreakdown_Item-var">{value}</var>
);

export default Variable;
