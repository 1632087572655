import { createRef, forwardRef, useCallback, useEffect } from 'react';
import { classNames } from '@/helpers';

/**
 * It is used to parse the units from a string format to pixels in numbers
 *
 * @example
 *   parseUnitToPixels('16px');
 *
 * @type {(unit: string) => number}
 */
const parseUnitToPixels = (unit) => {
  const unitInFloat = parseFloat(unit);
  return unit.endsWith('rem') ? unitInFloat * 16 : unitInFloat;
};

/**
 * @typedef {{
 *   id: string;
 *   name: string;
 *   onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
 *   onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
 *   onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
 *   onKeyUp?: React.KeyboardEventHandler<HTMLTextAreaElement>;
 *   onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
 *   onBeforeInput?: React.FormEventHandler<HTMLTextAreaElement>;
 *   value?: string;
 *   className?: string;
 *   disabled?: boolean;
 *   placeholder?: string;
 *   maxLines?: number;
 *   ['data-testid']?: string;
 * }} ResizeableTextareaProps
 */

/**
 * @type {import('react').ForwardRefExoticComponent<
 *   ResizeableTextareaProps &
 *     React.RefAttributes<React.LegacyRef<HTMLTextAreaElement>>
 * >}
 */
const ResizeableTextarea = forwardRef(
  (
    {
      id,
      name,
      className,
      value,
      onChange,
      onBlur,
      onFocus,
      onKeyUp,
      onKeyDown,
      onBeforeInput,
      disabled,
      placeholder,
      maxLines,
      'data-testid': dataTestId,
    },
    ref,
  ) => {
    const internalRef = ref ?? createRef();

    const getComputedStyles = useCallback(() => {
      /** @type {{ lineHeight?: number; padding?: number }} */
      const computedStyle = {};
      if (internalRef.current && maxLines) {
        // Getting the inherited or applied styles for the textarea
        const { lineHeight, paddingTop, paddingBottom } =
          window.getComputedStyle(internalRef.current);

        // Convert line height and padding units to pixels (float) using parseUnitToPixels helper function
        computedStyle.lineHeight = parseUnitToPixels(lineHeight);
        computedStyle.padding =
          parseUnitToPixels(paddingTop) + parseUnitToPixels(paddingBottom);
      }

      return computedStyle;
    }, [internalRef, maxLines]);

    const resizeTextarea = useCallback(() => {
      const { padding, lineHeight } = getComputedStyles();
      const maxHeight = padding + lineHeight * maxLines;
      if (
        internalRef.current &&
        (!maxLines || internalRef.current.scrollHeight < maxHeight)
      ) {
        internalRef.current.style.height = 'inherit';
        internalRef.current.style.height = `${internalRef.current.scrollHeight}px`;
      }
    }, [internalRef, maxLines, getComputedStyles]);

    useEffect(() => {
      queueMicrotask(resizeTextarea);
    }, [value, resizeTextarea]);

    return (
      <textarea
        ref={internalRef}
        id={id}
        name={name}
        className={classNames(
          'FormField_Input',
          'FormField_Input-textarea',
          className,
        )}
        data-testid={dataTestId ?? id}
        spellCheck={false}
        onChange={onChange}
        onBlur={(event) => onBlur?.(event)}
        onFocus={(event) => onFocus?.(event)}
        onKeyUp={(event) => onKeyUp?.(event)}
        onKeyDown={(event) => onKeyDown?.(event)}
        onBeforeInput={(event) => onBeforeInput?.(event)}
        placeholder={placeholder}
        value={value}
        rows={1}
        disabled={disabled}
      />
    );
  },
);

export default ResizeableTextarea;
