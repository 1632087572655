import { forwardRef, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import ChartRadioGroupPersistent from '@/components/common/ChartRadioGroupPersistent';
import { formatPercent } from '@/helpers';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import useChartPreferences from '@/hooks/useChartPreferences';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import NewRevenueChart, {
  NEW_REVENUE_TOGGLE_ID,
} from '@/pages/Dashboard/charts/NewRevenueChart';

const NewRevenueGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [newRevenue, setNewRevenue] = useState();
    const { startDate, endDate } = useSelector(({ shared }) => shared);
    const showPercentage = useChartPreferences(NEW_REVENUE_TOGGLE_ID);

    const onQueryStateChange = useCallback(({ data, isLoading }) => {
      return !isLoading && setNewRevenue(data.mainMetric);
    }, []);

    return (
      <ChartWidget
        ref={ref}
        title="New Revenue"
        controls={
          <ChartRadioGroupPersistent
            id={NEW_REVENUE_TOGGLE_ID}
            options={[
              {
                label: 'Currency',
                value: false,
              },
              {
                label: 'Percent',
                value: true,
              },
            ]}
            value={false}
            className="ControlDropdown_Child"
          />
        }
        metricValue={
          showPercentage
            ? formatPercent(newRevenue)
            : formatMonetary(newRevenue)
        }
        label={formatRangeWithShortYear(startDate, endDate)}
        url="/dashboard/new-revenue"
        data-testid="newRevWidget"
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={formatRangeWithShortYear(startDate, endDate)}
              value={
                showPercentage
                  ? formatPercent(newRevenue)
                  : formatMonetary(newRevenue)
              }
            />
            <NewRevenueChart
              {...childProps}
              onQueryStateChange={onQueryStateChange}
              showPercent={showPercentage}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default NewRevenueGlance;
