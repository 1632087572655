/* eslint-disable no-restricted-properties -- predates description requirement */
import { forwardRef, useMemo, useState } from 'react';
import ReactCalendar from 'react-calendar';
import RightArrowIcon from '@bill/cashflow.assets/right-arrow';
import PropTypes from 'prop-types';
import Button from '@/components/common/Button';
import { classNames } from '@/helpers';
import {
  getCurrentDayTimestamp,
  getLocalDayFromUTC,
  getUTCDayTimestamp,
} from '@/helpers/dateFormatter';
import CalendarPager from './CalendarPager';
import './Calendar.scss';

const VIEW_MONTH = 'month';
const VIEW_YEAR = 'year';
const CALENDAR_DAY = 'Calendar_Day';

const monthFormatter = new Intl.DateTimeFormat([], { month: 'short' });
const TODAY_LABEL = new Intl.RelativeTimeFormat([], { numeric: 'auto' }).format(
  0,
  'days',
);

function formatMonth(_, date) {
  return monthFormatter.format(date);
}

/**
 * @typedef {{
 *   'data-testid': string;
 *   'max'?: number;
 *   'min'?: number;
 *   'highlightRange'?: number[];
 *   'onHighlight'?: (highlighted: boolean) => void;
 *   'onChange': (timestamp: number) => void;
 *   'value': number | string;
 *   'view'?: typeof VIEW_MONTH | typeof VIEW_YEAR;
 * }} CalendarProps
 */

/**
 * Renders a calendar for selecting days or months.
 *
 * @example
 *   <Calendar
 *     value="2021-10-12"
 *     onChange={(newDate) => setDate(newDate)}
 *     data-testid="foo"
 *   />;
 *
 * @type {React.ForwardRefRenderFunction<HTMLDivElement, CalendarProps>}
 */
const CalendarFn = (
  {
    'data-testid': dataTestId,
    max,
    min,
    highlightRange,
    onHighlight,
    onChange,
    value,
    view = VIEW_MONTH,
  },
  ref,
) => {
  const [activePageDate, setActivePageDate] = useState(new Date());
  const minDate = useMemo(() => min && getLocalDayFromUTC(min), [min]);
  const maxDate = useMemo(() => max && getLocalDayFromUTC(max), [max]);

  // React-calendar requires dates to be in the local timezone
  const localDate = useMemo(() => {
    if (!value) return null;

    const utcDate = new Date(value);
    if (Number.isNaN(utcDate.getTime())) return null;

    const local = getLocalDayFromUTC(utcDate.getTime());

    if (
      local.getMonth() !== activePageDate.getMonth() ||
      local.getFullYear() !== activePageDate.getFullYear()
    ) {
      setActivePageDate(local);
    }

    return local;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [value]);

  const pageCalendar = (offset) => {
    let newDate = new Date(activePageDate.getTime());
    newDate.setMonth(newDate.getMonth() + offset, 1);

    if (newDate < minDate) newDate = minDate;
    else if (maxDate && newDate > maxDate) newDate = maxDate;

    setActivePageDate(newDate);
  };

  const handleChange = (newDate) => {
    // Convert local back to UTC
    onChange(getUTCDayTimestamp(newDate));
  };

  const activePageDateMs = activePageDate.getTime();

  return (
    <div
      ref={ref}
      className={classNames('Calendar', view === VIEW_YEAR && 'Calendar-year')}
      data-testid={dataTestId}
    >
      <div className="Calendar_Header">
        {view === VIEW_MONTH && (
          <CalendarPager
            disableNext={max && max <= activePageDateMs}
            disablePrev={min && min >= activePageDateMs}
            pageAmount={1}
            onPage={pageCalendar}
            data-testid={`${dataTestId}-monthPager`}
          >
            {monthFormatter.format(activePageDate)}
          </CalendarPager>
        )}
        <CalendarPager
          disableNext={
            // Since we've shifted the date to local time for react-calendar,
            // getUTCFullYear will return the previous year for Jan 1
            maxDate?.getFullYear() <= activePageDate.getFullYear()
          }
          disablePrev={
            !Number.isNaN(minDate) &&
            minDate?.getFullYear() >= activePageDate.getFullYear()
          }
          pageAmount={12}
          onPage={pageCalendar}
          data-testid={`${dataTestId}-yearPager`}
        >
          {activePageDate.getFullYear()}
        </CalendarPager>
      </div>
      <ReactCalendar
        activeStartDate={activePageDate}
        calendarType="US"
        className="Calendar_Content"
        onChange={handleChange}
        formatMonth={formatMonth}
        maxDate={maxDate}
        minDate={minDate}
        showFixedNumberOfWeeks
        showNavigation={false}
        tileClassName={({ date }) => {
          if (highlightRange?.length > 0 && !highlightRange.includes(value)) {
            onHighlight(true);
            const dateTime = getUTCDayTimestamp(date);
            if (highlightRange.includes(dateTime)) {
              return classNames(CALENDAR_DAY, 'Calendar_Day-highlight');
            }
            if (dateTime === value) {
              return classNames(CALENDAR_DAY, 'Calendar_Day-partial');
            }
          } else onHighlight?.(false);
          return CALENDAR_DAY;
        }}
        value={localDate}
        view={view}
        maxDetail={view}
      />
      {view === VIEW_MONTH && (
        <Button
          className="Button-primaryLink"
          data-testid={`${dataTestId}-todayBtn`}
          onClick={() => {
            const today = new Date(getCurrentDayTimestamp());
            onChange(today.getTime());
          }}
        >
          {TODAY_LABEL}
          <RightArrowIcon className="Button_LinkArrow" aria-hidden="true" />
        </Button>
      )}
    </div>
  );
};

const Calendar = forwardRef(CalendarFn);

Calendar.propTypes = {
  /** Unique ID for selecting the calendar in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Furthest date in the future which may be selected */
  'max': PropTypes.number,
  /** Furthest date in the past which may be selected */
  'min': PropTypes.number,
  /** A range of months to be highlighted */
  'highlightRange': PropTypes.arrayOf(PropTypes.number),
  /**
   * Event handler for parital date range selection
   *
   * @param {number} date The selected date
   */
  'onHighlight': PropTypes.func,
  /**
   * Event handler for when the user selects a date
   *
   * @param {number} date The selected date
   */
  'onChange': PropTypes.func.isRequired,
  /** The selected date */
  'value': PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Whether the calendar should show days of the month or months of the year.
   * Defaults to days of the month.
   */
  'view': PropTypes.oneOf([VIEW_MONTH, VIEW_YEAR]),
};

export default Calendar;
