// @ts-check
import {
  AUTHENTICATION_SUCCEEDED,
  PERSIST_STATEFUL_ROUTE,
  DISMISS_UNAUTHORIZED,
  PERMISSIONS_CHANGED,
  BILL_AUTHENTICATION_SUCCEEDED,
} from '@/actionTypes/auth';
import {
  SET_COMPANIES_ON_LOGIN,
  SET_SELECTED_COMPANY,
  RESET_COMPANIES,
  UPDATE_COMPANY,
  SET_COMPANIES_LOADING,
  UPDATE_SALARY_INCREASE,
  SALARY_INCREASE_ERROR,
  REFRESH_COMPANIES_DATA,
} from '@/actionTypes/companies';
import { LOGOUT } from '@/actionTypes/shared';

/**
 * @typedef {{
 *   type: string;
 *   systemType: import('@/constants/integrations').IntegrationFamily;
 *   connected: boolean;
 *   status: null | string;
 *   externalCompanyId: string;
 *   lastUpdatedAt: string;
 * }} Integration
 */

/**
 * @typedef {{
 *   salaryIncreasePercentage: number;
 *   frequency: number;
 *   salaryIncrementBasisType: number;
 *   customDate: null | string;
 * }} SalaryIncrease
 */

/**
 * @typedef {{
 *   status: number;
 *   displayName: string;
 *   amount?: number;
 * }} BillingPlan
 */

/**
 * @typedef {{
 *   id: number;
 *   name: string;
 *   loadMultiplier: number;
 *   currency: string;
 *   registrationStatus: string;
 *   isActive: boolean;
 *   defaultScenarioId: number;
 *   totalUser: number;
 *   billingPlan: BillingPlan;
 *   userRole: import('@/constants/permissions').UserRoles;
 *   enabledIntegrations: null | Integration[];
 *   adminNames: string[];
 *   productTypes: string[];
 * }} Company
 */

/**
 * @typedef {{
 *   companies: Company[];
 *   companiesLoading: boolean;
 *   salaryIncrease: {
 *     salaryIncrease: null | SalaryIncrease;
 *     error: null | string;
 *   };
 *   selectedCompanyId: null | number;
 * }} CompanyState
 */

const companyId = window.sessionStorage.getItem('companyId');

/** @type {CompanyState} */
const INITIAL_STATE = {
  companies: [],
  companiesLoading: false,
  salaryIncrease: {
    salaryIncrease: null,
    error: null,
  },
  selectedCompanyId: companyId ? Number(companyId) : null,
};

/** @type {(state: CompanyState, action: any) => CompanyState} */
const companiesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTHENTICATION_SUCCEEDED: {
      const { companies, selectedCompanyId: signupCompanyId } = action.payload;
      let selectedCompanyId = signupCompanyId;
      /**
       * Users with access to multiple companies will be redirected to the
       * company selection page on login. If there is only one company, select
       * it so the user can skip this page.
       */

      if (!selectedCompanyId) {
        const companyIds = Object.keys(companies);
        if (companyIds.length > 1) return state;
        [selectedCompanyId] = companyIds;
      }

      return {
        ...state,
        selectedCompanyId: Number(selectedCompanyId),
      };
    }
    case BILL_AUTHENTICATION_SUCCEEDED: {
      const { companies, selectedCompanyId } = action.payload;
      return {
        ...state,
        selectedCompanyId,
        companies: structuredClone(companies),
      };
    }
    case SET_COMPANIES_ON_LOGIN:
    case REFRESH_COMPANIES_DATA:
      return {
        ...state,
        companies: structuredClone(action.payload),
      };
    case PERMISSIONS_CHANGED:
      return { ...state, companies: action.payload.companies };
    case PERSIST_STATEFUL_ROUTE:
      return { ...state, selectedCompanyId: action.payload.companyId };
    case SET_COMPANIES_LOADING:
      return {
        ...state,
        companiesLoading: action.payload,
      };
    case SET_SELECTED_COMPANY:
      return {
        ...state,
        selectedCompanyId: action.payload,
      };
    case LOGOUT:
    case RESET_COMPANIES:
      return { ...INITIAL_STATE };
    case UPDATE_COMPANY: {
      const { selectedCompanyId } = state;
      const updateCompanyId = action.payload.id ?? selectedCompanyId;

      const companies = [...state.companies];
      const companyIdx = companies.findIndex(
        ({ id }) => id === updateCompanyId,
      );
      const updatedCompany = {
        ...companies[companyIdx],
        ...action.payload,
      };
      companies.splice(companyIdx, 1, updatedCompany);
      return {
        ...state,
        companies,
      };
    }
    case UPDATE_SALARY_INCREASE:
      return {
        ...state,
        salaryIncrease: {
          salaryIncrease: action.payload,
          error: null,
        },
      };
    case SALARY_INCREASE_ERROR:
      return {
        ...state,
        salaryIncrease: {
          ...state.salaryIncrease,
          error: action.payload,
        },
      };
    case DISMISS_UNAUTHORIZED:
      return { ...state, selectedCompanyId: action.payload.selectedCompanyId };
    default:
      return state;
  }
};

export default companiesReducer;
