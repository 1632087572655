import { useEffect, useState, useRef, useCallback } from 'react';

const observed = new Set();
/**
 * Returns TRUE whenever the given element overflows its parent's container
 *
 * @param {Object} ref Ref of the element to check
 * @returns {boolean} TRUE if the element overflows
 */
export default function useIfOverflows(ref) {
  const [doesOverflow, setDoesOverflow] = useState(false);
  const resizeCallback = useCallback((entries) => {
    requestAnimationFrame(() => {
      const [{ contentRect, target }] = entries;
      if (contentRect.width === 0) return;
      const truncatedWidth = target.offsetWidth;
      target.style.overflow = 'visible';
      target.style.maxWidth = 'none';
      const overflowWidth = target.offsetWidth;
      target.style.overflow = '';
      target.style.maxWidth = '';
      setDoesOverflow(truncatedWidth < overflowWidth);
    });
  }, []);
  /** @type {React.MutableRefObject<ResizeObserver | null>} */
  const observer = useRef(null);
  useEffect(() => {
    const { current } = ref;
    if (!observer.current) {
      observer.current = new ResizeObserver(resizeCallback);
    }
    if (current) {
      observer.current.observe(ref.current);
      observed.add(ref.current);
    }
    return () => {
      observer.current.unobserve(current);
      if (observed.size === 0) {
        observer.current.disconnect();
        observer.current = null;
      } else {
        observer.current.unobserve(current);
        observed.delete(current);
      }
    };
  }, [ref, resizeCallback]);
  return doesOverflow;
}
