/**
 * Displays a styled static numerical value which we use in formulaBreakdown
 *
 * @example
 *   <Static value="50" />;
 */
const Static = ({ value }) => (
  <span className="FormulaBreakdown_Item FormulaBreakdown_Item-static">
    {value}
  </span>
);

export default Static;
