import { useMemo } from 'react';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import {
  getExternalCellStyle,
  getStylesForExternalSpreadsheet,
} from '@/components/common/MonthlySpreadsheet/helpers';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import { GRID_OPTIONS } from '@/pages/Reports/constants';

const ExternalReport = ({
  apiRef,
  'data-testid': dataTestId,
  data,
  isLoading,
  name,
  isInteractive = true,
}) => {
  const colDef = useMemo(
    () => [
      {
        headerName: name,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: { suppressCount: true },
        field: 'title',
        headerComponent: HeaderRenderer,
        headerComponentParams: { enableExpandAll: isInteractive },
        minWidth: 300,
        showRowGroup: true,
        cellStyle: ({ data: { style } }) => {
          return getStylesForExternalSpreadsheet(style);
        },
      },
    ],
    [isInteractive, name],
  );

  return (
    <div className="ReportsGrid">
      <MonthlySpreadsheet
        {...GRID_OPTIONS}
        ref={apiRef}
        columnDefs={colDef}
        data-testid={dataTestId}
        data={data}
        cellStyle={getExternalCellStyle}
        isInteractive={isInteractive}
        hideZeroRowsByDefault={!isInteractive}
        loading={isLoading}
      />
    </div>
  );
};

export default ExternalReport;
