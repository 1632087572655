// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector, useDispatch } from 'react-redux';
import ListGroupedIcon from '@bill/cashflow.assets/list-grouped';
import MenuIcon from '@bill/cashflow.assets/menu';
import { userPreferencesAction } from '@/actions/auth';
import IconButton from '@/components/common/IconButton';

/**
 * Spreadsheet controls to change displaying the data rows flat or grouped.
 * Wired up to persist to localStorage when refreshing / logging out.
 *
 * @example
 *   <SpreadsheetToolbar>
 *     <GroupingToggle spreadsheetId={SPREADSHEET_ID} />
 *   </SpreadsheetToolbar>;
 */
const GroupingToggle = ({
  spreadsheetId,
  label = 'By category / department',
}) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const { userPreferences } = useSelector(({ auth }) => ({
    userPreferences: auth.preferences,
  }));

  return (
    <div className="SpreadsheetToolbar_ControlGroup">
      <span className="SpreadsheetToolbar_Label">View:</span>

      <IconButton
        active={userPreferences[`${spreadsheetId}-GroupingToggle`] ?? true}
        data-testid={`${spreadsheetId}-grouped`}
        Icon={ListGroupedIcon}
        label={label}
        onClick={() => {
          dispatch(
            userPreferencesAction({
              [`${spreadsheetId}-GroupingToggle`]: true,
            }),
          );
        }}
      />
      <IconButton
        active={userPreferences[`${spreadsheetId}-GroupingToggle`] === false}
        data-testid={`${spreadsheetId}-ungrouped`}
        Icon={MenuIcon}
        label="Ungrouped"
        onClick={() => {
          dispatch(
            userPreferencesAction({
              [`${spreadsheetId}-GroupingToggle`]: false,
            }),
          );
        }}
      />
    </div>
  );
};

export default GroupingToggle;
