import { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import MenuMiniIcon from '@bill/cashflow.assets/menu-mini';
import RoundCrossIcon from '@bill/cashflow.assets/round-cross';
import ContextMenu from '@/components/common/ContextMenu';
import FormField from '@/components/common/FormField';
import PlusButton from '@/components/common/PlusButton';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import { SECTION_PLACEHOLDER_ID } from '@/constants/variables';
import { isLimitCustomVariables } from '@/helpers';
import useIsFPALite from '@/hooks/useIsFPALite';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import './VariableSectionRenderer.scss';

function VariableSectionRenderer({
  api,
  data,
  node,
  onAddClick,
  onCancel,
  onDeleteClick,
  onSave,
  registerRowDragger,
  sections,
  value,
}) {
  const isFPALite = useIsFPALite();
  const { customVariables } = useSelector((state) => state.variables);
  const isUnmounted = useRef(false);
  const hasWritePermission = useNonDashboardWritePermission();
  useEffect(() => () => (isUnmounted.current = true), []);
  const [count, setCount] = useState(node.allChildrenCount);

  useEffect(() => {
    // It is possible for the event to fire after the component has unmounted,
    // but before the listener is removed, which will cause a setState warning.
    const handleModelUpdate = () =>
      !isUnmounted.current && setCount(node.allChildrenCount);
    api.addEventListener('modelUpdated', handleModelUpdate);
    return () => api.removeEventListener('modelUpdated', handleModelUpdate);
  }, [api, node.allChildrenCount]);

  const section = data || sections.find(({ id }) => id === value);

  const input = useRef(null);
  const dragBtn = useRef(null);
  const [name, setName] = useState(section.name);
  const [isEditing, setEditing] = useState(
    section.id === SECTION_PLACEHOLDER_ID,
  );
  const [isInvalid, setInvalid] = useState(false);
  const toggleExpanded = () => node.setExpanded(!node.expanded);

  const cancelSave = () => {
    setEditing(false);
    setName(section.name);
    onCancel(section.id);
  };

  const confirmEdit = () => {
    const hasDuplicate = sections.some((sect) => sect.name === name);
    setInvalid(hasDuplicate);
    if (!hasDuplicate) {
      setEditing(false);
      onSave(section.id, { name });
    } else {
      input.current.focus();
    }
  };

  useEffect(() => {
    if (isEditing) {
      setTimeout(() => {
        input.current.focus();
        // When adding a new section to a long list, it can be
        // obscured by the sticky header
        if (section.id === SECTION_PLACEHOLDER_ID) {
          input.current.scrollIntoView(false);
        }
      }, 0);
    }
  }, [section, isEditing]);

  useEffect(() => {
    if (!isEditing) registerRowDragger(dragBtn.current, 0);
  }, [isEditing, registerRowDragger]);

  return (
    <div
      className="VariableSectionRenderer"
      onKeyDown={({ key }) => key === 'Enter' && toggleExpanded()}
    >
      <button
        className="VariableSectionRenderer_ToggleIcon"
        onClick={toggleExpanded}
        aria-label="Expand / Collapse"
      />
      {isEditing ? (
        <FormField
          ref={input}
          id={`${section.name}-input`}
          showErrors={isInvalid}
          value={name}
          validate={() => isInvalid && 'Please enter a unique name'}
          onBlur={({ relatedTarget }) => {
            if (relatedTarget && !relatedTarget.contains(input.current)) {
              if (name) {
                confirmEdit();
              } else {
                onCancel(section.id);
              }
            }
          }}
          onChange={({ target }) => setName(target.value)}
          onClick={(event) => event.stopPropagation()}
          onKeyDown={(event) => {
            const { key } = event;
            if (['Enter', 'Tab'].includes(key)) {
              event.stopPropagation();
              confirmEdit();
            } else if (key === 'Escape') {
              cancelSave();
            }
          }}
        >
          <button
            type="button"
            className="VariableSectionRenderer_CancelBtn"
            onClick={(event) => {
              event.stopPropagation();
              cancelSave();
            }}
          >
            <RoundCrossIcon aria-label="Cancel" />
          </button>
        </FormField>
      ) : (
        <>
          <button
            ref={dragBtn}
            type="button"
            className="Spreadsheet_DragBtn"
            aria-hidden="true"
          >
            <MenuMiniIcon className="Spreadsheet_DragIcon" />
          </button>
          <TooltipTextOverflow
            label={<span onDoubleClick={toggleExpanded}>{name}</span>}
            data-testid={`${name}-tooltip`}
            placement="bottom"
            className="VariableSection_Name"
          />
          <span className="VariableSectionRenderer_Count">
            ({count ?? 0} variable{count !== 1 && 's'})
          </span>
          {!section.default && hasWritePermission && (
            <ContextMenu
              disabled={isEditing}
              placement="bottom-start"
              data-testid={`${section.name}-actions`}
            >
              <ContextMenu.Option
                data-testid={`${section.name}-rename`}
                onClick={(event) => {
                  event.stopPropagation();
                  setEditing(true);
                }}
              >
                Rename Section
              </ContextMenu.Option>
              <ContextMenu.Option
                danger
                disabled={count > 0}
                data-testid={`${section.name}-delete`}
                onClick={() => onDeleteClick(section)}
              >
                Delete Section
              </ContextMenu.Option>
            </ContextMenu>
          )}
          {hasWritePermission && (
            <PlusButton
              onAdd={(event) => {
                event.stopPropagation();
                onAddClick(section.id);
              }}
              disabled={
                isFPALite && isLimitCustomVariables(customVariables.length)
              }
            >
              Add Custom Variable
            </PlusButton>
          )}
        </>
      )}
    </div>
  );
}

export default VariableSectionRenderer;
