// @ts-check

/** @typedef {'Manage' | 'Read' | 'Write' | 'ReadWrite'} PermissionAction */

/** @type {Object<string, PermissionAction>} PermissionActions */
export const actions = {
  MANAGE: 'Manage',
  READ: 'Read',
  WRITE: 'Write',
  READ_WRITE: 'ReadWrite',
};

/**
 * @typedef {'Scenarios'
 *   | 'NonDashboard'
 *   | 'Dashboard'
 *   | 'Employees'
 *   | 'CompanySettings'
 *   | 'LoadMultiplier'
 *   | 'Terms'} PermissionSubject
 */

/** @type {Object<string, PermissionSubject>} PermissionSubjects */
export const subjects = {
  SCENARIOS: 'Scenarios',
  NON_DASHBOARD: 'NonDashboard',
  DASHBOARD: 'Dashboard',
  EMPLOYEE_SETTINGS: 'Employees',
  COMPANY_SETTINGS: 'CompanySettings',
  LOAD_MULTIPLIER: 'LoadMultiplier',
  PAYMENT_COLLECTION_TERMS: 'Terms',
};

export const USER_ROLES = /** @type {const} */ ({
  ROLE_ADMIN: 'Admin',
  ROLE_USER: 'User',
  ROLE_READ_ONLY: 'Dashboard Only',
});

/** @typedef {(typeof USER_ROLES)[keyof USER_ROLES]} UserRoles */
