import { useCallback } from 'react';
import { connect } from 'react-redux';
import EmployeeSalary from '@/components/Employee/EmployeeSalary';
import JobTitle from '@/components/Employee/JobTitle';
import { isCommissionEnabled } from '@/components/Employee/helpers';
import DateField from '@/components/common/DateField';
import FormLabel from '@/components/common/FormLabel';
import Select from '@/components/common/Select';
import {
  EMPLOYMENT_TYPE,
  FOUNDER,
  SET_DEPARTMENT,
  SET_JOB_TITLE,
  SET_ROLE_TYPE,
  SET_SALARY,
  SET_START_DATE,
  SET_END_DATE,
  COMMISSION_FORMULA,
  BONUS_FORMULA,
} from '@/constants/employees';
import {
  getCurrentDayTimestamp,
  getISODate,
  getNextDay,
} from '@/helpers/dateFormatter';

const COMPENSATION_TYPE = 'compensationType';
const getTimeForDate = (date) => new Date(date).getTime();

export const validateDate = (dateStr) => {
  const date = new Date(dateStr);

  if (!dateStr || Number.isNaN(date.getTime())) {
    return 'Please enter a valid date';
  }

  const year = date.getUTCFullYear();

  if (year < 1900 || year > 2199) {
    return 'Date year must be in between 1900 and 2199';
  }

  return null;
};

export const validateEndDate = (dateStr) => {
  if (!dateStr) {
    return null;
  }
  return validateDate(dateStr);
};

const FormFoundation = ({
  isEditMode,
  state,
  dispatchFn,
  departments,
  setIsSalaryValid,
  isEmployeeForm = false,
  children,
  scenarioId,
}) => {
  const startDateMs = state.startDate ? getTimeForDate(state.startDate) : null;
  const endDateMs = state.endDate ? getTimeForDate(state.endDate) : null;

  const employmentTypes = isEmployeeForm
    ? Object.values(EMPLOYMENT_TYPE)
    : Object.values(EMPLOYMENT_TYPE).filter((employmentType) =>
        [EMPLOYMENT_TYPE.fte, EMPLOYMENT_TYPE.contractor].includes(
          employmentType,
        ),
      );

  const handleStartDateChange = useCallback(
    (date) => {
      dispatchFn({
        type: SET_START_DATE,
        payload: date ? getISODate(date) : null,
      });
    },
    [dispatchFn],
  );

  const handleEndDateChange = useCallback(
    (date) => {
      dispatchFn({
        type: SET_END_DATE,
        payload: date ? getISODate(date) : null,
      });
    },
    [dispatchFn],
  );

  return (
    <>
      <div className="Form_Group">
        {state.errorMessage && (
          <p className="alert alert-danger">
            <b>Error </b> - {state.errorMessage}
          </p>
        )}
      </div>
      <div className="Form_Group Form_Group-halfWidth">
        <label htmlFor="departmentId" className="Label">
          Department
        </label>
        <Select
          id="departmentId"
          name="departmentId"
          validate={(e) =>
            !e && state.hasInteractedWithForm
              ? 'Please select a department'
              : ''
          }
          value={state.departmentId}
          onChange={({ target: { value } }) => {
            const keys = Object.keys(state);
            if (keys.includes(COMPENSATION_TYPE)) {
              const isCommisionVisible = isCommissionEnabled(
                departments,
                value,
              );
              dispatchFn({
                type: SET_DEPARTMENT,
                payload: {
                  departmentId: value,
                  compensationFormula: '',
                  compensationType:
                    isCommisionVisible && !isEmployeeForm
                      ? COMMISSION_FORMULA
                      : BONUS_FORMULA,
                },
              });
            } else {
              dispatchFn({
                type: SET_DEPARTMENT,
                payload: { departmentId: value },
              });
            }
            dispatchFn({ type: SET_JOB_TITLE, payload: '' });
          }}
          data-testid="departmentId"
        >
          <option value="">Select a department</option>
          {departments &&
            departments
              .filter((department) => department.code !== FOUNDER)
              .map((department) => (
                <option key={department.id} value={department.id}>
                  {department.name}
                </option>
              ))}
        </Select>
      </div>
      <div className="Form_Group Form_Group-halfWidth">
        <label htmlFor="employmentType" className="Label">
          Role Type
        </label>
        <Select
          id="employmentType"
          name="employmentType"
          value={state.employmentType}
          onChange={({ target: { value } }) => {
            dispatchFn({ type: SET_ROLE_TYPE, payload: value });
          }}
          data-testid="employmentType"
        >
          {employmentTypes.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </Select>
      </div>
      <div className="Form_Group Form_Group-halfWidth">
        <label htmlFor="titleId" className="Label">
          Job Title
        </label>
        <JobTitle
          currentRecord={state}
          onChange={(titleId) => {
            dispatchFn({ type: SET_JOB_TITLE, payload: titleId });
          }}
          disabled={!state.departmentId}
        />
      </div>
      {children}
      <EmployeeSalary
        scenarioId={scenarioId}
        isEditMode={isEditMode}
        salaryAmount={state?.annualSalary}
        salaryFormula={state?.salaryFormula}
        salaryVariableId={state?.salaryVariableId}
        setIsSalaryValid={setIsSalaryValid}
        onChange={({ annualSalary, salaryFormula, salaryVariableId }) => {
          dispatchFn({
            type: SET_SALARY,
            payload: { annualSalary, salaryFormula, salaryVariableId },
          });
        }}
      />
      <div className="Form">
        <div className="Form_Group Form_Group-halfWidth">
          <label className="Label" htmlFor="formFoundation-startDate">
            Start Date
          </label>
          <DateField
            id="formFoundation-startDate"
            value={startDateMs}
            min={!isEmployeeForm ? getCurrentDayTimestamp() : null}
            validate={validateDate}
            onChange={handleStartDateChange}
          />
        </div>
        <div className="Form_Group Form_Group-halfWidth">
          <FormLabel
            htmlFor="formFoundation-termDate"
            text={isEmployeeForm ? 'Term Date' : 'End Date'}
            optional
          />
          <DateField
            value={endDateMs}
            min={getNextDay(new Date(startDateMs))}
            validate={validateEndDate}
            onChange={handleEndDateChange}
            id="formFoundation-termDate"
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ scenario }) => ({
  scenarioId: scenario.scenarioId,
});

export default connect(mapStateToProps)(FormFoundation);
