import COLORS from '@/constants/colorPalette';

export const CHART_TITLE = 'Cash In';
export const TOTAL_CASH_IN_MAIN_METRIC = 'totalY';

export const CASH_IN_METRICS = [
  {
    key: 'y',
    name: CHART_TITLE,
    isMainMetric: true,
  },
];

export const cashAccountsColorMap = /** @type {const} */ ({
  0: COLORS.finmarkBlue,
  1: COLORS.crayola,
  2: COLORS.plum,
  3: COLORS.yellow,
  4: COLORS.slate,
  5: COLORS.raisinBlack,
  6: COLORS.sunrise,
  7: COLORS.cinnabar2,
  8: COLORS.spanishGrey,
});
