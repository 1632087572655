// @ts-check
import Api, { ApiV2 } from './Api';
import getByDateRange from './getByDateRange';

export const getProfitAndLoss = getByDateRange('/reports/profitAndLoss');

export const getVendorSpend = getByDateRange('/reports/vendorReport');

/**
 * Get the external balance sheet data
 *
 * @param {string} startDate First month of report to be retrieved
 * @param {string} endDate Last month of report to be retrieved
 * @param {number} scenarioId The id of the scenario
 * @returns {Promise} API response
 */
export const getExternalBalanceSheet = getByDateRange(
  '/spreadsheet-reports/balance-sheet',
);

/**
 * Get the external custom sheet data
 *
 * @param {string} startDate First month of report to be retrieved
 * @param {string} endDate Last month of report to be retrieved
 * @param {Array} scenarioIds Id of the scenarios
 * @param {string} customReportName The name of the custom report
 * @param {string} timePeriod Period of Time
 * @returns {Promise} API response
 */
export const getCustomReportByName = (
  startDate,
  endDate,
  scenarioIds,
  customReportName,
  timePeriod,
) => {
  return ApiV2.get(`/reports/customReports`, {
    params: {
      scenarioIds,
      startDate,
      endDate,
      name: customReportName,
      timePeriod,
    },
  });
};

/**
 * @typedef {{
 *   name: string;
 *   id: string;
 * }} ReportEntity
 */

/**
 * @typedef {{
 *   isVendorAvailable: boolean;
 *   customReports: ReportEntity[];
 *   enabledSpreadsheetReports: string[];
 *   userReports: ReportEntity[];
 * }} ReportsInfoResponse
 */

/**
 * Get reports information including names and availability
 *
 * @type {(
 *   scenarioId: number,
 * ) => Promise<import('axios').AxiosResponse<{ data: ReportsInfoResponse }>>}
 */
export const getReportsInfo = (scenarioId) => {
  return ApiV2.get(`/reports/info`, {
    params: { scenarioId },
  });
};

/**
 * @typedef {{
 *   scenarioId: number;
 *   key: string;
 * }} ReportScenarioKey
 */

/** @typedef {'Section' | 'Item'} ReportTypeEnum */

/**
 * @typedef {{
 *   scenarioId: number;
 *   value?: number;
 *   displayFormula?: string;
 *   faulted: boolean;
 *   type?: import('@/components/common/types').VariableValueType;
 *   variableValueFaultType?: import('@/components/common/types').VariableValueFaultType;
 * }} ReportScenarioValue
 */

/**
 * @typedef {{
 *   date: string;
 *   value: ReportScenarioValue[];
 *   variance: number;
 *   endDate: string;
 * }} ReportDateValue
 */

/**
 * @typedef {{
 *   id: string;
 *   key: ReportScenarioKey[];
 *   name: string;
 *   type: ReportTypeEnum;
 *   months: ReportDateValue[];
 *   isEditable: boolean;
 *   index: number;
 *   isAdditional: boolean;
 *   isExternal: boolean;
 *   accountId?: string;
 * }} ReportNode
 */

/**
 * @typedef {{
 *   children: ReportNode[];
 * }} ReportResponse
 */

/**
 * Get the user report data
 *
 * @type {(params: {
 *   scenarioIds: number[];
 *   reportName: string;
 *   startDate: string;
 *   endDate: string;
 *   timePeriod: import('@/components/common/types').TimePeriod;
 * }) => Promise<import('axios').AxiosResponse<{ data: ReportResponse }>>}
 */
export const getUserReportData = ({
  scenarioIds,
  reportName,
  startDate,
  endDate,
  timePeriod,
}) => {
  return ApiV2.get(`/reports`, {
    params: { scenarioIds, reportName, startDate, endDate, timePeriod },
  });
};

/**
 * POST user report subsection
 *
 * @param {number} scenarioId The id of the scenario
 * @param {Object} reportSubsection New subsection to commit
 * @returns {Promise} API response
 */
export const addReportSubsection = (scenarioId, reportSubsection) => {
  return ApiV2.post(`/reports/sections`, reportSubsection, {
    params: { scenarioId },
  });
};

/**
 * PUT user report subsection
 *
 * @param {number} scenarioId The id of the scenario
 * @param {Object} reportSubsection Updated subsection to commit
 * @returns {Promise} API response
 */
export const updateReportSubsection = (scenarioId, reportSubsection) => {
  return ApiV2.put(
    `/reports/sections/${reportSubsection.id}`,
    reportSubsection,
    {
      params: { scenarioId },
    },
  );
};

/**
 * DELETE user report subsection
 *
 * @param {number} scenarioId The id of the scenario
 * @param {Object} reportSubsectionId subsectionId to delete
 * @returns {Promise} API response
 */
export const deleteReportSubsection = (scenarioId, reportSubsectionId) => {
  return ApiV2.delete(`/reports/sections/${reportSubsectionId}`, {
    params: { scenarioId },
  });
};

/**
 * POST user report subsectionItem
 *
 * @param {number} scenarioId The id of the scenario
 * @param {Object} reportSubsectionItem New subsectionItem to commit
 * @returns {Promise} API response
 */
export const addReportSubsectionItem = (scenarioId, reportSubsectionItem) => {
  return ApiV2.post(`/reports/items`, reportSubsectionItem, {
    params: { scenarioId },
  });
};

/**
 * PUT user report subsectionItem
 *
 * @param {number} scenarioId The id of the scenario
 * @param {Object} reportSubsectionItem Updated subsectionItem to commit
 * @returns {Promise} API response
 */
export const updateReportSubsectionItem = (
  scenarioId,
  reportSubsectionItem,
) => {
  return ApiV2.put(
    `/reports/items/${reportSubsectionItem.id}`,
    reportSubsectionItem,
    {
      params: { scenarioId },
    },
  );
};

/**
 * Updates one or more user report item values in the given scenario
 *
 * @param {number} scenarioId ID of the scenario containing the report
 * @param {Object[]} data Values to update
 * @returns {Promise} API response
 */
export const setReportItemValues = (scenarioId, data) =>
  ApiV2.post('/reports/items/values', data, {
    params: { scenarioId },
  });

/**
 * DELETE user report subsectionItem
 *
 * @param {number} scenarioId The id of the scenario
 * @param {Object} reportSubsectionItemId subsectionItemId to delete
 * @returns {Promise} API response
 */
export const deleteReportSubsectionItem = (
  scenarioId,
  reportSubsectionItemId,
) => {
  return ApiV2.delete(`/reports/items/${reportSubsectionItemId}`, {
    params: { scenarioId },
  });
};

/**
 * Sets a new order for the given report rows
 *
 * @param {number} scenarioId
 * @param {Object[]} data
 * @returns {Promise} API response
 */
export const setReportOrder = (scenarioId, data) =>
  ApiV2.put('/reports/reorder', data, { params: { scenarioId } });

/**
 * @typedef {{
 *   chatGPTPromptPermissionGranted: boolean;
 *   chatGPTPromptPermissionDate: string;
 * }} getChatGPTPromptPermissionResponse
 */

/**
 * Get user company preference for AI Summary
 *
 * @type {(arg: string) => Promise<
 *   import('@/types/api').AxiosApiResponse<{
 *     preference: getChatGPTPromptPermissionResponse;
 *   }>
 * >}
 */
export const getChatGPTPromptPermission = (companyId) =>
  Api.get(`/user-companies/${companyId}/preferences`);

/**
 * Get user company preference for AI Summary
 *
 * @type {(params: {
 *   companyId: string;
 *   data: getChatGPTPromptPermissionResponse;
 * }) => Promise<
 *   import('@/types/api').AxiosApiResponse<{
 *     preference: getChatGPTPromptPermissionResponse;
 *   }>
 * >}
 */
export const setChatGPTPromptPermission = ({ companyId, data }) =>
  Api.put(`/user-companies/${companyId}/preferences`, data);

/**
 * Send user feedback for AI generated Executive Summary
 *
 * @type {(
 *   params: import('@/types/services/backend').PromptFeedbackDto,
 * ) => Promise<
 *   import('axios').AxiosResponse<
 *     import('@/types/services/backend').PromptResponseLogTrailDto
 *   >
 * >}
 */
export const sendUserFeedbackOnAISummary = (payload) =>
  Api.put('/text/feedback', payload);
