import { forwardRef, useCallback } from 'react';
import DateField from '@/components/common/DateField';
import FormField from '@/components/common/FormField';
import NumberField from '@/components/common/NumberField';
import {
  getLocalDayFromUTC,
  getUTCDayTimestamp,
} from '@/helpers/dateFormatter';
import { isEmptyOrNull } from '@/helpers/validators';

const FilterValueField = forwardRef(
  ({ type, onChange, value, ...props }, ref) => {
    let Field = FormField;
    let localValue = value;
    if (type === 'number') {
      Field = NumberField;
    } else if (type === 'date') {
      Field = DateField;
      localValue = value ? getUTCDayTimestamp(value) : value;
    }

    const handleChange = useCallback(
      (payload) => {
        let newValue;
        if (type === 'date') {
          // ag-Grid drops the time off the date, which can cause the day to
          // be incorrect depending on the user's time zone, so we must
          // offset it.
          newValue = !isEmptyOrNull(payload)
            ? new Date(getLocalDayFromUTC(payload))
            : payload;
        } else {
          newValue = payload.target.value;
        }
        onChange(newValue);
      },
      [onChange, type],
    );

    return (
      <Field ref={ref} value={localValue} onChange={handleChange} {...props} />
    );
  },
);

export default FilterValueField;
