// @ts-check
import { forwardRef, useCallback, useMemo, useRef } from 'react';
import { useApolloClient } from '@apollo/client/react';
import { FederatedParcel } from '@bill/cashflow.mfe-support';
import { GLOBAL_TOP_NAV_REMOTE } from '@/components/Remote/GlobalTopNav/remoteModules';
import loadRemoteModule from '@/helpers/loadRemoteModule';
import useLogout from '@/hooks/useLogout';
import { INSIGHTS_URL, SPEND_URL } from '@/runtimeConfig';
import { TOGGLE_SIDENAV_EVENT } from './constants';
import './GlobalTopNav.scss';

/**
 * @typedef {{
 *   withThemeContainer: boolean;
 *   props: import('@/types/global-top-nav').ITopNavProps;
 * }} ITopNavFederatedComponentProps
 */

/** @typedef {{ billBaseUrl: string }} GlobalTopNavProps */

/**
 * @type {React.ForwardRefRenderFunction<
 *   HTMLDivElement,
 *   GlobalTopNavProps
 * >}
 */
const GlobalTopNav = ({ billBaseUrl }, ref) => {
  const client = useApolloClient();
  const logout = useLogout();

  const loaderFn = useCallback(
    () => loadRemoteModule(GLOBAL_TOP_NAV_REMOTE),
    [],
  );

  const initLoadTime = useRef(Date.now());

  /** @type {ITopNavFederatedComponentProps} */
  const customProps = useMemo(
    () => ({
      initLoadTime: initLoadTime.current,
      withThemeContainer: true,
      props: {
        mfeName: 'GlobalTopNav',
        planningBaseUrl: window.location.origin,
        apolloClient: client,
        showGlobalTodos: false,
        billBaseUrl,
        spendBaseUrl: SPEND_URL,
        insightsBaseUrl: INSIGHTS_URL,
        host: 'PLANNING',
        onLogout: () => {
          logout();
          window.location.href = `${billBaseUrl}/Logout`;
        },
        onClickBillLink: () => {
          window.location.href = billBaseUrl;
        },
        routeToEx: (path) => {
          window.location.href = `${SPEND_URL}${path}`;
        },
        routeToAp: (path) => {
          window.location.href = `${billBaseUrl}${path}`;
        },
        initLoadTime: initLoadTime.current,
        onToggleMenu: () => {
          window.dispatchEvent(new CustomEvent(TOGGLE_SIDENAV_EVENT));
        },
      },
    }),
    [client, billBaseUrl, logout],
  );

  return (
    <div className="GlobalTopNav" data-testid="global-top-nav" ref={ref}>
      <FederatedParcel loaderFn={loaderFn} customProps={customProps} />
    </div>
  );
};

export default forwardRef(GlobalTopNav);
