// @ts-check
import { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FederatedParcel } from '@bill/cashflow.mfe-support';
import { SIDE_NAV_REMOTE } from '@/components/Remote/SideNav/remoteModule';
import {
  CASH_IN_OUT,
  DASHBOARD_PATH,
  SETTINGS_PATH,
  SETUP_PATH,
  VARIABLES_PATH,
} from '@/constants/pages';
import useGlobalTopNavContext from '@/contexts/useGlobalTopNavContext';
import useNotificationContext from '@/contexts/useNotificationContext';
import isBillDomain from '@/helpers/isBillDomain';
import loadRemoteModule from '@/helpers/loadRemoteModule';
import useSideNavLinks from '@/hooks/useSideNavLinks';
import useWebTracker from '@/hooks/useWebTracker';
import { REMOTE_GLOBAL_TOP_NAV_PATH } from '@/runtimeConfig';
import './SideNav.scss';

const SEGMENT_WEB_TRACKER_PATHS = {
  [SETUP_PATH]: 'left_nav_forecasting__setup__fired',
  [DASHBOARD_PATH]: 'left_nav_forecasting__cash_flow_dashboard__fired',
  [CASH_IN_OUT]: 'left_nav_forecasting__cash_in_cash_out__fired',
  [SETTINGS_PATH]: 'left_nav_forecasting__settings__fired',
  [VARIABLES_PATH]: 'left_nav_forecasting__variables__fired',
};

/** @typedef {{ props: import('@/types/side-nav').SideNavProps }} SideNavProps */

/**
 * @type {(
 *   props: {
 *     isOnboardingLayout?: boolean;
 *   } & Pick<import('@/types/side-nav').SideNavProps, 'onNavigation'>,
 * ) => React.ReactElement}
 */
const SideNav = ({ isOnboardingLayout = false, onNavigation }) => {
  const history = useHistory();
  const { margin: globalTopNavMargin } = useGlobalTopNavContext();
  const { margin: notificationMargin } = useNotificationContext();
  /** @type {import('@/types/side-nav').INavItem[]} */
  const navItems = useSideNavLinks({ isOnboardingLayout });
  /**
   * If there is a notification margin, the total margin is the sum of both the
   * global top navigation and the notification bar margins. Otherwise, only the
   * global top navigation margin is considered.
   */
  const margin = notificationMargin || globalTopNavMargin;
  const { webTracker } = useWebTracker();

  const customProps = useMemo(() => {
    /** @type {SideNavProps} */
    const result = {
      props: {
        navItems,
        onNavItemClick: (to) => {
          if (Object.keys(SEGMENT_WEB_TRACKER_PATHS).includes(to)) {
            webTracker.track(SEGMENT_WEB_TRACKER_PATHS[to]);
          }

          history.push(to);
        },
        hideHelpCenter: !isBillDomain(),
        onNavigation,
        sideNavHeaderRemoteUrl: REMOTE_GLOBAL_TOP_NAV_PATH,
        mfeName: 'SideNav',
      },
    };
    return result;
  }, [navItems, history, onNavigation, webTracker]);

  const loaderFn = useCallback(() => loadRemoteModule(SIDE_NAV_REMOTE), []);

  return (
    <div
      className="SideNavRoot"
      data-testid="side-nav"
      style={{
        height: `calc(100vh - ${margin}px)`,
        marginTop: `${margin}px`,
      }}
    >
      <FederatedParcel loaderFn={loaderFn} customProps={customProps} />
    </div>
  );
};

export default SideNav;
