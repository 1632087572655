// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';

/**
 * Retrieves the scenario id from `state.scenario` in redux store
 *
 * @type {() => number}
 */
const useScenarioId = () => {
  return useSelector((state) => {
    return state.scenario.scenarioId;
  });
};

export default useScenarioId;
