import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { USER_ROLES } from '@/constants/permissions';
import { EDIT, ScenarioRolesText } from '@/constants/scenario';
import { FPA_LITE_CUSTOM_VARIABLES_LIMIT } from '@/constants/variables';
import { isEmptyOrNull } from '@/helpers/validators';
import { REACT_APP_ENVIRONMENT } from '@/runtimeConfig';

const FINMARK_SUBS = /** @type {const} */ [
  'app',
  'preprod',
  'stg',
  'tst1',
  'tst2',
  'tst',
  'tst3',
  'tst4',
  'tst5',
  'tst6',
];

/**
 * Accepts any number of classNames and returns them all as a single string.
 *
 * @param {...string} args Classes to concatenate
 * @returns {string} Space-separated string of classes
 */
export function classNames(...args) {
  const set = new Set(args);
  return Array.from(set)
    .filter((className) => className)
    .join(' ')
    .trim();
}

/**
 * Capitalize a string
 *
 * @type {(str: string) => string}
 */
export const capitalize = (str) => {
  const lowerStr = str.toLowerCase();
  return lowerStr.charAt(0).toUpperCase() + lowerStr.slice(1);
};

/**
 * PropTypes helper function that specifies a component type to which children
 * should adhere. Note: If children is an iterator, it will be of type Array. To
 * account for this, you can do PropTypes.arrayOf(childrenOf([Type]))
 *
 * @param {Array} types Supported component types
 */
export function childrenOf(types) {
  const fieldType = PropTypes.shape({
    type: PropTypes.oneOf(types),
  });

  return PropTypes.oneOfType([
    fieldType,
    PropTypes.arrayOf(
      // Handle conditional rendering of individual children
      PropTypes.oneOfType([
        fieldType,
        PropTypes.arrayOf(fieldType),
        PropTypes.oneOf([false]),
      ]),
    ),
  ]);
}

// Removes path params from a url
export function removeParams() {
  window.history.replaceState(null, null, window.location.pathname);
}

/**
 * Ensures the given function does not get called too frequently by preventing
 * it from executing as long as it continues to be called within the given time
 * window.
 *
 * @type {<T extends (...args: any[]) => void>(
 *   func: T,
 *   timeout: number,
 * ) => (...args: Parameters<T>) => void}
 * @see throttle for an alternate approach
 */
export function debounce(func, timeoutMs) {
  let timer;
  return function debounceWrapper(...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), timeoutMs);
  };
}

/**
 * Ensures the given function does not get called to frequently by allowing only
 * one execution within the given time window.
 *
 * @param {Function} func Function to throttle
 * @param {number} limitMs Time window between executions
 * @returns {Function} Throttled function
 * @see debounce for an alternate approach
 */
export function throttle(func, limitMs) {
  let inThrottle;

  return function throttleWrapper(...args) {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
        func.apply(context, args);
      }, limitMs);
    }
  };
}

/**
 * Convert a string to a slug
 *
 * @param {string} str - A string
 * @returns {string} - A hyphen delimited slug from a string
 */
export function slugify(str) {
  return str
    .trim()
    .toLowerCase()
    .replace(/[^\p{L}\p{N} -]/gu, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
}

/**
 * Function that returns whatever is passed to it unchanged.
 *
 * The name "identity" is commonly used in functional programming. It is useful
 * for supplying a callback function that acts as a pass through or removing
 * falsy items from an array
 *
 * @example
 *   ['', 'hello', 'world', ''].filter(identity); // ['hello', 'world']
 *
 * @param {any} arg - An argument
 * @returns {any}
 */
export function identity(arg) {
  return arg;
}

/**
 * Remove session_id from url params
 *
 * @param {Function} [callback] - An optional callback
 */
export function stripSessionIdUrlParam(callback = undefined) {
  const url = new URL(window.location.href);
  if (url.searchParams.has('session_id')) {
    if (callback) {
      callback();
    }
    url.searchParams.delete('session_id');
    window.history.replaceState(null, null, url.href);
  }
}

/**
 * Returns key of value from the object passed
 *
 * @param {Object} object Object from which key is needed
 * @param {string} value value of key which is needed
 * @returns {string} Key of value
 */
export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

/**
 * Returns key of value from ScenarioRolesText
 *
 * @param {string} value value of key which is needed
 * @returns {string} Key of value
 */

export const getScenarioKeyFromValue = (value) =>
  getKeyByValue(ScenarioRolesText, value);

/**
 * Returns a value formatted as a number according to the user's locale
 *
 * @param {number} value Value to format
 * @returns {string} Formatted value
 */
export const formatNumber = (value) => {
  return new Intl.NumberFormat().format(value);
};

/**
 * Returns a value formatted as a percentage
 *
 * @param {number} value Value to format
 * @param {Object} options
 * @returns {string} Formatted value
 */
export const formatPercent = (value, options = {}) => {
  return new Intl.NumberFormat(
    {},
    {
      style: 'percent',
      maximumFractionDigits: 1,
      ...options,
    },
  ).format(value / 100);
};

/**
 * Downloads a CSV file
 *
 * @param {CSV} data CSV Data
 */
export const downloadCsvFile = (data) => {
  const csvFile = new Blob([data]);
  const link = document.createElement('a');
  link.download = 'Download existing-employees.csv';
  link.href = window.URL.createObjectURL(csvFile);
  link.click();
};

/**
 * Retrieves the value of a deeply-nested prop within an object using dot
 * notation
 *
 * @example
 *   getNestedProp({ a: { b: { c: 'foo' } } }, 'a.b.c');
 *
 * @param {Object} obj Object containing the desired prop
 * @param {string} propPath Dot-notated path to the prop
 * @returns {any} Value of the nested prop
 */
export function getNestedProp(obj, propPath) {
  return propPath.split('.').reduce((accum, key) => accum[key], obj);
}

/**
 * Returns a rough representation of the character width of the given value. Can
 * be used to resize an input element based on length.
 *
 * @param {string | number} value String or number to measure
 * @returns {number} Length of the value, in input 'size' units
 */
export function getSizeForInput(value) {
  return value ? Math.floor(value.toString().length * 1.1) : 1;
}

/**
 * Validates a given string expression for any special characters present
 *
 * @param {string} value The value to be tested
 * @returns {boolean} true if value has no special characters and false
 *   otherwise
 */
export function hasNoSpecialCharacters(value) {
  return /^[0-9A-Za-z\s]+$/.test(value);
}

/**
 * Returns TRUE if the given string is number. Used to differentiate between
 * static values and formulas.
 *
 * @param {string} value
 * @returns {boolean} TRUE if value is a number
 */
export function isNumber(value) {
  return (
    value !== '' && !`${value}`.endsWith('.') && !Number.isNaN(Number(value))
  );
}

/**
 * Returns the composition of a list of functions, where each function consumes
 * the return value of the function that follows (from right to left)
 *
 * @example
 *   const greet = (name) => `hi: ${name}!`;
 *   const exclaim = (name) => `${name.toUpperCase()}`;
 *   const welcome = compose(greet, exclaim);
 *   welcome('doug'); // 'hi: DOUG!'
 *
 * @param {...Function} fns - The functions to compose
 * @returns {Function}
 */
export const compose = (...fns) =>
  fns.reduce(
    (functionList, nextFn) =>
      (...args) =>
        functionList(nextFn(...args)),
  );

/**
 * Returns an object consisting of sub-domain and resource origin.
 *
 * @type {() => {
 *   subDomain: string | null;
 *   resourceHost: 'stg-resources' | 'resources';
 * }}
 */
export const getDomainAndResourceHost = () => {
  const [subDomain, domain] = window.location.hostname.split('.');
  const isLowerLevel = REACT_APP_ENVIRONMENT !== 'prod';
  return {
    subDomain:
      domain === 'finmark' && !FINMARK_SUBS.includes(subDomain)
        ? subDomain
        : null,
    resourceHost: isLowerLevel ? 'stg-resources' : 'resources',
  };
};

/**
 * Performs a natural sort comparison on the given values, alphabetical but
 * treating numbers within the string atomically, e.g: Employee 1, Employee 2,
 * Employee 11.
 *
 * @param {any} a
 * @param {any} b
 * @returns {number}
 */
export const naturalSortComparator = (a, b) => {
  if (isEmptyOrNull(a)) return isEmptyOrNull(b) ? 0 : -1;
  if (isEmptyOrNull(b)) return 1;

  if (Number.isFinite(a) && Number.isFinite(b)) return a - b;

  return a.toString().localeCompare(b, undefined, { numeric: true });
};

export const getNextText = (mode) => (mode === EDIT ? 'Save' : 'Add');

/**
 * Returns a value formatted as decimal places
 *
 * @param {number} precision Number of digits after decimal
 * @param {number} value Number to be formatted
 * @returns {number} Formatted value
 */
export const numberFormatter = (precision = 0, value) => {
  return new Intl.NumberFormat(
    {},
    {
      maximumFractionDigits: precision,
      minimumFractionDigits: precision,
    },
  ).format(value);
};

/**
 * Like Array.filter, but for objects. Filters properties based on key/value.
 *
 * @param {Object} obj Object to be filtered
 * @param {Function} filterFn Function which will be passed a key/value pair and
 *   returns a boolean
 * @returns {Object} Filtered object
 */
export const filterObject = (obj, filterFn) =>
  Object.fromEntries(Object.entries(obj).filter(filterFn));

/**
 * Get a given set of query params from the passed path
 *
 * @param {string} path - The path to search for the query params
 * @param {Array} queryParamKeys - (Ordered) keys to extract from the path
 * @returns {Array} (ordered) values cooresponding to the keys passed
 *   queryParamKeys
 */
export const getQueryParamsFromPath = (path) => {
  const params = {};
  if (!path) return params;

  /* eslint-disable-next-line no-unused-vars -- predates description requirement */
  const queryParamsStr = path.split('?').pop();

  const queryParams = new URLSearchParams(queryParamsStr);
  return Object.fromEntries(queryParams.entries());
};

/**
 * Checks if a string is UUID
 *
 * @param {value} - Value to check if its a UUID
 * @returns {boolean} Returns true if value is UUID else false
 */
export const isUUID = (value) => {
  return value.match(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$',
  );
};

const LAST_MONTH = new Date().setUTCDate(0);

/**
 * Returns TRUE if the given date is within the actuals period, or FALSE if it
 * is forecast.
 *
 * @param {Date | string | number} dateValue
 * @returns {boolean}
 */
export const isActualMonth = (dateValue) => {
  const month = new Date(dateValue).getTime();
  return month <= LAST_MONTH;
};

/**
 * Convert a list of strings into a comma separated string.
 *
 * @example
 *   formatListWithConjunction(['dog', 'cat', 'bird']); // 'dog, cat, and bird'
 *
 * @param {Array} list The list of strings
 * @returns {string}
 */
export const formatListWithConjunction = (
  list,
  options = { type: 'conjunction' },
) => {
  const formmater = new Intl.ListFormat([], {
    style: 'long',
    ...options,
  });
  return formmater.format(list);
};

/**
 * Guarentee the value is a number or throw an error.
 *
 * Useful in situations where TS can't statically guarantee the type of value
 * but logic dictates it should be a number.
 *
 * @example
 *   assertIsNumber(10);
 *
 * @type {(value: unknown) => asserts value is number}
 */
export const assertIsNumber = (value) => {
  if (typeof value !== 'number') throw new Error('Not a number');
};

/**
 * Convert string to title case
 *
 * @type {(value: string) => string}
 */
export const toTitleCase = (value) =>
  value
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

/**
 * Asserts that a condition is truthy, throwing an error with the specified
 * message if not.
 *
 * @param {any} condition - The condition to test.
 * @param {string} message - The error message to display if the condition is
 *   falsy.
 * @returns {void}
 * @throws {Error} If the condition is falsy.
 */
export const assert = (condition, message) => {
  if (!condition) {
    throw new Error(message);
  }
};

/**
 * Shallow compare two objects, it returns true if there is a difference else it
 * returns false
 *
 * @param {object} object1
 * @param {object} object2
 * @returns {boolean}
 */
export const shallowCompareObject = (object1, object2) => {
  if (Object.keys(object1).length !== Object.keys(object2).length) return true;
  for (const key1 in object1) {
    if (
      object1[key1] !== object2[key1] &&
      !(Number.isNaN(object2[key1]) && Number.isNaN(object1[key1]))
    ) {
      return true;
    }
  }

  return false;
};

/** @type {(arg: string) => string} */
export const toMacroCase = (string) => {
  return string.toUpperCase().replace(' ', '_');
};

/** @type {(arg: number) => boolean} */
export const isLimitCustomVariables = (customerVariablesCount) => {
  return customerVariablesCount >= FPA_LITE_CUSTOM_VARIABLES_LIMIT;
};

/** @type {(role: import('@/constants/permissions').UserRoles) => string} */
export const showUserRoleText = (role) => {
  return role === USER_ROLES.ROLE_USER ? 'Member' : role;
};

/**
 * Function to decode HTML entities
 *
 * @type {(html: string) => string}
 */
export const decodeHtml = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

/**
 * Function to sanitize input
 *
 * @type {(input: string) => string}
 */
export const sanitizeInput = (input) => {
  const decodedInput = decodeHtml(input);

  return DOMPurify.sanitize(decodedInput, {
    USE_PROFILES: {
      html: false,
      mathMl: false,
      svg: false,
      svgFilters: false,
    },
    ALLOWED_TAGS: ['#text'],
    ALLOW_UNKNOWN_PROTOCOLS: false,
  });
};
