import Api, { ApiV2 } from './Api';

/**
 * @typedef {{
 *   currency: string;
 *   companyName: string;
 *   manualAccountingSyncInProgress: boolean;
 *   manualPayrollSyncInProgress: boolean;
 *   manualRevenueSyncInProgress: boolean;
 *   accountingPlatformSyncDay: number;
 *   clearAccountingUserActualsOnSync: boolean;
 * }} CompanySettings
 */

/** @typedef {{ data: CompanySettings }} CompanySettingsResponse */

/**
 * Get a company's settings
 *
 * @type {(
 *   companyId: number,
 * ) => Promise<import('axios').AxiosResponse<CompanySettingsResponse>>}
 */
export const getCompanySettings = (companyId) => {
  return Api.get('/companies/settings', { params: { companyId } });
};

/**
 * @typedef {Omit<
 *   CompanySettingsResponse,
 *   | 'manualAccountingSyncInProgress'
 *   | 'manualPayrollSyncInProgress'
 *   | 'manualRevenueSyncInProgress'
 * >} UpdateCompanySettings
 */

/**
 * Update a company's settings
 *
 * @type {(
 *   params: UpdateCompanySettings,
 * ) => Promise<import('axios').AxiosResponse<{ data: UpdateCompanySettings }>>}
 */
export const updateCompanySettings = ({ companyId, ...params }) => {
  return Api.put('/companies/settings', params, { params: { companyId } });
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const getLoadMultiplier = (scenarioId) => {
  return Api.get('/companies/settings/load-multiplier', {
    params: {
      scenarioId,
    },
  });
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const updateLoadMultiplier = (params, scenarioId) => {
  return Api.put('/companies/settings/load-multiplier', params, {
    params: { scenarioId },
  });
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const changePassword = (params) => {
  return Api.patch('/users/settings/change/password', params);
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const getUserSettings = () => {
  return Api.get('/users/settings');
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const updateUserSettings = (params) => {
  return Api.put('/users/settings', params);
};

/**
 * @typedef {{
 *   type: import('@/types/services/backend').IntegrationStatus['type'];
 *   systemType: import('@/constants/integrations').IntegrationFamily;
 *   connected: boolean;
 *   status: 'REFRESH_TOKEN_ACTIVE' | 'REFRESH_TOKEN_EXPIRED';
 *   externalCompanyId: string;
 *   lastUpdatedAt: string;
 *   externalSyncStatus: string;
 *   hideIfNotConnected?: boolean;
 * }} IntegrationStatus
 */

/**
 * @typedef {{
 *   data: IntegrationStatus[];
 * }} IntegrationStatuses
 */

/**
 * Get statuses for a company's 3rd party integrations
 *
 * @type {(
 *   companyId: number,
 * ) => Promise<import('axios').AxiosResponse<IntegrationStatuses>>}
 */
export const getIntegrationsStatus = (companyId) => {
  return Api.get(`/companies/integration/status`, { params: { companyId } });
};

/**
 * @typedef {{
 *   companyId: string;
 *   systemType: import('@/constants/integrations').IntegrationFamily;
 * }} SyncIntegrationPostBody
 */

/**
 * Send company integration id to refresh an expense integration
 *
 * @type {(
 *   postBody: SyncIntegrationPostBody,
 *   requestParams: { platformCompanyId: number },
 * ) => Promise<import('axios').AxiosResponse<IntegrationStatuses>>}
 */
export const syncIntegration = (postBody, { platformCompanyId }) => {
  return ApiV2.post(`/runs/incremental`, postBody, {
    params: { companyId: platformCompanyId },
  });
};

/**
 * addMember is a function which takes one value i.e object.
 *
 * - email: string
 * - hasEmployeeAccess: boolean
 * - role : string
 * - scenarios : array It is used to call the POST API to invite a user to a
 *   company.
 *
 * @example
 *   addMember({
 *       'abc@gmail.com',
 *       true,
 *       'ROLE_USER',
 *       scenarios: [{
 *         scenarioid: 1,
 *         scenarioRole: 'DASHBOARD_ONLY'
 *     }]);
 *
 * @param {Object} params
 * @param {string} params.email - Email of invitee
 * @param {boolean} params.hasEmployeeAccess - Access to view employee data
 * @param {string} params.role - Company role for invitee
 * @returns {Array} - Contains scenarioId and scenarioRole for each scenario
 */
export const addMember = (params) => {
  return Api.post('/user-management/invite/share/scenario', params);
};

/**
 * Cancel a user's invite to join a company
 *
 * @param {number} param.companyId - The company ID
 * @param {number} param.scenarioId - The scenario ID
 * @param {string} param.email - The email address for the user whose invite to
 *   be cancelled
 * @returns {Promise} API response
 */
export const cancelExpiredInvite = ({ companyId, scenarioId, email }) =>
  Api.delete('/user-management/invite', {
    params: { companyId, scenarioId },
    data: { email },
  });

/**
 * getMembers is a function will bring member list from API.
 *
 * @example
 *   {
 *      data: {
 *      {
 *         companyUsers: [{
 *           email: "foobar@finmark.com"
 *           id: 1
 *           isExpiredInvitation: false
 *           isPendingInvitation: false
 *           name: "Foo bar"
 *           permission: "User"
 *        }]
 *        total: 1
 *      },
 *   }
 *
 * @returns {Object}
 */
export const getMembers = () => {
  return Api.get('user-management/company/users');
};

/**
 * Remove member form the company.
 *
 * @example
 *   removeMember(100);
 *
 * @param {number} id - id of a user
 * @returns {Promise} API response
 */
export const removeMember = (id) => {
  return Api.delete(`user-management/company/users/${id}`);
};

/**
 * disableMemberInvite is a function to disable member invite.
 *
 * @example
 *   disableMemberInvite({ email: 'foo@gmail.com' });
 *
 * @param {Object} payload { email: 'foo@gmail.com' } - email of user in object
 * @returns {Promise} API response
 */
export const disableMemberInvite = (payload) => {
  return Api.put('/user-management/disable-invite', { ...payload });
};
/**
 * editMember is a function which takes one value i.e object.
 *
 * - id: number
 * - email: string
 * - role : string
 * - scenarios : array It is used to call the PUT API to edit access of a user.
 *
 * @example
 *   editMember({
 *      1,
 *       'abc@gmail.com',
 *       'ROLE_USER',
 *       scenarios: [{
 *         scenarioid: 1,
 *         scenarioRole: 'DASHBOARD_ONLY'
 *     }]);
 *
 * @param {Object} params
 * @param {number} params.id - id of invitee
 * @param {string} params.email - Email of invitee
 * @param {string} params.role - Company role for invitee
 * @returns {Array} - Contains scenarioId and scenarioRole for each scenario
 */
export const editMember = (params) => {
  return Api.put(`/user-management/${params.id}`, params);
};

/**
 * getMemberInfo is a function to fetch a user detail about his company and
 * scenario roles.
 *
 * - id: number
 *
 * @example
 *   editMember(1);
 *
 * @param {number} id - id of a user whose data needs to be edited
 * @returns {Promise} API response
 */
export const getMemberInfo = (id) => {
  return Api.get(`/user-management/company/users/${id}`);
};

/**
 * A function to POST data to the Hubspot referral API
 *
 * @example
 *   sendHubspotReferral({
 *    firstName:'XYZ',
 *    lastName:'XYZ',
 *    email:'xyz@gmail.com',
 *    referredBy:'ABC'
 *    referredByEmail:'abc@gmail.com'
 *     });
 *
 * @param {Object} params
 * @param {string} params.firstName - first name of person being referred
 * @param {string} params.lastName - lastName name of person being referred
 * @param {string} params.email - email of person being referred
 * @param {string} params.referredBy - full name of person submitting the
 *   referral
 * @param {string} params.referredEmail - email of person submitting the
 *   referral
 * @returns {Promise} - API response
 */
export const sendHubspotReferral = (params) => {
  return Api.post('/hubspot/referral', params);
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const getPaymentPlans = () => {
  return Api.get('/subscriptions/payment-plans');
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const updatePaymentPlan = (selectedPaymentPlanId) => {
  return Api.put(
    '/subscriptions/payment-plans/selected',
    selectedPaymentPlanId,
  );
};

/**
 * Send successful integration and company information to v2 API on redirect to
 * Settings page
 *
 * @param {Object} params - The POST params required to link an integration
 * @param {number} params.platformCompanyId - Finmark generated comapany ID
 * @param {string} params.integrationId - Codat generated ID, retreived from url
 *   params on successful redirect
 * @param {number} params.statusCode - Status code of Codat integration login,
 *   retrieved from url params on successful redirect
 * @returns {Promise} API response
 */
export const authorizeIntegration = (params) => {
  return ApiV2.post('/setup/add-company-integration', params);
};
/**
 * Add custom department
 *
 * @param {Object} data - The POST params required to create a custom department
 * @param {number} data.expenseClassId - The expense class id
 * @param {string} data.name - Custom department name
 * @param {number} companyId ID of the company to which the department should be
 *   added
 * @returns {Promise} API response
 */
export const addCustomDepartment = (data, companyId) => {
  return Api.post('/department', data, {
    params: { companyId },
  });
};

/**
 * Edit an existing custom department
 *
 * @param {Object} data - Properties of the custom department
 * @param {number} data.expenseClassId - Expense class Id
 * @param {number} data.id - Department to edit
 * @param {string} data.name - Custom department name
 * @param {number} companyId - The companyId
 * @returns {Promise} API response
 */
export const editCustomDepartment = (data, companyId) => {
  return Api.put('/department', data, {
    params: { companyId },
  });
};

/**
 * Delete Custom Department
 *
 * @param {number} companyId - ID of the company containing the custom
 *   department
 * @param {number} id - ID of the expense that is being deleted
 * @returns {Promise} API response
 */
export const deleteCustomDepartment = (companyId, id) => {
  return Api.delete(`/department/${id}`, { params: { companyId } });
};

/**
 * getCollections is a function which will bring collection terms list from API.
 *
 * @param {number} companyId - ID of the selected company
 * @param {string} scenarioId - The scenario to get list of
 * @returns {Promise} API response
 */
export const getCollectionTerms = (companyId, scenarioId) => {
  return Api.get(`/companies/${companyId}/collection-terms`, {
    params: { scenarioId },
  });
};

/**
 * Delete Collection Term
 *
 * @param {number} companyId - ID of the company containing the collection term
 * @param {string} scenarioId - The scenario to get list of
 * @param {number} id - ID of the colection term that is being deleted
 * @returns {Promise} API response
 */
export const deleteCollectionTerm = (companyId, id, scenarioId) => {
  return Api.delete(`/companies/${companyId}/collection-term/${id}`, {
    params: { scenarioId },
  });
};

/**
 * addCollectionTerm is a function add collection term
 *
 * @param {Object} data - Request of the API
 * @param {number} companyId - ID of the selected company
 * @param {string} scenarioId - The scenario to get list of
 * @returns {Promise} API response
 */
export const addCollectionTerm = (data, companyId, scenarioId) => {
  return Api.post(`/companies/${companyId}/collection-term`, data, {
    params: { scenarioId },
  });
};

/**
 * updateCollectionTerm is a function to update collection term
 *
 * @param {Object} data - Request of the API
 * @param {number} companyId - ID of the selected company
 * @param {string} scenarioId - The scenario to get list of
 * @returns {Promise} API response
 */
export const updateCollectionTerm = (data, companyId, scenarioId) => {
  return Api.put(`/companies/${companyId}/collection-term`, data, {
    params: { scenarioId },
  });
};

/**
 * getPayments is a function which will bring payment terms list from API.
 *
 * @param {number} companyId - ID of the selected company
 * @param {string} scenarioId - The scenario to get list of
 * @returns {Promise} API response
 */
export const getPaymentTerms = (companyId, scenarioId) => {
  return Api.get(`/companies/${companyId}/payment-terms`, {
    params: { scenarioId },
  });
};

/**
 * addPaymentTerm is a function add collection term
 *
 * @param {Object} data - Request of the API
 * @param {number} companyId - ID of the selected company
 * @param {string} scenarioId - The scenario to get list of
 * @returns {Promise} API response
 */
export const addPaymentTerm = (data, companyId, scenarioId) => {
  return Api.post(`/companies/${companyId}/payment-term`, data, {
    params: { scenarioId },
  });
};

/**
 * updatePaymentTerm is a function to update collection term
 *
 * @param {Object} data - Request of the API
 * @param {number} companyId - ID of the selected company
 * @param {string} scenarioId - The scenario to get list of
 * @returns {Promise} API response
 */
export const updatePaymentTerm = (data, companyId, scenarioId) => {
  return Api.put(`/companies/${companyId}/payment-term`, data, {
    params: { scenarioId },
  });
};

/**
 * Delete Payment Term
 *
 * @param {number} companyId - ID of the company containing the payment term
 * @param {string} scenarioId - The scenario to get list of
 * @param {number} id - ID of the colection term that is being deleted
 * @returns {Promise} API response
 */
export const deletePaymentTerm = (companyId, id, scenarioId) => {
  return Api.delete(`/companies/${companyId}/payment-term/${id}`, {
    params: { scenarioId },
  });
};

/**
 * @typedef {{
 *   id: string;
 *   companyId: number;
 *   companyName: string;
 *   website?: string;
 *   phoneNumber?: string;
 *   createdDate: string;
 *   modifiedDate: string;
 *   address: null | import('@/components/Settings/Address/types').Address;
 *   industryId: string;
 * }} CompanyMetadata
 */

/**
 * Get Company Details
 *
 * @param {number} companyId - the Id of the company whose details we're
 *   fetching
 * @returns {Promise} API response
 */
export const getCompanyDetails = (companyId = undefined) => {
  /** @type {import('axios').AxiosRequestConfig} */
  const options = companyId ? { params: { companyId } } : {};
  return ApiV2.get(`/companyMetadata`, options);
};

/**
 * Save Company Details
 *
 * @param {Object} payload - Payload of company details
 * @returns {Promise} API response
 */
export const saveCompanyDetails = (payload) => {
  return ApiV2.post(`/companyMetadata`, payload, {
    params: { companyId: payload.companyId },
  });
};

/** @typedef {import('src/components/Settings/Address/types').AddressBase} ValidateCompanyAddressParams */

/**
 * @typedef {{
 *   data: import('src/components/Settings/Address/types').AddressUs &
 *     import('src/components/Settings/Address/types').AddressInternational;
 * }} ValidateCompanyAddressResponse
 */

/**
 * Validate Company Address
 *
 * @type {(
 *   payload: ValidateCompanyAddressParams,
 * ) => Promise<import('axios').AxiosResponse<ValidateCompanyAddressResponse>>}
 */
export const validateCompanyAddress = (payload) => {
  return ApiV2.post(`/address`, payload);
};

/**
 * Get Industry Codes
 *
 * @returns {Promise} API response
 */
export const getIndustryCodes = () => {
  /** @type {import('axios').AxiosRequestConfig} */
  return ApiV2.get(`/companyMetadata/industryCodes`);
};

/**
 * @typedef {{
 *   isInformationMissing: boolean;
 *   isLocked: boolean;
 * }} CompanyMetadataStatusResponse
 */

/**
 * Get Metadata Company Status
 *
 * @type {() => Promise<
 *   import('axios').AxiosResponse<{ data: CompanyMetadataStatusResponse }>
 * >}
 */
export const getCompanyMetadataStatus = () => {
  return ApiV2.get('/companyMetadata/status');
};

/**
 * Updates user roles in bulk
 *
 * @type {(args: {
 *   data: import('@/components/BillOnboarding/SetupRolesAndPermissions').UserPermissionPayload[];
 *   scenarioId: number;
 * }) => Promise<import('axios').AxiosResponse>}
 */
export const setRolesAndPermissions = ({ data, scenarioId }) => {
  return Api.post(`/user-management/role-assignment`, data, {
    params: { scenarioId },
  });
};
