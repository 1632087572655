/** @typedef {{ action: string; subject: string }} Ability */

/**
 * setupAbility is a utility function to parse permissions received from
 * backend.
 *
 * @example
 *   const permissions = setupAbility([
 *     'ReadWrite.CompanySettings',
 *     'ReadWrite.LoadMultiplier',
 *   ]);
 *
 * @param {string[]} permissions - An array of permissions, e.g.
 *   ["ReadWrite.CompanySettings", "ReadWrite.LoadMultiplier"]
 * @returns {Ability[]} The permissions as an object, with an action and the
 *   subject to be acted upon
 */
export const setupAbility = (permissions) => {
  if (!permissions) return null;

  return permissions.map((permission) => {
    const [action, subject] = permission.split('.');
    return {
      action,
      subject,
    };
  });
};

/**
 * Parses the permissions for the given scenarios
 *
 * @param {Object} scenarios A map of the user's scenario permissions from the
 *   login API
 * @returns {Object} The same map with the permissions parsed by subject and
 *   action
 */
export const parseScenarioPermissions = (scenarios) => {
  const scenarioPermissionArray = Object.entries(scenarios);
  if (!scenarioPermissionArray.length) return null;

  return scenarioPermissionArray.reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: { ...value, permissions: setupAbility(value.permissions) },
    }),
    {},
  );
};
