import {
  SET_CITY,
  SET_STREET_ADDRESS_PRIMARY,
  SET_COUNTRY,
  SET_ZIP_CODE,
  SET_STREET_ADDRESS_SECONDARY,
  SET_ADDRESS,
  US_CODE,
} from './constants';

/**
 * Reducer function for managing address state.
 *
 * @type {(
 *   state: import('./types').Address,
 *   action: { type: string; payload: import('./types').Address },
 * ) => import('./types').Address}
 */
const addressReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case SET_ADDRESS: {
      return {
        ...action.payload,
        zipCode: action.payload.isInternational
          ? action.payload.internationalZip?.toString()
          : action.payload.zipFiveDigit?.toString(),
      };
    }
    case SET_STREET_ADDRESS_PRIMARY: {
      return {
        ...state,
        street: action.payload,
      };
    }
    case SET_STREET_ADDRESS_SECONDARY: {
      return {
        ...state,
        streetLineTwo: action.payload,
      };
    }
    case SET_ZIP_CODE: {
      return {
        ...state,
        zipCode: action.payload,
      };
    }
    case SET_COUNTRY: {
      const isInternational = action.payload !== US_CODE;
      return {
        ...state,
        country: action.payload,
        isInternational,
      };
    }
    case SET_CITY: {
      return {
        ...state,
        city: action.payload,
      };
    }
    default:
      return state;
  }
};

export default addressReducer;
