// @ts-check
import { useQuery } from '@tanstack/react-query';
import { COMPANY_DETAILS } from '@/cacheKeys';
import useIsAdminUser from '@/hooks/useIsAdminUser';
import { getCompanyDetails } from '@/services/settingsService';

/**
 * A custom hook to fetch company details
 *
 * @type {(
 *   companyId?: number,
 * ) => import('@tanstack/react-query').UseQueryResult<
 *   import('src/services/settingsService').CompanyMetadata
 * >}
 */
const useCompanyDetailsQuery = (companyId) => {
  const isAdmin = useIsAdminUser();
  return useQuery(
    [COMPANY_DETAILS, companyId],
    async () => {
      const { data } = await getCompanyDetails(companyId);
      return data.data;
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: !!companyId && isAdmin,
    },
  );
};

export default useCompanyDetailsQuery;
