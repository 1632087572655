// @ts-check
import { forwardRef, useCallback, useMemo } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-enterprise';
import PropTypes from 'prop-types';
import { getColumnLimit } from '@/components/Reports/helpers';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';
import {
  exportWithForecastIndicators,
  getMonthlyHeaderNameByTimePeriod,
} from '@/components/common/MonthlySpreadsheet/helpers';
import MonthColumnHeaderGroupRenderer from '@/components/common/MonthlySpreadsheet/renderers/MonthColumnHeaderGroupRenderer';
import Spreadsheet from '@/components/common/Spreadsheet';
import WithEditing from '@/components/common/Spreadsheet/WithEditing';
import {
  EMPTY_CELL_VALUE,
  READ_ONLY_CELL_WIDTH,
  ROW_PINNED_BOTTOM,
} from '@/components/common/Spreadsheet/constants';
import spreadsheetDefaults, {
  isEditable,
} from '@/components/common/Spreadsheet/defaults';
import FormulaEditor from '@/components/common/Spreadsheet/editors/FormulaEditor';
import NumericEditor from '@/components/common/Spreadsheet/editors/NumericEditor';
import { cellValueFormatter } from '@/components/common/Spreadsheet/helpers';
import { actualsFamily } from '@/constants/actuals';
import { MONTHLY } from '@/constants/dateTime';
import { ERROR_BOUNDARY_TEXT } from '@/constants/tables';
import { classNames, numberFormatter } from '@/helpers';
import formatMonetary from '@/helpers/formatMonetary';
import { isEmptyOrNull } from '@/helpers/validators';
import { zeroFilter } from '@/helpers/zeroFilter';
import useTypedSelector from '@/hooks/useTypedSelector';
import getScenarioById from '@/selectors/getScenarioById';
import './MonthlySpreadsheet.scss';

/** @typedef {import('ag-grid-community').ColDef} ColDef */

/**
 * @type {(
 *   event: import('ag-grid-community').CellFocusedEvent<any>,
 * ) => void}
 */
function handleCellFocused({ api, column, rowIndex, rowPinned }) {
  if (
    typeof column !== 'string' &&
    column?.getColDef().type !== 'monthlyValue'
  ) {
    return;
  }

  // Force refresh the cell on focus/blur so that it selects
  // the appropriate renderer
  const row =
    rowPinned === ROW_PINNED_BOTTOM
      ? api.getPinnedBottomRow(rowIndex)
      : api.getDisplayedRowAtIndex(rowIndex);
  const refreshCell = () => {
    if (row) {
      api.refreshCells({
        columns: [column],
        rowNodes: [row],
        force: true,
      });
    }
  };
  document.activeElement.addEventListener(
    'blur',
    () => setTimeout(refreshCell, 0),
    {
      once: true,
    },
  );
  refreshCell();
}

/** @type {import('ag-grid-community').ValueFormatterFunc} */
const defaultValueFormatter = ({ value }) =>
  !isEmptyOrNull(value) ? formatMonetary(value) : null;

const handleCopy = ({ value }) =>
  typeof value === 'object' ? value?.value : value;
const handlePaste = ({ value }) => value.replace(/[^\d-.]+/g, '');

const QUARTERLY_ANNUALLY_PASTE_FAIL_ERROR =
  'Please switch to a monthly view to update values.';

/**
 * Renders a spreadsheet for editing the given data broken down by each month in
 * the current date range
 *
 * @example
 *   <MonthlySpreadsheet
 *     columnDefs={additionalColDefs}
 *     data={data}
 *     data-testid="foo"
 *     onMonthValueChange={({ newValue }) => doSomething(newValue)}
 *   />;
 *
 * @type {React.ForwardRefExoticComponent<any>}
 */
const MonthlySpreadsheet = forwardRef(
  (
    {
      cellClassRules,
      className,
      columnDefs,
      comparator,
      data,
      'data-testid': dataTestId,
      editable,
      lockVisible = true,
      editor = NumericEditor,
      editorParams,
      enableComparison = false,
      filterValueGetter,
      loading,
      onMonthValueChange,
      processCellForClipboard,
      processCellFromClipboard,
      renderer,
      rendererParams,
      rendererSelector,
      valueFormatter = cellValueFormatter,
      valueGetter,
      filterParams,
      showVarianceAmount,
      showVariancePercentage,
      'pasteFailError': failedPasteError,
      cellStyle,
      cellClass,
      showForecastIndicator = true,
      showFilter = true,
      isInteractive = true,
      excelExportParams,
      onCellKeyDown,
      ...props
    },
    ref,
  ) => {
    const baseScenario = useTypedSelector(({ scenario }) => {
      return getScenarioById(scenario.scenarioId, { scenario });
    });
    const compareScenario = useTypedSelector(({ scenario }) => {
      return getScenarioById(scenario.compareScenarioId, { scenario });
    });
    const startDate = useTypedSelector(({ shared }) => {
      return shared.startDate;
    });
    const endDate = useTypedSelector(({ shared }) => {
      return shared.endDate;
    });
    const timePeriod = useTypedSelector(({ shared }) => {
      return shared.timePeriod;
    });
    const companySettings = useTypedSelector(
      ({ settings }) => settings.companySettings,
    );
    const hasComparison =
      enableComparison && !!compareScenario && compareScenario !== baseScenario;

    const pasteFailError =
      failedPasteError ?? timePeriod !== MONTHLY
        ? QUARTERLY_ANNUALLY_PASTE_FAIL_ERROR
        : undefined;

    /** @type {import('ag-grid-community').ColGroupDef[]} */
    const monthColDefs = useMemo(() => {
      if (!baseScenario) return [];

      const colWidth = hasComparison ? 144 : 113;

      /** @type {Partial<ColDef>} */
      const colDefaults = {
        filterParams,
        filterValueGetter,
        type: 'monthlyValue',
        floatingFilterComponentParams: { enableComparison },
        valueFormatter: valueFormatter ?? defaultValueFormatter,
        width: isInteractive ? colWidth : READ_ONLY_CELL_WIDTH,
        minWidth: isInteractive ? colWidth : READ_ONLY_CELL_WIDTH,
        cellEditorSelector: (params) => {
          if (params.data.family === actualsFamily.EXPENSE) {
            return {
              component: FormulaEditor,
            };
          }

          return undefined;
        },
      };

      /** @type {(month: string, scenario: Object) => ColDef} */
      const getScenarioColDef = (month, scenario) => {
        const { scenarioId } = scenario;

        return {
          ...colDefaults,
          ...(isInteractive && { cellClassRules }),
          cellEditor: editor,
          cellEditorParams: editorParams,
          cellRenderer: renderer,
          cellRendererParams: rendererParams,
          cellRendererSelector: isInteractive ? rendererSelector : undefined,
          colId: `${month}_${scenarioId}`,
          comparator,
          editable: editable ?? true,
          field: `${scenarioId}`,
          headerName: scenario.name,
          onCellValueChanged: onMonthValueChange,
          lockVisible,
          valueGetter,
          cellStyle,
          cellClass,
        };
      };

      return getMonthlyHeaderNameByTimePeriod({
        startDate,
        endDate,
        timePeriod,
        showForecastIndicator,
        companySettings,
      }).map(({ month, headerName, indicator }, idx) => {
        const monthScenarioCols = [getScenarioColDef(month, baseScenario)];
        if (hasComparison) {
          monthScenarioCols.push(getScenarioColDef(month, compareScenario));
          if (showVarianceAmount) {
            monthScenarioCols.push({
              ...colDefaults,
              colId: `${month}-variance`,
              headerName: 'Variance Amount',
              valueGetter: ({ data: rowData }) => rowData.months[idx]?.variance,
              lockVisible,
            });
          }

          if (showVariancePercentage) {
            monthScenarioCols.push({
              ...colDefaults,
              colId: `${month}-variance`,
              headerName: 'Variance Percentage',
              valueFormatter: ({ data: rowData }) =>
                !isEmptyOrNull(rowData.months[idx]?.variancePercentage)
                  ? `${numberFormatter(
                      1,
                      rowData.months[idx]?.variancePercentage,
                    )}%`
                  : EMPTY_CELL_VALUE,
              valueGetter: ({ data: rowData }) =>
                rowData.months[idx]?.variancePercentage,
            });
          }
        }

        return {
          groupId: month,
          headerGroupComponent: MonthColumnHeaderGroupRenderer,
          headerGroupComponentParams: {
            headerName,
            indicator,
          },
          headerName,
          headerClass: (context) =>
            classNames(
              'Table_ColHead',
              'Table_ColHead-date',
              !isEditable(
                /** @type {import('ag-grid-community').EditableCallbackParams} */ (
                  context
                ),
              ) && 'Table_ColHead-readOnly',
            ),
          children: monthScenarioCols,
        };
      });
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    }, [
      baseScenario,
      hasComparison,
      filterParams,
      filterValueGetter,
      enableComparison,
      valueFormatter,
      valueGetter,
      startDate,
      endDate,
      timePeriod,
      editor,
      editorParams,
      renderer,
      rendererSelector,
      comparator,
      cellClassRules,
      cellStyle,
      cellClass,
      rendererParams,
      onMonthValueChange,
      compareScenario,
      showVarianceAmount,
      showVariancePercentage,
      showForecastIndicator,
    ]);

    const mergedColDefs = useMemo(
      () => [...columnDefs, ...monthColDefs],
      [columnDefs, monthColDefs],
    );

    const defaultColDef = useMemo(() => {
      return {
        ...spreadsheetDefaults().defaultColDef,
        pinned: 'left',
        suppressMovable: true,
      };
    }, []);

    /**
     * @type {(
     *   params: import('ag-grid-community').RowNode<
     *     import('@/helpers/zeroFilter').MonthlySpreadsheetData
     *   >,
     * ) => boolean}
     */
    const passesZeroFilter = useCallback(
      ({ data: monthlyData }) => zeroFilter(monthlyData),
      [],
    );

    const monthlyExcelExportParams = useMemo(() => {
      /** @type {import('ag-grid-community').ExcelExportParams} */
      const exportParams = {
        ...(showForecastIndicator
          ? { processGroupHeaderCallback: exportWithForecastIndicators }
          : {}),
        ...excelExportParams,
        ...(excelExportParams?.columnKeys
          ? {
              columnKeys: excelExportParams.columnKeys.concat(
                monthColDefs.map((monthColDef) => {
                  /** @type {ColDef} */
                  const colDef = monthColDef.children[0];
                  return colDef.colId;
                }),
              ),
            }
          : {}),
      };
      return exportParams;
    }, [showForecastIndicator, excelExportParams, monthColDefs]);

    const colDefsWithMonths = useMemo(() => {
      if (isInteractive) return mergedColDefs;
      const { truncated, limit } = getColumnLimit({
        startDate,
        endDate,
        timePeriod,
        hasComparison,
        showVariance: showVarianceAmount,
      });
      if (truncated) {
        return mergedColDefs.slice(
          0,
          limit + 1, // We want to skip the 1st column which is usually title/name column
        );
      }
      return mergedColDefs;
    }, [
      mergedColDefs,
      isInteractive,
      startDate,
      endDate,
      timePeriod,
      hasComparison,
      showVarianceAmount,
    ]);

    return (
      <CommonErrorBoundary text={ERROR_BOUNDARY_TEXT}>
        <WithEditing
          columnDefs={colDefsWithMonths}
          enabled={editable}
          pasteFailError={pasteFailError}
        >
          {({ handleCellLoading, ...editingHandlers }) => (
            <Spreadsheet
              className="Spreadsheet-monthly"
              defaultColDef={defaultColDef}
              ref={ref}
              data={data}
              data-testid={dataTestId}
              headerHeight={0}
              loading={loading || !baseScenario}
              onCellFocused={handleCellFocused}
              onCellValueChanged={handleCellLoading}
              passesZeroFilter={passesZeroFilter}
              processCellForClipboard={processCellForClipboard ?? handleCopy}
              processCellFromClipboard={processCellFromClipboard ?? handlePaste}
              excelExportParams={monthlyExcelExportParams}
              showFilter={hasComparison || isInteractive}
              isInteractive={isInteractive}
              onCellKeyDown={onCellKeyDown}
              {...props}
              {...editingHandlers}
            />
          )}
        </WithEditing>
      </CommonErrorBoundary>
    );
  },
);

MonthlySpreadsheet.propTypes = {
  /**
   * Columns to include in addition to the selected date range. MUST BE MEMOIZED
   *
   * @see https://www.ag-grid.com/react-grid/column-definitions/
   */
  'columnDefs': PropTypes.arrayOf(PropTypes.object).isRequired,
  /**
   * Determines the sort order of values in the date columns
   *
   * @param {any} a
   * @param {any} b
   * @returns {number}
   */
  'comparator': PropTypes.func,
  /** Map of functions for applying additional classes to the spreadsheet cells */
  'cellClassRules': PropTypes.objectOf(PropTypes.func),
  /** Additional class(es) to apply to the table wrapper */
  'className': PropTypes.string,
  /** Data to populate the table */
  'data': PropTypes.arrayOf(PropTypes.object),
  /**
   * Whether the date column cells are editable. Defaults to true. MUST BE
   * MEMOIZED
   */
  'editable': PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  /**
   * Component for editing an individual date column cell. Defaults to
   * NumericEditor.
   *
   * @see https://www.ag-grid.com/react-data-grid/component-cell-editor/
   */
  'editor': PropTypes.elementType,
  /**
   * Determines whether the monthly column should be included in the show/hide
   * toggle
   */
  'lockVisible': PropTypes.bool,
  /** Additional properties to pass to the editor MUST BE MEMOIZED */
  'editorParams': PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /**
   * Whether scenario comparison is enabled / disabled on a spreadsheet. When
   * enabled, scenario comparison is handled automatically
   *
   * @default false
   */
  'enableComparison': PropTypes.bool,
  /** Additional properties to pass to the filter MUST BE MEMOIZED */
  'filterParams': PropTypes.objectOf(PropTypes.any),
  /**
   * Optional function for when a different value must be passed to the filter
   * than is displayed in the cell MUST BE MEMOIZED
   *
   * @param {ValueGetterParams} params Context for the cell
   * @returns {any} Value to pass to to filter
   */
  'filterValueGetter': PropTypes.func,
  /** Whether or not a loading indicator should be displayed */
  'loading': PropTypes.bool,
  /**
   * Event handler for when the user confirms (Enter, Tab, etc) a new value for
   * a cell in a month column MUST BE MEMOIZED
   *
   * @see https://www.ag-grid.com/react-data-grid/cell-editing/#event-cell-value-changed
   */
  'onMonthValueChange': PropTypes.func,
  /**
   * Function for parsing outgoing data to the clipboard per cell
   *
   * @param {ProcessCellForExportParams} params
   * @returns {string} value to place in the clipboard
   */
  'processCellForClipboard': PropTypes.func,
  /**
   * Function for parsing incoming data from the clipboard per cell
   *
   * @param {ProcessCellForExportParams} params
   */
  'processCellFromClipboard': PropTypes.func,
  /**
   * Component for rendering an individual date column cell
   *
   * @see https://www.ag-grid.com/react-data-grid/component-cell-renderer/
   */
  'renderer': PropTypes.elementType,
  /**
   * Function for dynamically selecting the renderer for an individual date
   * column cell MUST BE MEMOIZED
   *
   * @param {ICellRendererParams} params
   * @returns {Function} Cell renderer component
   * @see https://www.ag-grid.com/react-data-grid/cell-rendering/#many-renderers-one-column
   */
  'rendererSelector': PropTypes.func,
  /** Additional properties to pass to the cell renderer MUST BE MEMOIZED */
  'rendererParams': PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /**
   * A function for formatting date column values, defaulting to monetary MUST
   * BE MEMOIZED
   */
  'valueFormatter': PropTypes.func,
  /**
   * A function that returns a value from the row data for populating the cell
   * renderer/editor MUST BE MEMOIZED
   *
   * @param {ValueGetterParams} params
   * @returns {any} Value to pass to the cell renderer/editor
   */
  'valueGetter': PropTypes.func,
  /**
   * Used to enable / disable amount variance columns when comparing scenarios.
   * When enableComparison is enabled, the amount variance column is visible by
   * default.
   *
   * @see enableComparison
   */
  'showVarianceAmount': PropTypes.bool,

  /**
   * Used to enable / disable percentage variance columns when comparing
   * scenarios. When enableComparison is enabled, the percentage variance column
   * is not visible by default.
   *
   * @see enableComparison
   */
  'showVariancePercentage': PropTypes.bool,
  /** Function to handle the user selection of a group of cells */
  'onRangeSelectionChanged': PropTypes.func,
  /** Function returning styles of the cell */
  'cellStyle': PropTypes.func,
  /** Class to add to cell */
  'cellClass': PropTypes.string,
  /** Should the monthly header names include "Forecast" or "Actual" */
  'showForecastIndicator': PropTypes.bool,
  /**
   * Custom Excel export params. To use our application defaults, just pass
   * `showForecastIndicator` rather than using this prop
   *
   * @see https://www.ag-grid.com/javascript-data-grid/excel-export-api/#reference-export-defaultExcelExportParams
   */
  'excelExportParams': PropTypes.object,
  /**
   * callback fires when a key is pressed down on a cell. Use the event argument
   * to scope your callback to run at the appropriate time, as this callback
   * fires for every key press on the grid
   *
   * @see https://www.ag-grid.com/react-data-grid/keyboard-navigation/?#keyboard-events
   */
  'onCellKeyDown': PropTypes.func,
  /** Message to display when paste fails */
  'pasteFailError': PropTypes.string,
  /**
   * Additional property used to enable/disable all interactive elements of the
   * spreadsheet
   */
  'isInteractive': PropTypes.bool,
  /** Enables showing filter for spreadsheet columns */
  'showFilter': PropTypes.bool,
  /** used in automated tests for finding the root element of the rendered grid */
  'data-testid': PropTypes.string.isRequired,
};

export default MonthlySpreadsheet;
