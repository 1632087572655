// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import getSelectedCompany from '@/selectors/getSelectedCompany';

/**
 * Hook for obtaining the current selected company from redux store. Uses a
 * cached selector under the hood to minimize re-renders.
 *
 * @type {() => import('../reducers/companies').Company | null}
 */
const useCurrentSelectedCompany = () => {
  return useSelector(getSelectedCompany);
};

export default useCurrentSelectedCompany;
