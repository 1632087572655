import { forwardRef, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import RunwayChart from '@/pages/Dashboard/charts/RunwayChart';
import { getLabeledMetric } from '@/pages/Dashboard/helpers';

const RunwayGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [runway, setRunway] = useState();
    const { timePeriod } = useSelector(({ shared }) => shared);
    const onQueryStateChange = useCallback(
      ([{ data }]) => setRunway(data.runway),
      [],
    );

    return (
      <ChartWidget
        ref={ref}
        title="Runway"
        url="/dashboard/runway"
        data-testid="runwayWidget"
        metricValue={getLabeledMetric(runway, timePeriod)}
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI value={getLabeledMetric(runway, timePeriod)} />
            <RunwayChart
              onQueryStateChange={onQueryStateChange}
              {...childProps}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default RunwayGlance;
