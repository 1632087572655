import { useMemo } from 'react';
import useSelectedDashboard from '@/hooks/useSelectedDashboard';
import useTypedSelector from '@/hooks/useTypedSelector';

/**
 * Gets the chart toggle preference for given system chart of the selected
 * dashboard
 *
 * @param {string} chartId ID of the system chart
 * @returns {boolean | string} typically a boolean but could be a string
 *   depending on the chart
 */
function useChartPreferences(chartId) {
  const { userChartPreferences, selectedDashboardId } = useTypedSelector(
    ({ dashboard }) => dashboard,
  );
  const dash = useSelectedDashboard();
  const { id } = dash ?? {};

  return useMemo(() => {
    return userChartPreferences?.[id]?.[chartId];
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [userChartPreferences, selectedDashboardId]);
}

export default useChartPreferences;
