// @ts-check
import { useCallback, useEffect, useState } from 'react';
import CheckmarkSmallIcon from '@bill/cashflow.assets/checkmark-small';
import EditRoundIcon from '@bill/cashflow.assets/edit-round';
import {
  sectionId,
  SET_FOOTER_DISCLAIMER,
} from '@/components/Reports/ExportableReport/constants';
import FormField from '@/components/common/FormField';
import IconButton from '@/components/common/IconButton';
import useExportableReportContext from '@/contexts/useExportableReportContext';
import { sanitizeInput } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';
import './Footer.scss';

export const FOOTER_ID = 'exportable-report-footer';
export const DEFAULT_FOOTER_TEXT =
  'Insert disclaimer here or delete to leave blank.';

/**
 * @typedef {{
 *   isEditMode: boolean;
 *   setIsEditMode: (value: boolean) => void;
 * }} FooterDisclaimerProps
 */

/**
 * Input for inserting a common disclaimer on each page, in the Summary Report.
 *
 * @type {(props: FooterDisclaimerProps) => React.ReactElement}
 */
const Footer = ({ isEditMode, setIsEditMode }) => {
  const { isEditControlVisible, reportState, setReportState } =
    useExportableReportContext();
  const { footerDisclaimer } = reportState[sectionId.DISCLAIMER].content;

  const [text, setText] = useState(null);
  useEffect(() => {
    if (footerDisclaimer !== undefined) {
      setText(footerDisclaimer);
    }
  }, [footerDisclaimer]);

  /** @type {() => void} */
  const persistText = useCallback(() => {
    if (!text) return;
    const sanitizedText = sanitizeInput(text);
    setReportState({
      type: SET_FOOTER_DISCLAIMER,
      footerDisclaimer: sanitizeInput(text),
    });
    setText(sanitizedText);
  }, [text, setReportState]);
  return (
    <div className="ExportableReport_Footer">
      {isEditMode && isEditControlVisible ? (
        <FormField
          type="text"
          id={FOOTER_ID}
          data-testid={FOOTER_ID}
          value={isEmptyOrNull(text) ? '' : text}
          maxLength={180}
          onKeyDown={(event) => event.key === 'Enter' && event.preventDefault()}
          onChange={({ target }) => {
            setText(target.value);
          }}
          onBlur={persistText}
          placeholder="Add your disclaimer/description here"
        >
          <IconButton
            label="Save"
            data-testid="footer-disclaimer-save-text-button"
            className="ExportableReport_Icon ExportableReport_Icon-save"
            onClick={() => setIsEditMode(false)}
            Icon={CheckmarkSmallIcon}
          />
        </FormField>
      ) : (
        <div className="Footer_Disclaimer-flex">
          <div
            data-testid="footer-disclaimer-text"
            className="Footer_Disclaimer_Text"
          >
            {text === null && isEditControlVisible ? DEFAULT_FOOTER_TEXT : text}
          </div>
          {isEditControlVisible && (
            <IconButton
              label="Edit Disclaimer"
              data-testid="footer-disclaimer-edit-text-button"
              className="ExportableReport_Icon Disclaimer_Edit_Button"
              onClick={() => {
                persistText();
                setIsEditMode(true);
              }}
              Icon={EditRoundIcon}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Footer;
