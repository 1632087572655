import { chartKeys } from './stockChartMap';

export const CASHFLOW_LAYOUT = [
  {
    name: 'Cash Balance',
    key: chartKeys.CASH_BALANCE,
    meta: {
      x: 0,
      y: 0,
      w: 6,
      h: 2,
    },
  },
  {
    name: 'Net Cash Flow',
    key: chartKeys.NET_CASHFLOW,
    meta: {
      x: 6,
      y: 0,
      w: 6,
      h: 2,
    },
  },
  {
    name: 'Cash In',
    key: chartKeys.CASH_IN,
    meta: {
      x: 0,
      y: 2,
      w: 6,
      h: 2,
    },
  },
  {
    name: 'Cash Out',
    key: chartKeys.CASH_OUT,
    meta: {
      x: 6,
      y: 2,
      w: 6,
      h: 2,
    },
  },
];

export default [
  {
    name: 'Runway',
    key: chartKeys.RUNWAY,
    meta: {
      x: 0,
      y: 0,
      w: 6,
      h: 2,
    },
  },
  {
    name: 'Burn Rate',
    key: chartKeys.BURN_RATE,
    meta: {
      x: 6,
      y: 0,
      w: 6,
      h: 2,
    },
  },
  {
    name: 'Total Revenue',
    key: chartKeys.TOTAL_REVENUE,
    meta: {
      x: 0,
      y: 2,
      w: 6,
      h: 2,
    },
  },
  {
    name: 'New Revenue',
    key: chartKeys.NEW_REVENUE,
    meta: {
      x: 6,
      y: 2,
      w: 6,
      h: 2,
    },
  },
  {
    name: 'Total MRR',
    key: chartKeys.TOTAL_MRR,
    meta: {
      x: 0,
      y: 4,
      w: 4,
      h: 2,
    },
  },
  {
    name: 'New MRR',
    key: chartKeys.NEW_MRR,
    meta: {
      x: 4,
      y: 4,
      w: 4,
      h: 2,
    },
  },
  {
    name: 'Churned MRR',
    key: chartKeys.CHURN_MRR,
    meta: {
      x: 8,
      y: 4,
      w: 4,
      h: 2,
    },
  },
  {
    name: 'New Customers',
    key: chartKeys.NEW_CUSTOMERS,
    meta: {
      x: 0,
      y: 6,
      w: 6,
      h: 2,
    },
  },
  {
    name: 'Logo Churn',
    key: chartKeys.LOGO_CHURN,
    meta: {
      x: 6,
      y: 6,
      w: 6,
      h: 2,
    },
  },
  {
    name: 'Total Expenses',
    key: chartKeys.TOTAL_EXPENSES,
    meta: {
      x: 0,
      y: 8,
      w: 6,
      h: 2,
    },
  },
  {
    name: 'Total Payroll',
    key: chartKeys.TOTAL_PAYROLL,
    meta: {
      x: 6,
      y: 8,
      w: 6,
      h: 2,
    },
  },
  {
    name: 'Average Revenue Per Account',
    key: chartKeys.ARPA,
    meta: {
      x: 0,
      y: 10,
      w: 4,
      h: 2,
    },
  },
  {
    name: 'Total Customers',
    key: chartKeys.TOTAL_CUSTOMERS,
    meta: {
      x: 4,
      y: 10,
      w: 4,
      h: 2,
    },
  },
  {
    name: 'Gross Margin',
    key: chartKeys.GROSS_MARGIN,
    meta: {
      x: 8,
      y: 10,
      w: 4,
      h: 2,
    },
  },
  {
    name: 'Customer Lifetime Value',
    key: chartKeys.CUSTOMER_LTV,
    meta: {
      x: 0,
      y: 12,
      w: 4,
      h: 2,
    },
  },
  {
    name: 'Customer Acquisition Cost',
    key: chartKeys.CAC,
    meta: {
      x: 4,
      y: 12,
      w: 4,
      h: 2,
    },
  },
  {
    name: 'CAC Payback',
    key: chartKeys.CAC_PAYBACK,
    meta: {
      x: 8,
      y: 12,
      w: 4,
      h: 2,
    },
  },
];
