import { useMemo } from 'react';
import { DASHBOARD_PATH } from '@/constants/pages';
import useSelectedDashboard from '@/hooks/useSelectedDashboard';

/**
 * Get the path for the user's currently selected dashboard
 *
 * @returns {string} relative path to dashboard
 */
const useDashboardPath = () => {
  const selectedDashboard = useSelectedDashboard();

  return useMemo(() => {
    if (selectedDashboard?.id && !selectedDashboard.isDefault) {
      return `${DASHBOARD_PATH}/${selectedDashboard.id}`;
    }
    return DASHBOARD_PATH;
  }, [selectedDashboard]);
};

export default useDashboardPath;
