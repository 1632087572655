// @ts-check
import { useMemo } from 'react';
import CrossIcon from '@bill/cashflow.assets/cross';
import PropTypes from 'prop-types';
import {
  FormulaDef,
  FormulaDefOffsets,
  FormulaDefOperators,
} from '@/components/common/FormulaGuide/FormulaDef';
import getGuideModalConstants from '@/components/common/FormulaGuide/formulaGuideModalConstants';
import FormulaGuide from '@/components/common/FormulaGuide/index';
import Modal from '@/components/common/Modal';
import { HELP_FINMARK_BASE_URL } from '@/constants/dataMapping';

/** @typedef {import('./formulaGuideModalConstants').formulaGuideKeys} keyTypes */
/**
 * @typedef {{
 *   'formulaKey': keyTypes;
 *   'data-testid': string;
 *   'onClose': React.MouseEventHandler;
 *   'open': boolean;
 * }} FormulaGuideModalProps
 */

/** @type {(props: FormulaGuideModalProps) => React.ReactElement} */

/**
 * Displays an informative modal on how to use formulas
 *
 * @example
 *   <FormulaGuideModal
 *     formulaKey="formula-key"
 *     open={isOpen}
 *     data-testid="formula-guide"
 *     onClose={() => setOpen(false)}
 *   />;
 */
function FormulaGuideModal({
  formulaKey,
  'data-testid': dataTestId,
  onClose,
  open,
}) {
  const {
    mainText,
    subText,
    formula,
    formulaBreakdown,
    formulaDef,
    additionalSection,
  } = useMemo(() => getGuideModalConstants(formulaKey), [formulaKey]);
  return (
    <Modal
      open={open}
      className="Modal-help"
      data-testid={dataTestId}
      onClose={onClose}
    >
      <button
        type="button"
        className="Modal_CloseBtn"
        data-testid={`${dataTestId}-closeBtn`}
        onClick={onClose}
      >
        <CrossIcon aria-label="Close" />
      </button>
      <h3 className="ModalBase_Heading">Formula Guide</h3>
      <h4 className="ModalBase_SubHeading">Scenario</h4>
      <p>
        {mainText} Click{' '}
        <a
          className="link"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="formulaGuideLink"
          href={`${HELP_FINMARK_BASE_URL}/en/articles/5599107-how-to-build-a-formula-in-finmark`}
        >
          here
        </a>{' '}
        for more information.
      </p>
      <h4 className="ModalBase_SubHeading">Formula</h4>
      <code className="FormulaGuideModal_Formula">{formula}</code>
      <p>{subText || 'OK, so let’s break this down...'}</p>
      <code className="FormulaBreakdown">{formulaBreakdown}</code>
      {formulaDef ? (
        <dl className="FormulaDefs">
          <FormulaDef className="FormulaDefs_Term-var" term="Variables">
            {formulaDef}
          </FormulaDef>
          <FormulaDefOperators />
          <FormulaDefOffsets />
          {additionalSection}
        </dl>
      ) : (
        <FormulaGuide.Defs />
      )}
    </Modal>
  );
}
FormulaGuideModal.propTypes = {
  /** Unique key for selecting the content for the formula guide modal */
  'formulaKey': PropTypes.string.isRequired,
  /** Unique ID for selecting the modal in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Event handler for when the user closes the modal */
  'onClose': PropTypes.func.isRequired,
  /** Whether or not the modal is open */
  'open': PropTypes.bool,
};

export default FormulaGuideModal;
