import { useState } from 'react';
import ColorPicker from '@/components/common/ColorPicker';
import WithPopover from '@/components/common/WithPopover';
import { CHART_COLORS } from '@/constants/charts';
import { classNames } from '@/helpers';
import './SecondaryMetricColorSelector.scss';

const SecondaryMetricColorSelector = ({
  color,
  'data-testid': dataTestId,
  onColorSelect,
  disabled,
  Icon,
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);

  return (
    <div
      className={classNames(
        'SecondaryMetricColor',
        disabled && 'SecondaryMetricColor-disabled',
      )}
      data-testid={dataTestId}
    >
      <Icon
        style={{ color }}
        className="SecondaryMetricColor_Icon"
        aria-label="SecondaryMetricIcon"
      />
      <WithPopover
        content={
          <ColorPicker
            className="ColorPicker-popover"
            colors={CHART_COLORS}
            selectedColor={color}
            onClick={(selectedColor) => {
              onColorSelect(selectedColor);
              setShowColorPicker(false);
            }}
          />
        }
        data-testid={`${dataTestId}-colorPicker`}
        onClose={() => setShowColorPicker(false)}
        visible={showColorPicker}
      >
        <button
          className="DataSourceSelected_Color"
          aria-label="Select Color"
          style={{ backgroundColor: color }}
          onClick={() => setShowColorPicker(!showColorPicker)}
        />
      </WithPopover>
    </div>
  );
};

export default SecondaryMetricColorSelector;
