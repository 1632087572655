import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { verifyScenarioAction } from '@/actions/signUp';
import ErrorMessages from '@/components/common/ErrorMessages';
import Loader from '@/components/common/Loader';
import Landing from '@/containers/Landing';

const VerifyScenario = ({ verificationToken, verifyScenario }) => {
  const {
    userId,
    companyId,
    verificationToken: verificationTokenParam,
  } = useParams();
  const [error, setError] = useState();

  useEffect(() => {
    /**
     * This function is responsible for redirection based on the invitation
     * token, it covers 3 cases:
     *
     * 1. Redirection to Create Password Page
     * 2. Redirection to Dashboard if the user is logged in and invitation is
     *    already accepted
     * 3. Redirection to Login screen with error if token has expired
     */
    async function verify() {
      try {
        await verifyScenario(userId, companyId, verificationTokenParam);
      } catch ({ message }) {
        setError(message);
      }
    }
    verify();
  }, [companyId, userId, verificationTokenParam, verifyScenario]);

  if (verificationToken) {
    return <Redirect to={{ pathname: '/create-account' }} />;
  }

  return (
    <Landing>
      {error ? (
        <div className="landing-Form">
          <h1 className="Panel_Title">Invitation Expired</h1>
          <ErrorMessages error={error} data-testid="SignInForm-errors" />
        </div>
      ) : (
        <div className="VerifyScenario-Container">
          <Loader />
        </div>
      )}
    </Landing>
  );
};

const mapStateToProps = ({ auth }) => ({
  verificationToken: auth.verificationToken,
});

export default connect(mapStateToProps, {
  verifyScenario: verifyScenarioAction,
})(VerifyScenario);
