import { useLocation } from 'react-router-dom';

/**
 * A hook for grabbing query params from a React Router Route.
 *
 * @example
 *   const queryParams = useQueryParams();
 *   queryParams.get('someParam');
 *
 * @see https://reactrouter.com/web/example/query-parameters
 */
const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export default useQueryParams;
