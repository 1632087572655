import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import ErrorMessages from '@/components/common/ErrorMessages';
import Select from '@/components/common/Select';
import { Currencies } from '@/constants/currencies';
import { isEmptyOrNull } from '@/helpers/validators';
import CardWrapper from './CardWrapper';
import Footer from './Footer';
import Header from './Header';

const Currency = ({ companyCurrency, updateCompanyAction, error, loading }) => {
  const [mode, setMode] = useState('read');

  const [currency, setCurrency] = useState();

  const [errorMessage, setErrorMessage] = useState(error);

  useEffect(() => {
    setCurrency(companyCurrency);
  }, [companyCurrency]);

  useEffect(() => {
    if (!error && !loading) {
      setMode('read');
    }

    setErrorMessage(error);
  }, [loading, error]);

  const handleOnSaveClick = async () => {
    updateCompanyAction({
      currency,
    });
  };

  const handleOnCancelClick = () => {
    setCurrency(companyCurrency);
    setMode('read');

    setErrorMessage(null);
  };
  return (
    <CardWrapper>
      <Header
        mode={mode}
        setMode={setMode}
        title="Currency"
        data-testid="currency-edit"
      />
      <Form className="pt-4">
        {mode === 'edit' && errorMessage && (
          <ErrorMessages success={null} error={errorMessage} />
        )}
        <Row>
          <Col md={6}>
            <Select
              id="currency"
              name="currency"
              value={currency}
              validate={() => isEmptyOrNull(currency) && 'Field is required!'}
              onChange={(e) => setCurrency(e.target.value)}
              disabled={mode !== 'edit'}
            >
              <option key="" value="">
                Select
              </option>
              {Currencies.map((c) => (
                <option key={c.name} value={c.value}>
                  {c.name}
                </option>
              ))}
            </Select>
          </Col>
        </Row>
        {mode === 'edit' && (
          <Footer
            onSaveClick={handleOnSaveClick}
            onCancelClick={handleOnCancelClick}
            disabledSaveBtn={!currency || loading}
            loading={loading}
          />
        )}
      </Form>
    </CardWrapper>
  );
};

Currency.propTypes = {
  /** Current company currency */
  companyCurrency: PropTypes.string.isRequired,
  /** Action function to make company currency change request */
  updateCompanyAction: PropTypes.func.isRequired,
  /** Any error happens while making change request */
  error: PropTypes.bool,
  /** Loading state during form submission */
  loading: PropTypes.bool,
};

export default Currency;
