import CrossIcon from '@bill/cashflow.assets/cross';
import EmployeesEmptyIcon from '@bill/cashflow.assets/employees-empty';
import SystemGeneratedIcon from '@bill/cashflow.assets/system-generated';
import CircularReference from '@/components/common/CircularReference';
import EmptyData from '@/components/common/EmptyData';
import Modal from '@/components/common/Modal';
import Table from '@/components/common/Table';
import { EMPLOYMENT_TYPE, salaryIntervals } from '@/constants/employees';
import { classNames, formatPercent } from '@/helpers';
import metricFormatters from '@/helpers/metricFormatters';
import { isEmptyOrNull } from '@/helpers/validators';
import './TheHiringDriverRecordsModal.scss';

const TheHiringDriverRecordsModal = ({ isOpen, records, onClose }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      data-testid="hiring-driver-records-modal"
      className="TheHiringDriverRecordsModal"
    >
      <button
        type="button"
        className="Modal_CloseBtn"
        data-testid="hiring-driver-records-modal-closeBtn"
        onClick={onClose}
      >
        <CrossIcon aria-label="Close" />
      </button>
      <h2 className="ModalBase_Heading">Driver Records</h2>
      {records.length > 0 ? (
        <Table data-testid="hiring-driver-records-table">
          <thead>
            <tr className="Table_HeadRow">
              <Table.ColHead>Department</Table.ColHead>
              <Table.ColHead>Title</Table.ColHead>
              <Table.ColHead>Name</Table.ColHead>
              <Table.ColHead>Start Date</Table.ColHead>
              <Table.ColHead>Salary</Table.ColHead>
              <Table.ColHead>
                Benefits and
                <br />
                Taxes
              </Table.ColHead>
              <Table.ColHead>
                Load
                <br />
                Multiplier
              </Table.ColHead>
            </tr>
          </thead>
          <tbody>
            {records.map(
              ({
                id,
                departmentName,
                employmentType,
                titleLabel,
                name,
                startDate,
                salary,
                salaryVariableId,
                benefitsAndTaxes,
                loadMultiplier,
                faulted,
              }) => {
                const interval = salaryVariableId
                  ? salaryIntervals.MONTHLY
                  : salaryIntervals.ANNUALLY;
                return (
                  <tr
                    key={id}
                    className={classNames(
                      'Table_Row',
                      faulted && 'Table_Row-faulted',
                    )}
                  >
                    <td className="Table_Cell">
                      {faulted ? (
                        <CircularReference className="CircularReference" />
                      ) : (
                        <SystemGeneratedIcon className="SystemGeneratedIcon" />
                      )}
                      {departmentName}
                    </td>
                    <td className="Table_Cell">{titleLabel}</td>
                    <td className="Table_Cell">{name}</td>
                    <td className="Table_Cell">{startDate || 'N/A'}</td>
                    <td className="Table_Cell">
                      <>
                        <span>{metricFormatters.monetary(salary)}</span>
                        <span>{interval}</span>
                      </>
                    </td>
                    <td className="Table_Cell">
                      {!isEmptyOrNull(benefitsAndTaxes)
                        ? `${metricFormatters.monetary(
                            benefitsAndTaxes,
                          )} ${interval}`
                        : 'N/A'}
                    </td>
                    <td className="Table_Cell">
                      {[
                        EMPLOYMENT_TYPE.contractor,
                        EMPLOYMENT_TYPE.other,
                      ].includes(employmentType)
                        ? 'N/A'
                        : formatPercent(loadMultiplier)}
                    </td>
                  </tr>
                );
              },
            )}
          </tbody>
        </Table>
      ) : (
        <EmptyData Icon={EmployeesEmptyIcon}>
          No data available. There are no forecasted employees for this hiring
          driver.
        </EmptyData>
      )}
    </Modal>
  );
};

export default TheHiringDriverRecordsModal;
