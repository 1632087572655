import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import FormLabel from '@/components/common/FormLabel';
import FormulaField from '@/components/common/FormulaField';
import FormulaValidationMessageWithLink from '@/components/common/FormulaValidationMessageWithLink';
import MonthField from '@/components/common/MonthField';
import { findCurrencySymbol } from '@/constants/currencies';
import VALID_FORMULA_MSG from '@/constants/formulas';
import { financingActionTypes } from '@/constants/revenue';
import { isNumber } from '@/helpers';
import {
  formatMonthYear,
  getDateOffsetByMonths,
  getDateOffsetByYears,
  getFormattedDateFromTimeStamp,
  getISODate,
  getUTCDayTimestamp,
} from '@/helpers/dateFormatter';
import { isEmptyOrNull, isEndDateBeforeStartDate } from '@/helpers/validators';
import validateCustomFormula from '@/services/formula.service';

const getTimeForDate = (date) => new Date(date).getTime();

const DebtForm = ({
  scenarioId,
  validationMsg,
  formState,
  dispatchFn,
  onValidation,
}) => {
  const prefixRef = useRef(findCurrencySymbol());

  const [isFocused, setIsFocused] = useState(false);

  const startDateMs = getTimeForDate(formState.startDate);
  const endDateMs = getTimeForDate(formState.endDate);

  return (
    <>
      <div className="Form_Group Form_Group-halfWidth">
        <FormLabel htmlFor="debt-start-date" text="Start Date" />
        <MonthField
          id="debt-start-date"
          value={startDateMs}
          max={getUTCDayTimestamp(
            getDateOffsetByYears(getISODate(new Date()), 3),
          )}
          onChange={(value) => {
            dispatchFn({
              type: financingActionTypes.SET_START_DATE,
              payload: getFormattedDateFromTimeStamp(value),
            });
          }}
          validate={(dateStr) => !dateStr && 'Please enter a valid date'}
        />
      </div>
      <div className="Form_Group Form_Group-halfWidth">
        <FormLabel htmlFor="debt-end-date" text="End Date" optional />
        <MonthField
          id="debt-end-date"
          value={endDateMs}
          min={getDateOffsetByMonths(formState.startDate, 1).getTime()}
          onChange={(value) => {
            dispatchFn({
              type: financingActionTypes.SET_END_DATE,
              payload: getFormattedDateFromTimeStamp(value),
            });
          }}
          validate={() =>
            isEndDateBeforeStartDate(formState.startDate, formState.endDate) &&
            `Date must be on or after ${formatMonthYear(startDateMs)}`
          }
        />
      </div>
      <div className="Form_Group">
        <FormLabel text="Amount" htmlFor="debt-formula-field" />
        <div className="alert alert-primary">
          The custom formula will be used to generate the debt finance.
        </div>
        <FormulaField
          id="debt-formula-field"
          value={
            !isFocused && isNumber(formState.amountFormula)
              ? `${prefixRef.current}${formState.amountFormula}`
              : formState.amountFormula
          }
          onChange={(value) => {
            dispatchFn({
              type: financingActionTypes.SET_AMOUNT,
              payload: value,
            });
          }}
          onBlur={async () => {
            if (isNumber(formState.amountFormula)) {
              const isValid = !isEmptyOrNull(formState.amountFormula);
              onValidation({
                isValid,
                msg: isValid ? VALID_FORMULA_MSG : 'Field required!',
              });
            } else {
              const {
                data: { data },
              } = await validateCustomFormula(
                scenarioId,
                formState.amountFormula,
              );
              onValidation(data?.valid);
              onValidation({
                isValid: data.valid,
                msg: data.valid ? VALID_FORMULA_MSG : data.error,
              });
            }
            setIsFocused(false);
          }}
          onFocus={() => {
            setIsFocused(true);
            onValidation({
              isValid: false,
              msg: '',
            });
          }}
        />
        <FormulaValidationMessageWithLink message={validationMsg} />
      </div>
    </>
  );
};

const mapStateToProps = ({ scenario }) => {
  return {
    scenarioId: scenario.scenarioId,
  };
};

export default connect(mapStateToProps)(DebtForm);
