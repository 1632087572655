import { forwardRef, useState, useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import AverageRevenuePerAccountChart from '@/pages/Dashboard/charts/AverageRevenuePerAccountChart';
import { getMetricValue } from '@/pages/Dashboard/helpers';

const AverageRevenuePerAccountGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [arpa, setArpa] = useState({});
    const { startDate, endDate } = useSelector(({ shared }) => shared);
    const onQueryStateChange = useCallback(
      ({ data: { overallAverageValue }, isLoading }) => {
        setArpa({ isLoading, total: overallAverageValue });
      },
      [],
    );

    return (
      <ChartWidget
        ref={ref}
        title="Average Revenue Per Account"
        metricValue={formatMonetary(arpa.total)}
        label={formatRangeWithShortYear(startDate, endDate)}
        data-testid="arpaWidget"
        url="/dashboard/average-revenue-per-account"
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={formatRangeWithShortYear(startDate, endDate)}
              value={
                arpa.isLoading ? '' : getMetricValue(arpa.total, formatMonetary)
              }
            />
            <AverageRevenuePerAccountChart
              {...childProps}
              onQueryStateChange={onQueryStateChange}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default AverageRevenuePerAccountGlance;
