import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  createFinancingAction,
  updateFinancingAction,
} from '@/actions/revenue';
import Button from '@/components/common/Button';
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';
import FormRadio from '@/components/common/FormRadio';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';
import VALID_FORMULA_MSG from '@/constants/formulas';
import {
  financingActionTypes,
  REVENUE_API_NAME_MAX_LENGTH,
} from '@/constants/revenue';
import {
  /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
  isDefined,
  isEmptyOrNull,
  isEndDateBeforeStartDate,
} from '@/helpers/validators';
import useViewOnlyMode from '@/hooks/useViewOnlyMode';
import { getFinanceDetail } from '@/services/revenueService';
import DebtForm from './TheAddEditFinancingModal/DebtForm';
import EquityForm from './TheAddEditFinancingModal/EquityForm';

const financingTypes = {
  EQUITY: 'EQUITY',
  DEBT: 'DEBT',
};

const initialState = {
  id: null,
  name: '',
  amountFormula: '',
  type: financingTypes.EQUITY,
  startDate: '',
  endDate: '',
};

const formattedFinancing = (financing) => {
  return {
    id: financing.id,
    name: financing.name,
    amountFormula: financing.amountFormula,
    type: financing.type,
    startDate: financing.startDate,
    endDate: financing.endDate,
  };
};

const validateForm = ({
  name,
  amountFormula,
  type,
  startDate,
  endDate,
  isFormulaValid,
}) => {
  const requiredFields = [name, amountFormula, type, startDate];
  switch (true) {
    case type === financingTypes.DEBT &&
      !isEmptyOrNull(endDate) &&
      isEndDateBeforeStartDate(startDate, endDate):
      return false;
    case type === financingTypes.DEBT && !isFormulaValid:
      return false;
    case type === financingTypes.EQUITY &&
      !Number.isFinite(Number(amountFormula)):
      return false;
    default:
      /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
      return requiredFields.every(isDefined);
  }
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case financingActionTypes.SET_NAME:
      return { ...state, name: payload };
    case financingActionTypes.SET_TYPE:
      return {
        ...initialState,
        name: state.name,
        type: payload,
      };
    case financingActionTypes.SET_START_DATE:
      return { ...state, startDate: payload };
    case financingActionTypes.SET_END_DATE:
      return { ...state, endDate: payload };
    case financingActionTypes.SET_AMOUNT:
      return { ...state, amountFormula: payload };
    case financingActionTypes.EDIT_FORM:
      return { ...payload };
    default:
      return state;
  }
};

const FinancingForm = ({
  error,
  financingToEdit,
  scenarioId,
  onClose,
  onError,
  createFinancing,
  updateFinancing,
}) => {
  const [formState, dispatch] = useReducer(reducer, initialState);
  const [isFormulaValid, setIsFormulaValid] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [loader, setLoader] = useState(false);

  const formRef = useRef(null);
  const isViewOnly = useViewOnlyMode(formRef);

  const isValidData = validateForm({ ...formState, isFormulaValid });

  const handleSave = async () => {
    try {
      const params = {
        name: formState.name,
        type: formState.type,
        amountFormula: formState.amountFormula,
        startDate: formState.startDate,
        ...(formState.type !== financingTypes.EQUITY && formState.endDate
          ? { endDate: formState.endDate }
          : {}),
      };
      setLoader(true);
      if (financingToEdit) {
        await updateFinancing(params, {
          id: formState.id,
          scenarioId,
        });
      } else {
        await createFinancing(params, scenarioId);
      }
      setLoader(false);
      onClose();
    } catch (e) {
      onError(e.response?.data?.error?.errorMessage || e.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    async function getFinanceForEdit(id) {
      const { data } = await getFinanceDetail(id, scenarioId);
      dispatch({
        type: financingActionTypes.EDIT_FORM,
        payload: formattedFinancing(data.data),
      });
    }
    if (financingToEdit) {
      setIsFormulaValid(true);
      setValidationMsg(VALID_FORMULA_MSG);
      getFinanceForEdit(financingToEdit);
    }
  }, [financingToEdit, scenarioId]);

  const formHeading = useMemo(() => {
    if (isViewOnly) {
      return 'Financing Details';
    }

    return `${financingToEdit ? 'Edit' : 'Add'} Financing`;
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [isViewOnly]);

  return (
    <ModalBase
      show
      onCancel={onClose}
      header={<h2 className="ModalBase_Heading">{formHeading}</h2>}
      footer={
        <>
          {!isViewOnly ? (
            <>
              <Button
                className="Button Button-cancelLink"
                onClick={onClose}
                data-testid="add-revenue-financing-cancel"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSave}
                disabled={!isValidData}
                loading={loader}
                data-testid="add-revenue-financing-save"
              >
                {financingToEdit ? 'Save' : 'Add'}
              </Button>
            </>
          ) : (
            <Button
              data-testid="add-revenue-financing-close"
              onClick={onClose}
              disabled={!isValidData}
            >
              Close
            </Button>
          )}
        </>
      }
      id="add-revenue-financing-modal"
    >
      {error && (
        <p className="alert alert-danger">
          <b>Error </b> - {error}
        </p>
      )}
      <div className="Form" ref={formRef}>
        <div className="Form_Group">
          <FormLabel htmlFor="name" text="Name" />
          <FormField
            id="name"
            name="name"
            maxLength={REVENUE_API_NAME_MAX_LENGTH}
            value={formState.name}
            onChange={({ target: { value } }) => {
              dispatch({
                type: financingActionTypes.SET_NAME,
                payload: value,
              });
            }}
            validate={() => {
              return (
                isEmptyOrNull(formState.name) &&
                !isViewOnly &&
                'Field is required!'
              );
            }}
            data-testid="add-revenue-financing-name"
          />
        </div>

        <div className="Form_Group">
          <div className="Label">Type</div>
          <FormRadio
            id="equity-option"
            name="financing-type"
            label="Equity"
            className="FinancingOption"
            data-testid="equity-option"
            checked={formState.type === financingTypes.EQUITY}
            disabled={
              !!(financingToEdit && formState.type !== financingTypes.EQUITY)
            }
            value="Equity"
            onChange={() => {
              setIsFormulaValid(false);
              dispatch({
                type: financingActionTypes.SET_TYPE,
                payload: financingTypes.EQUITY,
              });
            }}
          />
          <FormRadio
            id="debt-option"
            name="financing-type"
            label="Debt"
            className="FinancingOption"
            data-testid="debt-option"
            checked={formState.type === financingTypes.DEBT}
            disabled={
              !!(financingToEdit && formState.type !== financingTypes.DEBT)
            }
            value="Debt"
            onChange={() => {
              setIsFormulaValid(false);
              dispatch({
                type: financingActionTypes.SET_TYPE,
                payload: financingTypes.DEBT,
              });
            }}
          />
        </div>

        {formState.type === financingTypes.EQUITY ? (
          <EquityForm formState={formState} dispatchFn={dispatch} />
        ) : (
          <DebtForm
            formState={formState}
            validationMsg={validationMsg}
            dispatchFn={dispatch}
            onValidation={({ isValid, msg }) => {
              setIsFormulaValid(isValid);
              setValidationMsg(msg);
            }}
          />
        )}
      </div>
    </ModalBase>
  );
};

const mapStateToProps = ({ scenario }) => ({
  scenarioId: scenario.scenarioId,
});

export default connect(mapStateToProps, {
  createFinancing: createFinancingAction,
  updateFinancing: updateFinancingAction,
})(FinancingForm);
