import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { SET_CHART_PREFERENCES } from '@/actionTypes/dashboard';
import useChartPreferences from '@/hooks/useChartPreferences';
import Toggle from './Toggle';

/**
 * Creates a toggle for switching between two states and persisting the state
 * between views
 *
 * @example
 *   <TogglePersistent
 *     id="foo"
 *     value={123}
 *     options={[
 *       {
 *         Icon: SomeIcon,
 *         label: 'Option 1',
 *         value: 123,
 *       },
 *       {
 *         Icon: SomeOtherIcon,
 *         label: 'Option 2',
 *         value: 456,
 *       },
 *     ]}
 *   />;
 */
const TogglePersistent = ({ className, disabled, id, options, value }) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const toggleValueFromState = useChartPreferences(id);
  const toggleValue = toggleValueFromState ?? value;

  useEffect(() => {
    if (!toggleValueFromState) {
      dispatch({ type: SET_CHART_PREFERENCES, payload: { [id]: value } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, []);

  const onToggle = (togglePosition) =>
    dispatch({
      type: SET_CHART_PREFERENCES,
      payload: { [id]: togglePosition },
    });

  return (
    <Toggle
      className={className}
      disabled={disabled}
      id={id}
      onToggle={onToggle}
      options={options}
      value={toggleValue}
    />
  );
};

TogglePersistent.propTypes = {
  /** Classes to apply to the toggle button */
  className: PropTypes.string,
  /** Whether or not the toggle is disabled */
  disabled: PropTypes.bool,
  /**
   * A globally unique ID for storing toggle state between views. It is also
   * used for selecting the toggle in unit/integration tests
   */
  id: PropTypes.string.isRequired,
  /**
   * An array of objects describing the two states to toggle between. Each
   * object must contain an label and value.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.elementType,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    }),
  ).isRequired,
  /** The option (by option.value) to be selected */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};

export default TogglePersistent;
