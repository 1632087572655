import { useState } from 'react';
import { classNames } from '@/helpers';

function IntegrationButton({
  className,
  disabled,
  icon,
  onClick,
  isPending,
  isSelected,
  isUnavailable,
}) {
  const [isLoading, setLoading] = useState(false);

  return (
    <button
      className={classNames(
        'RegistrationIntegration_IconBtn',
        className,
        isSelected && 'RegistrationIntegration_IconBtn-selected',
        isUnavailable && 'RegistrationIntegration_IconBtn-unavailable',
      )}
      onClick={() => {
        setLoading(true);
        onClick();
      }}
      disabled={
        disabled || isLoading || isPending || isSelected || isUnavailable
      }
    >
      {icon}
      {!isUnavailable && !isSelected && (!disabled || isLoading) && (
        <div
          className={
            isLoading || isPending
              ? 'RegistrationIntegration_BtnOverlayLoading'
              : 'RegistrationIntegration_BtnOverlay'
          }
        />
      )}
    </button>
  );
}

export default IntegrationButton;
