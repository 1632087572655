import { PropTypes } from 'prop-types';
import Button from '@/components/common/Button';
import { classNames } from '@/helpers';
import CompanyLetterIcon from './CompanyLetterIcon';
import './CompanyInfoCard.scss';

/**
 * CompanyInfoCard component to `CompanyInfoCard_Details ${!company.isActive &&
 * 'disabled'}`show company detail on Card.
 *
 * @example
 *   <CompanyInfoCard company={company} onSelect={onCompanySelect} />;
 *
 * @param {Object} company company details
 * @param {Function} onSelect A function to select company
 */

export default function CompanyInfoCard({ company, onSelect }) {
  return (
    <div className="CompanyInfoCard">
      <div
        className={classNames(
          'CompanyInfoCard_Details',
          !company.isActive && 'disabled',
        )}
      >
        <CompanyLetterIcon
          companyName={company.name}
          companyId={company.id}
          className="CompanyInfoCard_LetterIcon"
        />

        <p className="CompanyInfoCard_Label">{company.name}</p>
      </div>

      {!company.isActive ? (
        <p className="CompanyInfoCard_InActiveLabel">
          There are no available scenarios for you to access. Please contact the
          company administrator.
        </p>
      ) : (
        <Button
          data-testid={`${company.name}-select`}
          className="Button Button-success"
          onClick={onSelect}
        >
          Select
        </Button>
      )}
    </div>
  );
}

CompanyInfoCard.propTypes = {
  /** Company Details */
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
  }),
  /** A function to select company */
  onSelect: PropTypes.func.isRequired,
};
