// @ts-check
export const ScenarioRoles = {
  DASHBOARD_ONLY: 'DASHBOARD_ONLY',
  VIEWER: 'VIEWER',
  EDITOR: 'EDITOR',
};

export const ScenarioRolesText = {
  DASHBOARD_ONLY: 'Dashboard Only',
  VIEWER: 'Viewer',
  EDITOR: 'Editor',
};

export const EDIT = /** @type {const} */ ('edit');
export const ADD = /** @type {const} */ ('add');
export const DUPLICATE = /** @type {const} */ ('duplicate');
export const SCENARIO = /** @type {const} */ ('scenario');
export const BUDGET = /** @type {const} */ ('budget');
export const DUPLICATED = /** @type {const} */ ('duplicated');
export const CREATED = /** @type {const} */ ('created');
export const scenarioMode = /** @type {const} */ ({
  EDIT,
  ADD,
  DUPLICATE,
});
/** @typedef {scenarioMode} Mode */
/** @typedef {(typeof scenarioMode)[keyof typeof scenarioMode]} ScenarioMode */

export const NO_COMPARISON = -1;
export const SCENARIO_ID = 'scenarioId';

export const scenarioActionType = /** @type {const} */ ({
  SET_SCENARIO_NAME: 'SET_SCENARIO_NAME',
  SET_SCENARIO_COLOR: 'SET_SCENARIO_COLOR',
  SET_SCENARIO_NOTES: 'SET_SCENARIO_NOTES',
  SET_ENABLED_INTEGRATIONS: 'SET_ENABLED_INTEGRATIONS',
});

/** @typedef {scenarioActionType} ScenarioActionType */

/** @typedef {scenarioTypes} scenarioType */
/**
 * @type {Readonly<{
 *   [key: string]: import('@/types/services/backend').ScenarioDto['type'];
 * }>}
 */
export const scenarioTypes = /** @type {const} */ ({
  SCENARIO: 'SCENARIO',
  BUDGET: 'BUDGET',
});

export const scenarioTypesText = /** @type {const} */ ({
  [scenarioTypes.SCENARIO]: 'Scenario',
  [scenarioTypes.BUDGET]: 'Budget',
});
