import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import MoreInfoIcon from '@bill/cashflow.assets/more-info';
import MoreVerticalTrinityIcon from '@bill/cashflow.assets/more-vertical-trinity';
import Highcharts from 'highcharts';
import highchartsExportData from 'highcharts/modules/export-data';
import highchartsExport from 'highcharts/modules/exporting';
import highchartsExportOffline from 'highcharts/modules/offline-exporting';
import { chartFonts, chartTypes } from '@/components/Charts/constants';
import { getDateLabel, getForecastBand } from '@/components/Charts/helpers';
import ContextMenu from '@/components/common/ContextMenu';
import { CHART_MARGIN } from '@/constants/charts';
import COLORS from '@/constants/colorPalette';
import { classNames } from '@/helpers';
import generateCsv from '@/helpers/csv';
import { formatDateWithShortYear } from '@/helpers/dateFormatter';
import useOneColor from '@/hooks/useOneColor';
import './ChartControls.scss';

const ExportButton = ({ type, onClick, oneColorEnabled }) => (
  <button
    id={`${type}-button`}
    data-testid={`${type}-button`}
    onClick={onClick}
    className={classNames(
      'ChartControls_ExportBtn',
      `ChartControls_ExportBtn-${type}`,
      oneColorEnabled && 'ChartControls_ExportBtn-forecasting',
    )}
  >
    {type.toUpperCase()}
  </button>
);

highchartsExport(Highcharts);
highchartsExportData(Highcharts);
highchartsExportOffline(Highcharts);

function formatXAxisLabels(value, timePeriod, endDate) {
  const [startDateTimeStamp, endDateTimeStamp] = getDateLabel(
    value,
    timePeriod,
    endDate,
  );
  if (startDateTimeStamp === endDateTimeStamp) {
    return `${formatDateWithShortYear(startDateTimeStamp)}`;
  }
  return `${formatDateWithShortYear(
    startDateTimeStamp,
  )} - ${formatDateWithShortYear(endDateTimeStamp)} `;
}

const getSVGForCharts = (chartsArr, options) => {
  let top = 0;
  let width = 0;

  const newOptions = Highcharts.merge(
    Highcharts.getOptions().exporting,
    options,
  );

  const svgArr = chartsArr.map((chart, idx) => {
    if (idx === 1) {
      newOptions.title = '';
    }

    /** @type {Highcharts.CaptionOptions} */
    let caption = {};
    /** @type {Highcharts.Series | null} */
    const firstSeries = chart.series.length ? chart.series[0] : null;
    if (firstSeries?.type === chartTypes.PIE) {
      caption = {
        useHTML: true,
        verticalAlign: 'top',
        align: 'center',
        y: 60,
        text: `
        <span style="
          font-size: 16px;
          color: ${COLORS.raisinBlack}
        ">
          ${firstSeries.name}
          &nbsp;&nbsp;&nbsp;
        </span>
      `,
      };
    }

    let svg = chart.getSVG({
      ...newOptions,
      caption,
    });

    // Get width/height of SVG for export
    const svgWidth = +svg.match(/^<svg[^>]*width\s*=\s*"?(\d+)"?[^>]*>/)[1];
    const svgHeight = +svg.match(/^<svg[^>]*height\s*=\s*"?(\d+)"?[^>]*>/)[1];

    svg = svg.replace('<svg', `<g transform="translate(${width}, 0 )" `);
    svg = svg.replace('</svg>', '</g>');

    width += svgWidth;
    top = Math.max(top, svgHeight);
    return svg;
  });

  return `<svg height="${top}" width="${width}" version="1.1" xmlns="http://www.w3.org/2000/svg">
  ${svgArr.join('')}
  </svg>`;
};

const exportCharts = (chartsArr, options, type) => {
  Highcharts.downloadSVGLocal(getSVGForCharts(chartsArr, options), {
    type,
    scale: 2,
    pdfFont: {
      normal: chartFonts.FAMILY_PATH,
      bold: chartFonts.FAMILY_PATH_BOLD,
    },
  });
};

/**
 * @typedef {{
 *   'children'?: React.ReactElement;
 *   'title': string;
 *   'metricValue'?: string;
 *   'label'?: string;
 *   'chartRef': Highcharts.Chart | React.RefObject<Highcharts.Chart>;
 *   'data-testid': string;
 *   'disabled'?: boolean;
 *   'csvColumnFilter'?: string;
 * }} ChartControlsProps
 */

/** @type {(props: ChartControlsProps) => React.ReactElement} */
const ChartControls = ({
  children,
  title,
  metricValue = '',
  label,
  chartRef,
  'data-testid': dataTestId,
  disabled,
  csvColumnFilter = '',
}) => {
  const { timePeriod, endDate } = useSelector(({ shared }) => shared);
  const isOneColorEnabled = useOneColor();
  const handleChartExport = useCallback(
    ({ target }) => {
      const chart =
        chartRef instanceof Highcharts.Chart || Array.isArray(chartRef)
          ? chartRef
          : chartRef.current;
      const chartOptions = {
        chart: {
          margin: null,
          marginTop: CHART_MARGIN,
          events: {
            load() {
              /**
               * The gradient fill on area series does not export properly to
               * PDF.
               *
               * @see https://github.com/highcharts/node-export-server/issues/328
               */
              this.series.forEach((s) => {
                const options = {};
                switch (true) {
                  case s.type === chartTypes.AREA && !s.options.stacking:
                    options.fillColor = 'transparent';
                    break;
                  case s.type === chartTypes.PIE:
                    options.dataLabels = { enabled: true };
                    break;
                  // no default
                }
                s.update(options, false);
              });

              this.xAxis.forEach((axis) => {
                axis.update({
                  plotBands: [
                    {
                      ...getForecastBand(axis.min, axis.max, isOneColorEnabled),
                      color: 'transparent',
                    },
                  ],
                });
              });
            },
          },
          spacing: [10, 8, 8, 8],
          backgroundColor: 'white',
        },
        title: {
          align: 'left',
          useHTML: true,
          y: 30,
          style: {
            fontFamily: chartFonts.FAMILY_NAME,
            whiteSpace: 'nowrap',
          },
          text: `
            <span style="
              position: relative;
              top: -5px;
              font-size: 16px;
              font-weight: normal;
            ">
              ${title}
              &nbsp;&nbsp;&nbsp;
            ${
              metricValue
                ? `
                <span style="font-size: 32px">
                  <span style="
                    padding-left: 20px;
                    font-weight: bold;
                    color: ${COLORS.finmarkBlue};
                    border-left: 1px solid #ccdaff;
                  ">
                    ${metricValue}
                  </span>
                </span>`
                : ''
            }
            ${
              label
                ? `
                <span style="
                  font-size: 12px;
                  color: ${COLORS.raisinBlack};
                ">
                  ${label}
                </span>`
                : ''
            }
          `,
        },
        series: {
          dataLabels: {
            style: {
              direction: 'ltr',
            },
          },
        },
        exporting: {
          sourceWidth: 1200,
          sourceHeight: 600,
        },
        xAxis: {
          visible: true,
          labels: {
            style: {
              fontWeight: 'normal',
              fontFamily: chartFonts.FAMILY_NAME,
            },
          },
        },
        yAxis: {
          visible: true,
          labels: {
            style: {
              fontWeight: 'normal',
              fontFamily: chartFonts.FAMILY_NAME,
            },
          },
        },
      };

      if (target.id === 'csv-button') {
        ((H) => {
          H.wrap(
            H.Chart.prototype,
            'getDataRows',
            // eslint-disable-next-line func-names -- predates description requirement
            function (proceed, multiLevelHeaders) {
              const rows = proceed.call(this, multiLevelHeaders);
              if (!this.axes.length) return rows;
              const index = rows[0].indexOf(csvColumnFilter);
              const updatedRows = rows.map((row) => {
                const updatedRow =
                  index !== -1
                    ? [...row.slice(0, index), ...row.slice(index + 1)]
                    : row;
                const newRow = updatedRow.map((value) =>
                  Number.isNaN(value) ? null : value,
                );
                if (updatedRow.x) {
                  newRow[0] = formatXAxisLabels(
                    updatedRow.x,
                    timePeriod,
                    endDate,
                  );
                }
                return newRow;
              });
              return updatedRows;
            },
          );
        })(Highcharts);

        if (!Array.isArray(chart)) {
          chart.downloadCSV();
        } else {
          const [baseChartCSV, compareChartCSV] = chart.map((chartItem) => {
            return chartItem.getCSV();
          });

          const combinedCsv = baseChartCSV.split('\n').map((row) => {
            const [rowKey] = row.split(',');
            let combinedRow = row;
            compareChartCSV.split('\n').forEach((baseRow) => {
              const [baseRowKey, value] = baseRow.split(',');
              if (baseRowKey === rowKey) {
                combinedRow = `${row},${value}`;
              }
            });
            return combinedRow;
          });

          generateCsv(
            'chart.csv',
            new Blob(['\ufeff', combinedCsv.join('\n')]),
            '',
          );
        }
      } else {
        let mimeType;
        switch (target.id) {
          case 'png-button':
            mimeType = 'image/png';
            break;
          case 'jpg-button':
            mimeType = 'image/jpeg';
            break;
          case 'pdf-button':
            mimeType = 'application/pdf';
            break;
          default:
            // eslint-disable-next-line no-console -- predates description requirement
            console.warn(`Invalid export of type ${target.id}`);
        }
        const chartsToExport = Array.isArray(chart) ? chart : [chart];
        exportCharts(chartsToExport, chartOptions, mimeType);
      }
    },
    [
      chartRef,
      title,
      metricValue,
      label,
      timePeriod,
      endDate,
      csvColumnFilter,
      isOneColorEnabled,
    ],
  );
  return (
    <div className="ChartControls">
      <ContextMenu
        data-testid={dataTestId}
        disabled={disabled}
        Icon={isOneColorEnabled ? MoreVerticalTrinityIcon : MoreInfoIcon}
      >
        <>
          <div className="ChartControls_ChildrenContainer">{children}</div>
          <div className="ChartControls_ExportWrapper">
            <p
              className={classNames(
                'ChartControls_ExportTxt',
                isOneColorEnabled && 'ChartControls_ExportTxt-forecasting',
              )}
            >
              Export Chart
            </p>
            <div className="ChartControls_ExportBtns">
              {['png', 'jpg', 'pdf', 'csv'].map((type) => (
                <ExportButton
                  key={type}
                  type={type}
                  onClick={handleChartExport}
                  oneColorEnabled={isOneColorEnabled}
                />
              ))}
            </div>
          </div>
        </>
      </ContextMenu>
    </div>
  );
};

export default ChartControls;
