// @ts-check
import Api, { ApiV2 } from './Api';
import getByDateRange from './getByDateRange';

/**
 * Get the external profit and loss data
 *
 * @param {string} startDate First month of report to be retrieved
 * @param {string} endDate Last month of report to be retrieved
 * @param {number} scenarioId The id of the scenario
 * @returns {Promise} API response
 */
export const getExternalProfitAndLoss = getByDateRange(
  '/spreadsheet-reports/profitAndLoss',
);

/**
 * Get the external cash flow data
 *
 * @param {string} startDate First month of report to be retrieved
 * @param {string} endDate Last month of report to be retrieved
 * @param {number} scenarioId The id of the scenario
 * @returns {Promise} API response
 */
export const getExternalCashFlow = getByDateRange(
  '/spreadsheet-reports/cash-flow',
);

export const getCashFlowActuals = getByDateRange('/cash-flow/compare');

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const patchActualFinancing = (payload, scenarioId) =>
  Api.patch(`/financing/actual`, payload, { params: { scenarioId } });

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const patchActualEndingCash = (payload, scenarioId) =>
  Api.patch(`/actualCash/endingcash`, payload, { params: { scenarioId } });

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const patchActualCashCollections = (payload, scenarioId) =>
  Api.put(`/actualCash/cashcollected`, payload, { params: { scenarioId } });

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const patchActualCashPayments = (payload, scenarioId) =>
  Api.put(`/actualCash/cashpayments`, payload, { params: { scenarioId } });

/**
 * @type {(
 *   params: import('./getByDateRange').GetByDateRangeProps<{
 *     actualFamily: import('@/types/services/backend').ActualNodeDto['family'];
 *   }>,
 * ) => Promise<
 *   import('@/types/api').AxiosApiResponse<{
 *     children: import('@/types/services/backend').ActualNodeDto[];
 *   }>
 * >}
 */
export const getCombinedActuals = getByDateRange('/actuals');

/**
 * @typedef {{
 *   scenarioId: number;
 *   yearMonth: string;
 *   revenueMetric?:
 *     | 'NEW_CUSTOMER'
 *     | 'NEW_MRR'
 *     | 'CHURNED_CUSTOMER'
 *     | 'CHURNED_MRR'
 *     | 'OTHER_REVENUE'
 *     | 'BEGINNING_MRR'
 *     | 'ENDING_MRR'
 *     | 'BEGINNING_CUSTOMER'
 *     | 'ONE_TIME_PURCHASE'
 *     | 'ONE_TIME_REVENUE'
 *     | 'ADJUSTMENT_MRR'
 *     | 'CONVERTED_LEADS'
 *     | 'DEFERRED_REVENUE'
 *     | 'ACCOUNT_RECEIVABLE'
 *     | 'ENDING_CUSTOMER'
 *     | 'RENEWED_MRR'
 *     | 'CASH'
 *     | 'NET_NEW_MRR'
 *     | 'EXPANSION_CUSTOMER'
 *     | 'CONTRACTION_CUSTOMER'
 *     | 'ADJUSTMENT_CUSTOMER'
 *     | 'EXPANSION_MRR'
 *     | 'CONTRACTION_MRR';
 *   revenueStreamPricingPlanId?: number;
 *   payrollMetric?: 'SALARY' | 'BENEFITS_TAXES' | 'BONUS' | 'EMPLOYEE_TYPE';
 *   value?: number;
 *   parentId?: string;
 *   type: import('@/types/services/backend').ActualNodeDto['family'];
 *   displayFormula?: string;
 *   customVariableId?: string;
 *   fillRight?: boolean;
 * }} UpdateActualsRequest
 */

/**
 * @typedef {(params: {
 *   scenarioIds: number[];
 *   actuals: UpdateActualsRequest;
 * }) => Promise<
 *   import('@/types/api').AxiosApiResponse<
 *     import('@/types/services/backend').ActualNodeDto[]
 *   >
 * >} UpdateActualsService
 */

/**
 * Updates one or more actuals in the given scenarios
 *
 * @type {UpdateActualsService}
 */
export const updateActuals = ({ scenarioIds, actuals }) =>
  ApiV2.post('/actuals', actuals, { params: { scenarioIds } });
