export const CHART_TITLE = 'Burn Rate';
export const BURN_RATE_METRICS = [
  {
    key: 'totalExpense',
    name: 'Cash Payments',
  },
  {
    key: 'totalRevenue',
    name: 'Cash Collections',
  },
  {
    key: 'y',
    name: 'Burn Rate',
    isMainMetric: true,
  },
];
