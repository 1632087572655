// @ts-check
import { useCallback, memo } from 'react';
import CircularReferenceIcon from '@bill/cashflow.assets/circular-reference';
import UserAvatarIcon from '@bill/cashflow.assets/user-avatar';
import WithTooltip from '@/components/common/WithTooltip';
import { iconTypes } from '@/constants/actuals';
import {
  CIRCULAR_REFERENCE_HEADING,
  CIRCULAR_REFERENCE_WARNING,
} from '@/constants/circularReference';
import { classNames } from '@/helpers';
import { ReactComponent as IntegrationsIcon } from '@/assets/icons/svg/integrations.svg';
import './TooltipCellRenderer.scss';

const EXTERNAL_SOURCE_EXTENDED_TEXT =
  'These values have been pulled from an external integrated platform.';
const EXTERNAL_SOURCE_EXTENDED_TEXT_WITH_CLICK = `${EXTERNAL_SOURCE_EXTENDED_TEXT} Click the icon to see the breakdown.`;
const EXTERNAL_HEADING = 'Externally Created';

/**
 * @typedef {{
 *   heading: string;
 *   icon: React.ReactElement;
 *   tooltipInfo?: string;
 * }} ToolTipOptions
 */

/**
 * @typedef {{
 *   heading: string;
 *   tooltipInfo?: string;
 * }} ToolTipContentProps
 */

/** @typedef {{ iconType: string; onClick?: () => void }} ToolTipProps */

/** @typedef {{ onClick?: () => void }} ToolTipButtonProps */

/** @type {(iconType: string) => ToolTipOptions} */
const getToolTipOptions = (iconType) => {
  switch (iconType) {
    case iconTypes.USER_ENTERED:
      return {
        heading: 'User Created',
        icon: <UserAvatarIcon />,
      };
    case iconTypes.EXTERNAL_SOURCE_GENERATED:
      return {
        heading: EXTERNAL_HEADING,
        icon: <IntegrationsIcon />,
        tooltipInfo: EXTERNAL_SOURCE_EXTENDED_TEXT_WITH_CLICK,
      };
    case iconTypes.CIRCULAR_REFERENCE:
      return {
        heading: CIRCULAR_REFERENCE_HEADING,
        icon: <CircularReferenceIcon />,
        tooltipInfo: CIRCULAR_REFERENCE_WARNING,
      };
    default:
      console.error(`Unknown icon type: ${iconType}`);
      return null;
  }
};

/** @type {React.NamedExoticComponent<ToolTipContentProps>} */
const ToolTipContent = memo(({ heading, tooltipInfo }) => {
  return (
    <>
      <div
        className="CashInOutToolTipCell_Heading"
        data-testid="cash-in-out-tooltip-heading"
      >
        {heading}
      </div>
      {tooltipInfo && (
        <span data-testid="cash-in-out-tooltip-info">{tooltipInfo}</span>
      )}
    </>
  );
});

/**
 * @type {(
 *   props: React.PropsWithChildren<ToolTipButtonProps>,
 * ) => React.ReactElement}
 */
const ToolTipButton = ({ children, onClick }) => {
  const handleOnClick = useCallback(
    (event) => {
      event.currentTarget.blur();
      onClick();
    },
    [onClick],
  );

  return (
    <button
      type="button"
      className="CashInOutTooltipCell_Icon-Button"
      data-testid="cash-in-out-tooltip-trigger"
      onClick={handleOnClick}
    >
      {children}
    </button>
  );
};

/**
 * Tooltip for Cash In/Out Grid Cell Icons. This is what powers the icons next
 * to the values in the cells, e.g. the "puzzle piece" icon.
 *
 * @type {(props: ToolTipProps) => React.ReactElement}
 */
const Tooltip = ({ iconType, onClick }) => {
  if (!iconType) {
    return null;
  }
  const options = getToolTipOptions(iconType);
  if (!options) return null;

  const { heading, icon, tooltipInfo } = options;
  const isExternal = heading === EXTERNAL_HEADING;

  return (
    <WithTooltip
      content={<ToolTipContent heading={heading} tooltipInfo={tooltipInfo} />}
      data-testid="cash-in-out-tooltip"
      placement="right"
      trigger="mouseenter"
    >
      <span
        className="CashInOutTooltipCell_Icon"
        data-testid="cash-in-out-tooltip-trigger"
      >
        {isExternal && typeof onClick === 'function' ? (
          <ToolTipButton onClick={onClick}>{icon}</ToolTipButton>
        ) : (
          icon
        )}
      </span>
    </WithTooltip>
  );
};

/**
 * @typedef {{
 *   cellLoading: boolean;
 *   iconType: string;
 *   onClick?: () => void;
 * }} RendererParams
 */

/**
 * AG Grid Renderer For The Tooltip Cell
 *
 * @type {(
 *   props: import('ag-grid-community').ICellRendererParams & RendererParams,
 * ) => React.ReactElement}
 */
const TooltipCellRenderer = (ctx) => {
  const { valueFormatted, iconType, onClick } = ctx;
  const shouldUseClickHandler = typeof onClick === 'function';

  const handleOnClick = useCallback(() => {
    if (shouldUseClickHandler) {
      onClick();
    }
  }, [onClick, shouldUseClickHandler]);

  return (
    <div
      className={classNames(
        'CashInOutTooltipCell',
        iconType === iconTypes.CIRCULAR_REFERENCE &&
          'CashInOutTooltipCellError',
      )}
      data-testid="cash-in-out-tooltip-cell"
    >
      <span
        className="CashInOutTooltipCell_Value"
        data-testid="cash-in-out-tooltip-cell-value"
      >
        {valueFormatted}
      </span>
      <Tooltip
        iconType={iconType}
        onClick={shouldUseClickHandler ? handleOnClick : null}
      />
    </div>
  );
};
export default TooltipCellRenderer;
