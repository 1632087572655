import SET_EXTERNAL_BALANCE_SHEET from '@/actionTypes/reports';
import { subscribeToTopic } from '@/actions/shared';
import { getExternalBalanceSheet } from '@/services/reports.service';

/**
 * Action to fetch balance sheet from external spreadsheets.
 *
 * @param {string} startDate First month of report to be retrieved
 * @param {string} endDate Last month of report to be retrieved
 * @param {number} scenarioId The id of the scenario
 * @returns {Function} Dispatchable action
 */
export const getExternalBalanceSheetAction = (
  startDate,
  endDate,
  scenarioId,
) => {
  return async (dispatch) => {
    try {
      const {
        data: { data },
      } = await getExternalBalanceSheet({ startDate, endDate, scenarioId });
      dispatch({
        type: SET_EXTERNAL_BALANCE_SHEET,
        payload: data,
      });
    } catch (e) {
      // eslint-disable-next-line no-console -- predates description requirement
      console.error(e);
    }
  };
};

/**
 * Action to subscribe to records updates for user reports
 *
 * @param {number} scenarioId ID of the scenario
 * @param {number} reportId ID of the report
 * @param {Function} callback An callback triggered when a subscribed topic
 *   receives a message
 * @returns {import('@/types/extend-redux-toolkit').AppThunk<
 *   Promise<import('@stomp/stompjs').StompSubscription>
 * >}
 *   Dispatchable action
 */
export const subscribeToReportUpdatesAction = (
  scenarioId,
  reportId,
  callback,
) => {
  const topic = `reports/${scenarioId}/${reportId}`;

  return (dispatch) => {
    return dispatch(
      subscribeToTopic(topic, (payload) => {
        callback(payload);
      }),
    );
  };
};

/**
 * Action to subscribe to cell values updates for user reports
 *
 * @param {number} scenarioId ID of the scenario
 * @param {number} reportId ID of the report
 * @param {Function} callback An callback triggered when a subscribed topic
 *   receives a message
 * @returns {import('@/types/extend-redux-toolkit').AppThunk<
 *   Promise<import('@stomp/stompjs').StompSubscription>
 * >}
 *   Dispatchable action
 */
export const subscribeToReportValuesUpdatesAction = (
  scenarioId,
  reportId,
  callback,
) => {
  const topic = `reports/values/${scenarioId}/${reportId}`;

  return (dispatch) => {
    return dispatch(
      subscribeToTopic(topic, (payload) => {
        callback(payload);
      }),
    );
  };
};

/**
 * Action to Subscribe AI Generated Executive Summary Topic
 *
 * @param {number} scenarioId ID of the scenario
 * @param {Function} callback A callback triggered when a subscribed topic
 *   receives a message
 * @returns {import('@/types/extend-redux-toolkit').AppThunk<
 *   Promise<import('@stomp/stompjs').StompSubscription>
 * >}
 *   Dispatchable action
 */
export const subscribeToGetPromptAiResponseAction = (scenarioId, callback) => {
  const topic = `report-summary-text-generation/${scenarioId}`;

  return (dispatch) => {
    return dispatch(
      subscribeToTopic(topic, (payload) => {
        callback(payload);
      }),
    );
  };
};
