// @ts-check
import { useEffect } from 'react';

/**
 * @typedef {{
 *   isLoading?: boolean;
 *   data: {
 *     [key: string]: number;
 *   };
 * }} QueryStateChangeData
 */

/**
 * A custom React hook that triggers a callback, when there are changes in
 * scenario queries.
 *
 * @type {(
 *   scenarioQueries: QueryStateChangeData[],
 *   onQueryStateChange: (data: QueryStateChangeData[]) => void,
 * ) => void}
 */
const useScenarioQueries = (scenarioQueries, onQueryStateChange) => {
  useEffect(() => {
    if (scenarioQueries.length && onQueryStateChange) {
      onQueryStateChange(scenarioQueries);
    }
  }, [scenarioQueries, onQueryStateChange]);
};
export default useScenarioQueries;
