import { createContext, useContext } from 'react';
import { isEmptyOrNull } from '@/helpers/validators';

const DashboardContext = createContext();
const DashboardContextProvider = DashboardContext.Provider;

function useDashboardContext() {
  const context = useContext(DashboardContext);
  if (isEmptyOrNull(context)) {
    throw new Error(
      'useDashboardContext must be used within a DashboardContextProvider',
    );
  }
  return context;
}

export { DashboardContextProvider, useDashboardContext };
