// @ts-check
const INTEGRATION_NAMES = /** @type {const} */ ({
  QUICKBOOKS: 'QUICKBOOKS(deprecated)',
  CODAT_QUICKBOOKS: 'CODAT QUICKBOOKS',
  BIPAAS_QUICKBOOKS: 'BIPAAS QUICKBOOKS',
  CODAT_WAVE: 'CODAT WAVE',
  CODAT_XERO: 'CODAT XERO',
  CODAT_ZOHO: 'CODAT ZOHO',
  CODAT_NETSUITE: 'CODAT NETSUITE',
  FINCH_GUSTO: 'FINCH GUSTO',
  FINCH_ZENEFITS: 'FINCH ZENEFITS',
  FINCH_JUSTWORKS: 'FINCH JUSTWORKS',
  FINCH_ADP: 'FINCH ADP_WORKFORCE_NOW',
  FINCH_RIPPLING: 'FINCH RIPPLING',
  FINCH_BAMBOOHR: 'FINCH BAMBOOHR',
  REVENUE_GSHEETS: 'REVENUE GSHEETS',
  REVENUE_GSHEETS_PAYMENT_PROCESSOR: 'REVENUE GSHEETS PP',
  REVENUE_STRIPE: 'REVENUE STRIPE',
});

export const {
  QUICKBOOKS,
  CODAT_QUICKBOOKS,
  BIPAAS_QUICKBOOKS,
  CODAT_WAVE,
  CODAT_XERO,
  CODAT_ZOHO,
  CODAT_NETSUITE,
  FINCH_GUSTO,
  FINCH_ZENEFITS,
  FINCH_JUSTWORKS,
  FINCH_ADP,
  FINCH_RIPPLING,
  FINCH_BAMBOOHR,
  REVENUE_GSHEETS,
  REVENUE_GSHEETS_PAYMENT_PROCESSOR,
  REVENUE_STRIPE,
} = INTEGRATION_NAMES;

export const integrationFamily = /** @type {const} */ ({
  INTEGRATION_FAMILY_CODAT: 'CODAT',
  INTEGRATION_FAMILY_FINCH: 'FINCH',
  INTEGRATION_FAMILY_REVENUE: 'REVENUE',
});

/**
 * @typedef {(typeof integrationFamily)[keyof typeof integrationFamily]}
 *   IntegrationFamily
 */

/**
 * @type {{
 *   [key in integrationFamily[keyof typeof integrationFamily]]: any;
 * }}
 */
export const integrationManualSyncMap = {
  [integrationFamily.INTEGRATION_FAMILY_CODAT]:
    'manualAccountingSyncInProgress',
  [integrationFamily.INTEGRATION_FAMILY_FINCH]: 'manualPayrollSyncInProgress',
  [integrationFamily.INTEGRATION_FAMILY_REVENUE]: 'manualRevenueSyncInProgress',
};

export const INTEGRATION_ERROR_MESSAGES = {
  INTERNAL_SERVER_ERROR:
    'There are temporary issues with the server. Please try again later.',
  GATEWAY_TIMEOUT_ERROR:
    'Connection to the server timed out. Please try again later.',
  REMOTE_CONNECTION_REFUSED:
    'The remote connection was not established. Please try again later.',
  NO_DATA_FOUND: `Unable to pull expenses, please try again later.`,
  SYNC_ERROR: `Unable to pull expenses, please try again later.`,
};

const ACCOUNTING_INTEGRATIONS_TEXT = /** @type {const} */ ({
  [CODAT_QUICKBOOKS]: 'Quickbooks',
  [BIPAAS_QUICKBOOKS]: 'Quickbooks',
  [CODAT_WAVE]: 'Wave',
  [CODAT_XERO]: 'Xero',
  [CODAT_ZOHO]: 'Zoho Books',
  [CODAT_NETSUITE]: 'NetSuite',
});

const PAYROLL_INTEGRATIONS_TEXT = /** @type {const} */ ({
  [FINCH_GUSTO]: 'Gusto',
  [FINCH_JUSTWORKS]: 'JustWorks',
  [FINCH_ADP]: 'ADP',
  [FINCH_RIPPLING]: 'Rippling',
  [FINCH_ZENEFITS]: 'Zenefits',
  [FINCH_BAMBOOHR]: 'Bamboo',
});

const REVENUE_INTEGRATIONS_TEXT = /** @type {const} */ ({
  [REVENUE_GSHEETS]: 'Google Sheets',
  [REVENUE_GSHEETS_PAYMENT_PROCESSOR]: 'Google Sheets Payment Processor',
  [REVENUE_STRIPE]: 'Stripe',
});

export const INTEGRATIONS_TEXT = {
  ...ACCOUNTING_INTEGRATIONS_TEXT,
  ...PAYROLL_INTEGRATIONS_TEXT,
  ...REVENUE_INTEGRATIONS_TEXT,
};

export const ACCOUNTING_INTEGRATIONS = Object.keys(
  ACCOUNTING_INTEGRATIONS_TEXT,
);

export const PAYROLL_INTEGRATIONS = Object.keys(PAYROLL_INTEGRATIONS_TEXT);

export const REVENUE_INTEGRATIONS = Object.keys(REVENUE_INTEGRATIONS_TEXT);

export const INTEGRATIONS = [
  ...ACCOUNTING_INTEGRATIONS,
  ...PAYROLL_INTEGRATIONS,
  ...REVENUE_INTEGRATIONS,
];

/**
 * @typedef {(typeof ACCOUNTING_INTEGRATIONS_TEXT)[keyof typeof ACCOUNTING_INTEGRATIONS_TEXT]
 *   | (typeof PAYROLL_INTEGRATIONS_TEXT)[keyof typeof PAYROLL_INTEGRATIONS_TEXT]
 *   | (typeof REVENUE_INTEGRATIONS_TEXT)[keyof typeof REVENUE_INTEGRATIONS_TEXT]} Integrations
 */
