import { useEffect, useRef } from 'react';

/**
 * A useEffect hook that will not fire the first time a dependency is changed.
 * Useful for executing code when state is updated due to user actions, NOT on
 * initial fetch.
 *
 * @param {Function} callback
 * @param {any[]} dependencies
 */
export default function useEffectOnUpdate(callback, dependencies) {
  const prevValues = useRef([...dependencies]);
  const updateCounters = useRef(Array(dependencies.length).fill(0));

  useEffect(() => {
    let hasUpdate = false;
    // Count the number of times each dependency has updated
    const counters = updateCounters.current.map((counter, idx) => {
      // Like all hooks, dependencies use referential equality,
      // so all object updates must be immutable (new object each time).
      if (prevValues.current[idx] !== dependencies[idx]) {
        if (counter > 0) hasUpdate = true;
        return counter + 1;
      }
      return counter;
    });

    // If at least one dependency has change since its first update,
    // run the callback.
    if (hasUpdate) callback();

    updateCounters.current = counters;
    prevValues.current = [...dependencies];
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, dependencies);
}
