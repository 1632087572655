import CardWrapper from '@/components/Settings/CardWrapper';
import Header from '@/components/Settings/Header';
import Button from '@/components/common/Button';
import ReferralsModal from './components/ReferralsModal';
import './Referrals.scss';

const Referrals = ({
  showReferralModal,
  setShowReferralModal,
  showAlertAndHideMessage,
}) => {
  return (
    <CardWrapper>
      <Header title="Refer a Friend" data-testid="refer-friend-edit" />
      <div className="Settings_Text">
        Why not treat yourself to <strong>one month free</strong> for{' '}
        <strong>every</strong> friend you refer to us?
        <br />
        Interested? Click{' '}
        <Button
          data-testid="Referral_Button"
          className="Button-primaryLink Referral_Button"
          onClick={() => setShowReferralModal(true)}
        >
          here
        </Button>{' '}
        to continue.
      </div>
      {showReferralModal && (
        <ReferralsModal
          setShow={setShowReferralModal}
          showAlertAndHideMessage={showAlertAndHideMessage}
        />
      )}
    </CardWrapper>
  );
};

export default Referrals;
