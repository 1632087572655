import RadioSelectedIcon from '@bill/cashflow.assets/radio-selected';
import VALID_FORMULA_MSG from '@/constants/formulas';
import { classNames } from '@/helpers';
import './FormulaValidationMessage.scss';

/**
 * Renders a validation message for use with custom formula inputs
 *
 * @example
 *   <FormulaValidationMessage message="VALID_FORMULA_MSG" />;
 */
const FormulaValidationMessage = ({ message }) => {
  switch (message) {
    case VALID_FORMULA_MSG:
      return (
        <div
          id="valid-formula-message"
          data-testid="valid-formula-message"
          className="ValidFormulaMessage"
        >
          <RadioSelectedIcon
            className="ValidationCheckmark"
            role="button"
            aria-label="checked"
            checked
          />
          <span className="Checkbox_Label">{VALID_FORMULA_MSG}</span>
        </div>
      );
    default:
      return (
        <p
          id="customFormulaError"
          className={classNames('FormField_Error', 'FormField_Error-formula')}
          data-testid="customFormulaError"
        >
          {message}
        </p>
      );
  }
};

export default FormulaValidationMessage;
