import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';

/**
 * Check if the passed feature flag is enabled
 *
 * @param {import('@/constants/features').FeatureFlag} feature - The feature
 *   flag to check
 * @returns {boolean} TRUE if the feature flag is enabled
 */
const useFeatureFlags = (feature) => {
  const { flags } = useSelector(({ features }) => features);
  return useMemo(() => flags.includes(feature), [feature, flags]);
};

export default useFeatureFlags;
