export const errorCodes = {
  SIZE_LIMIT: 'file-too-large',
  INVALID_EXTENSION: 'file-invalid-type',
};

export const statuses = {
  INIT: 'init',
  FILE_SELECTED: 'fileSelected',
  FILE_SELECTED_ERROR: 'fileSelectedError',
  UPLOADING: 'uploading',
  UPLOADING_COMPLETE: 'uploading_complete',
  ERROR: 'error',
};

export const acceptedFileExtensionTypes = {
  XLSX: {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ],
  },
  XLS: { 'application/vnd.ms-excel': ['.xls'] },
  CSV: { 'text/csv': ['.csv'] },
};
