import { useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import ComponentLoader from '@/components/common/ComponentLoader';
import InfoTooltip from '@/components/common/InfoTooltip';
import TogglePersistent from '@/components/common/TogglePersistent';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import useChartPreferences from '@/hooks/useChartPreferences';
import useDashboardPath from '@/hooks/useDashboardPath';
import ChurnedMrrChart, {
  CHURNED_MRR_TOGGLE_ID,
} from './charts/ChurnedMrrChart';

const CHART_TITLE = 'Churned MRR';

const ChurnedMrr = ({ startDate, endDate }) => {
  const [churnedMrr, setChurnedMrr] = useState({});
  const chartRef = useRef();
  const showPercentage = useChartPreferences(CHURNED_MRR_TOGGLE_ID);
  const dashboardPath = useDashboardPath();
  const onQueryStateChange = useCallback(({ data, isLoading }) => {
    setChurnedMrr({ ...data, isLoading });
  }, []);

  return (
    <section className="Panel Panel-toEdge">
      <ChartHeader back={dashboardPath} title={CHART_TITLE}>
        <div className="ChartHeader_KPIValue">
          {churnedMrr.isLoading ? (
            ''
          ) : (
            <>
              {churnedMrr.metric}{' '}
              {churnedMrr.tooltipText && (
                <InfoTooltip data-testid="new-mrr-tooltip">
                  {churnedMrr.tooltipText}
                </InfoTooltip>
              )}
            </>
          )}
        </div>
        <div className="ChartHeader_KPI">
          {showPercentage ? (
            <span className="ChartHeader_Subtitle">Average for:</span>
          ) : (
            <br />
          )}
          {formatRangeWithShortYear(startDate, endDate)}
        </div>
      </ChartHeader>
      <ComponentLoader loadingComponent="churnedMRR" paddingTop="0%" />
      <ChartToolbar>
        <TogglePersistent
          id={CHURNED_MRR_TOGGLE_ID}
          options={[
            {
              label: 'Currency',
              value: false,
            },
            {
              label: 'Percent',
              value: true,
            },
          ]}
          value={false}
        />
        <ChartControls
          chartRef={chartRef}
          title={CHART_TITLE}
          metricValue={churnedMrr.metric}
          label={
            showPercentage
              ? `Average for: ${formatRangeWithShortYear(startDate, endDate)}`
              : formatRangeWithShortYear(startDate, endDate)
          }
          data-testid="churned-mrr-chart-export"
        />
      </ChartToolbar>
      <ChurnedMrrChart
        className="ExpandedView_ChartWrapper"
        exportBtn={chartRef}
        showPercentage={showPercentage}
        onQueryStateChange={onQueryStateChange}
      />
    </section>
  );
};

function mapStateToProps({ shared }) {
  return {
    startDate: shared.startDate,
    endDate: shared.endDate,
  };
}

export default connect(mapStateToProps)(ChurnedMrr);
