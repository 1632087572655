// @ts-check
import { useMemo } from 'react';
import { BIPAAS_QUICKBOOKS, CODAT_QUICKBOOKS } from '@/constants/integrations';
import { REFRESH_TOKEN_EXPIRED } from '@/constants/settings';
import useTypedSelector from '@/hooks/useTypedSelector';

/**
 * Determines if QuickBooks integrations are unauthorized due to an expired
 * refresh token.
 *
 * @type {() => Boolean}
 */
const useQBOUnauthorizedIntegrations = () => {
  const integrations = useTypedSelector(
    ({ settings }) => settings.integrations ?? [],
  );
  return useMemo(
    () =>
      Boolean(
        integrations.find(
          ({ type, status }) =>
            (type === BIPAAS_QUICKBOOKS || type === CODAT_QUICKBOOKS) &&
            status === REFRESH_TOKEN_EXPIRED,
        ),
      ),
    [integrations],
  );
};

export default useQBOUnauthorizedIntegrations;
