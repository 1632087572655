import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import RevenueEmptyIcon from '@bill/cashflow.assets/revenue-empty';
import {
  deleteFinancingAction,
  getFinancingSourceListAction,
} from '@/actions/revenue';
import ContextMenuRenderer from '@/components/Revenue/ContextMenuRenderer';
import RevenueTitleBar from '@/components/Revenue/List/RevenueTitleBar';
import EmptyData from '@/components/common/EmptyData';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import CellRendererSelector from '@/components/common/Spreadsheet/renderers/CircularRefCellRendererSelector';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import { revenueFamily } from '@/constants/revenue';
import {
  getDatesInRange,
  getFormattedDateFromTimeStamp,
} from '@/helpers/dateFormatter';
import useCallbackRef from '@/hooks/useCallbackRef';
import useGridRefreshCells from '@/hooks/useGridRefreshCells';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import { GRID_OPTIONS } from '@/pages/Reports/constants';
import './FinancingList.scss';

const SPREADSHEET_ID = 'revenueFinancingGrid';
const NAME = 'name';

const formatData = (financingSourceList, monthsList) => {
  return financingSourceList.map((row) => {
    return {
      ...row,
      hierarchy: [row.id],
      months: monthsList.map((timestamp) => {
        const formattedMonth = getFormattedDateFromTimeStamp(timestamp);
        const monthRecord = row.monthlyValues.find(({ month }) => {
          return month === formattedMonth;
        });
        return {
          date: formattedMonth,
          value: monthRecord?.value,
        };
      }),
    };
  });
};

const FinancingList = ({ onEdit }) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const [gridApi, ref] = useCallbackRef();
  const [financingIdToDelete, setFinancingIdToDelete] = useState(null);
  const [deleteError, setDeleteError] = useState('');
  const [monthsList, setMonthsList] = useState([]);

  const financingSourceList = useSelector(
    ({ revenues }) => revenues.financingSourceList,
  );
  const lastUpdatedRow = useSelector(
    ({ revenues }) => revenues.lastUpdatedFinancingItem,
  );
  const startDate = useSelector(({ shared }) => shared.startDate);
  const endDate = useSelector(({ shared }) => shared.endDate);
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);

  const hasWritePermission = useNonDashboardWritePermission();

  const hasData = financingSourceList.length > 0;

  useEffect(() => {
    dispatch(getFinancingSourceListAction({ scenarioId, startDate, endDate }));
    setMonthsList(getDatesInRange(startDate, endDate));
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [startDate, endDate, scenarioId]);

  const handleDelete = async () => {
    try {
      await dispatch(deleteFinancingAction(scenarioId, financingIdToDelete));
    } catch (e) {
      setDeleteError(e.response?.data?.error?.errorMessage || e.message);
    } finally {
      setFinancingIdToDelete(null);
    }
  };

  const data = useMemo(
    () => (hasData ? formatData(financingSourceList, monthsList) : []),
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [financingSourceList, monthsList],
  );

  const colDefs = useMemo(() => {
    return [
      {
        field: NAME,
        headerName: '',
        headerComponent: HeaderRenderer,
        cellRendererSelector: CellRendererSelector,
        cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
      },
      {
        colId: 'actions',
        type: 'actions',
        cellRenderer: ContextMenuRenderer,
        cellRendererParams: {
          onEdit,
          hasWritePermission,
          onDelete: setFinancingIdToDelete,
          family: revenueFamily.FINANCING,
        },
      },
    ];
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [data, hasWritePermission]);

  useGridRefreshCells({ gridApi, rowId: lastUpdatedRow?.id, columnId: NAME }, [
    lastUpdatedRow,
  ]);

  return (
    <div className="RevenueTable">
      <RevenueTitleBar heading="Financing" gridApi={gridApi} />
      {hasData ? (
        <>
          <MonthlySpreadsheet
            {...GRID_OPTIONS}
            ref={ref}
            columnDefs={colDefs}
            data-testid={SPREADSHEET_ID}
            data={data}
          />
        </>
      ) : (
        <EmptyData Icon={RevenueEmptyIcon}>
          Add Financing for this grid to populate.
        </EmptyData>
      )}
      {!!financingIdToDelete && (
        <ModalConfirmation
          id="delete-financing-modal"
          title="Delete Financing"
          onAction={handleDelete}
          onCancel={() => setFinancingIdToDelete(null)}
        >
          This action cannot be undone. Are you sure you want to proceed?
        </ModalConfirmation>
      )}
      {deleteError && (
        <ModalConfirmation
          id="modal-financing-item-cannot-delete"
          onAction={() => setDeleteError('')}
          title="Cannot delete financing"
          actionBtnTxt="I understand"
        >
          {deleteError}
        </ModalConfirmation>
      )}
    </div>
  );
};

export default FinancingList;
