// @ts-check
import { actualsFamily } from '@/constants/actuals';
import {
  CASH_IN_TYPE,
  CASH_OUT_TYPE,
  cashInOutMetrics,
  DEFAULT_ROWS,
  TOTAL_CASH_IN_ID,
  TOTAL_CASH_OUT_ID,
} from '@/constants/cashInOut';
import { isEmptyOrNull } from '@/helpers/validators';

/** @typedef {import('@/actions/actuals').CashActualsValuesPayload} CashActualsValuesPayload */
/** @typedef {import('@/types/services/backend').CashInOutGrid} CashInOutGrid */
/** @typedef {import('@/types/services/backend').CashInOutGridItem} CashInOutGridItem */
/** @typedef {import('@/actions/fpaLite').CashGridWebSocketPayload} CashGridWebSocketPayload */

/** @typedef {[CashInOutGrid, string]} GridMetricUpdate */

/** @type {(data: CashInOutGrid[], type: String) => CashInOutGrid} */
const findMetric = (data, type) =>
  data.find(({ externalAccountId }) => externalAccountId === type);

/**
 * @type {(
 *   x: CashActualsValuesPayload | CashGridWebSocketPayload,
 * ) => x is CashActualsValuesPayload}
 */
const isCashActualsValuesPayload = (x) => Reflect.has(x, 'displayFormula');

/**
 * @type {(
 *   months: CashInOutGridItem[],
 *   updates: CashActualsValuesPayload[] | CashGridWebSocketPayload[],
 * ) => CashInOutGridItem[]}
 */
const updateMonths = (months, updates) => {
  return months.map((monthlyVal) => {
    const update = updates.find(({ month }) => month === monthlyVal.date);
    if (!update) {
      return monthlyVal;
    }

    const scenarioVals = [...monthlyVal.value];
    const { scenarioId, value } = update;
    const valueIdx = scenarioVals.findIndex(
      (scenario) => scenario.scenarioId === scenarioId,
    );
    scenarioVals[valueIdx] = {
      ...scenarioVals[valueIdx],
      value,
    };
    if (isCashActualsValuesPayload(update)) {
      scenarioVals[valueIdx].displayFormula = update.displayFormula;
    }

    return {
      ...monthlyVal,
      value: scenarioVals,
    };
  });
};

/**
 * @type {(params: {
 *   payload: CashActualsValuesPayload;
 *   rowData: CashInOutGrid[];
 * }) => [] | GridMetricUpdate}
 */
export const updateCashGridMetric = ({ payload, rowData }) => {
  const metric = findMetric(rowData, cashInOutMetrics[payload.type]);
  if (!metric) {
    return [];
  }

  const months = updateMonths(metric.months, [payload]);
  return [
    {
      ...metric,
      months,
    },
    metric.id,
  ];
};

/**
 * @type {function({
 * payload: CashGridWebSocketPayload[],
 * rowData: CashInOutGrid[], type: string}):
 * [] | GridMetricUpdate}
 */
export const updateCashFlowAndAdjustmentMetrics = ({
  payload,
  rowData,
  type,
}) => {
  const metric = findMetric(rowData, type);
  if (!metric) {
    return [];
  }

  const months = updateMonths(metric.months, payload);
  return [
    {
      ...metric,
      months,
    },
    metric.id,
  ];
};

/**
 * @type {(
 *   rowData: CashInOutGrid[],
 *   payload: import('@/actions/fpaLite').CashAccountActualsUpdatedPayload,
 * ) => CashInOutGrid[]}
 */
export const updateCashAccountActualsUpdates = (rowData, payload) => {
  const index = rowData.findIndex((entry) => {
    return entry.id === payload.cashAccountId;
  });
  if (index !== -1) {
    rowData[index].months = rowData[index].months.map((month) => {
      const newValue = payload.values.find((value) => {
        return value.month === month.date;
      });
      if (newValue) {
        month.value = month.value.map((val) => {
          return {
            ...val,
            ...newValue,
          };
        });
      }
      return month;
    });
  }
  return rowData;
};

/**
 * @type {(
 *   data: import('@/types/cashInOutGrid').CashInOutGridColumns[],
 *   parentId: string,
 *   targetCashFlowType: string,
 * ) => import('@/types/cashInOutGrid').CashInOutGridColumns[]}
 */
const findChild = (data, parentId, targetCashFlowType) =>
  data.filter(
    ({ id, cashFlowType }) =>
      id !== parentId && cashFlowType === targetCashFlowType,
  );

/**
 * Transform the Cash Grid data for reports grid
 *
 * @type {(
 *   data: import('@/types/cashInOutGrid').CashInOutGridColumns[],
 * ) => import('@/types/services/backend').CashInOutGrid[]}
 */
export const restructureCashInOutData = (data) => {
  const cashInChild = findChild(data, TOTAL_CASH_IN_ID, CASH_IN_TYPE);
  const cashOutChild = findChild(data, TOTAL_CASH_OUT_ID, CASH_OUT_TYPE);

  return data
    .reduce((accum, row) => {
      const family =
        row.cashFlowType === CASH_IN_TYPE
          ? actualsFamily.REVENUE
          : actualsFamily.EXPENSE;

      let children = [];
      if (row.id === TOTAL_CASH_IN_ID) {
        children = cashInChild;
      } else if (row.id === TOTAL_CASH_OUT_ID) {
        children = cashOutChild;
      }

      accum.push({ ...row, family, children });

      return accum;
    }, [])
    .filter((row) => DEFAULT_ROWS.has(row.id));
};

/**
 * @type {(
 *   a: string | import('@/hooks/useReportData').MonthValue,
 *   b: string | import('@/hooks/useReportData').MonthValue,
 *   nodeA: import('ag-grid-community').RowNode<
 *     import('@/types/cashInOutGrid').CashInOutGridColumns
 *   >,
 *   nodeB: import('ag-grid-community').RowNode<
 *     import('@/types/cashInOutGrid').CashInOutGridColumns
 *   >,
 * ) => number}
 */
export const customSortComparator = (a, b, nodeA, nodeB) => {
  const isKeyRow =
    DEFAULT_ROWS.has(nodeA.data.id) || DEFAULT_ROWS.has(nodeB.data.id);
  if (isKeyRow || isEmptyOrNull(a) || isEmptyOrNull(b)) return 0;

  const aValue = typeof a === 'object' && 'value' in a ? a.value : a;
  const bValue = typeof b === 'object' && 'value' in b ? b.value : b;

  const aNum = Number(aValue);
  const bNum = Number(bValue);

  return !Number.isNaN(aNum) && !Number.isNaN(bNum)
    ? aNum - bNum
    : String(aValue).localeCompare(String(bValue));
};
