// @ts-check
import ContextMenu from '@/components/common/ContextMenu';

/** @typedef {import('@/services/revenueService').RevenueIntegrationEntry} RevenueIntegrationEntry */

/**
 * @typedef {{
 *   data: RevenueIntegrationEntry;
 *   onEditIntegrationRecord: (
 *     integrationRecord: RevenueIntegrationEntry,
 *   ) => void;
 *   onDeleteRecord: (string) => void;
 * }} ContextMenuRendererProps
 */

/** @type {(props: ContextMenuRendererProps) => React.ReactElement} */
const ContextMenuRenderer = ({
  data,
  onEditIntegrationRecord,
  onDeleteRecord,
}) => {
  return (
    <ContextMenu data-testid={`${data.id}-revenue-entry-action`}>
      <ContextMenu.Option
        data-testid="edit-entry"
        onClick={() => onEditIntegrationRecord(data)}
      >
        Edit Entry
      </ContextMenu.Option>
      <ContextMenu.Option
        data-testid="delete-entry"
        danger
        onClick={() => onDeleteRecord(data.id)}
      >
        Delete Entry
      </ContextMenu.Option>
    </ContextMenu>
  );
};

export default ContextMenuRenderer;
