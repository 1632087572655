import { forwardRef, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import Widget from './Widget';
import WidgetKPI from './WidgetKPI';
import PayrollChart from './charts/PayrollChart';

const TotalPayrollGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [{ data, hasData }, setTotalPayrollData] = useState({});
    const { startDate, endDate } = useSelector(({ shared }) => shared);
    const onQueryStateChange = useCallback((payload) => {
      setTotalPayrollData(payload);
    }, []);

    return (
      <ChartWidget
        ref={ref}
        title="Total Payroll"
        url="/dashboard/total-payroll"
        data-testid="payrollWidget"
        metricValue={
          hasData ? formatMonetary(data.totalPayroll) : 'Awaiting Data'
        }
        label={formatRangeWithShortYear(startDate, endDate)}
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={
                hasData ? formatRangeWithShortYear(startDate, endDate) : ''
              }
              value={
                hasData ? formatMonetary(data.totalPayroll) : 'Awaiting Data'
              }
            />
            <PayrollChart
              {...childProps}
              onQueryStateChange={onQueryStateChange}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default TotalPayrollGlance;
