import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { getEmployees as getEmployeesAction } from '@/actions/employees';
import PageHeader from '@/components/Layouts/PageHeader';
import TableExportButton from '@/components/TableExportButton';
import ComponentLoader from '@/components/common/ComponentLoader';
import ErrorDiv from '@/components/common/ErrorDiv';
import Tabs from '@/components/common/Tabs';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import ActualsGrid from '@/pages/Actuals/ActualsGrid';
import Cash from '@/pages/Actuals/CashActuals';

const CASH = '/cash';

const TabBody = ({ children, error }) => {
  return (
    <div className="actuals-agGrid">
      {error && <ErrorDiv error={error} />}
      {children}
    </div>
  );
};

const Actuals = ({ error, getEmployees, startDate, endDate, scenarioId }) => {
  const gridApi = useRef(null);
  const hasWritePermission = useNonDashboardWritePermission();
  const { path } = useRouteMatch();

  useEffect(() => {
    getEmployees(scenarioId, startDate, endDate);
  }, [scenarioId, startDate, endDate, getEmployees]);

  return (
    <>
      <PageHeader page="/actuals" />
      <div className="PageLayout">
        <section className="Panel Panel-toEdge">
          <ComponentLoader loadingComponent="actuals" paddingTop="0%" />
          <div data-testid="actualsTabs">
            <Tabs
              controls={
                <TableExportButton
                  onClick={() => gridApi.current.api.exportDataAsExcel()}
                />
              }
            >
              <Tabs.Panel path={path} label="Actuals">
                <TabBody error={error}>
                  <ActualsGrid apiRef={gridApi} />
                </TabBody>
              </Tabs.Panel>
              <Tabs.Panel path={`${path}${CASH}`} label="Cash">
                <TabBody error={error}>
                  <Cash
                    apiRef={gridApi}
                    hasWritePermission={hasWritePermission}
                  />
                </TabBody>
              </Tabs.Panel>
            </Tabs>
          </div>
        </section>
      </div>
    </>
  );
};

const mapStateToProps = ({ actuals, shared, scenario }) => {
  return {
    error: actuals.error,
    startDate: shared.startDate,
    endDate: shared.endDate,
    scenarioId: scenario.scenarioId,
  };
};

export default connect(mapStateToProps, {
  getEmployees: getEmployeesAction,
})(Actuals);
