/**
 * Transform the data in flat structure for grid
 *
 * @param {Array} data Data to transform into flat structure
 * @param {Array} parentHierarchy Hierarchy of a row
 * @param {Array} parentKeys Key of the parent rows
 * @returns {Array}
 */
const transformDataForGrid = (data, parentHierarchy = [], parentKeys = []) => {
  let flatData = [];
  data.forEach((row) => {
    const hierarchy = [...parentHierarchy, row.id];
    flatData.push({
      ...row,
      children: undefined,
      hierarchy,
      parentKeys,
      childrenIds: row.children?.map((child) => child.id) ?? [],
    });
    if (row.children) {
      flatData = [
        ...flatData,
        ...transformDataForGrid(
          row.children,
          hierarchy,
          row.key ? [...parentKeys, row.key] : parentKeys,
        ),
      ];
    }
  });
  return flatData;
};

export default transformDataForGrid;
