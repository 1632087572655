import CrossIcon from '@bill/cashflow.assets/cross';
import PropTypes from 'prop-types';
import useNotificationContext from '@/contexts/useNotificationContext';
import { classNames } from '@/helpers';
import './NotificationBanner.scss';

export const notificationTypes = {
  ERROR: 'NotificationBanner-error',
  SUCCESS: 'NotificationBanner-success',
  WARN: 'NotificationBanner-warn',
  INFO: 'NotificationBanner-info',
};

/**
 * Renders a Banner
 *
 * @example
 *   <NotificationBanner type={notificationTypes.SUCCESS}>
 *     This text appears in the banner
 *   </NotificationBanner>;
 */
const NotificationBanner = ({ children, onCloseClick, type }) => {
  const { margin } = useNotificationContext();
  return (
    <div
      className={classNames(`NotificationBanner`, type)}
      style={{ marginTop: margin }}
    >
      {onCloseClick && (
        <button className="NotificationBanner_CloseBtn" onClick={onCloseClick}>
          <CrossIcon aria-label="Close" />
        </button>
      )}
      <span>{children}</span>
    </div>
  );
};

NotificationBanner.propTypes = {
  /** Content of the notification */
  children: PropTypes.node.isRequired,
  /**
   * Event handler for when the user clicks the close button. If no handler is
   * provided, the button will not appear.
   *
   * @param {Object} event
   */
  onCloseClick: PropTypes.func,
  /** Type of notification to display, e.g. success */
  type: PropTypes.oneOf(Object.values(notificationTypes)).isRequired,
};

export default NotificationBanner;
