import { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import PieChart from '@/components/Charts/PieChart';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import './PieChartComparison.scss';

/**
 * Displays a scenario comparison view of pie charts
 *
 * @example
 *   <PieChartComparison
 *     data={query.data}
 *     loading={query.isLoading}
 *     data-testid="foo"
 *   />;
 */
function PieChartComparison({
  data,
  'data-testid': dataTestId,
  loading,
  onChartCreated,
  url,
  ...props
}) {
  const createdChart = useRef([]);
  const seriesByScenario = useMemo(() => {
    if (loading) return [];

    const scenarioMap = data.reduce((accum, series) => {
      if (series.scenario) {
        const { scenarioId } = series.scenario;
        const scenarioSeries = accum[scenarioId] ?? [];
        accum[scenarioId] = [...scenarioSeries, series];
      }
      return accum;
    }, {});

    return Object.entries(scenarioMap);
  }, [data, loading]);

  return (
    <div className="PieChartComparison">
      {loading ? (
        <LoadingSpinner />
      ) : (
        seriesByScenario.map(([scenarioId, series], idx) => (
          <PieChart
            key={scenarioId}
            data={series}
            data-testid={`${dataTestId}-${scenarioId}`}
            isComparison={idx > 0}
            onChartCreated={(chart) => {
              if (chart.axes) {
                const prevCharts = createdChart.current;
                prevCharts[chart.index] = chart;
                createdChart.current = prevCharts;
              }
              const filteredCharts = createdChart.current.filter((d) => d.axes);
              onChartCreated?.(
                filteredCharts.length > 1 ? filteredCharts : chart,
              );
            }}
            showScenario={seriesByScenario.length > 1}
            url={idx === 0 ? url : undefined}
            {...props}
          />
        ))
      )}
    </div>
  );
}

PieChartComparison.propTypes = {
  /** Data to populate the chart(s) */
  'data': PropTypes.arrayOf(PropTypes.object),
  /** ID for selecting the chart container in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Whether or not a loading indicator should be displayed */
  'loading': PropTypes.bool,
  /**
   * Callback for accessing the chart object from the parent, to pass to
   * dependent components such as the legend or export button
   *
   * @param {Highcharts.Chart} chart
   */
  'onChartCreated': PropTypes.func,
  /** Relative URL to the expanded view for the chart */
  'url': PropTypes.string,
};

export default PieChartComparison;
