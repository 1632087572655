import { forwardRef, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import ChartRadioGroupPersistent from '@/components/common/ChartRadioGroupPersistent';
import Switch from '@/components/common/Switch';
import { EXPENSE_CLASS, EXPENSE_DEPT } from '@/constants/expenses';
import {
  TOTAL_EXPENSES_SWITCH_ID,
  TOTAL_EXPENSES_TOGGLE_ID,
} from '@/constants/ui';
import { classNames } from '@/helpers';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import useChartPreferences from '@/hooks/useChartPreferences';
import useOneColor from '@/hooks/useOneColor';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import TotalExpensesChart from '@/pages/Dashboard/charts/TotalExpensesChart';

const TotalExpenses = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [isLoading, setIsLoading] = useState(true);
    const [total, setTotal] = useState();
    const { startDate, endDate } = useSelector(({ shared }) => shared);
    const includePayroll = useChartPreferences(TOTAL_EXPENSES_SWITCH_ID);
    const isOneColorEnabled = useOneColor();

    const onQueryStateChange = useCallback(([base]) => {
      setIsLoading(base.isLoading);
      setTotal(base.data.overallTotal);
    }, []);

    return (
      <ChartWidget
        ref={ref}
        title="Total Expenses"
        url="/dashboard/total-expenses"
        data-testid="totalExpensesWidget"
        metricValue={formatMonetary(total)}
        label={formatRangeWithShortYear(startDate, endDate)}
        controls={
          <>
            <ChartRadioGroupPersistent
              id={TOTAL_EXPENSES_TOGGLE_ID}
              options={[
                {
                  label: 'Category',
                  value: EXPENSE_CLASS,
                },
                {
                  label: 'Dept',
                  value: EXPENSE_DEPT,
                },
              ]}
              value={EXPENSE_CLASS}
              className="ControlDropdown_Child"
            />
            <Switch
              id={TOTAL_EXPENSES_SWITCH_ID}
              on={includePayroll ?? true}
              label="Include Payroll"
              className={classNames(
                'ControlDropdown_Child',
                isOneColorEnabled && 'ControlDropdown_Child-forecasting',
              )}
            />
          </>
        }
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={
                !isLoading ? formatRangeWithShortYear(startDate, endDate) : ''
              }
              value={!isLoading ? formatMonetary(total) : 'Awaiting Data'}
            />
            <TotalExpensesChart
              {...childProps}
              onQueryStateChange={onQueryStateChange}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default TotalExpenses;
