// @ts-check
import { useState, useEffect, useRef, useCallback } from 'react';
import WithPopover from '@/components/common/WithPopover';
import { classNames } from '@/helpers';
import './WithAutocomplete.scss';

const KEY_DOWN = 'ArrowDown';
const KEY_TAB = 'Tab';
const KEY_UP = 'ArrowUp';
const KEY_BACKSPACE = 'Backspace';

const REGISTERED_KEYS = [KEY_TAB, KEY_DOWN, KEY_UP, KEY_BACKSPACE];

/** @typedef {HTMLInputElement | HTMLTextAreaElement} WithAutocompleteElement */

/**
 * @typedef {{
 *   id: string;
 *   value: string;
 *   label: string;
 * }} Option
 */

/**
 * @typedef {{
 *   isAutoCompleteFocused: boolean;
 * }} ChildrenParam
 */

/** @typedef {FocusEvent & { target: WithAutocompleteElement }} FocusInputEvent */

/**
 * @typedef {{
 *   'children': (param: ChildrenParam) => React.ReactElement;
 *   'options': Option[];
 *   'onClick': (param: Option) => void;
 *   'data-testid': string;
 *   'className': string;
 *   'onBackspace'?: () => void;
 *   'inputRef': React.MutableRefObject<WithAutocompleteElement>;
 *   'onFocus'?: (event?: FocusInputEvent) => void;
 *   'onBlur'?: (event?: FocusInputEvent) => void;
 * }} WithAutocompleteProps
 */

/**
 * The autocomplete menu for an input
 *
 * @example
 *   <WithAutocomplete
 *     options={[
 *       {
 *         id: 'foo',
 *         name: 'Foo',
 *       },
 *     ]}
 *     onClick={(event) => doSomethingWith(event)}
 *     showMenu
 *   >
 *     <input {...props} />
 *   </WithAutocomplete>;
 *
 * @type {(props: WithAutocompleteProps) => React.ReactElement}
 */
const WithAutocomplete = ({
  children,
  options,
  onClick,
  'data-testid': dataTestId,
  className,
  onBackspace,
  inputRef,
  onFocus,
  onBlur,
  ...props
}) => {
  const [isVisible, setVisible] = useState(false);
  const [currentFocusIndex, setCurrentFocusIndex] = useState(null);
  const [isAutoCompleteFocused, setAutoCompleteFocused] = useState(false);
  const optionsRef = useRef([]);
  /** @type {React.MutableRefObject<ReturnType<typeof setTimeout>>} */
  const timeoutRef = useRef();

  /** @type {(event: FocusInputEvent) => void} */
  const handleFocus = useCallback(
    (event) => {
      clearTimeout(timeoutRef.current);
      onFocus?.(event);
    },
    [onFocus],
  );

  /** @type {(event: FocusInputEvent) => void} */
  const handleBlur = useCallback(
    (event) => {
      if (!isAutoCompleteFocused) {
        timeoutRef.current = setTimeout(() => {
          onBlur?.(event);
        }, 250);
      }
    },
    [onBlur, isAutoCompleteFocused],
  );

  useEffect(() => {
    const { current } = inputRef;

    current?.addEventListener('focus', handleFocus);
    current?.addEventListener('blur', handleBlur);
    return () => {
      current?.removeEventListener('focus', handleFocus);
      current?.removeEventListener('blur', handleBlur);
    };
  }, [inputRef, handleFocus, handleBlur]);

  const setFocus = useCallback(() => {
    if (optionsRef.current.length && currentFocusIndex !== null) {
      optionsRef.current[currentFocusIndex].focus();
    }
  }, [currentFocusIndex]);

  const setFocusToFirstElement = useCallback(() => {
    setAutoCompleteFocused(true);
    setCurrentFocusIndex(0);
  }, [setAutoCompleteFocused, setCurrentFocusIndex]);

  const handleKey = (newFocus) => {
    const numberOfOptions = options.length;
    const lastOptionIndex = numberOfOptions - 1;

    if (numberOfOptions > 0) {
      switch (newFocus) {
        case KEY_TAB:
          onClick(options[currentFocusIndex]);
          break;
        case KEY_DOWN:
          if (currentFocusIndex < lastOptionIndex) {
            setCurrentFocusIndex(currentFocusIndex + 1);
          }
          break;
        case KEY_UP:
          if (currentFocusIndex > 0) {
            setCurrentFocusIndex(currentFocusIndex - 1);
          } else {
            inputRef.current.focus();
            setAutoCompleteFocused(false);
            setCurrentFocusIndex(null);
          }
          break;
        case KEY_BACKSPACE:
          onBackspace?.();
          break;
        default:
          throw new Error('The pointed direction of focus is invalid');
      }
    }
  };

  const handleInputFocus = useCallback(() => {
    setVisible(options.length > 0);
  }, [options]);

  const handleClose = useCallback(() => {
    setAutoCompleteFocused(false);
    setVisible(false);
    setCurrentFocusIndex(null);
  }, []);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        setFocusToFirstElement();
      }
    },
    [setFocusToFirstElement],
  );

  useEffect(() => {
    const { current } = inputRef;
    if (!current) return undefined;

    current.addEventListener('focus', handleInputFocus);
    return () => {
      current.removeEventListener('focus', handleInputFocus);
    };
  }, [handleInputFocus, handleClose, inputRef]);

  useEffect(() => {
    const { current } = inputRef;
    if (!current) return undefined;

    current.addEventListener('keydown', handleKeyDown);
    return () => current.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown, inputRef]);

  useEffect(() => {
    setAutoCompleteFocused(options.length > 0);
    setVisible(options.length > 0);
    setCurrentFocusIndex(null);
  }, [options]);

  useEffect(() => {
    setFocus();
  }, [setFocus]);

  return (
    <div className={classNames('Autocomplete_Container', className)}>
      <WithPopover
        className="Popover-scrollable"
        content={
          options.length > 0 && (
            <div
              onKeyDown={(event) => {
                const { key } = event;

                if (REGISTERED_KEYS.includes(key)) {
                  event.preventDefault();
                  handleKey(key);
                }
              }}
            >
              {options.map((option, index) => {
                return (
                  <button
                    ref={(element) => {
                      if (element) {
                        optionsRef.current[index] = element;
                      }
                    }}
                    key={option.id}
                    id={option.id}
                    className="Autocomplete_Option"
                    onClick={() => onClick(option)}
                    data-testid={`${option.label}-Autocomplete_Option`}
                  >
                    {option.label}
                  </button>
                );
              })}
            </div>
          )
        }
        visible={isVisible}
        onClose={handleClose}
        data-testid={dataTestId}
        placement="bottom-start"
        {...props}
      >
        {children({ isAutoCompleteFocused })}
      </WithPopover>
    </div>
  );
};

export default WithAutocomplete;
