// @ts-check
import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { getMembersAction } from '@/actions/settings';
import { userRoles } from '@/components/BillOnboarding/constants';
import Button from '@/components/common/Button';
import ErrorMessages from '@/components/common/ErrorMessages';
import Select from '@/components/common/Select';
import Table from '@/components/common/Table';
import { ScenarioRoles, ScenarioRolesText } from '@/constants/scenario';
import { classNames, getKeyByValue, showUserRoleText } from '@/helpers';
import useTypedSelector from '@/hooks/useTypedSelector';
import { setRolesAndPermissions } from '@/services/settingsService';
import { ReactComponent as FeedbackInfoIcon } from '@/assets/icons/svg/feedback_info.svg';
import './SetupRolesAndPermissions.scss';

const userRolesText = {
  ROLE_ADMIN: 'Admin',
  ROLE_USER: 'User',
};

/** @typedef {(payload: Partial<UserPermissionPayload>, index: number) => void} PermissionChangeHandler */

/**
 * @type {(props: {
 *   user: import('@/types/services/backend').CompanyUserDto;
 *   index?: number;
 *   payload?: UserPermissionPayload[];
 *   onPermissionChange?: PermissionChangeHandler;
 *   isLoggedInUser?: boolean;
 * }) => React.ReactElement}
 */
const UserPermissionRow = ({
  user,
  index,
  payload,
  onPermissionChange,
  isLoggedInUser,
}) => {
  return (
    <tr className="Table_Row">
      <td
        className={classNames(
          'Table_Cell',
          isLoggedInUser && 'Table_Cell-disabled',
        )}
        data-testid={`onboarding-user-name-${user.id}`}
      >
        {user.name}
      </td>
      <td
        className={classNames(
          'Table_Cell',
          isLoggedInUser && 'Table_Cell-disabled',
        )}
        data-testid={`onboarding-user-email-${user.id}`}
      >
        {user.email}
      </td>
      <td className="Table_Cell-select">
        <Select
          data-testid={`onboarding-company-role-${user.id}`}
          name="onboardingCompanyRole"
          disabled={isLoggedInUser}
          value={payload?.[index]?.companyRole}
          onChange={({ target }) =>
            onPermissionChange(
              {
                companyRole: target.value,
                ...(target.value === userRoles.ADMIN && {
                  scenarioRole: ScenarioRoles.EDITOR,
                }),
              },
              index,
            )
          }
        >
          {Object.values(userRoles).map((role) => (
            <option key={role} value={role}>
              {showUserRoleText(userRolesText[role])}
            </option>
          ))}
        </Select>
      </td>
      <td className="Table_Cell-select">
        <Select
          data-testid={`onboarding-scenario-role-${user.id}`}
          name="onboardingScenarioRole"
          value={
            isLoggedInUser
              ? ScenarioRoles.EDITOR
              : payload?.[index]?.scenarioRole
          }
          onChange={({ target }) =>
            onPermissionChange({ scenarioRole: target.value || null }, index)
          }
          disabled={
            isLoggedInUser || payload?.[index]?.companyRole === userRoles.ADMIN
          }
        >
          <option value="">No Access</option>
          {Object.keys(ScenarioRoles).map((key) => (
            <option key={key} value={key}>
              {ScenarioRolesText[key]}
            </option>
          ))}
        </Select>
      </td>
    </tr>
  );
};

/**
 * @typedef {{
 *   userId: number;
 *   companyRole: string;
 *   scenarioRole: string;
 * }} UserPermissionPayload
 */

/** @typedef {{ isDirty?: boolean } & UserPermissionPayload} UserPermission */

/**
 * Renders roles and permissions section of Bill onboarding process
 *
 * @type {(params: { reAuthenticate: () => void }) => React.ReactElement}
 */
export default function SetupRolesAndPermissions({ reAuthenticate }) {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  /** @type {import('@/types/services/backend').CompanyUserDto[]} */
  const companyUsers = useTypedSelector(
    ({ settings }) => settings.members.companyUsers,
  );
  const scenarioId = useTypedSelector(({ scenario }) => scenario.scenarioId);

  const userId = useTypedSelector(({ auth }) => auth.userInfo.userId);
  const userName = useTypedSelector(({ auth }) => auth.userInfo.fullName);
  const userEmail = useTypedSelector(({ auth }) => auth.userInfo.email);

  /** @type {import('@/types/services/backend').CompanyUserDto} */
  const currentUser = useMemo(
    () => ({
      id: userId,
      name: userName,
      email: userEmail,
      permission: userRoles.ADMIN,
    }),
    [userEmail, userName, userId],
  );

  /**
   * @type {[
   *   UserPermission[],
   *   React.Dispatch<React.SetStateAction<UserPermission[]>>,
   * ]}
   */
  const [userPermissions, setUserPermissions] = useState([]);

  const {
    mutateAsync: finishOnboardingSetup,
    isLoading: isFinishingSetup,
    error,
  } = useMutation({ mutationFn: setRolesAndPermissions });

  /** @type {(e: React.MouseEvent<HTMLButtonElement>) => Promise<void>} */
  const onFinishSetup = async (e) => {
    /** @type {UserPermissionPayload[]} */
    const payload =
      e.currentTarget.id === 'finish-setup-onboarding-button'
        ? userPermissions
            .filter((permission) => permission.isDirty)
            .map((permission) => {
              delete permission.isDirty;
              return permission;
            })
        : [];
    await finishOnboardingSetup({ data: payload, scenarioId });
    reAuthenticate();
  };

  /** @type {PermissionChangeHandler} */
  const onPermissionChange = (payload, index) => {
    setUserPermissions((prevPayload) => {
      prevPayload[index] = {
        isDirty: true,
        ...prevPayload[index],
        ...payload,
      };
      return [...prevPayload];
    });
  };

  useEffect(() => {
    setUserPermissions(
      companyUsers.map((user) => ({
        companyRole: getKeyByValue(userRolesText, user.permission),
        userId: user.id,
        scenarioRole:
          user.permission === userRolesText.ROLE_ADMIN
            ? ScenarioRoles.EDITOR
            : null,
      })),
    );
  }, [companyUsers]);

  useEffect(() => {
    if (scenarioId) {
      dispatch(getMembersAction());
    }
  }, [dispatch, scenarioId]);

  return (
    <div
      data-testid="roles-permissions-onboarding"
      className="OnboardingRolesPermissions"
    >
      <div>
        <h1 className="OnboardingRolesPermissions_Title">
          Set Up Roles and Permissions
        </h1>
        <div className="OnboardingRolesPermissions_Content">
          <ErrorMessages error={error} />
          <p className="OnboardingRolesPermissions_Text">
            You’re almost there! Assign a role to your teammates to get them
            started with Forecasting. Define responsibilities and access levels
            to optimize collaboration and unlock the full potential of your
            team.
          </p>
          <div className="OnboardingRolesPermissions_Banner">
            <div className="OnboardingRolesPermissions_Banner-content">
              <span className="OnboardingRolesPermissions_Banner-icon">
                <FeedbackInfoIcon />
              </span>
              <p className="OnboardingRolesPermissions_Banner-text">
                If you would like to skip this process for the moment, you can
                revisit this by visiting the <b>Members</b> section within{' '}
                <b>Settings</b>.
              </p>
            </div>
          </div>
          <div>
            <Table
              className="OnboardingRolesPermissions_Table"
              data-testid="onboarding-role-permissions-table"
            >
              <thead>
                <tr className="OnboardingRolesPermissions_Table-header">
                  <Table.ColHead className="Table_Header-name">
                    Name
                  </Table.ColHead>
                  <Table.ColHead className="Table_Header-email">
                    Email Address
                  </Table.ColHead>
                  <Table.ColHead className="Table_Header-role">
                    Forecasting Role
                  </Table.ColHead>
                  <Table.ColHead className="Table_Header-permission">
                    Scenario Permission
                  </Table.ColHead>
                </tr>
              </thead>
              <tbody>
                <UserPermissionRow user={currentUser} isLoggedInUser />
                {companyUsers.map((companyUser, index) => (
                  <UserPermissionRow
                    user={companyUser}
                    index={index}
                    payload={userPermissions}
                    onPermissionChange={onPermissionChange}
                  />
                ))}
              </tbody>
            </Table>
          </div>
          <div className="OnboardingRolesPermissions_Actions">
            <Button
              onClick={onFinishSetup}
              className="OnboardingFinishSetupButton"
              data-testid="finish-setup-onboarding-button"
              id="finish-setup-onboarding-button"
              disabled={isFinishingSetup}
            >
              Finish Set Up
            </Button>
            <Button
              onClick={onFinishSetup}
              className="OnboardingSkipSetupButton"
              data-testid="skip-setup-onboarding-button"
              id="skip-setup-onboarding-button"
              disabled={isFinishingSetup}
            >
              Skip for now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
