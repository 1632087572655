const sortFn = (a, b) => a.score - b.score || a.option.length - b.option.length;

/**
 * Filter and sort the list of provided options based on the the search term
 *
 * @param {Array} options The list of options in which to search
 * @param {string} searchTerm The term the user is searching for
 * @returns {Array} The autocomplete options, sorted first by where the search
 */
const filterAndSortOptions = (options, searchTerm) => {
  return options
    .reduce((accum, option) => {
      const index = option.label
        .toLowerCase()
        .indexOf(searchTerm.toLowerCase());
      if (index === -1) return accum;
      return [...accum, { score: index, option }];
    }, [])
    .sort(sortFn)
    .map(({ option }) => option);
};

export default filterAndSortOptions;
