import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';

/**
 * A list of scenarios the user is authorized to access
 *
 * @returns {number[]}
 */
const useAuthorizedScenarios = () => {
  const accessibleScenarios = useSelector(
    ({ auth }) => auth.accessibleScenarios,
  );
  const authorizedScenarios = useMemo(
    () => Object.keys(accessibleScenarios ?? {}).map(Number),
    [accessibleScenarios],
  );
  return authorizedScenarios;
};

export default useAuthorizedScenarios;
