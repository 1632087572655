// @ts-check
export const REACT_APP_STRIPE_CLIENT_REDIRECT = `${window.location.origin}/integrationRedirect`;

/**
 * These are runtime variables defined in .env-runtime. The browser loads them
 * from env-config.js, which is generated by env.sh on startup.
 */
export const {
  REACT_APP_BILL_API_GATEWAY,
  REACT_APP_BILL_DOMAIN,
  REACT_APP_ENABLE_ANALYTICS,
  REACT_APP_ENVIRONMENT,
  REACT_APP_STRIPE_KEY,
  REACT_APP_INTERCOM_ID,
  REACT_APP_XERO_CLIENT_ID,
  REMOTE_GLOBAL_TOP_NAV_PATH,
  REMOTE_SIDENAV_NAV_PATH,
  REMOTE_FOOTER_PATH,
  DD_ENV,
  SPEND_URL,
  BILL_APP_URL01,
  BILL_APP_URL02,
  INSIGHTS_URL,
  SUPER_GRAPH_URL,
  BILL_LOGIN_URL,
  GLANCE_SRC,
  GLANCE_GROUP_ID,
  GLANCE_ADDITIONAL_GROUP_IDS,
  GLANCE_SITE,
  REMOTE_INSIGHTS_PATH,
  SEGMENT_WRITE_KEY,
} = window[RUNTIME_ENV] ?? {};
