import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import PercentageIcon from '@bill/cashflow.assets/percentage';
import UnitSelector from '@/components/common/Spreadsheet/UnitSelector';
import useFocusOnEdit from '@/components/common/Spreadsheet/editors/useFocusOnEdit';
import WithVariableAutocomplete from '@/components/common/WithVariableAutocomplete';
import { findCurrencySymbol } from '@/constants/currencies';
import { units } from '@/constants/employees';
import { classNames, getSizeForInput } from '@/helpers';
import {
  formatValueForDisplay,
  formatValueForSave,
} from '@/helpers/percentageFormulaFormatter';
import { isEmptyOrNull } from '@/helpers/validators';

function CurrencySymbol(props) {
  return (
    <span
      {...props}
      className={classNames(props.className, 'UnitSelector_Icon-currency')}
    >
      {findCurrencySymbol()}
    </span>
  );
}

const unitMap = {
  [units.NUMBER]: {
    label: 'Currency',
    UnitSymbol: CurrencySymbol,
  },
  [units.PERCENTAGE]: {
    label: 'Percentage',
    UnitSymbol: PercentageIcon,
  },
};

const BonusCommissionEditor = forwardRef(({ charPress, data }, ref) => {
  const { bonusFormulaId, bonusFormula, commissionFormula, unit } = data;
  const input = useRef(null);
  const [selectedValue, setSelectedValue] = useState(
    charPress ?? isEmptyOrNull(bonusFormulaId)
      ? formatValueForDisplay(commissionFormula, unit)
      : formatValueForDisplay(bonusFormula, unit),
  );
  const [selectedUnit, setSelectedUnit] = useState(unit || units.PERCENTAGE);

  const size = getSizeForInput(selectedValue);

  useImperativeHandle(ref, () => ({
    getValue: () => ({
      selectedValue: formatValueForSave(selectedValue, selectedUnit),
      selectedUnit,
    }),
  }));

  useFocusOnEdit(input);

  return (
    <div className="Spreadsheet_CellEditor Spreadsheet_CellEditor-withUnit">
      <UnitSelector
        unit={selectedUnit}
        onChange={(newUnit) => {
          setSelectedUnit(newUnit);
          input.current.focus();
        }}
        unitMap={unitMap}
      />
      <WithVariableAutocomplete
        data-testid="bonus-commission"
        inputRef={input}
        value={selectedValue ?? ''}
        onChange={(newValue) => setSelectedValue(newValue)}
      >
        {({ inputRef, ...props }) => (
          <div className="FormulaEditor_InputWrapper">
            <input
              type="text"
              size={size}
              className="Spreadsheet_Input"
              onChange={({ target }) => setSelectedValue(target.value)}
              ref={inputRef}
              {...props}
            />
          </div>
        )}
      </WithVariableAutocomplete>
    </div>
  );
});

export default BonusCommissionEditor;
