export const SET_DEFAULT_SCENARIO = 'scenario/SET_DEFAULT_SCENARIO';
export const SET_SCENARIO = 'scenario/SET_SCENARIO';
export const SET_SCENARIO_ID = 'scenario/SET_SCENARIO_ID';
export const SET_SELECTED_SCENARIO_USERS =
  'scenario/SET_SELECTED_SCENARIO_USERS';
export const SET_SCENARIO_LOADER = 'scenario/SET_SCENARIO_LOADER';
export const SET_INVITED_USER = 'scenario/SET_INVITED_USER';
export const SET_COMPARE_SCENARIO_ID = 'scenario/SET_COMPARE_SCENARIO_ID';
export const SWAP_SCENARIOS = 'scenario/SWAP_SCENARIOS';
export const SCENARIO_DUPLICATION_STATUS =
  'scenario/SCENARIO_DUPLICATION_STATUS';
export const CREATE_SCENARIO = 'scenario/CREATE_SCENARIO';
