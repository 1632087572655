// @ts-check
export const PLACEHOLDER_ID = 'placeholder';
export const SECTION_PLACEHOLDER_ID = 'section-placeholder';

export const units =
  /** @type {const} */
  ({
    CURRENCY: 'Currency',
    NUMBER: 'Number',
    PERCENTAGE: 'Percentage',
  });

export const variableTypes = {
  CUSTOM: 'Custom',
  SYSTEM: 'System',
};

export const symbols = {
  PERCENTAGE: '%',
  NUMBER: '#',
};

export const FPA_LITE_CUSTOM_VARIABLES_LIMIT = 100;
export const varianceText = {
  SHOW_VARIANCE_AMOUNT: 'Show Variance Amount',
  SHOW_VARIANCE_PERCENTAGE: 'Show Variance %',
};
