import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { subscribeToEmployeeUpdateAction } from '@/actions/employees';
import { CASH_FLOW } from '@/cacheKeys';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import useChartQuery from '@/hooks/useChartQuery';
import useWsSubscription from '@/hooks/useWsSubscription';
import RUNWAY_METRICS from '@/pages/Dashboard/constants/runway';
import { getCashFlowChartData } from '@/services/dashboard.service';

const MAIN_METRIC = 'Ending Cash Balance';

function reducer({ cashFlows, runway }) {
  const payload = cashFlows?.map((entry) => ({
    ...entry,
    financing: entry.financing.value,
    revenue: entry.revenue.value,
    runway: runway === '∞' ? Infinity : entry.runway,
    totalExpenses: entry.totalExpenses.value,
  }));
  return {
    ...mapMonthlyData(payload, 'endingCashBalance.value'),
    runway,
  };
}

/**
 * Fetches and renders a line chart showing runway / cash flow
 *
 * @example
 *   <RunwayChart exportBtn={exportBtn} />;
 */
function RunwayChart({
  className,
  exportBtn,
  plotOptions,
  axisStyles,
  onQueryStateChange,
  tooltipOptions,
}) {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );

  const scenarioQueries = useChartQuery(
    CASH_FLOW,
    getCashFlowChartData,
    reducer,
  );

  const [base] = scenarioQueries;

  useEffect(
    () => {
      if (scenarioQueries.length) onQueryStateChange?.(scenarioQueries);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
    [scenarioQueries],
  );

  useWsSubscription(
    () =>
      dispatch(
        subscribeToEmployeeUpdateAction(scenarioId, () => {
          base.refetch();
        }),
      ),
    [base, scenarioId],
  );

  return (
    <DateChart
      className={className}
      data-testid="runwayChart"
      tooltip={
        <ChartTooltip
          metrics={RUNWAY_METRICS}
          endDate={endDate}
          timePeriod={timePeriod}
          {...tooltipOptions}
        />
      }
      loading={scenarioQueries.every(({ isLoading }) => isLoading)}
      plotOptions={plotOptions}
      axisStyles={axisStyles}
      ref={exportBtn}
      startDate={startDate}
      endDate={endDate}
      timePeriod={timePeriod}
    >
      {scenarioQueries.map(
        ({ data, scenario }, idx) =>
          data && (
            <DateChart.Series
              key={scenario.scenarioId}
              data={data.data}
              index={idx}
              metric={MAIN_METRIC}
              scenario={scenario}
              isComparison={idx === 1}
            />
          ),
      )}
    </DateChart>
  );
}

export default RunwayChart;
