import { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HelpIcon from '@bill/cashflow.assets/help-icon';
import LogoutIcon from '@bill/cashflow.assets/logout';
import SettingsIcon from '@bill/cashflow.assets/settings';
import {
  copilotConfirmationTypes,
  setCopilotWarningModal as setCopilotWarningModalAction,
} from '@/actions/aiConversation';
import {
  setAllFeatureFlagsAction,
  resetFeatureFlagsAction,
} from '@/actions/features';
import CompanyLetterIcon from '@/components/CompanySelection/CompanyLetterIcon';
import Checkbox from '@/components/common/Checkbox';
import NavLink from '@/components/common/NavLink';
import UserLetterIcon from '@/components/common/UserLetterIcon';
import WithPopover from '@/components/common/WithPopover';
import {
  registeredFeatureFlags,
  eligibleEmailAccounts,
} from '@/constants/features';
import {
  LOGOUT_PATH,
  MANAGEMENT_CONSOLE_PATH,
  SWITCH_COMPANIES_PATH,
} from '@/constants/pages';
import { classNames } from '@/helpers';
import useManagementConsole from '@/hooks/useManagementConsole';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import './TheUserMenu.scss';

function TheUserMenu({
  company,
  fullName,
  userId,
  userEmail,
  featureFlags,
  setAllFeatureFlags,
  resetFeatureFlags,
  copilotConversationId,
  setCopilotWarningModal,
}) {
  const history = useHistory();
  const isFeatureFlagEligible =
    !!eligibleEmailAccounts.find((emailHost) =>
      userEmail.includes(emailHost),
    ) && Object.values(registeredFeatureFlags).length > 0;

  const currentUserIcon = (
    <UserLetterIcon id={userId} name={fullName} aria-hidden="true" />
  );

  const handleLogoutClick = (event) => {
    event.preventDefault();
    history.replace(LOGOUT_PATH);
  };

  const [featureFlagsToggled, setFeatureFlagsToggled] = useState(
    featureFlags.length > 0,
  );

  useEffect(() => {
    setFeatureFlagsToggled(featureFlags.length > 0);
  }, [featureFlags, setFeatureFlagsToggled]);

  const handleToggleFeatureFlags = () => {
    if (featureFlagsToggled) {
      resetFeatureFlags();
    } else {
      setAllFeatureFlags();
    }
    setFeatureFlagsToggled(!featureFlagsToggled);
  };

  const isConsoleEnabled = useManagementConsole();

  const navigateToCompanySwitcher = useCallback(() => {
    const companySwitcherLink = isConsoleEnabled
      ? MANAGEMENT_CONSOLE_PATH
      : SWITCH_COMPANIES_PATH;

    if (copilotConversationId) {
      setCopilotWarningModal({
        showWarningModal: true,
        warningModalConfirmationAction:
          copilotConfirmationTypes.COMPANY_SWITCHER,
        pendingCompanySwitcherLink: companySwitcherLink,
      });
    } else {
      history.push(companySwitcherLink);
    }
  }, [
    isConsoleEnabled,
    history,
    copilotConversationId,
    setCopilotWarningModal,
  ]);

  return (
    <WithPopover
      className="Popover-toEdge"
      content={
        <>
          <div className="TheUserMenu_Item TheUserMenuItem-loggedInUser">
            {currentUserIcon}
            <span
              className="TheUserMenu_ItemLabel"
              data-testid="user-menu-name"
            >
              {fullName}
            </span>
          </div>
          <div className="TheUserMenu_Item TheUserMenuItem-company">
            {company && (
              <>
                <CompanyLetterIcon
                  companyName={company.name}
                  companyId={company.id}
                />
                <div className="TheUserMenu_ItemLabel">
                  <div data-testid="user-menu-company">{company.name}</div>
                  <button
                    className={classNames(
                      'TheUserMenu_ChangeCompanyLink',
                      'link',
                    )}
                    data-testid="user-menu-change-company"
                    onClick={navigateToCompanySwitcher}
                  >
                    Change company
                  </button>
                </div>
              </>
            )}
          </div>
          {isFeatureFlagEligible && (
            <div className="TheUserMenu_Item">
              <>
                <Checkbox
                  id="toggle-feature-flags"
                  name="feature-flags"
                  checked={featureFlagsToggled}
                  onChange={handleToggleFeatureFlags}
                  className="Checkbox_Label"
                >
                  Toggle Feature Flags
                </Checkbox>
              </>
            </div>
          )}
          <div className="TheUserMenu_Menu">
            <NavLink
              to="/settings"
              className="TheUserMenu_Item"
              data-testid="user-menu-settings"
            >
              <SettingsIcon
                className="TheUserMenu_ItemIcon"
                aria-hidden="true"
              />
              <span className="TheUserMenu_ItemLabel">Settings</span>
            </NavLink>
            <a
              href="https://help.finmark.com"
              target="_blank"
              className="TheUserMenu_Item"
              rel="noreferrer"
            >
              <HelpIcon className="TheUserMenu_ItemIcon" aria-hidden="true" />
              <span className="TheUserMenu_ItemLabel">Help Center</span>
            </a>
            <a
              href="/"
              onClick={handleLogoutClick}
              className="TheUserMenu_Item"
              data-testid="user-menu-logout"
            >
              <LogoutIcon className="TheUserMenu_ItemIcon" aria-hidden="true" />
              <span className="TheUserMenu_ItemLabel">Log out</span>
            </a>
          </div>
        </>
      }
      placement="bottom-end"
      data-testid="user-menu"
    >
      <button
        type="button"
        className="ThePageHeader_Button"
        aria-label={`User options for ${fullName}`}
        data-testid="user-menu-button"
      >
        {currentUserIcon}
      </button>
    </WithPopover>
  );
}

function mapStateToProps(state) {
  return {
    company: getSelectedCompany(state),
    fullName: state.auth.userInfo.fullName,
    userId: state.auth.userInfo.userId,
    userEmail: state.auth.userInfo.email,
    featureFlags: state.features.flags,
    copilotConversationId: state.aiConversation.copilot.conversationId,
  };
}

export default connect(mapStateToProps, {
  setAllFeatureFlags: setAllFeatureFlagsAction,
  resetFeatureFlags: resetFeatureFlagsAction,
  setCopilotWarningModal: setCopilotWarningModalAction,
})(TheUserMenu);
