// @ts-check
import { useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { getPayrollExpensesListAction } from '@/actions/expenses';
import CircularReference from '@/components/common/CircularReference';
import ContextMenu from '@/components/common/ContextMenu';
import InfoTooltip from '@/components/common/InfoTooltip';
import Spreadsheet from '@/components/common/Spreadsheet';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import { EMPTY_CELL_VALUE } from '@/components/common/Spreadsheet/constants';
import WithTooltip from '@/components/common/WithTooltip';
import { END_DATE, START_DATE } from '@/constants/employees';
import detectCircularRef from '@/helpers/circularReference';
import { isEmptyOrNull } from '@/helpers/validators';
import './PayrollExpensesTable.scss';

/**
 * @typedef {{
 *   name: string;
 *   detailedName?: string;
 *   startDate: string;
 *   endDate?: string;
 *   expenseAccountNum?: string;
 *   faulted: boolean;
 *   variableValueFaultType: import('@/helpers/circularReference').ValueFaultTypes;
 * }} ExpensePayroll
 */

const SPREADSHEET_ID = 'payrollExpenseGrid';

/**
 * @typedef {import('ag-grid-community').ICellRendererParams<
 *   ExpensePayroll,
 *   any
 * > & {
 *   hasWritePermission: boolean;
 *   onEdit: (expense: ExpensePayroll) => void;
 * }} IContextMenuRendererParams
 */

/**
 * Renders the context menu for expenses with edit/view details options
 *
 * @type {(params: IContextMenuRendererParams) => React.ReactElement}
 */
const ContextMenuRenderer = ({ data, onEdit, hasWritePermission }) => {
  return (
    <ContextMenu data-testid="expense-action">
      <ContextMenu.Option
        data-testid="edit-expense"
        className="pendo-EditExpense"
        onClick={() => onEdit(data)}
      >
        {hasWritePermission ? 'Edit Expense' : 'View Details'}
      </ContextMenu.Option>
    </ContextMenu>
  );
};

/**
 * Render cell name with details in tooltip and circular reference icon
 *
 * @type {(
 *   params: import('ag-grid-community').ICellRendererParams<ExpensePayroll>,
 * ) => React.ReactElement}
 */
const expenseNameDetailCircularRefRenderer = ({ data }) => {
  const { name, detailedName } = data;
  const isCircularRef = detectCircularRef(data);
  return (
    <div className="PayrollExpenseTable_Name">
      <WithTooltip
        data-testid="account-expense-detailedName"
        content={detailedName}
      >
        <span>{name}</span>
      </WithTooltip>
      {isCircularRef && <CircularReference />}
    </div>
  );
};

/**
 * Render cell name with circular reference icon
 *
 * @type {(
 *   params: import('ag-grid-community').ICellRendererParams<ExpensePayroll>,
 * ) => React.ReactElement}
 */
const expenseNameCircularRefRenderer = ({ data }) => {
  const { name } = data;
  return (
    <div className="PayrollExpenseTable_Name">
      <span>{name}</span>
      <CircularReference />
    </div>
  );
};

/** @type {import('ag-grid-community').CellRendererSelectorFunc<ExpensePayroll>} */
const expenseNameDetailRendererSelector = ({ data }) => {
  const { detailedName } = data;
  const isCircularRef = detectCircularRef(data);
  if (detailedName) {
    return { component: expenseNameDetailCircularRefRenderer };
  }
  return {
    component: isCircularRef ? expenseNameCircularRefRenderer : undefined,
  };
};

/**
 * @type {(
 *   params: import('ag-grid-community').ValueFormatterParams<
 *     ExpensePayroll,
 *     ExpensePayroll['expenseAccountNum']
 *   >,
 * ) => string}
 */
const valueFormatter = ({ value }) =>
  isEmptyOrNull(value) || value === '' ? EMPTY_CELL_VALUE : value;

const PayrollExpensesTable = ({
  setCurrentExpenseRecord,
  onEdit,
  hasWritePermission,
}) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const startDate = useSelector(
    (/** @type {import('@/store').RootState} */ { shared }) => shared.startDate,
  );
  const endDate = useSelector(
    (/** @type {import('@/store').RootState} */ { shared }) => shared.endDate,
  );
  const showAccountNumberColumn = useSelector(
    (/** @type {import('@/store').RootState} */ { expenses }) =>
      expenses.hasAccountNumbers,
  );
  const payrollExpenses = useSelector(
    (/** @type {import('@/store').RootState} */ { expenses }) =>
      expenses.payrollExpenses,
  );
  const scenarioId = useSelector(
    (/** @type {import('@/store').RootState} */ { scenario }) =>
      scenario.scenarioId,
  );
  const isLoading = useSelector(
    (/** @type {import('@/store').RootState} */ { componentLoading }) =>
      componentLoading.payrollExpensesList,
  );

  useEffect(() => {
    dispatch(getPayrollExpensesListAction(scenarioId, startDate, endDate));
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [scenarioId, startDate, endDate]);

  const handleEdit = (expense) => {
    setCurrentExpenseRecord(expense);
    onEdit(true);
  };

  const colDefs = useMemo(() => {
    const cols = [
      {
        field: 'expenseAccountNum',
        headerName: 'Account Number',
        cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
        valueFormatter,
        hide: !showAccountNumberColumn,
      },
      {
        field: 'name',
        headerName: 'Expense',
        cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
        cellRendererSelector: expenseNameDetailRendererSelector,
      },
      {
        field: START_DATE,
        type: 'date',
        editable: false,
        headerName: 'Start Date',
      },
      {
        field: END_DATE,
        type: 'date',
        editable: false,
        headerName: 'End Date',
      },
      {
        colId: 'actions',
        type: 'actions',
        cellRenderer: ContextMenuRenderer,
        cellRendererParams: {
          onEdit: handleEdit,
          hasWritePermission,
        },
      },
    ];
    return cols;
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [showAccountNumberColumn, hasWritePermission, onEdit]);

  if (payrollExpenses.length === 0) return null;

  return (
    <section className="Panel Panel-toEdge Expense_Panel Expense_Panel-payroll">
      <SpreadsheetToolbar hideLegend editable={false}>
        <h6 className="ExpenseTable_SubTitle">
          Payroll Expenses
          <InfoTooltip placement="right" data-testid="payroll-expense-tooltip">
            These expenses have been marked as payroll expenses. Historical
            actuals for the expenses listed below are added to the “Total
            Payroll” line in actuals, but they will not be included in any
            forecast
          </InfoTooltip>
        </h6>
      </SpreadsheetToolbar>
      <Spreadsheet
        animateRows={!window.Cypress}
        columnDefs={colDefs}
        data={payrollExpenses}
        data-testid={SPREADSHEET_ID}
        loading={isLoading}
        rowClassRules={{
          'ExpenseRow-inactive': ({ data }) => !data.active,
        }}
      />
    </section>
  );
};

export default PayrollExpensesTable;
