// @ts-check
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';
import CountryField from './CountryField';
import {
  SET_STREET_ADDRESS_PRIMARY,
  SET_STREET_ADDRESS_SECONDARY,
  SET_ZIP_CODE,
  SET_CITY,
  SET_COUNTRY,
  MAX_CHAR_LIMIT,
  EDIT,
} from './constants';
import { isInternationalAddress } from './helpers';

/**
 * @typedef {{
 *   address: import('./types').Address;
 *   mode: 'read' | 'edit';
 *   onChange: ({ type, payload }) => void;
 *   hideStreetLineTwo?: boolean;
 * }} CompanyAddressProps
 */

/**
 * Company Address Form Section
 *
 * @type {React.FC<CompanyAddressProps>}
 */
const CompanyAddress = ({
  address,
  mode,
  onChange,
  hideStreetLineTwo = false,
}) => {
  return (
    <div className="CompanyDetails_AddressWrapper">
      <FormLabel text="HQ Location" htmlFor="companyAddress" />
      <div className="Form">
        <div className="Form_Group">
          <FormField
            id="street"
            data-testid="company-street"
            name="street"
            maxLength={MAX_CHAR_LIMIT}
            value={address.street}
            onChange={({ target }) => {
              onChange({
                type: SET_STREET_ADDRESS_PRIMARY,
                payload: target.value,
              });
            }}
            disabled={mode !== EDIT}
            placeholder="Street Address"
          />
        </div>
        {!hideStreetLineTwo && (
          <div className="Form_Group">
            <FormField
              id="streetLineTwo"
              data-testid="company-streetLineTwo"
              name="streetLineTwo"
              maxLength={MAX_CHAR_LIMIT}
              value={address.streetLineTwo}
              onChange={({ target }) => {
                onChange({
                  type: SET_STREET_ADDRESS_SECONDARY,
                  payload: target.value,
                });
              }}
              disabled={mode !== EDIT}
              placeholder="Suite, Unit, Building, Floor, etc"
            />
          </div>
        )}
        <div className="Form_Group">
          <FormField
            id="city"
            data-testid="company-city"
            name="city"
            maxLength={MAX_CHAR_LIMIT}
            value={address.city}
            onChange={({ target }) => {
              onChange({
                type: SET_CITY,
                payload: target.value,
              });
            }}
            disabled={mode !== EDIT}
            placeholder="City"
          />
        </div>
        <div className="Form_Group">
          <FormField
            className="CompanyDetails_ZipCode"
            id="zipCode"
            data-testid="company-zip"
            name="zipCode"
            type={isInternationalAddress(address) ? 'text' : 'number'}
            maxLength={10}
            value={address.zipCode}
            validate={(zip) => {
              if (isInternationalAddress(address)) {
                return '';
              }
              return zip.length && zip.length !== 5
                ? 'Postal code must be 5 digits'
                : '';
            }}
            onChange={({ target }) => {
              onChange({
                type: SET_ZIP_CODE,
                payload: target.value,
              });
            }}
            disabled={mode !== EDIT}
            placeholder="Postal Code"
          />
        </div>
        <CountryField
          value={address.country}
          disabled={mode !== EDIT}
          onChange={({ target }) => {
            onChange({
              type: SET_COUNTRY,
              payload: target.value,
            });
          }}
        />
      </div>
    </div>
  );
};

export default CompanyAddress;
