import RadioSelectedIcon from '@bill/cashflow.assets/radio-selected';
import RoundCrossIcon from '@bill/cashflow.assets/round-cross';
import WarningIcon from '@bill/cashflow.assets/warning';
import PropTypes from 'prop-types';

/**
 * The text to display with the Uploader component
 *
 * @example
 *   <UploadProgressText
 *     id="uploader-body"
 *     progress={50}
 *     onCancel={() => {}}
 *   />;
 */
const UploadProgressText = ({
  id,
  className,
  children,
  onCancel,
  error,
  progress,
  'data-testid': dataTestId,
}) => {
  if (error) {
    return (
      <div id={id} data-testid={dataTestId ?? id} className="UploadError">
        <h6 className="UploadHeader">
          <strong>{error.title}</strong>{' '}
          <WarningIcon className="UploadIcon UploadIcon-error" />
        </h6>
        <div>{children}</div>
      </div>
    );
  }
  return (
    <div id={id} className={className} data-testid={dataTestId ?? id}>
      <p className="UploadText">
        {progress === 100 ? (
          <strong>
            Upload Completed{' '}
            <RadioSelectedIcon className="UploadIcon UploadIcon-success" />
          </strong>
        ) : (
          <>
            <strong>Uploading</strong>
            <RoundCrossIcon className="Upload_CancelIcon" onClick={onCancel} />
          </>
        )}
      </p>
    </div>
  );
};

UploadProgressText.propTypes = {
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string,
  /** Unique ID for the element */
  'id': PropTypes.string.isRequired,
  /** Class(es) to apply to the element */
  'className': PropTypes.string,
  /** The content to display within the component */
  'children': PropTypes.node,
  /** A function to call when cancelling an upload */
  'onCancel': PropTypes.func.isRequired,
  /** An error object when uploading */
  'error': PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  /** The progress of an upload expressed in a number from 0 to 100 */
  'progress': PropTypes.number.isRequired,
};

export default UploadProgressText;
