// @ts-check
import BlockedArrowIcon from '@bill/cashflow.assets/blocked-arrow';
import CaretIcon from '@bill/cashflow.assets/caret';
import CrossIcon from '@bill/cashflow.assets/cross';
import CursorIcon from '@bill/cashflow.assets/cursor';
import EllipseIcon from '@bill/cashflow.assets/ellipse';
import ReturnKeyIcon from '@bill/cashflow.assets/return-key';
import PropTypes from 'prop-types';
import Modal from '@/components/common/Modal';
import SpreadsheetLegendContent from '@/components/common/Spreadsheet/SpreadsheetLegendContent';
import { cashGridLegendCopy } from '@/constants/cashInOut';
import './CashGridGuideModal.scss';

/**
 * @typedef {{
 *   'data-testid': string;
 *   'onClose': React.MouseEventHandler;
 *   'open': boolean;
 * }} CashGridGuideModalProps
 */

/** @type {(props: CashGridGuideModalProps) => React.ReactElement} */

/**
 * Displays an informative modal on how to interact with the cash grid
 *
 * @example
 *   <CashGridGuideModal
 *     open={isOpen}
 *     data-testid="cash-grid-guide"
 *     onClose={() => setOpen(false)}
 *   />;
 */
function CashGridGuideModal({ 'data-testid': dataTestId, onClose, open }) {
  return (
    <Modal
      open={open}
      className="CashGridGuideModal"
      data-testid={dataTestId}
      onClose={onClose}
    >
      <button
        type="button"
        className="Modal_CloseBtn"
        data-testid={`${dataTestId}-closeBtn`}
        onClick={onClose}
      >
        <CrossIcon aria-label="Close" />
      </button>
      <div className="CashGridGuideModal_Sections">
        <section className="CashGridGuideModal_Section">
          <h3 className="CashGridGuideModal_Heading">Icon Identification</h3>
          <div className="CashGridGuideModal_SubSection">
            <SpreadsheetLegendContent
              content={cashGridLegendCopy}
              userEnteredTextOverride="Manual Adjustments"
            />
          </div>
        </section>
        <section className="CashGridGuideModal_Section">
          <h3 className="CashGridGuideModal_Heading">Cell Indentification</h3>
          <div className="CashGridGuideModal_SubSection">
            <div className="CashGridGuideModal_Item">
              <div className="CashGridGuideModal_Cells">
                <div className="CashGridGuideModal_RowHighlight CashGridGuideModal_RowHighlight-left" />
                <div className="CashGridGuideModal_Cell">$4,357</div>
                <div className="CashGridGuideModal_RowHighlight CashGridGuideModal_RowHighlight-right" />
              </div>
              <div>Black text can be edited</div>
            </div>
            <div className="CashGridGuideModal_Item">
              <div className="CashGridGuideModal_Cells">
                <div className="CashGridGuideModal_RowHighlight CashGridGuideModal_RowHighlight-left" />
                <div className="CashGridGuideModal_Cell CashGridGuideModal_Cell-disabled">
                  $4,357
                </div>
                <div className="CashGridGuideModal_RowHighlight CashGridGuideModal_RowHighlight-right" />
              </div>
              <div>Gray text cannot be edited</div>
            </div>
          </div>
        </section>
        <section className="CashGridGuideModal_Section">
          <h3 className="CashGridGuideModal_Heading">Keyboard Navigation</h3>
          <div className="CashGridGuideModal_SubSection">
            <div className="CashGridGuideModal_Item">
              <div className="CashGridGuideModal_Keyboard">
                <div className="CashGridGuideModal_Key">
                  <CaretIcon className="CashGridGuideModal_CaretIcon CashGridGuideModal_KeyUp" />
                </div>
                <div className="CashGridGuideModal_Keyboard-bottom">
                  <div className="CashGridGuideModal_Key">
                    <CaretIcon className="CashGridGuideModal_CaretIcon CashGridGuideModal_KeyLeft" />
                  </div>
                  <div className="CashGridGuideModal_Key">
                    <CaretIcon className="CashGridGuideModal_CaretIcon" />
                  </div>
                  <div className="CashGridGuideModal_Key">
                    <CaretIcon className="CashGridGuideModal_CaretIcon CashGridGuideModal_KeyRight" />
                  </div>
                </div>
              </div>
              <div>Navigate around the data grid using the arrow keys.</div>
            </div>
            <div className="CashGridGuideModal_Item">
              <div className="CashGridGuideModal_SingleKey">
                <div className="CashGridGuideModal_Key">
                  <BlockedArrowIcon className="CashGridGuideModal_BlockedArrow" />
                  <BlockedArrowIcon className="CashGridGuideModal_BlockedArrow CashGridGuideModal_BlockedArrow-reverse" />
                </div>
              </div>
              <div>Quickly move along cells using the ‘tab’ key.</div>
            </div>
            <div className="CashGridGuideModal_Item">
              <div className="CashGridGuideModal_SingleKey">
                <div className="CashGridGuideModal_Key">
                  <ReturnKeyIcon className="ReturnKeyIcon" />
                </div>
              </div>
              <div>Edit the cell by pressing the ‘return’ key.</div>
            </div>
          </div>
        </section>
        <section className="CashGridGuideModal_Section">
          <h3 className="CashGridGuideModal_Heading">Mouse Navigation</h3>
          <div className="CashGridGuideModal_SubSection">
            <div className="CashGridGuideModal_Item">
              <div className="CashGridGuideModal_Cells">
                <div className="CashGridGuideModal_RowHighlight CashGridGuideModal_RowHighlight-left" />
                <div className="CashGridGuideModal_Cell CashGridGuideModal_Cell-active">
                  <span>$50,954</span>
                  <CursorIcon className="CursorIcon CursorIcon-singleClick" />
                  <EllipseIcon className="EllipseIcon EllipseIcon-singleClick" />
                </div>
                <div className="CashGridGuideModal_RowHighlight CashGridGuideModal_RowHighlight-right" />
              </div>
              <div>Single-click to highlight a cell.</div>
            </div>
            <div className="CashGridGuideModal_Item">
              <div className="CashGridGuideModal_Cells">
                <div className="CashGridGuideModal_RowHighlight CashGridGuideModal_RowHighlight-left" />
                <div className="CashGridGuideModal_Cell CashGridGuideModal_Cell-active">
                  <span>$32,4</span>
                  <CursorIcon className="CursorIcon CursorIcon-doubleClick" />
                  <div className="EllipseIcon-doubleClick">
                    <EllipseIcon className="EllipseIcon" />
                    <EllipseIcon className="EllipseIcon" />
                  </div>
                </div>
                <div className="CashGridGuideModal_RowHighlight CashGridGuideModal_RowHighlight-right" />
              </div>
              <div>Double-click to edit a cell.</div>
            </div>
          </div>
        </section>
      </div>
    </Modal>
  );
}
CashGridGuideModal.propTypes = {
  /** Unique ID for selecting the modal in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Event handler for when the user closes the modal */
  'onClose': PropTypes.func.isRequired,
  /** Whether or not the modal is open */
  'open': PropTypes.bool,
};

export default CashGridGuideModal;
