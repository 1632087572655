// @ts-check
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Checkbox from '@/components/common/Checkbox';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import useExportableReportContext from '@/contexts/useExportableReportContext';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import './ReportSidebar.scss';

/** @typedef {import('@/components/Reports/ExportableReport/constants').SectionId} SectionId */

/**
 * Renders Exportable Report Sidebar
 *
 * @example
 *   <ReportSidebar sections={sections} isLoading={isLoading} />;
 *
 * @typedef {{
 *   sections: import('@/components/Reports/ExportableReport/ReportContentContainer.js').Section[];
 *   isLoading: boolean;
 *   onSectionToggle: (sectionId: SectionId, isChecked: boolean) => void;
 * }} ReportSidebarProps
 * @type {(props: ReportSidebarProps) => React.ReactElement}
 */
const ReportSidebar = ({ sections, isLoading, onSectionToggle }) => {
  const { hash } = useLocation();
  const activeSection = useMemo(() => hash.replace('#', ''), [hash]);
  const hasWritePermission = useNonDashboardWritePermission();
  const { isExporting } = useExportableReportContext();

  return (
    <div className="ExportableReportSidebar">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <ul className="ExportableReportSidebar_List">
          {sections.map((section) => (
            <li
              key={section.id}
              aria-current={section.id === activeSection ? 'page' : undefined}
              className="ExportableReportSidebar_ListItem"
            >
              <a
                href={`#${section.id}`}
                className="ExportableReportSidebar_Link"
              >
                {section.displayName}
              </a>
              <Checkbox
                id={`${section.id}_Checkbox`}
                name={section.id}
                className="ExportableReportSidebar_ListItemCheckbox"
                checked={section.active}
                disabled={!hasWritePermission || isExporting}
                onChange={({ target }) => {
                  onSectionToggle(section.id, target.checked);
                }}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ReportSidebar;
