// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';

/**
 * Retrieves the end date from `state.shared` in redux
 *
 * @type {() => string}
 */
const useEndDate = () => {
  return useSelector((state) => {
    return state.shared.endDate;
  });
};

export default useEndDate;
