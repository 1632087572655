import {
  SET_ERROR,
  SET_EXPENSES_LIST,
  SET_EXPENSES_CLASSES,
  SET_ORGANIZE_EXPENSE_RESPONSE,
  SET_EXPENSES_BY_DEPT_CODE,
  SET_PAYROLL_EXPENSES,
  UPDATE_EXPENSE,
} from '@/actionTypes/expenses';
import { subscribeToTopic } from '@/actions/shared';
import { NO_COMPARISON } from '@/constants/scenario';
import {
  getExpensesClasses,
  getPagedExpenses,
  getPayrollExpenses,
  createExpense,
  updateExpense,
  updateExpenseCategories,
  getExpensesListByDepartmentCode,
} from '@/services/expensesService';
import { changeLoadingState } from './componentLoading';

/** @typedef {import('@/types/services/backend').ExpenseResponseDto} ExpenseResponseDto */

export const setError = (error) => {
  return {
    type: SET_ERROR,
    payload: error,
  };
};

/**
 * Action that retrieves expenses list
 *
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @returns {Function} Dispatchable action
 */
export const getExpensesListAction = (scenarioId, startDate, endDate) => {
  return async (dispatch) => {
    try {
      const response = await getPagedExpenses(scenarioId, startDate, endDate);
      dispatch({ type: SET_EXPENSES_LIST, payload: response.data.data });
    } catch (e) {
      // eslint-disable-next-line no-console -- predates description requirement
      console.error(e);
    } finally {
      dispatch(changeLoadingState('expensesList', false));
    }
  };
};

/**
 * Action that retrieves payroll expenses list
 *
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @param {string} startDate First month of desired payroll expenses data, in
 *   the format YYYY-MM
 * @param {string} endDate Last month of desired payroll expenses data, in the
 *   format YYYY-MM
 * @returns {import('@reduxjs/toolkit').ThunkAction<any, any, any, any>}
 *   Dispatchable action
 */
export const getPayrollExpensesListAction = (
  scenarioId,
  startDate,
  endDate,
) => {
  return async (dispatch) => {
    dispatch(changeLoadingState('payrollExpensesList', true));
    try {
      const response = await getPayrollExpenses(scenarioId, startDate, endDate);
      dispatch({
        type: SET_PAYROLL_EXPENSES,
        payload: response.data.data.expenses,
      });
    } catch (e) {
      // eslint-disable-next-line no-console -- predates description requirement
      console.error(e);
    } finally {
      dispatch(changeLoadingState('payrollExpensesList', false));
    }
  };
};

/**
 * Action that retrieves expenses list
 *
 * @param {Object} params Param object
 * @param {number} params.scenarioId ID of the scenario containing the expenses
 * @param {number} params.departmentCode Department Code to get expense by
 *   department
 * @param {Date} params.startDate Start Date for expenses to be fetched
 * @param {Date} params.endDate End Date for expenses to be fetched
 * @returns {Function} Dispatchable action
 */
export const getExpensesListByDepartmentCodeAction = (params) => {
  return async (dispatch) => {
    const response = await getExpensesListByDepartmentCode(params);
    dispatch({
      type: SET_EXPENSES_BY_DEPT_CODE,
      payload: response.data.data.expenses || [],
    });
  };
};

/**
 * A factory function that generates a create/update expense/expenseClasses
 * action
 *
 * @param {Function} callback - The xhr service function
 * @returns {Function} Expense and graph Action
 */
const expenseActionFactory = (callback) => {
  return (expense, scenarioId, isExpenseClass = false) => {
    return async (dispatch, getState) => {
      const { shared, scenario } = getState();
      const { compareScenarioId } = scenario;
      const scenarioIds = [scenarioId];
      if (![scenarioId, NO_COMPARISON].includes(compareScenarioId)) {
        scenarioIds.push(compareScenarioId);
      }
      const { startDate, endDate } = shared;
      const response = await callback(expense, scenarioId);
      if (isExpenseClass) {
        dispatch({
          type: SET_ORGANIZE_EXPENSE_RESPONSE,
          payload: response.data,
        });
      }
      dispatch(getExpensesListAction(scenarioId, startDate, endDate));
    };
  };
};

/**
 * Action that updates a payroll expense and retrieves an updated list of
 * payroll expenses on success
 *
 * @param {Object} expense Data object that includes expense details
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @returns {Function} Dispatchable action
 */
export const updatePayrollExpenseAction = (expense, scenarioId) => {
  return async (dispatch, getState) => {
    try {
      const { shared } = getState();
      const { startDate, endDate } = shared;
      const response = await updateExpense(expense, scenarioId);
      if (response.data.success) {
        dispatch(getPayrollExpensesListAction(scenarioId, startDate, endDate));
      }
    } catch (e) {
      // eslint-disable-next-line no-console -- predates description requirement
      console.error(e);
    }
  };
};

/**
 * Action that retrieves expenses classes list
 *
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @returns {Function} Dispatchable action
 */
export const getExpensesClassesAction = (scenarioId, formatDuplicates) => {
  return async (dispatch) => {
    try {
      const response = await getExpensesClasses(scenarioId, formatDuplicates);
      const { expenseClasses } = response.data.data;
      if (expenseClasses && expenseClasses.length) {
        dispatch({
          type: SET_EXPENSES_CLASSES,
          payload: expenseClasses,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console -- predates description requirement
      console.error(e);
    }
  };
};

/**
 * Action that creates an expense
 *
 * @param {Object} data Data is the object that includes expense details
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @returns {Promise} API response
 */
export const createExpenseAction = expenseActionFactory(createExpense);

/**
 * Action that updated an expense
 *
 * @param {Object} data Data is the object that includes expense details and id
 *   which needs to be updated
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @returns {Promise} API response
 */
export const updateExpenseAction = expenseActionFactory(updateExpense);

/**
 * Action that update expense category
 *
 * @param {Object} data Data is the object that includes expenseId,
 *   expenseClassId & departmentId
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @returns {Function} Dispatchable action
 */
export const updateOrganizeExpenseAction = expenseActionFactory(
  updateExpenseCategories,
);

/**
 * Subscribes to expense update notifications
 *
 * @type {(
 *   params: {
 *     scenarioId: number;
 *   },
 *   callback?: (payload: ExpenseResponseDto) => void,
 * ) => import('@/types/extend-redux-toolkit').AppThunk<
 *   Promise<import('@stomp/stompjs').StompSubscription>
 * >}
 */
export const subscribeToExpenseUpdateAction = ({ scenarioId }, callback) => {
  return (dispatch) => {
    return dispatch(
      subscribeToTopic(
        `expense-group-updated/${scenarioId}`,
        (/** @type {ExpenseResponseDto} */ websocketData) => {
          dispatch({
            type: UPDATE_EXPENSE,
            payload: websocketData,
          });
          callback?.(websocketData);
        },
      ),
    );
  };
};
