import { useEffect, useState, useReducer, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  getCompensationFormula,
  refreshEmployeeDataAction,
  getAllJobTitlesAction,
} from '@/actions/employees';
import FormFoundation from '@/components/Employee/FormFoundation';
import PaidViaHris from '@/components/Employee/PaidViaHris';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';
import FormField from '@/components/common/FormField';
import InfoTooltip from '@/components/common/InfoTooltip';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import NumberField from '@/components/common/NumberField';
import {
  EDIT_EMPLOYEE,
  EMPLOYMENT_TYPE,
  SET_DEPARTMENT,
  SET_JOB_TITLE,
  SET_ROLE_TYPE,
  SET_SALARY,
  SET_START_DATE,
  SET_END_DATE,
  SET_COMPENSATION_TYPE,
  SET_COMPENSATION_UNIT,
  SET_COMPENSATION_FORMULA,
  BONUS_FORMULA,
  INITIAL_STATE,
  employeeFormModes,
  ADD_EMPLOYEE,
  DUPLICATE_EMPLOYEE,
  SET_API_ERROR_MESSAGE,
  SET_NO_END_DATE_CHECK_BOX,
} from '@/constants/employees';
import { FINCH_BAMBOOHR, integrationFamily } from '@/constants/integrations';
import { units } from '@/constants/variables';
import { formatValueForDisplay } from '@/helpers/percentageFormulaFormatter';
import {
  isPercentageValid,
  /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
  isDefined,
  isEmptyOrNull,
} from '@/helpers/validators';
import useIntegrationsConnected from '@/hooks/useIntegrationsConnected';
import useSelectedScenarios from '@/hooks/useSelectedScenarios';
import useViewOnlyMode from '@/hooks/useViewOnlyMode';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import { updateEmployees } from '@/services/employee.service';
import { CompensationPlan } from './CompensationPlan';
import './EditForm.scss';

const BONUS_FORMULA_WARNING =
  'The bonus/commission amount for this employee is set as a monthly bonus/commission. Are you sure you want to proceed? We recommend using a custom variable to specify the frequency of bonus/commission payout.';

const getState = (editRecord, selectedCompany) => {
  if (editRecord) {
    return {
      ...editRecord,
      annualSalary: isEmptyOrNull(editRecord.salaryVariableId)
        ? editRecord.salaryFormula
        : null,
      salaryFormula: !isEmptyOrNull(editRecord.salaryVariableId)
        ? editRecord.salaryFormula
        : null,
      loadMultiplier:
        editRecord.loadMultiplier ?? selectedCompany.loadMultiplier,
      compensationType: BONUS_FORMULA,
      /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
      unit: isDefined(editRecord.unit) ? editRecord.unit : units.PERCENTAGE,
      /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
      compensationFormula: isDefined(editRecord.commissionFormula)
        ? formatValueForDisplay(editRecord.commissionFormula, editRecord.unit)
        : formatValueForDisplay(editRecord.bonusFormula, editRecord.unit),
      employmentType: editRecord.employmentType ?? EMPLOYMENT_TYPE.fte,
      endDate: editRecord.termDate,
    };
  }
  return { ...INITIAL_STATE, loadMultiplier: selectedCompany?.loadMultiplier };
};

const SET_OVERRIDE_LOAD_MULTIPLIER = 'SET_OVERRIDE_LOAD_MULTIPLIER';
const SET_NAME = 'SET_NAME';
const SET_LOAD_MULTIPLIER = 'SET_LOAD_MULTIPLIER';
const SET_PAID_VIA_HRIS = 'SET_PAID_VIA_HRIS';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_DEPARTMENT:
      return { ...state, ...payload };
    case SET_JOB_TITLE:
      return { ...state, titleId: payload };
    case SET_ROLE_TYPE:
      return { ...state, employmentType: payload };
    case SET_SALARY:
      return { ...state, ...payload };
    case SET_START_DATE:
      return { ...state, startDate: payload };
    case SET_NO_END_DATE_CHECK_BOX:
      return { ...state, noEndDate: payload };
    case SET_END_DATE:
      return { ...state, endDate: payload };
    case SET_OVERRIDE_LOAD_MULTIPLIER:
      return { ...state, overrideGlobalLoadMultiplier: payload };
    case SET_NAME:
      return { ...state, name: payload };
    case SET_LOAD_MULTIPLIER:
      return { ...state, loadMultiplier: payload };
    case SET_COMPENSATION_TYPE:
      return { ...state, ...payload };
    case SET_COMPENSATION_UNIT:
      return { ...state, unit: payload };
    case SET_COMPENSATION_FORMULA:
      return { ...state, compensationFormula: payload };
    case SET_PAID_VIA_HRIS:
      return { ...state, paidViaHris: payload === 'true' };
    case SET_API_ERROR_MESSAGE:
      return { ...state, errorMessage: payload };
    default:
      return state;
  }
};

const EditForm = ({
  editRecord,
  handleClose,
  departments,
  viewMode,
  selectedCompany,
  refreshEmployeeData,
  getAllJobTitles,
}) => {
  const connectedIntegrations = useIntegrationsConnected();
  const externalPayrollIntegration = connectedIntegrations.find(
    ({ systemType }) =>
      systemType === integrationFamily.INTEGRATION_FAMILY_FINCH,
  );
  const isBambooHRConnected =
    externalPayrollIntegration?.type === FINCH_BAMBOOHR;
  const isEditMode = !!editRecord;

  const [formState, setFormState] = useReducer(
    reducer,
    getState(editRecord, selectedCompany),
  );

  const employeeModalRef = useRef(null);
  const [showFormulaWarning, setShowFormulaWarning] = useState(false);
  const [isFormulaValid, setIsFormulaValid] = useState(true);
  const [isSalaryValid, setIsSalaryValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const scenarios = useSelectedScenarios();
  const scenarioIds = scenarios.map(({ scenarioId: id }) => id);
  const [scenarioId] = scenarioIds;

  useEffect(() => {
    if (formState.employmentType === EMPLOYMENT_TYPE.contractor) {
      setFormState({
        type: SET_OVERRIDE_LOAD_MULTIPLIER,
        payload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [formState.employmentType]);

  const isLoadMultiplierInValid =
    formState.overrideGlobalLoadMultiplier &&
    !isPercentageValid(formState.loadMultiplier);

  const handleUpdate = async (formData) => {
    setIsLoading(true);
    const compensationFormula = getCompensationFormula(formData);
    let {
      data: {
        data: { errors },
      },
    } = await updateEmployees(scenarioId, [
      {
        ...formData,
        ...compensationFormula,
        id: formData.id ?? uuidv4(),
      },
    ]);
    setIsLoading(false);

    errors = Object.values(errors);
    if (errors.length) {
      setFormState({
        type: SET_API_ERROR_MESSAGE,
        payload: errors[0].titleId,
      });
      employeeModalRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return;
    }
    refreshEmployeeData(scenarioId);
    getAllJobTitles(scenarioId);
    handleClose();
  };

  const formatDataAndUpdate = () => {
    const data = {
      ...formState,
      termDate: formState.endDate,
    };
    delete data.endDate;
    handleUpdate(data);
  };

  const formRef = useRef(null);

  const isViewOnly = useViewOnlyMode(formRef, { shouldDisableButtons: true });

  /* eslint-disable consistent-return -- predates description requirement */
  const formHeading = useMemo(() => {
    if (isViewOnly) {
      return 'Employee Details';
    }

    if (viewMode === employeeFormModes.CREATE) {
      return ADD_EMPLOYEE;
    }

    if (viewMode === employeeFormModes.EDIT) {
      return EDIT_EMPLOYEE;
    }

    if (viewMode === employeeFormModes.DUPLICATE) {
      return DUPLICATE_EMPLOYEE;
    }
  }, [isViewOnly, viewMode]);

  return (
    <ModalBase
      show
      ref={employeeModalRef}
      id="add-employee-modal"
      data-testid="add-employee-modal"
      onCancel={handleClose}
      header={<h2 className="ModalBase_Heading">{formHeading}</h2>}
      footer={
        <>
          {isViewOnly ? (
            <Button onClick={handleClose} data-testid="add-employee-close">
              Close
            </Button>
          ) : (
            <>
              <Button
                onClick={handleClose}
                className="Button-cancelLink"
                data-testid="add-employee-cancel"
              >
                Cancel
              </Button>
              <Button
                loading={isLoading}
                disabled={
                  (formState &&
                    (!isSalaryValid ||
                      !formState.startDate ||
                      !formState.titleId ||
                      !formState.departmentId ||
                      !formState.name)) ||
                  isLoadMultiplierInValid ||
                  !isFormulaValid
                }
                onClick={() => {
                  if (
                    /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
                    isDefined(formState.compensationFormula) &&
                    !formState.compensationFormula.includes('Custom')
                  ) {
                    setShowFormulaWarning(true);
                  } else {
                    formatDataAndUpdate();
                  }
                }}
                data-testid="add-employee-save"
              >
                {formHeading === ADD_EMPLOYEE
                  ? 'Add Employee'
                  : 'Save Employee'}
              </Button>
            </>
          )}
        </>
      }
    >
      <>
        <div className="Form" ref={formRef}>
          <FormFoundation
            {...{
              isEditMode,
              state: formState,
              dispatchFn: setFormState,
              departments,
              setIsSalaryValid,
              isEmployeeForm: true,
            }}
          >
            <div className="Form_Group Form_Group-halfWidth">
              <label htmlFor="name" className="Label">
                Name
              </label>
              <FormField
                id="name"
                name="name"
                value={formState?.name || ''}
                onChange={({ target }) => {
                  setFormState({
                    type: SET_NAME,
                    payload: target.value,
                  });
                }}
                validate={() => {
                  const isInValid = formState && !formState.name;
                  return isInValid && 'Name is required!';
                }}
                data-testid="add-employee-name"
                maxLength="50"
              />
            </div>
          </FormFoundation>
          {[EMPLOYMENT_TYPE.fte, EMPLOYMENT_TYPE.pte].includes(
            formState.employmentType,
          ) && (
            <div className="Form_Group Form_Group-loadMultiplier">
              <div className="LoadMultiplier_Toggle">
                <Checkbox
                  name="overrideGlobalLoadMultiplier"
                  id="GlobalLoadMultiplier_Checkbox"
                  onChange={({ target }) => {
                    setFormState({
                      type: SET_OVERRIDE_LOAD_MULTIPLIER,
                      payload: !formState.overrideGlobalLoadMultiplier,
                    });

                    if (!target.checked) {
                      setFormState({
                        type: SET_LOAD_MULTIPLIER,
                        payload: selectedCompany.loadMultiplier,
                      });
                    }
                  }}
                  checked={formState.overrideGlobalLoadMultiplier}
                >
                  Override Global Load Multiplier
                </Checkbox>
                <InfoTooltip data-testid="load-multiplier-tooltip">
                  The load multiplier is a percentage of an employee's salary
                  that is additionally spent on taxes and benefits. This is 20%
                  by default, but can be changed in the settings page.
                </InfoTooltip>
              </div>
              <NumberField
                prefix="%"
                id="loadMultiplier"
                name="loadMultiplier"
                value={
                  formState.loadMultiplier ?? selectedCompany.loadMultiplier
                }
                className="FormField-loadMultiplier"
                onChange={({ target }) =>
                  setFormState({
                    type: SET_LOAD_MULTIPLIER,
                    payload: target.value,
                  })
                }
                validate={() => {
                  return (
                    !isPercentageValid(formState.loadMultiplier) &&
                    'Please enter a valid percentage'
                  );
                }}
                disabled={!formState.overrideGlobalLoadMultiplier}
                allowNegativeValues={false}
              />
            </div>
          )}
          <CompensationPlan
            scenarioId={scenarioId}
            departments={departments}
            currentRecord={formState}
            setIsFormulaValid={setIsFormulaValid}
            setCurrentRecord={setFormState}
            isEditMode={isEditMode}
          />
          {connectedIntegrations && isEditMode && editRecord.externalId && (
            <PaidViaHris
              paidViaHris={formState.paidViaHris}
              onChange={({ target }) =>
                setFormState({
                  type: SET_PAID_VIA_HRIS,
                  payload: target.value,
                })
              }
              isDisabled={isBambooHRConnected}
            />
          )}
        </div>
        {showFormulaWarning && (
          <ModalConfirmation
            id="modal-employee-confirm-formula"
            onCancel={() => setShowFormulaWarning(false)}
            onAction={() => formatDataAndUpdate()}
            title="Before you continue."
            actionBtnTxt="Continue"
          >
            {BONUS_FORMULA_WARNING}
          </ModalConfirmation>
        )}
      </>
    </ModalBase>
  );
};

const mapStateToProps = ({ companies }) => ({
  selectedCompany: getSelectedCompany({ companies }),
});

export default connect(mapStateToProps, {
  refreshEmployeeData: refreshEmployeeDataAction,
  getAllJobTitles: getAllJobTitlesAction,
})(EditForm);
