// @ts-check
import { forwardRef, useCallback, useState } from 'react';
import { AWAITING_DATA } from '@/constants/charts';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import useTypedSelector from '@/hooks/useTypedSelector';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import CashOutChart from '@/pages/Dashboard/charts/CashOutChart';
import { CHART_TITLE } from '@/pages/Dashboard/constants/cashOut';
import { getMetricValue } from '@/pages/Dashboard/helpers';

/**
 * @typedef {{
 *   children: React.ReactElement;
 *   ChartWidget?: React.ComponentType<import('@/types/dashboard').WidgetProps>;
 * }} CashOutGlanceProps
 */

/**
 * @typedef {{
 *   isLoading: boolean;
 *   total: number;
 * }} CashOutData
 */

/**
 * @type {React.ForwardRefExoticComponent<
 *   CashOutGlanceProps & React.RefAttributes<HTMLElement>
 * >}
 */
const CashOutChartGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    /** @type {ReturnType<typeof useState<CashOutData>>} */
    const [cashOutChart, setCashOutChart] = useState({
      isLoading: true,
      total: 0,
    });
    const { startDate, endDate } = useTypedSelector(({ shared }) => shared);
    const onQueryStateChange = useCallback(([base]) => {
      setCashOutChart({
        isLoading: base.isLoading,
        total: base.data.overallTotal,
      });
    }, []);

    return (
      <ChartWidget
        ref={ref}
        title={CHART_TITLE}
        metricValue={formatMonetary(cashOutChart.total)}
        label={formatRangeWithShortYear(startDate, endDate)}
        data-testid="cashoutWidget"
        url="/dashboard/cash-out"
        tooltipContent="Displays the total amount of cash paid during each period from different accounts"
        isLoading={cashOutChart.isLoading}
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={
                !cashOutChart.isLoading
                  ? formatRangeWithShortYear(startDate, endDate)
                  : ''
              }
              value={
                cashOutChart.isLoading
                  ? AWAITING_DATA
                  : getMetricValue(cashOutChart.total, formatMonetary)
              }
            />
            <CashOutChart
              {...childProps}
              onQueryStateChange={onQueryStateChange}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default CashOutChartGlance;
