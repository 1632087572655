import UsersAvatarIcon from '@bill/cashflow.assets/users-avatar';
import { PropTypes } from 'prop-types';
import ProductTypeFilter from '@/components/ProductTypeFilter/ProductTypeFilter';
import CardWrapper from '@/components/Settings/CardWrapper';
import Header from '@/components/Settings/Header';
import Button from '@/components/common/Button';
import UserInfo from '@/components/common/UserInfo';
import { FPA_LITE } from '@/constants/productTypes';
import { GLANCED_MEMBER_LIST_RECORD_COUNT } from '@/constants/settings';
import useBillBaseUrl from '@/hooks/useBillBaseUrl';
import './MemberList.scss';

const MemberList = ({
  members,
  setShowInvite,
  currentUser,
  setShowMembersDetailModal,
}) => {
  const billBaseUrl = useBillBaseUrl();
  return (
    <CardWrapper>
      <Header
        mode="read"
        onEdit={setShowMembersDetailModal}
        title="Users"
        data-testid="members-edit"
      />
      <ProductTypeFilter productType={FPA_LITE}>
        To invite users, go to your{' '}
        <a href={`${billBaseUrl}/neo3/settings/roles-and-permissions/users`}>
          <b>Payables & Receivables Settings</b>
        </a>
        , select <b>Users & Roles</b>, and then <b>Users</b> and add a new user.
        To manage Forecasting roles, click on the pencil icon at the top. Learn
        more about how Forecasting roles & permissions work{' '}
        <a
          href="https://help.bill.com/direct/s/article/000002829"
          target="_blank"
          rel="noreferrer"
        >
          <b>here</b>
        </a>
        .
      </ProductTypeFilter>
      <ul className="MemberList_List">
        <li className="MemberList_List_Item">
          <UserInfo isLoggedInUser member={currentUser} />
        </li>
        {members.companyUsers?.map((member) => (
          <li key={member.id} className="MemberList_List_Item">
            <UserInfo key={member.id} member={member} />
          </li>
        ))}
        {members.total > GLANCED_MEMBER_LIST_RECORD_COUNT && (
          <Button
            className="Button-cancelLink MemberList_ShowMore"
            onClick={setShowMembersDetailModal}
            data-testid="member-list-show-more"
          >
            <div className="MemberList_ShowMore_Avatar">
              <UsersAvatarIcon />
            </div>
            <span>
              {members.total - GLANCED_MEMBER_LIST_RECORD_COUNT} others
            </span>
          </Button>
        )}
      </ul>
      <ProductTypeFilter>
        <Button
          className="Button-primaryLink"
          onClick={() => {
            setShowInvite(true);
          }}
          data-testid="Button-invite-member"
        >
          + Invite users
        </Button>
      </ProductTypeFilter>
    </CardWrapper>
  );
};

MemberList.propTypes = {
  /** Company members */
  members: PropTypes.shape({
    /** Array of members */
    companyUsers: PropTypes.arrayOf(
      PropTypes.shape({
        /** ID of member */
        id: PropTypes.number.isRequired,
        /** Email of member */
        email: PropTypes.string,
        /** A flag to show if invitation of member is expired */
        isExpiredInvitation: PropTypes.bool,
        /** A flag to show if invitation of member is pending */
        isPendingInvitation: PropTypes.bool,
        /** Name of member */
        name: PropTypes.string,
        /** Permission of member */
        permission: PropTypes.oneOf(['Admin', 'User']),
      }),
    ),
    /** Total members available */
    total: PropTypes.number,
  }),
  /** A function to show user invite modal */
  setShowInvite: PropTypes.func,
  /** Current user information */
  currentUser: PropTypes.shape({
    /** ID of member */
    id: PropTypes.number.isRequired,
    /** Email of member */
    email: PropTypes.string,
    /** Name of member */
    name: PropTypes.string,
    /** Permission of member */
    permission: PropTypes.oneOf(['Admin', 'User']),
  }),
  /** A function to show users detail */
  setShowMembersDetailModal: PropTypes.func,
};

export default MemberList;
