import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { handleStreamDataChangeAction } from '@/actions/revenue';
import PlanNameWithInput from '@/components/Revenue/PlanNameWithInput';
import RevenueStreamFooter from '@/components/Revenue/RevenueStream/RevenueStreamFooter';
import Checkbox from '@/components/common/Checkbox';
import WithTooltip from '@/components/common/WithTooltip';
import { REVENUE_STRIPE } from '@/constants/integrations';
import { ONE_TIME_INDEX } from '@/constants/revenue';
import {
  ONE_TIME,
  SUBSCRIPTION,
  ADVANCED_SUBSCRIPTION,
} from '@/constants/revenueStream';
import './StepTwoPricingPlan.scss';

const getPlans = (revenueType, pricePlansList) => {
  if (revenueType === ONE_TIME) {
    return pricePlansList.filter((pp) => pp.frequency === ONE_TIME_INDEX);
  }
  if ([SUBSCRIPTION, ADVANCED_SUBSCRIPTION].includes(revenueType)) {
    return pricePlansList.filter((pp) => pp.frequency !== ONE_TIME_INDEX);
  }
  return pricePlansList;
};

const getEnrichedPlans = ({ plans, mode, plansAssociated }) => {
  if (mode === 'add') {
    return plans.map((plan) => {
      return {
        ...plan,
        isChecked: false,
        estimatedValue: 1,
      };
    });
  }
  return plans.map((plan) => {
    const planFound = plansAssociated.find((p) => p.id === plan.id);
    if (planFound) {
      return {
        ...planFound,
        isChecked: true,
        estimatedValue: planFound.estimatedValue,
      };
    }
    return {
      ...plan,
      isChecked: false,
      estimatedValue: 0,
    };
  });
};

const StepTwoPricingPlan = ({
  pricePlansList,
  handleStreamDataChange,
  mode,
  revenue,
  revenueStream,
  setStep,
  step,
  saving,
  handleClose,
  isViewOnly,
}) => {
  const { plansAssociated, revenueType } = revenueStream;
  const showAsRadio = revenueType === ADVANCED_SUBSCRIPTION;
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const isSelectable = (plan) => {
    return !(
      (plan.isUsedInStripe && plan.isUsedInRevenueStream) ||
      (revenue?.externalSource === REVENUE_STRIPE && !plan.isUsedInStripe)
    );
  };

  useEffect(() => {
    setIsAllSelected(
      selectedPlans
        .filter((plan) => isSelectable(plan))
        .every((plan) => plan.isChecked),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [selectedPlans, setIsAllSelected]);

  useEffect(() => {
    const plans = getPlans(revenueType, pricePlansList);
    const enrichedPlans = getEnrichedPlans({
      plans,
      mode,
      plansAssociated,
    });
    setSelectedPlans(enrichedPlans);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [pricePlansList]);

  useEffect(() => {
    handleStreamDataChange(
      'plansAssociated',
      selectedPlans.filter((p) => p.isChecked),
    );
  }, [selectedPlans, handleStreamDataChange]);

  const handlePlansChange = (id, checked) => {
    if (isViewOnly) {
      return;
    }
    const changedPlans = selectedPlans.map((plan) => {
      if (plan.id === id) {
        return { ...plan, isChecked: showAsRadio ? true : checked };
      }
      return showAsRadio ? { ...plan, isChecked: false } : plan;
    });
    handleStreamDataChange(
      'plansAssociated',
      changedPlans.filter((p) => {
        return p.isChecked;
      }),
    );
    setSelectedPlans(changedPlans);
  };

  const handleSelectAll = () => {
    const selected = !isAllSelected;
    const plans = selectedPlans.map((plan) => ({
      ...plan,
      isChecked: isSelectable(plan) && selected,
    }));
    setSelectedPlans(plans);
  };

  return (
    <>
      <div className="Form RevenueStreamPricingPlans_Form">
        <div className="Form_Group RevenueStreamPricingPlans_Header">
          <p>Select the pricing plans to which this stream applies.</p>
          <p className="RevenueStreamPricingPlans_Label">Pricing Plan Name</p>
          {!showAsRadio && (
            <Checkbox
              id="pricing-plans-select-all"
              className="RevenueStreamPricingPlans_SelectAll"
              onChange={handleSelectAll}
              checked={isAllSelected}
            >
              Select All
            </Checkbox>
          )}
        </div>
        <div className="Form_Group RevenueStreamPricingPlans_Plans">
          {selectedPlans &&
            selectedPlans.map((plan) => {
              return isSelectable(plan) ? (
                <div className="StepTwoPricingPlan" key={plan.id}>
                  <PlanNameWithInput
                    id={plan.id}
                    value={plan.id}
                    product={plan}
                    isChecked={plan.isChecked}
                    className="StepTwoPricingPlan_Label"
                    asRadio={showAsRadio}
                    onChange={() => handlePlansChange(plan.id, !plan.isChecked)}
                  />
                </div>
              ) : (
                <WithTooltip
                  key={plan.id}
                  placement="left"
                  content={
                    plan.isUsedInStripe && plan.isUsedInRevenueStream
                      ? 'This is a Stripe generated Pricing Plan. You cannot associate it with another revenue stream (driver).'
                      : 'This pricing plan cannot be selected, as it is currently associated with another revenue stream (driver).'
                  }
                  data-testid={`plan-tooltip-${plan.id}`}
                >
                  <div className="StepTwoPricingPlan">
                    <PlanNameWithInput
                      id={plan.name}
                      value={plan.name}
                      product={plan}
                      isChecked={plan.isChecked}
                      isDisabled
                      className="StepTwoPricingPlan_Label"
                      onChange={() =>
                        handlePlansChange(plan.id, !plan.isChecked)
                      }
                    />
                  </div>
                </WithTooltip>
              );
            })}
        </div>
      </div>
      <RevenueStreamFooter
        setStep={setStep}
        step={step}
        nextLoading={saving}
        disablePrevious
        disableNext={!plansAssociated.length}
        revenueStream={revenueStream}
        handleClose={() => handleClose()}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  pricePlansList: state.revenues.pricePlansList,
  revenueStream: state.revenues.revenueStream,
});

export default connect(mapStateToProps, {
  handleStreamDataChange: handleStreamDataChangeAction,
})(StepTwoPricingPlan);
