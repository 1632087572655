// @ts-check
import PropTypes from 'prop-types';
import InfoTooltip from '@/components/common/InfoTooltip';
import ExpandAllToggle from './ExpandAllToggle';
import './HeaderRenderer.scss';

/**
 * @typedef {{
 *   enableExpandAll?: boolean;
 *   tooltip?: string;
 * } & import('ag-grid-community').IHeaderParams<unknown>} HeaderRendererProps
 */
/**
 * A column header renderer with support for tooltips and an expand/collapse all
 * toggle, for use in ag-Grid
 *
 * @example
 *   columnDefs={[
 *    {
 *      ...
 *      headerComponent: HeaderRenderer,
 *      headerComponentParams: { tooltip: 'Foo' }
 *    }
 *   ]}
 *
 * @type {(params: HeaderRendererProps) => React.ReactElement}
 * @see https://www.ag-grid.com/react-grid/component-header/
 */
function HeaderRenderer({
  api,
  column,
  displayName,
  enableExpandAll,
  tooltip,
}) {
  const testId = `${column.getColId()}-header`;
  const hasGroups =
    column.isRowGroupActive() ||
    api.getRenderedNodes().some(({ allChildrenCount }) => allChildrenCount);

  return (
    <div className="HeaderRenderer" data-testid={testId}>
      <span className="HeaderRenderer_Name">{displayName}</span>{' '}
      {tooltip && (
        <InfoTooltip data-testid={`${testId}-tooltip`} placement="top">
          {tooltip}
        </InfoTooltip>
      )}
      {enableExpandAll && hasGroups && <ExpandAllToggle api={api} />}
    </div>
  );
}

HeaderRenderer.propTypes = {
  /** Column object supplied by ag-Grid */
  column: PropTypes.objectOf(PropTypes.any),
  /** Label for the column */
  displayName: PropTypes.string.isRequired,
  /** Whether to show an expand/collapse all toggle */
  enableExpandAll: PropTypes.bool,
  /** Content for a context help tooltip, if desired */
  tooltip: PropTypes.string,
  /**
   * AG Grid's api
   *
   * @see https://ag-grid.com/react-data-grid/grid-api/
   */
  api: PropTypes.objectOf(PropTypes.any),
};

export default HeaderRenderer;
