// @ts-check
import { m as motion } from 'framer-motion';
import useCellOverflow from '@/components/common/Spreadsheet/useCellOverflow';
import WithTooltip from '@/components/common/WithTooltip';
import { isNumber } from '@/helpers';
import { ReactComponent as FillRightIcon } from '@/assets/images/fill-right.svg';
import './FormulaMonthRenderer.scss';

function isFormula(formula) {
  return !!formula && !isNumber(formula);
}

const ANIM_HIDDEN = { opacity: 0, x: '-75%' };

/**
 * A renderer providing affordances for formula-backed monthly values, such as a
 * tooltip and 'fill right' behavior.
 *
 * @example
 *   <MonthlySpreadsheet
 *    renderer={FormulaMonthRenderer}
 *    rendererParams={{ onFillRightClick }}
 *    ...
 *   />
 */
function FormulaMonthRenderer({
  api,
  colDef,
  column,
  context,
  'data-testid': dataTestId,
  eGridCell,
  node,
  onFillRightClick,
  value,
  valueFormatted,
}) {
  // Toggle overflow on the cell so that the Fill Right button is visible
  useCellOverflow(eGridCell);

  const handleFillRightClick = () => {
    const { loadingCells } = context;
    context.loadingCells = {
      ...loadingCells,
      [node.id]: [
        ...(loadingCells[node.id] ?? []),
        colDef.colId ?? colDef.field,
      ],
    };
    api.refreshCells({ columns: [column], rowNodes: [node] });

    const month = column.getParent().getGroupId();
    onFillRightClick({
      month,
      displayFormula: value.displayFormula ?? value.value,
    });
  };

  return (
    <>
      {valueFormatted && (
        <>
          <motion.button
            animate={{ opacity: 1, x: 0 }}
            exit={ANIM_HIDDEN}
            initial={ANIM_HIDDEN}
            transition={{
              type: 'spring',
              damping: 6,
              mass: 0.2,
            }}
            className="FormulaMonthRenderer_FillRightBtn"
            data-testid={`${dataTestId}-fill-right`}
            onClick={handleFillRightClick}
          >
            <FillRightIcon aria-label="Fill right" />
          </motion.button>

          {isFormula(value.displayFormula) ? (
            <WithTooltip
              content={<>{value.displayFormula}</>}
              placement="bottom"
              className="FormulaMonthRenderer_Tooltip"
              data-testid={`${dataTestId}-tooltip`}
            >
              <span
                className="FormulaMonthRenderer_Value"
                data-testid={dataTestId}
              >
                {valueFormatted}
              </span>
            </WithTooltip>
          ) : (
            <span
              className="FormulaMonthRenderer_Value"
              data-testid={dataTestId}
            >
              {valueFormatted}
            </span>
          )}
        </>
      )}
    </>
  );
}

export default FormulaMonthRenderer;
