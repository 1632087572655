import { useState, useCallback, useRef } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import {
  getCustomVariablesAction,
  getCustomVariableSectionsAction,
} from '@/actions/variables';
import Button from '@/components/common/Button';
import BulkUploadButton from '@/components/common/DocumentUploader/BulkUploadButton';
import DocumentUploader from '@/components/common/DocumentUploader/DocumentUploader';
import {
  statuses,
  acceptedFileExtensionTypes,
} from '@/components/common/DocumentUploader/constants';
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';
import Modal from '@/components/common/Modal';
import { createBulkCustomVariables } from '@/services/variable.service';
import './MultipleVariablesModal.scss';

const EXCEL_TEMPLATE =
  'https://drive.google.com/uc?export=download&id=1EDBTnuoFKCsy2oHu5WrMfGBLQoTMKnhT';

const initialState = {
  status: statuses.INIT,
  initiatedAt: null,
  progressLoaded: 0,
  progressTotal: 0,
  files: null,
  error: null,
};

const DropzoneContent = () => (
  <p className="Dropzone_Text">
    Import from an Excel spreadsheet by dragging it here or{' '}
    <button type="button" className="Button Button-primaryLink">
      browsing
    </button>{' '}
    your local drive.
  </p>
);

const ModalContent = ({ onClose }) => {
  const createVariablesPromise = useRef(null);

  const [tabName, setTabName] = useState('');
  const [state, setState] = useState(initialState);

  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector(({ shared }) => ({
    startDate: shared.startDate,
    endDate: shared.endDate,
  }));
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);

  const getCustomVariables = useCallback(
    (...args) => dispatch(getCustomVariablesAction(...args)),
    [dispatch],
  );

  const getCustomVariableSections = useCallback(
    (...args) => dispatch(getCustomVariableSectionsAction(...args)),
    [dispatch],
  );

  const resetState = useCallback(() => setState(initialState), [setState]);

  const handleFinish = useCallback(() => {
    getCustomVariables(scenarioId, startDate, endDate);
    getCustomVariableSections(scenarioId);
    onClose();
  }, [
    getCustomVariableSections,
    getCustomVariables,
    onClose,
    endDate,
    startDate,
    scenarioId,
  ]);

  const onFilesSelected = useCallback(
    (droppedFiles) => {
      setState((previousState) => ({
        ...previousState,
        status: statuses.FILE_SELECTED,
        files: droppedFiles,
      }));
    },
    [setState],
  );

  const onSelectedFilesRejected = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      status: statuses.FILE_SELECTED_ERROR,
    }));
  }, [setState]);

  const onCancelUpload = useCallback(() => {
    createVariablesPromise.current?.cancel();
    resetState();
  }, [resetState]);

  const onUploadProgress = useCallback(
    (event) => {
      setState((previousState) => ({
        ...previousState,
        progressLoaded: event.loaded,
        progressTotal: event.total,
      }));
    },
    [setState],
  );

  const handleUpload = useCallback(async () => {
    const promise = createBulkCustomVariables({
      file: state.files[0],
      onUploadProgress,
      params: { scenarioId, tabName },
    });
    createVariablesPromise.current = promise;

    setState((previousState) => ({
      ...previousState,
      initiatedAt: new Date(),
      status: statuses.UPLOADING,
    }));
    try {
      await promise;
      setState((previousState) => ({
        ...previousState,
        status: statuses.UPLOADING_COMPLETE,
      }));
    } catch (e) {
      if (createVariablesPromise.current?.signal?.aborted) {
        resetState();
        return;
      }

      const { errorDetails, errorMessage, childErrors } = e.response.data.error;
      setState((previousState) => ({
        ...previousState,
        error: {
          title: errorMessage,
          body: errorDetails,
          childErrors,
        },
      }));
      setState((previousState) => ({
        ...previousState,
        status: statuses.ERROR,
      }));
    }
  }, [scenarioId, tabName, state.files, onUploadProgress, resetState]);

  const isTabNameEmpty = tabName === '';

  return (
    <div className="ModalBase_Wrapper">
      <header className="ModalBase_Header">
        <h2 className="ModalBase_Heading">Import Multiple Variables</h2>
      </header>
      <div className="ModalBase_Content">
        <p>
          Unsure where to begin? Use our sample{' '}
          <a
            className="link"
            target="_blank"
            rel="noopener noreferrer"
            href={EXCEL_TEMPLATE}
          >
            <strong>Excel template</strong>
          </a>{' '}
          to enter custom variables.
        </p>
        <DocumentUploader
          id="multiple-variables-dropzone"
          data-testid="multiple-variables-dropzone"
          acceptedFileTypes={acceptedFileExtensionTypes.XLSX}
          onFilesSelected={onFilesSelected}
          onSelectedFilesRejected={onSelectedFilesRejected}
          onCancelUpload={onCancelUpload}
          uploadState={state}
          error={state.error}
          DropzoneContent={DropzoneContent}
        />
        <FormLabel htmlFor="tab-name" text="Tab Name" />
        <FormField
          id="tab-name"
          value={tabName}
          onChange={({ target }) => setTabName(target.value)}
          placeholder="Tab name"
          validate={() => isTabNameEmpty && 'Field is required'}
        />
      </div>
      <div className="ModalBase_Footer">
        <>
          {state.status !== statuses.UPLOADING_COMPLETE && (
            <Button
              className="Button Button-cancelLink"
              onClick={() => {
                onCancelUpload();
                onClose();
              }}
              data-testid="multiple-variables-cancel-button"
            >
              Cancel
            </Button>
          )}
          <BulkUploadButton
            handleFinish={handleFinish}
            handleUpload={handleUpload}
            resetModal={resetState}
            status={state.status}
            disabled={isTabNameEmpty}
          />
        </>
      </div>
    </div>
  );
};

const MultipleVariablesModal = ({ open, onClose }) => {
  return (
    <Modal
      data-testid="multiple-variables-modal"
      className="MultipleVariablesModal"
      open={open}
      onClose={onClose}
    >
      <ModalContent onClose={onClose} />
    </Modal>
  );
};

export default MultipleVariablesModal;
