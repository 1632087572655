// @ts-check
import { useCallback, useEffect, useState } from 'react';

/** @typedef {Record<string, string>} ForecastMethods */

/**
 * Hook for managing forecast methods. It populates based on the initial query
 * that fires when the page is launched. Then it allows for optimistic updates
 * as the user changes methods. Required so forecast formulas and methods don't
 * get out of sync.
 *
 * @type {(
 *   rowData:
 *     | import('@/types/services/backend').CashInOutGrid[]
 *     | import('@/hooks/useReportData').ReportsData[],
 * ) => [ForecastMethods, (id: string, forecastMethod: string) => void]}
 */
const useForecastMethods = (rowData) => {
  const [forecastMethods, setForecastMethods] = useState(
    /** @type {Record<string, string>} */ {},
  );

  const onSetForecastMethod = useCallback((id, forecastMethod) => {
    setForecastMethods((prev) => {
      if (prev[id] === forecastMethod) {
        return prev;
      }
      return {
        ...prev,
        [id]: forecastMethod,
      };
    });
  }, []);

  useEffect(() => {
    if (
      Array.isArray(rowData) &&
      rowData.length > 0 &&
      Object.keys(forecastMethods).length === 0
    ) {
      const methods = rowData.reduce((acc, row) => {
        return {
          ...acc,
          [row.id]: row.forecastMethod,
        };
      }, {});
      setForecastMethods(methods);
    }
  }, [forecastMethods, rowData, setForecastMethods]);

  return [forecastMethods, onSetForecastMethod];
};

export default useForecastMethods;
