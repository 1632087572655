import Api from './Api';

/**
 * Exchange a code and redirectUri for information about the Xero user
 *
 * @param {string} code - The code provided by Xero
 * @param {string} redirectUri - The redirectUri we provided previously to Xero
 * @returns {Promise} API response
 */
const xeroAuthorization = ({ code, redirectUri }) => {
  return Api.post(
    '/xero/idTokenData',
    { code, redirectUri },
    { headers: false },
  );
};

export default xeroAuthorization;
