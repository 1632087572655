import { useState } from 'react';
import { connect } from 'react-redux';
import { handleStreamDataChangeAction } from '@/actions/revenue';
import PlanName from '@/components/Revenue/PlanName';
import RevenueStreamFooter from '@/components/Revenue/RevenueStream/RevenueStreamFooter';
import NumberField from '@/components/common/NumberField';
import {
  DRIVER,
  ONE_TIME,
  SUBSCRIPTION,
  STREAMS,
} from '@/constants/revenueStream';
import { isPercentageValid } from '@/helpers/validators';
import {
  updateMOMGrowthRevenueSourceForOneTime,
  createMOMGrowthRevenueSourceForOneTime,
  createRevenueSource,
  updateRevenueSource,
} from '@/services/revenueService';
import './StepFour.scss';

const getEstimate = (growth, beg) => {
  let total = beg;
  for (let i = 1; i < 12; i++) {
    total += (total / 100) * growth;
  }
  return total;
};

const StepFour = ({
  handleStreamDataChange,
  revenueStream,
  setStep,
  step,
  handleClose,
  mode,
  scenarioId,
  globalStartDate,
  globalEndDate,
  onSaved,
}) => {
  const { acquisitionGrowthRate, revenueType, driverType, plansAssociated } =
    revenueStream;

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  const calNoOfCustomerNextMonth = (plan) => {
    const noOfCustomerNextMonth =
      // eslint-disable-next-line radix -- predates description requirement
      parseInt(plan.estimatedValue) +
      // eslint-disable-next-line radix -- predates description requirement
      (plan.estimatedValue / 100) * parseInt(acquisitionGrowthRate);
    return Math.round(noOfCustomerNextMonth);
  };

  const calNoOfCustomerAYear = (plan) => {
    const noOfCustomerAYear = getEstimate(
      acquisitionGrowthRate,
      plan.estimatedValue,
    );
    return Math.round(noOfCustomerAYear);
  };

  const handleSubmit = async () => {
    const params = {
      scenarioId,
      revenueStream,
      globalStartDate,
      globalEndDate,
    };
    try {
      setSaving(true);
      setError('');
      switch (true) {
        case revenueStream.revenueType === SUBSCRIPTION && mode === 'edit':
          await updateRevenueSource(params);
          break;
        case revenueStream.revenueType === SUBSCRIPTION:
          await createRevenueSource(params);
          break;
        case revenueStream.revenueType === ONE_TIME && mode === 'edit':
          await updateMOMGrowthRevenueSourceForOneTime(params);
          break;
        case revenueStream.revenueType === ONE_TIME:
          await createMOMGrowthRevenueSourceForOneTime(params);
          break;
        default:
          throw new Error('unknown case');
      }
      onSaved?.();
      handleClose();
    } catch (e) {
      setError(e.response?.data?.error?.errorMessage || e.message);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="StepFour">
      {error && (
        <p className="SubscriptionRevenue_ErrorAlert">
          <b>Error </b> - {error}
        </p>
      )}
      <div className="StepFour_GrowForm">
        <p>
          {revenueType === ONE_TIME
            ? 'How much do you plan to grow your new purchases each month?'
            : 'How much do you plan to grow your customer base each month?'}
        </p>
        <NumberField
          prefix="%"
          id="acquisitionGrowthRate"
          name="acquisitionGrowthRate"
          allowNegativeValues={false}
          max={100}
          value={acquisitionGrowthRate}
          onChange={({ target }, number) => {
            handleStreamDataChange(target.name, number);
          }}
          validate={() => {
            return (
              !isPercentageValid(acquisitionGrowthRate) &&
              'Please enter a valid percentage'
            );
          }}
          data-testid="add-revenue-stream-growth"
        />
      </div>
      {driverType === DRIVER.MONTHLY_GROWTH && (
        <div className="StepFour_PricingPlanListInfo">
          <div>
            <h3 className="PricingPlanList_Info">
              {`Total ${STREAMS[revenueType].pricingPlanItem}`}
            </h3>
          </div>
          <div className="RevenueStream_Alert">
            {STREAMS[revenueType].pricingPlanInfo}
          </div>
          <div className="PricingPlanList">
            <div className="PricingPlan font-weight-bold">
              <div className="PricingPlan_PlanName">Pricing Plan name</div>
              <div className="PricingPlan_Subscribers">
                {STREAMS[revenueType].pricingPlanItem} next month
              </div>
              <div className="PricingPlan_Subscribers">
                {STREAMS[revenueType].pricingPlanItem} in 12 months
              </div>
            </div>

            {isPercentageValid(acquisitionGrowthRate)
              ? plansAssociated
                  .filter((p) => p.estimatedValue !== '' && p.name !== '')
                  .map((plan) => (
                    <div className="PricingPlan" key={plan.id}>
                      <PlanName
                        product={plan}
                        className="PricingPlan_PlanName"
                      />
                      <div className="PricingPlan_Subscribers">
                        {calNoOfCustomerNextMonth(plan)}
                      </div>
                      <div className="PricingPlan_Subscribers">
                        {calNoOfCustomerAYear(plan)}
                      </div>
                    </div>
                  ))
              : null}
          </div>
        </div>
      )}
      <RevenueStreamFooter
        setStep={setStep}
        step={step}
        nextLoading={saving}
        disablePrevious
        disableNext={!isPercentageValid(revenueStream.acquisitionGrowthRate)}
        revenueStream={revenueStream}
        handleClose={() => handleClose()}
        handleNext={handleSubmit}
        nextText={mode === 'edit' ? 'Save' : 'Add'}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  revenueStream: state.revenues.revenueStream,
  scenarioId: state.scenario.scenarioId,
  globalStartDate: state.shared.startDate,
  globalEndDate: state.shared.endDate,
});

export default connect(mapStateToProps, {
  handleStreamDataChange: handleStreamDataChangeAction,
})(StepFour);
