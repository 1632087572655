import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@/components/common/Button';
import FormField from '@/components/common/FormField';
import { validateEmail } from '@/helpers/validators';
import { BILL_LOGIN_URL } from '@/runtimeConfig';
import { checkIsRegistered } from '@/services/signUpService';
import { actions, steps } from './constants';

const ACCOUNT_CREATION_RESTRICTED = 'B-327';

const Email = ({ email, onChange, errorMessage = '' }) => {
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(errorMessage);
  const [accountCreationRestricted, setAccountCreationRestricted] =
    useState(false);
  const history = useHistory();
  const [newValue, setNewValue] = useState(email);

  useEffect(() => {
    onChange({ type: actions.SET_STEP, payload: steps.EMAIL });
  }, [onChange]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMsg('');
    setAccountCreationRestricted(false);
    try {
      const {
        data: {
          data: { value },
        },
      } = await checkIsRegistered(newValue);
      if (value) {
        history.push('/join/login');
      } else {
        history.push('/join/password');
      }
    } catch (e) {
      if (e.response?.data?.error?.errorCode === ACCOUNT_CREATION_RESTRICTED) {
        setAccountCreationRestricted(true);
      } else {
        setErrorMsg(e.response?.data?.error?.errorMessage || e.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="RegistrationHeading">Connect to Finmark</h1>
      <p className="RegistrationText">
        Let's get you connected to Finmark. The first thing we need is your
        email address, so start by entering it below.
      </p>
      <form className="RegistrationContent" onSubmit={handleSubmit}>
        <div className="Registration_FieldGroup">
          <label htmlFor="email" className="RegistrationLabel">
            Email
          </label>
          <FormField
            className="RegistrationField"
            data-testid="email"
            id="email"
            name="email"
            placeholder="Enter your email address..."
            onChange={({ target }) => {
              onChange({ type: actions.SET_EMAIL, payload: target.value });
              setNewValue(target.value);
            }}
            type="email"
            validate={(value) =>
              value && !validateEmail(value) && 'Please enter a valid email!'
            }
            value={newValue}
            autoFocus
          />
        </div>
        {errorMsg && !validateEmail(newValue) && (
          <div className="RegistrationAlert">{errorMsg}</div>
        )}
        {accountCreationRestricted && (
          <div
            className="RegistrationAlert"
            data-testid="account-creation-restricted-alert"
          >
            Account creation restricted. For Bill.com access, please login using
            this{' '}
            <a href={`${BILL_LOGIN_URL}`} target="_blank" rel="noreferrer">
              link
            </a>
            .
          </div>
        )}
        <Button
          type="submit"
          id="submit-email"
          className="Button-registration"
          data-testid="button-next-email"
          disabled={!validateEmail(newValue) || accountCreationRestricted}
          loading={isLoading}
        >
          Next
        </Button>
      </form>
    </>
  );
};

export default Email;
