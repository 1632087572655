import { Spinner } from 'react-bootstrap';

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
const Loader = () => (
  <Spinner animation="border" role="status">
    <span className="sr-only">Loading...</span>
  </Spinner>
);

export default Loader;
