// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import BackArrowIcon from '@bill/cashflow.assets/back-arrow';
import SwapIcon from '@bill/cashflow.assets/swap';
import { toggleEditMode } from '@/actions/dashboard';
import ProductTypeFilter from '@/components/ProductTypeFilter/ProductTypeFilter';
import ScenarioSelect from '@/components/Scenarios/ScenarioSelect';
import CoBranding from '@/components/common/CoBranding';
import DashboardLink from '@/components/common/DashboardLink';
import NavLink from '@/components/common/NavLink';
import Permissions from '@/components/common/Permissions';
import WithTooltip from '@/components/common/WithTooltip';
import PAGES, {
  CASH_IN_OUT,
  DASHBOARD_PATH,
  EXPENSES_PATH,
  VARIABLES_PATH,
} from '@/constants/pages';
import { actions, subjects } from '@/constants/permissions';
import { FPA_LITE } from '@/constants/productTypes';
import { NO_COMPARISON, SCENARIO_ID } from '@/constants/scenario';
import useNotificationContext from '@/contexts/useNotificationContext';
import { classNames, getDomainAndResourceHost } from '@/helpers';
import isBillDomain from '@/helpers/isBillDomain';
import useDashboardPath from '@/hooks/useDashboardPath';
import { ReactComponent as FinmarkLogo } from '@/assets/images/bill_finmark_logo.svg';
import './TheMainNavigation.scss';

const { READ, READ_WRITE } = actions;
const { DASHBOARD, NON_DASHBOARD, EMPLOYEE_SETTINGS } = subjects;

function MainNavigationItem({
  'data-testid': dataTestId,
  expanded,
  url,
  ...props
}) {
  const pathname = url.includes(DASHBOARD_PATH) ? DASHBOARD_PATH : url;
  const { icon: Icon, titleText } = PAGES[pathname];
  return (
    <WithTooltip
      className="TheMainNavigation_ItemTooltip"
      content={titleText}
      placement="right"
      trigger={!expanded ? 'mouseenter focus' : ''}
      data-testid={`${dataTestId}-tooltip`}
    >
      <NavLink
        to={url}
        className="TheMainNavigation_Item"
        data-testid={dataTestId}
        {...props}
      >
        <div>
          <Icon className="TheMainNavigation_ItemIcon" aria-hidden="true" />
          <span className="TheMainNavigation_ItemLabel">{titleText}</span>
        </div>
      </NavLink>
    </WithTooltip>
  );
}

const Logo = ({ subDomain, resourceHost, expanded }) => {
  const logoSize = expanded ? 'large' : 'small';
  return subDomain ? (
    <CoBranding
      subDomain={subDomain}
      resourceHost={resourceHost}
      logoSize={logoSize}
    />
  ) : (
    <FinmarkLogo className="FinmarkLogo" />
  );
};

/** The primary app navigation, presented as a sidebar. */
export default function TheMainNavigation({
  expanded,
  handleExpand,
  scenarios,
  selectedScenarioId,
  setScenarioId,
  scenarioLoader,
  compareScenarioId,
  setCompareScenarioId,
  swapScenarioIds,
  ...props
}) {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { subDomain, resourceHost } = getDomainAndResourceHost();
  const dashboardPath = useDashboardPath();
  const { margin } = useNotificationContext();

  const isHeaderScenarioSelectEnabled = isBillDomain();

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props -- predates description requirement */}
      <nav
        className="TheMainNavigation"
        aria-expanded={expanded}
        style={{ marginTop: margin, marginBottom: margin }}
        {...props}
        data-testid="mainNav"
      >
        <div
          className={classNames(
            'TheMainNavigation_Logo',
            subDomain && 'TheMainNavigation_PartnerLogo',
          )}
        >
          <DashboardLink
            to={{ pathname: dashboardPath }}
            className="FinmarkLogo_Link"
          >
            <Logo
              subDomain={subDomain}
              resourceHost={resourceHost}
              expanded={expanded}
            />
          </DashboardLink>
        </div>
        {!isHeaderScenarioSelectEnabled && (
          <>
            <ScenarioSelect
              className="ScenarioWrapper_SideNav"
              id="scenario-selector"
              scenarios={scenarios}
              selectedScenarioId={selectedScenarioId}
              setScenarioId={setScenarioId}
              scenarioLoader={scenarioLoader}
              scenarioSelectType="base"
              label={expanded ? 'Selected Scenario' : 'Selected'}
            />
            <div className="ScenarioSwap_Wrapper-sideNav">
              {selectedScenarioId !== compareScenarioId &&
                compareScenarioId !== NO_COMPARISON && (
                  <SwapIcon
                    className="ScenarioSwap_Icon-sideNav"
                    onClick={() => {
                      const queryParams = new URLSearchParams(location.search);
                      queryParams.set(SCENARIO_ID, compareScenarioId);
                      history.replace({ search: `?${queryParams.toString()}` });
                      swapScenarioIds();
                      dispatch(toggleEditMode(false));
                    }}
                  />
                )}
            </div>
            <ScenarioSelect
              className="ScenarioWrapper_SideNav"
              id="scenario-comparison-selector"
              scenarios={scenarios}
              selectedScenarioId={compareScenarioId}
              setScenarioId={setCompareScenarioId}
              scenarioLoader={scenarioLoader}
              scenarioSelectType="compare"
              label={expanded ? 'Compare Scenario' : 'Compare'}
            />
          </>
        )}
        <button
          type="button"
          className="TheMainNavigation_ExpandBtn"
          aria-hidden="true"
          onClick={() => handleExpand(!expanded)}
        >
          <span>
            <BackArrowIcon className="TheMainNavigation_ExpandBtnIcon" />
          </span>
        </button>
        <div
          data-testid="navigation-items-container"
          className="TheMainNavigation_Container"
        >
          <Permissions
            action={READ}
            subject={DASHBOARD}
            scenarioPermissionRequired
          >
            <MainNavigationItem
              url={dashboardPath}
              expanded={expanded}
              data-testid="mainNav-dashboard"
            />
          </Permissions>
          <ProductTypeFilter productType={FPA_LITE}>
            <Permissions
              action={READ}
              subject={NON_DASHBOARD}
              scenarioPermissionRequired
            >
              <MainNavigationItem
                url={CASH_IN_OUT}
                expanded={expanded}
                data-testid="mainNav-cash-in-out"
              />
            </Permissions>
          </ProductTypeFilter>
          <Permissions
            action={READ}
            subject={NON_DASHBOARD}
            scenarioPermissionRequired
          >
            <ProductTypeFilter>
              <Permissions action={READ_WRITE} subject={EMPLOYEE_SETTINGS}>
                <MainNavigationItem
                  url="/employees"
                  expanded={expanded}
                  data-testid="mainNav-employees"
                />
              </Permissions>
              <MainNavigationItem
                url={EXPENSES_PATH}
                expanded={expanded}
                data-testid="mainNav-expenses"
              />
              <MainNavigationItem
                url="/revenue"
                expanded={expanded}
                data-testid="mainNav-revenue"
              />
              <MainNavigationItem
                url="/actuals"
                expanded={expanded}
                data-testid="mainNav-actuals"
              />
              <MainNavigationItem
                url="/reports"
                expanded={expanded}
                data-testid="mainNav-reports"
              />
            </ProductTypeFilter>
            <MainNavigationItem
              url={VARIABLES_PATH}
              expanded={expanded}
              data-testid="mainNav-variables"
            />
            <ProductTypeFilter>
              <MainNavigationItem
                url="/metrics"
                expanded={expanded}
                data-testid="mainNav-metrics"
              />
            </ProductTypeFilter>
          </Permissions>
        </div>
      </nav>

      {expanded && (
        <div
          className="TheMainNavigation_Backdrop"
          data-testid="mainNav-backdrop"
          aria-hidden="true"
          onClick={() => handleExpand(false)}
        />
      )}
    </>
  );
}
