// @ts-check
import QuestionMarkIcon from '@bill/cashflow.assets/question-mark';
import WithPopover from '@/components/common/WithPopover';
import './SpreadsheetLegend.scss';

/**
 * @typedef {{
 *   editable: boolean;
 * }} ToolbarLegendProps
 */

/**
 * The default color legend
 *
 * @example
 *   <ToolbarLegend />;
 *
 * @type {(props: ToolbarLegendProps) => React.ReactElement}
 */
const ToolbarLegend = ({ editable }) => {
  return (
    <ul className="SpreadsheetToolbar_LegendList">
      {editable && (
        <li className="SpreadsheetToolbar_LegendItem SpreadsheetToolbar_LegendItem-edit">
          Can Edit
        </li>
      )}
      <li className="SpreadsheetToolbar_LegendItem">Cannot Edit</li>
    </ul>
  );
};

/**
 * @typedef {{
 *   content: React.ReactNode;
 *   editable: boolean;
 * }} LegendContentProps
 */

/**
 * @example
 *   <LegendContent />;
 *
 * @type {(props: LegendContentProps) => React.ReactElement}
 */
function LegendContent({ content, editable }) {
  return (
    <>
      {content}
      <ToolbarLegend editable={editable} />
    </>
  );
}

/**
 * @typedef {{
 *   editable?: boolean;
 *   children?: React.ReactNode;
 *   [key: string]: unknown;
 * }} SpreadsheetLegendProps
 */

/**
 * Displays a popover legend explaining the different icons in the
 * MonthlySpreadsheet component
 *
 * @example
 *   <SpreadsheetToolbar Legend={SpreadsheetLegend} />;
 *
 * @type {(props: SpreadsheetLegendProps) => React.ReactElement}
 */
const SpreadsheetLegend = ({ children, editable = true, ...props }) => {
  return (
    <>
      <span
        id="SpreadsheetLegendLabel"
        className="SpreadsheetLegend_Label"
        data-testid="spreadsheet-legend-label"
      >
        Legend:
      </span>
      <WithPopover
        className="SpreadsheetLegend_Popover"
        content={
          <LegendContent content={children} editable={editable} {...props} />
        }
        data-testid="spreadsheetLegend"
        placement="bottom-end"
      >
        <button
          type="button"
          className="Button-iconOnly"
          aria-labelledby="SpreadsheetLegendLabel"
          data-testid="spreadsheet-legend"
        >
          <QuestionMarkIcon className="HelpIcon" aria-hidden="true" />
        </button>
      </WithPopover>
    </>
  );
};

export default SpreadsheetLegend;
