import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import '@/components/Revenue/RevenueStream/RevenueModal.scss';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { createMonthsFromYears } from '@/helpers/createMonthsofYears';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import getBaseScenario from '@/selectors/getBaseScenario';
import getChartSeries from '@/selectors/getChartSeries';
import GridInput from './GridInput';
import PopupButton from './PopupButton';
import SumGridInput from './SumGridInput';

const currentMonth = new Date();
currentMonth.setUTCDate(1);

const CustomRevenue = ({
  customRevenue,
  updateCustomRevenue,
  scenarioId,
  scenario,
  selectedScenario,
}) => {
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [loading, setLoading] = useState(true);

  const chartData = useMemo(() => {
    const value =
      selectedMonth && customRevenue && customRevenue.length > 0
        ? customRevenue.find(
            (c) =>
              Number(c.year) === selectedMonth.getUTCFullYear() ||
              Number(c.year) ===
                Number(selectedMonth.getUTCFullYear().toString().substr(-2)),
          ).months
        : [];
    const data = getChartSeries(
      scenarioId,
      mapMonthlyData(value, 'revenueAmount'),
      { scenario },
    );
    return data;
  }, [customRevenue, scenario, scenarioId, selectedMonth]);

  useEffect(() => {
    setShow(false);
  }, [selectedMonth, customRevenue]);

  const substituteData = useCallback((data) => {
    return data?.length > 0 ? data : createMonthsFromYears(4, 'revenueAmount');
  }, []);

  useEffect(() => {
    if (customRevenue.length <= 0) {
      const updatedCustomRevenue = substituteData(customRevenue);
      updateCustomRevenue(updatedCustomRevenue);
    }
    setTimeout(() => {
      setLoading(false);
    }, 600);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, []);

  const updateRevenuePerMonth = ({ value: revenueAmount, year, month }) => {
    const updateSelectedCustomRevenue = structuredClone(customRevenue);
    const updatedYear = updateSelectedCustomRevenue.find(
      (c) => c.year === year,
    );
    const updatedMonth = updatedYear.months.find((m) => m.value === month);

    if (revenueAmount === null) {
      const revAmount = parseFloat(updatedMonth.revenueAmount);
      updatedYear.months = updatedYear.months.map((m) => {
        if (m.value > month) {
          return { ...m, revenueAmount: revAmount };
        }
        return { ...m };
      });
    } else {
      updatedMonth.revenueAmount = revenueAmount;
    }
    updateCustomRevenue(updateSelectedCustomRevenue);
  };

  const metrics = [
    {
      key: 'y',
      isMainMetric: true,
      name: 'Revenue Amount',
    },
  ];

  const currentYear = selectedMonth.getUTCFullYear();
  const startDate = `${currentYear}-01`;
  const endDate = `${currentYear}-12`;

  return (
    <>
      <section className="Panel Panel-toEdge">
        <header className="ChartHeader">
          <h3 className="ChartHeader_Title">{currentYear}</h3>
        </header>
        <div className="PaddedContainer_WithVerticalMargin">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <DateChart
              data-testid="custom-revenue-amount-chart"
              startDate={startDate}
              endDate={endDate}
              tooltip={<ChartTooltip metrics={metrics} />}
            >
              {!!chartData?.data.length && (
                <DateChart.Series
                  data={chartData.data}
                  index={0}
                  metric="Total Revenue"
                  scenario={selectedScenario}
                />
              )}
            </DateChart>
          )}
        </div>
      </section>
      <div data-testid="custom-rev-grid">
        {customRevenue?.length > 0 &&
          customRevenue.map(({ year, months }) => {
            return (
              <Row key={year.toString()}>
                <Col md={11}>
                  <Row>
                    {months.map((month) => {
                      return (
                        <Col
                          md={1}
                          className="GridInput_Container"
                          key={year + month.name}
                        >
                          <Form.Group className="grid-field-opt mb-4 text-center">
                            <GridInput
                              month={month}
                              year={year}
                              onClick={() =>
                                setSelectedMonth(new Date(month.month))
                              }
                              updateValue={updateRevenuePerMonth}
                              valueKey="revenueAmount"
                              id={month.month}
                            />
                            {selectedMonth.getTime() ===
                              new Date(month.month).getTime() &&
                              selectedMonth.getUTCMonth() < 11 && (
                                <PopupButton
                                  target={target}
                                  setShow={setShow}
                                  show={show}
                                  year={year}
                                  month={month}
                                  setValues={updateRevenuePerMonth}
                                />
                              )}
                          </Form.Group>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col md={1} className="GridInput_Container">
                  <SumGridInput
                    year={year}
                    selectedRevenue={customRevenue}
                    valueKey="revenueAmount"
                    id={String(year)}
                  />
                </Col>
              </Row>
            );
          })}
      </div>
    </>
  );
};

function mapStateToProps({ scenario }) {
  return {
    scenarioId: scenario.scenarioId,
    scenario,
    selectedScenario: getBaseScenario({ scenario }),
  };
}

export default connect(mapStateToProps)(CustomRevenue);
