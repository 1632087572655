import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  addCustomVariableAction,
  addCustomVariableSectionAction,
} from '@/actions/variables';
import { CUSTOM_CHART_INFO } from '@/cacheKeys';
import PageHeader from '@/components/Layouts/PageHeader';
import TableExportButton from '@/components/TableExportButton';
import MultipleVariablesModal from '@/components/Variables/MultipleVariablesModal';
import ButtonAdd from '@/components/common/ButtonAdd';
import DashboardLink from '@/components/common/DashboardLink';
import DropdownOption from '@/components/common/DropdownOption';
import NotificationBanner, {
  notificationTypes,
} from '@/components/common/NotificationBanner';
import Tabs from '@/components/common/Tabs';
import { registeredFeatureFlags } from '@/constants/features';
import { VARIABLES_PATH } from '@/constants/pages';
import { isLimitCustomVariables } from '@/helpers';
import useDashboardPath from '@/hooks/useDashboardPath';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useIsFPALite from '@/hooks/useIsFPALite';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import CustomVariablesList from '@/pages/Variables/CustomVariablesList';
import SystemVariablesList from '@/pages/Variables/SystemVariablesList';
import './Variables.scss';

const CUSTOM_VARIABLES = '';
const SYSTEM_VARIABLES = '/system';
const chartAlerts = {
  SUCCESS: 1,
  ERROR: 2,
};

const Variables = ({
  addCustomVariable,
  addCustomVariableSection,
  customVariables,
  scenarioId,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();
  const isFPALite = useIsFPALite();
  const customVariablesRef = useRef();
  const systemVariablesRef = useRef();
  const [chartNotify, setChartNotify] = useState(null);
  const [showMultipleVariablesModal, setShowMultipleVariablesModal] =
    useState(false);
  const dashboardPath = useDashboardPath();

  const activePath = location.pathname.replace(path, '');

  const hasWritePermission = useNonDashboardWritePermission();

  const queryClient = useQueryClient();
  const handleChartSuccess = () => {
    setChartNotify(chartAlerts.SUCCESS);
    queryClient.invalidateQueries([CUSTOM_CHART_INFO, scenarioId]);
  };

  const handleChartError = () => setChartNotify(chartAlerts.ERROR);
  const isBulkUploadsEnabled = useFeatureFlags(
    registeredFeatureFlags.BULK_UPLOAD_CUSTOM_VARS,
  );

  return (
    <>
      <PageHeader
        page={VARIABLES_PATH}
        addBtnSlot={
          hasWritePermission && (
            <ButtonAdd id="add-variables-button" direction="right">
              <DropdownOption
                id="add-custom-variable"
                disabled={
                  isFPALite && isLimitCustomVariables(customVariables.length)
                }
                onClick={() => {
                  history.push(`${path}${CUSTOM_VARIABLES}`);
                  addCustomVariable(scenarioId);
                }}
              >
                Add Custom Variable
              </DropdownOption>
              {isBulkUploadsEnabled && (
                <DropdownOption
                  id="add-multiple-custom-variable"
                  onClick={() => {
                    history.push(`${path}${CUSTOM_VARIABLES}`);
                    setShowMultipleVariablesModal(true);
                  }}
                >
                  Add Multiple Custom Variables
                </DropdownOption>
              )}
            </ButtonAdd>
          )
        }
      />
      <div className="PageLayout PageLayout-variables">
        <section className="Panel Panel-toEdge">
          <Tabs
            controls={
              activePath === CUSTOM_VARIABLES ? (
                <>
                  {hasWritePermission && (
                    <button
                      type="button"
                      className="Button Button-add"
                      disabled={!customVariables.length}
                      onClick={() => addCustomVariableSection(scenarioId)}
                    >
                      Add Section
                    </button>
                  )}
                  <TableExportButton
                    className="ExportButton"
                    onClick={() =>
                      customVariablesRef.current?.api.exportDataAsExcel({
                        processCellCallback: ({ value }) =>
                          value?.amount ?? value?.displayName,
                      })
                    }
                    disabled={!customVariables.length}
                  />
                </>
              ) : (
                <TableExportButton
                  onClick={() =>
                    systemVariablesRef.current.api.exportDataAsExcel()
                  }
                />
              )
            }
          >
            <Tabs.Panel path={path} label="Custom Variables">
              <CustomVariablesList
                ref={customVariablesRef}
                onChartCreated={handleChartSuccess}
                onChartError={handleChartError}
              />
            </Tabs.Panel>
            <Tabs.Panel
              path={`${path}${SYSTEM_VARIABLES}`}
              label="System Variables"
            >
              <SystemVariablesList
                ref={systemVariablesRef}
                onChartCreated={handleChartSuccess}
                onChartError={handleChartError}
              />
            </Tabs.Panel>
          </Tabs>
        </section>
      </div>
      {chartNotify && (
        <NotificationBanner
          type={
            chartNotify === chartAlerts.SUCCESS
              ? notificationTypes.SUCCESS
              : notificationTypes.ERROR
          }
          onCloseClick={() => setChartNotify(null)}
        >
          {chartNotify === chartAlerts.SUCCESS ? (
            <>
              A new chart has been created for the selected variable in the
              'Custom Charts' section of your Dashboard. You can view the chart
              now by clicking{' '}
              <DashboardLink
                to={{ pathname: dashboardPath }}
                className="NotificationBanner_Link"
              >
                here.
              </DashboardLink>
            </>
          ) : (
            <>
              An error occurred while attempting to create a chart for the
              selected variable. Please try again.
            </>
          )}
        </NotificationBanner>
      )}
      {isBulkUploadsEnabled && (
        <MultipleVariablesModal
          open={showMultipleVariablesModal}
          onClose={() => setShowMultipleVariablesModal(false)}
        />
      )}
    </>
  );
};

function mapStateToProps({ scenario, variables }) {
  return {
    scenarioId: scenario.scenarioId,
    customVariables: variables.customVariables,
  };
}

export default connect(mapStateToProps, {
  addCustomVariable: addCustomVariableAction,
  addCustomVariableSection: addCustomVariableSectionAction,
})(Variables);
