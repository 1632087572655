// @ts-check
import { SCENARIO_COLORS } from '@/constants/colors';

/**
 * Returns a color hex based on the number value provided as an argument
 *
 * @type {(params: number) => string}
 */
const getScenarioColor = (value) =>
  SCENARIO_COLORS[value % SCENARIO_COLORS.length];

export default getScenarioColor;
