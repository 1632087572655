// @ts-check
import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { CUSTOM_CHART } from '@/cacheKeys';
import { getBandedGradient } from '@/components/Charts/colors';
import { chartTypes, timePeriodLabel } from '@/components/Charts/constants';
import { timePeriods } from '@/constants/dateTime';
import {
  AVERAGE_MAIN_METRIC,
  CURRENT_MONTH_MAIN_METRIC,
  ENDING_PERIOD,
  DIFF_LAST_PERIOD,
  getMetricFormatter,
  PREVIOUS_PERIOD,
  SUM_MAIN_METRIC,
  aggregationTypes,
  nonMonthlyValueTypes,
  getChartPrecision,
} from '@/helpers/customCharts';
import {
  formatDateWithShortYear,
  formatRangeWithShortYear,
  getCurrentMonthTimestamp,
  getDateOffsetByMonths,
  getFormattedDateFromTimeStamp,
} from '@/helpers/dateFormatter';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import useSelectedScenarios from '@/hooks/useSelectedScenarios';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import { getMetricValue } from '@/pages/Dashboard/helpers';
import { getTimePeriodValuesForVariables } from '@/services/dashboard.service';

/** @typedef {typeof import('@/constants/variables').units} Units */
/**
 * Retrieves data to populate a chart by querying one or more variable IDs for
 * the currently selected date range and scenario(s).
 *
 * @param {Object[]} variables One or more variable IDs to chart
 * @param {Object} metadata Additional chart parameters
 * @param {'funnel' | 'pie'} metadata.chartType Type of the series in the chart,
 *   e.g. Line
 * @param {Object} [metadata.colors] Map of user-defined colors, keyed by
 *   variable ID
 * @param {Object} [metadata.customVariableNames] User-defined names for the
 *   variables in the chart
 * @param {string} metadata.mainMetric Type of aggregate to display for the main
 *   chart KPI
 * @param {string} [metadata.nonMonthlyMetric] How to aggregate when viewing
 *   quarterly or annually
 * @param {boolean} [metadata.isSecondaryMetricEnabled] Used to Enable/Disable
 *   Secondary Metric in custom charts
 * @param {number} metadata.chartPrecision The number of decimal places to show
 * @param {Units[keyof Units]} unit Unit of the chart: Currency, Percentage or
 *   Number
 * @returns {Object}
 */
function useVariableChartQuery(
  variables,
  {
    chartType,
    colors = {},
    customVariableNames = {},
    mainMetric,
    nonMonthlyMetric = nonMonthlyValueTypes.SUM,
    isSecondaryMetricEnabled = true,
    chartPrecision = 0,
  },
  unit,
) {
  const scenarioIds = useSelectedScenarioIds();
  const scenarios = useSelectedScenarios();
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );

  const variableIds = useMemo(
    () => variables.map(({ name }) => name),
    [variables],
  );

  const query = useQuery(
    [CUSTOM_CHART, scenarioIds, variableIds, startDate, endDate, timePeriod],
    ({ signal }) =>
      getTimePeriodValuesForVariables({
        startDate,
        endDate,
        scenarioIds,
        signal,
        variables: variableIds,
        timePeriod,
      }),
    {
      staleTime: 30000,
      enabled: !!variableIds.length,
    },
  );

  return useMemo(() => {
    const {
      variables: queryVariableData,
      metrics,
      currentPeriodLabel,
      previousPeriodLabel,
      endingPeriodLabel,
    } = query.data?.data.data || {};

    const isMonthlyPeriod =
      timePeriod.toLowerCase() === timePeriods.MONTHLY.toLowerCase();

    let selectedAggregationType = aggregationTypes.NONE;
    let nonMonthlyValues = nonMonthlyValueTypes.MONTHLY;

    if (!isMonthlyPeriod) {
      switch (nonMonthlyMetric) {
        case nonMonthlyValueTypes.AVERAGE:
          selectedAggregationType = aggregationTypes.AVERAGE;
          nonMonthlyValues = nonMonthlyValueTypes.AVERAGE;
          break;
        case nonMonthlyValueTypes.SUM:
          selectedAggregationType = aggregationTypes.SUM;
          nonMonthlyValues = nonMonthlyValueTypes.SUM;
          break;
        case nonMonthlyValueTypes.LAST_MONTH:
          selectedAggregationType = aggregationTypes.LAST_MONTH;
          nonMonthlyValues = nonMonthlyValueTypes.LAST_MONTH;
          break;
        default:
          // eslint-disable-next-line no-console -- predates description requirement
          console.warn(`Unknown non-monthly metric: ${nonMonthlyMetric}`);
      }
    }

    const { primaryMetrics, secondaryMetrics } =
      metrics?.find(
        ({ aggregationType }) => aggregationType === selectedAggregationType,
      ) || {};

    if (!query.isSuccess) {
      return {
        ...query,
        data: {},
      };
    }

    /** @type {import('@/types/services/backend').FinmarkVariableMultipleScenarioWithTimePeriodDto[]} */
    const sortedData = [...queryVariableData];
    // Some chart types render series in reverse order, so we need to reverse
    // the data to match what the user sees in the chart builder
    const isReversedChart = ![chartTypes.FUNNEL, chartTypes.PIE].includes(
      chartType,
    );
    if (isReversedChart) sortedData.reverse();
    sortedData.sort(
      (a, b) =>
        scenarioIds.indexOf(a.scenarioId) - scenarioIds.indexOf(b.scenarioId),
    );

    const defaultColors = scenarioIds.flatMap((scenarioId) => {
      const scenarioSeries = sortedData.filter(
        (s) => s.scenarioId === scenarioId,
      );
      if (!scenarioSeries.length) return [];

      const scenario = scenarios.find((s) => s.scenarioId === scenarioId);
      const colorBand = getBandedGradient(
        scenario?.color,
        scenarioSeries.length,
      );
      if (!isReversedChart) colorBand.reverse();
      return colorBand;
    });

    const hasData = !!sortedData.length;

    const series = sortedData.map(
      ({ series: dataSeries, scenarioId, variableName }, idx) => {
        const scenario = scenarios.find((s) => s.scenarioId === scenarioId);
        const customColor = colors[variableName];
        const seriesColor = customColor ?? defaultColors[idx];
        return {
          ...mapMonthlyData(dataSeries[nonMonthlyValues], 'value'),
          color: seriesColor,
          name: customVariableNames[variableName] ?? variableName,
          scenario,
          scenarioId,
        };
      },
    );

    // Gets Maximum plotted value from all the series
    const maxPlottedValue = series.length
      ? series.reduce((max, current) => {
          return current.maxValueInSeries > max
            ? current.maxValueInSeries
            : max;
        }, series[0].maxValueInSeries)
      : 0;

    const precision = getChartPrecision(chartPrecision, unit, maxPlottedValue);
    const formatter = hasData && getMetricFormatter(unit);
    const currentMonth = getFormattedDateFromTimeStamp(
      getCurrentMonthTimestamp(),
    );

    let label;
    let mainMetricValue;
    switch (mainMetric) {
      case AVERAGE_MAIN_METRIC: {
        label = `${timePeriods[timePeriod]} Average`;
        mainMetricValue = hasData ? primaryMetrics.average : null;
        break;
      }
      case CURRENT_MONTH_MAIN_METRIC:
        label = isMonthlyPeriod
          ? formatDateWithShortYear(currentMonth)
          : currentPeriodLabel;
        mainMetricValue = hasData ? primaryMetrics.currentPeriod : null;
        break;
      case SUM_MAIN_METRIC:
        label = formatRangeWithShortYear(startDate, endDate);
        mainMetricValue = hasData ? primaryMetrics.sum : null;
        break;
      case PREVIOUS_PERIOD: {
        label = isMonthlyPeriod
          ? formatDateWithShortYear(
              getDateOffsetByMonths(getCurrentMonthTimestamp(), -1),
            )
          : previousPeriodLabel;
        mainMetricValue = hasData ? primaryMetrics.previousPeriod : null;
        break;
      }
      case DIFF_LAST_PERIOD:
        label = `vs. Prior ${timePeriodLabel[timePeriod]}`;
        mainMetricValue = hasData
          ? primaryMetrics.differenceFromLastPeriodAmount
          : null;
        break;
      case ENDING_PERIOD: {
        label = isMonthlyPeriod
          ? `${formatDateWithShortYear(endDate)}`
          : endingPeriodLabel;
        mainMetricValue = hasData ? primaryMetrics.endingPeriod : null;
        break;
      }
      default:
        // eslint-disable-next-line no-console -- predates description requirement
        console.warn(`Unknown main metric: ${mainMetric}`);
    }

    const {
      secondaryMetricIncreaseColor,
      secondaryMetricDecreaseColor,
      secondaryMetricNoChangeColor,
    } = colors;
    const data = {
      series,
      mainMetric: {
        label,
        value: getMetricValue(mainMetricValue, formatter, precision),
      },
      ...(isSecondaryMetricEnabled && {
        secondaryMetric: {
          label: `vs. Prior ${timePeriodLabel[timePeriod]}`,
          value: secondaryMetrics?.differenceFromLastPeriodPercentage,
          colors: {
            secondaryMetricIncreaseColor,
            secondaryMetricDecreaseColor,
            secondaryMetricNoChangeColor,
          },
        },
      }),
      maxValue: maxPlottedValue,
      precision,
    };
    return {
      ...query,
      data,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [
    startDate,
    endDate,
    scenarios,
    variables,
    unit,
    query?.dataUpdatedAt,
    query?.isFetching,
  ]);
}

export default useVariableChartQuery;
