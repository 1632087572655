// @ts-check
import { useCallback } from 'react';
import { CASH_IN } from '@/cacheKeys';
import { stackChartDataReducer } from '@/components/Charts/helpers';
import useCashFlowChartQuery from '@/hooks/useCashFlowChartQuery';
import { getCashIn } from '@/services/dashboard.service';

/**
 * API hook to populate the Total Cash in and Total Cash in Breakdown by
 * Percentage charts
 *
 * @type {(options: {
 *   filter: import('@/types/services/backend').CashInOutGrid['cashFlowType'];
 * }) => import('@/hooks/useScenarioQueryReducer').ScenarioQueryResult[]}
 */
function useCashInOutChartQuery({ filter }) {
  const reducer = useCallback(({ accounts }) => {
    return stackChartDataReducer({ payload: accounts });
  }, []);
  const compareReducer = useCallback(({ accounts }) => {
    return stackChartDataReducer({
      payload: accounts,
      valueKey: 'otherScenarioValue',
    });
  }, []);
  return useCashFlowChartQuery(CASH_IN, getCashIn, reducer, compareReducer, {
    queryParams: { filter },
  });
}

export default useCashInOutChartQuery;
