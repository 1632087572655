import { useEffect, useState } from 'react';
import NumberField from '@/components/common/NumberField';
import Select from '@/components/common/Select';
import { findCurrencySymbol } from '@/constants/currencies';
import { expenseChangeTypes } from '@/constants/expenses';
import asNumberOrNull from './helpers';

const AmountOfChange = ({
  onUpdate,
  defaultChangeAmount,
  changeType,
  isChangingOverTimeDisabled,
}) => {
  const [changeAmount, setChangeAmount] = useState(defaultChangeAmount);

  useEffect(() => {
    setChangeAmount(defaultChangeAmount);
  }, [defaultChangeAmount]);

  return (
    <div className="Form_Group Form_Group-halfWidth">
      <label className="Label" htmlFor="amount-of-change-figure">
        Amount of change
      </label>
      <div className="AmountOfChange_Container">
        <div className="AmountOfChange_Type">
          <Select
            id="amount-of-change-type"
            data-testid="amount-of-change-type"
            value={changeType}
            onChange={({ target }) => {
              const value = Number(target.value);
              onUpdate({
                type: 'SET_EXPENSE_CHANGE_TYPE',
                payload: value,
              });
            }}
            disabled={isChangingOverTimeDisabled}
          >
            <option value={expenseChangeTypes.MONETARY}>
              {findCurrencySymbol()}
            </option>
            <option value={expenseChangeTypes.PERCENTAGE}>%</option>
          </Select>
        </div>
        <div className="AmountOfChange_Figure">
          <NumberField
            id="amount-of-change-figure"
            data-testid="amount-of-change-figure"
            value={changeAmount}
            onChange={({ target }) => {
              const amount = asNumberOrNull(target);
              setChangeAmount(amount);
            }}
            onBlur={() => {
              onUpdate({
                type: 'SET_CHANGE_AMOUNT',
                payload: changeAmount,
              });
            }}
            validate={(value) => {
              const numberValue = Number(value);
              return (
                (!Number.isFinite(numberValue) || numberValue === 0) &&
                'Must provide a valid amount'
              );
            }}
            disabled={isChangingOverTimeDisabled}
            key={changeType}
          />
        </div>
      </div>
    </div>
  );
};

export default AmountOfChange;
