// @ts-check
import { useState, useRef, useCallback } from 'react';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import ComponentLoader from '@/components/common/ComponentLoader';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import useCallbackRef from '@/hooks/useCallbackRef';
import useChartTableData from '@/hooks/useChartTableData';
import useDashboardPath from '@/hooks/useDashboardPath';
import useTypedSelector from '@/hooks/useTypedSelector';
import { valueFormatter } from '@/pages/Actuals/helpers';
import NetCashFlowChart from '@/pages/Dashboard/charts/NetCashFlowChart';
import {
  NET_CASH_FLOW_METRICS,
  CHART_TITLE,
} from '@/pages/Dashboard/constants/netCashFlow';

/**
 * @typedef {{
 *   field: string;
 *   headerName: string;
 * }} CashFlowTableEntry
 */

/** @typedef {import('ag-grid-community').ColDef<CashFlowTableEntry>[]} CashFlowColumnDefs */

/** @type {CashFlowColumnDefs} */
const colDefs = [
  {
    field: 'name',
    headerName: CHART_TITLE,
  },
];

const SPREADSHEET_ID = 'netCashFlow-chart-table';

function NetCashFlow() {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [chartData, setChartData] = useState([]);
  const dateChartRef = useRef();
  const dashboardPath = useDashboardPath();
  const [gridApi, gridRef] = useCallbackRef();
  const { startDate, endDate } = useTypedSelector(({ shared }) => shared);
  const tableData = useChartTableData(chartData, NET_CASH_FLOW_METRICS);
  const onQueryStateChange = useCallback((data) => {
    const [
      {
        isLoading,
        data: { totalNetCash },
      },
    ] = data;
    setLoading(isLoading);
    setTotal(totalNetCash);
    setChartData(data);
  }, []);

  return (
    <>
      <section className="Panel Panel-toEdge">
        <ChartHeader back={dashboardPath} title={CHART_TITLE}>
          <div className="ChartHeader_KPIValue">
            {loading ? '' : formatMonetary(total)}
          </div>
          <div className="ChartHeader_KPI">
            <br />
            {formatRangeWithShortYear(startDate, endDate)}
          </div>
        </ChartHeader>
        <ComponentLoader loadingComponent="netCashFlow" paddingTop="0%" />
        <ChartToolbar>
          <ChartControls
            chartRef={dateChartRef}
            title={CHART_TITLE}
            metricValue={formatMonetary(total)}
            label={formatRangeWithShortYear(startDate, endDate)}
            data-testid="netCashFlow-chartControls"
          />
        </ChartToolbar>
        <div className="ExpandedView_ChartWrapper">
          <NetCashFlowChart
            exportBtn={dateChartRef}
            onQueryStateChange={onQueryStateChange}
          />
        </div>
      </section>
      <section className="Panel Panel-toEdge">
        <SpreadsheetToolbar editable={false} gridApi={gridApi}>
          Options:
          <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
        </SpreadsheetToolbar>
        <MonthlySpreadsheet
          ref={gridRef}
          columnDefs={colDefs}
          data={tableData}
          data-testid={SPREADSHEET_ID}
          editable={false}
          enableComparison
          getRowId={({ data }) => data.name}
          valueFormatter={valueFormatter}
        />
      </section>
    </>
  );
}
export default NetCashFlow;
