import { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { subscribeToActualsUpdateAction } from '@/actions/actuals';
import {
  setFinancingModalErrorAction,
  setProductRecordToBeEdit,
  setRevenueSourceRecordToBeEdit,
} from '@/actions/revenue';
import { getCollectionTermsAction } from '@/actions/settings';
import PageHeader from '@/components/Layouts/PageHeader';
import DataMappingTab from '@/components/Revenue/DataMapping/DataMappingTab';
import ProductsTable from '@/components/Revenue/List/ProductsTable';
import RevenueStreamsTable from '@/components/Revenue/List/RevenueStreamsTable';
import TotalRevenuesTable from '@/components/Revenue/List/TotalRevenuesTable';
import AddRevenueStreamModal from '@/components/Revenue/RevenueStream/AddRevenueStreamModal';
import TableExportButton from '@/components/TableExportButton';
import ButtonAdd from '@/components/common/ButtonAdd';
import DropdownOption from '@/components/common/DropdownOption';
import Permissions from '@/components/common/Permissions';
import Tabs from '@/components/common/Tabs';
import { actualsFamily } from '@/constants/actuals';
import { registeredFeatureFlags } from '@/constants/features';
import { REVENUE_INTEGRATIONS } from '@/constants/integrations';
import { REVENUE_MAPPING_PATH } from '@/constants/pages';
import { actions, subjects } from '@/constants/permissions';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useIsAdminUser from '@/hooks/useIsAdminUser';
import useSelectedScenarios from '@/hooks/useSelectedScenarios';
import ActualsGrid from '@/pages/Actuals/ActualsGrid';
import TotalRevenue from '@/pages/Dashboard/TotalRevenue';
import FinancingForm from '@/pages/Revenue/FinancingForm';
import FinancingList from '@/pages/Revenue/FinancingList';
import ProductModal from '@/pages/Revenue/ProductModal';
import RevenueByProductChart from '@/pages/Revenue/RevenueByProductChart';
import { getProductByID } from '@/services/revenueService';
import './Revenue.scss';

const { WRITE } = actions;
const { NON_DASHBOARD } = subjects;
const REVENUE_ACTUALS = '/actuals';

const Revenues = ({
  error,
  setFinancingModalError,
  financingModalError,
  productToEdit,
  setProductRecordToBeEditAction,
  scenarioId,
  startDate,
  endDate,
  companyId,
  setRevenueSourceRecordToBeEditAction,
  getCollectionTerms,
}) => {
  const [showStream, setShowStream] = useState(false);
  const [revenueStreamMode, setRevenueStreamMode] = useState();
  const [revenueStreamRecordIdForEdit, setRevenueStreamRecordIdForEdit] =
    useState(null);
  const [showFinancingModal, setShowFinancingModal] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);
  const [addProductFromRevenue, setAddProductFromRevenue] = useState(false);
  const [financingToEdit, setFinancingToEdit] = useState('');
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const [selectedScenario] = useSelectedScenarios();

  const isAdmin = useIsAdminUser();
  const isDataMappingEnabled = useFeatureFlags(
    registeredFeatureFlags.GSHEETS_REVENUE_INTEGRATION,
  );

  const isRevenueIntegrationConnected = useMemo(
    () =>
      !!selectedScenario?.enabledIntegrations.find((enabledIntegration) =>
        REVENUE_INTEGRATIONS.includes(enabledIntegration),
      ),
    [selectedScenario],
  );

  const handleCloseAddProductModal = () => {
    setShowAddProductModal(false);
    setInEditMode(false);

    if (productToEdit) {
      setProductRecordToBeEditAction(undefined);
    }
  };

  useEffect(() => {
    getCollectionTerms(companyId, scenarioId);
  }, [getCollectionTerms, scenarioId, startDate, endDate, companyId]);

  const handleRevenueStreamOperation = (mode) => {
    setRevenueStreamMode(mode);
    setShowStream(true);
  };
  const handleCloseRevenueStreamModal = () => {
    setShowStream(false);
    setRevenueStreamMode('add');
    setRevenueSourceRecordToBeEditAction(null);
  };

  const handleOnClickEditRevenueStream = (recordId) => {
    setRevenueStreamRecordIdForEdit(recordId);
    handleRevenueStreamOperation('edit');
  };

  const editProduct = async (productId) => {
    getProductByID(scenarioId, productId);
  };

  useEffect(() => {
    if (productToEdit) {
      setShowAddProductModal(true);
      setInEditMode(true);
    }
  }, [productToEdit]);

  const toggleShowAddProductModal = () => {
    setShowAddProductModal(!showAddProductModal);
  };

  const handleEditFinancing = async (id) => {
    setFinancingToEdit(id);
    setShowFinancingModal(true);
  };

  const gridApi = useRef(null);

  useEffect(() => {
    if (!isDataMappingEnabled || !isRevenueIntegrationConnected) {
      history.replace(path);
    }
  }, [isDataMappingEnabled, isRevenueIntegrationConnected, path, history]);

  return (
    <>
      <PageHeader
        page="/revenue"
        addBtnSlot={
          <Permissions
            action={WRITE}
            subject={NON_DASHBOARD}
            scenarioPermissionRequired
          >
            <ButtonAdd id="add-revenue-button" direction="right">
              <DropdownOption
                id="add-revenue-product-option"
                className="pendo-AddProduct"
                onClick={() => setShowAddProductModal(true)}
              >
                Product
              </DropdownOption>
              <DropdownOption
                id="add-revenue-stream-option"
                className="pendo-AddRevenue"
                onClick={() => handleRevenueStreamOperation('add')}
              >
                Revenue Stream (Driver)
              </DropdownOption>
              <DropdownOption
                id="revenue-add-financing-option"
                className="pendo-AddFinancing"
                onClick={() => setShowFinancingModal(true)}
              >
                Financing
              </DropdownOption>
            </ButtonAdd>
          </Permissions>
        }
      />
      <div className="PageLayout">
        {error && (
          <p className="alert alert-danger">
            <b>Error </b> - {error}
          </p>
        )}
        <TotalRevenue showGrid={false} />
        <RevenueByProductChart />

        <section className="Panel Panel-toEdge">
          <Tabs
            controls={
              pathname === `${path}${REVENUE_ACTUALS}` && (
                <TableExportButton
                  onClick={() => gridApi.current.api.exportDataAsExcel()}
                />
              )
            }
          >
            <Tabs.Panel path={path} label="Revenue Summary">
              <>
                <ProductsTable onEdit={editProduct} />
                <RevenueStreamsTable onEdit={handleOnClickEditRevenueStream} />
                <TotalRevenuesTable />
              </>
            </Tabs.Panel>
            <Tabs.Panel
              path={`${path}${REVENUE_ACTUALS}`}
              label="Revenue Details"
            >
              <ActualsGrid
                actualFamily={actualsFamily.REVENUE}
                apiRef={gridApi}
              />
            </Tabs.Panel>
            {isAdmin && isRevenueIntegrationConnected && (
              <Tabs.Panel path={REVENUE_MAPPING_PATH} label="Revenue Mapping">
                <DataMappingTab />
              </Tabs.Panel>
            )}
          </Tabs>
        </section>
        {showStream && (
          <AddRevenueStreamModal
            setShowAddProductModal={setShowAddProductModal}
            show={showStream}
            mode={revenueStreamMode}
            recordIdForEdit={revenueStreamRecordIdForEdit}
            handleClose={handleCloseRevenueStreamModal}
            setAddProductFromRevenue={setAddProductFromRevenue}
            handleRevenueStreamOperation={handleRevenueStreamOperation}
            addProductFromRevenue={addProductFromRevenue}
            showAddProductModal={showAddProductModal}
          />
        )}
        <ProductModal
          showModal={showAddProductModal}
          closeModal={handleCloseAddProductModal}
          productToEdit={inEditMode && productToEdit}
          toggleMainModal={toggleShowAddProductModal}
        />

        <section className="Panel Panel-toEdge">
          <FinancingList onEdit={handleEditFinancing} />
        </section>
        {showFinancingModal && (
          <FinancingForm
            error={financingModalError}
            financingToEdit={financingToEdit}
            onClose={() => {
              setShowFinancingModal(false);
              setFinancingModalError('');
              setFinancingToEdit('');
            }}
            onError={setFinancingModalError}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ revenues, scenario, shared, companies }) => ({
  error: revenues.error,
  financingModalError: revenues.financingModalError,
  productToEdit: revenues.productToEdit,
  scenarioId: scenario.scenarioId,
  startDate: shared.startDate,
  endDate: shared.endDate,
  companyId: companies.selectedCompanyId,
});

export default connect(mapStateToProps, {
  setFinancingModalError: setFinancingModalErrorAction,
  setProductRecordToBeEditAction: setProductRecordToBeEdit,
  setRevenueSourceRecordToBeEditAction: setRevenueSourceRecordToBeEdit,
  getCollectionTerms: getCollectionTermsAction,
  subscribeToActualsUpdate: subscribeToActualsUpdateAction,
})(Revenues);
