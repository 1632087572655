/* eslint-disable no-underscore-dangle -- predates description requirement */

/* eslint-disable no-multi-assign -- predates description requirement */
import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { connect, useDispatch } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from '@reduxjs/toolkit';
import OnboardingNoAccess from '@/components/BillOnboarding/OnboardingNoAccess';
import VerifyScenario from '@/components/Landing/VerifyScenario';
import Insights from '@/components/Remote/Insights/Insights';
import { colorPalletes } from '@/constants/colors';
import { exportableReportTypes } from '@/constants/reports';
import CashInOutContainer from '@/containers/CashInOut/CashInOutContainer';
import ExportableReport from '@/containers/ExportableReport';
import Registration from '@/containers/Registration';
import Reports from '@/containers/Reports';
import { getQueryParamsFromPath, isNumber } from '@/helpers';
import useOneColor from '@/hooks/useOneColor';
import CreateScenarioUser from '@/pages/Landing/CreateScenarioUser';
import ForgotPasswordForm from '@/pages/Landing/ForgotPasswordForm';
import ResetPasswordForm from '@/pages/Landing/ResetPasswordForm';
import SignInForm from '@/pages/Landing/SignInForm';
import Unauthorized from '@/pages/Landing/Unauthorized';
import Logout from '@/pages/Logout';
import IntegrationRedirect from '@/pages/Registration/IntegrationRedirect';
import RequireAuth from './RequireAuth';
import { PERSIST_STATEFUL_ROUTE } from './actionTypes/auth';
import BillOnboardingLayout from './components/Layouts/BillOnboardingLayout';
import MainLayout from './components/Layouts/MainLayout';
import {
  DASHBOARD_PATH,
  EXPENSES_PATH,
  SWITCH_COMPANIES_PATH,
  UNAUTHORIZED_PATH,
  LOGOUT_PATH,
  LOGIN_PATH,
  METRICS_PATH,
  VARIABLES_PATH,
  MANAGEMENT_CONSOLE_PATH,
  CASH_IN_OUT,
  NO_ACCESS_PATH,
  INSIGHTS_PATH,
  SETUP_PATH,
} from './constants/pages';
import { actions, subjects } from './constants/permissions';
import Actuals from './containers/Actuals';
import SetupPlanning from './containers/BillOnboarding';
import Dashboard from './containers/Dashboard';
import Employees from './containers/Employees';
import Expenses from './containers/Expenses';
import ManagementConsole from './containers/ManagementConsole';
import Metrics from './containers/Metrics';
import Revenue from './containers/Revenue';
import Settings from './containers/Settings';
import Variables from './containers/Variables';
import XeroRedirect from './containers/XeroRedirect';
import NotificationProvider from './contexts/NotificationProvider';
import isBillDomain from './helpers/isBillDomain';
import useIsFPALite from './hooks/useIsFPALite';
import useManagementConsole from './hooks/useManagementConsole';
import usePermissions from './hooks/usePermissions';
import CompanySelectionPage from './pages/Landing/CompanySelectionPage';

const { READ, READ_WRITE } = actions;
const { DASHBOARD, NON_DASHBOARD, EMPLOYEE_SETTINGS } = subjects;

const AppRouter = ({
  userEmail,
  location,
  selectedCompanyId,
  scenarioId,
  companies,
}) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();

  const isLiteProduct = useIsFPALite();

  useEffect(() => {
    const { companyId: companyIdParam, scenarioId: scenarioIdParam } =
      getQueryParamsFromPath(location.search);
    const targetCompanyId =
      isNumber(companyIdParam ?? '') && Number(companyIdParam);
    const targetScenarioId =
      isNumber(scenarioIdParam ?? '') && Number(scenarioIdParam);
    if (
      targetCompanyId &&
      targetScenarioId &&
      (targetCompanyId !== selectedCompanyId || targetScenarioId !== scenarioId)
    ) {
      dispatch({
        type: PERSIST_STATEFUL_ROUTE,
        payload: {
          route: location.pathname,
          companyId: targetCompanyId,
          scenarioId: targetScenarioId,
        },
      });
    }
  }, [location, dispatch, selectedCompanyId, scenarioId]);

  const nonDashboardPermissions = usePermissions(READ, NON_DASHBOARD, true);
  const dashboardPermissions = usePermissions(READ, DASHBOARD, true);
  const employeePermissions = usePermissions(READ_WRITE, EMPLOYEE_SETTINGS);

  const isConsoleEnabled = useManagementConsole();

  useEffect(() => {
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['identify', { email: userEmail }]);
  }, [userEmail]);

  // Fire a HubSpot page view on each page change
  useEffect(() => {
    const _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', location.pathname]);
    _hsq.push(['trackPageView']);
  }, [location]);

  const isOneColorEnabled = useOneColor();

  useEffect(() => {
    const className = isOneColorEnabled
      ? colorPalletes.ONECOLOR
      : colorPalletes.FINMARK;
    document.documentElement.className = className;
  }, [isOneColorEnabled]);

  const isCashFlowEnable = companies.length > 0 ? isLiteProduct : true;

  return (
    <Switch>
      <Route
        path={INSIGHTS_PATH}
        render={() => (
          <MainLayout>
            <Insights />
          </MainLayout>
        )}
      />
      <Route
        path={DASHBOARD_PATH}
        render={() => (
          <RequireAuth condition={dashboardPermissions}>
            <MainLayout>
              <Dashboard />
            </MainLayout>
          </RequireAuth>
        )}
      />
      <Route
        path="/employees"
        render={() => (
          <RequireAuth condition={!isLiteProduct && employeePermissions}>
            <MainLayout>
              <Employees />
            </MainLayout>
          </RequireAuth>
        )}
      />

      <Route
        path={CASH_IN_OUT}
        render={() => {
          return (
            <RequireAuth
              condition={isCashFlowEnable && nonDashboardPermissions}
            >
              <MainLayout>
                <CashInOutContainer />
              </MainLayout>
            </RequireAuth>
          );
        }}
      />
      <Route
        path={EXPENSES_PATH}
        render={() => (
          <RequireAuth condition={!isLiteProduct && nonDashboardPermissions}>
            <MainLayout>
              <Expenses />
            </MainLayout>
          </RequireAuth>
        )}
      />
      <Route
        exact
        path="/settings"
        render={() => (
          <RequireAuth>
            <MainLayout>
              <Settings />
            </MainLayout>
          </RequireAuth>
        )}
      />
      <Route
        path="/revenue"
        render={() => (
          <RequireAuth condition={!isLiteProduct && nonDashboardPermissions}>
            <MainLayout>
              <Revenue />
            </MainLayout>
          </RequireAuth>
        )}
      />
      <Route
        path="/actuals"
        render={() => (
          <RequireAuth condition={!isLiteProduct && nonDashboardPermissions}>
            <MainLayout>
              <Actuals />
            </MainLayout>
          </RequireAuth>
        )}
      />
      <Route
        path="/reports"
        render={() => (
          <RequireAuth condition={!isLiteProduct && nonDashboardPermissions}>
            <MainLayout>
              <Reports />
            </MainLayout>
          </RequireAuth>
        )}
      />
      <Route
        path="/financial-summary"
        render={() => (
          <RequireAuth condition={!isLiteProduct && nonDashboardPermissions}>
            <ExportableReport type={exportableReportTypes.FINANCIAL_SUMMARY} />
          </RequireAuth>
        )}
      />
      <Route
        path="/cash-report"
        render={() => (
          <RequireAuth condition={isLiteProduct && nonDashboardPermissions}>
            <ExportableReport type={exportableReportTypes.CASH_REPORT} />
          </RequireAuth>
        )}
      />
      <Route
        path={VARIABLES_PATH}
        render={() => (
          <RequireAuth condition={nonDashboardPermissions}>
            <MainLayout>
              <Variables />
            </MainLayout>
          </RequireAuth>
        )}
      />
      <Route
        path={METRICS_PATH}
        render={() => (
          <RequireAuth condition={nonDashboardPermissions}>
            <MainLayout>
              <Metrics />
            </MainLayout>
          </RequireAuth>
        )}
      />
      <Route
        exact
        path={MANAGEMENT_CONSOLE_PATH}
        render={() => (
          <RequireAuth condition={isConsoleEnabled && !isBillDomain()}>
            <NotificationProvider>
              <ManagementConsole />
            </NotificationProvider>
          </RequireAuth>
        )}
      />
      <Route
        exact
        path={SWITCH_COMPANIES_PATH}
        render={() => (
          <RequireAuth condition={!isConsoleEnabled && !isBillDomain()}>
            <NotificationProvider>
              <CompanySelectionPage />
            </NotificationProvider>
          </RequireAuth>
        )}
      />
      <Route
        exact
        path="/integrationRedirect"
        render={() => (
          <RequireAuth>
            <IntegrationRedirect />
          </RequireAuth>
        )}
      />
      <Route exact path={UNAUTHORIZED_PATH} component={Unauthorized} />
      <Route exact path={LOGOUT_PATH} component={Logout} />
      {!isBillDomain()
        ? [
            <Route key="join" path="/join" component={Registration} />,
            <Route
              key="sign-up"
              exact
              path="/sign-up/:userId/:companyId/verification/share/scenario/:verificationToken"
              component={VerifyScenario}
            />,
            <Route
              key="create-account"
              exact
              path="/create-account"
              component={CreateScenarioUser}
            />,
            <Route
              key="forgot-password"
              exact
              path="/forgot-password"
              component={ForgotPasswordForm}
            />,
            <Route
              key="reset-password"
              exact
              path="/reset-password/verification/:resetPasswordToken"
              component={ResetPasswordForm}
            />,
            <Route
              key="xero"
              exact
              path="/xero-redirect"
              component={XeroRedirect}
            />,
            <Route key="login" path={LOGIN_PATH} component={SignInForm} />,
          ]
        : [
            <Route key="setup" exact path={SETUP_PATH}>
              <BillOnboardingLayout>
                <SetupPlanning />
              </BillOnboardingLayout>
            </Route>,
            <Route key="insights" path={SETUP_PATH + INSIGHTS_PATH}>
              <BillOnboardingLayout>
                <Insights />
              </BillOnboardingLayout>
            </Route>,
            <Route
              key="no-access"
              path={NO_ACCESS_PATH}
              render={() => (
                <BillOnboardingLayout>
                  <OnboardingNoAccess />
                </BillOnboardingLayout>
              )}
            />,
            <Redirect key="login" from={LOGIN_PATH} to={DASHBOARD_PATH} />,
          ]}
    </Switch>
  );
};

const mapStateToProps = ({ auth, companies, scenario }) => ({
  userEmail: auth.userInfo.email,
  scenarioId: scenario.scenarioId,
  selectedCompanyId: companies.selectedCompanyId,
  companies: companies.companies,
});

export default compose(withRouter, connect(mapStateToProps))(AppRouter);
