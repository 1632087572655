import {
  HIDE_NOTIFICATION,
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  CALCULATION_STATUS_UPDATED,
  CALCULATION_INTERVAL,
  CALCULATION_ERROR,
} from '@/actionTypes/notifications';
import { subscribeToTopic } from '@/actions/shared';
import { getCalculationProgress } from '@/services/notificationCenterService';

export const hideNotificationAction = (notification) => ({
  type: HIDE_NOTIFICATION,
  payload: notification,
});

export const createNotificationAction = (notification) => ({
  type: CREATE_NOTIFICATION,
  payload: notification,
});

export const deleteNotificationAction = (notification) => ({
  type: DELETE_NOTIFICATION,
  payload: notification,
});

/**
 * Action to subscribe to notifications updates
 *
 * @typedef {import('@/types/notificationCenter').ImportantNotification} ImportantNotification
 *
 *
 * @typedef {import('@/types/notificationCenter').NormalNotification} NormalNotification
 *
 *
 * @typedef {{
 *   deleted?: (NormalNotification | ImportantNotification)[];
 *   upserted?: (NormalNotification | ImportantNotification)[];
 * }} WebSocketsPayload
 * @type {(
 *   scenarioId: number,
 *   onSubscriptionComplete: () => void,
 *   callback: (payload: WebSocketsPayload) => void,
 * ) => ReturnType<typeof subscribeToTopic>}
 */
export const subscribeToNotificationsUpdatesAction = (
  scenarioId,
  onSubscriptionComplete,
  callback,
) => {
  const topic = `app-notifications/${scenarioId}`;

  return (dispatch) => {
    const subscription = subscribeToTopic(topic, (payload) => {
      callback(payload);
    });
    return dispatch(subscription).then((_) => {
      onSubscriptionComplete(); // a one time function call immediately after subscribing
    });
  };
};

/**
 * @type {(
 *   scenarioId: number,
 * ) => import('@/types/extend-redux-toolkit').AppThunk}
 */
export const subscribeToCalculationProgressAction = (scenarioId) => {
  return (dispatch) => {
    return dispatch(
      subscribeToTopic(
        `progress/${scenarioId}`,
        (
          /** @type {import('@/types/services/backend').CalculationProgressDto} */ data,
        ) => {
          dispatch({
            type: CALCULATION_STATUS_UPDATED,
            payload: data,
          });
        },
      ),
    );
  };
};

/**
 * @type {() => import('@/types/extend-redux-toolkit').AppAction<
 *   typeof CALCULATION_INTERVAL
 * >}
 */
export const setCalculationTimeElapsedAction = () => {
  return {
    type: CALCULATION_INTERVAL,
  };
};

/**
 * @type {(
 *   scenarioId: number,
 * ) => import('@/types/extend-redux-toolkit').AppThunk}
 */
export const getCalculationProgressAction = (scenarioId) => {
  return async (dispatch) => {
    try {
      const {
        data: { data },
      } = await getCalculationProgress(scenarioId);
      dispatch({ type: CALCULATION_STATUS_UPDATED, payload: data });
    } catch (e) {
      dispatch({
        type: CALCULATION_ERROR,
      });
    }
  };
};
