import {
  SET_ERROR,
  SET_CASH_FLOW_ACTUALS,
  SET_EXTERNAL_PROFIT_AND_LOSS,
  SET_EXTERNAL_CASH_FLOW,
  SET_CASH_FLOW_VALUES,
} from '@/actionTypes/actuals';
import { cashActualsMetrics } from '@/constants/actuals';
import sortCashRows from '@/reducers/helpers/sortCashRows';
import transformDataForGrid from './helpers/transformDataForGrid';

const INITIAL_STATE = {
  cashFlowActuals: [],
  cashFlow: [],
  externalCashFlow: [],
  externalProfitAndLoss: [],
  error: '',
};

const actuals = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EXTERNAL_PROFIT_AND_LOSS:
      return {
        ...state,
        externalProfitAndLoss: transformDataForGrid(action.payload.children),
        error: '',
      };
    case SET_CASH_FLOW_ACTUALS: {
      return {
        ...state,
        cashFlowActuals: sortCashRows(action.payload.children),
        error: '',
      };
    }
    case SET_CASH_FLOW_VALUES: {
      const cashFlowActuals = [...state.cashFlowActuals];
      const {
        displayFormula,
        faulted,
        month,
        scenarioId,
        type,
        value,
        valueType,
        variableValueFaultType,
      } = action.payload;
      const metricIdx = cashFlowActuals.findIndex(
        ({ id }) => id === cashActualsMetrics[type],
      );
      if (metricIdx < 0) return state;

      const months = [...cashFlowActuals[metricIdx].months];
      const monthIdx = months.findIndex(({ date }) => date === month);
      const scenarioVals = [...months[monthIdx].value];
      const valueIdx = scenarioVals.findIndex(
        (scenario) => scenario.scenarioId === scenarioId,
      );
      scenarioVals[valueIdx] = {
        ...scenarioVals[valueIdx],
        displayFormula,
        faulted,
        value,
        type: valueType,
        variableValueFaultType,
      };
      months[monthIdx] = {
        ...months[monthIdx],
        value: scenarioVals,
      };
      cashFlowActuals[metricIdx] = {
        ...cashFlowActuals[metricIdx],
        months,
      };
      return { ...state, cashFlowActuals };
    }
    case SET_EXTERNAL_CASH_FLOW: {
      return {
        ...state,
        externalCashFlow: transformDataForGrid(action.payload.children),
        error: '',
      };
    }
    case SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default actuals;
