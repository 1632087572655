import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

/**
 * A React portal for rendering a modal
 *
 * @example
 *   <ModalPortal>{children}</ModalPortal>;
 */
function ModalPortal({ children }) {
  const container = useMemo(() => document.createElement('div'), []);
  useEffect(() => {
    const modalRoot = document.getElementById('modal-root');
    modalRoot.appendChild(container);

    return () => modalRoot.removeChild(container);
  }, [container]);

  return createPortal(children, container);
}

export default ModalPortal;
