import { useState, useEffect, Fragment } from 'react';
import ProductTypeFilter from '@/components/ProductTypeFilter/ProductTypeFilter';
import Button from '@/components/common/Button';
import ContextMenu from '@/components/common/ContextMenu';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import UserInfo from '@/components/common/UserInfo';
import { classNames, showUserRoleText } from '@/helpers';
import useIsFullFpnaProduct from '@/hooks/useIsFullFpnaProduct';
import './MembersDetailModal.scss';

const Header = ({ setShowInvite }) => {
  return (
    <div className="MembersDetailModal_Header">
      <h2 className="ModalBase_Heading">Edit Users</h2>
      <ProductTypeFilter>
        <Button
          className="Button-primaryLink"
          onClick={setShowInvite}
          data-testid="members-invite-button"
        >
          + Invite users
        </Button>
      </ProductTypeFilter>
    </div>
  );
};

const InviteStatus = ({
  onResend,
  onDisable,
  onCancelInvite,
  isExpiredInvitation,
}) => {
  return (
    <div className="InviteStatus_Container">
      {isExpiredInvitation ? (
        <>
          <span className="InviteStatus_ExpiredText">Invite Expired: </span>
          <button
            className="InviteStatus_Button"
            onClick={onResend}
            data-testid="resend-invite-button"
          >
            Send Again
          </button>
          <button
            className={classNames(
              'InviteStatus_Button',
              'InviteStatus_Button-cancelExpiredInvite',
            )}
            onClick={onCancelInvite}
            data-testid="cancel-invite-button"
          >
            Cancel Invite
          </button>
        </>
      ) : (
        <>
          <span className="InviteStatus_PendingText">Invite Pending: </span>
          <button
            className="InviteStatus_Button InviteStatus_Button-disableInvite"
            onClick={onDisable}
            data-testid="disable-invite-button"
          >
            Disable Invite
          </button>
        </>
      )}
    </div>
  );
};

const MembersDetailModal = ({
  closeModal,
  setShowInvite,
  members,
  currentUser,
  resendInvite,
  removeMember,
  removeMemberState,
  disableMemberInvite,
  companyName,
  getMemberInfo,
  cancelExpiredInvite,
}) => {
  const { email, hasEmployeeAccess, permission, id } = currentUser;
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const isFullFpnaProduct = useIsFullFpnaProduct();

  useEffect(() => {
    if (removeMemberState.success && !removeMemberState.loading) {
      setShowRemoveUserModal(false);
    }
  }, [removeMemberState]);

  return (
    <ModalBase
      id="edit-members"
      onCancel={() => closeModal()}
      header={<Header setShowInvite={setShowInvite} />}
      className="MembersDetailModal"
      footer={
        <>
          <Button
            className="Button-cancelLink"
            onClick={() => closeModal()}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          <Button onClick={() => closeModal()} data-testid="finish-button">
            Finish Edit
          </Button>
        </>
      }
    >
      <div
        className={classNames(
          'MembersDetailGrid',
          !isFullFpnaProduct && 'MembersDetailGrid-fpaLiteGrid',
        )}
      >
        <>
          <div className="MembersDetailGrid_Header">Name</div>
          <div className="MembersDetailGrid_Header">Email</div>
          <ProductTypeFilter>
            <div
              className={classNames(
                'MembersDetailGrid_Header',
                'MembersDetailGrid_Header-employeeAccess',
              )}
            >
              View/Edit Employee Data
            </div>
          </ProductTypeFilter>

          <div className="MembersDetailGrid_Header">Access Level</div>
          <div className="MembersDetailGrid_Header" />
        </>
        <>
          <div className="MembersDetailGrid_Item">
            <UserInfo
              member={currentUser}
              showPermission={false}
              isLoggedInUser
            />
          </div>
          <div className="MembersDetailGrid_Item" aria-disabled>
            <TooltipTextOverflow
              label={email}
              data-testid={`${email}-email-field`}
            />
          </div>

          <ProductTypeFilter>
            <div
              className={classNames(
                'MembersDetailGrid_Item',
                'MembersDetailGrid_Item-employeeAccess',
              )}
              aria-disabled
            >
              {hasEmployeeAccess ? 'Yes' : 'No'}
            </div>
          </ProductTypeFilter>
          <div className="MembersDetailGrid_Item" aria-disabled>
            {showUserRoleText(permission)}
          </div>

          <div className="MembersDetailGrid_Item" aria-disabled>
            <ContextMenu data-testid={`scenario-action-${id}`} disabled />
          </div>
        </>
        <>
          {members.map((member) => {
            const {
              id: memberId,
              email: memberEmail,
              hasEmployeeAccess: memberHasEmployeeAccess,
              permission: memberPermission,
              isExpiredInvitation,
              isPendingInvitation,
            } = member;
            return (
              <Fragment key={memberId}>
                <div className="MembersDetailGrid_Item">
                  <UserInfo member={member} showPermission={false} />
                </div>
                <div className="MembersDetailGrid_Item">
                  <TooltipTextOverflow
                    label={memberEmail}
                    data-testid={`${memberEmail}-email-field`}
                  />
                  {(isExpiredInvitation || isPendingInvitation) && (
                    <InviteStatus
                      isExpiredInvitation={isExpiredInvitation}
                      onResend={() => resendInvite(member)}
                      onDisable={() => disableMemberInvite(memberEmail)}
                      onCancelInvite={() => cancelExpiredInvite(memberEmail)}
                    />
                  )}
                </div>
                <ProductTypeFilter>
                  <div
                    className={classNames(
                      'MembersDetailGrid_Item',
                      'MembersDetailGrid_Item-employeeAccess',
                    )}
                  >
                    {memberHasEmployeeAccess ? 'Yes' : 'No'}
                  </div>
                </ProductTypeFilter>
                <div className="MembersDetailGrid_Item">
                  {showUserRoleText(memberPermission)}
                </div>
                {!(isExpiredInvitation || isPendingInvitation) ? (
                  <div className="MembersDetailGrid_Item">
                    <ContextMenu data-testid={`scenario-action-${memberId}`}>
                      <ContextMenu.Option
                        data-testid={`edit-scenario-${memberId}`}
                        onClick={() => getMemberInfo && getMemberInfo(memberId)}
                      >
                        View User
                      </ContextMenu.Option>
                      <ProductTypeFilter>
                        <ContextMenu.Option
                          data-testid={`delete-scenario-${memberId}`}
                          danger
                          onClick={() => {
                            setSelectedUser(member);
                            setShowRemoveUserModal(true);
                          }}
                        >
                          Remove User
                        </ContextMenu.Option>
                      </ProductTypeFilter>
                    </ContextMenu>
                  </div>
                ) : (
                  <div className="MembersDetailGrid_Item" />
                )}
              </Fragment>
            );
          })}
        </>
      </div>

      {showRemoveUserModal && (
        <ModalConfirmation
          id="modal-remove-user"
          title="Remove User"
          actionBtnTxt="Remove User"
          onCancel={() => setShowRemoveUserModal(false)}
          onAction={() => removeMember(selectedUser.id)}
          className="RemoveUserModal"
        >
          <p className="RemoveUser_CompanyName">
            {selectedUser.name} - {companyName}
          </p>
          <p>
            This action will remove the user’s access to the above company. Are
            you sure you want to proceed?
          </p>
        </ModalConfirmation>
      )}
    </ModalBase>
  );
};

export default MembersDetailModal;
