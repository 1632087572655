/**
 * Generate a CSV file for download
 *
 * @param {string} title - the title of the CSV used to build up file name
 * @param {Blob} csvBlob - a blob of CSV data
 * @param {string} dateFormatted - a formatted date used to build up the file
 *   name
 */
const generateCsv = (title, csvBlob, dateFormatted) => {
  const encodedCsv = URL.createObjectURL(csvBlob);
  const link = document.createElement('a');
  link.setAttribute('href', encodedCsv);
  link.setAttribute('download', `${title}-${dateFormatted}.csv`);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default generateCsv;
