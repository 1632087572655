export const ADD_CUSTOM_VARIABLE = 'variables/ADD_CUSTOM_VARIABLE';
export const ADD_CUSTOM_VARIABLE_SECTION =
  'variables/ADD_CUSTOM_VARIABLE_SECTION';
export const CANCEL_ADD_CUSTOM_VARIABLE =
  'variables/CANCEL_ADD_CUSTOM_VARIABLE';
export const CANCEL_ADD_CUSTOM_VARIABLE_SECTION =
  'variables/CANCEL_ADD_CUSTOM_VARIABLE_SECTION';
export const DELETE_CUSTOM_VARIABLE = 'variables/DELETE_CUSTOM_VARIABLE';
export const DELETE_CUSTOM_VARIABLE_SECTION =
  'variables/DELETE_CUSTOM_VARIABLE_SECTION';
export const REORDER_CUSTOM_VARIABLE = 'variables/REORDER_CUSTOM_VARIABLE';
export const REORDER_CUSTOM_VARIABLE_SECTION =
  'variables/REORDER_CUSTOM_VARIABLE_SECTION';
export const SAVE_CUSTOM_VARIABLE = 'variables/SAVE_CUSTOM_VARIABLE';
export const SAVE_CUSTOM_VARIABLE_SECTION =
  'variables/SAVE_CUSTOM_VARIABLE_SECTION';
export const SET_CUSTOM_VARIABLES = 'variables/SET_CUSTOM_VARIABLES';
export const SET_CUSTOM_VARIABLE_SECTIONS =
  'variables/SET_CUSTOM_VARIABLE_SECTIONS';
export const SET_SYSTEM_VARIABLES = 'variables/SET_SYSTEM_VARIABLES';
export const SET_VARIABLE_AUTOCOMPLETE_OPTIONS =
  'variables/SET_VARIABLE_AUTOCOMPLETE_OPTIONS';
export const UPDATE_CUSTOM_VARIABLE = 'variables/UPDATE_CUSTOM_VARIABLE';
export const UPDATE_CUSTOM_VARIABLE_SECTION =
  'variables/UPDATE_CUSTOM_VARIABLE_SECTION';
export const UPDATE_CUSTOM_VARIABLE_VALUE =
  'variables/UPDATE_CUSTOM_VARIABLE_VALUE';
