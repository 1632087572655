import { useMemo } from 'react';

/**
 * Generate data suitable for MonthlySpreadsheet from system chart data
 *
 * @param {Array} chartData - A chart's data
 * @param {Array} metrics - The metrics to extract from the chart data
 */
const useChartTableData = (chartData, metrics) => {
  return useMemo(
    () =>
      metrics
        .map(({ key, name, unit }) => {
          return chartData?.reduce((accum, metricData) => {
            const metric = accum.find((v) => v.name === name);
            if (!metric) {
              const months = metricData?.data.data?.map((monthlyData) => {
                const metricValue = monthlyData[key];

                return {
                  month: monthlyData.month ?? monthlyData.date,
                  value: [
                    {
                      scenarioId: metricData?.scenario.scenarioId,
                      value: metricValue,
                    },
                  ],
                };
              });
              return [...accum, { months, name, unit }];
            }

            metric.months = metric.months?.map(({ value, ...props }, idx) => ({
              ...props,
              value: [
                ...value,
                {
                  scenarioId: metricData?.scenario.scenarioId,
                  value: metricData?.data.data?.[idx]?.[key],
                },
              ],
            }));
            return accum;
          }, []);
        })
        .flat(),
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [chartData],
  );
};

export default useChartTableData;
