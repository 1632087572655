import { useCallback, useEffect } from 'react';
import { YAxis } from 'react-jsx-highcharts';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import { LABEL_STYLES } from '@/components/Charts/chartDefaults';
import {
  getYAxisConfigNumber,
  getYAxisConfigPercent,
  setPercentKpi,
} from '@/components/Charts/helpers';
import formatWholeNumber from '@/helpers/formatWholeNumber';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import metricFormatters from '@/helpers/metricFormatters';
import useTotalMrrQuery from './useTotalMrrQuery';

export const CUSTOMERS_TOTAL_TOGGLE_ID = 'totalCustomers-toggle';

const MAIN_METRIC = 'Total Customers';

const yAxisConfigPercent = getYAxisConfigPercent();
const yAxisConfig = getYAxisConfigNumber();

const metrics = [
  {
    name: 'Forecast for Term',
    getValue: ({ series }) => series.data[series.data.length - 1].y,
    formatter: metricFormatters.count,
  },
  {
    name: '+/- Customers',
    key: 'adjustmentsToCustomers',
    formatter: metricFormatters.count,
  },
  {
    name: MAIN_METRIC,
    isMainMetric: true,
    key: 'y',
    formatter: metricFormatters.count,
  },
];

const pctMetrics = [
  {
    name: 'Current Total Customers',
    key: 'totalCustomers',
    formatter: metricFormatters.count,
  },
  {
    name: 'Prior Total Customers',
    key: 'beginningCustomers',
    formatter: metricFormatters.count,
  },
  {
    name: 'Total Customers %',
    isMainMetric: true,
    key: 'y',
    formatter: metricFormatters.percentLegacy,
  },
];

/**
 * Fetches and renders a line chart showing total customers in absolute and
 * percentage terms
 */
const CustomersTotalChart = ({
  className,
  tooltipOptions,
  plotOptions,
  axisStyles,
  exportBtn,
  showPercentage,
  onQueryStateChange,
}) => {
  const reducer = useCallback(
    ({
      mrrData,
      totalCurrentCustomers,
      totalForecastCustomers,
      compoundPeriodCustomerGrowthRate,
    }) => ({
      ...mapMonthlyData(
        mrrData,
        showPercentage ? 'totalCustomersPercentagePoP' : 'totalCustomers',
      ),
      currentMonthValue: formatWholeNumber(totalCurrentCustomers),
      forecastTermValue: formatWholeNumber(totalForecastCustomers),
      forecastTermValuePoP: setPercentKpi(compoundPeriodCustomerGrowthRate),
    }),
    [showPercentage],
  );

  const scenarioQueries = useTotalMrrQuery(reducer);
  const [base] = scenarioQueries;

  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );

  useEffect(() => {
    if (base) onQueryStateChange?.(base);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [base]);

  const yAxis = (defaults) => {
    return {
      ...defaults,
      labels: {
        ...defaults.labels,
        style: axisStyles || LABEL_STYLES,
      },
    };
  };

  return (
    <DateChart
      id="customersTotal"
      data-testid="customersTotal"
      className={className}
      loading={scenarioQueries.every(({ isLoading }) => isLoading)}
      tooltip={
        <ChartTooltip
          metrics={showPercentage ? pctMetrics : metrics}
          valueFormatter={
            showPercentage
              ? metricFormatters.percentLegacy
              : metricFormatters.count
          }
          endDate={endDate}
          timePeriod={timePeriod}
          {...tooltipOptions}
        />
      }
      plotOptions={plotOptions}
      axisStyles={axisStyles}
      ref={exportBtn}
      startDate={startDate}
      endDate={endDate}
      timePeriod={timePeriod}
    >
      <YAxis {...yAxis(showPercentage ? yAxisConfigPercent : yAxisConfig)}>
        {scenarioQueries.map(
          ({ data, scenario }, idx) =>
            data && (
              <DateChart.Series
                key={scenario.scenarioId}
                data={data.data}
                index={idx}
                metric={MAIN_METRIC}
                scenario={scenario}
                isComparison={idx === 1}
              />
            ),
        )}
      </YAxis>
    </DateChart>
  );
};

export default CustomersTotalChart;
