// @ts-check
import { useState } from 'react';
import HiddenIcon from '@bill/cashflow.assets/hidden';
import VisibleIcon from '@bill/cashflow.assets/visible';
import PropTypes from 'prop-types';
import FormField from './FormField';
import './PasswordField.scss';

export const PASSWORD_REGEX =
  // eslint-disable-next-line no-useless-escape -- predates description requirement
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*()_+=`’:{}.?;"<>,|'\-\\\/\[\]])(?=\S+$).{8,50}$/;

export const PASSWORD_RULE =
  'Passwords must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character.';

/**
 * Returns a boolean if password is valid or not.
 *
 * @example
 *   isPasswordValid(userPassword);
 */
export const isPasswordValid = (password) => {
  return PASSWORD_REGEX.test(password);
};

// eslint-disable-next-line consistent-return -- predates description requirement
function defaultValidator(value) {
  if (!PASSWORD_REGEX.test(value)) {
    return `${PASSWORD_RULE} Please try again.`;
  }
}

/**
 * Creates a password input with a toggle button to reveal/obfuscate the value.
 *
 * @example
 *   <PasswordField
 *     id="password"
 *     value={bar}
 *     onChange={(event) => setBar(event.target.value)}
 *   />;
 *
 * @type {(
 *   props: {
 *     id: string;
 *     disabled?: boolean;
 *     validate?: (val: string) => string;
 *   } & import('./FormField').FormFieldProps,
 * ) => React.ReactElement}
 */
function PasswordField({
  disabled = false,
  id,
  validate = defaultValidator,
  ...props
}) {
  const [isHidden, setHidden] = useState(true);

  const Icon = isHidden ? HiddenIcon : VisibleIcon;

  return (
    <FormField
      id={id}
      type={isHidden ? 'password' : 'text'}
      disabled={disabled}
      validate={validate}
      autoComplete="off"
      {...props}
    >
      <button
        type="button"
        className="PasswordField_ToggleBtn"
        aria-label={`${isHidden ? 'Show' : 'Hide'} Password`}
        aria-pressed={!isHidden}
        data-testid={`${id}-toggle`}
        disabled={disabled}
        onClick={() => setHidden(!isHidden)}
      >
        <Icon aria-hidden="true" />
      </button>
    </FormField>
  );
}

PasswordField.propTypes = {
  /** Whether or not the input element should be disabled */
  disabled: PropTypes.bool,
  /** The ID of the input element, corresponding to the label's htmlFor attribute */
  id: PropTypes.string.isRequired,
  /**
   * A function that returns an error message for the given value, or falsy
   *
   * @param {string} value
   */
  validate: PropTypes.func,
};

export default PasswordField;
