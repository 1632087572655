import {
  SET_EMPLOYEES_LIST,
  SET_EMPLOYEES_ERROR,
  SET_EMPLOYEES_BY_DEPARTMENT,
  SET_DEPARTMENTS,
  SET_HIRING_DRIVERS_LIST,
  SET_HIRING_DRIVER_RECORDS,
  SET_PENDING_EMPLOYEES,
  SET_SHOW_REVIEW_EMPLOYEE_MODAL,
  SET_UNLINKED_EMPLOYEES_LIST,
  SET_ALL_JOB_TITLES,
  DELETE_CUSTOM_JOB_TITLE,
  ADD_CUSTOM_JOB_TITLE,
  UPDATE_EMPLOYEE,
} from '@/actionTypes/employees';
import { LOGOUT } from '@/actionTypes/shared';

/** @typedef {import('@/types/services/backend').EmployeeDto} EmployeeDto */

/**
 * @type {{
 *   employeesList: EmployeeDto[];
 *   unLinkedEmployeesList: EmployeeDto[];
 *   pendingEmployees: EmployeeDto[];
 *   employeesByDepartment: import('@/types/services/backend').ExpenseClassDto[];
 *   error: string;
 *   departments: import('@/types/services/backend').DepartmentDto[];
 *   hiringDriversList: import('@/types/services/backend').EmployeeHiringDriverResponseDto[];
 *   hiringDriverRecords: EmployeeDto[];
 *   showReviewEmployeeModal: boolean;
 *   allJobTitles: import('@/types/services/backend').EmployeeTitlesWithDepartmentsDto[];
 * }}
 */
const INITIAL_STATE = {
  employeesList: [],
  unLinkedEmployeesList: [],
  pendingEmployees: [],
  employeesByDepartment: [],
  error: '',
  departments: [],
  hiringDriversList: [],
  hiringDriverRecords: [],
  showReviewEmployeeModal: false,
  allJobTitles: [],
};

const employees = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CUSTOM_JOB_TITLE: {
      const allJobTitles = [...state.allJobTitles, action.payload];
      return { ...state, allJobTitles };
    }
    case DELETE_CUSTOM_JOB_TITLE: {
      const allJobTitles = state.allJobTitles.filter(
        ({ jobTitleId }) => jobTitleId !== action.payload,
      );
      return { ...state, allJobTitles };
    }
    case SET_EMPLOYEES_LIST: {
      return { ...state, employeesList: action.payload };
    }
    case SET_UNLINKED_EMPLOYEES_LIST:
      return { ...state, unLinkedEmployeesList: action.payload };
    case SET_EMPLOYEES_ERROR:
      return { ...state, error: action.payload };
    case SET_EMPLOYEES_BY_DEPARTMENT:
      return { ...state, employeesByDepartment: action.payload };
    case SET_DEPARTMENTS:
      return { ...state, departments: action.payload };
    case SET_PENDING_EMPLOYEES:
      return { ...state, pendingEmployees: action.payload };
    case SET_SHOW_REVIEW_EMPLOYEE_MODAL:
      return { ...state, showReviewEmployeeModal: action.payload };
    case SET_HIRING_DRIVERS_LIST:
      return { ...state, hiringDriversList: action.payload };
    case SET_HIRING_DRIVER_RECORDS:
      return { ...state, hiringDriverRecords: action.payload };
    case SET_ALL_JOB_TITLES:
      return { ...state, allJobTitles: action.payload };
    case UPDATE_EMPLOYEE: {
      const employeesList = [...state.employeesList];
      const { employeeId, faulted, variableValueFaultType } = action.payload;
      const index = employeesList.findIndex(({ id }) => id === employeeId);

      if (index === -1 || employeesList[index].isUnsaved) return state;

      employeesList[index] = {
        ...employeesList[index],
        faulted,
        variableValueFaultType,
      };
      return {
        ...state,
        employeesList,
      };
    }
    case LOGOUT:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default employees;
