// @ts-check
import { useMemo } from 'react';
import CrossIcon from '@bill/cashflow.assets/cross';
import Integrations from '@/components/common/Integrations';
import Sidebar from '@/components/common/Sidebar';
import useCompanySettingsQuery from '@/hooks/useCompanySettingsQuery';
import useIntegrationStates from '@/hooks/useIntegrationStates';
import useIntegrations from '@/hooks/useIntegrations';
import './ConsoleIntegrationSidebar.scss';

const REFECTH_INTERVAL = 5000;

/**
 * @typedef {{
 *   onClose: () => void;
 *   companyData: import('@/reducers/companies').Company;
 * }} ConsoleIntegrationSidebarParams
 */

/** @typedef {ConsoleIntegrationSidebarParams} IntegrationSidebarContentParams */

/**
 * Renders the company integration content
 *
 * @example
 *   <IntegrationSidebarContent onClose={onCloseFn} />;
 *
 * @type {(props: IntegrationSidebarContentParams) => React.ReactElement}
 */
const IntegrationSidebarContent = ({ onClose, companyData }) => {
  const {
    isLoading: isLoadingIntegrations,
    integrations,
    integrationsConnected,
    integrationsUnauthorized,
    integrationsAllowed,
  } = useIntegrations(companyData.id, {
    refetchInterval: REFECTH_INTERVAL,
  });
  const { data, isLoading } = useCompanySettingsQuery(companyData.id, {
    refetchInterval: REFECTH_INTERVAL,
  });

  const companySettings = useMemo(
    () => ({ ...data, loading: isLoading }),
    [data, isLoading],
  );

  const { buttonStates, setButtonStates } = useIntegrationStates(
    integrationsAllowed,
    [companySettings, integrationsConnected],
  );

  return (
    <div className="ConsoleIntegrationSidebar_Container">
      <div className="ConsoleIntegrationSidebar_Panel">
        <header className="Sidebar_Header">
          <h3 className="Sidebar_Title">Integrations</h3>
          <button
            className="Sidebar_CloseBtn"
            data-testid="sidebarCloseBtn"
            onClick={onClose}
            aria-label="Close"
          >
            <CrossIcon className="CloseIcon" />
          </button>
        </header>
        <h5 className="Sidebar_Subtitle">Finmark from Bill</h5>
        <div className="Integration">
          <Integrations
            isLoading={isLoadingIntegrations}
            selectedCompany={companyData}
            integrations={integrations}
            integrationsConnected={integrationsConnected}
            integrationsUnauthorized={integrationsUnauthorized}
            companySettings={companySettings}
            redirectTo="console"
            buttonStates={buttonStates}
            onChangeBtnStates={setButtonStates}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Renders the company integration sidebar
 *
 * @example
 *   <ConsoleIntegrationSidebar companyData={company} onClose={onCloseFn} />;
 *
 * @type {(params: ConsoleIntegrationSidebarParams) => React.ReactElement}
 */
const ConsoleIntegrationSidebar = ({ onClose, companyData }) => {
  return (
    <Sidebar
      className="ConsoleIntegrationSidebar"
      open={!!companyData}
      onClose={onClose}
    >
      <IntegrationSidebarContent onClose={onClose} companyData={companyData} />
    </Sidebar>
  );
};

export default ConsoleIntegrationSidebar;
