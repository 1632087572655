// @ts-check
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { COMPANY_STATUS } from '@/cacheKeys';
import { getCompanyMetadataStatus } from '@/services/settingsService';
import useSelectedScenarioIds from './useSelectedScenaroIds';

/**
 * Get companies status if its lockedout or has missing information
 *
 * @type {() => import('@tanstack/react-query').UseQueryResult<
 *   import('@/services/settingsService').CompanyMetadataStatusResponse,
 *   unknown
 * >}
 */
const useCompanyStatusQuery = () => {
  const selectedCompanyId = useSelector(
    ({ companies }) => companies.selectedCompanyId,
  );
  const [scenarioId] = useSelectedScenarioIds();

  return useQuery({
    queryKey: [COMPANY_STATUS, scenarioId],
    queryFn: async () => {
      const { data } = await getCompanyMetadataStatus();
      return data.data;
    },
    staleTime: Infinity,
    enabled: !!selectedCompanyId,
  });
};

export default useCompanyStatusQuery;
