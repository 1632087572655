// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';

/**
 * Retrieves the start date from `state.shared` in redux store
 *
 * @type {() => string}
 */
const useStartDate = () => {
  return useSelector((state) => {
    return state.shared.startDate;
  });
};

export default useStartDate;
