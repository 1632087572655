import { useRef } from 'react';
import { HighchartsProvider } from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import CustomChartGlance from '@/pages/Dashboard/CustomChartGlance';
import STOCK_CHART_MAP from '@/pages/Dashboard/stockChartMap';
import ReportWidget from './ReportWidget';
import './ReportCharts.scss';

const ReportCharts = ({ layoutItems, onRemoveChart }) => {
  const container = useRef(null);
  return (
    <HighchartsProvider Highcharts={Highcharts}>
      <div ref={container} className="ReportCharts_Container">
        {layoutItems.map(({ key, chartInfo }) => {
          const Component = STOCK_CHART_MAP[key];
          if (Component)
            return (
              <Component
                ChartWidget={ReportWidget}
                key={key}
                onRemoveChart={() => onRemoveChart(key)}
              />
            );

          return (
            <CustomChartGlance
              key={key}
              chartInfo={chartInfo}
              onRemoveChart={() => onRemoveChart(key)}
              ChartWidget={ReportWidget}
            />
          );
        })}
      </div>
    </HighchartsProvider>
  );
};

export default ReportCharts;
