// @ts-check
import { useMemo } from 'react';
import { AnimatePresence, m as motion } from 'framer-motion';
// eslint-disable-next-line no-restricted-imports -- predates description requirement
import ProgressIndicator from '@/components/common/ProgressIndicator';
import { classNames } from '@/helpers';
import './LoadingIndicator.scss';

const ANIM_TRANSITION = window.Cypress
  ? { duration: 0 }
  : {
      type: 'spring',
      mass: 0.5,
    };

/** @typedef {string | number} PositionValue */
/**
 * @typedef {{
 *   left?: PositionValue;
 *   top?: PositionValue;
 *   right?: PositionValue;
 *   bottom?: PositionValue;
 * }} Position
 */

export const startAnimationPresets = /** @type {const} */ ({
  DOWN_DIAGONALLY: 'DOWN_DIAGONALLY',
});

/** @typedef {(typeof startAnimationPresets)[keyof typeof startAnimationPresets]} StartAnimationPreset */

export const endAnimationPresets = /** @type {const} */ ({
  DOWN_STRAIGHT: 'DOWN_STRAIGHT',
  DOWN_DIAGONALLY: 'DOWN_DIAGONALLY',
});

/** @typedef {(typeof endAnimationPresets)[keyof typeof endAnimationPresets]} EndAnimationPreset */

/**
 * @private Calculates the new position for animation
 * @type {(params: {
 *   positionValue?: PositionValue;
 *   incrementOption: boolean;
 * }) => PositionValue | undefined}
 */
const calculateNewPosition = ({ positionValue, incrementOption }) => {
  if (!positionValue) return undefined;
  if (typeof positionValue === 'string') {
    return `${parseFloat(positionValue) + (incrementOption ? 50 : -50)}%`;
  }
  return Number(positionValue) * (incrementOption ? 50 : -50);
};

/**
 * @private
 * @type {(
 *   position: Position,
 *   incrementOptions: {
 *     top?: boolean;
 *     right?: boolean;
 *     bottom?: boolean;
 *     left?: boolean;
 *   },
 * ) => Position}
 */
const calculateAnimationPosition = (position, incrementOptions) => {
  return Object.keys(position).reduce((accum, key) => {
    accum[key] = calculateNewPosition({
      positionValue: position[key],
      incrementOption: incrementOptions[key],
    });
    return accum;
  }, /** @type {Position} */ ({}));
};

/**
 * Generates start and stop animations for framer-motion
 *
 * @example
 *   const { initial, animate, exit } = generateAnimations(
 *     startAnimation,
 *     endAnimation,
 *     position,
 *   );
 *
 * @type {(
 *   startAnimation: StartAnimationPreset,
 *   endAnimation: EndAnimationPreset,
 *   position: Position,
 * ) => import('framer-motion').AnimationProps}
 */
const generateAnimations = (startAnimation, endAnimation, position) => {
  /** @type {import('framer-motion').AnimationProps} */
  const animation = { animate: { ...position, opacity: 1 } };

  animation.initial = !startAnimation
    ? false
    : {
        opacity: 0,
        ...calculateAnimationPosition(position, {
          top: false,
          left: true,
          right: false,
          bottom: true,
        }),
      };

  if (endAnimation === 'DOWN_STRAIGHT') {
    animation.exit = {
      opacity: 0,
      ...calculateAnimationPosition(
        { top: position.top, bottom: position.bottom },
        { top: true, bottom: false },
      ),
    };
  } else if (endAnimation === 'DOWN_DIAGONALLY') {
    animation.exit = {
      opacity: 0,
      ...calculateAnimationPosition(position, {
        top: true,
        left: true,
        right: false,
        bottom: false,
      }),
    };
  }

  return animation;
};

/**
 * Render a loading indicator, such as that used to indicate that calculations
 * are in progress on the BE
 *
 * @example
 *   <LoadingIndicator isVisible text="Loading" position={top: '10%', left: '20%'} />;
 *
 * @type {(props: {
 *   isVisible: boolean;
 *   text: string;
 *   position: Position;
 *   startAnimation?: StartAnimationPreset;
 *   endAnimation?: EndAnimationPreset;
 *   Icon?: () => React.ReactElement;
 *   showProgress?: boolean;
 *   progressStyle?: import('@/components/common/ProgressIndicator').ProgressStylePreset;
 *   total?: number;
 *   current?: number;
 *   secondaryText?: string;
 * }) => React.ReactElement}
 */
function LoadingIndicator({
  isVisible,
  text,
  Icon,
  startAnimation,
  endAnimation,
  position,
  showProgress,
  progressStyle,
  total,
  current,
  secondaryText,
}) {
  const { initial, animate, exit } = useMemo(
    () => generateAnimations(startAnimation, endAnimation, position),
    [startAnimation, endAnimation, position],
  );

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="loading"
          className={classNames(
            'LoadingIndicator',
            secondaryText && 'LoadingIndicator_WithSecondaryText',
          )}
          initial={initial}
          animate={animate}
          exit={exit}
          transition={ANIM_TRANSITION}
          aria-modal="true"
        >
          <div
            className={classNames(
              'LoadingIndicator_Display',
              secondaryText && 'LoadingIndicator_DisplayText-secondary',
            )}
          >
            {Icon && (
              <div className="LoadingIndicator_Icon">
                <Icon />
              </div>
            )}
            <span>{text}</span>
          </div>
          <div className="LoadingIndicator_SecondaryItems">
            {secondaryText && (
              <p className="LoadingIndicator_SecondaryText">{secondaryText}</p>
            )}
            {showProgress && (
              <ProgressIndicator
                id="LoadingIndicator_ProgressBar"
                data-testid="loading-indicator-progress-bar"
                max={total}
                progress={current}
                min={0}
                variant={progressStyle}
                className="LoadingIndicator_ProgressBar"
              />
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default LoadingIndicator;
