/**
 * Format the value to given decimalPlaces.
 *
 * @param {number} value Value to format
 * @param {number} decimalPlaces decimalPlaces to round of to
 * @returns {number} Formatted value
 */

function formatDecimalNumber(value, decimalPlaces = 2) {
  const pow = 10 ** decimalPlaces;

  /**
   * Number.EPSILON corrects for occasional rounding errors inherent in JS
   * floating-point math, e.g. 1.005 rounding to 1.
   *
   * @see https://javascript.plainenglish.io/why-0-1-0-2-0-3-in-javascript-d7e218224a72
   */
  return Math.round((value + Number.EPSILON) * pow) / pow;
}

export default formatDecimalNumber;
