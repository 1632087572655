// @ts-check
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useIntersection from '@/hooks/useIntersection';

/**
 * A hook which determines the section is visible in viewport and update the URl
 * in browser
 *
 * @type {(
 *   id: string,
 *   options: IntersectionObserverInit,
 * ) => React.RefObject<HTMLDivElement>}
 */
const useSectionVisibleOnScreen = (id, options) => {
  const ref = useRef();
  const history = useHistory();
  const isVisible = useIntersection(ref, {
    ...options,
    rootMargin: '-50% 0% -50% 0%',
    threshold: 0,
  });
  const sectionId = `#${id}`;
  useEffect(() => {
    if (isVisible) {
      history.push(sectionId);
    }
  }, [history, sectionId, isVisible]);
  return ref;
};

export default useSectionVisibleOnScreen;
