import CircularReferenceIcon from '@bill/cashflow.assets/circular-reference';
import UserAvatarIcon from '@bill/cashflow.assets/user-avatar';
import WithTooltip from '@/components/common/WithTooltip';
import { actualsFamily, iconTypes, actualsTitle } from '@/constants/actuals';
import {
  CIRCULAR_REFERENCE_HEADING,
  CIRCULAR_REFERENCE_WARNING,
} from '@/constants/circularReference';
import { actions, subjects } from '@/constants/permissions';
import { classNames } from '@/helpers';
import usePermissions from '@/hooks/usePermissions';
import { ReactComponent as IntegrationsIcon } from '@/assets/icons/svg/integrations.svg';
import './TooltipCellRenderer.scss';

const EXTERNAL_SOURCE_EXTENDED_TEXT =
  'These actuals have been pulled from an external integrated platform.';
const EXTERNAL_SOURCE_EXTENDED_TEXT_WITH_CLICK = `${EXTERNAL_SOURCE_EXTENDED_TEXT} Click the icon to see the breakdown.`;

const { READ, READ_WRITE } = actions;
const { EMPLOYEE_SETTINGS } = subjects;

const Tooltip = ({ iconType, onClick }) => {
  let heading;
  let icon;
  let tooltipInfo;
  if (!iconType) return null;

  switch (iconType) {
    case iconTypes.USER_ENTERED:
      heading = 'User Created';
      icon = <UserAvatarIcon />;
      break;
    case iconTypes.EXTERNAL_SOURCE_GENERATED:
      heading = 'Externally Created';
      icon = <IntegrationsIcon />;
      tooltipInfo = onClick
        ? EXTERNAL_SOURCE_EXTENDED_TEXT_WITH_CLICK
        : EXTERNAL_SOURCE_EXTENDED_TEXT;
      break;
    case iconTypes.CIRCULAR_REFERENCE:
      heading = CIRCULAR_REFERENCE_HEADING;
      icon = <CircularReferenceIcon />;
      tooltipInfo = CIRCULAR_REFERENCE_WARNING;
      break;
    default:
      throw new Error('Unknown valueType');
  }
  return (
    <WithTooltip
      content={
        <>
          <div className="ToolTipCell_Heading" data-testid="tooltip-heading">
            {heading}
          </div>
          <span data-testid="tooltip-info">{tooltipInfo}</span>
        </>
      }
      data-testid="tooltip"
      placement="right"
      trigger="mouseenter"
    >
      {onClick ? (
        <button
          className="TooltipCell_Icon"
          data-testid="tooltip-trigger"
          onClick={(event) => {
            event.currentTarget.blur();
            onClick();
          }}
        >
          {icon}
        </button>
      ) : (
        <span className="TooltipCell_Icon" data-testid="tooltip-trigger">
          {icon}
        </span>
      )}
    </WithTooltip>
  );
};

const TooltipCellRenderer = (context) => {
  const { valueFormatted, iconType, onClick, title, data, node, cellLoading } =
    context;

  const editorEmployeePerms = usePermissions(READ_WRITE, EMPLOYEE_SETTINGS);
  const viewerEmployeePerms = usePermissions(READ, EMPLOYEE_SETTINGS);
  const isExpenseActuals = data.family === actualsFamily.EXPENSE;
  const isPayrollActuals = data.family === actualsFamily.PAYROLL;
  const isRevenueActuals = data.family === actualsFamily.REVENUE;
  const isTotalPayroll =
    data.title.toLowerCase() === actualsTitle.TOTAL_PAYROLL;

  const canViewExternalIcon =
    data.title.toLowerCase() === actualsTitle.TOTAL_REVENUE ||
    isTotalPayroll ||
    (!node.allChildrenCount &&
      (isExpenseActuals || isPayrollActuals || isRevenueActuals));
  const canViewPayrollData =
    (editorEmployeePerms || viewerEmployeePerms) && isTotalPayroll;

  return (
    <div
      className={classNames(
        'TooltipCell',
        iconType === iconTypes.CIRCULAR_REFERENCE && 'TooltipCellError',
      )}
      data-testid="tooltip-cell"
    >
      <span className="TooltipCell_Value" data-testid="tooltip-cell-value">
        {valueFormatted}
      </span>
      {canViewExternalIcon && (
        <Tooltip
          iconType={iconType}
          onClick={
            !cellLoading &&
            (canViewPayrollData || isExpenseActuals || isRevenueActuals)
              ? onClick
              : null
          }
          title={title}
        />
      )}
    </div>
  );
};
export default TooltipCellRenderer;
