// @ts-check
import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import LoadingSpinner from './LoadingSpinner';
import './Button.scss';

/**
 * @typedef {{
 *   'children': React.ReactNode;
 *   'className'?: string;
 *   'data-testid'?: string;
 *   'disabled'?: boolean;
 *   'loading'?: boolean;
 *   'type'?: 'button' | 'reset' | 'submit';
 * } & React.ComponentPropsWithoutRef<'button'>} ButtonProps
 */

/**
 * Creates a button with an optional loading state
 *
 * @example
 *   <Button
 *     data-testid="foo"
 *     loading={isLoading}
 *     onClick={() => setIsLoading(true)}
 *   >
 *     Click Me!
 *   </Button>;
 *
 * @type {(params: ButtonProps) => React.ReactElement}
 */
function Button({
  children,
  className,
  'data-testid': dataTestId,
  disabled = false,
  loading = false,
  type = 'button',
  ...props
}) {
  return (
    // eslint-disable-next-line react/button-has-type -- predates description requirement
    <button
      type={type}
      className={classNames('Button', className)}
      aria-busy={loading}
      data-testid={dataTestId}
      disabled={loading || disabled}
      {...props}
    >
      {loading ? (
        <LoadingSpinner data-testid={`${dataTestId}-spinner`} />
      ) : (
        children
      )}
    </button>
  );
}

Button.propTypes = {
  /** The label or other contents of the button */
  'children': PropTypes.node,
  /** Class(es) to apply to the button, defaulting to the 'primary' variant */
  'className': PropTypes.string,
  /** Unique ID for selecting the button in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Whether or not the button is disabled */
  'disabled': PropTypes.bool,
  /** While true, disables the button and displays a loading indicator */
  'loading': PropTypes.bool,
  /** The type of the button, e.g. 'submit' */
  'type': PropTypes.oneOf(['button', 'reset', 'submit']),
};

export default Button;
