// @ts-check
import { useCallback, useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { DISMISS_UNAUTHORIZED } from '@/actionTypes/auth';
import { subscribeToUserPermissionChangeAction } from '@/actions/auth';
import { GET_RECENTLY_CREATED_FORECASTING_ADMINS } from '@/cacheKeys';
import { userRoles } from '@/components/BillOnboarding/constants';
import { DASHBOARD_PATH } from '@/constants/pages';
import useAuthorizedCompanies from '@/hooks/useAuthorizedCompanies';
import useAuthorizedScenarios from '@/hooks/useAuthorizedScenarios';
import useTypedSelector from '@/hooks/useTypedSelector';
import useWsSubscription from '@/hooks/useWsSubscription';
import { getRecentlyCreatedForecastingAdmins } from '@/services/onboarding.service';
import { ReactComponent as OnboardingNoAccessIcon } from '@/assets/images/office-desktop.svg';
import './OnboardingNoAccess.scss';

export default function OnboardingNoAccess() {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const history = useHistory();
  const { userInfo, isAuthenticated } = useTypedSelector(({ auth }) => auth);
  useWsSubscription(() => {
    if (!isAuthenticated) {
      return null;
    }
    return dispatch(subscribeToUserPermissionChangeAction(userInfo?.userId));
  }, [userInfo?.userId, isAuthenticated]);
  const authorizedCompanies = useAuthorizedCompanies();
  const authorizedScenarios = useAuthorizedScenarios();
  const { companies, selectedCompanyId } = useTypedSelector(
    (state) => state.companies,
  );

  /**
   * @type {import('@tanstack/react-query').UseQueryResult<
   *   import('@/types/services/backend').CompanyUserDummyDto[]
   * >}
   */
  const { isLoading, data: recentlyCreatedAdminEmails } = useQuery(
    [GET_RECENTLY_CREATED_FORECASTING_ADMINS],
    async () => {
      const { data } = await getRecentlyCreatedForecastingAdmins(
        userRoles.ADMIN,
      );
      return data.data;
    },
  );

  const hasAuthorized = useMemo(
    () => authorizedScenarios.length > 0 && authorizedCompanies.length > 0,
    [authorizedCompanies, authorizedScenarios],
  );
  const getDefaultScenarioId = useCallback(() => {
    const selectedCompany = companies.find(
      ({ id }) => id === selectedCompanyId,
    );
    return selectedCompany?.defaultScenarioId;
  }, [selectedCompanyId, companies]);

  useEffect(() => {
    if (hasAuthorized && selectedCompanyId) {
      const defaultScenarioId = getDefaultScenarioId();
      if (defaultScenarioId) {
        /** @type {import('@/pages/Landing/Unauthorized').DismissUnauthorizedPayload} */
        const payload = { defaultScenarioId, selectedCompanyId };
        dispatch({
          type: DISMISS_UNAUTHORIZED,
          payload,
        });
        history.replace(DASHBOARD_PATH);
      }
    }
  }, [
    dispatch,
    getDefaultScenarioId,
    authorizedCompanies,
    selectedCompanyId,
    hasAuthorized,
    history,
  ]);

  return (
    <div className="OnboardingNoAccess" data-testid="noaccess-onboarding">
      <OnboardingNoAccessIcon />
      <h1 className="OnboardingNoAccess_Title">
        Request access from your admin
      </h1>
      <p className="OnboardingNoAccess_Text">
        To view Cash Flow Forecasting, you must have the{' '}
        <a
          href="https://help.bill.com/direct/s/article/000002829"
          className="link"
          target="_blank"
          rel="noreferrer"
        >
          Forecasting role and permission
        </a>
        . Please contact your admin to grant you access. In the meantime, you
        can access Payable Insights from the left navigation menu.
      </p>
      <div
        className="OnboardingNoAccess_Emails"
        data-testid="noaccess-onboarding-emails"
      >
        {!isLoading &&
          recentlyCreatedAdminEmails.map(({ name, email }) => (
            <p
              className="OnboardingNoAccess_Email"
              data-testid={`noaccess-onboarding-email-${email}`}
            >
              {name} - {email}
            </p>
          ))}
      </div>
    </div>
  );
}
