import { useState } from 'react';
import { classNames } from '@/helpers';
import './IntegrationButton.scss';

const IntegrationButton = ({
  className,
  disabled,
  children,
  onClick,
  isPending,
  isSelected,
  isUnavailable,
  isUnauthorized,
  isSyncing,
}) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <button
      type="button"
      className={classNames(
        'SettingsIntegrationBtn',
        className,
        isSelected && 'SettingsIntegrationBtn-selected',
        isUnauthorized && 'SettingsIntegrationBtn-unauthorized',
        isUnavailable && 'SettingsIntegrationBtn-unavailable',
        (isLoading || isPending || isSyncing) &&
          'SettingsIntegrationBtn-loading',
      )}
      onClick={() => {
        setLoading(true);
        onClick();
      }}
      disabled={
        disabled || isLoading || isPending || isSelected || isUnavailable
      }
    >
      <div className="SettingsIntegrationBtn_Contents">{children}</div>
    </button>
  );
};

export default IntegrationButton;
