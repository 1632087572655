// @ts-check
import { useMemo } from 'react';
import DoubleArrowIcon from '@bill/cashflow.assets/double-arrow';
import RightArrowIcon from '@bill/cashflow.assets/right-arrow';
import PropTypes from 'prop-types';
import '@/components/common/Pagination.scss';
import Select from '@/components/common/Select';
import { assertIsNumber } from '@/helpers';

export const ELLIPSIS = '...';
const PLACES_COUNT = 9;
export const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

/**
 * @typedef {{
 *   'data-testid': string;
 *   'totalPages': number;
 *   'currentPage': number;
 *   'onNavigate': (param: { page: number }) => void;
 *   'itemsPerPage': number;
 *   'handleItemsPerPageChanged': (itemPerPage: number) => void;
 * }} PaginationProps
 */

/**
 * @example
 *   <NumberedButtons
 *     totalPages={totalPages}
 *     currentPage={currentPage}
 *     onNavigate={onNavigate}
 *   />;
 *
 * @type {React.FC<
 *   Pick<PaginationProps, 'totalPages' | 'currentPage' | 'onNavigate'>
 * >}
 */
const NumberedButtons = ({ totalPages, currentPage, onNavigate }) => {
  /** @type {number[][]} */
  const [pages, reversedPages] = useMemo(() => {
    const pagesArray = Array(totalPages)
      .fill()
      .map((_, index) => index + 1);
    const reversedPagesArray = [...pagesArray].reverse();
    return [pagesArray, reversedPagesArray];
  }, [totalPages]);

  /** @type {(string | number)[]} */
  const pageNumbers = useMemo(() => {
    if (pages.length <= PLACES_COUNT) return pages;

    const cache = pages.reduce(
      (accum, pageNumber) => {
        const indexFromBeginning = pages.indexOf(pageNumber);
        const indexFromEnd = reversedPages.indexOf(pageNumber);
        const pastHalfWay = PLACES_COUNT / 2 + 1;
        const previousPage = currentPage - 1;
        const nextPage = currentPage + 1;
        if ([pages[0], pages[1]].includes(pageNumber)) {
          accum.first.push(pageNumber);
        } else if ([reversedPages[0], reversedPages[1]].includes(pageNumber)) {
          accum.end.push(pageNumber);
        } else if (pageNumber < previousPage && indexFromEnd > pastHalfWay) {
          /* eslint-disable-next-line no-param-reassign -- predates description requirement */
          accum.firstEllipsis = [ELLIPSIS];
        } else if (pageNumber > nextPage && indexFromBeginning > pastHalfWay) {
          /* eslint-disable-next-line no-param-reassign -- predates description requirement */
          accum.secondEllipsis = [ELLIPSIS];
        } else {
          accum.middle.push(pageNumber);
        }
        return accum;
      },
      {
        first: [],
        firstEllipsis: [],
        middle: [],
        secondEllipsis: [],
        end: [],
      },
    );
    return Object.values(cache).flat();
  }, [pages, reversedPages, currentPage]);

  return (
    <>
      {pageNumbers.map((pageNumber, index) => {
        if (pageNumber === ELLIPSIS) {
          const id = `ellipsis-${index}`;
          return (
            <span key={id} className="Pagination_Ellipsis">
              {ELLIPSIS}
            </span>
          );
        }
        assertIsNumber(pageNumber);
        const id = `pagination-button-${pageNumber}`;
        return (
          <button
            key={pageNumber}
            aria-label={`Page ${pageNumber}`}
            aria-pressed={pageNumber === currentPage}
            className="Pagination_Button"
            id={id}
            data-testid={id}
            onClick={() => onNavigate({ page: pageNumber })}
          >
            {pageNumber}
          </button>
        );
      })}
    </>
  );
};

/** @typedef {React.FC<PaginationProps>} Pagination */

/**
 * A component for paging through data
 *
 * @example
 *   <Pagination
 *     data-testid="foo"
 *     totalPages={10}
 *     currentPage={1}
 *     onNavigate={({ page }) = goToPage(page)}
 *     itemsPerPage={25},
 *     handleItemsPerPageChanged={(itemPerPage) = setPageSize(itemPerPage)}
 *   />
 *
 * @type {Pagination}
 */
const Pagination = ({
  'data-testid': dataTestId,
  totalPages,
  currentPage,
  onNavigate,
  itemsPerPage,
  handleItemsPerPageChanged,
}) => {
  const isPreviousDisabled = currentPage <= 1;
  const isNextDisabled = currentPage >= totalPages;
  return (
    <div className="Pagination" data-testid={dataTestId}>
      <div className="PaginationControl_Wrapper">
        <button
          aria-label="Start"
          className="Pagination_Button Pagination_Button-doubleArrow"
          data-testid="pagination-button-start"
          disabled={isPreviousDisabled}
          onClick={() => onNavigate({ page: 1 })}
        >
          <DoubleArrowIcon className="Pagination_Icon Pagination_Icon-back" />
        </button>
        <button
          aria-label="Previous"
          className="Pagination_Button"
          data-testid="pagination-button-previous"
          disabled={isPreviousDisabled}
          onClick={() => onNavigate({ page: currentPage - 1 })}
        >
          <RightArrowIcon className="Pagination_Icon Pagination_Icon-back" />
        </button>
        <NumberedButtons
          totalPages={totalPages}
          currentPage={currentPage}
          onNavigate={onNavigate}
        />
        <button
          aria-label="Next"
          className="Pagination_Button"
          data-testid="pagination-button-next"
          disabled={isNextDisabled}
          onClick={() => onNavigate({ page: currentPage + 1 })}
        >
          <RightArrowIcon className="Pagination_Icon" />
        </button>
        <button
          aria-label="End"
          className="Pagination_Button Pagination_Button-doubleArrow"
          data-testid="pagination-button-end"
          disabled={isNextDisabled}
          onClick={() => onNavigate({ page: totalPages })}
        >
          <DoubleArrowIcon className="Pagination_Icon" />
        </button>
      </div>
      <div className="PaginationControl_Wrapper Pagination-withPerPage">
        <span className="Pagination_ItemsPerPage">Items per page:</span>
        <Select
          id="items-per-page"
          name="items-per-page"
          data-testid="items-per-page"
          className="Pagination_PerPageSelect"
          value={itemsPerPage}
          onChange={({ target }) =>
            handleItemsPerPageChanged(Number(target.value))
          }
        >
          {ITEMS_PER_PAGE_OPTIONS.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  /** A unique ID for the element for unit and e2e tests */
  'data-testid': PropTypes.string,
  /** The total number of pages to be paged through */
  'totalPages': PropTypes.number.isRequired,
  /** The current page number */
  'currentPage': PropTypes.number.isRequired,
  /** Navigate to a given page when clicked */
  'onNavigate': PropTypes.func.isRequired,
  /** number of items shown per page */
  'itemsPerPage': PropTypes.number.isRequired,
  /** onChange the number of items per page */
  'handleItemsPerPageChanged': PropTypes.func.isRequired,
};

export default Pagination;
