import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { CUSTOMER_ACQUISITION_COST } from '@/cacheKeys';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import metricFormatters from '@/helpers/metricFormatters';
import useChartQuery from '@/hooks/useChartQuery';
import { getCustomerAcquisitionCosts } from '@/services/dashboard.service';

const MAIN_METRIC = 'Customer Acquisition Cost';

const metrics = [
  {
    key: 'totalSalesMarketingCosts',
    name: 'Sales & Marketing Cost',
  },
  {
    key: 'customersAcquired',
    name: 'Customers Acquired',
    formatter: metricFormatters.count,
  },
  {
    isMainMetric: true,
    key: 'y',
    name: MAIN_METRIC,
  },
];

const reducer = ({ customerAcquisitionCost, months }) => {
  return {
    customerAcquisitionCost,
    ...mapMonthlyData(months, 'totalCac'),
  };
};

/**
 * Fetches and renders a line chart showing customer acquisition cost
 *
 * @example
 *   <CACChart exportBtn={exportBtn} />;
 */
function CACChart({
  className,
  exportBtn,
  plotOptions,
  axisStyles,
  tooltipOptions,
  onQueryStateChange,
}) {
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );

  const scenarioQueries = useChartQuery(
    CUSTOMER_ACQUISITION_COST,
    getCustomerAcquisitionCosts,
    reducer,
  );

  const [base] = scenarioQueries;
  useEffect(() => {
    if (base) onQueryStateChange?.(base);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [base]);

  return (
    <DateChart
      className={className}
      data-testid="cacChart"
      loading={scenarioQueries.every(({ isLoading }) => isLoading)}
      tooltip={
        <ChartTooltip
          metrics={metrics}
          endDate={endDate}
          timePeriod={timePeriod}
          {...tooltipOptions}
        />
      }
      plotOptions={plotOptions}
      axisStyles={axisStyles}
      ref={exportBtn}
      startDate={startDate}
      endDate={endDate}
      timePeriod={timePeriod}
    >
      {scenarioQueries.map(
        ({ data, scenario }, idx) =>
          data && (
            <DateChart.Series
              key={scenario.scenarioId}
              data={data.data}
              index={idx}
              metric={MAIN_METRIC}
              scenario={scenario}
              isComparison={idx === 1}
            />
          ),
      )}
    </DateChart>
  );
}

export default CACChart;
