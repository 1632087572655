export const REVENUE_TABLE_TITLES = [
  { key: 'beginningMrr', value: 'Beginning MRR' },
  { key: 'newMrr', value: 'New MRR' },
  { key: 'expansionMrr', value: 'Expansion MRR' },
  { key: 'contractionMrr', value: 'Contraction MRR' },
  { key: 'churnMrr', value: 'Churned MRR' },
  { key: 'netNewMrr', value: 'Net New MRR' },
  { key: 'adjustmentsToMrr', value: 'Adjustments to MRR' },
  { key: 'totalMrr', value: 'Total MRR' },
  { key: 'otherRevenue', value: 'Other Revenue' },
  { key: 'subtotalRevenue', value: 'Revenue Subtotal' },
  { key: 'totalAdjustments', value: 'Adjustments to Revenue' },
  { key: 'totalRevenue', value: 'Total Revenue' },
  { key: 'beginningCustomer', value: 'Beginning Customers' },
  { key: 'newCustomers', value: 'New Customers' },
  { key: 'expansionCustomers', value: 'Expansion Customers' },
  { key: 'contractionCustomers', value: 'Contraction Customers' },
  { key: 'churnedCustomers', value: 'Churned Customers' },
  { key: 'adjustmentsToCustomers', value: 'Adjustments to Customers' },
  { key: 'endingCustomers', value: 'Ending Customers' },
  { key: 'adjustments', value: 'Discounts (Stripe)' },
  { key: 'totalCustomers', value: 'Total Customers' },
  { key: 'oneTimePurchases', value: 'One Time Purchases' },
  { key: 'oneTimeRevenue', value: 'One Time Revenue' },
];

export const NON_CURRENCY_FIELDS = [
  'churnedCustomers',
  'beginningCustomer',
  'newCustomers',
  'totalCustomers',
  'adjustmentsToCustomers',
  'expansionCustomers',
  'contractionCustomers',
];

export const getRevenueTableTitles = (isStripeConnected) => {
  return isStripeConnected
    ? REVENUE_TABLE_TITLES
    : REVENUE_TABLE_TITLES.filter((title) => title.key !== 'adjustments');
};

export const PRODUCT_FREQUENCY = {
  0: 'Month',
  1: 'Quarter',
  2: '6 months',
  3: 'Year',
  4: 'One-time',
};

export const PRODUCT_FREQUENCY_FOR_SELECTION = {
  0: 'Monthly',
  1: 'Quarterly',
  2: 'Semi-Annually',
  3: 'Annually',
  4: 'One-time',
  5: 'Custom (months)',
};

export const MONTHLY_INDEX = 0;
export const QUARTERLY_INDEX = 1;
export const SEMI_ANNUALLY_INDEX = 2;
export const YEARLY_INDEX = 3;
export const ONE_TIME_INDEX = 4;
export const CUSTOM_MONTH_INDEX = 5;

export const financingActionTypes = {
  SET_NAME: 'SET_NAME',
  SET_TYPE: 'SET_TYPE',
  SET_AMOUNT: 'SET_AMOUNT',
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  EDIT_FORM: 'EDIT_FORM',
};

export const revenueFamily = {
  PRODUCT: 'Product',
  REVENUE_STREAM: 'Revenue Stream',
  TOTAL_REVENUE: 'Total Revenue',
  FINANCING: 'Financing',
};

export const REVENUE_API_NAME_MAX_LENGTH = 250;
