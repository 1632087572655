// @ts-check
import { actions, subjects } from '@/constants/permissions';
import usePermissions from '@/hooks/usePermissions';

const { WRITE } = actions;
const { NON_DASHBOARD } = subjects;

/**
 * Check if the user can make edits outside the dashboard
 *
 * @example
 *   const hasWritePermission = useNonDashboardWritePermission();
 *
 * @type {() => boolean}
 */
export default function useNonDashboardWritePermission() {
  return usePermissions(WRITE, NON_DASHBOARD, true);
}
