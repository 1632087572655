import * as actions from '@/actionTypes/variables';
import { createChart } from '@/services/dashboard.service';
import {
  createCustomVariable,
  getCustomVariables,
  getSystemVariables,
  getVariables,
  deleteCustomVariable,
  updateCustomVariable,
  getCustomVariableSections,
  updateCustomVariableSection,
  createCustomVariableSection,
  deleteCustomVariableSection,
} from '@/services/variable.service';
import { changeLoadingState } from './componentLoading';
import { subscribeToMonthlyValues, subscribeToTopic } from './shared';

export const getSystemVariablesAction = (startDate, endDate, scenarioId) => {
  return async (dispatch) => {
    dispatch(changeLoadingState('systemVariables', true));
    try {
      const { data } = await getSystemVariables({
        startDate,
        endDate,
        scenarioId,
      });
      dispatch({
        type: actions.SET_SYSTEM_VARIABLES,
        payload: data.data,
      });
    } catch (error) {
      // eslint-disable-next-line no-console -- predates description requirement
      console.error(error);
    } finally {
      dispatch(changeLoadingState('systemVariables', false));
    }
  };
};

/**
 * Action that adds a custom variable placeholder to the store for the user to
 * populate before creation
 *
 * @param {number} scenarioId ID of the scenario in which to add the variable
 * @param {string} [sectionId] ID of the section in which to add the variable
 * @returns {Function} Dispatchable action
 */
export const addCustomVariableAction = (scenarioId, sectionId) => {
  return (dispatch) => {
    dispatch({
      type: actions.ADD_CUSTOM_VARIABLE,
      payload: { scenarioId, sectionId },
    });
  };
};

/**
 * Action that removes any active placeholder for a new custom variable
 *
 * @returns {Function} Dispatchable action
 */
export const cancelAddCustomVariableAction = () => {
  return (dispatch) => {
    dispatch({ type: actions.CANCEL_ADD_CUSTOM_VARIABLE });
  };
};

/**
 * Reorders the custom variable with the given ID to a different index and/or
 * section. Used to preview drag-and-drop reordering.
 *
 * @param {string} id ID of the variable to be reordered
 * @param {number} index New index for the variable within its section
 * @param {string} sectionId ID of the section in which the variable should be
 *   placed
 * @returns {Function} Dispatchable action
 */
export const reorderCustomVariableAction = (id, index, sectionId) => {
  return (dispatch) => {
    dispatch({
      type: actions.REORDER_CUSTOM_VARIABLE,
      payload: { id, index, sectionId },
    });
  };
};

/**
 * Action to persist a new custom variable in the given scenario
 *
 * @param {number} scenarioId ID of the scenario in which to create the variable
 * @param {Object} params Properties of the variable to be created
 * @returns {Function} Dispatchable action
 */
export const saveCustomVariableAction = (scenarioId, params) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await createCustomVariable(scenarioId, {
        ...params,
      });
      const { startDate, endDate } = getState().shared;
      dispatch({
        type: actions.SAVE_CUSTOM_VARIABLE,
        payload: {
          ...data.data,
          startDate,
          endDate,
        },
      });
    } catch ({ response }) {
      return response?.data;
    }
    return null;
  };
};

/**
 * Action to update an existing custom variable with new properties
 *
 * @param {number} scenarioId ID of the scenario containing the variable
 * @param {string} id ID of variable to update
 * @param {Object} params New properties for the variable
 * @returns {Function} Dispatchable action
 */
export const updateCustomVariableAction = (scenarioId, id, params) => {
  return async (dispatch) => {
    try {
      const { data } = await updateCustomVariable(scenarioId, id, {
        ...params,
      });
      dispatch({
        type: actions.UPDATE_CUSTOM_VARIABLE,
        payload: data.data,
      });
    } catch ({ response }) {
      return response?.data;
    }
    return null;
  };
};

/**
 * Action that retrieves all custom variables in the given scenario, with their
 * values for the given date range
 *
 * @param {number} scenarioId ID of the scenario containing the variables
 * @param {string} startDate First month of desired variable values, in the
 *   format YYYY-MM
 * @param {string} endDate Last month of desired variable values, in the format
 *   YYYY-MM
 * @returns {Function} Dispatchable action
 */
export const getCustomVariablesAction =
  (scenarioId, startDate, endDate, showLoading = true) =>
  async (dispatch) => {
    dispatch(changeLoadingState('customVariables', showLoading));
    try {
      const { data } = await getCustomVariables({
        startDate,
        endDate,
        scenarioId,
      });
      dispatch({
        type: actions.SET_CUSTOM_VARIABLES,
        payload: data.data,
      });
    } finally {
      dispatch(changeLoadingState('customVariables', false));
    }
  };

/**
 * Action to subscribe to custom variable value updates
 *
 * @param {number} scenarioId ID of the scenario containing the variables
 * @returns {Function} Dispatchable action
 */
export const subscribeToVariablesAction = (scenarioId) => {
  return (dispatch) =>
    dispatch(
      subscribeToMonthlyValues(
        `custom-variable-values/${scenarioId}`,
        actions.UPDATE_CUSTOM_VARIABLE_VALUE,
      ),
    );
};

/**
 * Action that adds a custom variable section placeholder to the store for the
 * user to populate before creation.
 *
 * @param {number} scenarioId ID of the scenario in which to add the section
 * @returns {Function} Dispatchable action
 */
export const addCustomVariableSectionAction = (scenarioId) => {
  return (dispatch) => {
    dispatch({
      type: actions.ADD_CUSTOM_VARIABLE_SECTION,
      payload: { scenarioId },
    });
  };
};

/**
 * Action that removes any active placeholder for a new custom variable section
 *
 * @returns {Function} Dispatchable action
 */
export const cancelAddCustomVariableSectionAction = () => {
  return (dispatch) => {
    dispatch({ type: actions.CANCEL_ADD_CUSTOM_VARIABLE_SECTION });
  };
};

/**
 * Action to persist a new custom variable section in the given scenario
 *
 * @param {number} scenarioId ID of the scenario in which to create the section
 * @param {Object} params Properties of the new section
 * @returns {Function} Dispatchable action
 */
export const saveCustomVariableSectionAction = (scenarioId, params) => {
  return async (dispatch) => {
    const { data } = await createCustomVariableSection(scenarioId, {
      ...params,
      scenarioId,
    });
    dispatch({
      type: actions.SAVE_CUSTOM_VARIABLE_SECTION,
      payload: data.data,
    });
  };
};

/**
 * Action that retrives all user-defined sections for custom variables in the
 * given scenario
 *
 * @param {number} scenarioId ID of the scenario containing the sections
 * @returns {Function} Dispatchable action
 */
export const getCustomVariableSectionsAction = (scenarioId) => {
  return async (dispatch) => {
    const { data } = await getCustomVariableSections(scenarioId);
    dispatch({
      type: actions.SET_CUSTOM_VARIABLE_SECTIONS,
      payload: data.data,
    });
  };
};

/**
 * Action to update an existing custom variable section with new properties
 *
 * @param {number} scenarioId ID of the scenario containing the section
 * @param {string} id ID of the section to update
 * @param {Object} params New properties for the section
 * @returns {Function} Dispatchable action
 */
export const updateCustomVariableSectionAction = (scenarioId, id, params) => {
  return async (dispatch) => {
    const { data } = await updateCustomVariableSection(scenarioId, id, params);
    dispatch({
      type: actions.UPDATE_CUSTOM_VARIABLE_SECTION,
      payload: data.data,
    });
  };
};

/**
 * Action that deletes the custom variable section with the given ID
 *
 * @param {number} scenarioId ID of the scenario containing the section
 * @param {string} id ID of the section to be deleted
 * @returns {Function} Dispatchable action
 */
export const deleteCustomVariableSectionAction = (scenarioId, id) => {
  return async (dispatch) => {
    await deleteCustomVariableSection(scenarioId, id);
    dispatch({
      type: actions.DELETE_CUSTOM_VARIABLE_SECTION,
      payload: { id },
    });
  };
};

export const reorderCustomVariableSectionAction = (id, index) => {
  return (dispatch) => {
    dispatch({
      type: actions.REORDER_CUSTOM_VARIABLE_SECTION,
      payload: { id, index },
    });
  };
};

/**
 * Action that deletes the custom variable with the given ID
 *
 * @param {number} scenarioId ID of the scenario containing the variable
 * @param {string} id ID of the variable to be deleted
 * @returns {Function} Dispatchable action
 */
export const deleteCustomVariableAction = (scenarioId, id) => {
  return async (dispatch) => {
    const { status } = await deleteCustomVariable(scenarioId, id);
    if (status === 409 || status === 500) {
      throw new Error('unable to delete variable');
    }
    dispatch({
      type: actions.DELETE_CUSTOM_VARIABLE,
      payload: id,
    });
  };
};

/**
 * Action that retrieves all the variables in the given scenario
 *
 * @param {number} scenarioId ID of the scenario containing the variables
 * @returns {Function} Dispatchable action
 */
export const getVariablesAction = (scenarioId) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await getVariables(scenarioId);
    dispatch({
      type: actions.SET_VARIABLE_AUTOCOMPLETE_OPTIONS,
      payload: data,
    });
  } catch (e) {
    // eslint-disable-next-line no-console -- predates description requirement
    console.error(e);
  }
};

/**
 * Action that creates the variable chart
 *
 * @param {number} scenarioId ID of the scenario containing the variables
 * @param {string} dashboardLayoutId ID of the dashboard in which to create the
 *   chart of custom variable
 * @param {Object} params Properties of the chart
 * @returns {Function} Dispatchable action
 */
export const createVariableChartAction =
  (scenarioId, dashboardLayoutId, params, startDate, endDate) =>
  async (dispatch) => {
    await createChart(scenarioId, dashboardLayoutId, params);
    dispatch(getCustomVariablesAction(scenarioId, startDate, endDate));
  };

/**
 * Action to subscribe to custom variable updates e.g. creation of new variable,
 * edit of variable name, etc
 *
 * @param {number} scenarioId ID of the scenario containing the variables
 * @returns {Function} Dispatchable action
 */
export const subscribeToVariableUpdatesAction = (scenarioId) => {
  return (dispatch) => {
    return dispatch(
      subscribeToTopic(`custom-variables-updated/${scenarioId}`, (value) => {
        if (value.scenarioId) {
          dispatch(getVariablesAction(scenarioId));
        }
      }),
    );
  };
};
