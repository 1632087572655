import { forwardRef, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import InfoTooltip from '@/components/common/InfoTooltip';
import { INVALID_CAC_PAYBACK_TOOLTIP_TEXT } from '@/constants/charts';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import { isEmptyOrNull } from '@/helpers/validators';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import CacPaybackChart from '@/pages/Dashboard/charts/CacPaybackChart';
import { getLabeledMetric, getMetricValue } from '@/pages/Dashboard/helpers';

const CacPaybackGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [cacPayback, setCacPayback] = useState({});
    const { startDate, endDate } = useSelector(({ shared }) => shared);
    const onQueryStateChange = useCallback(({ data, isLoading }) => {
      setCacPayback({
        isLoading,
        currentMonth: data?.currentMonth,
        currentMonthValue: data?.currentMonthValue,
      });
    }, []);

    return (
      <ChartWidget
        ref={ref}
        title="CAC Payback"
        data-testid="cacPaybackWidget"
        metricValue={
          cacPayback.isLoading
            ? ''
            : getLabeledMetric(getMetricValue(cacPayback.currentMonthValue))
        }
        label={formatRangeWithShortYear(startDate, endDate)}
        url="/dashboard/cac-payback"
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={formatRangeWithShortYear(startDate, endDate)}
              value={
                cacPayback.isLoading
                  ? ''
                  : getLabeledMetric(
                      getMetricValue(cacPayback.currentMonthValue),
                    )
              }
              tooltip={
                !cacPayback.isLoading &&
                isEmptyOrNull(cacPayback.currentMonthValue) && (
                  <InfoTooltip data-testid="cac-payback-tooltip">
                    {INVALID_CAC_PAYBACK_TOOLTIP_TEXT}
                  </InfoTooltip>
                )
              }
            />
            <CacPaybackChart
              {...childProps}
              onQueryStateChange={onQueryStateChange}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default CacPaybackGlance;
