/**
 * Shared selector to get the selected company id
 *
 * @type {(
 *   state: import('../getSelectedCompany').CompanyStateTree,
 * ) => number | null}
 */
const getSelectedCompanyId = (state) => {
  return state.companies.selectedCompanyId;
};

export default getSelectedCompanyId;
