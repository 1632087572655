/**
 * Type Guard To Determine if an error is an instance of an Apollo Network Error
 *
 * @type {import('../types/type-guards').isServerError}
 */
const isServerError = (error) => {
  return (
    error != null &&
    typeof error === 'object' &&
    Reflect.has(
      /** @type {import('@apollo/client/errors').ServerError} */ error,
      'statusCode',
    )
  );
};

export default isServerError;
