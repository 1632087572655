import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import RevenueEmptyIcon from '@bill/cashflow.assets/revenue-empty';
import {
  getTotalRevenueAction,
  setExternalForTotalRevenueAction,
} from '@/actions/revenue';
import RevenueTitleBar from '@/components/Revenue/List/RevenueTitleBar';
import Checkbox from '@/components/common/Checkbox';
import ContextMenu from '@/components/common/ContextMenu';
import EmptyData from '@/components/common/EmptyData';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import { units } from '@/constants/employees';
import {
  ACCOUNTING_INTEGRATIONS,
  REVENUE_STRIPE,
} from '@/constants/integrations';
import {
  getRevenueTableTitles,
  NON_CURRENCY_FIELDS,
} from '@/constants/revenue';
import useCallbackRef from '@/hooks/useCallbackRef';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import useSelectedScenarios from '@/hooks/useSelectedScenarios';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import { GRID_OPTIONS } from '@/pages/Reports/constants';
import './TotalRevenuesTable.scss';

const TOTAL_REVENUE = 'totalRevenue';
const REVENUE_TABLE_HIGHLIGHTED_ROWS = [TOTAL_REVENUE, 'subtotalRevenue'];
const SPREADSHEET_ID = 'totalRevenueGrid';

const ROW_CLASS_RULES = {
  'Spreadsheet_Row-total': ({ data: rowData }) =>
    REVENUE_TABLE_HIGHLIGHTED_ROWS.includes(rowData.id),
};

const formatData = (streamTotal, isStripeConnected) => {
  const tableTitles = getRevenueTableTitles(isStripeConnected);
  return tableTitles
    .filter(
      (metric) =>
        !['oneTimePurchases', 'oneTimeRevenue'].includes(metric.key) &&
        streamTotal[metric.key],
    )
    .map((metric) => {
      return {
        id: metric.key,
        name: metric.value,
        isEditable: false,
        months: streamTotal[metric.key],
        hierarchy: [metric.key],
        unit: NON_CURRENCY_FIELDS.includes(metric.key)
          ? units.NUMBER
          : units.CURRENCY,
      };
    });
};

const ContextMenuRenderer = ({ isExternal, handleSetExternal }) => {
  return (
    <ContextMenu data-testid="external-revenue-action" isHiddenOnClick={false}>
      <div className="ExternalRevenueAction">
        <Checkbox
          id="external-toggle-checkbox"
          className="ExternalRevenueAction_Checkbox"
          name="isExternal"
          checked={isExternal}
          onChange={() => handleSetExternal()}
        >
          Do you want to use the total revenue value from your accounting
          platform?
        </Checkbox>
        <div className="ExternalRevenueAction_Description">
          If you choose to display the total revenue value from your accounting
          platform, this value will be displayed here and in the revenue
          actuals.
        </div>
      </div>
    </ContextMenu>
  );
};

const TotalRevenuesTable = () => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const [gridApi, ref] = useCallbackRef();
  const startDate = useSelector(({ shared }) => shared.startDate);
  const endDate = useSelector(({ shared }) => shared.endDate);
  const revenueStreamTotal = useSelector(
    ({ revenues }) => revenues.revenueStreamTotal,
  );

  const [selectedScenario] = useSelectedScenarios();
  const [scenarioId] = useSelectedScenarioIds();
  const hasWritePermission = useNonDashboardWritePermission();

  // Added to make the UI more responsive, though not strictly necessary
  const [isExternal, setExternal] = useState(
    selectedScenario?.useExternalsForTotalRevenue,
  );

  const [isStripeConnected, setIsStripeConnected] = useState(false);

  useEffect(() => {
    setIsStripeConnected(
      selectedScenario?.enabledIntegrations.includes(REVENUE_STRIPE),
    );
  }, [selectedScenario]);

  useEffect(() => {
    setExternal(selectedScenario?.useExternalsForTotalRevenue);
  }, [selectedScenario]);

  useEffect(() => {
    dispatch(getTotalRevenueAction(startDate, endDate, scenarioId));
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [startDate, endDate, scenarioId]);

  const data = useMemo(
    () =>
      revenueStreamTotal && formatData(revenueStreamTotal, isStripeConnected),
    [revenueStreamTotal, isStripeConnected],
  );

  const hasData = Boolean(data?.length);

  const isActionColumnVisible =
    hasWritePermission &&
    Boolean(
      selectedScenario?.enabledIntegrations.find((integration) =>
        ACCOUNTING_INTEGRATIONS.includes(integration),
      ),
    );

  const handleSetExternal = async () => {
    const value = !isExternal;
    setExternal(value);
    dispatch(setExternalForTotalRevenueAction(value));
  };

  const colDefs = useMemo(() => {
    const colDef = [
      {
        field: 'name',
        headerName: 'Total Revenue',
        headerComponent: HeaderRenderer,
        cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: { suppressCount: true },
        minWidth: 200,
        sortable: false,
      },
    ];
    if (isActionColumnVisible) {
      colDef.push({
        colId: 'actions',
        type: 'actions',
        cellRendererSelector: ({ data: rowData }) => {
          if (!rowData.months) return undefined;
          if (rowData.id === TOTAL_REVENUE)
            return {
              component: ContextMenuRenderer,
              params: {
                isExternal,
                handleSetExternal,
              },
            };
          return this;
        },
      });
    }
    return colDef;
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [revenueStreamTotal, isExternal]);

  return (
    <div className="RevenueTable">
      <RevenueTitleBar heading="Total Revenue" gridApi={gridApi} />
      {hasData ? (
        <MonthlySpreadsheet
          {...GRID_OPTIONS}
          ref={ref}
          rowClassRules={ROW_CLASS_RULES}
          columnDefs={colDefs}
          showFilter={false}
          data-testid={SPREADSHEET_ID}
          data={data}
        />
      ) : (
        <EmptyData Icon={RevenueEmptyIcon}>
          Add Product & Revenue Streams (Drivers) for this grid to populate.
        </EmptyData>
      )}
    </div>
  );
};

export default TotalRevenuesTable;
