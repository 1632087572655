import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import '@bdc-libs/trinity.scoped-custom-elements';
import '@bill/cashflow.typography/sohne/index.css';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { init as fsInit } from '@fullstory/browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LicenseManager } from 'ag-grid-enterprise';
import 'focus-visible/src/focus-visible';
import { PersistGate } from 'redux-persist/integration/react';
import ApolloClientProvider from '@/contexts/ApolloClientProvider';
import { BillSessionProvider } from '@/contexts/BillSessionProvider';
import { FFaaSProvider } from '@/contexts/FFaaSProvider';
import WebTrackingProvider from '@/contexts/WebTrackingProvider';
import isBillDomain from '@/helpers/isBillDomain';
import { DD_ENV, REACT_APP_ENABLE_ANALYTICS } from '@/runtimeConfig';
import App from './App';
import { store, persistor } from './store';

/** @type {import('@datadog/browser-core').InitConfiguration} */
const sharedDDConfig = {
  clientToken: process.env.REACT_APP_DD_TOKEN,
  site: 'datadoghq.com',
  service: 'finmark-frontend',
  env: DD_ENV,
  trackSessionAcrossSubdomains: true,
  version: process.env.VERSION,
  allowedTracingUrls: [
    {
      match: /https:\/\/.+\.(bill|cashview|finmark)\.com/,
      propagatorTypes: ['datadog'],
    },
  ],
};

datadogLogs.init({
  ...sharedDDConfig,
  forwardConsoleLogs: 'all',
  forwardErrorsToLogs: true,
  silentMultipleInit: false,
});

// sets the logs to only reproduce to the console
// prevents it from being sent in environments where REACT_APP_ENABLE_ANALYTICS isn't set
// and won't require scaffolding a wrapper around logging
datadogLogs.logger.setHandler('console');

if (REACT_APP_ENABLE_ANALYTICS === 'true') {
  datadogLogs.logger.setHandler(['console', 'http']);
  datadogRum.init({
    ...sharedDDConfig,
    applicationId: process.env.REACT_APP_DD_ID,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();

  if (isBillDomain()) {
    fsInit({
      orgId: process.env.REACT_APP_FS_ORG_ID,
      devMode: process.env.NODE_ENV !== 'production',
    });
  }
}

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY);

const queryClient = new QueryClient();

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <WebTrackingProvider>
        <ApolloClientProvider>
          <BillSessionProvider>
            <FFaaSProvider>
              <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                  <PersistGate loading={null} persistor={persistor}>
                    <App />
                  </PersistGate>
                </BrowserRouter>
              </QueryClientProvider>
            </FFaaSProvider>
          </BillSessionProvider>
        </ApolloClientProvider>
      </WebTrackingProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);
