import { forwardRef, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import ChartRadioGroupPersistent from '@/components/common/ChartRadioGroupPersistent';
import InfoTooltip from '@/components/common/InfoTooltip';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import useChartPreferences from '@/hooks/useChartPreferences';
import Widget from './Widget';
import WidgetKPI from './WidgetKPI';
import LogoChurnChart, { LOGO_CHURN_TOGGLE_ID } from './charts/LogoChurnChart';
import './DashboardLanding.scss';

const LogoChurnGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [logoChurn, setLogoChurn] = useState({});
    const { startDate, endDate } = useSelector(({ shared }) => shared);
    const showPercent = useChartPreferences(LOGO_CHURN_TOGGLE_ID);
    const onQueryStateChange = useCallback(
      ({ data: { mainMetric, tooltipText }, isLoading }) => {
        if (!isLoading) {
          setLogoChurn({
            mainMetric,
            tooltipText,
          });
        }
      },
      [],
    );

    return (
      <ChartWidget
        ref={ref}
        title="Logo Churn"
        data-testid="logoChurnWidget"
        url="/dashboard/logo-churn"
        metricValue={logoChurn.mainMetric}
        label={formatRangeWithShortYear(startDate, endDate)}
        controls={
          <ChartRadioGroupPersistent
            id={LOGO_CHURN_TOGGLE_ID}
            value={false}
            options={[
              {
                label: 'Customers',
                value: false,
              },
              {
                label: 'Percent',
                value: true,
              },
            ]}
            className="ControlDropdown_Child"
          />
        }
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={formatRangeWithShortYear(startDate, endDate)}
              value={logoChurn.mainMetric}
              tooltip={
                showPercent &&
                logoChurn.tooltipText && (
                  <InfoTooltip data-testid="logo-churn-kpi-tooltip">
                    {logoChurn.tooltipText}
                  </InfoTooltip>
                )
              }
            />
            <LogoChurnChart
              showPercent={showPercent}
              onQueryStateChange={onQueryStateChange}
              {...childProps}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default LogoChurnGlance;
