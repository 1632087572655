// @ts-check
import Button from '@/components/common/Button';
import Modal from '@/components/common/Modal';
import { getISODate } from '@/helpers/dateFormatter';
import './PermissionModal.scss';

/**
 * @typedef {{
 *   onSave: (params: {
 *     preference: import('@/services/reports.service').getChatGPTPromptPermissionResponse;
 *   }) => void;
 *   isLoading: boolean;
 *   open: boolean;
 *   onClose: () => void;
 * }} PermissionModalProps
 */

/** @type {(props: PermissionModalProps) => React.ReactElement} */
const PermissionModal = ({ onSave, isLoading, open, onClose }) => {
  const onPermissionAgreement = () => {
    const data = {
      preference: {
        chatGPTPromptPermissionGranted: true,
        chatGPTPromptPermissionDate: getISODate(Date.now()),
      },
    };
    onSave(data);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      data-testid="warning-permission-modal"
      className="PermissionModal"
    >
      <>
        <div className="PermissionModal_Content">
          <p className="header">
            By using this and other AI features in Finmark, you acknowledge that
            financial statement information will be sent to OpenAI's GPT for
            AI-generated responses and summaries. Please be aware of the
            following:
          </p>
          <ol className="list">
            <li className="list-item">
              Data Transmission: In order to provide accurate financial
              statement summaries and AI responses, certain financial data will
              be transmitted to OpenAl. This data may include, but is not
              limited to, balance sheets, income statements, and cash flow
              statements.
            </li>
            <li>
              Consent: By utilizing this feature, you consent to the
              transmission of your financial data to OpenAl and the processing
              of such data by their system.
            </li>
            <li>
              Accuracy and Liability: While efforts are made to ensure accuracy,
              Finmark and OpenAI do not guarantee the accuracy, completeness, or
              reliability of the financial summaries and AI-generated responses.
              You acknowledge that the summaries and AI-generated responses are
              for informational purposes only and should not be relied upon as a
              substitute for professional financial advice. Finmark and OpenAI
              shall not be liable for any losses, damages, or inaccuracies
              resulting from the use of the AI-generated financial summaries,
              responses, or analyses.
            </li>
          </ol>
          <p>
            By proceeding, you indicate that you have read, understood, and
            agreed to the terms outlined in this disclaimer. If you do not agree
            with any part of this disclaimer, please refrain from using this and
            other AI features in Finmark.
          </p>
        </div>
        <footer className="ModalBase_Footer">
          <Button
            className="Button ModalConfirmation_Button Button-cancelLink"
            onClick={onClose}
            data-testid="warning-permission-cancel-button"
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            onClick={onPermissionAgreement}
            data-testid="warning-permission-action-button"
          >
            Agree
          </Button>
        </footer>
      </>
    </Modal>
  );
};

export default PermissionModal;
