// @ts-check
import DateEditor from '@/components/common/Spreadsheet/editors/DateEditor';
import { isFirstCol } from '@/components/common/Spreadsheet/helpers';
import WithErrorRenderer from '@/components/common/Spreadsheet/renderers/WithErrorRenderer';
import COLORS from '@/constants/colorPalette';
import { DEFAULT_ITEMS_PER_PAGE } from '@/constants/dataMapping';
import { classNames, naturalSortComparator } from '@/helpers';
import {
  formatMonthDayYear,
  getISODate,
  getUTCDayTimestamp,
} from '@/helpers/dateFormatter';
import { isEmptyOrNull } from '@/helpers/validators';
import {
  EMPTY_CELL_VALUE,
  gridRowModels,
  HEADER_HEIGHT,
  ROW_HEIGHT,
} from './constants';
import FilterRenderer from './renderers/FilterRenderer';

export const excelCellClass = {
  BOLD: 'Spreadsheet_Cell-bold',
  ITALIC: 'Spreadsheet_Cell-italic',
  UNDERLINED: 'Spreadsheet_Cell-underlined',
  STRIKE_THROUGH: 'Spreadsheet_Cell-strikeThrough',
  BORDER_BOTTOM: 'Spreadsheet_Cell-borderBottom',
};
const LINE_STYLE_CONTINUOUS = 'Continuous';
const EXCEL_INDENT_STYLES = Array(6)
  .fill()
  .map((_, idx) => ({
    id: `Spreadsheet_Cell-indent${idx + 1}`,
    alignment: { indent: idx + 1 },
  }));

const EXCEL_FONT_STYLES = {
  color: COLORS.raisinBlack,
  fontName: 'Inter',
  size: 14,
};

const EXCEL_BORDER_STYLES = [
  'borderBottom',
  'borderLeft',
  'borderRight',
  'borderTop',
].reduce(
  (borders, side) => ({
    ...borders,
    [side]: {
      color: '#ccdaff',
      lineStyle: LINE_STYLE_CONTINUOUS,
      weight: 1,
    },
  }),
  {},
);

const onFilterChanged = ({ api }) => {
  // Expand all matched groups when filtering
  if (
    api.isColumnFilterPresent() &&
    api.getModel().getType() === gridRowModels.CLIENT_SIDE
  ) {
    api.forEachNodeAfterFilter((node) => {
      if (node.allChildrenCount) api.setRowNodeExpanded(node, true);
    });
  }
};

const dateFormatter = ({ value }) =>
  value ? formatMonthDayYear(value) : EMPTY_CELL_VALUE;

/**
 * @type {(
 *   params: import('ag-grid-community').PostSortRowsParams<any>,
 * ) => void}
 */
const handlePostSortRows = (params) => {
  const { api, context, nodes } = params;
  if (!context.lastFocusedCellOnEditingStarted?.dataId) return;

  const foundNode = nodes.find(
    (node) =>
      !node.group &&
      node.data.id === context.lastFocusedCellOnEditingStarted.dataId,
  );

  if (foundNode) {
    // We use setTimeout here to get around AG Grid asynchronously
    // setting rowIndex after the postSortRows event
    setTimeout(() => {
      if (isEmptyOrNull(foundNode.rowIndex)) return;
      api.setFocusedCell(
        foundNode.rowIndex,
        context.lastFocusedCellOnEditingStarted.column,
      );
      context.lastFocusedCellOnEditingStarted = {};
    }, 0);
  }
};

/**
 * Find the correct scenarioId, in ag-grid cell value and set the newValue
 *
 * @param {number} scenarioId
 * @param {Object[]} value
 * @param {Object | string} newValue
 * @returns {Object[]} Updated values
 */
export const setScenarioValue = (scenarioId, value, newValue) => {
  const scenarioEntry =
    typeof newValue === 'object' ? { ...newValue } : { value: newValue };
  scenarioEntry.scenarioId = scenarioId;
  const values = [...value];
  const scenarioIdx = values.findIndex((val) => val.scenarioId === scenarioId);
  if (scenarioIdx >= 0) {
    values[scenarioIdx] = {
      ...values[scenarioIdx],
      ...scenarioEntry,
    };
  } else {
    values.push(scenarioEntry);
  }
  return values;
};

export const GROUP_DISPLAY_TYPE = 'groupRows';

/**
 * Wrapper for all Spreadsheet editable params to allow them to be called
 * consistently as functions
 *
 * @param {import('ag-grid-community').EditableCallbackParams<any>} context
 *   ag-Grid context
 * @returns {boolean} Whether the cell is editable
 */
export const isEditable = (context) => {
  const { editable } = context.colDef;
  return typeof editable === 'function' ? editable(context) : editable;
};

/**
 * Default Excel export params for all Spreadsheets
 *
 * @type {import('ag-grid-community').ExcelExportParams}
 */
export const defaultExcelExportParams = {
  headerRowHeight: HEADER_HEIGHT,
  processCellCallback: ({ value }) =>
    typeof value === 'object' ? value?.value : value,
  rowHeight: ROW_HEIGHT,
  skipRowGroups: true,
  shouldRowBeSkipped: ({ node }) => node.data.isGroupPlaceholder,
};

/**
 * Returns the ag-Grid configuration for the Spreadsheet component
 *
 * @returns {import('ag-grid-community').GridOptions<any>}
 */
export default () => ({
  autoGroupColumnDef: {
    lockVisible: true,
  },
  context: {
    errors: {},
    loadingCells: {},
  },
  domLayout: 'autoHeight',
  enableRangeSelection: true,
  excludeChildrenWhenTreeDataFiltering: true,
  initialGroupOrderComparator: ({ nodeA, nodeB }) => {
    const aUnsaved = !!nodeA.data?.isUnsaved;
    const bUnsaved = !!nodeB.data?.isUnsaved;
    if (aUnsaved || bUnsaved) return Number(aUnsaved) - Number(bUnsaved);
    return naturalSortComparator(nodeA.key, nodeB.key);
  },
  suppressMultiRangeSelection: true,
  floatingFiltersHeight: 64,
  groupHeaderHeight: HEADER_HEIGHT,
  groupSelectsFiltered: true,
  headerHeight: HEADER_HEIGHT,
  columnTypes: {
    actions: {
      cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-action',
      editable: false,
      filter: false,
      pinned: 'right',
      resizable: false,
      sortable: false,
      suppressMovable: true,
      width: 50,
      minWidth: 50,
    },
    date: {
      cellEditor: DateEditor,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator(filterDate, value) {
          const filterMs = getUTCDayTimestamp(filterDate);
          const valueMs = new Date(value).getTime();
          if (valueMs < filterMs) return -1;
          if (valueMs > filterMs) return 1;
          return 0;
        },
      },
      valueFormatter: dateFormatter,
      valueSetter: ({ colDef, data, oldValue, newValue }) => {
        const newDate = newValue ? getISODate(newValue) : null;
        if (newDate !== oldValue) {
          // eslint-disable-next-line no-param-reassign -- predates description requirement
          data[colDef.field] = newValue ? getISODate(newValue) : null;
          return true;
        }
        return false;
      },
      minWidth: 105,
    },
    monthlyValue: {
      cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-numeric',
      comparator: (a, b) => {
        if (!a && !b) return 0;
        if (!a) return -1;
        if (!b) return 1;
        return typeof a === 'object' ? a.value - b.value : a - b;
      },
      filter: 'agNumberColumnFilter',
      filterValueGetter: (params) => {
        const { valueGetter } = params.colDef;
        if (typeof valueGetter !== 'function') return null;
        const value = valueGetter(params);
        return typeof value === 'object' ? value?.value : value;
      },
      headerClass: 'Spreadsheet_ColGroupChild',
      pinned: false,
      suppressMovable: true,
      valueGetter: ({ colDef, column, data }) => {
        if (!data?.months) return null;

        const { value } =
          data.months.find(
            (entry) =>
              (entry.date ?? entry.month) === column.getParent().getGroupId(),
          ) ?? {};
        return Array.isArray(value)
          ? value.find((val) => val.scenarioId === Number(colDef.field))?.value
          : value;
      },
      valueSetter: ({ colDef, column, data, newValue }) => {
        const month = column.getParent().getGroupId();
        const monthIdx = data.months.findIndex(
          (entry) => (entry.date ?? entry.month) === month,
        );
        const { value } = data.months[monthIdx] ?? {};
        const values = Array.isArray(value)
          ? setScenarioValue(Number(colDef.field), value, newValue)
          : { ...newValue };

        // eslint-disable-next-line no-param-reassign -- predates description requirement
        data.months[monthIdx] = {
          ...data.months[monthIdx],
          month,
          value: values,
        };

        return true;
      },
    },
    number: {
      cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-numeric',
      filter: 'agNumberColumnFilter',
    },
  },
  defaultColDef: {
    cellClass: (params) => {
      const classes = ['Spreadsheet_Cell'];
      let { parent } = params.node;
      let indent = 0;
      while (parent?.parent) {
        indent += 1;
        parent = parent.parent;
      }
      if (indent > 0 && isFirstCol(params))
        classes.push(`Spreadsheet_Cell-indent${indent}`);
      return classes;
    },
    cellClassRules: {
      'Spreadsheet_Cell-editable': isEditable,
      'Spreadsheet_Cell-invalid': ({ colDef, context, node, value, data }) =>
        value?.faulted ||
        data?.faulted ||
        !!context.errors[node.id]?.[colDef.field],
      'Spreadsheet_Cell-empty': ({ value }) => isEmptyOrNull(value),
    },
    cellRendererSelector: ({ colDef, context, node }) => {
      const error = context.errors[node.id]?.[colDef.field];
      if (!error) return undefined;

      return {
        component: WithErrorRenderer,
        params: { tooltip: error },
      };
    },
    comparator: naturalSortComparator,
    filter: 'agTextColumnFilter',
    filterParams: {
      debounceMs: 100,
    },
    flex: 1,
    floatingFilter: true,
    floatingFilterComponent: FilterRenderer,
    headerCheckboxSelectionFilteredOnly: true,
    /**
     * @type {(params: {
     *   colDef: import('ag-grid-community').ColDef<any>;
     * }) => string}
     */
    headerClass: ({ colDef }) =>
      classNames(
        'Table_ColHead',
        !colDef.suppressMovable && 'Table_ColHead-movable',
      ),
    lockPinned: true,
    minWidth: 100,
    resizable: true,
    // ag-Grid's default drag placeholder is not very flexible;
    // we'll make our own.
    rowDragText: () => '',
    sortable: true,
  },
  excelStyles: [
    {
      id: 'header',
      alignment: {
        vertical: 'Center',
      },
      borders: EXCEL_BORDER_STYLES,
      font: {
        bold: true,
        ...EXCEL_FONT_STYLES,
      },
    },
    {
      id: 'cell',
      alignment: { vertical: 'Center', horizontal: 'Left' },
      borders: EXCEL_BORDER_STYLES,
      font: EXCEL_FONT_STYLES,
    },
    {
      id: excelCellClass.BOLD,
      font: {
        bold: true,
      },
    },
    {
      id: excelCellClass.ITALIC,
      font: {
        italic: true,
      },
    },
    {
      id: excelCellClass.UNDERLINED,
      font: {
        underline: 'Single',
      },
    },
    {
      id: excelCellClass.STRIKE_THROUGH,
      font: {
        strikeThrough: true,
      },
    },
    {
      id: excelCellClass.BORDER_BOTTOM,
      borders: {
        borderBottom: {
          color: 'black !important',
          lineStyle: LINE_STYLE_CONTINUOUS,
          weight: 2,
        },
      },
    },
    ...EXCEL_INDENT_STYLES,
  ],
  isFullWidthRow: ({ rowNode }) => rowNode.data?.isGroupPlaceholder,
  groupDefaultExpanded: 1,
  groupDisplayType: GROUP_DISPLAY_TYPE,
  groupRowRendererParams: {
    suppressCount: true,
  },
  onFilterChanged,
  processCellForClipboard: ({
    api,
    column,
    columnApi,
    context,
    node,
    value,
  }) => {
    const colDef = column.getColDef();
    const { type, valueFormatter } = colDef;
    // Don't format numbers so the paste target can recognize them
    if (type !== 'number' && typeof valueFormatter === 'function') {
      return valueFormatter({
        api,
        colDef,
        column,
        columnApi,
        context,
        data: node.data,
        node,
        value,
      });
    }
    return value;
  },
  postSortRows: handlePostSortRows,
  rowClass: 'Spreadsheet_Row',
  rowHeight: ROW_HEIGHT,
  suppressContextMenu: true,
  suppressMaintainUnsortedOrder: true,
  suppressRowClickSelection: true,
  paginationPageSize: DEFAULT_ITEMS_PER_PAGE,
});
