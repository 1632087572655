import { domAnimation, LazyMotion } from 'framer-motion';
import { classNames } from '@/helpers';
import isBillDomain from '@/helpers/isBillDomain';
import usePageMetadata from '@/hooks/usePageMetadata';
import AppRouter from './AppRouter';
import './assets/scss/app.scss';
import { createSegmentDispatcher } from '@bill/cashflow.web-tracking';
import { SEGMENT_WRITE_KEY } from '@/runtimeConfig';

import { useEffect } from 'react';

declare const process: {
  env: {
    VERSION: string;
  };
};

const trackingDispatcher = createSegmentDispatcher(
  window,
  SEGMENT_WRITE_KEY,
  'forecasting',
  process.env.VERSION,
);
function App() {
  useEffect(() => {
    async function initWebTrackingDispatcher() {
      await trackingDispatcher.init();
    }

    initWebTrackingDispatcher().catch((error: unknown) => console.error(error));
  }, []);

  usePageMetadata();
  return (
    <div className={classNames('App', isBillDomain() && 'App-forecasting')}>
      <LazyMotion features={domAnimation}>
        <AppRouter />
      </LazyMotion>
    </div>
  );
}

export default App;
