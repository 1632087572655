import { forwardRef, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import ChartRadioGroupPersistent from '@/components/common/ChartRadioGroupPersistent';
import InfoTooltip from '@/components/common/InfoTooltip';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import useChartPreferences from '@/hooks/useChartPreferences';
import Widget from './Widget';
import WidgetKPI from './WidgetKPI';
import TotalMrrChart, { TOTAL_MRR_TOGGLE_ID } from './charts/TotalMrrChart';
import './DashboardLanding.scss';

const TotalMrrGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [totalMrr, setTotalMrr] = useState({});
    const { startDate, endDate } = useSelector(({ shared }) => shared);
    const showPercentage = useChartPreferences(TOTAL_MRR_TOGGLE_ID);
    const onQueryStateChange = useCallback((data) => {
      const [{ isLoading, data: chartData }] = data;
      if (!isLoading) {
        setTotalMrr(chartData);
      }
    }, []);

    return (
      <ChartWidget
        ref={ref}
        title="Total MRR"
        data-testid="totalMRRWidget"
        url="/dashboard/total-mrr"
        metricValue={totalMrr.mainMetric}
        label={formatRangeWithShortYear(startDate, endDate)}
        controls={
          <ChartRadioGroupPersistent
            id={TOTAL_MRR_TOGGLE_ID}
            value={false}
            options={[
              {
                label: 'Currency',
                value: false,
              },
              {
                label: 'Percent',
                value: true,
              },
            ]}
            className="ControlDropdown_Child"
          />
        }
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={formatRangeWithShortYear(startDate, endDate)}
              value={totalMrr.mainMetric}
              tooltip={
                showPercentage &&
                totalMrr.tooltipText && (
                  <InfoTooltip data-testid="total-mrr-tooltip">
                    {totalMrr.tooltipText}
                  </InfoTooltip>
                )
              }
            />
            <TotalMrrChart
              onQueryStateChange={onQueryStateChange}
              showPercentage={showPercentage}
              {...childProps}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default TotalMrrGlance;
