// @ts-check
import { useState, useEffect, forwardRef, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import RoundCrossIcon from '@bill/cashflow.assets/round-cross';
import { hideNotificationAction } from '@/actions/notifications';
import {
  notificationIds,
  notificationSeverity,
} from '@/components/common/Notifications/constants';
import {
  MANAGEMENT_CONSOLE_PATH,
  SWITCH_COMPANIES_PATH,
  SETTINGS_PATH,
} from '@/constants/pages';
import { classNames } from '@/helpers';
import NotificationPendingField from './Notifications/NotificationPendingField';
import './TheNotificationBar.scss';

/**
 * @typedef {{
 *   notifications: Partial<
 *     import('@/components/common/Notifications/constants').NotificationType
 *   >;
 *   hideNotification: (
 *     notification: import('@/components/common/Notifications/constants').Notification,
 *   ) => void;
 *   style?: React.CSSProperties;
 * }} NotificationProps
 */

/**
 * The global notification bar
 *
 * @example
 *   <TheNotificationBar ref={ref} />;
 *
 * @type {React.ForwardRefExoticComponent<
 *   NotificationProps & React.RefAttributes<HTMLDivElement>
 * >}
 */
const TheNotificationBar = forwardRef(
  ({ notifications, hideNotification, style }, ref) => {
    /**
     * @type ReturnType<typeof
     *   useState<import('@/components/common/Notifications/constants').Notification>>
     */
    const [selectedNotification, setSelectedNotification] = useState();

    const selectedCompanyId = useSelector(
      (/** @type {import('@/store').RootState} */ { companies }) =>
        companies.selectedCompanyId,
    );

    const location = useLocation();

    const isOutsideCompayCtx = useMemo(
      () =>
        [SWITCH_COMPANIES_PATH, MANAGEMENT_CONSOLE_PATH].includes(
          location.pathname,
        ),
      [location.pathname],
    );

    const isNotificationVisible = useMemo(
      () =>
        (selectedNotification?.id === notificationIds.PENDING_COMPANY_ADDRESS &&
          !isOutsideCompayCtx) ||
        selectedNotification?.id === notificationIds.PENDING_USER_COUNTRY,
      [selectedNotification, isOutsideCompayCtx],
    );

    useEffect(() => {
      const notificationClone = { ...notifications };
      const [first] = Object.values(notificationClone)
        .filter((notification) => !notification.isDismissed)
        .sort((a, b) => b.severity - a.severity);
      setSelectedNotification(first);
    }, [notifications]);

    const handleClick = () => {
      hideNotification(selectedNotification);
    };

    return (
      <div
        ref={ref}
        data-testid="TheNotificationBar"
        className="TheNotificationBar"
        style={style}
      >
        {/* Always render the outer div so that the resize observer
        has something to reference. This is primarily used for making
        sure the main navigation gets positioned correctly when the
        notification is dismissed */}
        {isNotificationVisible && (
          <div
            className={classNames(
              'TheNotificationBar_Content',
              selectedNotification.severity >= notificationSeverity.DANGER &&
                'TheNotificationBar_Content-danger',
              selectedNotification.severity === notificationSeverity.WARNING &&
                'TheNotificationBar_Content-warning',
            )}
          >
            <span className="NotificationMsg_Wrapper">
              {selectedNotification.id ===
                notificationIds.PENDING_COMPANY_ADDRESS && (
                <NotificationPendingField
                  text='Complete your "missing company" information to ensure uninterrupted use of the product.'
                  linkPath={SETTINGS_PATH}
                />
              )}
              {selectedNotification.id ===
                notificationIds.PENDING_USER_COUNTRY && (
                <NotificationPendingField
                  text={`Please add your Country of Residence in ${
                    selectedCompanyId ? '"settings"' : 'settings'
                  } to avoid not being able to use Finmark.`}
                  linkPath={SETTINGS_PATH}
                />
              )}
            </span>
            <button
              className="NotificationCloseBtn"
              type="button"
              data-testid="notification-hide"
              onClick={handleClick}
              aria-label="Close"
            >
              <RoundCrossIcon />
            </button>
          </div>
        )}
      </div>
    );
  },
);

const mapStateToProps = ({ notifications }) => ({
  notifications: notifications.notifications,
});

export default connect(
  mapStateToProps,
  {
    hideNotification: hideNotificationAction,
  },
  undefined,
  { forwardRef: true },
)(TheNotificationBar);
