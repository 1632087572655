import { useState, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  employeesByDepartment as employeesByDepartmentAction,
  getEmployees as getEmployeesAction,
} from '@/actions/employees';
import PageHeader from '@/components/Layouts/PageHeader';
import AddMultipleModal from '@/components/common/AddMultipleModal';
import ButtonAdd from '@/components/common/ButtonAdd';
import { acceptedFileExtensionTypes } from '@/components/common/DocumentUploader/constants';
import DropdownOption from '@/components/common/DropdownOption';
import Modal from '@/components/common/Modal';
import Permissions from '@/components/common/Permissions';
import { DEPARTMENT_ID } from '@/components/common/Spreadsheet/constants';
import { INITIAL_STATE } from '@/constants/employees';
import { actions, subjects } from '@/constants/permissions';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import PayrollByDept from '@/pages/Dashboard/PayrollByDept';
import TotalPayrollExpanded from '@/pages/Dashboard/TotalPayrollExpanded';
import EditMultipleEmployees from '@/pages/Employee/EditMultipleEmployees/EditMultipleEmployees';
import HiringDrivers from '@/pages/Employee/HiringDrivers';
import EmployeesList from '@/pages/Employee/List';
import PayrollExpensesTable from '@/pages/Employee/PayrollExpensesTable';
import TheAddEditHiringDriverModal from '@/pages/Employee/TheAddEditHiringDriverModal';
import TheAddExpenseModal from '@/pages/Expenses/TheAddExpenseModal';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import { uploadMultipleEmployees } from '@/services/employee.service';

const { WRITE } = actions;
const { NON_DASHBOARD } = subjects;
const CHART_TITLE = 'Payroll';
const EMPLOYEE_TYPE = 'Employees';
const GOOGLE_DRIVE_TEMPLATE =
  'https://docs.google.com/spreadsheets/d/1RxrDqxQ5Iz2hOS5Aqasss9AAeJwB8zs85JlqOXBljOE/template/preview';

const CSV_TEMPLATE = '/api/v1/templates/employee_upload_template.csv';

const Employees = ({
  error,
  selectedCompany,
  employeesList,
  getEmployees,
  employeesByDepartment,
  startDate,
  endDate,
  scenarioId,
}) => {
  const employeesWritePermission = useNonDashboardWritePermission();

  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [showMultipleEmployeeModal, setShowMultipleEmployeeModal] =
    useState(false);
  const [showEditMultipleEmployeeModal, setEditMultipleEmployeeModal] =
    useState(false);
  const [showHiringDriverModal, setShowHiringDriverModal] = useState(false);
  const [showExpensesForm, setShowExpensesForm] = useState(false);
  const [currentExpenseRecord, setCurrentExpenseRecord] = useState();
  const [editedDriver, setEditedDriver] = useState(null);
  const [employeeQueue, setEmployeeQueue] = useState([]);
  const [cellFocus, setCellFocus] = useState(null);
  const [progressLoaded, setProgressLoaded] = useState(0);
  const [progressTotal, setProgressTotal] = useState(0);
  const employees = useMemo(() => {
    // When multiple employees are added simultaneously, we may receive updated
    // records in an earlier refetch for employees that are still in the update
    // queue.
    const dedupedUnsaved = employeeQueue.filter(({ id }) =>
      employeesList.every((emp) => emp.id !== id),
    );
    return [...structuredClone(employeesList), ...dedupedUnsaved];
  }, [employeesList, employeeQueue]);
  const onUploadProgress = ({ loaded, total }) => {
    setProgressLoaded(loaded);
    setProgressTotal(total);
  };
  const onAddEmployeeToGrid = () => {
    setEmployeeQueue((prevState) => [
      ...prevState,
      {
        ...INITIAL_STATE,
        loadMultiplier: selectedCompany?.loadMultiplier,
        isUnsaved: true,
        id: uuidv4(),
      },
    ]);
    setCellFocus(DEPARTMENT_ID);
  };

  const addDuplicatedEmployee = (duplicatedData) => {
    setEmployeeQueue((prevState) => [...prevState, duplicatedData]);
  };

  const removeUnsavedEmployees = (ids = []) => {
    setEmployeeQueue((prevState) => {
      return prevState.filter((employee) => !ids.includes(employee.id));
    });
  };

  const handleClose = () => {
    setCurrentExpenseRecord(undefined);
    setShowExpensesForm(false);
  };

  const handleCloseDriverModal = () => {
    setEditedDriver(null);
    setShowHiringDriverModal(false);
  };

  const handleMultipleEmployeesFinish = () => {
    getEmployees(scenarioId, startDate, endDate);
    employeesByDepartment(startDate, endDate, scenarioId);
    setShowMultipleEmployeeModal(false);
  };

  const handleMultipleEmployeesUpload = ({ file }) => {
    setProgressLoaded(0);
    setProgressTotal(0);
    return uploadMultipleEmployees({
      file,
      onUploadProgress,
      params: { scenarioId, updateFlag: false },
    });
  };

  return (
    <>
      <PageHeader
        page="/employees"
        addBtnSlot={
          <Permissions
            action={WRITE}
            subject={NON_DASHBOARD}
            scenarioPermissionRequired
          >
            <ButtonAdd id="add-employee-button" direction="right">
              <DropdownOption
                id="add-employee-by-modal-option"
                className="pendo-AddEmployee"
                onClick={() => setShowEmployeeModal(true)}
              >
                Add Employee
              </DropdownOption>
              <DropdownOption
                id="add-multiple-employee-option"
                className="pendo-AddEmployee"
                onClick={() => setShowMultipleEmployeeModal(true)}
              >
                Add Multiple Employees
              </DropdownOption>
              <DropdownOption
                id="edit-multiple-employee-option"
                className="pendo-AddEmployee"
                onClick={() => setEditMultipleEmployeeModal(true)}
              >
                Edit Multiple Employees
              </DropdownOption>
              <DropdownOption
                id="add-hiring-driver-option"
                className="pendo-AddHiringDriver"
                onClick={() => setShowHiringDriverModal(true)}
              >
                Add Hiring Driver
              </DropdownOption>
            </ButtonAdd>
          </Permissions>
        }
      />
      <div className="PageLayout">
        <Container fluid className="p-0">
          {error && (
            <div
              style={{
                background: '#FF9494',
                margin: '10px   0px',
                borderRadius: 5,
              }}
            >
              <p className="text-center m-0 text-white">{error}</p>
            </div>
          )}
          <TotalPayrollExpanded title={CHART_TITLE} />
          <PayrollByDept />
          <HiringDrivers
            onAddEdit={(driver) => {
              if (driver) {
                setEditedDriver(driver);
              }
              setShowHiringDriverModal(true);
            }}
          />
          <EmployeesList
            show={showEmployeeModal}
            setShow={setShowEmployeeModal}
            employeesWritePermission={employeesWritePermission}
            onAddEmployeeToGrid={onAddEmployeeToGrid}
            onDeleteUnsaved={removeUnsavedEmployees}
            onDuplicate={addDuplicatedEmployee}
            employees={employees}
            cellFocus={cellFocus}
            setCellFocus={setCellFocus}
          />
          <PayrollExpensesTable
            setCurrentExpenseRecord={setCurrentExpenseRecord}
            onEdit={setShowExpensesForm}
            hasWritePermission={employeesWritePermission}
          />
          {showMultipleEmployeeModal && (
            <AddMultipleModal
              onClose={() => setShowMultipleEmployeeModal(false)}
              onFinish={handleMultipleEmployeesFinish}
              onUpload={handleMultipleEmployeesUpload}
              type={EMPLOYEE_TYPE}
              fileExtensions={acceptedFileExtensionTypes.CSV}
              progressLoaded={progressLoaded}
              progressTotal={progressTotal}
            >
              <p>
                Unsure where to begin? Use our sample{' '}
                <a
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={GOOGLE_DRIVE_TEMPLATE}
                >
                  <strong>Google Sheet template</strong>
                </a>{' '}
                to enter employee information.{' '}
                <span>
                  Alternatively, download and fill out this information using
                  this{' '}
                  <a className="link" href={CSV_TEMPLATE} download="sample.csv">
                    <strong>sample spreadsheet</strong>
                  </a>
                  .
                </span>
              </p>
            </AddMultipleModal>
          )}
          {showEditMultipleEmployeeModal && (
            <EditMultipleEmployees onClose={setEditMultipleEmployeeModal} />
          )}
          {showExpensesForm && (
            <TheAddExpenseModal
              onClose={handleClose}
              currentRecord={currentExpenseRecord}
              isPayrollExpenseSection
            />
          )}
          <Modal
            open={showHiringDriverModal}
            onClose={handleCloseDriverModal}
            data-testid="add-edit-hiring-driver"
          >
            <TheAddEditHiringDriverModal
              onClose={handleCloseDriverModal}
              editRecord={editedDriver}
            />
          </Modal>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = ({ employees, companies, shared, scenario }) => {
  return {
    error: employees.error,
    employeesList: employees.employeesList,
    selectedCompany: getSelectedCompany({ companies }),
    startDate: shared.startDate,
    endDate: shared.endDate,
    scenarioId: scenario.scenarioId,
  };
};
export default connect(mapStateToProps, {
  employeesByDepartment: employeesByDepartmentAction,
  getEmployees: getEmployeesAction,
})(Employees);
