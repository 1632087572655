import { useState, useEffect } from 'react';
import { AnimatePresence, m as motion } from 'framer-motion';
import PropTypes from 'prop-types';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';
import './DayOfMonthPicker.scss';

const ANIM_CLOSED = { opacity: 0, y: 40 };
const ANIM_TRANSITION = window.Cypress
  ? { duration: 0 }
  : {
      type: 'spring',
      mass: 0.6,
    };

/**
 * Renders a modal overlay for selecting a relative day of the month
 *
 * @example
 *   <DayOfMonthPicker
 *     data-testid="foo"
 *     value={day}
 *     onCancel={() => setShowMonthPicker(false)}
 *     onSave={setDay}
 *   />;
 */
function DayOfMonthPicker({
  'data-testid': dataTestId,
  children,
  monthSize = 28,
  onCancel,
  onSave,
  open,
  value,
  clearActualsOnSyncSetting,
  loading,
}) {
  const [selectedDay, setSelectedDay] = useState(value);
  const [clearActualsOnSync, setClearActualsOnSync] = useState(
    clearActualsOnSyncSetting,
  );
  useEffect(() => {
    setSelectedDay(value);
  }, [value]);

  return (
    <AnimatePresence>
      {open && (
        <>
          <motion.div
            className="Modal"
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={ANIM_TRANSITION}
          />
          <motion.dialog
            key="picker"
            className="DayOfMonthPicker"
            animate={{ opacity: 1, y: 0 }}
            initial={ANIM_CLOSED}
            exit={ANIM_CLOSED}
            transition={ANIM_TRANSITION}
            data-testid={dataTestId}
          >
            <div className="DayOfMonthPicker_Days">
              {Array(monthSize)
                .fill(0)
                .map((_, idx) => {
                  const day = idx + 1;
                  return (
                    <button
                      key={day}
                      className="DayOfMonthPicker_Day"
                      aria-pressed={selectedDay === day}
                      onClick={() => setSelectedDay(day)}
                    >
                      {day}
                    </button>
                  );
                })}
            </div>
            <p className="DayOfMonthPicker_Copy">{children}</p>
            <div className="DayOfMonthPicker_ClearUserActuals">
              <Checkbox
                name="clear-user-actuals"
                id="clear-user-actuals-check"
                checked={clearActualsOnSync}
                onChange={({ target }) => setClearActualsOnSync(target.checked)}
              />
              <p className="DayOfMonthPicker_Copy DayOfMonthPicker_Copy-clearActuals">
                Replace user-entered data with external data, starting on the
                next sync date.
              </p>
            </div>
            <div className="DayOfMonthPicker_Buttons">
              <button
                className="Button Button-cancelLink"
                onClick={() => {
                  setSelectedDay(value);
                  setClearActualsOnSync(clearActualsOnSyncSetting);
                  onCancel();
                }}
              >
                Cancel
              </button>
              <Button
                data-testid="save-sync-date-button"
                className="Button-primaryLink"
                onClick={() => onSave(selectedDay, clearActualsOnSync)}
                loading={loading}
              >
                Save
              </Button>
            </div>
          </motion.dialog>
        </>
      )}
    </AnimatePresence>
  );
}

DayOfMonthPicker.propTypes = {
  /** Copy describing the purpose of the picker */
  'children': PropTypes.node.isRequired,
  /** Unique identifier for selecting the picker in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** The number of days to display */
  'monthSize': PropTypes.number.isRequired,
  /** Event handler for when Cancel is clicked */
  'onCancel': PropTypes.func.isRequired,
  /**
   * Event handler for when Save is clicked
   *
   * @param {number} day
   */
  'onSave': PropTypes.func.isRequired,
  /** Whether the picker is visible */
  'open': PropTypes.bool,
  /** Current selected day */
  'value': PropTypes.number,
  /** Current setting for clear user actuals */
  'clearActualsOnSyncSetting': PropTypes.bool,
  /** While true, disables the save button and displays a loading indicator */
  'loading': PropTypes.bool,
};

export default DayOfMonthPicker;
