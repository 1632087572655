import { createCachedSelector, FlatMapCache } from 're-reselect';

const getScenario = (scenarioId, data, { scenario }) =>
  scenario.scenarios.find((s) => s.scenarioId === scenarioId);

const getData = (scenarioId, data) => data;

/**
 * Augments the given data with additional properties for rendering a chart
 * series, such as the selected scenario color.
 *
 * @param {number} scenarioId ID of the scenario with which the data is
 *   associated
 * @param {Object | Object[]} data Data for populating the chart
 * @param {Object} state The Redux store
 * @returns {Object[]} Augmented data
 */
export default createCachedSelector(getScenario, getData, (scenario, data) => {
  if (!scenario) return Array.isArray(data) ? [] : { data: [] };

  if (!Array.isArray(data)) {
    return {
      ...data,
      scenario,
    };
  }

  return data.map((series) => {
    return {
      ...series,
      scenario,
    };
  });
})({
  keySelector: (scenarioId, data) => data,
  cacheObject: new FlatMapCache(),
});
