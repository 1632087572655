// @ts-check
import FormulaGuide from '@/components/common/FormulaGuide/index';
import { VARIABLES_PATH } from '@/constants/pages';
import {
  ONE_TIME,
  REVENUE_ONLY,
  SUBSCRIPTION,
} from '@/constants/revenueStream';

export const EMPLOYEE_SALARY = 'Employee Salary';
export const EXPENSE = 'Expense';
export const CHURN = 'Churn';
export const PRODUCT_PRICING = 'Product Pricing';
export const CUSTOM_VARIABLE = 'Custom Variable';
export const BALANCE_SHEET = 'Balance Sheet';
export const CASH_FLOW_STATEMENT = 'Cash Flow Statement';

export const formulaGuideKeys = /** @type {const} */ ({
  EMPLOYEE_SALARY,
  EXPENSE,
  CHURN,
  PRODUCT_PRICING,
  CUSTOM_VARIABLE,
  BALANCE_SHEET,
  CASH_FLOW_STATEMENT,
  ONE_TIME,
  REVENUE_ONLY,
  SUBSCRIPTION,
});

const commonFormula =
  '"(Expenses.Marketing.Total*1/10)+(Expenses.Marketing.Total[-1]*0.5/10)"';
const commonFormulaBreakdown = (
  <>
    <FormulaGuide.Operator value="(" />
    <FormulaGuide.Variable value="Expenses.Marketing.Total" />
    <FormulaGuide.Operator value="*" />
    <FormulaGuide.Static value="10" />
    <FormulaGuide.Operator value=")" />
    <FormulaGuide.Operator value="+" />
    <FormulaGuide.Operator value="(" />
    <FormulaGuide.Variable value="Expenses.Marketing.Total" />
    <FormulaGuide.Offset value="[-1]" />
    <FormulaGuide.Operator value="*" />
    <FormulaGuide.Static value="5" />
    <FormulaGuide.Operator value=")" />
  </>
);

/**
 * @typedef {{
 *   mainText: string;
 *   subText?: string;
 *   formula: string;
 *   formulaBreakdown: React.ReactElement;
 *   formulaDef?: React.ReactElement;
 *   additionalSection?: React.ReactElement;
 * }} GuideModalProps
 */

/** @typedef {formulaGuideKeys[number]} keyTypes */
/**
 * Returns the constants for the Formula Guide
 *
 * @type {(key: keyTypes) => GuideModalProps}
 */
const getGuideModalConstants = (key) => {
  switch (key) {
    case BALANCE_SHEET: {
      return {
        mainText:
          'Say you want to forecast Accounts Receivable. First, double click into\n' +
          '        the cell and select “Accounts Receivable”. The formula will\n' +
          '        automatically be updated to the following (NOTE: the variable name will\n' +
          '        change based on the Subsection Name that you have chosen, the Account\n' +
          '        Number for Accounts Receivable and the specific name you assign to\n' +
          '        Accounts Receivable.)',
        formula:
          '"Assets.CurrentAssets.10800AccountsReceivable.Total[-1] + Sales.Total -\n' +
          '        Cash.Collections.Total"',
        formulaBreakdown: (
          <>
            <FormulaGuide.Variable value="Assets.CurrentAssets.10800AccountsReceivable.Total" />
            <FormulaGuide.Offset value="[-1]" />
            <FormulaGuide.Operator value="+" />
            <FormulaGuide.Variable value="Sales.Total" />
            <FormulaGuide.Operator value="-" />
            <FormulaGuide.Variable value="Cash.Collections.Total" />
          </>
        ),
        formulaDef: (
          <>
            Assets.CurrentAssets.10800AccountsReceivable.Total = The System
            Variable Name for your Accounts Receivable
            <br />
            <br />
            Sales.Total = the total value of all items sold in the period
            (disregarding collections timing and revenue recognition)
            <br />
            <br /> Cash.Collections = the amount of cash the company received
            generated from sales
            <br />
            <br /> To see a full list of Finmark variables, visit the
            ‘Variables’ section, by clicking{' '}
            <a
              href={VARIABLES_PATH}
              target="_blank"
              rel="noreferrer"
              className="Modal_CopyLink"
              data-testid={`${key}-systemVarsLink`}
            >
              here
            </a>
            .
          </>
        ),
        additionalSection: (
          <>
            To learn more about special formulas that can be used to forecast
            Balance Sheet items, click{' '}
            <a
              href="https://help.finmark.com/en/articles/6830714-balance-sheet-and-cash-flow-statement#h_2d3c86da1a"
              target="_blank"
              rel="noreferrer"
              className="Modal_CopyLink"
              data-testid={`${key}-balanceSheetArticleLink`}
            >
              here
            </a>
          </>
        ),
      };
    }
    case CASH_FLOW_STATEMENT: {
      return {
        mainText:
          'Say you want to forecast changes in Accounts Payable. You can create\n' +
          '        this formula by using the formula below. (We recommend building you\n' +
          '        Balance Sheet forecast prior to your Cash Flow Statement forecast. NOTE:\n' +
          '        the variable name will change based on the Subsection Name that you have\n' +
          '        chosen and the specific name you assign to Accounts Payable.)',
        formula:
          '"Assets.CurrentAssets.12500AccountsPayable.Total -\n' +
          '        Assets.CurrentAssets.12500AccountsPayable.Total[-1]"',
        formulaBreakdown: (
          <>
            <FormulaGuide.Variable value="Assets.CurrentAssets.12500AccountsPayable.Total" />
            <FormulaGuide.Operator value="-" />
            <FormulaGuide.Variable value="Assets.CurrentAssets.12500AccountsPayable.Total" />
            <FormulaGuide.Offset value="[-1]" />
          </>
        ),
        formulaDef: (
          <>
            Assets.CurrentAssets.12500AccountsPayable.Total = The System
            Variable Name for your AccountsPayable
            <br />
            To see a full list of Finmark variables, visit the ‘Variables’
            section, by clicking{' '}
            <a
              href={VARIABLES_PATH}
              target="_blank"
              rel="noreferrer"
              className="Modal_CopyLink"
              data-testid={`${key}-systemVarsLink`}
            >
              here
            </a>
            .
          </>
        ),
      };
    }
    case CHURN: {
      return {
        mainText:
          'Say, for example, you want a custom variable called "ProductAChurnRate"\n' +
          '        and entered in values for churn starting at 5% and stepping it to 4% in\n' +
          "        Nov '21 and then 3% starting in Jan '22. You could create a custom\n" +
          '        variable in the variables page and link it here.',
        formula: '"Custom.ProductAChurnRate"',
        formulaBreakdown: (
          <FormulaGuide.Variable value="Custom.ProductAChurnRate" />
        ),
      };
    }
    case EXPENSE: {
      return {
        mainText:
          'Say you want to model set-up expenses for accounts as $100/ new customer\n' +
          '        added in the current month, and $50 per new customer added in the\n' +
          '        previous month. Your formula would look like this.',
        formula:
          '"(Customers.ProductName.New* 100) + (Customers.ProductName.New[-1] *\n' +
          '        50)"',
        formulaBreakdown: (
          <>
            <FormulaGuide.Operator value="(" />
            <FormulaGuide.Variable value="Customers.ProductName.New" />
            <FormulaGuide.Operator value="*" />
            <FormulaGuide.Static value="100" />
            <FormulaGuide.Operator value=")" />
            <FormulaGuide.Operator value="+" />
            <FormulaGuide.Operator value="(" />
            <FormulaGuide.Variable value="Customers.ProductName.New" />
            <FormulaGuide.Offset value="[-1]" />
            <FormulaGuide.Operator value="*" />
            <FormulaGuide.Static value="50" />
            <FormulaGuide.Operator value=")" />
          </>
        ),
      };
    }
    case EMPLOYEE_SALARY: {
      return {
        mainText:
          'Say you have a contractor that you pay $30/hr and expect them to work 60\n' +
          '        hours a month. Your formula should look like this.',
        subText:
          'You also have the ability to choose from many different variables and\n' +
          '        offset them. You can see an example and explanations below.',
        formula: '"30 * 60"',
        formulaBreakdown: (
          <>
            <FormulaGuide.Operator value="(" />
            <FormulaGuide.Variable value="Customers.New" />
            <FormulaGuide.Operator value="*" />
            <FormulaGuide.Static value="4" />
            <FormulaGuide.Operator value="*" />
            <FormulaGuide.Static value="30" />
            <FormulaGuide.Operator value=")" />
            <FormulaGuide.Operator value="+" />
            <FormulaGuide.Operator value="(" />
            <FormulaGuide.Variable value="Customers.New" />
            <FormulaGuide.Offset value="[-1]" />
            <FormulaGuide.Operator value="*" />
            <FormulaGuide.Static value="2" />
            <FormulaGuide.Operator value="*" />
            <FormulaGuide.Static value="30" />
            <FormulaGuide.Operator value=")" />
          </>
        ),
      };
    }
    case PRODUCT_PRICING: {
      return {
        mainText:
          'Say, for example, you want to create a custom variable called\n' +
          '        ProductAProPlan to hold the values for the prices of our Pro pricing\n' +
          '        plan within Product A. Our price was initially $50 per month, but we\n' +
          "        increased the price by 10% to $55 starting in Jan '22. You could create\n" +
          '        a custom variable in the variables page and link it here.',
        formula: '"Custom.ProductAProPlan"',
        formulaBreakdown: (
          <FormulaGuide.Variable value="Custom.ProductAProPlan" />
        ),
      };
    }
    case ONE_TIME: {
      return {
        mainText:
          'Say you want to model one-time purchases for a particular revenue stream\n' +
          '        (driver) as 1 purchase for every $10 marketing spend this month and 0.5\n' +
          '        new purchases for every $10 marketing spend in the previous month.',
        formula: commonFormula,
        formulaBreakdown: commonFormulaBreakdown,
      };
    }
    case REVENUE_ONLY: {
      return {
        mainText:
          'Say you want to model $10 generated for every $1 marketing spend this\n' +
          '        month and $5 generated for every $1 marketing spend the previous month.',
        formula: commonFormula,
        formulaBreakdown: commonFormulaBreakdown,
      };
    }
    case CUSTOM_VARIABLE:
    case SUBSCRIPTION: {
      return {
        mainText:
          'Say you want to model New Subscribers for a particular revenue stream\n' +
          '        (driver) as 1 new subscriber for every $10 marketing spend this month,\n' +
          '        and 0.5 new subscribers for every $10 marketing spend in the previous\n' +
          '        month. Your formula would look like this.',
        formula: commonFormula,
        formulaBreakdown: commonFormulaBreakdown,
      };
    }
    default: {
      throw new Error('Invalid formulaKey provided');
    }
  }
};

export default getGuideModalConstants;
