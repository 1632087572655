import FormulaValidationMessage from '@/components/common/FormulaValidationMessage';
import Link from '@/components/common/Link';
import { VARIABLES_PATH } from '@/constants/pages';
import { classNames } from '@/helpers';

/**
 * Renders a validation message for use with custom formula inputs with a link
 * to the custom variables section that will open in a new tab
 *
 * @example
 *   <FormulaValidationMessageWithLink message="VALID_FORMULA_MSG" />;
 */
const FormulaValidationMessageWithLink = ({ message }) => {
  return (
    <div className="FormulaField_InfoContainer">
      <FormulaValidationMessage message={message} />
      <Link
        to={VARIABLES_PATH}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames('FormulaField_VariablesLink', 'link')}
      >
        View Custom Variables
      </Link>
    </div>
  );
};

export default FormulaValidationMessageWithLink;
