// @ts-check
import { useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import BellIcon from '@bill/cashflow.assets/bell';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { subscribeToNotificationsUpdatesAction } from '@/actions/notifications';
import { NOTIFICATIONS } from '@/cacheKeys';
import WithPopover from '@/components/common/WithPopover';
import { actions, subjects } from '@/constants/permissions';
import { classNames } from '@/helpers';
import usePermissions from '@/hooks/usePermissions';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import useWsSubscription from '@/hooks/useWsSubscription';
import getNotifications from '@/services/notificationCenterService';
import NotificationCenterContent from './NotificationCenterContent';
import './NotificationCenter.scss';

const { READ } = actions;
const { NON_DASHBOARD } = subjects;

/**
 * @typedef {import('@/types/notificationCenter').ImportantNotification} ImportantNotification
 *
 *
 * @typedef {import('@/types/notificationCenter').NormalNotification} NormalNotification
 */

/**
 * @type {(
 *   props: (ImportantNotification | NormalNotification)[],
 * ) => [ImportantNotification[], NormalNotification[]]}
 */
export const sortNotificationsBySeverity = (notifications) => {
  const importantNotifications = [];
  const normalNotifications = [];
  notifications.forEach((notification) => {
    if (notification.severity === 1) {
      importantNotifications.push(notification);
    } else {
      normalNotifications.push(notification);
    }
  });
  return [importantNotifications, normalNotifications];
};

/**
 * Renders the NotificationCenter IconButton which when expanded shows the
 * notifications
 *
 * @example
 *   <NotificationCenter />;
 */
const NotificationCenter = () => {
  const [show, setShow] = useState(false);
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();

  const [scenarioId] = useSelectedScenarioIds();

  const nonDashboardPermissions = usePermissions(READ, NON_DASHBOARD, true);

  const queryClient = useQueryClient();
  const queryKey = [NOTIFICATIONS, scenarioId];
  const { data: notifications = [[], []], refetch } = useQuery(
    queryKey,
    async () => {
      const { data } = await getNotifications(scenarioId);
      return sortNotificationsBySeverity(data.data.notifications);
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: nonDashboardPermissions,
    },
  );
  const [importantNotifications = []] = notifications;

  useWsSubscription(() => {
    if (nonDashboardPermissions) {
      return dispatch(
        subscribeToNotificationsUpdatesAction(
          scenarioId,
          refetch,
          ({ deleted, upserted }) => {
            /** @type {[ImportantNotification[], NormalNotification[]]} */
            const data = queryClient.getQueryData(queryKey);
            /** @type {(ImportantNotification | NormalNotification)[]} */
            let allNotifications = data.flat();

            if (deleted.length) {
              const deleteRowIds = deleted.map((row) => row.id);
              allNotifications = allNotifications.filter(
                (row) => !deleteRowIds.includes(row.id),
              );
            }

            upserted?.forEach((newNotification) => {
              const notificationIndex = allNotifications.findIndex(
                (notification) => notification.id === newNotification.id,
              );
              if (notificationIndex > -1) {
                allNotifications[notificationIndex] = newNotification;
              } else {
                allNotifications.unshift(newNotification);
              }
            });

            queryClient.setQueryData(
              queryKey,
              sortNotificationsBySeverity(allNotifications),
            );
          },
        ),
      );
    }
    return null;
  }, [scenarioId]);

  return (
    <WithPopover
      className="Popover-toEdge Popover-mainLayout"
      visible={show}
      content={<NotificationCenterContent notifications={notifications} />}
      placement="bottom-end"
      data-testid="notification-center"
      onClose={() => setShow(false)}
    >
      <button
        data-testid="notification-button"
        type="button"
        className="ThePageHeader_Button ThePageHeader_Button-notificationBtn"
        aria-label="Notification Center"
        onClick={() => setShow(!show)}
      >
        <div className="NotificationBtn">
          <BellIcon className="NotificationBtn_Icon" aria-hidden="true" />
          {!show &&
            notifications.some(
              (notificationType) => !!notificationType.length,
            ) && (
              <span className="NotificationBtn_IndicatorBg">
                <span
                  className={classNames(
                    'NotificationBtn_Indicator',
                    !!importantNotifications.length &&
                      'NotificationBtn_Indicator-important',
                  )}
                />
              </span>
            )}
        </div>
      </button>
    </WithPopover>
  );
};
export default NotificationCenter;
