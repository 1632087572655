// @ts-check
import { useEffect, useMemo } from 'react';
import { messageTypes } from '@/components/Copilot/constants';
import Button from '@/components/common/Button';
import LetterIcon from '@/components/common/LetterIcon';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import useSelectedScenarios from '@/hooks/useSelectedScenarios';
import { ReactComponent as BillAvatar } from '@/assets/icons/svg/bill_avatar.svg';
import { ReactComponent as ThumbsDown } from '@/assets/icons/svg/thumbs_down.svg';
import { ReactComponent as ThumbsUp } from '@/assets/icons/svg/thumbs_up.svg';
import './ChatContainer.scss';

/** @typedef {import('@/types/services/backend').PromptFeedbackDto['rating']} FeedbackRating */

/** @typedef {(index: number, rating: FeedbackRating) => void} OnFeedbackFunc */

/**
 * @typedef {{
 *   chat: import('./CopilotSidebar').CopilotMessage[];
 *   onFeedback: OnFeedbackFunc;
 * }} ChatContentProps
 */

/**
 * @type {(
 *   scenario: import('@/types/services/backend').ScenarioInfoDto,
 * ) => React.ReactElement}
 */
const UserIcon = (scenario) => (
  <LetterIcon
    className="ChatBubble_RectangleLetter"
    data-testid="user-icon"
    color={scenario?.color}
    string={scenario?.name ?? ''}
  />
);

/** @type {() => React.ReactElement} */
const BillIcon = () => (
  <div className="ChatBubble_BillAvatar">
    <BillAvatar />
  </div>
);

/** @type {(props: ChatContentProps) => React.ReactElement} */
const ChatContainer = ({ chat, onFeedback }) => {
  const [scenario] = useSelectedScenarios();

  useEffect(() => {
    const lastMessage = chat.at(-1);
    lastMessage?.messageRef.current?.scrollIntoView();
  }, [chat]);

  return useMemo(
    () => (
      <>
        {chat.map((chatObject, index) => {
          const {
            messageType,
            message,
            isLoading,
            logTrailId,
            messageRef,
            isFeedbackProvided,
            isNewConversation,
          } = chatObject;

          const chatIcon =
            messageType === messageTypes.USER ? UserIcon(scenario) : BillIcon();

          return (
            <div
              data-testid={`chat-bubble-${logTrailId}`}
              key={logTrailId}
              className="ChatBubble"
              ref={messageRef}
            >
              <div className="ChatBubble_Icon">{chatIcon}</div>
              <span className="ChatBubble_Text">
                {isLoading ? <LoadingSpinner /> : message}
              </span>
              {!isLoading && (
                <div className="ChatBubble_Action">
                  {messageType === messageTypes.ASSISTANT &&
                    !isNewConversation &&
                    !isFeedbackProvided && (
                      <div className="ChatBubble_FeedbackOptions">
                        <Button
                          className="ChatBubble_Feedback ChatBubble_ThumbsUp"
                          onClick={() => onFeedback(index, 'VeryUseful')}
                        >
                          <ThumbsUp />
                        </Button>
                        <Button
                          className="ChatBubble_Feedback ChatBubble_ThumbsDown"
                          onClick={() => onFeedback(index, 'NotUseful')}
                        >
                          <ThumbsDown />
                        </Button>
                      </div>
                    )}
                </div>
              )}
            </div>
          );
        })}
      </>
    ),
    [chat, scenario, onFeedback],
  );
};

export default ChatContainer;
