// @ts-check
import {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Select from '@/components/common/Select';

/**
 * @typedef {{
 *   disabled?: boolean;
 *   id: string;
 *   name: string;
 * }} Option
 */

/**
 * @typedef {{
 *   DefaultOption?: typeof React.Component;
 *   getSelectedValueOnly: boolean;
 *   id: string;
 *   options: Option[];
 * } & import('ag-grid-community').ICellEditorParams} SelectEditorProps
 */

/**
 * A selector editor for use in ag-Grid
 *
 * @example
 *   columnDefs={[
 *    {
 *      ...
 *      cellEditorFramework: DropdownEditor,
 *      editable: true
 *    }
 *   ]}
 *
 * @type {React.ForwardRefRenderFunction<
 *   import('ag-grid-community').ICellEditor,
 *   SelectEditorProps
 * >}
 * @see https://www.ag-grid.com/react-grid/component-cell-editor/
 */
const SelectEditorFn = (
  {
    charPress,
    colDef,
    options,
    DefaultOption,
    value,
    id,
    formatValue,
    getSelectedValueOnly = true,
  },
  ref,
) => {
  /** @type {React.MutableRefObject<HTMLSelectElement>} */
  const input = useRef(null);
  const [selectedValue, setSelectedValue] = useState(value);

  const formattedValue = formatValue?.(value);
  const optionsWithValue = useMemo(() => {
    return formattedValue &&
      options.every(({ name }) => ![value, formattedValue].includes(name))
      ? [...options, { id: value, name: formattedValue, disabled: true }]
      : options;
  }, [options, formattedValue, value]);

  useImperativeHandle(ref, () => ({
    getValue: () => {
      if (getSelectedValueOnly) return selectedValue;

      return optionsWithValue.find((option) => option.id === selectedValue);
    },
    isCancelAfterEnd: () => value === selectedValue,
  }));

  useEffect(() => {
    const { current } = input;
    current.focus();

    if (charPress) {
      const option = options.find(({ name }) =>
        name.toLowerCase().startsWith(charPress.toLowerCase()),
      );
      if (option) setSelectedValue(option.id);
    }
  }, [charPress, options]);

  return (
    <Select
      ref={input}
      id={id}
      value={selectedValue}
      name={colDef.field}
      onChange={({ target: { value: newValue } }) => {
        setSelectedValue(newValue);
      }}
    >
      {DefaultOption && <DefaultOption />}
      {optionsWithValue &&
        optionsWithValue.map((opt) => (
          <option key={opt.id} value={opt.id} disabled={opt.disabled}>
            {opt.name}
          </option>
        ))}
    </Select>
  );
};

const SelectEditor = forwardRef(SelectEditorFn);

SelectEditor.propTypes = {
  /** The column definition, provided by ag-Grid */
  colDef: PropTypes.objectOf(PropTypes.any),
  /** Array of options for Select component */
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  /** Default option to be displayed */
  DefaultOption: PropTypes.any,
  /** Array of options for Select component */
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  /** A unique ID */
  id: PropTypes.string.isRequired,
  /** Function that return formatted value */
  formatValue: PropTypes.func,
  /** Only get the selected option's 'value', not the entire selected option */
  getSelectedValueOnly: PropTypes.bool,
};

export default SelectEditor;
