import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  getAllDepartments as getAllDepartmentsAction,
  addHiringDriverAction,
  updateHiringDriverAction,
} from '@/actions/employees';
import FormFoundation from '@/components/Employee/FormFoundation';
import Button from '@/components/common/Button';
import FormLabel from '@/components/common/FormLabel';
import FormRadio from '@/components/common/FormRadio';
import FormulaField from '@/components/common/FormulaField';
import FormulaValidationMessageWithLink from '@/components/common/FormulaValidationMessageWithLink';
import InfoTooltip from '@/components/common/InfoTooltip';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import {
  EMPLOYMENT_TYPE,
  SET_DEPARTMENT,
  SET_JOB_TITLE,
  SET_ROLE_TYPE,
  SET_SALARY,
  SET_START_DATE,
  SET_END_DATE,
  SET_FORECAST_METHOD,
  forecastMethods,
} from '@/constants/employees';
import VALID_FORMULA_MSG from '@/constants/formulas';
import { isNumber } from '@/helpers';
import { getISODate, getDateOffsetByYears } from '@/helpers/dateFormatter';

/* eslint-disable-next-line import/no-deprecated -- predates description requirement */
import { isDefined } from '@/helpers/validators';
import useViewOnlyMode from '@/hooks/useViewOnlyMode';
import validateCustomFormula from '@/services/formula.service';

const INITIAL_STATE = {
  annualSalary: '',
  departmentId: '',
  titleId: '',
  employmentType: EMPLOYMENT_TYPE.fte,
  hiringTriggerFormula: '',
  salaryFormula: '',
  salaryVariableId: null,
  startDate: getISODate(new Date()),
  endDate: getISODate(getDateOffsetByYears(new Date(), 2)),
  forecastMethod: forecastMethods.NEW_HEAD_COUNT,
};

const SET_HIRING_DRIVER = 'setHiringDriver';

const setHasInteractedWithForm = (state) => {
  return { ...state, hasInteractedWithForm: true };
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_DEPARTMENT:
      return setHasInteractedWithForm({ ...state, ...payload });
    case SET_JOB_TITLE:
      return setHasInteractedWithForm({ ...state, titleId: payload });
    case SET_ROLE_TYPE:
      return setHasInteractedWithForm({ ...state, employmentType: payload });
    case SET_SALARY:
      return setHasInteractedWithForm({ ...state, ...payload });
    case SET_START_DATE:
      return setHasInteractedWithForm({ ...state, startDate: payload });
    case SET_END_DATE:
      return setHasInteractedWithForm({ ...state, endDate: payload });
    case SET_HIRING_DRIVER:
      return setHasInteractedWithForm({
        ...state,
        hiringTriggerFormula: payload,
      });
    case SET_FORECAST_METHOD:
      return setHasInteractedWithForm({
        ...state,
        forecastMethod: payload,
      });
    default:
      return state;
  }
};

const validateForm = (formState) => {
  const {
    departmentId,
    titleId,
    annualSalary,
    salaryFormula,
    startDate,
    endDate,
    hiringTriggerFormula,
    isDriverFormulaValid,
    isSalaryValid,
  } = formState;
  const requiredFields = [
    departmentId,
    titleId,
    startDate,
    hiringTriggerFormula,
  ];
  /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
  const selectedSalary = isDefined(annualSalary) ? annualSalary : salaryFormula;
  switch (true) {
    /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
    case !isDefined(selectedSalary) ||
      (isNumber(selectedSalary) && selectedSalary < 0):
      return false;
    case endDate && startDate > endDate:
      return false;
    case !isDriverFormulaValid || !isSalaryValid:
      return false;
    default:
      /* eslint-disable-next-line import/no-deprecated -- predates description requirement */
      return requiredFields.every(isDefined);
  }
};

const TheAddEditHiringDriverModal = ({
  onClose,
  departments,
  scenarioId,
  companyId,
  selectedCompanyId,
  getAllDepartments,
  addHiringDriver,
  updateHiringDriver,
  editRecord,
}) => {
  const [state, dispatch] = useReducer(reducer, editRecord ?? INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  const [isDriverFormulaValid, setIsDriverFormulaValid] = useState(false);
  const [showFormulaWarning, setShowFormulaWarning] = useState(false);

  const [isSalaryValid, setIsSalaryValid] = useState(false);
  const isEditMode = !!editRecord;

  const formRef = useRef(null);
  const isViewOnly = useViewOnlyMode(formRef, {
    shouldDisableButtons: true,
  });

  const isFormValid =
    (isEditMode && !state.hasInteractedWithForm) ||
    validateForm({
      ...state,
      isDriverFormulaValid,
      isSalaryValid,
    });

  const handleAddEdit = async () => {
    setLoading(true);
    if (isEditMode) {
      // The BE is always setting annualSalary on a GET request but trying to
      // PUT with both annualSalary and salaryFormula will throw a 500
      const data = state.salaryVariableId
        ? { ...state, annualSalary: null }
        : { ...state };
      delete data.hasInteractedWithForm;
      await updateHiringDriver(editRecord.id, data, { scenarioId, companyId });
    } else await addHiringDriver(state, scenarioId, companyId);
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    // When a user opens the driver in "edit" mode, the formula is valid
    // because only valid formulas can be created in the first place and they
    // cannot become invalid between creation and "edit" mode
    setIsDriverFormulaValid(true);
  }, [isEditMode]);

  useEffect(() => {
    if (!selectedCompanyId) return;
    getAllDepartments(selectedCompanyId);
  }, [scenarioId, getAllDepartments, selectedCompanyId]);

  const formHeading = useMemo(() => {
    if (isViewOnly) {
      return 'Hiring Driver Details';
    }
    return `${isEditMode ? 'Edit' : 'Add'} Hiring Driver`;
  }, [isViewOnly, isEditMode]);

  return (
    <>
      <div className="Form" ref={formRef}>
        <div className="Form_Group">
          <h2 className="ModalBase_Heading">{formHeading}</h2>
        </div>
        <FormFoundation
          {...{
            isEditMode,
            state,
            dispatchFn: dispatch,
            departments,
            setIsSalaryValid,
          }}
        />
        <div className="Form_Group">
          <div className="CustomFormulaDriver_Label">
            <FormLabel
              htmlFor="hiring-driver-formula-field"
              text="Hiring Trigger"
            />
          </div>
          <div className="alert alert-primary">
            The formula entered here will be evaluated monthly to determine the
            number of new headcount. Each individual hiring driver has a{' '}
            <b>
              limit of 25 new employee records per month and 250 new employee
              records total
            </b>
            .
          </div>
          <FormulaField
            id="hiring-trigger-formula-field"
            value={state.hiringTriggerFormula}
            onChange={(value) => {
              dispatch({ type: SET_HIRING_DRIVER, payload: value });
            }}
            onBlur={async () => {
              const {
                data: { data },
              } = await validateCustomFormula(
                scenarioId,
                state.hiringTriggerFormula,
              );
              setValidationMsg(data.valid ? VALID_FORMULA_MSG : data.error);
              setIsDriverFormulaValid(data.valid);
            }}
            onFocus={() => {
              setValidationMsg('');
              setIsDriverFormulaValid(false);
            }}
          />
          <FormulaValidationMessageWithLink message={validationMsg} />
        </div>
        <div className="Form_Group">
          <p className="Label">
            Forecast Method
            <InfoTooltip
              className="HiringDriver_Tooltip"
              data-testid="forecast-method-tooltip"
            >
              New headcount is the number of new employees/contractors to be
              hired into this position. <br />
              <br />
              Total Headcount is the total number of employees/contractors in
              the position at the end of the period.
            </InfoTooltip>
          </p>
        </div>
        <div className="Form_Group">
          <FormRadio
            id="new-headcount-option"
            name="employee-forecast-method"
            label="New Headcount"
            checked={state.forecastMethod === forecastMethods.NEW_HEAD_COUNT}
            value={forecastMethods.NEW_HEAD_COUNT}
            onChange={({ target: { value } }) => {
              dispatch({ type: SET_FORECAST_METHOD, payload: value });
            }}
          />
          <FormRadio
            id="total-headcount-option"
            name="employee-forecast-method"
            label="Total Headcount"
            checked={state.forecastMethod === forecastMethods.TOTAL_HEAD_COUNT}
            value={forecastMethods.TOTAL_HEAD_COUNT}
            onChange={({ target: { value } }) => {
              dispatch({ type: SET_FORECAST_METHOD, payload: value });
            }}
          />
        </div>
      </div>
      <div className="ModalBase_Footer ModalBase_Footer-hiringDrivers">
        {!isViewOnly && (
          <Button
            onClick={onClose}
            className="Button-cancelLink"
            data-testid="add-revenue-plan-cancel"
          >
            Cancel
          </Button>
        )}
        {!isViewOnly ? (
          <Button
            onClick={() => {
              if (isNumber(state.hiringTriggerFormula)) {
                setShowFormulaWarning(true);
              } else handleAddEdit();
            }}
            data-testid="add-revenue-plan-save"
            disabled={!isFormValid}
            loading={loading}
          >
            {isEditMode ? 'Save' : 'Add'} Driver
          </Button>
        ) : (
          <Button onClick={onClose}>Close</Button>
        )}
      </div>
      {showFormulaWarning && (
        <ModalConfirmation
          id="modal-employee-confirm-formula"
          onCancel={() => setShowFormulaWarning(false)}
          onAction={() => handleAddEdit()}
          title="Before you continue."
          actionBtnTxt="Continue"
        >
          The hiring forecast is set to add new hires monthly. Are you sure you
          want to proceed? We recommend using a custom variable to specify the
          frequency of hiring.
        </ModalConfirmation>
      )}
    </>
  );
};

const mapStateToProps = ({ employees, scenario, companies }) => {
  return {
    departments: employees.departments,
    scenarioId: scenario.scenarioId,
    companyId: companies.selectedCompanyId,
  };
};

export default connect(mapStateToProps, {
  getAllDepartments: getAllDepartmentsAction,
  updateHiringDriver: updateHiringDriverAction,
  addHiringDriver: addHiringDriverAction,
})(TheAddEditHiringDriverModal);
