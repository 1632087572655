import { forwardRef, useCallback, useState } from 'react';
import { getEndDateMetric } from '@/components/Charts/helpers';
import { AWAITING_DATA } from '@/constants/charts';
import formatMonetary from '@/helpers/formatMonetary';
import useTypedSelector from '@/hooks/useTypedSelector';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import CashBalanceChart from '@/pages/Dashboard/charts/CashBalanceChart';

/**
 * @typedef {{
 *   children: React.ReactElement;
 *   ChartWidget?: React.ComponentType<import('@/types/dashboard').WidgetProps>;
 * }} CashBalanceGlanceProps
 */

/**
 * @typedef {{
 *   isLoading: boolean;
 *   totalCashBalance: number;
 * }} CashBalanceData
 */

/**
 * @type {React.ForwardRefExoticComponent<
 *   CashBalanceGlanceProps & React.RefAttributes<HTMLElement>
 * >}
 */

const CashBalanceGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const timePeriod = useTypedSelector(({ shared }) => shared.timePeriod);

    const [cashBalance, setCashBalance] = useState({
      isLoading: true,
      previousPeriodTotal: 0,
      endDate: '',
    });

    const onQueryStateChange = useCallback(
      (data) => {
        const [
          {
            isLoading,
            data: { previousPeriodTotal, previousPeriodLabel },
          },
        ] = data;

        setCashBalance({
          previousPeriodTotal,
          endDate: getEndDateMetric(timePeriod, previousPeriodLabel),
          isLoading,
        });
      },
      [timePeriod],
    );

    return (
      <ChartWidget
        ref={ref}
        title="Cash Balance"
        url="/dashboard/cash-balance"
        data-testid="cahBalanceWidget"
        metricValue={formatMonetary(cashBalance.previousPeriodTotal)}
        label={cashBalance.endDate}
        tooltipContent="The amount of cash available in your account(s) at the end of each selected period"
        isLoading={cashBalance.isLoading}
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={!cashBalance.isLoading ? cashBalance.endDate : ''}
              value={
                cashBalance.isLoading
                  ? AWAITING_DATA
                  : formatMonetary(cashBalance.previousPeriodTotal)
              }
            />
            <CashBalanceChart
              onQueryStateChange={onQueryStateChange}
              {...childProps}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default CashBalanceGlance;
