import PropTypes from 'prop-types';
import ProgressIndicator from '@/components/common/ProgressIndicator';
import { classNames, childrenOf } from '@/helpers';
import UploadProgressText from './UploadProgressText';
import './UploaderProgress.scss';

/**
 * A component for reporting the progress of an uploaded file
 *
 * @example
 *   <UploadProgress
 *     id={id}
 *     className={className}
 *     progress={progress}
 *     progressText={progressText}
 *   >
 *     <UploadProgressText
 *       progress={progress}
 *       onCancel={onCancel}
 *       error={error}
 *       id="upload-body"
 *     />
 *   </UploadProgress>;
 */
const UploadProgress = ({
  id,
  className,
  'data-testid': dataTestId,
  progress,
  children,
  remainingTime,
  error,
}) => {
  let progressIndicatorText;

  if (error) {
    progressIndicatorText = 'complete';
  } else if (progress === 100) {
    progressIndicatorText = 'uploaded successfully';
  } else {
    const unit = remainingTime / 60 > 1 ? 'minute' : 'second';
    progressIndicatorText = `${Math.ceil(remainingTime) ?? 'unknown'} ${
      remainingTime === 1 ? unit : `${unit}s`
    } remaining`;
  }
  return (
    <div
      id={id}
      className={classNames('Upload_Container', className)}
      data-testid={dataTestId ?? id}
    >
      {children}
      <p>
        {progress} % - {progressIndicatorText}
      </p>
      <ProgressIndicator id="upload-progress" progress={progress} />
    </div>
  );
};

UploadProgress.propTypes = {
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string,
  /** Unique ID for the element */
  'id': PropTypes.string.isRequired,
  /** Class(es) to apply to the element */
  'className': PropTypes.string,
  /** The progress of an upload expressed in a number from 0 to 100 */
  'progress': PropTypes.number.isRequired,
  /** The content to display within the component */
  'children': childrenOf([UploadProgressText]),
  /** The number of seconds remaining until upload is complete */
  'remainingTime': PropTypes.number.isRequired,
  /** An error object from the API */
  'error': PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
};

export default UploadProgress;
