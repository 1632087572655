// @ts-check
import { useCallback, useEffect, useMemo, useReducer } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { refreshToken } from '@/actions/auth';
import CountryField from '@/components/Settings/Address/CountryField';
import CardWrapper from '@/components/Settings/CardWrapper';
import Footer from '@/components/Settings/Footer';
import Header from '@/components/Settings/Header';
import ErrorMessages from '@/components/common/ErrorMessages';
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';
import useEffectOnUpdate from '@/hooks/useEffectOnUpdate';

export const READ = /** @type {const} */ ('read');
export const EDIT = /** @type {const} */ ('edit');

export const actionTypes = /** @type {const} */ ({
  SET_FIRST_NAME: 'SET_FIRST_NAME',
  SET_LAST_NAME: 'SET_LAST_NAME',
  SET_EMAIL: 'SET_EMAIL',
  SET_COUNTRY: 'SET_COUNTRY',
  SET_STATE: 'SET_STATE',
  SET_MODE: 'SET_MODE',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
});
/** @typedef {actionTypes} ActionTypes */

const {
  SET_COUNTRY,
  SET_EMAIL,
  SET_ERROR_MESSAGE,
  SET_FIRST_NAME,
  SET_LAST_NAME,
  SET_MODE,
  SET_STATE,
} = actionTypes;

/**
 * @typedef {{
 *   mode: READ | EDIT;
 *   firstName: string;
 *   lastName: string;
 *   email: string;
 *   country: string;
 *   errorMessage: string | null;
 * }} PersonalDetailState
 */

const INITIAL_STATE = /** @type {PersonalDetailState} */ ({
  mode: READ,
  firstName: '',
  lastName: '',
  email: '',
  country: '',
  errorMessage: null,
});
/**
 * @typedef {{
 *       type:
 *         | ActionTypes['SET_FIRST_NAME']
 *         | ActionTypes['SET_LAST_NAME']
 *         | ActionTypes['SET_EMAIL']
 *         | ActionTypes['SET_COUNTRY']
 *         | ActionTypes['SET_ERROR_MESSAGE'];
 *       payload: string;
 *     }
 *   | { type: ActionTypes['SET_STATE']; payload: PersonalDetailState }
 *   | { type: ActionTypes['SET_MODE']; payload: READ | EDIT }} PersonalDetailDispatchType
 */

/**
 * @type {(
 *   state: PersonalDetailState,
 *   action: PersonalDetailDispatchType,
 * ) => PersonalDetailState}
 */
const reducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case SET_FIRST_NAME:
      return { ...state, firstName: action.payload };
    case SET_LAST_NAME:
      return { ...state, lastName: action.payload };
    case SET_EMAIL:
      return { ...state, email: action.payload };
    case SET_COUNTRY:
      return { ...state, country: action.payload };
    case SET_STATE:
      return { ...state, ...action.payload };
    case SET_MODE:
      return { ...state, mode: action.payload };
    case SET_ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

/**
 * @typedef {{
 *   userFirstName: string;
 *   userLastName: string;
 *   userEmailAddress: string;
 *   userCountry: string;
 *   updateUserAction: (params: {
 *     firstName: string;
 *     lastName: string;
 *     country?: string;
 *   }) => {};
 *   error: string | null;
 *   loading: boolean;
 * }} PersonalDetailsParams
 * @type {(params: PersonalDetailsParams) => React.ReactElement}
 */

const PersonalDetails = ({
  userFirstName,
  userLastName,
  userEmailAddress,
  userCountry,
  updateUserAction,
  error,
  loading,
}) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();

  const [
    { mode, firstName, lastName, email, country, errorMessage },
    setPersonalDetail,
  ] = useReducer(reducer, INITIAL_STATE);

  const setUserPersonalDetail = useCallback(
    () =>
      setPersonalDetail({
        type: SET_STATE,
        payload: {
          mode: READ,
          firstName: userFirstName,
          lastName: userLastName,
          email: userEmailAddress,
          country: userCountry,
          errorMessage: error ?? null,
        },
      }),
    [userFirstName, userLastName, userEmailAddress, userCountry, error],
  );

  useEffect(() => {
    setUserPersonalDetail();
  }, [setUserPersonalDetail]);

  useEffectOnUpdate(() => {
    dispatch(refreshToken());
  }, [userFirstName, userLastName, userEmailAddress, userCountry]);

  useEffect(() => {
    if (error) {
      setPersonalDetail({ type: SET_ERROR_MESSAGE, payload: error });
    } else if (!loading) {
      setPersonalDetail({ type: SET_MODE, payload: READ });
    }
  }, [loading, error]);

  const isSaveBtnDisabled = useMemo(
    () => !firstName || !lastName || !email || !country || loading,
    [firstName, lastName, email, country, loading],
  );

  const handleOnSaveClick = async () => {
    setPersonalDetail({ type: SET_ERROR_MESSAGE, payload: null });
    updateUserAction({
      firstName,
      lastName,
      country,
    });
  };

  return (
    <CardWrapper>
      <Header
        mode={mode}
        setMode={
          /** @type {(param: READ | EDIT) => void} */ (val) =>
            setPersonalDetail({ type: SET_MODE, payload: val })
        }
        title="Personal Details"
        data-testid="personal-details-edit"
      />
      {errorMessage && <ErrorMessages success={null} error={errorMessage} />}
      <div className="Form PeronsalDetailForm">
        <div className="Form_Group Form_Group-halfWidth">
          <FormLabel text="First Name" />
          <FormField
            id="firstName"
            validate={() => !firstName && 'First Name is Required!'}
            name="firstName"
            value={firstName}
            onChange={({ target }) =>
              setPersonalDetail({
                type: SET_FIRST_NAME,
                payload: target.value,
              })
            }
            disabled={mode !== 'edit'}
          />
        </div>
        <div className="Form_Group Form_Group-halfWidth">
          <FormLabel text="Last Name" />
          <FormField
            id="lastName"
            validate={() => !lastName && 'Last Name is Required!'}
            name="lastName"
            value={lastName}
            onChange={({ target }) =>
              setPersonalDetail({
                type: SET_LAST_NAME,
                payload: target.value,
              })
            }
            disabled={mode !== 'edit'}
          />
        </div>
      </div>
      <hr />
      <div className="Form">
        <div className="Form_Group Form_Group-halfWidth">
          <FormLabel text="Email Address" />
          <FormField id="email" name="email" value={email} disabled />
        </div>
      </div>
      <hr />
      <div className="Form">
        <div className="Form_Group Form_Group-halfWidth">
          <CountryField
            label="Country of Residence"
            value={country}
            onChange={({ target }) =>
              setPersonalDetail({ type: SET_COUNTRY, payload: target.value })
            }
            disabled={mode !== 'edit'}
          />
        </div>
      </div>
      {mode === 'edit' && (
        <Footer
          onSaveClick={handleOnSaveClick}
          onCancelClick={setUserPersonalDetail}
          disabledSaveBtn={isSaveBtnDisabled}
          saveBtnTxt="Save Edit"
          loading={loading}
        />
      )}
    </CardWrapper>
  );
};

export default PersonalDetails;
