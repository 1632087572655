import { useMemo } from 'react';
import Button from '@/components/common/Button';
import { ONE_TIME, REVENUE_ONLY } from '@/constants/revenueStream';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import './RevenueStreamFooter.scss';

/**
 * Returns name of current step
 *
 * @param {number} step current step
 * @param {string} revenueType current type of revenue
 * @returns {string} name of step
 */
const getStepName = (step, revenueType) => {
  switch (true) {
    case step === 1:
      return 'Stream type';
    case step === 2 && revenueType === REVENUE_ONLY:
      return 'Revenue';
    case step === 2:
      return 'Pricing Plans';
    case step === 3 && revenueType === ONE_TIME:
      return 'Purchases';
    case step === 3:
      return 'Subscriptions';
    case step === 4:
      return 'Growth';
    default:
      throw new Error('unknown step');
  }
};

const RevenueStreamFooter = ({
  step,
  nextText,
  handleNext,
  disableNext = false,
  nextLoading,
  revenueStream,
  handleClose,
  setStep,
}) => {
  const { revenueType } = revenueStream;
  const hasWritePermission = useNonDashboardWritePermission();

  const onNextClick = () => {
    if (nextText && !hasWritePermission) {
      handleClose();
      return;
    }

    if (handleNext) {
      handleNext();
      return;
    }

    setStep(step + 1);
  };

  const saveButtonLabel = useMemo(() => {
    if (hasWritePermission) {
      return nextText || 'Next';
    }
    return nextText ? 'Close' : 'Next';
  }, [nextText, hasWritePermission]);

  return (
    <div>
      <hr />
      <div className="RevenueStreamFooter">
        <div>
          <p className="RevenueStreamFooter_StepInfo">
            Step {step} - {getStepName(step, revenueType)}
          </p>
        </div>
        <div className="RevenueStreamFooter_ButtonGroup">
          <>
            <Button
              className="Button Button-cancelLink"
              onClick={handleClose}
              data-testid="add-revenue-stream-cancel"
            >
              Cancel
            </Button>
            <Button
              disabled={disableNext}
              className="Button"
              onClick={onNextClick}
              loading={nextLoading}
              data-testid="add-revenue-stream-next"
            >
              {saveButtonLabel}
            </Button>
          </>
        </div>
      </div>
    </div>
  );
};

export default RevenueStreamFooter;
