import QuestionMarkIcon from '@bill/cashflow.assets/question-mark';

/**
 * @type {(props: {
 *   onLegendClick: React.MouseEventHandler<HTMLButtonElement>;
 * }) => React.ReactElement}
 */
const CashGridLegend = ({ onLegendClick }) => (
  <>
    <span
      id="SpreadsheetLegendLabel"
      className="SpreadsheetLegend_Label"
      data-testid="spreadsheet-legend-label"
    >
      Helpful Info:
    </span>
    <button
      type="button"
      className="Button-iconOnly"
      aria-labelledby="SpreadsheetLegendLabel"
      data-testid="spreadsheet-legend"
      onClick={onLegendClick}
    >
      <QuestionMarkIcon className="HelpIcon" aria-hidden="true" />
    </button>
  </>
);

export default CashGridLegend;
