import metricFormatters from '@/helpers/metricFormatters';

const RUNWAY_METRICS = [
  {
    key: 'runwayMonths',
    name: 'Runway',
    unit: 'Month',
    formatter: metricFormatters.months,
  },
  {
    key: 'totalExpenses',
    name: 'Cash Payments',
    unit: 'Currency',
  },
  {
    key: 'revenue',
    name: 'Cash Collections',
    unit: 'Currency',
  },
  {
    key: 'burnAmount',
    name: 'Burn Rate',
    unit: 'Currency',
  },
  {
    key: 'financing',
    name: 'Financing',
    unit: 'Currency',
    exclude: (data) => data.every((point) => !point?.financing),
  },
  {
    isMainMetric: true,
    key: 'y',
    name: 'Ending Cash Balance',
  },
];

export default RUNWAY_METRICS;
