import { useState, useRef } from 'react';
import FormulaIcon from '@bill/cashflow.assets/formula';
import PropTypes from 'prop-types';
import ResizeableTextarea from '@/components/common/ResizeableTextArea';
import WithVariableAutocomplete from '@/components/common/WithVariableAutocomplete';
import { PRESET_FORMULA_KEYS } from '@/constants/formulas';
import { classNames } from '@/helpers';
import './FormulaField.scss';

/**
 * A field for entering a custom formula that fetches its own options form
 * autocompleting variables
 *
 * @example
 *   <FormulaField
 *     id="foo"
 *     name="foo"
 *     data-testid="foo-testid"
 *     className="Foo_Formula"
 *     value="Expense.Marketing.Total * 1/10"
 *     onChange={(newValue) => doSomethingWith(newValue)}
 *   />;
 */
const FormulaField = ({
  id,
  name,
  className,
  'data-testid': dataTestId,
  value,
  onChange,
  onBlur,
  onFocus,
  presetFormulasId,
  isDisabled = false,
}) => {
  const textareaRef = useRef();
  const [isFocused, setIsFocused] = useState();

  return (
    <WithVariableAutocomplete
      inputRef={textareaRef}
      className="Autocomplete_Container-fullWidth"
      value={value}
      data-testid={`${id}-autocomplete`}
      onChange={onChange}
      presetFormulasId={presetFormulasId}
      onBlur={(event) => {
        setIsFocused(false);
        onBlur?.(event);
      }}
      onFocus={(event) => {
        setIsFocused(true);
        onFocus?.(event);
      }}
    >
      {({ inputRef }) => (
        <div
          data-testid="formula-prefix"
          className={classNames(
            'FormField',
            'FormField-formula',
            isFocused && 'FormField-fomulaFocused',
            className,
          )}
          aria-disabled={isDisabled}
        >
          <span className="FormField_Prefix" data-testid={`${id}-prefix`}>
            <FormulaIcon
              className={classNames(
                'FormulaPrefix_Icon',
                isDisabled && 'FormulaPrefix_Icon-disabled',
              )}
            />
          </span>
          {!isFocused && (
            <span
              className={classNames(
                'FormulaField_EqualSign',
                isDisabled && 'FormulaField_EqualSign-disabled',
              )}
            >
              =
            </span>
          )}
          <ResizeableTextarea
            ref={inputRef}
            id={id}
            name={name}
            value={value}
            data-testid={dataTestId || id}
            disabled={isDisabled}
            onChange={(event) => onChange(event.target.value)}
            onKeyDown={(event) =>
              event.key === 'Enter' && event.preventDefault()
            }
          />
        </div>
      )}
    </WithVariableAutocomplete>
  );
};

FormulaField.propTypes = {
  /** Unique ID for the field */
  'id': PropTypes.string,
  /** The name of the FormulaField */
  'name': PropTypes.string,
  /** Class(es) to apply to the element */
  'className': PropTypes.string,
  /** Unique ID for selecting the field in unit/integration tests */
  'data-testid': PropTypes.string,
  /** The value of the input */
  'value': PropTypes.string,
  /** A flag for disabling or enabling input area */
  'isDisabled': PropTypes.bool,
  /**
   * Event handler for field changes
   *
   * @param {Object} event
   */
  'onChange': PropTypes.func.isRequired,
  /**
   * Event handler that fires when the field loses focus
   *
   * @param {Object} event
   */
  'onBlur': PropTypes.func,
  /**
   * Event handler that fires on focus
   *
   * @param {Object} event
   */
  'onFocus': PropTypes.func,
  /** A key for grabbing preset formulas from the store */
  'presetFormulasId': PropTypes.oneOf(PRESET_FORMULA_KEYS),
};

export default FormulaField;
