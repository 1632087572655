import { useCallback, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import { DASHBOARD_PATH } from '@/constants/pages';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import useCallbackRef from '@/hooks/useCallbackRef';
import useChartTableData from '@/hooks/useChartTableData';
import useDashboardPath from '@/hooks/useDashboardPath';
import { valueFormatter } from '@/pages/Actuals/helpers';
import TotalRevenueChart from './charts/TotalRevenueChart';
import { CHART_TITLE, TOTAL_REVENUE_METRICS } from './constants/totalRevenue';

function TotalRevenue({ showGrid = true }) {
  const [loading, setLoading] = useState(false);
  const [totalRevenue, setTotalRevenue] = useState();
  const [chartData, setChartData] = useState([]);
  const { startDate, endDate } = useSelector(({ shared }) => shared);
  const location = useLocation();
  const dashboardPath = useDashboardPath();
  const [gridApi, gridRef] = useCallbackRef();

  const chartRef = useRef();

  const colDefs = useMemo(
    () => [
      {
        field: 'name',
        headerName: CHART_TITLE,
      },
    ],
    [],
  );

  const tableData = useChartTableData(chartData, TOTAL_REVENUE_METRICS);
  const onQueryStateChange = useCallback((data) => {
    const [
      {
        data: { totalRevenue: totalRevenueValue },
        isLoading,
      },
    ] = data;
    setLoading(isLoading);
    setChartData(data);
    if (!isLoading) {
      setTotalRevenue(totalRevenueValue);
    }
  }, []);

  return (
    <>
      <section className="ChartPanel">
        <ChartHeader
          back={
            location.pathname.includes(DASHBOARD_PATH)
              ? dashboardPath
              : undefined
          }
          title={CHART_TITLE}
        >
          <div className="ChartHeader_KPIValue">
            {!loading ? formatMonetary(totalRevenue) : 'Awaiting Data'}
          </div>
          <div className="ChartHeader_KPI">
            <br />
            {formatRangeWithShortYear(startDate, endDate)}
          </div>
        </ChartHeader>
        <div>
          <ChartToolbar>
            <ChartControls
              chartRef={chartRef}
              title={CHART_TITLE}
              data-testid="totalRevenue-chartControls"
              metricValue={formatMonetary(totalRevenue)}
              label={formatRangeWithShortYear(startDate, endDate)}
              disabled={loading}
            />
          </ChartToolbar>
          <TotalRevenueChart
            className="ExpandedView_ChartWrapper"
            onQueryStateChange={onQueryStateChange}
            exportBtn={chartRef}
          />
        </div>
      </section>
      {showGrid && (
        <section className="Panel Panel-toEdge">
          <SpreadsheetToolbar editable={false} gridApi={gridApi} />
          <MonthlySpreadsheet
            ref={gridRef}
            columnDefs={colDefs}
            data={tableData}
            data-testid="total-revenue-table"
            editable={false}
            enableComparison
            getRowId={({ data }) => data.name}
            valueFormatter={valueFormatter}
          />
        </section>
      )}
    </>
  );
}

export default TotalRevenue;
