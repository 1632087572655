// @ts-check
import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import CompanyIcon from '@bill/cashflow.assets/company';
import { useQuery } from '@tanstack/react-query';
import { REFRESH_COMPANIES_DATA } from '@/actionTypes/companies';
import { COMPANIES } from '@/cacheKeys';
import AddUpdateCompanySidebar from '@/components/ManagementConsole/AddUpdateCompanySidebar';
import ProductTypeFilter from '@/components/ProductTypeFilter/ProductTypeFilter';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';
import { consoleSidebarTitles } from '@/constants/console';
import { FPA_FULL } from '@/constants/productTypes';
import { ERROR_BOUNDARY_TEXT } from '@/constants/tables';
import useNotificationContext from '@/contexts/useNotificationContext';
import useCurrentSelectedCompany from '@/hooks/useCurrentSelectedCompany';
import ManagementConsoleListItem from '@/pages/ManagementConsole/ListItem';
import ManagementConsoleCompanyGrid from '@/pages/ManagementConsole/ManagementConsoleCompanyGrid';
import { getCompanies } from '@/services/companies';
import './ManagementConsole.scss';

const POLLING_INTERVAL = 30000;

/**
 * Setting the companyId explicitly to null would override the selectedCompany
 * in the API requests
 */
const OVERRIDE_COMPANY_ID = null;

/**
 * Renders the layout for management console
 *
 * @example
 *   <ManagementConsole />;
 *
 * @type {() => React.ReactElement}
 */
export default function ManagementConsole() {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const [isSidebarOpen, openSidebar] = useState(false);
  /** @type {[number, React.Dispatch<React.SetStateAction<number>>]} */
  const [editCompanyId, setEditCompanyId] = useState(null);

  const handleAddNewCompany = () => openSidebar(true);

  const { margin } = useNotificationContext();

  const handleCloseSidebar = () => {
    openSidebar(false);
    setEditCompanyId(OVERRIDE_COMPANY_ID);
  };
  const handleEditCompany = (companyId) => {
    setEditCompanyId(companyId);
    openSidebar(true);
  };
  const currentSelectedCompany = useCurrentSelectedCompany();

  /**
   * @type {import('@tanstack/react-query').UseQueryResult<
   *   import('@/reducers/companies').Company[]
   * >}
   */
  const { data: companiesList, isLoading } = useQuery(
    [COMPANIES],
    async () => {
      const {
        data: { data },
      } = await getCompanies();
      dispatch({ type: REFRESH_COMPANIES_DATA, payload: data });
      return data;
    },
    { refetchInterval: POLLING_INTERVAL, enabled: !isSidebarOpen },
  );

  useEffect(() => {
    document.title = 'Management Console';
  }, []);

  return (
    <div
      className="ManagementConsole_Container"
      data-testid="managementConsole"
      style={{ marginTop: margin }}
    >
      <div className="ManagementConsole_Sidebar">
        <h4 className="ManagementConsole_SidebarTitle">Management Console</h4>
        <ManagementConsoleListItem
          title={consoleSidebarTitles.COMPANIES}
          Icon={CompanyIcon}
          count={companiesList?.length}
        />
      </div>
      <div className="MangementConsole_RightPanel">
        <header className="ManagementConsole_Header">
          <h1 className="ManagementConsole_HeaderTitle">Companies</h1>
          {currentSelectedCompany ? (
            <ProductTypeFilter productType={FPA_FULL}>
              <button
                className="ManagementConsole_HeaderBtn"
                data-testid="addNewCompanyBtn"
                onClick={handleAddNewCompany}
              >
                + Add New Company
              </button>
            </ProductTypeFilter>
          ) : (
            <button
              className="ManagementConsole_HeaderBtn"
              data-testid="addNewCompanyBtn"
              onClick={handleAddNewCompany}
            >
              + Add New Company
            </button>
          )}
        </header>
        <div className="ManagementConsole_Content">
          <CommonErrorBoundary text={ERROR_BOUNDARY_TEXT}>
            <div className="ManagementConsole_Wrapper">
              <ManagementConsoleCompanyGrid
                isLoading={isLoading}
                companies={companiesList}
                onEditCompany={handleEditCompany}
              />
            </div>
          </CommonErrorBoundary>
        </div>
      </div>
      <AddUpdateCompanySidebar
        open={isSidebarOpen}
        onClose={handleCloseSidebar}
        companyId={editCompanyId}
      />
    </div>
  );
}
