import { useMemo, useState } from 'react';
import ExpensesEmptyIcon from '@bill/cashflow.assets/expenses-empty';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartLegend from '@/components/Charts/ChartLegend';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import PercentBarWithMetrics from '@/components/Charts/PercentBarWithMetrics';
import EmptyData from '@/components/common/EmptyData';
import metricFormatters from '@/helpers/metricFormatters';
import useTotalExpensesChartColorMap from '@/hooks/useTotalExpensesChartColorMap';
import useTotalExpensesQuery from './useTotalExpensesQuery';

const CHART_TITLE = 'Total Expenses Breakdown by Percentage';

const reducer = (expense) =>
  expense.data.map((series) => ({
    ...series,
    data: [series.y],
  }));

const TotalExpensesPercentageChart = () => {
  const [chart, setChart] = useState();

  const scenarioQueries = useTotalExpensesQuery(reducer);
  const [base] = scenarioQueries;
  const scenarioId = base?.scenario.scenarioId;

  const expensesColorMap = useTotalExpensesChartColorMap(
    scenarioId,
    scenarioQueries,
  );

  const data = useMemo(
    () =>
      scenarioQueries.map((query) =>
        query.data.map((series) => ({
          ...series,
          scenario: query.scenario,
        })),
      ),
    [scenarioQueries],
  );

  const hasData = base?.isLoading || base?.data.length;

  return (
    <section className="ChartPanel">
      <ChartHeader title={CHART_TITLE} />
      <ChartToolbar className="Chart_Toolbar-withLegend">
        <ChartLegend chart={chart} data-testid="expense-percent-legend" />
        <ChartControls
          chartRef={chart}
          title={CHART_TITLE}
          data-testid="expense-percent-chart-export"
          disabled={!hasData}
        />
      </ChartToolbar>
      {hasData ? (
        <PercentBarWithMetrics
          data={data}
          data-testid="expense-percent-department"
          onChartCreated={setChart}
          valueFormatter={metricFormatters.monetary}
          colors={expensesColorMap}
        />
      ) : (
        <EmptyData Icon={ExpensesEmptyIcon}>
          Add Expenses or Employees for this chart to populate
        </EmptyData>
      )}
    </section>
  );
};

export default TotalExpensesPercentageChart;
