import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getPaymentPlansAction,
  updatePaymentPlanAction,
  selectCurrentPaymentPlanAction,
} from '@/actions/settings';
import CompanyDetail from '@/components/Settings/CompanyDetails';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import ModalLockout from '@/components/common/ModalLockout';
import Select from '@/components/common/Select';
import redirectToStripe from '@/helpers/stripe';

const TrialModal = ({
  selectedCompanyId,
  createStripeSession,
  stripeSessionCreationSuccess,
  stripeSessionCreationErrorMsg,
  setStripeSessionError,
  getPaymentPlans,
  paymentPlan: {
    paymentPlans,
    selectedPaymentPlanId,
    currentSelectedPaymentPlanId,
  },
  selectCurrentPaymentPlan,
  updatePaymentPlan,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    stripeSessionCreationErrorMsg,
  );
  const [showIntlAddressConfirmation, setShowIntlAddressConfirmation] =
    useState(false);

  useEffect(() => {
    getPaymentPlans();
  }, [getPaymentPlans]);

  useEffect(() => {
    if (stripeSessionCreationSuccess?.url) {
      window.location = stripeSessionCreationSuccess.url;
      return;
    }
    if (stripeSessionCreationSuccess?.sessionId) {
      redirectToStripe(stripeSessionCreationSuccess?.sessionId)
        .then((redirectError) => {
          if (redirectError) {
            setErrorMessage(redirectError.message);
          }
        })
        .catch((error) => setErrorMessage(error.message));
    }
  }, [stripeSessionCreationSuccess]);

  useEffect(() => {
    setErrorMessage(stripeSessionCreationErrorMsg);
  }, [stripeSessionCreationErrorMsg]);

  useEffect(() => {
    setLoading(false);
  }, [errorMessage]);

  /**
   * @type {(
   *   companyDetails: import('../Settings/CompanyDetails').CompanyDetails,
   * ) => void}
   */
  const onCompanyDetailSaved = (companyDetails) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    if (stripeSessionCreationErrorMsg) {
      setStripeSessionError('');
    }

    if (companyDetails.address?.isInternational) {
      setShowIntlAddressConfirmation(true);
      return;
    }

    setLoading(true);
    if (!selectedPaymentPlanId && currentSelectedPaymentPlanId) {
      updatePaymentPlan({
        selectedPaymentPlanId: currentSelectedPaymentPlanId,
      });
      return;
    }

    createStripeSession(window.location.href);
  };

  const headingContent = (
    <>
      <div>Thank you</div>for trying Finmark.
    </>
  );

  const footerContent = (
    <>
      <p>
        Want to grab some time with our customer success team? Get in touch with
        us.
      </p>
      <p>
        <a href="mailto:help@finmark.com" className="link">
          help@finmark.com
        </a>
      </p>
    </>
  );

  return (
    <ModalLockout header={headingContent} footer={footerContent}>
      <>
        <p className="ModalLockout_Content">
          You've reached the end of our free trial. To pick up where you left
          off building your financial plan and seeing all of your data,
          dashboards, and updates, please subscribe by clicking on the button
          below.
        </p>
        {errorMessage && <p className="ModalLockout_Error">{errorMessage}</p>}
        <div className="ModalLockout_RevenueContainer">
          <label htmlFor="paymentPlanId" className="Label">
            Current Annual Revenue
          </label>
          <Select
            id="paymentPlanId"
            name="paymentPlanId"
            value={selectedPaymentPlanId ?? currentSelectedPaymentPlanId}
            onChange={({ target: { value } }) => {
              const valueAsNumber = Number(value);
              selectCurrentPaymentPlan(
                Number.isFinite(valueAsNumber) ? valueAsNumber : null,
              );
            }}
            disabled={selectedPaymentPlanId}
          >
            <option value={null}>Select your revenue level</option>
            {paymentPlans
              .filter((plan) => plan.isActive)
              .map((plan) => (
                <option key={plan.id} value={plan.id}>
                  {plan.displayRange}
                </option>
              ))}
          </Select>
        </div>
        <div className="ModalLockout_AddressContainer">
          <CompanyDetail
            companyId={selectedCompanyId}
            showIndustryAndAddress
            hideStreetLineTwo
            isExternallyLoading={loading}
            saveButtonText="Subscribe Now"
            isSaveExternallyDisabled={
              !selectedPaymentPlanId && !currentSelectedPaymentPlanId
            }
            onFinish={onCompanyDetailSaved}
          />
        </div>
        <ModalConfirmation
          title="Pausing International Support"
          actionBtnTxt="I understand"
          onAction={() => setShowIntlAddressConfirmation(false)}
          open={showIntlAddressConfirmation}
        >
          Finmark was recently acquired by BILL. As we work towards a full
          integration with BILL in the coming months, we will need to
          temporarily pause our ability to support international customers. We
          are actively working towards supporting international customers and
          will notify you as soon as we open our international support
          capabilities.
        </ModalConfirmation>
      </>
    </ModalLockout>
  );
};

TrialModal.propTypes = {
  /** The selected company Id */
  selectedCompanyId: PropTypes.number.isRequired,
  /** Redux action to trigger redirecting the user to the Stripe payment screen */
  createStripeSession: PropTypes.func.isRequired,
  /** Stripe session created success object */
  stripeSessionCreationSuccess: PropTypes.shape({
    url: PropTypes.string,
    sessionId: PropTypes.string,
  }),
  /** Stripe error message */
  stripeSessionCreationErrorMsg: PropTypes.string,
  /** A Redux action to set a Stripe session error message */
  setStripeSessionError: PropTypes.func.isRequired,
  /** Action function to get all user payment plans */
  getPaymentPlans: PropTypes.func,
  /** Payment Plan Object */
  paymentPlan: PropTypes.shape({
    paymentPlans: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
      }),
    ),
    selectedPaymentPlanId: PropTypes.number,
    currentSelectedPaymentPlanId: PropTypes.number,
  }),
  /** Action function to cache user selected payment plan */
  selectCurrentPaymentPlan: PropTypes.func,
  /** Action function to update payment plan */
  updatePaymentPlan: PropTypes.func,
};

const mapStateToProps = ({
  settings: { paymentPlan },
  companies: { selectedCompanyId },
}) => ({
  paymentPlan,
  selectedCompanyId,
});

export default connect(mapStateToProps, {
  selectCurrentPaymentPlan: selectCurrentPaymentPlanAction,
  updatePaymentPlan: updatePaymentPlanAction,
  getPaymentPlans: getPaymentPlansAction,
})(TrialModal);
