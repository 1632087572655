import { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartLegend from '@/components/Charts/ChartLegend';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import InfoTooltip from '@/components/common/InfoTooltip';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import { cellValueFormatterLegacy } from '@/components/common/MonthlySpreadsheet/helpers';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import TogglePersistent from '@/components/common/TogglePersistent';
import { units } from '@/constants/variables';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import useCallbackRef from '@/hooks/useCallbackRef';
import useChartPreferences from '@/hooks/useChartPreferences';
import useChartTableData from '@/hooks/useChartTableData';
import useDashboardPath from '@/hooks/useDashboardPath';
import TotalMrrChart, { TOTAL_MRR_TOGGLE_ID } from './charts/TotalMrrChart';

const CHART_TITLE = 'Total MRR';
const SPREADSHEET_ID = 'totalMRR-chart-table';
const TOTAL_MRR = 'totalMrr';

const percentageMetrics = [
  { key: TOTAL_MRR, name: 'Current Ending MRR' },
  { key: 'previousMonthTotalMrr', name: 'Ending Total MRR' },
  {
    key: 'totalMRRPoP',
    name: 'Percentage Growth',
    unit: units.PERCENTAGE,
  },
];

const TotalMrr = ({ startDate, endDate }) => {
  const [chart, setChart] = useState();
  const [chartData, setChartData] = useState([]);
  const showPercentage = useChartPreferences(TOTAL_MRR_TOGGLE_ID);
  const colDefs = [
    {
      field: 'name',
      headerName: showPercentage ? 'Total MRR Growth' : CHART_TITLE,
    },
  ];
  const [baseChartQuery] = chartData;
  const metrics = useMemo(() => {
    if (
      chartData?.every((_chart) => !_chart.isLoading) &&
      baseChartQuery?.data.data.every((baseData) => baseData.id)
    ) {
      return baseChartQuery.data.data
        .map(({ id, name }) => ({ key: id, name }))
        .concat({ key: TOTAL_MRR, name: 'Total MRR' });
    }
    return [];
  }, [chartData, baseChartQuery]);
  const transformedData = chartData.map((newData) => {
    return {
      ...newData,
      data: {
        data: newData.data?.popData ?? [],
      },
    };
  });

  const tableData = useChartTableData(
    transformedData,
    showPercentage ? percentageMetrics : metrics,
  );
  const dashboardPath = useDashboardPath();
  const [gridApi, gridRef] = useCallbackRef();
  const onQueryStateChange = useCallback((data) => {
    const [{ isLoading }] = data;
    if (!isLoading) {
      setChartData(data);
    }
  }, []);

  return (
    <>
      <section className="Panel Panel-toEdge ChartPanel">
        <ChartHeader back={dashboardPath} title={CHART_TITLE}>
          <div className="ChartHeader_KPIValue">
            {baseChartQuery?.data.mainMetric}{' '}
            {baseChartQuery?.tooltipText && (
              <InfoTooltip data-testid="total-mrr-tooltip">
                {baseChartQuery?.tooltipText}
              </InfoTooltip>
            )}
          </div>
          <div className="ChartHeader_KPI">
            {showPercentage ? (
              <span className="ChartHeader_Subtitle">Average for:</span>
            ) : (
              <br />
            )}
            {formatRangeWithShortYear(startDate, endDate)}
          </div>
        </ChartHeader>
        <ChartToolbar className="Chart_Toolbar-withLegend">
          {!showPercentage && (
            <ChartLegend chart={chart} data-testid="totalMrr-legend" />
          )}
          <TogglePersistent
            id={TOTAL_MRR_TOGGLE_ID}
            options={[
              {
                label: 'Currency',
                value: false,
              },
              {
                label: 'Percent',
                value: true,
              },
            ]}
            value={false}
          />
          <ChartControls
            chartRef={chart}
            title={CHART_TITLE}
            metricValue={baseChartQuery?.data.mainMetric}
            label={
              showPercentage
                ? `Average for: ${formatRangeWithShortYear(startDate, endDate)}`
                : formatRangeWithShortYear(startDate, endDate)
            }
            data-testid="total-mrr-chart-export"
          />
        </ChartToolbar>
        <TotalMrrChart
          className="ExpandedView_ChartWrapper"
          onChartCreated={setChart}
          onQueryStateChange={onQueryStateChange}
          showPercentage={showPercentage}
        />
      </section>
      <section className="Panel Panel-toEdge">
        <SpreadsheetToolbar editable={false} gridApi={gridApi}>
          Options:
          <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
        </SpreadsheetToolbar>{' '}
        <MonthlySpreadsheet
          ref={gridRef}
          columnDefs={colDefs}
          data={tableData}
          data-testid={SPREADSHEET_ID}
          editable={false}
          enableComparison
          getRowId={({ data }) => data.name}
          // eslint-disable-next-line import/no-deprecated -- predates description requirement
          valueFormatter={cellValueFormatterLegacy}
        />
      </section>
    </>
  );
};

function mapStateToProps({ shared }) {
  return {
    startDate: shared.startDate,
    endDate: shared.endDate,
  };
}

export default connect(mapStateToProps)(TotalMrr);
