import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Email from '@/pages/Registration/Email';
import withProtectedRoute from '@/pages/Registration/WithProtectedRoute';
import { routeProtections } from '@/pages/Registration/constants';
import { checkIsRegistered } from '@/services/signUpService';

const EmailSkip = ({ onChange, email, firstName, lastName, companyName }) => {
  const history = useHistory();
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    (async function checkEmail() {
      try {
        const {
          data: {
            data: { value },
          },
        } = await checkIsRegistered(email);
        if (value) {
          history.push('/join/login');
        } else {
          history.push('/join/password');
        }
      } catch (e) {
        setErrorMsg(e.response?.data?.error?.errorMessage || e.message);
      }
    })();
  }, [onChange, history, email, firstName, lastName, companyName]);

  if (errorMsg) {
    return <Email onChange={onChange} email={email} errorMessage={errorMsg} />;
  }

  return null;
};

export default withProtectedRoute(EmailSkip, {
  protectedBy: routeProtections.EMAIL,
});
