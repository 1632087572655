// @ts-check
import Button from '@/components/common/Button';
import FeedbackFace from '@/components/common/Feedback/FeedbackFace';
import Modal from '@/components/common/Modal';
import { classNames } from '@/helpers';
import './AiFeedbackModal.scss';

/** @typedef {import('@/types/exportableReport').FeedbackRating} FeedbackRating */

/**
 * @typedef {{
 *   open: boolean;
 *   onClose: () => void;
 *   onChange: () => void;
 *   onSave: () => void;
 *   text: string;
 *   variant: FeedbackRating;
 *   variantIcon?: React.ReactElement;
 *   loading: boolean;
 * }} AiFeedbackModalProps
 */

/**
 * Renders a feedback icon depending on the variant passed in
 *
 * @example
 *   <AiFeedbackModal
 *     variant={feedbackRating}
 *     onClick={onClick}
 *     open={isOpen}
 *     onChange={onChange}
 *     onClose={onClose}
 *     text={text}
 *     loading={isLoading}
 *   />;
 *
 * @type {(props: AiFeedbackModalProps) => React.ReactElement}
 */

const AiFeedbackModal = ({
  open,
  onClose,
  onChange,
  onSave,
  text,
  variant,
  variantIcon,
  loading,
}) => {
  return (
    <Modal
      className="FeedbackModal"
      open={open}
      onClose={onClose}
      data-testid="ai-feedback-modal"
    >
      <div className="FeedbackModal_Header">
        <div className="FaceIcon-selected">
          {variantIcon ?? <FeedbackFace variant={variant} />}
        </div>
        <div className="FeedbackModal_HeadingText">
          How could the response be better?
        </div>
      </div>

      <textarea
        rows={4}
        placeholder="How could the response have been better"
        value={text}
        className={classNames(
          'Feedback_Textarea',
          'FormField_Input',
          'FormField_Input-area',
        )}
        onChange={onChange}
      />
      <footer className="ModalBase_Footer">
        <Button
          className="Button ModalConfirmation_Button Button-cancelLink"
          onClick={onClose}
          data-testid="ai-feedback-cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={onSave}
          data-testid="ai-feedback-action-button"
          loading={loading}
        >
          Submit
        </Button>
      </footer>
    </Modal>
  );
};

export default AiFeedbackModal;
