import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import './Switch.scss';
import './Toggle.scss';

function ToggleOption({ Icon, id, label, value, ...props }) {
  return (
    <>
      <input
        type="radio"
        id={id}
        aria-label={label}
        className="Toggle_OptionInput"
        data-testid={id}
        {...props}
      />
      <label
        className="Toggle_OptionLabel"
        data-testid={`${id}-label`}
        htmlFor={id}
        aria-hidden="true"
      >
        {Icon ? (
          <Icon className="Toggle_OptionIcon" />
        ) : (
          <span className="Toggle_OptionText">{label}</span>
        )}
      </label>
    </>
  );
}

/**
 * Creates a toggle for switching between two states
 *
 * @example
 *   <Toggle
 *     id="foo"
 *     value={bar}
 *     onToggle={(value) => setBar(value)}
 *     options={[
 *       {
 *         Icon: SomeIcon,
 *         label: 'Option 1',
 *         value: 123,
 *       },
 *       {
 *         Icon: SomeOtherIcon,
 *         label: 'Option 2',
 *         value: 456,
 *       },
 *     ]}
 *   />;
 */
function Toggle({
  className = '',
  disabled,
  id,
  onToggle,
  options,
  value,
  ...props
}) {
  const [option1, option2] = options;

  return (
    <div className={classNames('Toggle', className)}>
      <ToggleOption
        checked={value === option1.value}
        disabled={disabled}
        id={`${id}-option-1`}
        name={id}
        onChange={() => onToggle(option1.value)}
        {...option1}
      />
      <button
        type="button"
        id={id}
        className="Switch_Button"
        data-testid={id}
        disabled={disabled}
        onClick={() =>
          onToggle(value === option1.value ? option2.value : option1.value)
        }
        aria-hidden="true"
        {...props}
      />
      <ToggleOption
        checked={value === option2.value}
        disabled={disabled}
        id={`${id}-option-2`}
        name={id}
        onChange={() => onToggle(option2.value)}
        {...option2}
      />
    </div>
  );
}

Toggle.propTypes = {
  /** Classes to apply to the toggle button */
  className: PropTypes.string,
  /** Whether or not the toggle is disabled */
  disabled: PropTypes.bool,
  /** Unique ID for selecting the toggle in unit/integration tests */
  id: PropTypes.string.isRequired,
  /**
   * A callback triggered when the toggle is clicked
   *
   * @param {string} value
   */
  onToggle: PropTypes.func.isRequired,
  /**
   * An array of objects describing the two states to toggle between. Each
   * object must contain an Icon, label and value.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.elementType,
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  ).isRequired,
  /** The index of the option to be selected */
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

export default Toggle;
