// @ts-check
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { COMPANIES, COMPANY_ORPHAN_STATUS, PARTNER_NAMES } from '@/cacheKeys';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import { isEmptyOrNull } from '@/helpers/validators';
import {
  removeAccessToCompany,
  getOrphanStatus,
  getPartnerNames,
} from '@/services/companies';

const MODAL_TITLE = 'Remove Company';

/**
 * Renders the modal which prompts to remove access to a company for all users
 * of a partner organization
 *
 * @type {(params: {
 *   open: boolean;
 *   onClose: () => void;
 *   company?: import('@/reducers/companies').Company;
 * }) => React.ReactElement}
 */
const PromptRemovePartnerAccess = ({ open, onClose, company }) => {
  const [isValidatingUserDependency, setIsValidatingUserDependency] =
    useState(false);
  const [deleteError, setDeleteError] = useState('');
  const [showOrphanWarningModal, setShowOrphanWarningModal] = useState(false);

  const queryClient = useQueryClient();

  const hideAllModals = () => {
    setDeleteError('');
    setIsValidatingUserDependency(false);
    setShowOrphanWarningModal(false);
    onClose();
  };

  const { mutate: removeUserAccess, isLoading: isRemovingAccess } = useMutation(
    removeAccessToCompany,
    {
      onMutate: () => {
        setDeleteError('');
      },
      /**
       * @type {(
       *   params: import('axios').AxiosError<
       *     import('@/types/api').ApiResponse<number>
       *   >,
       * ) => void}
       */
      onError: (error) => {
        setDeleteError(error.response.data.error.errorMessage);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([COMPANIES]);
        hideAllModals();
      },
    },
  );

  const { data: partnerNames, isLoading: isLoadingPartnerNames } = useQuery(
    [PARTNER_NAMES, company?.id],
    async () => {
      const data = await getPartnerNames(company?.id);
      return data.data?.data;
    },
    {
      enabled: !isEmptyOrNull(company),
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      /**
       * @type {(
       *   params: import('axios').AxiosError<
       *     import('@/types/api').ApiResponse<string>
       *   >,
       * ) => void}
       */
      onError: (error) => {
        const { errorMessage } = error.response?.data.error ?? {};
        setDeleteError(errorMessage);
      },
    },
  );

  useQuery(
    [COMPANY_ORPHAN_STATUS, company?.id],
    async () => getOrphanStatus(company.id),
    {
      enabled: isValidatingUserDependency,
      retry: false,
      onSettled: () => setIsValidatingUserDependency(false),
      onSuccess: ({ data: { data } }) => {
        const { isOrphan } = data;
        if (isOrphan) {
          setShowOrphanWarningModal(true);
        } else {
          removeUserAccess(company.id);
        }
      },
      /**
       * @type {(
       *   params: import('axios').AxiosError<
       *     import('@/types/api').ApiResponse<string>
       *   >,
       * ) => void}
       */
      onError: (error) => {
        const { errorMessage } = error.response?.data.error ?? {};

        setShowOrphanWarningModal(false);
        setDeleteError(errorMessage);
      },
    },
  );

  return (
    <>
      {!!open && !deleteError && !showOrphanWarningModal && (
        <ModalConfirmation
          id="modal-prompt-remove-company-access"
          onCancel={hideAllModals}
          onAction={() => setIsValidatingUserDependency(true)}
          loading={isLoadingPartnerNames}
          danger
          actionBtnTxt="Confirm"
          title={MODAL_TITLE}
          data-testid="modal-prompt-remove-company-access"
        >
          {!isLoadingPartnerNames && (
            <div>
              All users of {partnerNames.join(', ')} would lose access to this
              company. Are you sure you want to remove it?
            </div>
          )}
        </ModalConfirmation>
      )}
      {showOrphanWarningModal && !deleteError && (
        <ModalConfirmation
          id="modal-remove-company-access-confirmation"
          loading={isRemovingAccess}
          danger
          onCancel={hideAllModals}
          onAction={() => removeUserAccess(company.id)}
          actionBtnTxt="I Understand"
          title={MODAL_TITLE}
          data-testid="modal-remove-company-access-confirmation"
        >
          <div>
            If you remove this company there will be no users left to manage it
            any longer.
          </div>
        </ModalConfirmation>
      )}
      {deleteError && (
        <ModalConfirmation
          id="modal-remove-company-access-error"
          onAction={hideAllModals}
          danger
          title="Could not remove the company"
          actionBtnTxt="I Understand"
        >
          {deleteError}
        </ModalConfirmation>
      )}
    </>
  );
};

export default PromptRemovePartnerAccess;
