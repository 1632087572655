import COLORS from './colorPalette';

export const colors = [
  '#0000FF',
  '#FFFF00',
  '#FF0000',
  '#008000',
  '#000000',
  '#6A5ACD',
  '#808080',
  '#00FF00',
  '#00FFFF',
  '#808000',
  '#FFA500',
  '#FA8072',
  '#663399',
  '#DC143C',
  '#FFC0CB',
  '#FF1493',
  '#BDB76B',
  '#EE82EE',
  '#90EE90',
  '#FF4500',
  '#008080',
  '#4682B4',
  '#FFDEAD',
  '#F4A460',
  '#A52A2A',
  '#D3D3D3',
  '#00BFFF',
  '#DDA0DD',
  '#2E8B57',
  '#7B68EE',
];

export const SCENARIO_COLORS = [
  COLORS.finmarkBlue,
  COLORS.crayola,
  COLORS.turf,
  COLORS.yellow,
  COLORS.sunrise,
  COLORS.cinnabar,
  COLORS.pink,
  COLORS.plum,
  COLORS.slate,
  '#989898',
];

export const PRIMARY_PALETTE_COLORS = [
  COLORS.finmarkBlue,
  COLORS.cornflower,
  COLORS.crayola,
  COLORS.turf,
  COLORS.yellow,
  COLORS.sunrise,
  COLORS.cinnabar,
  COLORS.pink,
  COLORS.plum,
  COLORS.slate,
];

/**
 * These values have direct correlation with the color palette theme class. Do
 * not change these values without updating the class within _cssvars.scss
 */
export const colorPalletes = {
  ONECOLOR: 'OneColor',
  FINMARK: 'Finmark',
};
