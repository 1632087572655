// @ts-check
import { useEffect, useState } from 'react';
import Accordion from '@/components/common/Accordion';
import Checkbox from '@/components/common/Checkbox';
import SearchField from '@/components/common/SearchField';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import useOneColor from '@/hooks/useOneColor';
import {
  TOGGLE_CHART,
  TOGGLE_ALL_CHART,
  SYSTEM_CHART,
  CUSTOM_CHART,
  SET_CHARTS,
  SET_FILTER_TEXT,
} from './constants';
import './ChartsList.scss';

const filterChartsByQuery = (chart, query) => {
  return query
    ? chart.name.toLowerCase().includes(query.trim().toLowerCase())
    : true;
};

const AccordionContent = ({
  list,
  state,
  setState,
  filterQuery,
  chartType,
}) => {
  return (
    <>
      {list.length > 0 && !filterQuery && (
        <Checkbox
          className="ChartsList_Checkbox ChartsList_Checkbox-SelectAll"
          checked={state.isSelectAll[chartType]}
          onChange={() => {
            setState({
              type: TOGGLE_ALL_CHART,
              toggleAllChart: {
                chartType,
              },
            });
          }}
          id={`${chartType}-select-all`}
        >
          Select All
        </Checkbox>
      )}
      {list.map(({ name, key }) => (
        <Checkbox
          key={key}
          className="ChartsList_Checkbox"
          checked={state.selectedChartIds.includes(key)}
          onChange={() => {
            setState({ type: TOGGLE_CHART, toggledChart: key });
          }}
          id={key}
        >
          <TooltipTextOverflow label={name} data-testid={`${key}-tooltip`} />
        </Checkbox>
      ))}
    </>
  );
};

const ChartsList = ({ state, setState, charts }) => {
  const isOneColorEnabled = useOneColor();
  const [query, setQuery] = useState('');

  useEffect(() => {
    const filteredCharts = charts?.filter((chart) =>
      filterChartsByQuery(chart, query),
    );

    setState({
      type: SET_CHARTS,
      filteredCharts,
    });
  }, [query, setState, charts]);

  const handleChartFilter = (filterText) => {
    setQuery(filterText);
    setState({
      type: SET_FILTER_TEXT,
      filterText,
    });
  };

  return (
    <>
      <header className="Sidebar_Header">
        <h3 className="Sidebar_Title">Charts</h3>
      </header>
      <SearchField
        id="search-chart"
        name="search-chart"
        value={query}
        onChange={handleChartFilter}
        hasPrefix={!isOneColorEnabled}
        label="Search for Charts"
        className="SearchField-chartsToggle"
      />
      <div className="ChartsList">
        <Accordion
          defaultExpanded
          label="Default Charts"
          key={`${query}-${SYSTEM_CHART}`}
        >
          <AccordionContent
            list={state.charts
              .filter(({ type }) => type === SYSTEM_CHART)
              .sort((a, b) => a.name.localeCompare(b.name))}
            state={state}
            setState={setState}
            filterQuery={query}
            chartType={SYSTEM_CHART}
          />
        </Accordion>

        <Accordion
          defaultExpanded
          label="Custom Charts"
          key={`${query}-${CUSTOM_CHART}`}
        >
          <AccordionContent
            list={state.charts.filter(({ type }) => type === CUSTOM_CHART)}
            state={state}
            setState={setState}
            filterQuery={query}
            chartType={CUSTOM_CHART}
          />
        </Accordion>
      </div>
    </>
  );
};

export default ChartsList;
