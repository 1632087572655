// @ts-check
import useIsPermitted from '@/hooks/useIsPermitted';

/**
 * usePermissions is a hook which takes two values and return boolean if user is
 * permitted to perform the action on the subject.
 *
 * @example
 *   const isUserAllowed = usePermissions('Write', 'Setting');
 *
 * @type {(
 *   action: import('@/constants/permissions').PermissionAction,
 *   subject: import('@/constants/permissions').PermissionSubject,
 *   scenarioPermissionRequired?: boolean,
 * ) => boolean}
 */
export default function usePermissions(
  action,
  subject,
  scenarioPermissionRequired,
) {
  const isPermitted = useIsPermitted();
  return isPermitted({ action, subject, scenarioPermissionRequired });
}
