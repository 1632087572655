// @ts-check
import { useEffect } from 'react';
import { useChart } from 'react-jsx-highcharts';

/**
 * A helper component for getting and setting a reference to a Highcharts chart.
 *
 * The package we use to ease working with Highcharts 'react-jsx-highcharts'
 * doesn't forward refs. To get around this, we place this component within one
 * of its chart components and get a reference to the chart via `useChart` and
 * set it here.
 *
 * @type {(props: {
 *   onChartCreated: (chart: import('highcharts').Chart) => void;
 * }) => null}
 */
const ChartRefContainer = ({ onChartCreated }) => {
  const chart = useChart();

  useEffect(() => {
    onChartCreated(chart.object);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [chart]);

  return null;
};

export default ChartRefContainer;
