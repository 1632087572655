import { VARIABLES_PATH } from '@/constants/pages';
import { classNames } from '@/helpers';

/**
 * Renders a term definition for a formula guide modal
 *
 * @example
 *   <FormulaDef term="Foo">Some text</FormulaDef>;
 */
export const FormulaDef = ({ children, className, term }) => (
  <>
    <dt className={classNames('FormulaDefs_Term', className)}>{term}</dt>
    <dd className="FormulaDefs_Def">{children}</dd>
  </>
);

/**
 * Definition of variables in a custom formula
 *
 * @example
 *   <FormulaDefVariables id="foo" />;
 */
export const FormulaDefVariables = ({ id }) => (
  <FormulaDef className="FormulaDefs_Term-var" term="Variables">
    You can choose from a wide variety of variables to build your forecast.
    Variables are calculated at a monthly level. To see a full list of Finmark
    variables, visit the ‘Variables’ section, by clicking{' '}
    <a
      href={VARIABLES_PATH}
      target="_blank"
      rel="noreferrer"
      className="Modal_CopyLink"
      data-testid={`${id}-systemVarsLink`}
    >
      here
    </a>
    .
  </FormulaDef>
);

/**
 * Definition of operators in a custom formula
 *
 * @example
 *   <FormulaDefOperators />;
 */
export const FormulaDefOperators = () => (
  <FormulaDef className="FormulaDefs_Term-operator" term="Operators">
    You can use basic arithmetic operators i.e. Addition (+), Subtraction (-),
    Multiplication (*) and Division (/). You can also use parentheses "(" and
    ")" in your formulae. All formulae will be calculated using PEMDAS going
    left to right.
  </FormulaDef>
);

/**
 * Definition of variable offsets in a custom formula
 *
 * @example
 *   <FormulaDefOffsets />;
 */
export const FormulaDefOffsets = () => (
  <FormulaDef className="FormulaDefs_Term-offset" term="Offsets">
    You can use square brackets at the end of the variable to offset months i.e.
    Suffix a variable name with [X] to refer to the Xth month with respect to
    the current month. Here, [-1] shows the immediately previous month. These
    offsets are limited to 60 months in both directions.
  </FormulaDef>
);

/**
 * Displays definitions on how to use variables, operators, offsets to construct
 * a custom formula
 *
 * @example
 *   <FormulaDefs />;
 */
const FormulaDefs = () => {
  return (
    <dl className="FormulaDefs">
      <FormulaDefVariables />
      <FormulaDefOperators />
      <FormulaDefOffsets />
    </dl>
  );
};

export default FormulaDefs;
