import { useMemo } from 'react';
import CrossIcon from '@bill/cashflow.assets/cross';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import { actualsFamily } from '@/constants/actuals';
import { classNames } from '@/helpers';
import formatMonetary from '@/helpers/formatMonetary';
import metricFormatters from '@/helpers/metricFormatters';
import useIsFPALite from '@/hooks/useIsFPALite';
import './ExternalDetailsModal.scss';

const AMOUNT = 'Amount';
const DATE = 'Date';
const DIRECTION = 'Direction';
const EXPENSE_DETAIL_HEADER = [
  DATE,
  'Account #',
  'Account Name',
  'Vendor',
  'Description',
  'Categories',
  AMOUNT,
];
const REVENUE_DETAIL_HEADER = [
  DATE,
  'Company Name / Customer Name',
  'Description',
  AMOUNT,
];

/**
 * @typedef {{
 *   name: string;
 *   family: ActualsView;
 *   details:
 *     | import('@/types/services/backend').ExpenseGroupActualDetails[]
 *     | import('@/types/services/backend').RevenueStreamActualDetails[];
 * }} ExternalActualDetails
 */

/**
 * @type {(props: {
 *   family: import('@/types/services/backend').ActualNodeDto['family'];
 *   details: ExternalActualDetails['details'];
 * }) => React.ReactElement}
 */
const TableData = ({ family, details }) => {
  const isFPALite = useIsFPALite();
  return (
    <>
      {family === actualsFamily.REVENUE &&
        details.map(
          (
            /** @type {import('@/types/services/backend').RevenueStreamActualDetails} */ {
              date,
              customerName,
              dealName,
              amount,
            },
            idx,
          ) => (
            // eslint-disable-next-line react/no-array-index-key -- predates description requirement
            <li key={idx} className="ExternalDetails_Item">
              <span>{date && metricFormatters.dateMonthDayYear(date)}</span>
              <TooltipTextOverflow
                label={customerName}
                className="ExternalDetails_CustomerName"
                data-testid={`customer-${idx}`}
              />
              <TooltipTextOverflow
                label={dealName}
                className="ExternalDetails_CustomerName"
                data-testid={`deal-${idx}`}
              />
              <span className="ExternalDetails_ItemAmount">
                {formatMonetary(amount)}
              </span>
            </li>
          ),
        )}

      {family !== actualsFamily.REVENUE &&
        details.map(
          (
            /** @type {import('@/types/services/backend').ExpenseGroupActualDetails} */ {
              amount,
              description,
              vendor,
              date,
              accountNumber,
              accountName,
              trackingCategories,
              cashFlowType,
            },
            idx,
          ) => (
            // eslint-disable-next-line react/no-array-index-key -- predates description requirement
            <li key={idx} className="ExternalDetails_Item">
              <span>{date && metricFormatters.dateMonthDayYear(date)}</span>
              <span>{accountNumber ?? '--'}</span>
              <span>{accountName}</span>
              <TooltipTextOverflow
                label={vendor || '(no vendor available)'}
                className="ExternalDetails_ItemVendor"
                data-testid={`vendor-${idx}`}
              />
              <TooltipTextOverflow
                label={description || '(no description available)'}
                data-testid={`description-${idx}`}
              />
              <TooltipTextOverflow
                label={
                  trackingCategories?.length
                    ? trackingCategories.join(', ')
                    : '(no categories available)'
                }
                data-testid={`tacking-categories-${idx}`}
              />
              <span className="ExternalDetails_ItemAmount">
                {formatMonetary(amount)}
              </span>
              {isFPALite && <span>{cashFlowType}</span>}
            </li>
          ),
        )}
    </>
  );
};

/**
 * @typedef {{
 *   entry: ExternalActualDetails;
 *   onClose: () => void;
 * }} Props
 * @type {(props: Props) => ReactElement}
 */
export default function ExternalDetailsModal({ entry, onClose }) {
  const { details, family } = entry;
  const isFPALite = useIsFPALite();

  const HEADER = useMemo(() => {
    let baseHeader = EXPENSE_DETAIL_HEADER;

    if (family === actualsFamily.REVENUE) {
      baseHeader = REVENUE_DETAIL_HEADER;
    }

    if (isFPALite) {
      return [...baseHeader, DIRECTION];
    }

    return baseHeader;
  }, [family, isFPALite]);

  const total = details.reduce((accum, { amount }) => accum + amount, 0);
  return (
    <>
      <button type="button" className="Modal_CloseBtn" onClick={onClose}>
        <CrossIcon aria-label="Close" />
      </button>
      <h2 className="ModalBase_Heading ModalBase_Heading-externalDetails">
        {entry.name}
      </h2>
      <h3 className="ExternalDetails_Total">Total: {formatMonetary(total)}</h3>
      {details.length > 0 && (
        <ul
          className={classNames(
            'ExternalDetails',
            family === actualsFamily.REVENUE && 'RevenueDetails',
            isFPALite && 'ExternalDetails-cashGrid',
          )}
        >
          <li className="ExternalDetails_Head">
            {HEADER.map((header) => (
              <span
                key={header}
                className={classNames(
                  header === AMOUNT && 'ExternalDetails_ItemAmount',
                )}
              >
                {header}
              </span>
            ))}
          </li>
          <TableData family={family} details={details} />
        </ul>
      )}
    </>
  );
}
