import PropTypes from 'prop-types';
import FormField from '@/components/common/FormField';

const ReferralsForm = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  checkValid,
}) => {
  return (
    <>
      Get <strong>one month free</strong> every time you refer a friend. Just
      fill out the form below, and we’ll do the rest.
      <div className="Form Referral_Form">
        <div className="Form_Group-halfWidth">
          <label className="Label" htmlFor="firstName">
            First Name
            <sup className="Required_Marker">*</sup>
          </label>
          <FormField
            id="firstName"
            type="text"
            name="firstName"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            validate={() => !firstName && 'Field is Required'}
          />
        </div>
        <div className="Form_Group-halfWidth">
          <label className="Label" htmlFor="lastName">
            Last Name
          </label>
          <sup className="Required_Marker">*</sup>
          <FormField
            id="lastName"
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            validate={() => !lastName && 'Field is Required'}
          />
        </div>

        <div className="Form_Group">
          <label htmlFor="email" className="Label">
            Email Address
          </label>
          <sup className="Required_Marker">*</sup>
          <FormField
            id="email"
            type="email"
            name="email"
            maxLength="50"
            placeholder="Enter email address..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            validate={checkValid}
          />
        </div>
      </div>
    </>
  );
};

ReferralsForm.prototype = {
  /** First name of the person being referred */
  firstName: PropTypes.string,
  /** Last name of the person being referred */
  lastName: PropTypes.string,
  /** Email of the person being referred */
  email: PropTypes.string,
  /** Function to set first name */
  setFirstName: PropTypes.func.isRequired,
  /** Function to set last name */
  setLastName: PropTypes.func.isRequired,
  /** Function to set email */
  setEmail: PropTypes.func.isRequired,
  /** Function to check if email is valid */
  checkValid: PropTypes.func.isRequired,
};

export default ReferralsForm;
