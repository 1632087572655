import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import ListIcon from '@bill/cashflow.assets/list-icon';
import RoundCrossIcon from '@bill/cashflow.assets/round-cross';
import { getScenarioUsersAction } from '@/actions/scenario';
import Button from '@/components/common/Button';
import ErrorDiv from '@/components/common/ErrorDiv';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import { ADMIN } from '@/constants/roles';
import { scenarioTypesText } from '@/constants/scenario';
import { classNames, getScenarioKeyFromValue } from '@/helpers';
import { revokeUserAccess, shareScenario } from '@/services/scenario.service';
import './ScenarioList.scss';

const ScenarioListUsers = ({
  scenario,
  selectedScenarioUsers,
  userId,
  getScenarioUsers,
  onClose,
}) => {
  const [userToRevoke, setUserToRevoke] = useState();
  const [error, setError] = useState('');

  const scenarioTypeText = scenarioTypesText[scenario.type];

  useEffect(() => {
    getScenarioUsers(scenario.scenarioId, userId);
  }, [getScenarioUsers, scenario.scenarioId, userId]);

  const revokeAccess = async () => {
    setError('');
    try {
      await revokeUserAccess(userToRevoke.userId, scenario.scenarioId);
      getScenarioUsers(scenario.scenarioId, userId);
    } catch (e) {
      setError(e.response?.data?.error?.errorMessage || e.message);
    } finally {
      setUserToRevoke(null);
    }
  };

  const resendInvite = async ({ email, role }) => {
    setError('');

    try {
      await shareScenario({
        scenarioId: scenario.scenarioId,
        inviterId: userId,
        email,
        scenarioRole: getScenarioKeyFromValue(role),
      });
      getScenarioUsers(scenario.scenarioId, userId);
    } catch (e) {
      setError(e.response?.data?.error?.errorMessage || e.message);
    }
  };

  return (
    <>
      <div className="ModalBase_Header">
        <h2 className="ModalBase_Heading">Edit {scenarioTypeText}</h2>
        <ListIcon role="button" className="List_Icon" onClick={onClose} />
      </div>
      {error && <ErrorDiv error={error} />}
      <h3 className="ModalBase_SubHeading">Current {scenarioTypeText} Users</h3>
      <table className="ScenariosTable" data-testid="scenarios-table">
        <thead className="ScenariosTable_Header">
          <tr>
            <th className="ScenariosTable_ColHead">Email Address</th>
            <th className="ScenariosTable_ColHead">Access Type</th>
            <td
              className="ScenariosTable_ColHead ScenariosTable_ColHead-hasBtn"
              aria-label="Remove"
            />
          </tr>
        </thead>
        <tbody>
          {selectedScenarioUsers.map((user) => {
            return (
              <tr key={user.userId}>
                <td
                  className={classNames(
                    'ScenariosTable_Cell',
                    user.isExpired && 'ScenariosTable_Cell-expired',
                  )}
                >
                  {user.email}
                  {user.isExpired && (
                    <>
                      <br />
                      <span className="ScenarioList_InviteExpired">
                        Invite Expired:{' '}
                        <span
                          aria-hidden="true"
                          role="button"
                          className="link"
                          onClick={() => resendInvite(user)}
                        >
                          Send Again
                        </span>
                      </span>
                    </>
                  )}
                </td>
                <td className="ScenariosTable_Cell">{user.role}</td>
                <td
                  className={classNames(
                    'ScenariosTable_Cell',
                    user.companyRole === ADMIN &&
                      'ScenariosTable_Cell-disabled',
                  )}
                >
                  <RoundCrossIcon
                    role="button"
                    className="ScenarioOption_Remove"
                    onClick={() => setUserToRevoke(user)}
                    aria-label="Remove"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Alert variant="primary" className="mt-4">
        You can remove a user from this {scenarioTypeText.toLowerCase()} at any
        time, by clicking the cancel icon next to their account details.
      </Alert>
      <div className="ModalBase_Footer">
        <Button data-testid="action-button-modal" onClick={onClose}>
          Done
        </Button>
      </div>
      <ModalConfirmation
        id="modal-revoke-user"
        title="Revoke Access"
        actionBtnTxt="Revoke Access"
        open={!!userToRevoke}
        onCancel={() => setUserToRevoke(null)}
        onAction={revokeAccess}
      >
        This action will remove this user’s access to the{' '}
        {scenarioTypeText.toLowerCase()}. Are you sure you want to proceed?
      </ModalConfirmation>
    </>
  );
};

function mapStateToProps({ auth, scenario }) {
  return {
    selectedScenarioUsers: scenario.selectedScenerioUsers,
    userId: auth.userInfo.userId,
  };
}

export default connect(mapStateToProps, {
  getScenarioUsers: getScenarioUsersAction,
})(ScenarioListUsers);
