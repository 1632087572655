/* eslint-disable no-console -- predates description requirement */
import { SET_IS_REFRESHING_TOKEN } from '@/actionTypes/auth';
import {
  SET_DEFAULT_SCENARIO,
  SET_SCENARIO,
  SET_SCENARIO_ID,
  SET_SELECTED_SCENARIO_USERS,
  SET_SCENARIO_LOADER,
  SET_INVITED_USER,
  SET_COMPARE_SCENARIO_ID,
  SWAP_SCENARIOS,
  SCENARIO_DUPLICATION_STATUS,
  CREATE_SCENARIO,
} from '@/actionTypes/scenario';
import { NOOP } from '@/actionTypes/shared';
import { authSucceeded } from '@/actions/auth';
import { NO_COMPARISON } from '@/constants/scenario';
import { isEmptyOrNull } from '@/helpers/validators';
import { pendoTrackEvent } from '@/services/analyticsService';
import { refreshPermissions } from '@/services/authService';
import {
  deleteScenario,
  getScenario,
  createScenario,
  updateScenario,
  getScenarioUsers,
  duplicateScenario,
} from '@/services/scenario.service';
import { subscribeToTopic } from './shared';

export const setScenarioLoaderAction = (value) => {
  return {
    type: SET_SCENARIO_LOADER,
    payload: value,
  };
};

const pendoTrackScenarioChange = (
  {
    scenario: { scenarios, compareScenarioId },
    companies: { companies },
    auth: { userInfo, companyId },
  },
  scenarioId,
) => {
  // We fallback to `compareScenarioId` for users swapping scenarios, which
  // doesn't receive a scenarioId
  const id = scenarioId ?? compareScenarioId;

  const { isTemplate, isDefault } =
    scenarios.find((scenario) => scenario.scenarioId === id) ?? {};
  const { name: companyName } =
    companies.find((company) => company.id === companyId) ?? {};

  if (isTemplate) {
    const metadata = {
      fullName: userInfo.fullName,
      userId: userInfo.userId,
      email: userInfo.email,
      userRole: userInfo.userRole,
      isTemplateDefaultScenario: isDefault,
      companyId,
      companyName,
    };
    pendoTrackEvent('Switched to template scenario', metadata);
  }
};

export const scenarioIdPayload = (scenarioId) => (dispatch, getState) => {
  pendoTrackScenarioChange(getState(), scenarioId);
  dispatch({
    type: SET_SCENARIO_ID,
    payload: scenarioId,
  });
};

export const compareScenarioIdPayload = (scenarioId) => {
  return {
    type: SET_COMPARE_SCENARIO_ID,
    payload: scenarioId,
  };
};

export const setScenarioIdAction = (scenarioId) => {
  return (dispatch) => {
    try {
      dispatch(scenarioIdPayload(scenarioId));
    } catch (e) {
      console.error(e);
    }
  };
};

export const setCompareScenarioIdAction = (scenarioId) => {
  return (dispatch) => {
    try {
      dispatch(compareScenarioIdPayload(scenarioId));
    } catch (e) {
      console.error(e);
    }
  };
};

export const getScenarioAction = () => {
  return async (dispatch) => {
    dispatch(setScenarioLoaderAction(true));
    try {
      const response = await getScenario();
      const scenarios = response.data?.data;
      dispatch({
        type: SET_SCENARIO,
        payload: scenarios,
      });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setScenarioLoaderAction(false));
    }
  };
};

/**
 * Action that deletes the scenario with the given ID
 *
 * @param {number} scenarioId ID of the scenario to delete
 * @returns {Function} Dispatchable action
 */
export const deleteScenarioAction = (scenarioId) => {
  return async (dispatch, getState) => {
    try {
      const { scenario: scenarioState } = getState();
      await deleteScenario(scenarioId);
      dispatch(getScenarioAction());
      if (scenarioState.scenarioId === scenarioId) {
        const defaultScenario = scenarioState.scenarios.find(
          (scenario) => scenario.isDefault,
        );
        dispatch(setScenarioIdAction(defaultScenario.scenarioId));
      }
      if (scenarioState.compareScenarioId === scenarioId) {
        dispatch(setCompareScenarioIdAction(NO_COMPARISON));
      }
    } catch (e) {
      console.error(e);
    }
  };
};

/**
 * A factory function that generates a create/update scenario action
 *
 * @param {Function} callback - The xhr service function
 * @returns {Function} Scenario Action
 */
const scenarioCreationActionFactory = (callback) => {
  return (scenario) => {
    return async (dispatch, getState) => {
      dispatch({
        type: SET_IS_REFRESHING_TOKEN,
        payload: true,
      });

      await callback(scenario);
      const { selectedCompanyId } = getState().companies;
      const { data } = await refreshPermissions();
      dispatch(authSucceeded({ ...data, selectedCompanyId }));
      const { data: scenarioData } = await getScenario();
      dispatch({
        type: CREATE_SCENARIO,
        payload: {
          scenarios: scenarioData.data,
        },
      });
      dispatch({
        type: SET_IS_REFRESHING_TOKEN,
        payload: false,
      });
    };
  };
};

/**
 * Action that creates a scenario with the provided data
 *
 * @param {Object} scenario - The scenario to create
 * @returns {Function} - Dispatchable action
 */
export const createScenarioAction =
  scenarioCreationActionFactory(createScenario);

/**
 * Action to duplicate a scenario
 *
 * @param {Object} scenario - The scenario to create
 * @returns {Function} - Dispatchable action
 */
export const duplicateScenarioAction =
  scenarioCreationActionFactory(duplicateScenario);

/*
 * Action to edit a scenario
 *
 * @param {Object} scenario - The scenario to edit
 * @returns {Function} - Dispatchable action
 */
export const updateScenarioAction = (scenario) => {
  return async (dispatch) => {
    await updateScenario(scenario);
    dispatch(getScenarioAction());
  };
};

/**
 * Action to get list of scenario users
 *
 * @param {string} scenarioId - The scenario to get list of
 * @param {string} userId - The user id of current user
 * @returns {Function} - Dispatchable action
 */
export const getScenarioUsersAction = (scenarioId, userId) => {
  return async (dispatch) => {
    try {
      const response = await getScenarioUsers(scenarioId);
      dispatch({
        type: SET_SELECTED_SCENARIO_USERS,
        payload: response.data.data.filter((user) => user.userId !== userId),
      });
    } catch (e) {
      console.log(e);
    }
  };
};
/**
 * Action to duplicate a scenario
 *
 * @param {Object} scenario - The object that contains invited user email and
 *   scenario name
 * @returns {Function} - Dispatchable action
 */
export const setInvitedUserAction = (invitedUser) => {
  return (dispatch) => {
    dispatch({
      type: SET_INVITED_USER,
      payload: invitedUser,
    });
    setTimeout(() => {
      dispatch({
        type: SET_INVITED_USER,
        payload: undefined,
      });
    }, 5000);
  };
};

// eslint-disable-next-line unicorn/consistent-function-scoping -- predates description requirement
export const swapScenarios = () => (dispatch, getState) => {
  pendoTrackScenarioChange(getState());
  dispatch({
    type: SWAP_SCENARIOS,
  });
};

/**
 * Sets the given scenario as the default for the user.
 *
 * @param {Object} scenario - Scenario to make the default
 * @returns {Function} - Dispatchable action
 */
export const setDefaultScenarioAction = (scenario) => {
  return async (dispatch) => {
    const updatedScenario = {
      ...scenario,
      isDefault: true,
    };
    try {
      await updateScenario(updatedScenario);
      dispatch({
        type: SET_DEFAULT_SCENARIO,
        payload: scenario.scenarioId,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const subscribeToScenarioDuplicationAction = (companyId) => {
  return (dispatch) => {
    if (isEmptyOrNull(companyId)) {
      return dispatch({ type: NOOP });
    }
    return dispatch(
      subscribeToTopic(`scenario-status/${companyId}`, (duplicatedScenario) => {
        if (duplicatedScenario.duplicatedFrom)
          dispatch({
            type: SCENARIO_DUPLICATION_STATUS,
            payload: duplicatedScenario,
          });

        setTimeout(() => {
          dispatch({
            type: SCENARIO_DUPLICATION_STATUS,
            payload: null,
          });
        }, 5000);
      }),
    );
  };
};
