import { REVENUE_ONLY } from '@/constants/revenueStream';

/**
 * Checks if sum of all plans equals to 100
 *
 * @param {Object} [revenueStream] - A object containing all form values while
 *   creating/updating revenue streams
 * @returns {boolean}
 */
export const isAccumulatedPlanValuesValid = (revenueStream) => {
  if (revenueStream.revenueType === REVENUE_ONLY) {
    return true;
  }
  const values = revenueStream.plansAssociated.map(
    (plan) => plan.estimatedValue,
  );
  return (
    !values.includes(null) &&
    values.reduce((acc, current) => acc + Number(current), 0) === 100
  );
};

/** @type {(noEndDate: boolean, endDate: string | null) => boolean} */
export const hasEndDate = (noEndDate, endDate) => {
  return noEndDate || endDate !== null;
};
