// @ts-check
import { useMemo, useRef, useState, useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setSelectedCompany } from '@/actions/companies';
import {
  scenarioIdPayload,
  setCompareScenarioIdAction,
} from '@/actions/scenario';
import Spreadsheet from '@/components/common/Spreadsheet';
import ColumnToggle from '@/components/common/Spreadsheet/ColumnToggle';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import { EMPTY_CELL_VALUE } from '@/components/common/Spreadsheet/constants';
import { billingPlanComparator } from '@/components/common/Spreadsheet/helpers';
import { DASHBOARD_PATH } from '@/constants/pages';
import { USER_ROLES } from '@/constants/permissions';
import { NO_COMPARISON } from '@/constants/scenario';
import { REFRESH_TOKEN_EXPIRED } from '@/constants/settings';
import { classNames, showUserRoleText } from '@/helpers';
import formatOrdinal from '@/helpers/formatOrdinals';
import getScenarioColor from '@/helpers/getScenarioColor';
import { isEmptyOrNull } from '@/helpers/validators';
import useColumnHidden from '@/hooks/useColumnHidden';
import CompanyContextMenuRenderer from './CompanyContextMenuRenderer';
import ConsoleIntegrationSidebar from './Integration/ConsoleIntegrationSidebar';
import PromptRemovePartnerAccess from './PromptRemovePartnerAccess';
import { integrationComparator, integrationValueGetter } from './helpers';
import AdminNamesRenderer from './renderers/AdminNamesRenderer';
import CompanyIntegrationRenderer from './renderers/CompanyIntegrationRenderer';
import './ManagementConsoleCompanyGrid.scss';

const SPREADSHEET_ID = 'managementConsoleCompanyGrid';
const columnIds = {
  COMPANY_ICON: 'icon',
  COMPANY_NAME: 'name',
  INTEGRATION: 'integration',
  USER_ROLE: 'userRole',
  CLOSING_DAY: 'closingDay',
  ACTIONS: 'actions',
  CREATION_DATE: 'createdDate',
  ADMIN_NAMES: 'adminNames',
  BILLING_PLAN: 'billingPlan',
  TOTAL_USERS: 'totalUsers',
};

const filterParams = { debounceMs: 500 };

/** @typedef {GridSpreadsheetOptions<import('@/reducers/companies').Company>} ManagementConsoleCompanyGridOptions */

/**
 * @typedef {{
 *   companies: import('@/reducers/companies').Company[];
 *   isLoading: boolean;
 *   onEditCompany: (companyId: number) => void;
 * }} ManagementConsoleCompanyGridProps
 */

/**
 * The Management Console Company Grid
 *
 * @type {(
 *   params: ManagementConsoleCompanyGridProps,
 * ) => React.ReactElement}
 */
const ManagementConsoleCompanyGrid = ({
  companies,
  isLoading,
  onEditCompany,
}) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [companyForIntegrationsSidebar, setCompanyForIntegrationsSidebar] =
    useState(null);

  /**
   * @type {[
   *   import('@/reducers/companies').Company,
   *   React.Dispatch<
   *     React.SetStateAction<import('@/reducers/companies').Company>
   *   >,
   * ]}
   */
  const [companyToBeRemoved, setCompanyToBeRemoved] = useState(null);

  const onCloseIntegration = () => setCompanyForIntegrationsSidebar(null);

  /** @type {(company: import('@/reducers/companies').Company) => void} */
  const onIntegrationClick = (company) => {
    setCompanyForIntegrationsSidebar(company);
  };

  /**
   * @type {(
   *   params: import('ag-grid-community').CellClickedEvent<
   *     import('@/reducers/companies').Company
   *   >,
   * ) => void}
   */
  const handleCompanySelected = useCallback(
    ({ data }) => {
      dispatch(setSelectedCompany(data.id));
      dispatch(scenarioIdPayload(data.defaultScenarioId));
      dispatch(setCompareScenarioIdAction(NO_COMPARISON));
      history.push(DASHBOARD_PATH);
    },
    [dispatch, history],
  );
  const isConsoleAdmin = useMemo(
    () =>
      Boolean(
        companies?.find(
          (company) => company.userRole === USER_ROLES.ROLE_ADMIN,
        ),
      ),
    [companies],
  );

  const isIntegrationRefreshTokenExpired = useMemo(
    () =>
      Boolean(
        companies?.find(({ enabledIntegrations }) =>
          enabledIntegrations?.find(
            ({ status }) => status === REFRESH_TOKEN_EXPIRED,
          ),
        ),
      ),
    [companies],
  );
  const isColumnHidden = useColumnHidden(SPREADSHEET_ID);

  const colDefs = useMemo(() => {
    /** @type {ManagementConsoleCompanyGridOptions['columnDefs']} */
    const coldef = [
      {
        headerName: '',
        colId: columnIds.COMPANY_ICON,
        cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-letterIcon',
        editable: false,
        filter: false,
        pinned: 'left',
        resizable: false,
        sortable: false,
        suppressMovable: true,
        lockVisible: true,
        width: 100,
        minWidth: 70,
        field: columnIds.COMPANY_NAME,
        valueFormatter: ({ value }) => value.charAt(0),
        cellStyle: ({ data }) => ({
          backgroundColor: getScenarioColor(data.id),
        }),
      },
      {
        field: columnIds.COMPANY_NAME,
        headerName: 'Company Name',
        lockVisible: true,
        onCellClicked: handleCompanySelected,
        cellClass:
          'Spreadsheet_Cell Spreadsheet_Cell-label Spreadsheet_Cell-link',
      },
      {
        field: columnIds.TOTAL_USERS,
        type: 'number',
        headerName: 'Users',
        initialHide: isColumnHidden(columnIds.TOTAL_USERS, {
          isHiddenByDefault: false,
        }),
        cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
      },
      {
        field: columnIds.BILLING_PLAN,
        type: 'number',
        filterValueGetter: ({ data }) => data.billingPlan.amount,
        valueFormatter: ({ value }) => value.displayName,
        comparator: billingPlanComparator,
        headerName: 'Billing Plan',
        initialHide: isColumnHidden(columnIds.BILLING_PLAN, {
          isHiddenByDefault: false,
        }),
        cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
      },
      {
        field: columnIds.USER_ROLE,
        headerName: 'My Role',
        initialHide: isColumnHidden(columnIds.USER_ROLE, {
          isHiddenByDefault: false,
        }),
        cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
        sortable: true,
        valueFormatter: ({ value }) => showUserRoleText(value),
      },
    ];

    if (isConsoleAdmin) {
      coldef.push(
        {
          field: columnIds.CREATION_DATE,
          type: 'date',
          headerName: 'Creation Date',
          initialHide: isColumnHidden(columnIds.CREATION_DATE),
          cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
        },
        {
          colId: columnIds.INTEGRATION,
          headerName: 'Integrations',
          headerClass: classNames(
            'IntegrationHeaderColumn',
            isIntegrationRefreshTokenExpired && 'IntegrationHeaderColumn-error',
          ),
          cellRendererSelector: ({ data }) => {
            const { enabledIntegrations } = data;
            if (!enabledIntegrations) return undefined;
            return {
              component: CompanyIntegrationRenderer,
              params: { enabledIntegrations },
            };
          },
          initialHide: isColumnHidden(columnIds.INTEGRATION, {
            isHiddenByDefault: false,
          }),
          cellClass:
            'Spreadsheet_Cell Spreadsheet_Cell-label ConsoleIntegration_CellRenderer',
          valueGetter: integrationValueGetter,
          comparator: integrationComparator,
        },
        {
          field: columnIds.CLOSING_DAY,
          headerName: 'Closing Day of the Month',
          valueFormatter: ({ value }) => {
            return isEmptyOrNull(value)
              ? EMPTY_CELL_VALUE
              : formatOrdinal(value);
          },
          initialHide: isColumnHidden(columnIds.CLOSING_DAY),
        },
        {
          field: columnIds.ADMIN_NAMES,
          headerName: 'Admin Names',
          initialHide: isColumnHidden(columnIds.ADMIN_NAMES),
          filterValueGetter: ({ data }) => {
            if (!data.adminNames) return null;
            return `${data.adminNames.join(' ')} ${data.adminNames
              .map((name) =>
                name
                  .split(' ')
                  .map((subName) => subName[0])
                  .join(''),
              )
              .join(' ')}`;
          },
          cellRenderer: AdminNamesRenderer,
          cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
        },
        {
          colId: columnIds.ACTIONS,
          type: columnIds.ACTIONS,
          lockVisible: true,
          pinned: false,
          maxWidth: 50,
          cellRendererSelector: ({ data }) => {
            if (data.userRole !== USER_ROLES.ROLE_ADMIN) return undefined;
            return {
              component: CompanyContextMenuRenderer,
              params: {
                onIntegrationClick,
                onEdit: onEditCompany,
                onRemove: () => setCompanyToBeRemoved(data),
              },
            };
          },
        },
      );
    }
    return coldef;
  }, [
    isConsoleAdmin,
    handleCompanySelected,
    onEditCompany,
    isColumnHidden,
    isIntegrationRefreshTokenExpired,
  ]);

  return (
    <div className="ManagementConsoleGrid">
      <SpreadsheetToolbar hideLegend>
        <div className="SpreadsheetToolbar_ControlGroup">
          Columns:
          {!isLoading && (
            <ColumnToggle ref={ref} spreadsheetId={SPREADSHEET_ID} />
          )}
        </div>
      </SpreadsheetToolbar>
      <Spreadsheet
        ref={ref}
        animateRows={!window.Cypress}
        columnDefs={colDefs}
        data={companies}
        data-testid={SPREADSHEET_ID}
        loading={isLoading}
        filterParams={filterParams}
        headerHeight={40}
        floatingFiltersHeight={48}
      />
      <ConsoleIntegrationSidebar
        companyData={companyForIntegrationsSidebar}
        onClose={onCloseIntegration}
      />
      <PromptRemovePartnerAccess
        open={!!companyToBeRemoved}
        company={companyToBeRemoved}
        onClose={() => setCompanyToBeRemoved(null)}
      />
    </div>
  );
};

export default ManagementConsoleCompanyGrid;
