/**
 * Displays a styled operator which we use in formulaBreakdown
 *
 * @example
 *   <Operator value="+" />;
 */
const Operator = ({ value }) => (
  <span className="FormulaBreakdown_Item FormulaBreakdown_Item-operator">
    {value}
  </span>
);

export default Operator;
