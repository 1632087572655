export const JOB_TITLES = 'jobTitles';
export const CUSTOM_CHART = 'customChart';
export const CUSTOM_CHART_INFO = 'customChartInfo';
export const EXPENSES_BY_CLASS = 'expensesByClass';
export const PROFIT_AND_LOSS = 'profitAndLoss';
export const MONTHLY_EXPENSES = 'monthlyExpenses';
export const GROSS_MARGIN = 'grossMargin';
export const CASH_FLOW = 'cashFlow';
export const CAC_PAYBACK = 'cacPayback';
export const TOTAL_PAYROLL = 'totalPayroll';
export const NEW_MRR = 'newMrr';
export const CHURNED_MRR = 'churnedMrr';
export const TOTAL_MRR = 'totalMrr';
export const CUSTOMER_LTV = 'customerLTV';
export const TOTAL_EXPENSES = 'totalExpenses';
export const CUSTOMER_ACQUISITION_COST = 'cac';
export const BURN_RATE = 'burnRate';
export const LOGO_CHURN = 'logoChurn';
export const ARPA = 'arpa';
export const REVENUE_BY_PRODUCTS = 'revenueByProduct';
export const ACTUALS = 'actuals';
export const VENDOR_SPEND = 'vendorSpend';
export const CUSTOM_REPORTS = 'customReports';
export const REPORTS_INFO = 'reportsInfo';
export const USER_REPORTS = 'userReports';
export const METRICS_LIST = 'metricsList';
export const REVENUE_INTEGRATION_COLUMNS = 'revenueIntegrationColumns ';
export const REVENUE_INTEGRATION_DATA = 'revenueIntegrationData';
export const REVENUE_INTEGRATION_RULES = 'revenueIntegrationRules';
export const REVENUE_INTEGRATION_REVENUES = 'revenueIntegrationRevenues';
export const UNMAPPED_REVENUE_ENTRIES_COUNT = 'unmappedRevenueEntriesCount';
export const METRIC_TAGS_LIST = 'metricTagsList';
export const COMPANY_DETAILS = 'companyDetails';
export const COMPANIES = 'companies';
export const COMPANY_SETTINGS = 'companySettings';
export const COMPANY_INTEGRATIONS = 'companyIntegrations';
export const NOTIFICATIONS = 'notifications';
export const INDUSTRY_LIST = 'industryList';
export const COMPANY_STATUS = 'companyStatus';
export const COMPANY_ORPHAN_STATUS = 'companyOrphanStatus';
export const PARTNER_NAMES = 'partnerNames';
export const USER_PREFERENCE = 'userPreference';
export const CASH_FORECAST = 'cashForecast';
export const AUTHENTICATE_ONBOARDED_USER = 'authenticateOnboardedUser';
export const ONBOARDING_STATUS = 'onboardingStatus';
export const NET_CASH_FLOW = 'netCashFlow';
export const CASH_BALANCE = 'cashBalance';
export const CASH_IN = 'cashIn';
export const GET_RECENTLY_CREATED_FORECASTING_ADMINS =
  'getRecentlyCreatedForecastingAdmins';
