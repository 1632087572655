import { useState } from 'react';
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';

const ExpenseName = ({ onUpdate, defaultValue, isDisabled }) => {
  const [name, setName] = useState(defaultValue ?? '');
  return (
    <div className="Form_Group Form_Group-halfWidth">
      <FormLabel htmlFor="expense-name" text="Expense Name" />
      <FormField
        id="expense-name"
        name="name"
        data-testid="add-expense-item-title"
        value={name}
        onChange={({ target }) => setName(target.value.trimLeft())}
        onBlur={() => onUpdate({ type: 'SET_EXPENSE_NAME', payload: name })}
        validate={(value) => {
          return !value ? 'Must provide a name' : '';
        }}
        disabled={isDisabled}
      />
    </div>
  );
};

export default ExpenseName;
