// @ts-check
import { useMemo } from 'react';
import OpenInNewTabIcon from '@bill/cashflow.assets/open-in-new-tab';
import './LinkCellRenderer.scss';

/**
 * @typedef {{
 *   value: string;
 * }} LinkCellRendererProps
 */

/** @type {React.FC<LinkCellRendererProps>} */
const LinkCellRenderer = ({ value }) => {
  return useMemo(() => {
    return (
      <div className="LinkCellRenderer">
        <a
          className="link"
          href="https://help.bill.com/direct/s/article/000003533"
          target="_blank"
          rel="noreferrer"
        >
          {value}
          <OpenInNewTabIcon className="LinkCellRenderer_NewTabIcon" />
        </a>
      </div>
    );
  }, [value]);
};

export default LinkCellRenderer;
