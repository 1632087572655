import {
  AUTHENTICATION_SUCCEEDED,
  PERSIST_STATEFUL_ROUTE,
  DISMISS_UNAUTHORIZED,
  PERMISSIONS_CHANGED,
  BILL_AUTHENTICATION_SUCCEEDED,
} from '@/actionTypes/auth';
import {
  RESET_COMPANIES,
  SET_COMPANIES_ON_LOGIN,
} from '@/actionTypes/companies';
import {
  SET_INVITED_USER,
  SET_SCENARIO,
  SET_SCENARIO_ID,
  SET_SELECTED_SCENARIO_USERS,
  SET_SCENARIO_LOADER,
  SET_COMPARE_SCENARIO_ID,
  SWAP_SCENARIOS,
  SET_DEFAULT_SCENARIO,
  SCENARIO_DUPLICATION_STATUS,
  CREATE_SCENARIO,
} from '@/actionTypes/scenario';
import { LOGOUT } from '@/actionTypes/shared';
import { ACCOUNTING_INTEGRATIONS } from '@/constants/integrations';
import { NO_COMPARISON } from '@/constants/scenario';

/** @type {(params: import('@/types/scenario').Scenario) => boolean} */
function hasAccountingIntegration({ enabledIntegrations }) {
  return enabledIntegrations.some((integration) =>
    ACCOUNTING_INTEGRATIONS.includes(integration),
  );
}

const scenarioId = window.sessionStorage.getItem('scenarioId');

/** @typedef {{ name: string; email: string }} InvitedUser */

/**
 * @typedef {{
 *   companyRole?: string;
 *   email?: string;
 *   isExpired?: boolean;
 *   role?: string;
 *   userId?: number;
 * }} ScenarioUser
 */

/**
 * @typedef {{
 *   scenarios: import('@/types/scenario').Scenario[];
 *   scenarioId: number | null;
 *   compareScenarioId: number;
 *   selectedScenerioUsers: ScenarioUser[];
 *   scenarioLoader: boolean;
 *   invitedUser?: InvitedUser;
 *   scenarioDuplicationData?: import('@/types/scenario').Scenario;
 *   error: string;
 * }} ScenarioState
 */

/** @type {ScenarioState} */
const INITIAL_STATE = {
  scenarios: [],
  scenarioId: scenarioId ? Number(scenarioId) : null,
  compareScenarioId: NO_COMPARISON,
  selectedScenerioUsers: [],
  scenarioLoader: false,
  invitedUser: undefined,
  error: '',
};

/**
 * @type {(
 *   state: ScenarioState,
 *   action: import('@/types/extend-redux-toolkit').UnknownAction,
 * ) => ScenarioState}
 */
const scenarioReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTHENTICATION_SUCCEEDED: {
      if (action.payload.scenarioId) {
        return { ...state, scenarioId: action.payload.scenarioId };
      }
      return { ...state };
    }
    case SET_SCENARIO: {
      const scenarios = [...action.payload].map((scenario) => ({
        ...scenario,
        hasAccountingIntegration: hasAccountingIntegration(scenario),
      }));
      scenarios.sort((a, b) => a.name.localeCompare(b.name));
      return {
        ...state,
        scenarios,
      };
    }
    case BILL_AUTHENTICATION_SUCCEEDED: {
      const {
        payload: { companies, selectedCompanyId, previousCompanyId },
      } = action;

      if (selectedCompanyId === previousCompanyId) {
        return state;
      }

      let defaultScenarioId = null;

      if (companies.length > 0 && selectedCompanyId) {
        const selectedCompany = companies.find(
          ({ id }) => id === selectedCompanyId,
        );
        defaultScenarioId = selectedCompany?.defaultScenarioId ?? null;
      }

      return {
        ...state,
        scenarioId: defaultScenarioId,
        compareScenarioId: NO_COMPARISON,
      };
    }
    case SET_COMPANIES_ON_LOGIN: {
      const { payload: companies } = action;
      if (state.scenarioId) {
        return {
          ...state,
          compareScenarioId: NO_COMPARISON,
        };
      }

      let defaultScenarioId = null;

      if (companies.length === 1) {
        const [onlyCompany] = companies;
        ({ defaultScenarioId } = onlyCompany);
      }

      return {
        ...state,
        scenarioId: defaultScenarioId,
        compareScenarioId: NO_COMPARISON,
      };
    }
    case PERMISSIONS_CHANGED:
      return { ...state, scenarios: action.payload.scenarios };
    case PERSIST_STATEFUL_ROUTE:
      return { ...state, scenarioId: action.payload.scenarioId };
    case SET_SCENARIO_ID:
      return { ...state, scenarioId: action.payload };
    case SET_COMPARE_SCENARIO_ID:
      return { ...state, compareScenarioId: action.payload };
    case SET_DEFAULT_SCENARIO: {
      const scenarios = state.scenarios.map((scenario) => ({
        ...scenario,
        isDefault: scenario.scenarioId === action.payload,
      }));
      return { ...state, scenarios, scenarioId: action.payload };
    }
    case SET_SELECTED_SCENARIO_USERS:
      return { ...state, selectedScenerioUsers: action.payload, error: '' };
    case LOGOUT:
      return { ...INITIAL_STATE };
    case SET_SCENARIO_LOADER:
      return { ...state, scenarioLoader: action.payload };
    case SET_INVITED_USER:
      return { ...state, invitedUser: action.payload };
    case SWAP_SCENARIOS:
      return {
        ...state,
        scenarioId: state.compareScenarioId,
        compareScenarioId: state.scenarioId,
      };
    case SCENARIO_DUPLICATION_STATUS:
      return {
        ...state,
        scenarios: action.payload
          ? state.scenarios.map((scenario) =>
              action.payload.scenarioId === scenario.scenarioId
                ? action.payload
                : scenario,
            )
          : state.scenarios,
      };
    case RESET_COMPANIES:
      return { ...state, scenarioId: null };
    case DISMISS_UNAUTHORIZED:
      return { ...state, scenarioId: action.payload.defaultScenarioId };
    case CREATE_SCENARIO:
      return { ...state, scenarios: action.payload.scenarios };
    default:
      return state;
  }
};

export default scenarioReducer;
