import { useState, useReducer, useEffect, useMemo, useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { refreshCompaniesAction } from '@/actions/companies';
import { COMPANY_STATUS } from '@/cacheKeys';
import Button from '@/components/common/Button';
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';
import PhoneNumberField, {
  isPhoneValid,
} from '@/components/common/PhoneNumberField';
import { isEmptyOrNull, isValidWebsite } from '@/helpers/validators';
import { patchRegistration } from '@/services/companies';
import {
  saveCompanyDetails as updateCompanyDetails,
  validateCompanyAddress,
} from '@/services/settingsService';
import CompanyAddress from './Address/CompanyAddress';
import CompanyAddressValidationModal from './Address/CompanyAddressValidationModal';
import IndustryNameField from './Address/IndustryField';
import addressReducer from './Address/addressReducer';
import {
  INITIAL_STATE,
  MAX_CHAR_LIMIT,
  EDIT,
  REQUIRED_ADDRESS_FIELDS,
  SET_ADDRESS,
} from './Address/constants';
import {
  hasAddressChanged,
  isInternationalAddress,
  isSameAddress,
} from './Address/helpers';
import Footer from './Footer';
import useCompanyDetailsQuery from './useCompanyDetailsQuery';
import './CompanyDetails.scss';

export const companyDetailFields = {
  NAME: 'companyName',
  INDUSTRY_ID: 'industryId',
  WEBSITE: 'website',
  PHONE: 'phoneNumber',
  ADDRESS: 'address',
};

/**
 * @typedef {(
 *   | typeof companyDetailFields.INDUSTRY_ID
 *   | typeof companyDetailFields.ADDRESS
 * )[]} HiddenFields
 */

/** @type {HiddenFields} */
const DEFAULT_HIDDEN_FIELDS = [];

/**
 * @typedef {{
 *   companyId?: number;
 *   companyName: string;
 *   website: string;
 *   industryId: string;
 *   address: import('@/components/Settings/Address/types').Address;
 * }} CompanyDetails
 */

/**
 * @typedef {{
 *   mode?: 'read' | 'edit';
 *   setMode?: () => void;
 *   companyId?: number;
 *   isExternallyLoading?: boolean;
 *   showFooter?: boolean;
 *   saveButtonText?: string;
 *   showIndustryAndAddress?: boolean;
 *   hideStreetLineTwo?: boolean;
 *   isSaveExternallyDisabled?: boolean;
 *   isCompanyRegistrationIncomplete?: boolean;
 *   hiddenFields?: HiddenFields;
 *   isLockedModal?: boolean;
 *   onFinish?: (params: CompanyDetails) => void;
 * }} CompanyDetailsProps
 */

/**
 * Creates a new company along with its metadata
 *
 * @type {(companyDetails: CompanyDetails) => Promise<any>}
 */
const finishCompanyRegistration = (companyDetails) =>
  patchRegistration({
    companyId: companyDetails.companyId,
    companyName: companyDetails.companyName,
    companyMetadata: companyDetails,
  });

/**
 * Renders Company Details form and handles create/update of company metadata.
 *
 * @example
 *   // incase of updating company metadata:
 *   <Permissions action={READ_WRITE} subject={COMPANY_SETTINGS}>
 *     <CompanyDetails />
 *   </Permissions>;
 *
 * @example
 *   // incase of creating/finish registration of company if status = pending
 *   <Permissions action={READ_WRITE} subject={COMPANY_SETTINGS}>
 *     <CompanyDetails isCompanyRegistrationIncomplete={true} />
 *   </Permissions>;
 *
 * @type {React.FC<CompanyDetailsProps>}
 */
const CompanyDetail = ({
  mode = EDIT,
  setMode = () => {},
  companyId = null,
  isExternallyLoading = false,
  showFooter = false,
  saveButtonText = 'Save',
  showIndustryAndAddress = false,
  hiddenFields = DEFAULT_HIDDEN_FIELDS,
  isLockedModal = false,
  hideStreetLineTwo = false,
  isSaveExternallyDisabled = false,
  isCompanyRegistrationIncomplete = false,
  onFinish = (_companyDetails) => {},
}) => {
  const [hasNameError, setNameError] = useState(false);
  const [hasIndustryError, setIndustryError] = useState(false);
  const [hasWebsiteError, setWebsiteError] = useState(false);
  const [hasPhoneNumberError, setPhoneNumberError] = useState(false);

  /**
   * @type {[
   *   CompanyDetails,
   *   React.Dispatch<React.SetStateAction<CompanyDetails>>,
   * ]}
   */
  const [companyDetails, setCompanyDetails] = useState({});
  const [address, setAddress] = useReducer(addressReducer, INITIAL_STATE);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { NAME, INDUSTRY_ID, WEBSITE, PHONE, ADDRESS } = companyDetailFields;
  const queryClient = useQueryClient();

  const isEmptyAddress = useMemo(
    () =>
      REQUIRED_ADDRESS_FIELDS.some((field) => isEmptyOrNull(address[field])),
    [address],
  );

  const isSaveBtnDisabled = useMemo(
    () =>
      hasNameError ||
      hasIndustryError ||
      hasWebsiteError ||
      hasPhoneNumberError ||
      isSaveExternallyDisabled ||
      isEmptyAddress ||
      isEmptyOrNull(companyDetails.industryId) ||
      (!showIndustryAndAddress &&
        (!companyDetails.companyName || !companyDetails.website)),
    [
      hasNameError,
      hasIndustryError,
      hasWebsiteError,
      hasPhoneNumberError,
      companyDetails,
      showIndustryAndAddress,
      isSaveExternallyDisabled,
      isEmptyAddress,
    ],
  );

  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();

  const { data, refetch: refetchCompanyDetails } =
    useCompanyDetailsQuery(companyId);

  useEffect(() => {
    if (!companyId || !data) return;
    setCompanyDetails((prevState) => ({
      ...prevState,
      companyName: data.companyName,
      industryId: data.industryId,
      website: data.website,
      phoneNumber: data.phoneNumber,
    }));
    if (data.address) {
      setAddress({ type: SET_ADDRESS, payload: data.address });
    }
  }, [companyId, data]);

  const onChange = useCallback((value, key) => {
    setCompanyDetails((prevState) => {
      return {
        ...prevState,
        [key]: !isEmptyOrNull(value) ? value : null,
      };
    });
  }, []);

  /** @type {React.ChangeEventHandler<HTMLInputElement>} */
  const onInputFieldChange = useCallback(
    ({ target: { name, value } }) => onChange(value, name),
    [onChange],
  );

  /** @type {(params: string) => void} */
  const handleIndustryFieldChange = useCallback(
    (value) => onChange(value, INDUSTRY_ID),
    [INDUSTRY_ID, onChange],
  );

  /** @type {(params: string) => void} */
  const onPhoneNumberChange = useCallback(
    (value) => onChange(value, PHONE),
    [PHONE, onChange],
  );

  const validateCompanyName = useCallback((value) => {
    if (isEmptyOrNull(value)) {
      setNameError(true);
      return 'Company Name is Required!';
    }

    setNameError(false);
    return '';
  }, []);

  const validateWebsite = useCallback((value) => {
    const trimmed = value?.trim();
    if (trimmed && !isValidWebsite(value)) {
      setWebsiteError(true);
      return 'Invalid URL';
    }
    setWebsiteError(false);
    return '';
  }, []);

  const validatePhoneNumber = useCallback((value) => {
    if (!isPhoneValid(value)) {
      setPhoneNumberError(true);
      return 'Please enter complete phone number';
    }
    setPhoneNumberError(false);
    return '';
  }, []);

  const validateAddressForm = () => {
    const completedFields = REQUIRED_ADDRESS_FIELDS.filter(
      (field) => address[field],
    );

    let hasValidZip;
    if (address.zipCode) {
      hasValidZip = isInternationalAddress(address)
        ? true
        : address.zipCode.length === 5;
    }
    // Address related fields are required
    const isValid =
      REQUIRED_ADDRESS_FIELDS.length === completedFields.length && hasValidZip;
    setIsAddressValid(isValid);
    return isValid;
  };

  const handleCancelClick = () => {
    setMode('read');
    setIndustryError(false);
    setIsAddressValid(true);
    setAddress({ type: SET_ADDRESS, payload: data.address ?? INITIAL_STATE });
    setCompanyDetails((prevState) => ({
      ...prevState,
      companyName: data.companyName,
      industryId: data.industryId,
      website: data.website,
      phoneNumber: data.phoneNumber,
    }));
  };

  const saveMetadata = async (addressPayload) => {
    setIsLoading(true);
    const newCompanyDetails = {
      ...companyDetails,
      companyId,
      address: addressPayload,
    };
    try {
      const { data: responseData } = await (isCompanyRegistrationIncomplete
        ? finishCompanyRegistration(newCompanyDetails)
        : updateCompanyDetails(newCompanyDetails));

      if (responseData.success) {
        if (isLockedModal) {
          queryClient.invalidateQueries([COMPANY_STATUS]);
        }
        dispatch(refreshCompaniesAction());
        setMode('read');
        if (companyId) refetchCompanyDetails();
      }
    } catch (e) {
      setErrorMessage(e.response?.data?.error?.errorMessage || e.message);
    }
    setIsLoading(false);
    onFinish(newCompanyDetails);
  };

  const handleAddressSelection = (useOriginalAddress, validationAddress) => {
    const addressPayload = useOriginalAddress
      ? { ...address, zipFiveDigit: address.zipCode }
      : validationAddress;
    saveMetadata({ ...addressPayload, userOverride: useOriginalAddress });
    if (!useOriginalAddress) {
      setAddress({ type: SET_ADDRESS, payload: validationAddress });
    }
    setShowValidationModal(false);
  };

  const onSave = async () => {
    if (!validateAddressForm()) {
      return;
    }

    setErrorMessage(null);
    const isAddressEmpty = REQUIRED_ADDRESS_FIELDS.every(
      (field) => !address[field],
    );
    if (isAddressEmpty) {
      await saveMetadata(null);
      return;
    }

    const isIntl = isInternationalAddress(address);
    // International addresses are not currently validated and need to be saved regardless of updates or not
    if (isIntl) {
      await saveMetadata({
        ...address,
        internationalZip: address.zipCode,
        userOverride: true,
      });
      return;
    }

    const savedAddress = data?.address && {
      ...data.address,
      zipCode: data.address?.zipFiveDigit?.toString(),
    };

    const isAddressUpdated = hasAddressChanged(savedAddress, address);
    // Unchanged addresses need to always be saved or it will be deleted from the company details
    if (!isAddressUpdated) {
      await saveMetadata(address);
      return;
    }

    try {
      setIsLoading(true);
      const {
        street,
        streetLineTwo,
        city,
        country,
        zipCode,
        isInternational,
        state,
      } = address;
      const { data: validationResponse } = await validateCompanyAddress({
        street,
        streetLineTwo,
        city,
        country,
        zipCode,
        isInternational,
        state,
      });
      setSuggestedAddress(validationResponse.data);

      // if the field values are essentially the same, then don't show the modal
      // and save the suggested address as it will have more dependable formatting
      if (isSameAddress(address, validationResponse.data)) {
        handleAddressSelection(false, validationResponse.data);
        return;
      }
      setShowValidationModal(true);
    } catch (e) {
      setErrorMessage(e.response?.data?.error?.errorMessage || e.message);
    }
    setIsLoading(false);
  };

  return (
    <div>
      {errorMessage && (
        <p className="alert alert-danger">
          <b>Error </b> - {errorMessage}
        </p>
      )}
      <div className="CompanyDetails_Wrapper">
        {!showIndustryAndAddress && (
          <div className="CompanyDetails_SectionWrapper">
            <FormLabel htmlFor={NAME} text="Company Name" />
            <FormField
              className="CompanyDetails_Input"
              id={NAME}
              maxLength={100}
              validate={validateCompanyName}
              name={NAME}
              value={companyDetails.companyName}
              onChange={onInputFieldChange}
              disabled={mode !== EDIT}
            />
          </div>
        )}
        {!hiddenFields.includes(INDUSTRY_ID) && (
          <div className="CompanyDetails_SectionWrapper">
            <IndustryNameField
              id={INDUSTRY_ID}
              name={INDUSTRY_ID}
              industryId={companyDetails.industryId}
              disabled={mode !== EDIT}
              onChange={handleIndustryFieldChange}
              error={hasIndustryError}
              setHasError={setIndustryError}
            />
          </div>
        )}

        {!hiddenFields.includes(ADDRESS) && (
          <div className="CompanyDetails_SectionWrapper">
            <CompanyAddress
              address={address}
              mode={mode}
              onChange={setAddress}
              isIncomplete={!isAddressValid}
              hideStreetLineTwo={hideStreetLineTwo}
            />
          </div>
        )}

        {!showIndustryAndAddress && (
          <>
            <div className="CompanyDetails_SectionWrapper">
              <FormLabel htmlFor={PHONE} text="Phone Number" optional />
              <PhoneNumberField
                className="CompanyDetails_Input"
                id={PHONE}
                validate={validatePhoneNumber}
                name={PHONE}
                value={companyDetails.phoneNumber}
                onChange={onPhoneNumberChange}
                disabled={mode !== EDIT}
              />
            </div>

            <div className="CompanyDetails_SectionWrapper">
              <FormLabel htmlFor={WEBSITE} text="Website" />
              <FormField
                className="CompanyDetails_Input"
                id={WEBSITE}
                maxLength={MAX_CHAR_LIMIT}
                validate={validateWebsite}
                name={WEBSITE}
                value={companyDetails.website}
                onChange={onInputFieldChange}
                disabled={mode !== EDIT}
              />
            </div>
          </>
        )}
      </div>

      <CompanyAddressValidationModal
        onClose={() => setShowValidationModal(false)}
        open={showValidationModal}
        originalAddress={address}
        suggestedAddress={suggestedAddress}
        onAddressSelection={handleAddressSelection}
      />

      {mode === EDIT &&
        (showFooter ? (
          <Footer
            onSaveClick={onSave}
            disabledSaveBtn={isSaveBtnDisabled}
            saveBtnTxt={saveButtonText}
            loading={isLoading || isExternallyLoading}
            onCancelClick={handleCancelClick}
          />
        ) : (
          <Button
            data-testid="add-company-save-btn"
            onClick={onSave}
            className="AddCompany_SaveBtn"
            disabled={isSaveBtnDisabled}
            loading={isLoading || isExternallyLoading}
          >
            {saveButtonText}
          </Button>
        ))}
    </div>
  );
};

export default CompanyDetail;
