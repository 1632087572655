import Api from './Api';

/**
 * Retrieve list of companies
 *
 * @type {(
 *   status?: string,
 * ) => Promise<
 *   import('axios').AxiosResponse<
 *     import('@/types/services/backend').RestResponseDtoListCompanyDto
 *   >
 * >}
 */
export const getCompanies = (status) => {
  if (status) {
    return Api.get(`/companies?status=${status}`);
  }

  return Api.get(`/companies`);
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const getCompanyInfo = (id) => {
  return Api.get(`/companies/${id}`);
};

/**
 * getSalaryIncrease is a function which will fetch company wide Salary increase
 * object
 *
 * @param {number} companyId - The companyId
 * @returns {Promise} API response
 */
export const getSalaryIncrease = (companyId) => {
  return Api.get('/companies/settings/salary-increase', {
    params: {
      companyId,
    },
  });
};

/**
 * Edit existing Salary Increase
 *
 * @param {Object} payload - Properties of the salary increase
 * @param {number} payload.salaryIncrementBasisType - The basic for which the
 *   increment would trigger
 * @param {number} payload.customDate - Date selected by user
 * @param {number} payload.salaryIncreasePercentage - Percentage by which annual
 *   salary increses
 * @param {string} payload.frequency - Annual/Semi-Annual increments
 * @param {number} companyId - The companyId
 * @returns {Promise} API response
 */
export const updateSalaryIncrease = (payload, companyId) => {
  return Api.post('/companies/settings/salary-increase', payload, {
    params: {
      companyId,
    },
  });
};

/**
 * Retrieve the names of all partner companies associated with a specific
 * company
 *
 * @type {(companyId: number) => Promise<import('axios').AxiosResponse<import('@/types/api').ApiResponse<string[]>>}
 */
export const getPartnerNames = async (companyId) =>
  Api.get(`/user-companies/${companyId}/partners`);

/**
 * Removes all partner companies' access to a company
 *
 * @type {(companyId: number) => Promise<import('axios').AxiosResponse<import('@/types/api').ApiResponse<number>>}
 */
export const removeAccessToCompany = async (companyId) =>
  Api.delete(`/user-companies/${companyId}`);

/**
 * Check if a company has any users
 *
 * @type {(companyId: number) => Promise<import('axios').AxiosResponse<import('@/types/api').ApiResponse<{isOrphan: boolean}>>}
 */
export const getOrphanStatus = async (companyId) =>
  Api.get(`/user-companies/${companyId}/orphan-status`);

/**
 * Creates a new company along with its metadata
 *
 * @type {(data: import('@/types/services/backend').RegistrationDetailsDto)
 *   => Promise<import('@/types/api').AxiosApiResponse<import('@/types/services/backend').RegistrationStepResponse>}
 */
export const patchRegistration = (data) => {
  const LAST_STEP = 6;
  const currency = data.currency ? data.currency : 'USD';
  /** @type {import('axios').AxiosRequestConfig} */
  const options =
    data.companyId !== undefined
      ? { params: { companyId: data.companyId } }
      : {};
  return Api.patch(
    `/registration`,
    { ...data, currency, step: LAST_STEP },
    options,
  );
};
