// @ts-check
import { useCallback, useEffect, useReducer, useState } from 'react';
import EditWithBorderIcon from '@bill/cashflow.assets/edit-with-border';
import RightArrowIcon from '@bill/cashflow.assets/right-arrow';
import TrashWithBorderIcon from '@bill/cashflow.assets/trash-with-border';
import { getRevenueStream } from '@/components/Revenue/DataMapping/helpers';
import Button from '@/components/common/Button';
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';
import NumberField from '@/components/common/NumberField';
import Select from '@/components/common/Select';
import {
  actionTypes,
  BLANK,
  columnTypes,
  NOT_BLANK_KEY,
  OPERATORS,
} from '@/constants/dataMapping';
import { classNames } from '@/helpers';
import './RuleForm.scss';

const maxCriteriaCount = 3;
const logicalAndOperator = 'And';

const {
  SET_RULE_NAME,
  SET_REVENUE_STREAM,
  SET_PRICING_PLAN,
  SET_COLUMN,
  SET_OPERATOR,
  SET_CRITERIA,
  ADD_CRITERIA,
  DELETE_CRITERIA,
} = actionTypes;

/** @type {(param: import('./types').RuleFormState['operator']) => boolean} */
const isBlankOrNotBlank = (operator) => {
  return [BLANK, NOT_BLANK_KEY].every((i) => !operator.includes(i));
};

/**
 * @type {(
 *   param: import('./types').RuleFormState['operator'],
 * ) => string | never}
 */
const getOperatorType = (value) => {
  const type = value.split('-')[0];
  if (!type) {
    throw new ReferenceError('`operator type` is undefined');
  }
  return type;
};

/**
 * @type {(
 *   param: import('./types').RuleFormState['operator'],
 * ) => string | never}
 */
const getOperator = (value) => {
  const operator = value.split('-')[1];

  if (!operator) {
    throw new ReferenceError('`operator` is undefined');
  }
  return operator;
};

/** @typedef {import('./types').CriteriaFields} CriteriaFields */

/** @typedef {import('./types').RuleFormState} RuleFormState */

/** @type {CriteriaFields} */
const CRITERIA_FIELDS = {
  column: '',
  operator: '',
  criteria: '',
  operatorType: '',
  isCriteriaVisible: true,
};

/** @type {RuleFormState} */
const INITIAL_STATE = {
  name: '',
  revenueStreamId: null,
  pricingPlanId: null,
  criteriaFields: [CRITERIA_FIELDS],
};

/**
 * @type {(
 *   editRule?: import('@/services/revenueService').RevenueIntegrationRule,
 * ) => RuleFormState}
 */
const getState = (editRule) => {
  if (!editRule) {
    return INITIAL_STATE;
  }
  const {
    name,
    revenueStreamId,
    pricingPlanId,
    expression: { criterion, logicalExpr },
  } = editRule;

  /** @type {import('@/services/revenueService').Criteria[]} */
  const criteria = logicalExpr?.criteria ?? [criterion];

  const criteriaFields = criteria.map(({ operand, type, operator, value }) => ({
    column: operand,
    operatorType: type,
    operator,
    criteria: value,
    isCriteriaVisible: isBlankOrNotBlank(operator),
  }));

  return {
    name,
    revenueStreamId,
    pricingPlanId,
    criteriaFields: !criteriaFields.length ? [CRITERIA_FIELDS] : criteriaFields,
  };
};

/**
 * - @type {(params: { ruleName: string; editRuleName?: string; rules:
 *   import('@/services/revenueService').RevenueIntegrationRule[] }) => null |
 *   string}
 */
const validateName = ({ ruleName, editRuleName, rules }) => {
  if (!ruleName) return 'Rule name is required';
  return rules.some(
    ({ name }) =>
      name.toLowerCase() === ruleName.toLowerCase().trim() &&
      name !== editRuleName,
  )
    ? 'Rule names must be unique'
    : null;
};

/**
 * @type {(
 *   state: RuleFormState,
 *   action: import('./types').RuleFormAction,
 * ) => RuleFormState}
 */
const reducer = (state, action) => {
  const { type } = action;

  switch (type) {
    case SET_RULE_NAME:
      return {
        ...state,
        name: action.name,
      };
    case SET_REVENUE_STREAM:
      return {
        ...state,
        revenueStreamId: action.revenueStreamId,
      };
    case SET_PRICING_PLAN:
      return {
        ...state,
        pricingPlanId: action.pricingPlanId,
      };
    case SET_COLUMN: {
      const { index } = action;
      const updatedColumnField = {
        ...state.criteriaFields[index],
        column: action.column,
      };
      const newCriteriaFields = [...state.criteriaFields];
      newCriteriaFields.splice(index, 1, updatedColumnField);
      return {
        ...state,
        criteriaFields: newCriteriaFields,
      };
    }
    case SET_OPERATOR: {
      const { index } = action;
      const updatedOperatorField = {
        ...state.criteriaFields[index],
        operator: getOperator(action.operator),
        operatorType: getOperatorType(action.operator),
      };

      const newCriteriaFields = [...state.criteriaFields];
      newCriteriaFields.splice(index, 1, updatedOperatorField);

      return {
        ...state,
        criteriaFields: newCriteriaFields.map((field) => {
          return {
            ...field,
            isCriteriaVisible: isBlankOrNotBlank(field.operator),
          };
        }),
      };
    }
    case SET_CRITERIA: {
      const { index } = action;
      const updatedCriteriaField = {
        ...state.criteriaFields[index],
        criteria: action.criteria,
      };
      const newCriteriaFields = [...state.criteriaFields];
      newCriteriaFields.splice(index, 1, updatedCriteriaField);
      return {
        ...state,
        criteriaFields: newCriteriaFields,
      };
    }
    case ADD_CRITERIA:
      return {
        ...state,
        criteriaFields: [...state.criteriaFields, CRITERIA_FIELDS],
      };
    case DELETE_CRITERIA: {
      const { index } = action;
      const criteriaFields = [...state.criteriaFields];
      criteriaFields.splice(index, 1);
      return {
        ...state,
        criteriaFields,
      };
    }
    default:
      return state;
  }
};

/**
 * @typedef {{
 *   rules: import('./types').RulePanelState['rules'];
 *   revenue: import('@/services/revenueService').RevenueStreamsWithPricingPlan[];
 *   columns: import('./types').RevenueIntegrationColumn;
 *   editRule: import('./types').Rule;
 *   onClickAdd: import('./types').HandleAddClick;
 *   onCancel: () => void;
 *   onDelete?: import('./types').HandleDeleteRule;
 * }} RulesFormProps
 */

/** @type {React.FC<RulesFormProps>} */
const RuleForm = ({
  rules,
  revenue,
  columns,
  editRule,
  onClickAdd,
  onCancel,
  onDelete,
}) => {
  const [formState, setFormState] = useReducer(reducer, getState(editRule));
  const [hasError, setHasError] = useState(false);

  /**
   * @typedef {{
   *   isPricingPlan: boolean;
   *   pricingPlanList: import('@/services/revenueService').PricingPlans;
   * }} PricingPlanState
   */

  /**
   * @type {[
   *   PricingPlanState,
   *   React.Dispatch<React.SetStateAction<PricingPlanState>>,
   * ]}
   */
  const [pricingPlans, setPricingPlans] = useState({
    pricingPlanList: [],
    isPricingPlan: true,
  });

  /**
   * @type {(
   *   revenueStream: import('@/services/revenueService').RevenueStreamsWithPricingPlan[],
   * ) => import('@/services/revenueService').PricingPlans}
   */
  const getPricingPlans = useCallback(
    (revenueStream) =>
      revenueStream.find(
        ({ streamId }) => Number(formState.revenueStreamId) === streamId,
      ).pricingPlans,
    [formState.revenueStreamId],
  );

  /** @type {(value: string, index: number) => void} */
  const handleChangeOperator = useCallback(
    (value, index) => {
      const operator = getOperator(value);
      const operatorType = getOperatorType(value);

      const shouldShowCriteriaField = isBlankOrNotBlank(operator);
      if (
        !shouldShowCriteriaField ||
        (formState.operatorType === columnTypes.STRING &&
          operatorType === columnTypes.NUMBER)
      ) {
        setFormState({ type: SET_CRITERIA, index, criteria: '' });
      }
      setFormState({ type: SET_OPERATOR, index, operator: value });
    },
    [setFormState, formState.operatorType],
  );

  useEffect(() => {
    if (formState.revenueStreamId) {
      const pricingPlansList = getPricingPlans(revenue);
      setPricingPlans({
        pricingPlanList: pricingPlansList,
        isPricingPlan: !!pricingPlansList.length,
      });
      setFormState({
        type: SET_PRICING_PLAN,
        pricingPlanId: pricingPlansList.length
          ? Number(pricingPlansList[0].planId)
          : null,
      });
    } else {
      setPricingPlans({
        pricingPlanList: [],
        isPricingPlan: true,
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [formState.revenueStreamId]);

  const isMaxCriteria = formState.criteriaFields.length === maxCriteriaCount;

  return (
    <div className="RuleForm">
      {editRule && (
        <div className="RuleForm_Icons">
          <EditWithBorderIcon className="RulesSideBarContent_ButtonIcon RulesSideBarContent_EditIcon" />
          <button
            className="RulesSideBarContent_ButtonIcon"
            onClick={() => onDelete(editRule.name)}
            aria-label="Delete"
          >
            <TrashWithBorderIcon className="RulesSideBarContent_DeleteIcon" />
          </button>
        </div>
      )}
      <div className="RuleForm_Wrapper">
        <FormLabel htmlFor="rule-name" text="Name of Rule" />
        <FormField
          id="rule-name"
          data-test="rule-name"
          validate={() => {
            const msg = validateName({
              ruleName: formState.name,
              editRuleName: editRule?.name,
              rules,
            });
            setHasError(!!msg);
            return msg;
          }}
          name="rule-name"
          placeholder="Enter Name"
          maxLength={50}
          value={formState.name}
          onChange={
            /** @type {React.ChangeEventHandler<HTMLInputElement>} */ ({
              target,
            }) =>
              setFormState({
                type: SET_RULE_NAME,
                name: target.value,
              })
          }
        />

        <p className="RuleForm_SectionLabel">Destination</p>
        <div className="RuleForm_Destination">
          <Select
            id="rule-revenueStreamId"
            name="rule-revenueStreamId"
            data-testid="rule-revenueStreamId"
            value={formState.revenueStreamId}
            onChange={
              /** @type {React.ChangeEventHandler<HTMLSelectElement>} */ ({
                target,
              }) =>
                setFormState({
                  type: SET_REVENUE_STREAM,
                  revenueStreamId: Number(target.value),
                })
            }
          >
            <option key="" value="" disabled>
              Revenue Stream
            </option>
            {revenue.map(({ streamId, streamName }) => (
              <option key={streamId} value={streamId}>
                {streamName}
              </option>
            ))}
          </Select>

          {pricingPlans.isPricingPlan && (
            <>
              <RightArrowIcon
                className="RuleForm_ArrowIcon"
                aria-hidden="true"
              />
              <Select
                id="rule-pricingPlanId"
                name="rule-pricingPlanId"
                data-testid="rule-pricingPlanId"
                value={formState.pricingPlanId}
                onChange={
                  /** @type {React.ChangeEventHandler<HTMLSelectElement>} */ ({
                    target,
                  }) =>
                    setFormState({
                      type: SET_PRICING_PLAN,
                      pricingPlanId: Number(target.value),
                    })
                }
              >
                <option key="" value="" disabled>
                  Pricing plan
                </option>
                {pricingPlans.pricingPlanList.map(({ planId, planName }) => (
                  <option key={planId} value={planId}>
                    {planName}
                  </option>
                ))}
              </Select>
            </>
          )}
        </div>
      </div>
      <p className="RuleForm_SectionLabel">Criteria</p>
      {formState.criteriaFields.map((criteria, index) => {
        const isLastIndex = index === formState.criteriaFields.length - 1;

        return (
          <>
            {index > 0 && <span className="RuleForm_Operator">AND</span>}

            <div
              className={classNames(
                'RuleForm_Criteria',
                formState.criteriaFields.length > 1 && 'hasDeleteButton',
                !isLastIndex && 'RuleForm_Criteria-border',
              )}
              // eslint-disable-next-line react/no-array-index-key -- predates description requirement
              key={index}
            >
              <Select
                id="column"
                name="column"
                data-testid="column"
                value={criteria.column}
                onChange={
                  /** @type {React.ChangeEventHandler<HTMLSelectElement>} */ ({
                    target,
                  }) =>
                    setFormState({
                      type: SET_COLUMN,
                      index,
                      column: target.value,
                    })
                }
              >
                <option key="" value="" disabled>
                  Column Name
                </option>
                {columns.map(({ label, name }) => (
                  <option value={name} key={label}>
                    {label}
                  </option>
                ))}
              </Select>
              <RightArrowIcon
                className="RuleForm_ArrowIcon"
                aria-hidden="true"
              />
              <Select
                id="operator"
                name="operator"
                data-testid="operator"
                value={
                  criteria.operatorType && criteria.operator
                    ? `${criteria.operatorType}-${criteria.operator}`
                    : ''
                }
                onChange={({ target }) =>
                  handleChangeOperator(target.value, index)
                }
              >
                <option key="" value="" disabled>
                  Operator
                </option>
                {Object.keys(OPERATORS).map((type) => (
                  <optgroup label={type} key={type}>
                    {Object.keys(OPERATORS[type]).map((value) => {
                      const valueText = `${type}-${value}`;
                      return (
                        <option value={valueText} key={valueText}>
                          {OPERATORS[type][value]}
                        </option>
                      );
                    })}
                  </optgroup>
                ))}
              </Select>
              {criteria.isCriteriaVisible && (
                <>
                  <RightArrowIcon
                    className="RuleForm_ArrowIcon"
                    aria-hidden="true"
                  />
                  {criteria.operatorType === columnTypes.STRING ? (
                    <FormField
                      id="criteria"
                      name="criteria"
                      data-testid="criteria_string"
                      placeholder="Enter Criteria"
                      value={criteria.criteria}
                      onChange={
                        /** @type {React.ChangeEventHandler<HTMLInputElement>} */ ({
                          target,
                        }) =>
                          setFormState({
                            type: SET_CRITERIA,
                            index,
                            criteria: target.value,
                          })
                      }
                    />
                  ) : (
                    <NumberField
                      id="criteria"
                      data-testid="criteria_number"
                      placeholder="Enter Criteria"
                      name="criteria"
                      className="RuleForm_NumberField"
                      value={criteria.criteria}
                      onChange={
                        /** @type {React.ChangeEventHandler<HTMLInputElement>} */ ({
                          target,
                        }) =>
                          setFormState({
                            type: SET_CRITERIA,
                            index,
                            criteria: target.value,
                          })
                      }
                    />
                  )}
                </>
              )}
              {formState.criteriaFields.length > 1 && (
                <button
                  className="RuleForm_DeleteCriteriaButton"
                  onClick={() => setFormState({ type: DELETE_CRITERIA, index })}
                  aria-label="Delete"
                >
                  <TrashWithBorderIcon className="RulesSideBarContent_DeleteIcon" />
                </button>
              )}
            </div>
          </>
        );
      })}
      <button
        className="RuleForm_AddCriteriaButton"
        onClick={() => setFormState({ type: ADD_CRITERIA })}
        disabled={isMaxCriteria}
      >
        + Add criteria
      </button>
      <Button
        className="Button-primary"
        data-testid="rule-add-button"
        disabled={
          hasError ||
          !formState.name ||
          !formState.revenueStreamId ||
          (pricingPlans.isPricingPlan && !formState.pricingPlanId) ||
          formState.criteriaFields.some(
            (field) =>
              !field.column ||
              !field.operator ||
              (field.isCriteriaVisible && !field.criteria),
          )
        }
        onClick={() => {
          const rule = {
            name: formState.name,
            revenueStreamId: formState.revenueStreamId,
            pricingPlanId: formState.pricingPlanId,
            revenueStream: getRevenueStream(formState.revenueStreamId, revenue),
            expression: {},
          };

          if (formState.criteriaFields.length > 1) {
            rule.expression.criterion = null;
            rule.expression.logicalExpr = {
              operator: logicalAndOperator,
              criteria: formState.criteriaFields.map((criterion) => ({
                type: criterion.operatorType,
                operand: criterion.column,
                operator: criterion.operator,
                value: criterion.criteria,
              })),
            };
          }

          if (formState.criteriaFields.length === 1) {
            const criterion = formState.criteriaFields[0];
            rule.expression.logicalExpr = null;
            rule.expression.criterion = {
              type: criterion.operatorType,
              operand: criterion.column,
              operator: criterion.operator,
              value: criterion.criteria,
            };
          }

          onClickAdd(rule);
        }}
      >
        {editRule ? 'Save' : 'Add'}
      </Button>
      <Button
        className="Button-primaryLink"
        data-testid="rule-cancel-button"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </div>
  );
};

export default RuleForm;
