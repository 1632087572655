import RedirectToExternal from '@/pages/Registration/RedirectToExternal';

const withProtectedRoute =
  (Component, { protectedBy }) =>
  ({ ...props }) => {
    if (props[protectedBy]) {
      return <Component {...props} />;
    }

    return <RedirectToExternal url="http://finmark.com/get-started" />;
  };

export default withProtectedRoute;
