import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import './Chip.scss';

/**
 * Creates a chip/tag with the given background color
 *
 * @example
 *   <Chip color="#f00" data-testid="foo">
 *     This can be text, an icon, etc
 *   </Chip>;
 */
function Chip({ color, 'data-testid': dataTestId, className, children }) {
  return (
    <div
      className={classNames('Chip', className)}
      style={{ backgroundColor: color }}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
}

Chip.propTypes = {
  /** Content to display within the component */
  'children': PropTypes.node,
  /** Additional class(es) to apply to the Chip component */
  'className': PropTypes.string,
  /** CSS color value for the chip background */
  'color': PropTypes.string.isRequired,
  /** A unique identifier to locate the element in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
};

export default Chip;
