// @ts-check
import camelCase from 'camelcase';
import { classNames } from '@/helpers';
import { ReactComponent as FaceIcon } from '@/assets/icons/svg/face.svg';
import './FeedbackFace.scss';

/** @typedef {import('@/types/exportableReport').FeedbackRating} FeedbackRating */

/**
 * @typedef {{
 *   variant: FeedbackRating;
 *   onClick?: () => void;
 * }} FeedbackFaceProps
 */

/**
 * Renders a feedback icon depending on the variant passed in
 *
 * @example
 *   <FeedbackFace variant={feedbackRating} onClick={onClick} />;
 *
 * @type {(props: FeedbackFaceProps) => React.ReactElement}
 */
const FeedbackFace = ({ variant, onClick }) => {
  return (
    <FaceIcon
      onClick={onClick}
      className={classNames('FaceIcon', `FaceIcon-${camelCase(variant)}`)}
    />
  );
};

export default FeedbackFace;
