import {
  SET_ERROR,
  SET_PRICE_PLANS_LIST,
  SET_REVENUE_ERROR,
  SET_REVENUE_MODAL_ERROR,
  SET_FINANCING_SOURCE_LIST,
  UPDATE_PRODUCT,
  UPDATE_PLAN,
  HANDLE_STREAM_DATA_CHANGE,
  UPDATE_PRODUCTS,
  UPDATE_PRODUCTS_LIST,
  UPDATE_PLANS,
  SET_REVENUE_STREAM,
  CLEAR_STREAM,
  SET_PRODUCT_REVENUE_LIST,
  UPDATE_CUSTOM_REVENUE,
  SET_REVENUE_STREAM_TOTAL,
  SET_REVENUE_DETAILS_LIST,
  SET_REVENUE_STREAMS_LIST,
  SET_REVENUE_STREAM_RECORD_TO_BE_EDIT,
  ADD_NEW_PRICING_PLAN,
  ADD_NEW_PRODUCT,
  UPDATE_PRICING_PLAN,
  SET_PRODUCT_RECORD_TO_BE_EDIT,
  REMOVE_PRICING_PLAN_BY_INDEX,
  SET_REVENUE_STREAM_WITH_PLANS,
  SET_FINANCING_MODAL_ERROR,
  SET_REFRESH_ACTUALS_ONLY,
} from '@/actionTypes/revenue';
import { LOGOUT } from '@/actionTypes/shared';
import { DRIVER } from '@/constants/revenueStream';
import { isEmptyOrNull } from '@/helpers/validators';

const INITIAL_STATE = {
  pricePlansList: [],
  financingSourceList: [],
  financing: {},
  lastUpdatedFinancingId: null,
  revenueByMonthlyData: {
    data: [],
  },
  error: '',
  revenueModalError: [],
  revenueStream: {
    id: '',
    expenseGroupId: '',
    revenueName: '',
    revenueType: '',
    products: [],
    plansAssociated: [],
    noOfProducts: '',
    acquisitionGrowthRate: '',
    churnRate: '',
    driverType: DRIVER.MONTHLY_GROWTH,
    startDate: '',
    endDate: '',
    noEndDate: true,
    initialRevenueAmount: null,
    monthlyGrowthRate: '',
    customRevenue: [],
    conversionOverTime: [100, 0, 0],
    conversionTimeInMonth: 3,
    annualQuota: 1000000,
    attainmentDuringRamp: [17, 34, 50, 67, 84, 100],
    salesRepRampTime: 6,
    quotaAttainment: 100,
  },
  productsRevenueList: null,
  revenueSteamTotal: null,
  revenueSteamDetailsList: null,
  refreshActualsOnly: false,
  lastUpdatedRevenueId: null,
  revenueStreamsList: null,
  revenueStreamRecordToBeEdit: null,
  newProduct: {
    name: '',
    pricingPlans: [],
  },
};

const revenues = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ERROR:
      return { ...state, error: action.payload };
    case SET_FINANCING_MODAL_ERROR:
      return { ...state, financingModalError: action.payload };
    case SET_PRICE_PLANS_LIST:
      return {
        ...state,
        pricePlansList: action.payload,
        error: null,
      };
    case SET_REVENUE_STREAM_RECORD_TO_BE_EDIT: {
      const revenueStreamRecordToBeEdit = action.payload;
      if (Array.isArray(revenueStreamRecordToBeEdit?.customValues)) {
        revenueStreamRecordToBeEdit.customValues =
          revenueStreamRecordToBeEdit.customValues.map((customValue) => {
            const newValue = { ...customValue };
            newValue.custom = customValue.custom.map((entry) => {
              // On the front-end, we assume and use year as a number, but the API returns its string value
              return { ...entry, year: Number(entry.year) };
            });
            return newValue;
          });
      }
      return {
        ...state,
        revenueStreamRecordToBeEdit,
      };
    }
    case SET_FINANCING_SOURCE_LIST: {
      const { financingSourceList, lastUpdatedFinancingId } = action.payload;
      const financingSourceListSorted = [...financingSourceList].sort(
        (a, b) => a.id - b.id,
      );
      let lastUpdatedFinancingItem;
      if (!isEmptyOrNull(lastUpdatedFinancingId)) {
        lastUpdatedFinancingItem = financingSourceListSorted.find(
          (item) => item.id === lastUpdatedFinancingId,
        );
      }
      return {
        ...state,
        financingSourceList,
        lastUpdatedFinancingItem,
        financingModalError: '',
      };
    }
    case SET_REVENUE_MODAL_ERROR:
      return { ...state, revenueModalError: action.payload };
    case SET_REVENUE_ERROR:
      return { ...state, error: action.payload };
    case UPDATE_PRODUCT:
      // eslint-disable-next-line no-case-declarations -- predates description requirement
      const products = [...state.revenueStream.products];
      products[action.payload.index] = {
        ...products[action.payload.index],
        [action.payload.name]: action.payload.value,
      };
      return {
        ...state,
        revenueStream: { ...state.revenueStream, products },
      };
    case UPDATE_PLAN:
      // eslint-disable-next-line no-case-declarations -- predates description requirement
      const plans = [...state.revenueStream.plansAssociated];
      plans[action.payload.index] = {
        ...plans[action.payload.index],
        [action.payload.name]: action.payload.value,
      };
      return {
        ...state,
        revenueStream: { ...state.revenueStream, plansAssociated: plans },
      };
    case UPDATE_CUSTOM_REVENUE:
      return {
        ...state,
        revenueStream: {
          ...state.revenueStream,
          customRevenue: action.payload,
        },
      };
    case UPDATE_PRODUCTS:
      return {
        ...state,
        revenueStream: { ...state.revenueStream, products: action.payload },
      };
    case UPDATE_PRODUCTS_LIST:
      return {
        ...state,
        pricePlansList: [
          ...state.pricePlansList,
          ...action.payload.pricingPlans,
        ],
      };
    case UPDATE_PLANS:
      return {
        ...state,
        revenueStream: {
          ...state.revenueStream,
          plansAssociated: action.payload,
        },
      };
    case HANDLE_STREAM_DATA_CHANGE:
      return {
        ...state,
        revenueStream: {
          ...state.revenueStream,
          [action.payload.name]: action.payload.value,
        },
      };
    case SET_REVENUE_STREAM:
      return { ...state, revenueStream: action.payload };
    case CLEAR_STREAM:
      return { ...state, revenueStream: { ...INITIAL_STATE.revenueStream } };
    case LOGOUT:
      return { ...INITIAL_STATE };
    case SET_PRODUCT_REVENUE_LIST:
      return { ...state, productsRevenueList: action.payload };
    case SET_REVENUE_STREAM_TOTAL:
      return { ...state, revenueStreamTotal: action.payload };
    case SET_REVENUE_DETAILS_LIST:
      return { ...state, revenueSteamDetailsList: action.payload };
    case SET_REVENUE_STREAMS_LIST: {
      const { revenueStreamsList, lastUpdatedRevenueId } = action.payload;
      let lastUpdatedRevenueStream;
      if (!isEmptyOrNull(lastUpdatedRevenueId)) {
        lastUpdatedRevenueStream = revenueStreamsList?.products.find(
          (item) => Number(item.id) === lastUpdatedRevenueId,
        );
      }

      return {
        ...state,
        revenueStreamsList,
        lastUpdatedRevenueStream,
      };
    }
    case ADD_NEW_PRODUCT:
      return {
        ...state,
        newProduct: {
          ...state.newProduct,
          [action.payload.name]: action.payload.value,
        },
      };
    case ADD_NEW_PRICING_PLAN:
      return {
        ...state,
        newProduct: {
          ...state.newProduct,
          pricingPlans: [...state.newProduct.pricingPlans, action.payload],
        },
      };
    case SET_PRODUCT_RECORD_TO_BE_EDIT:
      return {
        ...state,
        newProduct: action.payload,
        productToEdit: action.payload,
      };
    case REMOVE_PRICING_PLAN_BY_INDEX:
      // eslint-disable-next-line no-case-declarations -- predates description requirement
      const pricingPlans = [...state.newProduct.pricingPlans];
      pricingPlans.splice(action.payload.index, 1);
      return {
        ...state,
        newProduct: {
          ...state.newProduct,
          pricingPlans,
        },
      };
    case UPDATE_PRICING_PLAN:
      // eslint-disable-next-line no-case-declarations -- predates description requirement
      const updatedPricingPlans = [...state.newProduct.pricingPlans];
      updatedPricingPlans[action.payload.index] = action.payload.pricingPlan;
      return {
        ...state,
        newProduct: {
          ...state.newProduct,
          pricingPlans: updatedPricingPlans,
        },
      };
    case SET_REVENUE_STREAM_WITH_PLANS:
      return {
        ...state,
        revenueStream: {
          ...state.revenueStream,
          ...action.payload,
        },
      };
    case SET_REFRESH_ACTUALS_ONLY:
      return { ...state, refreshActualsOnly: action.payload };
    default:
      return state;
  }
};

export default revenues;
