// @ts-check
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  subscribeToOnboardingStatusAction,
  pollAuthenticateOnboardUser,
} from '@/actions/billOnboarding';
import { setDate } from '@/actions/shared';
import OnboardingError from '@/components/BillOnboarding/OnboardingError';
import OnboardingProcessing from '@/components/BillOnboarding/OnboardingProcessing';
import SetupRolesAndPermissions from '@/components/BillOnboarding/SetupRolesAndPermissions';
import TermsAndCondition from '@/components/BillOnboarding/TermsAndCondition';
import { onboardingStatus } from '@/components/BillOnboarding/constants';
import GlobalLoader from '@/components/common/GlobalLoader';
import { MONTHLY } from '@/constants/dateTime';
import { DASHBOARD_PATH } from '@/constants/pages';
import { USER_ROLES } from '@/constants/permissions';
import { BillProfileType } from '@/contexts/BillSessionProvider';
import {
  getDateOffsetByMonths,
  getFormattedDateFromTimeStamp,
} from '@/helpers/dateFormatter';
import useBillBaseUrl from '@/hooks/useBillBaseUrl';
import useBillSession from '@/hooks/useBillSession';
import useTypedSelector from '@/hooks/useTypedSelector';
import useWsSubscription from '@/hooks/useWsSubscription';
import { pendoTrackEvent } from '@/services/analyticsService';
import useBillAuthentication from './useBillAuthentication';
import useNonOnboardedUserToken from './useNonOnboardedUserToken';
import useOnboardStatus from './useOnboardStatus';

/** @type {string[]} */
const allowedProfileTypes = [
  BillProfileType.Admin,
  BillProfileType.Accountant,
  BillProfileType.Approver,
];

const ONBOARDING_FAIL_ERROR_TEXT =
  'There was an error while setting up Cash Flow Forecasting. Please reach out to support for help.';
const SESSION_INFO_ERROR_TEXT =
  'There was error loading Cash Flow Forecasting. Please reach out to support for help.';

/**
 * Checks user's authentication, onboarding status and renders the appropriate
 * component based on its state
 *
 * @type {() => import('react').ReactElement}
 */
const BillOnboarding = () => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const history = useHistory();
  const billBaseUrl = useBillBaseUrl();
  const [isOnboardStatusSubscribed, setOnboardStatusSubscribed] =
    useState(false);
  /**
   * @type {ReturnType<
   *   typeof useState<
   *     import('@/types/services/backend').CompanyOnboardingStatusDto['onboardingStatus']
   *   >
   * >}
   */
  const [currentOnboardingStatus, setCurrentOnboardingStatus] = useState(
    onboardingStatus.STARTED,
  );
  const selectedCompanyId = useTypedSelector(
    ({ companies }) => companies.selectedCompanyId,
  );
  const jwtToken = useTypedSelector(({ auth }) => auth.token);
  const isAuthenticating = useTypedSelector(
    ({ auth }) => auth.isAuthenticating,
  );
  const userRole = useTypedSelector(({ auth }) => auth.userInfo.userRole);
  const trackOnboardingTime = useRef({
    start: null,
    tncAccepted: null,
  });
  const onTncAccepted = useCallback((time) => {
    if (!trackOnboardingTime.current.tncAccepted) {
      trackOnboardingTime.current.tncAccepted = time;
    }
  }, []);

  useEffect(() => {
    document.title = 'Setup Planning';
    if (!trackOnboardingTime.current.start) {
      const timestamp = new Date(performance.now() + performance.timeOrigin);
      pendoTrackEvent('forecasting_onboarding_time', {
        timestamp: timestamp.getTime(),
      });
      trackOnboardingTime.current.start = timestamp.getTime();
    }
  }, []);

  const authenticate = useBillAuthentication();

  const {
    isSessionLoading,
    sessionInfo,
    error: isSessionInfoError,
  } = useBillSession();

  useEffect(() => {
    authenticate();
  }, [authenticate]);

  const {
    data: onboardingState,
    isError: isOnboardingStatusError,
    refetch: refetchOnboardingStatus,
  } = useOnboardStatus({ enabled: isOnboardStatusSubscribed });

  const { data: nonOnboardedUser } = useNonOnboardedUserToken({
    enabled: !jwtToken && !isAuthenticating,
  });

  const isOnboarded = useMemo(
    () =>
      onboardingState?.tncAccepted &&
      onboardingState?.onboardingStatus === onboardingStatus.COMPLETED,
    [onboardingState],
  );

  const reAuthenticate = useCallback(async () => {
    await refetchOnboardingStatus();
    dispatch(pollAuthenticateOnboardUser());
  }, [dispatch, refetchOnboardingStatus]);

  /** @type {import('@/actions/billOnboarding').OnboardStatusCallback} */
  const onMessage = useCallback(
    async (payload) => {
      const { status } = payload;
      if (
        status === onboardingStatus.COMPLETED ||
        status === onboardingStatus.ROLE_ASSIGNMENT_PENDING
      ) {
        await reAuthenticate();
      }

      setCurrentOnboardingStatus(status);
    },
    [reAuthenticate],
  );

  useEffect(() => {
    if (jwtToken && isOnboarded) {
      const timestamp = new Date(
        performance.now() + performance.timeOrigin,
      ).getTime();
      pendoTrackEvent('forecasting_onboarding_time', {
        start: trackOnboardingTime.current.start,
        end: timestamp,
        tnc_accepted: trackOnboardingTime.current.tncAccepted,
        difference: timestamp - trackOnboardingTime.current.start,
      });
      trackOnboardingTime.current = {
        start: null,
        tncAccepted: null,
      };
      const startDate = getFormattedDateFromTimeStamp(
        getDateOffsetByMonths(new Date(), -3),
      );
      const endDate = getFormattedDateFromTimeStamp(
        getDateOffsetByMonths(new Date(), 5),
      );
      dispatch(setDate(startDate, endDate, MONTHLY));
      history.replace(DASHBOARD_PATH);
    }
  }, [jwtToken, selectedCompanyId, isOnboarded, history, dispatch]);

  useEffect(() => {
    setCurrentOnboardingStatus(onboardingState?.onboardingStatus);
  }, [onboardingState?.onboardingStatus]);

  useWsSubscription(async () => {
    if (isSessionLoading || (!nonOnboardedUser?.identityToken && !jwtToken))
      return null;
    const subscription = dispatch(
      subscribeToOnboardingStatusAction(
        {
          orgId: sessionInfo.organizationId,
          tempIdentityToken: nonOnboardedUser?.identityToken,
        },
        onMessage,
      ),
    );
    await subscription;
    setOnboardStatusSubscribed(true);
    return subscription;
  }, [sessionInfo, isSessionLoading, onMessage, nonOnboardedUser, jwtToken]);

  if (isOnboardingStatusError) {
    return <OnboardingError subText={ONBOARDING_FAIL_ERROR_TEXT} />;
  }
  if (!isSessionLoading && isSessionInfoError) {
    return <OnboardingError subText={SESSION_INFO_ERROR_TEXT} />;
  }
  if (!onboardingState || isSessionLoading) {
    return <GlobalLoader />;
  }

  if (
    !allowedProfileTypes.includes(sessionInfo?.profile.type) &&
    onboardingState.onboardingStatus !== onboardingStatus.COMPLETED &&
    onboardingState.onboardingStatus !==
      onboardingStatus.ROLE_ASSIGNMENT_PENDING
  ) {
    window.location.replace(billBaseUrl);
    return <GlobalLoader />;
  }
  if (!onboardingState?.tncAccepted) {
    return (
      <TermsAndCondition
        refetchOnboardingStatus={refetchOnboardingStatus}
        onTncAccepted={onTncAccepted}
      />
    );
  }
  if (
    onboardingState.onboardingStatus ===
      onboardingStatus.ROLE_ASSIGNMENT_PENDING &&
    userRole === USER_ROLES.ROLE_ADMIN
  ) {
    return <SetupRolesAndPermissions reAuthenticate={reAuthenticate} />;
  }
  if (
    onboardingState.onboardingStatus ===
      onboardingStatus.ROLE_ASSIGNMENT_PENDING &&
    userRole === USER_ROLES.ROLE_USER
  ) {
    return (
      <OnboardingProcessing
        currentOnboardingStatus={currentOnboardingStatus}
        isOnboardingComplete
      />
    );
  }
  if (
    onboardingState.onboardingStatus !== onboardingStatus.COMPLETED ||
    (onboardingState.onboardingStatus === onboardingStatus.COMPLETED &&
      !jwtToken)
  ) {
    return (
      <OnboardingProcessing
        currentOnboardingStatus={currentOnboardingStatus}
        isOnboardingComplete={false}
      />
    );
  }
  return <GlobalLoader />;
};

export default BillOnboarding;
