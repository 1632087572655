import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useChart } from 'react-jsx-highcharts';
import PropTypes from 'prop-types';
import { getDateLabel } from '@/components/Charts/helpers';
import { MONTHLY } from '@/constants/dateTime';
import { formatDateWithShortYear } from '@/helpers/dateFormatter';
import { isEmptyOrNull } from '@/helpers/validators';

const generateTooltipId = (chartId) => `chartTooltip-${chartId}`;

const getChartMetrics =
  ({ scenarios, context }) =>
  (valueKey) => {
    const { x } = context;
    const { chart, options } = context.series;

    const sortedScenarioPoints = scenarios.map(({ name }) => {
      const series = chart.series.find(
        (s) => s.options.stack === name && s.options.name === options.name,
      );
      return series?.points?.find((p) => p.x === x);
    });

    let mainMetrics;
    if (valueKey || !options.stacking) {
      mainMetrics = sortedScenarioPoints.map(
        (point) => point?.[valueKey ?? 'y'],
      );
    } else {
      mainMetrics = scenarios.map(
        ({ name }) =>
          chart.series.reduce((total, series) => {
            if (series.options.stack === name) {
              const point = series?.points?.find((p) => p.x === x);
              return point ? total + point.y : total;
            }
            return total;
          }, 0),
        [],
      );
    }
    const mainMetricVariance = mainMetrics.reduce((variance, value) => {
      if (isEmptyOrNull(value)) return null;
      return !isEmptyOrNull(variance) ? value - variance : null;
    }, 0);

    return {
      chartMetrics: sortedScenarioPoints,
      mainMetrics,
      mainMetricVariance,
    };
  };

/**
 * Renders the given content into a Highchart tooltip by creating a portal to
 * the container created by Highcharts.
 *
 * @example
 *   <HighchartsChart>
 *     <HighchartsTooltipPortal>
 *       {(context) => <div>{context.y}</div>}
 *     </HighchartsTooltipPortal>
 *   </HighchartsChart>;
 */
function HighchartsTooltipPortal({ children, endDate, timePeriod, ...props }) {
  const isInit = useRef(false);
  const { object: chart } = useChart();
  const [tooltipContext, setTooltipContext] = useState(null);

  useEffect(() => {
    const { index, tooltip } = chart;

    /** @see https://api.highcharts.com/highcharts/tooltip.formatter */
    function formatter() {
      // Force Highcharts to create the tooltip container, so we can attach a
      // portal to it. Otherwise, it is not created until first hover.
      if (!isInit.current) {
        isInit.current = true;
        tooltip.refresh(this.point || this.points.map(({ point }) => point));
      }

      setTooltipContext(this);
      return `<div id="${generateTooltipId(index)}"></div>`;
    }

    chart.update(
      {
        tooltip: {
          formatter,
          enabled: true,
          ...props,
        },
      },
      false,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [chart]);

  const node = document.getElementById(generateTooltipId(chart.index));

  // Sometimes, Highcharts returns an empty series object while
  // rerendering
  const { chart: chartFromContext } = tooltipContext?.series ?? {};

  if (!node || !chartFromContext) return null;

  const { x: xValue, y: yValue } = tooltipContext;
  const hoveredScenario = {
    ...tooltipContext.series.options.custom.scenario,
    mainMetric: yValue,
  };

  // Show the scenario for the hovered series first
  const scenarios = Object.values(
    chartFromContext.series.reduce((accum, s) => {
      // default name set in the event retrieving scenarios is slow
      const { scenario = { name: '-' } } = s.options.custom;
      if (accum[scenario.name]) return accum;
      return {
        ...accum,
        [scenario.name]: scenario,
      };
    }, {}),
  );

  let tooltipLabel = formatDateWithShortYear(xValue);

  if (timePeriod && timePeriod !== MONTHLY) {
    const [intervalStartDate, intervalEndDate] = getDateLabel(
      xValue,
      timePeriod,
      endDate,
    );

    const formattedStartDate = formatDateWithShortYear(intervalStartDate);
    const formattedEndDate = formatDateWithShortYear(intervalEndDate);

    if (intervalStartDate === intervalEndDate) {
      tooltipLabel = formattedStartDate;
    } else {
      tooltipLabel = `${formattedStartDate} - ${formattedEndDate}`;
    }
  }

  const getMetrics = getChartMetrics({
    scenarios,
    context: tooltipContext,
  });

  return createPortal(
    children({
      chart: chartFromContext,
      scenarios,
      hoveredScenario,
      tooltipLabel,
      getChartMetrics: getMetrics,
      ...tooltipContext,
    }),
    node,
  );
}

HighchartsTooltipPortal.propTypes = {
  /**
   * A function that takes the Highcharts tooltip context and renders the
   * content of the tooltip
   *
   * @param {Highcharts.TooltipFormatterContextObject} context
   * @returns {JSX.node}
   * @see https://api.highcharts.com/class-reference/Highcharts.TooltipFormatterContextObject
   */
  children: PropTypes.func.isRequired,
  /** Intervals of the months */
  timePeriod: PropTypes.string,
  /** End date of the chart */
  endDate: PropTypes.string,
};

export default HighchartsTooltipPortal;
