import { getNestedProp } from '@/helpers';

const text = {
  accountsPayable: {
    addBtn: '+ Add another payment term',
    headerText: 'Payment Terms',
    termLabel: 'Name of Payment Term',
    percentageLabel: 'Select % Payment Per Month',
    percentageTooltipMsg: `Your payment term starts on the invoice month you specify in the expense.`,
    deleteConfirmation:
      'Are you sure you want to delete this payment term setting?',
    deleteErrorLabel: 'Cannot Delete Payment Terms',
    deleteErrorMsg:
      'You cannot delete this payment term as it is being used by an expense.',
  },
  accountsReceivable: {
    addBtn: '+ Add another collection term',
    headerText: 'Collection Terms',
    termLabel: 'Name of Collection Term',
    percentageLabel: 'Select % Collection Per Month',
    percentageTooltipMsg: `Your payment term starts on the invoice month you specify in the
    product pricing plan.`,
    deleteConfirmation:
      'Are you sure you want to delete this collection term setting?',
    deleteErrorLabel: 'Cannot Delete Collection Terms',
    deleteErrorMsg:
      'You cannot delete this collection term as it is being used by a pricing plan.',
  },
};

const PACTermText = ({ location }) => {
  return getNestedProp(text, location) ?? '';
};

export default PACTermText;
