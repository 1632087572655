// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import AddNewDashboardIcon from '@bill/cashflow.assets/add-new-dashboard';
import ChartNewIcon from '@bill/cashflow.assets/chart-new';
import NotesIcon from '@bill/cashflow.assets/notes';
import ProductTypeFilter from '@/components/ProductTypeFilter/ProductTypeFilter';
import IconButton from '@/components/common/IconButton';
import WithTooltip from '@/components/common/WithTooltip';
import { classNames } from '@/helpers';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import useOneColor from '@/hooks/useOneColor';
import { useDashboardContext } from '@/pages/Dashboard/DashboardContext';
import getBaseScenario from '@/selectors/getBaseScenario';
import { ReactComponent as AddNewDashboardForecastingIcon } from '@/assets/icons/svg/add_new_dashboard_forecasting.svg';
import './DashboardToolbar.scss';

const DashboardToolbar = ({ children }) => {
  const { name, notes, scenarioId } =
    useSelector(({ scenario }) => getBaseScenario({ scenario })) || {};
  const { onAddChart, setToggleChartsSidebar } = useDashboardContext();
  const hasToolbarEditAccess = useNonDashboardWritePermission();
  const isOneColorEnabled = useOneColor();

  return (
    <div
      className={classNames(
        'DashboardToolbar_Container',
        isOneColorEnabled && 'DashboardToolbar-forecasting',
      )}
    >
      {isOneColorEnabled && (
        <span className="DashboardToolbar_Tools">TOOLS</span>
      )}
      <div className="DashboardToolbar">
        {!isOneColorEnabled && (
          <div className="DashboardToolbar_Scenario">
            {name}
            {notes && (
              <WithTooltip
                content={notes}
                data-testid={`dashboard-scenario-tooltip-${scenarioId}`}
                placement="right"
              >
                <span className="DashboardToolbar_Scenario-notes">
                  <NotesIcon className="NotesIcon" />
                </span>
              </WithTooltip>
            )}
          </div>
        )}

        <>
          {children}
          <div className="DashboardToolbar_ButtonContainers">
            {hasToolbarEditAccess && (
              <>
                {!isOneColorEnabled && (
                  <IconButton
                    className="DashboardToolbar_Button DashboardToolbar_Button-border"
                    onClick={onAddChart}
                    Icon={ChartNewIcon}
                    label="Create New Chart"
                    data-testid="createChart"
                  />
                )}

                <ProductTypeFilter>
                  <IconButton
                    className={classNames(
                      isOneColorEnabled
                        ? 'DashboardToolbar_Button-forecasting'
                        : 'DashboardToolbar_Button DashboardToolbar_Button-border',
                    )}
                    onClick={() => setToggleChartsSidebar(true)}
                    Icon={
                      isOneColorEnabled
                        ? AddNewDashboardForecastingIcon
                        : AddNewDashboardIcon
                    }
                    label="Create New Dashboard"
                    data-testid="createDashboard"
                  />
                </ProductTypeFilter>
              </>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default DashboardToolbar;
