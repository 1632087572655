import { PropTypes } from 'prop-types';
import { PRIMARY_PALETTE_COLORS } from '@/constants/colors';
import LetterIcon from './LetterIcon';

/**
 * UserLetterIcon component to display initial of username name and set
 * background color wrt to Finmark color palette.
 *
 * @example
 *   <UserLetterIcon name={userName} />;
 *
 * @param {string} name user name to display
 * @param {string} id user name to display
 */
export default function UserLetterIcon({ name, id }) {
  return (
    <LetterIcon
      data-testid="UserLetterIcon"
      className="LetterIcon-circle"
      color={
        name
          ? PRIMARY_PALETTE_COLORS[id % PRIMARY_PALETTE_COLORS.length]
          : '#e5e5e5'
      }
      string={name}
    />
  );
}

UserLetterIcon.propTypes = {
  /** User Name */
  name: PropTypes.string.isRequired,
  /** Unique user ID */
  id: PropTypes.number.isRequired,
};
