import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import FormulaIcon from '@bill/cashflow.assets/formula';
import QuestionMarkIcon from '@bill/cashflow.assets/question-mark';
import PropTypes from 'prop-types';
import useFocusOnEdit from '@/components/common/Spreadsheet/editors/useFocusOnEdit';
import WithVariableAutocomplete from '@/components/common/WithVariableAutocomplete';
import { getSizeForInput } from '@/helpers';
import {
  formatValueForDisplay,
  formatValueForSave,
} from '@/helpers/percentageFormulaFormatter';
import { isEmptyOrNull } from '@/helpers/validators';
import './FormulaEditor.scss';

/**
 * A cell editor for entering formulas in ag-Grid
 *
 * @example
 *   columnDefs={[
 *    {
 *      ...
 *      cellEditor: FormulaEditor,
 *      editable: true
 *    }
 *   ]}
 *
 * @see https://www.ag-grid.com/react-grid/component-cell-editor/
 */
const FormulaEditor = forwardRef(
  (
    {
      allowEmpty = false,
      charPress,
      'data-testid': dataTestId,
      onHelpClick,
      unit,
      value,
    },
    ref,
  ) => {
    const input = useRef(null);
    const [displayFormula, setDisplayFormula] = useState(
      charPress ??
        formatValueForDisplay(
          value?.displayFormula ?? value?.value?.toString(),
          unit,
        ),
    );
    const size = getSizeForInput(displayFormula);

    useImperativeHandle(ref, () => ({
      getValue: () => ({
        ...value,
        displayFormula: formatValueForSave(displayFormula, unit),
        // If value is undefined, [object Object] will be displayed while
        // saving, due to cellValueFormatter
        value: '',
      }),
      isCancelAfterEnd: () => {
        if (!allowEmpty && isEmptyOrNull(displayFormula)) return true;
        const currentVal = value?.displayFormula ?? value?.value?.toString();
        return currentVal === formatValueForSave(displayFormula, unit);
      },
    }));

    useFocusOnEdit(input);

    return (
      <div className="Spreadsheet_CellEditor">
        <span className="FormulaEditor_Prefix">
          <FormulaIcon className="FormulaEditor_PrefixIcon" />=
        </span>
        <WithVariableAutocomplete
          inputRef={input}
          value={displayFormula ?? ''}
          data-testid={`${dataTestId}-autocomplete`}
          onChange={(newValue) => setDisplayFormula(newValue)}
        >
          {({ inputRef, ...props }) => (
            <div className="FormulaEditor_InputWrapper">
              <input
                ref={inputRef}
                type="text"
                className="Spreadsheet_Input Spreadsheet_Input-hasHelp"
                size={size}
                data-testid={`${dataTestId}-input`}
                onChange={({ target }) => setDisplayFormula(target.value)}
                {...props}
              />
            </div>
          )}
        </WithVariableAutocomplete>
        {onHelpClick && (
          <button
            type="button"
            className="Spreadsheet_CellHelp"
            data-testid={`${dataTestId}-helpBtn`}
            onClick={onHelpClick}
          >
            <QuestionMarkIcon
              aria-label="Formula Guide"
              className="Spreadsheet_HelpIcon"
            />
          </button>
        )}
      </div>
    );
  },
);

FormulaEditor.propTypes = {
  /**
   * Whether clearing the field is treated as a valid value. If TRUE, null will
   * be sent to the API. If FALSE, the edit operation will be canceled.
   */
  'allowEmpty': PropTypes.bool,
  /** Character that triggered editing on the cell, if any */
  'charPress': PropTypes.string,
  /** Unique ID for selecting the editor in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Event handler called when the user clicks on the help button */
  'onHelpClick': PropTypes.func,
  /** The unit for the result of the formula, e.g. percentage */
  'unit': PropTypes.string,
  /** Value of the cell */
  'value': PropTypes.shape({
    displayFormula: PropTypes.string,
    value: PropTypes.number,
    type: PropTypes.string,
  }),
};

export default FormulaEditor;
