import PropTypes from 'prop-types';
import Link from '@/components/common/Link';
import useDashboardPath from '@/hooks/useDashboardPath';

/**
 * A small component to link back to the Dashboard (with dashboard id, if
 * present)
 *
 * @example
 *   <DashboardLink>Click here</DashboardLink>;
 */
const DashboardLink = ({
  id,
  className,
  'data-testid': dataTestId,
  children,
}) => {
  const dashboardPath = useDashboardPath();
  const testId = id ?? dataTestId;

  return (
    <Link
      to={{ pathname: dashboardPath }}
      id={id}
      className={className}
      data-testid={testId}
    >
      {children}
    </Link>
  );
};

DashboardLink.propTypes = {
  /** The linked element or text */
  'children': PropTypes.node.isRequired,
  /** Class(es) to apply to the element */
  'className': PropTypes.string,
  /**
   * Unique ID for selecting the link in unit/integration tests. Defaults to the
   * `id`, if present
   */
  'data-testid': PropTypes.string,
  /** Globally unique ID for the link */
  'id': PropTypes.string,
};

export default DashboardLink;
