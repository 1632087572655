// @ts-check
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

/**
 * @private
 * @type {(
 *   callback: (body: string) => void,
 * ) => import('@stomp/stompjs').messageCallbackType}
 */
function getJsonMsgHandler(callback) {
  return function jsonMsgHandler({ body }) {
    const jsonBody = JSON.parse(body);
    callback(jsonBody);
  };
}

/** A client for communicating over a WebSocket */
export default class SocketClient {
  client;

  /** @type {Promise<void>} */
  #connectPromise;

  /**
   * Creates a new WebSocket client
   *
   * @param {string} url endpoint
   */
  constructor(url) {
    this.client = new Client({
      webSocketFactory: () => new SockJS(url),
    });
  }

  /**
   * Opens a new connection if one is not already established
   *
   * @type {(
   *   headers: import('@stomp/stompjs').StompHeaders,
   * ) => Promise<void>}
   */
  connect(headers) {
    this.#connectPromise =
      this.#connectPromise ??
      new Promise((resolve, reject) => {
        const { client } = this;
        if (client.connected) {
          resolve();
          return;
        }
        if (!headers?.Authorization) {
          throw Error('WebSocket Authorization token not set');
        }
        this.client.connectHeaders = {
          ...headers,
          Authorization: `Bearer ${headers.Authorization}`,
        };
        client.activate();
        client.onConnect = () => resolve();
        client.onStompError = client.onWebSocketClose = (error) => {
          reject(error);
          this.#connectPromise = undefined;
        };
      });
    return this.#connectPromise;
  }

  /**
   * Closes the active connection, if one exists
   *
   * @type {() => Promise<void>}
   */
  disconnect() {
    this.#connectPromise = undefined;
    return this.client.deactivate();
  }

  /**
   * Establishes a subscription to the given topic and executes the callback
   * when messages are received
   *
   * @type {(
   *   topic: string,
   *   callback: (body: string) => void,
   * ) => import('@stomp/stompjs').StompSubscription}
   */
  subscribe(topic, callback) {
    return this.client.subscribe(topic, getJsonMsgHandler(callback));
  }

  /**
   * Publishes the message to the given topic
   *
   * @type {(topic: string, message: string) => void}
   */
  publish(topic, message) {
    return this.client.publish({
      destination: topic,
      body: message,
    });
  }
}
