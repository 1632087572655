import {
  SET_ERROR,
  EDIT_MODE,
  SET_DASHBOARDS,
  SET_SELECTED_DASHBOARD_ID,
  SET_NO_LAYOUT,
  UPDATE_DASHBOARD,
  ADD_DASHBOARD,
} from '@/actionTypes/dashboard';
import {
  deleteCustomChart,
  getAllDashboardLayouts,
  setDashboardLayout,
} from '@/services/dashboard.service';

export const setError = (error) => {
  return {
    type: SET_ERROR,
    payload: error,
  };
};

export const toggleEditMode = (isEditMode) => {
  return {
    type: EDIT_MODE,
    payload: isEditMode,
  };
};

/**
 * Action that sets all dashboards
 *
 * @param {Array} dashboards Array of dashboards
 * @returns {Function} Dispatchable action
 */
export const setDashboardsAction = (dashboards) => {
  return {
    type: SET_DASHBOARDS,
    payload: dashboards,
  };
};

const updateDashboardLayout = (dashboard) => {
  return {
    type: UPDATE_DASHBOARD,
    payload: dashboard,
  };
};

const addDashboardLayout = (dashboard) => {
  return {
    type: ADD_DASHBOARD,
    payload: dashboard,
  };
};

/**
 * Action that adds a new dashboard
 *
 * @param {number} scenarioId ID of the selected scenario
 * @param {Object} dashboard Request body for updating the dashboard
 * @returns {Function} Dispatchable action
 */
export const addDashboardAction = (scenarioId, dashboard) => {
  return async (dispatch) => {
    try {
      const {
        data: { data },
      } = await setDashboardLayout(scenarioId, dashboard);
      const dashboardWithResponse = {
        ...dashboard,
        id: data.id,
        name: data.name,
        isDefault: data.isDefault,
      };
      dispatch(addDashboardLayout(dashboardWithResponse));
    } catch (e) {
      if (e.response?.status === 409) {
        throw new Error('Dashboard name is already in use');
      }
      throw new Error(e.response?.data?.error?.errorMessage || e.message);
    }
  };
};

/**
 * Action that update a single dashboard
 *
 * @param {number} scenarioId ID of the selected scenario
 * @param {Object} dashboard Request body for updating the dashboard
 * @returns {Function} Dispatchable action
 */
export const updateDashboardAction = (scenarioId, dashboard) => {
  return async (dispatch) => {
    const requestBody = dashboard;
    try {
      await setDashboardLayout(scenarioId, requestBody);
      dispatch(updateDashboardLayout(requestBody));
    } catch (e) {
      if (e.response?.status === 409) {
        throw new Error('Dashboard name is already in use');
      }
      throw new Error(e.response?.data?.error?.errorMessage || e.message);
    }
  };
};

/**
 * Action that fetches all dashboards
 *
 * @param {string} scenarioId scenario id against which we want to fetch
 *   dashboards
 * @returns {Function} Dispatchable action
 */
export const getDashboardsAction = (scenarioId) => {
  return async (dispatch) => {
    try {
      const { data } = await getAllDashboardLayouts(scenarioId);
      dispatch(setDashboardsAction(data.data));
    } catch ({ response }) {
      if (response?.status === 404) {
        dispatch({
          type: SET_NO_LAYOUT,
          payload: true,
        });
      } else {
        console.error(response);
      }
    }
  };
};

/**
 * Action that deletes the custom chart
 *
 * @param {number} scenarioId ID of the scenario in which chart is present
 * @param {number} chartId ID of the chart to delete
 * @returns {Function} Dispatchable action
 */
export const deleteCustomChartAction = (scenarioId, chartId) => {
  return async (dispatch) => {
    try {
      await deleteCustomChart(scenarioId, chartId);
      dispatch(getDashboardsAction(scenarioId));
    } catch (e) {
      dispatch(setError(e.response?.data?.error?.errorMessage || e.message));
    }
  };
};

/**
 * Action that selects dashboard ID
 *
 * @param {string} id ID of the dashboard
 * @returns {Function} Dispatchable action
 */
export const setSelectedDashboardIdAction = (id) => {
  return {
    type: SET_SELECTED_DASHBOARD_ID,
    payload: id,
  };
};
