import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useQueryParams from '@/hooks/useQueryParams';
import EmailSkip from '@/pages/Registration/EmailSkip';
import { actions, XERO } from '@/pages/Registration/constants';
import { REACT_APP_XERO_CLIENT_ID } from '@/runtimeConfig';

const SourceRoutes = ({ onChange }) => {
  const { source } = useParams();
  const query = useQueryParams();
  const email = query.get('email');
  const firstName = query.get('name');
  const lastName = query.get('last_name');
  const companyName = query.get('company_name');
  const currency = query.get('currency');

  useEffect(() => {
    onChange({
      type: actions.SET_USER_DATA,
      payload: {
        email,
        source,
        firstName,
        lastName,
        companyName,
        currency,
      },
    });
  }, [onChange, source, email, firstName, lastName, companyName, currency]);

  const queryArray = Array.from(query);

  if (source === XERO && queryArray.length === 0) {
    const { origin } = window.location;
    const redirectUrl = `${origin}/xero-redirect`;
    window.location = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${REACT_APP_XERO_CLIENT_ID}&redirect_uri=${redirectUrl}&scope=openid profile email offline_access accounting.journals.read accounting.reports.read accounting.transactions accounting.settings accounting.contacts accounting.attachments`;
    return <h1 className="RegistrationHeading">Redirecting you to Xero...</h1>;
  }

  return (
    <EmailSkip
      onChange={onChange}
      email={email}
      firstName={firstName}
      lastName={lastName}
      companyName={companyName}
    />
  );
};

export default SourceRoutes;
