import {
  useEffect,
  useMemo,
  useState,
  forwardRef,
  useRef,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import InfoIcon from '@bill/cashflow.assets/info';
import VariablesEmptyIcon from '@bill/cashflow.assets/variables-empty';
import {
  addCustomVariableAction,
  cancelAddCustomVariableAction,
  cancelAddCustomVariableSectionAction,
  deleteCustomVariableSectionAction,
  getCustomVariablesAction,
  getCustomVariableSectionsAction,
  reorderCustomVariableAction,
  reorderCustomVariableSectionAction,
  saveCustomVariableAction,
  saveCustomVariableSectionAction,
  subscribeToVariablesAction,
  updateCustomVariableAction,
  updateCustomVariableSectionAction,
  createVariableChartAction,
} from '@/actions/variables';
import ComponentLoader from '@/components/common/ComponentLoader';
import EmptyData from '@/components/common/EmptyData';
import FormulaGuideModal from '@/components/common/FormulaGuide/FormulaGuideModal';
import { CUSTOM_VARIABLE } from '@/components/common/FormulaGuide/formulaGuideModalConstants';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import FormulaMonthRenderer from '@/components/common/MonthlySpreadsheet/FormulaMonthRenderer';
import { handleAgGridPaste } from '@/components/common/MonthlySpreadsheet/helpers';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import FormulaEditor from '@/components/common/Spreadsheet/editors/FormulaEditor';
import {
  expandFirstGroup,
  getUnitFormatter,
} from '@/components/common/Spreadsheet/helpers';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import useRowDragAndDrop, {
  dropPositions,
  getDropPosition,
} from '@/components/common/Spreadsheet/useRowDragAndDrop';
import useUpdateQueue from '@/components/common/Spreadsheet/useUpdateQueue';
import WithTooltip from '@/components/common/WithTooltip';
import {
  FPA_LITE_CUSTOM_VARIABLES_LIMIT,
  PLACEHOLDER_ID,
  SECTION_PLACEHOLDER_ID,
  units,
} from '@/constants/variables';
import {
  debounce,
  isLimitCustomVariables,
  naturalSortComparator,
} from '@/helpers';
import detectCircularRef from '@/helpers/circularReference';
import convertDecimalToPercent from '@/helpers/convertDecimalToPercent';
import { AVERAGE_MAIN_METRIC } from '@/helpers/customCharts';
import { isEmptyOrNull } from '@/helpers/validators';
import useGridRefreshCells from '@/hooks/useGridRefreshCells';
import useIsFPALite from '@/hooks/useIsFPALite';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import useWsSubscription from '@/hooks/useWsSubscription';
import CustomVariablesLegend, {
  SPREADSHEET_PERCISION,
} from '@/pages/Variables/CustomVariablesLegend';
import MonthCellRenderer from '@/pages/Variables/renderers/MonthCellRenderer';
import {
  setCustomVariableOrder,
  setCustomVariableValues,
} from '@/services/variable.service';
import DeleteCustomVariableModal from './DeleteCustomVariableModal';
import VariableDependencyModal from './VariableDependencyModal';
import ContextMenuRenderer from './renderers/ContextMenuRenderer';
import {
  VariableNameEditor,
  VariableNameRenderer,
} from './renderers/VariableName';
import VariableSectionRenderer from './renderers/VariableSectionRenderer';
import './CustomVairbalesList.scss';

const VARIABLE = 'variable';
const valueFormatterWithPrecision =
  (precision) =>
  ({ value, data }) => {
    const formatter = getUnitFormatter(data.variable?.unit);
    const options = !precision
      ? {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }
      : {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        };
    return isEmptyOrNull(value?.amount) ? '' : formatter(value.amount, options);
  };

const getRowSection = (rowNode) =>
  rowNode.group || rowNode.data?.isGroupPlaceholder ? rowNode : rowNode.parent;

function getFilterValue({ column, data: { months, variable } }) {
  const month = column.getParent().getGroupId();
  const value = months?.find((entry) => entry.month === month).value;
  if (!value) return null;

  return variable.unit === units.PERCENTAGE
    ? convertDecimalToPercent(value.amount)
    : value.amount;
}

function getGroupKey({ data }) {
  return data.variable?.sectionId;
}

function varMonthComparator(a, b) {
  if (!a) return !b ? 0 : -1;
  if (!b) return 1;
  return a.amount - b.amount;
}

const isEditable = ({ node }) => {
  const { hasErrorMsg } = node.data.variable;
  return !hasErrorMsg;
};

function canBeNested({ overNode }) {
  return overNode.group;
}

/**
 * Select appropriate cell renderer based on cell focus or circular reference
 *
 * @param {import('ag-grid-community').ICellRendererParams} params
 * @returns {Object} renderer definition
 */
const cellRendererSelector = ({ eGridCell, value }) => {
  // Only use the custom renderer when the cell is focused,
  // to limit the performance impact of too many renderers
  const isFocused =
    document.activeElement === eGridCell ||
    eGridCell.contains(document.activeElement);
  const isCircularRef = detectCircularRef(value);
  if (!isFocused && !isCircularRef) return undefined;
  const component = isFocused ? FormulaMonthRenderer : MonthCellRenderer;
  return { component };
};

const CustomVariablesList = forwardRef(
  (
    {
      addCustomVariable,
      cancelAddCustomVariable,
      cancelAddSection,
      dashboardLayoutId,
      deleteSection,
      getCustomVariables,
      getSections,
      reorderSection,
      reorderCustomVariable,
      saveCustomVariable,
      saveSection,
      sections,
      scenarioId,
      startDate,
      subscribeToVariables,
      endDate,
      updateCustomVariable,
      updateSection,
      variables,
      createCustomVariableChart,
      onChartCreated,
      onChartError,
      precision,
      lastUpdatedCustomVariable,
    },
    ref,
  ) => {
    const firstGroupExpanded = useRef(false);
    const [varToDelete, setVarToDelete] = useState(null);
    const [sectionToDelete, setSectionToDelete] = useState(null);
    const [showHelp, setShowHelp] = useState(false);
    const [hasPendingReorder, setHasPendingReorder] = useState(false);

    const [traceVariable, setTraceVariable] = useState(null);

    const hasWritePermission = useNonDashboardWritePermission();
    const isFPALite = useIsFPALite();

    const columnId = `${lastUpdatedCustomVariable?.editedMonth.month}_${scenarioId}`;
    useGridRefreshCells(
      {
        gridApi: ref.current,
        rowId: lastUpdatedCustomVariable?.variableId,
        columnId,
      },
      [lastUpdatedCustomVariable],
    );

    const rowData = useMemo(() => {
      if (!sections.length) return null;

      // ag-Grid doesn't provide a way to create empty groups,
      // so we generate some placeholder rows for them
      const emptySections = sections.filter(({ id }) =>
        variables.every(({ variable }) => variable.sectionId !== id),
      );
      const placeholders = emptySections.map((section) => {
        return {
          ...section,
          isGroupPlaceholder: true,
        };
      });

      // ag-Grid manipulates row data directly, which will cause redux-toolkit
      // to throw invariant errors if the data is nested.
      const clonedVars = variables.map(({ variable, months }) => ({
        variable: { ...variable },
        months: [...months],
      }));

      return [...placeholders, ...clonedVars];
    }, [sections, variables]);
    // Debounced to handle the user clicking add at the same time the list of
    // existing vars is fetched
    const handleModelUpdate = useMemo(
      () =>
        debounce(({ api }) => {
          if (!firstGroupExpanded.current && variables.length) {
            expandFirstGroup(api);
            firstGroupExpanded.current = true;
          }

          // If the user added a new variable placeholder, trigger editing on it
          const placeholder = api.getRowNode(PLACEHOLDER_ID);
          if (placeholder) {
            placeholder.parent.setExpanded(true);
            api.startEditingCell({
              colKey: 'variable',
              rowIndex: Number(placeholder.rowIndex),
            });
          }
        }, 100),
      [variables],
    );

    const sortSections = useCallback(
      ({ nodeA, nodeB }) => {
        if (!sections.length) return null;

        const aIndex =
          nodeA.data?.index ??
          sections.find(({ id }) => [nodeA.key, nodeA.id].includes(id)).index;
        const bIndex =
          nodeB.data?.index ??
          sections.find(({ id }) => [nodeB.key, nodeB.id].includes(id)).index;
        return aIndex - bIndex;
      },
      [sections],
    );

    // If the mouse leaves the grid area, reset the dragged row's position
    const handleDragLeave = ({ node }) => {
      if (node.group || node.data?.isGroupPlaceholder) {
        const sectionId = node.key || node.id;
        const { oldIndex } = sections.find((sect) => sect.id === sectionId);
        reorderSection(sectionId, oldIndex);
      } else {
        const { variable } = node.data;
        reorderCustomVariable(node.id, variable.oldIndex, variable.sectionId);
      }
    };

    const addValueToQueue = useCallback(
      (queue, { column, data: { variable }, newValue }) => {
        if (variable.id === PLACEHOLDER_ID) return queue;

        const { displayFormula } = newValue;
        const month = column.getParent().getGroupId();
        const payload = {
          customVariableId: variable.id,
          displayFormula: displayFormula?.toString().trim()
            ? displayFormula
            : null,
          month,
        };
        return [...queue, payload];
      },
      [],
    );

    const updateBulkValues = useCallback(
      (queue) => setCustomVariableValues(scenarioId, queue),
      [scenarioId],
    );

    const handleMonthValueChange = useUpdateQueue(
      addValueToQueue,
      updateBulkValues,
    );

    const handleNameChange = useCallback(
      async ({ api, data, node }) => {
        const { variable } = data;
        let response;
        if (variable.id === PLACEHOLDER_ID) {
          response = await saveCustomVariable(scenarioId, {
            ...variable,
            id: undefined,
          });
        } else {
          response = await updateCustomVariable(
            scenarioId,
            variable.id,
            variable,
          );
          // Force the monthly values to rerender, in case the type changed
          api.refreshCells({
            force: true,
            rowNodes: [node],
          });
        }
        if (response?.error) {
          const { context } = api.gridOptionsWrapper.gridOptions;
          context.loadingCells = {};
          const rowNode = api.getRowNode(variable.id);
          rowNode.setData({
            ...rowNode.data,
            error: response.error.errorMessage,
          });
        }
      },
      [saveCustomVariable, scenarioId, updateCustomVariable],
    );

    const handleCopy = useCallback(
      ({ column, value }) =>
        column.colDef.field === 'variable' ? value.displayName : value?.amount,
      [],
    );

    const handleDeleteSection = async () => {
      await deleteSection(scenarioId, sectionToDelete.id);
      setSectionToDelete(null);
    };

    const handleDrop = useCallback(
      ({ node, overNode: hoveredRow, y }) => {
        if (!hoveredRow) return;

        const { key, id } = getRowSection(hoveredRow);
        const hoveredSectionId = key || id;

        const placeAfter =
          getDropPosition(hoveredRow, y) === dropPositions.BELOW;

        // Dropping a section
        if (node.group || node.data?.isGroupPlaceholder) {
          const section = sections.find((sect) =>
            [node.key, node.id].includes(sect.id),
          );
          const { index } = sections.find(
            (sect) => sect.id === hoveredSectionId,
          );
          const newIdx = placeAfter ? index + 1 : index;
          reorderSection(node.key || node.id, newIdx);
          updateSection(scenarioId, section.id, { ...section, index });
          // Dropping a variable
        } else {
          let newIdx = 0;
          if (!hoveredRow.group && !hoveredRow.data?.isGroupPlaceholder) {
            const hoveredData = variables
              .filter(({ variable }) => variable.sectionId === hoveredSectionId)
              .find(({ variable }) => variable.id === hoveredRow.id);
            if (hoveredData) {
              const { index } = hoveredData.variable;
              newIdx = placeAfter ? index + 1 : index;
            }
          }

          reorderCustomVariable(node.id, newIdx, hoveredSectionId);
          setHasPendingReorder(true);
        }
      },
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
      [scenarioId, variables],
    );

    useEffect(() => {
      if (!hasPendingReorder) return;

      // Push updated variable indices to the BE upon reorder
      const indices = variables.map(({ variable }, index) => ({
        index,
        id: variable.id,
        sectionId: variable.sectionId,
      }));
      setCustomVariableOrder(scenarioId, indices);
      setHasPendingReorder(false);
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    }, [hasPendingReorder, variables]);

    const dragHandlers = useRowDragAndDrop({
      onRowDragEnd: handleDrop,
      onRowDragLeave: handleDragLeave,
      canBeNested,
    });

    const onCreateClick = useCallback(
      async ({ id }, chartType) => {
        try {
          const data = await createCustomVariableChart(
            scenarioId,
            dashboardLayoutId,
            {
              customVariableId: id,
              scenarioId,
              metadata: {
                chartType,
                mainMetric: AVERAGE_MAIN_METRIC,
              },
            },
            startDate,
            endDate,
          );
          onChartCreated(data);
        } catch (e) {
          onChartError(e);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
      [createCustomVariableChart, scenarioId, startDate, endDate],
    );

    const valueFormatter = useMemo(
      () => valueFormatterWithPrecision(precision),
      [precision],
    );

    useEffect(() => {
      getSections(scenarioId);
      getCustomVariables(scenarioId, startDate, endDate);
    }, [getCustomVariables, getSections, scenarioId, startDate, endDate]);

    useWsSubscription(() => subscribeToVariables(scenarioId), [scenarioId]);

    const colDefs = useMemo(() => {
      const columns = [
        {
          editable: true,
          field: VARIABLE,
          headerName: 'Display Name',
          cellEditor: VariableNameEditor,
          cellEditorParams: { onCancelAdd: cancelAddCustomVariable },
          cellRenderer: VariableNameRenderer,
          filterValueGetter: ({ data }) => data.variable?.displayName,
          headerComponent: HeaderRenderer,
          headerComponentParams: {
            enableExpandAll: true,
            tooltip:
              'Hover mouse over display name to view the full variable ID.',
          },
          comparator: (valueA, _, aRow, bRow) => {
            if (!(aRow.data && bRow.data)) return valueA;
            return naturalSortComparator(
              aRow.data.variable.displayName,
              bRow.data.variable.displayName,
            );
          },
          minWidth: 275,
          onCellValueChanged: handleNameChange,
          suppressPaste: true,
          valueFormatter: ({ value }) => value.displayName,
          valueSetter: ({ data, newValue }) => {
            // eslint-disable-next-line no-param-reassign -- predates description requirement
            data.variable = newValue;
            return !newValue.hasErrorMsg;
          },
        },
      ];

      if (hasWritePermission) {
        columns.push({
          colId: 'actions',
          type: 'actions',
          cellRenderer: ContextMenuRenderer,
          cellRendererParams: {
            onDeleteClick: setVarToDelete,
            onCreateClick,
            onDependenciesClick: setTraceVariable,
          },
        });
      }
      return columns;
    }, [
      cancelAddCustomVariable,
      handleNameChange,
      onCreateClick,
      hasWritePermission,
    ]);

    const editorParams = useCallback(
      ({ column, data }) => ({
        'allowEmpty': true,
        'data-testid': `${data.variable.name}-${column.colId}`,
        'onHelpClick': () => setShowHelp(true),
        'unit': data.variable.unit,
      }),
      [],
    );

    const rendererParams = useCallback(
      ({ colDef, data: { variable } }) => ({
        'data-testid': `${variable.name}-${colDef.colId}`,
        'onFillRightClick': ({ month, displayFormula }) => {
          setCustomVariableValues(scenarioId, [
            {
              customVariableId: variable.id,
              displayFormula,
              month,
              fillRight: true,
            },
          ]);
        },
      }),
      [scenarioId],
    );

    const hasData =
      variables.length || sections.some((section) => !section.default);

    const groupRendererParams = useMemo(
      () => ({
        onAddClick: (sectionId) => {
          // Reset any filters so the new variable can be seen
          ref.current.api.setFilterModel(null);
          addCustomVariable(scenarioId, sectionId);
        },
        onDeleteClick: (section) => setSectionToDelete(section),
        onCancel: (id) => id === SECTION_PLACEHOLDER_ID && cancelAddSection(),
        onSave: (id, params) => {
          if (id === SECTION_PLACEHOLDER_ID) {
            saveSection(scenarioId, params);
          } else {
            updateSection(scenarioId, id, params);
          }
        },
        sections,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
      [
        addCustomVariable,
        cancelAddSection,
        saveSection,
        sections,
        scenarioId,
        updateSection,
      ],
    );

    return (
      <div data-testid="custom-variables">
        <ComponentLoader loadingComponent="customVariables" paddingTop="0%" />
        {hasData ? (
          <>
            <SpreadsheetToolbar Legend={CustomVariablesLegend}>
              {isFPALite && rowData && (
                <div className="CustomVariables_GridHeader">
                  {rowData.length > 0 && (
                    <span
                      className="CustomVariables_Counter"
                      data-testid="data-mapping-manage-button"
                    >
                      {variables.length} of {FPA_LITE_CUSTOM_VARIABLES_LIMIT}{' '}
                      variables used
                      <WithTooltip
                        content={
                          <span className="w-full">
                            You can create up to{' '}
                            {FPA_LITE_CUSTOM_VARIABLES_LIMIT} variables within
                            your current plan.
                          </span>
                        }
                      >
                        <span>
                          <InfoIcon className="MoreInfoIcon" />
                        </span>
                      </WithTooltip>
                    </span>
                  )}
                  {isLimitCustomVariables(variables.length) && (
                    <div className="CustomVariables_Warning">
                      You have reached the limit on custom variables. You can
                      create an additional custom variable by deleting an
                      existing one.
                    </div>
                  )}
                </div>
              )}
            </SpreadsheetToolbar>
            <MonthlySpreadsheet
              ref={ref}
              animateRows
              columnDefs={colDefs}
              comparator={varMonthComparator}
              data={rowData}
              editable={isEditable && hasWritePermission}
              initialGroupOrderComparator={sortSections}
              filterValueGetter={getFilterValue}
              getRowId={({ data }) => data.id ?? data.variable.id}
              editor={FormulaEditor}
              editorParams={editorParams}
              valueFormatter={valueFormatter}
              groupBy={getGroupKey}
              fullWidthCellRenderer={VariableSectionRenderer}
              fullWidthCellRendererParams={groupRendererParams}
              groupDefaultExpanded={1}
              groupRowRenderer={VariableSectionRenderer}
              groupRendererParams={groupRendererParams}
              rendererSelector={cellRendererSelector}
              rendererParams={rendererParams}
              data-testid="custom-variables-list"
              onMonthValueChange={handleMonthValueChange}
              onModelUpdated={handleModelUpdate}
              {...dragHandlers}
              processCellForClipboard={handleCopy}
              processCellFromClipboard={handleAgGridPaste}
            />
          </>
        ) : (
          <EmptyData
            Icon={VariablesEmptyIcon}
            className="EmptyData-tab"
            heading={isFPALite ? 'No Custom Variables to display.' : ''}
          >
            {isFPALite
              ? 'Create up to 25 custom variables. Click ‘Add’ at the top of the screen to create your first custom variable.'
              : 'Click ‘Add’ at the top of the screen to create your first custom variable.'}
          </EmptyData>
        )}
        <FormulaGuideModal
          formulaKey={CUSTOM_VARIABLE}
          open={showHelp}
          data-testid="variables-guide-modal"
          onClose={() => setShowHelp(false)}
        />
        <DeleteCustomVariableModal
          variable={varToDelete}
          onFinish={() => setVarToDelete(null)}
        />
        {Boolean(sectionToDelete) && (
          <ModalConfirmation
            id="section-delete-modal"
            title="Delete Section"
            onAction={handleDeleteSection}
            onCancel={() => setSectionToDelete(null)}
          >
            Are you sure that you want to delete the section "
            {sectionToDelete.name}
            "?
            <br />
            This action cannot be undone.
          </ModalConfirmation>
        )}

        {traceVariable && (
          <VariableDependencyModal
            onFinish={() => setTraceVariable(null)}
            variableId={traceVariable.id}
          />
        )}
      </div>
    );
  },
);

function mapStateToProps({ dashboard, scenario, shared, variables, ui }) {
  return {
    dashboardLayoutId: dashboard.selectedDashboardId,
    scenarioId: scenario.scenarioId,
    sections: variables.customVariableSections,
    startDate: shared.startDate,
    endDate: shared.endDate,
    variables: variables.customVariables,
    precision: ui[SPREADSHEET_PERCISION],
    lastUpdatedCustomVariable: variables.lastUpdatedCustomVariable,
  };
}

export default connect(
  mapStateToProps,
  {
    addCustomVariable: addCustomVariableAction,
    cancelAddCustomVariable: cancelAddCustomVariableAction,
    cancelAddSection: cancelAddCustomVariableSectionAction,
    deleteSection: deleteCustomVariableSectionAction,
    getCustomVariables: getCustomVariablesAction,
    getSections: getCustomVariableSectionsAction,
    reorderSection: reorderCustomVariableSectionAction,
    reorderCustomVariable: reorderCustomVariableAction,
    saveCustomVariable: saveCustomVariableAction,
    saveSection: saveCustomVariableSectionAction,
    subscribeToVariables: subscribeToVariablesAction,
    updateCustomVariable: updateCustomVariableAction,
    updateSection: updateCustomVariableSectionAction,
    createCustomVariableChart: createVariableChartAction,
  },
  undefined,
  { forwardRef: true },
)(CustomVariablesList);
