// @ts-check
import { SET_SELECTED_COMPANY } from '@/actionTypes/companies';
import {
  SET_ERROR,
  EDIT_MODE,
  SET_SELECTED_DASHBOARD_ID,
  SET_DASHBOARDS,
  SET_NO_LAYOUT,
  UPDATE_DASHBOARD,
  ADD_DASHBOARD,
  SET_CHART_PREFERENCES,
  RESET_CLEANUP_ROUTE,
} from '@/actionTypes/dashboard';
import {
  SET_DEFAULT_SCENARIO,
  SET_SCENARIO_ID,
  SWAP_SCENARIOS,
} from '@/actionTypes/scenario';
import { LOGOUT } from '@/actionTypes/shared';

/** @type {import('../types/dashboard').DashboardsState} */
const INITIAL_STATE = {
  error: '',
  isEditing: false,
  dashboards: [],
  selectedDashboardId: null,
  hasNoLayout: false,
  userChartPreferences: {},
  shouldCleanUpRoute: false,
};

/**
 * @type {(
 *   state: import('../types/dashboard').DashboardsState,
 *   action: any,
 * ) => import('../types/dashboard').DashboardsState}
 */
const dashboard = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case SET_ERROR:
      return { ...state, error: payload };
    case EDIT_MODE:
      return { ...state, isEditing: payload };
    case SET_SELECTED_DASHBOARD_ID:
      return {
        ...state,
        selectedDashboardId: payload,
      };
    case SET_DASHBOARDS: {
      return {
        ...state,
        dashboards: payload,
        hasNoLayout: !payload.length,
      };
    }
    case ADD_DASHBOARD: {
      let { selectedDashboardId } = state;
      if (!state.dashboards.length) {
        selectedDashboardId = payload.id;
      }
      return {
        ...state,
        selectedDashboardId,
        dashboards: [...state.dashboards, payload],
        hasNoLayout: false,
      };
    }
    case UPDATE_DASHBOARD: {
      const { dashboards } = state;
      const updatedDashboards = dashboards.map((layout) => {
        const updated = { ...layout };
        if (updated.id !== payload.id && payload.isDefault) {
          updated.isDefault = false;
        }
        if (updated.id === payload.id) {
          updated.items = payload.items;
          updated.name = payload.name;
          updated.isDefault = payload.isDefault;
        }
        return updated;
      });
      return {
        ...state,
        dashboards: updatedDashboards,
        hasNoLayout: false,
      };
    }
    case SET_NO_LAYOUT:
      return {
        ...state,
        hasNoLayout: payload,
      };
    case SET_CHART_PREFERENCES: {
      const id =
        state.selectedDashboardId ??
        state.dashboards.find((dash) => dash.isDefault).id;
      return {
        ...state,
        userChartPreferences: {
          ...state.userChartPreferences,
          [id]: {
            ...state.userChartPreferences[id],
            ...payload,
          },
        },
      };
    }
    case SET_SCENARIO_ID:
    case SET_DEFAULT_SCENARIO:
    case SWAP_SCENARIOS:
      return {
        ...state,
        shouldCleanUpRoute: true,
        selectedDashboardId: null,
        dashboards: [],
      };
    case RESET_CLEANUP_ROUTE:
      return { ...state, shouldCleanUpRoute: false };
    case SET_SELECTED_COMPANY:
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default dashboard;
