import '@/components/Charts/ExpandedViewChart.scss';
import useDashboardPath from '@/hooks/useDashboardPath';
import PayrollByDept from '@/pages/Dashboard/PayrollByDept';
import TotalPayrollExpanded from '@/pages/Dashboard/TotalPayrollExpanded';

function TotalPayroll() {
  const dashboardPath = useDashboardPath();
  return (
    <>
      <TotalPayrollExpanded back={dashboardPath} title="Total Payroll" />
      <PayrollByDept />
    </>
  );
}

export default TotalPayroll;
