import ConfigIcon from '@bill/cashflow.assets/config';
import FormLabel from '@/components/common/FormLabel';
import RadioGroupPersistent from '@/components/common/RadioGroupPersistent';
import SpreadsheetLegend from '@/components/common/Spreadsheet/SpreadsheetLegend';
import WithPopover from '@/components/common/WithPopover';
import './CustomVariablesLegend.scss';

export const SPREADSHEET_PERCISION = 'spreadsheet-precision';

const CustomVariablesLegend = () => {
  return (
    <>
      <SpreadsheetLegend />
      <div className="CustomVariablesConfig">
        <WithPopover
          className="CustomVariablesConfig_Popover"
          content={
            <>
              <FormLabel
                htmlFor={SPREADSHEET_PERCISION}
                text="Spreadsheet Precision"
              />
              <RadioGroupPersistent
                id={SPREADSHEET_PERCISION}
                options={[
                  { label: 'Round to whole number', value: false },
                  { label: 'Show 2 decimals', value: true },
                ]}
                value={false}
              />
            </>
          }
          data-testid="customVariablesConfig"
          placement="bottom-end"
        >
          <button
            type="button"
            className="Button-iconOnly"
            aria-label="Options"
          >
            <ConfigIcon className="ConfigIcon" aria-hidden="true" />
          </button>
        </WithPopover>
      </div>
    </>
  );
};

export default CustomVariablesLegend;
