// @ts-check
import { useEffect, useRef } from 'react';

/**
 * Calls the callback function after specific time intervals
 *
 * @type {(
 *   callback: TimerHandler,
 *   interval: number | null,
 *   disabled?: boolean,
 * ) => void}
 */
const useInterval = (callback, interval, disabled) => {
  /** @type {React.MutableRefObject<TimerHandler>} */
  const savedCallback = useRef(callback);

  useEffect(() => {
    /** @type {number} */
    let id;
    if (interval !== null && !disabled) {
      id = setInterval(savedCallback.current, interval);
    }
    return () => {
      if (id) clearInterval(id);
    };
  }, [interval, disabled]);
};

export default useInterval;
