// eslint-disable-next-line import/prefer-default-export -- predates description requirement
export const isCustomRevenueValid = (customRevenue, key) => {
  if (!customRevenue?.length) {
    return false;
  }

  const possibleKeys = ['revenueAmount', 'noOfCustomer'];

  if (!possibleKeys.includes(key)) {
    throw new Error(
      `Unknown key: ${key}. Expected either 'revenueAmout' or 'noOfCustomer'`,
    );
  }

  const allValues = customRevenue
    .map((year) => year.months.map((month) => month[key]))
    .flat();

  return (
    !allValues.some((value) => !Number.isFinite(parseInt(value, 10))) &&
    !allValues.every((value) => parseInt(value, 10) === 0)
  );
};
