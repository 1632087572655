// @ts-check
import { useCallback, useEffect, useReducer } from 'react';
import CrossIcon from '@bill/cashflow.assets/cross';
import EditWithBorderIcon from '@bill/cashflow.assets/edit-with-border';
import PlusFlatIcon from '@bill/cashflow.assets/plus-flat';
import RuleEmptyIcon from '@bill/cashflow.assets/rule-empty';
import TrashWithBorderIcon from '@bill/cashflow.assets/trash-with-border';
import UndoIcon from '@bill/cashflow.assets/undo';
import { useMutation } from '@tanstack/react-query';
import { Reorder, m as motion } from 'framer-motion';
import CollapsibleRulesPreview from '@/components/Revenue/DataMapping/CollapsibleRulesPreview';
import RuleForm from '@/components/Revenue/DataMapping/RuleForm';
import RuleListItem from '@/components/Revenue/DataMapping/RuleListItem';
import { getRevenueStream } from '@/components/Revenue/DataMapping/helpers';
import Button from '@/components/common/Button';
import EmptyData from '@/components/common/EmptyData';
import IconButton from '@/components/common/IconButton';
import Sidebar from '@/components/common/Sidebar';
import {
  ADD_RULES,
  EDIT_RULE,
  UPDATE_RULE,
  SHOW_FORM,
  DELETE_RULE,
  DELETE_MESSAGE,
  REORDER_RULES,
} from '@/constants/dataMapping';
import { classNames } from '@/helpers';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import { addRevenueStreamsRules } from '@/services/revenueService';
import './AddPreviewRulesSidebar.scss';

const OPEN = 'OPEN';

/** @type {import('./types').RulePanelState} */
const INITIAL_STATE = {
  rules: [],
  editRule: null,
  isFormVisible: false,
  deleteRules: [],
  isDeleteMessageVisible: true,
};

/**
 * @type {(
 *   state: import('./types').RulePanelState,
 *   action: import('./types').RulePanelAction,
 * ) => import('./types').RulePanelState}
 */
const reducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case ADD_RULES:
      return {
        ...state,
        rules: [...state.rules, ...action.rules],
        isFormVisible: false,
      };
    case EDIT_RULE: {
      const ruleByName = state.rules.find(
        (rule) => rule.name === action.editRuleName,
      );
      if (!ruleByName) return state;
      return { ...state, editRule: ruleByName, isFormVisible: true };
    }
    case UPDATE_RULE: {
      const rules = [...state.rules];
      const ruleIndex = state.rules.findIndex(
        ({ name }) => name === state.editRule.name,
      );
      if (ruleIndex === -1)
        return { ...state, editRule: null, isFormVisible: false };
      rules[ruleIndex] = action.updateRule;
      return { ...state, rules, editRule: null, isFormVisible: false };
    }
    case DELETE_RULE: {
      return {
        ...state,
        deleteRules: action.deleteRules,
        editRule: null,
        isFormVisible: false,
      };
    }
    case DELETE_MESSAGE: {
      return { ...state, isDeleteMessageVisible: false };
    }
    case REORDER_RULES:
      return { ...state, rules: action.rules };
    case SHOW_FORM: {
      const isFormVisible =
        action.force !== undefined
          ? action.force === OPEN
          : !state.isFormVisible;
      return {
        ...state,
        isFormVisible,
        editRule: null,
      };
    }
    default:
      return state;
  }
};

/**
 * @typedef {{
 *   onSave: () => void;
 *   revenue: import('@/services/revenueService').RevenueStreamsWithPricingPlan[];
 *   columns: import('./types').RevenueIntegrationColumn;
 *   onClose: () => void;
 *   rules: import('@/services/revenueService').RevenueIntegrationRule[];
 *   isLoading: boolean;
 * }} RulesSideBarContentProps
 */

/** @type {React.FC<RulesSideBarContentProps>} */
const RulesSideBarContent = ({
  rules,
  onSave,
  revenue,
  columns,
  isLoading,
  onClose,
}) => {
  const [state, setState] = useReducer(reducer, INITIAL_STATE);
  const editingRuleName = state.editRule ? state.editRule.name : '';
  const [scenarioId] = useSelectedScenarioIds();

  const { mutate: saveRules, isLoading: isSavingRules } = useMutation(
    addRevenueStreamsRules,
    {
      onSuccess: () => {
        onSave();
        onClose();
      },
    },
  );

  /** @type {import('./types').HandleAddClick} */
  const handleAddRules = (rule) => {
    setState({ type: ADD_RULES, rules: [rule] });
  };

  /** @type {import('./types').HandleAddClick} */
  const handleUpdateRule = (rule) => {
    setState({ type: UPDATE_RULE, updateRule: rule });
  };

  /** @type {(name: import('./types').RuleName) => void} */
  const handleEditRule = (name) => {
    setState({ type: EDIT_RULE, editRuleName: name });
  };

  /**
   * @type {(params?: {
   *   force?: import('./types').ForceVisibleState;
   * }) => void}
   */
  const toggleForm = ({ force } = {}) => setState({ type: SHOW_FORM, force });

  /** @type {import('./types').HandleDeleteRule} */
  const handleDeleteRule = (ruleName) => {
    setState({
      type: DELETE_RULE,
      deleteRules: [...state.deleteRules, ruleName],
    });
  };
  /** @type {(ruleName: import('./types').RuleName) => void} */
  const handleRevertRule = (ruleName) => {
    setState({
      type: DELETE_RULE,
      deleteRules: state.deleteRules.filter((rule) => rule !== ruleName),
    });
  };

  const handleSaveRules = useCallback(() => {
    const filteredRules = state.rules.filter(
      ({ name }) => !state.deleteRules.includes(name),
    );
    saveRules({ scenarioId, rules: filteredRules });
  }, [saveRules, scenarioId, state.rules, state.deleteRules]);

  useEffect(() => {
    if (!isLoading) {
      const rulesData = rules.map((data) => ({
        ...data,
        revenueStream: getRevenueStream(data.revenueStreamId, revenue),
      }));
      setState({ type: ADD_RULES, rules: rulesData });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [rules, isLoading]);

  const isAddingNewRule = state.isFormVisible && !state.editRule;

  return (
    <div className="RulesSideBarContent">
      <div className="RulesSideBarContent_Panel">
        <header className="Sidebar_Header">
          <h3 className="Sidebar_Title">Manage Data Mapping</h3>
          <button
            className="Sidebar_CloseBtn"
            onClick={() => {
              onClose();
            }}
            aria-label="Close"
          >
            <CrossIcon className="CloseIcon" />
          </button>
        </header>
        <motion.div className="RulesSideBarContent_Rules" layoutScroll>
          <>
            {!isLoading && state.rules.length > 0 && (
              <>
                <p className="RulesSideBarContent_ListDescription">
                  Rules determine where the records go in Finmark. The
                  precedence of the rules is set by the order below.
                </p>

                {state.deleteRules.length > 0 &&
                  state.isDeleteMessageVisible && (
                    <div className="RulesSideBarContent_Confirmation">
                      <p className="RulesSideBarContent_ConfirmationTitle">
                        You have selected rules to be deleted. Deleting these
                        rules will change Actuals calculations. The deletion
                        process will take place once you click the 'Save'
                        button.
                      </p>
                      <button
                        className="RulesList_ConfirmationButton"
                        onClick={() => setState({ type: DELETE_MESSAGE })}
                      >
                        OK, I understand
                      </button>
                    </div>
                  )}
                <Reorder.Group
                  axis="y"
                  values={state.rules}
                  onReorder={(newRules) => {
                    if (!isAddingNewRule) {
                      setState({ type: REORDER_RULES, rules: newRules });
                    }
                  }}
                  className="RulesSideBarContent_List"
                >
                  {state.rules.map((rule) => {
                    const { name } = rule;
                    const isDeleteRule = state.deleteRules.includes(name);
                    return (
                      <RuleListItem
                        id={name}
                        value={rule}
                        key={name}
                        disabled={isAddingNewRule}
                        className="RulesSideBarContent_ListItem"
                      >
                        {editingRuleName === name ? (
                          <RuleForm
                            revenue={revenue}
                            columns={columns}
                            editRule={state.editRule}
                            rules={state.rules}
                            onClickAdd={
                              editingRuleName
                                ? handleUpdateRule
                                : handleAddRules
                            }
                            onDelete={handleDeleteRule}
                            onCancel={() => toggleForm()}
                          />
                        ) : (
                          <div
                            className={classNames(
                              'RulesSideBarContent_ListItemWrapper',
                              isDeleteRule &&
                                'RulesSideBarContent_ListDeleteView',
                            )}
                          >
                            {isDeleteRule ? (
                              <h3 className="RulesSideBarContent_ListTitle">
                                <PlusFlatIcon className="RulesSideBarContent_PlusIcon" />
                                <span className="RulesSideBarContent_Undo">
                                  {name}
                                </span>
                              </h3>
                            ) : (
                              <CollapsibleRulesPreview rule={rule} />
                            )}
                            {isDeleteRule ? (
                              <IconButton
                                className="RulesSideBarContent_ButtonIcon"
                                onClick={() => handleRevertRule(name)}
                                Icon={UndoIcon}
                                label="Undo Delete"
                                data-testid="undo-icon-tooltip"
                              />
                            ) : (
                              <div>
                                <button
                                  className="RulesSideBarContent_ButtonIcon"
                                  onClick={() => handleEditRule(name)}
                                  aria-label="Edit"
                                >
                                  <EditWithBorderIcon />
                                </button>
                                <button
                                  className="RulesSideBarContent_ButtonIcon"
                                  onClick={() => handleDeleteRule(name)}
                                  aria-label="Delete"
                                >
                                  <TrashWithBorderIcon className="RulesSideBarContent_DeleteIcon" />
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </RuleListItem>
                    );
                  })}
                </Reorder.Group>
              </>
            )}
          </>
          {isAddingNewRule && (
            <RuleForm
              revenue={revenue}
              columns={columns}
              editRule={state.editRule}
              rules={state.rules}
              onClickAdd={handleAddRules}
              onCancel={() => toggleForm()}
            />
          )}

          {!state.rules.length && !state.isFormVisible ? (
            <EmptyData
              heading="No Rules Available"
              Icon={RuleEmptyIcon}
              onAdd={() => setState({ type: SHOW_FORM })}
            >
              You have not yet created any mapping rules.
              <br />
              Click the green ‘+’ button to begin.
            </EmptyData>
          ) : (
            <button
              className={classNames(
                'RulesSideBarContent_AddButton',
                state.isFormVisible && 'RulesSideBarContent_AddButton',
              )}
              onClick={() => toggleForm({ force: OPEN })}
              disabled={state.isFormVisible && !state.editRule}
            >
              + Add new rule
            </button>
          )}

          {state.rules.length > 0 &&
            !state.isFormVisible &&
            !editingRuleName && (
              <Button
                className="Button-primary RulesSideBarContent_SaveButton"
                data-testid="add-revenue-stream-next"
                onClick={handleSaveRules}
                loading={isSavingRules}
              >
                Save
              </Button>
            )}
        </motion.div>
      </div>
    </div>
  );
};

/**
 * @typedef {{
 *   open: boolean;
 *   onClose: () => void;
 *   rules: import('@/services/revenueService').RevenueIntegrationRule[];
 *   isLoading: boolean;
 *   revenue: import('@/services/revenueService').RevenueStreamsWithPricingPlan[];
 *   onSave: () => void;
 *   columns: import('./types').RevenueIntegrationColumn;
 * }} AddPreviewRulesSidebarProps
 */

/** @type {React.FC<AddPreviewRulesSidebarProps>} */
const AddPreviewRulesSidebar = ({
  rules,
  onSave,
  revenue,
  columns,
  isLoading,
  open,
  onClose,
}) => {
  return (
    <Sidebar
      className={classNames('RulesToggleSidebar')}
      open={open}
      onClose={onClose}
    >
      {open && (
        <RulesSideBarContent
          rules={rules}
          onSave={onSave}
          columns={columns}
          revenue={revenue}
          onClose={onClose}
          isLoading={isLoading}
        />
      )}
    </Sidebar>
  );
};

export default AddPreviewRulesSidebar;
