// @ts-check
import { createContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_BILL_SESSION_INFO } from '@/graphql/queries';
import isBillDomain from '@/helpers/isBillDomain';

export const BillProfileType = /** @type {const} */ ({
  Admin: '2',
  Clerk: '3',
  Approver: '4',
  Accountant: '5',
  Custom: '6', // Authorized User
  Payer: '7',
  Partner: '8',
  Auditor: '9',
  AuthUser: '6',
  NoAccess: '10',
});

const SESSION_INFO_DEFAULT = {
  isSessionLoading: false,
  /** @type {import('@/types/sessionInfo').SessionInfo} */
  sessionInfo: null,
};

/** @typedef {typeof SESSION_INFO_DEFAULT} BillSessionContextType */

/** @type {React.Context<BillSessionContextType>} */
const BillSessionContext = createContext(SESSION_INFO_DEFAULT);

/**
 * BILL Session Provider. Fetch the user's Bill session info and make it
 * available for use
 */
const BillSessionProvider = ({ children }) => {
  /**
   * @type {import('@apollo/client').QueryResult<{
   *   sessionInfo: import('@/types/sessionInfo').SessionInfo;
   * }>}
   */
  const { data, loading, error } = useQuery(GET_BILL_SESSION_INFO, {
    context: {
      gateway: true,
    },
    skip: !isBillDomain(),
  });

  /** @type {BillSessionContextType} */
  const value = useMemo(() => {
    return {
      isSessionLoading: loading,
      error,
      sessionInfo: data?.sessionInfo,
    };
  }, [loading, data, error]);

  return (
    <BillSessionContext.Provider value={value}>
      {children}
    </BillSessionContext.Provider>
  );
};

export { BillSessionProvider, BillSessionContext };
