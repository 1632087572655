export const SET_PRICE_PLANS_LIST = 'revenue/SET_PRICE_PLANS_LIST';
export const SET_REVENUE_STREAM_TOTAL = 'revenue/SET_REVENUE_STREAM_TOTAL';
export const SET_REVENUE_ERROR = 'revenue/SET_REVENUE_ERROR';
export const SET_REVENUE_MODAL_ERROR = 'revenue/SET_REVENUE_MODAL_ERROR';
export const SET_SAVE_FINANCING_RESPONSE =
  'revenue/SET_SAVE_FINANCING_RESPONSE';
export const SET_FINANCING_SOURCE_LIST = 'revenue/SET_FINANCING_SOURCE_LIST';
export const SET_FINANCING_MODAL_ERROR = 'revenue/SET_FINANCING_MODAL_ERROR';
export const UPDATE_PRODUCT = 'revenue/UPDATE_PRODUCT';
export const UPDATE_PLAN = 'revenue/UPDATE_PLAN';
export const HANDLE_STREAM_DATA_CHANGE = 'revenue/HANDLE_STREAM_DATA_CHANGE';
export const UPDATE_PRODUCTS = 'revenue/UPDATE_PRODUCTS';
export const UPDATE_PRODUCTS_LIST = 'revenue/UPDATE_PRODUCTS_LIST';
export const UPDATE_PLANS = 'revenue/UPDATE_PLANS';
export const SET_REVENUE_STREAM = 'revenue/SET_REVENUE_STREAM';
export const CLEAR_STREAM = 'revenue/CLEAR_STREAM';
export const UPDATE_CUSTOM_REVENUE = 'revenue/UPDATE_CUSTOM_REVENUE';
export const SET_PRODUCT_REVENUE_LIST = 'revenue/SET_PRODUCT_REVENUE_LIST';
export const SET_REVENUE_DETAILS_LIST = 'revenue/SET_REVENUE_DETAILS_LIST';
export const SET_REVENUE_STREAMS_LIST = 'revenue/SET_REVENUE_STREAMS_LIST';
export const ADD_NEW_PRICING_PLAN = 'revenue/ADD_NEW_PRICING_PLAN';
export const ADD_NEW_PRODUCT = 'revenue/ADD_NEW_PRODUCT';
export const SET_PRODUCT_RECORD_TO_BE_EDIT =
  'revenue/revenue/SET_PRODUCT_RECORD_TO_BE_EDIT';
export const UPDATE_PRICING_PLAN = 'UPDATE_PRICING_PLAN';
export const REMOVE_PRICING_PLAN_BY_INDEX =
  'revenue/REMOVE_PRICING_PLAN_BY_INDEX';
export const SET_REVENUE_STREAM_RECORD_TO_BE_EDIT =
  'revenue/SET_REVENUE_STREAM_RECORD_TO_BE_EDIT';
export const SET_ERROR = 'revenue/SET_ERROR';
export const SET_REVENUE_STREAM_WITH_PLANS =
  'revenue/setRevenueStreamWithPlans';
export const SET_REFRESH_ACTUALS_ONLY = 'revenue/SET_REFRESH_ACTUALS_ONLY';
