import COLORS from '@/constants/colorPalette';

/**
 * Tooltip text for a chart that has a date range beginning with a value invalid
 * for calculating period-over-period change.
 */
export const INVALID_POP_VALUE_TOOLTIP_TEXT =
  'You’ve selected a time range where the revenue value is zero in the first month of the range. Please select a starting month for the time range where the revenue value is not zero.';

/**
 * Tooltip text for a CAC payback chart that has a time in which Average New MRR
 * or Gross margin is zero.
 */
export const INVALID_CAC_PAYBACK_TOOLTIP_TEXT =
  'You’ve selected a time range where the average new MRR or average gross margin for the past 3 months is zero.';

export const CHART_MARGIN = 100;

export const ERROR_BOUNDARY_TEXT =
  'An error occured while trying to display this chart.';

export const CHART_COLORS = [
  COLORS.finmarkBlue,
  COLORS.finmarkBlue2,
  COLORS.finmarkBlue3,
  COLORS.cornflower,
  COLORS.cornflower2,
  COLORS.cornflower3,
  COLORS.crayola,
  COLORS.crayola2,
  COLORS.crayola3,
  COLORS.turf,
  COLORS.turf2,
  COLORS.turf3,
  COLORS.yellow,
  COLORS.yellow2,
  COLORS.yellow3,
  COLORS.sunrise,
  COLORS.sunrise2,
  COLORS.sunrise3,
  COLORS.cinnabar,
  COLORS.cinnabar2,
  COLORS.cinnabar3,
  COLORS.pink,
  COLORS.pink2,
  COLORS.pink3,
  COLORS.plum,
  COLORS.plum2,
  COLORS.plum3,
  COLORS.slate,
  COLORS.slate2,
  COLORS.slate3,
  COLORS.raisinBlack,
  COLORS.grey,
  COLORS.grey2,
  COLORS.grey3,
  COLORS.grey4,
  COLORS.grey5,
];

export const CHART_PRECISION_THRESHOLD = 1000;

export const AWAITING_DATA = 'Awaiting Data';
