// @ts-check
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@/components/common/Button';
import FormField from '@/components/common/FormField';
import Modal from '@/components/common/Modal';
import { REVENUE_STRIPE } from '@/constants/integrations';
import { REVENUE_API_NAME_MAX_LENGTH } from '@/constants/revenue';
import { formatValueForDisplay } from '@/helpers/percentageFormulaFormatter';
import { isEmptyOrNull } from '@/helpers/validators';
import useViewOnlyMode from '@/hooks/useViewOnlyMode';
import { addProduct, updateProduct } from '@/services/revenueService';
import FormatPricingPlanName from './FormatPricingPlanName';
import PricingPlanForm from './PricingPlanForm';
import ProductDeleteModals from './ProductDeleteModals';

const ProductModal = ({
  scenarioId,
  startDate,
  endDate,
  showModal,
  closeModal,
  productToEdit,
  toggleMainModal,
}) => {
  const [productName, setProductName] = useState('');
  const [pricingPlans, setPricingPlans] = useState([]);
  const [selectedPricingPlanIndex, setSelectedPricingPlanIndex] = useState();
  const [pricingPlanInProgress, setPricingPlanInProgress] = useState(true);
  const [showDuplicateErrorMessage, setShowDuplicateErrorMessage] =
    useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [showWarningMessage, setShowWarningMessage] = useState(true);
  const [error, setError] = useState();
  const [deleteResponseError, setDeleteResponseError] = useState();

  const toggleShowConfirmDeleteModal = (flag = !showConfirmDeleteModal) => {
    setShowConfirmDeleteModal(flag);
    toggleMainModal();
  };
  const [showCannotDeletePricingPlan, setShowCannotDeletePricingPlan] =
    useState(false);
  const toggleCannotDeletePricingPlan = (
    flag = !showCannotDeletePricingPlan,
  ) => {
    setShowCannotDeletePricingPlan(flag);
    toggleMainModal();
  };
  const [savingProduct, setSavingProduct] = useState(false);

  const modalBodyRef = useRef(null);

  const isViewOnly = useViewOnlyMode(modalBodyRef, {
    forceRerun: showModal,
  });

  useEffect(() => {
    /**
     * The ProductModal is mounted as soon as Revenue screen is rendered. It
     * does not unmount on every hide and show trigger, so we can handle
     * mounting and unmounting logic here
     */
    setShowDuplicateErrorMessage(false);
  }, [showModal]);

  useEffect(() => {
    if (productToEdit) {
      setProductName(productToEdit.name);
      setPricingPlans(productToEdit.pricingPlans);
      setPricingPlanInProgress(false);
      setShowWarningMessage(false);
    }
  }, [productToEdit]);

  const resetPricingPlanFields = () => {
    setSelectedPricingPlanIndex(undefined);
    setPricingPlanInProgress(false);
  };

  const isDuplicatePricingPlan = (newPricingPlan) => {
    return pricingPlans.some((plan) => plan.name === newPricingPlan.name);
  };

  const addPricingPlan = (pricePlan) => {
    if (isDuplicatePricingPlan(pricePlan)) {
      setShowDuplicateErrorMessage(true);
      return false;
    }

    if (showWarningMessage) {
      setShowWarningMessage(false);
    }

    if (showDuplicateErrorMessage) {
      setShowDuplicateErrorMessage(false);
    }

    setPricingPlans(pricingPlans.concat(pricePlan));
    resetPricingPlanFields();

    return true;
  };

  const editPricingPlan = (pricePlan, index) => {
    if (showWarningMessage) {
      setShowWarningMessage(false);
    }

    const tempPricingPlans = [...pricingPlans];
    tempPricingPlans[index] = {
      ...tempPricingPlans[index],
      ...pricePlan,
    };
    setPricingPlans(tempPricingPlans);
    resetPricingPlanFields();

    return true;
  };

  const handleEditPricingPlan = (index) => {
    setSelectedPricingPlanIndex(index);
    setPricingPlanInProgress(true);
  };

  const deletePricingPlan = (deleteIndex = 0) => {
    const tempPricingPlansList = pricingPlans.filter(
      (i, index) => index !== deleteIndex,
    );
    setPricingPlans(tempPricingPlansList);

    if (tempPricingPlansList.length < 1 && !productToEdit) {
      setShowWarningMessage(true);
    }
  };

  const handleDeletePricingPlan = (index) => {
    if (pricingPlans[index].isUsed) {
      toggleCannotDeletePricingPlan();

      return;
    }

    if (pricingPlans.length === 1 && productToEdit) {
      toggleShowConfirmDeleteModal(true);

      return;
    }

    deletePricingPlan(index);
  };

  const handleClose = () => {
    setProductName('');
    setPricingPlans([]);
    setError(null);
    closeModal();
  };

  const renderPricingPlans = () => {
    return (
      <>
        <h5 className="ProductModal_SubHeading">Pricing Plans</h5>
        {pricingPlans.map((pricingPlan, index) => {
          return (
            <Fragment key={pricingPlan.id ?? String(index)}>
              <FormatPricingPlanName
                product={pricingPlan}
                isCurrentPlanSelected={selectedPricingPlanIndex === index}
                productName={productName}
                hasWritePermission={!isViewOnly}
                onEditPlan={() => handleEditPricingPlan(index)}
                onDeletePlan={() => handleDeletePricingPlan(index)}
              />
              {selectedPricingPlanIndex === index && (
                <PricingPlanForm
                  pricingPlan={{
                    ...pricingPlan,
                    churnFormula: formatValueForDisplay(
                      pricingPlan.churnFormula,
                      pricingPlan.churnType,
                    ),
                  }}
                  editPricingPlan={editPricingPlan}
                  onCancel={resetPricingPlanFields}
                  selectedPricingPlanIndex={selectedPricingPlanIndex}
                  hasWritePermission={!isViewOnly}
                  hasPricingPlans={!!pricingPlans.length}
                />
              )}
            </Fragment>
          );
        })}
        <hr />
      </>
    );
  };

  const isFormValid = () => {
    let considerPricingPlanLength = true;

    if (!productToEdit) {
      considerPricingPlanLength = pricingPlans.length > 0;
    }

    return considerPricingPlanLength && productName && !pricingPlanInProgress;
  };

  const saveProduct = async (saveProd) => {
    try {
      const successfulAdd = await addProduct(
        scenarioId,
        saveProd,
        startDate,
        endDate,
      );
      if (successfulAdd) {
        handleClose();
      }
    } catch (e) {
      setError(e.response?.data?.error?.errorMessage || e.message);
    }
  };

  const handleEditProduct = async (productId, product) => {
    const successfulAdd = await updateProduct(
      scenarioId,
      productId,
      product,
      startDate,
      endDate,
    );
    if (successfulAdd) {
      handleClose();
    }
  };

  const onSave = async () => {
    setSavingProduct(true);
    const product = {
      name: productName,
      pricingPlans,
    };
    if (productToEdit) {
      try {
        await handleEditProduct(productToEdit.id, product);
      } catch (e) {
        setError(e.response?.data?.error?.errorMessage || e.message);
      }
    } else {
      await saveProduct(product);
    }
    setSavingProduct(false);
  };

  const formHeading = useMemo(() => {
    if (isViewOnly) {
      return 'Product Details';
    }
    return `${productToEdit ? 'Edit' : 'Add'} Product`;
  }, [isViewOnly, productToEdit]);

  const handleDeleteProductConfirmation = async () => {
    toggleShowConfirmDeleteModal(false);
    handleClose();
    try {
      await handleEditProduct(productToEdit.id, { name: productName });
      deletePricingPlan();
    } catch (e) {
      setDeleteResponseError(
        e.response?.data?.error?.errorMessage || e.message,
      );
    }
  };

  return (
    <>
      <Modal
        open={showModal}
        onClose={closeModal}
        className="ProductModal"
        data-testid="add-revenue-product-modal"
      >
        <header className="ModalBase_Header">
          <h1 className="mb-0">{formHeading}</h1>
        </header>

        <div>
          {error && (
            <p className="alert alert-danger">
              <b>Error </b> - {error}
            </p>
          )}

          <h5 className="ProductModal_SubHeading">Product</h5>
          <div className="Form">
            <div className="Form_Group">
              <label className="Label" htmlFor="productName">
                Name of Product
              </label>
              <FormField
                id="productName"
                maxLength={REVENUE_API_NAME_MAX_LENGTH}
                validate={() =>
                  isEmptyOrNull(productName) && 'Field is Required'
                }
                name="productName"
                value={productName}
                onChange={({ target }) => setProductName(target.value)}
                disabled={
                  productToEdit &&
                  productToEdit.externalSource === REVENUE_STRIPE
                }
              />
            </div>
            <div className="Form_Group">
              <hr />
            </div>
            {showWarningMessage && (
              <div className="Form_Group">
                <p className="alert alert-warning">
                  All new products must include at least&nbsp;
                  <b>one</b>
                  &nbsp;pricing plan
                </p>
              </div>
            )}
          </div>
          {pricingPlans.length > 0 && renderPricingPlans()}
          {showDuplicateErrorMessage && (
            <p className="alert alert-danger">
              <b>Error </b> - Duplicate plan. Please try again with different
              details
            </p>
          )}
          {(pricingPlanInProgress && selectedPricingPlanIndex === undefined) ||
          pricingPlans.length < 1 ? (
            <PricingPlanForm
              addPricingPlan={addPricingPlan}
              onCancel={resetPricingPlanFields}
              hasWritePermission={!isViewOnly}
              hasPricingPlans={!!pricingPlans.length}
            />
          ) : null}
          {!isViewOnly && (
            <>
              <Button
                className="mt-4 Button Button-primaryLink"
                disabled={pricingPlanInProgress || pricingPlans.length < 1}
                onClick={() => setPricingPlanInProgress(true)}
                data-testid="add-pricing-plan"
              >
                + Add another pricing plan
              </Button>
              <hr />
            </>
          )}
        </div>

        <footer className="ModalBase_Footer">
          {!isViewOnly ? (
            <>
              <Button
                onClick={handleClose}
                className="Button Button-cancelLink"
                data-testid="add-pricing-cancel"
              >
                Cancel
              </Button>

              <Button
                onClick={onSave}
                disabled={!isFormValid()}
                loading={savingProduct}
                data-testid="add-pricing-action"
              >
                {productToEdit ? 'Finish Editing' : 'Save'}
              </Button>
            </>
          ) : (
            <Button onClick={closeModal} data-testid="add-pricing-close">
              Close
            </Button>
          )}
        </footer>
      </Modal>

      <ProductDeleteModals
        showCannotDeleteModal={showCannotDeletePricingPlan}
        onCloseCannotDeleteModal={() => toggleCannotDeletePricingPlan(false)}
        showConfirmDeleteModal={showConfirmDeleteModal}
        onCancelConfirmDeleteModal={() => toggleShowConfirmDeleteModal(false)}
        onConfirmDeleteModal={handleDeleteProductConfirmation}
        deleteResponseError={deleteResponseError}
        removeDeleteResponseError={() => setDeleteResponseError(null)}
      />
    </>
  );
};

function mapStateToProps({ scenario, shared }) {
  return {
    scenarioId: scenario.scenarioId,
    startDate: shared.startDate,
    endDate: shared.endDate,
  };
}

export default connect(mapStateToProps)(ProductModal);
