import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  employeesByDepartment as employeesByDepartmentAction,
  getEmployees as getEmployeesAction,
} from '@/actions/employees';
import Button from '@/components/common/Button';
import BulkUploadButton from '@/components/common/DocumentUploader/BulkUploadButton';
import DocumentUploader from '@/components/common/DocumentUploader/DocumentUploader';
import { statuses } from '@/components/common/DocumentUploader/constants';
import Modal from '@/components/common/Modal';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';
import { formatListWithConjunction } from '@/helpers';
import UploadErrorsModal from '@/pages/Employee/UploadErrorsModal';

const initialState = {
  status: statuses.INIT,
  initiatedAt: null,
  progressLoaded: 0,
  progressTotal: 0,
  files: null,
  error: null,
  cancelToken: null,
};
const DropzoneContent = (fileExtensions) => {
  return (
    <p className="Dropzone_Text">
      Import from a {fileExtensions} spreadsheet by dragging it here or{' '}
      <button type="button" className="Button Button-primaryLink">
        browsing
      </button>{' '}
      your local drive.
    </p>
  );
};

const AddMultipleModal = ({
  onClose,
  onFinish,
  onUpload,
  type,
  fileExtensions,
  progressLoaded,
  progressTotal,
  children,
}) => {
  const [showErrorsModal, setShowErrorsModal] = useState(false);
  const [state, setState] = useState(initialState);
  const resetState = () => setState(initialState);
  const uploadPromise = useRef(null);

  useEffect(() => {
    setState((previousState) => ({
      ...previousState,
      progressLoaded,
      progressTotal,
    }));
  }, [progressLoaded, progressTotal]);

  const onFilesSelected = (droppedFiles) => {
    setState((previousState) => ({
      ...previousState,
      status: statuses.FILE_SELECTED,
    }));
    setState((previousState) => ({ ...previousState, files: droppedFiles }));
  };

  const onSelectedFilesRejected = () => {
    setState((previousState) => ({
      ...previousState,
      status: statuses.FILE_SELECTED_ERROR,
    }));
  };

  const onCancelUpload = () => {
    uploadPromise.current?.cancel();
    resetState();
  };

  const handleUpload = async () => {
    setState((previousState) => ({
      ...previousState,
      initiatedAt: new Date(),
      status: statuses.UPLOADING,
    }));
    const promise = onUpload({
      file: state.files[0],
    });
    uploadPromise.current = promise;
    try {
      await promise;
      setState((previousState) => ({
        ...previousState,
        status: statuses.UPLOADING_COMPLETE,
      }));
    } catch (e) {
      if (uploadPromise.current?.signal?.aborted) {
        resetState();
        return;
      }
      // Temporary solution until error response object data structure is adjusted to be the same
      if (e.response?.data?.data?.length) {
        const childErrors = e.response.data.data.reduce((accum, row) => {
          const errorCode = `Row: ${row.rowId}`;
          const mappedErrors = row.errors.map((error) => {
            return {
              errorCode,
              errorMessage: `Field: ${error.property}`,
              errorDetails: error.message,
            };
          });
          return [...accum, ...mappedErrors];
        }, []);
        setState((previousState) => ({
          ...previousState,
          status: statuses.ERROR,
          error: {
            title: 'Missing or incorrect data',
            childErrors,
          },
        }));
        return;
      }

      if (e.response?.data?.error) {
        const { errorMessage, childErrors } = e.response.data.error;
        setState((previousState) => ({
          ...previousState,
          status: statuses.ERROR,
          error: {
            title: errorMessage,
            childErrors,
          },
        }));
        return;
      }

      const errorMessage = e.response?.data?.error?.errorMessage || e.message;
      setState((previousState) => ({
        ...previousState,
        status: statuses.ERROR,
        error: {
          title: errorMessage,
        },
      }));
    }
  };

  const extensions = Array.from(new Set(Object.values(fileExtensions).flat()));
  const fileExtensionString = formatListWithConjunction(extensions, {
    type: 'disjunction',
  });

  return (
    <>
      <ModalBase
        id={`multiple-${type}-modal`}
        header={<h2 className="ModalBase_Heading">Import Multiple {type}</h2>}
        footer={
          <>
            {state.status !== statuses.UPLOADING_COMPLETE && (
              <Button
                className="Button Button-cancelLink"
                onClick={() => {
                  onCancelUpload();
                  onClose();
                }}
                data-testid={`multiple-${type}-cancel-button`}
              >
                Cancel
              </Button>
            )}

            <BulkUploadButton
              handleFinish={onFinish}
              handleUpload={handleUpload}
              resetModal={resetState}
              status={state.status}
            />
          </>
        }
      >
        {children}
        <DocumentUploader
          id={`multiple-${type}-dropzone`}
          acceptedFileTypes={fileExtensions}
          data-testid={`multiple-${type}-dropzone`}
          onFilesSelected={onFilesSelected}
          onSelectedFilesRejected={onSelectedFilesRejected}
          onCancelUpload={onCancelUpload}
          uploadState={state}
          error={state.error}
          DropzoneContent={() => DropzoneContent(fileExtensionString)}
        >
          {state.error?.childErrors?.length ? (
            <>
              There are missing required fields or contain incorrect data.
              Please update your {fileExtensionString} file and upload again.
              Click{' '}
              <Button
                data-testid="error-modal-trigger"
                className="Button-deleteLink Button-dropzoneError"
                onClick={() => setShowErrorsModal(true)}
              >
                here
              </Button>{' '}
              to view the specific records which will need your attention.
            </>
          ) : (
            state.error?.body
          )}
        </DocumentUploader>
      </ModalBase>
      <Modal
        className="UploadErrors_Modal"
        data-testid={`upload-errors-${type}-modal`}
        open={showErrorsModal}
      >
        <UploadErrorsModal
          errors={state.error?.childErrors}
          onClose={() => setShowErrorsModal(false)}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  startDate: state.shared.startDate,
  endDate: state.shared.endDate,
});
export default connect(mapStateToProps, {
  employeesByDepartmentAction,
  getEmployeesAction,
})(AddMultipleModal);
