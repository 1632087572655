// @ts-check
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import actuals from './actuals';
import aiConversation from './aiConversation';
import auth from './auth';
import companies from './companies';
import componentLoading from './componentLoading';
import dashboard from './dashboard';
import employees from './employees';
import expenses from './expenses';
import features from './features';
import notifications from './notifications';
import reports from './reports';
import revenues from './revenue';
import scenario from './scenario';
import settings from './settings';
import shared from './shared';
import signUp from './signUp';
import subscriptions from './subscriptions';
import ui from './ui';
import variables from './variables';

const signUpPersistConfig = {
  key: 'signUp',
  storage,
  whitelist: ['signUpResponse'],
};

const rootReducer = () =>
  combineReducers({
    actuals,
    aiConversation,
    auth,
    companies,
    componentLoading,
    dashboard: persistReducer(
      {
        key: 'dashboard',
        storage,
        whitelist: [
          'selectedDashboardId',
          'dashboards',
          'userChartPreferences',
        ],
      },
      dashboard,
    ),
    employees,
    expenses,
    features: persistReducer(
      { key: 'features', storage, whitelist: ['flags'] },
      features,
    ),
    reports,
    revenues,
    scenario,
    settings,
    shared,
    signUp: persistReducer(signUpPersistConfig, signUp),
    subscriptions,
    ui,
    notifications,
    variables,
  });
export default rootReducer;
