import Highcharts from 'highcharts';

export const COMPARE_PATTERN_COLOR = 'rgba(255, 255, 255, 0.8)';

export const white = Highcharts.color('#fff');

/**
 * Generates a banded gradient from the given color to white, with the given
 * number of steps.
 *
 * @param {string} colorString Base color for the gradient
 * @param {number} steps Desired number of steps in the gradient
 * @returns {string[]} Array of RGB colors
 */
export function getBandedGradient(colorString, steps) {
  const colorObj = Highcharts.color(colorString);
  return [...Array(steps)].map((_, idx) =>
    colorObj.tweenTo(white, (1 / steps) * idx),
  );
}

/**
 * Generates a contrasting shade (50% brighter) for the given color, for use in
 * overlaying multiple series.
 *
 * @param {string} colorString Base color
 * @returns {string} RGB color
 */
export function getContrastingShade(colorString) {
  return Highcharts.color(colorString).tweenTo(white, 0.5);
}

/**
 * Generates a gradient from the given color to fully transparent, for the
 * purpose of filling an area series.
 *
 * @param {string} colorString Base color
 * @returns {Highcharts.GradientColorObject} Object for populating AreaSeries
 *   fillColor
 */
export function getAreaSeriesFill(colorString) {
  return {
    linearGradient: {
      x1: 0,
      x2: 0,
      y1: 0.1,
      y2: 0.85,
    },
    stops: [
      [0, Highcharts.color(colorString).tweenTo(white, 0.86)],
      [1, 'rgba(255, 255, 255, 0)'],
    ],
  };
}

/**
 * Returns a Highcharts pattern using the given color, used for shading
 * comparison scenarios in charts.
 *
 * @param {string} backgroundColor
 * @returns {Object} pattern configuration
 */
export function getComparisonPatternFill(backgroundColor) {
  return {
    pattern: {
      path: {
        d: 'M-1,1 l2,-2 M0,10 l10,-10 M9,11 l2,-2',
        strokeWidth: 2,
      },
      width: 10,
      height: 10,
      backgroundColor,
      color: COMPARE_PATTERN_COLOR,
    },
  };
}
