import { START_SCENARIO_USER_CREATION } from '@/actionTypes/auth';
import {
  HANDLE_DATA_CHANGE,
  IS_AJAXING,
  SET_FORGOT_PASSWORD_RESPONSE,
  SET_RESET_PASSWORD_RESPONSE,
} from '@/actionTypes/signUp';

const INITIAL_STATE = {
  email: '',
  company: '',
  firstName: '',
  lastName: '',
  isAjaxing: false,
  signUpResponse: {
    emailAddress: '',
    error: '',
  },
  password: '',
  confirmPassword: '',
  forgotPasswordResponse: {},
  temporaryPassword: '',
  newPassword: '',
  confirmNewPassword: '',
  resetPasswordResponse: {},
};

const signUp = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case START_SCENARIO_USER_CREATION:
      return { ...state, signUpResponse: action.payload.signUpResponse };
    case HANDLE_DATA_CHANGE:
      return { ...state, [action.payload.name]: action.payload.value };
    case SET_FORGOT_PASSWORD_RESPONSE:
      return { ...state, forgotPasswordResponse: action.payload };
    case SET_RESET_PASSWORD_RESPONSE:
      return { ...state, resetPasswordResponse: action.payload };
    case IS_AJAXING:
      return { ...state, isAjaxing: action.payload };
    default:
      return state;
  }
};

export default signUp;
