import { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getVariablesAction } from '@/actions/variables';
import WithAutocomplete from '@/components/common/WithAutocomplete';
import { PRESET_FORMULA_KEYS } from '@/constants/formulas';
import filterAndSortOptions from './filterAndSortOptions';
import FormulaParser from './formulaParser';

const getAutocompleteOptions = (options, searchTerm = '') => {
  if (searchTerm === '') {
    return [];
  }

  return options.some(
    ({ label }) => label.toLowerCase() === searchTerm.toLowerCase(),
  )
    ? []
    : filterAndSortOptions(options, searchTerm);
};

/**
 * Augments an input field (or other children) with autocomplete functionality
 * for variables. For a drop-in component for writing formulas, @see
 * FormulaField
 *
 * @example
 *   <WithVariableAutocomplete
 *     inputRef={inputRef}
 *     value={foo}
 *     data-testid="foo-testid"
 *     onChange={(newValue) => setFoo(newValue)}
 *   >
 *     {(props) => <input type="text" {...props} />}
 *   </WithVariableAutocomplete>;
 */
const WithVariableAutocomplete = ({
  getVariables,
  scenarioId,
  children,
  className,
  'data-testid': dataTestId,
  inputRef,
  value,
  variables,
  onChange,
  presetFormulas,
  presetFormulasId,
  onBlur,
  onFocus,
  ...props
}) => {
  const parser = useMemo(() => new FormulaParser(), []);
  const [options, setOptions] = useState([]);
  const [variableRange, setVariableRange] = useState({
    variableStart: null,
    variableEnd: null,
  });
  const [cursorPosition, setCursorPosition] = useState(null);

  const handleInputFocus = useCallback(
    ({ target }) => {
      if (presetFormulasId && target.value.trim() === '') {
        setOptions(presetFormulas);
      }
    },
    [presetFormulas, presetFormulasId],
  );

  useEffect(() => {
    const { current } = inputRef;
    if (!current) return undefined;

    current.addEventListener('focus', handleInputFocus);
    return () => current.removeEventListener('focus', handleInputFocus);
  }, [handleInputFocus, inputRef]);

  useEffect(() => {
    getVariables(scenarioId);
  }, [getVariables, scenarioId]);

  useEffect(() => {
    if (!variables) return;

    if (value) {
      const { variableName, variableStart, variableEnd } = parser.parse(value);

      setVariableRange({
        variableStart,
        variableEnd,
      });

      setOptions(getAutocompleteOptions(variables, variableName));
    } else {
      setOptions([]);
      setVariableRange({
        variableStart: null,
        variableEnd: null,
      });
      parser.reset();
    }
  }, [value, parser, variables]);

  useEffect(() => {
    const { current } = inputRef;
    // eslint-disable-next-line no-param-reassign -- predates description requirement
    if (current) current.selectionEnd = cursorPosition;
  }, [cursorPosition, inputRef]);

  const handleClick = (option) => {
    const { variableStart, variableEnd } = variableRange;
    const start = variableStart ? value.slice(0, variableStart) : '';
    const end = variableEnd ? value.slice(variableEnd + 1, value.length) : '';
    onChange(`${start}${option.value}${end}`);
    inputRef.current.focus();
    setCursorPosition(variableStart + option.value.length);
  };

  return (
    <WithAutocomplete
      className={className}
      options={options}
      onClick={handleClick}
      onBackspace={() => {
        inputRef.current.focus();
      }}
      data-testid={dataTestId}
      inputRef={inputRef}
      onBlur={onBlur}
      onFocus={onFocus}
      {...props}
    >
      {(innerProps) => children({ inputRef, value, ...innerProps })}
    </WithAutocomplete>
  );
};

WithVariableAutocomplete.propTypes = {
  /**
   * A function that renders the input that should accept variables for
   * autocompletion
   *
   * @param {Object} props Props to pass to the input
   * @param {Object} props.ref Ref for exerting control on the input
   * @param {string} props.value Value to assign to the input
   */
  'children': PropTypes.func.isRequired,
  /** Additional class(es) to apply to the autocomplete menu */
  'className': PropTypes.string,
  /** Unique ID for selecting the field in unit/integration tests */
  'data-testid': PropTypes.string,
  'getVariables': PropTypes.func.isRequired,
  /**
   * A ref which should be assigned to the input, used to modify the cursor
   * position
   */
  'inputRef': PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }).isRequired,
  'scenarioId': PropTypes.number.isRequired,
  /** The value of the input */
  'value': PropTypes.string,
  /** An array of variable objects */
  'variables': PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
  /**
   * Event handler for field changes
   *
   * @param {Object} event
   */
  'onChange': PropTypes.func.isRequired,
  /**
   * Event handler that fires when the field loses focus
   *
   * @param {Object} event
   */
  'onBlur': PropTypes.func,
  /**
   * Event handler that fires on focus
   *
   * @param {Object} event
   */
  'onFocus': PropTypes.func,
  /** A key for grabbing preset formulas from the store */
  'presetFormulasId': PropTypes.oneOf(PRESET_FORMULA_KEYS),
  /** An array of preset formulas for the autocomplete */
  'presetFormulas': PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

function mapStateToProps({ scenario, variables }, { presetFormulasId }) {
  return {
    variables: variables.autocompleteOptions,
    presetFormulas: variables.presetFormulas[presetFormulasId] ?? [],
    scenarioId: scenario.scenarioId,
  };
}

export default connect(mapStateToProps, {
  getVariables: getVariablesAction,
})(WithVariableAutocomplete);
