import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import ArrowDecreaseIcon from '@bill/cashflow.assets/arrow-decrease';
import ArrowIncreaseIcon from '@bill/cashflow.assets/arrow-increase';
import AverageIcon from '@bill/cashflow.assets/average-icon';
import ColumnChartIcon from '@bill/cashflow.assets/column-chart';
import CrossIcon from '@bill/cashflow.assets/cross';
import CurrentMonthIcon from '@bill/cashflow.assets/current-month-icon';
import DifferenceAmountIcon from '@bill/cashflow.assets/difference-amount';
import EndingPeriodIcon from '@bill/cashflow.assets/ending-period';
import FunnelChartIcon from '@bill/cashflow.assets/funnel-chart';
import LineChartIcon from '@bill/cashflow.assets/line-chart-icon';
import NoChangeIcon from '@bill/cashflow.assets/no-change';
import PercentAreaIcon from '@bill/cashflow.assets/percent-area';
import PieChartIcon from '@bill/cashflow.assets/pie-chart';
import PreviousPeriodIcon from '@bill/cashflow.assets/previous-period';
import StackedAreaIcon from '@bill/cashflow.assets/stacked-area';
import SumIcon from '@bill/cashflow.assets/sum-icon';
import { useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import { getDashboardsAction } from '@/actions/dashboard';
import {
  getCustomVariablesAction,
  getCustomVariableSectionsAction,
  getSystemVariablesAction,
} from '@/actions/variables';
import { CUSTOM_CHART_INFO } from '@/cacheKeys';
import CollapsibleVariableGroup from '@/components/ChartBuilder/CollapsibleVariableGroup';
import DataSourceSelected from '@/components/ChartBuilder/DataSourceSelected';
import SecondaryMetricColorSelector from '@/components/ChartBuilder/SecondaryMetricColorSelector';
import { getBandedGradient } from '@/components/Charts/colors';
import { chartTypes } from '@/components/Charts/constants';
import Button from '@/components/common/Button';
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';
import FormRadio from '@/components/common/FormRadio';
import IconButton from '@/components/common/IconButton';
import InfoTooltip from '@/components/common/InfoTooltip';
import NotificationBanner, {
  notificationTypes,
} from '@/components/common/NotificationBanner';
import SearchField from '@/components/common/SearchField';
import Sidebar from '@/components/common/Sidebar';
import { variableTypes } from '@/constants/variables';
import { classNames, debounce, filterObject } from '@/helpers';
import {
  AVERAGE_MAIN_METRIC,
  SUM_MAIN_METRIC,
  CURRENT_MONTH_MAIN_METRIC,
  PREVIOUS_PERIOD,
  DIFF_LAST_PERIOD,
  ENDING_PERIOD,
  nonMonthlyValueTypes,
} from '@/helpers/customCharts';
import useEffectOnUpdate from '@/hooks/useEffectOnUpdate';
import useOneColor from '@/hooks/useOneColor';
import useSelectedScenarios from '@/hooks/useSelectedScenarios';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import {
  createChartMultiVariable,
  updateChartMultiVariable,
} from '@/services/dashboard.service';
import './ChartBuilder.scss';

const SET_MAIN_METRIC = 'SET_MAIN_METRIC';
const SET_NON_MONTHLY_METRIC = 'SET_NON_MONTHLY_METRIC';
const SET_CHART_TYPE = 'SET_CHART_TYPE';
const SET_CHART_TITLE = 'SET_CHART_TITLE';
const ADD_DATA_SOURCE = 'ADD_DATA_SOURCE ';
const CHANGE_VARIABLE_LABEL = 'CHANGE_VARIABLE_LABEL';
const REMOVE_DATA_SOURCE = 'REMOVE_DATA_SOURCE';
const SYNC_DATA_SOURCE = 'SYNC_DATA_SOURCE';
const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
const SET_SOURCE_COLOR = 'SET_SOURCE_COLOR';
const SET_ERROR = 'SET_ERROR';
const SET_SECONDARY_METRIC_OPTION = 'SET_SECONDARY_METRIC_OPTION';
const SET_CHART_PRECISION = 'SET_CHART_PRECISION';

const trimCustomVariableNames = (customVariableNames) => {
  return Object.keys(customVariableNames).reduce((accumulator, current) => {
    accumulator[current] = customVariableNames[current].trim();
    return accumulator;
  }, {});
};
const getSelectedVariableIds = (variables) =>
  Object.fromEntries(variables.map(({ variableId }) => [variableId, true]));

const filterVariableByQuery = (variable, query) => {
  return query
    ? variable.name.toLowerCase().includes(query.toLowerCase())
    : true;
};
const METRIC_TYPES = [
  {
    label: 'Average of Date Range',
    type: AVERAGE_MAIN_METRIC,
    Icon: AverageIcon,
  },
  {
    label: 'Sum of Date Range',
    type: SUM_MAIN_METRIC,
    Icon: SumIcon,
  },
  {
    label: 'Current Period',
    type: CURRENT_MONTH_MAIN_METRIC,
    Icon: CurrentMonthIcon,
  },
  {
    label: 'Variance From Last Period as an Amount',
    type: DIFF_LAST_PERIOD,
    Icon: DifferenceAmountIcon,
  },
  {
    label: 'Previous Period',
    type: PREVIOUS_PERIOD,
    Icon: PreviousPeriodIcon,
  },
  {
    label: 'Ending Period Value',
    type: ENDING_PERIOD,
    Icon: EndingPeriodIcon,
  },
];

const NON_MONTHLY_CALCULATION = [
  {
    label: 'Period Sum',
    type: nonMonthlyValueTypes.SUM,
    Icon: SumIcon,
  },
  {
    label: 'Period Average',
    type: nonMonthlyValueTypes.AVERAGE,
    Icon: AverageIcon,
  },
  {
    label: 'Last Month',
    type: nonMonthlyValueTypes.LAST_MONTH,
    Icon: PreviousPeriodIcon,
  },
];

const SECONDARY_METRIC_META = [
  {
    name: 'secondaryMetricIncreaseColor',
    Icon: ArrowIncreaseIcon,
    defaultColor: 'var(--icon-success-inverse)',
  },
  {
    name: 'secondaryMetricDecreaseColor',
    Icon: ArrowDecreaseIcon,
    defaultColor: 'var(--icon-danger)',
  },
  {
    name: 'secondaryMetricNoChangeColor',
    Icon: NoChangeIcon,
    defaultColor: 'var(--icon-secondary)',
  },
];

const CHART_TYPES = [
  {
    chartType: chartTypes.LINE,
    label: 'Line Chart',
    Icon: LineChartIcon,
  },
  {
    chartType: chartTypes.PERCENT_AREA,
    label: 'Percentage Area Chart',
    Icon: PercentAreaIcon,
  },
  {
    chartType: chartTypes.AREA,
    label: 'Area Chart',
    Icon: StackedAreaIcon,
  },
  {
    chartType: chartTypes.COLUMN,
    label: 'Column Chart',
    Icon: ColumnChartIcon,
  },
  {
    chartType: chartTypes.PIE,
    label: 'Pie Chart',
    Icon: PieChartIcon,
  },
  {
    chartType: chartTypes.FUNNEL,
    label: 'Funnel Chart',
    Icon: FunnelChartIcon,
  },
];

const SECONDARY_METRIC_OPTIONS = [
  {
    label: 'Enable',
    value: true,
  },
  {
    label: 'Disable',
    value: false,
  },
];

const defaultSecondaryMetricColors = SECONDARY_METRIC_META.reduce(
  (colors, { name, defaultColor }) => ({ ...colors, [name]: defaultColor }),
  {},
);

const INITIAL_STATE = {
  mainMetric: AVERAGE_MAIN_METRIC,
  chartType: chartTypes.LINE,
  nonMonthlyMetric: nonMonthlyValueTypes.SUM,
  colors: {},
  customVariableNames: {},
  variables: [],
  selectedVariablesIds: {},
  title: '',
  searchText: '',
  isSecondaryMetricEnabled: true,
  chartPrecision: 0,
  error: false,
};

const CHART_PRECISION_OPTIONS = [
  {
    label: 'Round to whole number',
    value: 0,
  },
  {
    label: '2 decimals',
    value: 2,
  },
];

function getInitialState(chart) {
  if (!chart) return INITIAL_STATE;

  const variables = chart.variables.map(
    ({ variableDto, variableId, variableType }) => ({
      variableId,
      variableType,
      name: variableDto.name,
      unit: variableDto.unit,
    }),
  );

  const {
    chartType,
    colors = {},
    customVariableNames = {},
    mainMetric,
    nonMonthlyMetric = nonMonthlyValueTypes.SUM,
    isSecondaryMetricEnabled = true,
    chartPrecision,
  } = chart.metadata;

  return {
    ...INITIAL_STATE,
    chartType,
    colors,
    customVariableNames,
    mainMetric,
    nonMonthlyMetric,
    variables,
    isSecondaryMetricEnabled,
    chartPrecision,
    selectedVariablesIds: getSelectedVariableIds(variables),
    title: chart.title,
  };
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_MAIN_METRIC:
      return { ...state, mainMetric: payload, error: false };
    case SET_CHART_TYPE:
      return {
        ...state,
        chartType: payload.chartType,
        error: false,
      };
    case ADD_DATA_SOURCE: {
      const selectedVariables = [...state.variables, payload];
      const selectedVariablesIds = getSelectedVariableIds(selectedVariables);

      const customVariableNames = { ...state.customVariableNames };
      delete customVariableNames[payload.name];
      return {
        ...state,
        variables: selectedVariables,
        customVariableNames,
        selectedVariablesIds,
        error: false,
      };
    }
    case CHANGE_VARIABLE_LABEL: {
      return {
        ...state,
        customVariableNames: {
          ...state.customVariableNames,
          [payload.name]: payload.value,
        },
      };
    }
    case SET_CHART_TITLE:
      return { ...state, title: payload, error: false };
    case SET_SOURCE_COLOR:
      return {
        ...state,
        colors: {
          ...state.colors,
          [payload.name]: payload.color,
        },
      };
    case REMOVE_DATA_SOURCE: {
      const variables = [...state.variables];
      const idx = variables.findIndex(
        ({ variableId }) => variableId === payload,
      );
      const { name } = variables[idx];

      variables.splice(idx, 1);
      const selectedVariablesIds = getSelectedVariableIds(variables);

      const colors = { ...state.colors };
      delete colors[name];
      const customVariableNames = { ...state.customVariableNames };
      delete customVariableNames[name];

      return {
        ...state,
        colors,
        customVariableNames,
        variables,
        selectedVariablesIds,
        error: false,
      };
    }
    case SYNC_DATA_SOURCE: {
      const updatedVariablesList = state.variables.reduce(
        (updatedList, selectedVariable) => {
          if (selectedVariable.variableType === variableTypes.SYSTEM) {
            updatedList.push(selectedVariable);
          }
          const updatedCustomVar = payload.find((customVar) => {
            return customVar.variableId === selectedVariable.variableId;
          });
          if (updatedCustomVar) {
            updatedList.push(updatedCustomVar);
          }
          return updatedList;
        },
        [],
      );

      const selectedVariableIds = Object.keys(updatedVariablesList);
      // Remove colors for deleted variables;
      const variableColors = filterObject(state.colors, ([name]) =>
        updatedVariablesList.some((v) => v.name === name),
      );

      return {
        ...state,
        colors: {
          ...state.colors,
          ...variableColors,
        },
        variables: updatedVariablesList,
        selectedVariableIds,
        error: false,
      };
    }
    case SET_CHART_PRECISION:
      return { ...state, chartPrecision: payload };
    case SET_SEARCH_TEXT:
      return { ...state, searchText: payload };
    case SET_SECONDARY_METRIC_OPTION:
      return { ...state, isSecondaryMetricEnabled: payload };
    case SET_ERROR:
      return { ...state, error: payload };
    case SET_NON_MONTHLY_METRIC:
      return { ...state, nonMonthlyMetric: payload, error: false };
    default:
      return state;
  }
};

const ChartBuilderContent = ({ chart, onClose, isExpanded, onExpandClick }) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const [scenario] = useSelectedScenarios();
  const [scenarioId] = useSelectedScenarioIds();
  const {
    autocompleteOptions: variables,
    customVariables,
    customVariableSections,
    systemVariables,
    systemVariablesSections,
  } = useSelector((state) => state.variables);
  const { startDate, endDate } = useSelector(({ shared }) => shared);
  const dashboardLayoutId = useSelector(
    ({ dashboard }) => dashboard.selectedDashboardId,
  );
  const isOneColorEnabled = useOneColor();
  const queryClient = useQueryClient();
  const [state, setState] = useReducer(reducer, chart, getInitialState);
  const [isLoading, setLoading] = useState(false);
  const [showMultiUnitsWarning, setShowMultiUnitsWarning] = useState({
    show: false,
    closedByUser: false,
  });
  const [query, setQuery] = useState('');

  const handleSearch = useMemo(
    () =>
      debounce((searchText) => {
        setQuery(searchText);
      }, 500),
    [],
  );

  useEffect(() => {
    handleSearch(state.searchText);
  }, [handleSearch, state.searchText]);

  useEffect(() => {
    if (showMultiUnitsWarning.closedByUser) return;
    const allUnits = state.variables.map((v) => v.unit);
    setShowMultiUnitsWarning((prevState) => ({
      ...prevState,
      show: new Set(allUnits).size > 1,
    }));
  }, [showMultiUnitsWarning.closedByUser, state.variables]);

  useEffect(() => {
    dispatch(getCustomVariableSectionsAction(scenarioId));
    dispatch(getCustomVariablesAction(scenarioId, startDate, endDate));
  }, [dispatch, scenarioId, startDate, endDate, variables]);

  useEffectOnUpdate(() => {
    if (state.variables.length) {
      setState({
        type: SYNC_DATA_SOURCE,
        payload: customVariables.map(({ variable }) => {
          const { id: variableId, name, unit } = variable;
          return {
            variableId,
            variableType: variableTypes.CUSTOM,
            name,
            unit,
          };
        }),
      });
    }
  }, [customVariables, state.variables.length]);

  useEffect(() => {
    dispatch(getSystemVariablesAction(startDate, endDate, scenarioId));
  }, [dispatch, scenarioId, startDate, endDate, variables]);

  const customVariableGroups = useMemo(() => {
    const customVars = customVariables
      .map(({ variable }) => variable)
      .filter((variable) => filterVariableByQuery(variable, query));

    return customVariableSections
      .map(({ id, name }) => {
        const variablesBySection = customVars.filter(
          ({ sectionId }) => id === sectionId,
        );
        return {
          sectionName: name,
          variables: variablesBySection,
        };
      })
      .filter(
        ({ variables: variablesBySection }) => variablesBySection.length > 0,
      );
  }, [customVariableSections, customVariables, query]);

  const systemVariableGroups = useMemo(() => {
    const systemVars = systemVariables
      .map(({ variable, categoryName }) => ({
        variable,
        sectionName: categoryName,
      }))
      .filter(({ variable }) => filterVariableByQuery(variable, query));
    return systemVariablesSections
      .map((groupName) => ({
        sectionName: groupName,
        variables: systemVars
          .filter(({ sectionName }) => groupName === sectionName)
          .map(({ variable }) => variable),
      }))
      .filter(
        ({ variables: variablesBySection }) => variablesBySection.length > 0,
      );
  }, [systemVariablesSections, systemVariables, query]);

  const handleSave = useCallback(async () => {
    setLoading(true);

    const payload = {
      metadata: {
        chartType: state.chartType,
        colors: {
          ...defaultSecondaryMetricColors,
          ...state.colors,
        },
        customVariableNames: trimCustomVariableNames(state.customVariableNames),
        mainMetric: state.mainMetric,
        nonMonthlyMetric: state.nonMonthlyMetric,
        chartPrecision: state.chartPrecision ?? 0,
        isSecondaryMetricEnabled: state.isSecondaryMetricEnabled,
      },
      title: state.title,
      variables: state.variables,
    };

    try {
      if (chart) {
        await updateChartMultiVariable(scenarioId, [
          {
            id: chart.id,
            ...payload,
          },
        ]);
      } else {
        await createChartMultiVariable(scenarioId, dashboardLayoutId, {
          id: uuidv4(),
          ...payload,
        });
      }
      queryClient.invalidateQueries(CUSTOM_CHART_INFO, scenarioId);
      dispatch(getDashboardsAction(scenarioId));
      onClose();
    } catch (error) {
      setState({ type: SET_ERROR, payload: true });
    } finally {
      setLoading(false);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [chart, onClose, scenarioId, state, queryClient]);

  const handleSelectedDataSource = useCallback((selectedOption, checked) => {
    const variableType = selectedOption.name.toLowerCase().startsWith('custom.')
      ? variableTypes.CUSTOM
      : variableTypes.SYSTEM;

    if (checked) {
      setState({
        type: ADD_DATA_SOURCE,
        payload: {
          name: selectedOption.name,
          variableId: selectedOption.id,
          variableType,
          unit: selectedOption.unit,
        },
      });
    } else {
      setState({
        type: REMOVE_DATA_SOURCE,
        payload: selectedOption.id,
      });
    }
  }, []);

  const defaultColors = getBandedGradient(
    scenario.color,
    state.variables.length,
  ).reverse();

  const onColorSelect = (color, name) =>
    setState({
      type: SET_SOURCE_COLOR,
      payload: { color, name },
    });

  const handleSearchChart = (value) =>
    setState({
      type: SET_SEARCH_TEXT,
      payload: value.trim(),
    });

  const handleSecondaryMetricOption = ({ target }) => {
    setState({
      type: SET_SECONDARY_METRIC_OPTION,
      payload: target.value === 'true',
    });
  };
  const handleOnChangeChartPrecision = ({ target }) => {
    const intVal = Number(target.value);
    setState({
      type: SET_CHART_PRECISION,
      payload: intVal,
    });
  };
  return (
    <div className="ChartBuilder_Container">
      <div className="ChartBuilder_Panel">
        <header className="Sidebar_Header">
          <h3 className="Sidebar_Title">
            {chart ? 'Edit Chart' : 'Create New Chart'}
          </h3>
          <button
            className="Sidebar_CloseBtn"
            onClick={onClose}
            aria-label="Close"
          >
            <CrossIcon className="CloseIcon" />
          </button>
        </header>
        {state.error && (
          <div className="ChartBuilder_Error">
            <header>
              <button
                className="Sidebar_CloseBtn Sidebar_CloseBtn-error"
                onClick={() =>
                  setState({
                    type: SET_ERROR,
                    payload: false,
                  })
                }
                aria-label="Close"
              >
                <CrossIcon className="CloseIcon" />
              </button>
            </header>
            An error occurred while attempting to create a chart for the
            selected data sources. Please try again.
          </div>
        )}
        <div className="ChartBuilder_Form">
          <div className="Form_Group">
            <FormLabel htmlFor="custom-chart-name" text="Chart Name" />
            <FormField
              id="custom-chart-name"
              name="custom-chart-name"
              data-testid="custom-chart-name"
              value={state.title}
              onChange={({ target }) =>
                setState({
                  type: SET_CHART_TITLE,
                  payload: target.value,
                })
              }
              validate={() => !state.title && 'Required'}
              maxLength={256}
            />
          </div>
        </div>
        <div className="ChartBuilder_Form ChartBuilder_Form-scrollable">
          <div className="Form_Group">
            <h4 className="Label">Primary Metric</h4>
            <div className="ChartBuilder_IconsContainer">
              {METRIC_TYPES.map(({ Icon, label, type }) => (
                <div key={type} className="ChartBuilder_IconButton">
                  <IconButton
                    active={state.mainMetric === type}
                    data-testid={`${type}-metric-selection`}
                    onClick={() =>
                      setState({
                        type: SET_MAIN_METRIC,
                        payload: type,
                      })
                    }
                    label={label}
                    Icon={Icon}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="Form_Group">
            <div className="ChartBuilder_HeadingRow">
              <h4 className="Label">Secondary Metric</h4>
              <InfoTooltip data-testid="non-monthly-calculation-tooltip">
                Choose the colors which best reflect the trend of your secondary
                metric. Any changes you make will affect this chart only.
              </InfoTooltip>
            </div>
            <div className="ChartBuilder_IconsContainer">
              {SECONDARY_METRIC_OPTIONS.map(({ label, value }) => {
                return (
                  <FormRadio
                    key={label}
                    label={label}
                    id={`secondaryMetricSelector-${label}`}
                    value={String(value)}
                    name="secondaryMetricSelector-control"
                    checked={value === state.isSecondaryMetricEnabled}
                    onChange={handleSecondaryMetricOption}
                    labelClass="ChartBuilder_SecondaryMetricOptions_Label"
                  />
                );
              })}
            </div>
            <div className="ChartBuilder_IconsContainer">
              {SECONDARY_METRIC_META.map(({ Icon, name, defaultColor }) => (
                <SecondaryMetricColorSelector
                  key={name}
                  data-testid={name}
                  color={state.colors[name] || defaultColor}
                  Icon={Icon}
                  disabled={state.isSecondaryMetricEnabled === false}
                  onColorSelect={(color) => onColorSelect(color, name)}
                />
              ))}
            </div>
          </div>
          <div className="Form_Group">
            <div className="ChartBuilder_HeadingRow">
              <h4 className="Label">Decimals</h4>
              <InfoTooltip data-testid="non-monthly-calculation-tooltip">
                By default figures are rounded to the nearest whole number;
                however, you can choose to display two decimal places
              </InfoTooltip>
            </div>
            <div className="ChartBuilder_IconsContainer">
              {CHART_PRECISION_OPTIONS.map((option) => {
                const stringValue = String(option.value);
                return (
                  <FormRadio
                    key={stringValue}
                    label={option.label}
                    id={`chartPrecisionSelector-${stringValue}`}
                    value={stringValue}
                    name="chartPrecisionSelector-control"
                    checked={option.value === state.chartPrecision}
                    onChange={handleOnChangeChartPrecision}
                    labelClass="ChartBuilder_ChartPrecisions_Label"
                  />
                );
              })}
            </div>
          </div>
          <div className="Form_Group">
            <div className="ChartBuilder_HeadingRow">
              <h4 className="Label">Non-monthly Calculation</h4>
              <InfoTooltip data-testid="non-monthly-calculation-tooltip">
                Select how you would like to calculate values when viewing the
                chart in a non-monthly view (for example, quarterly or
                annually).
              </InfoTooltip>
            </div>
            <div className="ChartBuilder_IconsContainer">
              {NON_MONTHLY_CALCULATION.map(({ Icon, label, type }) => (
                <div key={type} className="ChartBuilder_IconButton">
                  <IconButton
                    active={state.nonMonthlyMetric === type}
                    data-testid={`${type}-non-monthly-calculation`}
                    onClick={() =>
                      setState({
                        type: SET_NON_MONTHLY_METRIC,
                        payload: type,
                      })
                    }
                    label={label}
                    Icon={Icon}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="Form_Group">
            <h4 className="Label">Chart Type</h4>
            <div className="ChartBuilder_IconsContainer">
              {CHART_TYPES.map(({ chartType, Icon, label }) => (
                <div key={chartType} className="ChartBuilder_IconButton">
                  <IconButton
                    active={state.chartType === chartType}
                    data-testid={`${chartType}-chart-type`}
                    onClick={() =>
                      setState({
                        type: SET_CHART_TYPE,
                        payload: {
                          chartType,
                        },
                      })
                    }
                    label={label}
                    Icon={Icon}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="Form_Group">
            <header className="SelectedVariables_Header">
              Variables
              <Button
                onClick={() => onExpandClick((prevState) => !prevState)}
                className="Button-primaryLink"
                data-testid="expand-sidebar-button"
              >
                ({isExpanded ? 'Close' : 'Open'} List)
              </Button>
            </header>
            {state.variables.length === 0 && (
              <div className="Form_Group SelectedVariables_EmptyText">
                Open list to add variables
              </div>
            )}
          </div>
          {state.variables.length > 0 && (
            <div className="Form_Group SelectedVariables">
              {state.variables.map(({ name, variableId }, idx) => (
                <DataSourceSelected
                  key={variableId}
                  color={state.colors[name] ?? defaultColors[idx]}
                  data-testid={variableId}
                  label={state.customVariableNames[name] ?? name}
                  onColorSelect={(color) => onColorSelect(color, name)}
                  onRemove={() =>
                    setState({
                      type: REMOVE_DATA_SOURCE,
                      payload: variableId,
                    })
                  }
                  onLabelChange={(props) => {
                    const value = props?.target.value ?? '';
                    setState({
                      type: CHANGE_VARIABLE_LABEL,
                      payload: { name, value },
                    });
                  }}
                  onEditCancel={(initialLabel) =>
                    setState({
                      type: CHANGE_VARIABLE_LABEL,
                      payload: { name, value: initialLabel },
                    })
                  }
                />
              ))}
            </div>
          )}
        </div>
        <div className="Form_Group ChartBuilder_SaveButton">
          <Button
            className="Button-primary"
            data-testid="add-custom-chart"
            loading={isLoading}
            onClick={handleSave}
            disabled={
              state.variables.length < 1 ||
              !state.title ||
              Object.values(
                trimCustomVariableNames(state.customVariableNames),
              ).includes('')
            }
          >
            Save
          </Button>
        </div>
      </div>
      {isExpanded && (
        <>
          <div className="ChartBuilder_Panel ChartBuilder_Panel-secondary">
            <header className="Sidebar_Header">
              <h3 className="Sidebar_Title">Variables</h3>
            </header>
            {!showMultiUnitsWarning.closedByUser &&
              showMultiUnitsWarning.show && (
                <NotificationBanner
                  type={notificationTypes.WARN}
                  onCloseClick={() =>
                    setShowMultiUnitsWarning(() => ({
                      show: false,
                      closedByUser: true,
                    }))
                  }
                >
                  Choosing different unit types in a single chart may not result
                  in the visualization you’re looking for.
                </NotificationBanner>
              )}
            {isOneColorEnabled && (
              <span className="ChartBuilder_Label">Search for a variable</span>
            )}
            <SearchField
              id="variableSearchBox"
              name="variableSearchBox"
              value={state.searchText}
              onChange={handleSearchChart}
              hasPrefix={!isOneColorEnabled}
              className="ChartBuilder_SearchBox"
            />
            <div className="ChartBuilder_VariablePanel">
              <CollapsibleVariableGroup
                label="Custom Variables"
                variableGroup={customVariableGroups}
                selectedVariables={state.selectedVariablesIds}
                onSelectVariable={handleSelectedDataSource}
                searchQuery={query}
              />
              <CollapsibleVariableGroup
                label="System Variables"
                variableGroup={systemVariableGroups}
                selectedVariables={state.selectedVariablesIds}
                onSelectVariable={handleSelectedDataSource}
                searchQuery={query}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const ChartBuilder = ({ chart, open, onClose }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (open) setIsExpanded(true);
  }, [open]);

  return (
    <Sidebar
      open={open}
      onClose={onClose}
      className={classNames(
        'ChartBuilderSidebar',
        isExpanded && 'ChartBuilderSidebar-expanded',
      )}
    >
      {open && (
        <ChartBuilderContent
          chart={chart}
          open={open}
          onClose={onClose}
          isExpanded={isExpanded}
          onExpandClick={setIsExpanded}
        />
      )}
    </Sidebar>
  );
};

export default ChartBuilder;
