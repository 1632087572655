import DeleteIcon from '@bill/cashflow.assets/delete';
import RightArrowIcon from '@bill/cashflow.assets/right-arrow';
import SearchIcon from '@bill/cashflow.assets/search';
import Button from '@/components/common/Button';
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';
import FormRadio from '@/components/common/FormRadio';
import InfoMessages from '@/components/common/InfoMessage';
import Select from '@/components/common/Select';
import './ExpenseLink.scss';

const MAX_CRITERIA = 2;

const CriteriaForm = ({ criterion, onUpdate, criteriaNumber }) => {
  return (
    <>
      {criteriaNumber > 0 && (
        <div className="ExpenseLink_Operator">
          <hr className="ExpenseLink_OperatorLine" />
          <span className="ExpenseLink_OperatorTxt">AND</span>
        </div>
      )}
      <div className="ExpenseLink_FormSelect">
        <Select
          id="expense-link-type"
          value={criterion.type}
          onChange={({ target }) =>
            onUpdate({
              type: 'SET_EXPENSE_LINK',
              payload: { value: { type: target.value }, criteriaNumber },
            })
          }
        >
          <option value="Vendor">Vendor</option>
          <option value="Item">Item</option>
          <option value="Account">Account</option>
          <option value="TrackingCategories">Tracking Categories</option>
        </Select>
        <RightArrowIcon className="ExpenseLink_Arrow" />
        <Select
          id="expense-link-operator"
          value={criterion.operator}
          onChange={({ target }) =>
            onUpdate({
              type: 'SET_EXPENSE_LINK',
              payload: { value: { operator: target.value }, criteriaNumber },
            })
          }
        >
          <option value="Contains">Contains</option>
          <option value="Equals">Equals</option>
        </Select>
      </div>
      <div className="ExpenseLink_FormSearch">
        <FormField
          id="expenses-link-value"
          className="ExpenseLink_Value"
          prefix={<SearchIcon className="FormField_PrefixIcon" />}
          placeholder="Search for entries"
          value={criterion.value}
          validate={(value) => !value.trim() && 'Please enter a search term'}
          onChange={({ target }) =>
            onUpdate({
              type: 'SET_EXPENSE_LINK',
              payload: { value: { value: target.value }, criteriaNumber },
            })
          }
        />
        {criteriaNumber !== 0 && (
          <button
            type="button"
            className="ExpenseLink_DeleteBtn"
            onClick={() =>
              onUpdate({ type: 'REMOVE_EXPENSE_LINK', payload: criteriaNumber })
            }
          >
            <DeleteIcon
              className="ExpenseLink_Delete"
              aria-label="Delete Linked Expense"
            />
          </button>
        )}
      </div>
    </>
  );
};

function ExpenseLink({ criteria, onUpdate, hasWritePermission }) {
  const isEnabled = Boolean(criteria);

  return (
    <>
      <div className="Form_Group">
        <p className="Form_Desc">
          Would you like to link this expense to entries
          <br />
          within your accounting platform?
        </p>
        <FormRadio
          id="expense-link-yes"
          name="expense-link"
          label="Yes"
          checked={isEnabled}
          value="false"
          onChange={() => onUpdate({ type: 'ADD_EXPENSE_LINK' })}
        />
        <FormRadio
          id="expense-link-no"
          name="expense-link"
          label="No"
          checked={!isEnabled}
          value="true"
          onChange={() => onUpdate({ type: 'RESET_EXPENSE_LINK' })}
        />
      </div>
      {criteria && (
        <>
          <div className="Form_Group">
            <InfoMessages
              message="Linking this expense to entries within your accounting platform may change historical values"
              type="Warning"
            />
          </div>
          <div className="Form Form_Group">
            {criteria.map((criterion, idx) => (
              // eslint-disable-next-line react/no-array-index-key -- predates description requirement
              <div className="Form_Group ExpenseLink" key={idx}>
                <FormLabel htmlFor="expense-link-type" text="Link Criteria" />
                <CriteriaForm
                  criterion={criterion}
                  onUpdate={onUpdate}
                  criteriaNumber={idx}
                />
              </div>
            ))}
            {hasWritePermission && criteria.length <= MAX_CRITERIA && (
              <div className="Form_Group">
                <Button
                  className="Button Button-primaryLink"
                  data-testid="add-new-criteria"
                  onClick={() => onUpdate({ type: 'ADD_EXPENSE_LINK' })}
                >
                  + Add additional criteria
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ExpenseLink;
