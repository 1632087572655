import { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { DISMISS_UNAUTHORIZED } from '@/actionTypes/auth';
import {
  subscribeToUserPermissionChangeAction,
  setUnauthorizedByTMXAction,
} from '@/actions/auth';
import {
  DASHBOARD_PATH,
  LOGOUT_PATH,
  MANAGEMENT_CONSOLE_PATH,
  SWITCH_COMPANIES_PATH,
} from '@/constants/pages';
import useAuthorizedCompanies from '@/hooks/useAuthorizedCompanies';
import useAuthorizedScenarios from '@/hooks/useAuthorizedScenarios';
import useManagementConsole from '@/hooks/useManagementConsole';
import useWsSubscription from '@/hooks/useWsSubscription';
import { ReactComponent as FinmarkLogo } from '@/assets/images/bill_finmark_logo.svg';
import { ReactComponent as PadlockImage } from '@/assets/images/padlock.svg';
import './Unauthorized.scss';

/** @typedef {{ selectedCompanyId: number; defaultScenarioId: number }} DismissUnauthorizedPayload */

const Unauthorized = () => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const history = useHistory();
  const { companies } = useSelector((state) => state.companies);
  const { userId } = useSelector(({ auth }) => auth.userInfo);
  const { isAuthenticated, isUnauthorizedByTMX } = useSelector(
    (state) => state.auth,
  );
  const authorizedCompanies = useAuthorizedCompanies();
  const authorizedScenarios = useAuthorizedScenarios();
  const [redirectPath, setRedirectPath] = useState();
  const isConsoleEnabled = useManagementConsole();

  useEffect(() => {
    document.title = 'Unauthorized';
  }, []);

  useEffect(() => {
    history.replace(redirectPath);
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [redirectPath]);

  useWsSubscription(
    () => dispatch(subscribeToUserPermissionChangeAction(userId)),
    [userId],
  );

  const hasAuthorized =
    authorizedScenarios.length > 0 && authorizedCompanies.length > 0;

  const selectedCompanyId =
    authorizedCompanies.length === 1 ? authorizedCompanies[0] : null;

  const getDefaultScenarioId = useCallback(() => {
    if (selectedCompanyId === null) {
      return null;
    }
    const selectedCompany = companies.find(
      ({ id }) => id === selectedCompanyId,
    );
    return selectedCompany?.defaultScenarioId;
  }, [selectedCompanyId, companies]);

  const handleClick = useCallback(() => {
    if (!hasAuthorized) {
      history.replace(LOGOUT_PATH);
    } else {
      const defaultScenarioId = getDefaultScenarioId();
      /** @type {DismissUnauthorizedPayload} */
      const payload = { defaultScenarioId, selectedCompanyId };
      dispatch({
        type: DISMISS_UNAUTHORIZED,
        payload,
      });

      let path = SWITCH_COMPANIES_PATH;
      if (authorizedCompanies.length === 1) {
        path = DASHBOARD_PATH;
      } else if (isConsoleEnabled) {
        path = MANAGEMENT_CONSOLE_PATH;
      }
      setRedirectPath(path);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [
    dispatch,
    getDefaultScenarioId,
    authorizedCompanies,
    selectedCompanyId,
    hasAuthorized,
  ]);

  if (!isAuthenticated && !isUnauthorizedByTMX) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <div className="Unauthorized">
      <div className="Unauthorized_Container">
        <FinmarkLogo className="Unauthorized_Logo" />
        <PadlockImage className="Unauthorized_Padlock" />
        <h1 className="Unauthorized_Heading">
          {isUnauthorizedByTMX ? 'Service Unavailable' : 'No Access!'}{' '}
        </h1>
        <p className="Unauthorized_Text">
          {isUnauthorizedByTMX
            ? 'Sorry, Finmark is not available in your country or region.'
            : "Sorry, but you don't have access to the company and/or scenario. Please request access in order to view the page."}
        </p>
        {!isUnauthorizedByTMX ? (
          <button className="Unauthorized_Button" onClick={handleClick}>
            Dismiss
          </button>
        ) : (
          <button
            className="Unauthorized_Button"
            onClick={() => {
              dispatch(setUnauthorizedByTMXAction(false));
            }}
          >
            Go Back
          </button>
        )}
      </div>
    </div>
  );
};

export default Unauthorized;
