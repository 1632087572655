// @ts-check

/**
 * @typedef {(
 *   | {
 *       date: string;
 *       month?: never;
 *     }
 *   | {
 *       date?: never;
 *       month: string;
 *     }
 * ) & { value: number | import('@/hooks/useReportData').MonthValue[] }} MonthlyData
 */

/**
 * @typedef {{
 *   name?: string;
 *   months?: MonthlyData[];
 * }} MonthlySpreadsheetData
 */

/**
 * Checks if the monthly values are zero or not
 *
 * @type {(
 *   params:
 *     | MonthlySpreadsheetData
 *     | import('@/types/services/backend').CashInOutGrid,
 * ) => boolean}
 */
// eslint-disable-next-line import/prefer-default-export -- predates description requirement
export const zeroFilter = ({ months }) => {
  return !!months?.some(({ value }) =>
    Array.isArray(value) ? value.some((scenario) => scenario.value) : !!value,
  );
};
