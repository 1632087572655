import type { AppDispatch } from '@/store';
// eslint-disable-next-line no-restricted-imports -- This is the way
import { useDispatch } from 'react-redux';

/**
 * A custom hook to dispach actions on the Redux store with proper types.
 */
const useTypedDispatch: () => AppDispatch = useDispatch;

export default useTypedDispatch;
