// @ts-check
import { useEffect, useMemo } from 'react';
import CashFlowFooter from '@/components/Remote/CashFlowFooter/CashFlowFooter';
import SideNav from '@/components/Remote/SideNav/SideNav';
import { handleOnboardingNavigation } from '@/components/Remote/SideNav/helpers';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';
import { PAGE_ERROR_BOUNDARY_TEXT } from '@/constants/pages';
import GlobalTopNavProvider from '@/contexts/GlobalTopNavProvider';
import useBillSession from '@/hooks/useBillSession';
import {
  REACT_APP_ENVIRONMENT,
  GLANCE_ADDITIONAL_GROUP_IDS,
  GLANCE_GROUP_ID,
  GLANCE_SRC,
  GLANCE_SITE,
} from '@/runtimeConfig';
import { initBillAnalytics } from '@/services/analyticsService';
import GlobalTopNavMarginWrapper from './GlobalTopNavMarginWrapper';
import TheLayout from './TheLayout';

/** @type {(props: { children: React.ReactNode }) => React.ReactElement} */
export default function BillOnboardingLayout({ children }) {
  const glanceEnv = useMemo(() => {
    return {
      isLocal: REACT_APP_ENVIRONMENT === 'dev',
      glaceJsSrc: GLANCE_SRC,
      glanceJsGroupId: GLANCE_GROUP_ID,
      glanceJsAdditionalGroupIds: GLANCE_ADDITIONAL_GROUP_IDS,
      glanceJsSite: GLANCE_SITE,
    };
  }, []);

  const { isSessionLoading, sessionInfo } = useBillSession();
  useEffect(() => {
    if (!isSessionLoading) {
      initBillAnalytics(sessionInfo);
    }
  }, [sessionInfo, isSessionLoading]);

  return (
    <GlobalTopNavProvider>
      <GlobalTopNavMarginWrapper>
        <SideNav isOnboardingLayout onNavigation={handleOnboardingNavigation} />
        <TheLayout>
          <CommonErrorBoundary text={PAGE_ERROR_BOUNDARY_TEXT}>
            <div className="PageLayoutContainer">{children}</div>
          </CommonErrorBoundary>
          <CashFlowFooter glanceEnv={glanceEnv} />
        </TheLayout>
      </GlobalTopNavMarginWrapper>
    </GlobalTopNavProvider>
  );
}
