// @ts-check
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import Modal from './Modal';
import './ModalBase.scss';

const ModalHeader = ({ children }) => {
  return <header className="ModalBase_Header">{children}</header>;
};

const ModalBody = ({ children }) => {
  return <div className="ModalBase_Content">{children}</div>;
};

const ModalFooter = ({ children }) => {
  return <footer className="ModalBase_Footer">{children}</footer>;
};

/**
 * @typedef {{
 *   'id': string;
 *   'onCancel'?: () => void;
 *   'header'?: React.ReactNode;
 *   'footer'?: React.ReactNode;
 *   'children': React.ReactNode;
 *   'show'?: boolean;
 *   'className'?: string;
 *   'data-testid'?: string;
 * }} ModalBaseProps
 */

/**
 * An abstraction around react-bootstrap's modal
 *
 * @deprecated Use Modal instead
 * @example
 *   <ModalBase
 *     id={id}
 *     className="MyModal"
 *     show={show}
 *     onCancel={onCancel}
 *     header={header}
 *     footer={
 *       <>
 *         <Button
 *           className="ActionButton"
 *           onClick={onAction}
 *           data-testid="modal-action-button"
 *         >
 *           {actionBtnLoading ? <LoadingSpinner /> : actionBtnTxt}
 *         </Button>
 *         <Button
 *           className="Button ModalConfirmation_Button Button-cancelLink"
 *           onClick={onCancel}
 *           data-testid="modal-cancel-button"
 *         >
 *           {cancelBtnTxt}
 *         </Button>
 *       </>
 *     }
 *   >
 *     {children}
 *   </ModalBase>;
 *
 * @type {import('react').ForwardRefExoticComponent<ModalBaseProps>}
 */
const ModalBase = forwardRef(
  (
    {
      id,
      onCancel,
      show = true,
      header,
      footer,
      children,
      className,
      'data-testid': dataTestId,
    },
    ref,
  ) => {
    return (
      <Modal
        open={show}
        onClose={onCancel}
        className={classNames('ModalBase', className)}
        data-testid={dataTestId ?? id}
        ref={ref}
      >
        <div className="ModalBase_Wrapper">
          {header && <ModalHeader>{header}</ModalHeader>}
          {children && <ModalBody>{children}</ModalBody>}
          {footer && <ModalFooter>{footer}</ModalFooter>}
        </div>
      </Modal>
    );
  },
);

ModalBase.propTypes = {
  /** The id for the modal */
  'id': PropTypes.string.isRequired,
  /** Callback function called when the modal is closed */
  'onCancel': PropTypes.func,
  /** The content displayed in the "header" slot */
  'header': PropTypes.node,
  /** The content inside the modal */
  'children': PropTypes.node.isRequired,
  /** The content displayed in the "footer" slot */
  'footer': PropTypes.node,
  /** The className to apply to the component */
  'className': PropTypes.string,
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string,
  /** Whether or not the modal is displayed */
  'show': PropTypes.bool,
};

export default ModalBase;
