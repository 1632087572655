import CurrencyField from '@/components/common/CurrencyField';
import FormLabel from '@/components/common/FormLabel';
import MonthField from '@/components/common/MonthField';
import { financingActionTypes } from '@/constants/revenue';
import {
  getDateOffsetByYears,
  getFormattedDateFromTimeStamp,
  getISODate,
  getUTCDayTimestamp,
} from '@/helpers/dateFormatter';

const EquityForm = ({ formState, dispatchFn }) => {
  return (
    <>
      <div className="Form_Group Form_Group-halfWidth">
        <FormLabel htmlFor="amount" text="Amount" />
        <CurrencyField
          id="amount"
          name="amount"
          allowNegativeValues={false}
          value={formState.amountFormula}
          onChange={({ target: { value } }) => {
            dispatchFn({
              type: financingActionTypes.SET_AMOUNT,
              payload: value,
            });
          }}
          validate={() =>
            !Number.isFinite(Number(formState.amountFormula)) &&
            'Field is required!'
          }
          min="0"
          maxLength="15"
          data-testid="add-revenue-financing-amount"
        />
      </div>
      <div className="Form_Group Form_Group-halfWidth">
        <FormLabel htmlFor="equity-date" text="Date" />
        <MonthField
          id="equity-date"
          value={new Date(formState.startDate).getTime()}
          max={getUTCDayTimestamp(
            getDateOffsetByYears(getISODate(new Date()), 3),
          )}
          onChange={(value) => {
            dispatchFn({
              type: financingActionTypes.SET_START_DATE,
              payload: getFormattedDateFromTimeStamp(value),
            });
          }}
          validate={(dateStr) => !dateStr && 'Please enter a valid date'}
        />
      </div>
    </>
  );
};

export default EquityForm;
