import CurrencyBoxIcon from '@bill/cashflow.assets/currency-box-icon';
import NumberBoxIcon from '@bill/cashflow.assets/number-box-icon';
import PercentageBoxIcon from '@bill/cashflow.assets/percentage-box';
import Accordion from '@/components/common/Accordion';
import Checkbox from '@/components/common/Checkbox';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import { units } from '@/constants/variables';

const VariableIcon = ({ unit }) => {
  switch (unit) {
    case units.CURRENCY:
      return <CurrencyBoxIcon className="ChartBuilder_VariableIcon" />;
    case units.NUMBER:
      return <NumberBoxIcon className="ChartBuilder_VariableIcon" />;
    case units.PERCENTAGE:
      return <PercentageBoxIcon className="ChartBuilder_VariableIcon" />;
    default:
      throw new Error('Invalid unit provided');
  }
};

const VariableList = ({ variables, selectedVariables, onSelectVariable }) => {
  return (
    <div className="ChartBuilder_VariableList">
      {variables.map((option) => {
        const { id, name, unit } = option;
        return (
          <div className="ChartBuilder_Checkbox_Row" key={id}>
            <Checkbox
              id={id}
              key={id}
              className="ChartBuilder_Checkbox"
              checked={selectedVariables[id]}
              onChange={({ target: { checked } }) =>
                onSelectVariable(option, checked)
              }
            />
            <VariableIcon unit={unit} />
            <TooltipTextOverflow
              label={name}
              placement="top"
              className="ChartBuilder_Text"
              data-testid={name}
            />
          </div>
        );
      })}
    </div>
  );
};

const CollapsibleVariableGroup = ({
  label,
  variableGroup,
  selectedVariables,
  onSelectVariable,
  searchQuery,
}) => {
  return (
    <Accordion
      key={`${searchQuery}-${label}`}
      defaultExpanded={!!variableGroup.length}
      label={label}
    >
      {variableGroup.map(({ sectionName, variables }) =>
        variableGroup.length > 1 ? (
          <Accordion
            key={`${searchQuery}-${sectionName}-${label}`}
            defaultExpanded={!!searchQuery}
            label={sectionName}
          >
            <VariableList
              variables={variables}
              selectedVariables={selectedVariables}
              onSelectVariable={onSelectVariable}
            />
          </Accordion>
        ) : (
          <VariableList
            variables={variables}
            selectedVariables={selectedVariables}
            onSelectVariable={onSelectVariable}
            key={sectionName}
          />
        ),
      )}
    </Accordion>
  );
};

export default CollapsibleVariableGroup;
