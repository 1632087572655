import { useEffect } from 'react';
import { actions, subjects } from '@/constants/permissions';
import usePermissions from '@/hooks/usePermissions';

const { WRITE } = actions;
const { NON_DASHBOARD } = subjects;

/**
 * A hook to disable form field elements for view-only users
 *
 * @param {Object} ref
 * @param {Object} options
 * @param {import('@/constants/permissions').PermissionAction} [options.action]
 *   - Permission to check against subject
 *
 * @param {import('@/constants/permissions').PermissionSubject} [options.subject]
 *   - Permission subject to be checked
 *
 * @param {boolean} [options.isScenarioRequired]
 * @param {boolean} [options.shouldDisableButtons]
 * @param {boolean} [options.forceRerun]
 * @returns {boolean}
 */
const useViewOnlyMode = (
  ref,
  {
    action = WRITE,
    subject = NON_DASHBOARD,
    isScenarioRequired = true,
    shouldDisableButtons = false,
    forceRerun = false,
  } = {},
) => {
  const hasWritePermission = usePermissions(
    action,
    subject,
    isScenarioRequired,
  );
  const observerOptions = { attributes: true, childList: true, subtree: true };
  const mutationObserverCallback = () =>
    ref.current
      ?.querySelectorAll(
        `input,select,textarea,${shouldDisableButtons && 'button'}`,
      )
      .forEach((input) => {
        if (
          !input.classList.contains('disabledStatusUpdated') &&
          !input.disabled
        ) {
          // eslint-disable-next-line no-param-reassign -- predates description requirement
          input.disabled = true;
          input.parentNode.setAttribute('aria-disabled', 'true');
          input.classList.add('disabledStatusUpdated');
        }
      });

  useEffect(() => {
    if (!ref.current || hasWritePermission) return undefined;

    const observer = new MutationObserver(mutationObserverCallback);
    observer.observe(ref.current, observerOptions);

    return () => {
      observer.disconnect();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [hasWritePermission, forceRerun]);

  return !hasWritePermission;
};

export default useViewOnlyMode;
