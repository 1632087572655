import SearchIcon from '@bill/cashflow.assets/search';
import PropTypes from 'prop-types';
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';
import { classNames } from '@/helpers';

/**
 * Creates a search field input, with optional form label.
 *
 * @example
 *   <SearchField
 *     id="foo"
 *     name="foo"
 *     value={bar}
 *     onChange={(event) => setBar(event.target.value)}
 *     label="Foo"
 *   />;
 */

const SearchField = ({
  id,
  name,
  value,
  onChange,
  label,
  className,
  placeholder = 'Search',
  hasPrefix = true,
}) => {
  return (
    <div className={classNames('SearchForm', className)}>
      {label && <FormLabel htmlFor={id} text={label} />}
      <FormField
        id={id}
        name={name}
        placeholder={placeholder}
        prefix={hasPrefix && <SearchIcon className="FormField_PrefixIcon" />}
        value={value}
        onChange={({ target }) => onChange(target.value)}
      />
    </div>
  );
};

SearchField.propTypes = {
  /** Additional class(es) to apply to the search field wrapper */
  className: PropTypes.string,
  /** Text content for the field. e.g 'Search for Charts' */
  label: PropTypes.string,
  /** The ID of the input element, corresponding to the label's htmlFor attribute */
  id: PropTypes.string.isRequired,
  /** A name for search field and it is mandatory */
  name: PropTypes.string.isRequired,
  /** The value of the input */
  value: PropTypes.oneOfType([PropTypes.string]),
  /**
   * Event handler for when the input has been changed
   *
   * @param {Object} event
   */
  onChange: PropTypes.func,
  /**
   * Placeholder text for the search field, defaults to 'Search' if none is
   * passed in
   */
  placeholder: PropTypes.string,
  /** Set to true to display the prefix icon (default), or false to hide it. */
  hasPrefix: PropTypes.bool,
};

export default SearchField;
