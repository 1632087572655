import './InfoMessage.scss';

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
const InfoMessages = ({ message, type = 'Info', ...props }) => {
  return (
    <div className={`InfoMessage ${type}`} {...props}>
      <span>{message}</span>
    </div>
  );
};

export default InfoMessages;
