// @ts-check
import { useMemo } from 'react';
import { actualsFamily } from '@/constants/actuals';
import {
  CASH_IN_TYPE,
  CASH_OUT_TYPE,
  FORECAST_METHODS,
  TOTAL_ROWS,
  SPREADSHEET_ID,
  DEFAULT_ROWS,
  TOTAL_CASH_IN_ID,
  TOTAL_CASH_OUT_ID,
} from '@/constants/cashInOut';
import useFeatureFlagsService from '@/hooks/useFeatureFlagsService';
import useTypedSelector from '@/hooks/useTypedSelector';
import useCashInOutGridQuery from './useCashInOutGridQuery';

/**
 * Fetches data for the Cash In/Out grid and transforms it into the shape needed
 * for the Cash grid table
 *
 * @type {(
 *   filter?: import('@/types/services/backend').CashInOutGrid['cashFlowType'],
 *   shouldGroupView?: boolean,
 * ) => import('../types/cashInOutGrid').CashInOutGridData}
 */
const useCashInOutGridData = (filter = null, shouldGroupView = true) => {
  const { isLoading, data } = useCashInOutGridQuery({ filter });
  const userPreferences = useTypedSelector(({ auth }) => auth.preferences);
  const { flags } = useFeatureFlagsService();
  const isGrouped = useMemo(() => {
    return (
      flags['cash-grid-grouped-view'] &&
      shouldGroupView &&
      (userPreferences[`${SPREADSHEET_ID}-GroupingToggle`] ?? true)
    );
  }, [flags, shouldGroupView, userPreferences]);
  const [rowData, shouldRenderAccountNumber] = useMemo(() => {
    if (isLoading || !Array.isArray(data) || data.length === 0) {
      return [];
    }
    return data.reduce(
      (accum, row) => {
        if (!isGrouped && !DEFAULT_ROWS.has(row.id) && row.isParentRow) {
          return accum;
        }
        /** @type {string[] | [string, string]} */
        const hierarchy = [];
        if (TOTAL_ROWS.has(row.name)) {
          hierarchy.push(row.id);
        } else if (row.cashFlowType === CASH_IN_TYPE) {
          hierarchy.push(TOTAL_CASH_IN_ID, row.id);
        } else if (row.cashFlowType === CASH_OUT_TYPE) {
          hierarchy.push(TOTAL_CASH_OUT_ID, row.id);
        }
        accum[0].push({
          ...row,
          family:
            row.cashFlowType === CASH_IN_TYPE
              ? actualsFamily.REVENUE
              : actualsFamily.EXPENSE,
          hierarchy: isGrouped ? row.hierarchy : hierarchy,
          forecastMethod:
            FORECAST_METHODS[row.forecastMethod] ?? FORECAST_METHODS.DEFAULT,
          forecastFormulaType: row.forecastMethod,
        });
        accum[1] = accum[1] || row.accountNumber !== null;
        return accum;
      },
      [[], false],
    );
  }, [isLoading, data, isGrouped]);
  return {
    isLoading,
    rowData,
    shouldRenderAccountNumber,
  };
};

export default useCashInOutGridData;
