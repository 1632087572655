import Button from '@/components/common/Button';

const Footer = ({
  onSaveClick,
  onCancelClick,
  disabledSaveBtn,
  saveBtnTxt = 'Save',
  loading = false,
}) => {
  return (
    <div className="d-flex justify-content-end">
      <Button
        onClick={onCancelClick}
        className="Button Button-cancelLink"
        data-testid="add-pricing-cancel"
      >
        Cancel
      </Button>

      <Button
        onClick={onSaveClick}
        disabled={disabledSaveBtn}
        data-testid="setting-footer-save-btn"
        className="Button Button-primaryLink"
        loading={loading}
      >
        {saveBtnTxt}
      </Button>
    </div>
  );
};
export default Footer;
