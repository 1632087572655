// @ts-check
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ResetIcon from '@bill/cashflow.assets/reset';
import { useQueryClient } from '@tanstack/react-query';
import { subscribeToExpenseActualsUpdateAction } from '@/actions/actuals';
import {
  getAllDepartments as getAllDepartmentsAction,
  subscribeToEmployeeUpdateAction,
} from '@/actions/employees';
import { updateActualsOnlyRevFlagAction } from '@/actions/revenue';
import { ACTUALS } from '@/cacheKeys';
import AddRevenueStreamModal from '@/components/Revenue/RevenueStream/AddRevenueStreamModal';
import RevenueStreamDelete from '@/components/Revenue/RevenueStream/RevenueStreamDelete';
import Checkbox from '@/components/common/Checkbox';
import Modal from '@/components/common/Modal';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import {
  formulaMonthRendererSelector,
  handleAgGridPaste,
} from '@/components/common/MonthlySpreadsheet/helpers';
import useIconClassRules from '@/components/common/MonthlySpreadsheet/useIconClassRules';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetLegend from '@/components/common/Spreadsheet/SpreadsheetLegend';
import SpreadsheetLegendContent from '@/components/common/Spreadsheet/SpreadsheetLegendContent';
import SpreadsheetStatusBar from '@/components/common/Spreadsheet/SpreadsheetStatusBar';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import { setScenarioValue } from '@/components/common/Spreadsheet/defaults';
import useRangeSelection from '@/components/common/Spreadsheet/hooks/useRangeSelection';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import useUpdateQueue from '@/components/common/Spreadsheet/useUpdateQueue';
import {
  RESET_ACTUAL_LABEL,
  actualsFamily,
  actualsLegendCopy,
  actualsText,
  actualsTitle,
  iconTypes,
} from '@/constants/actuals';
import { MONTHLY } from '@/constants/dateTime';
import { registeredFeatureFlags } from '@/constants/features';
import { NEG_METRIC_KEYS, POS_METRIC_KEYS } from '@/constants/metricTitles';
import { SUBSCRIPTION_ADVANCED_GROWTH } from '@/constants/revenueStream';
import { NO_COMPARISON } from '@/constants/scenario';
import { varianceText } from '@/constants/variables';
import { isActualMonth, toTitleCase } from '@/helpers';
import detectCircularRef from '@/helpers/circularReference';
import { isEmptyOrNull } from '@/helpers/validators';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import useWsSubscription from '@/hooks/useWsSubscription';
import ActualsContextMenuRenderer from '@/pages/Actuals/ActualsContextMenuRenderer';
import LegendWarning from '@/pages/Actuals/LegendWarning';
import TooltipCellRenderer from '@/pages/Actuals/TooltipCellRenderer';
import {
  useActualsMutation,
  useActualsQuery,
  valueFormatter,
  groupRowValueFormatter,
  tooltipCountFormatter,
  CLASS_PREFIX_KEY,
  valueGetter,
} from '@/pages/Actuals/helpers';
import EditForm from '@/pages/Employee/EditForm';
import EmployeeDelete from '@/pages/Employee/EmployeeDelete';
import ExpenseDelete from '@/pages/Expenses/ExpenseDelete';
import TheAddExpenseModal from '@/pages/Expenses/TheAddExpenseModal';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import { updateActuals } from '@/services/actuals.service';
import ExternalDetailsModal from './ExternalDetailsModal';
import FilterButtons from './FilterButtons';
import './ActualsGrid.scss';

const SPREADSHEET_ID = 'actuals';

const ALWAYS_NEGATIVE_VALUES = ['CHURNED_MRR', 'CHURNED_CUSTOMER'];
const ALWAYS_POSITIVE_VALUES = ['NEW_MRR', 'NEW_CUSTOMER'];

/**
 * @typedef {{
 *   id: string;
 *   scenarioId: number;
 *   title?: string;
 *   type: import('@/types/services/backend').ActualNodeDto['family'];
 *   values: (import('@/types/services/backend').ScenarioValueDto & {
 *     month: import('@/types/services/backend').ActualDateValueDetailsDto['date'];
 *   })[];
 * }} ExpenseActualsUpdate
 */

/**
 * @typedef {import('@/types/services/backend').ActualNodeDto['family']
 *   | 'combined'} ActualsView
 */

const getUpdatedExpenseRow = (actualsData, expenseActualsUpdate) => {
  const expenseRow = actualsData?.find(
    ({ id }) => id === expenseActualsUpdate.id,
  );
  if (!expenseRow || !actualsData || !expenseActualsUpdate) return null;
  const rowIndex = actualsData.findIndex(
    ({ id }) => id === expenseActualsUpdate.id,
  );
  const { values } = expenseActualsUpdate;
  values.forEach((newValue) => {
    const monthIdx = expenseRow.months.findIndex(
      (entry) => (entry.date ?? entry.month) === newValue.month,
    );
    const { value: oldValue } = expenseRow.months[monthIdx] ?? {};
    const value = Array.isArray(oldValue)
      ? setScenarioValue(expenseActualsUpdate.scenarioId, oldValue, newValue)
      : [{ ...newValue }];
    expenseRow.months[monthIdx] = {
      ...expenseRow.months[monthIdx],
      value,
    };
  });
  return { rowIndex, expenseRow };
};

const isAdvanceRevenueStream = (rowData) => {
  const {
    data: { key },
    column: { colDef },
  } = rowData;
  const advancedRevenueStreamScenarioIds = key
    .filter((k) => k.attributionType === SUBSCRIPTION_ADVANCED_GROWTH)
    .map(({ scenarioId }) => scenarioId);
  return advancedRevenueStreamScenarioIds.includes(Number(colDef.field));
};

const editorParams = (data) => {
  if (isAdvanceRevenueStream(data)) {
    return {};
  }

  const {
    data: { id, title, unit },
    column: { colId },
  } = data;
  const expensesEditorParams = {
    'data-testid': `${id}-${colId}`,
    'unit': unit,
  };
  if (ALWAYS_NEGATIVE_VALUES.includes(title)) {
    return { ...expensesEditorParams, allowPositive: false };
  }
  if (ALWAYS_POSITIVE_VALUES.includes(title)) {
    return { ...expensesEditorParams, allowNegative: false };
  }
  return expensesEditorParams;
};

/** @type {(family: ActualsView, id: string) => string} */
const generateRecordViewId = (family, id) => {
  switch (family) {
    case actualsFamily.EXPENSE:
      return `${id}-${actualsFamily.EXPENSE}`;
    case 'combined':
      return id;
    default:
      throw new Error('Unknown actual type');
  }
};

const ActualsGridLegend = (props) => (
  <SpreadsheetLegend {...props}>
    <SpreadsheetLegendContent
      content={actualsLegendCopy}
      userEnteredTextOverride="Manual Adjustments"
    />
  </SpreadsheetLegend>
);

const ActualsGrid = ({
  actualFamily = actualsFamily.COMBINE,
  apiRef,
  startDate,
  endDate,
  scenarioId,
  compareScenarioId,
  selectedCompany,
  getAllDepartments,
  departments,
  employeesList,
  updateActualsOnlyRevFlag,
  subscribeToEmployeeUpdate,
  subscribeToExpenseActualsUpdate,
  timePeriod,
}) => {
  const [family, setFamily] = useState(actualFamily);
  const headerName = actualsText[family];

  /**
   * @type {ReturnType<
   *   typeof useState<
   *     import('@/pages/Actuals/ExternalDetailsModal').ExternalActualDetails
   *   >
   * >}
   */
  const [externalActualDetails, setExternalActualDetails] = useState(null);

  const [showVarianceAmount, setShowVarianceAmount] = useState(true);
  const [showVariancePercentage, setShowVariancePercentage] = useState(false);

  const hasWritePermission = useNonDashboardWritePermission();

  const { pathname } = useLocation();
  const hasRevenueData = pathname.includes('/revenue');

  const [expenseRecord, setExpenseRecord] = useState(null);
  const [showExpenseDelete, setShowExpenseDelete] = useState(false);
  const [showExpense, setShowExpense] = useState(false);

  const [employeeRecord, setEmployeeRecord] = useState(null);
  const [showEmployeeDelete, setShowEmployeeDelete] = useState(false);
  const [showEmployee, setShowEmployee] = useState(false);
  const [employeeFormViewMode, setEmployeeFormViewMode] = useState();
  const [selectedCells, setSelectedCells] = useState([]);
  const [cellCount, setCellCount] = useState(0);
  const [cellSum, setCellSum] = useState(0);
  const [cellUnit, setCellUnit] = useState('');

  const selectedCompanyId = selectedCompany?.id;

  const isShowPercentVarianceEnabled = useFeatureFlags(
    registeredFeatureFlags.SHOW_VARIANCE_PERCENT,
  );

  const scenarioIds = useMemo(
    () =>
      [...new Set([scenarioId, compareScenarioId])].filter(
        (id) => id !== NO_COMPARISON,
      ),
    [scenarioId, compareScenarioId],
  );
  const [showRevenueStream, setShowRevenueStream] = useState(null);
  const [revenueStreamId, setRevenueStreamId] = useState(null);
  const [showRevenueDelete, setShowRevenueDelete] = useState(false);

  /**
   * @type {[
   *   ExpenseActualsUpdate[],
   *   React.Dispatch<React.SetStateAction<ExpenseActualsUpdate[]>>,
   * ]}
   */
  const [expenseActualsUpdate, setExpenseActualsUpdates] = useState([]);

  const hasComparison = compareScenarioId !== NO_COMPARISON;

  const queryClient = useQueryClient();
  const {
    data: actualsData,
    isLoading,
    refetch,
  } = useActualsQuery({
    family,
    startDate,
    endDate,
    timePeriod,
    scenarioIds,
    hasComparison,
  });

  const refreshActuals = useCallback(() => {
    queryClient.invalidateQueries([ACTUALS, scenarioId]);
    refetch();
  }, [queryClient, scenarioId, refetch]);

  const { mutateAsync: updateActualsMutation, isError: isUpdateError } =
    useActualsMutation(updateActuals, setExpenseActualsUpdates);

  useEffect(() => {
    if (isUpdateError) refreshActuals();
  }, [isUpdateError, refreshActuals]);

  useEffect(() => {
    setCellCount(0);
    setSelectedCells([]);
  }, [scenarioIds, startDate, endDate, family, timePeriod]);

  useEffect(() => {
    if (!selectedCompanyId) return;
    getAllDepartments(selectedCompanyId);
  }, [getAllDepartments, selectedCompanyId]);

  useEffect(() => {
    updateActualsOnlyRevFlag(!hasRevenueData);
    return () => {
      updateActualsOnlyRevFlag(false);
    };
  }, [hasRevenueData, updateActualsOnlyRevFlag]);

  useWsSubscription(
    () =>
      subscribeToEmployeeUpdate(scenarioId, () => {
        refreshActuals();

        getAllDepartments(selectedCompanyId);
      }),
    [scenarioId, selectedCompanyId, family],
  );

  useWsSubscription(
    () =>
      subscribeToExpenseActualsUpdate(
        scenarioId,
        (/** @type {ExpenseActualsUpdate} */ payload) => {
          setExpenseActualsUpdates([payload]);
        },
      ),
    [scenarioId],
  );

  useWsSubscription(() => {
    if (compareScenarioId === NO_COMPARISON) return null;
    return subscribeToExpenseActualsUpdate(
      compareScenarioId,
      (/** @type {ExpenseActualsUpdate} */ payload) => {
        setExpenseActualsUpdates([payload]);
      },
    );
  }, [compareScenarioId]);

  /**
   * @type {(
   *   view: ActualsView,
   *   expenseUpdate: ExpenseActualsUpdate[],
   *   api: import('ag-grid-community').GridApi,
   * ) => void}
   */
  const updateQueryCacheAndRefreshCell = (view, expenseUpdate, api) => {
    const comparisonScenarioId = hasComparison ? scenarioIds[1] : NO_COMPARISON;
    const baseScenarioId = scenarioIds[0];
    const cacheKey = [
      ACTUALS,
      view,
      startDate,
      endDate,
      timePeriod,
      baseScenarioId,
      comparisonScenarioId,
    ];
    const cachedData = queryClient.getQueryData(cacheKey);

    /** @type {{ [key: string]: import('ag-grid-community').RowNode }} */
    const recordViewRowNode = expenseUpdate.reduce((nodes, payload) => {
      const recordViewId = generateRecordViewId(view, payload.id);

      const expenseUpdatedData = getUpdatedExpenseRow(cachedData, {
        ...payload,
        id: recordViewId,
      });

      if (expenseUpdatedData) {
        cachedData[expenseUpdatedData.rowIndex] = expenseUpdatedData.expenseRow;
        queryClient.setQueryData(cacheKey, cachedData);
      }

      if (nodes[recordViewId]) return nodes;

      let rowNode = api.getRowNode(recordViewId);
      // When comparing scenarios, WS updates have the ID of the entity in the
      // scenario on which the update occurred, but the row IDs come from the
      // base scenario. When updating a comparison value, we need to look up the
      // corresponding entity ID in the base scenario to find the row.
      if (!rowNode) {
        const entry = actualsData.find((expense) =>
          expense.key.some(({ key }) => key === recordViewId),
        );
        if (entry) {
          const [baseKey] = entry.key;
          rowNode = api.getRowNode(baseKey.key);
        }
      }

      if (rowNode) {
        return { ...nodes, [recordViewId]: rowNode };
      }
      return nodes;
    }, /** @type {{ [key: string]: import('ag-grid-community').RowNode }} */ ({}));

    const rowNodes = Object.values(recordViewRowNode);

    if (rowNodes.length > 0) {
      api.refreshCells({ rowNodes });
    }
  };

  // BU-9080: TODO As per discussion with team,this code is temporary fix and will need to be removed
  // We are modifying React query cache for both expense and combined api call when a websocket event
  // is triggered.
  useEffect(() => {
    if (!expenseActualsUpdate.length || !apiRef.current.api) return;

    const { api } = apiRef.current;
    const gridOptions = api.gridOptionsWrapper?.gridOptions;
    if (gridOptions) gridOptions.context.loadingCells = {};

    if (family === actualsFamily.EXPENSE || !family) {
      updateQueryCacheAndRefreshCell('combined', expenseActualsUpdate, api);
      updateQueryCacheAndRefreshCell(
        actualsFamily.EXPENSE,
        expenseActualsUpdate,
        api,
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [actualsData, expenseActualsUpdate, family, apiRef, queryClient]);

  const cellRenderer = useCallback((params) => {
    const { colDef, column, data } = params;
    const month = column.getParent().getGroupId();
    const monthValue = data.months.find(({ date }) => date === month);
    const scenarioEntry = monthValue?.value.find(
      (val) => val.scenarioId === Number(colDef.field),
    );
    if (!scenarioEntry) return undefined;

    const { loading: cellLoading, type } = scenarioEntry;

    const isCircularRef = detectCircularRef(scenarioEntry);

    const handleClickTooltip =
      !monthValue?.revenueActualDetails?.length &&
      !monthValue?.expenseGroupActualDetails?.length
        ? undefined
        : () =>
            setExternalActualDetails({
              name: toTitleCase(data.title.replace(/_/g, ' ')),
              details:
                data.family === actualsFamily.REVENUE
                  ? monthValue.revenueActualDetails
                  : monthValue.expenseGroupActualDetails,
              family: data.family,
            });

    if (
      !cellLoading &&
      data.family === actualsFamily.EXPENSE &&
      type === iconTypes.USER_ENTERED &&
      !isCircularRef
    ) {
      return formulaMonthRendererSelector(params);
    }

    if (
      !cellLoading &&
      !isCircularRef &&
      (!isActualMonth(month) ||
        ![
          iconTypes.CIRCULAR_REFERENCE,
          iconTypes.EXTERNAL_SOURCE_GENERATED,
        ].includes(type))
    ) {
      return undefined;
    }

    let iconType = type;
    if (isCircularRef) {
      iconType = iconTypes.CIRCULAR_REFERENCE;
    } else if (cellLoading) {
      iconType = iconTypes.USER_ENTERED;
    }

    return {
      component: TooltipCellRenderer,
      params: {
        iconType,
        cellLoading,
        onClick: handleClickTooltip,
        title: data.title,
      },
    };
  }, []);

  const iconClassRules = useIconClassRules(timePeriod);

  const handleModalClose = () => setExternalActualDetails(null);

  const handleEdit = useCallback(
    (record, recordFamily, employeeView) => {
      switch (recordFamily) {
        case actualsFamily.EXPENSE:
          setExpenseRecord({ ...record, type: record.expenseGroupType });
          setShowExpense(true);
          break;
        case actualsFamily.PAYROLL:
          setEmployeeRecord(record);
          setShowEmployee(true);
          setEmployeeFormViewMode(employeeView);
          break;
        case actualsFamily.REVENUE: {
          const { key } = record.find((rec) => rec.scenarioId === scenarioId);
          setRevenueStreamId(key);
          setShowRevenueStream(true);
          break;
        }
        default:
          throw new Error(`invalid family type: ${recordFamily}`);
      }
    },
    [scenarioId],
  );

  const handleDelete = useCallback(
    async (record, recordFamily) => {
      switch (recordFamily) {
        case actualsFamily.EXPENSE:
          setExpenseRecord(record);
          setShowExpenseDelete(true);
          break;
        case actualsFamily.PAYROLL:
          setEmployeeRecord(record);
          setShowEmployeeDelete(true);
          break;
        case actualsFamily.REVENUE: {
          const { key } = record.find((rec) => rec.scenarioId === scenarioId);
          setRevenueStreamId(key);
          setShowRevenueDelete(true);
          break;
        }
        default:
          throw new Error(`invalid family type: ${recordFamily}`);
      }
    },
    [scenarioId],
  );

  const employeeExpenseClassMap = useMemo(() => {
    return employeesList.reduce((acc, employee) => {
      const { departmentName, expenseClassName } = employee;

      // we need to prepend 'Class' to prevent collision when departmentName and expenseClassName are the same
      const expenseClass = `${CLASS_PREFIX_KEY}${expenseClassName}`;

      // init keys if it doesn't exist
      acc[expenseClass] = acc[expenseClass] || {};
      acc[departmentName] = acc[departmentName] || [];
      acc[expenseClass][departmentName] =
        acc[expenseClass][departmentName] || [];

      acc[expenseClass][departmentName].push(employee);
      acc[departmentName].push(employee);

      return acc;
    }, {});
  }, [employeesList]);

  const colDefs = useMemo(() => {
    /** @type {import('ag-grid-community').ColDef[]} */
    const colDef = [
      {
        headerName,
        cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          suppressCount: true,
          employeeMap: employeeExpenseClassMap,
        },
        field: 'title',
        valueFormatter:
          family === actualsFamily.PAYROLL ? groupRowValueFormatter : undefined,
        tooltipValueGetter: tooltipCountFormatter,
        headerComponent: HeaderRenderer,
        headerComponentParams: { enableExpandAll: true },
        minWidth: 300,
        showRowGroup: true,
      },
    ];

    colDef.push({
      type: 'actions',
      cellRendererSelector: ({ data }) => {
        const { family: rowFamily, key, actualParentEntity } = data;

        if (
          !key?.length ||
          (rowFamily !== actualsFamily.REVENUE && !actualParentEntity) ||
          // Very brittle check for non-revenue stream rows
          (rowFamily === actualsFamily.REVENUE &&
            data.hierarchy.length !== 2) ||
          data.actualParentEntity?.isHiringDriverEmployee
        ) {
          return null;
        }

        return {
          component: ActualsContextMenuRenderer,
          params: {
            onEditClick: handleEdit,
            onDeleteClick: handleDelete,
          },
        };
      },
    });

    return colDef;
  }, [headerName, employeeExpenseClassMap, family, handleEdit, handleDelete]);

  const isEditable = useCallback(
    ({ colDef, column, data, node }) => {
      const month = column.getParent().getGroupId();
      const scenarioEntry = data?.months
        .find(({ date }) => date === month)
        ?.value.find((val) => val.scenarioId === Number(colDef.field));

      if (!scenarioEntry || scenarioEntry.value === null) return false;

      return (
        hasWritePermission &&
        timePeriod === MONTHLY &&
        (!node?.allChildrenCount ||
          data?.title.toLowerCase() === actualsTitle.TOTAL_REVENUE) &&
        ![
          actualsTitle.ADJUSTMENT_TO_REVENUE,
          actualsTitle.NET_INCOME,
          actualsTitle.TOTAL_NON_PAYROLL_EXPENSE,
          actualsTitle.ADJUSTMENT_TO_PAYROLL,
          actualsTitle.BEGINNING_MRR,
          actualsTitle.ENDING_MRR,
          actualsTitle.BEGINNING_CUSTOMER,
          actualsTitle.ENDING_CUSTOMER,
        ].includes(data?.title.toLowerCase()) &&
        (isActualMonth(month) || data.family === actualsFamily.EXPENSE)
      );
    },
    [hasWritePermission, timePeriod],
  );

  // Pasting a range of cells will fire this callback for each cell,
  // so we add them to a queue and do a bulk update when it stops firing.
  const addValueToQueue = useCallback((queue, rowData) => {
    const { column, data, newValue } = rowData;
    const colScenarioId = Number(column.getColDef().field);
    const findByScenarioId = (scenario) =>
      scenario.scenarioId === colScenarioId;

    // Editable aggregate rows (e.g. Total Revenue) do not have keys
    const key = data.key.find(findByScenarioId)?.key;

    const [grandparent, parent] = data.parentKeys
      .slice(-2)
      .map((scenarioKeys) => scenarioKeys.find(findByScenarioId));

    const value =
      newValue?.displayFormula ??
      (typeof newValue === 'object' ? newValue?.value : newValue);
    const payload = {
      yearMonth: column.getParent().getGroupId(),
      scenarioId: colScenarioId,
      type: data.family,
      value: !isEmptyOrNull(value) ? Number(value) : null,
    };
    switch (data.family) {
      case actualsFamily.EXPENSE: {
        const { displayFormula, fillRight } = newValue;
        payload.displayFormula = displayFormula;
        payload.fillRight = fillRight;
        payload.parentId = key;
        break;
      }
      case actualsFamily.PAYROLL:
        // no key = update Total Payroll
        if (key) {
          payload.parentId = parent.key;
          payload.payrollMetric = Number(key);
        }
        break;
      case actualsFamily.REVENUE:
        // no key = update Total Revenue
        if (key) {
          const keyAsNum = Number(key);
          const { value: metricValue } = payload;
          const isAdvanceSubscription = isAdvanceRevenueStream(rowData);
          if (isEmptyOrNull(metricValue)) {
            payload.value = null;
          } else if (
            !isAdvanceSubscription &&
            NEG_METRIC_KEYS.includes(keyAsNum)
          ) {
            payload.value = -Math.abs(metricValue);
          } else if (
            !isAdvanceSubscription &&
            POS_METRIC_KEYS.includes(keyAsNum)
          ) {
            payload.value = Math.abs(metricValue);
          }

          payload.revenueMetric = keyAsNum;
          payload.revenueStreamPricingPlanId = parent.key;
          payload.parentId = grandparent.key;
        }
        break;
      default:
        throw new Error(`unknown actual type: ${data.family}`);
    }
    return [...queue, payload];
  }, []);

  const updateBulkActuals = useCallback(
    async (queue) => {
      await updateActualsMutation({ scenarioIds, actuals: queue });
      if (queue.some((element) => element.type !== actualsFamily.EXPENSE)) {
        refreshActuals();
      }
    },
    [updateActualsMutation, refreshActuals, scenarioIds],
  );

  const handleMonthValueChange = useUpdateQueue(
    addValueToQueue,
    updateBulkActuals,
  );

  const handleRangeSelection = useRangeSelection(
    setSelectedCells,
    setCellCount,
    setCellUnit,
    setCellSum,
  );

  const handleResetActuals = useCallback(() => {
    const { api, columnApi } = apiRef.current;
    const columns = [];
    const rowNodes = [];
    selectedCells.forEach((cell) => {
      const { colId } = cell.colDef;
      const newValue = { displayFormula: null, value: null };
      if (cell.data.family === actualsFamily.EXPENSE) {
        cell.node.setDataValue(cell.colDef.colId, newValue);
      } else {
        // eslint-disable-next-line no-param-reassign -- predates description requirement
        cell.oldValue.loading = true;
        columns.push(colId);
        rowNodes.push(cell.node);
        const column = columnApi.getColumn(colId);
        handleMonthValueChange({
          column,
          data: cell.node.data,
          newValue,
        });
        // Force the loading indicator to display
        api.refreshCells({ columns, rowNodes });
      }
    });
    setSelectedCells([]);
    setCellCount(0);
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [selectedCells, handleMonthValueChange]);

  let Legend = ActualsGridLegend;
  if (timePeriod !== MONTHLY) {
    Legend = LegendWarning;
  }

  const rendererParams = useCallback(
    (params) => {
      return {
        'data-testid': `${params.data.title}-${params.colDef.colId}`,
        'onFillRightClick': () => {
          const paramsCopy = {
            ...params,
            newValue: {
              ...params.value,
              fillRight: true,
            },
          };
          const payload = addValueToQueue([], paramsCopy);
          updateBulkActuals(payload);
        },
      };
    },
    [addValueToQueue, updateBulkActuals],
  );

  return (
    <>
      <SpreadsheetToolbar Legend={Legend}>
        <FilterButtons filter={family} onFilterClick={setFamily} />
        <div className="SpreadsheetToolbar_ControlGroup">
          Options: <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
        </div>
        {hasComparison && (
          <>
            <Checkbox
              id="variance-toggle"
              checked={showVarianceAmount}
              className="SpreadsheetToolbar_ControlGroup"
              onChange={() => setShowVarianceAmount(!showVarianceAmount)}
            >
              {varianceText.SHOW_VARIANCE_AMOUNT}
            </Checkbox>
            {isShowPercentVarianceEnabled && (
              <Checkbox
                id="variance-toggle"
                checked={showVariancePercentage}
                className="SpreadsheetToolbar_ControlGroup"
                onChange={() =>
                  setShowVariancePercentage(!showVariancePercentage)
                }
              >
                {varianceText.SHOW_VARIANCE_PERCENTAGE}
              </Checkbox>
            )}
          </>
        )}
      </SpreadsheetToolbar>
      <MonthlySpreadsheet
        ref={apiRef}
        enableComparison
        cellClassRules={iconClassRules}
        columnDefs={colDefs}
        data-testid={SPREADSHEET_ID}
        editable={isEditable}
        getDataPath={(data) => data.hierarchy}
        groupDisplayType="custom"
        enableBrowserTooltips
        loading={isLoading}
        onMonthValueChange={handleMonthValueChange}
        valueFormatter={valueFormatter}
        valueGetter={valueGetter}
        rendererSelector={cellRenderer}
        rendererParams={rendererParams}
        rowClassRules={{
          'Spreadsheet_Row-total': ({ data }) =>
            [
              actualsTitle.TOTAL_REVENUE,
              actualsTitle.NET_INCOME,
              actualsTitle.TOTAL_NON_PAYROLL_EXPENSE,
              actualsTitle.TOTAL_PAYROLL,
            ].includes(data.title.toLowerCase()),
        }}
        data={actualsData}
        editorParams={editorParams}
        treeData
        showVarianceAmount={showVarianceAmount}
        showVariancePercentage={showVariancePercentage}
        onRangeSelectionChanged={handleRangeSelection}
        processCellFromClipboard={handleAgGridPaste}
      />

      {cellCount > 0 && (
        <SpreadsheetStatusBar
          cellCount={cellCount}
          cellSum={cellSum}
          cellUnit={cellUnit}
        >
          {!!selectedCells.length && hasWritePermission && (
            <button
              type="button"
              onClick={handleResetActuals}
              className="ResetContainer"
            >
              <ResetIcon className="ResetIcon" />
              {RESET_ACTUAL_LABEL}
            </button>
          )}
        </SpreadsheetStatusBar>
      )}

      <Modal
        open={!!externalActualDetails}
        className="Modal-flex"
        onClose={handleModalClose}
        data-testid="expense-or-payroll-details"
      >
        <ExternalDetailsModal
          entry={externalActualDetails}
          onClose={handleModalClose}
        />
      </Modal>

      {showExpense && (
        <TheAddExpenseModal
          onClose={() => {
            setExpenseRecord(null);
            setShowExpense(false);
          }}
          currentRecord={expenseRecord}
          onEdit={refreshActuals}
        />
      )}

      <ExpenseDelete
        showDelete={showExpenseDelete}
        setShowDelete={setShowExpenseDelete}
        isExpenseGroupCriteria={expenseRecord?.expenseGroupCriteria}
        expenseId={expenseRecord?.id}
        setCurrentRecord={setExpenseRecord}
        isActualsOrigin
        onDelete={refreshActuals}
      />

      {showEmployee && (
        <EditForm
          handleClose={() => {
            setEmployeeRecord(null);
            setShowEmployee(false);
          }}
          editRecord={employeeRecord}
          viewMode={employeeFormViewMode}
          departments={departments}
        />
      )}

      <EmployeeDelete
        showDeleteModal={showEmployeeDelete}
        setShowDeleteModal={setShowEmployeeDelete}
        currentEmployee={employeeRecord}
        setCurrentEmployee={setEmployeeRecord}
        isActualsOrigin
        onDelete={refreshActuals}
      />

      {showRevenueStream && (
        <AddRevenueStreamModal
          show={showRevenueStream}
          mode="edit"
          recordIdForEdit={revenueStreamId}
          handleClose={() => {
            setRevenueStreamId(null);
            setShowRevenueStream(false);
          }}
          showAddProductModal={false}
          addProductFromRevenue={false}
          onSaved={refreshActuals}
        />
      )}
      <RevenueStreamDelete
        showDeleteConfirmation={showRevenueDelete}
        setShowDeleteConfirmation={setShowRevenueDelete}
        recordId={revenueStreamId}
        handleClose={() => setRevenueStreamId(null)}
        onDelete={refreshActuals}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { shared, scenario, employees } = state;
  return {
    startDate: shared.startDate,
    endDate: shared.endDate,
    timePeriod: shared.timePeriod,
    scenarioId: scenario.scenarioId,
    compareScenarioId: scenario.compareScenarioId,
    selectedCompany: getSelectedCompany(state),
    departments: employees.departments,
    employeesList: employees.employeesList,
  };
};
export default connect(mapStateToProps, {
  getAllDepartments: getAllDepartmentsAction,
  updateActualsOnlyRevFlag: updateActualsOnlyRevFlagAction,
  subscribeToEmployeeUpdate: subscribeToEmployeeUpdateAction,
  subscribeToExpenseActualsUpdate: subscribeToExpenseActualsUpdateAction,
})(ActualsGrid);
