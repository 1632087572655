import InfoTooltip from '@/components/common/InfoTooltip';
import {
  BEGINNING_CASH,
  CASH_PROFIT_OR_LOSS,
  FINANCING,
  ADJUSTMENTS_TO_CASH,
  ACTUAL_ENDING_CASH,
  ACTUAL_CASH_COLLECTIONS,
  ACTUAL_CASH_PAYMENTS,
} from '@/constants/actuals';
import { capitalize, slugify } from '@/helpers';

const getTooltipText = (value) => {
  switch (value) {
    case BEGINNING_CASH:
      return (
        <>
          <div className="InfoTooltip_Title">{BEGINNING_CASH}</div>
          {capitalize(BEGINNING_CASH)} is the ending cash balance from the prior
          month.
        </>
      );
    case CASH_PROFIT_OR_LOSS:
      return (
        <>
          <div className="InfoTooltip_Title">{CASH_PROFIT_OR_LOSS}</div>
          {capitalize(CASH_PROFIT_OR_LOSS)} is the same as the monthly burn rate
          $, but shown as an opposite sign to reflect the effect on cash.
        </>
      );
    case FINANCING:
      return (
        <>
          <div className="InfoTooltip_Title">{FINANCING}</div>
          {capitalize(FINANCING)} is the sum of financing for the month.
        </>
      );
    case ADJUSTMENTS_TO_CASH:
      return (
        <>
          <div className="InfoTooltip_Title">{ADJUSTMENTS_TO_CASH}</div>
          <p className="InfoTooltip_Text">
            Cash balances can be volatile and the difference between your ending
            bank balance and the Finmark plan will be represented here as a
            "plug". Large values should be tracked down and potentially included
            in the plan. Small values may reverse over time.
          </p>
          {capitalize(ADJUSTMENTS_TO_CASH)} calculates the difference between
          ending cash and the sum of beginning cash, monthly burn rate, and
          financing.
        </>
      );
    case ACTUAL_ENDING_CASH:
      return (
        <>
          <div className="InfoTooltip_Title">{ACTUAL_ENDING_CASH}</div>
          {capitalize(ACTUAL_ENDING_CASH)} is the ending bank balance for your
          business.
        </>
      );
    case ACTUAL_CASH_COLLECTIONS:
      return (
        <>
          <div className="InfoTooltip_Title">{ACTUAL_CASH_COLLECTIONS}</div>
          {capitalize(ACTUAL_CASH_COLLECTIONS)} is the amount you’ve collected
          this month from all your invoiced revenue streams (drivers).
        </>
      );
    case ACTUAL_CASH_PAYMENTS:
      return (
        <>
          <div className="InfoTooltip_Title">{ACTUAL_CASH_PAYMENTS}</div>
          {capitalize(ACTUAL_CASH_PAYMENTS)} is the amount you paid out this
          month for all your invoiced expenses.
        </>
      );
    default:
      throw new Error('Unknown value; cannot get tooltip text.');
  }
};

const TooltipCellRenderer = ({ value }) => {
  const testId = slugify(value);
  return (
    <div className="ActualsTable_Cell-withTooltip" data-testid={testId}>
      {value}{' '}
      <InfoTooltip data-testid={`${testId}-tooltip`}>
        {getTooltipText(value)}
      </InfoTooltip>
    </div>
  );
};

export default TooltipCellRenderer;
