import { useCallback, useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import ExpensesEmptyIcon from '@bill/cashflow.assets/expenses-empty';
import {
  getEmployees as getEmployeesAction,
  subscribeToEmployeeUpdateAction,
} from '@/actions/employees';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import {
  columnTooltipPositioner,
  getStackedColumnTooltipMetrics,
} from '@/components/Charts/tooltips';
import EmptyData from '@/components/common/EmptyData';
import { TOTAL_EXPENSES_SWITCH_ID } from '@/constants/ui';
import useChartPreferences from '@/hooks/useChartPreferences';
import useTotalExpensesChartColorMap from '@/hooks/useTotalExpensesChartColorMap';
import useWsSubscription from '@/hooks/useWsSubscription';
import useTotalExpensesQuery from './useTotalExpensesQuery';

const TOTAL_EXPENSES_MAIN_METRIC = 'totalY';

const metrics = (context) =>
  getStackedColumnTooltipMetrics(context, {
    additional: [
      {
        name: 'Total Expenses',
        key: TOTAL_EXPENSES_MAIN_METRIC,
        isMainMetric: true,
      },
    ],
  });

const TotalExpensesChart = ({
  exportBtn,
  plotOptions,
  axisStyles,
  tooltipOptions,
  className,
  onChartCreated,
  onQueryStateChange,
}) => {
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );
  const employeesList = useSelector(({ employees }) => employees.employeesList);
  const includePayroll = useChartPreferences(TOTAL_EXPENSES_SWITCH_ID) ?? true;

  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const scenarioQueries = useTotalExpensesQuery();
  const [base] = scenarioQueries;
  const scenarioId = base?.scenario.scenarioId;

  const expensesColorMap = useTotalExpensesChartColorMap(
    scenarioId,
    scenarioQueries,
  );

  useEffect(
    () => {
      if (scenarioQueries.length) onQueryStateChange?.(scenarioQueries);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
    [scenarioQueries],
  );

  const getEmployees = useCallback(
    () => dispatch(getEmployeesAction(scenarioId, startDate, endDate)),
    [dispatch, scenarioId, startDate, endDate],
  );
  useEffect(() => {
    getEmployees();
  }, [getEmployees]);

  useWsSubscription(
    () =>
      dispatch(
        subscribeToEmployeeUpdateAction(scenarioId, () => {
          base.refetch();
          getEmployees();
        }),
      ),
    [scenarioId],
  );

  const hasData =
    base?.isLoading ||
    (includePayroll && employeesList.length) ||
    base?.data.data.length;

  return (
    <>
      {hasData ? (
        <DateChart
          className={className}
          data-testid="expensesChart"
          loading={scenarioQueries.every(({ isLoading }) => isLoading)}
          tooltip={
            <ChartTooltip
              metrics={metrics}
              positioner={columnTooltipPositioner}
              valueKey={TOTAL_EXPENSES_MAIN_METRIC}
              timePeriod={timePeriod}
              endDate={endDate}
              {...tooltipOptions}
            />
          }
          plotOptions={plotOptions}
          axisStyles={axisStyles}
          ref={exportBtn}
          onChartCreated={onChartCreated}
          timePeriod={timePeriod}
          startDate={startDate}
          endDate={endDate}
        >
          {scenarioQueries.map(({ data, scenario }) => (
            <DateChart.StackedColumnSeries
              key={scenario.scenarioId}
              data={data.data}
              isComparison={scenario?.scenarioId !== scenarioId}
              scenario={scenario}
              colors={expensesColorMap}
            />
          ))}
        </DateChart>
      ) : (
        <EmptyData Icon={ExpensesEmptyIcon}>
          Add Expenses or Employees for this chart to populate
        </EmptyData>
      )}
    </>
  );
};

export default TotalExpensesChart;
