import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { updateDashboardAction } from '@/actions/dashboard';

/**
 * A hook for persisting dashboard changes to the server, including setting a
 * default dashboard, updating its layout, etc.
 *
 * @returns {(
 *   dashId: string,
 *   updates: Partial<import('../types/dashboard').Dashboard>,
 * ) => Promise<void>}
 *   - A function that will update the dashboard layout on the server
 */
const useUpdateDashboard = () => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const scenarioId = useSelector(({ scenario }) => {
    return scenario.scenarioId;
  });
  const dashboards = useSelector(({ dashboard }) => {
    return dashboard.dashboards;
  });

  return useCallback(
    (dashId, updates) => {
      const dashboard = dashboards.find((dash) => {
        return dash.id === dashId;
      });
      return dispatch(
        updateDashboardAction(scenarioId, {
          ...dashboard,
          ...updates,
        }),
      );
    },
    [dashboards, dispatch, scenarioId],
  );
};

export default useUpdateDashboard;
