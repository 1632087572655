import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import FormulaIcon from '@bill/cashflow.assets/formula';
import UnitSelector from '@/components/common/Spreadsheet/UnitSelector';
import useFocusOnEdit from '@/components/common/Spreadsheet/editors/useFocusOnEdit';
import WithVariableAutocomplete from '@/components/common/WithVariableAutocomplete';
import { findCurrencySymbol } from '@/constants/currencies';
import { units } from '@/constants/employees';
import {
  ALLOW_NEGATIVE_NUM_REGEX,
  EXPENSE,
  NUM_REGEX,
} from '@/constants/expenses';
import { getSizeForInput } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';

function CurrencySymbol(props) {
  return <span {...props}>{findCurrencySymbol()}</span>;
}

const EMPLOYEE = 'employee';

const unitMap = {
  [units.CURRENCY]: {
    label: 'Currency',
    UnitSymbol: CurrencySymbol,
  },
  [units.FORMULA]: {
    label: 'Formula',
    UnitSymbol: FormulaIcon,
  },
};

const getUnit = (type, data) => {
  if (type === EXPENSE) {
    return isEmptyOrNull(data.customFormula) ? units.CURRENCY : units.FORMULA;
  }
  return isEmptyOrNull(data.salaryVariableId) ? units.CURRENCY : units.FORMULA;
};

const AmountEditor = forwardRef(
  (
    {
      charPress,
      data,
      type = EMPLOYEE,
      presetFormulasId = null,
      allowNegativeValues = false,
    },
    ref,
  ) => {
    const initialValue =
      type === EXPENSE
        ? data.customFormula ?? data.expenseAmount
        : data.salaryFormula;
    const input = useRef(null);
    const [selectedValue, setSelectedValue] = useState(
      charPress ?? initialValue,
    );
    const [unit, setUnit] = useState(getUnit(type, data));

    const size = getSizeForInput(selectedValue);

    useImperativeHandle(ref, () => ({
      getValue: () => ({
        selectedValue,
        unit,
      }),
    }));

    useFocusOnEdit(input);

    return (
      <div className="Spreadsheet_CellEditor Spreadsheet_CellEditor-withUnit">
        <UnitSelector
          data-testid="amount-editor"
          unit={unit}
          onChange={(newUnit) => {
            setUnit(newUnit);
            setSelectedValue('');
            input.current.focus();
          }}
          unitMap={unitMap}
        />
        {unit === units.FORMULA ? (
          <WithVariableAutocomplete
            data-testid="amount-editor-autocomplete"
            inputRef={input}
            value={selectedValue ?? ''}
            onChange={(newValue) => setSelectedValue(newValue)}
            presetFormulasId={presetFormulasId}
          >
            {({ inputRef, ...props }) => (
              <div className="FormulaEditor_InputWrapper">
                <input
                  data-testid="amount-editor-input"
                  type="text"
                  className="Spreadsheet_Input"
                  size={size}
                  onChange={({ target }) => setSelectedValue(target.value)}
                  ref={inputRef}
                  {...props}
                />
              </div>
            )}
          </WithVariableAutocomplete>
        ) : (
          <input
            id="amountEditor-input"
            ref={input}
            type="text"
            style={{ width: `${size * 10}px` }}
            className="Spreadsheet_Input"
            value={selectedValue || ''}
            onChange={({ target }) => {
              const { value } = target;
              if (
                (allowNegativeValues
                  ? ALLOW_NEGATIVE_NUM_REGEX
                  : NUM_REGEX
                ).test(value)
              )
                setSelectedValue(value);
            }}
          />
        )}
      </div>
    );
  },
);

export default AmountEditor;
