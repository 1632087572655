const hasItems = (array) => {
  return array.length > 0;
};

/**
 * Flatten a variable tree into an array of variable names
 *
 * @param {Object} nodes The api response from /v1/variables
 * @returns {Array} An array of variable names
 */
const flattenVariablesTree = (nodes) => {
  const result = [];
  let stack = [...nodes.children];

  while (hasItems(stack)) {
    const item = stack.pop();
    const { children, variable } = item;

    if (hasItems(children)) {
      stack = [...stack, ...children];
    } else {
      result.push(variable);
    }
  }

  return result;
};

export default flattenVariablesTree;
