import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import RevenueEmptyIcon from '@bill/cashflow.assets/revenue-empty';
import { getRevenueStreamsAction } from '@/actions/revenue';
import ContextMenuRenderer from '@/components/Revenue/ContextMenuRenderer';
import RevenueTitleBar from '@/components/Revenue/List/RevenueTitleBar';
import RevenueStreamDelete from '@/components/Revenue/RevenueStream/RevenueStreamDelete';
import EmptyData from '@/components/common/EmptyData';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import CellRendererSelector from '@/components/common/Spreadsheet/renderers/CircularRefCellRendererSelector';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import { REVENUE_STRIPE } from '@/constants/integrations';
import { revenueFamily } from '@/constants/revenue';
import useCallbackRef from '@/hooks/useCallbackRef';
import useGridRefreshCells from '@/hooks/useGridRefreshCells';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import { GRID_OPTIONS } from '@/pages/Reports/constants';

const SPREADSHEET_ID = 'revenueStreamsGrid';
const NAME = 'name';

const formatData = (revenueStreamsList) => {
  const TOTAL = 'total';
  const hierarchy = [TOTAL];
  const { total, products: revenueStreams } = revenueStreamsList;
  return [
    {
      id: TOTAL,
      name: 'Total',
      isEditable: false,
      months: total,
      hierarchy,
    },
    ...revenueStreams.map((revenueStream) => ({
      ...revenueStream,
      isEditable: true,
      hierarchy: [...hierarchy, revenueStream.id],
    })),
  ];
};

const RevenueStreamsTable = ({ onEdit }) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const [gridApi, ref] = useCallbackRef();
  const startDate = useSelector(({ shared }) => shared.startDate);
  const endDate = useSelector(({ shared }) => shared.endDate);
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);
  const revenueStreamsList = useSelector(
    ({ revenues }) => revenues.revenueStreamsList,
  );
  const lastUpdatedRow = useSelector(
    ({ revenues }) => revenues.lastUpdatedRevenueStream,
  );

  useEffect(() => {
    dispatch(getRevenueStreamsAction({ startDate, endDate, scenarioId }));
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [startDate, endDate, scenarioId]);

  const hasWritePermission = useNonDashboardWritePermission();
  const hasData = !!revenueStreamsList?.products.length;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [revenueStreamRecordIdForDelete, setRevenueStreamRecordIdForDelete] =
    useState(null);

  const onDelete = (recordId) => {
    setRevenueStreamRecordIdForDelete(recordId);
    setShowDeleteConfirmation(true);
  };

  const handleCloseDeleteRevenueStreamModal = () => {
    setRevenueStreamRecordIdForDelete(null);
    setShowDeleteConfirmation(false);
  };

  const data = useMemo(
    () => revenueStreamsList && formatData(revenueStreamsList),
    [revenueStreamsList],
  );

  const colDefs = useMemo(() => {
    return [
      {
        field: NAME,
        headerName: 'Revenue',
        headerComponent: HeaderRenderer,
        cellRendererSelector: CellRendererSelector,
        headerComponentParams: { enableExpandAll: true },
        cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: { suppressCount: true },
      },
      {
        colId: 'actions',
        type: 'actions',
        cellRendererSelector: ({ data: rowData }) => {
          if (!rowData.isEditable) return undefined;
          return {
            component: ContextMenuRenderer,
            params: {
              onEdit,
              onDelete,
              isDeleteDisabled: rowData.externalSource === REVENUE_STRIPE,
              hasWritePermission,
              family: revenueFamily.REVENUE_STREAM,
            },
          };
        },
      },
    ];
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [revenueStreamsList]);

  useGridRefreshCells({ gridApi, rowId: lastUpdatedRow?.id, columnId: NAME }, [
    lastUpdatedRow,
  ]);

  return (
    <div className="RevenueTable">
      <RevenueTitleBar heading="Revenue Streams (Drivers)" gridApi={gridApi} />
      {hasData ? (
        <>
          <MonthlySpreadsheet
            {...GRID_OPTIONS}
            ref={ref}
            columnDefs={colDefs}
            data-testid={SPREADSHEET_ID}
            data={data}
          />
          <RevenueStreamDelete
            showDeleteConfirmation={showDeleteConfirmation}
            setShowDeleteConfirmation={setShowDeleteConfirmation}
            recordId={revenueStreamRecordIdForDelete}
            handleClose={handleCloseDeleteRevenueStreamModal}
          />
        </>
      ) : (
        <EmptyData Icon={RevenueEmptyIcon}>
          Add Revenue Streams (Drivers) for this grid to populate.
        </EmptyData>
      )}
    </div>
  );
};

export default RevenueStreamsTable;
