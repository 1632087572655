// @ts-check
import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCompaniesOnLoginAction } from '@/actions/companies';
import { setScenarioIdAction } from '@/actions/scenario';
import CompanyDetail from '@/components/Settings/CompanyDetails';
import { JOIN_INTEGRATIONS_PATH } from '@/constants/pages';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import getSelectedCompanyId from '@/selectors/shared/getSelectedCompanyId';
import withProtectedRoute from './WithProtectedRoute';
import { routeProtections } from './constants';

/** Renders additional information fields in registration flow */

function AdditionalInformation() {
  const history = useHistory();
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const selectedCompanyId = useSelector(getSelectedCompanyId);
  const { defaultScenarioId } = useSelector(getSelectedCompany);
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);

  useEffect(() => {
    dispatch(setCompaniesOnLoginAction());
  }, [dispatch]);

  const onNext = () => {
    if (!scenarioId && defaultScenarioId) {
      dispatch(setScenarioIdAction(defaultScenarioId));
    }
    history.push(JOIN_INTEGRATIONS_PATH);
  };

  return (
    <>
      <h1 className="RegistrationHeading">Additional Information</h1>
      <p className="RegistrationText">
        Please provide us with the following mandatory information about your
        company before continuing
      </p>

      <div className="RegistrationCompanyDetails">
        <CompanyDetail
          mode="edit"
          companyId={selectedCompanyId}
          showIndustryAndAddress
          isCompanyRegistrationIncomplete
          saveButtonText="Next"
          hideStreetLineTwo
          onFinish={onNext}
        />
      </div>
    </>
  );
}

/** @type {(props: { authToken: string }) => React.ReactElement} */
const ProtectedAdditionalInformation = withProtectedRoute(
  AdditionalInformation,
  {
    protectedBy: routeProtections.AUTH_TOKEN,
  },
);

export default ProtectedAdditionalInformation;
