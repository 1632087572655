import { useEffect } from 'react';

const OVERFLOW_CLASS = 'Spreadsheet_Cell-overflow';

/**
 * Toggles a class with overflow: visible when the component mounts. Used to
 * show overlapping UI elements (e.g. drag handle, unit selector).
 *
 * @param {HTMLElement} cell
 */
function useCellOverflow(cell) {
  const { classList } = cell;
  if (!classList.contains(OVERFLOW_CLASS)) {
    setTimeout(() => cell.classList.add(OVERFLOW_CLASS), 0);
  }

  useEffect(() => {
    return () => cell.classList.remove(OVERFLOW_CLASS);
  }, [cell]);
}

export default useCellOverflow;
