// @ts-check
import { useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { clearReducers } from '@/actions/shared';
import wsApi from '@/services/WsApi';
import { resetAnalytics } from '@/services/analyticsService';

/**
 * Logout hook that clears reducers, disconnects websocket client and resets
 * analytics.
 *
 * @type {() => () => void}
 */
const useLogout = () => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  return useCallback(() => {
    wsApi.disconnect();
    dispatch(clearReducers());
    resetAnalytics();
  }, [dispatch]);
};

export default useLogout;
