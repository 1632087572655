// @ts-check
import { useState, useEffect, forwardRef } from 'react';
import DownArrowIcon from '@bill/cashflow.assets/down-arrow';
import PropTypes from 'prop-types';
import './Select.scss';

/**
 * @typedef {React.ComponentPropsWithRef<'select'> & {
 *   'value': string | number | boolean;
 *   'showErrors'?: boolean;
 *   'validate'?: (value: unknown) => unknown;
 *   'data-testid'?: string;
 * }} SelectProps
 */

/**
 * Creates a standard select dropdown with the given options as children, and
 * optional inline validation.
 *
 * @example
 *   <Select
 *     id="foo"
 *     value={bar}
 *     onChange={({ target }) => setBar(target.value)}
 *   />;
 *
 * @type {import('react').ForwardRefExoticComponent<SelectProps>}
 */
const Select = forwardRef(
  (
    {
      children,
      className = '',
      id,
      onBlur,
      showErrors,
      validate = () => {},
      value,
      'data-testid': dataTestId,
      disabled,
      ...props
    },
    ref,
  ) => {
    const [wasTouched, setTouched] = useState(false);
    const [error, setError] = useState(null);

    const errMsgId = `${id}-error`;

    useEffect(() => {
      // Don't show errors if the user has yet to interact with the field
      const errMsg = (wasTouched || showErrors) && validate(value);
      setError(errMsg);
    }, [showErrors, validate, value, wasTouched]);

    return (
      <div className="FormField" aria-disabled={disabled}>
        <select
          ref={ref}
          id={id}
          aria-invalid={Boolean(error)}
          aria-describedby={errMsgId}
          className={`FormField_Input FormField_Input-select ${className}`}
          value={value ?? ''}
          data-testid={dataTestId ?? id}
          disabled={disabled}
          onBlur={(event) => {
            setTouched(true);
            if (onBlur) onBlur(event);
          }}
          {...props}
        >
          {children}
        </select>
        <DownArrowIcon className="FormField_SelectArrow" aria-hidden="true" />
        {Boolean(error) && (
          <p id={errMsgId} className="FormField_Error" data-testid={errMsgId}>
            {error}
          </p>
        )}
      </div>
    );
  },
);

Select.propTypes = {
  /** Options for the select list */
  'children': PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
      ]),
    ),
  ]).isRequired,
  /** Additional class(es) to apply to the select element */
  'className': PropTypes.string,
  /** Whether the select element is disabled */
  'disabled': PropTypes.bool,
  /**
   * The ID of the select element, corresponding to the label's htmlFor
   * attribute
   */
  'id': PropTypes.string.isRequired,
  /**
   * Event handler for when the select has been defocused
   *
   * @param {Object} event
   */
  'onBlur': PropTypes.func,
  /**
   * Forces any validation errors to display, even if the user has not
   * interacted with the field yet
   */
  'showErrors': PropTypes.bool,
  /**
   * A function that returns an error message for the given value, or falsy
   *
   * @param {string} value
   */
  'validate': PropTypes.func,
  /** The value of the select */
  'value': PropTypes.any,
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string,
};

export default Select;
