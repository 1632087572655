// @ts-check
import { EXPENSES_PATH } from '@/constants/pages';
import Api, { ApiV2 } from './Api';
import getByDateRange from './getByDateRange';

/**
 * Gets the monthly expenses chart data for the current date range,
 *
 * @param {string} startDate First month of expense data
 * @param {string} endDate Last month of expense data
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @returns {Promise} API response
 */
export const getMonthlyChartData = getByDateRange('/expenses/monthlyExpenses');

/**
 * Gets the category chart data expenses for the current date range,
 *
 * @param {string} startDate First month of expense data
 * @param {string} endDate Last month of expense data
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @returns {Promise} API response
 */
export const getCategoryChartData = getByDateRange(
  '/expenses/expenseByExpenseClasses',
);

/**
 * Gets the expenses
 *
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @param {string} startDate First month of desired expenses
 * @param {string} endDate Last month of desired expenses
 * @returns {Promise} API response
 */
export const getPagedExpenses = (scenarioId, startDate, endDate) => {
  return ApiV2.get(EXPENSES_PATH, {
    params: { scenarioId, startDate, endDate },
  });
};

/**
 * Gets the payroll expenses
 *
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @param {string} startDate First month of desired payroll expenses data, in
 *   the format YYYY-MM
 * @param {string} endDate Last month of desired payroll expenses data, in the
 *   format YYYY-MM
 * @returns {Promise} API response
 */
export const getPayrollExpenses = (scenarioId, startDate, endDate) => {
  return ApiV2.get('/expenses/payroll', {
    params: { scenarioId, startDate, endDate },
  });
};

/**
 * Gets the expenses by department code
 *
 * @param {Object} params Param object
 * @param {number} params.scenarioId ID of the scenario containing the expenses
 * @param {number} params.departmentCode Department Code to get expense by
 *   department
 * @param {Date} params.startDate Start Date for expenses to be fetched
 * @param {Date} params.endDate End Date for expenses to be fetched
 * @returns {Promise} API response
 */
export const getExpensesListByDepartmentCode = (params) => {
  return ApiV2.get('/expenses/expensesByDepartmentCode', {
    params,
  });
};

/**
 * Gets the expenses classes
 *
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @param {boolean} formatDuplicates
 * @returns {Promise} API response
 */
export const getExpensesClasses = (scenarioId, formatDuplicates) => {
  return Api.get('/expenseClasses', {
    params: { scenarioId, formatDuplicates },
  });
};

/**
 * Gets the expenses sub categories
 *
 * @param {number} categoryId ID of the expense category
 * @returns {Promise} API response expense
 */
export const getExpensesSubCategories = (categoryId) => {
  return Api.get(`/expenseClasses/${categoryId}/subCategories`);
};

/**
 * Create expense
 *
 * @param {Object} data Data is the object that includes expense details
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @returns {Promise} API response
 */
export const createExpense = (data, scenarioId) => {
  return ApiV2.post(EXPENSES_PATH, data, { params: { scenarioId } });
};

/**
 * Update expense
 *
 * @param {Object} data Data is the object that includes expense details and id
 *   which needs to be updated
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @returns {Promise} API response
 */
export const updateExpense = (data, scenarioId) => {
  return ApiV2.put(`${EXPENSES_PATH}/${data.id}`, data, {
    params: { scenarioId },
  });
};

/**
 * Get single expense
 *
 * @param {string} id ID of the expense which needs to be retrieved
 * @param {number} scenarioId ID of the scenario containing the expense
 * @returns {Promise} API response
 */
export const getExpense = (id, scenarioId) => {
  return ApiV2.get(`${EXPENSES_PATH}/${id}`, { params: { scenarioId } });
};

/**
 * Update expense category
 *
 * @param {Object} data Data is the object that includes expenseId,
 *   expenseClassId & departmentId
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @returns {Promise} API response
 */
export const updateExpenseCategories = (data, scenarioId) => {
  return ApiV2.put('/expenses/assign/expenseClasses', data, {
    params: { scenarioId },
  });
};

/**
 * Delete expense
 *
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @param {number} id ID of the expense that is being deleted
 * @returns {Promise} API response
 */
export const deleteExpense = (scenarioId, id) => {
  return ApiV2.delete(`${EXPENSES_PATH}/${id}`, { params: { scenarioId } });
};

/**
 * Gets the monthly expenses for the current date range, broken down by
 * category.
 */
export const getExpenses = getByDateRange(
  '/expenses/monthlyExpensesByExpenseCategoryAndDepartment',
);

/**
 * Gets the monthly payroll for the current date range.
 *
 * @param {string} startDate First month of payroll data
 * @param {string} endDate Last month of payroll data
 * @param {number} scenarioId ID of the scenario containing the expenses
 * @returns {Promise} API response
 */
export const getPayroll = getByDateRange('/expenses/totalPayroll');

/**
 * Gets a preview of entries matching the given search criteria in the linked
 * expense platform
 *
 * @param {Object} data Parameters with which to look up expense entries
 * @param {number} scenarioId
 * @returns {Promise} API response
 */
export const getLinkedExpensePreview = (data, scenarioId) => {
  return ApiV2.post('/expenses/linked/preview', data, {
    params: { scenarioId },
  });
};

/**
 * Fetches an array of eligible expenses that can be used as an expense's
 * parent.
 *
 * @param {number} scenarioId ID of the scenario containing the expenses.
 * @param {string} expenseGroupId ID of the expense.
 * @param {string} departmentId Department ID of the department against which we
 *   are trying to get eligible parents.
 * @returns {Promise} API response
 */
export const getEligibleParentExpenses = (
  scenarioId,
  expenseGroupId,
  departmentId,
) => {
  return ApiV2.get('/expenses/eligible-parent-expenses', {
    params: {
      scenarioId,
      expenseGroupId,
      departmentId,
    },
  });
};

/**
 * Creates or updates one or more expenses in the given scenario
 *
 * @param {Object} params Param object
 * @param {Date} params.startDate First month/year selected in the global date
 *   picker
 * @param {Date} params.endDate Last month/year selected in the global date
 *   picker
 * @param {number} params.scenarioId ID of the scenario containing the
 *   expense(s)
 * @param {Object[]} params.expenses New or existing expenses to create/update
 * @returns {Promise} API response
 */
export const updateExpenses = ({ startDate, endDate, scenarioId, expenses }) =>
  ApiV2.post('/expenses/list', expenses, {
    params: { scenarioId, startDate, endDate },
  });

/**
 * Call the POST API to add multiple expenses record
 *
 * @param {Object} args
 * @param {File} args.file File that is uploaded
 * @param {Object} args.params Param object
 * @param {(progressEvent: any) => void} args.onUploadProgress Progress of the
 *   uploaded file
 * @returns {Promise} API response
 */
export const uploadMultipleExpenses = ({
  file,
  params = {},
  onUploadProgress,
}) => {
  const formData = new FormData();
  formData.append('file', file);
  const controller = new AbortController();
  const promise = ApiV2.post('/expenses/bulkAdd', formData, {
    params,
    headers: {
      'content-type': 'multipart/form-data',
    },
    onUploadProgress,
    signal: controller.signal,
  });
  // @ts-ignore
  promise.cancel = () => controller.abort();
  // @ts-ignore
  promise.signal = controller.signal;
  return promise;
};

/**
 * Gets an expense xls template for uploading/editing multiple expenses
 *
 * @param {number} scenarioId ID of the scenario for adding/editing expenses
 * @returns {Promise} API response
 */
export const getExpenseTemplate = (scenarioId) => {
  return ApiV2.get('/expenses/uploadTemplate', {
    params: { scenarioId },
    headers: { 'content-type': 'blob' },
    responseType: 'arraybuffer',
  });
};
