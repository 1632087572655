// @ts-check
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import ResetIcon from '@bill/cashflow.assets/reset';
import {
  getCashFlowActualsAction,
  subscribeToCashActualsValuesAction,
  updateActualsAction,
} from '@/actions/actuals';
import Checkbox from '@/components/common/Checkbox';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import {
  formulaMonthRendererSelector,
  handleAgGridPaste,
} from '@/components/common/MonthlySpreadsheet/helpers';
import useIconClassRules from '@/components/common/MonthlySpreadsheet/useIconClassRules';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetLegend from '@/components/common/Spreadsheet/SpreadsheetLegend';
import SpreadsheetLegendContent from '@/components/common/Spreadsheet/SpreadsheetLegendContent';
import SpreadsheetStatusBar from '@/components/common/Spreadsheet/SpreadsheetStatusBar';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import FormulaEditor from '@/components/common/Spreadsheet/editors/FormulaEditor';
import useRangeSelection from '@/components/common/Spreadsheet/hooks/useRangeSelection';
import CircularRefCellRenderer from '@/components/common/Spreadsheet/renderers/CircularRefCellRenderer';
import useUpdateQueue from '@/components/common/Spreadsheet/useUpdateQueue';
import {
  ACTUAL_ENDING_CASH,
  cashLegendCopy,
  EDITABLE_CASH_METRICS,
  EDITABLE_FUTURE_CASH_METRICS,
  RESET_ACTUAL_LABEL,
} from '@/constants/actuals';
import { MONTHLY } from '@/constants/dateTime';
import { registeredFeatureFlags } from '@/constants/features';
import { NO_COMPARISON } from '@/constants/scenario';
import { varianceText } from '@/constants/variables';
import detectCircularRef from '@/helpers/circularReference';
import { isEmptyOrNull } from '@/helpers/validators';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useWsSubscription from '@/hooks/useWsSubscription';
import LegendWarning from '@/pages/Actuals/LegendWarning';
import { valueFormatter } from '@/pages/Actuals/helpers';
import TooltipCellRenderer from './TooltipCellRenderer';
import './Cash.scss';

const lastMonth = new Date().setUTCDate(0);

/** @type {(colId: string) => boolean} */
const isActualMonth = (colId) => {
  const month = new Date(colId).getTime();
  return month <= lastMonth;
};

function valueGetter({ colDef, column, data: rowData }) {
  if (!rowData?.months) return null;

  const { value } =
    rowData.months.find(
      ({ date }) => date === column.getParent().getGroupId(),
    ) ?? {};
  return value?.find((val) => val.scenarioId === Number(colDef.field));
}

/**
 * @type {(
 *   queue: Object[],
 *   event: import('ag-grid-community').CellValueChangedEvent,
 * ) => Object[]}
 */
function addValueToQueue(queue, { colDef, column, data, newValue }) {
  const { displayFormula } = newValue ?? {};
  const payload = {
    yearMonth: column.getParent().getGroupId(),
    parentId: data.id,
    scenarioId: Number(colDef.field),
    type: data.family,
    displayFormula: !isEmptyOrNull(displayFormula) ? displayFormula : null,
  };
  return [...queue, payload];
}

/** @type {React.FC<any>} */
const CashLegend = (props) => (
  <SpreadsheetLegend {...props}>
    <SpreadsheetLegendContent
      content={cashLegendCopy}
      userEnteredTextOverride="Manual Adjustments"
    />
  </SpreadsheetLegend>
);
const SPREADSHEET_ID = 'cashTable';

const Cash = ({
  apiRef,
  startDate,
  endDate,
  scenarioId,
  compareScenarioId,
  cashFlowActuals,
  getCashFlowActuals,
  updateActuals,
  hasWritePermission,
  subscribeToCashActualsValues,
  timePeriod,
}) => {
  const [showVarianceAmount, setShowVarianceAmount] = useState(true);
  const [showVariancePercentage, setShowVariancePercentage] = useState(false);
  const [cellCount, setCellCount] = useState(0);
  const [selectedCells, setSelectedCells] = useState([]);
  const [cellSum, setCellSum] = useState(0);
  const [cellUnit, setCellUnit] = useState('');

  const isShowPercentVarianceEnabled = useFeatureFlags(
    registeredFeatureFlags.SHOW_VARIANCE_PERCENT,
  );

  const scenarioIds = useMemo(
    () =>
      [...new Set([scenarioId, compareScenarioId])].filter(
        (val) => val !== NO_COMPARISON,
      ),
    [scenarioId, compareScenarioId],
  );

  const cashFlowActualsData = useMemo(
    () => structuredClone(cashFlowActuals),
    [cashFlowActuals],
  );

  useEffect(() => {
    setCellCount(0);
    setSelectedCells([]);
    getCashFlowActuals({ startDate, endDate, scenarioIds, timePeriod });
  }, [getCashFlowActuals, startDate, endDate, scenarioIds, timePeriod]);

  useWsSubscription(
    () => subscribeToCashActualsValues(scenarioId),
    [scenarioId],
  );

  useWsSubscription(() => {
    if (compareScenarioId === NO_COMPARISON) return null;
    return subscribeToCashActualsValues(compareScenarioId);
  }, [compareScenarioId]);

  /**
   * @type {(params: {
   *   column: import('ag-grid-community').Column;
   *   data: Object;
   * }) => boolean}
   */
  const isEditable = useCallback(
    ({ column, data }) =>
      hasWritePermission &&
      timePeriod === MONTHLY &&
      (!data || EDITABLE_CASH_METRICS.includes(data.title)) &&
      (isActualMonth(column.getParent().getGroupId()) ||
        EDITABLE_FUTURE_CASH_METRICS.includes(data.title)),
    [hasWritePermission, timePeriod],
  );

  const cellRendererSelector = useCallback(
    (props) => {
      const isCircularRef = detectCircularRef(props.value);

      if (isCircularRef) {
        return { component: CircularRefCellRenderer };
      }

      const nextColumn = apiRef.current?.columnApi.getDisplayedColAfter(
        props.column,
      );
      const isNextCellEditable =
        !!nextColumn &&
        isEditable({
          column: nextColumn,
          data: props.data,
        });

      return isNextCellEditable && props.data.title === ACTUAL_ENDING_CASH
        ? formulaMonthRendererSelector(props)
        : undefined;
    },
    [isEditable, apiRef],
  );

  const updateBulkCash = useCallback(
    (queue) => updateActuals(scenarioIds, queue),
    [scenarioIds, updateActuals],
  );

  const handleMonthValueChange = useUpdateQueue(
    addValueToQueue,
    updateBulkCash,
  );

  const colDefs = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Metrics',
        cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
        cellRenderer: TooltipCellRenderer,
        width: 246,
      },
    ],
    [],
  );

  const iconClassRules = useIconClassRules(timePeriod);

  const handleRangeSelection = useRangeSelection(
    setSelectedCells,
    setCellCount,
    setCellUnit,
    setCellSum,
  );

  const handleResetActuals = useCallback(() => {
    selectedCells.forEach(({ colDef, node }) =>
      node.setDataValue(colDef.colId, { displayFormula: null }),
    );
    setSelectedCells([]);
    setCellCount(0);
  }, [selectedCells, setSelectedCells, setCellCount]);

  let Legend = CashLegend;
  if (timePeriod !== MONTHLY) {
    Legend = LegendWarning;
  }

  const editorParams = useCallback(
    ({ column, data }) => ({
      'data-testid': `${data.id}-${column.colId}`,
      'unit': data.unit,
    }),
    [],
  );

  const rendererParams = useCallback(
    ({ colDef, data }) => ({
      'data-testid': `${data.id}-${colDef.colId}`,
      'onFillRightClick': ({ displayFormula, month }) => {
        const payload = {
          displayFormula,
          scenarioId,
          fillRight: true,
          parentId: data.id,
          type: data.family,
          yearMonth: month,
        };
        updateActuals([scenarioId], [payload]);
      },
    }),
    [scenarioId, updateActuals],
  );

  const hasComparison = compareScenarioId !== NO_COMPARISON;

  return (
    <>
      <SpreadsheetToolbar Legend={Legend}>
        <div className="SpreadsheetToolbar_ControlGroup">
          Options:
          <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
        </div>
        {hasComparison && (
          <>
            <Checkbox
              id="variance-toggle"
              checked={showVarianceAmount}
              className="SpreadsheetToolbar_ControlGroup"
              onChange={() => setShowVarianceAmount((prevState) => !prevState)}
            >
              {varianceText.SHOW_VARIANCE_AMOUNT}
            </Checkbox>
            {isShowPercentVarianceEnabled && (
              <Checkbox
                id="variance-toggle"
                checked={showVariancePercentage}
                className="SpreadsheetToolbar_ControlGroup"
                onChange={() =>
                  setShowVariancePercentage((prevState) => !prevState)
                }
              >
                {varianceText.SHOW_VARIANCE_PERCENTAGE}
              </Checkbox>
            )}
          </>
        )}
      </SpreadsheetToolbar>
      <MonthlySpreadsheet
        ref={apiRef}
        cellClassRules={iconClassRules}
        columnDefs={colDefs}
        data={cashFlowActualsData}
        data-testid={SPREADSHEET_ID}
        editable={isEditable}
        editor={FormulaEditor}
        editorParams={editorParams}
        onMonthValueChange={handleMonthValueChange}
        valueFormatter={valueFormatter}
        valueGetter={valueGetter}
        enableComparison
        rendererSelector={cellRendererSelector}
        rendererParams={rendererParams}
        showVarianceAmount={showVarianceAmount}
        showVariancePercentage={showVariancePercentage}
        onRangeSelectionChanged={handleRangeSelection}
        processCellFromClipboard={handleAgGridPaste}
      />
      {cellCount > 0 && (
        <SpreadsheetStatusBar
          cellCount={cellCount}
          cellSum={cellSum}
          cellUnit={cellUnit}
        >
          {!!selectedCells.length && hasWritePermission && (
            <button
              type="button"
              onClick={handleResetActuals}
              className="ResetContainer"
            >
              <ResetIcon className="ResetIcon" />
              {RESET_ACTUAL_LABEL}
            </button>
          )}
        </SpreadsheetStatusBar>
      )}
    </>
  );
};

const mapStateToProps = ({ shared, scenario, actuals }) => ({
  startDate: shared.startDate,
  endDate: shared.endDate,
  scenarioId: scenario.scenarioId,
  compareScenarioId: scenario.compareScenarioId,
  cashFlowActuals: actuals.cashFlowActuals,
  timePeriod: shared.timePeriod,
});

export default connect(mapStateToProps, {
  getCashFlowActuals: getCashFlowActualsAction,
  updateActuals: updateActualsAction,
  subscribeToCashActualsValues: subscribeToCashActualsValuesAction,
})(Cash);
