/* eslint-disable no-console -- predates description requirement */
import { SET_SUBSCRIPTION_EXPIRED } from '@/actionTypes/subscriptions';
import { changeLoadingState } from '@/actions/componentLoading';
import { getExpiredStatus } from '@/services/subscriptions.service';

const IS_EXPIRED = 'isExpired';

// eslint-disable-next-line import/prefer-default-export -- predates description requirement
export const getExpiredStatusAction = () => {
  return async (dispatch) => {
    dispatch(changeLoadingState(IS_EXPIRED, true));
    try {
      const response = await getExpiredStatus();
      const isExpired = response.data?.data;
      dispatch({
        type: SET_SUBSCRIPTION_EXPIRED,
        payload: isExpired,
      });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(changeLoadingState(IS_EXPIRED, false));
    }
  };
};
