import MinusIcon from '@bill/cashflow.assets/minus';
import PlusIcon from '@bill/cashflow.assets/plus';
import PropTypes from 'prop-types';
import './IncrementableField.scss';

/**
 * Creates an input for numbers with accompanying buttons to increase/decrease
 * the value.
 *
 * @example
 *   <IncrementableField
 *     id="foo"
 *     value={bar}
 *     onUpdate={(number) => setBar(number)}
 *   />;
 */
function IncrementableField({
  className = '',
  disabled,
  id,
  onUpdate,
  value,
  minValue = 0,
  ...props
}) {
  const numericValue = Number(value);

  const handleChange = ({ target }) => {
    if (Number(target.value) < 0) {
      return;
    }
    const formattedValue =
      target.value.trim() === '' ? '' : Math.trunc(target.value);
    onUpdate(formattedValue);
  };
  return (
    <div className="FormField FormField-incrementable">
      <button
        type="button"
        className="IncrementableField_Button"
        aria-label="Decrease"
        disabled={disabled || numericValue <= minValue}
        onClick={() => onUpdate(numericValue - 1)}
        data-testid={`${id}-decrease`}
      >
        <MinusIcon className="ButtonIcon" aria-hidden="true" />
      </button>
      <input
        type="number"
        id={id}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        className={`FormField_Input FormField_Input-incrementable ${className}`}
        data-testid={id}
        {...props}
      />
      <button
        type="button"
        className="IncrementableField_Button"
        aria-label="Increase"
        disabled={disabled}
        onClick={() => onUpdate(numericValue + 1)}
        data-testid={`${id}-increase`}
      >
        <PlusIcon className="ButtonIcon" aria-hidden="true" />
      </button>
    </div>
  );
}

IncrementableField.propTypes = {
  /** Additional class(es) to apply to the input element */
  className: PropTypes.string,
  /** Whether or not the field is disabled */
  disabled: PropTypes.bool,
  /** The ID of the input element, corresponding to the label's htmlFor attribute */
  id: PropTypes.string.isRequired,
  /**
   * Event handler for value changes, either via the input element or the
   * increase/decrease buttons
   *
   * @param {number} value the new value of the field
   */
  onUpdate: PropTypes.func.isRequired,
  /** The value of the input */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** The minimum value of the input */
  minValue: PropTypes.number,
};

export default IncrementableField;
