import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import MinusFlatIcon from '@bill/cashflow.assets/minus-flat';
import PlusFlatIcon from '@bill/cashflow.assets/plus-flat';
import { AnimatePresence, m as motion } from 'framer-motion';
import AdvancedRevenueFormulaField from '@/components/Revenue/AdvancedRevenueFormulaField';
import RevenueStreamFooter from '@/components/Revenue/RevenueStream/RevenueStreamFooter';
import Button from '@/components/common/Button';
import FormLabel from '@/components/common/FormLabel';
import MonthField from '@/components/common/MonthField';
import VALID_FORMULA_MSG from '@/constants/formulas';
import { SUBSCRIPTION_ADVANCED } from '@/constants/revenueStream';
import { EDIT } from '@/constants/scenario';
import { getNextText } from '@/helpers';
import {
  getCurrentDayTimestamp,
  getDateOffsetByMonths,
  MAX_DATE,
} from '@/helpers/dateFormatter';
import { isEmptyOrNull, isEndDateBeforeStartDate } from '@/helpers/validators';
import { createOrUpdateAdvanceRevenueStream } from '@/services/revenueService';
import './StepThreeSubscriptionRevenue.scss';

const TRANSITION = {
  type: 'tween',
  duration: 0.5,
};
const COLLAPSIBLE_OPEN = {
  height: 'auto',
  opacity: 1,
};
const COLLAPSIBLE_CLOSED = {
  height: 0,
  opacity: 0,
};

const initialRevenueStream = {
  startDate: getCurrentDayTimestamp(),
  endDate: null,
  newMrrDisplayFormula: '',
  churnedMrrDisplayFormula: null,
  newCustomersDisplayFormula: null,
  churnedCustomersDisplayFormula: null,
  expansionMrrDisplayFormula: null,
  contractionMrrDisplayFormula: null,
  adjustmentsToMrrDisplayFormula: null,
  expansionCustomersDisplayFormula: null,
  contractionCustomersDisplayFormula: null,
  adjustmentsToCustomersDisplayFormula: null,
};

const getInitialValidationState = (mode) => ({
  isNewMrrFormulaValid: mode === EDIT,
  isChurnMrrFormulaValid: true,
  isNewCustomersFormulaValid: true,
  isChurnCustomersFormulaValid: true,
  isExpansionMrrFormulaValid: true,
  isContractionMrrFormulaValid: true,
  isAdjustmentsToMrrFormulaValid: true,
  isExpansionCustomersFormulaValid: true,
  isContractionCustomersFormulaValid: true,
  isAdjustmentsToCustomersFormulaValid: true,
});

const validateForm = ({
  startDate,
  endDate,
  isNewMrrFormulaValid,
  isChurnMrrFormulaValid,
  isNewCustomersFormulaValid,
  isChurnCustomersFormulaValid,
  isExpansionMrrFormulaValid,
  isContractionMrrFormulaValid,
  isAdjustmentsToMrrFormulaValid,
  isExpansionCustomersFormulaValid,
  isContractionCustomersFormulaValid,
  isAdjustmentsToCustomersFormulaValid,
}) => {
  return (
    isNewMrrFormulaValid &&
    isChurnMrrFormulaValid &&
    isChurnCustomersFormulaValid &&
    isNewCustomersFormulaValid &&
    isExpansionMrrFormulaValid &&
    isContractionMrrFormulaValid &&
    isAdjustmentsToMrrFormulaValid &&
    isExpansionCustomersFormulaValid &&
    isContractionCustomersFormulaValid &&
    isAdjustmentsToCustomersFormulaValid &&
    !isEmptyOrNull(startDate) &&
    !isEndDateBeforeStartDate(startDate + 1, endDate)
  );
};

const ExpandIconWithText = ({ isExpanded, children }) => {
  const Icon = isExpanded ? MinusFlatIcon : PlusFlatIcon;
  return (
    <strong>
      <Icon className="SubscriptionRevenue_ExpandIcon" />
      {children}
    </strong>
  );
};

const StepThreeSubscriptionRevenue = ({
  mode,
  setStep,
  step,
  handleClose,
  revenue,
  revenueStream,
  scenarioId,
  globalStartDate,
  globalEndDate,
  onSaved,
}) => {
  const initialValidationMsg = useMemo(
    () => (mode === EDIT ? VALID_FORMULA_MSG : ''),
    [mode],
  );
  const { plansAssociated, revenueName } = revenueStream;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [formState, setFormState] = useState(
    mode === EDIT ? revenueStream : initialRevenueStream,
  );

  const updateFormState = (newState) => {
    setFormState((prevState) => ({ ...prevState, ...newState }));
  };
  const [formValidation, setFormValidation] = useState(
    getInitialValidationState(mode),
  );

  const updateFormValidation = (newState) => {
    setFormValidation((prevState) => ({ ...prevState, ...newState }));
  };

  const [isMrrOptionsExpanded, setIsMrrOptionsExpanded] = useState(true);
  const [isCustomerOptionsExpanded, setIsCustomerOptionsExpanded] =
    useState(false);

  const handleSubmit = async () => {
    const params = {
      globalStartDate,
      globalEndDate,
      scenarioId,
      revenue,
      revenueStream: {
        ...formState,
        revenueName,
        plansAssociated,
      },
      revenueAttributionType: SUBSCRIPTION_ADVANCED,
    };
    try {
      setLoading(true);
      await createOrUpdateAdvanceRevenueStream(params);
      setError('');
      setLoading(false);
      onSaved?.();
      handleClose();
    } catch (e) {
      setLoading(false);
      setError(e.response?.data?.error?.errorMessage || e.message);
    }
  };

  return (
    <div className="SubscriptionRevenue_Form">
      {error && (
        <p className="SubscriptionRevenue_ErrorAlert">
          <b>Error </b> - {error}
        </p>
      )}
      <div className="Form">
        <div className="Form_Group Form_Group-halfWidth">
          <FormLabel htmlFor="startDate" text="Start Date" />
          <MonthField
            id="startDate"
            value={formState.startDate}
            max={MAX_DATE}
            validate={(dateStr) => !dateStr && 'Please enter a valid date'}
            onChange={(date) => {
              updateFormState({ startDate: date });
            }}
          />
        </div>
        <div className="Form_Group Form_Group-halfWidth">
          <FormLabel htmlFor="endDate" text="End Date" optional />
          <MonthField
            id="endDate"
            value={formState.endDate}
            min={getDateOffsetByMonths(formState.startDate, 1).getTime()}
            onChange={(date) => {
              updateFormState({ endDate: date });
            }}
          />
        </div>
        <div className="Form_Group">
          <AdvancedRevenueFormulaField
            id="new-mrr-display-formula"
            value={formState.newMrrDisplayFormula}
            onValidation={(isValid) => {
              updateFormValidation({ isNewMrrFormulaValid: isValid });
            }}
            onChange={(formula) => {
              updateFormState({ newMrrDisplayFormula: formula });
            }}
            text="New MRR"
            showAsCurrency
            initialValidationMsg={initialValidationMsg}
            showVariablesLink
          />
        </div>
        <div className="SubscriptionRevenue_ExpandBtn">
          <Button
            data-testid="expand-mrr-options-button"
            className="Button-primaryLink"
            onClick={() =>
              setIsMrrOptionsExpanded((currentState) => !currentState)
            }
          >
            <ExpandIconWithText isExpanded={isMrrOptionsExpanded}>
              MRR: Additional Options
            </ExpandIconWithText>
          </Button>
        </div>
        <motion.div
          className="Form_Group"
          animate={isMrrOptionsExpanded ? COLLAPSIBLE_OPEN : COLLAPSIBLE_CLOSED}
          initial={isMrrOptionsExpanded ? COLLAPSIBLE_CLOSED : COLLAPSIBLE_OPEN}
          transition={TRANSITION}
        >
          <AnimatePresence>
            {isMrrOptionsExpanded && (
              <motion.div exit={{ opacity: 0 }}>
                <div className="Form_Group">
                  <AdvancedRevenueFormulaField
                    id="expansion-mrr-display-formula"
                    value={formState.expansionMrrDisplayFormula ?? ''}
                    onValidation={(isValid) => {
                      updateFormValidation({
                        isExpansionMrrFormulaValid: isValid,
                      });
                    }}
                    onChange={(formula) => {
                      updateFormState({
                        expansionMrrDisplayFormula: formula || null,
                      });
                    }}
                    text="Expansion MRR"
                    optional
                    showAsCurrency
                    initialValidationMsg={initialValidationMsg}
                  />
                </div>
                <div className="Form_Group">
                  <AdvancedRevenueFormulaField
                    id="contraction-mrr-display-formula"
                    value={formState.contractionMrrDisplayFormula ?? ''}
                    onValidation={(isValid) => {
                      updateFormValidation({
                        isContractionMrrFormulaValid: isValid,
                      });
                    }}
                    onChange={(formula) => {
                      updateFormState({
                        contractionMrrDisplayFormula: formula || null,
                      });
                    }}
                    text="Contraction MRR"
                    optional
                    showAsCurrency
                    initialValidationMsg={initialValidationMsg}
                  />
                </div>
                <div className="Form_Group">
                  <AdvancedRevenueFormulaField
                    id="churn-mrr-display-formula"
                    value={formState.churnedMrrDisplayFormula ?? ''}
                    onValidation={(isValid) => {
                      updateFormValidation({ isChurnMrrFormulaValid: isValid });
                    }}
                    onChange={(formula) => {
                      updateFormState({
                        churnedMrrDisplayFormula: formula || null,
                      });
                    }}
                    text="Churned MRR"
                    optional
                    showAsCurrency
                    initialValidationMsg={initialValidationMsg}
                  />
                </div>
                <div className="Form_Group">
                  <AdvancedRevenueFormulaField
                    id="adjustments-to-mrr-display-formula"
                    value={formState.adjustmentsToMrrDisplayFormula ?? ''}
                    onValidation={(isValid) => {
                      updateFormValidation({
                        isAdjustmentsToMrrFormulaValid: isValid,
                      });
                    }}
                    onChange={(formula) => {
                      updateFormState({
                        adjustmentsToMrrDisplayFormula: formula || null,
                      });
                    }}
                    text="Adjustments to MRR"
                    optional
                    showAsCurrency
                    initialValidationMsg={initialValidationMsg}
                  />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
        <div className="SubscriptionRevenue_ExpandBtn">
          <Button
            data-testid="expand-customer-options-button"
            className="Button-primaryLink"
            onClick={() =>
              setIsCustomerOptionsExpanded((currentState) => !currentState)
            }
          >
            <ExpandIconWithText isExpanded={isCustomerOptionsExpanded}>
              Customers: Additional Options
            </ExpandIconWithText>
          </Button>
        </div>
        <motion.div
          className="Form_Group"
          animate={
            isCustomerOptionsExpanded ? COLLAPSIBLE_OPEN : COLLAPSIBLE_CLOSED
          }
          initial={
            isCustomerOptionsExpanded ? COLLAPSIBLE_CLOSED : COLLAPSIBLE_OPEN
          }
          transition={TRANSITION}
        >
          <AnimatePresence>
            {isCustomerOptionsExpanded && (
              <motion.div exit={{ opacity: 0 }}>
                <div className="Form_Group">
                  <AdvancedRevenueFormulaField
                    id="new-customer-display-formula"
                    value={formState.newCustomersDisplayFormula ?? ''}
                    onValidation={(isValid) => {
                      updateFormValidation({
                        isNewCustomersFormulaValid: isValid,
                      });
                    }}
                    onChange={(formula) => {
                      updateFormState({
                        newCustomersDisplayFormula: formula || null,
                      });
                    }}
                    text="New Customers"
                    optional
                    initialValidationMsg={initialValidationMsg}
                  />
                </div>
                <div className="Form_Group">
                  <AdvancedRevenueFormulaField
                    id="expansion-customers-display-formula"
                    value={formState.expansionCustomersDisplayFormula ?? ''}
                    onValidation={(isValid) => {
                      updateFormValidation({
                        isExpansionCustomersFormulaValid: isValid,
                      });
                    }}
                    onChange={(formula) => {
                      updateFormState({
                        expansionCustomersDisplayFormula: formula || null,
                      });
                    }}
                    text="Expansion Customers"
                    optional
                    initialValidationMsg={initialValidationMsg}
                  />
                </div>
                <div className="Form_Group">
                  <AdvancedRevenueFormulaField
                    id="contraction-customers-display-formula"
                    value={formState.contractionCustomersDisplayFormula ?? ''}
                    onValidation={(isValid) => {
                      updateFormValidation({
                        isContractionCustomersFormulaValid: isValid,
                      });
                    }}
                    onChange={(formula) => {
                      updateFormState({
                        contractionCustomersDisplayFormula: formula || null,
                      });
                    }}
                    text="Contraction Customers"
                    optional
                    initialValidationMsg={initialValidationMsg}
                  />
                </div>
                <div className="Form_Group">
                  <AdvancedRevenueFormulaField
                    id="churned-customer-display-formula"
                    value={formState.churnedCustomersDisplayFormula ?? ''}
                    onValidation={(isValid) => {
                      updateFormValidation({
                        isChurnCustomersFormulaValid: isValid,
                      });
                    }}
                    onChange={(formula) => {
                      updateFormState({
                        churnedCustomersDisplayFormula: formula || null,
                      });
                    }}
                    text="Churned Customers"
                    optional
                    initialValidationMsg={initialValidationMsg}
                  />
                </div>
                <div className="Form_Group">
                  <AdvancedRevenueFormulaField
                    id="adjustments-to-customers-display-formula"
                    value={formState.adjustmentsToCustomersDisplayFormula ?? ''}
                    onValidation={(isValid) => {
                      updateFormValidation({
                        isAdjustmentsToCustomersFormulaValid: isValid,
                      });
                    }}
                    onChange={(formula) => {
                      updateFormState({
                        adjustmentsToCustomersDisplayFormula: formula || null,
                      });
                    }}
                    text="Adjustments to Customers"
                    optional
                    initialValidationMsg={initialValidationMsg}
                  />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>

      <RevenueStreamFooter
        setStep={setStep}
        step={step}
        nextLoading={loading}
        disablePrevious
        disableNext={
          !validateForm({
            ...formState,
            ...formValidation,
          })
        }
        revenueStream={revenueStream}
        handleClose={() => handleClose()}
        handleNext={handleSubmit}
        nextText={getNextText(mode)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  revenueStream: state.revenues.revenueStream,
  scenarioId: state.scenario.scenarioId,
  globalStartDate: state.shared.startDate,
  globalEndDate: state.shared.endDate,
  scenario: state.scenario,
});

export default connect(mapStateToProps)(StepThreeSubscriptionRevenue);
