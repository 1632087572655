import { units } from '@/constants/variables';
import convertDecimalToPercent from '@/helpers/convertDecimalToPercent';
import formatDecimalNumber from '@/helpers/formatDecimalNumber';
import { isNumber } from '@/helpers/index';

/**
 * A function that takes a decimal number and it's unit type. If the unit is
 * percentage then it converts the decimal to percentage and returns the
 * formatted output.
 *
 * @param {string | number} value the value to be formatted
 * @param {string} unit the unit type "Percentage"|"Number"
 * @returns {string | number} Number formatted
 */
export function formatValueForDisplay(value, unit) {
  if (!value && !isNumber(value)) return null;

  return isNumber(value) && unit === units.PERCENTAGE
    ? convertDecimalToPercent(value).toString()
    : value;
}

/**
 * A function that takes a number and it's unit type. If the unit type is
 * 'percentage' then it converts the value into it's respective decimal
 * representation.
 *
 * @param {string | number} value the value to be formatted
 * @param {string} unit the unit type "Percentage"|"Number"
 * @returns {string | number} Number formatted
 */
export function formatValueForSave(value, unit) {
  if (!value) return null;

  const trimmedVal = value.trim();
  return isNumber(value) && unit === 'Percentage'
    ? formatDecimalNumber(Number(value) / 100, 4)
    : trimmedVal;
}
