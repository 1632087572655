import {
  cloneElement,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from 'react';
import TooltipArrowIcon from '@bill/cashflow.assets/tooltip-arrow';
import {
  arrow,
  autoPlacement,
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports -- predates description requirement
import Overlay, { placements } from '@/components/common/Overlay';
import { classNames } from '@/helpers';
import './WithTooltip.scss';

const AUTO = 'auto';

/**
 * @typedef {{
 *   'className'?: string;
 *   'children': React.ReactElement;
 *   'content': React.ReactNode;
 *   'data-testid'?: string;
 *   'placement'?: string;
 *   'disabled'?: boolean;
 *   'trigger'?: string;
 * }} WithTooltipProps
 */

/**
 * Renders a tooltip when hovering over the given children
 *
 * @example
 *   <WithTooltip content="I'm a tooltip!" data-testid="foo">
 *     <button>Hover me!</button>
 *   </WithTooltip>;
 *
 * @type {(props: WithTooltipProps) => React.ReactElement}
 */
function WithTooltip({
  children,
  className,
  content,
  'data-testid': dataTestId,
  placement = AUTO,
  disabled = false,
  ...props
}) {
  const arrowRef = useRef(null);
  const [open, setOpen] = useState(false);
  const {
    x,
    y,
    context,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    placement: adjPlacement,
    reference,
    refs,
    floating,
    strategy,
  } = useFloating({
    open,
    onOpenChange: setOpen,
    placement,
    middleware: [
      offset(10),
      placement === AUTO ? autoPlacement() : flip(),
      shift({ padding: 10 }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const { getFloatingProps, getReferenceProps } = useInteractions([
    useFocus(context, { keyboardOnly: true }),
    useHover(context),
  ]);

  // Handle refs attached to the trigger element by the parent
  useLayoutEffect(() => {
    const { ref } = children;
    if (ref) ref.current = refs.reference.current;
  }, [children, refs]);

  useEffect(() => {
    if (disabled) {
      setOpen(false);
    }
  }, [disabled]);

  return (
    <>
      {cloneElement(children, getReferenceProps({ ref: reference }))}
      <Overlay
        ref={floating}
        className={classNames('Tooltip', className)}
        data-testid={dataTestId}
        open={open}
        placement={adjPlacement}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        }}
        {...getFloatingProps()}
        {...props}
      >
        {content}
        <span
          ref={arrowRef}
          className="Tooltip_Arrow"
          style={{ top: arrowY, left: arrowX }}
        >
          <TooltipArrowIcon className="Tooltip_ArrowIcon" />
        </span>
      </Overlay>
    </>
  );
}

WithTooltip.propTypes = {
  /** Element that should trigger the tooltip */
  'children': PropTypes.node.isRequired,
  /** Additional class(es) to apply to the tooltip */
  'className': PropTypes.string,
  /** Content for the tooltip */
  'content': PropTypes.node.isRequired,
  /** Unique identifier for selecting the tooltip in unit/integration tests */
  'data-testid': PropTypes.string,
  /** Whether the item the tooltip is attached to is disabled */
  'disabled': PropTypes.bool,
  /**
   * Position of the tooltip relative to its trigger. Defaults to 'auto':
   * dynamic placement determined by Floating UI.
   */
  'placement': PropTypes.oneOf([AUTO, ...Object.values(placements)]),
  /** Trigger for when to show tooltip */
  'trigger': PropTypes.string,
};

export default WithTooltip;
