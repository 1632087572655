// @ts-check
import PlusIcon from '@bill/cashflow.assets/plus';
import { classNames } from '@/helpers';
import './PlusButton.scss';

/**
 * @typedef {{
 *   'data-testid'?: string;
 *   'children': React.ReactNode;
 *   'onAdd': () => void;
 *   'disabled'?: boolean;
 * }} PlusButtonProps
 */

/**
 * A plus button used for adding new items
 *
 * @example
 *   <PlusButton onAdd={() => {}} data-testid="foo">
 *     Add an item
 *   </PlusButton>;
 *
 *   /** @type {(props: PlusButtonProps) => React.ReactElement}
 */
const PlusButton = ({
  onAdd,
  children,
  'data-testid': dataTestId,
  disabled,
}) => {
  return (
    <button
      type="button"
      className={classNames('PlusButton', disabled && 'PlusButton-disabled')}
      onClick={onAdd}
      data-testid={dataTestId}
      disabled={disabled}
    >
      <PlusIcon className="PlusButton_Icon" aria-hidden="true" />
      <span className="PlusButton_Label">{children}</span>
    </button>
  );
};

export default PlusButton;
