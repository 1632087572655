export const SET_COMPANY_SETTINGS_SUCCESS =
  'settings/SET_COMPANY_SETTINGS_SUCCESS';
export const SET_COMPANY_SETTINGS_ERROR = 'settings/SET_COMPANY_SETTINGS_ERROR';
export const SET_COMPANY_SETTINGS_LOADING =
  'settings/SET_COMPANY_SETTINGS_LOADING';
export const SET_USER_SETTINGS_SUCCESS = 'settings/SET_USER_SETTINGS_SUCCESS';
export const SET_USER_SETTINGS_ERROR = 'settings/SET_USER_SETTINGS_ERROR';
export const SET_USER_SETTINGS_LOADING = 'settings/SET_USER_SETTINGS_LOADING';
export const HANDLE_DATA_CHANGE = 'settings/HANDLE_DATA_CHANGE';
export const SET_LOADING = 'settings/SET_LOADING';
export const SET_INTEGRATIONS_STATUS_SUCCESS =
  'settings/SET_INTEGRATIONS_STATUS_SUCCESS';
export const SET_INTEGRATIONS_STATUS_ERROR =
  'settings/SET_INTEGRATIONS_STATUS_ERROR';
export const SET_CONNECTION_ERROR = 'settings/SET_CONNECTION_ERROR';
export const USER_UPDATE_SUCCESS = 'settings/USER_UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'settings/USER_UPDATE_ERROR';
export const CHANGE_USER_PASSWORD_SUCCESS =
  'settings/CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_ERROR = 'settings/CHANGE_USER_PASSWORD_ERROR';
export const CHANGE_USER_PASSWORD_LOADING =
  'settings/CHANGE_USER_PASSWORD_LOADING';
export const UPDATE_COMPANY_SUCCESS = 'settings/UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_ERROR = 'settings/UPDATE_COMPANY_ERROR';
export const STRIPE_SESSION_SUCCESS = 'settings/STRIPE_SESSION_SUCCESS';
export const STRIPE_SESSION_ERROR = 'settings/STRIPE_SESSION_ERROR';
export const LOAD_MULTIPLIER_LOADING = 'settings/LOAD_MULTIPLIER_LOADING';
export const LOAD_MULTIPLIER_ERROR = 'settings/LOAD_MULTIPLIER_ERROR';
export const SET_SHARE_COMPANY_ERROR = 'settings/SET_SHARE_COMPANY_ERROR';
export const CHANGE_SHARE_COMPANY_LOADING =
  'settings/CHANGE_SHARE_COMPANY_LOADING';
export const RESET_SHARE_COMPANY_ERROR = 'settings/RESET_SHARE_COMPANY_ERROR';
export const SET_SHARE_COMPANY_SUCCESS = 'settings/SET_SHARE_COMPANY_SUCCESS';
export const MEMBER_LIST_SUCCESS = 'settings/MEMBER_LIST_SUCCESS';
export const MEMBER_REMOVE_SUCCESS = 'settings/MEMBER_REMOVE_SUCCESS';
export const MEMBER_REMOVE_LOADING = 'settings/MEMBER_REMOVE_LOADING';
export const MEMBER_DISABLE_INVITE_SUCCESS =
  'settings/MEMBER_DISABLE_INVITE_SUCCESS';
export const MEMBER_DISABLE_INVITE_LOADING =
  'settings/MEMBER_DISABLE_INVITE_LOADING';
export const MEMBER_DISABLE_INVITE_ERROR =
  'settings/MEMBER_DISABLE_INVITE_ERROR';
export const MEMBER_DISABLE_INVITE_RESET =
  'settings/MEMBER_DISABLE_INVITE_RESET';
export const MEMBER_INFO_SUCCESS = 'settings/MEMBER_INFO_SUCCESS';
export const MEMBER_INFO_RESET = 'settings/MEMBER_INFO_RESET';
export const SET_PAYMENT_PLANS = 'settings/SET_PAYMENT_PLANS';
export const COLLECTION_TERMS_SUCCESS = 'settings/COLLECTION_TERMS_SUCCESS';
export const COLLECTION_TERMS_ERROR = 'settings/COLLECTION_TERMS_ERROR';
export const PAYMENT_TERMS_SUCCESS = 'settings/PAYMENT_TERMS_SUCCESS';
export const PAYMENT_TERMS_ERROR = 'settings/PAYMENT_TERMS_ERROR';
export const INTEGRATION_DATA_SYNC_STARTED =
  'settings/INTEGRATION_DATA_SYNC_STARTED';
export const INTEGRATION_DATA_SYNC_COMPLETED =
  'settings/INTEGRATION_DATA_SYNC_COMPLETED';
