// @ts-check

/**
 * @typedef {{
 *   remoteEntryUrl: string;
 *   scope: string;
 *   exposedModule: string;
 * }} RemoteModule
 */

/**
 * @typedef {{
 *   init: (shareScope: unknown) => Promise<void>;
 *   get: (module: string) => Promise<() => unknown>;
 * }} Container
 */

/**
 * @type {(
 *   scope: RemoteModule['scope'],
 *   module: RemoteModule['exposedModule'],
 * ) => () => Promise<import('single-spa').ParcelConfigObject>}
 */
const loadModuleFromScope = (scope, module) => {
  return async () => {
    // Initializes the share scope. This fills it with known provided modules from this build and all remotes
    // @ts-ignore
    // eslint-disable-next-line no-undef -- required
    await __webpack_init_sharing__('default');

    /** @type {Container} */
    const container = window[scope]; // or get the container somewhere else
    // Initialize the container, it may provide shared modules
    // @ts-ignore
    // eslint-disable-next-line no-undef,camelcase -- required
    await container.init(__webpack_share_scopes__.default);
    // @ts-ignore
    const factory = await window[scope].get(module);
    const Module = factory();
    return Module;
  };
};

/** @type {(scope: string, url: string) => Promise<boolean>} */
const loadRemoteEntry = (scope, url) => {
  if (window[scope]) {
    return Promise.resolve(true);
  }
  return new Promise((resolve, reject) => {
    const element = document.createElement('script');
    element.src = url;
    element.type = 'text/javascript';
    element.async = true;
    element.onload = () => resolve(true);
    element.onerror = (event, source, lineno, colno, error) => {
      let message = `Failed to load remote module ${url} from script tag, no error thrown.`;
      if (!event) return reject(message);

      message = `Error with ${url}:`;

      if (source) message += ` Source: ${source}`;
      if (lineno && colno) message += `${lineno}:${colno}`;
      if (error?.message) message += ` Message: ${error.message}`;
      if (error?.stack) message += ` Stack: ${error.stack}`;

      return reject(message);
    };

    document.head.appendChild(element);
  });
};

/**
 * Load a remote, federated module
 *
 * @example
 *   loadRemoteModule({
 *     remoteEntryUrl: 'http://example.com/remoteEntry.js',
 *     scope: 'Foo',
 *     exposedModule: 'bar',
 *   });
 *
 * @type {(
 *   module: RemoteModule,
 * ) => Promise<import('single-spa').ParcelConfigObject>}
 */
const loadRemoteModule = async (module) => {
  await loadRemoteEntry(module.scope, module.remoteEntryUrl);
  return loadModuleFromScope(module.scope, `./${module.exposedModule}`)();
};

export default loadRemoteModule;
