import { useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AddNewDashboardIcon from '@bill/cashflow.assets/add-new-dashboard';
import CheckmarkSmallIcon from '@bill/cashflow.assets/checkmark-small';
import DashboardDefaultIcon from '@bill/cashflow.assets/dashboard-default';
import DownArrowIcon from '@bill/cashflow.assets/down-arrow';
import ProductTypeFilter from '@/components/ProductTypeFilter/ProductTypeFilter';
import ContextMenu from '@/components/common/ContextMenu';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import WithPopover from '@/components/common/WithPopover';
import { registeredFeatureFlags } from '@/constants/features';
import { DASHBOARD_PATH } from '@/constants/pages';
import { classNames } from '@/helpers';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import useOneColor from '@/hooks/useOneColor';
import useSelectedDashboard from '@/hooks/useSelectedDashboard';
import useUpdateDashboard from '@/hooks/useUpdateDashboard';
import { ReactComponent as AddNewDashboardOneColorIcon } from '@/assets/icons/svg/add_new_dashboard_forecasting.svg';
import { ReactComponent as ContextMenuForecastingIcon } from '@/assets/icons/svg/context_menu_oneColor.svg';
import './DashboardSelector.scss';

/**
 * @typedef {{
 *   id: string;
 *   isDefaultDashboard: boolean;
 *   onToggle: (boolean) => void;
 * }} SetAsDefaultOptionProps
 */

/**
 * Sub Component For Dashboard Selector that handles the option for setting the
 * default dashboard.
 *
 * @type {React.FC<SetAsDefaultOptionProps>}
 */
const SetAsDefaultOption = ({ id, isDefaultDashboard, onToggle }) => {
  const updateDefaultDashboard = useUpdateDashboard();
  const handleSetDefault = useCallback(async () => {
    try {
      await updateDefaultDashboard(id, { isDefault: true });
    } catch (error) {
      console.error(error);
    } finally {
      onToggle(false);
    }
  }, [id, onToggle, updateDefaultDashboard]);
  return (
    <ContextMenu.Option
      data-testid={`layout-default-${id}`}
      disabled={isDefaultDashboard}
      onClick={handleSetDefault}
    >
      Set as Default
    </ContextMenu.Option>
  );
};

const DashboardSelector = ({
  options,
  disabled,
  onCreateNew,
  onEdit,
  showDashboardList,
  onToggle,
  toggleShowConfirmDeleteModal,
}) => {
  const history = useHistory();

  const location = useLocation();

  const selectedDashboard = useSelectedDashboard();
  const canSetDefaultDashboardFeature = useFeatureFlags(
    registeredFeatureFlags.DEFAULT_DASHBOARD_SELECTION,
  );

  const defaultDashboardName = useMemo(() => {
    return options.find(({ isDefault }) => isDefault)?.name;
  }, [options]);

  const selectedDashboardName = selectedDashboard?.name || defaultDashboardName;
  const hasToolbarEditAccess = useNonDashboardWritePermission();
  const isOneColorEnabled = useOneColor();
  const NewDashboardIcon = isOneColorEnabled
    ? AddNewDashboardOneColorIcon
    : AddNewDashboardIcon;

  return (
    <WithPopover
      data-testid="dashboard-selector"
      className="Popover-toEdge"
      visible={showDashboardList}
      placement="bottom-start"
      content={
        <div className="DashboardSelector">
          {options.map(({ name, id, isDefault }, _) => {
            const isDefaultDashboard = name === defaultDashboardName;
            return (
              <div className="DashboardSelector_Container" key={id}>
                <button
                  onClick={() => {
                    const pathname = isDefaultDashboard
                      ? DASHBOARD_PATH
                      : `${DASHBOARD_PATH}/${id}`;

                    history.replace({
                      pathname,
                      search: location.search,
                    });
                  }}
                  key={id}
                  className={classNames(
                    'DashboardSelector_Item',
                    isOneColorEnabled && 'DashboardSelector_Item-forecasting',
                  )}
                >
                  <TooltipTextOverflow
                    data-testid={`selected-dashboard-name-${id}`}
                    label={name}
                  />
                  {isDefaultDashboard && (
                    <>
                      <CheckmarkSmallIcon
                        aria-labelledby="dashboard-selector-icon-title"
                        className="DashboardSelector_CheckedIcon"
                      />
                      <span
                        data-testid="dashboard-selector-icon-title"
                        id="dashboard-selector-icon-title"
                        className="DashboardSelector_OptionIconTitle"
                      >
                        Default Dashboard
                      </span>
                    </>
                  )}
                </button>
                {hasToolbarEditAccess && (
                  <div className="DashboardControls">
                    <ContextMenu
                      data-testid="dashboard-selector-action"
                      placement="bottom-start"
                      Icon={
                        isOneColorEnabled
                          ? ContextMenuForecastingIcon
                          : undefined
                      }
                    >
                      {canSetDefaultDashboardFeature && (
                        <>
                          <SetAsDefaultOption
                            isDefaultDashboard={isDefaultDashboard}
                            id={id}
                            onToggle={onToggle}
                          />
                        </>
                      )}
                      <ContextMenu.Option
                        data-testid={`layout-edit-${id}`}
                        onClick={() => {
                          onEdit(id);
                          onToggle(false);
                        }}
                      >
                        Edit Dashboard
                      </ContextMenu.Option>

                      <ContextMenu.Option
                        data-testid={`layout-delete-${id}`}
                        danger
                        disabled={isDefault}
                        onClick={() => {
                          toggleShowConfirmDeleteModal(name);
                          onToggle(false);
                        }}
                      >
                        Delete Dashboard
                      </ContextMenu.Option>
                    </ContextMenu>
                  </div>
                )}
              </div>
            );
          })}
          {hasToolbarEditAccess && (
            <ProductTypeFilter>
              <button
                onClick={() => {
                  onCreateNew();
                  onToggle(false);
                }}
                className="DashboardSelector_Item DashboardSelector_Item-create"
                data-testid="dashboard-create-new"
              >
                <NewDashboardIcon className="Dashboard_AddNewDashboardIcon" />+
                Add a new dashboard
              </button>
            </ProductTypeFilter>
          )}
        </div>
      }
      onClose={() => onToggle(false)}
    >
      <button
        className={classNames(
          'DashboardSelector_Button',
          isOneColorEnabled && 'DashboardSelector_Button-forecasting',
        )}
        data-testid="dashboard-selector-button"
        onClick={() => onToggle((showStatus) => !showStatus)}
        aria-expanded={showDashboardList}
        disabled={disabled}
      >
        {!isOneColorEnabled && (
          <DashboardDefaultIcon className="DashboardSelector_DropdownIcon" />
        )}

        <TooltipTextOverflow
          data-testid="dashboard-name"
          className="DashboardSelector_Name"
          label={selectedDashboardName}
        />
        <DownArrowIcon className="FormField_SelectArrow DashboardSelector_DownArrowIcon" />
      </button>
    </WithPopover>
  );
};

export default DashboardSelector;
