import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import RoundCrossIcon from '@bill/cashflow.assets/round-cross';
import useFocusOnEdit from '@/components/common/Spreadsheet/editors/useFocusOnEdit';
import WithTooltip from '@/components/common/WithTooltip';
import keyConstants from '@/constants/keys';
import { ITEM, SUBSECTION } from '@/constants/reports';
import { PLACEHOLDER_ID } from '@/constants/variables';
import { isEmptyOrNull } from '@/helpers/validators';
import useInputBlurKeyDownListener from '@/hooks/useInputBlurKeyDownListener';
import './RowNameEditor.scss';

const INVALID_CLASS = 'Spreadsheet_Cell-invalid';

/**
 * A text cell editor for use in ag-Grid with 'agGroupCellRenderer'
 *
 * @example
 *   columnDefs={[
 *    {
 *      ...
 *      cellEditor: RowNameEditor,
 *      editable: true
 *    }
 *   ]}
 *
 * @see https://www.ag-grid.com/react-grid/component-cell-editor/
 */
const RowNameEditor = forwardRef(
  ({ charPress, colDef, node, value, eGridCell, api, onCancelAdd }, ref) => {
    const type = node.data.type === ITEM ? ITEM : SUBSECTION;
    const dataTestId = `${node.data.name}-${colDef.field}`;
    const input = useRef(null);
    const [localValue, setLocalValue] = useState(charPress ?? value ?? '');
    const [errorMsg, setErrorMsg] = useState(null);

    useEffect(() => {
      const { error } = node.data;
      if (error) {
        eGridCell.classList.add(INVALID_CLASS);
        setErrorMsg(error);
      }
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    }, [node.data]);

    const validate = () => {
      if (!localValue.length) return 'Please enter a unique name';

      let error = null;
      api.forEachNode(({ data = {} }) => {
        const { name, hasErrorMsg } = data;
        if (
          data.id === node.id ||
          hasErrorMsg ||
          data.hierarchy.at(-2) !== node.data.hierarchy.at(-2)
        )
          return;
        if (name.toLowerCase() === localValue.toLowerCase().trim()) {
          error = `An entity with this name already exists`;
        }
      });
      return error;
    };

    const setError = (event) => {
      const error = validate();
      if (error) {
        eGridCell.classList.add(INVALID_CLASS);
        setErrorMsg(error);
        event?.stopPropagation();
      }
    };

    useImperativeHandle(ref, () => ({
      getValue: () => ({
        value: localValue.trim(),
        parentSectionId: node.data.hierarchy.at(-2),
        hasErrorMsg: !!validate(),
      }),
    }));

    useFocusOnEdit(input);

    useEffect(() => {
      if (localValue) {
        setError();
      }
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    }, []);

    const onCancel = () => {
      api.stopEditing(true);
      if (node.data.id === PLACEHOLDER_ID) onCancelAdd(node.data.id);
    };

    // Don't allow saving of empty or duplicate names
    const onBlur = useCallback(
      (event) => {
        if (!localValue) return;
        setError(event);
      },
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
      [localValue],
    );

    const onKeyDown = useCallback(
      (event) => {
        setErrorMsg(null);
        eGridCell.classList.remove(INVALID_CLASS);
        if (event.key === keyConstants.ESCAPE) {
          onCancel();
        }

        if ([keyConstants.TAB, keyConstants.ENTER].includes(event.key)) {
          onBlur(event);
        }
      },
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
      [onBlur],
    );

    useInputBlurKeyDownListener(input, onBlur, onKeyDown);

    return (
      <div className="Spreadsheet_CellEditor">
        <button
          type="button"
          className="RowNameEditor_CancelBtn"
          onClick={onCancel}
          data-testid={`${dataTestId}-cancel`}
        >
          <RoundCrossIcon aria-label="Cancel" />
        </button>
        <WithTooltip
          content={errorMsg || ''}
          hidden={isEmptyOrNull(errorMsg)}
          className="Tooltip-flex"
          placement="bottom"
          data-testid="error-display-tooltip"
        >
          <input
            ref={input}
            type="text"
            className="Spreadsheet_Input"
            placeholder={`Enter ${type} name....`}
            name={colDef.field}
            value={localValue}
            data-testid={`${dataTestId}-input`}
            onChange={({ target }) => {
              setLocalValue(target.value);
            }}
          />
        </WithTooltip>
      </div>
    );
  },
);

export default RowNameEditor;
