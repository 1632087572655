/**
 * A number formatter for creating ordinal numbers, e.g. 1st, 2nd, etc.
 *
 * @param {number} number - The number to format
 * @param {Object} (locale) - A string with a BCP 47 language tag
 * @param {Object} (options) - Options accepted by Intl.PluralRules
 * @returns {string} The formatted number
 */
const formatOrdinal = (
  number,
  locale = undefined,
  options = { type: 'ordinal' },
) => {
  const rules = new Intl.PluralRules(locale, options);
  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ]);

  const rule = rules.select(number);
  return `${number}${suffixes.get(rule)}`;
};

export default formatOrdinal;
