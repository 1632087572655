import PropTypes from 'prop-types';
import WithTooltip from '@/components/common/WithTooltip';
import { classNames } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';
import './IconButton.scss';

/** @typedef {{ [key: string]: any }} IconButtonProps */

/**
 * Renders an icon as a button, with a tooltip instead of a text label
 *
 * @example
 *   <IconButton
 *     data-testid="foo"
 *     Icon={FooIcon}
 *     label="Foo"
 *     onClick={setFoo}
 *   />;
 *
 * @type {(props: IconButtonProps) => import('react').ReactElement}
 */
function IconButton({
  active,
  className,
  'data-testid': dataTestId,
  Icon,
  label,
  tooltipPlacement = 'bottom',
  disabled,
  ...props
}) {
  return (
    <WithTooltip
      content={label}
      data-testid={`${dataTestId}-tooltip`}
      placement={tooltipPlacement}
      disabled={disabled}
    >
      <button
        className={classNames(
          'IconButton',
          !isEmptyOrNull(active) && 'IconButton-toggle',
          className,
        )}
        aria-label={label}
        aria-pressed={active}
        data-testid={dataTestId}
        disabled={disabled}
        {...props}
      >
        <Icon className="IconButton_Icon" />
      </button>
    </WithTooltip>
  );
}

IconButton.propTypes = {
  /** Whether the button state is toggled on */
  'active': PropTypes.bool,
  /** Additional class(es) to apply to the button */
  'className': PropTypes.string,
  /** Unique identifier for selecting the button in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Icon component to render within the button */
  'Icon': PropTypes.elementType.isRequired,
  /** Descriptive label for the button's tooltip */
  'label': PropTypes.string.isRequired,
  /** Whether the button is disabled */
  'disabled': PropTypes.bool,
  /** Position of the tooltip relative to the button. Defaults to bottom. */
  'tooltipPlacement': PropTypes.string,
};

export default IconButton;
