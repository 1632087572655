import { useState } from 'react';
import QuestionMarkIcon from '@bill/cashflow.assets/question-mark';
import FormLabel from '@/components/common/FormLabel';
import FormulaField from '@/components/common/FormulaField';
import FormulaGuideModal from '@/components/common/FormulaGuide/FormulaGuideModal';
import { CHURN } from '@/components/common/FormulaGuide/formulaGuideModalConstants';
import FormulaValidationMessage from '@/components/common/FormulaValidationMessage';
import Select from '@/components/common/Select';
import { units, symbols } from '@/constants/variables';

const CHURN_RATE_TOOLTIP_MSG =
  'This formula is used to forecast the churn rate for this pricing plan. The churn rate will be applied based on the frequency selected above. You can also simply enter a number if you want to keep the churn rate constant. Please note that churn rate must be greater than or equal to zero.';
const CHURN_CUSTOMERS_TOOLTIP_MSG =
  'This formula is used to forecast monthly churned customers for this pricing plan. You can also simply enter a number if you want to keep your churned customer constant. Please note that churned customers must be greater than or equal to zero.';

const ChurnFormulaField = ({
  value,
  churnType,
  isDisabled,
  validationMsg,
  onChange,
  onChangeSelection,
  handleValidation,
  onFocus,
}) => {
  const [showChurnGuideModal, setShowChurnGuideModal] = useState(false);
  const isPercentageChurn = churnType === units.PERCENTAGE;

  return (
    <div className="Form_Group">
      <div className="CustomFormulaDriver_Label">
        <FormLabel
          htmlFor="churn-rate-formula-field"
          text={isPercentageChurn ? 'Churn Rate' : 'Churned Customers'}
          tooltip={
            isPercentageChurn
              ? CHURN_RATE_TOOLTIP_MSG
              : CHURN_CUSTOMERS_TOOLTIP_MSG
          }
        />
        <button
          type="button"
          className="Button-iconOnly HelpIconButtonWrapper"
          aria-label="Help"
          onClick={() => setShowChurnGuideModal(true)}
        >
          <QuestionMarkIcon className="HelpIcon" aria-hidden="true" />
        </button>
      </div>
      <div className="FormulaField_WithUnit">
        <Select
          id="churnFormatsDropdown"
          name="churnFormatsDropdown"
          value={isPercentageChurn ? symbols.PERCENTAGE : symbols.NUMBER}
          disabled={isDisabled}
          onChange={({ target }) => {
            onChangeSelection(
              target.value === symbols.PERCENTAGE
                ? units.PERCENTAGE
                : units.NUMBER,
            );
          }}
        >
          {[symbols.PERCENTAGE, symbols.NUMBER].map((format) => (
            <option key={format} value={format}>
              {format}
            </option>
          ))}
        </Select>
        <FormulaField
          id="churn-rate-formula-field"
          value={value}
          isDisabled={isDisabled}
          onChange={onChange}
          onBlur={() => {
            handleValidation(value, isPercentageChurn);
          }}
          onFocus={onFocus}
        />
        {validationMsg && <FormulaValidationMessage message={validationMsg} />}
        <FormulaGuideModal
          formulaKey={CHURN}
          open={showChurnGuideModal}
          data-testid="churn-guide-modal"
          onClose={() => setShowChurnGuideModal(false)}
        />
      </div>
    </div>
  );
};

export default ChurnFormulaField;
