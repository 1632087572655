import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  changeUserPasswordAction,
  getCompanySettingAction,
  getUserSettingAction,
  updateCompanySettingAction,
  updateUserSettingAction,
  addOrEditMemberAction,
  resetShareCompanyError,
  getMembersAction,
  resetDisableMemberInviteAction,
  removeMemberAction,
  disableMemberInviteAction,
  getMemberInfoAction,
  setMemberInfoAction,
  resetMemberInfoAction,
  cancelExpiredInviteAction,
} from '@/actions/settings';
import PageHeader from '@/components/Layouts/PageHeader';
import ProductTypeFilter from '@/components/ProductTypeFilter/ProductTypeFilter';
import Billing from '@/components/Settings/Billing/Billing';
import CardWrapper from '@/components/Settings/CardWrapper';
import ChangePassword from '@/components/Settings/ChangePassword';
import CompanyDetails from '@/components/Settings/CompanyDetails';
import Currency from '@/components/Settings/Currency';
import Department from '@/components/Settings/Department/Department';
import Employee from '@/components/Settings/Employee';
import Header from '@/components/Settings/Header';
import IntegrationsSettings from '@/components/Settings/Integrations';
import AddMemberModal from '@/components/Settings/MemberInvite/AddMemberModal';
import MemberList from '@/components/Settings/MemberInvite/MemberList';
import MembersDetailModal from '@/components/Settings/MemberInvite/MembersDetailModal';
import PaymentAndCollection from '@/components/Settings/PaymentAndCollection/PaymentAndCollection';
import PersonalDetails from '@/components/Settings/PersonalDetails';
import ReferFriendModal from '@/components/Settings/Referrals/ReferFriendModal';
import Referrals from '@/components/Settings/Referrals/Referrals';
import ComponentLoader from '@/components/common/ComponentLoader';
import Permissions from '@/components/common/Permissions';
import { actions, subjects } from '@/constants/permissions';
import { FPA_LITE } from '@/constants/productTypes';
import { GLANCED_MEMBER_LIST_RECORD_COUNT } from '@/constants/settings';
import useIsAdminUser from '@/hooks/useIsAdminUser';
import usePermissions from '@/hooks/usePermissions';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import './Settings.scss';

const INITIAL_INVITE_EMAIL_STATE = {
  email: '',
  error: '',
  show: false,
  resent: false,
  sent: false,
  disableInvite: false,
};

const AlertMessage = ({ invitedEmail }) => {
  if (invitedEmail.error) {
    return invitedEmail.error;
  }

  if (invitedEmail.warning) {
    return invitedEmail.warning;
  }

  if (invitedEmail.referred) {
    return (
      <span>
        Thank you for your referral. An invitation has been sent to{' '}
        <span className="Alert-global_Email">{invitedEmail.email}</span>
      </span>
    );
  }

  if (invitedEmail.resent) {
    return (
      <span>
        A re-invite has been sent to{' '}
        <span className="Alert-global_Email">{invitedEmail.email}</span>
      </span>
    );
  }

  if (invitedEmail.disableInvite) {
    return (
      <span>
        Invite has been disabled for{' '}
        <span className="Alert-global_Email">{invitedEmail.email}</span>
      </span>
    );
  }

  if (invitedEmail.sent) {
    return (
      <span>
        An invite has been sent to{' '}
        <span className="Alert-global_Email">{invitedEmail.email}</span>
      </span>
    );
  }

  return null;
};

const MembersList = ({
  members,
  setShowInvite,
  userInfo,
  setShowMembersDetailModal,
}) => {
  return (
    <MemberList
      members={{
        ...members,
        companyUsers: members.companyUsers.slice(
          0,
          GLANCED_MEMBER_LIST_RECORD_COUNT,
        ),
      }}
      setShowInvite={setShowInvite}
      currentUser={{
        name: userInfo.fullName,
        permission: userInfo.userRole,
        id: userInfo.userId,
        email: userInfo.email,
      }}
      setShowMembersDetailModal={setShowMembersDetailModal}
    />
  );
};

const Settings = ({
  // eslint-disable-next-line no-shadow -- predates description requirement
  changeUserPasswordAction,
  changeUserPasswordError,
  changeUserPasswordLoading,
  getCompanySetting,
  updateCompanySetting,
  companySettings,
  companySettingsError,
  getUserSetting,
  updateUserSetting,
  userSettings,
  userInfo,
  userSettingsError,
  scenarioId,
  scenarios,
  addOrEditMember,
  shareCompanyState,
  getMembers,
  getMemberInfo,
  memberInfo,
  setMemberInfo,
  members,
  selectedCompany,
  removeMember,
  removeMemberState,
  disableMemberInvite,
  memberInviteDisableState,
  resetDisableMemberInvite,
  resetShareCompanyError: resetCompany,
  resetMemberInfo,
  cancelExpiredInvite,
}) => {
  const { READ_WRITE } = actions;
  const { COMPANY_SETTINGS, LOAD_MULTIPLIER, PAYMENT_COLLECTION_TERMS } =
    subjects;
  const companySettingPermission = usePermissions(READ_WRITE, COMPANY_SETTINGS);
  const [companyDetailsMode, setCompanyDetailsMode] = useState('read');
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [invitedEmail, setInvitedEmail] = useState({
    ...INITIAL_INVITE_EMAIL_STATE,
  });
  const [showMembersDetailModal, setShowMembersDetailModal] = useState(false);
  const [persistMembersModal, setPersistMembersModal] = useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [showReferFriendModal, setShowReferFriendModal] = useState(false);
  const isAdmin = useIsAdminUser();

  useEffect(() => {
    getUserSetting();
    if (companySettingPermission) {
      getCompanySetting();
      getMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [selectedCompany?.id, scenarioId]);

  useEffect(() => {
    if (memberInfo) {
      setShowInviteModal(true);
      setShowMembersDetailModal(false);
    }
  }, [memberInfo]);

  const resetErrorMessage = () => {
    resetCompany();
  };
  const showAlertAndHideMessage = (messageFlags) => {
    setInvitedEmail({ ...invitedEmail, ...messageFlags, show: true });
    setTimeout(() => {
      setInvitedEmail({ ...INITIAL_INVITE_EMAIL_STATE });
      resetDisableMemberInvite();
    }, 5000);
  };

  const handleClose = (value, email) => {
    if (email) {
      showAlertAndHideMessage();
      if (companySettingPermission) {
        getMembers();
      }
    } else {
      setInvitedEmail({ ...invitedEmail, show: false });
    }
    resetErrorMessage();
    setShowInviteModal(value);
    setMemberInfo(null);
  };

  useEffect(() => {
    if (shareCompanyState.success && !shareCompanyState.loading) {
      if (memberInfo) {
        getMembers();
        setShowMembersDetailModal(true);
      } else {
        setShowMembersDetailModal(false);
      }

      handleClose(false, invitedEmail.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [shareCompanyState]);

  useEffect(() => {
    if (memberInviteDisableState.success && !memberInviteDisableState.loading) {
      showAlertAndHideMessage({ disableInvite: true });
      setShowMembersDetailModal(false);

      return;
    }
    if (memberInviteDisableState.error && !memberInviteDisableState.loading) {
      showAlertAndHideMessage({ error: memberInviteDisableState.error });
      setShowMembersDetailModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [memberInviteDisableState]);

  useEffect(() => {
    if (removeMemberState.success && !removeMemberState.loading) {
      getMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [removeMemberState]);

  const addOrEditMemberAndSetInvite = (data) => {
    addOrEditMember(data);
    setInvitedEmail({ email: data.email, show: false, sent: true });
  };

  const showInviteEmail = () => {
    setInvitedEmail({ ...invitedEmail, show: false });
    setShowInviteModal(false);
    resetErrorMessage();
    resetMemberInfo();
    if (memberInfo) {
      setShowMembersDetailModal(true);
      resetMemberInfo();
    }

    if (persistMembersModal) {
      setShowMembersDetailModal(true);
      setPersistMembersModal(false);
    }
  };

  const resendInvite = ({ email }) => {
    addOrEditMemberAndSetInvite({ email });
    setInvitedEmail({ email, show: false, resent: true });
    setShowMembersDetailModal(false);
  };

  const fetchMembersAndOpenDetailModal = () => {
    setShowMembersDetailModal(true);
    getMembers();
  };

  const openInviteUserAndResetMemberInfo = () => {
    setMemberInfo();
    setShowInviteModal(true);
  };
  const showInviteModalAndPersistDetailModalState = () => {
    openInviteUserAndResetMemberInfo();
    setPersistMembersModal(true);
    setShowMembersDetailModal(false);
  };

  const getAlertVariant = () => {
    switch (true) {
      case !!invitedEmail.error:
        return 'danger';
      case !!invitedEmail.warning:
        return 'warning';
      default:
        return 'success';
    }
  };
  return (
    <div className="Settings">
      {showReferFriendModal && (
        <ReferFriendModal
          setShowReferralModal={setShowReferralModal}
          setShow={setShowReferFriendModal}
        />
      )}
      {invitedEmail.show && (
        <div className="SettingsAlert">
          <Alert variant={getAlertVariant()} className="Alert-global">
            <AlertMessage invitedEmail={invitedEmail} />
          </Alert>
        </div>
      )}
      <PageHeader page="/settings" includeDatepicker={false} />
      <div className="SettingsContainer">
        <div className="SettingsColumn">
          <ComponentLoader loadingComponent="settings" paddingTop="0%" />
          <ProductTypeFilter productType={FPA_LITE}>
            <MembersList
              members={members}
              setShowInvite={openInviteUserAndResetMemberInfo}
              userInfo={userInfo}
              setShowMembersDetailModal={
                isAdmin ? fetchMembersAndOpenDetailModal : null
              }
            />
          </ProductTypeFilter>

          <ProductTypeFilter>
            <PersonalDetails
              userFirstName={userSettings.firstName}
              userLastName={userSettings.lastName}
              userEmailAddress={userSettings.emailAddress}
              userCountry={userSettings.country}
              updateUserAction={updateUserSetting}
              error={userSettingsError}
              loading={userSettings.loading}
            />

            <ChangePassword
              changeUserPasswordAction={changeUserPasswordAction}
              error={changeUserPasswordError}
              loading={changeUserPasswordLoading}
            />

            <Permissions action={READ_WRITE} subject={COMPANY_SETTINGS}>
              <CardWrapper>
                <Header
                  mode={companyDetailsMode}
                  setMode={setCompanyDetailsMode}
                  title="Company Details"
                  data-testid="company-name"
                />
                <CompanyDetails
                  companyId={selectedCompany?.id}
                  mode={companyDetailsMode}
                  setMode={setCompanyDetailsMode}
                  showFooter
                />
              </CardWrapper>

              <Currency
                companyCurrency={companySettings.currency}
                updateCompanyAction={(currency) =>
                  updateCompanySetting({ ...companySettings, ...currency })
                }
                error={companySettingsError}
                loading={companySettings.loading}
              />

              <Department />
            </Permissions>

            <Permissions
              action={READ_WRITE}
              subject={PAYMENT_COLLECTION_TERMS}
              scenarioPermissionRequired
            >
              <PaymentAndCollection />
            </Permissions>
          </ProductTypeFilter>
        </div>
        <div className="SettingsColumn">
          <ComponentLoader loadingComponent="settings" paddingTop="0%" />
          <ProductTypeFilter>
            {companySettingPermission && (
              <Billing setShowReferFriendModal={setShowReferFriendModal} />
            )}
            <Referrals
              showAlertAndHideMessage={showAlertAndHideMessage}
              showReferralModal={showReferralModal}
              setShowReferralModal={setShowReferralModal}
            />
            <Permissions
              action={READ_WRITE}
              subject={LOAD_MULTIPLIER}
              scenarioPermissionRequired
            >
              <Employee />
            </Permissions>
          </ProductTypeFilter>
          <Permissions action={READ_WRITE} subject={COMPANY_SETTINGS}>
            <ProductTypeFilter>
              <MembersList
                members={members}
                setShowInvite={openInviteUserAndResetMemberInfo}
                userInfo={userInfo}
                setShowMembersDetailModal={fetchMembersAndOpenDetailModal}
              />
            </ProductTypeFilter>
            {showInviteModal && (
              <AddMemberModal
                scenarios={scenarios}
                addMember={addOrEditMemberAndSetInvite}
                error={shareCompanyState.error || null}
                loading={shareCompanyState.loading}
                resetErrorMessage={resetErrorMessage}
                showInviteEmail={showInviteEmail}
                memberData={memberInfo}
              />
            )}
            {showMembersDetailModal && (
              <MembersDetailModal
                closeModal={() => setShowMembersDetailModal(false)}
                members={members.companyUsers}
                currentUser={{
                  name: userInfo.fullName,
                  hasEmployeeAccess: userInfo.hasEmployeeAccess,
                  permission: userInfo.userRole,
                  id: userInfo.userId,
                  email: userInfo.email,
                }}
                resendInvite={resendInvite}
                getMemberInfo={getMemberInfo}
                setShowInvite={showInviteModalAndPersistDetailModalState}
                removeMember={removeMember}
                removeMemberState={removeMemberState}
                disableMemberInvite={(email) => {
                  setInvitedEmail({ email });
                  disableMemberInvite({ email });
                }}
                companyName={selectedCompany?.name}
                cancelExpiredInvite={(email) => {
                  cancelExpiredInvite({
                    companyId: selectedCompany.id,
                    scenarioId,
                    email,
                  });
                }}
              />
            )}
            <IntegrationsSettings />
          </Permissions>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  updateUserSuccess: state.settings.updateUserSuccess,
  changeUserPasswordSuccess: state.settings.changeUserPasswordSuccess,
  changeUserPasswordLoading: state.settings.changeUserPasswordLoading,
  changeUserPasswordError: state.settings.changeUserPasswordError,
  updateCompanySuccess: state.settings.updateCompanySuccess,
  companySettings: state.settings.companySettings,
  companySettingsError: state.settings.companySettingsError,
  userSettings: state.settings.userSettings,
  userSettingsError: state.settings.userSettingsError,
  loadMultiplierLoading: state.settings.loadMultiplierLoading,
  loadMultiplierError: state.settings.loadMultiplierError,
  userInfo: state.auth.userInfo,
  selectedCompany: getSelectedCompany(state),
  scenarios: state.scenario.scenarios,
  scenarioId: state.scenario.scenarioId,
  shareCompanyError: state.settings.shareCompanyError,
  shareCompanyLoading: state.settings.shareCompanyLoading,
  shareCompanySuccess: state.settings.shareCompanySuccess,
  removeMemberState: state.settings.removeMember,
  memberInviteDisableState: state.settings.memberInviteDisable,
  shareCompanyState: state.settings.shareCompany,
  memberInfo: state.settings.memberInfo,
  members: state.settings.members,
});

export default connect(mapStateToProps, {
  getCompanySetting: getCompanySettingAction,
  updateCompanySetting: updateCompanySettingAction,
  getUserSetting: getUserSettingAction,
  updateUserSetting: updateUserSettingAction,
  changeUserPasswordAction,
  resetShareCompanyError,
  getMembers: getMembersAction,
  removeMember: removeMemberAction,
  disableMemberInvite: disableMemberInviteAction,
  resetDisableMemberInvite: resetDisableMemberInviteAction,
  getMemberInfo: getMemberInfoAction,
  addOrEditMember: addOrEditMemberAction,
  setMemberInfo: setMemberInfoAction,
  resetMemberInfo: resetMemberInfoAction,
  cancelExpiredInvite: cancelExpiredInviteAction,
})(Settings);
