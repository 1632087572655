// @ts-check
import AiAssistantIcon from '@bill/cashflow.assets/ai-assistant';
import './CopilotButton.scss';

/**
 * A Button which Opens AI Assistant - Copilot
 *
 * @example
 *   <PageHeader page="/reports" addBtnSlot={[<CopilotButton />]} />;
 *
 * @typedef {{ onClick: () => void }} CopilotButtonProps
 * @type {(props: CopilotButtonProps) => React.ReactElement}
 */
const CopilotButton = ({ onClick }) => {
  return (
    <button
      aria-label="Open AI Assistant"
      className="CopilotButton"
      onClick={onClick}
    >
      <AiAssistantIcon
        className="CopilotButton_Icon"
        focusable={false}
        aria-hidden
      />
    </button>
  );
};

export default CopilotButton;
