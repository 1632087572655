// @ts-check
import { ApiV2 } from './Api';

/**
 * Gets the list of metrics
 *
 * @returns {Promise} API response
 */
export const getMetricsList = () => ApiV2.get('/metrics');

/** @typedef {import('@/pages/Metrics/types').TagsList} TagsListResponse */

/**
 * Get List of Tags with metric Categories
 *
 * @type {() => Promise<import('axios').AxiosResponse<TagsListResponse>>}
 */
export const getTagsList = () => ApiV2.get('/metrics/tags');

/**
 * Creates the variable and/or chart of the metric
 *
 * @type {(
 *   scenarioId: number,
 *   payload: { metricId: string },
 *   createChart: boolean,
 * ) => Promise<import('axios').AxiosResponse<any>>}
 */
export const createMetricVariable = (scenarioId, payload, createChart) => {
  return ApiV2.post('/metrics/variable', payload, {
    params: { scenarioId, createChart },
  });
};
