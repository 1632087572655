import { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { deleteCustomDepartmentAction } from '@/actions/settings';
import TheAddEditDepartmentModal from '@/components/Settings/Department/TheAddEditDepartmentModal';
import Button from '@/components/common/Button';
import ContextMenu from '@/components/common/ContextMenu';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import './TheDepartmentsModal.scss';

const INITIAL_DEPARTMENT = {};

const GENERIC_ERROR_MESSAGE =
  'This department cannot be deleted, as it is currently linked to either an expense or an employee.';

const TheDepartmentsModal = ({
  expenseClasses,
  companyId,
  scenarioId,
  onClose,
  deleteCustomDepartment,
}) => {
  const [showAddEditDeptModal, setShowAddEditDeptModal] = useState(false);
  const [showDeleteDeptModal, setShowDeleteDeptModal] = useState(false);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [error, setError] = useState('');
  const [selectedDepartment, setSelectedDepartment] =
    useState(INITIAL_DEPARTMENT);

  const handleDelete = async () => {
    try {
      await deleteCustomDepartment(
        companyId,
        selectedDepartment.id,
        scenarioId,
      );
      setSelectedDepartment(INITIAL_DEPARTMENT);
    } catch (e) {
      setError(e.response?.data?.error?.errorMessage ?? GENERIC_ERROR_MESSAGE);
      setShowCannotDeleteModal(true);
    } finally {
      setShowDeleteDeptModal(false);
    }
  };

  return (
    <ModalBase
      id="departments-modal"
      className="TheDepartmentsModal"
      onCancel={onClose}
      header={
        <div className="TheDepartmentsModal_Header">
          <h2 className="ModalBase_Heading">Edit Departments</h2>
          <Button
            className="Button-primaryLink"
            onClick={() => setShowAddEditDeptModal(true)}
            data-testid="departments-modal-add-button"
          >
            + Add Custom Department
          </Button>
        </div>
      }
      footer={
        <>
          <Button
            className="Button Button-cancelLink"
            onClick={onClose}
            data-testid="departments-modal-cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={onClose}
            data-testid="departments-modal-finish-button"
            className="Button"
          >
            Finish Edit
          </Button>
        </>
      }
    >
      <div>
        <div className="TheDepartmentsModal_SubSection">
          <div className="TheDepartmentsModal_Heading">Custom Departments</div>
          <div className="TheDepartmentsModal_Grid">
            <div className="TheDepartmentsModal_GridHeading">
              Name of Category
            </div>
            <div className="TheDepartmentsModal_GridHeading TheDepartmentsModal_GridHeading-spanColumns">
              Name of Department
            </div>
            {expenseClasses.map(({ departments, name, id }) => {
              return departments
                .filter((department) => department.custom)
                .map((department) => {
                  const editId = `department-edit-${department.name}`;
                  const deleteId = `department-delete-${department.name}`;
                  return (
                    <Fragment key={department.name}>
                      <div data-testid={`${name}-${department.name}`}>
                        {name}
                      </div>
                      <div data-testid={department.name}>{department.name}</div>
                      <ContextMenu
                        data-testid={`department-action-${department.name}`}
                      >
                        <ContextMenu.Option
                          data-testid={editId}
                          onClick={() => {
                            setSelectedDepartment({
                              ...department,
                              expenseClassId: id,
                            });
                            setShowAddEditDeptModal(true);
                          }}
                        >
                          Edit Department
                        </ContextMenu.Option>
                        <ContextMenu.Option
                          data-testid={deleteId}
                          danger
                          onClick={() => {
                            setSelectedDepartment(department);
                            setShowDeleteDeptModal(true);
                          }}
                        >
                          Delete Department
                        </ContextMenu.Option>
                      </ContextMenu>
                    </Fragment>
                  );
                });
            })}
          </div>
        </div>
        <div>
          <div className="TheDepartmentsModal_Heading">
            System-Defined Departments
          </div>
          <div className="TheDepartmentsModal_Grid TheDepartmentsModal_Grid-systemDepartments">
            <div className="TheDepartmentsModal_GridHeading">
              Name of Category
            </div>
            <div className="TheDepartmentsModal_GridHeading">
              Name of Department
            </div>
            {expenseClasses.map(({ departments, name }) => {
              return departments
                .filter((department) => !department.custom)
                .map((department) => {
                  return (
                    <Fragment key={department.name}>
                      <div>{name}</div>
                      <div>{department.name}</div>
                    </Fragment>
                  );
                });
            })}
          </div>
        </div>
      </div>

      {showDeleteDeptModal && (
        <ModalConfirmation
          id="modal-remove-department"
          title="Delete Custom Department"
          actionBtnTxt="Delete Department"
          onCancel={() => setShowDeleteDeptModal(false)}
          onAction={handleDelete}
        >
          <span className="RemoveDepartment_DepartmentName">
            {selectedDepartment.name}
          </span>
          This action will remove your custom department from the system, and
          will no longer be available to you when adding new expenses or
          employees.
        </ModalConfirmation>
      )}

      {showCannotDeleteModal && (
        <ModalConfirmation
          id="modal-cannot-remove-department"
          title="Cannot Delete Department"
          actionBtnTxt="I understand"
          onAction={() => {
            setSelectedDepartment(INITIAL_DEPARTMENT);
            setShowCannotDeleteModal(false);
            setError('');
          }}
          show
        >
          <span className="RemoveDepartment_DepartmentName">
            {selectedDepartment.name}
          </span>
          {error}
        </ModalConfirmation>
      )}

      {showAddEditDeptModal && (
        <TheAddEditDepartmentModal
          onClose={() => {
            setShowAddEditDeptModal(false);
            setSelectedDepartment(INITIAL_DEPARTMENT);
          }}
          department={selectedDepartment}
        />
      )}
    </ModalBase>
  );
};

const mapStateToProps = (state) => ({
  scenarioId: state.scenario.scenarioId,
  expenseClasses: state.expenses.expensesClasses,
  companyId: state.companies.selectedCompanyId,
});

export default connect(mapStateToProps, {
  deleteCustomDepartment: deleteCustomDepartmentAction,
})(TheDepartmentsModal);
