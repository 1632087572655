import { previousCustomRange, futureCustomRange } from '@/constants/Months';
import {
  getCurrentMonthTimestamp,
  getDateOffsetByMonths,
} from '@/helpers/dateFormatter';
import './CustomRangePicker.scss';

const thisMonth = new Date(getCurrentMonthTimestamp());

const dateFormatter = new Intl.DateTimeFormat(
  {},
  {
    timeZone: 'UTC',
    month: 'short',
    year: 'numeric',
  },
);

const CustomRangePicker = ({
  'data-testid': dataTestId,
  onSelect,
  startDate,
  endDate,
}) => {
  return (
    <div className="CustomRangePicker" data-testid={dataTestId}>
      <div className="Calendar_Header">
        <div className="Calendar_HeaderItem">Past</div>
        <div className="Calendar_HeaderItem">Future</div>
      </div>
      <div className="CustomRangePicker_Content">
        <ul className="CustomRangePicker_List">
          {previousCustomRange.map(({ text, numMonths }) => {
            const startMonth = getDateOffsetByMonths(thisMonth, -numMonths);
            const isActive =
              startMonth.getTime() === startDate &&
              thisMonth.getTime() === endDate;
            return (
              <li key={numMonths}>
                <button
                  type="button"
                  className="CustomRangePicker_ItemBtn"
                  aria-pressed={isActive}
                  data-testid={`${dataTestId}-last${numMonths}`}
                  onClick={() => onSelect(startMonth, thisMonth)}
                >
                  {text}
                  <div className="CustomRangePicker_ItemRange">
                    {dateFormatter.format(startMonth)} -{' '}
                    {dateFormatter.format(thisMonth)}
                  </div>
                </button>
              </li>
            );
          })}
        </ul>
        <ul className="CustomRangePicker_List">
          {futureCustomRange.map(({ text, numMonths }) => {
            const endMonth = getDateOffsetByMonths(thisMonth, numMonths);
            const isActive =
              endMonth.getTime() === endDate &&
              thisMonth.getTime() === startDate;
            return (
              <li key={numMonths}>
                <button
                  type="button"
                  className="CustomRangePicker_ItemBtn"
                  aria-pressed={isActive}
                  data-testid={`${dataTestId}-next${numMonths}`}
                  onClick={() => onSelect(thisMonth, endMonth)}
                >
                  {text}
                  <div className="CustomRangePicker_ItemRange">
                    {dateFormatter.format(thisMonth)} -{' '}
                    {dateFormatter.format(endMonth)}
                  </div>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CustomRangePicker;
