// @ts-check
import { SET_SELECTED_COMPANY } from '@/actionTypes/companies';
import { SET_SCENARIO_ID } from '@/actionTypes/scenario';
import { LOGOUT } from '@/actionTypes/shared';
import { CHANGE_LOADING_STATE } from '@/actions/componentLoading';

/**
 * @typedef {{
 *   expensesList: boolean;
 *   employeesList: boolean;
 *   customVariables: boolean;
 *   systemVariables: boolean;
 *   cashFlowChart: boolean;
 *   settings: boolean;
 *   productsRevenueList: boolean;
 *   payrollExpensesList: boolean;
 *   hiringDrivers: boolean;
 *   integrationStatuses: boolean;
 *   isExpired: boolean;
 * }} ComponentLoadingState
 */

/** @type {ComponentLoadingState} */
const INITIAL_STATE = {
  expensesList: true,
  employeesList: true,
  customVariables: true,
  systemVariables: true,
  cashFlowChart: true,
  settings: true,
  productsRevenueList: true,
  payrollExpensesList: true,
  hiringDrivers: true,
  integrationStatuses: true,
  isExpired: true,
};

/** @typedef {{ component: string; state: boolean }} ComponentLoading */

/**
 * @type {(
 *   state: ComponentLoadingState,
 *   action: { payload: unknown; type: string },
 * ) => ComponentLoadingState}
 */
const componentLoading = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOADING_STATE:
      // eslint-disable-next-line no-case-declarations -- predates description requirement
      const { payload } = /** @type {{ payload: ComponentLoading }} */ (action);
      return {
        ...state,
        [payload.component]: payload.state,
      };
    case LOGOUT:
    case SET_SELECTED_COMPANY:
    case SET_SCENARIO_ID:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default componentLoading;
