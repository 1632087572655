import { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';
import CrossIcon from '@bill/cashflow.assets/cross';
import DeleteIcon from '@bill/cashflow.assets/delete';
import {
  getAllDepartments as getAllDepartmentsAction,
  getEmployees as getEmployeesAction,
  subscribeToEmployeeUpdateAction,
} from '@/actions/employees';
import EmployeeGrid from '@/components/Employee/EmployeeGrid';
import TableExportButton from '@/components/TableExportButton';
import Chip from '@/components/common/Chip';
import IconButton from '@/components/common/IconButton';
import Tabs from '@/components/common/Tabs';
import { actualsFamily } from '@/constants/actuals';
import { employeeFormModes } from '@/constants/employees';
import { classNames } from '@/helpers';
import useWsSubscription from '@/hooks/useWsSubscription';
import ActualsGrid from '@/pages/Actuals/ActualsGrid';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import { getCurrentEmployeeInfo } from '@/services/employee.service';
import EditForm from './EditForm';
import EmployeeDelete from './EmployeeDelete';
import './EmployeeTable.scss';

const EMPLOYEE_ACTUALS = '/actuals';

const EmployeesList = ({
  getEmployees,
  getAllDepartments,
  departments,
  startDate,
  endDate,
  show,
  setShow,
  selectedCompany,
  scenarioId,
  subscribeToEmployeeUpdate,
  onAddEmployeeToGrid,
  onDeleteUnsaved,
  onDuplicate,
  employees,
  cellFocus,
  setCellFocus,
}) => {
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editFormMode, setEditFormMode] = useState(employeeFormModes.CREATE);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const selectedCompanyId = selectedCompany?.id;

  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const gridApi = useRef(null);

  useEffect(() => {
    if (!selectedCompanyId) return;
    getAllDepartments(selectedCompanyId);
    getEmployees(scenarioId, startDate, endDate);
  }, [
    getAllDepartments,
    getEmployees,
    selectedCompanyId,
    scenarioId,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (currentEmployee && [employeeFormModes.EDIT].includes(editFormMode)) {
      setShow(true);
    }
  }, [currentEmployee, setShow, editFormMode]);

  useWsSubscription(
    () =>
      subscribeToEmployeeUpdate(scenarioId, () => {
        getAllDepartments(selectedCompanyId);
        getEmployees(scenarioId, startDate, endDate);
      }),
    [scenarioId],
  );

  const handleClose = () => {
    setShow(false);
    setCurrentEmployee(null);
    setEditFormMode(employeeFormModes.CREATE);
  };

  const onEditEmployee = useCallback(
    async (employeeId) => {
      setEditFormMode(employeeFormModes.EDIT);
      const {
        data: { data: employeeData },
      } = await getCurrentEmployeeInfo(employeeId, scenarioId);
      setCurrentEmployee(employeeData);
    },
    [scenarioId, setCurrentEmployee],
  );

  const onDuplicateEmployee = useCallback(
    async (employeeId) => {
      setEditFormMode(employeeFormModes.DUPLICATE);
      const {
        data: { data: employeeData },
      } = await getCurrentEmployeeInfo(employeeId, scenarioId);
      setCurrentEmployee(employeeData);
    },
    [scenarioId, setCurrentEmployee],
  );

  const onDeleteEmployee = useCallback(
    (data) => {
      if (data.isUnsaved) {
        onDeleteUnsaved([data.id]);
        return;
      }
      setCurrentEmployee(data);
      setShowDeleteModal(true);
    },
    [setCurrentEmployee, setShowDeleteModal, onDeleteUnsaved],
  );

  const unselectAll = () => {
    setSelectedEmployees([]);
    gridApi.current.api.deselectAll();
  };

  const ControlsMenu = (
    <div className="EmployeeTable_ControlsContainer">
      <div className="EmployeeTable_ChipContainer">
        <Chip
          color="#628ff8"
          className="EmployeeTable_Chip"
          data-testid="selected-employee-count"
        >
          SELECTED: {selectedEmployees.length}{' '}
          <CrossIcon
            className="EmployeeTable_RemoveIcon"
            onClick={unselectAll}
          />
        </Chip>
      </div>
      <div className="EmployeeTable_ControlsIconContainer">
        <IconButton
          data-testid="bulk-delete-button"
          Icon={DeleteIcon}
          label="Delete"
          onClick={() => {
            return selectedEmployees.length && setShowDeleteModal(true);
          }}
          className={classNames(
            'EmployeeTable_DeleteIcon',
            showDeleteModal && 'EmployeeTable_DeleteIcon-active',
          )}
        />
      </div>
    </div>
  );

  return (
    <div className="EmployeeList">
      {show && (
        <EditForm
          handleClose={handleClose}
          viewMode={editFormMode}
          departments={departments}
          editRecord={currentEmployee}
        />
      )}
      <div className="Panel Panel-toEdge">
        <Tabs
          controls={
            pathname === `${path}${EMPLOYEE_ACTUALS}` ? (
              <TableExportButton
                data-testid="employee-actuals-export"
                onClick={() => gridApi.current.api.exportDataAsExcel()}
              />
            ) : (
              selectedEmployees.length && ControlsMenu
            )
          }
        >
          <Tabs.Panel path={path} label="Employee Summary">
            <EmployeeGrid
              apiRef={gridApi}
              onEditEmployee={onEditEmployee}
              onDuplicateEmployee={onDuplicateEmployee}
              onDeleteEmployee={onDeleteEmployee}
              onSelectEmployees={setSelectedEmployees}
              onAddEmployeeToGrid={onAddEmployeeToGrid}
              onDuplicate={onDuplicate}
              employees={employees}
              onDeleteUnsaved={onDeleteUnsaved}
              cellFocus={cellFocus}
              setCellFocus={setCellFocus}
            />
          </Tabs.Panel>
          <Tabs.Panel
            path={`${path}${EMPLOYEE_ACTUALS}`}
            label="Employee Details"
          >
            <ActualsGrid
              actualFamily={actualsFamily.PAYROLL}
              apiRef={gridApi}
            />
          </Tabs.Panel>
        </Tabs>
      </div>

      <EmployeeDelete
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        currentEmployee={currentEmployee}
        setCurrentEmployee={setCurrentEmployee}
        selectedEmployees={selectedEmployees}
        setSelectedEmployees={setSelectedEmployees}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  startDate: state.shared.startDate,
  endDate: state.shared.endDate,
  selectedCompany: getSelectedCompany(state),
  departments: state.employees.departments,
  scenarioId: state.scenario.scenarioId,
});

export default connect(mapStateToProps, {
  getEmployees: getEmployeesAction,
  getAllDepartments: getAllDepartmentsAction,
  subscribeToEmployeeUpdate: subscribeToEmployeeUpdateAction,
})(EmployeesList);
