import { forwardRef, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { formatPercent } from '@/helpers';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import GrossMarginChart from '@/pages/Dashboard/charts/GrossMarginChart';

const GrossMarginGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [grossMargins, setGrossMargins] = useState({});
    const { startDate, endDate } = useSelector(({ shared }) => shared);
    const onQueryStateChange = useCallback(({ data, isLoading }) => {
      setGrossMargins({
        averageGM: data?.averageGM,
        isLoading,
      });
    }, []);

    return (
      <ChartWidget
        ref={ref}
        url="/dashboard/gross-margin"
        title="Gross Margin"
        metricValue={
          !grossMargins.isLoading ? formatPercent(grossMargins.averageGM) : ''
        }
        label={formatRangeWithShortYear(startDate, endDate)}
        data-testid="grossMarginWidget"
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={formatRangeWithShortYear(startDate, endDate)}
              value={
                !grossMargins.isLoading
                  ? formatPercent(grossMargins.averageGM)
                  : ''
              }
            />
            <GrossMarginChart
              onQueryStateChange={onQueryStateChange}
              {...childProps}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default GrossMarginGlance;
