// @ts-check
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import InputMask from 'inputmask';
import PropTypes from 'prop-types';
import FormField from '@/components/common/FormField';

/**
 * @typedef {Override<
 *   import('@/components/common/FormField').FormFieldProps,
 *   {
 *     onChange: (params?: string) => void;
 *     maskOptions: Inputmask.Options;
 *     value: string;
 *     onFocus?: React.FocusEventHandler<HTMLInputElement>;
 *     onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
 *   }
 * >} MaskedFormFieldProps
 */

/**
 * Renders an input field where the value is masked according to the given
 * parameters (e.g. dates in MM/DD/YYYY).
 *
 * @example
 *   <MaskedFormField
 *     id="foo"
 *     value={bar}
 *     onChange={(maskedValue) => setBar(maskedValue)}
 *   />;
 *
 * @type {React.ForwardRefRenderFunction<
 *   HTMLInputElement,
 *   MaskedFormFieldProps
 * >}
 */
const MaskedFormFieldFn = (
  { value, id, onChange, maskOptions, onFocus, onKeyDown, ...props },
  ref,
) => {
  /** @type {ReturnType<typeof useRef<HTMLInputElement>>} */
  const input = useRef();
  /** @type {ReturnType<typeof useRef<Inputmask.Instance>>} */
  const mask = useRef();
  const justFocused = useRef(false);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  // Reuse the local ref for any forwarded ref, e.g. ag-Grid
  useImperativeHandle(ref, () => input.current);

  useEffect(() => {
    const oncleared = () => onChange();
    const oncomplete = ({ target }) => {
      const newValue = target.value;
      // If complete is triggered by a prefill and the user isn't done
      // typing, firing onChange will reset their caret.
      if (newValue && target.selectionStart === newValue.length) {
        onChange(newValue);
      }
    };

    if (!mask.current) {
      mask.current = new InputMask({
        noValuePatching: true,
        showMaskOnHover: false,
        positionCaretOnTab: false,
        oncleared,
        oncomplete,
        ...maskOptions,
      });
    } else {
      mask.current.option({
        oncleared,
        oncomplete,
        ...maskOptions,
      });
    }

    return () => mask.current.remove();
  }, [maskOptions, onChange]);

  /**
   * If this component rerenders while the user is typing, React will replace
   * the input element but Inputmask will not know to reapply the value, leaving
   * it empty. This hook tells Inputmask to remask on every render, and we
   * reduce rerenders by memoizing this component.
   */
  useEffect(() => {
    if (!input.current.inputmask) {
      mask.current.mask(input.current).setValue(localValue);
    }
  }); // Do not add a dependency array here!

  return (
    <FormField
      ref={input}
      id={id}
      value={localValue}
      onBlur={({ target }) => {
        const newValue = target.value;
        if (newValue !== value && target.inputmask.isComplete()) {
          onChange(target.value);
        }
      }}
      onChange={({ target }) => setLocalValue(target.value)}
      onFocus={(event) => {
        justFocused.current = true;
        onFocus?.(event);
      }}
      onKeyDown={(event) => {
        justFocused.current = false;
        onKeyDown?.(event);
      }}
      onKeyUp={({ key }) => {
        // Select the entire mask if the user tabs into the field
        if (key === 'Tab' && justFocused.current) {
          input.current?.setSelectionRange(
            0,
            mask.current.getemptymask().length,
          );
          justFocused.current = false;
        }
      }}
      {...props}
    />
  );
};

const MaskedFormField = forwardRef(MaskedFormFieldFn);

MaskedFormField.propTypes = {
  /**
   * A unique ID to associate the field with its label, and to select it in
   * unit/integration tests
   */
  id: PropTypes.string.isRequired,
  /**
   * Options for configuring the mask
   *
   * @see https://github.com/RobinHerbots/Inputmask#options
   */
  maskOptions: PropTypes.objectOf(PropTypes.any).isRequired,
  /**
   * Event handler called when the user completes or clears the field
   *
   * @param {string} newValue
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Event handler called when the user focused on the field
   *
   * @param {Object} event
   */
  onFocus: PropTypes.func,
  /**
   * Event handler called when the user presses a key within the field
   *
   * @param {Object} event
   */
  onKeyDown: PropTypes.func,
  /** The current value of the field */
  value: PropTypes.string,
};

export default React.memo(MaskedFormField);
