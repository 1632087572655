import { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  employeesByDepartment as employeesByDepartmentAction,
  getEmployees as getEmployeesAction,
} from '@/actions/employees';
import Button from '@/components/common/Button';
import BulkUploadButton from '@/components/common/DocumentUploader/BulkUploadButton';
import DocumentUploader from '@/components/common/DocumentUploader/DocumentUploader';
import {
  statuses,
  acceptedFileExtensionTypes,
} from '@/components/common/DocumentUploader/constants';
import Modal from '@/components/common/Modal';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';
import { downloadCsvFile } from '@/helpers';
import UploadErrorsModal from '@/pages/Employee/UploadErrorsModal';
import '@/pages/Employee/UploadErrorsModal.scss';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import {
  editMultipleEmployees,
  getExistingEmployeesCsv,
} from '@/services/employee.service';
import './EditMultipleEmployees.scss';

const initialState = {
  status: statuses.INIT,
  initiatedAt: null,
  progressLoaded: 0,
  progressTotal: 0,
  files: null,
  error: null,
  cancelToken: null,
};

const DropzoneContent = () => {
  return (
    <p className="Dropzone_Text">
      Upload your employees spreadsheet by dragging it here or{' '}
      <button type="button" className="Button Button-primaryLink">
        browse
      </button>{' '}
      your local drive.
    </p>
  );
};

const EditMultipleEmployees = ({
  onClose,
  scenarioId,
  companyId,
  startDate,
  endDate,
  getEmployeesAction: getEmployees,
  employeesByDepartmentAction: employeesByDepartment,
}) => {
  const [showErrorsModal, setShowErrorsModal] = useState(false);
  const [state, setState] = useState(initialState);

  const resetState = () => setState(initialState);

  const handleFinish = () => {
    getEmployees(scenarioId, startDate, endDate);
    employeesByDepartment(startDate, endDate, scenarioId);
    onClose();
  };

  const onFilesSelected = (droppedFiles) => {
    setState((previousState) => ({
      ...previousState,
      status: statuses.FILE_SELECTED,
      files: droppedFiles,
    }));
  };

  const onSelectedFilesRejected = () => {
    setState((previousState) => ({
      ...previousState,
      status: statuses.FILE_SELECTED_ERROR,
    }));
  };

  const onCancelUpload = () => {
    state.cancelToken?.cancel();
    resetState();
  };

  const onUploadProgress = (event) => {
    setState((previousState) => ({
      ...previousState,
      progressLoaded: event.loaded,
      progressTotal: event.total,
    }));
  };

  const getCancelToken = () => {
    const axiosToken = axios.CancelToken.source();
    setState((previousState) => ({
      ...previousState,
      cancelToken: axiosToken,
    }));
    return axiosToken.token;
  };

  const handleUpload = async () => {
    setState((previousState) => ({
      ...previousState,
      initiatedAt: new Date(),
      status: statuses.UPLOADING,
    }));
    try {
      await editMultipleEmployees({
        file: state.files[0],
        onUploadProgress,
        getCancelToken,
        params: { scenarioId, companyId, updateFlag: false },
      });
      setState((previousState) => ({
        ...previousState,
        status: statuses.UPLOADING_COMPLETE,
      }));
    } catch (e) {
      if (axios.isCancel(e)) {
        resetState();
        return;
      }

      const { errorDetails, errorMessage, childErrors } = e.response.data.error;
      setState((previousState) => ({
        ...previousState,
        error: {
          title: errorMessage,
          body: errorDetails,
          childErrors,
        },
      }));
      setState((previousState) => ({
        ...previousState,
        status: statuses.ERROR,
      }));
    }
  };

  const downloadSpreadsheet = async () => {
    try {
      const { data } = await getExistingEmployeesCsv(scenarioId, companyId);

      if (data) {
        downloadCsvFile(data);
      }
    } catch (e) {
      // eslint-disable-next-line no-console -- predates description requirement
      console.log(e);
    }
  };

  return (
    <>
      <ModalBase
        className="EditMultipleEmployeesModal"
        id="edit-multiple-employees-modal"
        header={<h2 className="ModalBase_Heading">Edit Multiple Employees</h2>}
        footer={
          <>
            <Button
              className="Button Button-cancelLink"
              onClick={() => {
                state.cancelToken?.cancel();
                onClose();
              }}
              data-testid="edit-multiple-employees-cancel-button"
            >
              Cancel
            </Button>
            <BulkUploadButton
              handleFinish={handleFinish}
              handleUpload={handleUpload}
              resetModal={resetState}
              status={state.status}
            />
          </>
        }
      >
        <p>
          You can{' '}
          <button
            className="Button Button-primaryLink"
            onClick={downloadSpreadsheet}
          >
            <strong>download a spreadsheet</strong>
          </button>{' '}
          of your existing employees and make any changes or updates. Once
          you’re done, save your changes and upload the spreadsheet.
        </p>
        <DocumentUploader
          id="multiple-employee-dropzone"
          acceptedFileTypes={acceptedFileExtensionTypes.CSV}
          data-testid="multiple-employee-dropzone"
          onFilesSelected={onFilesSelected}
          onSelectedFilesRejected={onSelectedFilesRejected}
          onCancelUpload={onCancelUpload}
          uploadState={state}
          error={state.error}
          DropzoneContent={DropzoneContent}
        >
          {state.error?.childErrors?.length ? (
            <>
              There are missing required fields or contain incorrect data.
              Please update your CSV file and upload again. Click{' '}
              <Button
                data-testid="error-modal-trigger"
                className="Button-deleteLink Button-dropzoneError"
                onClick={() => setShowErrorsModal(true)}
              >
                here
              </Button>{' '}
              to view the specific records which will need your attention.
            </>
          ) : (
            state.error?.body
          )}
        </DocumentUploader>
      </ModalBase>
      <Modal
        className="UploadErrors_Modal"
        data-testid="upload-errors-employees-modal"
        open={showErrorsModal}
      >
        <UploadErrorsModal
          errors={state.error?.childErrors}
          onClose={() => setShowErrorsModal(false)}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  startDate: state.shared.startDate,
  endDate: state.shared.endDate,
  scenarioId: state.scenario.scenarioId,
  companyId: getSelectedCompany(state).id,
});
export default connect(mapStateToProps, {
  employeesByDepartmentAction,
  getEmployeesAction,
})(EditMultipleEmployees);
