import VarianceIcon from '@bill/cashflow.assets/variance-icon';
import PropTypes from 'prop-types';
import LetterIcon from '@/components/common/LetterIcon';
import metricFormatters from '@/helpers/metricFormatters';
import { isEmptyOrNull } from '@/helpers/validators';
import ChartMetrics from './ChartMetrics';
import HighchartsTooltipPortal from './HighchartsTooltipPortal';
import './ChartTooltip.scss';

const defaultValueFormatter = (value) => metricFormatters.monetary(value);

/**
 * Renders the tooltip for a Highcharts chart
 *
 * @example
 *   <ChartTooltip metrics={[{ key: 'fooProp', name: 'Foo' }, ...]} />
 */
function ChartTooltip({
  metrics,
  valueKey,
  valueFormatter = defaultValueFormatter,
  timePeriod,
  endDate,
  ...props
}) {
  return (
    <HighchartsTooltipPortal
      endDate={endDate}
      timePeriod={timePeriod}
      {...props}
    >
      {({
        scenarios,
        hoveredScenario,
        tooltipLabel,
        getChartMetrics,
        ...context
      }) => {
        const metricsArray =
          typeof metrics === 'function' ? metrics(context) : metrics;
        const { chartMetrics, mainMetrics, mainMetricVariance } =
          getChartMetrics(valueKey);

        const { point, series } = context;
        const total =
          series.userOptions.dataLabels.formatter?.call(point) ??
          valueFormatter(hoveredScenario.mainMetric, context);

        return (
          <div className="ChartTooltip_Inner">
            {scenarios.length > 1 && (
              <h3 className="ChartTooltip_Scenario">{hoveredScenario.name}</h3>
            )}
            <h4 className="ChartTooltip_Title">{tooltipLabel}</h4>
            <div
              className="ChartTooltip_TotalWrapper"
              style={{ color: hoveredScenario.color }}
            >
              <span
                key={hoveredScenario.name}
                className="ChartTooltip_Total ChartTooltip_Total-expanded"
              >
                {total}
              </span>
              {mainMetrics.map((value, idx) => {
                if (isEmptyOrNull(value)) return null;

                /** @type {import('@/types/scenario').Scenario} */
                const scenario = scenarios[idx];
                const { color, name, scenarioId } = scenario;
                return (
                  <span key={scenarioId} className="ChartTooltip_Total">
                    <LetterIcon
                      string={name}
                      color={color}
                      className="ChartTooltip_TotalIcon"
                      data-testid={`tooltip-${name}-kpiIcon`}
                    />
                    {valueFormatter(value, context)}
                  </span>
                );
              })}
              {scenarios.length > 1 && !isEmptyOrNull(mainMetricVariance) && (
                <span className="ChartTooltip_Total">
                  <VarianceIcon className="VarianceIcon" />
                  {valueFormatter(
                    mainMetricVariance === 0
                      ? mainMetricVariance
                      : mainMetricVariance * -1,
                    context,
                  )}
                </span>
              )}
            </div>
            {metricsArray && (
              <ChartMetrics
                data={chartMetrics}
                data-testid="tooltip-metrics"
                metrics={metricsArray}
                valueFormatter={(value) => valueFormatter(value, context)}
                scenarios={scenarios}
              />
            )}
          </div>
        );
      }}
    </HighchartsTooltipPortal>
  );
}

ChartTooltip.propTypes = {
  /**
   * An array (or function returning one) of additional metrics to display in
   * the tooltip
   */
  metrics: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  /**
   * Formats the tooltip KPI for display, defaulting to monetary
   *
   * @param {number} value
   */
  valueFormatter: PropTypes.func,
  /**
   * The data property containing the KPI value for the tooltip. Defaults to
   * 'y'.
   */
  valueKey: PropTypes.string,
  /** Intervals of the months */
  timePeriod: PropTypes.string,
  /** End date of the chart */
  endDate: PropTypes.string,
};

export default ChartTooltip;
