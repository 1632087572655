// @ts-check
const valueFaultTypes = /** @type {const} */ {
  CIRCULAR_REFERENCE: 'CIRCULAR_REFERENCE',
};

/** @typedef {(typeof valueFaultTypes)[keyof typeof valueFaultTypes]} ValueFaultTypes */

/**
 * @type {(params: {
 *   faulted: boolean;
 *   variableValueFaultType: ValueFaultTypes;
 * }) => boolean}
 */
const detectCircularRef = (value) =>
  value?.faulted &&
  value?.variableValueFaultType === valueFaultTypes.CIRCULAR_REFERENCE;

export default detectCircularRef;
