import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import InfoIcon from '@bill/cashflow.assets/info';
import RightArrowIcon from '@bill/cashflow.assets/right-arrow';
import PropTypes from 'prop-types';
import ChartControls from '@/components/Charts/ChartControls';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';
import Link from '@/components/common/Link';
import WithTooltip from '@/components/common/WithTooltip';
import { ERROR_BOUNDARY_TEXT } from '@/constants/charts';
import { AXIS_STYLES, PLOT_OPTIONS, TOOLTIP_OPTIONS } from '@/constants/widget';
import { classNames } from '@/helpers';
import useElementSize from '@/hooks/useElementSize';
import useIntersection from '@/hooks/useIntersection';
import './Widget.scss';

/**
 * A wrapper for a dashboard view, rendering the container, title, chart
 * controls and clickthrough navigation.
 *
 * @example
 *   <Widget title="Foo" url="/bar" data-testid="foo">
 *     {({ exportBtn, plotOptions, tooltipOptions }) => (
 *       <DateChart exportBtn={exportBtn} plotOptions={plotOptions}>
 *         ...
 *       </DateChart>
 *     )}
 *   </Widget>;
 *
 * @type {React.ForwardRefRenderFunction<
 *   HTMLElement | null,
 *   import('@/types/dashboard').WidgetProps
 * >}
 */
const Widget = forwardRef(
  (
    {
      children,
      className,
      controls,
      'data-testid': dataTestId,
      title,
      label,
      url,
      tooltipContent,
      metricValue = '',
      Icon,
      style,
      isLoading = false,
      ...props
    },
    ref,
  ) => {
    const { scenarioId, compareScenarioId } = useSelector(
      ({ scenario }) => scenario,
    );
    const { startDate, endDate } = useSelector(({ shared }) => shared);

    const widget = useRef();
    useImperativeHandle(ref, () => widget.current);
    const isVisible = useIntersection(widget);
    const { contentRect } = useElementSize(widget);
    const isTrend = contentRect?.height < 200;
    const isPortrait = contentRect?.width < 382;

    const exportBtn = useRef();
    const [render, setRender] = useState(false);
    useEffect(() => {
      // Wait to load widget contents until its in the viewport
      if (isVisible) setRender(true);
    }, [exportBtn, isVisible]);

    useEffect(() => {
      // Unload the widget if the scenarios or date range change so that we're
      // not reloading the entire dashboard at once
      if (!isVisible) setRender(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
    }, [scenarioId, compareScenarioId, startDate, endDate]);

    return (
      <section
        className={classNames(
          'Widget',
          isTrend && 'Widget-trend',
          isPortrait && 'Widget-portrait',
          className,
        )}
        ref={widget}
        data-testid={dataTestId}
        style={style}
        {...props}
      >
        <div className="Widget_Header">
          <div className="Widget_HeaderLeft">
            <h2 className="Widget_Title">
              {url ? (
                <Link
                  to={url}
                  className="Widget_TitleLink"
                  data-testid={`${dataTestId}-link`}
                >
                  {Icon && <Icon className="Widget_TitleIcon" />}
                  {title}
                  <RightArrowIcon className="Widget_TitleArrow" />
                </Link>
              ) : (
                <>
                  {Icon && <Icon className="Widget_TitleIcon" />}
                  {title}
                </>
              )}
            </h2>
            {tooltipContent && (
              <WithTooltip
                data-testid={`${dataTestId}-info-tooltip`}
                content={tooltipContent}
              >
                <span
                  className="Widget_InfoIcon"
                  data-testid={`${dataTestId}-info-icon`}
                >
                  <InfoIcon />
                </span>
              </WithTooltip>
            )}
          </div>
          <ChartControls
            chartRef={exportBtn}
            title={title}
            metricValue={metricValue}
            label={label}
            data-testid={`${dataTestId}-chartControls`}
            disabled={isLoading}
          >
            {controls}
          </ChartControls>
        </div>
        <div className="Widget_Content">
          <CommonErrorBoundary text={ERROR_BOUNDARY_TEXT}>
            {render &&
              children({
                exportBtn,
                axisStyles: AXIS_STYLES,
                isTrend,
                plotOptions: PLOT_OPTIONS,
                tooltipOptions: TOOLTIP_OPTIONS,
              })}
          </CommonErrorBoundary>
        </div>
      </section>
    );
  },
);

Widget.propTypes = {
  /**
   * Content of the widget, as a render prop
   *
   * @param {Object} params Parameters to pass to the widget content
   * @param {HTMLElement} params.exportBtn Container in which to render the
   *   export button
   * @param {Object} params.plotOptions Configuration options for the chart
   * @param {Object} params.tooltipOptions Configuration options for the chart
   *   tooltip
   * @returns content for the widget
   */
  'children': PropTypes.func.isRequired,
  /** Additional class(es) to apply to the widget */
  'className': PropTypes.string,
  /** Optional control(s) to affect the content, such as a toggle */
  'controls': PropTypes.node,
  /**
   * Identifies the widget container in unit and integration tests, and serves
   * as a prefix for the IDs of its children
   */
  'data-testid': PropTypes.string.isRequired,
  /** Inline styles to apply to the widget, supplied by react-grid-layout */
  'style': PropTypes.objectOf(PropTypes.string),
  /** The title of the widget */
  'title': PropTypes.string,
  /** URL to navigate to when the user clicks the widget title */
  'url': PropTypes.string,
  /** Tooltip content to display when the user hovers the info icon */
  'tooltipContent': PropTypes.string,
  /** Metric Value against selected date range */
  'metricValue': PropTypes.string,
  /** Date range describing the value */
  'label': PropTypes.string,
  /** Icon on widget title */
  'Icon': PropTypes.elementType,
  /** Shows a loading state */
  'isLoading': PropTypes.bool,
};

export default Widget;
