// @ts-check
import { useEffect, useMemo } from 'react';
import useIntegrationStates from '@/hooks/useIntegrationStates';
import { actionTypes } from './actionTypes';

const handleBeforeUnload = (state) => () => {
  window.sessionStorage.setItem('integrationStates', JSON.stringify(state));
};

/**
 * A hook for persisting to / reading from sessionStorage the integration button
 * states.
 *
 * @type {(
 *   integrations: import('@/helpers/integrations').IntegrationAllowed[],
 * ) => Pick<
 *   import('@/hooks/useIntegrationStates/index').UseIntegrationStatesResult,
 *   'buttonStates' | 'setButtonStates'
 * >}
 */
const useIntegrationStatesPersisted = (integrations) => {
  const { buttonStates, setButtonStates, uiState } =
    useIntegrationStates(integrations);

  useEffect(() => {
    const data = window.sessionStorage.getItem('integrationStates');

    if (data) {
      setButtonStates({
        type: actionTypes.HYDRATE,
        hydratedState: JSON.parse(data),
      });

      window.sessionStorage.removeItem('integrationStates');
    }
  }, [setButtonStates]);

  useEffect(() => {
    const eventHandler = handleBeforeUnload(uiState);
    window.addEventListener('beforeunload', eventHandler);

    return () => {
      window.removeEventListener('beforeunload', eventHandler);
    };
  }, [uiState]);

  return useMemo(
    () => ({ buttonStates, setButtonStates }),
    [buttonStates, setButtonStates],
  );
};

export default useIntegrationStatesPersisted;
