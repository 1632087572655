import WarningIcon from '@bill/cashflow.assets/warning';
import WithTooltip from '@/components/common/WithTooltip';
import './WithErrorRenderer.scss';

/**
 * A cell renderer for showing an error icon with a tooltip, for use in ag-Grid
 *
 * @example
 *   columnDefs={[
 *    {
 *      ...
 *      cellRenderer: WithErrorRenderer
 *      cellRendererParams: { tooltip: 'Foo' }
 *    }
 *   ]}
 *
 * @see https://www.ag-grid.com/react-grid/component-cell-renderer/
 */
function WithErrorRenderer({ tooltip, valueFormatted, value }) {
  return (
    <div className="WithErrorRenderer">
      <span className="WithErrorRenderer_Value">{valueFormatted ?? value}</span>
      <WithTooltip content={tooltip} placement="bottom">
        <span>
          <WarningIcon className="WithErrorRenderer_Icon" />
        </span>
      </WithTooltip>
    </div>
  );
}

export default WithErrorRenderer;
