// @ts-check
import { useEffect, useState } from 'react';
import { BillProfileType } from '@/contexts/BillSessionProvider';
import useBillSession from '@/hooks/useBillSession';

export const forecastingActions = /** @type {const} */ ({
  SHOW_UPGRADE_TO_FORECASTING: 'SHOW_UPGRADE_TO_FORECASTING',
  ASK_ADMIN_FOR_FORECASTING: 'ASK_ADMIN_FOR_FORECASTING',
  ADMIN_ACCESS_FORECASTING: 'ADMIN_ACCESS_FORECASTING',
});

/** @typedef {keyof typeof forecastingActions} ForecastingAction */

/**
 * Hook to determine the action for onboarding forecasting access based on user
 * session information.
 *
 * @type {() => ForecastingAction}
 */
const useOnboardForecastingAccess = () => {
  const { sessionInfo } = useBillSession();
  const [onboardForecastingAccess, setOnboardForecastingAccess] =
    useState(null);

  const isEssentialPlan =
    sessionInfo?.neoFunctionality?.OrgFeatures?.isPlanningAvailableForUpgrade;
  const isTeamsPlan =
    sessionInfo?.neoFunctionality?.OrgFeatures?.isPlanningAvailable;
  const userRole = sessionInfo?.profile.type;
  const isAdmin = userRole === BillProfileType.Admin;
  const isAccountant = userRole === BillProfileType.Accountant;
  const isApprover = userRole === BillProfileType.Approver;

  useEffect(() => {
    switch (true) {
      case isEssentialPlan && isAdmin:
        setOnboardForecastingAccess(
          forecastingActions.SHOW_UPGRADE_TO_FORECASTING,
        );
        break;
      case isApprover && isTeamsPlan:
      case (isApprover || isAccountant) && isEssentialPlan:
        setOnboardForecastingAccess(
          forecastingActions.ASK_ADMIN_FOR_FORECASTING,
        );
        break;
      case (isAdmin || isAccountant) && isTeamsPlan:
        setOnboardForecastingAccess(
          forecastingActions.ADMIN_ACCESS_FORECASTING,
        );
        break;
      default:
        setOnboardForecastingAccess('');
        break;
    }
  }, [isEssentialPlan, isTeamsPlan, isAdmin, isAccountant, isApprover]);
  return onboardForecastingAccess;
};

export default useOnboardForecastingAccess;
