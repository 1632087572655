// @ts-check
import { useMemo } from 'react';
import RightArrowIcon from '@bill/cashflow.assets/right-arrow';
import Accordion from '@/components/common/Accordion';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import './CollapsibleRulesPreview.scss';

/** @typedef {{ rule: import('./types').Rule }} RulesListProps */

/** @type {(props: RulesListProps) => React.ReactElement} */
const CollapsibleRulesPreview = ({ rule }) => {
  const pricingPlanName = useMemo(
    () =>
      rule.pricingPlanId
        ? rule.revenueStream?.pricingPlans?.find(
            ({ planId }) => planId === rule.pricingPlanId,
          ).planName
        : '',
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [rule.revenueStream],
  );

  const ruleDetails = useMemo(() => {
    const streamNameTag = rule.revenueStream?.streamName ?? '';
    const pricingPlanTag = pricingPlanName;

    const criterion = rule.expression?.criterion;
    const logicalExpr = rule.expression?.logicalExpr;

    /** @type {import('@/services/revenueService').Criteria[]} */
    const criteriaTags = logicalExpr?.criteria
      ? logicalExpr.criteria
      : [criterion];

    return { streamNameTag, pricingPlanTag, criteriaTags };
  }, [rule.revenueStream, pricingPlanName, rule.expression]);

  return (
    <Accordion label={rule.name}>
      <div className="RulesPreview">
        <span className="RulesPreview_Title">Destination:</span>

        <div className="RulesPreview_TagWrapper">
          <div className="RulesPreview-flex">
            <div className="RulesPreview_Tag">
              <TooltipTextOverflow
                data-testid={`rules-preview-tag-${ruleDetails.streamNameTag}`}
                label={ruleDetails.streamNameTag}
              />
            </div>
            {ruleDetails.pricingPlanTag && (
              <>
                <RightArrowIcon
                  className="RulesPreview_ArrowIcon"
                  aria-hidden="true"
                />
                <div className="RulesPreview_Tag">
                  <TooltipTextOverflow
                    data-testid={`rules-preview-tag-${ruleDetails.pricingPlanTag}`}
                    label={ruleDetails.pricingPlanTag}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="RulesPreview RulesPreview_Criteria">
        <span className="RulesPreview_Title">Criteria:</span>
        <div className="RulesPreview_TagWrapper">
          {ruleDetails.criteriaTags.map(
            ({ operand, operator, value }, index) => (
              // eslint-disable-next-line react/no-array-index-key -- predates description requirement
              <div className="RulesPreview-flex" key={`${rule.name}-${index}`}>
                <div className="RulesPreview_Tag">
                  <TooltipTextOverflow
                    data-testid={`rules-preview-tag-operand-${operand}`}
                    label={operand}
                  />
                </div>
                <RightArrowIcon
                  className="RulesPreview_ArrowIcon"
                  aria-hidden="true"
                />
                <div className="RulesPreview_Tag">
                  <TooltipTextOverflow
                    data-testid={`rules-preview-tag-operator-${operator}`}
                    label={operator}
                  />
                </div>
                {value && (
                  <>
                    <RightArrowIcon
                      className="RulesPreview_ArrowIcon"
                      aria-hidden="true"
                    />
                    <div className="RulesPreview_Tag">
                      <TooltipTextOverflow
                        data-testid={`rules-preview-tag-value-${value}`}
                        label={value}
                      />
                    </div>
                  </>
                )}
              </div>
            ),
          )}
        </div>
      </div>
    </Accordion>
  );
};

export default CollapsibleRulesPreview;
