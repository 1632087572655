import { useState } from 'react';
import { connect } from 'react-redux';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import { deleteRevenueStreamByID } from '@/services/revenueService';

const RevenueStreamDelete = ({
  handleClose,
  recordId,
  scenarioId,
  startDate,
  endDate,
  showDeleteConfirmation,
  setShowDeleteConfirmation,
  onDelete,
}) => {
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false);
  const [deleteError, setDeleteError] = useState('');

  const handleDelete = async (forceDeletion) => {
    if (recordId) {
      try {
        const response = await deleteRevenueStreamByID(
          scenarioId,
          recordId,
          startDate,
          endDate,
          forceDeletion,
        );
        if (response.success) {
          if (response.data === 'ActualsFound') {
            setShowDeleteConfirmation(false);
            setShowDeleteWarningModal(true);
          } else {
            setShowDeleteWarningModal(false);
            setShowDeleteConfirmation(false);
            onDelete?.();
            handleClose();
          }
        }
      } catch (e) {
        setShowDeleteWarningModal(false);
        setShowDeleteConfirmation(false);
        setDeleteError(e.response?.data?.error?.errorMessage || e.message);
      }
    }
  };
  return (
    <>
      {showDeleteConfirmation && (
        <ModalConfirmation
          id="cannot-delete-modal"
          title="Delete Revenue Stream (Driver)"
          onCancel={() => setShowDeleteConfirmation(false)}
          onAction={() => handleDelete(false)}
          actionBtnTxt="Delete Permanently"
          message="This action cannot be undone. Are you sure you want to proceed?"
        >
          This action cannot be undone. Are you sure you want to proceed?
        </ModalConfirmation>
      )}

      {showDeleteWarningModal && (
        <ModalConfirmation
          id="modal-revenue-stream-delete"
          onCancel={() => setShowDeleteWarningModal(false)}
          onAction={() => handleDelete(true)}
          title="Before you continue."
        >
          Deleting this revenue will remove it from your revenue forecast and
          your actuals. Do you want to proceed?
        </ModalConfirmation>
      )}

      {deleteError && (
        <ModalConfirmation
          id="modal-revenue-delete-error"
          onAction={() => {
            setDeleteError('');
            handleClose();
          }}
          title="Cannot delete revenue stream (driver)"
          actionBtnTxt="Close"
        >
          {deleteError}
        </ModalConfirmation>
      )}
    </>
  );
};

const mapStateToProps = ({ scenario, shared }) => ({
  scenarioId: scenario.scenarioId,
  startDate: shared.startDate,
  endDate: shared.endDate,
});

export default connect(mapStateToProps, {})(RevenueStreamDelete);
