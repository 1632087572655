// @ts-check
import { useReducer, useEffect, useMemo } from 'react';
import { QUICKBOOKS, INTEGRATIONS } from '@/constants/integrations';
import { actionTypes } from './actionTypes';

const states = /** @type {const} */ ({
  INITIAL: 'initial',
  PENDING: 'pending',
});

/** @typedef {(typeof states)[keyof typeof states]} ButtonStates */

/**
 * @private
 * @type {(
 *   state: IntegrationState,
 *   allowedIntegrations: import('@/helpers/integrations').IntegrationAllowed[],
 *   selectedIntegration: import('@/helpers/integrations').IntegrationAllowed['type'],
 * ) => boolean}
 */
const getIsUnavailable = (state, allowedIntegrations, selectedIntegration) => {
  const excludedIntegrations = [selectedIntegration, QUICKBOOKS];
  const hasPending = Object.entries(state)
    .filter(
      ([integration]) =>
        !excludedIntegrations.includes(
          /** @type {import('@/helpers/integrations').IntegrationAllowed['type']} */ (
            integration
          ),
        ),
    )
    .some(([_, integrationState]) => integrationState === states.PENDING);
  return (
    hasPending ||
    !!allowedIntegrations.find(
      ({ type, disabled }) => type === selectedIntegration && disabled,
    )
  );
};

/**
 * @typedef {{
 *   [key in import('@/constants/integrations').Integrations]: ButtonStates;
 * } & {
 *   isRedirecting: boolean;
 * }} IntegrationState
 */

const INITIAL_STATE = /** @type {IntegrationState} */ ({
  ...INTEGRATIONS.reduce(
    (accum, integration) => ({
      ...accum,
      [integration]: states.INITIAL,
    }),
    {},
  ),
  isRedirecting: false,
});

const INITIAL_BUTTONS_STATE = INTEGRATIONS.reduce(
  (accum, integration) => ({ ...accum, [integration]: {} }),
  {},
);

/**
 * @typedef {{
 *       type: import('@/hooks/useIntegrationStates/actionTypes').ActionTypes['RESET_STATE'];
 *     }
 *   | {
 *       type: import('@/hooks/useIntegrationStates/actionTypes').ActionTypes['SET_BUTTON_STATE'];
 *       payload: import('@/constants/integrations').Integrations;
 *     }
 *   | {
 *       type: import('@/hooks/useIntegrationStates/actionTypes').ActionTypes['HYDRATE'];
 *       hydratedState: IntegrationState;
 *     }} ButtonStateDispatachType
 */

/**
 * @private
 * @type {(
 *   state: IntegrationState,
 *   action: ButtonStateDispatachType,
 * ) => IntegrationState}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.RESET_STATE:
      return { ...INITIAL_STATE };
    case actionTypes.SET_BUTTON_STATE:
      return {
        ...state,
        [action.payload]: states.PENDING,
        isRedirecting: true,
      };
    case actionTypes.HYDRATE:
      return {
        ...action.hydratedState,
        isRedirecting: false,
      };
    default:
      return state;
  }
};

/**
 * @typedef {{
 *   buttonStates: { [key: string]: unknown };
 *   setButtonStates: (params: ButtonStateDispatachType) => void;
 *   uiState: { [key: string]: unknown };
 * }} UseIntegrationStatesResult
 */

/**
 * A hook to get the derived button states for each integration depending on its
 * status and the statuses of other integrations within the same "family"
 *
 * @type {(
 *   integrations: import('@/helpers/integrations').IntegrationAllowed[],
 *   dependencies?: unknown[],
 * ) => UseIntegrationStatesResult}
 */
const useIntegrationStates = (integrations, deps = []) => {
  const [uiState, setButtonStates] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    if (integrations.length > 0) {
      setButtonStates({ type: actionTypes.RESET_STATE });
    }
  }, [integrations.length]);

  const buttonStates = useMemo(() => {
    // Merge UI-only states with integrations' states from the server
    return integrations.reduce((accum, integration) => {
      const result = { ...accum };
      const { type: integrationType } = integration;
      const isPending = uiState[integrationType] === states.PENDING;
      result[integrationType] = {
        isPending,
        isUnavailable: isPending
          ? false
          : getIsUnavailable(uiState, integrations, integrationType),
        isSelected: integrations.some(
          ({ type, connected }) => type === integrationType && connected,
        ),
      };
      return result;
    }, INITIAL_BUTTONS_STATE);
  }, [integrations, uiState]);

  return useMemo(
    () => ({ buttonStates, setButtonStates, uiState }),
    [
      buttonStates,
      uiState,
      /* eslint-disable-line react-hooks/exhaustive-deps -- predates description requirement */ ...deps,
    ],
  );
};

export default useIntegrationStates;
