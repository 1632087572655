export const EXPENSE_FORECAST_METHODS = 'expenseForecastMethods';
export const presetFormulas = {
  [EXPENSE_FORECAST_METHODS]: [
    {
      id: 'percent-of-revenue',
      value: 'Revenue.Total * ',
      label: 'As Percent of Revenue',
    },
    {
      id: 'expense-per-employee',
      value: 'Headcount.Total * ',
      label: 'Expense per Employee',
    },
  ],
};
export const PRESET_FORMULA_KEYS = Object.keys(presetFormulas);
export const FAULTED_MSG = '#REF!';

const VALID_FORMULA_MSG = 'Valid Formula';
export default VALID_FORMULA_MSG;
