import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setTMXSessionId } from '@/actions/auth';
import { createFromScenarioAction } from '@/actions/signUp';
import CountryField from '@/components/Settings/Address/CountryField';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';
import ErrorMessages from '@/components/common/ErrorMessages';
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';
import PasswordField, {
  PASSWORD_REGEX,
  PASSWORD_RULE,
} from '@/components/common/PasswordField';
import { DASHBOARD_PATH, UNAUTHORIZED_PATH } from '@/constants/pages';
import Landing from '@/containers/Landing';

const checkValid = (pass) => {
  return PASSWORD_REGEX.test(pass);
};

const CreateScenarioUser = ({
  createFromScenario,
  signUpResponse,
  verificationToken,
  isAuthenticated,
  isUnauthorizedByTMX,
}) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const deviceSessionId = useSelector(({ auth }) => auth.deviceSessionId);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [country, setCountry] = useState('');
  const [confirmAcceptTerms, setConfirmAcceptTerms] = useState(false);

  useEffect(() => {
    document.title = 'Welcome';
  }, []);

  useEffect(() => {
    dispatch(setTMXSessionId());
  }, [dispatch]);

  if (isAuthenticated) {
    return <Redirect to={{ pathname: DASHBOARD_PATH }} />;
  }

  if (isUnauthorizedByTMX) {
    return <Redirect to={{ pathname: UNAUTHORIZED_PATH }} />;
  }

  const isButtonDisable =
    !checkValid(password) ||
    password !== confirmPassword ||
    !confirmAcceptTerms ||
    !country;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await createFromScenario({
        ...signUpResponse,
        userId: signUpResponse.id,
        verificationToken,
        firstName,
        lastName,
        password,
        confirmPassword,
        deviceSessionId,
        country,
      });
    } catch ({ message }) {
      setLoading(false);
      setError(message);
    }
  };

  return (
    <Landing>
      <form
        className="landing-Form landing-Form-welcome"
        onSubmit={handleSubmit}
      >
        <h1 className="Panel_Title">Welcome to Finmark!</h1>
        <ErrorMessages error={error} />
        <p className="landing-Form_Copy">
          So that we can get you started in using Finmark, we just need a couple
          of details from you:
        </p>
        <div className="Form">
          <div className="Form_Group Form_Group-halfWidth">
            <FormLabel htmlFor="lastName" text="First Name" />
            <FormField
              id="firstName"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              validate={() => {
                return !firstName && 'Please enter a valid firstname';
              }}
            />
          </div>
          <div className="Form_Group Form_Group-halfWidth">
            <FormLabel htmlFor="lastName" text="Last Name" />
            <FormField
              id="lastName"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              validate={() => {
                return !lastName && 'Please enter a valid lastname';
              }}
            />
          </div>
          <div className="Form_Group">
            <FormLabel
              htmlFor="password"
              text="Create Password"
              tooltip={PASSWORD_RULE}
            />
            <PasswordField
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="Form_Group">
            <FormLabel htmlFor="confirmPassword" text="Confirm Password" />
            <PasswordField
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              // eslint-disable-next-line consistent-return -- predates description requirement
              validate={() => {
                const valid = password === confirmPassword;
                if (!valid) return 'Password does not match';
              }}
            />
          </div>
          <div className="Form_Group">
            <CountryField
              value={country}
              onChange={({ target }) => setCountry(target.value)}
              label="Country of Residence"
            />
          </div>
          <div className="Form_Group">
            <Checkbox
              className="landing-Form_Checkbox"
              id="confirmAcceptTerms"
              name="confirmAcceptTerms"
              checked={confirmAcceptTerms}
              onChange={() => {
                setConfirmAcceptTerms(!confirmAcceptTerms);
              }}
            >
              I agree to the Finmark{' '}
              <a
                href="https://finmark.com/terms-conditions/"
                className="link"
                target="_blank"
                data-testid="termsAndConditionsLink"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
              ,{' '}
              <a
                href="https://finmark.com/privacy-policy/"
                className="link"
                target="_blank"
                data-testid="privacyPolicyLink"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              , and{' '}
              <a
                href="https://finmark.com/acceptable-use-policy/"
                className="link"
                target="_blank"
                data-testid="aupLink"
                rel="noopener noreferrer"
              >
                Acceptable Use Policy
              </a>
            </Checkbox>
          </div>
        </div>

        <Button
          type="submit"
          className="Button Button-success"
          data-testid="submit"
          disabled={isButtonDisable}
          loading={isLoading}
        >
          Get Started
        </Button>
      </form>
    </Landing>
  );
};

const mapStateToProps = (state) => ({
  signUpResponse: state.signUp.signUpResponse,
  verificationToken: state.auth.verificationToken,
  isAuthenticated: state.auth.isAuthenticated,
  isUnauthorizedByTMX: state.auth.isUnauthorizedByTMX,
});

export default connect(mapStateToProps, {
  createFromScenario: createFromScenarioAction,
})(CreateScenarioUser);
