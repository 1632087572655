import { useMemo, useState, useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DownArrowIcon from '@bill/cashflow.assets/down-arrow';
import NotesIcon from '@bill/cashflow.assets/notes';
import PropTypes from 'prop-types';
import { toggleEditMode } from '@/actions/dashboard';
import ScenarioShareModal from '@/components/Dashboard/ScenarioShareModal';
import ProductTypeFilter from '@/components/ProductTypeFilter/ProductTypeFilter';
import Dropdown from '@/components/common/Dropdown';
import DropdownOption from '@/components/common/DropdownOption';
import InfoTooltip from '@/components/common/InfoTooltip';
import LetterIcon from '@/components/common/LetterIcon';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import Permissions from '@/components/common/Permissions';
import WithTooltip from '@/components/common/WithTooltip';
import { registeredFeatureFlags } from '@/constants/features';
import { actions, subjects } from '@/constants/permissions';
import { classNames, slugify } from '@/helpers';
import isBillDomain from '@/helpers/isBillDomain';
import { isEmptyOrNull } from '@/helpers/validators';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import TheManageScenariosModal from './TheManageScenariosModal';
import { ReactComponent as Share } from '@/assets/icons/svg/share.svg';
import './ScenarioSelect.scss';

const { MANAGE } = actions;
const { SCENARIOS } = subjects;

const SCENARIOS_DEFAULT = [];

const noComparisonLetterOverride = (scenarioId) =>
  scenarioId === -1 ? 'X' : '';

const ScenarioOption = ({ color, name, letter, isSideNav }) => {
  return (
    <span className="ScenarioOption_Wrapper">
      <LetterIcon
        string={letter || name}
        color={color}
        data-testid={`letter-icon-${slugify(name)}`}
        className={isSideNav ? 'LetterIcon_SideNav' : ''}
      />
      <span className={`Scenario_Label${isSideNav ? '-sideNav' : ''}`}>
        {name}
      </span>
    </span>
  );
};

const WrappedScenarioOption = ({
  name,
  scenarioId,
  color,
  notes,
  selectedScenarioId,
  setScenarioId,
  scenarioCount,
}) => {
  const isChecked = scenarioId === selectedScenarioId;
  const wrapperClass = !!isChecked && scenarioCount > 1 ? 'Selected' : '';
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const isOneColorThemeEnabled = useFeatureFlags(
    registeredFeatureFlags.ONE_COLOR_CSS_THEME,
  );
  const isHeaderScenarioSelectEnabled =
    isBillDomain() || isOneColorThemeEnabled;
  const history = useHistory();

  const handleSelectScenario = useCallback(
    (id) => {
      if (isChecked) return;
      history.replace({ search: `` });
      setScenarioId(id);
      dispatch(toggleEditMode(false));
    },
    [isChecked, setScenarioId, dispatch, history],
  );

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events -- predates description requirement */
    <div
      id={`scenario-${scenarioId}`}
      key={scenarioId}
      className={`ScenarioSelect_OptionWrapper ${wrapperClass}`}
      onClick={() => handleSelectScenario(scenarioId)}
      aria-current={isChecked}
    >
      <WithTooltip content={name} data-testid={String(scenarioId)}>
        <div className="ScenarioSelect_TooltipWrapper">
          <DropdownOption
            id={`scenario-option-${scenarioId}`}
            className="ScenarioSelect_DropdownOption"
          >
            <ScenarioOption
              color={color}
              name={name}
              letter={noComparisonLetterOverride(scenarioId)}
              isNoteAdded={!isEmptyOrNull(notes)}
              isSideNav={!isHeaderScenarioSelectEnabled}
            />
          </DropdownOption>
        </div>
      </WithTooltip>
      {notes && (
        <InfoTooltip
          id={`scenario-tooltip-${scenarioId}`}
          data-testid={`scenario-tooltip-${scenarioId}`}
          placement="right"
          icon={<NotesIcon className="NotesIcon" aria-hidden="true" />}
        >
          {notes}
        </InfoTooltip>
      )}
    </div>
  );
};

const WrappedScenarioOptions = ({
  scenario,
  selectedScenario,
  setScenarioId,
}) => {
  return scenario.map((item) => (
    <WrappedScenarioOption
      key={item.scenarioId}
      {...item}
      selectedScenarioId={selectedScenario.scenarioId}
      setScenarioId={setScenarioId}
      scenarioCount={scenario.length}
    />
  ));
};

const ScenarioSelect = ({
  id,
  className,
  scenarios = SCENARIOS_DEFAULT,
  selectedScenarioId,
  setScenarioId,
  scenarioLoader,
  scenarioSelectType,
  label,
}) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const [showManageModal, setShowManageModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const isBaseScenario = scenarioSelectType === 'base';
  const isOneColorThemeEnabled = useFeatureFlags(
    registeredFeatureFlags.ONE_COLOR_CSS_THEME,
  );
  const isHeaderScenarioSelectEnabled =
    isBillDomain() || isOneColorThemeEnabled;

  const allScenarios = useMemo(() => {
    const activeScenarios = scenarios.filter(
      (scenario) => scenario.status === 'ACTIVE',
    );

    if (!isBaseScenario)
      activeScenarios.unshift({
        scenarioId: -1,
        name: 'No Comparison',
        color: '#000000',
        isDefault: false,
        companyId: -1,
        enabledIntegrations: [],
        status: 'ACTIVE',
      });

    return activeScenarios;
  }, [isBaseScenario, scenarios]);

  const selectedScenario = useMemo(
    () =>
      allScenarios.find(
        (scenario) => scenario.scenarioId === selectedScenarioId,
      ),
    [allScenarios, selectedScenarioId],
  );
  const sortedScenarios = useMemo(() => {
    return allScenarios.sort((a, b) => {
      if (a.scenarioId === selectedScenario?.scenarioId) return -1;
      if (b.scenarioId === selectedScenario?.scenarioId) return 1;
      if (a.scenarioId === -1) return -1;
      if (b.scenarioId === -1) return 1;
      return a.isDefault ? -1 : b.isDefault;
    });
  }, [allScenarios, selectedScenario]);

  const handleClose = () => {
    setShowShareModal(false);
  };

  const handleAction = () => {
    setShowShareModal(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div className={classNames('ScenarioWrapper', className)}>
        {!isHeaderScenarioSelectEnabled && (
          <span className="ScenarioSelect_Label">
            <span className="ScenarioHeader">
              <span>{label}</span>
              <ProductTypeFilter>
                <Permissions action={MANAGE} subject={SCENARIOS}>
                  {isBaseScenario && (
                    <button
                      type="button"
                      className="ScenarioSelect_ShareBtn"
                      onClick={() => setShowShareModal(true)}
                      aria-label="Share"
                    >
                      <Share className="ShareIcon" aria-hidden />
                    </button>
                  )}
                </Permissions>
              </ProductTypeFilter>
            </span>
          </span>
        )}
        {selectedScenario && (
          <Dropdown
            id={id}
            className={`ScenarioSelect${
              !isHeaderScenarioSelectEnabled ? '_SideNav' : ''
            }`}
            buttonSlot={
              <>
                <ScenarioOption
                  color={selectedScenario.color}
                  name={selectedScenario.name}
                  letter={noComparisonLetterOverride(
                    selectedScenario.scenarioId,
                  )}
                />
                <span className="ScenarioSelect_Chevron" aria-hidden="true">
                  <DownArrowIcon />
                </span>
              </>
            }
          >
            <div className="ScenarioSelect_List">
              {scenarioLoader ? (
                <LoadingSpinner className="py-5 px-0 text-center" />
              ) : (
                <WrappedScenarioOptions
                  scenario={sortedScenarios}
                  selectedScenario={selectedScenario}
                  setScenarioId={setScenarioId}
                />
              )}
            </div>
            <Permissions action={MANAGE} subject={SCENARIOS}>
              <DropdownOption
                key="manage-scenario"
                id="manage-scenario"
                className="ScenarioSelect_Manage"
                onClick={() => {
                  setShowManageModal(true);
                  dispatch(toggleEditMode(false));
                }}
              >
                Manage your scenarios
              </DropdownOption>
            </Permissions>
          </Dropdown>
        )}
        {isHeaderScenarioSelectEnabled && (
          <>
            {selectedScenario?.notes && (
              <InfoTooltip
                id={`scenario-tooltip-${selectedScenario.scenarioId}`}
                data-testid={`scenario-tooltip-${selectedScenario.scenarioId}`}
                placement="right"
                icon={<NotesIcon className="NotesIcon" aria-hidden="true" />}
              >
                {selectedScenario.notes}
              </InfoTooltip>
            )}
            <ProductTypeFilter>
              <Permissions action={MANAGE} subject={SCENARIOS}>
                {isBaseScenario && (
                  <button
                    type="button"
                    className="ScenarioSelect_ShareBtn"
                    onClick={() => setShowShareModal(true)}
                    aria-label="Share"
                  >
                    <Share className="ShareIcon" aria-hidden />
                  </button>
                )}
              </Permissions>
            </ProductTypeFilter>
          </>
        )}
      </div>
      {showManageModal && (
        <TheManageScenariosModal
          setShowShareModal={setShowShareModal}
          onClose={() => setShowManageModal(false)}
          scenario={selectedScenarioId}
        />
      )}
      {showShareModal && (
        <ScenarioShareModal
          handleClose={handleClose}
          handleAction={handleAction}
          scenarios={scenarios}
          selectedScenarioId={selectedScenarioId}
        />
      )}
    </>
  );
};

ScenarioSelect.propTypes = {
  /** The ID for the component */
  id: PropTypes.string.isRequired,
  /** The className to apply to the component */
  className: PropTypes.string,
  /** An array of Scenarios */
  scenarios: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      companyId: PropTypes.number.isRequired,
      isDefault: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  /** A function to set a scenarioId */
  setScenarioId: PropTypes.func.isRequired,
  /** The selected scenario id */
  selectedScenarioId: PropTypes.number,
  /** The scenario loader indicates loading on dropdown */
  scenarioLoader: PropTypes.bool,
  /** The type of scenario selector */
  scenarioSelectType: PropTypes.oneOf(['base', 'compare']),
  /** The label to show above the scenario selector component */
  label: PropTypes.string.isRequired,
};

export default ScenarioSelect;
