import { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import EmployeesEmptyIcon from '@bill/cashflow.assets/employees-empty';
import { subscribeToActualsUpdateAction } from '@/actions/actuals';
import { subscribeToEmployeeUpdateAction } from '@/actions/employees';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartLegend from '@/components/Charts/ChartLegend';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import PercentBarWithMetrics from '@/components/Charts/PercentBarWithMetrics';
import EmptyData from '@/components/common/EmptyData';
import Toggle from '@/components/common/Toggle';
import { actualsFamily } from '@/constants/actuals';
import { CATEGORY_COLUMN } from '@/constants/employees';
import metricFormatters from '@/helpers/metricFormatters';
import useWsSubscription from '@/hooks/useWsSubscription';
import useTotalPayrollQuery from '@/pages/Dashboard/charts/useTotalPayrollQuery';

const SECONDARY_CHART_TITLE = 'Payroll by Department';

const PayrollByDept = ({
  subscribeToActualsUpdate,
  subscribeToEmployeeUpdate,
  scenarioId,
}) => {
  const [chart, setChart] = useState();
  const [showHeadcount, setShowHeadcount] = useState(false);

  const scenarioQueries = useTotalPayrollQuery();
  const [base] = scenarioQueries;
  const loading = scenarioQueries.every(({ isLoading }) => isLoading);

  const valueFormatter = showHeadcount
    ? metricFormatters.count
    : metricFormatters.monetary;

  const chartData = useMemo(() => {
    return scenarioQueries.reduce((accum, { data, scenario }) => {
      if (Array.isArray(data)) {
        return accum;
      }
      const { employeesByDept, payrollByDept } = data;
      const enrichWithScenario = (params) => ({ ...params, scenario });
      const result = showHeadcount
        ? employeesByDept.data.map(enrichWithScenario)
        : payrollByDept.data.map(enrichWithScenario);
      return [...accum, result];
    }, []);
  }, [scenarioQueries, showHeadcount]);

  useWsSubscription(
    () =>
      subscribeToActualsUpdate(scenarioId, actualsFamily.PAYROLL, () => {
        base.refetch();
      }),
    [scenarioId],
  );

  useWsSubscription(
    () =>
      subscribeToEmployeeUpdate(scenarioId, () => {
        base.refetch();
      }),
    [scenarioId],
  );

  const hasData = loading || !!chartData.flat().length;

  return (
    <section className="ChartPanel">
      <ChartHeader title={SECONDARY_CHART_TITLE} />
      <ChartToolbar className="Chart_Toolbar-withLegend">
        <ChartLegend chart={chart} data-testid="payroll-department-legend" />
        <Toggle
          id="total-payroll-toggle"
          options={[
            { label: 'Payroll', value: false },
            { label: 'Employee', value: true },
          ]}
          value={showHeadcount}
          onToggle={(value) => {
            setShowHeadcount(value);
          }}
        />
        <ChartControls
          chartRef={chart}
          title={SECONDARY_CHART_TITLE}
          data-testid="payroll-chart-export"
          csvColumnFilter={CATEGORY_COLUMN}
        />
      </ChartToolbar>
      {hasData ? (
        <PercentBarWithMetrics
          data={chartData}
          data-testid="payroll-by-department"
          onChartCreated={setChart}
          valueFormatter={valueFormatter}
          loading={loading}
        />
      ) : (
        <EmptyData Icon={EmployeesEmptyIcon}>
          Add Employees for this chart to populate
        </EmptyData>
      )}
    </section>
  );
};

const mapStateToProps = ({ scenario }) => {
  const { scenarioId } = scenario;

  return {
    scenarioId,
  };
};

export default connect(mapStateToProps, {
  subscribeToActualsUpdate: subscribeToActualsUpdateAction,
  subscribeToEmployeeUpdate: subscribeToEmployeeUpdateAction,
})(PayrollByDept);
