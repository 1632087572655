// @ts-check
import { createContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_FEATURE_FLAGS } from '@/graphql/queries';
import useBillSession from '@/hooks/useBillSession';

/**
 * Types taken from https://app-dev-bdc.divvy.co/gw/graphql (see
 * `FfaasOrgProperties` in Schema)
 *
 * @typedef {{
 *   userId?: string;
 *   payablesOrgType?: string;
 *   receivablesOrgType?: string;
 *   partnerType?: string;
 *   organization?: string;
 *   accountingSoftware?: string;
 *   profileType?: string;
 *   isIVAEnabled?: boolean;
 *   isWalletEnabled?: boolean;
 *   podNumber?: string;
 *   domain?: string;
 *   environment?: string;
 *   isSimpleBasic?: boolean;
 *   isEmployeeOrg?: boolean;
 *   isAutomationTestOrg?: boolean;
 *   isAccountantChannelOrg?: boolean;
 *   isConsoleUser?: boolean;
 *   anonymous?: boolean;
 *   orgCreatedTime?: number;
 *   canActivateSpend?: boolean;
 *   mobileAppSemVer?: string;
 *   frontendPlatform?: 'IOS' | 'ANDROID' | 'WEB';
 *   isBillingSetup?: boolean;
 *   isInsightsAvailable?: boolean;
 *   billerId?: string;
 *   isInTrialOrGracePeriod?: boolean;
 *   isAdmin?: boolean;
 * }} FFaaSOrgProps
 */

/**
 * @typedef {{
 *   children: React.ReactNode;
 *   ffaasProject?: string;
 *   orgProps?: FFaaSOrgProps;
 * }} FFaaSProviderProps
 */

/**
 * @typedef {{
 *   getFlags: {
 *     feature_flags: {
 *       data: Record<string, boolean | string | object>;
 *     };
 *   };
 * }} FFaaSQueryData
 */

/**
 * @typedef {{
 *   orgId: string;
 *   flags: FFaaSQueryData['getFlags']['feature_flags']['data'];
 *   project: string;
 *   isFeatureFlagLoading: boolean;
 * }} FFaaSObject
 */

/**
 * @typedef {{
 *   orgId: string;
 *   project: string;
 *   orgProps: FFaaSOrgProps;
 * }} FFaaSQueryVariables
 */

/**
 * Context for the Feature Flag Service.
 *
 * @type {React.Context<FFaaSObject>}
 */
export const FFaaSContext = createContext({
  orgId: '',
  flags: {},
  project: '',
  isFeatureFlagLoading: false,
});

/**
 * Feature Flag Service Provider. This should wrap the root of the application.
 * To access feature flags, use the `useFeatureFlagsService` hook.
 *
 * @type {(props: FFaaSProviderProps) => React.ReactElement}
 */
export const FFaaSProvider = ({
  children,
  ffaasProject = 'bill',
  orgProps,
}) => {
  const { sessionInfo } = useBillSession();

  /**
   * @type {import('@apollo/client/react').QueryResult<
   *   FFaaSQueryData,
   *   FFaaSQueryVariables
   * >}
   */
  const {
    data: { getFlags: { feature_flags: ff } } = {
      getFlags: {
        feature_flags: {
          data: {},
        },
      },
    },
    loading,
  } = useQuery(GET_FEATURE_FLAGS, {
    skip: !sessionInfo?.organizationId,
    variables: {
      orgId: sessionInfo?.organizationId,
      project: ffaasProject,
      orgProps: orgProps ?? {},
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    context: {
      ffaas: true,
    },
  });

  /** @type {FFaaSObject} */
  const value = useMemo(() => {
    return {
      orgId: sessionInfo?.organizationId ?? '',
      flags: ff.data ?? {},
      project: ffaasProject,
      isFeatureFlagLoading: loading,
    };
  }, [ff, ffaasProject, sessionInfo?.organizationId, loading]);

  return (
    <FFaaSContext.Provider value={value}>{children}</FFaaSContext.Provider>
  );
};
