import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { getDashboardsAction } from '@/actions/dashboard';
import ChartBuilder from '@/components/ChartBuilder';
import PageHeader from '@/components/Layouts/PageHeader';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';
import ErrorDiv from '@/components/common/ErrorDiv';
import { DASHBOARD_PATH, PAGE_ERROR_BOUNDARY_TEXT } from '@/constants/pages';
import useReportsInfo from '@/hooks/useReportsInfo';
import AverageRevenuePerAccountExpanded from '@/pages/Dashboard/AverageRevenuePerAccountExpanded';
import BurnRate from '@/pages/Dashboard/BurnRate';
import CacPaybackExpanded from '@/pages/Dashboard/CacPaybackExpanded';
import CashBalance from '@/pages/Dashboard/CashBalance';
import CashInChartExpanded from '@/pages/Dashboard/CashInChartExpanded';
import CashOutChartExpanded from '@/pages/Dashboard/CashOutChartExpanded';
import ChurnedMrr from '@/pages/Dashboard/ChurnedMrrExpanded';
import CustomChartExpanded from '@/pages/Dashboard/CustomChartExpanded';
import CustomerAcquisitionCost from '@/pages/Dashboard/CustomerAcquisitionCost';
import CustomerLTV from '@/pages/Dashboard/CustomerLTV';
import CustomersNew from '@/pages/Dashboard/CustomersNew';
import CustomersTotal from '@/pages/Dashboard/CustomersTotal';
import { DashboardContextProvider } from '@/pages/Dashboard/DashboardContext';
import DashboardLanding from '@/pages/Dashboard/DashboardLanding';
import GrossMargin from '@/pages/Dashboard/GrossMargin';
import LogoChurn from '@/pages/Dashboard/LogoChurn';
import NetCashFlow from '@/pages/Dashboard/NetCashFlow';
import NewMrr from '@/pages/Dashboard/NewMrr';
import NewRevenue from '@/pages/Dashboard/NewRevenue';
import Runway from '@/pages/Dashboard/Runway';
import TotalExpenses from '@/pages/Dashboard/TotalExpenses';
import TotalMrr from '@/pages/Dashboard/TotalMrr';
import TotalPayroll from '@/pages/Dashboard/TotalPayroll';
import TotalRevenue from '@/pages/Dashboard/TotalRevenue';

function Dashboard({ error, scenarioId, getDashboards }) {
  const match = useRouteMatch();
  const [showChartBuilder, setShowChartBuilder] = useState(false);
  const [chartToEdit, setChartToEdit] = useState();
  const [toggleChartsSidebar, setToggleChartsSidebar] = useState(false);

  useEffect(() => {
    getDashboards(scenarioId);
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [scenarioId]);

  useReportsInfo(scenarioId);

  return (
    <DashboardContextProvider
      value={{
        onAddChart: () => setShowChartBuilder(true),
        onEditChart: (chart) => {
          setChartToEdit(chart);
          setShowChartBuilder(true);
        },
        setToggleChartsSidebar,
      }}
    >
      <PageHeader page={DASHBOARD_PATH} />
      <div className="PageLayout">
        {error && <ErrorDiv error={error} />}
        <CommonErrorBoundary text={PAGE_ERROR_BOUNDARY_TEXT}>
          <Switch>
            <Route
              exact
              path={`${match.url}/total-revenue`}
              component={TotalRevenue}
            />
            <Route
              exact
              path={`${match.url}/new-revenue`}
              component={NewRevenue}
            />
            <Route exact path={`${match.url}/new-mrr`} component={NewMrr} />
            <Route exact path={`${match.url}/total-mrr`} component={TotalMrr} />
            <Route
              exact
              path={`${match.url}/total-expenses`}
              component={TotalExpenses}
            />
            <Route
              exact
              path={`${match.url}/total-payroll`}
              component={TotalPayroll}
            />
            <Route
              exact
              path={`${match.url}/gross-margin`}
              component={GrossMargin}
            />
            <Route exact path={`${match.url}/burn-rate`} component={BurnRate} />
            <Route
              exact
              path={`${match.url}/net-cash-flow`}
              component={NetCashFlow}
            />
            <Route exact path={`${match.url}/runway`} component={Runway} />
            <Route
              exact
              path={`${match.url}/cash-balance`}
              component={CashBalance}
            />
            <Route
              exact
              path={`${match.url}/logo-churn`}
              component={LogoChurn}
            />
            <Route
              exact
              path={`${match.url}/total-customers`}
              component={CustomersTotal}
            />
            <Route
              exact
              path={`${match.url}/customer-acquisition-cost`}
              component={CustomerAcquisitionCost}
            />
            <Route
              exact
              path={`${match.url}/average-revenue-per-account`}
              component={AverageRevenuePerAccountExpanded}
            />
            <Route
              exact
              path={`${match.url}/new-customers`}
              component={CustomersNew}
            />
            <Route
              exact
              path={`${match.url}/churned-mrr`}
              component={ChurnedMrr}
            />
            <Route
              exact
              path={`${match.url}/customer-ltv`}
              component={CustomerLTV}
            />
            <Route
              exact
              path={`${match.url}/cac-payback`}
              component={CacPaybackExpanded}
            />
            <Route
              exact
              path={`${match.url}/custom-chart/:chartId`}
              component={CustomChartExpanded}
            />
            <Route
              exact
              path={`${match.url}/cash-in`}
              component={CashInChartExpanded}
            />
            <Route
              exact
              path={`${match.url}/cash-out`}
              component={CashOutChartExpanded}
            />
            <Route
              path={`${match.url}/:dashboardId?`}
              render={(props) => {
                return (
                  <DashboardLanding
                    toggleChartsSidebar={toggleChartsSidebar}
                    {...props}
                  />
                );
              }}
            />
          </Switch>
        </CommonErrorBoundary>
        <ChartBuilder
          chart={chartToEdit}
          open={showChartBuilder}
          onClose={() => {
            setChartToEdit(null);
            setShowChartBuilder(false);
          }}
        />
      </div>
    </DashboardContextProvider>
  );
}

const mapStateToProps = ({ dashboard, scenario }) => ({
  error: dashboard.error,
  scenarioId: scenario.scenarioId,
});

export default connect(mapStateToProps, {
  getDashboards: getDashboardsAction,
})(Dashboard);
