import { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getSalaryIncreaseAction } from '@/actions/companies';
import { getLoadMultiplierAction } from '@/actions/settings';
import CardWrapper from '@/components/Settings/CardWrapper';
import Header from '@/components/Settings/Header';
import InfoMessages from '@/components/common/InfoMessage';
import { MODES } from '@/constants/settings';
import LoadMultiplier from './LoadMultiplier';
import SalaryIncrease from './SalaryIncrease';
import './index.scss';

const SALARY_INCREASE = 'Salary Increase';
const LOAD_MULTIPLIER = 'Load Multiplier';

const Employee = ({
  scenarioId,
  companyId,
  getLoadMultiplier,
  getSalaryIncrease,
}) => {
  const [mode, setMode] = useState(MODES.READ);
  const [selectedTab, setSelectedTab] = useState(SALARY_INCREASE);

  useEffect(() => {
    getLoadMultiplier(scenarioId);
    getSalaryIncrease(companyId);
  }, [scenarioId, getLoadMultiplier, companyId, getSalaryIncrease]);

  return (
    <CardWrapper>
      <Header
        mode={mode}
        setMode={setMode}
        title="Employees"
        data-testid="edit-load-multiplier"
      />
      <div className="Employee-marginTop">
        <InfoMessages
          message="These are company-wide settings that govern salary increases and benefits/taxes expenses for all full-time employees"
          type="Info"
        />
      </div>
      <Tabs
        defaultActiveKey={selectedTab}
        className="Employee-marginTop"
        id="employee-card-tabs"
        activeKey={selectedTab}
        onSelect={setSelectedTab}
      >
        <Tab
          className="Employee-marginTop"
          eventKey={SALARY_INCREASE}
          title={SALARY_INCREASE}
        >
          <SalaryIncrease mode={mode} setMode={setMode} />
        </Tab>
        <Tab
          className="Employee-marginTop"
          eventKey={LOAD_MULTIPLIER}
          title={LOAD_MULTIPLIER}
        >
          <LoadMultiplier mode={mode} setMode={setMode} />
        </Tab>
      </Tabs>
    </CardWrapper>
  );
};

const mapStateToProps = ({ scenario, companies }) => ({
  scenarioId: scenario.scenarioId,
  companyId: companies.selectedCompanyId,
});

export default connect(mapStateToProps, {
  getLoadMultiplier: getLoadMultiplierAction,
  getSalaryIncrease: getSalaryIncreaseAction,
})(Employee);
