import {
  forwardRef,
  useMemo,
  useCallback,
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector, useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import ExpensesEmptyIcon from '@bill/cashflow.assets/expenses-empty';
import { useQueryClient } from '@tanstack/react-query';
import {
  getExpensesClassesAction,
  updateExpenseAction,
  getExpensesListAction,
  subscribeToExpenseUpdateAction,
} from '@/actions/expenses';
import { getPaymentTermsAction } from '@/actions/settings';
import ContextMenuRenderer from '@/components/Expenses/ContextMenuRenderer';
import Button from '@/components/common/Button';
import EmptyData from '@/components/common/EmptyData';
import {
  cancelBtnSelector,
  getMissingFieldErrors,
} from '@/components/common/MonthlySpreadsheet/helpers';
import PlusButton from '@/components/common/PlusButton';
import Spreadsheet from '@/components/common/Spreadsheet';
import ColumnToggle from '@/components/common/Spreadsheet/ColumnToggle';
import GroupingToggle from '@/components/common/Spreadsheet/GroupingToggle';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetLegend from '@/components/common/Spreadsheet/SpreadsheetLegend';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import WithEditing from '@/components/common/Spreadsheet/WithEditing';
import {
  DEPARTMENT_ID,
  EMPTY_CELL_VALUE,
} from '@/components/common/Spreadsheet/constants';
import AmountEditor from '@/components/common/Spreadsheet/editors/AmountEditor';
import SelectEditor from '@/components/common/Spreadsheet/editors/SelectEditor';
import TextEditor from '@/components/common/Spreadsheet/editors/TextEditor';
import {
  departmentNameComparator,
  setDepartment,
  sanitizeValue,
} from '@/components/common/Spreadsheet/helpers';
import CellFormulaRenderer from '@/components/common/Spreadsheet/renderers/CellFormulaRenderer';
import CircularRefCellRenderer from '@/components/common/Spreadsheet/renderers/CircularRefCellRenderer';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import WithErrorRenderer from '@/components/common/Spreadsheet/renderers/WithErrorRenderer';
import useUpdateQueue from '@/components/common/Spreadsheet/useUpdateQueue';
import WithTooltip from '@/components/common/WithTooltip';
import {
  units,
  EXPENSE_FREQUENCY,
  invoiceTypes,
  invoiceTypesMap,
  MONTHLY_EXPENSE,
  ONE_TIME_EXPENSE,
  expenseGroupTypes,
  EXPENSE,
  SPREADSHEET_ID,
  expenseTypes,
  NON_CASH_PAYMENT_TERM,
  NON_CASH_EXPENSE_CLASS,
  DEFAULT_PAYMENT_TERM,
} from '@/constants/expenses';
import { registeredFeatureFlags } from '@/constants/features';
import { EXPENSE_FORECAST_METHODS } from '@/constants/formulas';
import detectCircularRef from '@/helpers/circularReference';
import {
  getFormattedDateFromTimeStamp,
  MAX_DATE,
  MIN_DATE,
} from '@/helpers/dateFormatter';
import { amountFormatter } from '@/helpers/expense';
import { filterObject, isUUID, naturalSortComparator } from '@/helpers/index';
import { isEmptyOrNull } from '@/helpers/validators';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import useColumnHidden from '@/hooks/useColumnHidden';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import useSelectedScenarios from '@/hooks/useSelectedScenarios';
import useWsSubscription from '@/hooks/useWsSubscription';
import EXPENSE_ACTUALS_QUERY_KEY from '@/pages/Expenses/constants';
import transformDataForGrid from '@/reducers/helpers/transformDataForGrid';
import {
  getPagedExpenses,
  updateExpenses,
  getLinkedExpensePreview,
} from '@/services/expensesService';
import ExpenseCriteriaRenderer from './ExpenseCriteriaRenderer';
import { ReactComponent as ArchivedIcon } from '@/assets/images/icon_archive.svg';

const EXPENSE_ACCOUNT_NUM = 'expenseAccountNum';
const END_DATE = 'endDate';
const INVOICE_TIMING = 'invoiceTiming';
const PAYMENT_TERMS = 'paymentTermId';
const PARENT_EXPENSE = 'parentId';
const NAME = 'name';
const EXPENSE_AMOUNT = 'expenseAmount';
const START_DATE = 'startDate';
const FREQUENCY = 'frequency';
const CUSTOM_FORMULA = 'customFormula';
const EXPENSE_GROUP_CRITERIA_STRING = 'expenseGroupCriteriaString';

const DEFAULT_REQUIRED_FIELDS = [DEPARTMENT_ID, START_DATE, NAME, FREQUENCY];

const FULL_WIDTH_PARAMS = { suppressCount: true };
const GROUP_RENDERER_PARAMS = { innerRenderer: ({ data }) => data.name };

const frequencies = Object.entries(EXPENSE_FREQUENCY).map(([key, value]) => ({
  id: key,
  name: value,
}));

const invoiceTimingTypes = Object.entries(invoiceTypesMap).map(
  ([key, value]) => ({
    id: key,
    name: value,
  }),
);

const getMissingFields = (expenseData) => {
  const requiredFields =
    expenseData.expenseType === expenseTypes.AMOUNT
      ? [...DEFAULT_REQUIRED_FIELDS, EXPENSE_AMOUNT]
      : [...DEFAULT_REQUIRED_FIELDS, CUSTOM_FORMULA];

  const missingFields = requiredFields.filter((field) =>
    isEmptyOrNull(expenseData[field]),
  );

  return missingFields;
};

const ArchivedTooltipRenderer = ({ value, dataTestId }) => (
  <>
    <WithTooltip
      content={
        <div
          className="InfoTooltip_Text"
          data-testid="expenses-archive-tooltip-text"
        >
          This expense has been deleted or archived within your external account
          platform. Finmark will not include this expense in your financial
          model.
        </div>
      }
    >
      <span className="ExpenseGrid_ArchivedIcon" data-testid={dataTestId}>
        <ArchivedIcon />
      </span>
    </WithTooltip>
    {value}
  </>
);

const DepartmentDefaultOption = () => (
  <option value="" disabled>
    Select Department
  </option>
);
const FrequencyDefaultOption = () => (
  <option value="" disabled>
    Select a frequency
  </option>
);
const InvoiceTimingDefaultOption = () => (
  <option value="" disabled>
    Select invoice timing
  </option>
);
const ParentExpenseDefaultOption = () => (
  <option value="">No Parent Expense</option>
);

const ExpensesGridLegend = ({ onOrganize }) => {
  return (
    <>
      <SpreadsheetLegend />
      <div className="ExpenseOrganize_LegendWrapper ">
        <Button
          className="ExpenseOrganize_Button"
          data-testid="expenses-button-organize"
          onClick={() => onOrganize(true)}
        >
          Organize
        </Button>
      </div>
    </>
  );
};

const departmentFormatter = ({ data }) =>
  data.departmentName ?? (data.isUnsaved && '');

const updateFrequencyAndDependencies = (data, { id, name }) => {
  if (id === ONE_TIME_EXPENSE) {
    // eslint-disable-next-line no-param-reassign -- predates description requirement
    data[INVOICE_TIMING] = invoiceTypes.UPFRONT;

    // eslint-disable-next-line no-param-reassign -- predates description requirement
    data[END_DATE] = null;
  }
  if (id === MONTHLY_EXPENSE) {
    // eslint-disable-next-line no-param-reassign -- predates description requirement
    data[INVOICE_TIMING] = invoiceTypes.UPFRONT;
  }
  if (name) {
    // eslint-disable-next-line no-param-reassign -- predates description requirement
    data.frequencyName = name;
  }

  // eslint-disable-next-line no-param-reassign -- predates description requirement
  data[FREQUENCY] = id;
};

const passesZeroFilter = ({ data }) => data.isUnsaved || data.maxAmount;

const ExpensesGrid = forwardRef(
  (
    {
      onEdit,
      onDelete,
      onOrganize,
      setCurrentRecord,
      setPreviewEntries,
      onAddExpense,
      expenses,
      cellFocus,
      onDeleteUnsaved,
      setCellFocus,
    },
    ref,
  ) => {
    /**
     * @type {React.MutableRefObject<
     *   import('ag-grid-react').AgGridReact<
     *     import('@/types/services/backend').ExpenseResponseDto
     *   >
     * >}
     */
    const gridApi = useRef(null);
    useImperativeHandle(ref, () => gridApi);

    /** @type {import('@/store').AppDispatch} */
    const dispatch = useDispatch();
    const isLoading = useSelector(
      ({ componentLoading }) => componentLoading.expensesList,
    );
    const userPreferences = useSelector(({ auth }) => auth.preferences);
    const isColumnHidden = useColumnHidden(SPREADSHEET_ID);
    const { departments, expensesClasses } = useSelector(
      ({ expenses: allExpenses }) => allExpenses,
    );

    const nonCashExpenseClass = useMemo(
      () =>
        expensesClasses.find(
          (expenseClass) => expenseClass.name === NON_CASH_EXPENSE_CLASS,
        ),
      [expensesClasses],
    );

    const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);
    const companyId = useSelector(
      ({ companies }) => companies.selectedCompanyId,
    );
    const { startDate, endDate } = useSelector(({ shared }) => shared);

    const isGroupedByDept =
      userPreferences[`${SPREADSHEET_ID}-GroupingToggle`] ?? true;

    const rowData = useMemo(() => {
      if (!isGroupedByDept) {
        // ag-Grid's treeData prop is not reactive, so we must disable it for
        // the ungrouped view by flattening the hierarchy
        return expenses.map((expense) => ({
          ...expense,
          hierarchy: [expense.id],
        }));
      }

      /**
       * Grouping expenses by parent requires tree data, but row grouping is not
       * supported in this mode. To get around this limitation, we add
       * placeholder entries and render them as group rows.
       */
      return expenses.reduce((accum, expense) => {
        const {
          departmentId,
          departmentName,
          expenseClassId,
          expenseClassName,
        } = expense;
        if (expense.isUnsaved) {
          accum.push(expense);
          return accum;
        }
        const classGroupId = `class-${expenseClassId}`;
        const deptGroupId = `dept-${departmentId}`;
        const groupedExpense = {
          ...expense,
          hierarchy: [classGroupId, deptGroupId, ...expense.hierarchy],
        };

        if (!accum.some(({ id }) => id === classGroupId)) {
          accum.push({
            id: classGroupId,
            hierarchy: [classGroupId],
            name: expenseClassName,
            isGroupPlaceholder: true,
          });
        }

        if (!accum.some(({ id }) => id === deptGroupId)) {
          accum.push({
            id: deptGroupId,
            hierarchy: [classGroupId, deptGroupId],
            name: departmentName,
            isGroupPlaceholder: true,
          });
        }

        accum.push(groupedExpense);
        return accum;
      }, []);
    }, [expenses, isGroupedByDept]);

    const [hasUnsavedData, setHasUnsavedData] = useState(false);
    useBeforeUnload(hasUnsavedData);

    const [errors, setErrors] = useState({});
    const [parentExpenseList, setParentExpenseList] = useState();
    const hasData = isLoading || !!rowData.length;

    /** @type {[string, React.Dispatch<React.SetStateAction<string>>]} */
    const [rowToForceUpdate, setRowToForceUpdate] = useState(null);
    useEffect(() => {
      if (rowToForceUpdate) {
        const { api } = gridApi.current;
        const rowNode = api.getRowNode(rowToForceUpdate);
        if (rowNode) {
          api.refreshCells({ rowNodes: [rowNode] });
        }
        setRowToForceUpdate(null);
      }
    }, [rowToForceUpdate]);

    const hasWritePermission = useNonDashboardWritePermission();

    const isLinkCriteriaEnabled = useFeatureFlags(
      registeredFeatureFlags.EXPENSE_LINK_CRITERIA,
    );

    const isDepartmentNonCash = useCallback(
      (departmentId) => {
        return !!(
          nonCashExpenseClass &&
          nonCashExpenseClass.departments.find(
            (department) => department.id === departmentId,
          )
        );
      },
      [nonCashExpenseClass],
    );

    const expensesIdToNameMap = useMemo(() => {
      return expenses.reduce((nameMap, { id, name }) => {
        return { ...nameMap, [id]: name };
      }, {});
    }, [expenses]);

    const addExpenseRow = (event) => {
      event.stopPropagation();
      onAddExpense();
    };

    const handleEdit = useCallback(
      (expense) => {
        setCurrentRecord(expense);
        onEdit(true);
      },
      [onEdit, setCurrentRecord],
    );

    const queryClient = useQueryClient();
    const handleDelete = useCallback(
      (expense) => {
        setCurrentRecord(expense);
        onDelete(true);
        queryClient.invalidateQueries(EXPENSE_ACTUALS_QUERY_KEY);
      },
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
      [onDelete, setCurrentRecord],
    );

    const handlePreview = useCallback(
      async (expense) => {
        setCurrentRecord(expense);
        const { expenseGroupCriteria, id } = expense;

        const { data } = await getLinkedExpensePreview(
          {
            startDate: expense.startDate,
            endDate: expense.endDate,
            expenseGroupCriteria,
            expenseGroupId: id,
          },
          scenarioId,
        );
        setPreviewEntries(data.data);
        onEdit(true);
      },
      [onEdit, setPreviewEntries, setCurrentRecord, scenarioId],
    );

    const handleExcludeFromModel = useCallback(
      (data) => {
        dispatch(
          updateExpenseAction({ ...data, active: !data.active }, scenarioId),
        );
        const rowNode = ref.current.api.getRowNode(data.id);
        rowNode.setData({ ...data, active: !data.active });
        queryClient.invalidateQueries(EXPENSE_ACTUALS_QUERY_KEY);
      },
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
      [dispatch, scenarioId, ref],
    );
    useEffect(() => {
      dispatch(getPaymentTermsAction(companyId, scenarioId));
    }, [companyId, dispatch, scenarioId]);

    const paymentTerms = useSelector(
      ({ settings }) => settings.paymentTerms.data,
    );

    const [baseScenario] = useSelectedScenarios();

    const getParentExpenseList = useCallback(async () => {
      try {
        const {
          data: { data },
        } = await getPagedExpenses(
          scenarioId,
          getFormattedDateFromTimeStamp(MIN_DATE),
          getFormattedDateFromTimeStamp(MAX_DATE),
        );
        const potentialParents = transformDataForGrid(data.expenses).sort(
          (a, b) => naturalSortComparator(a.name, b.name),
        );
        setParentExpenseList(potentialParents);
      } catch (e) {
        console.error(e.response?.data?.error?.errorMessage || e.message);
      }
    }, [scenarioId]);

    useWsSubscription(
      () =>
        dispatch(
          subscribeToExpenseUpdateAction({ scenarioId }, ({ id }) => {
            // ag-Grid won't rerender if the value doesn't change, so we need to force it
            setRowToForceUpdate(id);
          }),
        ),
      [scenarioId],
    );

    useEffect(() => {
      dispatch(getExpensesClassesAction(scenarioId));
      getParentExpenseList();
    }, [dispatch, getParentExpenseList, scenarioId]);

    const handleRowDataUpdated = useCallback(
      ({ api }) => {
        let hasUnsaved = false;
        api.forEachLeafNode(({ data }) => {
          if (data.isUnsaved) hasUnsaved = true;
        });
        setHasUnsavedData(hasUnsaved);

        if (cellFocus) {
          setTimeout(() => {
            api.stopEditing();
            api.startEditingCell({
              rowIndex: api.getDisplayedRowCount() - 1,
              colKey: cellFocus,
            });
            setCellFocus(null);
          }, 0);
        }
      },
      [cellFocus, setCellFocus],
    );

    const handleFirstDataRendered = useCallback(
      ({ api }) => {
        if (rowData.length === 1 && rowData[0].isUnsaved) {
          setCellFocus(DEPARTMENT_ID);
          handleRowDataUpdated({ api });
        }
      },
      [rowData, handleRowDataUpdated, setCellFocus],
    );
    const addChangeToQueue = useCallback(
      (queue, { api, data }) => {
        const missingFields = getMissingFields(data);
        const hasMissingFields = !!missingFields.length;

        if (hasMissingFields && data.isUnsaved) return null;

        if (hasMissingFields) {
          const newErrors = getMissingFieldErrors(missingFields);
          setErrors((current) => ({
            ...current,
            [data.id]: newErrors,
          }));
          return null;
        }

        const rowsWithErrors = Object.keys(errors)
          .filter((expenseId) => expenseId !== data.id)
          .map((expenseId) => api.getRowNode(expenseId).data);

        const expenseIdx = queue.findIndex(({ id }) => id === data.id);
        if (expenseIdx < 0)
          return [
            ...queue,
            {
              ...data,
              departmentId: data.departmentId,
              expenseType: data.expenseType,
              parentId: data.parentId === '' ? null : data.parentId,
              parentName: data.parentName === '' ? null : data.parentName,
            },
            ...rowsWithErrors,
          ];

        const updatedQueue = [...queue];
        updatedQueue[expenseIdx] = data;
        return updatedQueue;
      },
      [errors],
    );

    const updateBulkExpenses = useCallback(
      async (queue, { api, context }) => {
        const { data } = await updateExpenses({
          scenarioId,
          expenses: queue,
          startDate,
          endDate,
        });
        queryClient.invalidateQueries(EXPENSE_ACTUALS_QUERY_KEY);

        // Hide the loading indicators
        context.loadingCells = filterObject(
          context.loadingCells,
          ([rowId]) => !queue.some(({ id }) => id === rowId),
        );

        const rowNodes = queue.reduce((rows, { id }) => {
          const row = api.getRowNode(id);
          if (!row) return rows;
          return [...rows, row];
        }, []);
        api.refreshCells({ rowNodes });

        const { errors: fieldErrors } = data.data;
        setErrors((current) => {
          // The user can continue editing while a row is invalid, so we need to
          // persist any errors that were not resolved in the update
          const unresolved = filterObject(
            current,
            ([rowId]) => !queue.some(({ id }) => id === rowId),
          );

          return {
            ...unresolved,
            ...fieldErrors,
          };
        });

        if (!Object.keys(fieldErrors).length) {
          dispatch(getExpensesListAction(scenarioId, startDate, endDate));
          getParentExpenseList();

          // remove newly saved expenses from unsaved array to avoid duplicate entries
          const newlySavedExpenseIds = queue
            .filter((expense) => expense.isUnsaved)
            .map((expense) => expense.id);
          if (newlySavedExpenseIds.length) {
            onDeleteUnsaved(newlySavedExpenseIds);
          }
        }
      },
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
      [
        scenarioId,
        startDate,
        endDate,
        dispatch,
        getParentExpenseList,
        onDeleteUnsaved,
      ],
    );

    const handleExpenseChange = useUpdateQueue(
      addChangeToQueue,
      updateBulkExpenses,
    );

    const colDefs = useMemo(() => {
      const defs = [
        {
          field: EXPENSE_ACCOUNT_NUM,
          headerName: 'Account Number',
          valueFormatter: ({ value }) => {
            return isEmptyOrNull(value) || value === ''
              ? EMPTY_CELL_VALUE
              : value;
          },
          initialHide: isColumnHidden(EXPENSE_ACCOUNT_NUM),
          cellClassRules: {
            'Spreadsheet_Cell-invalid': ({ data }) => data?.faulted,
          },
          cellRendererSelector: cancelBtnSelector,
          cellRendererParams: { onDeleteClick: onDeleteUnsaved },
          editable: ({ data }) => data.type !== expenseGroupTypes.ACCOUNT,
          cellEditor: TextEditor,
        },
        {
          comparator: departmentNameComparator,
          field: DEPARTMENT_ID,
          cellRendererSelector: (params) => {
            const { data, context, node, colDef } = params;
            const error = context.errors[node.id]?.[colDef.field];

            if (!data.isUnsaved && isGroupedByDept) {
              return {
                component: 'agGroupCellRenderer',
                params: { suppressCount: true },
              };
            }
            if (error) {
              return {
                component: WithErrorRenderer,
                params: { tooltip: error },
              };
            }
            return cancelBtnSelector(params);
          },
          cellRendererParams: { onDeleteClick: onDeleteUnsaved },
          headerName: 'Department',
          headerComponent: HeaderRenderer,
          headerComponentParams: {
            enableExpandAll: true,
          },
          lockVisible: true,
          minWidth: 240,
          cellClass: ({ node }) => {
            const classes = ['Spreadsheet_Cell'];
            if (!isGroupedByDept) return classes;
            let { parent } = node;
            let indent = 0;
            while (parent?.parent) {
              indent += 1;
              parent = parent.parent;
            }
            if (indent > 0) classes.push(`Spreadsheet_Cell-indent${indent}`);
            return classes;
          },
          editable: ({ data }) =>
            isEmptyOrNull(data.parentId) && !data.accountDeleted,
          cellEditor: SelectEditor,
          cellEditorParams: {
            options: departments,
            id: 'department-SelectorEditor',
            DefaultOption: DepartmentDefaultOption,
          },
          showRowGroup: isGroupedByDept,
          valueFormatter: departmentFormatter,
          filterValueGetter: departmentFormatter,
          valueSetter: ({ data, oldValue, newValue }) => {
            setDepartment(data, newValue, departments);
            /* eslint-disable no-param-reassign -- predates description requirement */
            if (isDepartmentNonCash(Number(newValue))) {
              data.paymentTermId = paymentTerms.find(
                (paymentTerm) => paymentTerm.name === NON_CASH_PAYMENT_TERM,
              ).id;
              data.paymentTermName = NON_CASH_PAYMENT_TERM;
            } else if (isDepartmentNonCash(Number(oldValue))) {
              data.paymentTermId = paymentTerms.find(
                (paymentTerm) => paymentTerm.name === DEFAULT_PAYMENT_TERM,
              ).id;
              data.paymentTermName = DEFAULT_PAYMENT_TERM;
            }
            /* eslint-enable no-param-reassign -- predates description requirement */
          },
        },
        {
          field: NAME,
          flex: 2,
          headerName: 'Expense Name',
          lockVisible: true,
          cellRendererSelector: ({ data, context, node, colDef }) => {
            const error = context.errors[node.id]?.[colDef.field];
            if (data.accountDeleted) {
              return {
                component: ArchivedTooltipRenderer,
                params: {
                  dataTestId: `${data.id}-archived-tooltip`,
                },
              };
            }
            if (error) {
              return {
                component: WithErrorRenderer,
                params: { tooltip: error },
              };
            }
            return undefined;
          },
          editable: ({ data }) =>
            !data.accountDeleted && data.type !== expenseGroupTypes.ACCOUNT,
          cellEditor: TextEditor,
          cellEditorParams: {
            placeholder: 'Enter expense name',
          },
        },
        {
          field: EXPENSE_AMOUNT,
          type: 'number',
          headerName: 'Amount',
          minWidth: 200,
          cellEditor: AmountEditor,
          cellEditorParams: {
            type: EXPENSE,
            presetFormulasId: EXPENSE_FORECAST_METHODS,
            allowNegativeValues: true,
          },
          lockVisible: true,
          cellRendererSelector: ({ data }) => ({
            component: detectCircularRef(data)
              ? CircularRefCellRenderer
              : CellFormulaRenderer,
          }),
          valueFormatter: amountFormatter,
          valueGetter: ({ data }) => data.customFormula ?? data.expenseAmount,
          valueSetter: ({ data, newValue, oldValue }) => {
            // Handle pasted values
            if (typeof newValue === 'string') {
              const valAsNum = Number(sanitizeValue(newValue));
              const isFormula = Number.isNaN(valAsNum);

              // eslint-disable-next-line no-param-reassign -- predates description requirement
              data[EXPENSE_AMOUNT] = isFormula ? null : valAsNum.toFixed(2);
              // eslint-disable-next-line no-param-reassign -- predates description requirement
              data[CUSTOM_FORMULA] = isFormula ? newValue : null;
              // eslint-disable-next-line no-param-reassign -- predates description requirement
              data.expenseType = isFormula
                ? expenseTypes.CUSTOM_FORMULA
                : expenseTypes.AMOUNT;

              return oldValue !== newValue;
            }

            const { unit, selectedValue } = newValue;
            /* eslint-disable no-param-reassign -- predates description requirement */
            if (unit === units.CURRENCY) {
              data[EXPENSE_AMOUNT] = selectedValue;
              data.customFormula = null;
              data.expenseType = expenseTypes.AMOUNT;
            } else {
              data[EXPENSE_AMOUNT] = null;
              data.customFormula = selectedValue;
              data.expenseType = expenseTypes.CUSTOM_FORMULA;
            }
            /* eslint-enable no-param-reassign -- predates description requirement */
            return oldValue !== selectedValue;
          },
          cellClassRules: {
            'EmployeeGrid_Cell-formula': ({ data }) =>
              detectCircularRef(data) && !!data?.customFormula,
            'Spreadsheet_Cell-invalid': ({ data, context, node, colDef }) => {
              const error = context.errors[node.id]?.[colDef.field];
              return data?.faulted || error;
            },
          },
          editable: ({ data }) => !data.accountDeleted,
        },
        {
          field: CUSTOM_FORMULA,
          headerName: 'View Formula',
          flex: 2,
          valueFormatter: ({ value }) => {
            return isEmptyOrNull(value) ? EMPTY_CELL_VALUE : value;
          },
          cellClassRules: {
            'Spreadsheet_Cell-invalid': ({ data, node }) => {
              const error = errors[node.id]?.[EXPENSE_AMOUNT];
              return data?.faulted || error;
            },
          },
          initialHide: isColumnHidden(CUSTOM_FORMULA),
          editable: false,
        },
        {
          field: START_DATE,
          type: 'date',
          headerName: 'Start Date',
          lockVisible: true,
          editable: ({ data }) => !data.accountDeleted,
        },
        {
          field: END_DATE,
          type: 'date',
          headerName: 'End Date',
          initialHide: isColumnHidden(END_DATE),
          editable: ({ data }) =>
            Number(data.frequency) !== ONE_TIME_EXPENSE && !data.accountDeleted,
          cellEditorParams: {
            id: `${END_DATE}-dateEditor`,
          },
        },
        {
          field: FREQUENCY,
          headerName: 'Frequency',
          valueFormatter: ({ data, value }) => {
            if (value === null && data.isUnsaved) {
              return null;
            }
            const frequency = frequencies.find(
              ({ id }) => Number(id) === Number(value),
            );

            return frequency ? frequency.name : data.frequencyName;
          },
          lockVisible: true,
          editable: ({ data }) => !data.accountDeleted,
          cellEditor: SelectEditor,
          cellEditorParams: {
            options: frequencies,
            id: 'frequency-SelectEditor',
            DefaultOption: FrequencyDefaultOption,
          },
          valueSetter: ({ data, newValue, oldValue }) => {
            const UNKNOWN_FREQUENCY = -1;
            const frequency = Number(newValue);
            const isFrequencyNumber = !Number.isNaN(frequency);

            if (isFrequencyNumber) {
              updateFrequencyAndDependencies(data, { id: frequency });
            } else {
              const pastedFrequency = frequencies.find(
                ({ name }) => name.toLowerCase() === newValue.toLowerCase(),
              );
              const params = {
                id: pastedFrequency?.id ?? UNKNOWN_FREQUENCY,
                name: pastedFrequency?.name ?? newValue,
              };
              updateFrequencyAndDependencies(data, params);
            }

            return oldValue !== newValue;
          },
        },
        {
          field: INVOICE_TIMING,
          headerName: 'Invoice Timing',
          valueFormatter: ({ value }) => invoiceTypesMap[value],
          editable: ({ data }) =>
            ![ONE_TIME_EXPENSE, MONTHLY_EXPENSE].includes(
              Number(data.frequency),
            ) && !data.accountDeleted,
          cellEditor: SelectEditor,
          cellEditorParams: {
            options: invoiceTimingTypes,
            id: 'invoiceTypes-SelectEditor',
            DefaultOption: InvoiceTimingDefaultOption,
          },
          initialHide: isColumnHidden(INVOICE_TIMING),
        },
        {
          field: PAYMENT_TERMS,
          headerName: 'Payment Terms',
          editable: ({ data }) => {
            if (isDepartmentNonCash(data.departmentId)) {
              return false;
            }
            return !data.accountDeleted;
          },
          cellEditor: SelectEditor,
          initialHide: isColumnHidden(PAYMENT_TERMS),
          cellEditorParams: {
            options: paymentTerms,
            id: 'payment-term-SelectorEditor',
          },
          valueFormatter: ({ data, value }) => {
            const paymentTerm = paymentTerms.find(
              (term) => term.id === Number(value),
            );
            return paymentTerm ? paymentTerm.name : data.paymentTermName;
          },
          valueSetter: ({ data, newValue, oldValue }) => {
            if (Number.isNaN(Number(newValue))) {
              const paymentTerm = paymentTerms.find(
                ({ name }) => name.toLowerCase() === newValue.toLowerCase(),
              );
              let paymentTermId = -1;
              let paymentTermName = newValue;

              if (paymentTerm) {
                paymentTermId = paymentTerm.id;
                paymentTermName = paymentTerm.name;
              }

              // eslint-disable-next-line no-param-reassign -- predates description requirement
              data[PAYMENT_TERMS] = paymentTermId;
              // eslint-disable-next-line no-param-reassign -- predates description requirement
              data.paymentTermName = paymentTermName;
            } else {
              // eslint-disable-next-line no-param-reassign -- predates description requirement
              data[PAYMENT_TERMS] = newValue;
            }
            return oldValue !== newValue;
          },
        },
        {
          field: PARENT_EXPENSE,
          headerName: 'Parent Expense',
          cellEditor: SelectEditor,
          hide: isGroupedByDept || (isColumnHidden(PARENT_EXPENSE) ?? true),
          cellEditorParams: ({ data, value }) => {
            const filteredByDepartmentSelected = parentExpenseList.filter(
              ({ departmentId, name }) =>
                departmentId === data.departmentId && name !== data.name,
            );

            return {
              options: filteredByDepartmentSelected,
              id: 'parentExpense-SelectorEditor',
              value: value ?? -1,
              DefaultOption: ParentExpenseDefaultOption,
            };
          },
          lockVisible: isGroupedByDept,
          valueFormatter: ({ value, data }) =>
            data.parentName ?? expensesIdToNameMap[value] ?? EMPTY_CELL_VALUE,
          filterValueGetter: ({ data }) => data.parentName,
          valueSetter: ({ data, newValue, oldValue }) => {
            if (newValue === -1) return false;

            let newParentName;
            let newParentId = null;
            if (isUUID(newValue)) {
              newParentId = newValue;
              newParentName = expensesIdToNameMap[newValue];
            } else {
              newParentName = newValue;

              const parentExpense = parentExpenseList.find(
                ({ name }) => name.toLowerCase() === newValue.toLowerCase(),
              );
              if (parentExpense) {
                newParentId = parentExpense.id;
              }
            }

            // eslint-disable-next-line no-param-reassign -- predates description requirement
            data.parentId = newParentId;
            // eslint-disable-next-line no-param-reassign -- predates description requirement
            data.parentName = newParentName;

            return oldValue !== newValue;
          },
        },
      ];

      if (baseScenario?.hasAccountingIntegration) {
        defs.push({
          field: EXPENSE_GROUP_CRITERIA_STRING,
          flex: 2,
          headerName: 'Linking Criteria',
          editable: ({ data }) =>
            isLinkCriteriaEnabled && data.type !== expenseGroupTypes.ACCOUNT,
          cellEditor: TextEditor,
          initialHide: isColumnHidden(EXPENSE_GROUP_CRITERIA_STRING),
          cellRenderer: ExpenseCriteriaRenderer,
        });
      }

      defs.push({
        colId: 'actions',
        type: 'actions',
        cellRendererSelector: ({ data }) => {
          if (data.isUnsaved) return undefined;
          return {
            component: ContextMenuRenderer,
            params: {
              onEdit: handleEdit,
              onDelete: handleDelete,
              onExcludeFromModel: handleExcludeFromModel,
              onPreview: handlePreview,
              hasWritePermission,
            },
          };
        },
        lockVisible: true,
      });

      return defs;
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    }, [
      baseScenario?.hasAccountingIntegration,
      isGroupedByDept,
      isColumnHidden,
      isLinkCriteriaEnabled,
      departments,
      onDeleteUnsaved,
      paymentTerms,
      hasWritePermission,
      parentExpenseList,
      expensesIdToNameMap,
      handleEdit,
      handleDelete,
      handleExcludeFromModel,
      handlePreview,
      isDepartmentNonCash,
    ]);

    const Legend = useCallback(
      () => <ExpensesGridLegend onOrganize={onOrganize} />,
      [onOrganize],
    );
    return (
      <>
        <Prompt
          when={hasUnsavedData}
          message="Are you sure you want to leave this page? Incomplete expenses won't be saved"
        />
        <SpreadsheetToolbar Legend={Legend}>
          <GroupingToggle spreadsheetId={SPREADSHEET_ID} />
          <div className="SpreadsheetToolbar_ControlGroup">
            Options:
            {hasData && (
              <ColumnToggle ref={gridApi} spreadsheetId={SPREADSHEET_ID} />
            )}
            <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
          </div>
          {hasWritePermission && (
            <div className="SpreadsheetToolbar_ControlGroup">
              <PlusButton
                data-testid="add-expense-to-grid-option"
                onAdd={onAddExpense}
              >
                Add New Expense
              </PlusButton>
            </div>
          )}
        </SpreadsheetToolbar>

        {hasData ? (
          <WithEditing
            columnDefs={colDefs}
            enabled={hasWritePermission}
            errors={errors}
          >
            {({ handleCellLoading, ...editingHandlers }) => (
              <Spreadsheet
                animateRows={!window.Cypress}
                ref={gridApi}
                data={rowData}
                data-testid={SPREADSHEET_ID}
                fullWidthCellRenderer="agGroupCellRenderer"
                fullWidthCellRendererParams={FULL_WIDTH_PARAMS}
                getDataPath={({ hierarchy }) => hierarchy}
                groupDisplayType="custom"
                groupRowRendererParams={GROUP_RENDERER_PARAMS}
                loading={isLoading}
                passesZeroFilter={passesZeroFilter}
                rowClassRules={{
                  'Spreadsheet_Row-excluded': ({ data }) => !data?.active,
                }}
                onRowDataUpdated={handleRowDataUpdated}
                onCellValueChanged={(params) =>
                  handleExpenseChange(params) && handleCellLoading(params)
                }
                onFirstDataRendered={handleFirstDataRendered}
                treeData
                {...editingHandlers}
              />
            )}
          </WithEditing>
        ) : (
          <EmptyData
            className="EmptyData-tab"
            Icon={ExpensesEmptyIcon}
            onAdd={hasWritePermission ? addExpenseRow : undefined}
          >
            Add Expenses for this grid to populate.
          </EmptyData>
        )}
      </>
    );
  },
);

export default ExpensesGrid;
