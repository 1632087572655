// @ts-check
import Api from './Api';

/**
 * Get a Bill organization's onboarding status with FP&A
 *
 * @type {() => Promise<
 *   import('axios').AxiosResponse<
 *     import('@/types/services/backend').RestResponseDtoCompanyOnboardingStatusDto
 *   >
 * >}
 */
export const getOnboardingStatus = () => {
  return Api.get('/planning/onboarding/status', {
    withCredentials: true,
  });
};

/**
 * Accepts user's Terms and Conditions for FPA
 *
 * @type {() => Promise<
 *   import('axios').AxiosResponse<
 *     import('@/types/services/backend').RestResponseDtoFPAUserTermsAndConditionsAcceptanceResponseDto
 *   >
 * >}
 */
export const setAcceptTnc = () => {
  return Api.post('planning/organizations/tnc-acceptance');
};

/**
 * Authenticate the onboarded Bill user for FP&A
 *
 * @type {() => Promise<
 *   import('axios').AxiosResponse<
 *     import('@/types/services/backend').IatResponse
 *   >
 * >}
 */
export const authenticateOnboardedUser = () => {
  return Api.get('/planning/access-token', {
    withCredentials: true,
  });
};

/**
 * Authenticate the onboarded Bill user for FP&A
 *
 * @type {() => Promise<
 *   import('axios').AxiosResponse<
 *     import('@/types/services/backend').RestResponseDtoNonOnboardedUser
 *   >
 * >}
 */
export const getNonOnboardedUserToken = () => {
  return Api.get('/planning/non-onboarded-user/token', {
    withCredentials: true,
  });
};

/**
 * Fetch top admin emails
 *
 * @type {(
 *   role: string,
 * ) => Promise<
 *   import('axios').AxiosResponse<
 *     import('@/types/services/backend').RestResponseDtoListCompanyUserDummyDto
 *   >
 * >}
 */
export const getRecentlyCreatedForecastingAdmins = (role) => {
  return Api.get(`/planning/users`, {
    params: {
      role,
    },
    withCredentials: true,
  });
};
