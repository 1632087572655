import { useState, useRef, useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import useDashboardPath from '@/hooks/useDashboardPath';
import { getMetricValue } from '@/pages/Dashboard/helpers';
import CACChart from './charts/CACChart';

const CHART_TITLE = 'Customer Acquisition Cost';

function CustomerAcquisitionCost() {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState();
  const chartRef = useRef();
  const kpiValue = getMetricValue(total, formatMonetary);
  const dashboardPath = useDashboardPath();

  const { startDate, endDate } = useSelector(({ shared }) => shared);
  const onQueryStateChange = useCallback(({ data, isLoading }) => {
    setLoading(isLoading);
    setTotal(data.customerAcquisitionCost);
  }, []);

  return (
    <section className="Panel Panel-toEdge">
      <ChartHeader back={dashboardPath} title={CHART_TITLE}>
        <div className="ChartHeader_KPIValue">{loading ? '' : kpiValue}</div>
        <div className="ChartHeader_KPI">
          <br />
          {formatRangeWithShortYear(startDate, endDate)}
        </div>
      </ChartHeader>
      <ChartToolbar>
        <ChartControls
          chartRef={chartRef}
          title={CHART_TITLE}
          metricValue={kpiValue}
          label={formatRangeWithShortYear(startDate, endDate)}
          data-testid="cac-chart-export"
        />
      </ChartToolbar>
      <CACChart
        className="ExpandedView_ChartWrapper"
        exportBtn={chartRef}
        onQueryStateChange={onQueryStateChange}
      />
    </section>
  );
}

export default CustomerAcquisitionCost;
