import RoundCrossIcon from '@bill/cashflow.assets/round-cross';
import './CancelBtnRenderer.scss';

/**
 * Renders a cancel button within the cell before the value. Typically used for
 * canceling the addition of a new row in the grid.
 *
 * @example
 *   columnDefs={[
 *    {
 *      ...
 *      cellRenderer: CancelBtnRenderer
 *      cellRendererParams: { onDeleteClick: handleDelete }
 *    }
 *   ]}
 */
const CancelBtnRenderer = ({ data, valueFormatted, onDeleteClick }) => {
  return (
    <>
      <button
        className="CancelBtnRenderer_Button"
        onClick={() => {
          onDeleteClick([data.id]);
        }}
        aria-label="Cancel"
      >
        <RoundCrossIcon />
      </button>
      {valueFormatted}
    </>
  );
};

export default CancelBtnRenderer;
