/**
 * Returns the given number formatted correctly
 *
 * @param {number} value Number to format
 * @param {Object} options Intl.NumberFormat options
 * @param {Object} locale Intl.NumberFormat locale
 * @returns {string | null} value formatted or number. Will return null if the
 *   value provided is not a number
 */
export default function formatWholeNumber(value, options = {}, locale = {}) {
  if (!Number.isFinite(Number.parseFloat(value))) return null;

  const config = {
    style: 'decimal',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    ...options,
  };

  const formatter = new Intl.NumberFormat(locale, config);
  return formatter.format(value);
}
