// @ts-check

/* eslint-disable import/no-named-as-default-member -- Eslint Throwing Errors: Caution PAGES, also has a named export */
import PAGES, {
  ACTUALS_PATH,
  CASH_IN_OUT,
  DASHBOARD_PATH,
  EMPLOYEES_PATH,
  EXPENSES_PATH,
  METRICS_PATH,
  REPORTS_PATH,
  REVENUE_PATH,
  SETTINGS_PATH,
  VARIABLES_PATH,
} from '@/constants/pages';
import { actions, subjects } from '@/constants/permissions';

const { READ, READ_WRITE } = actions;
const { DASHBOARD, NON_DASHBOARD, EMPLOYEE_SETTINGS } = subjects;

const NAV_LINK_DASHBOARD = /** @type {const} */ ({
  label: PAGES[DASHBOARD_PATH].titleText,
  to: DASHBOARD_PATH,
  permissions: {
    action: READ,
    subject: DASHBOARD,
    scenarioPermissionRequired: true,
  },
});

const NAV_LINK_VARIABLES = /** @type {const} */ ({
  label: PAGES[VARIABLES_PATH].titleText,
  to: VARIABLES_PATH,
  permissions: {
    action: READ,
    subject: NON_DASHBOARD,
    scenarioPermissionRequired: true,
  },
});

export const SIDE_NAV_LINKS_FPA_LITE = /** @type {const} */ [
  { ...NAV_LINK_DASHBOARD, label: 'Cash Flow Dashboard' },
  {
    label: PAGES[CASH_IN_OUT].titleText,
    to: CASH_IN_OUT,
    permissions: {
      action: READ,
      subject: NON_DASHBOARD,
      scenarioPermissionRequired: true,
    },
  },
  { ...NAV_LINK_VARIABLES, label: 'Variables' },
  {
    label: PAGES[SETTINGS_PATH].titleText,
    to: SETTINGS_PATH,
    permissions: {
      action: READ,
      subject: DASHBOARD,
      scenarioPermissionRequired: true,
    },
  },
];

export const SIDE_NAV_LINKS_FPA_FULL = /** @type {const} */ [
  NAV_LINK_DASHBOARD,
  {
    label: PAGES[EMPLOYEES_PATH].titleText,
    to: EMPLOYEES_PATH,
    permissions: {
      action: READ_WRITE,
      subject: EMPLOYEE_SETTINGS,
      scenarioPermissionRequired: false,
    },
  },
  {
    label: PAGES[EXPENSES_PATH].titleText,
    to: EXPENSES_PATH,
    permissions: {
      action: READ,
      subject: NON_DASHBOARD,
      scenarioPermissionRequired: true,
    },
  },
  {
    label: PAGES[REVENUE_PATH].titleText,
    to: REVENUE_PATH,
    permissions: {
      action: READ,
      subject: NON_DASHBOARD,
      scenarioPermissionRequired: true,
    },
  },
  {
    label: PAGES[ACTUALS_PATH].titleText,
    to: ACTUALS_PATH,
    permissions: {
      action: READ,
      subject: NON_DASHBOARD,
      scenarioPermissionRequired: true,
    },
  },
  {
    label: PAGES[REPORTS_PATH].titleText,
    to: REPORTS_PATH,
    permissions: {
      action: READ,
      subject: NON_DASHBOARD,
      scenarioPermissionRequired: true,
    },
  },
  NAV_LINK_VARIABLES,
  {
    label: PAGES[METRICS_PATH].titleText,
    to: METRICS_PATH,
    permissions: {
      action: READ,
      subject: NON_DASHBOARD,
      scenarioPermissionRequired: true,
    },
  },
];
