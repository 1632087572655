import { scenarioTypes, scenarioTypesText } from '@/constants/scenario';

/**
 * @type {(
 *   type: import('@/constants/scenario').scenarioTypes,
 * ) => import('@/constants/scenario').scenarioTypesText | string}
 */
const getScenarioText = (type) => {
  return !type || type === scenarioTypes.BUDGET ? '' : scenarioTypesText[type];
};

export default getScenarioText;
