import { useState } from 'react';
import { connect } from 'react-redux';
import { updateLoadMultiplierAction } from '@/actions/settings';
import Footer from '@/components/Settings/Footer';
import ErrorMessages from '@/components/common/ErrorMessages';
import NumberField from '@/components/common/NumberField';
import { MODES } from '@/constants/settings';
import { isPercentageValid } from '@/helpers/validators';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import './LoadMultiplier.scss';

const LoadMultiplier = ({
  selectedCompany,
  updateLoadMultiplier,
  scenarioId,
  errorMsg,
  mode,
  setMode,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(errorMsg);
  const [loadMultiplier, setLoadMultiplier] = useState(
    selectedCompany?.loadMultiplier || '20%',
  );

  const handleOnSaveClick = async () => {
    try {
      setLoading(true);
      await updateLoadMultiplier({ loadMultiplier }, scenarioId);
      setMode(MODES.READ);
    } catch (e) {
      setError(e.response?.data?.error?.errorMessage || e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOnCancelClick = () => {
    setLoadMultiplier(selectedCompany?.loadMultiplier);
    setMode(MODES.READ);
  };
  return (
    <form>
      {error && <ErrorMessages success={null} error={error} />}
      <div className="Form">
        <div className="Form_Group">
          <NumberField
            prefix="%"
            id="loadMultiplier"
            name="loadMultiplier"
            value={loadMultiplier}
            className="LoadMultiplier_Value"
            onChange={({ target }) => setLoadMultiplier(target.value)}
            validate={() => {
              return (
                !isPercentageValid(loadMultiplier) &&
                'Please enter a valid percentage'
              );
            }}
            disabled={mode !== MODES.EDIT}
            allowNegativeValues={false}
          />
        </div>
      </div>

      {mode === MODES.EDIT && (
        <Footer
          onSaveClick={handleOnSaveClick}
          onCancelClick={handleOnCancelClick}
          disabledSaveBtn={!isPercentageValid(loadMultiplier) || loading}
          loading={loading}
        />
      )}
    </form>
  );
};

const mapStateToProps = (state) => ({
  selectedCompany: getSelectedCompany(state),
  scenarioId: state.scenario.scenarioId,
  errorMsg: state.settings.loadMultiplierError,
});

export default connect(mapStateToProps, {
  updateLoadMultiplier: updateLoadMultiplierAction,
})(LoadMultiplier);
