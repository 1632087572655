import { SET_SCENARIO_ID, SWAP_SCENARIOS } from '@/actionTypes/scenario';
import * as actions from '@/actionTypes/variables';
import { presetFormulas } from '@/constants/formulas';
import {
  PLACEHOLDER_ID,
  SECTION_PLACEHOLDER_ID,
  units,
  variableTypes,
} from '@/constants/variables';
import {
  getDatesInRange,
  getFormattedDateFromTimeStamp,
} from '@/helpers/dateFormatter';
import { isEmptyOrNull } from '@/helpers/validators';
import flattenVariablesTree from '@/reducers/helpers/flattenVariablesTree';

const INITIAL_STATE = {
  customVariables: [],
  customVariableSections: [],
  systemVariables: [],
  systemVariablesSections: [],
  autocompleteOptions: null,
  presetFormulas,
  lastUpdatedCustomVariable: null,
};

function mapSystemVariables(payload) {
  return payload.reduce((accum, { name, variables }) => {
    const updatedVariables = variables.map((variable) => ({
      ...variable,
      categoryName: name,
    }));
    return [...accum, ...updatedVariables];
  }, []);
}

function getExistingCustomVariables(state) {
  return state.customVariables.filter(
    ({ variable }) => variable.id !== PLACEHOLDER_ID,
  );
}

function getExistingCustomVariableSections(state) {
  return state.customVariableSections.filter(
    ({ id }) => id !== SECTION_PLACEHOLDER_ID,
  );
}

function mapSectionIdxAsProp(section, index) {
  return { ...section, index };
}

function mapVarIdxAsProp(variable, index) {
  return {
    ...variable,
    variable: {
      ...variable.variable,
      index,
    },
  };
}

function isAnyMonthFaulted(months) {
  return months.some((month) => month.value?.faulted);
}

const variablesReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actions.ADD_CUSTOM_VARIABLE: {
      let { sectionId } = payload;
      if (!sectionId) {
        const defaultSection = state.customVariableSections.find(
          (section) => section.default,
        );
        if (!defaultSection) throw Error('Default section not found');
        sectionId = defaultSection.id;
      }

      // Don't allow more than one placeholder at a time
      const customVariables = getExistingCustomVariables(state);

      const placeholder = {
        variable: {
          id: PLACEHOLDER_ID,
          index: customVariables.length,
          displayName: '',
          name: '',
          unit: units.NUMBER,
          scenarioId: payload.scenarioId,
          sectionId,
        },
        months: [],
      };
      return {
        ...state,
        customVariables: [...customVariables, placeholder],
      };
    }
    case actions.ADD_CUSTOM_VARIABLE_SECTION: {
      const sections = getExistingCustomVariableSections(state);
      const placeholder = {
        id: SECTION_PLACEHOLDER_ID,
        name: '',
        createdDate: new Date().toISOString().slice(0, -1),
        scenarioId: payload.scenarioId,
      };
      const customVariableSections = [placeholder, ...sections].map(
        mapSectionIdxAsProp,
      );
      return {
        ...state,
        customVariableSections,
      };
    }
    case actions.CANCEL_ADD_CUSTOM_VARIABLE: {
      const customVariables = getExistingCustomVariables(state);
      return {
        ...state,
        customVariables,
      };
    }
    case actions.CANCEL_ADD_CUSTOM_VARIABLE_SECTION: {
      const customVariableSections =
        getExistingCustomVariableSections(state).map(mapSectionIdxAsProp);
      return {
        ...state,
        customVariableSections,
      };
    }
    case actions.DELETE_CUSTOM_VARIABLE: {
      const customVariables = [...state.customVariables];
      const varIdx = customVariables.findIndex(
        ({ variable }) => variable.id === payload,
      );
      if (varIdx > -1) {
        customVariables.splice(varIdx, 1);
      }
      return { ...state, customVariables };
    }
    case actions.DELETE_CUSTOM_VARIABLE_SECTION: {
      let customVariableSections = [...state.customVariableSections];
      const sectionIdx = customVariableSections.findIndex(
        ({ id }) => id === payload.id,
      );
      customVariableSections.splice(sectionIdx, 1);
      customVariableSections = customVariableSections.map(mapSectionIdxAsProp);
      return { ...state, customVariableSections };
    }
    case actions.REORDER_CUSTOM_VARIABLE: {
      const { id, index, sectionId } = payload;

      let customVariables = [...state.customVariables];
      const varIdx = customVariables.findIndex(
        ({ variable }) => variable.id === id,
      );
      const [rowToReorder] = customVariables.splice(varIdx, 1);

      // Pull all variables in the same section and reorder within that section
      let varsInSection = customVariables.filter(
        ({ variable }) => variable.sectionId === sectionId,
      );
      customVariables = customVariables.filter(
        (variableRow) => !varsInSection.includes(variableRow),
      );
      const updatedVar = {
        ...rowToReorder.variable,
        index,
        sectionId,
      };
      // Cache the original position, in case we need to reset
      if (isEmptyOrNull(updatedVar.oldIndex)) {
        updatedVar.oldIndex = rowToReorder.variable.index;
      }
      varsInSection.splice(index, 0, {
        ...rowToReorder,
        variable: updatedVar,
      });
      varsInSection = varsInSection.map(mapVarIdxAsProp);
      // Replace the reordered section in the main array
      const firstVarIdx = customVariables.findIndex(
        ({ variable }) => variable.sectionId === sectionId,
      );
      customVariables.splice(firstVarIdx, 0, ...varsInSection);

      return { ...state, customVariables };
    }
    case actions.REORDER_CUSTOM_VARIABLE_SECTION: {
      let customVariableSections = [...state.customVariableSections];
      const sectionIdx = customVariableSections.findIndex(
        ({ id }) => id === payload.id,
      );
      const [section] = customVariableSections.splice(sectionIdx, 1);
      const updatedSection = { ...section };
      // Cache the original position, in case we need to reset
      if (isEmptyOrNull(updatedSection.oldIndex)) {
        updatedSection.oldIndex = updatedSection.index;
      }
      customVariableSections.splice(payload.index, 0, updatedSection);
      customVariableSections = customVariableSections.map(mapSectionIdxAsProp);
      return { ...state, customVariableSections };
    }
    case actions.SAVE_CUSTOM_VARIABLE: {
      const newVar = {
        variable: { ...payload },
        months: getDatesInRange(payload.startDate, payload.endDate).map(
          (month) => ({
            month: getFormattedDateFromTimeStamp(month),
            value: null,
          }),
        ),
      };
      const customVariables = getExistingCustomVariables(state);
      return {
        ...state,
        customVariables: [...customVariables, newVar],
      };
    }
    case actions.SAVE_CUSTOM_VARIABLE_SECTION: {
      const newSection = { ...payload };
      const existingSections = getExistingCustomVariableSections(state);
      const customVariableSections = [newSection, ...existingSections].map(
        mapSectionIdxAsProp,
      );
      return { ...state, customVariableSections };
    }
    case actions.SET_CUSTOM_VARIABLES: {
      // If the user is in the process of adding a new variable, don't erase it
      const placeholder = state.customVariables.filter(
        ({ id }) => id === PLACEHOLDER_ID,
      );
      const customVariables = [
        ...placeholder,
        ...payload.map(({ variable, months }) => ({
          months,
          variable: {
            ...variable,
            faulted: isAnyMonthFaulted(months),
          },
        })),
      ];
      return { ...state, customVariables };
    }
    case actions.SET_CUSTOM_VARIABLE_SECTIONS:
      return { ...state, customVariableSections: payload };
    case actions.SET_SYSTEM_VARIABLES: {
      const systemVariables = mapSystemVariables(payload);
      const systemVariablesSections = Array.from(
        new Set(systemVariables.map(({ categoryName }) => categoryName)),
      );
      return { ...state, systemVariables, systemVariablesSections };
    }
    case actions.SET_VARIABLE_AUTOCOMPLETE_OPTIONS:
      return {
        ...state,
        autocompleteOptions: flattenVariablesTree(payload).map(
          ({ id: variableId, finmarkVariableType, name }) => ({
            id: variableId,
            value: name,
            label: name,
            type:
              finmarkVariableType === 'SystemVariableDto'
                ? variableTypes.SYSTEM
                : variableTypes.CUSTOM,
          }),
        ),
      };
    case actions.UPDATE_CUSTOM_VARIABLE: {
      const customVariables = [...state.customVariables];
      const varIdx = customVariables.findIndex(
        ({ variable }) => variable.id === payload.id,
      );
      customVariables[varIdx] = {
        ...customVariables[varIdx],
        variable: payload,
      };
      return { ...state, customVariables };
    }
    case actions.UPDATE_CUSTOM_VARIABLE_SECTION: {
      const customVariableSections = [...state.customVariableSections];
      const sectionIdx = customVariableSections.findIndex(
        ({ id }) => id === payload.id,
      );
      customVariableSections[sectionIdx] = payload;
      return { ...state, customVariableSections };
    }
    case actions.UPDATE_CUSTOM_VARIABLE_VALUE: {
      const {
        variableId,
        amount,
        displayFormula,
        faulted,
        variableValueFaultType,
      } = payload;
      const customVariables = [...state.customVariables];
      const varIdx = customVariables.findIndex(
        ({ variable }) => variable.id === variableId,
      );

      if (varIdx < 0) return state;

      const editedVariable = customVariables[varIdx];

      const months = [...editedVariable.months];
      const monthIdx = months.findIndex(
        ({ month } = {}) => month === payload.month,
      );
      const editedMonth = months[monthIdx];
      months[monthIdx] = {
        month: editedMonth.month,
        value: {
          ...editedMonth.value,
          amount,
          displayFormula,
          faulted,
          variableValueFaultType,
        },
      };
      customVariables[varIdx] = {
        variable: {
          ...editedVariable.variable,
          faulted: isAnyMonthFaulted(months),
        },
        months,
      };
      return {
        ...state,
        customVariables,
        lastUpdatedCustomVariable: {
          variableId,
          editedMonth,
          variableValueFaultType,
        },
      };
    }
    case SET_SCENARIO_ID:
    case SWAP_SCENARIOS:
      return {
        ...state,
        customVariables: [],
        customVariableSections: [],
      };
    default:
      return state;
  }
};

export default variablesReducer;
