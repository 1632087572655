import { useState, useEffect } from 'react';

const observed = new Map();
let ro;

/**
 * A hook that makes getting an element's size easier, especially when the
 * element size changes.
 *
 * @param {Object} node - A React reference or dom element
 * @returns {ResizeObserverEntry}
 * @see https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver/ResizeObserver
 */
const useElementSize = ({ current }) => {
  const [observerEntry, setObserverEntry] = useState({});

  useEffect(() => {
    if (!current) return undefined;

    if (!ro) {
      ro = new ResizeObserver((entries) => {
        // Wrapping in requestAnimationFrame prevents the console error
        // "ResizeObserver loop limit exceeded", which causes Cypress to fail
        requestAnimationFrame(() => {
          entries.forEach((entry) => {
            const callback = observed.get(entry.target);
            callback?.(entry);
          });
        });
      });
    }

    observed.set(current, setObserverEntry);
    ro.observe(current);

    return () => {
      observed.delete(current);
      if (!observed.size) {
        ro.disconnect();
        ro = null;
      } else {
        ro.unobserve(current);
      }
    };
  }, [current]);

  return observerEntry;
};

export default useElementSize;
