import { useEffect } from 'react';
import { NET_CASH_FLOW } from '@/cacheKeys';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import { chartTypes } from '@/components/Charts/constants';
import { classNames } from '@/helpers';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import useChartQuery from '@/hooks/useChartQuery';
import useTypedSelector from '@/hooks/useTypedSelector';
import {
  CHART_TITLE,
  NET_CASH_FLOW_METRICS,
} from '@/pages/Dashboard/constants/netCashFlow';
import { getNetCashFlow } from '@/services/dashboard.service';

const reducer = ({ cashFlows, totalNetCash }) => {
  return {
    totalNetCash,
    ...mapMonthlyData(cashFlows, 'netCash'),
  };
};

/**
 * @typedef {{
 *   isLoading?: boolean;
 *   data: {
 *     totalNetCash: number;
 *   };
 * }} QueryStateChangeData
 */

/**
 * @typedef {{
 *   className?: string;
 *   plotOptions?: Highcharts.PlotOptions;
 *   axisStyles?: Highcharts.CSSObject | {};
 *   onQueryStateChange?: (data: QueryStateChangeData[]) => void;
 *   tooltipOptions?: Object;
 *   exportBtn: Object;
 * }} NetCashFlowChart
 */

/**
 * Fetches and renders a column chart showing monthly NetCash flow
 *
 * @example
 *   <NetCashFlowChart exportBtn={exportBtn} />;
 *
 * @type {(props: NetCashFlowChart) => React.ReactElement}
 */
function NetCashFlowChart({
  className,
  exportBtn,
  plotOptions,
  axisStyles,
  tooltipOptions,
  onQueryStateChange,
}) {
  const scenarioId = useTypedSelector(({ scenario }) => scenario.scenarioId);
  const { startDate, endDate, timePeriod } = useTypedSelector(
    ({ shared }) => shared,
  );

  const scenarioQueries = useChartQuery(NET_CASH_FLOW, getNetCashFlow, reducer);

  useEffect(() => {
    if (scenarioQueries.length) onQueryStateChange?.(scenarioQueries);
  }, [scenarioQueries, onQueryStateChange]);

  return (
    <DateChart
      className={classNames('NetCashFlowChart', className)}
      data-testid="netFlowChart"
      loading={scenarioQueries.every(({ isLoading }) => isLoading)}
      plotOptions={plotOptions}
      axisStyles={axisStyles}
      tooltip={
        <ChartTooltip
          metrics={NET_CASH_FLOW_METRICS}
          timePeriod={timePeriod}
          endDate={endDate}
          {...tooltipOptions}
        />
      }
      ref={exportBtn}
      timePeriod={timePeriod}
      startDate={startDate}
      endDate={endDate}
    >
      {scenarioQueries.map(
        ({ data, scenario }, idx) =>
          data && (
            <DateChart.Series
              key={scenario.scenarioId}
              type={chartTypes.COLUMN}
              data={data.data}
              index={idx}
              isComparison={scenario?.scenarioId !== scenarioId}
              metric={CHART_TITLE}
              scenario={scenario}
            />
          ),
      )}
    </DateChart>
  );
}

export default NetCashFlowChart;
