// @ts-check
import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setTMXSessionId, setUnauthorizedByTMXAction } from '@/actions/auth';
import { setCompaniesOnLoginAction } from '@/actions/companies';
import { createAccountWithoutVerificationAction } from '@/actions/signUp';
import CountryField from '@/components/Settings/Address/CountryField';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';
import InfoTooltip from '@/components/common/InfoTooltip';
import PasswordField, {
  PASSWORD_REGEX,
  PASSWORD_RULE,
} from '@/components/common/PasswordField';
import { CODAT_XERO } from '@/constants/integrations';
import {
  JOIN_ADDITIONAL_INFO_PATH,
  UNAUTHORIZED_PATH,
} from '@/constants/pages';
import { DECLINED_ERROR_CODE } from '@/constants/threatmetrix';
import integrationsRedirect from '@/helpers/integrationsRedirect';
import useTypedSelector from '@/hooks/useTypedSelector';
import withProtectedRoute from '@/pages/Registration/WithProtectedRoute';
import getSelectedCompanyId from '@/selectors/shared/getSelectedCompanyId';
import { actions, steps, XERO, routeProtections } from './constants';

const checkValid = (pass) => {
  return PASSWORD_REGEX.test(pass);
};

/**
 * @type {(
 *   props: import('@/containers/Registration').RegistrationState & {
 *     onChange: React.Dispatch<
 *       import('@/containers/Registration').RegistrationDispatchType
 *     >;
 *   },
 * ) => React.ReactElement}
 */
const Password = ({
  email,
  password,
  confirmPassword,
  confirmAcceptTerms,
  source,
  firstName,
  lastName,
  companyName,
  currency,
  onChange,
  country,
}) => {
  const history = useHistory();
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const deviceSessionId = useTypedSelector(({ auth }) => auth.deviceSessionId);
  const identityToken = useTypedSelector(({ auth }) => auth.identityToken);
  const [isLoading, setLoading] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState([]);
  const selectedCompanyId = useSelector(getSelectedCompanyId);
  const companies = useSelector(
    ({ companies: companiesState }) => companiesState.companies,
  );
  const { isAuthenticated, isUnauthorizedByTMX } = useSelector(
    ({ auth }) => auth,
  );
  useEffect(() => {
    onChange({ type: actions.SET_STEP, payload: steps.PASSWORD });
  }, [onChange]);

  useEffect(() => {
    if (selectedCompanyId && isAuthenticated) {
      dispatch(setCompaniesOnLoginAction());
    }
  }, [selectedCompanyId, dispatch, isAuthenticated]);

  useEffect(() => {
    if (isUnauthorizedByTMX) {
      history.replace(UNAUTHORIZED_PATH);
    }
    if (companies.length > 0 && selectedCompanyId) {
      const selectedCompany = companies.find(
        ({ id }) => id === selectedCompanyId,
      );

      if (source === XERO) {
        integrationsRedirect({
          selectedCompany,
          redirectTo: 'dashboard',
          identityToken,
        })(CODAT_XERO);
      } else {
        history.push(JOIN_ADDITIONAL_INFO_PATH);
      }
    }
  }, [
    identityToken,
    companies,
    history,
    source,
    selectedCompanyId,
    isUnauthorizedByTMX,
  ]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMsgs([]);
    try {
      await dispatch(
        createAccountWithoutVerificationAction({
          email,
          password,
          confirmPassword,
          firstName,
          lastName,
          companyName,
          currency,
          country,
          deviceSessionId,
        }),
      );
    } catch (e) {
      if (e.response?.data?.error?.errorCode === DECLINED_ERROR_CODE) {
        dispatch(setUnauthorizedByTMXAction());
        return;
      }
      if (Array.isArray(e)) {
        setErrorMsgs(
          e.map(
            (error) =>
              error.response?.data?.error?.errorMessage || error.message,
          ),
        );
      } else {
        setErrorMsgs([e.response?.data?.error?.errorMessage || e.message]);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(setTMXSessionId());
  }, [dispatch]);

  return (
    <>
      <h1 className="RegistrationHeading">Connect to Finmark</h1>
      <p className="RegistrationText">
        The next step is for you to create a password. Once you've done that,
        please agree to the terms and conditions. When you’re ready, hit
        'Finish'.
      </p>
      <form className="RegistrationContent-passwords" onSubmit={handleSubmit}>
        <div className="Registration_FieldGroup">
          <label htmlFor="password" className="RegistrationLabel">
            Password
          </label>
          <PasswordField
            id="password"
            name="password"
            className="RegistrationField-password"
            value={password}
            placeholder="Create Password"
            validate={() => {
              const isValid = checkValid(password);
              return !isValid
                ? 'Passwords must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character. Please try again.'
                : '';
            }}
            onChange={({ target }) =>
              onChange({ type: actions.SET_PASSWORD, payload: target.value })
            }
            autoFocus
          />
          <InfoTooltip
            placement="top"
            data-testid="password-rule"
            className="RegistrationTooltip"
          >
            {PASSWORD_RULE}
          </InfoTooltip>
        </div>
        <div className="Registration_FieldGroup">
          <label htmlFor="password-confirm" className="RegistrationLabel">
            Confirm Password
          </label>
          <PasswordField
            id="confirm-password"
            name="confirmPassword"
            className="RegistrationField-password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={({ target }) =>
              onChange({
                type: actions.SET_PASSWORD_CONFIRM,
                payload: target.value,
              })
            }
            validate={() => {
              const isValid = password === confirmPassword;
              return !isValid ? 'Password does not match' : '';
            }}
          />
        </div>
        <div className="RegistrationField_Country">
          <CountryField
            label="Country of Residence"
            value={country}
            onChange={({ target }) =>
              onChange({ type: actions.SET_COUNTRY, payload: target.value })
            }
          />
        </div>
        <Checkbox
          id="confirmAcceptTerms"
          name="confirmAcceptTerms"
          className="RegistrationToS"
          checked={confirmAcceptTerms}
          onChange={() => {
            onChange({ type: actions.SET_TOS, payload: !confirmAcceptTerms });
          }}
        >
          I agree to the Finmark{' '}
          <a
            href="https://finmark.com/terms-conditions/"
            className="RegistrationLink"
            target="_blank"
            data-testid="termsAndConditionsLink"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>
          ,{' '}
          <a
            href="https://finmark.com/privacy-policy/"
            className="RegistrationLink"
            target="_blank"
            data-testid="privacyPolicyLink"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          , and{' '}
          <a
            href="https://finmark.com/acceptable-use-policy/"
            className="RegistrationLink"
            target="_blank"
            data-testid="aupLink"
            rel="noopener noreferrer"
          >
            Acceptable Use Policy
          </a>
        </Checkbox>
        {errorMsgs.length > 0 && (
          <div className="RegistrationAlert">
            <ul className="RegistrationAlert_List">
              {errorMsgs.map((msg, index) => (
                // eslint-disable-next-line react/no-array-index-key -- no unique identifier available
                <li key={index} className="RegistrationAlert_ListItem">
                  {msg}
                </li>
              ))}
            </ul>
          </div>
        )}
        <Button
          type="submit"
          id="submit-password"
          className="Button-registration"
          data-testid="button-next-password"
          disabled={
            !(
              checkValid(password) &&
              password === confirmPassword &&
              confirmAcceptTerms &&
              country
            )
          }
          loading={isLoading}
        >
          Next
        </Button>
      </form>
    </>
  );
};

export default withProtectedRoute(Password, {
  protectedBy: routeProtections.EMAIL,
});
