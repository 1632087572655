// @ts-check
import { useEffect } from 'react';
import { Reorder, useMotionValue, animate } from 'framer-motion';
import { classNames } from '@/helpers';

const INACTIVE_COLOR = '#f5f8ff';
const ACTIVE_COLOR = '#e8eefc';
const INACTIVE_SHADOW = '';
const ACTIVE_SHADOW = '0px 2px 2px rgba(98, 143, 248, 0.11)';

/**
 * @typedef {{
 *   id: string;
 *   value: import('@/components/Revenue/DataMapping/types.js').RuleWithRevenueStream;
 *   className: string;
 *   disabled: boolean;
 * }} RuleListItemProps
 */

/** @type React.FC<RuleListItemProps> */
const RuleListItem = ({ id, value, className, children, disabled }) => {
  const vertical = useMotionValue(0);
  const backgroundColor = useMotionValue(INACTIVE_COLOR);
  const boxShadow = useMotionValue(INACTIVE_SHADOW);

  useEffect(() => {
    let isActive = false;
    vertical.onChange((latest) => {
      if (disabled) return;
      const wasActive = isActive;
      // When framer motion is exiting an animation, `latest` will be a
      // fractional number
      const isExiting = latest % 1 !== 0;
      if (latest !== 0) {
        isActive = true;
        if (isActive !== wasActive) {
          animate(backgroundColor, ACTIVE_COLOR);
          animate(boxShadow, ACTIVE_SHADOW);
          // Immediately stop animating position and animate to resting
          // backgroundColor and boxShadow when exiting
        } else if (isExiting && vertical.isAnimating()) {
          isActive = false;
          animate(backgroundColor, INACTIVE_COLOR);
          animate(boxShadow, INACTIVE_SHADOW);
          // Stop animating immediately. This will result in
          // vertical.isAnimating() to return false
          vertical.stop();
        }
      }
    });
    return () => vertical.destroy();
  }, [vertical, backgroundColor, boxShadow, disabled]);

  return (
    <Reorder.Item
      id={id}
      value={value}
      className={classNames(
        className,
        !disabled && 'RulesSideBarContent_ListItem-draggable',
      )}
      transformTemplate={({ x, y }) =>
        `translateX(${x}) translateY(${y}) translate3d(${x}, ${y}, 1)`
      }
      style={{ y: vertical, backgroundColor, boxShadow }}
    >
      {children}
    </Reorder.Item>
  );
};

export default RuleListItem;
