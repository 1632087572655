// @ts-check
import { useCallback, useState } from 'react';
import SendIcon from '@bill/cashflow.assets/send';
import Button from '@/components/common/Button';
import LetterIcon from '@/components/common/LetterIcon';
import ResizeableTextarea from '@/components/common/ResizeableTextArea';
import { classNames } from '@/helpers';
import useSelectedScenarios from '@/hooks/useSelectedScenarios';
import './CopilotTextArea.scss';

/**
 * @typedef {{
 *   onSend: (text: string) => void;
 *   disabled?: boolean;
 *   isLoading?: boolean;
 * }} CopilotTextareaProps
 */

/** @type {(props: CopilotTextareaProps) => React.ReactElement} */
const CopilotTextarea = ({ disabled, onSend, isLoading }) => {
  const [scenario] = useSelectedScenarios();
  const [text, setText] = useState('');

  /** @type {React.ChangeEventHandler<HTMLTextAreaElement>} */
  const handleTextChange = ({ target }) => {
    setText(target.value);
  };

  const handleSend = useCallback(() => {
    if (text.trim().length > 0) {
      onSend(text);
      setText('');
    }
  }, [onSend, text]);

  /** @type {React.KeyboardEventHandler<HTMLTextAreaElement>} */
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        if (!disabled && !isLoading) {
          handleSend();
        }
      }
    },
    [handleSend, disabled, isLoading],
  );

  return (
    <div
      data-testid="letter-prefix"
      className={classNames(
        'CopilotTextArea',
        disabled && 'CopilotTextArea_Disabled',
      )}
      aria-disabled={disabled}
    >
      {!disabled && (
        <span className="FormField_Prefix" data-testid="copilot-prefix">
          <LetterIcon
            string={scenario?.name ?? ''}
            color={scenario?.color}
            className="CopilotTextArea_LetterIcon"
            data-testid="copilot-letter-icon"
          />
        </span>
      )}
      <ResizeableTextarea
        id="copilot-textarea"
        name="copilot-textarea"
        onChange={handleTextChange}
        onKeyDown={handleKeyDown}
        placeholder="Send a message"
        value={text}
        maxLines={5}
        disabled={disabled}
      />
      <Button
        className="CopilotTextArea_SendButton"
        data-testid="copilot-send-button"
        onClick={handleSend}
        disabled={disabled || text.trim().length === 0}
        loading={isLoading}
      >
        <SendIcon className="SendIcon" />
      </Button>
    </div>
  );
};

export default CopilotTextarea;
