import CheckmarkIcon from '@bill/cashflow.assets/checkmark';
import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import './Checkbox.scss';

/**
 * Creates a checkbox input
 *
 * @example
 *   <Checkbox
 *     id="foo"
 *     checked={bar}
 *     onChange={({ target }) => setBar(target.value)}
 *   >
 *     Click me!
 *   </Checkbox>;
 *
 * @type {(
 *   props: React.ComponentPropsWithoutRef<'input'>,
 * ) => React.ReactElement}
 */
function Checkbox({
  id,
  checked = false,
  className,
  children,
  onChange,
  ...props
}) {
  return (
    <label className={classNames('Checkbox', className)} data-testid={id}>
      <input
        type="checkbox"
        id={id}
        className="Checkbox_Input"
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <div className="Checkbox_Box" aria-hidden="true">
        <CheckmarkIcon className="Checkbox_Icon" />
      </div>
      {children && <span className="Checkbox_Label">{children}</span>}
    </label>
  );
}

Checkbox.propTypes = {
  /** Whether or not the checkbox is checked */
  checked: PropTypes.bool,
  /** Label for the checkbox */
  children: PropTypes.node,
  /** Additional class(es) to apply to the element */
  className: PropTypes.string,
  /** Unique ID for selecting the checkbox in unit/integration tests */
  id: PropTypes.string.isRequired,
  /**
   * Event handler for when the user changes the state of the checkbox
   *
   * @param {Object} event Original change event
   */
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;
