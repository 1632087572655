import { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Catches any errors thrown from a component tree and renders a fallback
 * component instead of the dreaded white screen
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  handleReset = () => this.setState(() => ({ hasError: false }));

  render() {
    const { children, FallbackComponent, onClose, ...props } = this.props;
    if (this.state.hasError)
      return (
        <FallbackComponent
          onReset={this.handleReset}
          onClose={onClose}
          {...props}
        />
      );

    return children ?? null;
  }
}

ErrorBoundary.propTypes = {
  /** React component(s) that may throw errors to catch */
  children: PropTypes.node,
  /** Component to render if an error is thrown */
  FallbackComponent: PropTypes.elementType,
  /**
   * Event handler for when the user clicks the close button. If no handler is
   * provided, the button will not appear.
   */
  onClose: PropTypes.func,
};

export default ErrorBoundary;
