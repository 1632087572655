/**
 * Returns the scenario state for the currently selected base scenario ID.
 *
 * @param {Object} state Redux store
 * @returns {Object} The selected base scenario
 */
export default function getBaseScenario(state) {
  const { scenarioId, scenarios } = state.scenario;
  return scenarios.find((scenario) => scenario.scenarioId === scenarioId);
}
