import { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { connect } from 'react-redux';
import {
  handleStreamDataChangeAction,
  updateCustomRevenueAction,
} from '@/actions/revenue';
import RevenueDriverTooltip from '@/components/Revenue/RevenueStream/RevenueDriverTooltip';
import RevenueStreamFooter from '@/components/Revenue/RevenueStream/RevenueStreamFooter';
import { hasEndDate } from '@/components/Revenue/helpers';
import Checkbox from '@/components/common/Checkbox';
import CurrencyField from '@/components/common/CurrencyField';
import MonthField from '@/components/common/MonthField';
import NumberField from '@/components/common/NumberField';
import Select from '@/components/common/Select';
import { REVENUE_STRIPE } from '@/constants/integrations';
import { STREAMS, DRIVER } from '@/constants/revenueStream';
import { isCustomRevenueValid } from '@/helpers/customRevenue';
import {
  getDateOffsetByMonths,
  getDateOffsetByYears,
  getISODate,
} from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import { isPercentageValid } from '@/helpers/validators';
import {
  createCustomRevenueSourceForRevenueOnly,
  createRevenueSourceForMOMGrowth,
  updateCustomRevenueSourceForRevenueOnly,
  updateRevenueSourceForMOMGrowth,
  createOrUpdateCustomFormulaRevenueStreamRevenueOnly,
} from '@/services/revenueService';
import CustomRevenue from './CustomRevenue';
import CustomFormulaDriverValues from './Drivers/CustomFormulaDriverValues';

const calTotalRevenueInAYear = (initialRevenueAmount, monthlyGrowthRate) => {
  let total = initialRevenueAmount;
  for (let i = 1; i < 12; i += 1) {
    total += (total / 100) * monthlyGrowthRate;
  }
  return Math.round(total);
};

const StepTwoRevenueOnly = ({
  revenue,
  revenueStream,
  handleStreamDataChange,
  updateCustomRevenue,
  mode,
  revenueStreamRecordToBeEdit,
  setStep,
  step,
  handleClose,
  scenarioId,
  globalStartDate,
  globalEndDate,
  scenario,
  onFormulaGuideClick,
  onSaved,
}) => {
  const [totalRevenueNextMonth, setTotalRevenueNextMonth] = useState();
  const [totalRevenueInAYear, setTotalRevenueInAYear] = useState();
  const [saving, setSaving] = useState(false);
  const [isStreamValid, setIsStreamValid] = useState(false);
  const [error, setError] = useState('');

  const {
    driverType,
    startDate,
    endDate,
    noEndDate,
    monthlyGrowthRate,
    initialRevenueAmount,
    customRevenue,
    revenueType,
  } = revenueStream;

  const { drivers: revenueTypeDrivers, pricingPlanItem } = STREAMS[revenueType];

  const mapDrivers = (driver) => (
    <option value={driver.type} key={driver.id}>
      {driver.label}
    </option>
  );

  useEffect(() => {
    if (mode === 'edit') {
      handleStreamDataChange('isFormulaValid', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, []);

  useEffect(() => {
    const nextMonthRevenue =
      parseInt(initialRevenueAmount, 10) +
      parseInt(initialRevenueAmount, 10) *
        (parseFloat(monthlyGrowthRate) / 100);
    setTotalRevenueNextMonth(parseInt(nextMonthRevenue, 10));
    const yearlyRevenue = calTotalRevenueInAYear(
      parseInt(initialRevenueAmount, 10),
      parseFloat(monthlyGrowthRate),
    );
    setTotalRevenueInAYear(yearlyRevenue);
  }, [initialRevenueAmount, monthlyGrowthRate]);

  useEffect(() => {
    if (driverType === DRIVER.MONTHLY_GROWTH) {
      setIsStreamValid(
        startDate &&
          hasEndDate(noEndDate, endDate) &&
          monthlyGrowthRate > -1 &&
          initialRevenueAmount !== null,
      );
    }
    if (driverType === DRIVER.CUSTOM_GROWTH) {
      setIsStreamValid(isCustomRevenueValid(customRevenue, 'revenueAmount'));
    }
  }, [
    revenueStream,
    customRevenue,
    startDate,
    endDate,
    noEndDate,
    monthlyGrowthRate,
    driverType,
    initialRevenueAmount,
  ]);

  const handleSubmit = async () => {
    const params = {
      scenarioId,
      revenueStream,
      globalStartDate,
      globalEndDate,
    };
    try {
      setSaving(true);
      setError('');
      switch (true) {
        case revenueStream.driverType === DRIVER.CUSTOM_FORMULA:
          await createOrUpdateCustomFormulaRevenueStreamRevenueOnly(params);
          break;
        case revenueStream.driverType === DRIVER.MONTHLY_GROWTH &&
          mode === 'edit':
          await updateRevenueSourceForMOMGrowth(params);
          break;
        case revenueStream.driverType === DRIVER.MONTHLY_GROWTH:
          await createRevenueSourceForMOMGrowth(params);
          break;
        case !revenueStream.driverType !== DRIVER.MONTHLY_GROWTH &&
          mode === 'edit':
          await updateCustomRevenueSourceForRevenueOnly(params);
          break;
        case !revenueStream.driverType !== DRIVER.MONTHLY_GROWTH:
          await createCustomRevenueSourceForRevenueOnly(params);
          break;
        default:
      }
      onSaved?.();
      handleClose();
    } catch (e) {
      setError(e.response?.data?.error?.errorMessage || e.message);
    } finally {
      setSaving(false);
    }
  };

  const handleEndDateChange = ({ target }) => {
    handleStreamDataChange('noEndDate', target.checked);
    handleStreamDataChange('endDate', null);
  };

  const startDateMs = startDate ? new Date(startDate).getTime() : null;
  const endDateMs = !noEndDate ? new Date(endDate).getTime() : null;

  return (
    <Container fluid className="px-0 pt-4 pl-2">
      {error && (
        <p className="SubscriptionRevenue_ErrorAlert">
          <b>Error </b> - {error}
        </p>
      )}
      <Row>
        <Col className="text-left">
          <p>How would you like to forecast your revenue?</p>
          <div className="RevenueStream_Alert">
            You have chosen to enter revenue totals only for this revenue stream
            (driver), rather then building up those totals from unit sales,
            signups and other details. Please select how you'd like to project
            it.
          </div>
        </Col>
      </Row>{' '}
      <Row>
        <Col className="text-left mt-3">
          <Form.Group>
            <label htmlFor="driverType" className="Label">
              Forecast Method
              <RevenueDriverTooltip isRevOnly formulaForecastItem="revenues" />
            </label>
            <Select
              id="driverType"
              name="driverType"
              disabled={mode === 'edit'}
              value={driverType}
              onChange={(event) =>
                handleStreamDataChange(event.target.name, event.target.value)
              }
            >
              {revenueTypeDrivers.map(mapDrivers)}
            </Select>
          </Form.Group>
        </Col>
      </Row>
      {driverType === DRIVER.MONTHLY_GROWTH && (
        <>
          <Row className="mb-2">
            <Col md={12}>
              <p className="mb-0">
                {STREAMS[revenueStream.revenueType].revenueGenerationDateLabel}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="text-left pr-2">
              <Form.Group className="mb-0">
                <label className="Label" htmlFor="startDatePicker">
                  {
                    STREAMS[revenueStream.revenueType]
                      .revenueGenerationStartDateLabel
                  }
                  <sup style={{ color: 'red' }}>*</sup>
                </label>
                <MonthField
                  value={startDateMs}
                  id="startDatePicker"
                  onChange={(val) =>
                    handleStreamDataChange('startDate', getISODate(val))
                  }
                  max={getDateOffsetByYears(new Date().setUTCMonth(11), 6)}
                />
              </Form.Group>
            </Col>
            <Col md={6} lg={6} className="text-left pl-2">
              <Form.Group className="mb-0">
                <label className="Label" htmlFor="endDatePicker">
                  {
                    STREAMS[revenueStream.revenueType]
                      .revenueGenerationEndDateLabel
                  }
                </label>
                <MonthField
                  value={endDateMs}
                  min={getDateOffsetByMonths(startDate, 1).getTime()}
                  id="endDatePicker"
                  onChange={(val) => {
                    handleStreamDataChange('noEndDate', !val);
                    handleStreamDataChange(
                      'endDate',
                      val ? getISODate(val) : null,
                    );
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Checkbox
                  name="noEndDate"
                  checked={noEndDate}
                  id="end-date-check"
                  onChange={handleEndDateChange}
                >
                  No end date
                </Checkbox>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md={6} className="text-left pl-2">
              <Form.Group>
                <label className="Label" htmlFor="initialRevenueAmount">
                  Initial Revenue Amount
                </label>
                <InputGroup>
                  <CurrencyField
                    id="initialRevenueAmount"
                    name="initialRevenueAmount"
                    value={initialRevenueAmount}
                    onChange={({ target }, number) => {
                      handleStreamDataChange(target.name, number);
                    }}
                    validate={() => {
                      if (
                        !Number.isFinite(initialRevenueAmount) ||
                        initialRevenueAmount < 1
                      ) {
                        return 'Please enter a number greater than or equal to one';
                      }
                      return '';
                    }}
                    disabled={
                      revenueStreamRecordToBeEdit?.externalSource ===
                      REVENUE_STRIPE
                    }
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6} className="text-left pl-2">
              <Form.Group>
                <label className="Label" htmlFor="monthlyGrowthRate">
                  Monthly Growth Rate
                </label>
                <div className="d-flex">
                  <InputGroup>
                    <NumberField
                      prefix="%"
                      id="monthlyGrowthRate"
                      validate={() => {
                        const isValid = isPercentageValid(monthlyGrowthRate);
                        return !isValid && 'Please enter a valid number';
                      }}
                      name="monthlyGrowthRate"
                      value={monthlyGrowthRate}
                      onChange={({ target }, number) => {
                        handleStreamDataChange(target.name, number);
                      }}
                    />
                  </InputGroup>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="text-left pl-2">
              <Form.Group>
                <h6 className="Label">Total Revenue Next Month</h6>
                <p className="text-heading">
                  {totalRevenueNextMonth
                    ? formatMonetary(totalRevenueNextMonth)
                    : ''}
                </p>
              </Form.Group>
            </Col>
            <Col md={6} className="text-left pl-2">
              <Form.Group>
                <h6 className="Label">
                  Total Revenue 12 Months from start date
                </h6>
                <p className="text-heading">
                  {totalRevenueInAYear
                    ? formatMonetary(totalRevenueInAYear)
                    : ''}
                </p>
              </Form.Group>
            </Col>
          </Row>
        </>
      )}
      {driverType === DRIVER.CUSTOM_GROWTH && (
        <CustomRevenue
          customRevenue={customRevenue}
          updateCustomRevenue={updateCustomRevenue}
          scenario={scenario}
          scenarioId={scenarioId}
        />
      )}
      {driverType === DRIVER.CUSTOM_FORMULA && (
        <CustomFormulaDriverValues
          pricingPlanItem={pricingPlanItem}
          revenue={revenue}
          onValid={setIsStreamValid}
          onFormulaGuideClick={onFormulaGuideClick}
        />
      )}
      <RevenueStreamFooter
        setStep={setStep}
        step={step}
        nextLoading={saving}
        disablePrevious
        disableNext={!isStreamValid}
        revenueStream={revenueStream}
        handleClose={() => handleClose()}
        handleNext={handleSubmit}
        nextText={mode === 'edit' ? 'Save' : 'Add'}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  revenueStream: state.revenues.revenueStream,
  revenueStreamRecordToBeEdit: state.revenues.revenueStreamRecordToBeEdit,
  scenarioId: state.scenario.scenarioId,
  globalStartDate: state.shared.startDate,
  globalEndDate: state.shared.endDate,
  scenario: state.scenario,
});

export default connect(mapStateToProps, {
  handleStreamDataChange: handleStreamDataChangeAction,
  updateCustomRevenue: updateCustomRevenueAction,
})(StepTwoRevenueOnly);
