import ContextMenu from '@/components/common/ContextMenu';
import { actualsFamily } from '@/constants/actuals';
import { employeeFormModes } from '@/constants/employees';
import { expenseGroupTypes } from '@/constants/expenses';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';

const menuLabels = {
  EXPENSE: 'Expense',
  REVENUE: 'Revenue Stream (Driver)',
  PAYROLL: 'Employee',
};

const entityMap = {
  EXPENSE: 'expenseGroup',
  REVENUE: 'revenueStream',
  PAYROLL: 'employee',
};

const canDelete = (currentRecord, family) => {
  if (family === actualsFamily.EXPENSE) {
    const canDeleteParentExpense = !currentRecord.isParentExpense;
    return (
      (currentRecord.expenseGroupType !== expenseGroupTypes.ACCOUNT ||
        !currentRecord.active) &&
      canDeleteParentExpense
    );
  }
  return family === actualsFamily.PAYROLL || family === actualsFamily.REVENUE;
};

function ActualsContextMenuRenderer({ data, onEditClick, onDeleteClick }) {
  const { family, key, actualParentEntity } = data;

  const currentRecord = actualParentEntity?.[entityMap[family]];
  const editInfo = currentRecord ?? key;
  const hasWritePermission = useNonDashboardWritePermission();

  return (
    <ContextMenu data-testid={`${key}-actual-contextMenu`}>
      <ContextMenu.Option
        onClick={() => onEditClick(editInfo, family, employeeFormModes.EDIT)}
        data-testid={`${key}-edit-actual`}
      >
        <span className="ContextMenu_Label">
          {hasWritePermission ? `Edit ${menuLabels[family]}` : `View Details`}
        </span>
      </ContextMenu.Option>
      {family === actualsFamily.PAYROLL && hasWritePermission && (
        <ContextMenu.Option
          onClick={() => {
            onEditClick(editInfo, family, employeeFormModes.DUPLICATE);
          }}
          data-testid={`${key}-duplicate-actual`}
        >
          <span className="ContextMenu_Label">
            Duplicate {menuLabels[family]}
          </span>
        </ContextMenu.Option>
      )}

      {hasWritePermission && canDelete(currentRecord, family) && (
        <ContextMenu.Option
          onClick={() => onDeleteClick(editInfo, family)}
          danger
          data-testid={`${key}-delete-actual`}
        >
          <span>Delete {menuLabels[family]}</span>
        </ContextMenu.Option>
      )}
    </ContextMenu>
  );
}

export default ActualsContextMenuRenderer;
