// @ts-check
import { useCallback, useEffect, useState } from 'react';

/** @typedef {Record<string, string>} ForecastFormulas */

/**
 * Hook for managing forecast formulas. It populates based on the initial query
 * that fires when the page is launched. Then it allows for optimistic updates
 * as the user changes values.
 *
 * @type {(
 *   rowData:
 *     | import('@/types/services/backend').CashInOutGrid[]
 *     | import('@/hooks/useReportData').ReportsData[],
 * ) => [ForecastFormulas, (id: string, formula: string) => void]}
 */
const useForecastFormulas = (rowData) => {
  const [formulas, setFormulas] = useState(/** @type {ForecastFormulas} */ {});

  const onSetForecastFormula = useCallback((id, forecastFormula) => {
    setFormulas((prev) => {
      if (prev[id] === forecastFormula) {
        return prev;
      }
      return {
        ...prev,
        [id]: forecastFormula,
      };
    });
  }, []);

  useEffect(() => {
    if (
      Array.isArray(rowData) &&
      rowData.length > 0 &&
      Object.keys(formulas).length === 0
    ) {
      const forecastFormulas = rowData.reduce((acc, row) => {
        return {
          ...acc,
          [row.id]: row.forecastFormula,
        };
      }, {});
      setFormulas(forecastFormulas);
    }
  }, [formulas, rowData, setFormulas]);

  return [formulas, onSetForecastFormula];
};

export default useForecastFormulas;
