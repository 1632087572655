import { forwardRef, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { isInvalidValueForPoP } from '@/components/Charts/helpers';
import ChartRadioGroupPersistent from '@/components/common/ChartRadioGroupPersistent';
import InfoTooltip from '@/components/common/InfoTooltip';
import { INVALID_POP_VALUE_TOOLTIP_TEXT } from '@/constants/charts';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import useChartPreferences from '@/hooks/useChartPreferences';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import CustomersTotalChart, {
  CUSTOMERS_TOTAL_TOGGLE_ID,
} from '@/pages/Dashboard/charts/CustomersTotalChart';

const TotalCustomersGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [customersTotal, setCustomersTotal] = useState({});
    const { startDate, endDate } = useSelector(({ shared }) => shared);
    const showPercentage = useChartPreferences(CUSTOMERS_TOTAL_TOGGLE_ID);
    const onQueryStateChange = useCallback(({ data, isLoading }) => {
      return !isLoading && setCustomersTotal({ ...data });
    }, []);
    return (
      <ChartWidget
        ref={ref}
        title="Total Customers"
        data-testid="customersTotalWidget"
        url="/dashboard/total-customers"
        metricValue={
          showPercentage
            ? customersTotal.forecastTermValuePoP
            : customersTotal.forecastTermValue
        }
        label={formatRangeWithShortYear(startDate, endDate)}
        controls={
          <ChartRadioGroupPersistent
            id={CUSTOMERS_TOTAL_TOGGLE_ID}
            value={false}
            options={[
              { label: 'Customers', value: false },
              { label: 'Percentage', value: true },
            ]}
            className="ControlDropdown_Child"
          />
        }
        {...props}
      >
        {(childProps) => {
          return (
            <>
              <WidgetKPI
                value={
                  showPercentage
                    ? customersTotal.forecastTermValuePoP
                    : customersTotal.forecastTermValue
                }
                label={formatRangeWithShortYear(startDate, endDate)}
                tooltip={
                  showPercentage &&
                  isInvalidValueForPoP(customersTotal.forecastTermValuePoP) && (
                    <InfoTooltip data-testid="customers-total-kpi-tooltip">
                      {INVALID_POP_VALUE_TOOLTIP_TEXT}
                    </InfoTooltip>
                  )
                }
              />
              <CustomersTotalChart
                {...childProps}
                showPercentage={showPercentage}
                onQueryStateChange={onQueryStateChange}
              />
              {children}
            </>
          );
        }}
      </ChartWidget>
    );
  },
);

export default TotalCustomersGlance;
