// @ts-check
import { SET_SELECTED_COMPANY } from '@/actionTypes/companies';
import {
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  HIDE_NOTIFICATION,
  CALCULATION_STATUS_UPDATED,
  CALCULATION_INTERVAL,
  CALCULATION_ERROR,
} from '@/actionTypes/notifications';
import {
  calculationThresholds,
  notificationTypes,
} from '@/components/common/Notifications/constants';
import { calculateTimeElapsed } from '@/helpers/dateFormatter';

/**
 * @typedef {{
 *   calculationPending: boolean;
 *   showCalculationIndicator: boolean;
 *   calculationStartTime: string | null;
 * }} Calculation
 */

/**
 * @typedef {{
 *   notifications: Partial<
 *     import('@/components/common/Notifications/constants').NotificationType
 *   >;
 *   calculations: Calculation;
 * }} NotificationState
 */

/** @type {NotificationState} */
const INITIAL_STATE = {
  notifications: {},
  calculations: {
    calculationPending: false,
    showCalculationIndicator: false,
    calculationStartTime: null,
  },
};

/**
 * @type {(
 *   id: import('@/components/common/Notifications/constants').NotificationId,
 *   state: NotificationState,
 * ) => NotificationState}
 */
const createNotification = (id, state) => {
  const notifications = { ...state.notifications };
  const notification = notifications[id];
  if (!notification) {
    notifications[id] = { ...notificationTypes[id] };
  }
  return { ...state, notifications };
};

/**
 * @param {any} payload - The payload to check
 * @returns {asserts payload is import('@/components/common/Notifications/constants').Notification}
 */
function assertIsNotification(payload) {
  /** @type {(keyof import('@/components/common/Notifications/constants').Notification)[]} */
  const knownKeys = ['severity', 'id', 'isDismissed'];
  const keys = Object.keys(payload);
  if (knownKeys.every((k) => !keys.includes(k))) {
    throw new Error('Not a notification');
  }
}

/**
 * @param {any} payload - The payload to check
 * @returns {asserts payload is import('@/types/services/backend').CalculationProgressDto}
 */
function assertIsCalculationStatus(payload) {
  /** @type {(keyof import('@/types/services/backend').CalculationProgressDto)[]} */
  const knownKeys = ['inProgress', 'updatedAt'];
  const keys = Object.keys(payload);
  if (
    knownKeys.length !== keys.length ||
    knownKeys.every((k) => !keys.includes(k))
  ) {
    throw new Error('Not a calculation status ');
  }
}

/**
 * @type {(
 *   state: NotificationState,
 *   action: import('@/types/extend-redux-toolkit').UnknownAction,
 * ) => NotificationState}
 */
export default function notificationsReducer(
  state = INITIAL_STATE,
  { type, payload },
) {
  switch (type) {
    case HIDE_NOTIFICATION: {
      assertIsNotification(payload);
      const { id } = payload;
      const foundNotification = state.notifications[id];
      if (!foundNotification) return state;

      const clonedNotification = { ...foundNotification };
      clonedNotification.isDismissed = true;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [clonedNotification.id]: clonedNotification,
        },
      };
    }
    case CREATE_NOTIFICATION: {
      assertIsNotification(payload);
      const { id } = payload;
      return createNotification(id, state);
    }
    case DELETE_NOTIFICATION: {
      assertIsNotification(payload);
      const { id } = payload;
      const notifications = { ...state.notifications };
      const foundNotification = notifications[id];
      if (foundNotification) {
        delete notifications[id];
      }
      return { ...state, notifications };
    }
    case SET_SELECTED_COMPANY: {
      return {
        ...INITIAL_STATE,
        notifications: { ...state.notifications },
      };
    }
    case CALCULATION_INTERVAL: {
      const { calculations } = state;
      const timeElapsed = calculateTimeElapsed(
        calculations.calculationStartTime,
      );
      if (timeElapsed >= calculationThresholds.MAX_THRESHOLD) {
        return {
          ...state,
          calculations: {
            ...INITIAL_STATE.calculations,
          },
        };
      }

      /**
       * Hide the indicator when pending calculations are done on the BE and we
       * have shown it to the user for the minimum length of time
       */
      if (
        !calculations.calculationPending &&
        timeElapsed >=
          calculationThresholds.MIN_THRESHOLD +
            calculationThresholds.MIN_PERSIST_TIME
      ) {
        return {
          ...state,
          calculations: {
            ...calculations,
            showCalculationIndicator: false,
            calculationStartTime: null,
          },
        };
      }

      /**
       * We allow up to (MIN_THRESHOLD) for the BE to perform calculations
       * before showing the indicator, also known as a "grace period". Show the
       * indicator when there are pending calculations and the calculation has
       * taken longer than the "grace period"
       */
      if (
        calculations.calculationPending &&
        !calculations.showCalculationIndicator &&
        timeElapsed >= calculationThresholds.MIN_THRESHOLD
      ) {
        return {
          ...state,
          calculations: {
            ...calculations,
            showCalculationIndicator: true,
          },
        };
      }
      return state;
    }
    case CALCULATION_STATUS_UPDATED: {
      assertIsCalculationStatus(payload);
      const { calculations } = state;
      const { inProgress, updatedAt } = payload;

      const startTime = inProgress ? updatedAt : null;
      const timeElapsed = inProgress ? calculateTimeElapsed(startTime) : 0;

      if (timeElapsed > calculationThresholds.MAX_THRESHOLD) {
        return {
          ...state,
          calculations: {
            ...INITIAL_STATE.calculations,
          },
        };
      }

      /**
       * We show the indicator for a minimum amount of time once it's visible.
       * Continue showing it but update state that the BE has completed its
       * calculations
       */
      if (
        !inProgress &&
        calculations.showCalculationIndicator &&
        timeElapsed <
          calculationThresholds.MIN_PERSIST_TIME +
            calculationThresholds.MIN_THRESHOLD
      ) {
        return {
          ...state,
          calculations: { ...calculations, calculationPending: false },
        };
      }

      return {
        ...state,
        calculations: {
          calculationPending: inProgress,
          calculationStartTime: startTime,
          showCalculationIndicator: false,
        },
      };
    }
    case CALCULATION_ERROR:
      return {
        ...state,
        calculations: {
          ...INITIAL_STATE.calculations,
        },
      };
    default:
      return state;
  }
}
