import ContextMenu from '@/components/common/ContextMenu';
import './PaymentAndCollectionItem.scss';

const PaymentAndCollectionItem = ({ term, onDelete, onEdit, showDropDown }) => (
  <div className="PC_Item">
    <span>{term.isDefault ? `Default / ${term.description}` : term.name}</span>
    {showDropDown && (
      <ContextMenu data-testid={`dropdown-action-${term.id}`}>
        <ContextMenu.Option
          data-testid={`edit-term-${term.id}`}
          onClick={onEdit}
        >
          Edit Plan
        </ContextMenu.Option>
        <ContextMenu.Option
          data-testid={`delete-term-${term.id}`}
          danger
          onClick={onDelete}
        >
          Delete Plan
        </ContextMenu.Option>
      </ContextMenu>
    )}
  </div>
);

export default PaymentAndCollectionItem;
