import { gql } from '@apollo/client';

/** Mutation to trigger manual BiPaaSSync from forecasting */
// eslint-disable-next-line import/prefer-default-export, jsdoc/require-jsdoc -- only one mutation export needed for now.
export const BIPAAS_MANUAL_SYNC_MUTATION = gql`
  mutation BiPaaSSyncNow($firstSync: Boolean) {
    biPaaSSyncNow(firstSync: $firstSync) {
      empty
    }
  }
`;
