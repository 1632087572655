import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { ARPA } from '@/cacheKeys';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import { chartTypes } from '@/components/Charts/constants';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import metricFormatters from '@/helpers/metricFormatters';
import useChartQuery from '@/hooks/useChartQuery';
import { getAverageRevenuePerAccount } from '@/services/dashboard.service';

const MAIN_METRIC = 'ARPA';

const metrics = [
  {
    key: 'mrr',
    name: 'Monthly Recurring Revenue',
  },
  {
    key: 'numCustomers',
    name: 'Number of Accounts',
    formatter: metricFormatters.count,
  },
  {
    key: 'y',
    name: MAIN_METRIC,
    isMainMetric: true,
  },
];

const reducer = ({ months, overallAverageValue }) => {
  return {
    overallAverageValue,
    ...mapMonthlyData(months, 'arpa'),
  };
};

/**
 * Fetches and renders a bar chart showing Average Revenue Per Account
 *
 * @example
 *   <AverageRevenuePerAccountChart
 *     exportBtn={exportBtn}
 *     plotOptions={plotOptions}
 *     tooltipOptions={tooltipOptions}
 *   />;
 */
function AverageRevenuePerAccountChart({
  tooltipOptions,
  exportBtn,
  plotOptions,
  axisStyles,
  onQueryStateChange,
}) {
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );

  const scenarioQueries = useChartQuery(
    ARPA,
    getAverageRevenuePerAccount,
    reducer,
  );

  const [base] = scenarioQueries;

  useEffect(() => {
    if (base) onQueryStateChange?.(base);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [base]);

  return (
    <DateChart
      id="arpa-xyChart"
      data-testid="arpa-xyChart-test-id"
      loading={scenarioQueries.every(({ isLoading }) => isLoading)}
      tooltip={
        <ChartTooltip
          metrics={metrics}
          endDate={endDate}
          timePeriod={timePeriod}
          {...tooltipOptions}
        />
      }
      plotOptions={plotOptions}
      axisStyles={axisStyles}
      ref={exportBtn}
      startDate={startDate}
      endDate={endDate}
      timePeriod={timePeriod}
    >
      {scenarioQueries.map(
        ({ data, scenario }, idx) =>
          data && (
            <DateChart.Series
              key={scenario.scenarioId}
              type={chartTypes.COLUMN}
              data={data.data}
              index={idx}
              isComparison={scenario.scenarioId !== scenarioId}
              metric={MAIN_METRIC}
              scenario={scenario}
            />
          ),
      )}
    </DateChart>
  );
}

export default AverageRevenuePerAccountChart;
