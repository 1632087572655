// @ts-check
import { useMemo } from 'react';
import CashDropIcon from '@bill/cashflow.assets/cash-drop';
import LightBulbIcon from '@bill/cashflow.assets/light-bulb-icon';
import {
  SIDE_NAV_LINKS_FPA_FULL,
  SIDE_NAV_LINKS_FPA_LITE,
} from '@/components/Remote/SideNav/SideNavLinks';
import { DASHBOARD_PATH, SETUP_PATH } from '@/constants/pages';
import { BillProfileType } from '@/contexts/BillSessionProvider';
import isBillDomain from '@/helpers/isBillDomain';
import useBillSession from '@/hooks/useBillSession';
import useInsightsBaseUrl from '@/hooks/useInsightsBaseUrl';
import useIsFPALite from '@/hooks/useIsFPALite';
import useIsPermitted from '@/hooks/useIsPermitted';
import useTypedSelector from '@/hooks/useTypedSelector';

/**
 * Custom hook to generate navigation items for the side nav.
 *
 * @type {(params?: {
 *   isOnboardingLayout?: boolean;
 * }) => import('@/types/side-nav').INavItem[]}
 */
const useSideNavLinks = ({ isOnboardingLayout = false } = {}) => {
  const isFpaLiteUser = useIsFPALite();
  const isPermitted = useIsPermitted();
  const isAuthenticated = useTypedSelector(({ auth }) => auth.isAuthenticated);
  const insightsBaseUrl = useInsightsBaseUrl();
  const { isSessionLoading, sessionInfo } = useBillSession();
  const isInsightsAvailable = useMemo(
    () =>
      /**
       * Temporary hotfix to hide Insights for approvers
       *
       * @see https://billcom.atlassian.net/browse/BU-15086
       */
      sessionInfo?.profile.type !== BillProfileType.Approver &&
      sessionInfo?.neoFunctionality.OrgFeatures.isInsightsAccessibleToUser,
    [sessionInfo],
  );

  return useMemo(() => {
    const links = isFpaLiteUser
      ? SIDE_NAV_LINKS_FPA_LITE
      : SIDE_NAV_LINKS_FPA_FULL;

    const generatedNavLinks = isAuthenticated
      ? links.filter((link) => isPermitted(link.permissions))
      : null;
    /** @type {import('@/types/side-nav').INavItem[]} */
    const baseNavItems = [
      {
        label: 'Forecasting',
        to: isOnboardingLayout ? SETUP_PATH : DASHBOARD_PATH,
        isSelected: true,
        icon: <CashDropIcon />,
        subNavItems: isOnboardingLayout ? null : generatedNavLinks,
      },
    ];
    if (
      !isSessionLoading &&
      isInsightsAvailable &&
      (isFpaLiteUser || isBillDomain())
    ) {
      baseNavItems.unshift({
        label: 'Insights',
        isSelected: false,
        icon: <LightBulbIcon />,
        to: insightsBaseUrl,
        subNavItems: [
          {
            label: 'Payables',
            to: insightsBaseUrl,
            isSelected: false,
          },
        ],
      });
    }

    return baseNavItems;
  }, [
    isFpaLiteUser,
    isAuthenticated,
    isPermitted,
    insightsBaseUrl,
    isSessionLoading,
    isOnboardingLayout,
    isInsightsAvailable,
  ]);
};

export default useSideNavLinks;
