import RoundCrossIcon from '@bill/cashflow.assets/round-cross';
import '@/pages/Employee/UploadErrorsModal.scss';

const UploadErrorsModal = ({ errors, onClose }) => {
  return (
    <>
      <div className="UploadErrors_Header">
        <h2 className="ModalBase_Heading">Missing / Incorrect Data</h2>
        <RoundCrossIcon className="Error_CloseIcon" onClick={onClose} />
      </div>
      <div className="UploadErrors_Container">
        {errors.map(({ errorCode, errorMessage, errorDetails }) => (
          <p key={`${errorCode}-${errorMessage}`} className="UploadErrors_Text">
            {`${errorCode} - ${errorMessage} - ${errorDetails}`}
          </p>
        ))}
      </div>
    </>
  );
};
export default UploadErrorsModal;
