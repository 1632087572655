import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';

/**
 * Get a user-selected dashboard or fallback to the 'Default' dashboard
 *
 * @returns {Object | undefined} dashboard
 */
function useSelectedDashboard() {
  const { dashboards, selectedDashboardId } = useSelector(
    ({ dashboard }) => dashboard,
  );

  const dashboardId = useMemo(() => {
    const defaultDashboardId = dashboards.find(
      ({ isDefault }) => isDefault,
    )?.id;

    return selectedDashboardId ?? defaultDashboardId;
  }, [dashboards, selectedDashboardId]);

  return useMemo(
    () => dashboards.find((dashboard) => dashboard.id === dashboardId),
    [dashboards, dashboardId],
  );
}

export default useSelectedDashboard;
