import RightArrowIcon from '@bill/cashflow.assets/right-arrow';
import PropTypes from 'prop-types';
import './CalendarPager.scss';

/**
 * A header element for paging between months/years on a calendar.
 *
 * @example
 *   <CalendarPager
 *     disableNext={maxDate <= currentDate}
 *     disablePrev={minDate >= currentDate}
 *     pageAmount={1}
 *     onPage={adjustCalendarMonth}
 *   >
 *     June
 *   </CalendarPager>;
 */
function CalendarPager({
  children,
  'data-testid': dataTestId,
  disableNext,
  disablePrev,
  pageAmount,
  onPage,
}) {
  return (
    <div className="Calendar_HeaderItem" data-testid={dataTestId}>
      <button
        type="button"
        className="CalendarPager_Btn CalendarPager_Btn-prev"
        disabled={disablePrev}
        aria-label="Previous"
        onClick={() => onPage(-pageAmount)}
        data-testid={`${dataTestId}-prev`}
      >
        <RightArrowIcon
          className="CalendarPager_ArrowIcon"
          aria-hidden="true"
        />
      </button>
      {children}
      <button
        type="button"
        className="CalendarPager_Btn"
        disabled={disableNext}
        aria-label="Next"
        onClick={() => onPage(pageAmount)}
        data-testid={`${dataTestId}-next`}
      >
        <RightArrowIcon
          className="CalendarPager_ArrowIcon"
          aria-hidden="true"
        />
      </button>
    </div>
  );
}

CalendarPager.propTypes = {
  /** Label for the pager */
  'children': PropTypes.node.isRequired,
  /** Unique ID for selecting the pager in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Whether to disable the next button */
  'disableNext': PropTypes.bool,
  /** Whether to disable the previous button */
  'disablePrev': PropTypes.bool,
  /** Amount to be paged in either direction */
  'pageAmount': PropTypes.number.isRequired,
  /**
   * Event handler for when the user clicks the next or previous buttons
   *
   * @param {number} offset Amount to adjust the calendar's current interval
   */
  'onPage': PropTypes.func.isRequired,
};

export default CalendarPager;
