export const onboardingStatus = /** @type {const} */ ({
  STARTED: 'started',
  PROCESSING: 'processing',
  BIPAAS_CONNECTION_STARTED: 'bipaas_connection_started',
  DATA_IMPORT_STARTED: 'data_import_started',
  FINALIZATION_STARTED: 'finalization_started',
  ROLE_ASSIGNMENT_PENDING: 'role_assignment_pending',
  COMPLETED: 'completed',
});

export const onboardingStatusText = /** @type {const} */ ({
  [onboardingStatus.STARTED]: 'Creating your Forecasting profile',
  [onboardingStatus.BIPAAS_CONNECTION_STARTED]:
    'Connecting to your Quickbooks Online account',
  [onboardingStatus.DATA_IMPORT_STARTED]:
    'Importing data from QuickBooks Online',
  [onboardingStatus.FINALIZATION_STARTED]: 'Creating cash flow model',
});

export const userRoles = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
};
