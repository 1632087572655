import { PropTypes } from 'prop-types';
import CardWrapper from '@/components/Settings/CardWrapper';
import Header from '@/components/Settings/Header';
import './DepartmentList.scss';

const DEPARTMENT_LIST_RECORD_COUNT = 2;

const departmentCount = (length) => {
  if (length <= 0) return '';

  return length > 1 ? ` and  ${length} others` : ` and  ${length} other`;
};

const DepartmentList = ({ expenseClasses, onEdit }) => {
  return (
    <CardWrapper>
      <Header
        mode="read"
        onEdit={onEdit}
        title="Departments"
        data-testid="custom-departments-edit"
      />

      {expenseClasses.map((expenseClass) => (
        <div key={expenseClass.id} className="Department_Wrapper">
          <div className="Department_MainTitle">{expenseClass.name}</div>
          <div>
            Includes:{' '}
            <span className="Department_SubDepartments">
              {expenseClass.departments
                .slice(0, DEPARTMENT_LIST_RECORD_COUNT)
                .map((department) => department.name)
                .join(', ')}
              {departmentCount(
                expenseClass.departments.length - DEPARTMENT_LIST_RECORD_COUNT,
              )}
            </span>
          </div>
        </div>
      ))}
    </CardWrapper>
  );
};

DepartmentList.propTypes = {
  expenseClasses: PropTypes.arrayOf(
    PropTypes.shape({
      /** ID of expense class */
      id: PropTypes.number.isRequired,
      /** name of expense class */
      name: PropTypes.string,

      departments: PropTypes.arrayOf(
        PropTypes.shape({
          /** ID of expense department */
          id: PropTypes.number.isRequired,
          /** name of expense department */
          name: PropTypes.string,
          /** code of expense department */
          code: PropTypes.string,
          /** if expense department is custom */
          custom: PropTypes.bool,
        }),
      ),
    }),
  ),
  /** Handle editing a department */
  onEdit: PropTypes.func,
};

export default DepartmentList;
