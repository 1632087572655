import { useState } from 'react';
import Modal from '@/components/common/Modal';
import CreateEditScenarioForm from './CreateEditScenarioForm';
import ScenarioListUsers from './ScenarioListUsers';
import './TheCreateEditDuplicateScenarioModal.scss';

const TheCreateEditDuplicateScenarioModal = ({
  id,
  onClose,
  open,
  mode,
  scenario,
  'data-testid': dataTestId,
  onScenarioNotify,
}) => {
  const [shareScenarioMode, setShareScenarioMode] = useState(false);

  return (
    <Modal
      open={open}
      id={id}
      onCancel={onClose}
      data-testid={dataTestId ?? id}
    >
      {shareScenarioMode ? (
        <ScenarioListUsers
          scenario={scenario}
          onClose={() => setShareScenarioMode(false)}
        />
      ) : (
        <CreateEditScenarioForm
          scenario={scenario}
          mode={mode}
          onClose={onClose}
          onShare={() => setShareScenarioMode(true)}
          onScenarioNotify={onScenarioNotify}
        />
      )}
    </Modal>
  );
};

export default TheCreateEditDuplicateScenarioModal;
