// @ts-check
import WarningIcon from '@bill/cashflow.assets/warning';
import './ChatError.scss';

/**
 * @type {(props: {
 *   startNewConversation: () => void;
 *   onRetry: () => void;
 * }) => React.ReactElement}
 */
const ChatError = ({ startNewConversation, onRetry }) => {
  return (
    <div data-testid="copilot-chat-error" className="ChatError">
      <div className="ChatError_Icon">
        <WarningIcon />
      </div>

      <span className="ChatError_Text">
        <span>
          Something went wrong. Please{' '}
          <button className="ChatError_ButtonLink" onClick={onRetry}>
            try again
          </button>{' '}
          or{' '}
          <button
            className="ChatError_ButtonLink"
            onClick={startNewConversation}
          >
            start a new conversation
          </button>
        </span>
      </span>
    </div>
  );
};

export default ChatError;
