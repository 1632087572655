import {
  RESET_FEATURE_FLAGS,
  SET_ALL_FEATURE_FLAGS,
} from '@/actionTypes/features';

export const setAllFeatureFlagsAction = () => {
  return (dispatch) => {
    dispatch({ type: SET_ALL_FEATURE_FLAGS });
  };
};

export const resetFeatureFlagsAction = () => {
  return (dispatch) => {
    dispatch({ type: RESET_FEATURE_FLAGS });
  };
};
