import Api from './Api';

/**
 * Check if a user's subscription has expired
 *
 * @returns {Promise} - API Response
 */
// eslint-disable-next-line import/prefer-default-export -- predates description requirement
export const getExpiredStatus = () => {
  return Api.get('subscriptions/get-expired-status');
};
