// @ts-check
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { COMPANY_SETTINGS } from '@/cacheKeys';
import { actions, subjects } from '@/constants/permissions';
import { DEFAULT_COMPANY_SETTINGS } from '@/constants/settings';
import { getCompanySettings } from '@/services/settingsService';
import useCompanyPermissions from './useCompanyPermissions';

const { READ_WRITE } = actions;
const { COMPANY_SETTINGS: COMPANY_SETTINGS_SUBJECT } = subjects;

/**
 * Get a company's settings, if permitted
 *
 * @type {(
 *   companyId: number,
 *   options?: { refetchInterval?: number },
 * ) => import('@tanstack/react-query').UseQueryResult<
 *   import('@/services/settingsService').CompanySettings,
 *   unknown
 * >} }
 */
const useCompanySettingsQuery = (companyId, { refetchInterval } = {}) => {
  const companySettingPermission = useCompanyPermissions(
    companyId,
    READ_WRITE,
    COMPANY_SETTINGS_SUBJECT,
  );
  const fetchCompanySettings = useCallback(async () => {
    if (!companyId || !companySettingPermission)
      return DEFAULT_COMPANY_SETTINGS;
    const { data } = await getCompanySettings(companyId);
    return data.data;
  }, [companyId, companySettingPermission]);

  return useQuery([COMPANY_SETTINGS, companyId], fetchCompanySettings, {
    placeholderData: DEFAULT_COMPANY_SETTINGS,
    staleTime: 60000,
    refetchOnWindowFocus: true,
    refetchInterval,
  });
};

export default useCompanySettingsQuery;
