import { useState, useRef } from 'react';
import { connect } from 'react-redux';
import QuestionMarkIcon from '@bill/cashflow.assets/question-mark';
import FormLabel from '@/components/common/FormLabel';
import FormulaField from '@/components/common/FormulaField';
import FormulaGuideModal from '@/components/common/FormulaGuide/FormulaGuideModal';
import { PRODUCT_PRICING } from '@/components/common/FormulaGuide/formulaGuideModalConstants';
import FormulaValidationMessageWithLink from '@/components/common/FormulaValidationMessageWithLink';
import { findCurrencySymbol } from '@/constants/currencies';
import VALID_FORMULA_MSG from '@/constants/formulas';
import { isNumber } from '@/helpers';
import validateCustomFormula from '@/services/formula.service';
import './PriceFormulaField.scss';

const PriceFormulaField = ({
  scenarioId,
  value,
  onValidation,
  onChange,
  isDisabled,
}) => {
  const prefixRef = useRef(findCurrencySymbol());
  const [validationMsg, setValidationMsg] = useState('');
  const [showFormulaGuide, setShowFormulaGuide] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <div className="Price_Label">
        <FormLabel
          htmlFor="price-formula-field"
          text="Price"
          tooltip="The formula is used to forecast a monthly plan price which is used
            to calculate new revenue for each month. The price must be greater
            than or equal to zero."
        />
        <button
          type="button"
          className="Button-iconOnly HelpIconButtonWrapper"
          aria-label="Help"
          onClick={() => setShowFormulaGuide(true)}
        >
          <QuestionMarkIcon className="HelpIcon" aria-hidden="true" />
        </button>
      </div>

      <div className="alert alert-primary">
        The formula is used to forecast the monthly plan price.
      </div>
      <FormulaField
        id="price-formula-field"
        value={
          isNumber(value) && !isFocused ? `${prefixRef.current}${value}` : value
        }
        isDisabled={isDisabled}
        onChange={(newPrice) => {
          const price = isNumber(newPrice)
            ? newPrice.replace(prefixRef.current, '')
            : newPrice;
          onChange(price);
        }}
        onBlur={async () => {
          switch (true) {
            case !value:
              setValidationMsg('Field is required');
              onValidation(false);
              break;
            case isNumber(value): {
              const isInvalid = value < 0;
              setValidationMsg(
                isInvalid ? "Price can't be negative" : VALID_FORMULA_MSG,
              );
              onValidation(!isInvalid);
              break;
            }
            default: {
              const {
                data: { data },
              } = await validateCustomFormula(scenarioId, value);
              setValidationMsg(data?.valid ? VALID_FORMULA_MSG : data.error);
              onValidation(data?.valid);
              break;
            }
          }
          setIsFocused(false);
        }}
        onFocus={() => {
          setValidationMsg('');
          onValidation(false);
          setIsFocused(true);
        }}
      />
      <FormulaValidationMessageWithLink message={validationMsg} />
      <FormulaGuideModal
        formulaKey={PRODUCT_PRICING}
        open={showFormulaGuide}
        data-testid="product-price-guide-modal"
        onClose={() => setShowFormulaGuide(false)}
      />
    </>
  );
};

const mapStateToProps = ({ scenario }) => {
  return {
    scenarioId: scenario.scenarioId,
  };
};

export default connect(mapStateToProps)(PriceFormulaField);
