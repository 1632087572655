import { FORMATTED_MONTHS } from '@/constants/Months';

export const getFormattedMonthOfAnYear = (year, valueKey) => {
  return FORMATTED_MONTHS.map((month) => {
    return { ...month, [valueKey]: 0, month: `${year}-${month.value}` };
  });
};

export const createMonthsFromYears = (years, valueKey) => {
  const currentYear = new Date().getUTCFullYear();
  return Array(years)
    .fill()
    .map((_, index) => ({
      year: currentYear + index,
      months: getFormattedMonthOfAnYear(currentYear + index, valueKey),
    }));
};
