// @ts-check
import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import getSelectedCompany from '@/selectors/getSelectedCompany';

/** Hook to get the product types of the current accessible company */
const useProductTypes = () => {
  const currentCompany = useSelector(getSelectedCompany);
  return useMemo(() => {
    return new Set(currentCompany?.productTypes ?? []);
  }, [currentCompany?.productTypes]);
};

export default useProductTypes;
