import { useEffect } from 'react';
import { YAxis } from 'react-jsx-highcharts';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { subscribeToEmployeeUpdateAction } from '@/actions/employees';
import { CAC_PAYBACK } from '@/cacheKeys';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import { LABEL_STYLES } from '@/components/Charts/chartDefaults';
import { getYAxisConfigNumber } from '@/components/Charts/helpers';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import metricFormatters from '@/helpers/metricFormatters';
import useChartQuery from '@/hooks/useChartQuery';
import useWsSubscription from '@/hooks/useWsSubscription';
import { getCacPayback } from '@/services/dashboard.service';

const MAIN_METRIC = 'CAC Payback';

const CAC_PAYBACK_CURRENCY_TOOLTIP_METRICS = [
  {
    name: 'Sales & Marketing Expense',
    key: 'salesMarketingCost',
    formatter: metricFormatters.monetary,
  },
  {
    name: 'Net New Gross Margin',
    key: 'grossMargin',
    formatter: metricFormatters.percentLegacy,
  },
  {
    name: MAIN_METRIC,
    isMainMetric: true,
    key: 'y',
    formatter: metricFormatters.months,
  },
];

const YAXIS_DEFAULTS = getYAxisConfigNumber();

const reducer = ({ currentMonth, currentMonthValue, months }) => {
  return {
    currentMonth,
    currentMonthValue,
    ...mapMonthlyData(months, 'cacPayback'),
  };
};

/**
 * Fetches and renders a line chart showing CAC Payback
 *
 * @example
 *   <CacPaybackChart exportBtn={exportBtn} />;
 */
function CacPaybackChart({
  className,
  exportBtn,
  plotOptions,
  axisStyles,
  tooltipOptions,
  onQueryStateChange,
}) {
  const yAxisConfigs = {
    ...YAXIS_DEFAULTS,
    labels: {
      ...YAXIS_DEFAULTS.labels,
      style: axisStyles || LABEL_STYLES,
    },
  };

  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);
  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );

  const scenarioQueries = useChartQuery(CAC_PAYBACK, getCacPayback, reducer);

  const [base] = scenarioQueries;
  useEffect(() => {
    if (base) onQueryStateChange?.(base);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [base]);

  useWsSubscription(
    () =>
      dispatch(
        subscribeToEmployeeUpdateAction(scenarioId, () => {
          base.refetch();
        }),
      ),
    [base, scenarioId],
  );

  return (
    <DateChart
      className={className}
      data-testid="cacPaybackChart"
      loading={scenarioQueries.every(({ isLoading }) => isLoading)}
      tooltip={
        <ChartTooltip
          metrics={CAC_PAYBACK_CURRENCY_TOOLTIP_METRICS}
          valueFormatter={(value) => metricFormatters.months(value)}
          endDate={endDate}
          timePeriod={timePeriod}
          {...tooltipOptions}
        />
      }
      plotOptions={plotOptions}
      axisStyles={axisStyles}
      ref={exportBtn}
      startDate={startDate}
      endDate={endDate}
      timePeriod={timePeriod}
    >
      <YAxis {...yAxisConfigs}>
        {scenarioQueries.map(
          ({ data, scenario }, idx) =>
            data && (
              <DateChart.Series
                key={scenario.scenarioId}
                data={data.data}
                index={idx}
                metric={MAIN_METRIC}
                scenario={scenario}
                isComparison={idx === 1}
              />
            ),
        )}
      </YAxis>
    </DateChart>
  );
}

export default CacPaybackChart;
