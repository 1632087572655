import { useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import { ITEM, SECTION, SUBSECTION } from '@/constants/reports';
import {
  deleteReportSubsection,
  deleteReportSubsectionItem,
} from '@/services/reports.service';

const SectionDelete = ({ currentRecord, onCancel, onDelete, open }) => {
  const [error, setError] = useState('');
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);

  if (!currentRecord) return null;

  const isSection = currentRecord.type === SECTION;
  const recordType = isSection ? SUBSECTION : ITEM;
  const recordTypeText = recordType.toLowerCase();
  const handleDelete = async () => {
    setError('');
    try {
      const deleteFunc = isSection
        ? deleteReportSubsection
        : deleteReportSubsectionItem;
      const response = await deleteFunc(scenarioId, currentRecord.id);

      if (response.data.success) {
        onDelete();
      }
    } catch (e) {
      setError(e.response?.data?.error?.errorMessage || e.message);
    }
  };

  return (
    <>
      {error ? (
        <ModalConfirmation
          id="modal-report-item-cannot-delete"
          onAction={onCancel}
          title={`Cannot delete the ${recordTypeText}`}
          actionBtnTxt="I understand"
        >
          {error}
        </ModalConfirmation>
      ) : (
        <ModalConfirmation
          id={`modal-${recordTypeText}-delete`}
          data-testid={`modal-${recordTypeText}-delete`}
          title={`Delete ${recordType}`}
          onCancel={onCancel}
          onAction={handleDelete}
          open={open}
        >
          Are you sure you would like to delete this {recordTypeText}?
        </ModalConfirmation>
      )}
    </>
  );
};

export default SectionDelete;
