export const NUM_COLS = 12;

export const GUTTER = 40;

export const ROW_HEIGHT = 155;

export const layoutConstraints = {
  DEFAULT_ROW_SPAN: 2,
  MIN_W: 3,
  MIN_H: 1,
};
