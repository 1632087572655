export const CHART_TITLE = 'Net Cashflow';
export const NET_CASH_FLOW_METRICS = [
  {
    key: 'cashIn',
    name: 'Cash In',
  },
  {
    key: 'cashOut',
    name: 'Cash Out',
  },
  {
    key: 'y',
    name: 'Net Cashflow',
    isMainMetric: true,
  },
];
