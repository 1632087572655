import { Fragment } from 'react';
import VarianceIcon from '@bill/cashflow.assets/variance-icon';
import PropTypes from 'prop-types';
import LetterIcon from '@/components/common/LetterIcon';
import { classNames } from '@/helpers';
import HighchartsTooltipPortal from './HighchartsTooltipPortal';
import './ChartTooltip.scss';
import './CustomChartGlanceTooltip.scss';

/**
 * Gets formatted metric value.
 *
 * @param {Function} formatter
 * @param {number} value
 * @param {number} precision
 * @returns {number}
 */
const getFormattedMetric = (formatter, value, precision) => {
  return formatter(value, {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  });
};

/**
 * Custom chart tooltip body for glance views.
 *
 * @param {Array} data - The metrics data being displayed in the tooltip
 * @param {Array} metricsMeta - A list of metadata objects describing how
 *   metrics should be categorized and formatted
 * @param {Array} scenarios - A list of scenarios plotted on a chart, sorted by
 *   series being hovered
 * @param {number} precision - Decimal points you want to show in tooltip
 *   values, default is 0
 */
const DefaultTooltipBody = ({
  data,
  metricsMeta,
  scenarios,
  precision = 0,
}) => {
  const [hoveredScenario] = scenarios;
  const { name, color } = hoveredScenario;

  return (
    <div className="TooltipBody">
      <LetterIcon
        string={name}
        color={color}
        className="TooltipBody_ScenarioIcon"
        data-testid={`tooltip-${name}-scenarioIcon`}
      />
      <table>
        <tbody>
          {metricsMeta.map(
            ({ name: variableName, formatter, getValue }, idx) => {
              const getValueFn = getValue?.[name];
              const value = getValueFn ? getValueFn(data[idx]) : data[idx]?.y;
              return (
                <tr key={variableName}>
                  <td className="TooltipMetric_Value">
                    {getFormattedMetric(formatter, value, precision)}
                  </td>
                  <th className="TooltipMetric_Name">{variableName}</th>
                </tr>
              );
            },
          )}
        </tbody>
      </table>
    </div>
  );
};

/**
 * Custom chart tooltip body for glance views when comparing scenarios.
 *
 * @param {Array} data - The metrics data being displayed in the tooltip
 * @param {Array} metricsMeta - A list of metadata objects describing how
 *   metrics should be categorized and formatted
 * @param {Array} scenarios - A list of scenarios plotted on a chart, sorted by
 *   series being hovered
 * @param {number} precision - Decimal points you want to show in tooltip
 *   values, default is 0
 */
const CompareTooltipBody = ({
  data,
  metricsMeta,
  scenarios,
  precision = 0,
}) => {
  return (
    <div className="TooltipBody TooltipBody-compare">
      {metricsMeta.map(
        ({ name: variableName, formatter: metricFormatter, getValue }) => {
          let varianceValue = 0;

          return (
            <div key={variableName} className="TooltipMetric">
              <div className="TooltipMetric_Name">{variableName}</div>
              <div className="TooltipMetric_ValueContainer">
                {data.map((point, idx) => {
                  const scenario = scenarios[idx];
                  const getValueFn = getValue?.[scenario.name];
                  const value = getValueFn ? getValueFn(point) : point?.y;

                  if (!value) return null;

                  varianceValue = value - varianceValue;

                  return (
                    <Fragment key={`${scenario.name}-${variableName}`}>
                      <LetterIcon
                        string={scenario.name}
                        color={scenario.color}
                        className="TooltipBody_ScenarioIcon"
                        data-testid={`tooltip-${scenario.name}-scenarioIcon`}
                      />
                      <div className="TooltipMetric_Value">
                        {getFormattedMetric(metricFormatter, value, precision)}
                      </div>
                    </Fragment>
                  );
                })}
                <>
                  <VarianceIcon className="VarianceIcon" />
                  {getFormattedMetric(
                    metricFormatter,
                    varianceValue === 0 ? varianceValue : varianceValue * -1,
                    precision,
                  )}
                </>
              </div>
            </div>
          );
        },
      )}
    </div>
  );
};

/**
 * Renders the tooltip for the custom chart glance view
 *
 * @example
 *   <CustomChartGlanceTooltip metrics={[{ key: 'fooProp', name: 'Foo' }, ...]} />
 */
const CustomChartGlanceTooltip = ({ metrics, precision, ...props }) => {
  return (
    <HighchartsTooltipPortal {...props}>
      {({ scenarios, tooltipLabel, getChartMetrics, ...context }) => {
        const metricsMeta = metrics(context);
        const isComparingScenarios = scenarios.length > 1;
        const { chartMetrics } = getChartMetrics();

        const TooltipBody = isComparingScenarios
          ? CompareTooltipBody
          : DefaultTooltipBody;

        return (
          <div
            className={classNames(
              'CustomChartGlanceTooltip',
              isComparingScenarios && 'CustomChartGlanceTooltip-compare',
            )}
          >
            <div className="ChartTooltip_Inner">
              <h4 className="CustomChartGlanceTooltip_Title">{tooltipLabel}</h4>
              <TooltipBody
                data={chartMetrics}
                metricsMeta={metricsMeta}
                scenarios={scenarios}
                precision={precision}
              />
              <p className="CustomChartGlanceTooltip_HelpText">
                Click chart title to view more
              </p>
            </div>
          </div>
        );
      }}
    </HighchartsTooltipPortal>
  );
};

CustomChartGlanceTooltip.propTypes = {
  /**
   * An array (or function returning one) of additional metrics to display in
   * the tooltip
   */
  metrics: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.object),
  ]),

  /** A decimal point precision you want to show in the value of tooltip. */
  precision: PropTypes.number,
};
export default CustomChartGlanceTooltip;
