import { useEffect, useState, useCallback, useMemo } from 'react';
import { HighchartsProvider } from 'react-jsx-highcharts';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { connect, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { FullStory } from '@fullstory/browser';
import Highcharts from 'highcharts';
import {
  INTEGRATION_DATA_SYNC_STARTED,
  INTEGRATION_DATA_SYNC_COMPLETED,
} from '@/actionTypes/settings';
import {
  copilotConfirmationTypes,
  setCopilotWarningModal as setCopilotWarningModalAction,
} from '@/actions/aiConversation';
import { getPendingEmployeeAction } from '@/actions/employees';
import {
  getCalculationProgressAction,
  setCalculationTimeElapsedAction,
  subscribeToCalculationProgressAction,
} from '@/actions/notifications';
import {
  setScenarioIdAction,
  setCompareScenarioIdAction,
  swapScenarios,
  subscribeToScenarioDuplicationAction,
} from '@/actions/scenario';
import {
  createStripeSessionAction,
  setStripeSessionErrorAction,
  subscribeToEmployeeRecordsAction,
  subscribeToManualSyncAction,
} from '@/actions/settings';
import { setWebSocketFailedAction } from '@/actions/shared';
import { setUiStateAction } from '@/actions/ui';
import { subscribeToVariableUpdatesAction } from '@/actions/variables';
import getHighchartsDefaults from '@/components/Charts/chartDefaults';
import CopilotSidebar from '@/components/Copilot/CopilotSidebar';
import TheReviewEmployeeRecordsModal from '@/components/Employee/PendingEmployees/TheReviewEmployeeRecordsModal';
import CashFlowFooter from '@/components/Remote/CashFlowFooter/CashFlowFooter';
import SideNav from '@/components/Remote/SideNav/SideNav';
import { handleMainLayoutNavigation } from '@/components/Remote/SideNav/helpers';
import TrialModal from '@/components/TrialModal/TrialModal';
import ClockIcon from '@/components/common/ClockIcon';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';
import LoadingIndicator from '@/components/common/LoadingIndicator';
import NotificationBanner, {
  notificationTypes,
} from '@/components/common/NotificationBanner';
import CompanyStatusBannerWithLockoutPopup from '@/components/common/Notifications/CompanyStatusBannerWithLockoutPopup';
import { calculationThresholds } from '@/components/common/Notifications/constants';
import PAGES, { PAGE_ERROR_BOUNDARY_TEXT } from '@/constants/pages';
import GlobalTopNavProvider from '@/contexts/GlobalTopNavProvider';
import NotificationProvider from '@/contexts/NotificationProvider';
import isBillDomain from '@/helpers/isBillDomain';
import useBillSession from '@/hooks/useBillSession';
import useInterval from '@/hooks/useInterval';
import useOneColor from '@/hooks/useOneColor';
import useProductTypes from '@/hooks/useProductTypes';
import useWsSubscription from '@/hooks/useWsSubscription';
import {
  REACT_APP_ENABLE_ANALYTICS,
  REACT_APP_ENVIRONMENT,
  GLANCE_ADDITIONAL_GROUP_IDS,
  GLANCE_GROUP_ID,
  GLANCE_SRC,
  GLANCE_SITE,
} from '@/runtimeConfig';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import { initAnalytics, initBillAnalytics } from '@/services/analyticsService';
import TheLayout from './TheLayout';
import TheMainNavigation from './TheMainNavigation';

Highcharts.setOptions(getHighchartsDefaults());

const intercomEvents = { UPDATE: 'update' };

/** The top-level layout of the application */
const MainLayout = ({
  children,
  scenarios,
  scenarioId,
  setScenarioId,
  scenarioLoader,
  isExpired,
  createStripeSession,
  getPendingEmployees,
  selectedCompanyId,
  stripeSessionCreationSuccess,
  stripeSessionCreationErrorMsg,
  setStripeSessionError,
  invitedUser,
  activeCompany,
  userInfo,
  setCompareScenarioId,
  compareScenarioId,
  setWebSocketFailed,
  swapScenarioIds,
  subscribeToEmployeeRecords,
  subscribeToManualSync,
  subscribeToScenarioDuplication,
  subscribeToVariableUpdates,
  subscribeToCalculationProgress,
  setCalculationTime,
  getCalculationProgress,
  webSocketFailed,
  setUiState,
  copilotExpanded,
  isExpiredLoading,
  calculationPending,
  showCalculationIndicator,
  copilotConversationId,
  setCopilotWarningModal,
}) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const [isNavExpanded, setNavExpanded] = useState(false);
  const { path } = useRouteMatch();
  const title = useMemo(() => PAGES[path].titleText, [path]);
  const property = useProductTypes();

  const { isSessionLoading, sessionInfo } = useBillSession();
  useEffect(() => {
    if (isBillDomain() && !isSessionLoading) {
      initBillAnalytics(sessionInfo);
    }
  }, [sessionInfo, isSessionLoading]);

  useEffect(() => {
    if (!isBillDomain() && activeCompany) {
      initAnalytics(userInfo, activeCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [activeCompany]);

  useEffect(() => {
    document.title = title;
    if (window.Intercom) window.Intercom(intercomEvents.UPDATE);
  }, [title]);

  useEffect(() => {
    if (REACT_APP_ENABLE_ANALYTICS === 'true' && activeCompany) {
      datadogRum.setGlobalContextProperty('productType', Array.from(property));

      if (isBillDomain()) {
        datadogRum.setGlobalContextProperty(
          'fullStoryRecordingUrl',
          FullStory('getSession', { format: 'url' }),
        );
      }
    }
  }, [userInfo, activeCompany, property]);

  useInterval(
    setCalculationTime,
    calculationThresholds.TIME_INTERVAL,
    !showCalculationIndicator && !calculationPending,
  );

  useEffect(() => {
    getCalculationProgress(scenarioId);
  }, [scenarioId, getCalculationProgress]);

  useWsSubscription(() => subscribeToVariableUpdates(scenarioId), [scenarioId]);
  useWsSubscription(
    () => subscribeToCalculationProgress(scenarioId),
    [scenarioId],
  );

  useWsSubscription(() => {
    subscribeToManualSync(
      selectedCompanyId,
      ({ value: isSyncing, systemType = null, status = null, type = null }) => {
        if (isSyncing) {
          dispatch({
            type: INTEGRATION_DATA_SYNC_STARTED,
            payload: { systemType },
          });
        } else {
          dispatch({
            type: INTEGRATION_DATA_SYNC_COMPLETED,
            payload: { systemType, status, type },
          });
        }
      },
    );
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedCompanyId && scenarioId) {
      getPendingEmployees(selectedCompanyId, scenarioId);
    }
  }, [selectedCompanyId, getPendingEmployees, scenarioId]);

  useWsSubscription(
    () => subscribeToEmployeeRecords(selectedCompanyId, scenarioId),
    [selectedCompanyId, scenarioId],
  );

  useWsSubscription(
    () => subscribeToScenarioDuplication(selectedCompanyId),
    [selectedCompanyId],
  );

  const closeCopilotSidebar = useCallback(() => {
    setUiState({ copilotExpanded: false });
  }, [setUiState]);

  /** @type {(scenarioId) => void} */
  const handleScenarioChange = useCallback(
    (selectedScenarioId) => {
      if (copilotConversationId) {
        setCopilotWarningModal({
          showWarningModal: true,
          warningModalConfirmationAction:
            copilotConfirmationTypes.CHANGE_SCENARIO_ID,
          pendingScenarioId: selectedScenarioId,
        });
      } else {
        setScenarioId(selectedScenarioId);
      }
    },
    [setScenarioId, copilotConversationId, setCopilotWarningModal],
  );

  const handleSwapScenario = useCallback(() => {
    if (copilotConversationId) {
      setCopilotWarningModal({
        showWarningModal: true,
        warningModalConfirmationAction:
          copilotConfirmationTypes.SWAP_SCENARIO_ID,
      });
    } else {
      swapScenarioIds();
    }
  }, [copilotConversationId, swapScenarioIds, setCopilotWarningModal]);

  const isRemoteSideNavEnabled = useOneColor();

  useEffect(() => {
    setNavExpanded(isRemoteSideNavEnabled);
  }, [isRemoteSideNavEnabled]);

  const glanceEnv = useMemo(() => {
    return {
      isLocal: REACT_APP_ENVIRONMENT === 'dev',
      glaceJsSrc: GLANCE_SRC,
      glanceJsGroupId: GLANCE_GROUP_ID,
      glanceJsAdditionalGroupIds: GLANCE_ADDITIONAL_GROUP_IDS,
      glanceJsSite: GLANCE_SITE,
    };
  }, []);

  return (
    <GlobalTopNavProvider>
      <NotificationProvider>
        {!isExpiredLoading && isExpired && (
          <TrialModal
            createStripeSession={createStripeSession}
            stripeSessionCreationSuccess={stripeSessionCreationSuccess}
            stripeSessionCreationErrorMsg={stripeSessionCreationErrorMsg}
            setStripeSessionError={setStripeSessionError}
          />
        )}
        <TheReviewEmployeeRecordsModal />
        <LoadingIndicator
          isVisible={showCalculationIndicator}
          position={{ bottom: '1%', left: '1%' }}
          text="Calculating..."
          Icon={ClockIcon}
          startAnimation="DOWN_DIAGONALLY"
          endAnimation="DOWN_DIAGONALLY"
        />
        {!isRemoteSideNavEnabled ? (
          <TheMainNavigation
            expanded={isNavExpanded}
            handleExpand={(expanded) => {
              setNavExpanded(expanded);
              setUiState({ navBarExpanded: expanded });
            }}
            scenarios={scenarios}
            selectedScenarioId={scenarioId}
            setScenarioId={handleScenarioChange}
            compareScenarioId={compareScenarioId}
            setCompareScenarioId={setCompareScenarioId}
            scenarioLoader={scenarioLoader}
            swapScenarioIds={handleSwapScenario}
          />
        ) : (
          <SideNav onNavigation={handleMainLayoutNavigation} />
        )}
        <CommonErrorBoundary text={PAGE_ERROR_BOUNDARY_TEXT}>
          <CompanyStatusBannerWithLockoutPopup />
          <HighchartsProvider Highcharts={Highcharts}>
            <TheLayout copilotExpanded={copilotExpanded}>
              {webSocketFailed && (
                <NotificationBanner
                  type={notificationTypes.WARN}
                  onCloseClick={() => setWebSocketFailed(false)}
                >
                  Some data on this page could not be updated automatically.
                  Refresh the browser to see the latest data.
                </NotificationBanner>
              )}
              {!isRemoteSideNavEnabled && (
                <button
                  type="button"
                  className="TheLayout_ToggleNavBtn"
                  aria-label="Show Navigation"
                  data-testid="mainNav-toggleBtn"
                  onClick={() => setNavExpanded(true)}
                />
              )}
              {children}
              {isRemoteSideNavEnabled && (
                <CashFlowFooter glanceEnv={glanceEnv} />
              )}
            </TheLayout>
            <CopilotSidebar
              navExpanded={isNavExpanded}
              open={copilotExpanded}
              onClose={closeCopilotSidebar}
            />
          </HighchartsProvider>
        </CommonErrorBoundary>
      </NotificationProvider>
    </GlobalTopNavProvider>
  );
};

const mapStateToProps = (state) => ({
  scenarios: state.scenario.scenarios,
  scenarioId: state.scenario.scenarioId,
  selectedCompanyId: state.companies.selectedCompanyId,
  compareScenarioId: state.scenario.compareScenarioId,
  activeCompany: getSelectedCompany(state),
  userInfo: state.auth.userInfo,
  scenarioLoader: state.scenario.scenarioLoader,
  isExpired: state.subscriptions.isExpired,
  stripeSessionCreationSuccess: state.settings.stripeSessionSuccess,
  stripeSessionCreationErrorMsg: state.settings.stripeSessionError,
  invitedUser: state.scenario.invitedUser,
  webSocketFailed: state.shared.webSocketFailed,
  copilotExpanded: state.ui.copilotExpanded,
  isExpiredLoading: state.componentLoading.isExpired,
  calculationPending: state.notifications.calculations.calculationPending,
  showCalculationIndicator:
    state.notifications.calculations.showCalculationIndicator,
  copilotConversationId: state.aiConversation.copilot.conversationId,
});

export default connect(mapStateToProps, {
  setScenarioId: setScenarioIdAction,
  setCompareScenarioId: setCompareScenarioIdAction,
  createStripeSession: createStripeSessionAction,
  getPendingEmployees: getPendingEmployeeAction,
  setStripeSessionError: setStripeSessionErrorAction,
  setWebSocketFailed: setWebSocketFailedAction,
  subscribeToEmployeeRecords: subscribeToEmployeeRecordsAction,
  subscribeToManualSync: subscribeToManualSyncAction,
  subscribeToScenarioDuplication: subscribeToScenarioDuplicationAction,
  subscribeToVariableUpdates: subscribeToVariableUpdatesAction,
  subscribeToCalculationProgress: subscribeToCalculationProgressAction,
  setCalculationTime: setCalculationTimeElapsedAction,
  getCalculationProgress: getCalculationProgressAction,
  swapScenarioIds: swapScenarios,
  setUiState: setUiStateAction,
  setCopilotWarningModal: setCopilotWarningModalAction,
})(MainLayout);
