// @ts-check
import ExportButtonIcon from '@bill/cashflow.assets/export-button';
import PropTypes from 'prop-types';
import './TableExportButton.scss';

const TITLE = 'Export as Excel Spreadsheet';

/**
 * @typedef {{
 *   'onClick': React.MouseEventHandler<HTMLButtonElement>;
 *   'data-testid'?: string;
 *   'className'?: string;
 *   'disabled'?: boolean;
 * }} TableExportButtonProps
 */

/**
 * An export button for tables, primarily used for exporting data to a
 * spreadsheet via CSV, for example
 *
 * @example
 *   ```jsx
 *     <TableExportButton onClick={() => {}} />;
 *   ```;
 *
 * @type {(props: TableExportButtonProps) => React.ReactElement}
 */
function TableExportButton({
  onClick,
  'data-testid': dataTestId,
  className,
  disabled = false,
}) {
  return (
    <div className={className}>
      <button
        type="button"
        className="TableExport_Btn"
        title={TITLE}
        onClick={onClick}
        data-testid={dataTestId}
        disabled={disabled}
      >
        <ExportButtonIcon className="TableExport_Icon" aria-label={TITLE} />
      </button>
    </div>
  );
}

TableExportButton.propTypes = {
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string,
  /** A callback function for 'click' events */
  'onClick': PropTypes.func.isRequired,
  /** A CSS class name to apply to the export container */
  'className': PropTypes.string,
  /** A prop to disable the on click of button */
  'disabled': PropTypes.bool,
};

export default TableExportButton;
