import { ApiV2 } from './Api';

/**
 * Validate a custom formula
 *
 * @param {number} scenarioId - ID of the scenario the formula is associated
 *   with
 * @param {string} displayFormula - The formula to validate
 * @returns {Promise} API response
 */
const validateCustomFormula = (scenarioId, displayFormula) => {
  // Ordinarily, we'd prefer to pass params as an object, but using string
  // interpolation gets us around this issue with Axios:
  // https://github.com/axios/axios/issues/3316
  return ApiV2.get(
    `/variables/formulas?scenarioId=${scenarioId}&displayFormula=${encodeURIComponent(
      displayFormula,
    )}`,
  );
};

export default validateCustomFormula;
