// @ts-check
import { forwardRef, useCallback, useState } from 'react';
import { AWAITING_DATA } from '@/constants/charts';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import useTypedSelector from '@/hooks/useTypedSelector';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import NetCashFlowChart from '@/pages/Dashboard/charts/NetCashFlowChart';
import { CHART_TITLE } from '@/pages/Dashboard/constants/netCashFlow';

/**
 * @typedef {{
 *   children: React.ReactElement;
 *   ChartWidget?: React.ComponentType<import('@/types/dashboard').WidgetProps>;
 * }} NetCashFlowGlanceProps
 */

/**
 * @typedef {{
 *   isLoading: boolean;
 *   totalNetCash: number;
 * }} NetCashData
 */

/**
 * @type {React.ForwardRefExoticComponent<
 *   NetCashFlowGlanceProps & React.RefAttributes<HTMLElement>
 * >}
 */
const NetCashFlowGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    /** @type {ReturnType<typeof useState<NetCashData>>} */
    const [netCash, setNetCash] = useState({
      isLoading: true,
      totalNetCash: 0,
    });
    const { startDate, endDate } = useTypedSelector(({ shared }) => shared);
    const onQueryStateChange = useCallback((data) => {
      const [
        {
          isLoading,
          data: { totalNetCash },
        },
      ] = data;
      setNetCash({
        totalNetCash,
        isLoading,
      });
    }, []);
    return (
      <ChartWidget
        ref={ref}
        title={CHART_TITLE}
        url="/dashboard/net-cash-flow"
        data-testid="netCashFlowWidget"
        metricValue={formatMonetary(netCash.totalNetCash)}
        label={formatRangeWithShortYear(startDate, endDate)}
        tooltipContent="The difference between your ending cash balance and your beginning cash balance for any selected period"
        isLoading={netCash.isLoading}
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={
                !netCash.isLoading
                  ? formatRangeWithShortYear(startDate, endDate)
                  : ''
              }
              value={
                netCash.isLoading
                  ? AWAITING_DATA
                  : formatMonetary(netCash.totalNetCash)
              }
            />
            <NetCashFlowChart
              {...childProps}
              onQueryStateChange={onQueryStateChange}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default NetCashFlowGlance;
