import WithTooltip from '@/components/common/WithTooltip';
import { classNames } from '@/helpers';

const PlanName = ({ product, className, tooltip }) => {
  return (
    // TODO instead of injecting className, override from parent
    <WithTooltip
      placement="right"
      content={`${product.productName} | ${product.name}`}
      data-testid={`${product.productName}-tooltip`}
    >
      <div className={classNames('TooltipTextOverflow', className)}>
        {`${product.productName} | ${product.name}`}
        {tooltip}
      </div>
    </WithTooltip>
  );
};

export default PlanName;
