// @ts-check
import { useEffect, useReducer, useState, useRef, useMemo } from 'react';
import AddChartsIcon from '@bill/cashflow.assets/add-charts';
import CrossIcon from '@bill/cashflow.assets/cross';
import '@/components/AddUpdateDashboardSidebar/AddUpdateDashboardSidebar.scss';
import CashInOutGrid from '@/components/CashInOut/CashInOutGrid';
import Footer from '@/components/Reports/ExportableReport/Footer';
import Page from '@/components/Reports/ExportableReport/Page';
import SectionHeader from '@/components/Reports/ExportableReport/Sections/SectionHeader';
import useSectionVisibleOnScreen from '@/components/Reports/ExportableReport/Sections/useSectionVisibleOnScreen';
import { SET_CHARTS } from '@/components/Reports/ExportableReport/constants';
import ExternalReport from '@/components/Reports/ExternalReport';
import {
  filterRows,
  generateDefaultPage,
  paginateGridData,
} from '@/components/Reports/helpers';
import Button from '@/components/common/Button';
import ChartsList from '@/components/common/ChartList/ChartsList';
import '@/components/common/ChartList/ChartsList.scss';
import chartReducer from '@/components/common/ChartList/chartReducer';
import {
  CUSTOM_CHART,
  SYSTEM_CHART,
  ON_LOAD,
  RESET_SELECTIONS,
  TOGGLE_CHART,
} from '@/components/common/ChartList/constants';
import useChartList from '@/components/common/ChartList/useChartList';
import IconButton from '@/components/common/IconButton';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import Sidebar from '@/components/common/Sidebar';
import {
  BALANCE_SHEET_SLUG,
  CASH_FLOW_STATEMENT_SLUG,
  externalReportNames,
  reportTypes,
} from '@/constants/reports';
import useExportableReportContext from '@/contexts/useExportableReportContext';
import { classNames, slugify } from '@/helpers';
import { hasAccountingIntegration } from '@/helpers/integrations';
import useReportData from '@/hooks/useReportData';
import useReportsInfo from '@/hooks/useReportsInfo';
import ProfitAndLoss from '@/pages/Reports/ProfitAndLoss';
import UserReportsGrid from '@/pages/Reports/UserReportsGrid';
import {
  MAX_CHARTS_PER_ROW,
  MAX_CHART_PER_PAGE,
} from '@/pages/Reports/constants';
import ReportCharts from './ReportCharts';

/** @typedef {import('@/components/Reports/ExportableReport/constants').SectionId} SectionId */

/**
 * @typedef {{
 *   charts: import('@/components/common/ChartList/types').Chart[];
 *   reportData: import('@/hooks/useReportData').ReportsData[];
 * }} NestedPage
 */

/** @type {import('@/components/common/ChartList/types').ChartListState} */
const INITIAL_STATE = {
  charts: [],
  selectedCharts: {},
  selectedChartIds: [],
  isSelectAll: {
    [SYSTEM_CHART]: false,
    [CUSTOM_CHART]: false,
  },
};

/** @type {(props: { onClick: () => void }) => React.ReactElement} */
const AddChartsButton = ({ onClick }) => {
  return (
    <IconButton
      className="SectionHeader_AddChartIcon"
      onClick={onClick}
      Icon={AddChartsIcon}
      label="Add Charts"
      data-testid="add-chart"
    />
  );
};

const ChartsListWithSave = ({ onSaveCharts, charts, state, setState }) => {
  return (
    <>
      <ChartsList state={state} setState={setState} charts={charts} />
      <Button
        className="ChartToggleSidebar_AddChartBtn"
        data-testid="toggle-charts-apply"
        onClick={onSaveCharts}
      >
        Save
      </Button>
    </>
  );
};

/**
 * @typedef {{
 *   id: import('@/types/exportableReport').ReportSectionId;
 *   documentRef: HTMLDivElement;
 *   scenario: Object;
 *   isFooterDisclaimerInEditMode: boolean;
 *   setIsFooterDisclaimerInEditMode: (value: boolean) => void;
 * }} ReportPageProps
 */

/**
 * Renders Report Section of Exportable Reports
 *
 * @example
 *   <Report section={section} scenario={selectedScenario} />;
 *
 * @type {(props: ReportPageProps) => React.ReactElement[]}
 */
const Report = ({
  id,
  scenario,
  documentRef,
  isFooterDisclaimerInEditMode,
  setIsFooterDisclaimerInEditMode,
}) => {
  const { data: reportsInfo, isLoading } = useReportsInfo(scenario.scenarioId);
  const gridApi = useRef(null);
  const ref = useSectionVisibleOnScreen(id, { root: documentRef });

  const [isChartListOpen, setIsChartListOpen] = useState(false);
  const [sidebarState, setSidebarState] = useReducer(
    chartReducer,
    INITIAL_STATE,
  );
  const [tempState, setTempState] = useState(INITIAL_STATE);
  const { exportableReportPreferences, reportState, setReportState } =
    useExportableReportContext();

  const section = reportState[id];

  const isAccountingIntegrationEnabled = hasAccountingIntegration(scenario);

  const layoutItems = useMemo(() => {
    return section.content.charts;
  }, [section]);

  const { charts, selectedCharts, isSelectAll } = useChartList(layoutItems);

  const { data: reportData, isLoading: isReportDataLoading } = useReportData(
    section.content.report_type,
    { ...reportsInfo, isAccountingIntegrationEnabled },
    !isLoading,
  );

  useEffect(() => {
    setSidebarState({
      type: ON_LOAD,
      onLoad: {
        charts,
        selectedCharts,
        isSelectAll,
        selectedChartIds: Object.keys(selectedCharts),
      },
    });
  }, [charts, isSelectAll, selectedCharts]);

  const paginatedCharts = useMemo(() => {
    if (isChartListOpen || !reportData) return [generateDefaultPage()];

    const selectedChartList = sidebarState.selectedChartIds.map((chartId) =>
      charts.find(({ key }) => key === chartId),
    );

    return selectedChartList.reduce(
      (accum, chart, index) => {
        const lastPage = accum.length - 1;
        accum[lastPage].charts.push(chart);

        const is4thChart = (index + 1) % MAX_CHART_PER_PAGE === 0;
        const isLastChart = index === selectedChartList.length - 1;
        const hasNoRoomForGrid =
          isLastChart && accum[lastPage].charts.length > MAX_CHARTS_PER_ROW;

        if (is4thChart || hasNoRoomForGrid) {
          accum.push(generateDefaultPage());
        }
        return accum;
      },
      [generateDefaultPage()],
    );
  }, [isChartListOpen, reportData, sidebarState.selectedChartIds, charts]);

  const gridData = useMemo(() => {
    if (!reportData) return [];
    const filteredData = reportData.filter((row) =>
      filterRows(row, reportData),
    );

    return filteredData.sort((rowA, rowB) => {
      if (rowA.isAdditional) {
        return 1;
      }
      if (rowB.isAdditional) {
        return -1;
      }

      return 0;
    });
  }, [reportData]);

  const pages = useMemo(() => {
    return paginateGridData(gridData, paginatedCharts);
  }, [gridData, paginatedCharts]);

  const onClose = () => {
    setIsChartListOpen(false);
    setSidebarState({
      type: RESET_SELECTIONS,
      resetSelections: { ...tempState },
    });
  };

  /** @type {import('@/services/reports.service').ReportEntity} */
  const userReportData = useMemo(
    () =>
      reportsInfo?.userReports.find(
        (report) =>
          report.name.toLowerCase() === section.displayName.toLowerCase(),
      ),
    [reportsInfo, section.displayName],
  );

  const handleSaveCharts = () => {
    const chartsToSave = Object.values(sidebarState.selectedCharts).map(
      ({ type, key }, index) => {
        return { type, key, index };
      },
    );
    setReportState({
      type: SET_CHARTS,
      setCharts: { id, charts: chartsToSave },
    });
    setIsChartListOpen(false);
  };

  const handleAddChartClick = () => {
    setTempState({ ...sidebarState });
    setIsChartListOpen(true);
  };

  const handleChartRemoval = (key) => {
    const updatedCharts = section.content.charts.filter(
      (chart) => chart.key !== key,
    );
    setReportState({
      type: SET_CHARTS,
      setCharts: { id, charts: updatedCharts },
    });
    setSidebarState({ type: TOGGLE_CHART, toggledChart: key });
  };

  return pages.map((page, index) => (
    <Page
      ref={index === 0 ? ref : null}
      id={index === 0 ? section.id : ''}
      /* eslint-disable-next-line react/no-array-index-key -- predates description requirement */
      key={index}
    >
      <SectionHeader
        section={section}
        headerControl={
          index === 0 ? <AddChartsButton onClick={handleAddChartClick} /> : null
        }
        isFirstPage
      />
      <ReportCharts
        onRemoveChart={handleChartRemoval}
        layoutItems={page.charts}
      />
      {(() => {
        if (page.charts.length > 2) return null;
        if (isLoading) {
          return <LoadingSpinner />;
        }
        switch (section.content.report_type) {
          case reportTypes.BALANCE_SHEET:
          case reportTypes.CASH_FLOW_STATEMENT:
            if (
              slugify(section.displayName) === BALANCE_SHEET_SLUG &&
              reportsInfo?.isExternalBalanceSheet &&
              isAccountingIntegrationEnabled
            ) {
              return (
                <ExternalReport
                  data={page.reportData}
                  isLoading={isReportDataLoading}
                  data-testid="balance-sheet"
                  apiRef={gridApi}
                  name={externalReportNames.BALANCE_SHEET}
                  isInteractive={false}
                />
              );
            }
            if (
              slugify(section.displayName) === CASH_FLOW_STATEMENT_SLUG &&
              reportsInfo?.isExternalCashFlow
            ) {
              return (
                <ExternalReport
                  data={page.reportData}
                  isLoading={isReportDataLoading}
                  data-testid="cashflow-summary"
                  apiRef={gridApi}
                  name={externalReportNames.CASH_FLOW_STATEMENT}
                  isInteractive={false}
                />
              );
            }
            return (
              <UserReportsGrid
                name={userReportData?.name}
                apiRef={gridApi}
                reportId={userReportData?.id}
                isInteractive={false}
                hideZeroRows
                showVarianceAmount={exportableReportPreferences.showVariance}
                variancePercentage={
                  exportableReportPreferences.showVariancePercentage
                }
                data={page.reportData}
                isLoading={isReportDataLoading}
              />
            );
          case reportTypes.PROFIT_AND_LOSS:
            return (
              <>
                {reportsInfo?.isExternalProfitAndLoss ? (
                  <ExternalReport
                    data={page.reportData}
                    isLoading={isReportDataLoading}
                    data-testid="profit-and-loss"
                    apiRef={gridApi}
                    name={externalReportNames.PROFIT_AND_LOSS}
                    isInteractive={false}
                  />
                ) : (
                  <ProfitAndLoss
                    apiRef={gridApi}
                    isInteractive={false}
                    hideZeroRows
                    data={page.reportData}
                    isLoading={isReportDataLoading}
                    showVarianceAmount={
                      exportableReportPreferences.showVariance
                    }
                    showVariancePercentage={
                      exportableReportPreferences.showVariancePercentage
                    }
                  />
                )}
              </>
            );
          case reportTypes.CASH_IN_OUT:
            return (
              <CashInOutGrid
                gridApi={gridApi}
                isInteractive={false}
                hideZeroRows
                showVarianceAmount={exportableReportPreferences.showVariance}
                showVariancePercentage={
                  exportableReportPreferences.showVariancePercentage
                }
                isLoading={isReportDataLoading}
                rowData={page.reportData}
                shouldRenderAccountNumber={false}
              />
            );
          default:
            return null;
        }
      })()}

      {index === 0 && (
        <Sidebar
          className={classNames('ChartToggleSidebar')}
          open={isChartListOpen}
          onClose={onClose}
        >
          <div className="ChartToggleSidebar_Container">
            <div className="ChartToggleSidebar_Panel">
              <header className="Sidebar_Header">
                <h3 className="Sidebar_Title">{section?.displayName}</h3>
                <button
                  className="Sidebar_CloseBtn"
                  onClick={() => {
                    onClose();
                  }}
                  aria-label="Close"
                >
                  <CrossIcon className="CloseIcon" />
                </button>
              </header>
              <ChartsListWithSave
                state={sidebarState}
                setState={setSidebarState}
                charts={charts}
                onSaveCharts={handleSaveCharts}
              />
            </div>
          </div>
        </Sidebar>
      )}
      <Footer
        isEditMode={isFooterDisclaimerInEditMode}
        setIsEditMode={setIsFooterDisclaimerInEditMode}
      />
    </Page>
  ));
};

export default Report;
