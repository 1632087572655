import { PropTypes } from 'prop-types';
import Button from '@/components/common/Button';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';
import './CompanyTermsModal.scss';

const CompanyTermsModal = ({ onConfirm, onCancel }) => {
  return (
    <ModalBase
      id="company-terms-modal"
      className="CompanyTermsModal"
      header={
        <h1 className="CompanyTermsModal_Heading">Create a new company</h1>
      }
    >
      <>
        <p className="CompanyTermsModal_Content">
          Creating a new company will take you to the company registration page,
          which will begin a 30-day trial period for your new company.
        </p>

        <div className="CompanyTermsModal_Buttons">
          <Button onClick={onConfirm} data-testid="modal-submit-button">
            Proceed
          </Button>
          <Button
            className="Button Button-cancelLink"
            onClick={onCancel}
            data-testid="modal-cancel-button"
          >
            Cancel
          </Button>
        </div>
      </>
    </ModalBase>
  );
};

CompanyTermsModal.propTypes = {
  /** A function to trigger confirmation of terms */
  onConfirm: PropTypes.func.isRequired,
  /** A function to trigger cancellation of terms */
  onCancel: PropTypes.func.isRequired,
};

export default CompanyTermsModal;
