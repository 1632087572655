// @ts-check
import { useCallback, useState } from 'react';

/**
 * @template [T=any] Default is `any`
 * @typedef {[T, React.RefCallback<T>]} CallbackRef<T>
 */

/**
 * A custom hook for implementing callback refs. Callback refs are primarily
 * used when the item assigned the `ref` is conditionally rendered or the `ref`
 * assignment changes after the initial render. This will cause the component
 * using the hook to re-render whenever the ref changes.
 *
 * @example
 *   const [ref, callbackRef] = useCallbackRef();
 *
 *   // later in your component
 *   <div ref={callbackRef}>...</div>;
 *
 * @type {() => CallbackRef}
 */
function useCallbackRef() {
  const [ref, setRef] = useState();
  const callbackRef = useCallback((node) => {
    if (ref !== node) {
      setRef(node);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, []);
  return [ref, callbackRef];
}

export default useCallbackRef;
