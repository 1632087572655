/**
 * Displays a styled offset value which we use in formulaBreakdown
 *
 * @example
 *   <Offset value="[-1]" />;
 */
const Offset = ({ value }) => (
  <span className="FormulaBreakdown_Item FormulaBreakdown_Item-offset">
    {value}
  </span>
);

export default Offset;
