import { ApiV2 } from '@/services/Api';

/**
 * @typedef {{
 *   scenarioId: number;
 *   reportName: import('src/constants/reports').ReportTypes;
 * }} GetExportableReportParams
 */

/**
 * @typedef {{
 *   scenarioId: number;
 *   reportName: import('src/constants/reports').ReportTypes;
 *   sectionData: {
 *     name: string;
 *     sections: import('@/components/Reports/ExportableReport/ReportContentContainer.js').Section[];
 *     scenarioId: number;
 *   };
 * }} SaveExportableReportParams
 */

/**
 * @typedef {{
 *   data: {
 *     createdDate: string;
 *     id: string;
 *     modifiedDate: string;
 *     name: import('src/constants/reports').ReportTypes;
 *     scenarioId: number;
 *     sections: import('@/components/Reports/ExportableReport/ReportContentContainer.js').Section[];
 *   };
 * }} GetExportableReportResponse
 */

/**
 * Gets Exportable Report Data
 *
 * @type {(
 *    payload: GetExportableReportParams
 *  ) => Promise<import('axios').AxiosResponse<GetExportableReportResponse>}
 */
// eslint-disable-next-line import/prefer-default-export -- predates description requirement
export const getExportableReport = ({ scenarioId, reportName }) => {
  return ApiV2.get('/exportable-reports', {
    params: { scenarioId, name: reportName },
  });
};

/**
 * Save Exportable Report Data
 *
 * @type {(
 *    payload: SaveExportableReportParams
 *  ) => Promise<import('axios').AxiosResponse<GetExportableReportResponse>}
 */
export const saveExportableReport = ({
  scenarioId,
  reportName,
  sectionData,
}) => {
  return ApiV2.put('/exportable-reports', sectionData, {
    params: { scenarioId, name: reportName },
  });
};

/**
 * Get Executive Summary Response with AI
 *
 * @type {(
 *   params: import('@/types/services/backend').TextGenerationDto,
 * ) => Promise<
 *   import('axios').AxiosResponse<
 *     import('@/types/services/backend').TextGenerationDto
 *   >
 * >}
 */
export const generateAiResponse = (data) =>
  ApiV2.post('/exportable-reports/summary-generation', data);
