// @ts-check
import Api from './Api';

/**
 * Get Cash In Out Grid Data
 *
 * @type {(
 *   params: import('../types/cashInOutGrid').CashInOutGridParams,
 * ) => Promise<
 *   import('axios').AxiosResponse<
 *     import('@/types/services/backend').RestResponseDtoCashInOutGridResponseDto
 *   >
 * >}
 */
export const getCashInOutGrid = ({
  startDate,
  endDate,
  timePeriod,
  scenarioId,
  comparisonScenarioId,
  filter,
}) => {
  const scenarioIds = [scenarioId];
  if (comparisonScenarioId > 0) {
    scenarioIds.push(comparisonScenarioId);
  }
  return Api.get('/cash-in-out/grid', {
    params: {
      startDate,
      endDate,
      timePeriod,
      scenarioIds,
      filter,
    },
  });
};

/**
 * Update Cash Grid Forecast Method. This triggers a recalculation of the grid
 * row that is affected by the change.
 *
 * @type {import('../types/mutateForecastMethod').UpdateForecastMethodFn}
 */
export const updateForecastMethod = ({
  cashAccountId,
  forecastFormula,
  clearFutureUserActuals,
  scenarioId,
}) => {
  return Api.put(
    `/cash-in-out/forecast-method/${cashAccountId}`,
    {
      cashAccountId,
      forecastFormula,
      clearFutureUserActuals,
    },
    {
      params: {
        scenarioId,
      },
    },
  );
};

/**
 * Delete Cash Grid Forecast Method.
 *
 * @type {import('../types/mutateForecastMethod').DeleteForecastMethodFn}
 */
export const deleteForecastMethod = ({
  cashAccountId,
  clearFutureUserActuals,
  scenarioId,
}) => {
  return Api.delete(`/cash-in-out/forecast-method/${cashAccountId}`, {
    params: {
      scenarioId,
      clearFutureUserActuals,
    },
  });
};

/**
 * Update Cash In Out Grid Data
 *
 * @type {(params: {
 *   scenarioId: number | number[];
 *   forecast: import('@/types/services/backend').CashInOutGridItemUpdateRequest;
 * }) => Promise<
 *   import('axios').AxiosResponse<
 *     import('@/types/services/backend').CashInOutGridItemUpdateRequest
 *   >
 * >}
 */
export const updateForecast = ({ scenarioId, forecast }) => {
  const scenarioIds = [scenarioId];
  return Api.post('/cash-in-out/items', forecast, { params: { scenarioIds } });
};
