import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import Button from './Button';
import './ColorPicker.scss';

/**
 * A color picker component.
 *
 * @example
 *   <ColorPicker
 *     label={label}
 *     colors={colors}
 *     onClick={setSelectedColor}
 *     selectedColor={selectedColor}
 *   />;
 */
const ColorPicker = ({
  label,
  colors,
  className = '',
  selectedColor = '',
  onClick: setSelectedColor,
}) => {
  return (
    <div className={classNames('ColorPicker', className)}>
      {label && <p className="ColorPicker_Label">{label}</p>}
      <div className="ColorPicker_BtnWrapper" data-testid="color-picker">
        {colors.map((color) => (
          <Button
            key={color}
            data-testid={`color-picker-button-${color.slice(1)}`}
            className="ColorPicker_Button"
            style={{ backgroundColor: color }}
            onClick={() => setSelectedColor(color)}
            aria-pressed={color.toLowerCase() === selectedColor.toLowerCase()}
          />
        ))}
      </div>
    </div>
  );
};

ColorPicker.propTypes = {
  /** The className to apply to the component */
  className: PropTypes.string,
  /** Text describing the color picker */
  label: PropTypes.string,
  /** An array of hex colors */
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** The hex value of the selected color */
  selectedColor: PropTypes.string,
  /** A function to set the chosen color */
  onClick: PropTypes.func.isRequired,
};

export default ColorPicker;
