// @ts-check
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { INSIGHTS_PATH, SETUP_PATH } from '@/constants/pages';

/**
 * Get the Insights baseUrl
 *
 * - @type {() => string}
 */
const useInsightsBaseUrl = () => {
  const { pathname } = useLocation();

  return useMemo(
    () =>
      pathname.includes(SETUP_PATH)
        ? SETUP_PATH + INSIGHTS_PATH
        : INSIGHTS_PATH,
    [pathname],
  );
};

export default useInsightsBaseUrl;
