// @ts-check
import { forwardRef, useCallback, useMemo } from 'react';
import { FederatedParcel } from '@bill/cashflow.mfe-support';
import { CASH_FLOW_FOOTER_REMOTE } from '@/components/Remote/CashFlowFooter/remoteModule';
import loadRemoteModule from '@/helpers/loadRemoteModule';
import './CashFlowFooter.scss';

/** @typedef {import('@/types/cashflow-footer').CashFlowFooterProps} CashFlowFooterProps */

/**
 * @type {React.ForwardRefRenderFunction<
 *   HTMLDivElement,
 *   CashFlowFooterProps
 * >}
 */
const CashFlowFooter = ({ glanceEnv }, ref) => {
  const customProps = useMemo(
    () => ({
      props: {
        mfeName: 'CashFlowFooter',
        glanceEnv,
      },
    }),
    [glanceEnv],
  );

  const loaderFn = useCallback(
    () => loadRemoteModule(CASH_FLOW_FOOTER_REMOTE),
    [],
  );
  return (
    <div
      className="CashFlowFooterRoot"
      data-testid="cash-flow-footer"
      ref={ref}
    >
      <FederatedParcel loaderFn={loaderFn} customProps={customProps} />
    </div>
  );
};

export default forwardRef(CashFlowFooter);
