import { units } from '@/constants/variables';
import metricFormatters from '@/helpers/metricFormatters';
import { layoutConstraints } from '@/pages/Dashboard/constants/gridConstants';

export const UNKNOWN_UNIT = 'Unknown unit';

export const AVERAGE_MAIN_METRIC = 'AverageOfDateRange';
export const SUM_MAIN_METRIC = 'SumOfDateRange';
export const CURRENT_MONTH_MAIN_METRIC = 'CurrentMonth';
export const PREVIOUS_PERIOD = 'PreviousPeriod';
export const ENDING_PERIOD = 'EndingPeriod';
export const DIFF_LAST_PERIOD = 'DiffLastPeriod';

/** @type {import('../types/dashboard').DashboardItemMeta} */
export const DEFAULT_CHART_META = {
  x: 0,
  y: 100,
  w: 6,
  h: layoutConstraints.DEFAULT_ROW_SPAN,
};

export const aggregationTypes = {
  NONE: 'NONE',
  AVERAGE: 'AVERAGE',
  SUM: 'SUM',
  LAST_MONTH: 'LAST_MONTH',
};

export const nonMonthlyValueTypes = {
  MONTHLY: 'monthly',
  AVERAGE: 'average',
  SUM: 'sum',
  LAST_MONTH: 'lastMonth',
};

/**
 * Returns a value formatter for the given variable unit
 *
 * @type {(unit: (typeof units)[keyof typeof units]) => Formatter}
 */
export const getMetricFormatter = (unit) => {
  switch (unit) {
    case units.NUMBER:
      return metricFormatters.count;
    case units.PERCENTAGE:
      return metricFormatters.percent;
    case units.CURRENCY:
      return metricFormatters.monetary;
    default:
      console.warn(UNKNOWN_UNIT, unit);
      return metricFormatters.count;
  }
};

/**
 * Gets chart precision based on
 *
 * @param {number} chartPrecisionState
 * @param {string} unit
 * @param {null | number} maxValue
 * @returns {number}
 */
export const getChartPrecision = (
  chartPrecisionState,
  unit,
  maxValue = null,
) => {
  // if chart precision is not defined return 0 = round to whole number
  if (!chartPrecisionState) {
    return 0;
  }

  // if unit of metric is not number, currency or percentage we dont want to show decimal places
  // e.g getChartPrecision(2, 'FLOAT', 900) returns 0
  // getChartPrecision(2, 'NUMBER', 900) returns 2
  if (![units.NUMBER, units.CURRENCY, units.PERCENTAGE].includes(unit)) {
    return 0;
  }

  return chartPrecisionState;
};

/**
 * Gets Formatted value based on chart precision
 *
 * @type {(params: {
 *   value: number;
 *   formatter: Function;
 *   precision: number;
 * }) => number}
 */
export const valueFormatter = ({ value, formatter, precision }) => {
  return formatter(value, {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  });
};
