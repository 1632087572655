import ColumnChartIcon from '@bill/cashflow.assets/column-chart';
import LineChartIcon from '@bill/cashflow.assets/line-chart-icon';
import { chartTypes } from '@/components/Charts/constants';
import ContextMenu from '@/components/common/ContextMenu';
import { PLACEHOLDER_ID } from '@/constants/variables';

function ContextMenuRenderer({
  data: { variable },
  onDeleteClick,
  onCreateClick,
  onDependenciesClick,
}) {
  return (
    variable.id !== PLACEHOLDER_ID && (
      <ContextMenu data-testid={`${variable.name}-contextMenu`}>
        <>
          <ContextMenu.Option
            onClick={() => onCreateClick(variable, chartTypes.LINE)}
            data-testid={`${variable.name}-create-line-chart`}
          >
            <LineChartIcon className="ContextMenu_ChartIcon" />
            <span className="ContextMenu_Label">Create Line Chart</span>
          </ContextMenu.Option>
          <ContextMenu.Option
            onClick={() => onCreateClick(variable, chartTypes.COLUMN)}
            data-testid={`${variable.name}-create-column-chart`}
          >
            <ColumnChartIcon className="ContextMenu_ChartIcon" />
            <span className="ContextMenu_Label">Create Column Chart</span>
          </ContextMenu.Option>
          {onDependenciesClick && (
            <ContextMenu.Option
              onClick={() => onDependenciesClick(variable)}
              data-testid={`${variable.name}-trace-dependencies`}
            >
              <span className="ContextMenu_Label">Trace Dependencies</span>
            </ContextMenu.Option>
          )}
        </>

        {onDeleteClick && (
          <ContextMenu.Option
            onClick={() => onDeleteClick(variable)}
            danger
            data-testid={`${variable.name}-delete`}
          >
            <span>Delete Custom Variable</span>
          </ContextMenu.Option>
        )}
      </ContextMenu>
    )
  );
}

export default ContextMenuRenderer;
