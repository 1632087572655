// @ts-check
import { forwardRef } from 'react';

/* eslint-disable-next-line no-restricted-imports -- predates description requirement */
import { NavLink as ReactRouterNavLink } from 'react-router-dom';

/**
 * @typedef {{
 *   scrollToTop?: boolean;
 *   children: React.ReactNode;
 * } & import('react-router-dom').NavLinkProps} NavLinkProps
 */

/**
 * An adapter for the React Router NavLink component that scrolls to the top of
 * the page by default upon navigating.
 *
 * @example
 *   <NavLink to="/">Login</NavLink>;
 *
 * @type {import('react').ForwardRefExoticComponent<NavLinkProps>}
 * @see https://v5.reactrouter.com/web/api/NavLink
 */

const NavLink = forwardRef(
  ({ scrollToTop = true, children, ...props }, ref) => {
    return (
      <ReactRouterNavLink
        ref={ref}
        // Scroll to the top of the page by default
        onClick={() => scrollToTop && window.scrollTo(0, 0)}
        {...props}
      >
        {children}
      </ReactRouterNavLink>
    );
  },
);

export default NavLink;
