import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DownArrowIcon from '@bill/cashflow.assets/down-arrow';
import PlusIcon from '@bill/cashflow.assets/plus';
import {
  deleteCustomJobTitleAction,
  getAllJobTitlesAction,
} from '@/actions/employees';
import Button from '@/components/common/Button';
import Dropdown from '@/components/common/Dropdown';
import DropdownOption from '@/components/common/DropdownOption';
import FormField from '@/components/common/FormField';
import InfoTooltip from '@/components/common/InfoTooltip';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import { CUSTOM_TITLE_TOOLTIP } from '@/constants/employees';
import { classNames, naturalSortComparator } from '@/helpers';
import { postEmployeeTitle } from '@/services/employee.service';
import {
  MINIMUM_CHARS_IN_JOBTITLE,
  CUSTOM_JOB_SELECT,
  CUSTOM_JOBTITLE_LENGTH_ERROR_MESSAGE,
} from './constants';
import './JobTitle.scss';

const validateCustomJobTitle = (value) => {
  if (value.trim().length < MINIMUM_CHARS_IN_JOBTITLE) {
    return CUSTOM_JOBTITLE_LENGTH_ERROR_MESSAGE;
  }

  return null;
};

const sortByJobTitle = (a, b) => {
  return naturalSortComparator(a.jobTitle, b.jobTitle);
};

export function JobTitleUnwrapped({
  currentRecord,
  departments,
  scenarioId,
  onChange,
  disabled,
  getAllJobTitles,
  allJobTitles,
  deleteCustomJobTitle,
}) {
  const [showCustom, setShowCustom] = useState(false);
  const [customTitle, setCustomTitle] = useState('');
  const [error, setError] = useState();

  useEffect(() => {
    if (!showCustom) setCustomTitle('');
  }, [showCustom]);

  const titles = allJobTitles
    ?.filter(
      ({ departmentId }) => departmentId === Number(currentRecord.departmentId),
    )
    .sort(sortByJobTitle);

  const selectedTitle = titles?.find(
    ({ jobTitleId }) => jobTitleId === currentRecord.titleId,
  );

  const handleJobTitleUpdate = async () => {
    const departmentId = currentRecord.departmentId ?? departments[0].id;
    try {
      const { data } = await postEmployeeTitle(
        { departmentId, jobTitle: customTitle.trim() },
        scenarioId,
      );
      if (data?.success) {
        onChange(data.data.id);
        getAllJobTitles(scenarioId);
        setShowCustom(false);
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.error?.errorMessage;
      setError(errorMessage ?? 'Unable to save this title at the moment');
    }
  };

  const handleCustomTitleDeletion = async (id, inUse) => {
    if (!inUse) {
      const titleId = id;
      await deleteCustomJobTitle(scenarioId, titleId);
      if (currentRecord.titleId === titleId) onChange('');
    }
  };

  return showCustom ? (
    <>
      <FormField
        id="customJobTitle"
        name="customJobTitle"
        maxLength={50}
        value={customTitle}
        placeholder="Enter your custom job title..."
        validate={(value) => validateCustomJobTitle(value)}
        onChange={({ target }) => setCustomTitle(target.value)}
        data-testid="custom-employee-title"
      />
      {error && (
        <p
          id="customJobTitleError"
          className={classNames(
            'FormField_Error',
            'EmployeeCustomTitle_ErrorMessage',
          )}
          data-testid="customJobTitleError"
        >
          {error}
        </p>
      )}
      <div className="EmployeeCustomTitle_ActionButtonsWrapper">
        <Button
          onClick={() => setShowCustom(false)}
          className="Button-cancelLink"
          data-testid="custom-employeeTitle-cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={handleJobTitleUpdate}
          className="Button-primaryLink"
          data-testid="custom-employeeTitle-save"
          disabled={!!validateCustomJobTitle(customTitle)}
        >
          Save
        </Button>
      </div>
    </>
  ) : (
    <Dropdown
      id="titleSelect"
      buttonSlot={
        <div
          className="EmployeeTitleDropdown_TitleWrapper"
          aria-disabled={disabled}
        >
          <TooltipTextOverflow
            label={selectedTitle?.jobTitle || 'Select a job title'}
            className="EmployeeTitleDropdown_Title"
            placement="top"
            data-testid="employee-title"
          />
          <DownArrowIcon
            className="FormField_SelectArrow DropdownIcon"
            aria-hidden="true"
          />
        </div>
      }
      buttonClass="Button-primaryLink Button_EmployeeTitleList"
      className="EmployeeTitleDropDown"
      disabled={disabled}
      data-testid="titleSelect"
    >
      <>
        <div className="EmployeeTitleDropdown_List">
          <div className="EmployeeTitleListSection">
            {titles?.map(({ jobTitleId, isCustom, jobTitle, isUsed }) => {
              const optionId = `EmployeeTitleListSection_Option${jobTitleId}`;
              return (
                <div
                  key={jobTitleId}
                  className={classNames(
                    'EmployeeTitleListSection_TitleWrapper',
                    isCustom && 'EmployeeTitleListSection_CustomTitleWrapper',
                  )}
                >
                  <DropdownOption
                    key={jobTitleId}
                    id={optionId}
                    data-testid={optionId}
                    onClick={() => onChange(jobTitleId)}
                  >
                    <TooltipTextOverflow
                      label={jobTitle}
                      placement="right"
                      data-testid={`EmployeeTitleListSection_Title${jobTitleId}`}
                    />
                  </DropdownOption>
                  {isCustom && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events -- predates description requirement
                    <div
                      onClick={() =>
                        handleCustomTitleDeletion(jobTitleId, isUsed)
                      }
                      className="EmployeeTitleListSection_RemoveTitle"
                    >
                      {isUsed ? (
                        <InfoTooltip
                          data-testid="employeeCustomTitle-tooltip"
                          icon={
                            <PlusIcon
                              className="DropdownCross CrossFaded"
                              aria-hidden="true"
                            />
                          }
                        >
                          {CUSTOM_TITLE_TOOLTIP}
                        </InfoTooltip>
                      ) : (
                        <PlusIcon
                          className="DropdownCross"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="EmployeeTitleListSection_CustomTitleOption"
          aria-hidden
          onClick={() => {
            onChange('');
            setShowCustom(true);
          }}
        >
          {CUSTOM_JOB_SELECT}
        </div>
      </>
    </Dropdown>
  );
}

const mapStateToProps = (state) => ({
  departments: state.employees.departments,
  scenarioId: state.scenario.scenarioId,
  allJobTitles: state.employees.allJobTitles,
});

export default connect(mapStateToProps, {
  getAllJobTitles: getAllJobTitlesAction,
  deleteCustomJobTitle: deleteCustomJobTitleAction,
})(JobTitleUnwrapped);
