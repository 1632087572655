import { TOTAL_MRR } from '@/cacheKeys';
import useChartQuery from '@/hooks/useChartQuery';
import { getTotalMRR } from '@/services/dashboard.service';

/**
 * API hook to populate any chart or table that relies on the totalMrr API
 *
 * @param {Function} reducer Transforms the API response for consumption by the
 *   chart
 * @param {(string | number | boolean)[]} [dependencies] Additional dependencies
 *   for the API request
 * @returns {import('@/hooks/useScenarioQueryReducer').ScenarioQueryResult[]}
 */
export default function useTotalMrrQuery(reducer, dependencies) {
  return useChartQuery(TOTAL_MRR, getTotalMRR, reducer, {
    dependencies,
  });
}
