import { useRef } from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import useIfOverflows from '@/hooks/useIfOverflows';
import WithTooltip from './WithTooltip';
import './TooltipTextOverflow.scss';

/**
 * Truncates the given label if it would overflow its parent and enables a
 * tooltip to display the full string.
 *
 * @example
 *   <TooltipTextOverflow label="Very long string" data-testid="foo" />;
 */
export default function TooltipTextOverflow({
  label,
  placement,
  className,
  'data-testid': dataTestId,
}) {
  const spanEl = useRef(null);
  const showTooltip = useIfOverflows(spanEl);

  if (showTooltip) {
    return (
      <WithTooltip
        placement={placement}
        content={label}
        data-testid={`${dataTestId}-tooltip`}
        className="Tooltip-flex"
      >
        <span
          ref={spanEl}
          className={classNames('TooltipTextOverflow', className)}
          data-testid={dataTestId}
        >
          {label}
        </span>
      </WithTooltip>
    );
  }

  return (
    <span
      ref={spanEl}
      className={classNames('TooltipTextOverflow', className)}
      data-testid={dataTestId}
    >
      {label}
    </span>
  );
}

TooltipTextOverflow.propTypes = {
  /** Label to display on hover */
  'label': PropTypes.node,
  /** Placement of overlay. Defaults to to auto */
  'placement': PropTypes.oneOf(['top', 'right', 'bottom', 'left', 'auto']),
  /** CSS Class to be applied on label span */
  'className': PropTypes.string,
  /** Unique ID for selecting the Label span in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
};
