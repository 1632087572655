import { forwardRef, useCallback, useState } from 'react';
import { formatDateWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import CustomerLTVChart from '@/pages/Dashboard/charts/CustomerLTVChart';
import { getMetricValue } from '@/pages/Dashboard/helpers';

const CustomerLTVGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [customerLTV, setCustomerLTV] = useState({});
    const onQueryStateChange = useCallback(({ data, isLoading }) => {
      setCustomerLTV({
        isLoading,
        currentMonth: data?.currentMonth,
        currentMonthValue: data?.currentMonthValue,
      });
    }, []);

    return (
      <ChartWidget
        ref={ref}
        title="Customer Lifetime Value"
        data-testid="customerLTVWidget"
        metricValue={
          customerLTV.isLoading
            ? ''
            : getMetricValue(customerLTV.currentMonthValue, formatMonetary)
        }
        label={`${formatDateWithShortYear(customerLTV.currentMonth)} LTV`}
        url="/dashboard/customer-ltv"
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={`${formatDateWithShortYear(customerLTV.currentMonth)} LTV`}
              value={
                customerLTV.isLoading
                  ? ''
                  : getMetricValue(
                      customerLTV.currentMonthValue,
                      formatMonetary,
                    )
              }
            />
            <CustomerLTVChart
              {...childProps}
              onQueryStateChange={onQueryStateChange}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default CustomerLTVGlance;
