// @ts-check
import { getNestedProp } from '.';

/**
 * Converts the given month/value pair to an x/y point suitable for plotting on
 * a chart.
 *
 * @param {import('@/types/dashboard').MonthlyApiData} data Data to convert to a
 *   point
 * @param {string} valueKey Name of the data prop containing the value
 * @returns {import('@/types/dashboard').ChartPoint} X/Y point
 */
export function mapToChartPoint(data, valueKey) {
  const yValue = Number.parseFloat(getNestedProp(data, valueKey));
  return {
    ...data,
    x: Date.parse(data.month || data.date),
    y: Number.isFinite(yValue) ? yValue : null,
    rawY: null,
  };
}

/**
 * Maps a given array of monthly data as retrieved from an API to a format that
 * can be charted.
 *
 * @param {import('@/types/dashboard').MonthlyApiData[]} months Array of monthly
 *   data to map
 * @returns {import('@/types/dashboard').ChartMonthlyData}
 */
export default function mapMonthlyData(months, valueKey = 'value') {
  const maxAndTotal = months.reduce(
    (accumulator, month) => {
      const value = Number(getNestedProp(month, valueKey));
      return {
        total: accumulator.total + value,
        maxValueInSeries:
          value > accumulator.maxValueInSeries
            ? value
            : accumulator.maxValueInSeries,
      };
    },
    {
      total: 0,
      maxValueInSeries: 0,
    },
  );

  const data = months.map((month) => mapToChartPoint(month, valueKey));
  return {
    y: maxAndTotal.total,
    maxValueInSeries: maxAndTotal.maxValueInSeries,
    data,
  };
}
