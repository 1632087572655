import { loadStripe } from '@stripe/stripe-js';
import { REACT_APP_STRIPE_KEY } from '@/runtimeConfig';

/** @type {(sessionId: string) => import('@stripe/stripe-js').StripeError} */
const redirectToStripe = async (sessionId) => {
  const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);
  const stripe = await stripePromise;
  const { error: redirectError } = await stripe.redirectToCheckout({
    sessionId,
  });

  return redirectError;
};

export default redirectToStripe;
