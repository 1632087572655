import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import formatDecimalNumber from '@/helpers/formatDecimalNumber';
import FormField from './FormField';

const numberFormatter = new Intl.NumberFormat();

// In Chrome, using a scroll wheel/gesture while focusing a number input will
// increment/decrement the number. This is confusing, so defocus the field to
// prevent it.
document.addEventListener('wheel', () => {
  const { activeElement } = document;
  if (activeElement.type === 'number') activeElement.blur();
});

/**
 * @typedef {React.HTMLProps<HTMLInputElement> & {
 *   allowNegativeValues?: boolean;
 *   precision?: boolean;
 *   validate?: () => void;
 * }} NumberFieldProps
 */

/**
 * Creates an input for numbers, formatted according to the user's locale.
 *
 * @example
 *   <NumberField value={bar} onChange={(number) => setBar(number)} />;
 *
 * @type {import('react').ForwardRefExoticComponent<NumberFieldProps>}
 */
const NumberField = forwardRef(
  (
    {
      onChange,
      value,
      allowNegativeValues = true,
      precision = false,
      validate = () => null,
      ...props
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const formattedValue = value
      ? numberFormatter.format(Number(value))
      : value;

    return (
      <FormField
        ref={ref}
        type={isFocused ? 'number' : 'text'}
        value={isFocused ? value : formattedValue}
        inputMode="numeric"
        autoComplete="false"
        onBlur={(event) => {
          if (value && precision !== false) {
            onChange(event, formatDecimalNumber(value, precision));
          }
          setIsFocused(false);
        }}
        onChange={(event) => {
          const { valueAsNumber } = event.target;
          if (!allowNegativeValues && valueAsNumber < 0) {
            return;
          }
          onChange(event, !Number.isNaN(valueAsNumber) ? valueAsNumber : null);
        }}
        onFocus={() => setIsFocused(true)}
        validate={() => validate(value)}
        {...props}
      />
    );
  },
);

NumberField.propTypes = {
  /**
   * Event handler for when the user changes the value of the field
   *
   * @param {Object} event Original event object
   * @param {number} number Value of the field cast as a number, or null
   */
  onChange: PropTypes.func.isRequired,
  /** The value of the input */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Allow user to enter negative numbers */
  allowNegativeValues: PropTypes.bool,
  /** Decimal places to which user input will be rounded. Unlimited by default. */
  precision: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  /**
   * A function that returns an error message for the given value, or falsy
   *
   * @param {number} value
   * @returns {string} An error message, or falsy
   */
  validate: PropTypes.func,
};

export default NumberField;
