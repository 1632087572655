// @ts-check
import { useEffect, useMemo, useState, useCallback, useReducer } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import {
  useQuery,
  useMutation,
  useQueryClient,
  useIsFetching,
} from '@tanstack/react-query';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import ReportContentContainer from '@/components/Reports/ExportableReport/ReportContentContainer';
import ReportHeader from '@/components/Reports/ExportableReport/ReportHeader';
import ReportSidebar from '@/components/Reports/ExportableReport/ReportSidebar';
import {
  HYDRATE_STATE,
  TOGGLE_VISIBILITY,
  INITIAL_FINANCIAL_REPORT_STATE,
  INITIAL_CASH_FLOW_REPORT_STATE,
} from '@/components/Reports/ExportableReport/constants';
import exportableReportReducer from '@/components/Reports/ExportableReport/reportReducer';
import LoadingIndicator from '@/components/common/LoadingIndicator';
import NotificationBanner, {
  notificationTypes,
} from '@/components/common/NotificationBanner';
import { exportableReportTypes } from '@/constants/reports';
import ExportableReportContext from '@/contexts/ExportableReport';
import { slugify, classNames } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import useCallbackRef from '@/hooks/useCallbackRef';
import useEffectOnUpdate from '@/hooks/useEffectOnUpdate';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import getBaseScenario from '@/selectors/getBaseScenario';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import {
  getExportableReport,
  saveExportableReport,
} from '@/services/exportableReport';
import './ExportableReport.scss';

const jsPdfConfig = /** @type {const} */ ({
  ORIENTATION: 'landscape',
  PAPER_TYPE: 'letter',
  UNIT: 'px',
  IMAGE_TYPE: 'JPEG',
});

const enrichSections = (sections) => {
  return sections.map((section) => {
    const { displayName } = section;
    return { ...section, id: slugify(displayName) };
  });
};

const reportAlerts = {
  SUCCESS: 1,
  ERROR: 2,
};

/** @type {import('@/contexts/ExportableReport').ExportableReportsPreferences} */
const INITIAL_STATE = {
  showVariance: true,
};

const FINANCIAL_SUMMARY_PATH = '/financial-summary';
const CASH_REPORT_PATH = '/cash-report';

const PROMPT_MESSAGE =
  'You are about the leave the Financial Summary Report, but you have unsaved changes. If you continue, you will lose your current report progress. Are you sure you would like to continue without saving?';

/**
 * @typedef {{
 *   type: import('@/constants/reports').ExportableReportType;
 *   isExportable?: boolean;
 * }} ExportableReportProps
 *   /** Renders Exportable Report Container
 * @type {(props: ExportableReportProps) => React.ReactElement}
 */
const ExportableReport = ({ type }) => {
  /** @type {import('@/hooks/useCallbackRef').CallbackRef<HTMLDivElement | null>} */
  const [documentRef, setDocumentRef] = useCallbackRef();
  const [reportState, setReportState] = useReducer(
    exportableReportReducer,
    type.key === exportableReportTypes.CASH_REPORT.key
      ? INITIAL_CASH_FLOW_REPORT_STATE
      : INITIAL_FINANCIAL_REPORT_STATE,
  );
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  const [reportNotify, setReportNotify] = useState(null);
  const [exportableReportPreferences, setExportableReportPreferences] =
    useState(INITIAL_STATE);
  const [isExporting, setIsExporting] = useState(false);
  const hasWritePermission = useNonDashboardWritePermission();
  const [isEditControlVisible, setIsEditControlVisible] =
    useState(hasWritePermission);
  const [exportingPageCount, setExportingPageCount] = useState(0);
  const [totalExportPageCount, setTotalExportPageCount] = useState(0);
  const [exportCompleted, setExportCompleted] = useState(false);

  useBeforeUnload(hasUnsavedData);

  /**
   * @type {[
   *   import('@/contexts/ExportableReport').EditableFields,
   *   React.Dispatch<
   *     React.SetStateAction<
   *       import('@/contexts/ExportableReport').EditableFields
   *     >
   *   >,
   * ]}
   */
  const [editableFields, setEditableFields] = useState({});

  const selectedScenario = useSelector(
    ({ scenario }) => getBaseScenario({ scenario }) ?? {},
  );
  const selectedCompany = useSelector(getSelectedCompany);

  /** @type {import('@/contexts/ExportableReport').ExportableReportContextType} */
  const contextValue = useMemo(
    () => ({
      exportableReportPreferences,
      setPreferences: setExportableReportPreferences,
      reportState,
      setReportState,
      isEditControlVisible,
      setIsEditControlVisible,
      isExporting,
      editableFields,
      setEditableFields,
    }),
    [
      exportableReportPreferences,
      reportState,
      isExporting,
      isEditControlVisible,
      editableFields,
      setEditableFields,
    ],
  );

  const exportableReportQueryKey = useMemo(
    () => [type.key, selectedScenario?.scenarioId],
    [type.key, selectedScenario.scenarioId],
  );

  const queryClient = useQueryClient();
  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(exportableReportQueryKey);
    };
  }, [queryClient, exportableReportQueryKey]);

  const { data, isLoading } = useQuery(
    exportableReportQueryKey,
    async () => {
      const response = await getExportableReport({
        scenarioId: selectedScenario?.scenarioId,
        reportName: type.key,
      });
      return enrichSections(response.data.data?.sections ?? []);
    },
    { staleTime: Infinity, refetchOnWindowFocus: false },
  );

  const { mutate: saveReport, isLoading: isSavingReport } = useMutation(
    saveExportableReport,
    {
      onSuccess: () => {
        setReportNotify(reportAlerts.SUCCESS);
        setHasUnsavedData(false);
      },
      onError: (error) => {
        setReportNotify(reportAlerts.ERROR);
      },
    },
  );

  const handleSaveReport = useCallback(() => {
    saveReport({
      scenarioId: selectedScenario?.scenarioId,
      reportName: type.key,
      sectionData: {
        sections: Object.values(reportState),
        name: type.key,
        scenarioId: selectedScenario?.scenarioId,
      },
    });
  }, [saveReport, reportState, selectedScenario, type]);

  useEffect(() => {
    if (!isEmptyOrNull(data)) {
      const sectionMap = data.reduce((acc, section) => {
        acc[section.id] = section;
        return acc;
      }, {});
      setReportState({ type: HYDRATE_STATE, state: sectionMap });
    }
  }, [data]);

  useEffect(() => {
    const sections = Object.values(reportState);
    const anyActive = sections.some(({ active }) => active);
    const newState = !anyActive
      ? { noSections: true }
      : { noSections: undefined };
    setEditableFields((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, [reportState]);

  const handleSectionToggle = (sectionId, isActive) => {
    setEditableFields((prevState) => {
      const fieldsInSection = Object.keys(prevState).filter((field) =>
        field.includes(sectionId),
      );
      if (!fieldsInSection.length) return prevState;
      const clone = { ...prevState };
      fieldsInSection.forEach((field) => {
        clone[field] = undefined;
      });
      return clone;
    });

    setReportState({
      type: TOGGLE_VISIBILITY,
      toggleVisibility: {
        id: sectionId,
        isActive,
      },
    });
  };

  const processPages = useCallback(async () => {
    const pdf = new jsPDF(
      jsPdfConfig.ORIENTATION,
      jsPdfConfig.UNIT,
      jsPdfConfig.PAPER_TYPE,
    );
    const pages = documentRef.querySelectorAll('.js-ExportableReport_Page');

    const pdfPages = Array.from(pages);
    setTotalExportPageCount(pdfPages.length);
    for (let i = 0; i < pdfPages.length; i++) {
      // eslint-disable-next-line no-await-in-loop -- This is required because by using Promise.all(), We won't be able to track the progress of each page.  We also want to release the UI thread for 1500ms, so the current tab doesn't get unresponsive.
      const canvas = await new Promise((resolve) => {
        setTimeout(() => {
          setExportingPageCount(i + 1);
          const element = /** @type {HTMLElement} */ (pdfPages[i]);
          resolve(html2canvas(element, { scale: 2 }));
        }, 1500);
      });
      pdf.addImage(
        canvas,
        jsPdfConfig.IMAGE_TYPE,
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight(),
      );
      if (i !== pdfPages.length - 1) {
        pdf.addPage(jsPdfConfig.PAPER_TYPE, jsPdfConfig.ORIENTATION);
      }
    }

    pdf.save(
      `${slugify(selectedCompany.name)}-${slugify(
        selectedScenario.name,
      )}-report`,
    );

    setIsEditControlVisible(hasWritePermission);
    setIsExporting(false);
    setExportCompleted(true);
  }, [selectedCompany, selectedScenario, documentRef, hasWritePermission]);

  useEffect(() => {
    if (isExporting) {
      // Delay calling processPages because it blocks rendering, which prevents
      // loading indicators from displaying correctly
      setTimeout(processPages, 50);
    }
  }, [isExporting, processPages]);

  useEffect(() => {
    if (exportCompleted === true) {
      setTimeout(() => {
        setExportCompleted(false);
        setExportingPageCount(0);
      }, 3000);
    }
  }, [exportCompleted]);

  const fetchingQueryCount = useIsFetching();

  const onExport = () => {
    if (fetchingQueryCount === 0) {
      setIsEditControlVisible(false);
      setIsExporting(true);
    }
  };

  const sections = Object.values(reportState);
  // After the initial hydration of state, subsequent updates should
  // set the hasUnsavedData to true in order to prompt the user to save
  useEffectOnUpdate(() => setHasUnsavedData(true), sections);

  return (
    <ExportableReportContext.Provider value={contextValue}>
      <Prompt
        when={hasUnsavedData}
        message={(location) => {
          return location.pathname.startsWith(FINANCIAL_SUMMARY_PATH) ||
            location.pathname.startsWith(CASH_REPORT_PATH)
            ? true
            : PROMPT_MESSAGE;
        }}
      />

      <div className={classNames('ExportableReport_Wrapper')}>
        <ReportHeader
          scenario={selectedScenario}
          title={type.title}
          onExport={onExport}
          onSave={handleSaveReport}
          hasWritePermission={hasWritePermission}
          isSaving={isSavingReport}
          fetchingQueryCount={fetchingQueryCount}
        />
        <ReportSidebar
          sections={sections}
          isLoading={isLoading}
          onSectionToggle={(sectionId, isChecked) =>
            handleSectionToggle(sectionId, isChecked)
          }
        />
        <ReportContentContainer
          sections={sections}
          scenario={selectedScenario}
          isLoading={isLoading}
          documentRef={documentRef}
          ref={setDocumentRef}
        />
      </div>

      {!!reportNotify && (
        <NotificationBanner
          onCloseClick={() => setReportNotify(null)}
          type={
            reportNotify === reportAlerts.SUCCESS
              ? notificationTypes.SUCCESS
              : notificationTypes.ERROR
          }
        >
          {reportNotify === reportAlerts.SUCCESS ? (
            <>Your report has been successfully saved</>
          ) : (
            <>
              An error occurred while attempting to save this report. Please try
              again.
            </>
          )}
        </NotificationBanner>
      )}
      <LoadingIndicator
        isVisible={isExporting}
        position={{ top: '7rem', right: '1.5rem' }}
        text="Please Wait..."
        showProgress
        progressStyle="warning"
        total={totalExportPageCount}
        current={exportingPageCount}
        secondaryText={`Exporting Page ${exportingPageCount} of ${totalExportPageCount} pages`}
      />
      <LoadingIndicator
        isVisible={exportCompleted}
        position={{ top: '7rem', right: '1.5rem' }}
        text="Completed!"
        showProgress
        progressStyle="success"
        total={totalExportPageCount}
        current={exportingPageCount}
        secondaryText={`Exported ${totalExportPageCount} out of ${totalExportPageCount} pages`}
      />
    </ExportableReportContext.Provider>
  );
};

export default ExportableReport;
