import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import MenuMiniIcon from '@bill/cashflow.assets/menu-mini';
import RoundCrossIcon from '@bill/cashflow.assets/round-cross';
import UnitSelector from '@/components/common/Spreadsheet/UnitSelector';
import useCellOverflow from '@/components/common/Spreadsheet/useCellOverflow';
import WithTooltip from '@/components/common/WithTooltip';
import keyConstants from '@/constants/keys';
import { PLACEHOLDER_ID, units } from '@/constants/variables';
import { classNames } from '@/helpers';
import { unitMap } from '@/helpers/units';
import useIfOverflows from '@/hooks/useIfOverflows';
import useInputBlurKeyDownListener from '@/hooks/useInputBlurKeyDownListener';
import getVariableIdFromName from './getVariableIdFromName';
import './VariableName.scss';

const INVALID_CLASS = 'Spreadsheet_Cell-invalid';

function VariableId({ id }) {
  return (
    <>
      <strong>Variable ID - </strong>
      {id}
    </>
  );
}

export function VariableNameRenderer({
  eGridCell,
  registerRowDragger,
  value: { displayName, name, unit },
  data: { error },
  showDragButton = true,
  showUnitSymbol = true,
}) {
  const nameRef = useRef(null);
  const dragBtn = useRef(null);
  const doesOverflow = useIfOverflows(nameRef);

  const { UnitSymbol, label } = showUnitSymbol ? unitMap[unit] : {};

  useCellOverflow(eGridCell);

  useEffect(() => {
    if (showDragButton) registerRowDragger(dragBtn.current, 0);
  }, [registerRowDragger, showDragButton]);

  return (
    <div className={classNames('VariableNameRenderer', error && INVALID_CLASS)}>
      {showDragButton && (
        <button
          ref={dragBtn}
          type="button"
          className="Spreadsheet_DragBtn"
          aria-hidden="true"
        >
          <MenuMiniIcon className="Spreadsheet_DragIcon" />
        </button>
      )}
      {showUnitSymbol && (
        <UnitSymbol
          className={classNames(
            'VariableNameRenderer_UnitSymbol',
            label === units.CURRENCY &&
              'VariableNameRenderer_UnitSymbol-currency',
          )}
          aria-label={label}
          data-testid={`${name}-unit`}
        />
      )}
      <WithTooltip
        content={
          error ?? (
            <>
              {doesOverflow && <div>{displayName}</div>}
              <VariableId id={name} />
            </>
          )
        }
        className="Tooltip-flex"
        data-testid={`${name}-tooltip`}
        placement="bottom"
      >
        <span
          ref={nameRef}
          className="VariableNameRenderer_Name"
          data-testid={`${name}-label`}
        >
          {displayName}
        </span>
      </WithTooltip>
    </div>
  );
}

export const VariableNameEditor = forwardRef(
  ({ api, eGridCell, onCancelAdd, value }, ref) => {
    const input = useRef(null);
    const [displayName, setDisplayName] = useState(value.displayName);
    const [unit, setUnit] = useState(value.unit);
    const [errorMsg, setErrorMsg] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);

    const name = getVariableIdFromName(displayName);

    const validate = () => {
      if (!displayName.length) return 'Please enter a unique name';

      let error = null;
      api.forEachNode(({ data = {} }) => {
        const { variable } = data;
        if (!variable || variable.id === value.id || variable.hasErrorMsg)
          return;

        if (
          variable.displayName.toLowerCase() ===
          displayName.toLowerCase().trim()
        ) {
          error = 'A variable with this name already exists';
        }
      });
      return error;
    };

    useImperativeHandle(ref, () => ({
      getValue: () => ({
        id: value.id,
        displayName: displayName.trim(),
        name,
        sectionId: value.sectionId,
        unit,
        hasErrorMsg: !!validate(),
      }),
    }));

    useEffect(() => {
      input.current.focus();
      setShowTooltip(true);
      if (displayName) {
        const error = validate();
        if (error) {
          eGridCell.classList.add(INVALID_CLASS);
          setErrorMsg(error);
        }
      }
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    }, []);

    // Don't allow saving of empty or duplicate names
    const onBlur = useCallback(
      (event) => {
        const error = validate();
        if (error) {
          event.stopPropagation();
          eGridCell.classList.add(INVALID_CLASS);
          setErrorMsg(error);
        }
      },
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
      [displayName],
    );

    const onKeyDown = useCallback(
      (event) => {
        setErrorMsg(null);
        eGridCell.classList.remove(INVALID_CLASS);
        if (event.key === keyConstants.ESCAPE) {
          if (value.id === PLACEHOLDER_ID) onCancelAdd();
          // Remove blur event handler
          input.current.removeEventListener('blur', onBlur);
          return;
        }
        if ([keyConstants.ENTER, keyConstants.TAB].includes(event.key)) {
          onBlur(event);
        }
      },
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
      [onBlur],
    );

    useInputBlurKeyDownListener(input, onBlur, onKeyDown);

    const { UnitSymbol, label } = unitMap[unit];
    const dataTestId = value.name || value.id;

    return (
      <div className="VariableNameEditor">
        <button
          type="button"
          className="VariableNameEditor_CancelBtn"
          data-testid={`${dataTestId}-cancel`}
          onClick={() => {
            api.stopEditing(true);
            onCancelAdd();
          }}
        >
          <RoundCrossIcon aria-label="Cancel" />
        </button>
        <UnitSymbol
          className={classNames(
            'VariableNameRenderer_UnitSymbol',
            label === units.CURRENCY &&
              'VariableNameRenderer_UnitSymbol-currency',
          )}
          aria-label={label}
          data-testid={`${dataTestId}-unit`}
        />
        <WithTooltip
          content={errorMsg || <VariableId id={name} />}
          className="Tooltip-flex"
          placement="bottom"
          visible={showTooltip && (displayName || errorMsg)}
          data-testid={`${dataTestId}-editTooltip`}
        >
          <input
            ref={input}
            type="text"
            className="Spreadsheet_Input"
            maxLength="84"
            placeholder="Enter display name..."
            size="1"
            value={displayName}
            onChange={({ target }) => {
              setDisplayName(target.value);
            }}
            data-testid={`${dataTestId}-input`}
          />
        </WithTooltip>
        <UnitSelector
          unit={unit}
          data-testid={`${dataTestId}-unitSelector`}
          onChange={(newUnit) => {
            setUnit(newUnit);
            input.current.focus();
          }}
          unitMap={unitMap}
        />
      </div>
    );
  },
);
