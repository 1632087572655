import Button from '@/components/common/Button';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';

const ReferFriendModal = ({ setShow, setShowReferralModal }) => {
  return (
    <ModalBase
      id="refer-friend-modal"
      className="ReferFriend_Modal"
      onCancel={() => setShow(false)}
      header={
        <h2 className="ReferFriend_HeaderText">
          Get one month{' '}
          <span className="ReferFriend_HeaderText-emphasis">free</span> by
          referring a friend!
        </h2>
      }
      footer={
        <>
          <Button
            className="Button-success Button-referFriend"
            onClick={() => {
              setShow(false);
              setShowReferralModal(true);
            }}
            data-testid="refer-friend-cancel-button"
          >
            Yes please!
          </Button>
          <Button
            className="Button-cancel Button-referFriend"
            onClick={() => setShow(false)}
            data-testid="refer-friend-cancel-button"
          >
            No thanks
          </Button>
        </>
      }
    >
      <p>
        Thanks very much for subscribing to Finmark. How about getting{' '}
        <strong>one month free</strong> by referring a friend?
      </p>
    </ModalBase>
  );
};

export default ReferFriendModal;
