import { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CalendarYearIcon from '@bill/cashflow.assets/calendar-year';
import ListTwoColumnsIcon from '@bill/cashflow.assets/list-two-columns';
import WarningIcon from '@bill/cashflow.assets/warning';
import {
  setDate as setDateAction,
  resetTimePeriodAction,
} from '@/actions/shared';
import Button from '@/components/common/Button';
import Calendar from '@/components/common/Calendar/Calendar';
import WithPopover from '@/components/common/WithPopover';
import { MONTHLY, QUARTERLY, timePeriods } from '@/constants/dateTime';
import {
  ACTUALS_PATH,
  ACTUALS_CASH_PATH,
  DASHBOARD_PATH,
  REPORTS_PATH,
  REPORTS_CASH_FLOW_PATH,
  REPORTS_BALANCE_SHEET_PATH,
  CUSTOM_REPORTS_PATH,
  REPORTS_VENDOR_SPEND_PATH,
  REPORTS_CASH_FLOW_STATEMENT_PATH,
  FINANCIAL_SUMMARY_PATH,
  CASH_IN_OUT,
} from '@/constants/pages';
import { classNames } from '@/helpers';
import {
  getFormattedDateFromTimeStamp,
  getUTCDayTimestamp,
  MAX_DATE,
  MIN_DATE,
  getTimePeriodsInRange,
  FPA_LITE_MAX_DATE,
  formatRangeWithShortYear,
} from '@/helpers/dateFormatter';
import useIsFPALite from '@/hooks/useIsFPALite';
import CustomRangePicker from './CustomRangePicker';
import './TheGlobalDatePicker.scss';

export const TheGlobalDatePickerUnwrapped = ({
  currentStartDate,
  currentEndDate,
  currentTimePeriod,
  setDate,
  resetTimePeriod,
}) => {
  const [show, setShow] = useState(false);
  const [customShow, setCustomShow] = useState(false);
  const [startDate, setStartDate] = useState(
    getUTCDayTimestamp(currentStartDate),
  );
  const [endDate, setEndDate] = useState(getUTCDayTimestamp(currentEndDate));
  const [isPartialRangeSelected, setIsPartialRangeSelected] = useState(false);
  const [timePeriod, setTimePeriod] = useState(currentTimePeriod);

  const isLiteProduct = useIsFPALite();
  const maxDate = isLiteProduct ? FPA_LITE_MAX_DATE : MAX_DATE;
  const { pathname } = useLocation();
  const isTimePeriodsEnabled =
    [
      ACTUALS_PATH,
      ACTUALS_CASH_PATH,
      REPORTS_PATH,
      REPORTS_CASH_FLOW_PATH,
      REPORTS_BALANCE_SHEET_PATH,
      REPORTS_CASH_FLOW_STATEMENT_PATH,
      REPORTS_VENDOR_SPEND_PATH,
      FINANCIAL_SUMMARY_PATH,
      CASH_IN_OUT,
    ].includes(pathname) ||
    pathname.includes(DASHBOARD_PATH) ||
    pathname.includes(CUSTOM_REPORTS_PATH);

  const highlightRange = useMemo(() => {
    const isMonthlyInterval = timePeriod === MONTHLY;
    if (!isTimePeriodsEnabled || isMonthlyInterval) {
      return [];
    }

    const formattedRange = getTimePeriodsInRange(
      startDate,
      maxDate,
      timePeriod,
    ).map((range) => {
      return range[1];
    });
    return formattedRange;
  }, [startDate, timePeriod, isTimePeriodsEnabled, maxDate]);

  useEffect(() => {
    if (!isTimePeriodsEnabled) {
      resetTimePeriod();
    }
  }, [isTimePeriodsEnabled, resetTimePeriod]);

  const setDates = useCallback((start, end, period) => {
    const pickerStartDate = new Date(start).getTime();
    const pickerEndDate = new Date(end).getTime();
    setStartDate(pickerStartDate);
    setEndDate(pickerEndDate);
    setTimePeriod(period);
  }, []);

  const cancelDateSelection = () => {
    setShow(false);
    setDates(currentStartDate, currentEndDate, currentTimePeriod);
  };

  const handleCustomCancel = () => {
    setCustomShow(!customShow);
    if (!customShow) cancelDateSelection();
  };

  useEffect(() => {
    setDates(currentStartDate, currentEndDate, currentTimePeriod);
  }, [currentEndDate, currentStartDate, currentTimePeriod, setDates]);

  return (
    <div className="TheGlobalDatePicker">
      <WithPopover
        className="Popover-toEdge"
        visible={show}
        placement="bottom-end"
        content={
          <div className="TheGlobalDatePicker_Container">
            {isTimePeriodsEnabled && (
              <ul className="TimePeriods" role="listbox">
                {Object.keys(timePeriods).map((period) => (
                  <li
                    key={period}
                    role="option"
                    className="TimePeriod_Item"
                    aria-selected={period === timePeriod}
                  >
                    <button
                      data-testid={`time-periods-${timePeriods[period]}`}
                      className="TimePeriod_Button"
                      onClick={() => setTimePeriod(period)}
                    >
                      {timePeriods[period]}
                    </button>
                  </li>
                ))}
              </ul>
            )}
            <div
              className={classNames(
                isTimePeriodsEnabled &&
                  'TheGlobalDatePicker_CalendarsContainer',
              )}
            >
              <div className="TheGlobalDatePicker_Calendars">
                <Calendar
                  min={MIN_DATE}
                  max={maxDate}
                  value={startDate}
                  onChange={(newDate) => {
                    setStartDate(newDate);
                    // Adjust the end date if it falls before the start date
                    if (newDate > endDate) setEndDate(newDate);
                  }}
                  view="year"
                  data-testid="global-datepicker-calendar-start"
                />
                <Calendar
                  min={MIN_DATE}
                  max={maxDate}
                  value={endDate}
                  highlightRange={highlightRange}
                  onHighlight={setIsPartialRangeSelected}
                  onChange={(newDate) => {
                    setEndDate(newDate);
                    // Adjust the start date if it falls after the end date
                    if (newDate < startDate) setStartDate(newDate);
                  }}
                  view="year"
                  data-testid="global-datepicker-calendar-end"
                />
              </div>
              <div
                className={
                  !isPartialRangeSelected
                    ? 'TheGlobalDatePicker_BtnRow'
                    : 'TheGlobalDatePicker_BtnWithWarning'
                }
              >
                {isPartialRangeSelected && (
                  <span className="PartialRangeWarning_Msg">
                    <WarningIcon className="WarningIcon" aria-hidden="true" />
                    Your selected date range includes a partial{' '}
                    {timePeriod === QUARTERLY ? 'quarter' : 'year'}. The
                    <br />
                    highlighted lines denote months which complete a full{' '}
                    {timePeriod === QUARTERLY ? 'quarter' : 'year'}.
                  </span>
                )}
                <Button
                  data-testid="global-datepicker-apply"
                  onClick={() => {
                    setDate(
                      getFormattedDateFromTimeStamp(startDate),
                      getFormattedDateFromTimeStamp(endDate),
                      timePeriod,
                    );
                    setShow(false);
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        }
        data-testid="global-datepicker"
        onClose={cancelDateSelection}
      >
        <button
          type="button"
          onClick={() => {
            setShow(!show);
            if (!show) setCustomShow(false);
          }}
          className="ThePageHeader_Button"
          data-testid="global-datepicker-trigger"
          aria-expanded={show}
        >
          <CalendarYearIcon
            className="TheGlobalDatePicker_ToggleBtnIcon"
            aria-hidden="true"
          />
          <div>
            <div className="TheGlobalDatePicker_ToggleBtnLabel">
              {isTimePeriodsEnabled
                ? `View: ${timePeriods[currentTimePeriod]}`
                : 'Current selected dates'}
            </div>
            {formatRangeWithShortYear(currentStartDate, currentEndDate)}
          </div>
        </button>
      </WithPopover>

      <WithPopover
        className="Popover-toEdge"
        visible={customShow}
        content={
          <CustomRangePicker
            startDate={startDate}
            endDate={endDate}
            data-testid="global-datepicker-presets"
            onSelect={(newStart, newEnd) => {
              setDate(
                getFormattedDateFromTimeStamp(newStart),
                getFormattedDateFromTimeStamp(newEnd),
                timePeriod,
              );
              setCustomShow(false);
            }}
          />
        }
        placement="bottom-end"
        data-testid="global-datepicker-presets"
        onClose={() => setCustomShow(false)}
      >
        <button
          type="button"
          className="ThePageHeader_Button"
          aria-label="Select a date range"
          onClick={handleCustomCancel}
        >
          <ListTwoColumnsIcon
            className="TheGlobalDatePicker_RangePresetBtnIcon"
            aria-hidden="true"
          />
        </button>
      </WithPopover>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentStartDate: state.shared.startDate,
  currentEndDate: state.shared.endDate,
  currentTimePeriod: state.shared.timePeriod,
});

export default connect(mapStateToProps, {
  setDate: setDateAction,
  resetTimePeriod: resetTimePeriodAction,
})(TheGlobalDatePickerUnwrapped);
