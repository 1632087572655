// @ts-check
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';

/**
 * useCompanyPermissions validates if the current user has access to perform an
 * action on a given subject for a selected company.
 *
 * - companyId: id of company
 * - action: 'Write' | 'Read'
 * - subject : string
 *
 * @example
 *   const isUserAllowed = useCompanyPermissions(1443, 'Write', 'Setting');
 *
 * @type {(
 *   companyId: number,
 *   action: 'Write' | 'Read' | 'ReadWrite' | 'Manage',
 *   subject: string,
 * ) => boolean}
 */
export default function useCompanyPermissions(companyId, action, subject) {
  const accessibleCompanies = useSelector(
    ({ auth }) => auth.accessibleCompanies,
  );
  const company = accessibleCompanies[companyId];
  return company ? company.permissions.includes(`${action}.${subject}`) : false;
}
