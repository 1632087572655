// @ts-check
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Events } from 'ag-grid-community';
import NotificationBanner, {
  notificationTypes,
} from '@/components/common/NotificationBanner';
import { ROW_PINNED_BOTTOM } from '@/components/common/Spreadsheet/constants';
import useTypedSelector from '@/hooks/useTypedSelector';

/** @typedef {import('ag-grid-community').ColDef} ColDef */

const DEFAULT_PASTE_FAIL_ERROR =
  'Unable to paste into the selected cells. Please ensure you are pasting into editable cells.';

/**
 * @private
 * @type {(
 *   event: import('ag-grid-community').CellValueChangedEvent,
 * ) => void}
 */
function handleCellLoading({ api, colDef, context, node }) {
  // Show the loading indicator
  const { loadingCells } = context;
  const id = colDef.colId ?? colDef.field;
  context.loadingCells = {
    ...loadingCells,
    [node.id]: loadingCells[node.id]?.concat(id) ?? [id],
  };

  // Clear any errors on the cell so they don't show while loading
  const rowErrors = context.errors[node.id];
  if (rowErrors) {
    context.errors = {
      ...context.errors,
      [node.id]: {
        ...rowErrors,
        [colDef.field]: undefined,
      },
    };
  }

  api.refreshCells({
    rowNodes: [node],
    force: true,
  });
}

/**
 * @private
 * @type {(event: import('ag-grid-community').RowDataUpdatedEvent) => void}
 */
function handleRowDataUpdated({ api, context }) {
  const { loadingCells } = context;
  const columns = [...new Set(Object.values(loadingCells).flat())];
  const rowNodes = Object.keys(loadingCells)
    .map((rowId) => api.getRowNode(rowId))
    .filter(Boolean);
  context.loadingCells = {};
  api.refreshCells({ columns, rowNodes });
}

/**
 * @typedef {{
 *   columnDefs: ColDef[];
 *   handleCellLoading: (
 *     event: import('ag-grid-community').CellValueChangedEvent,
 *   ) => void;
 *   onGridReady: (event: import('ag-grid-community').GridReadyEvent) => void;
 *   processDataFromClipboard: (
 *     params: import('ag-grid-community').ProcessDataFromClipboardParams,
 *   ) => string[][] | null;
 * }} GridEditingCallbacks
 */

/**
 * @typedef {{
 *   [rowId: string]: { [colId: string]: string };
 * }} SpreadsheetErrors
 */

/**
 * @typedef {Object} WithEditingProps
 * @property {(props: GridEditingCallbacks) => React.ReactElement} children
 * @property {ColDef[]} columnDefs Column definitions to pass to ag-Grid
 * @property {boolean} enabled Whether editing is allowed on the spreadsheet
 * @property {SpreadsheetErrors} [errors] Errors in the editable cells, mapped
 *   by row ID and column
 * @property {string} [pasteFailError] Message to display when paste fails
 */

/**
 * Adds editing capabilities, including copy/paste, loading and error handling
 * to the wrapped Spreadsheet
 *
 * @example
 *   <WithEditing columnDefs={colDefs} enabled={hasWritePermission}>
 *     {(editingHandlers) => <Spreadsheet {...editingHandlers} />}
 *   </WithEditing>;
 *
 * @type {(props: WithEditingProps) => React.ReactElement}
 */
function WithEditing({
  children,
  columnDefs,
  enabled,
  errors,
  pasteFailError = DEFAULT_PASTE_FAIL_ERROR,
}) {
  /**
   * @typedef {import('ag-grid-community').GridApi} GridApi
   * @type {[GridApi, React.Dispatch<GridApi>]}
   */
  const [gridApi, setGridApi] = useState();
  /** @type {React.MutableRefObject<{ errors?: SpreadsheetErrors }>} */
  const context = useRef({});

  /** @type {boolean} */
  const isCalculating = useTypedSelector(
    ({ notifications }) => notifications.calculations.calculationPending,
  );

  const handleGridReady = useCallback((event) => {
    setGridApi(event.api);
    context.current = event.context;
  }, []);

  /**
   * @type {(
   *   event: import('ag-grid-community').CellEditingStartedEvent,
   * ) => void}
   */
  const handleCellEditingStarted = useCallback(
    ({ api, context: gridContext }) => {
      const focusedCell = api.getFocusedCell();
      const node =
        focusedCell && api.getDisplayedRowAtIndex(focusedCell.rowIndex);
      if (node?.data) {
        const intendedFocus = {
          ...focusedCell,
          dataId: node.data.id,
        };

        // Only update the focus if the system is not currently calculating
        if (!isCalculating) {
          gridContext.lastFocusedCellOnEditingStarted = intendedFocus;
        }
      }
    },
    [isCalculating],
  );

  useEffect(() => {
    if (!gridApi || enabled === false) {
      return undefined;
    }

    gridApi.addEventListener(
      Events.EVENT_CELL_EDITING_STARTED,
      handleCellEditingStarted,
    );
    gridApi.addEventListener(
      Events.EVENT_ROW_DATA_UPDATED,
      handleRowDataUpdated,
    );

    return () => {
      gridApi.removeEventListener(
        Events.EVENT_CELL_EDITING_STARTED,
        handleCellEditingStarted,
      );
    };
  }, [enabled, gridApi, handleCellEditingStarted]);

  const [pasteFailed, setPasteFailed] = useState(false);
  const handlePaste = useCallback(
    ({ api, columnApi, data: clipData }) => {
      if (enabled === false) return null;

      const cellRanges = api.getCellRanges();

      let columns;
      let startRowIdx;
      let isRowPinnedBottom;
      // If the user has selected a single cell, cellRanges will be empty.
      // See handleRangeSelection() below.
      if (!cellRanges.length) {
        const { column, rowIndex, rowPinned } = api.getFocusedCell();
        columns = [column];
        startRowIdx = rowIndex;
        isRowPinnedBottom = rowPinned === ROW_PINNED_BOTTOM;
      } else {
        const [{ endRow, startRow }] = cellRanges;
        [{ columns }] = cellRanges;
        startRowIdx =
          startRow.rowIndex < endRow.rowIndex
            ? startRow.rowIndex
            : endRow.rowIndex;
        isRowPinnedBottom = startRow.rowPinned === ROW_PINNED_BOTTOM;
      }

      // Pastes from Excel include an extra row with an empty cell.
      // suppressLastEmptyLineOnPaste does not handle all occurrences,
      // so we have to filter it out manually.
      const [lastRow] = clipData.slice(-1);
      const cleanData =
        lastRow.length === 1 && lastRow[0] === ''
          ? clipData.slice(0, -1)
          : clipData;
      if (!cleanData.length) return null;

      const [{ length: numCols }] = cleanData;

      /** @type {import('ag-grid-community').Column[]} */
      const flatCols = columnApi
        .getAllDisplayedColumns()
        .filter(({ pinned }) => pinned !== 'right');
      const selectedColIdx = flatCols.findIndex(
        (col) => col.getId() === columns[0].colId,
      );
      const targetCols = flatCols.slice(
        selectedColIdx,
        selectedColIdx + Math.max(columns.length, numCols),
      );

      // Check to see if we would be pasting into any uneditable cell.
      // If so, cancel the whole paste.
      for (let i = 0; i < targetCols.length; i += 1) {
        const column = targetCols[i];
        const colDef = column.getColDef();
        for (let r = 0; r < cleanData.length; r += 1) {
          const node = isRowPinnedBottom
            ? api.getPinnedBottomRow(startRowIdx + r)
            : api.getDisplayedRowAtIndex(startRowIdx + r);
          const params = {
            api,
            column,
            columnApi,
            colDef,
            context,
            node,
            data: node.data,
          };
          const { editable } = colDef;
          const isUneditable =
            typeof editable === 'function' ? !editable(params) : !editable;
          if (isUneditable) {
            setPasteFailed(true);
            return null;
          }
        }
      }

      setPasteFailed(false);
      return cleanData;
    },
    [enabled],
  );

  useEffect(() => {
    if (!gridApi || !errors) return;

    // Set field errors for invalid cell styling,
    // and force hide loading indicators
    context.current.errors = errors;

    // Setting context does not automatically refresh
    const fields = [];
    const rowNodes = Object.entries(errors).map(([rowId, errorsByField]) => {
      fields.push(Object.keys(errorsByField));
      return gridApi.getRowNode(rowId);
    });
    const columns = [...new Set(...fields)];
    // force: true is necessary to trigger cellRendererSelector
    gridApi.refreshCells({ columns, rowNodes, force: true });
  }, [gridApi, errors]);

  const updatedColDefs = useMemo(
    () =>
      columnDefs.map((colDef) =>
        enabled === false ? { ...colDef, editable: false } : colDef,
      ),
    [columnDefs, enabled],
  );

  return (
    <>
      {children({
        columnDefs: updatedColDefs,
        handleCellLoading,
        onGridReady: handleGridReady,
        processDataFromClipboard: handlePaste,
      })}
      {pasteFailed && (
        <NotificationBanner
          type={notificationTypes.ERROR}
          onCloseClick={() => setPasteFailed(false)}
        >
          {pasteFailError}
        </NotificationBanner>
      )}
    </>
  );
}

export default WithEditing;
