import { useQuery } from '@tanstack/react-query';
import { REPORTS_INFO } from '@/cacheKeys';
import { getReportsInfo } from '@/services/reports.service';

const transformData = (data) => {
  const isExternalProfitAndLoss =
    data?.enabledSpreadsheetReports.includes('PROFIT_AND_LOSS');
  const isExternalCashFlow =
    data?.enabledSpreadsheetReports.includes('CASH_FLOW');
  const isExternalBalanceSheet =
    data?.enabledSpreadsheetReports.includes('BALANCE_SHEET');
  return {
    ...data,
    isExternalBalanceSheet,
    isExternalCashFlow,
    isExternalProfitAndLoss,
  };
};

/**
 * @typedef {import('@/services/reports.service').ReportsInfoResponse & {
 *   isExternalBalanceSheet: boolean;
 *   isExternalCashFlow: boolean;
 *   isExternalProfitAndLoss: boolean;
 * }} ReportsInfoWithExternalReportFlags
 */

/**
 * A custom hook to refresh ReportsInfo, on scenario change
 *
 * @type {(
 *   scenarioId: number,
 * ) => import('@tanstack/react-query').UseQueryResult<ReportsInfoWithExternalReportFlags>}
 */
const useReportsInfo = (scenarioId) => {
  return useQuery(
    [REPORTS_INFO, scenarioId],
    async () => {
      const { data } = await getReportsInfo(scenarioId);
      return transformData(data.data);
    },
    { staleTime: 30000 },
  );
};

export default useReportsInfo;
