// @ts-check
import { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import './FormField.scss';

/**
 * @template [T=string] Default is `string`
 * @typedef {Override<
 *   React.ComponentPropsWithoutRef<'input'>,
 *   {
 *     'data-testid'?: string;
 *     'id': string;
 *     'prefix'?: React.ReactNode;
 *     'showErrors'?: boolean;
 *     'validate'?: (value: T) => string;
 *     'value': T;
 *   }
 * >} FormFieldProps<T>
 */

/**
 * Creates a standard text (or email, phone, etc) input, with optional inline
 * validation.
 *
 * @example
 *   <FormField
 *     id="foo"
 *     value={bar}
 *     onChange={(event) => setBar(event.target.value)}
 *   />;
 *
 * @type {import('react').ForwardRefRenderFunction<
 *   HTMLInputElement,
 *   FormFieldProps
 * >}
 */
const FormFieldFn = (
  {
    children,
    className,
    'data-testid': dataTestId,
    id,
    type = 'text',
    validate,
    value,
    onBlur,
    prefix,
    showErrors,
    disabled,
    ...props
  },
  ref,
) => {
  const [wasTouched, setTouched] = useState(false);
  /** @type {ReturnType<typeof useState<string | null>>} */
  const [error, setError] = useState(null);

  const errMsgId = `${id}-error`;

  useEffect(() => {
    // Don't show errors if the user has yet to interact with the field
    const errMsg = (wasTouched || showErrors) && validate?.(value);
    setError(errMsg);
  }, [validate, value, wasTouched, showErrors]);

  return (
    <div
      className={classNames(
        'FormField',
        error && 'FormField-invalid',
        className,
      )}
      aria-disabled={disabled}
    >
      {prefix && (
        <span className="FormField_Prefix" data-testid={`${id}-prefix`}>
          {prefix}
        </span>
      )}
      <input
        ref={ref}
        type={type}
        id={id}
        value={value ?? ''}
        aria-invalid={Boolean(error)}
        aria-describedby={errMsgId}
        data-testid={dataTestId ?? id}
        className={classNames(
          'FormField_Input',
          children && 'FormField_Input-hasBtn',
        )}
        onBlur={(event) => {
          setTouched(true);
          if (onBlur) onBlur(event);
        }}
        disabled={disabled}
        {...props}
      />
      {children}
      {Boolean(error) && (
        <p id={errMsgId} className="FormField_Error" data-testid={errMsgId}>
          {error}
        </p>
      )}
    </div>
  );
};

const FormField = forwardRef(FormFieldFn);

FormField.propTypes = {
  /** A supporting element, such as a toggle button for password fields */
  'children': PropTypes.node,
  /** Additional class(es) to apply to the input element */
  'className': PropTypes.string,
  'data-testid': PropTypes.string,
  /** The ID of the input element, corresponding to the label's htmlFor attribute */
  'id': PropTypes.string.isRequired,
  /**
   * Event handler for when the input has been defocused
   *
   * @param {Object} event
   */
  'onBlur': PropTypes.func,
  /** Optional symbol to place in front of the field, such as a currency */
  'prefix': PropTypes.node,
  /** The type of the input element, e.g. 'email' */
  'type': PropTypes.oneOf([
    'email',
    'number',
    'password',
    'search',
    'tel',
    'text',
  ]),
  /**
   * A function that returns an error message for the given value, or falsy
   *
   * @param {string} value
   * @returns {string} An error message, or falsy
   */
  'validate': PropTypes.func,
  /** The value of the input */
  'value': PropTypes.any,
  /**
   * Forces any validation errors to display, even if the user has not
   * interacted with the field yet
   */
  'showErrors': PropTypes.bool,
  /** Disable the input */
  'disabled': PropTypes.bool,
};

export default FormField;
