import { forwardRef, useEffect, useRef, useState } from 'react';
import ChartLegend from '@/components/Charts/ChartLegend';
import { chartTypes } from '@/components/Charts/constants';
import ContextMenu from '@/components/common/ContextMenu';
import useElementSize from '@/hooks/useElementSize';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import Widget from '@/pages/Dashboard/Widget';
import WidgetKPI from '@/pages/Dashboard/WidgetKPI';
import CustomChart from '@/pages/Dashboard/charts/CustomChart';
import './CustomChartGlance.scss';

const MIN_LEGEND_ITEM_WIDTH = 125;

/**
 * Fetches and renders a line or column chart of custom variable
 *
 * @example
 *   <CustomChartGlance chartInfo={chartInfo} />;
 */
const CustomChartGlance = forwardRef(
  (
    { chartInfo, children, onDeleted, onEdit, ChartWidget = Widget, ...props },
    ref,
  ) => {
    const legend = useRef(null);
    const [chart, setChart] = useState();
    const [meta, setMeta] = useState(chartInfo ?? { metadata: {} });
    const [mainMetric, setMainMetric] = useState({});
    const [secondaryMetric, setSecondaryMetric] = useState({});
    const [maxLegendItems, setMaxLegendItems] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const hasWritePermission = useNonDashboardWritePermission();

    const { metadata, title } = meta ?? {};

    useEffect(() => {
      setMeta(chartInfo);
    }, [chartInfo]);

    const hasBuiltinLegend = [chartTypes.FUNNEL, chartTypes.PIE].includes(
      metadata?.chartType,
    );

    const { contentRect } = useElementSize(legend);

    useEffect(() => {
      if (!contentRect) return;
      const maxItems = Math.floor(contentRect.width / MIN_LEGEND_ITEM_WIDTH);
      setMaxLegendItems(maxItems);
    }, [contentRect]);

    const url = chartInfo
      ? `/dashboard/custom-chart/${chartInfo.id}`
      : undefined;
    return (
      <>
        <ChartWidget
          ref={ref}
          url={url}
          title={title}
          data-testid={`${title}-widget`}
          metricValue={mainMetric.value}
          label={mainMetric.label}
          isLoading={isLoading}
          controls={
            chartInfo &&
            hasWritePermission && (
              <>
                <ContextMenu.Option
                  data-testid="custom-chart-edit"
                  onClick={() => onEdit(meta)}
                >
                  Edit Chart
                </ContextMenu.Option>

                <ContextMenu.Option
                  danger
                  data-testid="custom-chart-delete"
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete Chart
                </ContextMenu.Option>
              </>
            )
          }
          {...props}
        >
          {(childProps) =>
            chartInfo && (
              <>
                <WidgetKPI
                  value={mainMetric.value}
                  label={mainMetric.label}
                  secondaryMetric={secondaryMetric}
                />
                <CustomChart
                  data-testid={`${title}-chart`}
                  id={chartInfo.id}
                  meta={meta}
                  onCancelDelete={() => setShowDeleteModal(false)}
                  onChartCreated={setChart}
                  onDeleted={onDeleted}
                  onQueryStateChange={({
                    data,
                    isLoading: queryStateLoading,
                  }) => {
                    if (!queryStateLoading) {
                      setMainMetric(data.mainMetric);
                      setSecondaryMetric(data.secondaryMetric);
                      setIsLoading(false);
                    }
                  }}
                  showDeleteModal={showDeleteModal}
                  {...childProps}
                />
                {!hasBuiltinLegend && !childProps.isTrend && (
                  <div className="Widget_Legend" ref={legend}>
                    <ChartLegend
                      chart={chart}
                      className="ChartLegend-glance"
                      data-testid={`${title}-legend`}
                      maxItems={maxLegendItems}
                      reverse
                      showMoreUrl={url}
                    />
                  </div>
                )}
                {children}
              </>
            )
          }
        </ChartWidget>
      </>
    );
  },
);

export default CustomChartGlance;
