import { useEffect, useReducer, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import {
  getExpensesClassesAction,
  createExpenseAction,
  updateExpenseAction,
  updatePayrollExpenseAction,
} from '@/actions/expenses';
import { getPaymentTermsAction } from '@/actions/settings';
import FormulaGuideModal from '@/components/common/FormulaGuide/FormulaGuideModal';
import { EXPENSE } from '@/components/common/FormulaGuide/formulaGuideModalConstants';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';
import {
  SET_CUSTOM_FORMULA,
  SET_FORMULA_VALIDATION_RESPONSE,
  SET_INITIAL_EXP_AMOUNT,
  DEFAULT_CHANGE_OVER_TIME_STATES,
  getInitialState,
  NON_CASH_PAYMENT_TERM,
  NON_CASH_EXPENSE_CLASS,
  DEFAULT_PAYMENT_TERM,
} from '@/constants/expenses';
import { getISODate } from '@/helpers/dateFormatter';
import useViewOnlyMode from '@/hooks/useViewOnlyMode';
import { getLinkedExpensePreview } from '@/services/expensesService';
import ExpenseForm from './Form';
import LinkPreview from './LinkPreview';
import EXPENSE_ACTUALS_QUERY_KEY from './constants';
import './Form.scss';

const getFormReducer =
  (departments, paymentTerms, nonCashExpenseClassId) =>
  (state, { type, payload }) => {
    switch (type) {
      case 'SET_DEPARTMENT_ID': {
        const department = departments.find((dept) => dept.id === payload);
        const expenseClassId = department?.expenseClassId;
        const isNonCashExpenseClass = expenseClassId === nonCashExpenseClassId;
        const paymentTermId = paymentTerms
          .find(
            (paymentTerm) =>
              paymentTerm.name ===
              (isNonCashExpenseClass
                ? NON_CASH_PAYMENT_TERM
                : DEFAULT_PAYMENT_TERM),
          )
          .id.toString();
        return {
          ...state,
          departmentId: payload,
          expenseClassId,
          paymentTermId,
        };
      }
      case 'SET_PARENT_ID': {
        return {
          ...state,
          parentId: payload,
        };
      }
      case 'SET_EXPENSE_NAME':
        return { ...state, name: payload };
      case 'SET_ACCOUNT_NUMBER':
        return { ...state, expenseAccountNum: payload };
      case 'SET_RATE_OF_OCCURANCE':
        return { ...state, frequency: payload };
      case 'SET_ONE_TIME_EXPENSE':
        return {
          ...state,
          ...DEFAULT_CHANGE_OVER_TIME_STATES,
          frequency: 4,
          endDate: null,
        };
      case 'SET_EXPENSE_TYPE':
        return {
          ...state,
          ...DEFAULT_CHANGE_OVER_TIME_STATES,
          expenseType: payload,
          expenseAmount: null,
        };
      case 'SET_EXPENSE_AMOUNT':
        return { ...state, expenseAmount: payload };
      case 'SET_START_DATE':
        return { ...state, startDate: payload };
      case 'SET_FREQUENCY':
        return { ...state, frequency: payload, changeFrequency: null };
      case 'SET_END_DATE':
        return { ...state, endDate: payload };
      case 'SET_CHANGE_OVER_TIME':
        return {
          ...state,
          ...DEFAULT_CHANGE_OVER_TIME_STATES,
          changeOverTime: payload,
        };
      case 'SET_CHANGE_FREQUENCY':
        return { ...state, changeFrequency: payload };
      case 'SET_EXPENSE_CHANGE_TYPE':
        return {
          ...state,
          expenseChangeType: payload,
          changeAmount: null,
        };
      case 'SET_CHANGE_AMOUNT':
        return { ...state, changeAmount: payload };
      case 'SET_EXPENSE_LINK': {
        const { criteriaNumber, value } = payload;
        const expenseGroupCriteria = [...state.expenseGroupCriteria];
        expenseGroupCriteria[criteriaNumber] = {
          ...expenseGroupCriteria[criteriaNumber],
          ...value,
        };
        return {
          ...state,
          expenseGroupCriteria,
        };
      }
      case 'RESET_EXPENSE_LINK':
        return {
          ...state,
          expenseGroupCriteria: null,
          payroll: false,
        };
      case 'ADD_EXPENSE_LINK':
        return {
          ...state,
          expenseGroupCriteria: [
            ...(state.expenseGroupCriteria ?? []),
            {
              type: 'Vendor',
              operator: 'Contains',
              value: '',
            },
          ],
        };
      case 'REMOVE_EXPENSE_LINK': {
        const expenseGroupCriteria = [...state.expenseGroupCriteria];
        expenseGroupCriteria.splice(payload, 1);
        return {
          ...state,
          expenseGroupCriteria,
        };
      }
      case 'RESET_CHANGE_OVER_TIME':
        return {
          ...state,
          ...DEFAULT_CHANGE_OVER_TIME_STATES,
        };
      case 'SET_PAYROLL_EXPENSE':
        return {
          ...state,
          payroll: payload,
        };
      case 'SET_INVOICE_TIMING':
        return {
          ...state,
          invoiceTiming: payload,
        };
      case 'SET_PAYMENT_TERM':
        return {
          ...state,
          paymentTermId: payload,
        };
      case SET_CUSTOM_FORMULA:
        return {
          ...state,
          customFormula: payload,
        };
      case SET_FORMULA_VALIDATION_RESPONSE:
        return {
          ...state,
          formulaValidationMsg: payload.formulaValidationMsg,
          isFormulaValid: payload.isFormulaValid,
        };
      case 'SET_API_ERROR_MESSAGE':
        return {
          ...state,
          errorMessage: payload,
        };
      case SET_INITIAL_EXP_AMOUNT:
        return {
          ...state,
          initialAmount: payload,
        };
      default:
        throw new Error(`unknown action type: ${type}`);
    }
  };

function TheAddExpenseModal({
  onClose,
  currentRecord,
  currentPreviewEntries = null,
  departments,
  scenarioId,
  getExpensesClasses,
  createExpense,
  updateExpense,
  updatePayrollExpense,
  isPayrollExpenseSection = false,
  paymentTerms,
  getPaymentTerms,
  companyId,
  onEdit,
  nonCashExpenseClassId,
}) {
  const expenseModalRef = useRef(null);
  const [localFormState, setLocalFormState] = useReducer(
    getFormReducer(departments, paymentTerms, nonCashExpenseClassId),
    currentRecord ?? getInitialState(getISODate(Date.now())),
  );
  const [previewEntries, setPreviewEntries] = useState(currentPreviewEntries);
  const [showExpenseGuideModal, setShowExpenseGuideModal] = useState(false);

  useEffect(() => {
    getExpensesClasses(scenarioId);
    if (!paymentTerms.length) {
      getPaymentTerms(companyId, scenarioId);
    }
  }, [
    getExpensesClasses,
    scenarioId,
    paymentTerms,
    getPaymentTerms,
    companyId,
  ]);

  useEffect(() => {
    // When a user opens the driver in "edit" mode, the formula is valid
    // because only valid formulas can be created in the first place and they
    // cannot become invalid between creation and "edit" mode
    if (localFormState.id) {
      setLocalFormState({
        type: SET_FORMULA_VALIDATION_RESPONSE,
        payload: {
          formulaValidationMsg: '',
          isFormulaValid: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, []);

  const queryClient = useQueryClient();

  const getUpdateAction = () => {
    if (isPayrollExpenseSection) {
      return updatePayrollExpense;
    }
    return updateExpense;
  };

  const handleSave = async () => {
    try {
      setLocalFormState({ type: 'SET_API_ERROR_MESSAGE', payload: '' });
      const stateWithTrimmedExpenseName = {
        ...localFormState,
        name: localFormState?.name?.trim(),
        expenseAccountNum: localFormState?.expenseAccountNum?.trim(),
      };
      if (localFormState.id) {
        const updateAction = getUpdateAction();
        await updateAction(stateWithTrimmedExpenseName, scenarioId);
        if (!isPayrollExpenseSection) {
          queryClient.invalidateQueries(EXPENSE_ACTUALS_QUERY_KEY);
        }
        onEdit?.();
      } else {
        await createExpense(stateWithTrimmedExpenseName, scenarioId);
      }
      onClose();
    } catch (e) {
      setPreviewEntries(null);
      setLocalFormState({
        type: 'SET_API_ERROR_MESSAGE',
        payload: e.response?.data?.error?.errorMessage
          ? e.response.data.error.errorMessage
          : e.message,
      });
      expenseModalRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const isViewOnly = useViewOnlyMode(expenseModalRef);

  const handlePreview = async () => {
    const { startDate, endDate, expenseGroupCriteria, id } = localFormState;
    const { data } = await getLinkedExpensePreview(
      { startDate, endDate, expenseGroupCriteria, expenseGroupId: id },
      scenarioId,
    );
    setPreviewEntries(data.data);
  };

  return (
    <>
      <FormulaGuideModal
        formulaKey={EXPENSE}
        open={showExpenseGuideModal}
        data-testid="expense-guide-modal"
        onClose={() => setShowExpenseGuideModal(false)}
      />
      <ModalBase
        id="expense-modal"
        data-testid="add-expense-modal"
        className={previewEntries && 'LinkPreviewModal'}
        onCancel={onClose}
        ref={expenseModalRef}
      >
        {!previewEntries ? (
          <ExpenseForm
            state={localFormState}
            onCancel={onClose}
            onSubmit={() =>
              localFormState.expenseGroupCriteria
                ? handlePreview()
                : handleSave()
            }
            onUpdate={setLocalFormState}
            onFormulaGuideClick={() => setShowExpenseGuideModal(true)}
            paymentTerms={paymentTerms}
            hasWritePermission={!isViewOnly}
          />
        ) : (
          <LinkPreview
            entries={previewEntries}
            state={localFormState}
            onCancel={() => setPreviewEntries(null)}
            onSubmit={handleSave}
          />
        )}
      </ModalBase>
    </>
  );
}

const mapStateToProps = ({ expenses, scenario, settings, companies }) => ({
  departments: expenses.departments,
  scenarioId: scenario.scenarioId,
  paymentTerms: settings.paymentTerms.data,
  companyId: companies.selectedCompanyId,
  nonCashExpenseClassId: expenses.expensesClasses.find(
    (expenseClass) => expenseClass.name === NON_CASH_EXPENSE_CLASS,
  )?.id,
});

export default connect(mapStateToProps, {
  getExpensesClasses: getExpensesClassesAction,
  createExpense: createExpenseAction,
  updateExpense: updateExpenseAction,
  updatePayrollExpense: updatePayrollExpenseAction,
  getPaymentTerms: getPaymentTermsAction,
})(TheAddExpenseModal);
