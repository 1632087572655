// @ts-check
import { getCurrencyLocale } from '@/constants/currencies';

/**
 * Get a Intl.NumberFormat instance to format a number as a monetary unit based
 * on the passed locale
 *
 * @param {string | string[]} locale Intl.NumberFormat locale
 * @param {Intl.NumberFormatOptions} [options] Intl.NumberFormat options
 * @returns {Intl.NumberFormat} an Intl.NumberFormat instance
 */
export const getMonetaryFormatter = (locale = [], options = {}) => {
  let config = {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    ...options,
  };

  const currency = getCurrencyLocale();
  if (currency) {
    config = {
      style: 'currency',
      currency,
      // Ensures the correct symbol is displayed, e.g. Rs vs PKR
      currencyDisplay: 'narrowSymbol',
      ...config,
    };
  }

  return new Intl.NumberFormat(locale, config);
};

/**
 * Returns the given number formatted as a monetary value, using the user's
 * configured currency and the number format appropriate to their browser
 * locale.
 *
 * @param {string | number} value Number to format
 * @returns {string | null} value formatted as currency (if currency configured)
 *   or number. Will return null if the value provided is not a number
 */
function formatMonetary(value, options = {}) {
  if (typeof value === 'string' && !Number.isFinite(Number.parseFloat(value)))
    return null;
  const formatter = getMonetaryFormatter([], options);
  return formatter.format(Number(value));
}

export default formatMonetary;
