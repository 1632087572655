// @ts-check
import CheckmarkSmallIcon from '@bill/cashflow.assets/checkmark-small';
import SolidCircleIcon from '@bill/cashflow.assets/solid-circle';
import SyncIcon from '@bill/cashflow.assets/sync';
import TrinitySpotSyncImage from '@bill/cashflow.assets/trinity-spot-sync';
import { onboardingStatus, onboardingStatusText } from './constants';
import './OnboardingChecklist.scss';

const checklistStepState = /** @type {const} */ ({
  PENDING: 'pending',
  LOADING: 'loading',
  COMPLETED: 'completed',
});

/** @typedef {(typeof checklistStepState)[keyof typeof checklistStepState]} ChecklistStep */

/** @type {(stepState: ChecklistStep) => React.ReactElement | never} */
const getIcon = (stepState) => {
  switch (stepState) {
    case checklistStepState.PENDING:
      return (
        <SolidCircleIcon
          className="OnboardingChecklist_PendingIcon"
          data-testid="checklist-pending-icon"
        />
      );
    case checklistStepState.LOADING:
      return (
        <SyncIcon
          className="OnboardingChecklist_Icon OnboardingChecklist_Icon-loading"
          data-testid="checklist-loading-icon"
        />
      );
    case checklistStepState.COMPLETED:
      return (
        <CheckmarkSmallIcon
          className="OnboardingChecklist_Icon OnboardingChecklistIcon-completed"
          data-testid="checklist-completed-icon"
        />
      );
    default: {
      /** @type {never} */
      const exhaustiveCheck = stepState;
      throw Error(`Unknown state: ${exhaustiveCheck}`);
    }
  }
};

/** @typedef {(typeof onboardingStatus)[keyof typeof onboardingStatus]} OnboardingStatus */

/**
 * Renders Bill processing screen checklist
 *
 * @example
 *   <OnboardingChecklist
 *     currentOnboardingStatus={currentOnboardingStatus}
 *   />;
 *
 * @typedef {{
 *   currentOnboardingStatus: OnboardingStatus;
 * }} OnboardingChecklistProps
 *
 * @type {(props: OnboardingChecklistProps) => React.ReactElement}
 */
export default function OnboardingChecklist({ currentOnboardingStatus }) {
  const checklist = /** @type {[keyof typeof onboardingStatusText]} */ (
    Object.keys(onboardingStatusText)
  );

  /** @type {Number} */
  let currentStateIndex = checklist.length;
  return (
    <div className="OnboardingChecklist">
      <div>
        {checklist.map((status, index) => {
          /** @type {ChecklistStep} */
          let state = checklistStepState.PENDING;
          if (
            status === currentOnboardingStatus ||
            (currentOnboardingStatus === onboardingStatus.PROCESSING &&
              status === onboardingStatus.STARTED)
          ) {
            currentStateIndex = index;
            state = checklistStepState.LOADING;
          } else if (currentStateIndex > index) {
            state = checklistStepState.COMPLETED;
          }
          return (
            <div className="OnboardingChecklist_Step" key={status}>
              <div>
                {getIcon(state)}
                {index < checklist.length - 1 && (
                  <div className="OnboardingChecklist_Separator">|</div>
                )}
              </div>
              <div
                className={`${
                  state === checklistStepState.PENDING &&
                  'OnboardingChecklist_PendingText'
                }`}
              >
                {onboardingStatusText[status]}
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <TrinitySpotSyncImage className="OnboardingChecklist_Image" />
      </div>
    </div>
  );
}
