// @ts-check
import {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import DateField from '@/components/common/DateField';
import { getISODate } from '@/helpers/dateFormatter';
import './DateEditor.scss';

/**
 * @typedef {{
 *   id: string;
 * } & import('ag-grid-community').ICellEditorParams} DateEditorParams
 */

/**
 * An AG Grid cell editor for dates
 *
 * @example
 *   columnDefs={[
 *    {
 *      ...
 *      cellEditor: DateEditor,
 *      editable: true
 *    }
 *   ]}
 *
 * @type {React.ForwardRefRenderFunction<
 *   import('ag-grid-react').ICellEditorReactComp,
 *   DateEditorParams
 * >}
 * @see https://www.ag-grid.com/react-grid/component-cell-editor/
 */
const DateEditor = ({ charPress, value, id }, ref) => {
  /** @type {ReturnType<typeof useRef<HTMLInputElement>>} */
  const inputRef = useRef();
  const [localValue, setLocalValue] = useState(() => {
    const numPress = Number(charPress);
    if (charPress && !Number.isNaN(numPress)) return numPress;
    if (value) return new Date(value).getTime();
    return null;
  });

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useImperativeHandle(ref, () => ({
    getValue: () => (localValue ? getISODate(localValue) : null),
  }));

  const handleChange = useCallback((payload) => setLocalValue(payload), []);

  return (
    <DateField
      ref={inputRef}
      id={id}
      className="Spreadsheet_CellEditor Spreadsheet_CellEditor-date"
      value={localValue}
      onChange={handleChange}
    />
  );
};

export default forwardRef(DateEditor);
