import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import ErrorMessages from '@/components/common/ErrorMessages';
import InfoMessages from '@/components/common/InfoMessage';
import PasswordField, {
  PASSWORD_RULE,
  isPasswordValid,
} from '@/components/common/PasswordField';
import CardWrapper from './CardWrapper';
import Footer from './Footer';
import Header from './Header';

const ChangePassword = ({ changeUserPasswordAction, error, loading }) => {
  const [mode, setMode] = useState('read');

  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [errorMessage, setErrorMessage] = useState(error);

  const clear = () => {
    setCurrentPassword(null);
    setNewPassword(null);
    setConfirmPassword(null);
    setMode('read');
    setErrorMessage(null);
  };

  useEffect(() => {
    if (!error && !loading) {
      clear();
    }

    setErrorMessage(error);
  }, [loading, error]);

  const handleOnSaveClick = async () => {
    const params = {
      newPassword,
      currentPassword,
    };
    changeUserPasswordAction(params);
  };
  const handleOnCancelClick = () => {
    clear();
  };

  const isButtonDisabled = () => {
    return (
      !currentPassword ||
      !newPassword ||
      !confirmPassword ||
      loading ||
      newPassword !== confirmPassword ||
      !isPasswordValid(confirmPassword)
    );
  };

  const validateConfirmPassword = () => {
    const valid = confirmPassword === newPassword;
    if (!valid) return 'Password does not match';

    return false;
  };

  return (
    <CardWrapper>
      <Header
        mode={mode}
        setMode={setMode}
        title="Password"
        data-testid="password-edit"
      />

      {mode === 'edit' && (
        <Form className="pt-4">
          <InfoMessages message={PASSWORD_RULE} />
          {errorMessage && (
            <ErrorMessages success={null} error={errorMessage} />
          )}
          <Row className="pt-4">
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="currentPassword" className="pl-1">
                  Current Password
                </Form.Label>
                <PasswordField
                  id="currentPassword"
                  name="currentPassword"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  validate={() =>
                    !currentPassword && 'Please enter your current password'
                  }
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="newPassword" className="pl-1">
                  New Password
                </Form.Label>
                <PasswordField
                  id="newPassword"
                  name="newPassword"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="confirmPassword" className="pl-1">
                  Confirm Password
                </Form.Label>
                <PasswordField
                  id="confirmPassword"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  validate={validateConfirmPassword}
                />
              </Form.Group>
            </Col>
          </Row>
          {mode === 'edit' && (
            <Footer
              onSaveClick={handleOnSaveClick}
              onCancelClick={handleOnCancelClick}
              disabledSaveBtn={isButtonDisabled()}
              loading={loading}
              saveBtnTxt="Save New Password"
            />
          )}
        </Form>
      )}
    </CardWrapper>
  );
};

ChangePassword.propTypes = {
  /** Action function to change user password */
  changeUserPasswordAction: PropTypes.func.isRequired,
  /** Any error happens while making change request */
  error: PropTypes.bool,
  /** Loading state during form submission */
  loading: PropTypes.bool,
};

export default ChangePassword;
