import { useMemo } from 'react';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';
import { ERROR_BOUNDARY_TEXT } from '@/constants/charts';
import useSelectedScenarios from '@/hooks/useSelectedScenarios';
import ChartMetrics from './ChartMetrics';
import ChartTooltip from './ChartTooltip';
import PercentBarChart from './PercentBarChart';
import { getStackedChartMetrics } from './helpers';
import { getBarTooltipPositioner } from './tooltips';

/**
 * Renders a stacked bar chart with accompanying per-scenario metrics
 *
 * @example
 *   <PercentBarWithMetrics
 *     data={[base, compare]}
 *     data-testid="foo"
 *     onChartCreated={setChart}
 *   />;
 */
function PercentBarWithMetrics({
  chartRef,
  colors,
  data,
  'data-testid': dataTestId,
  onChartCreated,
  valueFormatter,
  loading,
}) {
  const scenarios = useSelectedScenarios();

  const metrics = useMemo(
    () => getStackedChartMetrics(data, { formatter: valueFormatter, colors }),
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [data, valueFormatter],
  );
  const hasComparison = scenarios.length > 1;

  return (
    <CommonErrorBoundary text={ERROR_BOUNDARY_TEXT}>
      <div className="ExpandedView_ChartWrapper ExpandedView_ChartWrapper-barChart">
        <PercentBarChart
          data={data}
          data-testid={dataTestId}
          ref={chartRef}
          onChartCreated={onChartCreated}
          loading={loading}
          colors={colors}
          tooltip={
            !hasComparison && (
              <ChartTooltip
                className="ChartTooltip ChartTooltip-bottom"
                valueFormatter={
                  valueFormatter ? (value) => valueFormatter(value) : undefined
                }
                positioner={getBarTooltipPositioner}
                metrics={metrics}
              />
            )
          }
        />
      </div>
      {hasComparison && (
        <ChartMetrics
          data={data}
          data-testid={`${dataTestId}-metrics`}
          metrics={metrics}
          scenarios={scenarios}
          valueFormatter={valueFormatter}
        />
      )}
    </CommonErrorBoundary>
  );
}

PercentBarWithMetrics.propTypes = {
  /** Ref for storing the Highcharts chart object. Used for facilitating export. */
  'chartRef': PropTypes.shape({
    current: PropTypes.instanceOf(Highcharts.Chart),
  }),
  /** Data to populate the chart and metrics */
  'data': PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  /** Unique ID for selecting the chart in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /**
   * Callback for accessing the chart object from the parent, to pass to
   * dependent components such as the legend or export button
   *
   * @param {Highcharts.Chart} chart
   */
  'onChartCreated': PropTypes.func,
  /**
   * Formats the metrics for display, defaulting to monetary
   *
   * @param {number} value Number to format
   * @returns {string} formatted value
   */
  'valueFormatter': PropTypes.func,
  /** Whether or not a loading indicator should be displayed */
  'loading': PropTypes.bool,
  /** Color map for items on the chart */
  'colors': PropTypes.object,
};

export default PercentBarWithMetrics;
