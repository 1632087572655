// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

const getSelectedScenarios = createSelector(
  [
    ({ scenario }) => scenario.scenarios,
    ({ scenario }) => scenario.scenarioId,
    ({ scenario }) => scenario.compareScenarioId,
  ],
  (scenarios, scenarioId, compareScenarioId) =>
    scenarios
      .filter((scenario) =>
        [scenarioId, compareScenarioId].includes(scenario.scenarioId),
      )
      // Always put the base scenario first
      .sort((a, b) => {
        if (b.scenarioId === scenarioId) return 1;
        if (a.scenarioId === scenarioId) return -1;
        return 0;
      }),
);

/**
 * Returns the current selected base and comparison scenarios
 *
 * @returns {import('@/types/services/backend').ScenarioInfoDto[]} Array of
 *   scenario objects
 */
function useSelectedScenarios() {
  return useSelector(getSelectedScenarios);
}

export default useSelectedScenarios;
