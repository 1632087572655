// @ts-check
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

/* eslint-disable-next-line no-restricted-imports -- predates description requirement */
import { Link, useHistory } from 'react-router-dom';
import { loginAction, setTMXSessionId } from '@/actions/auth';
import { setCompaniesOnLoginAction } from '@/actions/companies';
import CountryRequiredModal from '@/components/Landing/CountryRequiredModal';
import Button from '@/components/common/Button';
import ErrorMessages from '@/components/common/ErrorMessages';
import FormField from '@/components/common/FormField';
import PasswordField from '@/components/common/PasswordField';
import {
  DASHBOARD_PATH,
  LOGIN_PATH,
  UNAUTHORIZED_PATH,
} from '@/constants/pages';
import Landing from '@/containers/Landing';
import { getCountryLockoutDetails } from '@/services/complaince.service';
import { persistor } from '@/store';

const validateEmail = (e) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = re.test(String(e).toLowerCase());
  return !isValid && 'Please enter a valid email!';
};

const validatePassword = (e) => {
  return !String(e) && 'Please enter a password';
};

export const SignInFormUnwrapped = ({
  login,
  setCompaniesOnLogin,
  isAuthenticated,
  statefulRoute,
  isUnauthorizedByTMX,
  deviceSessionId,
  setTMXSession,
  country,
}) => {
  const history = useHistory();
  const [loggingIn, setLoggingIn] = useState(false);
  /** @type {ReturnType<typeof useState<string>>} */
  const [email, setEmail] = useState();
  /** @type {ReturnType<typeof useState<string>>} */
  const [password, setPassword] = useState();
  const [error, setError] = useState(null);
  const [showCountryRequiredModal, setShowCountryRequiredModal] =
    useState(false);

  const submitLogin = async (e) => {
    e.preventDefault();
    setLoggingIn(true);
    setError(null);
    try {
      await login({ emailAddress: email, password, deviceSessionId });
    } catch ({ message }) {
      setLoggingIn(false);
      setError(message);
    }
  };

  const redirectOnLogin = () => {
    // At this point, to route to the correct persisted route we only
    // need the pathname because the scenarioId and companyId are already
    // stored in state via the `AUTHENTICATION_SUCCEEDED` action type, so all that's
    // left is navigating to the correct route
    const { pathname } = statefulRoute ?? {};
    let path = DASHBOARD_PATH;
    if (pathname && pathname !== LOGIN_PATH) {
      path = pathname;
    }
    history.replace(path);
  };

  useEffect(() => {
    document.title = 'Sign In';
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      persistor.flush().then((res) => {
        // eslint-disable-next-line no-console -- predates description requirement
        console.log('Persisted Redux state to localStorage', res);
      });
    }
  }, [isAuthenticated]);

  const getIsCountryLockout = async () => {
    const lockoutDetails = await getCountryLockoutDetails();
    const isCountryLocked = lockoutDetails.data.data.isCountryComplianceEnabled;
    if (isCountryLocked) {
      setShowCountryRequiredModal(true);
      setLoggingIn(false);
    } else {
      redirectOnLogin();
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      setCompaniesOnLogin();
      if (country) {
        redirectOnLogin();
      } else {
        getIsCountryLockout();
      }
    }

    if (isUnauthorizedByTMX) {
      history.replace(UNAUTHORIZED_PATH);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- statefulRoute is deliberately excluded from the dependency array */
  }, [isAuthenticated, history, isUnauthorizedByTMX]);

  useEffect(() => {
    // On login Page Load Calling to send ORG_ID & deviceSession ID to Tags.js CDN for UI profiling, we are doing this
    // on page load instead of action call i.e on Login action call, Because TMX server required more time to do processing.
    if (setTMXSession) {
      setTMXSession();
    }
  }, [setTMXSession]);

  return (
    <Landing>
      {showCountryRequiredModal && (
        <CountryRequiredModal onSuccess={redirectOnLogin} />
      )}
      <form id="signInForm" className="landing-Form" onSubmit={submitLogin}>
        <h1 className="Panel_Title">Sign In</h1>

        <ErrorMessages error={error} data-testid="SignInForm-errors" />
        <div className="landing-Form_Group">
          <label htmlFor="email" className="landing-Form_Label">
            Email Address
          </label>
          <FormField
            type="email"
            id="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            validate={validateEmail}
            data-testid="email"
          />
        </div>
        <div className="landing-Form_Group">
          <label htmlFor="password" className="landing-Form_Label">
            Password
          </label>
          <PasswordField
            id="password"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            data-testid="password"
            validate={validatePassword}
          />
        </div>
        <div className="landing-Form_Group">
          <Button
            id="submit"
            type="submit"
            data-testid="submit"
            disabled={!email || !password}
            className="Button Button-success"
            loading={loggingIn}
          >
            Sign In
          </Button>
        </div>
        <Link to="/forgot-password">Forgot Password?</Link>
      </form>
    </Landing>
  );
};

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
  deviceSessionId: auth.deviceSessionId,
  isUnauthorizedByTMX: auth.isUnauthorizedByTMX,
  statefulRoute: auth.statefulRoute,
  country: auth.userInfo.country,
});

export default connect(mapStateToProps, {
  login: loginAction,
  setCompaniesOnLogin: setCompaniesOnLoginAction,
  setTMXSession: setTMXSessionId,
})(SignInFormUnwrapped);
