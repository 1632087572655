// @ts-check
import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { COMPANY_INTEGRATIONS } from '@/cacheKeys';
import {
  enrichIntegrations,
  integrationStatusesFromPayload,
} from '@/helpers/integrations';
import { getIntegrationsStatus } from '@/services/settingsService';

const DEAFULT_INTEGRATIONS =
  /** @type {import('@/services/settingsService').IntegrationStatus[]} */ ([]);

/**
 * @typedef {{
 *   isLoading: boolean;
 *   integrations: import('@/helpers/integrations').EnrichedIntegrationStatus[];
 *   integrationsUnauthorized: import('@/helpers/integrations').IntegrationUnauthorized[];
 *   integrationsConnected: import('@/helpers/integrations').IntegrationConnected[];
 *   integrationsAllowed: import('@/helpers/integrations').IntegrationAllowed[];
 * }} UseIntegrationResult
 */

/**
 * Get a company's 3rd party integrations
 *
 * @type {(
 *   companyId?: number,
 *   options?: { refetchInterval?: number },
 * ) => UseIntegrationResult} }
 */
const useIntegrations = (companyId, { refetchInterval } = {}) => {
  const fetchStatus = useCallback(async () => {
    if (!companyId) return DEAFULT_INTEGRATIONS;
    const { data } = await getIntegrationsStatus(companyId);
    return data.data;
  }, [companyId]);

  const integrations = useQuery(
    [COMPANY_INTEGRATIONS, companyId],
    fetchStatus,
    {
      staleTime: 60000,
      refetchOnWindowFocus: true,
      refetchInterval,
    },
  );

  return useMemo(() => {
    const enrichedIntegrations = enrichIntegrations(
      integrations.data ?? DEAFULT_INTEGRATIONS,
    );

    const [
      integrationsUnauthorized,
      integrationsConnected,
      integrationsAllowed,
    ] = integrationStatusesFromPayload(enrichedIntegrations);

    return {
      isLoading: integrations.isLoading,
      integrations: enrichedIntegrations,
      integrationsUnauthorized,
      integrationsConnected,
      integrationsAllowed,
    };
  }, [integrations.isLoading, integrations.data]);
};

export default useIntegrations;
