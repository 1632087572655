import { useCallback } from 'react';
import Checkbox from '@/components/common/Checkbox';
import MonthField from '@/components/common/MonthField';
import { REVENUE_STRIPE } from '@/constants/integrations';
import { STREAMS } from '@/constants/revenueStream';
import {
  getFormattedDateFromTimeStamp,
  formatMonthYear,
  getDateOffsetByYears,
  getDateOffsetByMonths,
} from '@/helpers/dateFormatter';
import { isEndDateBeforeStartDate } from '@/helpers/validators';

const DriverDateRange = ({
  revenue,
  revenueStream,
  handleStreamDataChange,
}) => {
  const { startDate, endDate, noEndDate, revenueType } = revenueStream;

  const handleNoEndDateChange = ({ target }) => {
    handleStreamDataChange('noEndDate', target.checked);
    handleStreamDataChange('endDate', null);
  };

  const startDateMs = startDate ? new Date(startDate).getTime() : null;
  const endDateMs = endDate ? new Date(endDate).getTime() : null;

  const handleStartDateChange = useCallback(
    (val) =>
      handleStreamDataChange('startDate', getFormattedDateFromTimeStamp(val)),
    [handleStreamDataChange],
  );

  const handleEndDateChange = useCallback(
    (val) => {
      handleStreamDataChange('noEndDate', !val);
      handleStreamDataChange('endDate', getFormattedDateFromTimeStamp(val));
    },
    [handleStreamDataChange],
  );

  return (
    <>
      <p className="Form_Desc">
        {STREAMS[revenueType].revenueGenerationDateLabel}
      </p>

      <div className="Form">
        <div className="Form_Group-halfWidth">
          <label className="Label" htmlFor="startDatePicker">
            {STREAMS[revenueType].revenueGenerationStartDateLabel}
            <sup style={{ color: 'red' }}>*</sup>
          </label>
          <MonthField
            disabled={
              revenue?.externalSource !== REVENUE_STRIPE &&
              revenue?.hasSubscribers
            }
            value={startDateMs}
            id="startDatePicker"
            onChange={handleStartDateChange}
            max={getDateOffsetByYears(new Date().setUTCMonth(11), 6).getTime()}
            data-testid="add-revenue-stream-start-date"
          />
        </div>
        <div className="Form_Group-halfWidth">
          <label className="Label" htmlFor="endDatePicker">
            {STREAMS[revenueType].revenueGenerationEndDateLabel}
          </label>
          <MonthField
            value={endDateMs}
            min={getDateOffsetByMonths(startDate, 1).getTime()}
            id="endDatePicker"
            onChange={handleEndDateChange}
            validate={() =>
              isEndDateBeforeStartDate(startDate, endDate) &&
              `Date must be on or after ${formatMonthYear(startDateMs)}`
            }
          />
          <Checkbox
            name="noEndDate"
            checked={noEndDate}
            id="end-date-check"
            onChange={handleNoEndDateChange}
          >
            No end date
          </Checkbox>
        </div>
      </div>
    </>
  );
};

export default DriverDateRange;
