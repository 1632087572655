// @ts-check
export const SET_TITLE = 'SET_TITLE';
export const TOGGLE_VISIBILITY = 'TOGGLE_VISIBILITY';
export const SET_TEXT = 'SET_TEXT';
export const SET_AI_GENERATED_TEXT = 'SET_AI_GENERATED_TEXT';
export const SET_CHARTS = 'SET_CHARTS';
export const SET_FOOTER_DISCLAIMER = 'SET_FOOTER_DISCLAIMER';
export const HYDRATE_STATE = 'HYDRATE_STATE';

export const sectionCategory = /** @type {const} */ ({
  COVER_PAGE: 'COVER_PAGE',
  EXECUTIVE_SUMMARY: 'EXECUTIVE_SUMMARY',
  DISCLAIMER: 'DISCLAIMER',
  REPORT: 'REPORT',
});

/** @typedef {(typeof sectionCategory)[keyof typeof sectionCategory]} SectionCategory */

export const baseSectionId = /** @type {const} */ ({
  COVER_PAGE: 'cover-page',
  DISCLAIMER: 'disclaimer',
  EXECUTIVE_SUMMARY: 'executive-summary',
});

/** @typedef {(typeof baseSectionId)[keyof typeof baseSectionId]} BaseSectionId */

export const sectionId = /** @type {const} */ ({
  ...baseSectionId,
  PROFIT_LOSS: 'profit-loss',
  BALANCE_SHEET: 'balance-sheet',
  CASH_FLOW: 'cash-flow-statement',
});

/** @typedef {(typeof sectionId)[keyof typeof sectionId]} SectionId */

export const cashReportSectionId = /** @type {const} */ ({
  ...baseSectionId,
  CASH_IN_OUT: 'cash-in-out',
});

const financialReport = /** @type {const} */ ({
  PROFIT_LOSS: 'profit_loss',
  BALANCE_SHEET: 'balance_sheet',
  CASH_FLOW: 'cashflow_statement',
});

const cashReport = /** @type {const} */ ({
  CASH_IN_OUT: 'cash_in_out',
});

/** @typedef {(typeof financialReport)[keyof typeof financialReport]} FinancialReport */

const displayName = /** @type {const} */ ({
  COVER_PAGE: 'Cover Page',
  DISCLAIMER: 'Disclaimer',
  EXECUTIVE_SUMMARY: 'Executive Summary',
  PROFIT_LOSS: 'Profit & Loss',
  BALANCE_SHEET: 'Balance Sheet',
  CASH_FLOW: 'Cash flow Statement', // flow should be updated to Flow on the BE and then here as well
  CASH_IN_OUT: 'Cash In & Out',
});

/** @typedef {(typeof displayName)[keyof typeof displayName]} DisplayName */

export const summaryKey = /** @type {const} */ ({
  DISCLAIMER: 'disclaimer',
  EXECUTIVE_SUMMARY: 'executiveSummary',
});

export const promptType = /** @type {const} */ ({
  [summaryKey.EXECUTIVE_SUMMARY]: 'EXPORTABLE_REPORT_EXECUTIVE_SUMMARY',
});

/** @typedef {(typeof summaryKey)[keyof typeof summaryKey]} SummaryKey */

/** @type {import('@/types/exportableReport').ExportableReportBaseState} */
export const INITIAL_BASE_REPORT_STATE = {
  [sectionId.COVER_PAGE]: {
    active: true,
    content: { title: '' },
    displayName: displayName.COVER_PAGE,
    id: sectionId.COVER_PAGE,
    type: sectionCategory.COVER_PAGE,
  },
  [sectionId.DISCLAIMER]: {
    active: true,
    content: { disclaimer: '', footerDisclaimer: null },
    displayName: displayName.DISCLAIMER,
    id: sectionId.DISCLAIMER,
    type: sectionCategory.DISCLAIMER,
  },
  [sectionId.EXECUTIVE_SUMMARY]: {
    active: true,
    content: {
      executiveSummary: '',
      title: '',
      aiText: '',
      disclaimerText: '',
    },
    displayName: displayName.EXECUTIVE_SUMMARY,
    id: sectionId.EXECUTIVE_SUMMARY,
    type: sectionCategory.EXECUTIVE_SUMMARY,
  },
};

/** @type {import('@/types/exportableReport').ExportableFinancialReportsState} */
export const INITIAL_FINANCIAL_REPORT_STATE = {
  ...INITIAL_BASE_REPORT_STATE,
  [sectionId.PROFIT_LOSS]: {
    active: true,
    content: {
      title: '',
      charts: [],
      report_type: financialReport.PROFIT_LOSS,
    },
    displayName: displayName.PROFIT_LOSS,
    id: sectionId.PROFIT_LOSS,
    type: sectionCategory.REPORT,
  },
  [sectionId.BALANCE_SHEET]: {
    active: true,
    content: {
      title: '',
      charts: [],
      report_type: financialReport.BALANCE_SHEET,
    },
    displayName: displayName.BALANCE_SHEET,
    id: sectionId.BALANCE_SHEET,
    type: sectionCategory.REPORT,
  },
  [sectionId.CASH_FLOW]: {
    active: true,
    content: { title: '', charts: [], report_type: financialReport.CASH_FLOW },
    displayName: displayName.CASH_FLOW,
    id: sectionId.CASH_FLOW,
    type: sectionCategory.REPORT,
  },
};

/** @type {import('@/types/exportableReport').ExportableFinancialReportsState} */

export const INITIAL_CASH_FLOW_REPORT_STATE = {
  ...INITIAL_BASE_REPORT_STATE,
  [cashReportSectionId.CASH_IN_OUT]: {
    active: true,
    content: {
      title: '',
      charts: [],
      report_type: cashReport.CASH_IN_OUT,
    },
    displayName: displayName.CASH_IN_OUT,
    id: cashReportSectionId.CASH_IN_OUT,
    type: sectionCategory.REPORT,
  },
};

export const DEFAULT_AI_DISCLAIMER =
  'The author generated this text in part with GPT, OpenAI’s large-scale language-generation model. Upon generating draft language, the author reviewed, edited, and revised the language to their own liking and takes ultimate responsibility for the content of this publication';

export const CHARACTER_LIMIT = 22000;

export const MAX_PAGES = 6;
