import { useEffect, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { getExpensesClassesAction } from '@/actions/expenses';
import { getBandedGradient } from '@/components/Charts/colors';
import COLORS from '@/constants/colorPalette';
import { TOTAL_EXPENSES_TOGGLE_ID } from '@/constants/ui';
import { identity } from '@/helpers/index';
import useChartPreferences from './useChartPreferences';

const DEPARTMENT = 'dept';
const ADJUSTMENT_TO_PAYROLL = 'Adjustment to Payroll';
const payrollColorMap = {
  [ADJUSTMENT_TO_PAYROLL]: COLORS.cinnabar2,
};
const expenseClassColorMap = {
  1: COLORS.finmarkBlue,
  2: COLORS.crayola,
  3: COLORS.plum,
  5: COLORS.yellow,
  6: COLORS.spanishGrey,
  7: COLORS.raisinBlack,
  8: COLORS.sunrise,
  9: COLORS.slate,
  10: COLORS.pink,
  ...payrollColorMap,
};

/* eslint-disable-next-line jsdoc/require-jsdoc -- predates new ESLint config */
const useTotalExpensesChartColorMap = (scenarioId, scenarioQueries) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const expenseClasses = useSelector((state) => state.expenses.expensesClasses);

  const isGroupedByDept =
    useChartPreferences(TOTAL_EXPENSES_TOGGLE_ID) === DEPARTMENT;

  useEffect(() => {
    dispatch(getExpensesClassesAction(scenarioId));
  }, [dispatch, scenarioId]);

  // get all department ids that have expenses across base and compare scenarios
  const allDeptsInUse = useMemo(() => {
    if (!isGroupedByDept) return [];
    return [
      ...new Set(
        scenarioQueries
          .flatMap((scenario) => scenario.data.data ?? scenario.data)
          .map((exp) => exp?.id)
          .filter(identity),
      ),
    ];
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [scenarioQueries]);

  // Remove any departments not in use from the expense class's departments
  // list.
  //
  // This is necessary to prevent generating unused gradients. It also
  // ensures the widest discernible variety of plotted gradients
  const departmentsInUseByClass = useMemo(() => {
    if (!isGroupedByDept) return [];
    // remove any deparments not in use from the default class departments lists
    return expenseClasses.map((expense) => {
      const activeDepts = expense.departments.filter((dept) =>
        allDeptsInUse.includes(dept.id),
      );
      return { ...expense, departments: activeDepts };
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [isGroupedByDept, scenarioQueries, expenseClasses]);

  // Generate a color map for each department id based on its parent expense
  // class color
  const departmentGradientMap = useMemo(() => {
    if (!isGroupedByDept) return {};
    return departmentsInUseByClass?.reduce((gradientMap, expense) => {
      const { departments } = expense;
      if (!departments.length) return gradientMap;

      // get color gradients for departments within a class and map to dept id
      const departmentColorsFromExpenseClass = getBandedGradient(
        expenseClassColorMap[expense.id],
        departments.length,
      );

      const colorObj = departments.reduce((classDeptMap, dept, idx) => {
        return {
          ...classDeptMap,
          [dept.id]: departmentColorsFromExpenseClass[idx],
        };
      }, {});

      return { ...gradientMap, ...colorObj };
    }, payrollColorMap);
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [departmentsInUseByClass]);

  return isGroupedByDept ? departmentGradientMap : expenseClassColorMap;
};

export default useTotalExpensesChartColorMap;
