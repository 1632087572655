import { MONTHLY, timePeriodLength } from '@/constants/dateTime';
import formatOrdinal from './formatOrdinals';

const thisYear = new Date().getUTCFullYear();
const thisMonth = new Date().getUTCMonth();
export const MIN_DATE = Date.UTC(thisYear - 10);
export const MAX_DATE = Date.UTC(thisYear + 6, 11);
export const FPA_LITE_MAX_DATE = Date.UTC(thisYear + 1, thisMonth);

export const shortFormatter = new Intl.DateTimeFormat(
  {},
  {
    timeZone: 'UTC',
    month: 'short',
    year: '2-digit',
  },
);

const monthDayYearFormatter = new Intl.DateTimeFormat(
  {},
  { timeZone: 'UTC', month: '2-digit', day: '2-digit', year: 'numeric' },
);

const monthYearFormatter = new Intl.DateTimeFormat(
  {},
  { timeZone: 'UTC', month: '2-digit', year: 'numeric' },
);

const shortMonthFullYearFormatter = new Intl.DateTimeFormat(
  {},
  { timeZone: 'UTC', month: 'short', year: 'numeric' },
);

const DAY = 'day';

/**
 * Format a given date-like value in MM/YYYY format
 *
 * @param {Date | string | number} dateValue A Date object, or any value
 *   accepted by the Date() constructor
 * @returns {string}
 */
export function formatMonthYear(dateValue) {
  const date = new Date(dateValue);
  if (Number.isNaN(date.getTime())) {
    throw new TypeError(
      `Cannot instantiate a Date object from dateString: ${dateValue}`,
    );
  }
  return monthYearFormatter.format(date);
}

function addApostropheToYear(dateArray) {
  return dateArray.map(({ type, value }) =>
    type === 'year' ? `’${value}` : value,
  );
}

const relativeFormatter = new Intl.RelativeTimeFormat({});

const longFormFormatter = new Intl.DateTimeFormat('en-GB', {
  timeZone: 'UTC',
  dateStyle: 'medium',
});

/**
 * Describes the given date relative to now, e.g. "3 days ago"
 *
 * @param {number} value Timestamp
 * @returns {string} Relative description
 */
export function formatFromNow(value) {
  const minutes = Math.round(Math.abs(value - Date.now()) / (1000 * 60));

  if (minutes < 1) return 'less than a minute';
  if (minutes <= 59) return 'less than an hour';

  const hours = minutes / 60;
  if (hours < 24) {
    return relativeFormatter.format(-hours, 'hours');
  }

  const days = hours / 24;
  if (days < 7) {
    return relativeFormatter.format(-days, 'days');
  }

  return longFormFormatter.format(value);
}

/**
 * Formats the given date-like value with a short month and abbreviated year,
 * e.g. 'Nov ’20'.
 *
 * @param {Date | string | number} dateValue A Date object, or any value
 *   accepted by the Date() constructor
 * @returns {string} return formatted date if dateValue is not empty otherwise
 *   it will return empty string
 */
export function formatDateWithShortYear(dateValue) {
  if (!dateValue) {
    return '';
  }
  const date = new Date(dateValue);

  // Do other languages abbreviate years with an apostrophe?
  // I have no idea, but I assume not.
  if (!navigator.language.startsWith('en')) {
    return shortFormatter.format(date);
  }

  const dateArray = shortFormatter.formatToParts(date);
  return addApostropheToYear(dateArray).join('');
}

/**
 * Formats the given date range with short months and abbreviated years, e.g.
 * 'Nov ’20 - Feb ’21'.
 *
 * @param {Date | string | number} startDateValue A Date object, or any value
 *   accepted by the Date() constructor
 * @param {Date | string | number} endDateValue
 * @returns {string} formatted date range
 */
export function formatRangeWithShortYear(startDateValue, endDateValue) {
  const startDate = new Date(startDateValue);
  const endDate = new Date(endDateValue);

  return `${formatDateWithShortYear(startDate)} – ${formatDateWithShortYear(
    endDate,
  )}`;
}

/**
 * Return the timestamp for the current month
 *
 * @returns {number} timestamp
 */
export function getCurrentMonthTimestamp() {
  const curDate = new Date();
  return Date.UTC(curDate.getUTCFullYear(), curDate.getUTCMonth());
}

/**
 * Get a timestamp for the current day in UTC
 *
 * @returns {number} timestamp
 */
export function getCurrentDayTimestamp() {
  const curDate = new Date();
  return Date.UTC(
    curDate.getUTCFullYear(),
    curDate.getUTCMonth(),
    curDate.getUTCDate(),
  );
}

/**
 * Get an array of date timestamps (in milliseconds) for a range of dates
 *
 * @param {Date | string | number} startDate A Date object, or any value
 *   accepted by the Date() constructor
 * @param {Date | string | number} endDate
 * @returns {number[]}
 */
export function getDatesInRange(startDate, endDate) {
  const dates = [];
  const nextDate = new Date(startDate);
  const endDateMs = new Date(endDate).getTime();
  while (nextDate.getTime() <= endDateMs) {
    dates.push(nextDate.getTime());
    nextDate.setUTCMonth(nextDate.getUTCMonth() + 1);
  }
  return dates;
}

/**
 * Return ISO formatted date
 *
 * @returns {string} ISO formatted date
 */
export function getISODate(date) {
  const formattedDate = new Date(date);
  return formattedDate.toISOString().split('T')[0];
}

/**
 * Return 'YYYY-MM' formatted date
 *
 * @returns {string} 'YYYY-MM' formatted date
 */
export function getFormattedDateFromTimeStamp(timeStamp) {
  if (!timeStamp) {
    return '';
  }
  const date = new Date(timeStamp);

  let currentMonth = date.getUTCMonth() + 1;
  if (currentMonth < 10) {
    currentMonth = `0${currentMonth}`;
  }
  return `${date.getUTCFullYear()}-${currentMonth}`;
}

/**
 * Return 'MM/DD/YYYY' formatted date
 *
 * @param {string} dateString Anything that can be converted to a date object
 * @returns {string | null}
 */
export function formatMonthDayYear(dateString) {
  const date = new Date(dateString);
  if (Number.isNaN(date.getTime())) {
    throw new TypeError(
      `Cannot instantiate a Date object from dateString: ${dateString}`,
    );
  }
  return monthDayYearFormatter.format(date);
}

/**
 * Get the day after the passed date
 *
 * @param {Date} date - The date that precedes the next day
 * @returns {Date}
 */
export function getNextDay(date) {
  return date.setUTCDate(date.getUTCDate() + 1);
}

/**
 * Returns a new date offset from the given date by the given number of months.
 *
 * @param {Date | string | number} date A Date object, or any value accepted by
 *   the Date() constructor
 * @param {number} numMonths Number of months to offset, positive or negative
 * @returns {Date} The offset date
 */
export function getDateOffsetByMonths(date, numMonths) {
  const newDate = new Date(date);
  newDate.setUTCMonth(newDate.getUTCMonth() + numMonths, 1);
  return newDate;
}

/**
 * Returns a new date offset from the given date by the given number of years.
 *
 * @param {Date | string | number} date A Date object, or any value accepted by
 *   the Date() constructor
 * @param {number} numYears Number of years to offset, positive or negative
 * @returns {Date} The offset date
 */
export function getDateOffsetByYears(date, numYears) {
  const newDate = new Date(date);
  newDate.setUTCFullYear(newDate.getUTCFullYear() + numYears);
  return newDate;
}

/**
 * Returns a timestamp for midnight UTC on the given date, assuming the given
 * date will be intepreted as local time, e.g. 07/02/2021 2:00 PM GMT-4 ->
 * 07/02/2021 12:00 AM GMT.
 *
 * @param {Date | string | number} date A Date object, or any value accepted by
 *   the Date() constructor
 * @returns {number} Timestamp for the given date
 */
export function getUTCDayTimestamp(date) {
  const dateObj = new Date(date);
  return dateObj.setMinutes(-dateObj.getTimezoneOffset());
}

/**
 * Returns a timestamp for midnight local time on the given date, assuming the
 * given date will be interpreted as UTC
 *
 * @param {number} date A timestamp
 * @returns {Date} Date object representing midnight local time
 */
export function getLocalDayFromUTC(timestamp) {
  const dateObj = new Date(timestamp);
  dateObj.setUTCMinutes(dateObj.getTimezoneOffset());
  return dateObj;
}

/**
 * For a given date range, get an array of timestamps that corresponds to the
 * beginning of each interval within the time period
 *
 * @param {Date | string | number} startDate A Date object, or any value
 *   accepted by the Date() constructor
 * @param {Date | string | number} endDate
 * @param {string} timePeriod A time period, e.g. MONTHLY, QUARTERLY, ANNUALLY
 * @returns {number[][]}
 */
export const getTimePeriodsInRange = (
  startDate,
  endDate,
  timePeriod = MONTHLY,
) => {
  const dates = [];
  const nextDate = new Date(startDate);
  const endDateMs = new Date(endDate).getTime();
  const intervalOffset = timePeriodLength[timePeriod] - 1;
  while (nextDate.getTime() <= endDateMs) {
    const timestamp = nextDate.getTime();
    const intervalEndDate = new Date(timestamp);
    const timestampEndInterval = intervalEndDate.setUTCMonth(
      intervalEndDate.getUTCMonth() + intervalOffset,
    );
    dates.push([timestamp, timestampEndInterval]);
    nextDate.setUTCMonth(nextDate.getUTCMonth() + timePeriodLength[timePeriod]);
  }
  return dates;
};

export const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/**
 * Return '(Today | Yesterday), {dayOfWeek}, {month} {day}, {year}' formatted
 * date
 *
 * @param {string | number} timeStamp
 * @returns {string | null}
 */
export const formatDateFromNow = (timeStamp) => {
  const date = new Date(timeStamp);

  // Get the day of the week
  const dayOfWeek = daysOfWeek[date.getDay()];

  // Get the month
  const month = months[date.getUTCMonth()];

  // Get the day and year
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  // Get yesterday's date
  const yesterday = new Date();
  yesterday.setDate(yesterday.getUTCDate() - 1);

  // Determine if the date is today or yesterday
  const isToday = date.toDateString() === new Date().toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();

  // Format the date string
  if (isToday) {
    return `Today, ${dayOfWeek}, ${month} ${day}, ${year}`;
  }
  if (isYesterday) {
    return `Yesterday, ${dayOfWeek}, ${month} ${day}, ${year}`;
  }
  return `${dayOfWeek}, ${month} ${day}, ${year}`;
};

/**
 * Calculates the time elapsed in milliseconds from the starting timestamp given
 * in the params
 *
 * @type {(time: string | number | Date) => number}
 */
export const calculateTimeElapsed = (time) => {
  const startingTime = new Date(time);
  const currentTime = new Date();
  return currentTime.getTime() - startingTime;
};

/*
 * Format a given date-like value in Jun 5th, 2023 format
 *
 * @type {(dateValue?: number | Date | string) => string | null}
 */
export const getFormattedMonthDayYear = (dateValue) => {
  if (!dateValue) return null;

  const date = new Date(dateValue);

  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC',
    dateStyle: 'medium',
  });

  return formatter.formatToParts(date).reduce((accum, part) => {
    if (part.type === DAY) {
      return `${accum}${formatOrdinal(Number(part.value))}`;
    }
    return `${accum}${part.value}`;
  }, '');
};

/**
 * Formats the given date-like value with a short month and full year, e.g. 'Nov
 * 2020'.
 *
 * @param {Date | string | number} dateValue A Date object, or any value
 *   accepted by the Date() constructor
 * @returns {string} return formatted date if dateValue is not empty otherwise
 *   it will return empty string
 */
export function formatDateWithShortMonthFullYear(dateValue) {
  if (!dateValue) {
    return '';
  }
  const date = new Date(dateValue);

  // Do other languages abbreviate years with an apostrophe?
  // I have no idea, but I assume not.
  if (!navigator.language.startsWith('en')) {
    return shortMonthFullYearFormatter.format(date);
  }

  return shortMonthFullYearFormatter
    .formatToParts(date)
    .map(({ value }) => value)
    .join('');
}

/**
 * Formats the given date range with short months and full years, e.g. 'Nov 2020
 * - Feb 2021'.
 *
 * @param {Date | string | number} startDateValue A Date object, or any value
 *   accepted by the Date() constructor
 * @param {Date | string | number} endDateValue
 * @returns {string} formatted date range
 */
export function formatRangeWithShortMonthFullYear(
  startDateValue,
  endDateValue,
) {
  const startDate = new Date(startDateValue);
  const endDate = new Date(endDateValue);

  return `${formatDateWithShortMonthFullYear(
    startDate,
  )} – ${formatDateWithShortMonthFullYear(endDate)}`;
}
