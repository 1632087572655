import LoadingSpinner from '@/components/common/LoadingSpinner';
import MetricItem from './MetricItem';
import './Metrics.scss';

const MetricsList = ({ metricsList, isLoading }) => {
  return (
    <div className="Panel Panel-toEdge MetricsList_Wrapper">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        metricsList.map(({ metric, tags }) => {
          const {
            id,
            unit,
            name,
            modifiedDate,
            link,
            summary,
            displayFormula,
          } = metric;
          return (
            <MetricItem
              key={id}
              id={id}
              unit={unit}
              name={name}
              modifiedDate={modifiedDate}
              link={link}
              tags={tags}
              summary={summary}
              displayFormula={displayFormula}
            />
          );
        })
      )}
    </div>
  );
};

export default MetricsList;
