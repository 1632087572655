import { useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import { formatPercent } from '@/helpers';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import useDashboardPath from '@/hooks/useDashboardPath';
import GrossMarginChart from './charts/GrossMarginChart';

const CHART_TITLE = 'Gross Margin';

const GrossMargin = ({ startDate, endDate }) => {
  const [grossMargins, setGrossMargins] = useState({});
  const chartRef = useRef();
  const dashboardPath = useDashboardPath();
  const onQueryStateChange = useCallback(({ data, isLoading }) => {
    setGrossMargins({
      averageGM: data?.averageGM,
      isLoading,
    });
  }, []);

  return (
    <section className="Panel Panel-toEdge">
      <ChartHeader back={dashboardPath} title="Gross Margin">
        <div className="ChartHeader_KPIValue">
          {!grossMargins.isLoading && formatPercent(grossMargins.averageGM)}
        </div>
        <div className="ChartHeader_KPI">
          <br />
          {formatRangeWithShortYear(startDate, endDate)}
        </div>
      </ChartHeader>
      <ChartToolbar>
        <ChartControls
          chartRef={chartRef}
          title={CHART_TITLE}
          metricValue={
            !grossMargins.isLoading ? formatPercent(grossMargins.averageGM) : ''
          }
          label={formatRangeWithShortYear(startDate, endDate)}
          data-testid="gross-margin-chart-export"
        />
      </ChartToolbar>
      <GrossMarginChart
        className="ExpandedView_ChartWrapper"
        onQueryStateChange={onQueryStateChange}
        exportBtn={chartRef}
      />
    </section>
  );
};

function mapStateToProps({ shared }) {
  return {
    startDate: shared.startDate,
    endDate: shared.endDate,
  };
}

export default connect(mapStateToProps)(GrossMargin);
