import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useFocusOnEdit from '@/components/common/Spreadsheet/editors/useFocusOnEdit';
import { getSizeForInput } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';

const VALID_REGEX = /^[0-9-.]*$/;
const VALID_POS_REGEX = /^[0-9.]*$/;

/**
 * A numeric cell editor for use in ag-Grid
 *
 * @example
 *   columnDefs={[
 *    {
 *      ...
 *      cellEditor: NumericEditor,
 *      editable: true
 *    }
 *   ]}
 *
 * @see https://www.ag-grid.com/react-grid/component-cell-editor/
 */
const NumericEditor = forwardRef(
  (
    {
      allowNegative = true,
      allowPositive = true,
      charPress,
      colDef,
      node,
      value,
    },
    ref,
  ) => {
    const isValueAsObject = typeof value === 'object';
    const input = useRef(null);
    const [localValue, setLocalValue] = useState(
      charPress ?? (isValueAsObject ? value?.value : value),
    );
    const size = getSizeForInput(localValue);
    useImperativeHandle(ref, () => ({
      getValue: () => {
        const resValue =
          !allowPositive && localValue > 0
            ? localValue * -1
            : Number(localValue);
        if (isValueAsObject) {
          return {
            ...value,
            value: resValue,
          };
        }
        return resValue;
      },
      isCancelAfterEnd: () => {
        if (isEmptyOrNull(localValue) || Number.isNaN(Number(localValue)))
          return true;
        const currentVal = isValueAsObject ? value?.value : value;
        return currentVal === Number(localValue);
      },
    }));

    useFocusOnEdit(input);

    return (
      <div className="Spreadsheet_CellEditor">
        <input
          ref={input}
          type="text"
          className="Spreadsheet_Input"
          size={size}
          value={!isEmptyOrNull(localValue) ? localValue : ''}
          data-testid={`${node.id}-${colDef.field}-input`}
          onChange={({ target }) => {
            const newValue = target.value;
            if ((allowNegative ? VALID_REGEX : VALID_POS_REGEX).test(newValue))
              setLocalValue(newValue);
          }}
        />
      </div>
    );
  },
);

NumericEditor.propTypes = {
  /** Whether negative values are allowed */
  allowNegative: PropTypes.bool,
  /** Whether positive values are allowed */
  allowPositive: PropTypes.bool,
  /** Character that triggered editing on the cell, if any */
  charPress: PropTypes.string,
  /** The column definition, provided by ag-Grid */
  colDef: PropTypes.objectOf(PropTypes.any).isRequired,
  /** The row context, provided by ag-Grid */
  node: PropTypes.objectOf(PropTypes.any).isRequired,
  /** Value of the cell */
  value: PropTypes.shape({
    value: PropTypes.number,
    type: PropTypes.string,
  }),
};

export default NumericEditor;
