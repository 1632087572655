// @ts-check
import FormLabel from '@/components/common/FormLabel';
import Select from '@/components/common/Select';
import { COUNTRY_OPTIONS } from './constants';

/**
 * Renders the country field with country options.
 *
 * @typedef {{
 *   label?: string;
 *   placeholder?: string;
 * } & import('@/components/common/Select').SelectProps} CountryFieldProps
 * @type {(props: CountryFieldProps) => React.ReactElement}
 */

const CountryField = ({ label, placeholder, ...props }) => {
  return (
    <>
      {label && (
        <FormLabel
          className="CountryField_Label"
          htmlFor="country"
          text={label}
        />
      )}
      <div className="Form_Group">
        <Select
          id="country"
          name="country"
          data-testid="company-country"
          {...props}
        >
          <option key="" value="">
            {placeholder ?? 'Country / Region'}
          </option>
          {COUNTRY_OPTIONS.map((country) => {
            return (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            );
          })}
        </Select>
      </div>
    </>
  );
};

export default CountryField;
