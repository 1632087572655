// @ts-check
import { forwardRef } from 'react';
import { FloatingPortal } from '@floating-ui/react';
import { AnimatePresence, m as motion } from 'framer-motion';
import PropTypes from 'prop-types';

/** @typedef {import('@floating-ui/react-dom').Side} Side */

/** @type {Record<string, Side>} */
export const placements = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
};

/** @type {import('framer-motion').TargetAndTransition} */
const ENTER_ANIM = {
  opacity: 1,
  x: 0,
  y: 0,
};

const INITIAL_OFFSET = 7;

/** @type {import('framer-motion').Transition} */
const SPRING_SETTINGS = {
  type: 'spring',
  mass: 0.5,
  stiffness: 115,
  velocity: 5,
};

/**
 * @private
 * @type {(placement: Side) => number}
 */
function getOffsetForPlacement(placement) {
  return [placements.TOP, placements.LEFT].includes(placement)
    ? INITIAL_OFFSET
    : -INITIAL_OFFSET;
}

/**
 * @typedef {{
 *   children: React.ReactNode;
 *   open: boolean;
 *   placement: import('@floating-ui/react-dom').Placement;
 * } & import('framer-motion').MotionProps &
 *   React.HTMLAttributes<HTMLDivElement>} OverlayProps
 */

/**
 * Base component for rendering a floating overlay, such as a tooltip or
 * popover. Not intended to be used directly.
 *
 * @example
 *   See WithTooltip or WithPopover
 *
 * @type {React.ForwardRefRenderFunction<HTMLDivElement, OverlayProps>}
 */
const OverlayFn = ({ children, open, placement, ...props }, ref) => {
  // Change the animation direction based on actual overlay placement.
  // Actual placement may differ to prevent overflow.
  const overlaySide = /** @type {Side} */ (placement.split('-')[0]);
  const animAxis = [placements.LEFT, placements.RIGHT].includes(overlaySide)
    ? 'x'
    : 'y';
  const exitAnim = {
    opacity: window.Cypress ? 1 : 0,
    [animAxis]: getOffsetForPlacement(overlaySide),
  };

  return (
    <FloatingPortal>
      <AnimatePresence>
        {open && (
          <motion.div
            ref={ref}
            animate={ENTER_ANIM}
            data-placement={overlaySide}
            initial={exitAnim}
            exit={exitAnim}
            transition={SPRING_SETTINGS}
            {...props}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </FloatingPortal>
  );
};

const Overlay = forwardRef(OverlayFn);

Overlay.propTypes = {
  /** Content of the overlay */
  children: PropTypes.node.isRequired,
  /** Whether or not the overlay is visible */
  open: PropTypes.bool.isRequired,
  /** Position of the overlay relative to its trigger */
  placement: PropTypes.any.isRequired,
};

export default Overlay;
