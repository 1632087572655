import { useCallback, useEffect } from 'react';
import { YAxis } from 'react-jsx-highcharts';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import { LABEL_STYLES } from '@/components/Charts/chartDefaults';
import {
  getYAxisConfig,
  getYAxisConfigPercent,
} from '@/components/Charts/helpers';
import { getStackedColumnTooltipMetrics } from '@/components/Charts/tooltips';
import { INVALID_POP_VALUE_TOOLTIP_TEXT } from '@/constants/charts';
import COLORS from '@/constants/colorPalette';
import { formatPercent } from '@/helpers';
import formatMonetary from '@/helpers/formatMonetary';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import metricFormatters from '@/helpers/metricFormatters';
import { isEmptyOrNull } from '@/helpers/validators';
import useTotalMrrQuery from './useTotalMrrQuery';

export const TOTAL_MRR_PERCENTAGE_MAIN_METRIC = 'totalMRRPoP';
export const TOTAL_MRR_MAIN_METRIC = 'endingMrr';
export const TOTAL_MRR_TOGGLE_ID = 'totalMrr-toggle';
export const TOTAL_MRR_TOOLTIP_TEXT = 'Total MRR';

export const TOTAL_MRR_PERCENTAGE_TOOLTIP_TEXT = 'Total MRR %';
export const TOTAL_MRR_PERCENTAGE_TOOLTIP_METRICS = [
  {
    name: 'Total MRR %',
    key: TOTAL_MRR_PERCENTAGE_MAIN_METRIC,
    formatter: metricFormatters.percentLegacy,
    isMainMetric: true,
  },
  {
    name: 'Current Ending MRR',
    key: 'endingMrr',
    formatter: metricFormatters.monetary,
  },
  {
    name: 'Prior Ending MRR',
    key: 'previousMonthTotalMrr',
    formatter: metricFormatters.monetary,
  },
];

const EXPANSION_MRR = 'expansionMrr';
const CONTRACTION_MRR = 'contractionMrr';
const ADJUSTMENTS_TO_MRR = 'adjustmentsToMrr';
const BEGINNING_MRR = 'beginningMrr';
const NEW_MRR = 'newMrr';
const CHURN_MRR = 'churnMrr';
const TOTAL_MRR = 'totalMrr';

const ADV_REVENUE_STREAM_MRR_CATEGORIES = [
  EXPANSION_MRR,
  CONTRACTION_MRR,
  ADJUSTMENTS_TO_MRR,
];
const TOTAL_MRR_CATEGORIES = [
  { valueKey: BEGINNING_MRR, name: 'Beginning MRR' },
  { valueKey: NEW_MRR, name: 'New MRR' },
  { valueKey: EXPANSION_MRR, name: 'Expansion MRR' },
  { valueKey: CONTRACTION_MRR, name: 'Contraction MRR' },
  { valueKey: CHURN_MRR, name: 'Churned MRR' },
  { valueKey: ADJUSTMENTS_TO_MRR, name: 'Adjustment MRR' },
];

const nonPctMetrics = (context) =>
  getStackedColumnTooltipMetrics(context, {
    additional: [
      {
        name: 'Total MRR',
        key: TOTAL_MRR_MAIN_METRIC,
        isMainMetric: true,
      },
    ],
    reverse: false,
  });

export const mapTotalMrrByCategory = (payload) => {
  const data = TOTAL_MRR_CATEGORIES.map((key) => {
    return {
      id: key.valueKey,
      name: key.name,
      ...mapMonthlyData(payload, key.valueKey),
    };
  }).reduce((dataset, row) => {
    if (
      ADV_REVENUE_STREAM_MRR_CATEGORIES.includes(row.id) &&
      row.data.every((monthData) => monthData[row.id] === null)
    ) {
      return dataset;
    }
    return [...dataset, row];
  }, []);

  return { data };
};

const totalMrrColorMap = {
  [EXPANSION_MRR]: COLORS.crayola,
  [CONTRACTION_MRR]: COLORS.yellow,
  [ADJUSTMENTS_TO_MRR]: COLORS.spanishGrey,
  [BEGINNING_MRR]: COLORS.finmarkBlue,
  [NEW_MRR]: COLORS.turf,
  [CHURN_MRR]: COLORS.cinnabar,
  [TOTAL_MRR]: COLORS.raisinBlack,
};

/**
 * Fetches and renders a line chart showing total Mrr
 *
 * @example
 *   <TotalMrrChart exportBtn={exportBtn} />;
 */
function TotalMrrChart({
  className,
  exportBtn,
  onChartCreated,
  onQueryStateChange,
  plotOptions,
  axisStyles,
  tooltipOptions,
  showPercentage,
}) {
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);
  const reducer = useCallback(
    ({ cumulativeTotalMrr, mrrData, totalMrrCPGR }) => {
      const { data: totals } = mapMonthlyData(mrrData, 'totalMrr');
      const { data: categorized } = mapTotalMrrByCategory(mrrData);
      const { data: percentages } = mapMonthlyData(mrrData, 'totalMRRPoP');

      let mainMetric = 'N/A';
      let tooltipText;
      if (!showPercentage) {
        mainMetric = formatMonetary(cumulativeTotalMrr);
      } else if (!isEmptyOrNull(totalMrrCPGR)) {
        mainMetric = formatPercent(totalMrrCPGR);
      } else {
        tooltipText = INVALID_POP_VALUE_TOOLTIP_TEXT;
      }

      return {
        mainMetric,
        tooltipText,
        data: showPercentage ? totals : categorized,
        popData: showPercentage ? percentages : totals,
      };
    },
    [showPercentage],
  );

  const scenarioQueries = useTotalMrrQuery(reducer);

  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );

  useEffect(() => {
    if (scenarioQueries.length) onQueryStateChange?.(scenarioQueries);
  }, [onQueryStateChange, scenarioQueries]);

  const YAXIS_DEFAULTS = showPercentage
    ? getYAxisConfigPercent()
    : getYAxisConfig();

  const yAxisConfigs = {
    ...YAXIS_DEFAULTS,
    labels: {
      ...YAXIS_DEFAULTS.labels,
      style: axisStyles || LABEL_STYLES,
    },
  };
  return (
    <DateChart
      className={className}
      data-testid="totalMRRChart"
      loading={scenarioQueries.every(({ isLoading }) => isLoading)}
      tooltip={
        <ChartTooltip
          metrics={
            showPercentage
              ? TOTAL_MRR_PERCENTAGE_TOOLTIP_METRICS
              : nonPctMetrics
          }
          valueFormatter={
            showPercentage
              ? metricFormatters.percentLegacy
              : metricFormatters.monetary
          }
          valueKey={
            showPercentage
              ? TOTAL_MRR_PERCENTAGE_MAIN_METRIC
              : TOTAL_MRR_MAIN_METRIC
          }
          endDate={endDate}
          timePeriod={timePeriod}
          {...tooltipOptions}
        />
      }
      onChartCreated={onChartCreated}
      plotOptions={plotOptions}
      axisStyles={axisStyles}
      ref={exportBtn}
      startDate={startDate}
      endDate={endDate}
      timePeriod={timePeriod}
    >
      <YAxis {...yAxisConfigs}>
        {!showPercentage &&
          scenarioQueries.map(
            ({ data, scenario }) =>
              data && (
                <DateChart.StackedColumnSeries
                  key={scenario.scenarioId}
                  data={data.data}
                  isComparison={scenario?.scenarioId !== scenarioId}
                  scenario={scenario}
                  colors={totalMrrColorMap}
                />
              ),
          )}
        {scenarioQueries.map(
          ({ data, scenario }, idx) =>
            data && (
              <DateChart.Series
                key={scenario.scenarioId}
                data={data.popData}
                index={idx}
                metric={
                  showPercentage
                    ? TOTAL_MRR_PERCENTAGE_TOOLTIP_TEXT
                    : TOTAL_MRR_TOOLTIP_TEXT
                }
                scenario={scenario}
                showInLegend={false}
                zIndex={100 + idx}
                color={!showPercentage ? totalMrrColorMap[TOTAL_MRR] : null}
                isComparison={idx === 1}
              />
            ),
        )}
      </YAxis>
    </DateChart>
  );
}

export default TotalMrrChart;
