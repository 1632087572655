// @ts-check
import { trimExceeedingTextLimits } from '@/components/Reports/helpers';
import {
  SET_TITLE,
  SET_TEXT,
  SET_CHARTS,
  HYDRATE_STATE,
  TOGGLE_VISIBILITY,
  SET_AI_GENERATED_TEXT,
  SET_FOOTER_DISCLAIMER,
  sectionId,
} from './constants';

/** @typedef {import('@/types/exportableReport').ExportableReportBaseState} ExportableReportBaseState */
/** @typedef {import('@/types/exportableReport').ExportableFinancialReportsState} ExportableReportsState */

/**
 * @type {(
 *   state: ExportableReportBaseState,
 *   action: import('@/types/exportableReport').ExportableReportAction,
 * ) => ExportableReportBaseState}
 */
const exportableReportReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case HYDRATE_STATE: {
      const { content } = action.state['executive-summary'];
      /**
       * A hack to ensure backwards compatibility,
       *
       * @see https://billcom.atlassian.net/browse/BU-11264
       */
      if (typeof content.executiveSummary === 'object') {
        const { executiveSummary } = /**
         * @type {{
         *   executiveSummary: {
         *     userText?: string;
         *     aiText?: string;
         *     disclaimerText?: string;
         *   };
         * }}
         */ (content);
        const userText = executiveSummary.userText ?? '';
        content.aiText = executiveSummary.aiText ?? '';
        content.disclaimerText = executiveSummary.disclaimerText ?? '';
        content.executiveSummary = userText;
      }

      return {
        ...action.state,
      };
    }
    case SET_TITLE: {
      const { id, title } = action.setTitle;
      const sectionCopy = { ...state[id] };
      sectionCopy.content = { ...sectionCopy.content, title };
      return {
        ...state,
        [id]: sectionCopy,
      };
    }
    case SET_TEXT: {
      const { id, userText, aiText, disclaimerText, summaryType } =
        action.setText;
      const sectionCopy = { ...state[id] };
      if (sectionCopy.id !== id) {
        return state;
      }
      sectionCopy.content = {
        ...sectionCopy.content,
        [summaryType]: userText ?? sectionCopy.content[summaryType],
        aiText,
        disclaimerText,
      };
      return {
        ...state,
        [id]: sectionCopy,
      };
    }
    case SET_AI_GENERATED_TEXT: {
      const { id, aiText, disclaimerText } = action.setAIGeneratedText;
      const sectionCopy = { ...state[id] };
      if (sectionCopy.id !== id) {
        return state;
      }
      const { content } = sectionCopy;
      const { executiveSummary } = content;

      const { trimmedAIText, trimmedDisclaimerText, newExecutiveSummary } =
        trimExceeedingTextLimits(
          executiveSummary,
          content?.aiText,
          aiText,
          disclaimerText,
        );

      sectionCopy.content = {
        ...sectionCopy.content,
        aiText: trimmedAIText,
        disclaimerText: trimmedDisclaimerText,
        executiveSummary: newExecutiveSummary,
      };
      return {
        ...state,
        [id]: sectionCopy,
      };
    }
    case TOGGLE_VISIBILITY: {
      const { id, isActive } = action.toggleVisibility;
      const sectionCopy = { ...state[id], active: isActive };
      return {
        ...state,
        [id]: sectionCopy,
      };
    }
    case SET_CHARTS: {
      const { id, charts } = action.setCharts;
      const sectionCopy = { ...state[id] };
      sectionCopy.content = { ...sectionCopy.content, charts };
      return {
        ...state,
        [id]: sectionCopy,
      };
    }
    case SET_FOOTER_DISCLAIMER: {
      const { footerDisclaimer } = action;
      const stateCopy = { ...state };
      stateCopy[sectionId.DISCLAIMER].content.footerDisclaimer =
        footerDisclaimer;
      return stateCopy;
    }
    default:
      return state;
  }
};

export default exportableReportReducer;
