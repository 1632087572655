// @ts-check
import { useCallback, useState } from 'react';
import EssentialPlanImage from '@bill/cashflow.assets/essential-plan';
import TeamsPlanImage from '@bill/cashflow.assets/teams-plan';
import { useMutation } from '@tanstack/react-query';
import Upsell from '@/components/BillOnboarding/Upsell';
import Button from '@/components/common/Button';
import ErrorMessages from '@/components/common/ErrorMessages';
import { isEmptyOrNull } from '@/helpers/validators';
import useBillBaseUrl from '@/hooks/useBillBaseUrl';
import useBillSession from '@/hooks/useBillSession';
import useOnboardForecastingAccess, {
  forecastingActions,
} from '@/hooks/useOnboardForecastingAccess';
import RedirectToExternal from '@/pages/Registration/RedirectToExternal';
import { pendoTrackEvent } from '@/services/analyticsService';
import { setAcceptTnc } from '@/services/onboarding.service';
import { ReactComponent as AddNewChartIcon } from '@/assets/icons/svg/add-new-chart.svg';
import { ReactComponent as CashflowIcon } from '@/assets/icons/svg/cashflow.svg';
import { ReactComponent as ForecastIcon } from '@/assets/icons/svg/forecast.svg';
import { ReactComponent as GroupBudgetIcon } from '@/assets/icons/svg/group-budget.svg';
import { ReactComponent as OnboardingTermsAndConditionImage } from '@/assets/images/planning_terms_and_condition.svg';
import './TermsAndCondition.scss';

const PRICING_PLAN_URL =
  '/neo3/settings/billing-and-subscription/pricing-plans';

/**
 * @type {{
 *   text: string;
 *   icon: React.FC<React.SVGProps<SVGSVGElement>>;
 * }[]}
 */
const TERMS_TEXT = [
  {
    text: 'Get real-time visibility into your cash flow with a direct sync to QuickBooks Online.',
    icon: CashflowIcon,
  },
  {
    text: 'Create budgets to track actual cash flow against your plan.',
    icon: ForecastIcon,
  },
  {
    text: 'Assess business performance and detect variations',
    icon: GroupBudgetIcon,
  },
  {
    text: 'Use our forecasting models to predict your future cash flow',
    icon: AddNewChartIcon,
  },
];

const UPSELL_PLANS_INFO = /** @type {const} */ ({
  [forecastingActions.SHOW_UPGRADE_TO_FORECASTING]: {
    title: 'Upgrade, Sync & Start Planning.',
    description:
      'Great news! Cash Flow Forecasting is available in Teams and Corporate plan. On upgrade, setup sync with Quickbooks Online to predict future cash flow, uncover trends and opportunities, and maximize your business potential.',
    image: EssentialPlanImage,
  },
  [forecastingActions.ASK_ADMIN_FOR_FORECASTING]: {
    title: 'Let your admin know about BILL Cash Flow Forecasting',
    description:
      'Only BILL administrators can set up BILL Cash Flow Forecasting.',
    image: TeamsPlanImage,
  },
});

/**
 * Render's Terms and Condition page for setup planning FPA.
 *
 * @type {(params: {
 *   refetchOnboardingStatus: () => void;
 *   onTncAccepted: (timestamp: number) => void;
 * }) => React.ReactElement}
 */

export default function OnboardingTermsAndCondition({
  refetchOnboardingStatus,
  onTncAccepted,
}) {
  const [errorMessage, setErrorMessage] = useState(null);
  const { mutateAsync: onAcceptTnc, isLoading } = useMutation(setAcceptTnc);
  const { sessionInfo } = useBillSession();
  const isPlanningAvailable =
    sessionInfo?.neoFunctionality?.OrgFeatures?.isPlanningAvailable;
  const onboardForecastingAccess = useOnboardForecastingAccess();

  const onSaveTnc = useCallback(async () => {
    const timestamp = new Date(
      performance.now() + performance.timeOrigin,
    ).getTime();

    try {
      await onAcceptTnc();
      onTncAccepted(timestamp);
      pendoTrackEvent('forecasting_onboarding_tnc_accepted', {
        timestamp,
      });
      refetchOnboardingStatus();
    } catch (error) {
      setErrorMessage(error.response?.data.error);
      pendoTrackEvent('forecasting_onboarding_tnc_error', {
        timestamp,
      });
    }
  }, [onAcceptTnc, refetchOnboardingStatus, onTncAccepted]);

  const billBaseUrl = useBillBaseUrl();
  const handleEssentialsPlanClick = () => {
    RedirectToExternal({ url: `${billBaseUrl}${PRICING_PLAN_URL}` });
  };

  return (
    <div className="OnboardingTermsAndCondition_Wrapper">
      <div
        data-testid="terms-and-condition-onboarding"
        className="OnboardingTermsAndCondition"
      >
        <div className="OnboardingTermsAndCondition_Container">
          <div className="OnboardingTermsAndCondition_Header">
            <ErrorMessages error={errorMessage} />
            <h1 className="OnboardingTermsAndCondition_HeaderTitle">
              Add Cash Flow Forecasting to BILL{' '}
              {isPlanningAvailable && '– for free'}
            </h1>
            <h4 className="OnboardingTermsAndCondition_SubHeading">
              Manage your financial operations in one platform.
            </h4>
            <div className="OnboardingTermsAndCondition_Divider" />
          </div>
          <div className="OnboardingTermsAndCondition_Content">
            <h1 className="OnboardingTermsAndCondition_ContentTitle">
              Confidently plan for tomorrow with Cash Flow Forecasting
            </h1>
            <div className="OnboardingTermsAndCondition_List">
              {TERMS_TEXT.map(({ text, icon: Icon }) => (
                <div
                  className="OnboardingTermsAndCondition_ListItem"
                  key={text}
                >
                  <div>
                    <Icon className="OnboardingTermsAndCondition_Icon" />
                  </div>

                  <p className="OnboardingTermsAndCondition_Text">{text}</p>
                </div>
              ))}
            </div>
            {onboardForecastingAccess ===
              forecastingActions.ADMIN_ACCESS_FORECASTING && (
              <Button
                onClick={onSaveTnc}
                className="OnboardingSetupButton"
                data-testid="terms-and-condition-onboarding-button"
                disabled={isLoading}
              >
                Continue
              </Button>
            )}
          </div>
          <div className="OnboardingTermsAndCondition_ImageContainer">
            <OnboardingTermsAndConditionImage />
          </div>
          {!isEmptyOrNull(onboardForecastingAccess) &&
            onboardForecastingAccess !==
              forecastingActions.ADMIN_ACCESS_FORECASTING && (
              <Upsell
                title={UPSELL_PLANS_INFO[onboardForecastingAccess].title}
                description={
                  UPSELL_PLANS_INFO[onboardForecastingAccess].description
                }
                image={UPSELL_PLANS_INFO[onboardForecastingAccess].image}
                onActionClick={
                  onboardForecastingAccess ===
                    forecastingActions.SHOW_UPGRADE_TO_FORECASTING &&
                  handleEssentialsPlanClick
                }
              />
            )}
        </div>
      </div>
    </div>
  );
}
