// @ts-check
import { FPA_FULL } from '@/constants/productTypes';
import useProductTypes from './useProductTypes';

/**
 * Hook to check if the product is FPNA Product. This is used for filtering
 * product features in the UI that are exposed to the user.
 *
 * @type {() => boolean}
 */
const useIsFullFpnaProduct = () => {
  const productTypes = useProductTypes();
  return productTypes.has(FPA_FULL);
};

export default useIsFullFpnaProduct;
