// @ts-check
import { useState, useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import BillLogo from '@bill/cashflow.assets/bill-logo';
import CheckmarkSmallIcon from '@bill/cashflow.assets/checkmark-small';
import EditRoundIcon from '@bill/cashflow.assets/edit-round';
import Page from '@/components/Reports/ExportableReport/Page';
import useSectionVisibleOnScreen from '@/components/Reports/ExportableReport/Sections/useSectionVisibleOnScreen';
import { SET_TITLE } from '@/components/Reports/ExportableReport/constants';
import FormField from '@/components/common/FormField';
import IconButton from '@/components/common/IconButton';
import useExportableReportContext from '@/contexts/useExportableReportContext';
import { getDomainAndResourceHost } from '@/helpers';
import {
  formatRangeWithShortMonthFullYear,
  formatRangeWithShortYear,
} from '@/helpers/dateFormatter';
import useOneColor from '@/hooks/useOneColor';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import { ReactComponent as FinmarkLogo } from '@/assets/images/finmark-logo-round.svg';
import './CoverPage.scss';

const CoverPageFooter = ({ subDomain, resourceHost }) => {
  const isOneColorEnabled = useOneColor();
  const url = `https://${resourceHost}.finmark.com/${subDomain}/small`;
  return (
    <footer className="CoverPage_Footer">
      {subDomain ? (
        <>
          <p className="CoverPage_FooterText">
            This report has been created by
          </p>
          <div
            className="PartnerLogo"
            style={{
              backgroundImage: `url("${url}.svg"), url("${url}.png")`,
            }}
          />
        </>
      ) : (
        <>
          <p className="CoverPage_FooterText">
            {isOneColorEnabled
              ? 'This report has been created by'
              : 'This report has been generated using'}
          </p>
          {isOneColorEnabled ? (
            <BillLogo className="CoverPage_Logo" />
          ) : (
            <FinmarkLogo className="CoverPage_Logo" />
          )}
        </>
      )}
    </footer>
  );
};

const CoverPageHeader = ({ editMode, setEditMode, id }) => {
  const {
    isEditControlVisible,
    setEditableFields,
    reportState,
    setReportState,
  } = useExportableReportContext();

  const { title } = reportState[id].content;
  const fieldId = `${id}-title-field`;

  useEffect(() => {
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldId]: editMode,
    }));
  }, [setEditableFields, editMode, fieldId]);

  return (
    <>
      {editMode ? (
        <FormField
          type="text"
          id={fieldId}
          value={title}
          className="CoverPage_Input"
          maxLength={50}
          onChange={({ target }) => {
            setReportState({
              type: SET_TITLE,
              setTitle: { id, title: target.value.trimStart() },
            });
          }}
        >
          <IconButton
            label="Save"
            data-testid={`${id}-save-title-button`}
            className="CoverPage_SaveIcon"
            onClick={() => setEditMode(false)}
            Icon={CheckmarkSmallIcon}
          />
        </FormField>
      ) : (
        <div className="CoverPage_HeadingContainer">
          <h1 className="CoverPage_Heading">{title}</h1>
          {isEditControlVisible && (
            <IconButton
              label="Edit Title"
              data-testid={`${id}-edit-title-button`}
              className="CoverPage_EditIcon"
              onClick={() => setEditMode(true)}
              Icon={EditRoundIcon}
            />
          )}
        </div>
      )}
    </>
  );
};

/**
 * Renders Cover Page section of exportable report builder
 *
 * @example
 *   <CoverPage id={section.id} />;
 *
 * @typedef {{
 *   id: import('@/types/exportableReport').CoverSectionId;
 *   documentRef: HTMLDivElement;
 * }} CoverPageProps
 * @type {(props: CoverPageProps) => React.ReactElement}
 */
const CoverPage = ({ id, documentRef }) => {
  const { subDomain, resourceHost } = getDomainAndResourceHost();
  const selectedCompany = useSelector(getSelectedCompany);
  const startDate = useSelector(({ shared }) => shared.startDate);
  const endDate = useSelector(({ shared }) => shared.endDate);
  const [editMode, setEditMode] = useState(false);
  const ref = useSectionVisibleOnScreen(id, { root: documentRef });
  const isOneColorEnabled = useOneColor();

  return (
    <Page ref={ref} id={id} className="ExportableReport_Page-coverPage">
      <header className="CoverPage_Header">
        <CoverPageHeader
          editMode={editMode}
          setEditMode={setEditMode}
          id={id}
        />
        <h2 className="CoverPage_DateRange">
          {isOneColorEnabled
            ? formatRangeWithShortMonthFullYear(startDate, endDate)
            : formatRangeWithShortYear(startDate, endDate)}
        </h2>
        <p className="CoverPage_CompanyName">{selectedCompany?.name}</p>
      </header>
      <CoverPageFooter subDomain={subDomain} resourceHost={resourceHost} />
    </Page>
  );
};

export default CoverPage;
