import { forwardRef, useEffect } from 'react';
import { FloatingFocusManager, useFloating } from '@floating-ui/react';
import { AnimatePresence, m as motion } from 'framer-motion';
import PropTypes from 'prop-types';
import ModalPortal from '@/components/common/ModalPortal';
import { classNames } from '@/helpers';
import CommonErrorBoundary from './CommonErrorBoundary';
import './Modal.scss';

const ANIM_CLOSED = { y: 40 };
const ANIM_TRANSITION = window.Cypress
  ? { duration: 0 }
  : {
      type: 'spring',
      mass: 0.6,
    };
const ANIM_INITIAL = window.Cypress ? { opacity: 1 } : { opacity: 0 };
const ERROR_BOUNDARY_TEXT =
  'An error occured while trying to display this dialog.';

let numOpenModals = 0;

/**
 * @typedef {{
 *   'children': React.ReactNode;
 *   'className'?: string;
 *   'data-testid': string;
 *   'onClose'?: () => void;
 *   'open': boolean;
 * }} ModalProps
 */

/**
 * Renders a modal window on top of the page
 *
 * @example
 *   <Modal
 *     data-testid="foo"
 *     onClose={() => setShowModal(false)}
 *     open={showModal}
 *   >
 *     <ModalContent />
 *   </Modal>;
 *
 * @type {(props: ModalProps) => React.ReactElement}
 */
const Modal = (
  { children, className, 'data-testid': dataTestId, onClose, open },
  ref,
) => {
  const { context, floating } = useFloating();

  useEffect(() => {
    if (open) {
      numOpenModals += 1;
    }
    const bodyClasses = document.body.classList;
    bodyClasses.toggle('Body-hasModal', numOpenModals > 0);
    return () => {
      if (open) numOpenModals -= 1;
      // This could be a nested modal, so don't remove the body class unless
      // we close the last one.
      if (numOpenModals === 0) bodyClasses.remove('Body-hasModal');
    };
  }, [open]);

  return (
    <ModalPortal>
      <AnimatePresence>
        {open && (
          <motion.div
            key="modal"
            ref={ref}
            className={classNames('Modal', className)}
            animate={{ opacity: 1 }}
            initial={ANIM_INITIAL}
            exit={ANIM_INITIAL}
            transition={ANIM_TRANSITION}
            aria-modal="true"
          >
            <FloatingFocusManager context={context}>
              <motion.dialog
                ref={floating}
                open
                className="Modal_Dialog"
                animate={{ y: 0 }}
                initial={ANIM_CLOSED}
                exit={ANIM_CLOSED}
                transition={ANIM_TRANSITION}
                tabIndex="-1"
                data-testid={dataTestId}
                onKeyUp={({ key }) => key === 'Escape' && onClose && onClose()}
              >
                <CommonErrorBoundary
                  text={ERROR_BOUNDARY_TEXT}
                  onClose={onClose}
                >
                  {children}
                </CommonErrorBoundary>
              </motion.dialog>
            </FloatingFocusManager>
          </motion.div>
        )}
      </AnimatePresence>
    </ModalPortal>
  );
};

const ModalWithRef = forwardRef(Modal);

ModalWithRef.propTypes = {
  /** Content for the modal */
  'children': PropTypes.node.isRequired,
  /** Additional class(es) to apply to the modal */
  'className': PropTypes.string,
  /** Unique ID for selecting the modal in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Event handler for when the user presses the Escape key to close the modal */
  'onClose': PropTypes.func,
  /** Whether or not the modal is displayed */
  'open': PropTypes.bool.isRequired,
};

export default ModalWithRef;
