// @ts-check
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import { userPreferencesAction } from '@/actions/auth';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import { READ_ONLY_TITLE_WIDTH } from '@/components/common/Spreadsheet/constants';
import { dropPositions } from '@/components/common/Spreadsheet/useRowDragAndDrop';
import { ERROR_BOUNDARY_TEXT } from '@/constants/tables';
import { classNames } from '@/helpers';
import getSpreadsheetDefaults, {
  defaultExcelExportParams,
  GROUP_DISPLAY_TYPE,
} from './defaults';
import './Spreadsheet.scss';

const isSectionRow = ({ api, data: rowData, node }) => {
  const { groupDisplayType } = api.gridOptionsWrapper.gridOptions;

  return (
    (node.allChildrenCount && groupDisplayType === GROUP_DISPLAY_TYPE) ||
    rowData?.isGroupPlaceholder
  );
};

const EXCEL_EXPORT_PARAMS = {};

/**
 * @typedef {{
 *   location: {
 *     column: string;
 *     row: string;
 *     section: string;
 *   };
 * }} CellAnnotation
 */

/**
 * @template [T=any] Default is `any`
 * @typedef {{
 *   'className'?: string;
 *   'columnDefs': import('ag-grid-community').ColDef<T>[];
 *   'data'?: T[];
 *   'data-testid': string;
 *   'excelExportParams'?: import('ag-grid-community').ExcelExportParams;
 *   'filterParams'?: Object;
 *   'filterValueGetter'?: import('ag-grid-community').ValueGetterFunc<T>;
 *   'loading'?: boolean;
 *   'groupBy'?: import('ag-grid-community').ValueGetterFunc<T>;
 *   'groupRendererParams'?: import('ag-grid-community').GroupCellRendererParams<T>;
 *   'passesZeroFilter'?: (
 *     node: import('ag-grid-community').RowNode<T>,
 *   ) => boolean;
 *   'showFilter'?: boolean;
 *   'hideZeroRowsByDefault'?: boolean;
 *   'isInteractive'?: boolean;
 * } & import('ag-grid-community').GridOptions<T>} SpreadsheetProps<T>
 */

/**
 * @template [T=any] Default is `any`
 * @template [R=unknown] Default is `unknown`
 * @typedef {React.ForwardRefRenderFunction<R, SpreadsheetProps<T>>} Spreadsheet
 */

/**
 * Renders a spreadsheet for editing the given data broken down by each month in
 * the current date range
 *
 * @example
 *   <Spreadsheet columnDefs={colDefs} data={data} data-testid="foo" />;
 *
 * @type {Spreadsheet}
 */
const SpreadsheetFn = (
  {
    className,
    columnDefs,
    data,
    'data-testid': dataTestId,
    defaultColDef,
    filterValueGetter,
    loading,
    groupBy,
    groupRendererParams,
    onSelectionChanged,
    passesZeroFilter,
    rowClassRules,
    filterParams,
    onCellKeyDown,
    showFilter = true,
    onGridReady,
    onRangeSelectionChanged,
    excelExportParams = EXCEL_EXPORT_PARAMS,
    hideZeroRowsByDefault = false,
    isInteractive = true,
    ...props
  },
  ref,
) => {
  const [isRangeSelectionEnabled, setIsRangeSelectionEnabled] = useState(true);
  const apiRef = useRef(null);

  useImperativeHandle(ref, () => apiRef.current);

  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const columnOrder = useSelector(
    ({ auth }) => auth.preferences[`${dataTestId}-columns`],
  );
  const hideZeroValueRows = useSelector(
    ({ auth }) => auth.preferences[dataTestId],
  );

  useEffect(() => {
    const { current } = apiRef;
    if (!current) return;
    current.api?.onFilterChanged();
  }, [hideZeroValueRows]);

  const isExternalFilterPresent = useCallback(
    () => (hideZeroValueRows && !!passesZeroFilter) || hideZeroRowsByDefault,
    [hideZeroValueRows, passesZeroFilter, hideZeroRowsByDefault],
  );

  const gridOptions = useMemo(() => getSpreadsheetDefaults(), []);

  const mergedColDefs = useMemo(() => {
    const defs = [...columnDefs];
    // Order columns according to the user's preference
    if (columnOrder) {
      defs.sort((a, b) => {
        const aIdx = columnOrder.indexOf(a.colId ?? a.field);
        const bIdx = columnOrder.indexOf(b.colId ?? b.field);
        return aIdx >= 0 && bIdx >= 0 ? aIdx - bIdx : 0;
      });
    }

    if (groupBy) {
      defs.push({
        colId: 'group',
        hide: true,
        rowGroup: true,
        valueGetter: groupBy,
      });
    }
    return defs.map((column, index) => ({
      ...column,
      cellRendererParams: {
        ...column.cellRendererParams,
        suppressDoubleClickExpand: !isInteractive
          ? true
          : column.cellRendererParams?.suppressDoubleClickExpand,
      },
      ...(index === 0 &&
        !isInteractive && {
          width: READ_ONLY_TITLE_WIDTH,
          minWidth: READ_ONLY_TITLE_WIDTH,
          maxWidth: READ_ONLY_TITLE_WIDTH,
        }),
      ...(!isInteractive && { autoHeight: true, wrapText: true }),
    }));
  }, [columnDefs, columnOrder, groupBy, isInteractive]);

  const handleColumnMoved = useCallback(
    ({ columnApi }) => {
      const colIds = columnApi
        .getAllGridColumns()
        .filter(({ visible }) => visible)
        .map(({ colId }) => colId);
      dispatch(userPreferencesAction({ [`${dataTestId}-columns`]: colIds }));
    },
    [dataTestId, dispatch],
  );

  const mergedDefaultColDef = useMemo(
    () => ({
      ...gridOptions.defaultColDef,
      ...defaultColDef,
      cellClassRules: {
        ...gridOptions.defaultColDef.cellClassRules,
        ...defaultColDef?.cellClassRules,
        'Spreadsheet_Cell-loading': ({
          colDef,
          context,
          data: rowData,
          node,
        }) => {
          const rowLoadingCells = context.loadingCells[node.id] ?? [];
          return (
            (colDef.type !== 'actions' &&
              rowData?.isUnsaved &&
              rowLoadingCells.length) ||
            rowLoadingCells.includes(colDef.colId ?? colDef.field)
          );
        },
      },
      filterParams: {
        ...gridOptions.defaultColDef.filterParams,
        ...defaultColDef?.filterParams,
        ...filterParams,
      },
      floatingFilter: showFilter,
      floatingFilterComponentParams: {
        ...defaultColDef?.floatingFilterComponentParams,
        showFilter: isInteractive,
      },
      resizable: isInteractive,
    }),
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [defaultColDef, filterParams, showFilter, isInteractive],
  );

  const isRowSelectable = useCallback(
    () => !!onSelectionChanged,
    [onSelectionChanged],
  );

  const rangeSelectionId = useRef(null);

  const handleRangeSelection = useCallback(
    (params) => {
      if (rangeSelectionId.current) {
        clearTimeout(rangeSelectionId.current);
        rangeSelectionId.current = null;
      }

      const { api, finished } = params;
      const [cellRange] = api.getCellRanges();
      if (!cellRange) return;

      // In spite of AG Grid's docs, when range selection is enabled, it
      // starts when the user clicks on a single cell.
      //
      // On macOS, this undocumented range selection behavior can result in
      // two disparate cells appearing to be `focused` when manually
      // controlling grid cell focus.
      const isSingleCellSelected =
        finished &&
        cellRange.columns.length === 1 &&
        cellRange.startRow.rowIndex === cellRange.endRow.rowIndex;

      if (isSingleCellSelected) {
        // We wrap `clearRangeSelection` in a setTimeout to avoid a timing
        // issue when selecting a range. Depending on how quickly a user
        // tries to select a range, it could be cleared prematurely. Here we
        // introduce a small delay that is cancelled if this function gets
        // called again within the timeout period. See the clearTimeout call
        // above
        rangeSelectionId.current = setTimeout(() => {
          api.clearRangeSelection();
          rangeSelectionId.current = null;
        }, 50);
      }
      onRangeSelectionChanged?.(params);
    },
    [onRangeSelectionChanged],
  );

  const mergedRowClassRules = useMemo(
    () => ({
      'Spreadsheet_Row-dropAbove': ({ data: rowData }) =>
        rowData?.dropTarget === dropPositions.ABOVE,
      'Spreadsheet_Row-dropBelow': ({ data: rowData }) =>
        rowData?.dropTarget === dropPositions.BELOW,
      'Spreadsheet_Row-dropWithin': ({ data: rowData }) =>
        rowData?.dropTarget === dropPositions.WITHIN,
      'Spreadsheet_Row-group': ({ node, data: rowData }) =>
        !!node.allChildrenCount || rowData?.childrenIds?.length > 0,
      'Spreadsheet_Row-section': isSectionRow,
      'Spreadsheet_Row-selectable': ({ node }) => node.selectable,
      'Spreadsheet_Row-unsaved': ({ data: rowData }) => rowData?.isUnsaved,
      ...rowClassRules,
    }),
    [rowClassRules],
  );

  const handleGridReady = useCallback(
    (event) => {
      apiRef.current = event;
      onGridReady?.(event);
    },
    [onGridReady],
  );

  return (
    <div
      className={classNames(
        'Spreadsheet',
        !isInteractive && 'Spreadsheet_NonInteractive',
        className,
      )}
      data-testid={dataTestId}
      translate="no"
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CommonErrorBoundary text={ERROR_BOUNDARY_TEXT}>
          <AgGridReact
            {...gridOptions}
            columnDefs={mergedColDefs}
            defaultColDef={mergedDefaultColDef}
            defaultExcelExportParams={{
              ...defaultExcelExportParams,
              ...excelExportParams,
            }}
            doesExternalFilterPass={passesZeroFilter}
            isExternalFilterPresent={isExternalFilterPresent}
            isRowSelectable={isRowSelectable}
            onColumnMoved={handleColumnMoved}
            onSelectionChanged={onSelectionChanged}
            getRowId={
              isInteractive ? ({ data: rowData }) => rowData.id : undefined
            }
            rowClassRules={mergedRowClassRules}
            rowData={data}
            groupRowRendererParams={
              groupRendererParams ?? gridOptions.groupRowRendererParams
            }
            onRangeSelectionChanged={
              isInteractive ? handleRangeSelection : (_) => {}
            }
            onCellEditingStarted={() => setIsRangeSelectionEnabled(false)}
            onCellEditingStopped={() => setIsRangeSelectionEnabled(true)}
            enableRangeSelection={isRangeSelectionEnabled}
            groupDefaultExpanded={!isInteractive ? -1 : 1}
            onCellKeyDown={(event) => {
              gridOptions.onCellKeyDown?.(event);
              onCellKeyDown?.(event);
            }}
            onGridReady={handleGridReady}
            {...props}
          />
        </CommonErrorBoundary>
      )}
    </div>
  );
};

const Spreadsheet = forwardRef(SpreadsheetFn);
Spreadsheet.propTypes = {
  /**
   * Columns to include in addition to the selected date range. MUST BE MEMOIZED
   *
   * @see https://www.ag-grid.com/react-grid/column-definitions/
   */
  'columnDefs': PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Additional class(es) to apply to the table wrapper */
  'className': PropTypes.string,
  /** Data to populate the table */
  'data': PropTypes.arrayOf(PropTypes.object),
  /** Unique ID for selecting the spreadsheet in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /**
   * Properties to apply to all columns
   *
   * @see https://www.ag-grid.com/react-data-grid/column-definitions/#default-column-definitions
   */
  'defaultColDef': PropTypes.objectOf(PropTypes.any),
  /** Additional properties to pass to the filter MUST BE MEMOIZED */
  'filterParams': PropTypes.objectOf(PropTypes.any),
  /**
   * Optional function for when a different value must be passed to the filter
   * than is displayed in the cell MUST BE MEMOIZED
   *
   * @param {ValueGetterParams} params Context for the cell
   * @returns {any} Value to pass to to filter
   */
  'filterValueGetter': PropTypes.func,
  /**
   * Optional function which returns a value for grouping rows into collapsible
   * sections
   *
   * @param {ValueGetterParams} params Context for the row
   * @see https://www.ag-grid.com/react-data-grid/value-getters/
   */
  'groupBy': PropTypes.func,
  /** Additional properties to pass to the group renderer */
  'groupRendererParams': PropTypes.any,
  /** Whether or not a loading indicator should be displayed */
  'loading': PropTypes.bool,
  /**
   * Event handler for when the user selects a row
   *
   * @param {SelectionChangedEvent} event
   */
  'onSelectionChanged': PropTypes.func,
  /**
   * Determines if a row should be shown when "Hide Zero Rows" is enabled
   *
   * @param {RowNode} rowNode ag-Grid row object
   * @returns {boolean} TRUE if the row should be visible
   */
  'passesZeroFilter': PropTypes.func,
  /**
   * Functions for applying additional classes to each row
   *
   * @see https://www.ag-grid.com/react-data-grid/row-styles/#row-class-rules
   */
  'rowClassRules': PropTypes.objectOf(PropTypes.func),
  /** Whether or not the filter row should be displayed */
  'showFilter': PropTypes.bool,
  /** An event handler for when a change to a range selection has occurred */
  'onRangeSelectionChanged': PropTypes.func,
  /** Additional properties used when exporting to Excel */
  'excelExportParams': PropTypes.object,
  /** Whether bypass the external filter and hide zero rows by default */
  'hideZeroRowsByDefault': PropTypes.bool,
  /**
   * Additional property used to enable/disable all interactive elements of
   * spreadsheet
   */
  'isInteractive': PropTypes.bool,
  /**
   * callback fires when a key is pressed down on a cell. Use the event argument
   * to scope your callback to run at the appropriate time, as this callback
   * fires for every key press on the grid
   *
   * @see https://www.ag-grid.com/react-data-grid/keyboard-navigation/?#keyboard-events
   */
  'onCellKeyDown': PropTypes.func,
};

export default Spreadsheet;
