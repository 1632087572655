// @ts-check
import { useMemo, createContext } from 'react';
import GlobalTopNav from '@/components/Remote/GlobalTopNav/GlobalTopNav';
import isBillDomain from '@/helpers/isBillDomain';
import useBillBaseUrl from '@/hooks/useBillBaseUrl';
import useCallbackRef from '@/hooks/useCallbackRef';
import useElementSize from '@/hooks/useElementSize';

export const GlobalTopNavContext = createContext({ margin: 0 });

/** @type {(props: { children: React.ReactNode }) => React.ReactElement} */
const GlobalTopNavProvider = ({ children }) => {
  /** @type {import('@/hooks/useCallbackRef').CallbackRef<HTMLDivElement>} */
  const [globalTopNav, setGlobalTopNav] = useCallbackRef();

  const globalTopNavObserver = useElementSize({ current: globalTopNav });
  const billBaseUrl = useBillBaseUrl();

  const value = useMemo(
    () => ({
      margin: globalTopNavObserver.contentRect?.height ?? 0,
    }),
    [globalTopNavObserver],
  );
  return (
    <>
      {isBillDomain() && billBaseUrl && (
        <GlobalTopNav billBaseUrl={billBaseUrl} ref={setGlobalTopNav} />
      )}
      <GlobalTopNavContext.Provider value={value}>
        {children}
      </GlobalTopNavContext.Provider>
    </>
  );
};

export default GlobalTopNavProvider;
