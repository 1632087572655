import {
  useMemo,
  forwardRef,
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import DataMappingNavIcon from '@bill/cashflow.assets/data-mapping-nav';
import ResetIcon from '@bill/cashflow.assets/reset';
import RevenueEmptyIcon from '@bill/cashflow.assets/revenue-empty';
import WarningIcon from '@bill/cashflow.assets/warning';
import { useMutation } from '@tanstack/react-query';
import { subscribeToFetchRevenueDealMappingAction } from '@/actions/revenue';
import ContextMenuRenderer from '@/components/Revenue/DataMapping/ContextMenuRenderer';
import { getRevenueStream } from '@/components/Revenue/DataMapping/helpers';
import Button from '@/components/common/Button';
import EmptyData from '@/components/common/EmptyData';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import PlusButton from '@/components/common/PlusButton';
import Spreadsheet from '@/components/common/Spreadsheet';
import ColumnToggle from '@/components/common/Spreadsheet/ColumnToggle';
import SpreadsheetLegend from '@/components/common/Spreadsheet/SpreadsheetLegend';
import SpreadsheetLegendContent from '@/components/common/Spreadsheet/SpreadsheetLegendContent';
import SpreadsheetStatusBar from '@/components/common/Spreadsheet/SpreadsheetStatusBar';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import WithEditing from '@/components/common/Spreadsheet/WithEditing';
import {
  HEADER_HEIGHT,
  FILTER_ROW_HEIGHT,
  gridRowModels,
  EMPTY_CELL_VALUE,
} from '@/components/common/Spreadsheet/constants';
import NumericEditor from '@/components/common/Spreadsheet/editors/NumericEditor';
import SelectEditor from '@/components/common/Spreadsheet/editors/SelectEditor';
import useUpdateQueue from '@/components/common/Spreadsheet/useUpdateQueue';
import { iconTypes } from '@/constants/actuals';
import {
  columnNames,
  HELP_FINMARK_BASE_URL,
  REVENUE_METRICS,
} from '@/constants/dataMapping';
import { assert, filterObject, toMacroCase } from '@/helpers';
import { formatMonthDayYear, getISODate } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import { isEmptyOrNull } from '@/helpers/validators';
import useColumnHidden from '@/hooks/useColumnHidden';
import useElementSize from '@/hooks/useElementSize';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import useWsSubscription from '@/hooks/useWsSubscription';
import {
  updateBulkRevenueIntegrationEntries,
  overwriteRevenueIntegrationEntry,
} from '@/services/revenueService';
import useSpreadSheetRangeSelection from './useSpreadsheetRangeSelection';
import './DataMappingGrid.scss';

/** @typedef {import('ag-grid-community').RowEvent<RevenueIntegrationEntry>} RowEvent */
/** @typedef {import('ag-grid-community').CellValueChangedEvent<RevenueIntegrationEntry>} CellValueChangedEvent */
/** @typedef {import('ag-grid-community').CellClassParams<RevenueIntegrationEntry>} CellClassParams */
const TOOLBAR_HEIGHT = 88;
const SPREADSHEET_ID = 'dataMapping';
const BORDER_WIDTH = 1;
const DISTANCE_FROM_TOP = HEADER_HEIGHT + TOOLBAR_HEIGHT + BORDER_WIDTH;
const REVENUE_METRIC_KEY = 'revenueMetric';
const CUSTOMER_METRIC_KEY = 'customerMetric';
const STREAM_TYPE_REVENUE_ONLY = 'REVENUE_ONLY';

const {
  DATE,
  CUSTOMER_NAME,
  AMOUNT,
  DEAL_NAME,
  REVENUE_DRIVER,
  PRODUCT_PRICING_PLAN,
  PRICING_PLAN,
  REVENUE_STREAM,
  CUSTOMER_METRIC,
  REVENUE_METRIC,
} = columnNames;

const revenueMappingContent = {
  [iconTypes.EXTERNAL_SOURCE_GENERATED]:
    'These values have been pulled from an external integrated platform.',
  [iconTypes.SYSTEM_GENERATED]:
    'These values have been created by Finmark based on certain assumptions.',
  [iconTypes.USER_ENTERED]: 'These values have been entered by the user.',
};

const LOCKED_COLUMNS = [
  DATE,
  CUSTOMER_NAME,
  AMOUNT,
  DEAL_NAME,
  REVENUE_DRIVER,
  PRODUCT_PRICING_PLAN,
  REVENUE_STREAM,
  PRICING_PLAN,
  REVENUE_METRIC,
  CUSTOMER_METRIC,
];

/** @typedef {(typeof LOCKED_COLUMNS)[number]} LockedColumn */

/**
 * @param {any} fieldName - The field name check
 * @returns {fieldName is LockedColumn}
 */
const isLockedColumn = (fieldName) => {
  return LOCKED_COLUMNS.includes(fieldName);
};

// TODO: We need to alter the BE error response to provide the field name like we do with employees
const PRICING_PLAN_ERROR =
  'Product pricing plan does not belong to this revenue stream';

const PROMPT_UNSAVED_COLUMNS = [REVENUE_STREAM, PRICING_PLAN];

/** @typedef {(typeof PROMPT_UNSAVED_COLUMNS)[number]} PromptUnsavedColumn */

const EDITABLE_METRIC_COLUMNS = /** @type {const} */ ([
  columnNames.REVENUE_METRIC,
  columnNames.CUSTOMER_METRIC,
]);

/** @typedef {(typeof EDITABLE_METRIC_COLUMNS)[number]} EditableMetricColumn */
/**
 * @param {any} fieldName - The field name to check
 * @returns {fieldName is EditableMetricColumn}
 */
const isEditableMetricColumn = (fieldName) => {
  return EDITABLE_METRIC_COLUMNS.includes(fieldName);
};

/**
 * @param {any} fieldName - The field name check
 * @returns {fieldName is PromptUnsavedColumn}
 */
const isUnsavedColumn = (fieldName) => {
  return PROMPT_UNSAVED_COLUMNS.includes(fieldName);
};

/** @typedef {import('@/services/revenueService').RevenueIntegrationEntry} RevenueIntegrationEntry */

/** @typedef {import('@/services/revenueService').RevenueIntegrationField} RevenueIntegrationField */
/** @typedef {import('@/services/revenueService').RevenueIntegrationOverRide} RevenueIntegrationOverRide */
/** @typedef {import('@/services/revenueService').BulkPayloadObject} BulkPayloadObject */

/**
 * @type {(
 *   data: RevenueIntegrationEntry,
 *   fieldName: string,
 * ) => RevenueIntegrationField}
 */
const findFieldByName = (data, fieldName) => {
  return data.fields.find((field) => field.name === fieldName);
};

/** @type {(metricType: string, value: string) => string} */
const getSelectedMetric = (metricType, value) => {
  /** @type {import('@/constants/dataMapping').MetricType} */
  const selectedMetric = Object.values(REVENUE_METRICS)
    .flatMap((metric) => metric[metricType])
    .find((metric) => metric.id === value);
  return selectedMetric?.name;
};

const RevenueStreamDefaultOption = () => (
  <option value="" disabled>
    Select Revenue Stream
  </option>
);

const PricingPlanDefaultOption = () => (
  <option value="" disabled>
    Select Pricing Plan
  </option>
);

const RevenueMetricDefaultOption = () => (
  <option value="" disabled>
    Select Revenue Metric
  </option>
);

const CustomerMetricDefaultOption = () => (
  <option value="" disabled>
    Select Customer Metric
  </option>
);

/**
 * @template [T=unknown] Default is `unknown`
 * @typedef {import('@/services/revenueService').OverwriteRevenueDealEntryRequest<T>} OverwriteRevenueDealEntryRequest
 */

/**
 * @type {(
 *   data: RevenueIntegrationEntry,
 *   revenueStreams: import('@/services/revenueService').RevenueStreamsWithPricingPlan[],
 * ) =>
 *   | import('@/services/revenueService').RevenueStreamsWithPricingPlan
 *   | undefined}
 */
function getMatchingRevenueStream(data, revenueStreams) {
  const revenueStreamField = findFieldByName(data, REVENUE_STREAM);
  return revenueStreams.find(
    (stream) => stream.streamId === revenueStreamField.id,
  );
}

/**
 * @type {(
 *   event:
 *     | import('ag-grid-community').NewValueParams<RevenueIntegrationEntry>
 *     | import('ag-grid-community').EditableCallbackParams<RevenueIntegrationEntry>,
 * ) => boolean}
 */
const isPasteEvent = ({ context }) => {
  return context.isPasting;
};

/** @type {(event: CellClassParams) => boolean} */
const cellHasError = ({ context, node, colDef }) => {
  return !!context.errors[node.id]?.[colDef.field];
};

/**
 * @type {(
 *   event: import('ag-grid-community').ValueSetterParams<RevenueIntegrationEntry>,
 * ) => boolean}
 */
const defaultValueSetter = ({ data, newValue, colDef }) => {
  const column = findFieldByName(data, colDef.field);
  /* eslint-disable-next-line no-param-reassign -- AG Grid updates to column values must mutate parameters */
  column.id = null;
  column.value = typeof newValue === 'object' ? newValue.value : newValue;
  return true;
};

/** @type {() => React.ReactElement} */
const RevenueMappingLegend = () => (
  <SpreadsheetLegend>
    <SpreadsheetLegendContent
      content={revenueMappingContent}
      entityText="Values"
    />
  </SpreadsheetLegend>
);

/**
 * @typedef {{
 *   onAddRules: () => void;
 *   unmappedEntriesCount: number;
 * }} DataMappingGridLegendProps
 */

/** @type {(props: DataMappingGridLegendProps) => React.ReactElement} */
const DataMappingGridLegend = ({ onAddRules, unmappedEntriesCount }) => {
  return (
    <>
      {unmappedEntriesCount > 0 && (
        <>
          <WarningIcon className="WarningIcon" />
          <p className="DataMappingGrid_WarningMessage">
            Missing information that could cause some data to be excluded from
            revenue. Click{' '}
            <a
              className="link"
              href={`${HELP_FINMARK_BASE_URL}/en/articles/7897681-mapping-data-from-your-integrated-revenue-platform`}
            >
              here
            </a>{' '}
            to learn more
          </p>
        </>
      )}
      <RevenueMappingLegend />
      <div className="DataMappingGrid_LegendWrapper">
        <Button
          className="DataMappingManage_Button"
          data-testid="data-mapping-manage-button"
          onClick={onAddRules}
        >
          <DataMappingNavIcon className="DataMappingIcon" aria-hidden="true" />
          Manage
        </Button>
      </div>
    </>
  );
};

/**
 * @type
 *   {React.ForwardRefRenderFunction<import('./types').DataMappingSpreadsheet,
 *   import('@/components/common/Spreadsheet/index').SpreadsheetProps<RevenueIntegrationEntry>>}
 */
const GridRenderFn = (props, ref) => <Spreadsheet {...props} ref={ref} />;
const Grid = forwardRef(GridRenderFn);

/**
 * @typedef {{
 *   colDefs: import('./types').DataMappingColumnDefs;
 *   datasource: import('ag-grid-community').IServerSideDatasource;
 *   gridApi: GridSpreadsheet<RevenueIntegrationEntry>;
 *   isLoading: boolean;
 *   revenueStreams: import('@/services/revenueService').RevenueStreamsWithPricingPlan[];
 *   onAddRecord: () => void;
 *   onAddRules: () => void;
 *   onEditIntegrationRecord: (
 *     integrationRecord: import('@/services/revenueService').RevenueIntegrationEntry,
 *   ) => void;
 *   onDeleteRecord: (param: string) => void;
 *   unmappedEntriesCount: number;
 *   cacheBlockSize: import('ag-grid-community').GridOptions['cacheBlockSize'];
 * }} DataMappingGridProps
 */

/**
 * The data mapping grid
 *
 * @type
 *   {React.ForwardRefRenderFunction<import('./types').DataMappingSpreadsheet,
 *   DataMappingGridProps>}
 */
const DataMappingGridFn = (
  {
    colDefs,
    datasource,
    cacheBlockSize,
    isLoading,
    gridApi,
    revenueStreams,
    onAddRecord,
    onAddRules,
    onEditIntegrationRecord,
    onDeleteRecord,
    unmappedEntriesCount,
  },
  ref,
) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const [scenarioId] = useSelectedScenarioIds();
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  /**
   * @type {ReturnType<
   *   typeof useState<
   *     import('@/services/revenueService').UpdateBulkRevenueIntegrationFieldErrors
   *   >
   * >}
   */
  const [errors, setErrors] = useState({});
  const [cellCount, setCellCount] = useState(0);
  const [cellSum, setCellSum] = useState(0);

  /**
   * @type {ReturnType<
   *   typeof useState<
   *     import('@/components/Revenue/DataMapping/useSpreadsheetRangeSelection').Cell[]
   *   >
   * >}
   */
  const [selectedCells, setSelectedCells] = useState([]);

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const container = useRef();

  const gridApiAdapted = useMemo(() => ({ current: gridApi }), [gridApi]);
  const isColumnHidden = useColumnHidden(SPREADSHEET_ID);

  const hasWritePermission = useNonDashboardWritePermission();
  useEffect(() => {
    if (gridApi?.api && gridApi.columnApi) {
      gridApi.api.setFilterModel(null);
      gridApi.columnApi.resetColumnState();
    }
  }, [gridApi, scenarioId]);

  const revenueStreamOptions = useMemo(
    () =>
      revenueStreams.map(({ streamId: id, streamName: name }) => ({
        id,
        name,
      })),
    [revenueStreams],
  );

  const notEditabeColumns = useMemo(() => {
    return colDefs
      .filter((colDef) => !colDef.editable)
      .map(({ field }) => field);
  }, [colDefs]);

  const handleRangeSelection = useSpreadSheetRangeSelection({
    setSelectedCells,
    setCellCount,
    setCellSum,
    excludedColumnsIds: notEditabeColumns,
  });

  /**
   * @type {(data: RevenueIntegrationEntry) =>
   *   | {
   *       revenueMetric?: import('@/constants/dataMapping').MetricType[];
   *       customerMetric?: import('@/constants/dataMapping').MetricType[];
   *     }
   *   | undefined}
   */
  const getMetrics = useCallback(
    (data) => {
      const { id } = findFieldByName(data, REVENUE_STREAM);
      const { streamType } = getRevenueStream(Number(id), revenueStreams) ?? {};
      return REVENUE_METRICS[streamType];
    },
    [revenueStreams],
  );

  /**
   * @type {(
   *   payload: OverwriteRevenueDealEntryRequest,
   *   event: import('ag-grid-community').NewValueParams<RevenueIntegrationEntry>,
   * ) => Promise<void>} HandleCellValueChanged<T>
   */
  const handleCellValueChanged = useCallback(
    async (payload, event) => {
      const { data, context, node, colDef, api } = event;
      const { id } = data;
      let errorMessage = '';
      try {
        const response = await overwriteRevenueIntegrationEntry({
          scenarioId,
          revenueDealEntryId: id,
          payload,
        });
        if (response.data.error) {
          errorMessage = response.data.error.errorMessage;
        }
      } catch (e) {
        errorMessage = e.response?.data?.error?.errorMessage;
      }
      if (errorMessage) {
        // TODO: We need to alter the BE error response to provide the field name like we do with employees
        const isPlanError = errorMessage === PRICING_PLAN_ERROR;
        const key = isPlanError ? PRICING_PLAN : colDef.field;
        setErrors((current) => ({ ...current, [id]: { [key]: errorMessage } }));
        node.setData({ ...node.data, error: errorMessage });
      }
      setHasUnsavedData(false);
      context.loadingCells = {};
      api.refreshCells({ rowNodes: [node] });
    },
    [scenarioId],
  );

  /**
   * @type {(
   *   event: import('ag-grid-community').NewValueParams<RevenueIntegrationEntry>,
   *   additionalPayload?: { [key: string]: string },
   * ) => void}
   */
  const handleRevStreamPricingPlanChanged = useCallback(
    async (event, additionalPayload) => {
      const { data } = event;
      const revenueStream = findFieldByName(data, REVENUE_STREAM);
      const pricingPlan = findFieldByName(data, PRICING_PLAN);

      /**
       * @type {OverwriteRevenueDealEntryRequest<{
       *   [REVENUE_STREAM]: string;
       *   [PRICING_PLAN]: string;
       * }>}
       */
      const payload = {
        ...additionalPayload,
        [REVENUE_STREAM]: String(revenueStream.id),
        [PRICING_PLAN]: pricingPlan.id ? String(pricingPlan.id) : null,
      };
      handleCellValueChanged(payload, event);
    },
    [handleCellValueChanged],
  );

  /**
   * @type {(
   *   queue: BulkPayloadObject[],
   *   event: CellValueChangedEvent,
   * ) => BulkPayloadObject[]}
   */
  const addChangeToQueue = useCallback((queue, { data, column, columnApi }) => {
    const dataField = data.fields.find(
      (field) => column.getColId() === field.name,
    );
    const fieldValue =
      dataField.id ||
      (isEditableMetricColumn(dataField.name) && !isEmptyOrNull(dataField.value)
        ? toMacroCase(dataField.value)
        : dataField.value);
    /** @type {BulkPayloadObject} */
    const payload = {
      [data.id]: {
        [dataField.name]: fieldValue,
      },
    };
    let updatedQueue = queue;

    if (column.getColId() === REVENUE_STREAM) {
      updatedQueue = addChangeToQueue(updatedQueue, {
        data,
        column: columnApi.getColumn(PRICING_PLAN),
      });
    }

    const rowIndex = updatedQueue.findIndex((row) => {
      const id = Object.keys(row)[0];
      return id === data.id;
    });
    if (rowIndex < 0) {
      return [...updatedQueue, payload];
    }

    const finalQueue = [...updatedQueue];
    finalQueue[rowIndex] = {
      [data.id]: {
        ...finalQueue[rowIndex][data.id],
        ...payload[data.id],
      },
    };
    return finalQueue;
  }, []);

  const { mutate: updateEntries } = useMutation(
    updateBulkRevenueIntegrationEntries,
    {
      onSuccess: (_, variables) => {
        variables.refreshCells();
      },
      /**
       * @type {(
       *   err: import('axios').AxiosError<import('@/types/api').ApiResponse>,
       *   variables: { refreshCells: () => void; stopEditing: () => void },
       * ) => void}
       */
      onError: (err, variables) => {
        variables.stopEditing();
        const fieldErrors = err.response.data.data;
        setErrors((current) => {
          // The user can continue editing while a row is invalid, so we need to
          // persist any errors that were not resolved in the update
          const rowIds = Object.keys(variables.data);

          /** @type {import('@/services/revenueService').UpdateBulkRevenueIntegrationFieldErrors} */
          const unresolved = filterObject(
            current,
            ([rowId]) => !rowIds.includes(rowId),
          );

          return {
            ...unresolved,
            ...fieldErrors,
          };
        });
        variables.refreshCells();
      },
    },
  );
  /** @type {(queue: BulkPayloadObject[], event: RowEvent) => Promise<void>} */
  const updateBulkRevenueMappings = useCallback(
    (queue, { api, context }) => {
      const queueObject = queue.reduce(
        (prevValue, object) => ({ ...prevValue, ...object }),
        {},
      );

      const rowIds = Object.keys(queueObject);
      // Hide the loading indicators
      context.loadingCells = filterObject(
        context.loadingCells,
        ([rowId]) => !rowIds.includes(rowId),
      );

      const rowNodes = rowIds.reduce((rows, id) => {
        const row = api.getRowNode(id);
        if (!row) return rows;
        return [...rows, row];
      }, /** @type {import('ag-grid-community').RowNode<RevenueIntegrationEntry>[]} */ ([]));

      updateEntries({
        scenarioId,
        data: queueObject,
        refreshCells: () => api.refreshCells({ rowNodes }),
        stopEditing: () => api.stopEditing(),
      });
    },
    [scenarioId, updateEntries],
  );

  /** @type {(event: CellValueChangedEvent) => boolean} */
  const handleResetButton = () => {
    const editableCells = selectedCells.filter((cell) => cell.colDef.editable);
    const rowsToReset = editableCells.reduce((rows, cell) => {
      const { data, colDef } = cell;
      const currentRow = rows[data.id] ?? {};
      rows[data.id] = {
        ...currentRow,
        [colDef.field]: null,
      };

      return rows;
    }, /** @type {BulkPayloadObject} */ ({}));

    const { context, api } = editableCells[0];
    const queue = /** @type {BulkPayloadObject[]} */ Object.keys(
      rowsToReset,
    ).map((key) => {
      return { [key]: rowsToReset[key] };
    });
    updateBulkRevenueMappings(queue, { context, api });

    setSelectedCells([]);
    setCellCount(0);
    setCellSum(0);
  };

  /**
   * @type {(
   *   event: import('ag-grid-community').CellValueChangedEvent<RevenueIntegrationEntry>,
   * ) => boolean}
   */
  const handleMappingChange = useUpdateQueue(
    addChangeToQueue,
    updateBulkRevenueMappings,
  );

  const columnDefs = useMemo(() => {
    /** @type {import('ag-grid-community').ColDef<RevenueIntegrationEntry>} */
    const actionMenu = {
      colId: 'actions',
      type: 'actions',
      cellRendererSelector: ({ data }) => {
        if (!data.external) return null;
        return {
          component: ContextMenuRenderer,
          params: {
            onEditIntegrationRecord,
            onDeleteRecord,
          },
        };
      },
      lockVisible: true,
    };

    /** @type {import('./types').DataMappingColumnDefs} */
    const cols = colDefs.map((colDef) => {
      const isLocked = isLockedColumn(colDef.field);
      const defaults = {
        ...colDef,
        cellClassRules: {
          'Spreadsheet_IconCell': (/** @type {CellClassParams} */ event) =>
            !cellHasError(event),
          'Spreadsheet_IconCell-external': (
            /** @type {CellClassParams} */ { data, ...event },
          ) => {
            const field = findFieldByName(data, colDef.field);
            return (
              !cellHasError(event) &&
              field.revenueType === iconTypes.EXTERNAL_SOURCE_GENERATED
            );
          },
          'Spreadsheet_IconCell-system': (
            /** @type {CellClassParams} */ { data, ...event },
          ) => {
            const field = findFieldByName(data, colDef.field);
            return (
              !cellHasError(event) &&
              field.revenueType === iconTypes.SYSTEM_GENERATED
            );
          },
          'Spreadsheet_IconCell-user': (
            /** @type {CellClassParams} */ { data, ...event },
          ) => {
            const field = findFieldByName(data, colDef.field);
            return (
              !cellHasError(event) &&
              field.revenueType === iconTypes.USER_ENTERED
            );
          },
        },
        lockVisible: isLocked,
        initialHide: isLocked ? false : isColumnHidden(colDef.field),
        valueSetter: defaultValueSetter,
        onCellValueChanged: async (
          /** @type {CellValueChangedEvent} */ event,
        ) => {
          if (isPasteEvent(event)) return;
          const { newValue } = event;
          const payload = { [colDef.field]: newValue };
          handleCellValueChanged(payload, event);
        },
      };
      switch (colDef.field) {
        case DATE:
          return {
            ...defaults,
            valueSetter: (event) =>
              defaultValueSetter({
                ...event,
                newValue: getISODate(event.newValue),
              }),
            valueFormatter: (
              /**
               * @type {import('ag-grid-community').ValueFormatterParams<
               *   import('@/services/revenueService').RevenueIntegrationEntry,
               *   string
               * >}
               */ { value },
            ) => formatMonthDayYear(value),
          };
        case AMOUNT:
          return {
            ...defaults,
            cellEditor: NumericEditor,
            valueFormatter: (
              /**
               * @type {import('ag-grid-community').ValueFormatterParams<
               *   import('@/services/revenueService').RevenueIntegrationEntry,
               *   string
               * >}
               */ { value },
            ) => formatMonetary(value),
          };
        case REVENUE_STREAM:
          return {
            ...defaults,
            cellEditor: SelectEditor,
            cellEditorParams: {
              id: 'revenue-stream-select-editor',
              DefaultOption: RevenueStreamDefaultOption,
              options: revenueStreamOptions,
            },
            valueGetter: ({ data }) => {
              const revenueStream = findFieldByName(data, REVENUE_STREAM);
              return revenueStream.id;
            },
            valueFormatter: ({ value }) => {
              if (!value) return EMPTY_CELL_VALUE;
              const stream = revenueStreams.find(
                ({ streamId }) => streamId === value,
              );
              return stream?.streamName;
            },
            valueSetter: (event) => {
              const { data, newValue, oldValue } = event;
              const streamId = Number(newValue);
              const isPastedName = Number.isNaN(streamId);

              const revenueStream = revenueStreams.find((stream) =>
                isPastedName
                  ? stream.streamName.toLowerCase() === newValue.toLowerCase()
                  : stream.streamId === streamId,
              );
              setErrors((previousErrors) => {
                if (revenueStream && previousErrors[data.id]) {
                  delete previousErrors[data.id][REVENUE_STREAM];
                  return previousErrors;
                }

                return {
                  ...previousErrors,
                  [data.id]: {
                    ...previousErrors[data.id],
                    [REVENUE_STREAM]: 'Revenue stream name is invalid',
                  },
                };
              });
              const updatedFields = data.fields.map((field) => {
                if (field.name === REVENUE_STREAM) {
                  return {
                    ...field,
                    value: revenueStream?.streamName ?? newValue,
                    id: revenueStream?.streamId ?? undefined,
                  };
                }
                if (field.name === PRICING_PLAN) {
                  return {
                    ...field,
                    value: null,
                    id: null,
                  };
                }
                return field;
              });
              /* eslint-disable-next-line no-param-reassign -- predates description requirement */
              data.fields = updatedFields;
              return !!revenueStream && (oldValue !== newValue || isPastedName);
            },
            onCellValueChanged: (event) => {
              const { context, data, api, node, newValue } = event;
              const streamId = Number(newValue);
              const isPastedName = Number.isNaN(streamId);
              const { pricingPlans = [], streamType } = revenueStreams.find(
                (stream) =>
                  isPastedName
                    ? stream.streamName.toLowerCase() === newValue.toLowerCase()
                    : stream.streamId === streamId,
              );
              if (pricingPlans.length === 1) {
                const pricingPlan = findFieldByName(data, PRICING_PLAN);
                pricingPlan.value = pricingPlans[0].planName;
                pricingPlan.id = pricingPlans[0].planId;
              } else if (pricingPlans.length) {
                api.startEditingCell({
                  rowIndex: node.rowIndex,
                  colKey: PRICING_PLAN,
                });
                setTimeout(() => {
                  context.loadingCells = {};
                  api.refreshCells({ rowNodes: [node] });
                }, 0);
                return;
              }
              if (streamType === STREAM_TYPE_REVENUE_ONLY) {
                const pricingPlan = findFieldByName(data, PRICING_PLAN);
                pricingPlan.value = null;
                pricingPlan.id = null;
                const customerMetric = findFieldByName(data, CUSTOMER_METRIC);
                customerMetric.value = null;
                customerMetric.id = null;
              }
              if (isPasteEvent(event)) return;
              handleRevStreamPricingPlanChanged(event);
            },
          };
        case PRICING_PLAN:
          return {
            ...defaults,
            cellEditor: SelectEditor,
            cellEditorParams: ({ data }) => {
              const revenueStream = getMatchingRevenueStream(
                data,
                revenueStreams,
              );
              const { pricingPlans } = revenueStream;
              const options = pricingPlans.map(({ planId, planName }) => ({
                id: String(planId),
                name: planName,
              }));
              return {
                id: 'pricing-plan-select-editor',
                DefaultOption: PricingPlanDefaultOption,
                getSelectedValueOnly: false,
                options,
              };
            },
            editable: (event) => {
              const revenueStream = findFieldByName(event.data, REVENUE_STREAM);
              return (
                !isEmptyOrNull(revenueStream?.value) || isPasteEvent(event)
              );
            },
            valueGetter: ({ data }) => {
              const pricingPlan = findFieldByName(data, PRICING_PLAN);
              return pricingPlan.id;
            },
            valueFormatter: ({ data, value }) => {
              const revenueStream = getMatchingRevenueStream(
                data,
                revenueStreams,
              );
              const plan = revenueStream?.pricingPlans.find(
                (pricingPlan) => pricingPlan.planId === value,
              );
              return plan ? plan.planName : EMPTY_CELL_VALUE;
            },
            valueSetter: (event) => {
              const { data, newValue, oldValue } = event;
              const pricingPlan = findFieldByName(data, PRICING_PLAN);

              const pricingPlanIndex = data.fields.findIndex(
                (field) => field.name === columnNames.PRICING_PLAN,
              );

              // Handle pasted values
              if (typeof newValue === 'string') {
                const revenueStream = getMatchingRevenueStream(
                  data,
                  revenueStreams,
                );

                if (!revenueStream) return false;
                const { pricingPlans, streamType } = revenueStream;
                let plan = {};
                if (Number.isNaN(Number(newValue))) {
                  plan = pricingPlans.find(
                    ({ planName }) => planName === newValue,
                  );
                } else {
                  plan = pricingPlans.find(
                    ({ planId }) => planId === Number(newValue),
                  );
                }

                setErrors((previousErrors) => {
                  if (
                    (plan || streamType === STREAM_TYPE_REVENUE_ONLY) &&
                    previousErrors[data.id]
                  ) {
                    delete previousErrors[data.id][PRICING_PLAN];
                    return previousErrors;
                  }

                  return {
                    ...previousErrors,
                    [data.id]: {
                      ...previousErrors[data.id],
                      [PRICING_PLAN]: 'Pricing plan name is invalid',
                    },
                  };
                });

                // AG Grid valueSetters updates must mutate parameters
                data.fields.splice(pricingPlanIndex, 1, {
                  ...data.fields[pricingPlanIndex],
                  id: plan?.planId ?? null,
                  value: plan?.planName,
                });

                return !!plan && oldValue !== newValue;
              }
              const { id, name } = newValue;
              const planId = Number(id);
              assert(!Number.isNaN(planId), '"planId" is NaN');

              /* eslint-disable-next-line no-param-reassign -- predates description requirement */
              pricingPlan.value = name;
              pricingPlan.id = planId;

              return oldValue !== newValue;
            },
            onCellValueChanged: (event) => {
              if (isPasteEvent(event)) return;
              handleRevStreamPricingPlanChanged(event);
            },
          };
        case REVENUE_METRIC:
          return {
            ...defaults,
            editable: (event) => {
              if (isPasteEvent(event)) return true;
              const { data } = event;

              const revenueStream = findFieldByName(data, REVENUE_STREAM);
              return !isEmptyOrNull(revenueStream?.value);
            },
            cellEditor: SelectEditor,
            cellEditorParams: ({ data }) => {
              const { revenueMetric: options } = getMetrics(data) ?? {
                revenueMetric: [],
              };
              return {
                id: 'revenue-metric-select-editor',
                DefaultOption: RevenueMetricDefaultOption,
                options,
                getSelectedValueOnly: false,
              };
            },
            valueGetter: ({ data }) => {
              const revenueMetric = findFieldByName(data, REVENUE_METRIC);
              return revenueMetric.id;
            },
            valueFormatter: ({ value }) => {
              if (!value) return EMPTY_CELL_VALUE;
              return getSelectedMetric(REVENUE_METRIC_KEY, value);
            },
            valueSetter: (event) => {
              const { data, newValue, oldValue } = event;
              const revenueMetric = findFieldByName(data, REVENUE_METRIC);
              const { revenueMetric: metrics } = getMetrics(data);
              const revenueVal = metrics.find(
                (metric) =>
                  metric.name.toLowerCase() === newValue?.name?.toLowerCase(),
              );

              if (!revenueVal) {
                setErrors((previousErrors) => {
                  return {
                    ...previousErrors,
                    [data.id]: {
                      ...previousErrors[data.id],
                      [REVENUE_METRIC]: 'Revenue metric name is invalid',
                    },
                  };
                });

                return false;
              }

              // Handle pasted values
              if (typeof event.newValue === 'string') {
                if (!revenueVal) {
                  setErrors((previousErrors) => {
                    return {
                      ...previousErrors,
                      [data.id]: {
                        ...previousErrors[data.id],
                        [REVENUE_METRIC]: 'Revenue metric name is invalid',
                      },
                    };
                  });

                  return false;
                }

                /* eslint-disable-next-line no-param-reassign -- AG Grid updates to column values must mutate parameters */
                revenueMetric.id = revenueVal.id;
                revenueMetric.value = revenueVal.name;
                return true;
              }

              if (!revenueVal) {
                setErrors((previousErrors) => {
                  return {
                    ...previousErrors,
                    [data.id]: {
                      ...previousErrors[data.id],
                      [REVENUE_METRIC]: 'Revenue metric name is invalid',
                    },
                  };
                });

                return false;
              }

              const { id, name } = newValue;
              /* eslint-disable-next-line no-param-reassign -- predates description requirement */
              revenueMetric.value = name;
              revenueMetric.id = id;
              return oldValue !== newValue;
            },
            onCellValueChanged: async (event) => {
              if (isPasteEvent(event)) return;

              const { data } = event;
              const revenueMetric = findFieldByName(data, REVENUE_METRIC);
              /**
               * @type {OverwriteRevenueDealEntryRequest<{
               *   [REVENUE_METRIC]: string;
               * }>}
               */
              const payload = {
                [REVENUE_METRIC]: toMacroCase(String(revenueMetric.value)),
              };
              handleRevStreamPricingPlanChanged(event, payload);
            },
          };
        case CUSTOMER_METRIC:
          return {
            ...defaults,
            editable: (event) => {
              if (isPasteEvent(event)) return true;

              const { data } = event;
              const revenueStream = findFieldByName(data, REVENUE_STREAM);
              return !isEmptyOrNull(revenueStream?.value);
            },
            cellEditor: SelectEditor,
            cellEditorParams: ({ data }) => {
              const { customerMetric: options } = getMetrics(data) ?? {
                customerMetric: [],
              };
              return {
                id: 'customer-metric-select-editor',
                DefaultOption: CustomerMetricDefaultOption,
                options,
                getSelectedValueOnly: false,
              };
            },
            valueGetter: ({ data }) => {
              const customerMetric = findFieldByName(data, CUSTOMER_METRIC);
              return customerMetric.id;
            },
            valueFormatter: ({ value }) => {
              if (!value) return EMPTY_CELL_VALUE;
              return getSelectedMetric(CUSTOMER_METRIC_KEY, value);
            },
            valueSetter: (event) => {
              const { data, newValue, oldValue } = event;
              const customerMetric = findFieldByName(data, CUSTOMER_METRIC);
              const { customerMetric: metrics } = getMetrics(data);
              const customerVal = metrics.find(
                (metric) =>
                  metric.name.toLowerCase() === newValue?.name?.toLowerCase(),
              );

              if (!customerVal) {
                setErrors((previousErrors) => {
                  return {
                    ...previousErrors,
                    [data.id]: {
                      ...previousErrors[data.id],
                      [CUSTOMER_METRIC]: 'Customer metric name is invalid',
                    },
                  };
                });

                return false;
              }

              // Handle pasted values
              if (typeof event.newValue === 'string') {
                if (!customerVal) {
                  setErrors((previousErrors) => {
                    return {
                      ...previousErrors,
                      [data.id]: {
                        ...previousErrors[data.id],
                        [CUSTOMER_METRIC]: 'Customer metric name is invalid',
                      },
                    };
                  });

                  return false;
                }
                /* eslint-disable-next-line no-param-reassign -- AG Grid updates to column values must mutate parameters */
                customerMetric.id = customerVal.id;
                customerMetric.value = customerVal.name;
                return true;
              }

              if (!customerVal) {
                setErrors((previousErrors) => {
                  return {
                    ...previousErrors,
                    [data.id]: {
                      ...previousErrors[data.id],
                      [CUSTOMER_METRIC]: 'Customer metric name is invalid',
                    },
                  };
                });

                return false;
              }

              const { id, name } = newValue;
              /* eslint-disable-next-line no-param-reassign -- predates description requirement */
              customerMetric.value = name;
              customerMetric.id = id;
              return oldValue !== newValue;
            },
            onCellValueChanged: async (event) => {
              if (isPasteEvent(event)) return;

              const { data } = event;
              const customerMetric = findFieldByName(data, CUSTOMER_METRIC);
              /**
               * @type {OverwriteRevenueDealEntryRequest<{
               *   [CUSTOMER_METRIC]: string;
               * }>}
               */
              const payload = {
                [CUSTOMER_METRIC]: toMacroCase(String(customerMetric.value)),
              };
              handleRevStreamPricingPlanChanged(event, payload);
            },
          };
        default:
          return defaults;
      }
    });
    cols.push(actionMenu);
    return cols;
  }, [
    isColumnHidden,
    colDefs,
    revenueStreams,
    getMetrics,
    handleCellValueChanged,
    revenueStreamOptions,
    handleRevStreamPricingPlanChanged,
    onEditIntegrationRecord,
    onDeleteRecord,
  ]);

  /**
   * @type {(
   *   params?: import('ag-grid-community').RefreshServerSideParams,
   * ) => void}
   */
  const refreshServerSide = useMemo(() => {
    if (!gridApi?.api) return null;
    // We need to bind the `api` or `gridApi.api.refreshServerSide` will
    // throw a TypeError because `this` is undefined inside of it
    return gridApi.api.refreshServerSide.bind(gridApi.api);
  }, [gridApi?.api]);

  useWsSubscription(() => {
    if (refreshServerSide) {
      dispatch(
        subscribeToFetchRevenueDealMappingAction(scenarioId, refreshServerSide),
      );
    }
  }, [scenarioId, refreshServerSide]);

  /**
   * @type {(
   *   event: import('ag-grid-community').CellEditingStartedEvent<
   *     import('@/services/revenueService').RevenueIntegrationEntry
   *   >,
   * ) => void}
   */
  const handleCellEditingStarted = useCallback(({ colDef }) => {
    const { field } = colDef;
    setHasUnsavedData(isUnsavedColumn(field));
  }, []);

  const hasRowNodes = gridApi?.api?.getModel().getRowCount() > 0;

  const { contentRect } = useElementSize(container);

  const distanceFromTop = useMemo(() => {
    if (hasRowNodes) {
      return DISTANCE_FROM_TOP + FILTER_ROW_HEIGHT;
    }
    return DISTANCE_FROM_TOP;
  }, [hasRowNodes]);

  const CustomLegend = useCallback(
    () => (
      <DataMappingGridLegend
        onAddRules={onAddRules}
        unmappedEntriesCount={unmappedEntriesCount}
      />
    ),
    [onAddRules, unmappedEntriesCount],
  );

  return (
    <div className="DataMappingGrid_Container" ref={container}>
      {/* @ts-ignore */}
      <Prompt
        when={hasUnsavedData}
        message="Are you sure you want to leave this page? Incomplete data mapping won't be saved."
      />
      <SpreadsheetToolbar Legend={CustomLegend}>
        <div className="SpreadsheetToolbar_ControlGroup">
          Options:
          <ColumnToggle ref={gridApiAdapted} spreadsheetId={SPREADSHEET_ID} />
        </div>
        <div className="SpreadsheetToolbar_ControlGroup">
          <PlusButton
            data-testid="add-external-data-to-grid-option"
            onAdd={onAddRecord}
          >
            Add New Entry
          </PlusButton>
        </div>
      </SpreadsheetToolbar>
      <WithEditing
        columnDefs={columnDefs}
        enabled={hasWritePermission}
        errors={errors}
      >
        {({ handleCellLoading, ...editingHandlers }) => (
          <Grid
            className="DataMappingGrid"
            data-testid="data-mapping-grid"
            pagination
            cacheBlockSize={cacheBlockSize}
            ref={ref}
            rowModelType={gridRowModels.SERVER_SIDE}
            serverSideDatasource={datasource}
            serverSideStoreType="partial"
            onPasteStart={(event) => {
              event.context.isPasting = true;
            }}
            onPasteEnd={(event) => {
              event.context.isPasting = false;
            }}
            onCellValueChanged={(params) => {
              if (params.source === 'paste') {
                handleMappingChange(params);
              }
              handleCellLoading(params);
            }}
            onColumnMoved={({ columnApi }) => {
              const columns = columnApi.getAllGridColumns();
              const revenueStreamColumnIndex = columns.findIndex(
                (column) => column.getColId() === REVENUE_STREAM,
              );
              const pricingPlanColumnIndex = columns.findIndex(
                (column) => column.getColId() === PRICING_PLAN,
              );
              if (pricingPlanColumnIndex < revenueStreamColumnIndex) {
                columnApi.moveColumn(PRICING_PLAN, revenueStreamColumnIndex);
              }
            }}
            onCellEditingStarted={handleCellEditingStarted}
            suppressPaginationPanel
            isInteractive
            onRangeSelectionChanged={handleRangeSelection}
            {...editingHandlers}
          />
        )}
      </WithEditing>
      {/**
       * Working around SSRM always displaying a loading row
       *
       * @see https://github.com/ag-grid/ag-grid/issues/4461
       */}
      {isLoading && (
        <LoadingSpinner
          style={{
            bottom: 0,
            top: distanceFromTop,
            height: (contentRect?.height ?? 0) - distanceFromTop,
          }}
        />
      )}

      {!isLoading && !hasRowNodes && (
        <EmptyData Icon={RevenueEmptyIcon} className="EmptyData-tab">
          Add data to the revenue integration for this grid to populate
        </EmptyData>
      )}

      {!!cellCount && (
        <SpreadsheetStatusBar cellCount={cellCount} cellSum={cellSum}>
          {cellCount && hasWritePermission && (
            <button
              type="button"
              onClick={handleResetButton}
              className="ResetContainer"
            >
              <ResetIcon className="ResetIcon" />
              Reset
            </button>
          )}
        </SpreadsheetStatusBar>
      )}
    </div>
  );
};

const DataMappingGrid = forwardRef(DataMappingGridFn);

export default DataMappingGrid;
