import { useState } from 'react';
import { connect } from 'react-redux';
import Button from '@/components/common/Button';
// eslint-disable-next-line import/no-deprecated -- predates description requirement
import ModalBase from '@/components/common/ModalBase';
import { validateEmail } from '@/helpers/validators';
import { sendHubspotReferral } from '@/services/settingsService';
import ReferralsForm from './ReferralsForm';
import ReferralsPreview from './ReferralsPreview';

const referralState = {
  FORM: 'FORM',
  PREVIEW: 'PREVIEW',
};

const checkValid = (typedEmail) =>
  !validateEmail(typedEmail) && 'Please enter a valid email!';

const ReferralsModal = ({
  setShow,
  showAlertAndHideMessage,
  referredBy,
  referredByEmail,
}) => {
  const [currentState, setCurrentState] = useState(referralState.FORM);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [loader, setLoader] = useState(false);

  const referViaEmail = async () => {
    setLoader(true);
    try {
      await sendHubspotReferral({
        firstName,
        lastName,
        email,
        referredBy,
        referredByEmail,
      });
      showAlertAndHideMessage({
        email,
        referred: true,
      });
    } catch (e) {
      const errorMessage = e.response?.data?.error?.errorMessage || e.message;
      const errorObject =
        errorMessage ===
        'The email address you are trying to invite has already been referred'
          ? { email, warning: errorMessage }
          : { email, error: errorMessage };

      showAlertAndHideMessage(errorObject);
    } finally {
      setLoader(false);
      setShow(false);
    }
  };

  const handleSubmit = () => {
    switch (currentState) {
      case referralState.FORM:
        setCurrentState(referralState.PREVIEW);
        break;
      case referralState.PREVIEW:
        referViaEmail();
        break;
      default:
        throw new Error('No matched state found');
    }
  };

  return (
    <ModalBase
      id="referral-modal"
      onCancel={() => setShow(false)}
      header={
        <h2 className="ModalBase_Heading">
          {currentState === referralState.FORM
            ? 'Refer a friend'
            : 'Preview the referral'}
        </h2>
      }
      footer={
        <>
          <Button
            className="Button-cancelLink"
            onClick={() => setShow(false)}
            data-testid="referral-cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            data-testid="referral-finish"
            loading={loader}
            disabled={
              !(
                currentState === referralState.PREVIEW ||
                (validateEmail(email) && firstName && lastName)
              )
            }
          >
            {currentState === referralState.FORM ? 'Preview' : 'OK, Send'}
          </Button>
        </>
      }
    >
      {currentState === referralState.FORM ? (
        <ReferralsForm
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          checkValid={checkValid}
        />
      ) : (
        <ReferralsPreview
          firstName={firstName}
          lastName={lastName}
          referredBy={referredBy}
        />
      )}
    </ModalBase>
  );
};

const mapStateToProps = (state) => ({
  referredBy: state.auth.userInfo.fullName,
  referredByEmail: state.auth.userInfo.email,
});

export default connect(mapStateToProps)(ReferralsModal);
