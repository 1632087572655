import CrossIcon from '@bill/cashflow.assets/cross';
import PropTypes from 'prop-types';
import Modal from '@/components/common/Modal';

/**
 * Displays an informative modal about parent expenses
 *
 * @example
 *   <ParentExpenseGuideModal
 *     open={isOpen}
 *     data-testid="parent-expense-guide-modal"
 *     onClose={() => setOpen(false)}
 *   />;
 */
function ParentExpenseGuideModal({ 'data-testid': dataTestId, onClose, open }) {
  return (
    <Modal
      open={open}
      className="Modal-help"
      data-testid={dataTestId}
      onClose={onClose}
    >
      <button
        type="button"
        className="Modal_CloseBtn"
        data-testid={`${dataTestId}-closeBtn`}
        onClick={onClose}
      >
        <CrossIcon aria-label="Close" />
      </button>
      <div className="ModalBase_Header">
        <h3 className="ModalBase_Heading">Nested Expenses</h3>
      </div>
      <p>
        Actuals for nested expenses can be found under the parent expense.
        Parent expenses cannot be deleted until all child expenses are removed.
        Child expenses will follow the department of the parent expense; any
        department change made to the parent expense will also apply to child
        expenses.
      </p>
    </Modal>
  );
}

ParentExpenseGuideModal.propTypes = {
  /** Unique ID for selecting the modal in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Event handler for when the user closes the modal */
  'onClose': PropTypes.func.isRequired,
  /** Whether or not the modal is displayed */
  'open': PropTypes.bool,
};

export default ParentExpenseGuideModal;
