export const NEW_CUSTOMERS = 'New Customers';
export const TOTAL_CUSTOMERS = 'Total Customers';
export const NEW_MRR = 'New MRR';
export const CHURNED_CUSTOMERS = 'Churned Customers';
export const CHURNED_MRR = 'Churned MRR';
export const OTHER_REVENUE = 'Other Revenue';
export const BEGINNING_MRR = 'Beginning MRR';
export const ENDING_MRR = 'Ending MRR';
export const EXPANSION_MRR = 'Expansion MRR';
export const CONTRACTION_MRR = 'Contraction MRR';
export const ADJUSTMENT_MRR = 'Adjustments to MRR (+/-)';
export const ONE_TIME_PURCHASES = 'One Time Purchases';
export const ONE_TIME_REVENUE = 'One Time Revenue';
export const ADJUSTMENTS = 'Discounts (Stripe)';
export const TOTAL_REVENUE = 'Total Revenue';
export const ADJUSTMENTS_TO_REVENUE = 'Adjustments to Revenue';
export const BEGINNING_CUSTOMER = 'Beginning Customers';
export const ENDING_CUSTOMER = 'Ending Customers';
export const EXPANSION_CUSTOMER = 'Expansion Customers';
export const CONTRACTION_CUSTOMER = 'Contraction Customers';
export const ADJUSTMENT_CUSTOMER = 'Adjustments to Customers (+/-)';

export const POS_METRIC_KEYS = [
  // New Customers
  0,
  // New MRR
  1,
];
export const NEG_METRIC_KEYS = [
  // Churned Customers
  2,
  // Churned MMR
  3,
];
