// @ts-check
export const actionTypes = /** @type {const} */ ({
  SET_RULE_NAME: 'name',
  SET_REVENUE_STREAM: 'revenueStreamId',
  SET_PRICING_PLAN: 'pricingPlanId',
  SET_COLUMN: 'column',
  SET_OPERATOR: 'operator',
  SET_CRITERIA: 'criteria',
  ADD_CRITERIA: 'addCriteria',
  DELETE_CRITERIA: 'deleteCriteria',
});

/** @typedef {actionTypes} ActionTypes */

export const ADD_RULES = 'ADD_RULES';
export const EDIT_RULE = 'EDIT_RULE';
export const UPDATE_RULE = 'UPDATE_RULE';
export const DELETE_RULE = 'DELETE_RULE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const SHOW_FORM = 'SHOW_FORM';
export const REORDER_RULES = 'REORDER_RULES';

const EQUALS_KEY = 'Equals';
const EQUALS_VALUE = '=';
export const NOT_BLANK_KEY = 'NotBlank';
const NOT_BLANK = 'Not blank';
export const BLANK = 'Blank';
export const REVENUE_STREAM = 'Revenue Stream';

export const OPERATORS = /** @type {const} */ ({
  STRING: {
    [EQUALS_KEY]: EQUALS_VALUE,
    Contains: 'Contains',
    [NOT_BLANK_KEY]: NOT_BLANK,
    [BLANK]: BLANK,
  },
  NUMBER: {
    [EQUALS_KEY]: EQUALS_VALUE,
    [NOT_BLANK_KEY]: NOT_BLANK,
    [BLANK]: BLANK,
    LessThan: '<',
    GreaterThan: '>',
    LessThanEquals: '<=',
    GreaterThanEquals: '>=',
  },
});

export const columnTypes = /** @type {const} */ ({
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  STRING: 'STRING',
});

/** @typedef {(typeof columnTypes)[keyof typeof columnTypes]} RevenueIntegrationColumnType */

export const DEFAULT_ITEMS_PER_PAGE = 25;

/**
 * @typedef {Extract<
 *   import('ag-grid-community').ISimpleFilterModel['type'],
 *   | 'equals'
 *   | 'notEqual'
 *   | 'greaterThan'
 *   | 'greaterThanOrEqual'
 *   | 'lessThan'
 *   | 'lessThanOrEqual'
 * >} NumberFilterModel
 */

/**
 * @typedef {Extract<
 *   import('ag-grid-community').ISimpleFilterModel['type'],
 *   'equals' | 'notEqual'
 * >} DateFilterModel
 */

/**
 * @typedef {Extract<
 *   import('ag-grid-community').ISimpleFilterModel['type'],
 *   'equals' | 'notEqual' | 'contains'
 * >} TextFilterModel
 */

/** @type {NumberFilterModel[]} */
export const NUMBER_FILTER_OPTIONS = [
  'equals',
  'notEqual',
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
];

/** @type {DateFilterModel[]} */
export const DATE_FILTER_OPTIONS = ['equals', 'notEqual'];

/** @type {TextFilterModel[]} */
export const TEXT_FILTER_OPTONS = ['contains', 'equals', 'notEqual'];

export const filterTypes = /** @type {const} */ ({
  date: 'date',
  number: 'number',
  text: 'text',
});

/**
 * @typedef {{
 *   id: string;
 *   name: string;
 * }} MetricType
 */

/**
 * @typedef {{
 *   revenueMetric: MetricType;
 * }} RevenueMetric
 */

/**
 * @typedef {{
 *   customerMetric: MetricType;
 * }} CustomerMetric
 */

const NO_CHANGE = {
  id: 'NO_CHANGE',
  name: 'No Change',
};

const NEW_MRR = {
  id: 'NEW_MRR',
  name: 'New MRR',
};

const CHURN_MRR = {
  id: 'CHURN_MRR',
  name: 'Churn MRR',
};

const NEW_CUSTOMER = {
  id: 'NEW_CUSTOMER',
  name: 'New Customer',
};

const CHURN_CUSTOMER = {
  id: 'CHURN_CUSTOMER',
  name: 'Churn Customer',
};

export const REVENUE_METRICS = /** @type {const} */ ({
  REVENUE_ONLY: {
    revenueMetric: [
      {
        id: 'REVENUE_ONLY',
        name: 'Revenue Only',
      },
    ],
    customerMetric: [],
  },
  ONE_TIME: {
    revenueMetric: [
      {
        id: 'ONE_TIME',
        name: 'One Time',
      },
    ],
    customerMetric: [],
  },
  STANDARD_SUBSCRIPTION: {
    revenueMetric: [NO_CHANGE, NEW_MRR, CHURN_MRR],
    customerMetric: [NO_CHANGE, NEW_CUSTOMER, CHURN_CUSTOMER],
  },
  ADVANCED_SUBSCRIPTION: {
    revenueMetric: [
      NO_CHANGE,
      NEW_MRR,
      {
        id: 'EXPANSION_MRR',
        name: 'Expansion MRR',
      },
      CHURN_MRR,
      {
        id: 'CONTRACTION_MRR',
        name: 'Contraction MRR',
      },
    ],
    customerMetric: [
      NO_CHANGE,
      NEW_CUSTOMER,
      {
        id: 'EXPANSION_CUSTOMER',
        name: 'Expansion Customer',
      },
      CHURN_CUSTOMER,
      {
        id: 'CONTRACTION_CUSTOMER',
        name: 'Contraction Customer',
      },
    ],
  },
});

/**
 * @typedef {{
 *       filterType: filterTypes['number'];
 *       filter: number;
 *       type: NumberFilterModel;
 *     }
 *   | {
 *       filterType: filterTypes['date'];
 *       dateFrom: string | null;
 *       dateTo: string | null;
 *       type: DateFilterModel;
 *     }
 *   | {
 *       filterType: filterTypes['text'];
 *       filter: string;
 *       type: TextFilterModel;
 *     }} FilterSingleCondition
 */

/**
 * @typedef {FilterSingleCondition
 *   | import('ag-grid-community').ICombinedSimpleModel} FilterCondition
 */

export const columnNames = /** @type {const} */ ({
  DEAL_ID: 'Deal Id',
  CUSTOMER_ID: 'Customer Id',
  CUSTOMER_NAME: 'Customer Name',
  DATE: 'Date',
  AMOUNT: 'Amount',
  DEAL_NAME: 'Deal Name',
  REVENUE_DRIVER: 'Revenue Driver',
  PRODUCT_PRICING_PLAN: 'Product Pricing Plan',
  REVENUE_STREAM: 'Revenue Stream',
  PRICING_PLAN: 'Pricing Plan',
  REVENUE_METRIC: 'Revenue Metric',
  CUSTOMER_METRIC: 'Customer Metric',
});

export const HELP_FINMARK_BASE_URL = 'https://help.finmark.com';

/** @typedef {(typeof columnNames)[keyof typeof columnNames]} ColumnName */
/**
 * @typedef {Extract<
 *   ColumnName,
 *   | typeof columnNames.AMOUNT
 *   | typeof columnNames.REVENUE_STREAM
 *   | typeof columnNames.PRICING_PLAN
 *   | typeof columnNames.REVENUE_METRIC
 *   | typeof columnNames.CUSTOMER_METRIC
 *   | typeof columnNames.CUSTOMER_NAME
 *   | typeof columnNames.DATE
 *   | typeof columnNames.DEAL_NAME
 * >} EditableColumnName
 */
