/**
 * Returns the scenario state for the given scenario ID
 *
 * @param {number} id ID of the scenario to retrieve
 * @param {Object} state Redux store
 * @returns {Object} Scenario state
 */
export default function getScenarioById(id, state) {
  const { scenarios } = state.scenario;
  return scenarios.find((scenario) => scenario.scenarioId === id);
}
