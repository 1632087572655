// @ts-check
import { useQuery } from '@tanstack/react-query';
import { CASH_FORECAST } from '@/cacheKeys';
import useTypedSelector from '@/hooks/useTypedSelector';
import { getCashInOutGrid } from '@/services/cashInOut';
import useComparisonScenarioId from './useComparisonScenarioId';
import useEndDate from './useEndDate';
import useScenarioId from './useScenarioId';
import useStartDate from './useStartDate';

/**
 * Query hook for required data of the cash flow in/out grid.
 *
 * @type {(options?: {
 *   filter?: import('@/types/services/backend').CashInOutGrid['cashFlowType'];
 * }) => import('@tanstack/react-query').UseQueryResult<
 *   import('@/types/services/backend').CashInOutGrid[],
 *   unknown
 * >}
 */
const useCashInOutGridQuery = (options = {}) => {
  const { filter = null } = options;
  const startDate = useStartDate();
  const endDate = useEndDate();
  const { timePeriod } = useTypedSelector(({ shared }) => shared);
  const scenarioId = useScenarioId();
  const comparisonScenarioId = useComparisonScenarioId();
  return useQuery({
    queryKey: [
      CASH_FORECAST,
      startDate,
      endDate,
      timePeriod,
      scenarioId,
      comparisonScenarioId,
      filter,
    ],
    queryFn: async () => {
      const response = await getCashInOutGrid({
        startDate,
        endDate,
        timePeriod,
        scenarioId,
        comparisonScenarioId,
        filter,
      });
      if (response.data.error?.errorMessage) {
        throw new Error(response.data.error.errorMessage);
      }
      return response.data.data.cashInOut;
    },
  });
};

export default useCashInOutGridQuery;
