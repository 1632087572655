import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import VarianceIcon from '@bill/cashflow.assets/variance-icon';
import LetterIcon from '@/components/common/LetterIcon';
import { classNames, debounce } from '@/helpers';
import useForceUpdate from '@/hooks/useForceUpdate';
import useSelectedScenarios from '@/hooks/useSelectedScenarios';
import FilterSortField from './FilterSortField';
import FilterValueField from './FilterValueField';
import './FilterRenderer.scss';

/** A floating filter component for use in ag-Grid */
const FilterRenderer = forwardRef(
  (
    { api, column, columnApi, enableComparison, filterParams, showFilter },
    ref,
  ) => {
    const inputRef = useRef(null);
    const forceUpdate = useForceUpdate();
    useEffect(() => {
      api.addEventListener('sortChanged', forceUpdate);
      return () => api.removeEventListener('sortChanged', forceUpdate);
    }, [api, forceUpdate]);

    const scenarios = useSelectedScenarios();
    const scenario = useMemo(() => {
      if (!enableComparison || scenarios.length < 2) return null;

      const idxInGroup = column.parent.children.indexOf(column);
      return scenarios[idxInGroup];
    }, [column, enableComparison, scenarios]);

    const filterInstance = useMemo(
      () => api.getFilterInstance(column.colId),
      [api, column],
    );
    const [model, setModel] = useState({});

    useImperativeHandle(ref, () => ({
      onParentModelChanged(parentModel) {
        setModel(parentModel ?? {});
      },
    }));

    const filterType = filterInstance.getFilterType();

    const { debounceMs } = filterParams;
    const debouncedQuickFiltering = useMemo(() => {
      return debounce(
        (filter) => filterInstance.onFloatingFilterChanged(filterType, filter),
        debounceMs,
      );
    }, [filterInstance, filterType, debounceMs]);

    const handleQuickChange = useCallback(
      (value) => {
        const isEmpty = value === '';
        const filter = !isEmpty ? value : null;
        setModel((current) => ({
          ...current,
          [filterType === 'date' ? 'dateFrom' : 'filter']: filter,
        }));
        debouncedQuickFiltering(filter);
      },
      [debouncedQuickFiltering, filterType],
    );

    const testId = column.colId;

    const { condition1, condition2, dateFrom, filter, operator } = model;
    let quickFilterValue = dateFrom ?? filter;
    const isMultiCondition = !!condition2;
    if (isMultiCondition) {
      const cond1Filter = condition1.dateFrom ?? condition1.filter;
      const cond2Filter = condition2.dateFrom ?? condition2.filter;
      quickFilterValue = `${cond1Filter} ${operator} ${cond2Filter}`;
    }

    const hasComparison = enableComparison && scenarios.length > 1;

    return (
      <div
        className={classNames(
          'FilterRenderer',
          hasComparison && 'FilterRenderer-perScenario',
          column.colDef.headerCheckboxSelection && 'FilterRenderer-selectCol',
        )}
        style={{ borderBottomColor: scenario?.color }}
      >
        {hasComparison &&
          (scenario ? (
            <LetterIcon
              color={scenario.color}
              string={scenario.name}
              data-testid={`${testId}-scenarioIcon`}
            />
          ) : (
            <VarianceIcon
              className="FilterRenderer_VarianceIcon"
              data-testid={`${testId}-scenarioIcon`}
            />
          ))}

        {showFilter && (
          <>
            <FilterValueField
              ref={inputRef}
              type={isMultiCondition ? 'text' : filterType}
              id={`${testId}-filter`}
              className="FilterRenderer_Field"
              value={quickFilterValue}
              aria-label="Filter"
              disabled={isMultiCondition}
              onChange={handleQuickChange}
            />
            {column.colDef.sortable && (
              <FilterSortField
                data-testid={`${testId}-sortBtn`}
                column={column}
                columnApi={columnApi}
                api={api}
              />
            )}
          </>
        )}
      </div>
    );
  },
);

export default FilterRenderer;
