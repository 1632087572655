// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import GridOptionsIcon from '@bill/cashflow.assets/grid-options';
import { userPreferencesAction } from '@/actions/auth';
import Checkbox from '@/components/common/Checkbox';
import ControlDropdown from '@/components/common/ControlDropdown';
import './OptionsToggle.scss';

const OptionsToggleIcon = () => (
  <GridOptionsIcon className="ColumnToggleIcon" />
);

/**
 * A control for toggling the visibility of zero value rows in a grid
 *
 * @example
 *   <SpreadsheetToolbar>
 *     <OptionsToggle spreadsheetId={spreadsheetId} />
 *   </SpreadsheetToolbar>;
 */
const OptionsToggle = ({ spreadsheetId }) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const userPreferences = useSelector(({ auth }) => auth.preferences);

  return (
    <ControlDropdown id="options-toggle" Icon={OptionsToggleIcon}>
      <div className="OptionsToggle_Popover">
        <Checkbox
          id="filter-zero-values-toggle"
          name={spreadsheetId}
          checked={Boolean(userPreferences[spreadsheetId])}
          onChange={({ target: { checked } }) => {
            dispatch(
              userPreferencesAction({
                [spreadsheetId]: checked,
              }),
            );
          }}
          className="Checkbox_Label"
        >
          Hide Zero Rows
          <div className="OptionsToggle_FilterText">
            Hides all zero rows or rows that don’t have data.
          </div>
        </Checkbox>
      </div>
    </ControlDropdown>
  );
};

export default OptionsToggle;
