// eslint-disable-next-line no-restricted-imports -- predates description requirement
import { Overlay, Popover } from 'react-bootstrap';
import SheetIcon from '@bill/cashflow.assets/sheet';
import PropTypes from 'prop-types';
import Button from '@/components/common/Button';
import { isEmptyOrNull } from '@/helpers/validators';
import './PopupButton.scss';

const PopupButton = ({
  target,
  setShow,
  show,
  year,
  month,
  customId,
  setValues,
}) => {
  return (
    <>
      <span
        data-testid={`${year}-${month.value}-popover`}
        className="PopupButton_IconContainer"
        ref={target}
        onClick={() => setShow(!show)}
        aria-hidden
      >
        <SheetIcon className="PopupButton_Icon" />
      </span>

      {show && (
        <Overlay
          target={target.current}
          show
          placement="bottom"
          popperConfig={{
            year,
            month: month.value,
            ...(customId ? { id: customId } : {}),
          }}
        >
          {(props) => (
            <Popover
              id="popover-basic"
              className="PopupButton_Container"
              {...props}
            >
              {props.popper.state?.options && (
                <div className="PopupActive_Container">
                  <p className="PopupButton_Label">
                    Fill right with
                    <br /> the same value
                  </p>
                  <Button
                    data-testid="custom-revenue-popover-button"
                    onClick={() => {
                      const {
                        month: popoverMonth,
                        year: popoverYear,
                        id,
                      } = props.popper.state.options;
                      setValues({
                        year: popoverYear,
                        month: popoverMonth,
                        id: isEmptyOrNull(id) ? id : null,
                        value: null,
                      });
                    }}
                  >
                    Apply
                  </Button>
                </div>
              )}
            </Popover>
          )}
        </Overlay>
      )}
    </>
  );
};

PopupButton.propTypes = {
  /**
   * Month object, including the abbreviated month name and and the two-digit
   * numerical value, i.e. "01"
   */
  month: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
  /** Year in which the grid input lies */
  year: PropTypes.number,
  /** Ref element for popover */
  target: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  /** Function used to toggle Popover Button */
  setShow: PropTypes.func.isRequired,
  /** Custom ID to update the value of the grid inputs */
  customId: PropTypes.number,
  /** Flag to indication the show/hide state of pop over */
  show: PropTypes.bool,
  /** Function to update the value of a string based on year and custom ID */
  setValues: PropTypes.func.isRequired,
  /** Callback values provided by popper */
  popper: PropTypes.objectOf(PropTypes.object, PropTypes.string),
};

export default PopupButton;
