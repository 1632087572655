import { forwardRef, useCallback, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import ChartRadioGroupPersistent from '@/components/common/ChartRadioGroupPersistent';
import InfoTooltip from '@/components/common/InfoTooltip';
import { CHURNED_MRR_PATH } from '@/constants/pages';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import useChartPreferences from '@/hooks/useChartPreferences';
import Widget from './Widget';
import WidgetKPI from './WidgetKPI';
import ChurnedMrrChart, {
  CHURNED_MRR_TOGGLE_ID,
} from './charts/ChurnedMrrChart';
import './DashboardLanding.scss';

const ChurnedMrrGlance = forwardRef(
  ({ ChartWidget = Widget, children, ...props }, ref) => {
    const [churnedMrr, setChurnedMrr] = useState({});
    const { startDate, endDate } = useSelector(({ shared }) => shared);
    const showPercentage = useChartPreferences(CHURNED_MRR_TOGGLE_ID);
    const onQueryStateChange = useCallback(({ data, isLoading }) => {
      setChurnedMrr({ ...data, isLoading });
    }, []);

    return (
      <ChartWidget
        ref={ref}
        title="Churned MRR"
        data-testid="churnedMRRWidget"
        url={CHURNED_MRR_PATH}
        metricValue={churnedMrr.metric}
        label={formatRangeWithShortYear(startDate, endDate)}
        controls={
          <ChartRadioGroupPersistent
            id={CHURNED_MRR_TOGGLE_ID}
            value={false}
            options={[
              {
                label: 'Currency',
                value: false,
              },
              {
                label: 'Percent',
                value: true,
              },
            ]}
            className="ControlDropdown_Child"
          />
        }
        {...props}
      >
        {(childProps) => (
          <>
            <WidgetKPI
              label={formatRangeWithShortYear(startDate, endDate)}
              value={churnedMrr.isLoading ? '' : churnedMrr.metric}
              tooltip={
                churnedMrr.tooltipText && (
                  <InfoTooltip data-testid="new-mrr-tooltip">
                    {churnedMrr.tooltipText}
                  </InfoTooltip>
                )
              }
            />
            <ChurnedMrrChart
              showPercentage={showPercentage}
              {...childProps}
              onQueryStateChange={onQueryStateChange}
            />
            {children}
          </>
        )}
      </ChartWidget>
    );
  },
);

export default ChurnedMrrGlance;
