// @ts-check
import { datadogRum } from '@datadog/browser-rum';
import {
  START_SCENARIO_USER_CREATION,
  SET_USER_INFO,
  AUTHENTICATION_SUCCEEDED,
  SET_IS_REFRESHING_TOKEN,
  PERSIST_STATEFUL_ROUTE,
  UNSET_STATEFUL_ROUTE,
  SET_TMX_SESSION_ID,
  AUTHENTICATION_DECLINED_BY_TMX,
  BILL_AUTHENTICATION_STARTED,
  BILL_AUTHENTICATION_SUCCEEDED,
  BILL_AUTHENTICATION_FAILED,
} from '@/actionTypes/auth';
import {
  SET_SELECTED_COMPANY,
  SET_COMPANIES_ON_LOGIN,
} from '@/actionTypes/companies';
import { SET_USER_PREFERENCES, LOGOUT } from '@/actionTypes/shared';
import { USER_ROLES } from '@/constants/permissions';
import { parseScenarioPermissions, setupAbility } from '@/helpers/ability';

/** @typedef {{ pathname: string; fullPath?: string; search: string }} StatefulRoute */

/**
 * @typedef {{
 *   role: import('@/constants/permissions').UserRoles;
 *   permissions: string[];
 *   productTypes: string[];
 * }} AccessibleCompany
 */

/**
 * @typedef {{
 *   fullName: string;
 *   userId: number;
 *   hasEmployeeAccess: boolean;
 *   email: string;
 *   createdAt: number;
 *   userRole: string;
 *   country: string | null;
 * }} UserInfo
 */

/**
 * @typedef {{
 *   companyId: number;
 *   role: string;
 *   permissions: string[];
 * }} AccessibleScenario
 */

/**
 * @typedef {{
 *   companyId: number;
 *   role: string;
 *   permissions: import('@/helpers/ability').Ability[];
 * }} ScenarioPermission
 */

/**
 * @typedef {{
 *   accessibleCompanies: Record<string, AccessibleCompany>;
 *   accessibleScenarios: Record<string, AccessibleScenario>;
 *   isAuthenticated: boolean;
 *   isRefreshingToken: boolean;
 *   isPartner: boolean;
 *   verificationToken: string;
 *   permissions: import('@/helpers/ability').Ability[];
 *   scenarioPermissions: Record<string, ScenarioPermission>;
 *   userInfo: UserInfo;
 *   identityToken: string;
 *   token: string;
 *   statefulRoute: null | StatefulRoute;
 *   preferences: Record<string, unknown>;
 *   deviceSessionId: string;
 *   isUnauthorizedByTMX: boolean;
 *   isAuthenticating: boolean;
 *   isLoggingout: boolean;
 * }} AuthState
 */

/**
 * @typedef {{
 *   selectedCompanyId?: number | null;
 *   companies: Record<string, AccessibleCompany>;
 *   fullName: string;
 *   userId: number;
 *   jwtToken: string;
 *   isPartner: boolean;
 *   creationDate: string;
 *   emailAddress?: string;
 *   userEmail?: string;
 *   country: string | null;
 *   hasEmployeeAccess: boolean;
 *   identityToken: string;
 *   scenarios: Record<string, AccessibleScenario>;
 * }} ActionPayload
 */

/**
 * @typedef {{
 *       type: typeof AUTHENTICATION_SUCCEEDED;
 *       payload: ActionPayload;
 *     }
 *   | {
 *       type: typeof BILL_AUTHENTICATION_SUCCEEDED;
 *       payload: { auth: ActionPayload };
 *     }
 *   | {
 *       type: typeof SET_USER_INFO;
 *       payload: UserInfo;
 *     }
 *   | {
 *       type: typeof START_SCENARIO_USER_CREATION;
 *       payload: { verificationToken: string };
 *     }
 *   | {
 *       type: typeof SET_SELECTED_COMPANY;
 *       payload: number;
 *     }
 *   | {
 *       type: typeof SET_USER_PREFERENCES;
 *       payload: object;
 *     }
 *   | {
 *       type: typeof SET_IS_REFRESHING_TOKEN;
 *       payload: boolean;
 *     }
 *   | {
 *       type: typeof PERSIST_STATEFUL_ROUTE;
 *       payload: {
 *         companyId: number;
 *         scenarioId: number;
 *         route: string;
 *         fullPath: string;
 *       };
 *     }
 *   | {
 *       type: typeof SET_TMX_SESSION_ID;
 *       payload: string;
 *     }
 *   | {
 *       type: typeof AUTHENTICATION_DECLINED_BY_TMX;
 *       payload: boolean;
 *     }
 *   | {
 *       type:
 *         | typeof SET_COMPANIES_ON_LOGIN
 *         | typeof LOGOUT
 *         | typeof UNSET_STATEFUL_ROUTE
 *         | typeof BILL_AUTHENTICATION_STARTED;
 *       payload: null;
 *     }} AuthAction
 */

// Used by cypress to skip the login page
const token = window.sessionStorage.getItem('jwt');
const identityToken = window.sessionStorage.getItem('identityToken');

/** @type {import('@/helpers/ability').Ability[]} */
const permissions =
  JSON.parse(window.sessionStorage.getItem('permissions')) || [];

/** @type {Record<string, ScenarioPermission>} */
const scenarioPermissions =
  JSON.parse(window.sessionStorage.getItem('scenarioPermissions')) || {};

/** @type {UserInfo} */
const userInfo = /** @type {UserInfo} */ JSON.parse(
  window.sessionStorage.getItem('userInfo'),
) ?? {
  userRole: '',
  fullName: '',
  userId: '',
  email: '',
  country: '',
  createdAt: null,
};

/** @type {boolean} */
const isAuthenticated =
  window.sessionStorage.getItem('isAuthenticated') === 'true';

/** @type {AuthState['accessibleCompanies']} */
const accessibleCompanies =
  JSON.parse(window.sessionStorage.getItem('accessibleCompanies')) ?? {};

/** @type {AuthState['accessibleScenarios']} */
const accessibleScenarios =
  JSON.parse(window.sessionStorage.getItem('accessibleScenarios')) ?? {};

/** @type {boolean} */
const isPartner =
  JSON.parse(window.sessionStorage.getItem('isPartner')) ?? false;

// SESSION_STATE and INITIAL_STATE are separated so that items in
// INITIAL_STATE, but not SESSION_STATE, will be persisted via Redux Persist
// in localStorage even if the user logs out of the application.

/** @type {Omit<AuthState, 'preferences' | 'statefulRoute'>} */
const SESSION_STATE = {
  token,
  identityToken,
  userInfo,
  verificationToken: '',
  permissions,
  scenarioPermissions,
  isRefreshingToken: false,
  isAuthenticated,
  accessibleCompanies,
  accessibleScenarios,
  isPartner,
  deviceSessionId: '',
  isUnauthorizedByTMX: false,
  isAuthenticating: false,
  isLoggingout: false,
};

/** @type {AuthState} */
const INITIAL_STATE = {
  preferences: {},
  statefulRoute: null,
  ...SESSION_STATE,
};

/**
 * @type {(
 *   companies: AuthState['accessibleCompanies'],
 *   selectedCompanyId: number,
 * ) => [string, import('@/helpers/ability').Ability[]]}
 */
const getCompanyUserRoleAndPermissions = (companies, selectedCompanyId) => {
  const selectedCompany = companies[selectedCompanyId];
  const companyUserRole = selectedCompany?.role;
  /** @type {import('@/constants/permissions').UserRoles | string} */
  const userRole = USER_ROLES[companyUserRole] ?? '';
  const companyPermissions = selectedCompany?.permissions;
  const companyAbilities = companyPermissions
    ? setupAbility(companyPermissions)
    : [];
  return [userRole, companyAbilities];
};

/**
 * @type {(
 *   payload: ActionPayload,
 * ) => Omit<
 *   AuthState,
 *   | 'isRefreshingToken'
 *   | 'statefulRoute'
 *   | 'preferences'
 *   | 'deviceSessionId'
 *   | 'isUnauthorizedByTMX'
 * >}
 */
const handleAuthSucceeded = (payload) => {
  const { companies } = payload;
  const companyIds = Object.keys(companies).map(Number);
  let defaultCompanyId;
  if (companyIds.length === 1) {
    [defaultCompanyId] = companyIds;
  }

  const [userRole, companyAbilities] = getCompanyUserRoleAndPermissions(
    companies,
    payload.selectedCompanyId ?? defaultCompanyId,
  );

  /** @type {UserInfo} */
  const user = {
    fullName: payload.fullName,
    userId: payload.userId,
    hasEmployeeAccess: payload.hasEmployeeAccess,
    email: payload.userEmail || payload.emailAddress,
    country: payload.country,
    createdAt: new Date(payload.creationDate).getTime(),
    userRole,
  };

  return {
    userInfo: user,
    token: payload.jwtToken,
    isAuthenticated: true,
    isPartner: payload.isPartner,
    identityToken: payload.identityToken,
    accessibleCompanies: companies,
    accessibleScenarios: payload.scenarios,
    permissions: companyAbilities,
    scenarioPermissions: parseScenarioPermissions(payload.scenarios),
    verificationToken: '',
    isAuthenticating: false,
    isLoggingout: false,
  };
};

/**
 * @type {(
 *   state: AuthState,
 *   action:
 *     | AuthAction
 *     | import('@/types/extend-redux-toolkit.d').UnknownAction,
 * ) => AuthState}
 */
const auth = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case AUTHENTICATION_SUCCEEDED: {
      const authSucceededState = handleAuthSucceeded(payload);
      return {
        ...state,
        ...authSucceededState,
      };
    }
    case BILL_AUTHENTICATION_SUCCEEDED: {
      const { auth: authPayload } = payload;
      const authSucceededState = handleAuthSucceeded(authPayload);
      return {
        ...state,
        ...authSucceededState,
      };
    }
    case SET_COMPANIES_ON_LOGIN:
      return { ...state, statefulRoute: null };
    case SET_IS_REFRESHING_TOKEN:
      return { ...state, isRefreshingToken: payload };
    case SET_USER_INFO:
      return { ...state, userInfo: { ...state.userInfo, ...payload } };
    case BILL_AUTHENTICATION_FAILED: {
      return { ...state, ...SESSION_STATE };
    }
    case BILL_AUTHENTICATION_STARTED:
      return { ...state, ...SESSION_STATE, isAuthenticating: true };
    case LOGOUT:
      /**
       * Setting isLoggingOut=true to trigger the loader in RequireAuth instead
       * of displaying the no-access page
       */
      return { ...state, ...SESSION_STATE, isLoggingout: true };
    case START_SCENARIO_USER_CREATION:
      return { ...state, verificationToken: payload.verificationToken };
    case SET_SELECTED_COMPANY: {
      const [userRole, companyAbilities] = getCompanyUserRoleAndPermissions(
        state.accessibleCompanies,
        payload,
      );
      return {
        ...state,
        permissions: companyAbilities,
        userInfo: { ...state.userInfo, userRole },
      };
    }
    case SET_USER_PREFERENCES:
      return {
        ...state,
        preferences: { ...state.preferences, ...action.payload },
      };
    case UNSET_STATEFUL_ROUTE:
      return { ...state, statefulRoute: null };
    case PERSIST_STATEFUL_ROUTE: {
      const { route, companyId, scenarioId, fullPath } = payload;
      if (companyId === null && scenarioId === null) {
        const error = new Error(
          'companyId and scenarioId must be provided when persisting stateful route.',
        );
        datadogRum.addError(error, {
          source: 'reducers/auth',
          action: PERSIST_STATEFUL_ROUTE,
        });
        return state;
      }
      const queryParams = new URLSearchParams({
        companyId: String(companyId),
        scenarioId: String(scenarioId),
      });
      return {
        ...state,
        statefulRoute: {
          pathname: route,
          fullPath,
          search: `?${queryParams}`,
        },
      };
    }
    case SET_TMX_SESSION_ID:
      return { ...state, deviceSessionId: action.payload };
    case AUTHENTICATION_DECLINED_BY_TMX:
      return { ...state, isUnauthorizedByTMX: action.payload };
    default:
      return state;
  }
};

export default auth;
