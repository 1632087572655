import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import keyConstants from '@/constants/keys';
import { classNames } from '@/helpers';
import Button from './Button';
import DropdownMenu from './DropdownMenu';
import './Dropdown.scss';

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
const Dropdown = ({
  'data-testid': dataTestId,
  id,
  buttonSlot,
  children,
  className,
  buttonClass,
  direction = 'left',
  disabled = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const wrapperRef = useRef(null);
  const menuRef = useRef(null);

  const handleToggleClick = (event) => {
    event.stopPropagation();
    setIsExpanded((wasExpanded) => !wasExpanded);
  };

  const handleClick = (event, componentRef, optionMenuRef) => {
    if (!componentRef || !optionMenuRef) return;

    // Detect click on a disable option and ignore it
    if (event.target.id === optionMenuRef.current.id) {
      return;
    }
    setIsExpanded(false);
  };

  const handleClose = (event, componentRef) => {
    if (!componentRef.current?.contains(event.target)) {
      setIsExpanded(false);
    }
  };

  const handleKeydown = ({ key }) => {
    if (key === keyConstants.ARROW_DOWN) {
      setIsExpanded(true);
    } else if (key === keyConstants.ESCAPE) {
      setIsExpanded(false);
    }
  };

  const handleClickWrapper = (event) => {
    if (!disabled) {
      handleClick(event, wrapperRef, menuRef);
    }
  };

  const handleCloseWrapper = (event) => {
    handleClose(event, wrapperRef);
  };
  const testId = dataTestId ?? id;

  useEffect(() => {
    document.addEventListener('click', handleCloseWrapper, true);
    return () => {
      document.removeEventListener('click', handleCloseWrapper, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, []);

  return (
    <div
      id={id}
      className={classNames('Dropdown_Wrapper', className)}
      onClick={handleClickWrapper}
      onKeyDown={handleKeydown}
      data-testid={testId}
      ref={wrapperRef}
    >
      <Button
        id={`${id}-trigger`}
        type="button"
        aria-haspopup="true"
        aria-expanded={isExpanded}
        className={classNames('Dropdown_Trigger', buttonClass)}
        onClick={handleToggleClick}
        data-testid={`${testId}-trigger`}
        disabled={disabled}
      >
        {buttonSlot}
      </Button>
      <DropdownMenu
        id={`${id}-menu`}
        align={direction}
        expanded={isExpanded}
        aria-labelledby={`${id}-trigger`}
        ref={menuRef}
      >
        {children}
      </DropdownMenu>
    </div>
  );
};

Dropdown.propTypes = {
  /** The ID for the Button that triggers the dropdown */
  'id': PropTypes.string.isRequired,
  /** The content that appears within the button */
  'buttonSlot': PropTypes.element.isRequired,
  /** The Dropdown options */
  'children': PropTypes.node,
  /** The className to apply to the component */
  'className': PropTypes.string,
  /** CSS class name of the Button type to use. Defaults to "Button" */
  'buttonClass': PropTypes.string,
  /** Where to align the Dropdown Menu. Defaults to "left" */
  'direction': PropTypes.oneOf(['left', 'right']),
  /** Unique ID for selecting the element in unit/integration tests */
  'disabled': PropTypes.bool,
  /** Identified to enable disable toggle functionality of dropdown parent div */
  'data-testid': PropTypes.string,
};

export default Dropdown;
