// @ts-check
import { useState, useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import CheckmarkSmallIcon from '@bill/cashflow.assets/checkmark-small';
import EditRoundIcon from '@bill/cashflow.assets/edit-round';
import { SET_TITLE } from '@/components/Reports/ExportableReport/constants';
import FormField from '@/components/common/FormField';
import IconButton from '@/components/common/IconButton';
import useExportableReportContext from '@/contexts/useExportableReportContext';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import useCurrentSelectedCompany from '@/hooks/useCurrentSelectedCompany';
import './SectionHeader.scss';

/**
 * Renders Header Of the section
 *
 * @example
 *   <SectionHeader id={section.id} />;
 *
 * @typedef {{
 *   section: import('../ReportContentContainer').Section;
 *   hideSubtitle?: boolean;
 *   headerControl?: React.ReactElement;
 *   isFirstPage?: boolean;
 * }} SectionHeaderProps
 * @type {(props: SectionHeaderProps) => React.ReactElement}
 */
const SectionHeader = ({
  section,
  hideSubtitle = false,
  isFirstPage = false,
  headerControl,
}) => {
  const { startDate, endDate } = useSelector(({ shared }) => shared);
  const selectedCompany = useCurrentSelectedCompany();
  const [editMode, setEditMode] = useState(false);
  const {
    isEditControlVisible,
    setEditableFields,
    reportState,
    setReportState,
  } = useExportableReportContext();

  const fieldId = `${section.id}-title-field`;
  const { title } = reportState[section.id].content;

  useEffect(() => {
    setEditableFields((prevState) => ({
      ...prevState,
      [fieldId]: editMode,
    }));
  }, [setEditableFields, editMode, fieldId]);

  return (
    <div className="SectionHeader_Container">
      <div className="SectionHeader_TitleContainer">
        {editMode ? (
          <FormField
            type="text"
            id={fieldId}
            value={title}
            className="SectionHeader_TitleField"
            maxLength={50}
            onChange={({ target }) => {
              setReportState({
                type: SET_TITLE,
                setTitle: {
                  id: section.id,
                  title: target.value.trimStart(),
                },
              });
            }}
          >
            <IconButton
              label="Save"
              data-testid={`${section.id}-save-title-button`}
              className="ExportableReport_Icon ExportableReport_Icon-save"
              onClick={() => setEditMode(false)}
              Icon={CheckmarkSmallIcon}
            />
          </FormField>
        ) : (
          <h4 className="SectionHeader_Title">
            {title}{' '}
            <sup className="SectionHeader_TitleEdit">
              {isEditControlVisible && isFirstPage && (
                <IconButton
                  label="Edit Title"
                  data-testid={`${section.id}-edit-title-button`}
                  className="ExportableReport_Icon"
                  onClick={() => setEditMode(true)}
                  Icon={EditRoundIcon}
                />
              )}
            </sup>
          </h4>
        )}
        {!hideSubtitle && (
          <p className="SectionHeader_Subtitle">
            {selectedCompany.name} |{' '}
            {formatRangeWithShortYear(startDate, endDate)}
          </p>
        )}
      </div>
      {isEditControlVisible && headerControl && <div>{headerControl}</div>}
    </div>
  );
};

export default SectionHeader;
