import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { YAxis } from 'react-jsx-highcharts';
import { connect } from 'react-redux';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import PlanName from '@/components/Revenue/PlanName';
import GridInput from '@/components/Revenue/RevenueStream/Steps/GridInput';
import PopupButton from '@/components/Revenue/RevenueStream/Steps/PopupButton';
import SumGridInput from '@/components/Revenue/RevenueStream/Steps/SumGridInput';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import Select from '@/components/common/Select';
import WithTooltip from '@/components/common/WithTooltip';
import { createMonthsFromYears } from '@/helpers/createMonthsofYears';
import { isCustomRevenueValid } from '@/helpers/customRevenue';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import metricFormatters from '@/helpers/metricFormatters';
import getBaseScenario from '@/selectors/getBaseScenario';
import getChartSeries from '@/selectors/getChartSeries';

const currentMonth = new Date();
currentMonth.setUTCDate(1);

const CustomGrowthDriverValues = ({
  label,
  products,
  updateProducts,
  setIsStreamValid,
  scenario,
  scenarioId,
  selectedScenario,
}) => {
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const chartData = useMemo(() => {
    const value =
      selectedMonth &&
      selectedProduct &&
      selectedProduct.custom &&
      selectedProduct.custom.length > 0
        ? selectedProduct.custom.find(
            (c) =>
              Number(c.year) === selectedMonth.getUTCFullYear() ||
              Number(c.year) ===
                Number(selectedMonth.getUTCFullYear().toString().substr(-2)),
          ).months
        : [];
    const data = getChartSeries(
      scenarioId,
      mapMonthlyData(value, 'noOfCustomer'),
      { scenario },
    );
    return data;
  }, [scenario, scenarioId, selectedMonth, selectedProduct]);

  useEffect(() => {
    setShow(false);
  }, [selectedMonth, selectedProduct]);
  const substituteData = useCallback((data) => {
    return data.map((p) => {
      return {
        ...p,
        custom:
          p.custom && p.custom.length
            ? p.custom
            : createMonthsFromYears(4, 'noOfCustomer'),
      };
    });
  }, []);
  useEffect(() => {
    if (products.length) {
      const updatedProducts = substituteData(products);
      updateProducts(updatedProducts);
      setSelectedProduct(updatedProducts[0]);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, []);

  useEffect(() => {
    const { custom } = selectedProduct || {};
    setIsStreamValid(isCustomRevenueValid(custom, 'noOfCustomer'));
  }, [selectedProduct, setIsStreamValid]);

  const updateCustomerPerMonth = ({ value: noOfCustomer, year, month, id }) => {
    const updatedSelectedProduct = structuredClone(selectedProduct);
    const updatedYear = updatedSelectedProduct.custom.find(
      (c) => c.year === year,
    );
    const updatedMonth = updatedYear.months.find((m) => m.value === month);

    // updates noOfCustomer within selectedProduct
    if (noOfCustomer === null) {
      const numberOfCustomers = Math.trunc(updatedMonth.noOfCustomer);
      updatedYear.months = updatedYear.months.map((m) => {
        if (m.value > month) {
          return { ...m, noOfCustomer: numberOfCustomers };
        }
        return { ...m };
      });
    } else {
      updatedMonth.noOfCustomer = noOfCustomer;
    }
    setSelectedProduct(updatedSelectedProduct);

    // updates products with updated selectedProduct
    const updatedProducts = structuredClone(products);
    const index = updatedProducts.findIndex((p) => p.id === id);
    if (index > -1) {
      updatedProducts[index] = updatedSelectedProduct;
    }
    updateProducts(updatedProducts);
  };

  const metrics = [
    {
      key: 'y',
      isMainMetric: true,
      name: label,
      formatter: metricFormatters.count,
    },
  ];

  return (
    <>
      <Row>
        <Col md={6} className="pr-2 pb-4">
          <Form.Group className="fixed-pricing-plan-width">
            <Form.Label className="align-field-text">Pricing Plan</Form.Label>
            <WithTooltip
              content={
                selectedProduct && <PlanName product={selectedProduct} />
              }
              placement="bottom"
            >
              <Select
                value={selectedProduct && selectedProduct.id}
                name="departmentId"
                onChange={(e) => {
                  setSelectedProduct(
                    products.find(
                      (p) => Number(p.id) === Number(e.target.value),
                    ),
                  );
                }}
              >
                {products.map((product) => {
                  const planName = `${product.productName} | ${product.name}`;
                  return (
                    <option value={product.id} key={product.id}>
                      {planName}
                    </option>
                  );
                })}
              </Select>
            </WithTooltip>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={12}>
          <div className="white-container">
            <p className="heading mb-0 graph-heading">
              {selectedMonth.getUTCFullYear()}
            </p>
            <div className="graph">
              {loading ? (
                <LoadingSpinner />
              ) : (
                <DateChart
                  data-testid="custom-monthly-chart"
                  tooltip={
                    <ChartTooltip
                      metrics={metrics}
                      valueFormatter={(value) => metricFormatters.count(value)}
                    />
                  }
                >
                  <YAxis>
                    {!!chartData?.data.length && (
                      <DateChart.Series
                        data={chartData.data}
                        index={0}
                        metric={label}
                        scenario={selectedScenario}
                      />
                    )}
                  </YAxis>
                </DateChart>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <div data-testid="custom-rev-grid">
        {selectedProduct?.custom?.length > 0 &&
          selectedProduct.custom.map((yearInfo) => {
            return (
              <Row key={yearInfo.year.toString()}>
                <Col md={11}>
                  <Row>
                    {yearInfo.months.map((month) => {
                      return (
                        <Col
                          md={1}
                          className="GridInput_Container"
                          key={month.name + month.year}
                        >
                          <Form.Group className="grid-field-opt mb-4 text-center">
                            <GridInput
                              month={month}
                              year={yearInfo.year}
                              updateValue={updateCustomerPerMonth}
                              customId={selectedProduct.id}
                              valueKey="noOfCustomer"
                              onClick={() =>
                                setSelectedMonth(new Date(month.month))
                              }
                              id={month.month}
                            />
                            {selectedMonth.getTime() ===
                              new Date(month.month).getTime() &&
                              selectedMonth.getUTCMonth() < 11 && (
                                <PopupButton
                                  target={target}
                                  setShow={setShow}
                                  show={show}
                                  year={yearInfo.year}
                                  month={month}
                                  customId={selectedProduct.id}
                                  setValues={updateCustomerPerMonth}
                                />
                              )}
                          </Form.Group>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col md={1} className="GridInput_Container">
                  <SumGridInput
                    year={yearInfo.year}
                    selectedRevenue={selectedProduct?.custom}
                    valueKey="noOfCustomer"
                    id={String(yearInfo.year)}
                  />
                </Col>
              </Row>
            );
          })}
      </div>
    </>
  );
};

function mapStateToProps({ scenario }) {
  return {
    scenarioId: scenario.scenarioId,
    scenario,
    selectedScenario: getBaseScenario({ scenario }),
  };
}
export default connect(mapStateToProps)(CustomGrowthDriverValues);
