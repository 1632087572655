// @ts-check
import {
  SET_AI_CONVERSATION_EXPORTABLE_REPORT_METADATA,
  SET_AI_CONVERSATION_COPILOT_METADATA,
  SET_AI_CONVERSATION_COPILOT_WARNING_MODAL,
} from '@/actionTypes/aiConversation';

/**
 * @typedef {{
 *   conversationId: string | null;
 *   logTrailId: string | null;
 *   logTrailIdWithFeedback: string | null;
 * }} ExportableReportAiConversationMetadata
 */

/**
 * @typedef {{
 *   conversationId: string | null;
 *   showWarningModal: boolean;
 *   warningModalConfirmationAction?: import('@/actions/aiConversation').CopilotConfirmationType;
 *   pendingScenarioId?: string;
 *   pendingCompanySwitcherLink?: string;
 * }} CopilotAiConversationMetadata
 */

/**
 * @typedef {{
 *   showWarningModal: boolean;
 *   warningModalConfirmationAction?: import('@/actions/aiConversation').CopilotConfirmationType;
 *   pendingScenarioId?: string;
 *   pendingCompanySwitcherLink?: string;
 * }} CopilotWarningModalPayload
 */

/**
 * @typedef {{
 *   exportableReport: ExportableReportAiConversationMetadata;
 *   copilot: CopilotAiConversationMetadata;
 * }} AiConversationState
 */

/** @typedef {keyof AiConversationState} AiConversationType */

/** @type {AiConversationState} */
const INITIAL_STATE = {
  exportableReport: {
    conversationId: null,
    logTrailId: null,
    logTrailIdWithFeedback: null,
  },
  copilot: {
    conversationId: null,
    showWarningModal: false,
  },
};

/**
 * @type {(
 *   state: AiConversationState,
 *   action: { payload: unknown; type: string },
 * ) => AiConversationState}
 */
const aiConversation = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AI_CONVERSATION_EXPORTABLE_REPORT_METADATA: {
      const { payload } = /**
       * @type {{
       *   payload: ExportableReportAiConversationMetadata & {
       *     conversationType: string;
       *   };
       * }} *
       */ (action);
      return {
        ...state,
        exportableReport: {
          conversationId: payload.conversationId,
          logTrailId: payload.logTrailId,
          logTrailIdWithFeedback: payload.logTrailIdWithFeedback,
        },
      };
    }
    case SET_AI_CONVERSATION_COPILOT_METADATA: {
      const { payload } = /**
       * @type {{
       *   payload: CopilotAiConversationMetadata & {
       *     conversationType: string;
       *   };
       * }} *
       */ (action);
      return {
        ...state,
        copilot: {
          ...INITIAL_STATE.copilot,
          conversationId: payload.conversationId,
        },
      };
    }
    case SET_AI_CONVERSATION_COPILOT_WARNING_MODAL: {
      const { payload } = /**
       * @type {{
       *   payload: CopilotWarningModalPayload;
       * }} *
       */ (action);
      return {
        ...state,
        copilot: {
          ...state.copilot,
          showWarningModal: payload.showWarningModal,
          warningModalConfirmationAction:
            payload.warningModalConfirmationAction ?? null,
          pendingCompanySwitcherLink:
            payload.pendingCompanySwitcherLink ?? null,
          pendingScenarioId: payload.pendingScenarioId ?? null,
        },
      };
    }

    default:
      return state;
  }
};
export default aiConversation;
