import { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loginAction, setTMXSessionId } from '@/actions/auth';
import { setCompaniesOnLoginAction } from '@/actions/companies';
import CountryRequiredModal from '@/components/Landing/CountryRequiredModal';
import Button from '@/components/common/Button';
import PasswordField from '@/components/common/PasswordField';
import { DASHBOARD_PATH, UNAUTHORIZED_PATH } from '@/constants/pages';
import withProtectedRoute from '@/pages/Registration/WithProtectedRoute';
import { getCountryLockoutDetails } from '@/services/complaince.service';
import { actions, steps, routeProtections } from './constants';

const Login = ({
  email,
  loginPassword,
  onChange,
  loginUser,
  setCompaniesOnLogin,
  isAuthenticated,
}) => {
  const history = useHistory();
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const { deviceSessionId, isUnauthorizedByTMX } = useSelector(
    ({ auth }) => auth,
  );
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [showCountryRequiredModal, setShowCountryRequiredModal] =
    useState(false);
  const { country } = useSelector(({ auth }) => auth.userInfo);
  useEffect(() => {
    onChange({ type: actions.SET_STEP, payload: steps.LOGIN });
  }, [onChange]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMsg('');
    try {
      await loginUser({
        emailAddress: email,
        password: loginPassword,
        deviceSessionId,
      });
    } catch ({ message }) {
      setLoading(false);
      setErrorMsg(message);
    }
  };
  const redirectOnLogin = useCallback(() => {
    history.replace(DASHBOARD_PATH);
  }, [history]);

  const getIsCountryLockout = useCallback(async () => {
    const lockoutDetails = await getCountryLockoutDetails();
    const isCountryLocked = lockoutDetails.data.data.isCountryComplianceEnabled;
    if (isCountryLocked) {
      setShowCountryRequiredModal(true);
    } else {
      redirectOnLogin();
    }
  }, [redirectOnLogin]);

  useEffect(() => {
    if (isAuthenticated) {
      setCompaniesOnLogin();
      if (country) {
        redirectOnLogin();
      } else {
        getIsCountryLockout();
      }
    }

    if (isUnauthorizedByTMX) {
      history.replace(UNAUTHORIZED_PATH);
    }
  }, [
    isAuthenticated,
    setCompaniesOnLogin,
    redirectOnLogin,
    country,
    history,
    isUnauthorizedByTMX,
    getIsCountryLockout,
  ]);

  useEffect(() => {
    dispatch(setTMXSessionId());
  }, [dispatch]);

  return (
    <>
      {showCountryRequiredModal && (
        <CountryRequiredModal onSuccess={redirectOnLogin} />
      )}
      <h1 className="RegistrationHeading">Login to Finmark</h1>
      <p className="RegistrationText">
        Congratulations! It looks like you're already within the Finmark system.
        All you have to do now is log in with your Finmark password.
      </p>
      <form className="RegistrationContent" onSubmit={handleSubmit}>
        <div className="Registration_FieldGroup">
          <label htmlFor="password" className="RegistrationLabel">
            Password
          </label>
          <PasswordField
            id="password"
            name="password"
            className="RegistrationField-password"
            value={loginPassword}
            placeholder="Password"
            onChange={({ target }) =>
              onChange({
                type: actions.SET_PASSWORD_LOGIN,
                payload: target.value,
              })
            }
            validate={(value) => !value && 'Please enter a password'}
            autoFocus
          />
        </div>
        {errorMsg && <div className="RegistrationAlert">{errorMsg}</div>}
        <Button
          type="submit"
          id="submit-login"
          className="Button-registration"
          data-testid="button-next-login"
          loading={isLoading}
          disabled={!loginPassword}
        >
          Login
        </Button>
      </form>
    </>
  );
};

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
});

const ConnectedLogin = connect(mapStateToProps, {
  loginUser: loginAction,
  setCompaniesOnLogin: setCompaniesOnLoginAction,
})(Login);

export default withProtectedRoute(ConnectedLogin, {
  protectedBy: routeProtections.EMAIL,
});
