// @ts-check
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BackArrowIcon from '@bill/cashflow.assets/back-arrow';
import SaveIcon from '@bill/cashflow.assets/save-icon';
import VarianceIcon from '@bill/cashflow.assets/variance-icon';
import TheGlobalDatePickerOneTheme from '@bill/cashflow.react/global-date-picker';
import { resetTimePeriodAction, setDate } from '@/actions/shared';
import TheGlobalDatePicker from '@/components/Datepicker/TheGlobalDatePicker';
import Button from '@/components/common/Button';
import Checkbox from '@/components/common/Checkbox';
import IconButton from '@/components/common/IconButton';
import LetterIcon from '@/components/common/LetterIcon';
import { CASH_IN_OUT, REPORTS_PATH } from '@/constants/pages';
import useExportableReportContext from '@/contexts/useExportableReportContext';
import { classNames } from '@/helpers';
import { FPA_LITE_MAX_DATE, MAX_DATE } from '@/helpers/dateFormatter';
import useIsFPALite from '@/hooks/useIsFPALite';
import useOneColor from '@/hooks/useOneColor';
import useTypedSelector from '@/hooks/useTypedSelector';
import getScenarioById from '@/selectors/getScenarioById';
import './ReportHeader.scss';

/**
 * @typedef {{
 *   onExport: () => void;
 *   isDisabled: boolean;
 * }} ExportButtonProps
 */

/** @type {(props: ExportButtonProps) => React.ReactElement} */
const ExportButton = ({ onExport, isDisabled }) => {
  const { isExporting } = useExportableReportContext();
  return (
    <Button
      data-testid="export-reports-button"
      loading={isExporting}
      className="ExportableReport_ActionBtn"
      onClick={onExport}
      disabled={isDisabled}
    >
      Export as PDF
    </Button>
  );
};

/**
 * Renders Exportable Report Header
 *
 * @example
 *   <ReportHeader scenario={scenario} />;
 *
 * @typedef {{
 *   scenario: object;
 *   title: string;
 *   onSave: () => void;
 *   onExport: () => void;
 *   isSaving: boolean;
 *   hasWritePermission: boolean;
 *   fetchingQueryCount: number;
 * }} ReportHeaderProps
 * @type {(props: ReportHeaderProps) => React.ReactElement}
 */
const ReportHeader = ({
  scenario,
  title,
  onExport,
  onSave,
  isSaving,
  hasWritePermission,
  fetchingQueryCount,
}) => {
  const history = useHistory();
  const {
    exportableReportPreferences,
    setPreferences,
    editableFields,
    isExporting,
  } = useExportableReportContext();

  const hasFieldsInEditMode = Object.values(editableFields).includes(true);
  const isExportDisabled = hasFieldsInEditMode;
  const isSaveDisabled = hasFieldsInEditMode || isSaving || isExporting;
  const isFpaLiteUser = useIsFPALite();

  // eslint-disable-next-line no-shadow -- predates description requirement
  const baseScenario = useSelector(({ scenario }) =>
    getScenarioById(scenario.scenarioId, { scenario }),
  );
  // eslint-disable-next-line no-shadow -- predates description requirement
  const compareScenario = useSelector(({ scenario }) =>
    getScenarioById(scenario.compareScenarioId, { scenario }),
  );

  const globalStartDate = useTypedSelector(({ shared }) => shared.startDate);
  const globalEndDate = useTypedSelector(({ shared }) => shared.endDate);
  const globalTimePeriod = useTypedSelector(({ shared }) => shared.timePeriod);

  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const isOneColorEnabled = useOneColor();
  const hasComparison = !!compareScenario && compareScenario !== baseScenario;

  const isLiteProduct = useIsFPALite();
  const maxDate = isLiteProduct ? FPA_LITE_MAX_DATE : MAX_DATE;

  return (
    <header className={classNames('ExportableReportHeader', 'ThePageHeader')}>
      <div className="ExportableReportHeader_CtrlSegment">
        <button
          className="Button-iconOnly ExportableReportHeader_BackButton"
          onClick={() => {
            history.push(isFpaLiteUser ? `${CASH_IN_OUT}` : `${REPORTS_PATH}`);
          }}
          disabled={isExporting}
          aria-label="Back"
        >
          <BackArrowIcon className="ExportableReportHeader_BackIcon" />
        </button>
      </div>
      <h1 className="ExportableReportHeader_Title">{title}</h1>
      <div className="ExportableReportHeader_CtrlSegment">
        <h1 className="ExportableReportHeader_ScenarioName">
          {' '}
          <LetterIcon
            string={scenario?.name || ''}
            className="ExportableReportHeader_ScenarioIcon"
            color={scenario?.color}
            data-testid="exportable-report-scenario-icon"
          />{' '}
          {scenario?.name}
        </h1>
      </div>
      <div className="ThePageHeader_Buttons">
        {hasComparison && (
          <div className="ReportHeader_VarianceWrapper">
            <VarianceIcon className="ReportHeader_VarianceIcon" />
            <div className="ReportHeader_VarianceText">Show Variance:</div>
            <Checkbox
              id="exportable-report-variance-checkbox"
              disabled={isExporting}
              checked={exportableReportPreferences.showVariance}
              onChange={() => {
                setPreferences((prevState) => ({
                  showVariance: !prevState.showVariance,
                }));
              }}
            />
          </div>
        )}
        {isOneColorEnabled ? (
          <TheGlobalDatePickerOneTheme
            startTime={globalStartDate}
            endTime={globalEndDate}
            timePeriod={globalTimePeriod}
            maxDate={maxDate}
            isTimePeriodsEnabled
            resetTimePeriod={() => dispatch(resetTimePeriodAction())}
            onDateChange={({ startTime, endTime, timePeriod }) =>
              dispatch(setDate(startTime, endTime, timePeriod))
            }
          />
        ) : (
          <TheGlobalDatePicker />
        )}
        {hasWritePermission && (
          <IconButton
            className="ThePageHeader_Button ExportableReportHeader_SaveReportBtn"
            data-testid="save-report"
            Icon={SaveIcon}
            disabled={isSaveDisabled}
            label="Save Report"
            onClick={onSave}
          />
        )}
      </div>
      <ExportButton
        onExport={onExport}
        isDisabled={isExportDisabled || fetchingQueryCount > 0}
      />
    </header>
  );
};

export default ReportHeader;
