// @ts-check
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TheGlobalDatePickerOneTheme from '@bill/cashflow.react/global-date-picker';
import { resetTimePeriodAction, setDate } from '@/actions/shared';
import { setUiStateAction } from '@/actions/ui';
import CopilotButton from '@/components/Copilot/CopilotButton';
import TheGlobalDatePicker from '@/components/Datepicker/TheGlobalDatePicker';
import NotificationCenter from '@/components/NotificationCenter/NotificationCenter';
import ProductTypeFilter from '@/components/ProductTypeFilter/ProductTypeFilter';
import ScenarioSelection from '@/components/Scenarios/ScenarioSelection';
import PAGES, { SETTINGS_PATH } from '@/constants/pages';
import { actions, subjects } from '@/constants/permissions';
import { classNames } from '@/helpers';
import { FPA_LITE_MAX_DATE, MAX_DATE } from '@/helpers/dateFormatter';
import isBillDomain from '@/helpers/isBillDomain';
import useIsFPALite from '@/hooks/useIsFPALite';
import useOneColor from '@/hooks/useOneColor';
import usePermissions from '@/hooks/usePermissions';
import useTypedSelector from '@/hooks/useTypedSelector';
import TheUserMenu from './TheUserMenu';
import './PageHeader.scss';

const { READ, READ_WRITE } = actions;
const { NON_DASHBOARD, EMPLOYEE_SETTINGS } = subjects;

/**
 * Renders the icon, title and context buttons for the given page
 *
 * @example
 *   <PageHeader
 *     addBtnSlot={
 *       <ButtonAdd>
 *         <DropdownOption>Option</DropdownOption>
 *       </ButtonAdd>
 *     }
 *   />;
 *
 * @type {(props: {
 *   page: keyof typeof PAGES;
 *   addBtnSlot?: React.ReactNode;
 *   className?: string;
 *   includeDatepicker?: boolean;
 * }) => React.ReactElement}
 */

const PageHeader = ({
  page,
  addBtnSlot,
  className,
  includeDatepicker = true,
}) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const { title: Title, titleText } = PAGES[page];
  const copilotExpanded = useSelector(({ ui }) => ui.copilotExpanded);
  const nonDashboardPermissions = usePermissions(READ, NON_DASHBOARD, true);
  const employeePermissions = usePermissions(READ_WRITE, EMPLOYEE_SETTINGS);
  const { pathname } = useLocation();
  const globalStartDate = useTypedSelector(({ shared }) => shared.startDate);
  const globalEndDate = useTypedSelector(({ shared }) => shared.endDate);
  const globalTimePeriod = useTypedSelector(({ shared }) => shared.timePeriod);
  const isOneColorEnabled = useOneColor();
  const isLiteProduct = useIsFPALite();
  const maxDate = isLiteProduct ? FPA_LITE_MAX_DATE : MAX_DATE;

  /** @type {React.ReactNode[]} */
  let controls = [];
  if (addBtnSlot) {
    controls = Array.isArray(addBtnSlot) ? addBtnSlot : [addBtnSlot];
  }

  return (
    <header className={classNames('ThePageHeader', className)}>
      <h1 className="ThePageHeader_Title">{Title ? <Title /> : titleText}</h1>
      <div className="ThePageHeader_Buttons">
        {controls.map((control, idx) => (
          // eslint-disable-next-line react/no-array-index-key -- no unique identifier available
          <div key={idx} className="cashFlow-ThePageHeader_CtrlSegment">
            {control}
          </div>
        ))}
        {includeDatepicker && (
          <div className="cashFlow-ThePageHeader_CtrlSegment">
            {isOneColorEnabled ? (
              <TheGlobalDatePickerOneTheme
                startTime={globalStartDate}
                endTime={globalEndDate}
                timePeriod={globalTimePeriod}
                maxDate={maxDate}
                isTimePeriodsEnabled
                resetTimePeriod={() => dispatch(resetTimePeriodAction())}
                onDateChange={({ startTime, endTime, timePeriod }) =>
                  dispatch(setDate(startTime, endTime, timePeriod))
                }
              />
            ) : (
              <TheGlobalDatePicker />
            )}
          </div>
        )}
        <div className="cashFlow-ThePageHeader_CtrlSegment">
          {nonDashboardPermissions && employeePermissions && (
            <ProductTypeFilter>
              <CopilotButton
                onClick={() => {
                  dispatch(
                    setUiStateAction({ copilotExpanded: !copilotExpanded }),
                  );
                }}
              />
            </ProductTypeFilter>
          )}
          <NotificationCenter />
          {!isBillDomain() && <TheUserMenu />}
        </div>
      </div>
      {pathname !== SETTINGS_PATH && isOneColorEnabled && (
        <div className="ThePageHeader_ScenarioContainer">
          <ScenarioSelection />
        </div>
      )}
    </header>
  );
};

export default PageHeader;
