// @ts-check
import OnboardingChecklist from './OnboardingChecklist';
import './OnboardingProcessing.scss';

/**
 * @typedef {{
 *   title: string;
 *   subTitle: string;
 * }} OnboardingProcessingText
 */

/** @type {OnboardingProcessingText} */
export const onboardingProcessingText = {
  title: 'Setting up Cash Flow Forecasting...',
  subTitle:
    'This process may require some time. Take a moment to relax or check back later; an email notification will be sent once the process is complete.',
};

/** @type {OnboardingProcessingText} */
export const onboardingPendingRolesText = {
  title: 'Setup is complete. Waiting for role assignment by Admin.',
  subTitle:
    "Please wait while your admin assigns a role to you in Forecasting. If it's been a long wait, reach out to your admin.",
};

/**
 * @type {(props: {
 *   isOnboardingComplete: boolean;
 *   currentOnboardingStatus: import('@/types/services/backend').CompanyOnboardingStatusDto['onboardingStatus'];
 * }) => React.ReactElement}
 */
export default function OnboardingProcessing({
  isOnboardingComplete,
  currentOnboardingStatus,
}) {
  const { title, subTitle } = isOnboardingComplete
    ? onboardingPendingRolesText
    : onboardingProcessingText;

  return (
    <div className="OnboardingProcessing" data-testid="processing-onboarding">
      <h1 className="OnboardingProcessing_Title">{title}</h1>
      <p className="OnboardingProcessing_SubTitle">{subTitle}</p>
      <OnboardingChecklist currentOnboardingStatus={currentOnboardingStatus} />
      <p className="OnboardingProcessing_BodyTitle">What’s Happening?</p>
      <p className="OnboardingProcessing_BodySubTitle">
        We're importing all the essentials from your accounting software to set
        up Cash Flow Forecasting – think income and expense details, chart of
        accounts and beyond. Once the setup is complete, you’ll have visibility
        into your cash flow and a suite of financial planning tools at your
        fingertips.
      </p>
    </div>
  );
}
