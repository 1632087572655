// eslint-disable-next-line import/no-cycle -- predates description requirement
import Api from './Api';

/**
 * Forgot Password
 *
 * @type {(
 *   email: string
 * ) => Promise<import('axios').AxiosResponse<import('@/types/services/backend').RestResponseDtoResetPasswordDto>}
 */
export const forgotPasswordService = (email) => {
  return Api.post(
    '/users/credentials/reset',
    {
      emailAddress: email,
    },
    // Adding validateStatus config to prevent axios from throwing an error on 4xx status
    { headers: false, validateStatus: (status) => status < 500 },
  );
};

/**
 * Reset User Password
 *
 * @type {(
 *   resetPasswordToken: string,
 *   temporaryPassword: string,
 *   newPassword: string,
 *   confirmNewPassword: string
 * ) => Promise<import('axios').AxiosResponse<import('@/types/services/backend').RestResponseDtoUserCompanyDto>}
 */
export const resetPasswordService = (
  resetPasswordToken,
  temporaryPassword,
  newPassword,
  confirmNewPassword,
) => {
  return Api.post(
    '/users/credentials/resetUserPassword',
    {
      resetPasswordToken,
      temporaryPassword,
      newPassword,
      confirmPassword: confirmNewPassword,
    },
    { headers: false },
  );
};

// eslint-disable-next-line jsdoc/require-jsdoc -- predates description requirement
export const verifyScenario = ({ userId, verificationToken, companyId }) => {
  return Api.post(
    `/users/verification/share/scenario/${userId}`,
    {
      verificationToken,
      companyId: Number(companyId),
    },
    { headers: false },
  );
};

/**
 * Create User from Scenario Invite
 *
 * @type {(params: import('@/types/services/backend').ShareScenarioPasswordDto & {userId: string})=>Promise<import('axios').AxiosResponse<import('@/types/services/backend').RestResponseDtoUserRegistrationResponseDto>}
 */
export const createFromScenario = (shareScenarioData) => {
  return Api.post(
    `/users/credentials/share/scenario/${shareScenarioData.userId}`,
    shareScenarioData,
    { headers: false },
  );
};

/**
 * Check if an email address is already register with Finmark
 *
 * @param {string} emailAddress - A user's email address
 * @returns {Promise} API response
 */
export const checkIsRegistered = (emailAddress) => {
  return Api.post('/users/emailExists', { emailAddress }, { headers: false });
};

/**
 * Add a new user without sending a verification email.
 *
 * @param {Object} params Properties of the user to be added
 * @returns {Promise} API response
 */
export const createAccountWithoutVerification = (params) => {
  return Api.post('/users/newSignUp', params, { headers: false });
};
