import PropTypes from 'prop-types';
import { classNames } from '@/helpers';
import './EmptyData.scss';

/**
 * Placeholder to show inside tables when there's no data to display
 *
 * @example
 *   <EmptyData dataType="employee" />;
 */
const EmptyData = ({
  Icon,
  'data-testid': dataTestId,
  heading = 'No Data Available',
  children,
  className,
  onAdd,
}) => (
  <div
    className={classNames('EmptyData', onAdd && 'EmptyData-withAdd', className)}
    data-testid={dataTestId}
  >
    <Icon className="EmptyData_Icon" onClick={onAdd} />
    <h5 className="EmptyData_Heading">{heading}</h5>
    <p className="EmptyData_Text">{children}</p>
  </div>
);

EmptyData.propTypes = {
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string,
  /** Class(es) to apply to the element */
  'className': PropTypes.string,
  /** Custom heading to display */
  'heading': PropTypes.string,
  /** The custom text to display */
  'children': PropTypes.node,
  /** The custom icon to display */
  'Icon': PropTypes.elementType.isRequired,
  /** A callback function for 'click' event */
  'onAdd': PropTypes.func,
};

export default EmptyData;
