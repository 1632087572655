import { useEffect } from 'react';

/**
 * Adds the onBlur and onKeyDown event listeners on the input, removes the
 * listener on unmount Useful when custom functionality implemented on Escape,
 * Tab and Enter key presses.
 *
 * @param {Object} input reference to the input field
 * @param {Function} onBlur The onBlur callback function
 * @param {Function} onKeyDown The onKeyDown callback function
 */
const useInputBlurKeyDownListener = (input, onBlur, onKeyDown) => {
  useEffect(() => {
    input.current.addEventListener('blur', onBlur);
    input.current.addEventListener('keydown', onKeyDown);
    return () => {
      input.current.removeEventListener('blur', onBlur);
      /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
      input.current.removeEventListener('keydown', onKeyDown);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [onBlur, onKeyDown]);
};

export default useInputBlurKeyDownListener;
