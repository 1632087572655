import PropTypes from 'prop-types';
import { classNames } from '@/helpers';

/**
 * @typedef {{
 *   'children': React.ReactNode;
 *   'onClick': React.MouseEventHandler<HTMLButtonElement>;
 *   'data-testid': string;
 *   'className'?: string;
 *   'danger'?: boolean;
 *   'disabled'?: boolean;
 * }} ContextMenuOptionProps
 */

/**
 * Renders a single option within a ContextMenu
 *
 * @example
 *   <ContextMenu>
 *     <ContextMenu.Option data-testid="edit" onClick={() => setEdit(foo)}>
 *       Edit
 *     </ContextMenu.Option>
 *     <ContextMenu.Option
 *       data-testid="delete"
 *       danger
 *       onClick={() => setDelete(foo)}
 *     >
 *       Delete
 *     </ContextMenu.Option>
 *   </ContextMenu>;
 *
 * @type {React.FunctionComponent<ContextMenuOptionProps>}
 */
function ContextMenuOption({
  children,
  className,
  danger,
  'data-testid': dataTestId,
  disabled,
  onClick,
}) {
  return (
    <button
      type="button"
      className={classNames(
        'ContextMenuOption',
        danger && 'ContextMenuOption-danger',
        className,
      )}
      data-testid={dataTestId}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

ContextMenuOption.propTypes = {
  /** Label for the option */
  'children': PropTypes.node.isRequired,
  /** Additional class(es) to apply to the option */
  'className': PropTypes.string,
  /** Whether the option is destructive */
  'danger': PropTypes.bool,
  /** Unique ID for selecting the option in unit/integration tests */
  'data-testid': PropTypes.string.isRequired,
  /** Whether or not the option is disabled */
  'disabled': PropTypes.bool,
  /**
   * Handler for when the user clicks on the option
   *
   * @param {Object} event
   */
  'onClick': PropTypes.func.isRequired,
};

export default ContextMenuOption;
