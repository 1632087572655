// @ts-check
import { connect } from 'react-redux';
import { setShowReviewEmployeeModalAction } from '@/actions/employees';
import { classNames } from '@/helpers';
import './NotificationPendingEmployee.scss';

/**
 * @typedef {{
 *   setShowReviewEmployeeModal: (param: boolean) => void;
 *   className?: string;
 * }} NotificationPendingEmployeeProps
 */

/** @type {(props: NotificationPendingEmployeeProps) => React.ReactElement} */

const NotificationPendingEmployee = ({
  setShowReviewEmployeeModal,
  className,
}) => {
  const handleClick = () => {
    setShowReviewEmployeeModal(true);
  };

  return (
    <>
      You have employee records that still require your attention. Click{' '}
      <button
        onClick={handleClick}
        className={classNames(
          className,
          'NotificationPendingEmployee_ActionBtn',
        )}
        data-testid="notification-pending-employee-action-btn"
      >
        here
      </button>{' '}
      to look at them now.
    </>
  );
};

export default connect(null, {
  setShowReviewEmployeeModal: setShowReviewEmployeeModalAction,
})(NotificationPendingEmployee);
