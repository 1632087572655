// @ts-check
import CrossIcon from '@bill/cashflow.assets/cross';
import CompanyDetails from '@/components/Settings/CompanyDetails';
import Sidebar from '@/components/common/Sidebar';
import useNotificationContext from '@/contexts/useNotificationContext';
import './AddUpdateCompanySidebar.scss';

/** @typedef {{ companyId?: number; onClose: () => void }} CompanySidebarContentParams */

/** @typedef {CompanySidebarContentParams & { open: boolean }} AddUpdateCompanySidebarParams */

/**
 * Renders the add/update company content
 *
 * @example
 *   <CompanySidebarContent onClose={onCloseFn} />;
 *
 * @type {(params: CompanySidebarContentParams) => React.ReactElement}
 */
const CompanySidebarContent = ({ companyId, onClose }) => {
  return (
    <div className="AddCompanySidebar_Container">
      <div className="AddCompanySidebar_Panel">
        <header className="Sidebar_Header">
          <h3 className="Sidebar_Title">
            {companyId ? 'Edit Company' : 'Add New Company'}
          </h3>
          <button
            className="Sidebar_CloseBtn"
            data-testid="sidebar-close-btn"
            onClick={onClose}
            aria-label="Close"
          >
            <CrossIcon className="CloseIcon" />
          </button>
        </header>
        <div className="AddCompanySidebar_Content">
          <CompanyDetails
            onFinish={onClose}
            companyId={companyId}
            isCompanyRegistrationIncomplete={!companyId}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Renders the add/update company sidebar
 *
 * @example
 *   <AddUpdateCompanySidebar open={true} onClose={onCloseFn} />;
 *
 * @type {(params: AddUpdateCompanySidebarParams) => React.ReactElement}
 */
const AddUpdateCompanySidebar = ({ open, onClose, companyId }) => {
  const { margin } = useNotificationContext();
  return (
    <Sidebar
      className="AddCompanySidebar"
      style={{ marginTop: margin }}
      open={open}
      onClose={onClose}
    >
      {open && (
        <CompanySidebarContent companyId={companyId} onClose={onClose} />
      )}
    </Sidebar>
  );
};

export default AddUpdateCompanySidebar;
