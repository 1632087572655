import { SET_USER_INFO } from '@/actionTypes/auth';
import { UPDATE_COMPANY } from '@/actionTypes/companies';
import {
  SET_COMPANY_SETTINGS_SUCCESS,
  SET_COMPANY_SETTINGS_ERROR,
  SET_COMPANY_SETTINGS_LOADING,
  SET_USER_SETTINGS_SUCCESS,
  SET_USER_SETTINGS_ERROR,
  SET_USER_SETTINGS_LOADING,
  HANDLE_DATA_CHANGE,
  SET_LOADING,
  SET_INTEGRATIONS_STATUS_SUCCESS,
  SET_INTEGRATIONS_STATUS_ERROR,
  SET_CONNECTION_ERROR,
  CHANGE_USER_PASSWORD_SUCCESS,
  CHANGE_USER_PASSWORD_ERROR,
  CHANGE_USER_PASSWORD_LOADING,
  STRIPE_SESSION_SUCCESS,
  STRIPE_SESSION_ERROR,
  LOAD_MULTIPLIER_ERROR,
  SET_SHARE_COMPANY_ERROR,
  CHANGE_SHARE_COMPANY_LOADING,
  RESET_SHARE_COMPANY_ERROR,
  SET_SHARE_COMPANY_SUCCESS,
  MEMBER_LIST_SUCCESS,
  MEMBER_REMOVE_SUCCESS,
  MEMBER_REMOVE_LOADING,
  MEMBER_DISABLE_INVITE_SUCCESS,
  MEMBER_DISABLE_INVITE_LOADING,
  MEMBER_DISABLE_INVITE_ERROR,
  MEMBER_DISABLE_INVITE_RESET,
  MEMBER_INFO_SUCCESS,
  MEMBER_INFO_RESET,
  SET_PAYMENT_PLANS,
  COLLECTION_TERMS_SUCCESS,
  COLLECTION_TERMS_ERROR,
  PAYMENT_TERMS_SUCCESS,
  PAYMENT_TERMS_ERROR,
} from '@/actionTypes/settings';
import { NOOP } from '@/actionTypes/shared';
import { refreshCompaniesAction } from '@/actions/companies';
import { getExpensesClassesAction } from '@/actions/expenses';
import { actionWithPermission, subscribeToTopic } from '@/actions/shared';
import { BIPAAS_QUICKBOOKS } from '@/constants/integrations';
import { actions, subjects } from '@/constants/permissions';
import { BIPAAS_MANUAL_SYNC_MUTATION } from '@/graphql/mutations';
import { removeParams } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';
import { REACT_APP_STRIPE_CLIENT_REDIRECT } from '@/runtimeConfig';
import {
  getLoadMultiplier,
  updateLoadMultiplier,
  getCompanySettings,
  getUserSettings,
  updateCompanySettings,
  updateUserSettings,
  changePassword,
  getIntegrationsStatus,
  syncIntegration,
  addMember,
  getMembers,
  removeMember,
  disableMemberInvite,
  editMember,
  getMemberInfo,
  getPaymentPlans,
  updatePaymentPlan,
  authorizeIntegration,
  addCustomDepartment,
  editCustomDepartment,
  deleteCustomDepartment,
  getCollectionTerms,
  deleteCollectionTerm,
  addCollectionTerm,
  updateCollectionTerm,
  getPaymentTerms,
  addPaymentTerm,
  updatePaymentTerm,
  deletePaymentTerm,
  cancelExpiredInvite,
} from '@/services/settingsService';
import { createSession } from '@/services/stripe.service';
import { changeLoadingState } from './componentLoading';
import { getPendingEmployeeAction } from './employees';

const setIsLoading = {
  type: SET_LOADING,
  payload: {
    loading: true,
    error: null,
  },
};

const setConnectionsError = (error) => {
  return {
    type: SET_CONNECTION_ERROR,
    payload: error,
  };
};

const setIsNotLoading = {
  type: SET_LOADING,
  payload: { loading: false },
};

const setCompanySettingsError = (e, dispatch) => {
  dispatch({
    type: SET_COMPANY_SETTINGS_ERROR,
    payload: e.response?.data?.error?.errorMessage || e.message,
  });
};

const setCompanySettingsData = ({ data, success }, dispatch) => {
  if (success) {
    dispatch({ type: SET_COMPANY_SETTINGS_SUCCESS, payload: data });
    dispatch(refreshCompaniesAction());
  }
};

export const updateCompanySettingAction = (payload) => {
  return async (dispatch) => {
    dispatch(setIsLoading);
    dispatch({ type: SET_COMPANY_SETTINGS_LOADING, payload: true });
    try {
      const { data } = await updateCompanySettings(payload);
      setCompanySettingsData(data, dispatch);
    } catch (e) {
      setCompanySettingsError(e, dispatch);
    } finally {
      dispatch(setIsNotLoading);
      dispatch({ type: SET_COMPANY_SETTINGS_LOADING, payload: false });
    }
  };
};

export const getCompanySettingAction = () => {
  return async (dispatch) => {
    dispatch(setIsLoading);
    dispatch(setConnectionsError(null));
    try {
      const { data } = await getCompanySettings();
      setCompanySettingsData(data, dispatch);
    } catch (e) {
      dispatch(
        setConnectionsError(e.response?.data?.error?.errorMessage || e.message),
      );
      setCompanySettingsError(e, dispatch);
    } finally {
      dispatch(setIsNotLoading);
      dispatch(changeLoadingState('settings', false));
    }
  };
};
export const getUserSettingAction = () => {
  return async (dispatch) => {
    dispatch(setIsLoading);
    try {
      const {
        data: { data, success },
      } = await getUserSettings();
      if (success) {
        dispatch({ type: SET_USER_SETTINGS_SUCCESS, payload: data });
      }
    } catch (e) {
      dispatch({
        type: SET_USER_SETTINGS_ERROR,
        payload: e.response?.data?.error?.errorMessage || e.message,
      });
    } finally {
      dispatch(setIsNotLoading);
      dispatch(changeLoadingState('settings', false));
    }
  };
};

export const updateUserSettingAction = (payload) => {
  return async (dispatch) => {
    dispatch(setIsLoading);
    dispatch({ type: SET_USER_SETTINGS_LOADING, payload: true });
    try {
      const {
        data: { data, success },
      } = await updateUserSettings(payload);
      if (success) {
        dispatch({ type: SET_USER_SETTINGS_SUCCESS, payload: data });
        dispatch({
          type: SET_USER_INFO,
          payload: { fullName: `${data.firstName} ${data.lastName}` },
        });
      }
    } catch (e) {
      dispatch({
        type: SET_USER_SETTINGS_ERROR,
        payload: e.response?.data?.error?.errorMessage || e.message,
      });
    } finally {
      dispatch({ type: SET_USER_SETTINGS_LOADING, payload: false });
      dispatch(setIsNotLoading);
    }
  };
};

export const getLoadMultiplierAction = (scenarioId) => {
  return async (dispatch) => {
    dispatch(setIsLoading);
    try {
      const {
        data: { data, success },
      } = await getLoadMultiplier(scenarioId);
      if (success) {
        dispatch({
          type: UPDATE_COMPANY,
          payload: { loadMultiplier: data.loadMultiplier },
        });
      }
    } catch (e) {
      dispatch({
        type: LOAD_MULTIPLIER_ERROR,
        payload: e.response?.data?.error?.errorMessage || e.message,
      });
    } finally {
      dispatch(setIsNotLoading);
    }
  };
};

export const updateLoadMultiplierAction = (payload, scenarioId) => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await updateLoadMultiplier(payload, scenarioId);
    dispatch({
      type: UPDATE_COMPANY,
      payload: { loadMultiplier: data.loadMultiplier },
    });
    dispatch(refreshCompaniesAction());
  };
};

export const handleDataChange = (name, value) => {
  return {
    type: HANDLE_DATA_CHANGE,
    payload: { name, value },
  };
};

export const getIntegrationsStatusAction = () => {
  return async (dispatch, getState) => {
    dispatch(changeLoadingState('integrationStatuses', true));
    try {
      const {
        data: { data: integrations },
        config: { params },
      } = await getIntegrationsStatus();
      const { companies } = getState();

      // Prevent stale responses from a previously selected company from
      // polluting the currently selected company's state
      if (params.companyId === companies.selectedCompanyId) {
        dispatch({
          type: SET_INTEGRATIONS_STATUS_SUCCESS,
          payload: integrations,
        });
      }
    } catch (e) {
      dispatch({
        type: SET_INTEGRATIONS_STATUS_ERROR,
        payload: e.response?.data?.error?.errorMessage || e.message,
      });
    } finally {
      dispatch(changeLoadingState('integrationStatuses', false));
    }
  };
};

export const authorizeIntegrationAction = ({
  platformCompanyId,
  statusCode,
  integrationId,
  syncStartDate,
}) => {
  return async (dispatch) => {
    try {
      dispatch(setConnectionsError(null));
      await authorizeIntegration({
        platformCompanyId,
        statusCode,
        integrationId,
        syncStartDate,
      });
    } catch (e) {
      dispatch(
        setConnectionsError(
          e.response?.data?.error?.errorMessage || e.message || e,
        ),
      );
    } finally {
      removeParams();
    }
  };
};

/**
 * @typedef {Pick<
 *   import('@/helpers/integrations').EnrichedIntegrationStatus,
 *   'systemType' | 'type'
 * > & {
 *   companyId: import('@/helpers/integrations').EnrichedIntegrationStatus['externalCompanyId'];
 *   platformCompanyId: import('@/reducers/companies').Company['id'];
 * }} SelectedIntegration
 */

/**
 * @type {(
 *   selectedIntegration: SelectedIntegration,
 *   apolloClient: import('@/types/apolloClient').GqlClient,
 * ) => (dispatch: any) => Promise<void>}
 */
export const syncIntegrationAction = (
  { companyId, systemType, platformCompanyId, type },
  apolloClient,
) => {
  return async (dispatch) => {
    dispatch(setIsLoading);
    dispatch(setConnectionsError(null));
    try {
      if (type === BIPAAS_QUICKBOOKS) {
        await apolloClient.mutate({
          mutation: BIPAAS_MANUAL_SYNC_MUTATION,
          variables: { firstSync: true },
        });
      } else {
        await syncIntegration(
          {
            companyId,
            systemType,
          },
          { platformCompanyId },
        );
      }
    } catch (e) {
      dispatch(
        setConnectionsError(
          e.response?.data?.error?.errorMessage || e.message || e,
        ),
      );
    } finally {
      dispatch(setIsNotLoading);
    }
  };
};

export const changeUserPasswordAction = (passwordData) => {
  return async (dispatch) => {
    dispatch(setIsLoading);
    try {
      const payload = await changePassword(passwordData);
      dispatch({ type: CHANGE_USER_PASSWORD_LOADING, payload: true });
      dispatch({ type: CHANGE_USER_PASSWORD_SUCCESS, payload });
    } catch (e) {
      dispatch({
        type: CHANGE_USER_PASSWORD_ERROR,
        payload: e.response?.data?.error?.errorMessage || e.message,
      });
    } finally {
      dispatch({ type: CHANGE_USER_PASSWORD_LOADING, payload: false });
      dispatch(setIsNotLoading);
    }
  };
};

export const setStripeSessionErrorAction = (e) => {
  return (dispatch) => {
    dispatch({
      type: STRIPE_SESSION_ERROR,
      payload: e.response?.data?.error?.errorMessage || e.message,
    });
  };
};

export const createStripeSessionAction = (redirectUrl = undefined) => {
  return async (dispatch) => {
    try {
      const response = await createSession(
        redirectUrl ?? REACT_APP_STRIPE_CLIENT_REDIRECT,
      );
      dispatch({ type: STRIPE_SESSION_SUCCESS, payload: response.data.data });
    } catch (e) {
      dispatch(setStripeSessionErrorAction(e));
    }
  };
};

export const addOrEditMemberAction = (params) => {
  return async (dispatch) => {
    dispatch(setIsLoading);
    dispatch({ type: CHANGE_SHARE_COMPANY_LOADING, payload: true });
    try {
      if (params.id) {
        await editMember(params);
      } else {
        await addMember(params);
      }
      dispatch({
        type: SET_SHARE_COMPANY_SUCCESS,
        payload: true,
      });
    } catch (e) {
      dispatch({
        type: SET_SHARE_COMPANY_ERROR,
        payload: e.response?.data?.error?.errorMessage || e.message,
      });
    } finally {
      dispatch({ type: CHANGE_SHARE_COMPANY_LOADING, payload: false });
      dispatch(setIsNotLoading);
    }
  };
};

export const editCustomDepartmentAction = (
  expenseClassId,
  name,
  companyId,
  scenarioId,
  id,
) => {
  return async (dispatch) => {
    await editCustomDepartment(
      {
        expenseClassId,
        id,
        name: name.trim(),
      },
      companyId,
    );
    dispatch(getExpensesClassesAction(scenarioId, false));
  };
};

export const deleteCustomDepartmentAction = (
  companyId,
  departmentId,
  scenarioId,
) => {
  return async (dispatch) => {
    await deleteCustomDepartment(companyId, departmentId);
    dispatch(getExpensesClassesAction(scenarioId, false));
  };
};

export const addCustomDepartmentAction = (
  expenseClassId,
  name,
  companyId,
  scenarioId,
) => {
  return async (dispatch) => {
    await addCustomDepartment({ expenseClassId, name: name.trim() }, companyId);
    dispatch(getExpensesClassesAction(scenarioId, false));
  };
};

export const resetShareCompanyError = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_SHARE_COMPANY_ERROR,
    });
  };
};

export const getMembersAction = () => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await getMembers();
    dispatch({ type: MEMBER_LIST_SUCCESS, payload: data });
  };
};

export const removeMemberAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: MEMBER_REMOVE_LOADING, payload: true });
    try {
      const {
        data: { data },
      } = await removeMember(id);

      dispatch({ type: MEMBER_REMOVE_SUCCESS, payload: data });
    } catch (e) {
      /* eslint-disable-next-line no-console -- predates description requirement */
      console.error(e);
    } finally {
      dispatch({ type: MEMBER_REMOVE_LOADING, payload: false });
    }
  };
};
export const setMemberInfoAction = (payload) => ({
  type: MEMBER_INFO_SUCCESS,
  payload,
});

export const getMemberInfoAction = (id) => {
  return async (dispatch) => {
    dispatch(setIsLoading);
    try {
      const {
        data: { data },
      } = await getMemberInfo(id);
      dispatch(setMemberInfoAction(data));
    } catch (e) {
      /* eslint-disable-next-line no-console -- predates description requirement */
      console.error(e);
    } finally {
      dispatch(setIsNotLoading);
    }
  };
};

export const resetDisableMemberInviteAction = () => ({
  type: MEMBER_DISABLE_INVITE_RESET,
});

export const disableMemberInviteAction = (payload) => {
  return async (dispatch) => {
    dispatch(setIsLoading);
    dispatch({ type: MEMBER_DISABLE_INVITE_LOADING, payload: true });
    try {
      await disableMemberInvite(payload);
      dispatch({
        type: MEMBER_DISABLE_INVITE_SUCCESS,
        payload,
      });
    } catch (e) {
      dispatch({
        type: MEMBER_DISABLE_INVITE_ERROR,
        payload: e.response?.data?.error?.errorMessage || e.message,
      });
    } finally {
      dispatch({ type: MEMBER_DISABLE_INVITE_LOADING, payload: false });
      dispatch(setIsNotLoading);
    }
  };
};
export const resetMemberInfoAction = () => ({
  type: MEMBER_INFO_RESET,
});

export const getPaymentPlansAction = () => {
  return async (dispatch) => {
    try {
      const {
        data: { data },
      } = await getPaymentPlans();
      dispatch({ type: SET_PAYMENT_PLANS, payload: data });
    } catch (e) {
      /* eslint-disable-next-line no-console -- predates description requirement */
      console.error(e);
    }
  };
};

export const selectCurrentPaymentPlanAction = (
  currentSelectedPaymentPlanId,
) => {
  return { type: SET_PAYMENT_PLANS, payload: { currentSelectedPaymentPlanId } };
};

export const updatePaymentPlanAction = (selectedPaymentPlanId) => {
  return async (dispatch) => {
    await updatePaymentPlan(selectedPaymentPlanId);
    dispatch(createStripeSessionAction());
  };
};

export const setCollectionTermErrorAction = (e) => {
  return (dispatch) => {
    dispatch({
      type: COLLECTION_TERMS_ERROR,
      payload: e,
    });
  };
};

export const getCollectionTermsAction = (companyId, scenarioId) => {
  return actionWithPermission(
    {
      scenarioAction: actions.READ_WRITE,
      scenarioSubject: subjects.PAYMENT_COLLECTION_TERMS,
    },
    async (dispatch) => {
      try {
        const {
          data: { data },
        } = await getCollectionTerms(companyId, scenarioId);
        dispatch({ type: COLLECTION_TERMS_SUCCESS, payload: data });
      } catch (e) {
        dispatch(
          setCollectionTermErrorAction(
            e.response?.data?.error?.errorMessage || e.message,
          ),
        );
      }
    },
  );
};

export const deleteCollectionTermsAction = (companyId, id, scenarioId) => {
  return async (dispatch) => {
    await deleteCollectionTerm(companyId, id, scenarioId);
    dispatch(getCollectionTermsAction(companyId, scenarioId));
  };
};

export const addOrUpdateCollectionTermAction = (
  payload,
  companyId,
  scenarioId,
) => {
  return async (dispatch) => {
    if (payload.id) {
      await updateCollectionTerm(payload, companyId, scenarioId);
    } else {
      await addCollectionTerm(payload, companyId, scenarioId);
    }
    dispatch(getCollectionTermsAction(companyId, scenarioId));
  };
};

export const setPaymentTermErrorAction = (e) => {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_TERMS_ERROR,
      payload: e,
    });
  };
};

export const getPaymentTermsAction = (companyId, scenarioId) => {
  return actionWithPermission(
    {
      scenarioAction: actions.READ_WRITE,
      scenarioSubject: subjects.PAYMENT_COLLECTION_TERMS,
    },
    async (dispatch) => {
      try {
        const {
          data: { data },
        } = await getPaymentTerms(companyId, scenarioId);
        dispatch({ type: PAYMENT_TERMS_SUCCESS, payload: data });
      } catch (e) {
        dispatch(
          setPaymentTermErrorAction(
            e.response?.data?.error?.errorMessage || e.message,
          ),
        );
      }
    },
  );
};

export const addOrUpdatePaymentTermAction = (
  payload,
  companyId,
  scenarioId,
) => {
  return async (dispatch) => {
    if (payload.id) {
      await updatePaymentTerm(payload, companyId, scenarioId);
    } else {
      await addPaymentTerm(payload, companyId, scenarioId);
    }
    dispatch(getPaymentTermsAction(companyId, scenarioId));
  };
};

export const deletePaymentTermsAction = (companyId, id, scenarioId) => {
  return async (dispatch) => {
    await deletePaymentTerm(companyId, id, scenarioId);
    dispatch(getPaymentTermsAction(companyId, scenarioId));
  };
};

/**
 * @type {(
 *   companyId: number,
 *   callback: (
 *     props: import('@/types/services/backend').SystemTypeBody,
 *   ) => void,
 * ) => ReturnType<typeof subscribeToTopic>}
 */
export const subscribeToManualSyncAction = (companyId, callback) => {
  return (dispatch) => {
    return dispatch(subscribeToTopic(`manual-sync/${companyId}`, callback));
  };
};

export const subscribeToEmployeeRecordsAction = (companyId, scenarioId) => {
  return (dispatch) => {
    if (isEmptyOrNull(scenarioId)) {
      return dispatch({ type: NOOP });
    }
    return dispatch(
      subscribeToTopic(`employee-records-available/${scenarioId}`, (value) => {
        if (value.scenarioId) {
          dispatch(getPendingEmployeeAction(companyId, scenarioId));
        }
      }),
    );
  };
};

/**
 * An action to cancel a user's invite to join a company
 *
 * @param {number} param.companyId - The company ID
 * @param {number} param.scenarioId - The scenario ID
 * @param {string} param.email - The email address for the user whose invite to
 *   be cancelled
 */
export const cancelExpiredInviteAction =
  ({ companyId, scenarioId, email }) =>
  async (dispatch) => {
    try {
      await cancelExpiredInvite({ companyId, scenarioId, email });
      dispatch(getMembersAction());
    } catch (e) {
      /* eslint-disable-next-line no-console -- predates description requirement */
      console.error(e.response?.data?.error?.errorMessage || e.message);
    }
  };

/**
 * Action to subscribe billing subscription updates
 *
 * @typedef {{
 *   subscriptionId: string;
 * }} WebSocketsPayload
 * @type {(
 *   companyId: number,
 *   callback: (payload: WebSocketsPayload) => void,
 * ) => import('@reduxjs/toolkit').ThunkAction<any, any, any, any>}
 */
export const subscribeToBillingSubscriptionAction = (companyId, callback) => {
  return (dispatch) => {
    return dispatch(
      subscribeToTopic(`billing-subscription-updated/${companyId}`, callback),
    );
  };
};
