import { Col, Row } from 'react-bootstrap';
import EditDefaultIcon from '@bill/cashflow.assets/edit-default';
import { PropTypes } from 'prop-types';
import Button from '@/components/common/Button';

const Header = ({
  mode,
  setMode,
  title,
  onEdit,
  'data-testid': dataTestId,
}) => {
  return (
    <Row>
      <Col md={10} className="d-flex align-items-center">
        <h4 className="Header_Text">{title}</h4>
      </Col>
      {mode === 'read' && (setMode || onEdit) && (
        <Col md={2} className="text-right">
          <Button
            onClick={() => {
              if (onEdit) {
                onEdit();
              }

              if (setMode) {
                setMode('edit');
              }
            }}
            className="Button-iconOnly EditLink"
            data-testid={dataTestId}
          >
            <EditDefaultIcon className="EditIcon" />
          </Button>
        </Col>
      )}
    </Row>
  );
};

Header.propTypes = {
  /** Current Header mode, can be read or edit */
  'mode': PropTypes.oneOf(['read', 'edit']),
  /** Set Mode of the Header */
  'setMode': PropTypes.func,
  /** Title to display as header */
  'title': PropTypes.node.isRequired,
  /** Additional function to perform on edit. */
  'onEdit': PropTypes.func,
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string,
};

export default Header;
