// @ts-check
import escapeRegex from 'inputmask/lib/escapeRegex';
import {
  READ_ONLY_INDENT_PADDING,
  READ_ONLY_ROW_HEIGHT,
  READ_ONLY_LINE_HEIGHT,
  READ_ONLY_TITLE_WIDTH,
  READ_ONLY_CELL_PADDING,
} from '@/components/common/Spreadsheet/constants';
import { DEFAULT_ROWS } from '@/constants/cashInOut';
import {
  columnTruncationLimits,
  maxRowsPerPageLimits,
  MAX_LETTERS_IN_ROW,
} from '@/constants/reports';
import { getTimePeriodsInRange } from '@/helpers/dateFormatter';
import { zeroFilter } from '@/helpers/zeroFilter';
import { MAX_CHARTS_PER_ROW } from '@/pages/Reports/constants';
import { CHARACTER_LIMIT } from './ExportableReport/constants';

const newLineEndRegex = /\n$/;

/**
 * @typedef {{
 *   startDate: string;
 *   endDate: string;
 *   timePeriod: string;
 *   hasComparison: boolean;
 *   showVariance: boolean;
 * }} getColumnLimitParams
 */

/**
 * @type {(params: getColumnLimitParams) => {
 *   truncated: boolean;
 *   limit: number;
 * }}
 */
export const getColumnLimit = ({
  startDate,
  endDate,
  timePeriod,
  hasComparison,
  showVariance,
}) => {
  const timePeriodInRange = getTimePeriodsInRange(
    startDate,
    endDate,
    timePeriod,
  );

  let limit;
  switch (true) {
    case hasComparison && showVariance:
      limit = columnTruncationLimits.WITH_VARIANCE_LIMIT;
      break;
    case hasComparison && !showVariance:
      limit = columnTruncationLimits.WITH_COMPARISON_LIMIT;
      break;
    default:
      limit = columnTruncationLimits.NO_COMPARISON_LIMIT;
  }

  return {
    truncated: timePeriodInRange.length > limit,
    limit,
  };
};

/** @type {() => import('./ExportableReport/Sections/Report').NestedPage} */
export const generateDefaultPage = () => ({
  charts: [],
  reportData: [],
});

/**
 * Calculates the number of rows to split
 *
 * @type {(
 *   data: import('@/hooks/useReportData').ReportsData[],
 *   numRows: number,
 * ) => number}
 */
export const numRowsToSplit = (data, maxRows) => {
  let rows = 0;
  const textWidth = READ_ONLY_TITLE_WIDTH - READ_ONLY_CELL_PADDING * 4;
  const approxLetterWidth = textWidth / MAX_LETTERS_IN_ROW;

  for (
    let rowIndex = 0;
    rowIndex < Math.min(data.length, maxRows);
    rowIndex += 1
  ) {
    const item = data[rowIndex];
    const level = item.hierarchy.length - 1;
    const maxNumLetterInRow = Math.floor(
      (textWidth - level * READ_ONLY_INDENT_PADDING) / approxLetterWidth,
    );

    const name = item.name ?? item.title;

    const textWrappedRows = Math.ceil(name.length / maxNumLetterInRow);

    const rowCount =
      (textWrappedRows - 1) * (READ_ONLY_LINE_HEIGHT / READ_ONLY_ROW_HEIGHT) +
      1;

    if (rows + rowCount > maxRows) {
      // Exit early with the rowIndex where we need paginate the grid data
      return rowIndex;
    }
    rows += rowCount;
  }

  return maxRows;
};

/**
 * Splits the grid data into multiple pages
 *
 * @type {(
 *   reportData: import('@/hooks/useReportData').ReportsData[],
 *   pages: import('./ExportableReport/Sections/Report').NestedPage[],
 * ) => import('./ExportableReport/Sections/Report').NestedPage[]}
 */
export const paginateGridData = (reportData, reportPages) => {
  const data = [...reportData];
  const pages = [...reportPages];

  while (data.length > 0) {
    const lastPage = pages[pages.length - 1];
    const currentPage =
      lastPage.reportData.length > 0 ||
      lastPage.charts.length > MAX_CHARTS_PER_ROW
        ? generateDefaultPage()
        : lastPage;
    if (currentPage !== lastPage) {
      pages.push(currentPage);
    }
    const previousPage = pages[pages.length - 2];
    const [firstRowOnPage] = data;
    const parentIds = firstRowOnPage?.hierarchy.slice(0, -1) ?? [];

    if (parentIds.length && !previousPage) {
      throw new Error(
        'There are ancestor rows in the hierarchy but no previous page to copy them from.',
      );
    }

    const parents = parentIds.map((parentId) =>
      previousPage?.reportData.find((row) => row.id === parentId),
    );

    data.unshift(...parents);

    if (currentPage.charts.length === 0) {
      const numRows = numRowsToSplit(data, maxRowsPerPageLimits.FULL_PAGE);
      currentPage.reportData = data.splice(0, numRows);
    } else if (
      currentPage.charts.length <= MAX_CHARTS_PER_ROW &&
      parentIds.length < maxRowsPerPageLimits.HALF_PAGE
    ) {
      const numRows = numRowsToSplit(data, maxRowsPerPageLimits.HALF_PAGE);
      currentPage.reportData = data.splice(0, numRows);
    }
  }
  return pages;
};

/**
 * Handles user input changes with AI response
 *
 * @type {(params: {
 *   newText: string;
 *   oldUserText: string;
 *   oldAIText: string;
 *   oldDisclaimerText: string;
 * }) => { userText: string; aiText: string; disclaimerText: string }}
 */
export const handleUserAIChange = ({
  newText,
  oldUserText,
  oldAIText,
  oldDisclaimerText,
}) => {
  const aiTextRegex = new RegExp(`^${escapeRegex(oldAIText ?? '')}`);
  const disclaimerTextRegex = new RegExp(
    `${escapeRegex(oldDisclaimerText ?? '')}$`,
  );

  const hasAITextChanged = !aiTextRegex.test(newText);
  const hasDisclaimerTextChanged = !disclaimerTextRegex.test(newText);

  let userText = oldUserText;
  let aiText = oldAIText;
  let disclaimerText = oldDisclaimerText;

  if (hasAITextChanged) {
    userText = newText.replace(disclaimerTextRegex, '');
    aiText = '';
  }

  if (hasDisclaimerTextChanged) {
    userText = newText.replace(aiTextRegex, '');
    disclaimerText = '';
  }

  if (!hasAITextChanged && !hasDisclaimerTextChanged) {
    const newTextWithoutAI = newText.replace(aiTextRegex, '');
    userText = newTextWithoutAI.replace(disclaimerTextRegex, '');
  }

  return { userText, aiText, disclaimerText };
};

/**
 * @type {(
 *   existingExecutiveSummary: string,
 *   existingAIText: string,
 *   newAIText: string,
 *   newDisclaimerText: string,
 * ) => {
 *   trimmedAIText: string;
 *   trimmedDisclaimerText: string;
 *   newExecutiveSummary: string;
 * }}
 */
export const trimExceeedingTextLimits = (
  existingExecutiveSummary,
  existingAIText,
  newAIText,
  newDisclaimerText,
) => {
  let newExecutiveSummary = existingExecutiveSummary;
  let charactersRemaining = CHARACTER_LIMIT - existingExecutiveSummary.length;

  if (!existingAIText && charactersRemaining > 0) {
    newExecutiveSummary = `\n${existingExecutiveSummary}`;
    charactersRemaining -= 1;
  }

  const trimmedAIText = newAIText.substring(0, charactersRemaining);
  charactersRemaining -= trimmedAIText.length;

  if (
    newExecutiveSummary &&
    !newLineEndRegex.test(newExecutiveSummary) &&
    charactersRemaining > 0
  ) {
    newExecutiveSummary += '\n';
    charactersRemaining -= 1;
  }

  const trimmedDisclaimerText = newDisclaimerText.substring(
    0,
    charactersRemaining,
  );

  return {
    trimmedAIText,
    trimmedDisclaimerText,
    newExecutiveSummary,
  };
};

/**
 * @type {(
 *   row: import('@/hooks/useReportData').ReportsData,
 *   reportData: import('@/hooks/useReportData').ReportsData[],
 * ) => boolean}
 */
export const filterRows = (row, reportData) => {
  return (
    DEFAULT_ROWS.has(row.id) ||
    row.isAdditional ||
    zeroFilter(row) ||
    !!row.childrenIds.find((childId) =>
      filterRows(
        reportData.find((item) => item.id === childId),
        reportData,
      ),
    )
  );
};
