import { useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import {
  isInvalidValueForPoP,
  getCustomerMetric,
} from '@/components/Charts/helpers';
import InfoTooltip from '@/components/common/InfoTooltip';
import TogglePersistent from '@/components/common/TogglePersistent';
import { INVALID_POP_VALUE_TOOLTIP_TEXT } from '@/constants/charts';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import useChartPreferences from '@/hooks/useChartPreferences';
import useDashboardPath from '@/hooks/useDashboardPath';
import CustomersTotalChart, {
  CUSTOMERS_TOTAL_TOGGLE_ID,
} from './charts/CustomersTotalChart';

const CHART_TITLE = 'Total Customers';

const CustomersTotal = ({ endDate, startDate }) => {
  const [customersTotal, setCustomersTotal] = useState({});
  const chartRef = useRef();
  const showPercentage = useChartPreferences(CUSTOMERS_TOTAL_TOGGLE_ID);
  const dashboardPath = useDashboardPath();
  const onQueryStateChange = useCallback(({ data, isLoading }) => {
    setCustomersTotal({ ...data, isLoading });
  }, []);

  return (
    <section className="Panel Panel-toEdge">
      <ChartHeader back={dashboardPath} title={CHART_TITLE}>
        {showPercentage ? (
          <>
            <div className="ChartHeader_KPIValue">
              {!customersTotal.isLoading && customersTotal.forecastTermValuePoP}
              {!customersTotal.isLoading &&
                isInvalidValueForPoP(customersTotal.forecastTermValuePoP) && (
                  <InfoTooltip data-testid="customers-total-kpi-tooltip">
                    {INVALID_POP_VALUE_TOOLTIP_TEXT}
                  </InfoTooltip>
                )}
            </div>
            <div className="ChartHeader_KPI">
              <span className="ChartHeader_Subtitle">Average for:</span>
              {formatRangeWithShortYear(startDate, endDate)}
            </div>
          </>
        ) : (
          <>
            <div className="ChartHeader_KPIValue">
              {customersTotal.currentMonthValue}
            </div>
            <div className="ChartHeader_KPI">
              <span className="ChartHeader_Subtitle">
                Current
                <br /> Month
              </span>
            </div>
            <div className="ChartHeader_KPIValue">
              {customersTotal.forecastTermValue}
            </div>
            <div className="ChartHeader_KPI">
              <span className="ChartHeader_Subtitle">
                Forecast
                <br /> Term End
              </span>
            </div>
          </>
        )}
      </ChartHeader>
      <ChartToolbar>
        <TogglePersistent
          id={CUSTOMERS_TOTAL_TOGGLE_ID}
          value={false}
          options={[
            { label: 'Customers', value: false },
            { label: 'Percentage', value: true },
          ]}
          data-testid="totalCustomers-toggle"
        />
        <ChartControls
          chartRef={chartRef}
          title={CHART_TITLE}
          metricValue={
            showPercentage
              ? customersTotal.forecastTermValuePoP
              : getCustomerMetric(
                  customersTotal.currentMonthValue,
                  customersTotal.forecastTermValue,
                )
          }
          label={
            showPercentage
              ? `Average for: ${formatRangeWithShortYear(startDate, endDate)}`
              : ''
          }
          data-testid="total-customers-chart-export"
        />
      </ChartToolbar>
      <CustomersTotalChart
        className="ExpandedView_ChartWrapper"
        exportBtn={chartRef}
        showPercentage={showPercentage}
        onQueryStateChange={onQueryStateChange}
      />
    </section>
  );
};

const mapStateToProps = ({ shared }) => {
  const { startDate, endDate } = shared;
  return {
    endDate,
    startDate,
  };
};

export default connect(mapStateToProps)(CustomersTotal);
