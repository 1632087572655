import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  deleteEmployeesAction,
  getAllJobTitlesAction,
} from '@/actions/employees';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import useSelectedScenarios from '@/hooks/useSelectedScenarios';
import getSelectedCompany from '@/selectors/getSelectedCompany';

const EMPLOYEES_DEFAULT = [];

const EmployeeDelete = ({
  showDeleteModal,
  setShowDeleteModal,
  setCurrentEmployee,
  currentEmployee,
  selectedEmployees = EMPLOYEES_DEFAULT,
  setSelectedEmployees = () => {},
  deleteEmployees,
  selectedCompany,
  isActualsOrigin = false,
  getAllJobTitles,
  onDelete,
  error,
}) => {
  const scenarios = useSelectedScenarios();
  const scenarioIds = scenarios.map(({ scenarioId: id }) => id);
  const [scenarioId] = scenarioIds;

  const selectedCompanyId = selectedCompany?.id;

  const [deleteError, setDeleteError] = useState('');

  useEffect(() => {
    if (error) {
      setDeleteError(error);
    }
  }, [error]);

  const handleCancel = () => {
    setShowDeleteModal(null);
    setCurrentEmployee(null);
  };

  const handleDelete = async () => {
    const employeesIds = currentEmployee
      ? [currentEmployee.id]
      : selectedEmployees.map(({ id }) => id);

    await deleteEmployees({
      employeesIds,
      scenarioId,
      companyId: selectedCompanyId,
    });
    getAllJobTitles(scenarioId);

    if (isActualsOrigin) {
      onDelete?.();
    }
    setShowDeleteModal(false);

    if (employeesIds.length && !currentEmployee) {
      setSelectedEmployees([]);
    } else {
      setSelectedEmployees((prevState) =>
        prevState.filter((employee) => employee?.id !== currentEmployee?.id),
      );
    }
    setCurrentEmployee(null);
  };

  return (
    <>
      {showDeleteModal && (
        <ModalConfirmation
          id="modal-employee-delete"
          onCancel={handleCancel}
          onAction={handleDelete}
          title={`Delete Employee${currentEmployee ? '' : 's'}`}
          data-testid="modal-employee-delete"
        >
          {currentEmployee ? (
            <div>Are you sure you would like to delete this employee?</div>
          ) : (
            <>
              <div>
                You are about to delete following employees, which cannot be
                undone. Are you sure?
              </div>
              <div>
                <ul className="EmployeeDelete_List">
                  {selectedEmployees.map((employee) => {
                    return <li key={employee.id}>{employee.name}</li>;
                  })}
                </ul>
              </div>
            </>
          )}
        </ModalConfirmation>
      )}
      {deleteError && (
        <ModalConfirmation
          id="modal-employee-item-cannot-delete"
          onAction={() => setDeleteError('')}
          title="Cannot delete the employee"
          actionBtnTxt="I understand"
        >
          {deleteError}
        </ModalConfirmation>
      )}
    </>
  );
};
const mapStateToProps = ({ companies, employees }) => {
  return {
    selectedCompany: getSelectedCompany({ companies }),
    error: employees.error,
  };
};

export default connect(mapStateToProps, {
  deleteEmployees: deleteEmployeesAction,
  getAllJobTitles: getAllJobTitlesAction,
})(EmployeeDelete);
