import { SET_UI_STATE } from '@/actionTypes/ui';

export default function uiReducer(state = {}, { type, payload }) {
  switch (type) {
    case SET_UI_STATE:
      return { ...state, ...payload };
    default:
      return state;
  }
}
