/**
 * Returns either a number or null
 *
 * @param {Object} - React synthetic event
 * @param {number} valueAsNumber - A Number, that could be NaN
 * @returns {number | null}
 */
export default function asNumberOrNull({ valueAsNumber }) {
  return Number.isFinite(valueAsNumber) ? valueAsNumber : null;
}
