// @ts-check
import usePermissions from '@/hooks/usePermissions';

/**
 * @typedef {{
 *   action: import('@/constants/permissions').PermissionAction;
 *   subject: import('@/constants/permissions').PermissionSubject;
 *   scenarioPermissionRequired?: boolean;
 * }} PermissionBaseProps
 */

/**
 * @typedef {PermissionBaseProps & {
 *   render: (params: boolean) => React.ReactElement;
 *   children?: never;
 * }} PermissionRenderProps
 */

/**
 * @typedef {PermissionBaseProps & {
 *   children: React.ReactNode;
 *   render?: never;
 * }} PermissionChildrenProps
 */

/** @typedef {PermissionRenderProps | PermissionChildrenProps} PermissionProps */

/**
 * Permission is a wrapper component that can take two props It validates if the
 * current user has access to perform that action on a given subject and render
 * the children accordingly
 *
 * @example
 *   - 1
 *   <Permissions action="Read" subject="CompanySettings">
 *      <ComponentThatNeedsValidation />
 *   </Permissions>
 *
 * @example
 *   - 2
 *   Permission have another prop render which is needed when we have to control of permission bool inside the
 *   component that is needed to permitted.
 *   <Permissions
 *      action="Read"
 *      subject="CompanySettings"
 *      render={permission => <ComponentThatNeedsValidation permission={permission} />}
 *   />
 *
 * @example
 *   - 3
 *   Permission have another prop isScenerio which checks if that specific scenerio has access.
 *   <Permissions
 *      action="Read"
 *      subject="NonDashboard"
 *      scenarioPermissionRequired
 *   />
 *
 * @type {(props: PermissionProps) => React.ReactElement}
 */
const Permissions = ({
  action,
  subject,
  render,
  children,
  scenarioPermissionRequired = false,
}) => {
  const allowed = usePermissions(action, subject, scenarioPermissionRequired);

  if (render) {
    return render(allowed);
  }

  if (allowed) {
    return <>{children}</>;
  }

  return null;
};

export default Permissions;
