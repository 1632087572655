import {
  SET_SELECTED_COMPANY,
  RESET_COMPANIES,
  SET_COMPANIES_LOADING,
  REFRESH_COMPANIES_DATA,
  SET_COMPANIES_ON_LOGIN,
  UPDATE_COMPANY,
  UPDATE_SALARY_INCREASE,
  SALARY_INCREASE_ERROR,
} from '@/actionTypes/companies';
import { NO_COMPARISON } from '@/constants/scenario';
import {
  getCompanies,
  getCompanyInfo,
  getSalaryIncrease,
  updateSalaryIncrease,
} from '@/services/companies';
import { compareScenarioIdPayload, scenarioIdPayload } from './scenario';

export const setSelectedCompany = (payload) => {
  return {
    type: SET_SELECTED_COMPANY,
    payload,
  };
};

export const resetCompaniesAction = () => {
  return { type: RESET_COMPANIES };
};

export const refreshCompaniesAction = () => {
  return async (dispatch) => {
    dispatch({ type: SET_COMPANIES_LOADING, payload: true });
    try {
      const {
        data: { data: companies },
      } = await getCompanies();
      dispatch({ type: REFRESH_COMPANIES_DATA, payload: companies });
    } catch (e) {
      /* eslint-disable-next-line no-console -- predates description requirement */
      console.error(e);
    } finally {
      dispatch({ type: SET_COMPANIES_LOADING, payload: false });
    }
  };
};

export const setCompaniesOnLoginAction = () => {
  return async (dispatch) => {
    dispatch({ type: SET_COMPANIES_LOADING, payload: true });
    try {
      const {
        data: { data: companies },
      } = await getCompanies();
      dispatch({ type: SET_COMPANIES_ON_LOGIN, payload: companies });
    } catch (e) {
      /* eslint-disable-next-line no-console -- predates description requirement */
      console.error(e);
    } finally {
      dispatch({ type: SET_COMPANIES_LOADING, payload: false });
    }
  };
};

export const getCompanyInfoAction = (id) => {
  return async (dispatch) => {
    const {
      data: {
        data: { loadMultiplier, defaultScenarioId, ...companyInfo },
      },
    } = await getCompanyInfo(id);
    dispatch(scenarioIdPayload(defaultScenarioId));
    dispatch(compareScenarioIdPayload(NO_COMPARISON));
    dispatch({
      type: UPDATE_COMPANY,
      payload: { ...companyInfo, loadMultiplier },
    });
  };
};

/**
 * Retrieves a global salary object
 *
 * @param {number} companyId ID of the company to which the global salary
 *   increase belong
 * @returns {Function} Dispatchable action
 */
export const getSalaryIncreaseAction = (companyId) => {
  return async (dispatch) => {
    try {
      const {
        data: { data },
      } = await getSalaryIncrease(companyId);
      dispatch({
        type: UPDATE_SALARY_INCREASE,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: SALARY_INCREASE_ERROR,
        payload: e.response?.data?.error?.errorMessage || e.message,
      });
    }
  };
};

/**
 * Updates global salary which belongs to the company
 *
 * @param {number} companyId ID of the company to which the global salary
 *   increase belong
 * @param {Object} payload Global Salary object to update
 * @returns {Function} dispatchable action
 */
export const updateSalaryIncreaseAction = (payload, companyId) => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await updateSalaryIncrease(payload, companyId);
    dispatch({
      type: UPDATE_SALARY_INCREASE,
      payload: data,
    });
  };
};
