// @ts-check
import CircularReference from '@/components/common/CircularReference';
import './MonthCellRenderer.scss';

/**
 * @typedef {{
 *   amount: number;
 * }} MonthCellRendererValue
 */

/**
 * Render cell value with circular reference icon and tooltip
 *
 * @type {(
 *   params: import('ag-grid-community').ICellRendererParams<
 *     any,
 *     MonthCellRendererValue
 *   >,
 * ) => React.ReactElement}
 */
const MonthCellRenderer = ({ value, valueFormatted }) => {
  return (
    <div className="MonthCellRenderer">
      <span className="MonthCellRenderer_Value">
        {valueFormatted ?? value?.amount}
      </span>
      <CircularReference />
    </div>
  );
};

export default MonthCellRenderer;
