import { useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import QuestionMarkIcon from '@bill/cashflow.assets/question-mark';
import CurrencyField from '@/components/common/CurrencyField';
import FormRadio from '@/components/common/FormRadio';
import FormulaField from '@/components/common/FormulaField';
import FormulaGuideModal from '@/components/common/FormulaGuide/FormulaGuideModal';
import { EMPLOYEE_SALARY } from '@/components/common/FormulaGuide/formulaGuideModalConstants';
import FormulaValidationMessageWithLink from '@/components/common/FormulaValidationMessageWithLink';
import { sanitizeValue } from '@/components/common/Spreadsheet/helpers';
import { findCurrencySymbol } from '@/constants/currencies';
import VALID_FORMULA_MSG from '@/constants/formulas';
import { isNumber } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';
import validateCustomFormula from '@/services/formula.service';
import './EmployeeSalary.scss';

const INVALID_SALARY_MSG =
  'Salary value must be a positive whole number or zero!';

const EmployeeSalary = ({
  scenarioId,
  isEditMode,
  salaryAmount,
  salaryFormula,
  salaryVariableId,
  setIsSalaryValid,
  onChange,
}) => {
  const [isAnnualSelected, setIsAnnualSelected] = useState(
    !salaryFormula && !salaryVariableId,
  );
  const [validationMsg, setValidationMsg] = useState('');
  const [currencyPrefix, setCurrencyPrefix] = useState('');
  const [showFormulaGuideModal, setShowFormulaGuideModal] = useState(false);

  const autocompleteOptions = useSelector(
    ({ variables }) => variables.autocompleteOptions,
  );

  const validateFormula = useCallback(async () => {
    if (isNumber(salaryFormula)) {
      const isValid =
        !isEmptyOrNull(salaryFormula) && parseInt(salaryFormula, 10) >= 0;
      setValidationMsg(isValid ? VALID_FORMULA_MSG : INVALID_SALARY_MSG);
      setIsSalaryValid(isValid);
      setCurrencyPrefix(isValid ? findCurrencySymbol() : '');
    } else {
      const {
        data: { data },
      } = await validateCustomFormula(scenarioId, salaryFormula);
      setValidationMsg(data?.valid ? VALID_FORMULA_MSG : data.error);
      setIsSalaryValid(data?.valid);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [autocompleteOptions, salaryFormula]);

  useEffect(() => {
    if (isEditMode) {
      // When a user opens the driver in "edit" mode, the formula is valid
      // because only valid formulas can be created in the first place and they
      // cannot become invalid between creation and "edit" mode
      setIsSalaryValid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, [isEditMode]);

  useEffect(() => {
    if (salaryFormula) validateFormula();
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [autocompleteOptions]);

  return (
    <>
      <FormulaGuideModal
        formulaKey={EMPLOYEE_SALARY}
        open={showFormulaGuideModal}
        data-testid="employee-salary-guide-modal"
        onClose={() => setShowFormulaGuideModal(false)}
      />
      <div className="Form_Group EmployeeSalary_OptionsRow">
        <div>
          <FormRadio
            id="annual-salary-option"
            name="employee-salary"
            label="Annual Salary"
            className="EmployeeSalaryOption"
            data-testid="annual-salary-option"
            checked={isAnnualSelected}
            value="annualSalary"
            onChange={({ target: { checked } }) => {
              setIsAnnualSelected(checked);
              setValidationMsg('');
              setCurrencyPrefix('');
              onChange?.({
                annualSalary: '',
                salaryFormula: '',
                salaryVariableId: null,
              });
            }}
          />
          <FormRadio
            id="custom-formula-option"
            name="employee-salary"
            label="Custom Formula"
            className="EmployeeSalaryOption"
            data-testid="custom-formula-option"
            checked={!isAnnualSelected}
            value="customSalary"
            onChange={({ target: { checked } }) => {
              setIsAnnualSelected(!checked);
              setValidationMsg('');
              setCurrencyPrefix('');
              onChange?.({
                annualSalary: '',
                salaryFormula: '',
                salaryVariableId: null,
              });
            }}
          />
        </div>

        {!isAnnualSelected && (
          <button
            type="button"
            className="Button-iconOnly"
            aria-label="Help"
            onClick={() => setShowFormulaGuideModal(true)}
          >
            <QuestionMarkIcon className="HelpIcon" aria-hidden="true" />
          </button>
        )}
      </div>
      {isAnnualSelected ? (
        <div className="Form_Group Form_Group-halfWidth">
          <CurrencyField
            id="salary"
            name="salary"
            value={salaryAmount}
            precision={0}
            onChange={({ target: { value } }) => {
              if (value.length <= 9) {
                setIsSalaryValid(!isEmptyOrNull(value) && value >= 0);
                onChange?.({
                  annualSalary: sanitizeValue(value),
                  salaryFormula: null,
                  salaryVariableId: null,
                });
              } else setIsSalaryValid(false);
            }}
            validate={() => {
              const isValid = parseInt(salaryAmount, 10) >= 0;
              return !isValid && INVALID_SALARY_MSG;
            }}
            data-testid="add-employee-salary"
          />
        </div>
      ) : (
        <div className="Form_Group">
          <div className="alert alert-primary">
            A floor of zero is applied to this formula (i.e., must be greater
            than or equal to zero)
          </div>
          <FormulaField
            id="employee-salary-formula-field"
            value={`${currencyPrefix}${salaryFormula || ''}`}
            onChange={(value) => {
              onChange?.({
                annualSalary: null,
                salaryFormula: value,
              });
            }}
            onBlur={validateFormula}
            onFocus={() => {
              setCurrencyPrefix('');
              setValidationMsg('');
              setIsSalaryValid(false);
            }}
          />
          <FormulaValidationMessageWithLink message={validationMsg} />
        </div>
      )}
    </>
  );
};

export default EmployeeSalary;
