import PropTypes from 'prop-types';
import FormField from '@/components/common/FormField';

const SumGridInput = ({ year, selectedRevenue, valueKey, id }) => {
  return (
    <div className="grid-field-opt">
      <label className="GridInput_Label">
        {year.toString().length === 2
          ? new Date().getUTCFullYear().toString().substr(0, 2) + year
          : year}
      </label>
      <FormField
        type="number"
        disabled
        className="GridInput_FormField"
        value={selectedRevenue
          .find((c) => c.year === year)
          .months.reduce((acc, cur) => {
            return acc + Number(cur[valueKey]);
          }, 0)}
        id={id}
      />
    </div>
  );
};

SumGridInput.propTypes = {
  /** Specific revenue stream for a year */
  selectedRevenue: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      months: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ),
  /** Year in which the grid input lies */
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Dynamic key that is used to hold the value of the grid input */
  valueKey: PropTypes.string.isRequired,
  /** A unique ID for the field */
  id: PropTypes.string.isRequired,
};

export default SumGridInput;
