import { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartLegend from '@/components/Charts/ChartLegend';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import { chartTypes } from '@/components/Charts/constants';
import { setPercentKpi } from '@/components/Charts/helpers';
import CommonErrorBoundary from '@/components/common/CommonErrorBoundary';
import ContextMenu from '@/components/common/ContextMenu';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import { EMPTY_CELL_VALUE } from '@/components/common/Spreadsheet/constants';
import TrendArrow from '@/components/common/TrendArrow';
import { ERROR_BOUNDARY_TEXT } from '@/constants/charts';
import { classNames, naturalSortComparator } from '@/helpers';
import { getMetricFormatter, valueFormatter } from '@/helpers/customCharts';
import { isEmptyOrNull } from '@/helpers/validators';
import useCallbackRef from '@/hooks/useCallbackRef';
import useDashboardPath from '@/hooks/useDashboardPath';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import { useDashboardContext } from '@/pages/Dashboard/DashboardContext';
import CustomChart from '@/pages/Dashboard/charts/CustomChart';
import useCustomChartsQuery from '@/pages/Dashboard/charts/useCustomChartsQuery';
import { VariableNameRenderer } from '@/pages/Variables/renderers/VariableName';

const SPREADSHEET_ID = 'custom-chart-table';
const VARIABLE_HEADER_NAME = 'Variable';

/**
 * Performs a natural sort comparison on the variable names, alphabetical but
 * treating numbers within the string atomically.
 *
 * @type {import('ag-grid-community').ColDef<{
 *   variable: { displayName: string };
 * }>['comparator']}
 */
const variableNameComparator = (_, __, aRow, bRow) =>
  naturalSortComparator(
    aRow.data.variable.displayName,
    bRow.data.variable.displayName,
  );

/**
 * Fetches and renders a line or column chart of custom variable using the
 * chartId from the url
 */
function CustomChartExpanded() {
  const { chartId } = useParams();
  const [chartApi, setChartApi] = useState();
  const [meta, setMeta] = useState();
  const [chartData, setChartData] = useState([]);
  const [mainMetric, setMainMetric] = useState({});
  const [secondaryMetric, setSecondaryMetric] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { onEditChart } = useDashboardContext();
  const hasWritePermission = useNonDashboardWritePermission();
  const dashboardPath = useDashboardPath();
  const [gridApi, gridRef] = useCallbackRef();

  const chartsQuery = useCustomChartsQuery();

  const history = useHistory();

  useEffect(() => {
    if (!chartsQuery.data) return;
    const chart = chartsQuery.data.find(({ id }) => id === chartId);
    if (!chart) history.replace('/dashboard');
    setMeta(chart);
  }, [chartId, chartsQuery.data, history]);

  const { metadata, title, variables } = meta ?? {};
  const { colors } = metadata ?? {};
  const chartPrecision = metadata?.chartPrecision ?? 0;

  const colDefs = useMemo(
    () => [
      {
        field: 'variable',
        headerName: VARIABLE_HEADER_NAME,
        cellRenderer: VariableNameRenderer,
        cellRendererParams: { showDragButton: false, showUnitSymbol: false },
        valueFormatter: (value) => value.displayName,
        filterValueGetter: ({ data }) => data.variable.displayName,
        comparator: variableNameComparator,
      },
    ],
    [],
  );

  const tableData = useMemo(
    () =>
      chartData.reduce((accum, { data, name, scenario }) => {
        const variableData = accum.find((v) => v.variable.displayName === name);
        if (!variableData) {
          let unit;
          let variableName = '';
          const variable = variables.find(
            ({ variableDto }) =>
              variableDto.name === name ||
              metadata.customVariableNames?.[variableDto.name] === name,
          );
          if (variable) {
            unit = variable.variableDto.unit;
            variableName = variable.variableDto.name;
          }
          const months = data.map(({ month, value }) => ({
            month,
            value: [
              {
                scenarioId: scenario.scenarioId,
                value,
              },
            ],
          }));
          return [
            ...accum,
            {
              months,
              unit,
              variable: { displayName: name, name: variableName },
            },
          ];
        }

        variableData.months = variableData.months.map(
          ({ value, ...props }, idx) => ({
            ...props,
            value: [
              ...value,
              {
                scenarioId: scenario.scenarioId,
                value: data[idx].value,
              },
            ],
          }),
        );
        return accum;
      }, []),
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [chartData, variables],
  );

  const excelExportParams = useMemo(() => {
    return {
      processCellCallback: ({ column, node, value }) => {
        if (column.colDef.headerName === VARIABLE_HEADER_NAME) {
          return node.data.variable.displayName;
        }
        return typeof value === 'object' ? value?.value : value;
      },
    };
  }, []);

  return (
    <>
      <section className="Panel Panel-toEdge">
        <ChartHeader back={dashboardPath} title={title} withTooltip>
          <>
            <div className="ChartHeader_KPIValue">{mainMetric.value}</div>
            <div className="ChartHeader_KPI">{mainMetric.label}</div>
            {secondaryMetric && (
              <>
                <TrendArrow value={secondaryMetric.value} colors={colors} />
                <div className="ChartHeader_SecondaryKPI">
                  {secondaryMetric.label
                    ? `${setPercentKpi(secondaryMetric.value)} ${
                        secondaryMetric.label
                      }`
                    : setPercentKpi(secondaryMetric.value)}
                </div>
              </>
            )}
          </>
        </ChartHeader>
        <ChartToolbar>
          {variables?.length &&
            ![chartTypes.FUNNEL, chartTypes.PIE].includes(
              metadata?.chartType,
            ) && (
              <ChartLegend
                chart={chartApi}
                data-testid="customChart-legend"
                reverse
              />
            )}
          <ChartControls
            chartRef={chartApi}
            title={title}
            metricValue={mainMetric.value}
            label={mainMetric.label}
            data-testid={`${chartId}-custom-chart-export`}
          >
            {meta && hasWritePermission && (
              <>
                <ContextMenu.Option
                  data-testid="custom-chart-edit"
                  onClick={() => onEditChart(meta)}
                >
                  Edit Chart
                </ContextMenu.Option>
                <ContextMenu.Option
                  danger
                  data-testid="custom-chart-delete"
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete Chart
                </ContextMenu.Option>
              </>
            )}
          </ChartControls>
        </ChartToolbar>
        <div
          className={classNames(
            'ExpandedView_ChartWrapper',
            metadata && `ExpandedView_ChartWrapper-${metadata.chartType}`,
          )}
        >
          <CommonErrorBoundary text={ERROR_BOUNDARY_TEXT}>
            {meta && (
              <CustomChart
                data-testid={`${title}-chart`}
                id={chartId}
                meta={meta}
                onDeleted={() => history.replace('/dashboard')}
                onCancelDelete={() => setShowDeleteModal(false)}
                onChartCreated={setChartApi}
                onQueryStateChange={({ data, isLoading }) => {
                  if (isLoading) return;
                  setMainMetric(data.mainMetric);
                  setSecondaryMetric(data.secondaryMetric);
                  setChartData(data.series);
                }}
                showDeleteModal={showDeleteModal}
                Tooltip={ChartTooltip}
              />
            )}
          </CommonErrorBoundary>
        </div>
      </section>
      <section className="Panel Panel-toEdge">
        <SpreadsheetToolbar editable={false} gridApi={gridApi}>
          Options:
          <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
        </SpreadsheetToolbar>
        <MonthlySpreadsheet
          ref={gridRef}
          columnDefs={colDefs}
          data={tableData}
          data-testid={SPREADSHEET_ID}
          editable={false}
          enableComparison
          getRowId={({ data }) => data.variable.displayName}
          valueFormatter={({ data, value }) => {
            if (isEmptyOrNull(value)) return EMPTY_CELL_VALUE;
            const formatter = getMetricFormatter(data.unit);
            return valueFormatter({
              formatter,
              value,
              precision: chartPrecision,
            });
          }}
          excelExportParams={excelExportParams}
        />
      </section>
    </>
  );
}

export default CustomChartExpanded;
