import { useState } from 'react';
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';

const ExpenseAccountNumber = ({ onUpdate, defaultValue, isDisabled }) => {
  const [accountNumber, setAccountNumber] = useState(defaultValue ?? '');
  return (
    <div className="Form_Group Form_Group-halfWidth">
      <FormLabel
        htmlFor="expense-account-number"
        text="Account Number"
        optional={!isDisabled}
      />
      <FormField
        id="expense-account-number"
        name="account-number"
        data-testid="add-expense-account-number"
        value={accountNumber}
        onChange={({ target }) => setAccountNumber(target.value.trimLeft())}
        onBlur={() =>
          onUpdate({ type: 'SET_ACCOUNT_NUMBER', payload: accountNumber })
        }
        disabled={isDisabled}
      />
    </div>
  );
};

export default ExpenseAccountNumber;
