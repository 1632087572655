import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import ChurnFormulaField from '@/components/Revenue/ChurnFormulaField';
import PriceFormulaField from '@/components/Revenue/PriceFormulaField';
import Button from '@/components/common/Button';
import FormField from '@/components/common/FormField';
import FormLabel from '@/components/common/FormLabel';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import NumberField from '@/components/common/NumberField';
import Select from '@/components/common/Select';
import {
  DECIMAL_PLACES_ERROR,
  MAX_LIMIT_ERROR,
  MIN_LIMIT_ERROR,
} from '@/constants/errors';
import { invoiceTypes } from '@/constants/expenses';
import VALID_FORMULA_MSG from '@/constants/formulas';
import {
  PRODUCT_FREQUENCY_FOR_SELECTION,
  ONE_TIME_INDEX,
  MONTHLY_INDEX,
  CUSTOM_MONTH_INDEX,
  REVENUE_API_NAME_MAX_LENGTH,
} from '@/constants/revenue';
import { units } from '@/constants/variables';
import { compose, isNumber, shallowCompareObject } from '@/helpers';
import { formatValueForSave } from '@/helpers/percentageFormulaFormatter';
import {
  isEmptyOrNull,
  validateDecimalPlaces,
  validateLowerBound,
  validateUpperBound,
} from '@/helpers/validators';
import validateCustomFormula from '@/services/formula.service';
import './ProductModal.scss';

const MAX_MONTHLY_FREQUENCY = 72;

/**
 * @typedef {{
 *   name: string;
 *   priceFormula: string;
 *   frequency: string;
 *   churnFormula: string;
 *   churnType: string;
 *   collectionTermId: string;
 *   invoiceTiming: string;
 *   frequencyMonths?: number;
 * }} PricingPlan
 */

/** @type {PricingPlan} */
const initialPricingPlan = {
  name: '',
  priceFormula: '',
  frequency: '',
  churnFormula: '',
  churnType: units.PERCENTAGE,
  collectionTermId: '',
  invoiceTiming: invoiceTypes.UPFRONT,
  frequencyMonths: null,
};

const isValidFrequency = (frequency) => {
  return frequency > 0 && frequency <= MAX_MONTHLY_FREQUENCY;
};

const validateStaticNumber = (formula, isPercentageChurn) => {
  const validate = compose(
    validateDecimalPlaces,
    validateUpperBound,
    validateLowerBound,
  );

  const { msg } = validate({
    value: formula,
    minLimit: 0,
    maxLimit: isPercentageChurn ? 100 : null,
    maxDecimals: 2,
    msg: '',
  });

  switch (msg) {
    case MIN_LIMIT_ERROR:
      return {
        isValid: false,
        msg: isPercentageChurn
          ? "Churn rate can't be negative"
          : "Churn customers can't be negative",
      };
    case MAX_LIMIT_ERROR:
      return { isValid: false, msg: 'Please enter a valid percentage' };
    case DECIMAL_PLACES_ERROR:
      return {
        isValid: false,
        msg: `Decimal inputs are allowed up to 2 decimal places.`,
      };
    default:
      return { isValid: true, msg: VALID_FORMULA_MSG };
  }
};

/**
 * @type {(props: {
 *   scenarioId: number;
 *   pricingPlan?: ProductPricingPlan;
 *   addPricingPlan?: (pricingPlan: ProductPricingPlan) => void;
 *   editPricingPlan?: (
 *     pricingPlan: ProductPricingPlan,
 *     planIndex: number,
 *   ) => void;
 *   onCancel: () => void;
 *   selectedPricingPlanIndex?: number;
 *   collectionTerms: import('@/types/services/backend').CollectionTermEntity[];
 *   hasWritePermission: boolean;
 *   hasPricingPlans: boolean;
 * }) => React.ReactElement}
 */
function PricingPlanForm({
  scenarioId,
  pricingPlan,
  addPricingPlan,
  editPricingPlan,
  onCancel,
  selectedPricingPlanIndex,
  collectionTerms,
  hasWritePermission,
  hasPricingPlans,
}) {
  const [isChurnFormulaValid, setIsChurnFormulaValid] = useState(false);
  const [isNameValid, setIsNameValid] = useState(false);
  const [isPriceFormulaValid, setIsPriceFormulaValid] = useState(false);
  /** @type {[PricingPlan, React.Dispatch<PricingPlan>]} */
  const [currentPricingPlan, setCurrentPricingPlan] = useState(
    pricingPlan ?? initialPricingPlan,
  );
  const [pricingPlanBeforeEdit, setPricingPlanBeforeEdit] =
    useState(initialPricingPlan);
  const [churnValidationMsg, setChurnValidationMsg] = useState('');
  const [hasPriceChanged, setHasPriceChanged] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const isPlanValid =
    isNameValid &&
    isPriceFormulaValid &&
    (currentPricingPlan.frequency !== CUSTOM_MONTH_INDEX ||
      (currentPricingPlan.frequency === CUSTOM_MONTH_INDEX &&
        isValidFrequency(currentPricingPlan.frequencyMonths))) &&
    (currentPricingPlan.frequency !== ONE_TIME_INDEX ||
      (currentPricingPlan.frequency === ONE_TIME_INDEX && isChurnFormulaValid));

  const resetPricingPlanFields = () => {
    setCurrentPricingPlan(initialPricingPlan);
  };

  const [fieldErrors, setFieldErrors] = useState({
    priceFormula: false,
    frequency: false,
    churnFormula: false,
    collectionTermId: false,
  });

  const onAddOrEdit = (add = true) => {
    let successfulAdd = true;
    if (
      isEmptyOrNull(currentPricingPlan.priceFormula) ||
      isEmptyOrNull(currentPricingPlan.frequency) ||
      (isEmptyOrNull(currentPricingPlan.churnFormula) &&
        currentPricingPlan.frequency !== ONE_TIME_INDEX)
    ) {
      setFieldErrors({
        priceFormula: isEmptyOrNull(currentPricingPlan.priceFormula),
        frequency: isEmptyOrNull(currentPricingPlan.frequency),
        churnFormula: !isEmptyOrNull(currentPricingPlan.churnFormula),
      });
      return;
    }

    const currentPlanWithTermName = {
      ...currentPricingPlan,
      churnFormula: formatValueForSave(
        currentPricingPlan.churnFormula,
        currentPricingPlan.churnType,
      ),
      collectionTermName: collectionTerms.find(
        (term) => term.id === currentPricingPlan.collectionTermId,
      )?.name,
    };
    if (add) {
      successfulAdd = addPricingPlan(currentPlanWithTermName);
    } else {
      successfulAdd = editPricingPlan(
        currentPlanWithTermName,
        selectedPricingPlanIndex,
      );
    }

    if (successfulAdd) {
      resetPricingPlanFields();
    }
  };

  const handleChurnValidation = async (value, isPercentageChurn) => {
    if (value === '') return;
    if (isNumber(value)) {
      const { isValid, msg } = validateStaticNumber(value, isPercentageChurn);
      setChurnValidationMsg(msg);
      setIsChurnFormulaValid(isValid);
    } else {
      const {
        data: { data },
      } = await validateCustomFormula(scenarioId, value);
      setChurnValidationMsg(data.valid ? VALID_FORMULA_MSG : data.error);
      setIsChurnFormulaValid(data.valid);
    }
  };

  useEffect(() => {
    setCurrentPricingPlan((current) => ({
      ...current,
      collectionTermId: current?.collectionTermId || collectionTerms[0].id,
    }));
  }, [collectionTerms]);

  useEffect(() => {
    if (editPricingPlan) {
      setPricingPlanBeforeEdit(currentPricingPlan);
      // When a user opens the plan in "edit" mode, the formula is valid
      // because only valid formulas can be created in the first place and they
      // cannot become invalid between creation and "edit" mode
      setIsChurnFormulaValid(true);
      setIsPriceFormulaValid(true);
      setIsNameValid(true); // Only valid names can be created
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, []);

  const collectionTermsList = useMemo(
    () =>
      hasWritePermission
        ? collectionTerms
        : [
            {
              id: currentPricingPlan.collectionTermId,
              name: currentPricingPlan.collectionTermName,
            },
          ],
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [collectionTerms],
  );

  const handlePriceChange = (newPrice) => {
    setCurrentPricingPlan((current) => ({
      ...current,
      priceFormula: newPrice,
    }));
  };

  return (
    <div className="Form">
      <div className="Form_Group">
        <label htmlFor="pricing-plan-name" className="Label">
          Name of Pricing Plan
        </label>
        <FormField
          id="pricing-plan-name"
          name="pricingPlanName"
          maxLength={REVENUE_API_NAME_MAX_LENGTH}
          value={currentPricingPlan.name}
          onChange={({ target }) => {
            setIsNameValid(!!target.value);
            setCurrentPricingPlan((current) => ({
              ...current,
              name: target.value,
            }));
          }}
          validate={(value) => {
            setIsNameValid(!!value);
            return !value ? 'Must provide a plan name' : '';
          }}
        />
      </div>
      <div className="Form">
        <div className="Form_Group">
          <PriceFormulaField
            value={currentPricingPlan.priceFormula}
            onValidation={setIsPriceFormulaValid}
            onChange={handlePriceChange}
          />
        </div>
        <div className="Form_Group Form_Group-halfWidth">
          <label htmlFor="frequency" className="Label">
            What is the frequency?
          </label>
          <Select
            id="frequency"
            name="frequency"
            value={currentPricingPlan.frequency}
            validate={() =>
              isEmptyOrNull(currentPricingPlan.frequency) &&
              'Field is required!'
            }
            onChange={({ target: { value } }) => {
              const frequency = Number(value);
              setCurrentPricingPlan((current) => ({
                ...current,
                frequency,
                frequencyMonths: null,
                churnFormula:
                  frequency === ONE_TIME_INDEX
                    ? ''
                    : currentPricingPlan.churnFormula,
                invoiceTiming:
                  frequency === MONTHLY_INDEX || frequency === ONE_TIME_INDEX
                    ? invoiceTypes.UPFRONT
                    : currentPricingPlan.invoiceTiming,
              }));
              setChurnValidationMsg(
                frequency === ONE_TIME_INDEX ? '' : churnValidationMsg,
              );
              setIsChurnFormulaValid(frequency === ONE_TIME_INDEX);
            }}
            showErrors={fieldErrors.frequency}
            disabled={
              currentPricingPlan &&
              (currentPricingPlan.isUsed || currentPricingPlan.isUsedInStripe)
            }
          >
            <option key="" value="">
              Select
            </option>
            {Object.entries(PRODUCT_FREQUENCY_FOR_SELECTION).map(
              ([id, name]) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ),
            )}
          </Select>
        </div>
        {currentPricingPlan.frequency === CUSTOM_MONTH_INDEX && (
          <div className="Form_Group Form_Group-halfWidth">
            <FormLabel htmlFor="custom-frequency" text="Number of Months" />
            <NumberField
              id="custom-frequency"
              name="customFrequency"
              precision={0}
              allowNegativeValues={false}
              className="PricingPlanForm_CustomFrequency"
              value={currentPricingPlan.frequencyMonths}
              validate={() =>
                !isValidFrequency(currentPricingPlan.frequencyMonths) &&
                'Months must be between 1-72'
              }
              onChange={(_, value) => {
                setCurrentPricingPlan((current) => ({
                  ...current,
                  frequencyMonths: value !== '' ? value : null,
                  invoiceTiming:
                    value === 1
                      ? invoiceTypes.UPFRONT
                      : currentPricingPlan.invoiceTiming,
                }));
              }}
              disabled={
                currentPricingPlan &&
                (currentPricingPlan.isUsed || currentPricingPlan.isUsedInStripe)
              }
            />
          </div>
        )}
      </div>
      <ChurnFormulaField
        value={currentPricingPlan.churnFormula}
        churnType={currentPricingPlan.churnType}
        isDisabled={Number(currentPricingPlan.frequency) === ONE_TIME_INDEX}
        validationMsg={churnValidationMsg}
        onChangeSelection={(newType) => {
          handleChurnValidation(
            currentPricingPlan.churnFormula,
            newType === units.PERCENTAGE,
          );
          setCurrentPricingPlan((current) => ({
            ...current,
            churnType: newType,
          }));
        }}
        onChange={(newChurn) => {
          setCurrentPricingPlan((current) => ({
            ...current,
            churnFormula: newChurn,
          }));
        }}
        handleValidation={handleChurnValidation}
        onFocus={() => {
          setChurnValidationMsg('');
          setIsChurnFormulaValid(false);
        }}
      />

      <div className="Form_Group Form_Group-halfWidth">
        <FormLabel
          htmlFor="collectionTerm"
          text="Collection Term"
          tooltip='Collection Term refers to the collection timing of the invoice.
              The default is "Immediate", which means that collection occurs in
              the same month as the invoice. You may add unique collection terms
              (e.g. Net 60) in Settings and they will be displayed here.'
        />
        <Select
          id="collectionTerm"
          name="collectionTerm"
          value={currentPricingPlan.collectionTermId}
          onChange={(e) => {
            setCurrentPricingPlan((current) => ({
              ...current,
              collectionTermId: Number(e.target.value),
            }));
          }}
        >
          {collectionTermsList.map((term) => (
            <option key={term.id} value={term.id}>
              {term.name}
            </option>
          ))}
        </Select>
      </div>
      <div className="Form_Group Form_Group-halfWidth">
        <FormLabel
          htmlFor="invoiceTiming"
          text="Invoice Timing"
          tooltip='Select "Upfront" if the invoice is sent on the first month of
                the frequency term. Select "In Arrears" if the invoice is sent
                on the last month of the frequency term. For monthly
                subscriptions, this selection is not available because monthly
                subscriptions are assumed to be invoiced in the same month.'
        />
        <Select
          id="invoiceTiming"
          value={currentPricingPlan.invoiceTiming}
          onChange={(e) => {
            setCurrentPricingPlan((current) => ({
              ...current,
              invoiceTiming: e.target.value,
            }));
          }}
          disabled={
            isEmptyOrNull(currentPricingPlan.frequency) ||
            [MONTHLY_INDEX, ONE_TIME_INDEX].includes(
              currentPricingPlan.frequency,
            ) ||
            (currentPricingPlan.frequency === CUSTOM_MONTH_INDEX &&
              currentPricingPlan.frequencyMonths === 1)
          }
        >
          <option value={invoiceTypes.UPFRONT}>Upfront</option>
          <option value={invoiceTypes.ARREARS}>In Arrears</option>
        </Select>
      </div>

      <div className="Form_Group">
        <div className="PricingPlanFooter">
          {hasWritePermission ? (
            <>
              {hasPricingPlans && (
                <Button
                  onClick={() => {
                    setHasPriceChanged(false);
                    onCancel();
                  }}
                  className="Button Button-cancelLink"
                  data-testid="add-revenue-plan-cancel"
                >
                  Cancel
                </Button>
              )}
              {currentPricingPlan && editPricingPlan ? (
                <Button
                  onClick={() => {
                    const isEditMode = !isEmptyOrNull(currentPricingPlan?.id);
                    if (hasPriceChanged && isEditMode) {
                      setShowWarning(true);
                    } else {
                      onAddOrEdit(false);
                      setPricingPlanBeforeEdit(currentPricingPlan);
                    }
                  }}
                  className="Button Button-primaryLink"
                  data-testid="add-revenue-plan-edit"
                  disabled={
                    !isPlanValid ||
                    !shallowCompareObject(
                      pricingPlanBeforeEdit,
                      currentPricingPlan,
                    )
                  }
                >
                  Save Edit
                </Button>
              ) : (
                <Button
                  onClick={() => onAddOrEdit(true)}
                  className="Button Button-primaryLink"
                  data-testid="add-revenue-plan-save"
                  disabled={!isPlanValid}
                >
                  Add Plan
                </Button>
              )}
            </>
          ) : (
            <Button
              onClick={() => {
                setHasPriceChanged(false);
                onCancel();
              }}
              className="Button Button-primaryLink"
              data-testid="add-revenue-plan-close"
            >
              Close
            </Button>
          )}
        </div>
      </div>
      {showWarning && (
        <ModalConfirmation
          id="warning-modal"
          actionBtnTxt="Confirm"
          onCancel={() => setShowWarning(false)}
          onAction={() => {
            setHasPriceChanged(false);
            setShowWarning(false);
            onAddOrEdit(false);
          }}
        >
          This change to the pricing plan will only affect future months'
          forecasts. Previous months' actuals will not be changed.
        </ModalConfirmation>
      )}
    </div>
  );
}

const mapStateToProps = ({ scenario, settings }) => ({
  collectionTerms: settings.collectionTerms.collectionTerms,
  scenarioId: scenario.scenarioId,
});

export default connect(mapStateToProps)(PricingPlanForm);
