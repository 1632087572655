import CircularReferenceIcon from '@bill/cashflow.assets/circular-reference';
import WithTooltip from '@/components/common/WithTooltip';
import {
  CIRCULAR_REFERENCE_HEADING,
  CIRCULAR_REFERENCE_WARNING_EMPLOYEE,
} from '@/constants/circularReference';

export default function CircularRefRenderer({ data, valueFormatted }) {
  return (
    <>
      {data.faulted && (
        <WithTooltip
          content={
            <>
              <div
                className="InfoTooltip_Title"
                data-testid="employee-ref-error-table-tooltip-title"
              >
                {CIRCULAR_REFERENCE_HEADING}
              </div>
              <span
                className="InfoTooltip_Text"
                data-testid="employee-ref-error-table-tooltip-text"
              >
                {CIRCULAR_REFERENCE_WARNING_EMPLOYEE}
              </span>
            </>
          }
        >
          <span>
            <CircularReferenceIcon className="CircularReference" />
          </span>
        </WithTooltip>
      )}
      {valueFormatted}
    </>
  );
}
