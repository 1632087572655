import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartLegend from '@/components/Charts/ChartLegend';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import '@/components/Charts/ExpandedViewChart.scss';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import Switch from '@/components/common/Switch';
import TogglePersistent from '@/components/common/TogglePersistent';
import { EXPENSE_CLASS, EXPENSE_DEPT } from '@/constants/expenses';
import {
  TOTAL_EXPENSES_SWITCH_ID,
  TOTAL_EXPENSES_TOGGLE_ID,
} from '@/constants/ui';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import useCallbackRef from '@/hooks/useCallbackRef';
import useChartPreferences from '@/hooks/useChartPreferences';
import useDashboardPath from '@/hooks/useDashboardPath';
import useTotalExpensesTableData from '@/hooks/useTotalExpensesTableData';
import { valueFormatter } from '@/pages/Actuals/helpers';
import TotalExpensesPercentageChart from '@/pages/Dashboard/charts/TotalExpensesPercentageChart';
import TotalExpensesChart from './charts/TotalExpensesChart';

const CHART_TITLE = 'Total Expenses';
const SPREADSHEET_ID = 'total-expenses-chart-table';

function TotalExpenses({ startDate, endDate, expensesList, employeesList }) {
  const [chart, setChart] = useState();
  const [queries, setQueries] = useState([]);
  const includePayroll = useChartPreferences(TOTAL_EXPENSES_SWITCH_ID);

  const hasData =
    (includePayroll && employeesList.length > 0) || expensesList?.length > 0;

  const [{ data: baseData, isLoading = true } = {}] = queries;

  const colDefs = useMemo(
    () => [
      {
        field: 'name',
        headerName: CHART_TITLE,
      },
    ],
    [],
  );

  const tableData = useTotalExpensesTableData(queries);
  const dashboardPath = useDashboardPath();
  const [gridApi, gridRef] = useCallbackRef();

  return (
    <>
      <section className="ChartPanel">
        <ChartHeader back={dashboardPath} title={CHART_TITLE}>
          <div className="ChartHeader_KPIValue">
            {!isLoading
              ? formatMonetary(baseData.overallTotal)
              : 'Awaiting Data'}
          </div>
          <div className="ChartHeader_KPI">
            <br />
            {formatRangeWithShortYear(startDate, endDate)}
          </div>
        </ChartHeader>
        <ChartToolbar className="Chart_Toolbar-withLegend">
          <ChartLegend chart={chart} data-testid="totalExpenses-legend" />
          <>
            <Switch
              id={TOTAL_EXPENSES_SWITCH_ID}
              on={includePayroll ?? true}
              label="Include Payroll"
            />
            <TogglePersistent
              id={TOTAL_EXPENSES_TOGGLE_ID}
              options={[
                {
                  label: 'Category',
                  value: EXPENSE_CLASS,
                },
                {
                  label: 'Dept',
                  value: EXPENSE_DEPT,
                },
              ]}
              value={EXPENSE_CLASS}
            />
            {!isLoading && (
              <ChartControls
                chartRef={chart}
                data-testid="total-expenses-controls"
                title={CHART_TITLE}
                metricValue={formatMonetary(baseData.overallTotal)}
                label={formatRangeWithShortYear(startDate, endDate)}
                disabled={!hasData}
              />
            )}
          </>
        </ChartToolbar>
        <TotalExpensesChart
          className="ExpandedView_ChartWrapper"
          onChartCreated={setChart}
          onQueryStateChange={setQueries}
        />
      </section>
      <TotalExpensesPercentageChart />
      <section className="Panel Panel-toEdge">
        <SpreadsheetToolbar editable={false} gridApi={gridApi}>
          Options:
          <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
        </SpreadsheetToolbar>
        <MonthlySpreadsheet
          ref={gridRef}
          columnDefs={colDefs}
          data={tableData}
          data-testid={SPREADSHEET_ID}
          editable={false}
          enableComparison
          getRowId={({ data }) => data.name}
          valueFormatter={valueFormatter}
          suppressMaintainUnsortedOrder={false}
        />
      </section>
    </>
  );
}

function mapStateToProps({ expenses, shared, employees }) {
  return {
    startDate: shared.startDate,
    endDate: shared.endDate,
    expensesList: expenses.expensesList.expenses,
    employeesList: employees.employeesList,
  };
}

export default connect(mapStateToProps)(TotalExpenses);
