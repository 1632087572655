/* eslint-disable no-underscore-dangle, jsdoc/require-example -- predates description requirement */

const DELIMITERS = /^[\s+*\-[\]()/]/;
const DEFAULT_DIFF_RESULT = {
  variableName: '',
  variableStart: null,
  variableEnd: null,
};

/**
 * Parse a custom formula looking for variable names, regardless of its positon
 * in the formula.
 */
export default class FormulaParser {
  #variables;

  #previousFormula;

  #previousChanged;

  constructor() {
    this.#variables = [];
    this.#previousFormula = '';
    this.#previousChanged = DEFAULT_DIFF_RESULT;
  }

  /**
   * Diff variable names between changes to the formula.
   *
   * If it determines a variable has changed, it sets the changed variable's new
   * name and start / end positions in the formula.
   *
   * @param {Array} variables - the variables in the formula
   */
  diffVariables(variables) {
    const clonedVariables = [...variables];
    const changedIdx = clonedVariables.findIndex(
      (item) =>
        !this.#variables.find((i) => i.variableName === item.variableName),
    );

    const changed = clonedVariables[changedIdx];

    if (changed || clonedVariables.length > this.#variables.length) {
      const previousChanged =
        changed ?? clonedVariables[clonedVariables.length - 1];
      this.#variables = clonedVariables;
      this.#previousChanged = previousChanged;
      return previousChanged;
    }

    if (clonedVariables.length !== this.#variables.length) {
      this.#variables = clonedVariables;
      return DEFAULT_DIFF_RESULT;
    }

    return DEFAULT_DIFF_RESULT;
  }

  /**
   * Parse the formula looking for custom and system variables
   *
   * @example
   *   parser.parse('some.variable * another.variable + 1.5');
   *
   * @param {string} formula - The formula being parsed
   */
  parse(formula) {
    if (formula === this.#previousFormula) {
      return this.#previousChanged;
    }
    // concat a space to simplify parsing logic
    const symbols = formula.split('').concat(' ');
    let variableName = '';
    let variables = [];
    let index = 0;
    let variableStart = null;
    let variableEnd = null;

    while (symbols.length > index) {
      if (/[a-zA-Z0-9]/.test(symbols[index])) {
        variableStart = index;
        while (!DELIMITERS.test(symbols[index])) {
          variableName += symbols[index];
          variableEnd = index;
          index += 1;
        }
        // eslint-disable-next-line no-continue -- predates description requirement
        continue;
      } else {
        variables = variableName
          ? [
              ...variables,
              {
                variableName,
                variableEnd,
                variableStart,
              },
            ]
          : variables;
        variableName = '';
        variableStart = null;
        variableEnd = null;
      }
      index += 1;
    }

    this.#previousFormula = formula;
    return this.diffVariables(variables);
  }

  /** Reset internal state, for example after a formula has been deleted */
  reset() {
    this.#variables = [];
  }
}
