// @ts-check
import jwtDecode from 'jwt-decode';
import {
  BILL_AUTHENTICATION_FAILED,
  BILL_AUTHENTICATION_STARTED,
  BILL_AUTHENTICATION_SUCCEEDED,
} from '@/actionTypes/auth';
import { getCompanies } from '@/services/companies';
import { authenticateOnboardedUser } from '@/services/onboarding.service';
import { subscribeToTopicWithTempToken } from './shared';

/** @type {() => import('@/types/extend-redux-toolkit').AppThunk} */
export const billAuthenticationStartedAction = () => {
  return (dispatch) => {
    dispatch({ type: BILL_AUTHENTICATION_STARTED });
  };
};

/** @type {() => import('@/types/extend-redux-toolkit').AppThunk} */
export const billAuthenticationFailedAction = () => {
  return (dispatch) => {
    dispatch({ type: BILL_AUTHENTICATION_FAILED });
  };
};

/**
 * @type {(
 *   data: import('@/types/services/backend').IatResponse,
 * ) => import('@/types/extend-redux-toolkit').AppThunk}
 */
export const billAuthenticationSucceededAction = (data) => {
  return async (dispatch, getState) => {
    try {
      const {
        companies: { selectedCompanyId },
      } = getState();

      /** @type {Partial<import('@/reducers/auth').AuthState>} */
      const tokenData = jwtDecode(data.jwtToken);
      const {
        data: { data: companies },
      } = await getCompanies();

      dispatch({
        type: BILL_AUTHENTICATION_SUCCEEDED,
        payload: {
          auth: {
            ...data,
            ...tokenData,
          },
          companies,
          selectedCompanyId: data.selectedCompanyId,
          previousCompanyId: selectedCompanyId,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
};

/**
 * @typedef {{
 *   status: import('@/types/services/backend').CompanyOnboardingStatusDto['onboardingStatus'];
 * }} Payload
 */

/** @typedef {(payload: Payload) => void} OnboardStatusCallback */

/**
 * Action to subscribe to onboarding status
 *
 * @type {(
 *   dataProps: { orgId: string; tempIdentityToken: string | null },
 *   callback: OnboardStatusCallback,
 * ) => ReturnType<typeof subscribeToTopicWithTempToken>}
 */
export const subscribeToOnboardingStatusAction = (
  { orgId, tempIdentityToken },
  callback,
) => {
  return (dispatch) => {
    return dispatch(
      subscribeToTopicWithTempToken(
        { topic: `planning-onboarding-status/${orgId}`, tempIdentityToken },
        callback,
      ),
    );
  };
};

/**
 * Retry the '/api/v1/planning/access-token' endpoint when it returns a 400
 * error
 *
 * @type {() => import('@/types/extend-redux-toolkit').AppThunk<
 *   Promise<void>
 * >}
 */
export const pollAuthenticateOnboardUser = () => {
  return async (dispatch) => {
    try {
      const { data } = await authenticateOnboardedUser();
      dispatch(billAuthenticationSucceededAction(data));
    } catch (error) {
      if (error.response?.status === 400) {
        setTimeout(() => {
          dispatch(pollAuthenticateOnboardUser());
        }, 10000);
      }
    }
  };
};
