// @ts-check
import { useCallback } from 'react';
import { useStore } from 'react-redux';

/**
 * A custom hook to check if a column is toggled in user preferences
 *
 * @type {(
 *   spreadsheetId: string,
 * ) => (colId: string, options?: { isHiddenByDefault?: boolean }) => boolean}
 */
const useColumnHidden = (spreadsheetId) => {
  /** @type {import('redux').Store<import('@/store').RootState>} */
  const store = useStore();

  return useCallback(
    (colId, { isHiddenByDefault } = { isHiddenByDefault: true }) => {
      const { preferences } = store.getState().auth;
      const userColumnPreferences = preferences[`${spreadsheetId}-columns`];
      return userColumnPreferences && Array.isArray(userColumnPreferences)
        ? !userColumnPreferences.includes(colId)
        : isHiddenByDefault;
    },
    [spreadsheetId, store],
  );
};

export default useColumnHidden;
