// @ts-check
import WithTooltip from '@/components/common/WithTooltip';
import './AdminNamesRenderer.scss';

/**
 * Renders admin names in the cell with tooltip
 *
 * @type {(
 *   params: import('ag-grid-community').ICellRendererParams<
 *     import('@/reducers/companies').Company,
 *     string[]
 *   >,
 * ) => React.ReactElement}
 */
const AdminNamesRenderer = ({ value }) => {
  const names = value?.slice(0, 3).map((name) =>
    name
      .split(' ')
      .map((subName) => subName.charAt(0))
      .join('')
      .toUpperCase(),
  );
  const remainingNames = value?.slice(3);
  return (
    <>
      {names?.map((name, index) => (
        <span key={value[index]}>
          <WithTooltip
            key={value[index]}
            content={<span>{value[index]}</span>}
            placement="bottom"
          >
            <span>{name}</span>
          </WithTooltip>
          {index !== value.length - 1 && <span>, </span>}
        </span>
      ))}
      {remainingNames?.length > 0 && (
        <WithTooltip
          placement="bottom"
          content={remainingNames.map((name) => (
            <div key={name}>{name}</div>
          ))}
        >
          <span className="AdminNamesRenderer_OthersText">
            +{remainingNames.length} other{remainingNames.length > 1 && 's'}
          </span>
        </WithTooltip>
      )}
    </>
  );
};

export default AdminNamesRenderer;
