// @ts-check
import { useMemo, useCallback, useState, useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { connect, useDispatch } from 'react-redux';
import { Prompt } from 'react-router-dom';
import EmployeesEmptyIcon from '@bill/cashflow.assets/employees-empty';
import { v4 as uuidv4 } from 'uuid';
import {
  getAllJobTitlesAction,
  getEmployees as getEmployeesAction,
  subscribeToEmployeeFaultedUpdateAction,
} from '@/actions/employees';
import CircularRefRenderer from '@/components/Employee/CircularRefRenderer';
import ContextMenuRenderer from '@/components/Employee/ContextMenuRenderer';
import { SPREADSHEET_ID } from '@/components/Employee/constants';
import EmptyData from '@/components/common/EmptyData';
import {
  cancelBtnSelector,
  getMissingFieldErrors,
} from '@/components/common/MonthlySpreadsheet/helpers';
import PlusButton from '@/components/common/PlusButton';
import Spreadsheet from '@/components/common/Spreadsheet';
import ColumnToggle from '@/components/common/Spreadsheet/ColumnToggle';
import GroupingToggle from '@/components/common/Spreadsheet/GroupingToggle';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import WithEditing from '@/components/common/Spreadsheet/WithEditing';
import {
  DEPARTMENT_ID,
  EMPTY_CELL_VALUE,
} from '@/components/common/Spreadsheet/constants';
import AmountEditor from '@/components/common/Spreadsheet/editors/AmountEditor';
import BonusCommissionEditor from '@/components/common/Spreadsheet/editors/BonusCommissionEditor';
import JobTitleEditor from '@/components/common/Spreadsheet/editors/JobTitleEditor';
import NumericEditor from '@/components/common/Spreadsheet/editors/NumericEditor';
import SelectEditor from '@/components/common/Spreadsheet/editors/SelectEditor';
import TextEditor from '@/components/common/Spreadsheet/editors/TextEditor';
import {
  departmentNameComparator,
  setDepartment,
  sanitizeValue,
  isFirstCol,
} from '@/components/common/Spreadsheet/helpers';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import WithErrorRenderer from '@/components/common/Spreadsheet/renderers/WithErrorRenderer';
import useUpdateQueue from '@/components/common/Spreadsheet/useUpdateQueue';
import {
  EMPLOYMENT_TYPE,
  salaryIntervals,
  ROLE_TYPE,
  BENEFITS_AND_TAXES,
  LOAD_MULTIPLIER,
  TERM_DATE,
  START_DATE,
  units,
  TITLE_ID,
  NAME,
  SALARY_AMOUNT,
  SALARY_FORMULA,
  BONUS_FORMULA,
  OVERRIDE_GLOBAL_LOAD_MULTIPLIER,
  PAID_VIA_HRIS,
  SALARY_VARIABLE_ID,
  VIEW_FORMULA,
} from '@/constants/employees';
import { FINCH_BAMBOOHR, integrationFamily } from '@/constants/integrations';
import { filterObject, formatPercent, naturalSortComparator } from '@/helpers';
import convertDecimalToPercent from '@/helpers/convertDecimalToPercent';
import formatMonetary from '@/helpers/formatMonetary';
import { isEmptyOrNull } from '@/helpers/validators';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import useColumnHidden from '@/hooks/useColumnHidden';
import useIntegrationsConnected from '@/hooks/useIntegrationsConnected';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import useWsSubscription from '@/hooks/useWsSubscription';
import getSelectedCompany from '@/selectors/getSelectedCompany';
import {
  getCurrentEmployeeInfo,
  updateEmployees,
} from '@/services/employee.service';
import './EmployeeGrid.scss';

const EXPAND_ALL = -1;

const FULL_TIME = 'Full-time Employee';
const PART_TIME = 'Part-time Employee';

const ROLE_TYPE_DISPLAY_MAP = {
  [FULL_TIME]: 'FTE',
  [PART_TIME]: 'PTE',
  Contractor: 'Contractor',
  Other: 'Other',
};
const PAID_VIA_HRIS_MAP = { Yes: true, No: false };
const PAID_VIA_HRIS_TOOLTIP =
  'Yes - utilize data from HRIS / payroll platform for actuals (default for most HRIS platforms). No - keep forecasted values for actuals.';

const paidViaHrisOptions = Object.entries(PAID_VIA_HRIS_MAP).map(
  ([key, value]) => ({
    id: value,
    name: key,
  }),
);

const REQUIRED_FIELDS = [DEPARTMENT_ID, TITLE_ID, START_DATE, NAME, ROLE_TYPE];

const currencyFormatter = (data, value) => {
  const { salaryVariableId } = data;
  return !isEmptyOrNull(value) && value >= 0
    ? `${formatMonetary(value)}${
        salaryVariableId ? salaryIntervals.MONTHLY : salaryIntervals.ANNUALLY
      }`
    : EMPTY_CELL_VALUE;
};

const bonusCommissionFormatter = ({ data }) => {
  const { bonusAmount, unit } = data;
  if (!isEmptyOrNull(bonusAmount) && bonusAmount >= 0) {
    return unit === units.NUMBER
      ? formatMonetary(bonusAmount)
      : formatPercent(convertDecimalToPercent(bonusAmount));
  }

  return EMPTY_CELL_VALUE;
};

const departmentFormatter = ({ data }) =>
  data.departmentName ?? (data.isUnsaved && '');

const formattedName = ({ titleLabel }, employeeList) => {
  const postFix =
    employeeList.filter((employee) =>
      new RegExp(`${titleLabel}( \\d+)?`).test(employee.name),
    ).length + 1;
  return `${titleLabel} ${postFix}`;
};
const roleTypes = Object.keys(ROLE_TYPE_DISPLAY_MAP).map((key) => ({
  id: key,
  name: key,
}));

const roleTypeFormatter = ({ value }) => ROLE_TYPE_DISPLAY_MAP[value] ?? value;

const salaryFormatter = ({ data }) => {
  const { annualSalary, salary, salaryVariableId } = data;
  if (!isEmptyOrNull(annualSalary)) {
    return `${formatMonetary(annualSalary)}${salaryIntervals.ANNUALLY}`;
  }
  if (!isEmptyOrNull(salary)) {
    return `${formatMonetary(salary)}${
      salaryVariableId ? salaryIntervals.MONTHLY : salaryIntervals.ANNUALLY
    }`;
  }

  return EMPTY_CELL_VALUE;
};
const nullsLastComparator = (a, b, aRow, bRow, isInverse) => {
  const order = naturalSortComparator(a, b);
  return !isInverse && (isEmptyOrNull(a) || isEmptyOrNull(b)) ? -order : order;
};

const getMissingFields = (empData) => {
  const missingFields = REQUIRED_FIELDS.filter((field) =>
    isEmptyOrNull(empData[field]),
  );
  if (
    isEmptyOrNull(empData[SALARY_AMOUNT]) &&
    isEmptyOrNull(empData[SALARY_FORMULA])
  ) {
    missingFields.push(SALARY_FORMULA);
  }
  return missingFields;
};

const titleValueGetter = ({ data }) => data.titleLabel;
const bonusValueGetter = ({ data }) =>
  data.unit === units.PERCENTAGE
    ? convertDecimalToPercent(data.bonusAmount)
    : data.bonusAmount;

const DefaultOption = ({ text }) => (
  <option value="" disabled>
    {text}
  </option>
);
const DepartmentDefaultOption = () => (
  <DefaultOption text="Select Department" />
);
const RoleDefaultOption = () => <DefaultOption text="Select a role" />;
const HrisDefaultOption = () => <DefaultOption text={EMPTY_CELL_VALUE} />;

const passesZeroFilter = ({ data }) => data.isUnsaved || data.salary;

/**
 * @type {(
 *   params: import('ag-grid-community').ICellRendererParams,
 * ) => import('react').ReactElement}
 */
const GroupRowInnerRenderer = (params) => {
  const { node, value } = params;
  const count = node.allChildrenCount;
  const employeesCount = `${value} (${count} ${
    count === 1 ? 'employee' : 'employees'
  })`;

  return <span title={employeesCount}>{`${value} (${count})`}</span>;
};

const EmployeeGrid = ({
  apiRef,
  getEmployees,
  isLoading,
  selectedCompany,
  onDeleteEmployee,
  onSelectEmployees,
  scenarioId,
  startDate,
  endDate,
  onEditEmployee,
  departments,
  userPreferences,
  onAddEmployeeToGrid,
  onDuplicate,
  employees,
  onDeleteUnsaved,
  getAllJobTitles,
  allJobTitles,
  cellFocus,
  setCellFocus,
}) => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const connectedIntegrations = useIntegrationsConnected();
  const externalPayrollIntegration = connectedIntegrations.find(
    ({ systemType }) =>
      systemType === integrationFamily.INTEGRATION_FAMILY_FINCH,
  );
  const isBambooHRConnected =
    externalPayrollIntegration?.type === FINCH_BAMBOOHR;

  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  const [errors, setErrors] = useState({});

  /** @type {[string, React.Dispatch<React.SetStateAction<string>>]} */
  const [rowToForceUpdate, setRowToForceUpdate] = useState(null);
  useEffect(() => {
    if (rowToForceUpdate) {
      const { api } = apiRef.current;
      const rowNode = api.getRowNode(rowToForceUpdate);
      if (rowNode) {
        api.refreshCells({ rowNodes: [rowNode] });
      }
      setRowToForceUpdate(null);
    }
  }, [apiRef, rowToForceUpdate]);

  const hasData = isLoading || !!employees.length;

  useBeforeUnload(hasUnsavedData);

  const isColumnHidden = useColumnHidden(SPREADSHEET_ID);

  const hasWritePermission = useNonDashboardWritePermission();

  const showFilter = useMemo(
    () => employees.some((employee) => !employee.isUnsaved),
    [employees],
  );

  const defaultColDef = useMemo(
    () => ({
      checkboxSelection: (params) =>
        hasWritePermission && !params.data.isUnsaved && isFirstCol(params),
      editable: hasWritePermission,
      headerCheckboxSelection: hasWritePermission && isFirstCol,
    }),
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [isFirstCol, hasWritePermission],
  );

  useEffect(() => {
    getAllJobTitles(scenarioId);
  }, [scenarioId, getAllJobTitles]);

  const loadMultiplierFormatter = useCallback(
    ({ data: { employmentType, loadMultiplier } }) => {
      if (
        [EMPLOYMENT_TYPE.contractor, EMPLOYMENT_TYPE.other].includes(
          employmentType,
        )
      ) {
        return EMPTY_CELL_VALUE;
      }

      return loadMultiplier !== null
        ? formatPercent(loadMultiplier)
        : formatPercent(selectedCompany.loadMultiplier);
    },
    [selectedCompany?.loadMultiplier],
  );

  const handleSelection = useCallback(
    ({ api }) => {
      const selectedEmployees = api
        .getSelectedNodes()
        .filter((row) => !row.data.isUnsaved)
        .map((row) => ({ ...row.data }));
      onSelectEmployees(selectedEmployees);
    },
    [onSelectEmployees],
  );

  const addChangeToQueue = useCallback((queue, { data }) => {
    const missingFields = getMissingFields(data);
    if (missingFields.length) {
      if (data.isUnsaved) return null;

      const newErrors = getMissingFieldErrors(missingFields);
      setErrors((current) => ({
        ...current,
        [data.id]: newErrors,
      }));
      return null;
    }

    const employeeIdx = queue.findIndex(({ id }) => id === data.id);
    if (employeeIdx < 0)
      return [
        ...queue,
        {
          ...data,
          salaryFormula: !isEmptyOrNull(data.annualSalary)
            ? null
            : data.salaryFormula,
        },
      ];

    const updatedQueue = [...queue];
    updatedQueue[employeeIdx] = data;
    return updatedQueue;
  }, []);

  const updateBulkEmployees = useCallback(
    async (queue, { api, context }) => {
      const { data } = await updateEmployees(scenarioId, queue);

      // Hide the loading indicators
      context.loadingCells = filterObject(
        context.loadingCells,
        ([rowId]) => !queue.some(({ id }) => id === rowId),
      );
      const rowNodes = queue.reduce((rows, { id }) => {
        const row = api.getRowNode(id);
        if (!row) return rows;
        return [...rows, row];
      }, []);
      api.refreshCells({ rowNodes });

      const { errors: fieldErrors } = data.data;
      setErrors((current) => {
        // The user can continue editing while a row is invalid, so we need to
        // persist any errors that were not resolved in the update
        const unresolved = filterObject(
          current,
          ([rowId]) => !queue.some(({ id }) => id === rowId),
        );
        return {
          ...unresolved,
          ...fieldErrors,
        };
      });

      if (!Object.keys(fieldErrors).length) {
        // Update all job titles, so custom title usage stays in sync
        getAllJobTitles(scenarioId);

        await getEmployees(scenarioId, startDate, endDate);
        const newlySavedEmployeeIds = queue
          .filter((employee) => employee.isUnsaved)
          .map((employee) => employee.id);
        if (newlySavedEmployeeIds.length) {
          onDeleteUnsaved(newlySavedEmployeeIds);
        }
      }
    },
    [
      getEmployees,
      scenarioId,
      startDate,
      endDate,
      onDeleteUnsaved,
      getAllJobTitles,
    ],
  );

  const handleEmployeeChange = useUpdateQueue(
    addChangeToQueue,
    updateBulkEmployees,
  );

  const onDuplicateEmployeeInline = useCallback(
    async (employeeId) => {
      try {
        const {
          data: { data: employeeData },
        } = await getCurrentEmployeeInfo(employeeId, scenarioId);
        const bonusAmount = isEmptyOrNull(employeeData.bonusFormulaId)
          ? employeeData.commissionAmount
          : employeeData.bonusAmount;
        const duplicatedData = {
          ...employeeData,
          isUnsaved: true,
          isDuplicate: true,
          name: formattedName(employeeData, employees),
          id: uuidv4(),
          bonusAmount,
          salaryFormula: employeeData.salaryVariableId
            ? employeeData.salaryFormula
            : null,
        };
        onDuplicate(duplicatedData);
        setCellFocus(NAME);
      } catch (error) {
        /* eslint-disable-next-line no-console -- predates description requirement */
        console.error(error);
      }
    },
    [scenarioId, employees, onDuplicate, setCellFocus],
  );

  useWsSubscription(
    () =>
      dispatch(
        subscribeToEmployeeFaultedUpdateAction(
          { scenarioId },
          ({ employeeId }) => {
            // ag-Grid won't rerender if the value doesn't change, so we need to force it
            setRowToForceUpdate(employeeId);
          },
        ),
      ),
    [scenarioId],
  );

  const handleRowDataUpdated = useCallback(
    ({ api }) => {
      let hasUnsaved = false;
      api.forEachLeafNode(({ data }) => {
        if (data.isUnsaved) hasUnsaved = true;
      });
      setHasUnsavedData(hasUnsaved);

      if (cellFocus) {
        setTimeout(() => {
          api.stopEditing();
          api.startEditingCell({
            rowIndex: api.getDisplayedRowCount() - 1,
            colKey: cellFocus,
          });
          setCellFocus(null);
        }, 0);
      }
    },
    [cellFocus, setCellFocus],
  );

  const handleFirstDataRendered = useCallback(
    ({ api }) => {
      if (employees.length === 1 && employees[0].isUnsaved) {
        setCellFocus(DEPARTMENT_ID);
        handleRowDataUpdated({ api });
      }
    },
    [employees, handleRowDataUpdated, setCellFocus],
  );

  const addEmployeeRow = (event) => {
    event.stopPropagation();
    onAddEmployeeToGrid();
    setCellFocus(DEPARTMENT_ID);
  };

  const isGroupedByDept =
    userPreferences[`${SPREADSHEET_ID}-GroupingToggle`] ?? true;

  /* eslint-disable no-param-reassign -- predates description requirement */
  const colDefs = useMemo(
    () => [
      {
        field: 'expenseClassName',
        hide: true,
        lockVisible: true,
        rowGroup: isGroupedByDept,
      },
      {
        field: 'departmentName',
        hide: true,
        lockVisible: true,
        rowGroup: isGroupedByDept,
      },
      {
        cellRendererSelector: (params) => {
          const { data, context, node, colDef } = params;
          if (data.isUnsaved) return cancelBtnSelector(params);
          const updateData = employees.find(({ id }) => id === node.id) ?? data;
          const error = context.errors[node.id]?.[colDef.field];
          if (error) {
            return {
              component: WithErrorRenderer,
              params: { tooltip: error },
            };
          }
          return {
            component: CircularRefRenderer,
            params: { data: updateData },
          };
        },
        cellRendererParams: { onDeleteClick: onDeleteUnsaved },
        field: DEPARTMENT_ID,
        headerComponent: HeaderRenderer,
        headerComponentParams: {
          enableExpandAll: true,
        },
        headerName: 'Department',
        minWidth: 240,
        cellEditor: SelectEditor,
        cellEditorParams: {
          options: departments,
          id: 'department-SelectorEditor',
          DefaultOption: DepartmentDefaultOption,
        },
        comparator: departmentNameComparator,
        lockVisible: true,
        valueFormatter: departmentFormatter,
        valueSetter: ({ data, newValue }) => {
          setDepartment(data, newValue, departments);
          // Check if the new department contains the current job title
          data.titleId = null;
          if (data.titleLabel) {
            const title = allJobTitles.find(
              ({ departmentId, jobTitle }) =>
                departmentId === data.departmentId &&
                jobTitle === data.titleLabel,
            );
            if (title) {
              data.titleId = title.jobTitleId;
            }
          }
          return true;
        },
        filterValueGetter: departmentFormatter,
      },
      {
        flex: 2,
        field: TITLE_ID,
        headerName: 'Title',
        minWidth: 150,
        cellEditor: JobTitleEditor,
        filterValueGetter: titleValueGetter,
        valueFormatter: titleValueGetter,
        valueSetter: ({ data, newValue, oldValue }) => {
          // Handle pasted job title
          if (typeof newValue === 'string') {
            let foundTitle = null;
            const valAsNum = Number(newValue);
            if (Number.isNaN(valAsNum)) {
              const foundTitles = allJobTitles.filter(
                ({ jobTitle }) =>
                  jobTitle.toLowerCase() === newValue.toLowerCase(),
              );
              foundTitle =
                foundTitles.find(
                  ({ departmentId }) => departmentId === data.departmentId,
                ) ?? foundTitles[0];
            } else if (Number.isInteger(valAsNum)) {
              foundTitle = allJobTitles.find(
                ({ jobTitleId }) => jobTitleId === valAsNum,
              );
            }
            data.titleLabel = foundTitle?.jobTitle ?? newValue;
            data.titleId = foundTitle?.jobTitleId ?? null;
          } else {
            data.titleLabel = newValue.titleLabel;
            data.titleId = newValue.titleId ?? null;
          }

          return oldValue !== data.titleId;
        },
        cellEditorParams: {
          scenarioId,
        },
        lockVisible: true,
      },
      {
        field: NAME,
        flex: 2,
        headerName: 'Name',
        minWidth: 150,
        cellEditor: TextEditor,
        cellEditorParams: {
          placeholder: 'Enter full name',
        },
        lockVisible: true,
      },
      {
        field: START_DATE,
        type: 'date',
        headerName: 'Start Date',
        cellEditorParams: {
          id: `${START_DATE}-dateEditor`,
        },
        lockVisible: true,
      },
      {
        comparator: nullsLastComparator,
        field: TERM_DATE,
        type: 'date',
        headerName: 'Term Date',
        cellEditorParams: {
          id: `${TERM_DATE}-dateEditor`,
        },
        initialHide: isColumnHidden(TERM_DATE),
      },
      {
        field: SALARY_FORMULA,
        type: 'number',
        headerName: 'Salary',
        minWidth: 100,
        cellEditor: AmountEditor,
        filterValueGetter: ({ data }) => data.salary,
        lockVisible: true,
        cellRendererSelector: ({ node }) => {
          const error = errors[node.id]?.[SALARY_AMOUNT];
          return {
            component: error ? WithErrorRenderer : undefined,
            ...(error && { params: { tooltip: error } }),
          };
        },
        valueFormatter: salaryFormatter,
        valueSetter: ({ data, newValue, oldValue }) => {
          // Handle pasted salaries
          if (typeof newValue === 'string') {
            const valAsNum = Number(sanitizeValue(newValue));
            data[SALARY_AMOUNT] = Number.isNaN(valAsNum) ? null : valAsNum;
            data[SALARY_FORMULA] = Number.isNaN(valAsNum) ? newValue : null;
            return oldValue !== newValue;
          }

          const { unit, selectedValue } = newValue;
          if (unit === units.CURRENCY) {
            data[SALARY_AMOUNT] = selectedValue;
            data[SALARY_FORMULA] = null;
          } else {
            data[SALARY_AMOUNT] = null;
            data[SALARY_FORMULA] = selectedValue;
          }
          return oldValue !== selectedValue;
        },
        cellClassRules: {
          'EmployeeGrid_Cell-formula': ({ data }) => !!data.salaryVariableId,
          'Spreadsheet_Cell-invalid': ({ data, node }) => {
            return data?.faulted || errors[node.id]?.[SALARY_AMOUNT];
          },
        },
      },
      {
        field: SALARY_FORMULA,
        headerName: 'View Formula',
        colId: VIEW_FORMULA,
        flex: 2,
        valueFormatter: ({ value, data }) => {
          return isEmptyOrNull(data[SALARY_VARIABLE_ID])
            ? EMPTY_CELL_VALUE
            : value;
        },
        cellClassRules: {
          'Spreadsheet_Cell-invalid': ({ data, node }) => {
            const error = errors[node.id]?.[SALARY_FORMULA];
            return data?.faulted || error;
          },
        },
        cellRendererSelector: null,
        initialHide: isColumnHidden(VIEW_FORMULA),
        editable: false,
      },
      {
        field: PAID_VIA_HRIS,
        headerName: 'Paid Via HRIS?',
        headerComponent: HeaderRenderer,
        headerComponentParams: {
          tooltip: PAID_VIA_HRIS_TOOLTIP,
        },
        minWidth: 100,
        hide: !externalPayrollIntegration,
        lockVisible: !externalPayrollIntegration,
        editable: ({ data }) => !isBambooHRConnected && !!data.externalId,
        cellEditor: SelectEditor,
        cellEditorParams: {
          options: paidViaHrisOptions,
          id: 'paid-via-hris-SelectorEditor',
          DefaultOption: HrisDefaultOption,
        },
        initialHide: isColumnHidden(PAID_VIA_HRIS),
        filterValueGetter: ({ data }) => {
          if (!data.externalId) return EMPTY_CELL_VALUE;
          return Object.keys(PAID_VIA_HRIS_MAP).find(
            (key) => PAID_VIA_HRIS_MAP[key] === data.paidViaHris,
          );
        },
        valueFormatter: ({ data, value }) => {
          if (!data.externalId) return EMPTY_CELL_VALUE;
          return Object.keys(PAID_VIA_HRIS_MAP).find(
            (key) => PAID_VIA_HRIS_MAP[key] === value,
          );
        },
        valueSetter: ({ data, oldValue, newValue }) => {
          data.paidViaHris = newValue === 'true';
          // Pasting value
          const newValueAsLowerCase = newValue.toLowerCase();
          const match = Object.keys(PAID_VIA_HRIS_MAP).find(
            (key) => key.toLowerCase() === newValueAsLowerCase,
          );
          if (match) data.paidViaHris = PAID_VIA_HRIS_MAP[match];
          return oldValue !== newValue;
        },
      },
      {
        field: ROLE_TYPE,
        headerName: 'Role Type',
        cellEditor: SelectEditor,
        cellEditorParams: {
          options: roleTypes,
          id: 'role-type-SelectorEditor',
          DefaultOption: RoleDefaultOption,
        },
        initialHide: isColumnHidden(ROLE_TYPE),
        valueFormatter: roleTypeFormatter,
        valueSetter: ({ data, newValue }) => {
          // Handle pasting the full or the abbreviated type
          const match = Object.entries(ROLE_TYPE_DISPLAY_MAP).find((type) =>
            type
              .map((element) => element.toLowerCase())
              .includes(newValue.toLowerCase()),
          );
          if (match) {
            const [roleType] = match;
            data.employmentType = roleType;
          }
          return true;
        },
      },
      {
        field: BONUS_FORMULA,
        headerName: 'Bonus/Commission',
        cellEditor: BonusCommissionEditor,
        cellEditorParams: {
          id: 'bonusCommissionEditor',
        },
        initialHide: isColumnHidden(BONUS_FORMULA),
        valueFormatter: bonusCommissionFormatter,
        valueSetter: ({ data, newValue }) => {
          // Pasting value
          if (typeof newValue === 'string') {
            const valAsNum = Number(sanitizeValue(newValue));
            if (Number.isNaN(valAsNum)) {
              data.unit = units.NUMBER;
              data[BONUS_FORMULA] = newValue;
            } else {
              data[BONUS_FORMULA] = valAsNum > 100 ? valAsNum : valAsNum / 100;
              data.bonusAmount = valAsNum > 100 ? valAsNum : valAsNum / 100;
              data.unit = valAsNum > 100 ? units.NUMBER : units.PERCENTAGE;
            }
            return true;
          }

          const { selectedValue, selectedUnit } = newValue;
          data[BONUS_FORMULA] = selectedValue;
          // eslint-disable-next-line no-param-reassign -- predates description requirement
          data.unit = selectedUnit;
          return true;
        },
        filter: 'agNumberColumnFilter',
        filterValueGetter: bonusValueGetter,
        valueGetter: bonusValueGetter,
      },
      {
        field: BENEFITS_AND_TAXES,
        type: 'number',
        editable: false,
        headerName: 'Benefits & Taxes',
        minWidth: 130,
        initialHide: isColumnHidden(BENEFITS_AND_TAXES),
        valueFormatter: ({ data, value }) => currencyFormatter(data, value),
      },
      {
        field: LOAD_MULTIPLIER,
        type: 'number',
        headerComponent: HeaderRenderer,
        headerComponentParams: {
          tooltip: `The load multiplier is a percentage of an employee's salary that is
          additionally spent on taxes and benefits. This is 20% by default, but
          can be changed in the settings page.`,
        },
        headerName: 'Load Multiplier',
        valueFormatter: loadMultiplierFormatter,
        minWidth: 120,
        editable: ({ data }) =>
          ![
            ROLE_TYPE_DISPLAY_MAP.Contractor,
            ROLE_TYPE_DISPLAY_MAP.Other,
          ].includes(data[ROLE_TYPE]),
        cellEditor: NumericEditor,
        filterValueGetter: ({ data }) =>
          [FULL_TIME, PART_TIME].includes(data.employmentType)
            ? data.loadMultiplier
            : null,
        initialHide: isColumnHidden(LOAD_MULTIPLIER),
        valueSetter: ({ data, newValue, oldValue }) => {
          data[OVERRIDE_GLOBAL_LOAD_MULTIPLIER] = true;
          // Handle pasted load multipliers
          if (typeof newValue === 'string') {
            const strippedVal = newValue.replace(/[^\d-.]/g, '');
            const valAsNum = Number(strippedVal);
            if (!strippedVal.length || Number.isNaN(valAsNum)) return false;
            data[LOAD_MULTIPLIER] = valAsNum;
            return valAsNum !== oldValue;
          }

          data[LOAD_MULTIPLIER] = newValue;
          return newValue !== oldValue;
        },
      },
      {
        colId: 'actions',
        type: 'actions',
        cellRendererSelector: ({ data }) => {
          if (data.isUnsaved) return undefined;
          return {
            component: ContextMenuRenderer,
            params: {
              onDuplicateEmployee: onDuplicateEmployeeInline,
              onDeleteEmployee: (params) => {
                setErrors((current) => {
                  const newErrors = { ...current };
                  delete newErrors[params.id];
                  return newErrors;
                });
                onDeleteEmployee(params);
              },
              onEditEmployee,
              isDuplicateDisabled: data.isUnsaved,
            },
          };
        },
        lockVisible: true,
      },
    ],
    [
      errors,
      allJobTitles,
      departments,
      scenarioId,
      loadMultiplierFormatter,
      onDuplicateEmployeeInline,
      onEditEmployee,
      isColumnHidden,
      isGroupedByDept,
      onDeleteEmployee,
      onDeleteUnsaved,
      externalPayrollIntegration,
      isBambooHRConnected,
      employees,
    ],
  );
  return (
    <>
      <Prompt
        when={hasUnsavedData}
        message="Are you sure you want to leave this page? Incomplete employees won't be saved"
      />
      <SpreadsheetToolbar>
        <GroupingToggle spreadsheetId={SPREADSHEET_ID} />
        <div className="SpreadsheetToolbar_ControlGroup">
          Options:
          {hasData && (
            <ColumnToggle ref={apiRef} spreadsheetId={SPREADSHEET_ID} />
          )}
          <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
        </div>
        {hasWritePermission && (
          <div className="SpreadsheetToolbar_ControlGroup">
            <PlusButton
              data-testid="add-employee-to-grid-option"
              onAdd={addEmployeeRow}
            >
              Add New Employee
            </PlusButton>
          </div>
        )}
      </SpreadsheetToolbar>
      {hasData ? (
        <WithEditing
          columnDefs={colDefs}
          enabled={hasWritePermission}
          errors={errors}
        >
          {({ handleCellLoading, ...editingHandlers }) => (
            <Spreadsheet
              animateRows={!window.Cypress}
              ref={apiRef}
              data={employees}
              data-testid={SPREADSHEET_ID}
              defaultColDef={defaultColDef}
              groupDefaultExpanded={EXPAND_ALL}
              groupRowRendererParams={{
                checkbox: hasWritePermission,
                suppressCount: true,
                innerRenderer: GroupRowInnerRenderer,
              }}
              rowClass="EmployeeGrid_Row-section"
              groupSelectsChildren
              loading={isLoading}
              onSelectionChanged={handleSelection}
              passesZeroFilter={passesZeroFilter}
              rowSelection="multiple"
              onCellEditingStopped={(props) =>
                props.data.isDuplicate && handleEmployeeChange(props)
              }
              onCellValueChanged={(params) =>
                handleEmployeeChange(params) && handleCellLoading(params)
              }
              onRowDataUpdated={handleRowDataUpdated}
              onFirstDataRendered={handleFirstDataRendered}
              showFilter={showFilter}
              {...editingHandlers}
            />
          )}
        </WithEditing>
      ) : (
        <EmptyData
          className="EmptyData-tab"
          Icon={EmployeesEmptyIcon}
          onAdd={hasWritePermission ? addEmployeeRow : undefined}
        >
          Add Employees for this table to populate.
        </EmptyData>
      )}
    </>
  );
};

const mapStateToProps = ({
  componentLoading,
  companies,
  scenario,
  shared,
  auth,
  employees,
}) => ({
  departments: employees.departments,
  isLoading: componentLoading.employeesList,
  selectedCompany: getSelectedCompany({ companies }),
  scenarioId: scenario.scenarioId,
  startDate: shared.startDate,
  endDate: shared.endDate,
  userPreferences: auth.preferences,
  allJobTitles: employees.allJobTitles,
});

export default connect(mapStateToProps, {
  getEmployees: getEmployeesAction,
  getAllJobTitles: getAllJobTitlesAction,
})(EmployeeGrid);
