import { FORMATTED_NUM_REGEX } from '@/constants/actuals';
import { units } from '@/constants/variables';
import { naturalSortComparator } from '@/helpers';
import metricFormatters from '@/helpers/metricFormatters';
import { isEmptyOrNull } from '@/helpers/validators';
import { EMPTY_CELL_VALUE } from './constants';

const formatterMap = {
  [units.CURRENCY]: metricFormatters.monetary,
  [units.NUMBER]: metricFormatters.wholeNumber,
  [units.PERCENTAGE]: metricFormatters.percent,
  Month: metricFormatters.months,
};

/**
 * Returns a formatter for the given unit type, defaulting to unitless number if
 * one is not found.
 *
 * @param {string} unit Unit of value to format
 * @returns {Function} Formatter
 */
export const getUnitFormatter = (unit) => {
  const formatter = formatterMap[unit];
  if (!formatter) {
    console.error(`No formatter found for unit: ${unit}`);
    return formatterMap[units.NUMBER];
  }
  return formatter;
};

/**
 * Set department selected
 *
 * @param {Object} data data of a row
 * @param {string} newValue New department
 * @param {Array} departments List of the department
 * @returns {boolean}
 */
export const setDepartment = (data, newValue, departments) => {
  // Handle pasted department names
  if (Number.isNaN(Number(newValue))) {
    // eslint-disable-next-line no-param-reassign -- predates description requirement
    data.departmentName = newValue;
    const dept = departments.find(
      ({ name }) => name.toLowerCase() === newValue.toLowerCase(),
    );
    // eslint-disable-next-line no-param-reassign -- predates description requirement
    data.departmentId = dept ? dept.id : -1;
  } else {
    const deptId = Number(newValue);
    const department = departments.find(({ id }) => id === deptId);
    if (department) {
      // eslint-disable-next-line no-param-reassign -- predates description requirement
      data.departmentId = department.id;
      // eslint-disable-next-line no-param-reassign -- predates description requirement
      data.departmentName = department.name;
    }
  }
  return true;
};

export default setDepartment;

/**
 * Performs a natural sort comparison on the department names, alphabetical but
 * treating numbers within the string atomically.
 *
 * @param {any} _
 * @param {any} __
 * @param {Object} aRow
 * @param {Object} bRow
 * @returns {number}
 */
export const departmentNameComparator = (_, __, aRow, bRow) =>
  naturalSortComparator(aRow.data?.departmentName, bRow.data?.departmentName);
/**
 * Clean the pasted value in Ag-Grid for e.g $1,000 is sanitized to 1000
 *
 * @param {string} value
 * @returns {string}
 */
export const sanitizeValue = (value) => {
  if (FORMATTED_NUM_REGEX.test(value.trim())) {
    return value.replace(/^\(/, '-').replace(/[^\d-.]+/g, '');
  }
  return value;
};

/**
 * Format as a percentage with two decimal places
 *
 * @param {number} value
 * @returns {number} Formatted value
 */
export const formatPercentageWithDecimal = (value) => {
  const config = {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };
  const formatter = new Intl.NumberFormat({}, config);
  return formatter.format(value / 100);
};

/**
 * Returns whether the column for the given context is the first in the grid.
 * Called within ag-Grid callbacks such as cellClass or checkboxSelection.
 *
 * @param {Object} params Params passed to an ag-Grid callback
 * @param {ColDef} params.colDef ag-Grid column definition
 * @param {ColumnApi} params.columnApi ag-Grid column API
 * @returns {boolean}
 */
export const isFirstCol = ({ colDef, columnApi }) => {
  const [firstCol] = columnApi.getAllDisplayedColumns();
  return colDef.field === firstCol.colDef.field;
};

/**
 * Expands the first group in the grid. Used to limit the performance impact of
 * large numbers of grouped rows.
 *
 * @param {Object} api Ag-Grid API
 */
export const expandFirstGroup = (api) => {
  let firstGroupExpanded = false;
  api.forEachNode((node) => {
    if (!firstGroupExpanded && node.allChildrenCount > 0) {
      api.setRowNodeExpanded(node, true);
      firstGroupExpanded = true;
    }
  });
};

/**
 * Formats the cell value according to the unit
 *
 * @example
 *   <Spreadsheet
 *     ...
 *     valueFormatter={valueFormatter}
 *     ...
 *     />
 *
 * @param {Object} params
 * @param {Object} params.data Object containing unit which is used to get the
 *   required formatter
 * @param {number} params.value Value which needs to be formatted
 * @returns {string} Formatted value
 */
export const cellValueFormatter = ({ data: { unit } = {}, value }) => {
  const formatter = getUnitFormatter(unit ?? units.CURRENCY);
  const flatValue = value?.value !== undefined ? value.value : value;
  return isEmptyOrNull(flatValue) || flatValue === ''
    ? EMPTY_CELL_VALUE
    : formatter(flatValue);
};

/**
 * Performs a sort on the billing plan, numeric fields have first preference
 * then alphabetical and finally the hyphen is given the least preference.
 *
 * @type {(
 *   nodeA: import('@/reducers/companies').BillingPlan,
 *   nodeB: import('@/reducers/companies').BillingPlan,
 * ) => number}
 */
export const billingPlanComparator = (nodeA, nodeB) => {
  // case for handling if both are numeric
  if (nodeA.status === 1 && nodeB.status === 1) {
    return nodeA.amount - nodeB.amount;
  }
  // case for handling if any one is numeric
  if (nodeA.status === 1) return 1;
  if (nodeB.status === 1) return -1;
  // case for handling if any one has no billing plan
  if (nodeA.status === 4) return nodeB.status === 4 ? 0 : -1;
  if (nodeB.status === 4) return 1;
  // case for handling all other scenarios
  return naturalSortComparator(nodeA.displayName, nodeB.displayName);
};
