// @ts-check
import { isEmptyOrNull } from '@/helpers/validators';
import { BILL_LOGIN_URL } from '@/runtimeConfig';

/** @type {(url: string) => string} */
export default function getBillLoginUrl(url) {
  let billLoginUrl = BILL_LOGIN_URL;
  const redirect = encodeURIComponent(url);
  if (process.env.NODE_ENV !== 'development' && !isEmptyOrNull(redirect)) {
    billLoginUrl += `?url=${redirect}`;
  }
  return billLoginUrl;
}
