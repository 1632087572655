// @ts-check
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector, useDispatch } from 'react-redux';
import ResetIcon from '@bill/cashflow.assets/reset';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  subscribeToReportUpdatesAction,
  subscribeToReportValuesUpdatesAction,
} from '@/actions/reports';
import { USER_REPORTS } from '@/cacheKeys';
import Checkbox from '@/components/common/Checkbox';
import FormulaGuideModal from '@/components/common/FormulaGuide/FormulaGuideModal';
import MonthlySpreadsheet from '@/components/common/MonthlySpreadsheet';
import { handleAgGridPaste } from '@/components/common/MonthlySpreadsheet/helpers';
import useIconClassRules from '@/components/common/MonthlySpreadsheet/useIconClassRules';
import OptionsToggle from '@/components/common/Spreadsheet/OptionsToggle';
import SpreadsheetLegend from '@/components/common/Spreadsheet/SpreadsheetLegend';
import SpreadsheetStatusBar from '@/components/common/Spreadsheet/SpreadsheetStatusBar';
import SpreadsheetToolbar from '@/components/common/Spreadsheet/SpreadsheetToolbar';
import { ROW_PINNED_BOTTOM } from '@/components/common/Spreadsheet/constants';
import FormulaEditor from '@/components/common/Spreadsheet/editors/FormulaEditor';
import useRangeSelection from '@/components/common/Spreadsheet/hooks/useRangeSelection';
import HeaderRenderer from '@/components/common/Spreadsheet/renderers/HeaderRenderer';
import useRowDragAndDrop, {
  dropPositions,
  getDropPosition,
} from '@/components/common/Spreadsheet/useRowDragAndDrop';
import useUpdateQueue from '@/components/common/Spreadsheet/useUpdateQueue';
import { MONTHLY } from '@/constants/dateTime';
import { registeredFeatureFlags } from '@/constants/features';
import { ITEM, SECTION, SUBSECTION } from '@/constants/reports';
import { PLACEHOLDER_ID, varianceText } from '@/constants/variables';
import { classNames, debounce } from '@/helpers';
import {
  getDatesInRange,
  getFormattedDateFromTimeStamp,
} from '@/helpers/dateFormatter';
import { isEmptyOrNull } from '@/helpers/validators';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useNonDashboardWritePermission from '@/hooks/useNonDashboardWritePermission';
import useSelectedScenarioIds from '@/hooks/useSelectedScenaroIds';
import useWsSubscription from '@/hooks/useWsSubscription';
import LegendWarning from '@/pages/Actuals/LegendWarning';
import ContextMenuRenderer from '@/pages/Reports/ContextMenuRenderer';
import RowNameEditor from '@/pages/Reports/RowNameEditor';
import SectionDelete from '@/pages/Reports/SectionDelete';
import { formulaCircularRefCellRenderSelector } from '@/pages/Reports/cellRendererSelector';
import transformDataForGrid from '@/reducers/helpers/transformDataForGrid';
import {
  addReportSubsection,
  updateReportSubsection,
  addReportSubsectionItem,
  updateReportSubsectionItem,
  setReportItemValues,
  setReportOrder,
} from '@/services/reports.service';
import RowNameRenderer from './RowNameRenderer';
import { ADJUSTMENTS_TO_ASSETS, GRID_OPTIONS } from './constants';

const CUSTOM_NAME = 'name';
const SPREADSHEET_ID = 'user-report';

const PARENT_IDX = -2;

/** @type {import('ag-grid-community').ValueGetterFunc} */
function valueGetter({ colDef, column, data: rowData }) {
  if (!rowData?.months || isEmptyOrNull(rowData.months)) return null;

  const month = column.getParent().getGroupId();
  const monthEntry = rowData.months.find(({ date }) => date === month);
  if (!monthEntry) return null;

  const value = monthEntry.value.find(
    ({ scenarioId }) => scenarioId === Number(colDef.field),
  );
  return value;
}

/**
 * @type {(
 *   queue: Object[],
 *   event: import('ag-grid-community').CellValueChangedEvent,
 * ) => Object[]}
 */
function addValueToQueue(queue, { column, data, newValue }) {
  if (data.isUnsaved) return queue;

  const { displayFormula } = newValue;
  const month = column.getParent().getGroupId();
  const payload = {
    itemId: data.id,
    displayFormula: displayFormula?.toString().trim() ? displayFormula : null,
    month,
  };
  return [...queue, payload];
}

/** @type {(event: import('ag-grid-community').RowDragEvent) => boolean} */
function canBeNested({ overNode }) {
  return overNode.data.type !== ITEM;
}

const UserReportsGrid = ({
  apiRef,
  reportId,
  name: reportName,
  hideZeroRows = false,
  isInteractive: interactive = true,
  showVarianceAmount,
  setShowVarianceAmount = (_arg) => {},
  variancePercentage,
  data: userReportData,
  isLoading = false,
}) => {
  const dragBackup = useRef(null);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [allowWebsocketUpdates, setAllowWebsocketUpdates] = useState(true);
  const [showHelp, setShowHelp] = useState(false);
  const [showVariancePercentage, setShowVariancePercentge] =
    useState(variancePercentage);

  const [cellCount, setCellCount] = useState(0);
  const [selectedCells, setSelectedCells] = useState([]);
  const [cellSum, setCellSum] = useState(0);
  const [cellUnit, setCellUnit] = useState('');
  const handleReset = useCallback(() => {
    selectedCells.forEach(({ colDef, node }) =>
      node.setDataValue(colDef.colId, { displayFormula: null }),
    );
    setSelectedCells([]);
    setCellCount(0);
  }, [selectedCells]);
  const handleRangeSelection = useRangeSelection(
    setSelectedCells,
    setCellCount,
    setCellUnit,
    setCellSum,
    [CUSTOM_NAME],
  );

  const isShowPercentVarianceEnabled = useFeatureFlags(
    registeredFeatureFlags.SHOW_VARIANCE_PERCENT,
  );

  const { startDate, endDate, timePeriod } = useSelector(
    ({ shared }) => shared,
  );
  const dateRange = useMemo(
    () =>
      getDatesInRange(startDate, endDate).map((month) =>
        getFormattedDateFromTimeStamp(month),
      ),
    [startDate, endDate],
  );
  const scenarioIds = useSelectedScenarioIds();
  const scenarioId = useSelector(({ scenario }) => scenario.scenarioId);
  const hasComparison = scenarioIds.length > 1;
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();

  const hasWritePermission = useNonDashboardWritePermission();

  const queryKey = useMemo(
    () => [
      USER_REPORTS,
      reportName,
      scenarioIds,
      startDate,
      endDate,
      timePeriod,
    ],
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [scenarioIds, startDate, endDate, timePeriod],
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    setCellCount(0);
    setSelectedCells([]);
  }, [userReportData]);

  const [reportData, setReportData] = useState([]);

  // Previews the new order of the section/variable being dragged.
  // Debounced to avoid jumpiness while the user is still dragging.
  const reorder = useCallback(
    (rowToReorder, destHierarchy, newIdx) => {
      const { id, hierarchy } = rowToReorder;
      const srcParentId = hierarchy.at(PARENT_IDX);
      const destParentId = destHierarchy.at(-1);

      // Update the row's hierarchy
      const newHierarchy = [...destHierarchy, id];
      const updatedRow = {
        ...rowToReorder,
        hierarchy: newHierarchy,
      };

      const srcSiblings = [];
      let destSection = [];
      const otherRows = reportData.reduce((accum, row) => {
        if (row.id === id) return accum;

        const parentId = row.hierarchy.at(PARENT_IDX);
        switch (parentId) {
          // Siblings of the row in the destination section
          case destParentId:
            destSection.push(row);
            break;
          // If the row moved sections, re-index its former siblings
          case srcParentId: {
            srcSiblings.push({ ...row, index: srcSiblings.length, parentId });
            break;
          }
          // Update the hierarchy of the row's children, if any
          case id:
            accum.push({
              ...row,
              hierarchy: [...newHierarchy, row.id],
              parentId,
            });
            break;
          default:
            accum.push({ ...row, parentId });
        }
        return accum;
      }, []);

      // Insert the reordered row into the destination section and re-index
      destSection.splice(newIdx, 0, updatedRow);
      destSection = destSection.map((row, index) => ({
        ...row,
        index,
      }));

      return otherRows.concat(srcSiblings, destSection);
    },
    [reportData],
  );

  const handleDragEnter = useCallback(
    ({ node }) => (dragBackup.current = node.data),
    [],
  );

  // If the mouse leaves the grid area, reset the dragged row's position
  const handleDragLeave = useCallback(() => {
    const { current } = dragBackup;
    if (!current) return;
    const parentHierarchy = current.hierarchy.slice(0, -1);
    const reorderedData = reorder(current, parentHierarchy, current.index);
    setReportData(reorderedData);
    dragBackup.current = null;
  }, [reorder]);

  const handleDrop = useCallback(
    async (params) => {
      const { api, node, overNode, y } = params;

      // Dropping a row
      const rowData = node.data;
      const hovered = overNode.data;
      const { hierarchy } = hovered;

      const allowNesting = canBeNested(params);
      const dropPosition = getDropPosition(overNode, y, allowNesting);
      const destHierarchy =
        dropPosition !== dropPositions.WITHIN
          ? hierarchy.slice(0, -1)
          : hierarchy;

      // Row cannot be moved to the topmost layer
      if (
        !destHierarchy.length ||
        (reportName === 'Balance Sheet' && overNode.rowIndex < 1)
      ) {
        return;
      }

      const isSection = hierarchy.length === 1;
      let newIdx = 0;
      if (!isSection) {
        // If the row is dragged towards the top of another row,
        // it should be placed above it. If it's towards the bottom,
        // place it below.
        const { data } = overNode;
        const placeAfter =
          dropPosition === dropPositions.BELOW ||
          data.name === ADJUSTMENTS_TO_ASSETS;
        newIdx = placeAfter ? hovered.index + 1 : hovered.index;
      }
      const reorderedData = reorder(rowData, destHierarchy, newIdx);
      setReportData(reorderedData);

      if (dropPosition === dropPositions.WITHIN) overNode.setExpanded(true);

      const parentId = node.data.hierarchy.at(PARENT_IDX);
      const siblings = reorderedData
        .filter(
          (row) =>
            !row.isAdditional &&
            row.hierarchy.length > 1 &&
            row.name !== ADJUSTMENTS_TO_ASSETS,
        )
        .map((row) =>
          row.hierarchy.at(PARENT_IDX) === parentId
            ? {
                ...row,
                parentId,
              }
            : row,
        );

      try {
        await setReportOrder(scenarioId, siblings);
        api
          .getRenderedNodes()
          .filter((rowNode) => rowNode.data.name === rowData.name)
          .forEach((rowNode) =>
            rowNode.setData({
              ...rowNode.data,
              error: undefined,
            }),
          );
      } catch (e) {
        node.setData({
          ...node.data,
          error: e.response?.data?.error?.errorMessage,
        });

        setReportData((prevState) => {
          const newState = [...prevState];
          const rowIndex = newState.findIndex((row) => row.id === node.id);
          const row = newState[rowIndex];
          row.error = e.response?.data?.error?.errorMessage;
          return newState;
        });
      }
      dragBackup.current = null;
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [reportData],
  );

  const dragHandlers = useRowDragAndDrop({
    onRowDragEnd: handleDrop,
    onRowDragEnter: handleDragEnter,
    onRowDragLeave: handleDragLeave,
    canBeNested,
  });

  const startEditing = useCallback(
    (rowId) => {
      const { api } = apiRef.current;
      const placeholder = api.getRowNode(rowId);
      if (placeholder) {
        api.startEditingCell({
          colKey: CUSTOM_NAME,
          rowIndex: Number(placeholder.rowIndex),
        });
      }
    },
    [apiRef],
  );

  useEffect(() => {
    if (isEmptyOrNull(userReportData)) return;
    setReportData(userReportData);
  }, [userReportData]);

  const addlItems = useMemo(
    () => userReportData?.filter(({ isAdditional }) => isAdditional),
    [userReportData],
  );

  const editorParams = useCallback(
    ({ column, data }) => ({
      'allowEmpty': true,
      'data-testid': `${data.name}-${column.colId}`,
      'onHelpClick': () => setShowHelp(true),
    }),
    [],
  );

  const [unsavedEntity, gridData] = useMemo(
    () => [
      reportData.find((row) => row.id === PLACEHOLDER_ID),
      reportData.filter(({ isAdditional }) => !isAdditional),
    ],
    [reportData],
  );

  const handleModelUpdate = useMemo(
    () =>
      debounce(() => {
        if (unsavedEntity) startEditing(unsavedEntity.id);
      }, 100),
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [unsavedEntity],
  );

  const refreshCells = useCallback(
    (columns) => {
      apiRef.current.api.refreshCells({ columns });
    },
    [apiRef],
  );

  const handleNameChange = useCallback(
    async ({ data, api }) => {
      setAllowWebsocketUpdates(false);

      const { name, parentSectionId, id, hierarchy, type } = data;
      const isUnsaved = id === PLACEHOLDER_ID;
      const payload = {
        id,
        name,
        reportId,
      };

      let addOrUpdate = isUnsaved
        ? addReportSubsection
        : updateReportSubsection;
      if (type === ITEM) {
        addOrUpdate = isUnsaved
          ? addReportSubsectionItem
          : updateReportSubsectionItem;
        payload.sectionId = parentSectionId;
      } else {
        payload.parentSectionId = parentSectionId;
      }
      if (isUnsaved) {
        let index = 0;
        api.forEachNode((node) => {
          if (node.id !== id && node.parent.id === parentSectionId) {
            index += 1;
          }
        });
        payload.index = index;
      }

      const rowNode = api.getRowNode(id);
      try {
        const { data: responseData } = await addOrUpdate(scenarioId, payload);
        if (responseData.success) {
          setReportData((prevState) => {
            const newState = [...prevState];
            const rowIndex = newState.findIndex((row) => row.id === id);
            const row = newState[rowIndex];
            row.hierarchy[row.hierarchy.length - 1] = responseData.data.id;
            newState[rowIndex] = {
              ...row,
              ...responseData.data,
            };
            if (isUnsaved) {
              newState[rowIndex].months = dateRange.map((month) => ({
                date: month,
                value: [{ scenarioId, value: null }],
              }));
            }
            return newState;
          });
          const rowNodes = [rowNode];
          // Updates row styles
          if (isUnsaved) {
            const parentRow = api.getRowNode(hierarchy.at(PARENT_IDX));
            rowNodes.push(parentRow);
          }
          api.redrawRows({ rowNodes });
        }
      } catch (e) {
        const { context } = api.gridOptionsWrapper.gridOptions;
        context.loadingCells = {};
        rowNode.setData({
          ...rowNode.data,
          error: e.response?.data?.error?.errorMessage,
        });
        api.redrawRows([rowNode]);
        startEditing(id);
      }
      setAllowWebsocketUpdates(true);
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [scenarioId, dateRange],
  );

  const onWebSocketUpdate = useCallback(
    (updates) => {
      if (!allowWebsocketUpdates) return;

      setReportData((oldReportData) => {
        let newReportData = [...oldReportData];
        if (Array.isArray(updates)) {
          const refreshColumns = [];
          updates.forEach((cellValueObject) => {
            const { id, date, ...value } = cellValueObject;
            const rowIndex = newReportData.findIndex((row) => row.id === id);
            if (rowIndex === -1) return;

            const colIndex = newReportData[rowIndex].months.findIndex(
              (month) => month.date === date,
            );
            if (colIndex === -1) return;

            const cellValueIndex = newReportData[rowIndex].months[
              colIndex
            ].value.findIndex(
              (v) => v.scenarioId === cellValueObject.scenarioId,
            );
            if (cellValueIndex === -1) return;

            newReportData[rowIndex].months[colIndex].value[cellValueIndex] =
              value;
            refreshColumns.push(`${date}_${value.scenarioId}`);
          });
          refreshCells(refreshColumns);
          return newReportData;
        }

        if (updates.deleted) {
          const deleteRowIds = updates.deleted.map((row) => row.id);
          newReportData = newReportData.filter(
            (row) => !deleteRowIds.includes(row.id),
          );
        }

        updates.upserted?.forEach((newRow) => {
          const rowIndex = newReportData.findIndex(
            (row) => row.id === newRow.id,
          );
          if (rowIndex > -1) {
            newReportData[rowIndex] = {
              ...newReportData[rowIndex],
              ...newRow,
            };
          } else {
            const parentNode = apiRef.current.api.getRowNode(
              newRow.parentSectionId,
            );
            newReportData.push(
              ...transformDataForGrid(
                [
                  {
                    ...newRow,
                    months: dateRange.map((month) => ({
                      date: month,
                      value: [],
                    })),
                  },
                ],
                parentNode ? parentNode.data.hierarchy : [],
              ),
            );
          }
        });

        queryClient.invalidateQueries(queryKey, { refetchType: 'none' });
        return newReportData;
      });
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [allowWebsocketUpdates],
  );

  useWsSubscription(
    () =>
      dispatch(
        subscribeToReportUpdatesAction(scenarioId, reportId, onWebSocketUpdate),
      ),
    [scenarioId, reportId, onWebSocketUpdate],
  );

  useWsSubscription(
    () =>
      dispatch(
        subscribeToReportValuesUpdatesAction(
          scenarioId,
          reportId,
          onWebSocketUpdate,
        ),
      ),
    [scenarioId, reportId, onWebSocketUpdate],
  );

  const updateBulkValues = useCallback(
    async (queue) => {
      await setReportItemValues(scenarioId, queue);
    },
    [scenarioId],
  );

  const handleMonthValueChange = useUpdateQueue(
    addValueToQueue,
    updateBulkValues,
  );

  const onCellEditing = useCallback(({ api, valueChanged, data }) => {
    if (data.error) {
      if (valueChanged === undefined) api.stopEditing(true);
      const rowNode = api.getRowNode(data.id);
      startEditing(rowNode.id);
      return;
    }
    handleModelUpdate();
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, []);

  const { mutate: fillRight } = useMutation({
    mutationFn: updateBulkValues,
    onSuccess: (_, [variable]) => {
      const { api } = apiRef.current;
      const { context } = api.gridOptionsWrapper.gridOptions;
      context.loadingCells = {};
      const rowNode =
        variable.rowPinned === ROW_PINNED_BOTTOM
          ? api.getPinnedBottomRow(variable.rowIndex)
          : api.getDisplayedRowAtIndex(variable.rowIndex);
      api.refreshCells({ rowNodes: [rowNode] });
    },
  });

  const colDefs = useMemo(() => {
    /** @type {import('ag-grid-community').ColDef[]} */
    const columns = [
      {
        editable: ({ data }) =>
          data &&
          (data.id === PLACEHOLDER_ID || !unsavedEntity) &&
          hasWritePermission &&
          timePeriod === MONTHLY &&
          data.isEditable &&
          !data.isAdditional &&
          interactive &&
          !data.error,
        headerName: reportName,
        cellEditor: RowNameEditor,
        cellEditorParams: {
          onCancelAdd: (subSectionId) => {
            setReportData((prevState) =>
              prevState.filter((row) => row.id !== subSectionId),
            );
          },
        },
        onCellValueChanged: handleNameChange,
        suppressPaste: true,
        onCellDoubleClicked: onCellEditing,
        valueSetter: ({ data, oldValue, newValue }) => {
          /* eslint-disable no-param-reassign -- predates description requirement */
          data.name = newValue.value;
          data.hasErrorMsg = newValue.hasErrorMsg;
          data.parentSectionId = newValue.parentSectionId;
          /* eslint-enable no-param-reassign -- predates description requirement */
          return oldValue !== newValue.value && !newValue.hasErrorMsg;
        },
        cellClass: 'Spreadsheet_Cell Spreadsheet_Cell-label',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          suppressCount: true,
          suppressDoubleClickExpand: true,
          innerRendererSelector: () =>
            interactive && hasWritePermission && timePeriod === MONTHLY
              ? {
                  component: RowNameRenderer,
                }
              : null,
          innerRendererParams: {
            hasComparison,
          },
          onAddClick: (section, type) => {
            if (!interactive) return;
            if (unsavedEntity) {
              startEditing(unsavedEntity.id);
              return;
            }
            const newEntity = {
              reportId,
              parentSectionId: section.id,
              name: '',
              type: type === SUBSECTION ? SECTION : ITEM,
              id: PLACEHOLDER_ID,
              isEditable: true,
              months: [],
            };
            setReportData((prevState) => [
              ...prevState,
              ...transformDataForGrid([newEntity], [...section.hierarchy]),
            ]);
            const node = apiRef.current.api.getRowNode(section.id);
            node.setExpanded(true);
          },
        },
        field: CUSTOM_NAME,
        headerComponent: HeaderRenderer,
        headerComponentParams: { enableExpandAll: interactive },
        minWidth: 300,
      },
    ];

    if (hasWritePermission && interactive) {
      columns.push({
        colId: 'actions',
        type: 'actions',
        cellRendererSelector: ({ data }) => {
          if (
            !data ||
            data.isUnsaved ||
            data.hierarchy.length === 1 ||
            (data.type === ITEM &&
              ((data.isExternal && !!data.accountId) ||
                (!data.isExternal && !data.isEditable)))
          ) {
            return undefined;
          }
          return {
            component: ContextMenuRenderer,
            params: { onDeleteRow: setRowToDelete },
          };
        },
        lockVisible: true,
      });
    }
    return columns;
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
  }, [
    reportName,
    unsavedEntity,
    apiRef,
    hasWritePermission,
    timePeriod,
    hasComparison,
    interactive,
  ]);

  let Legend = SpreadsheetLegend;
  if (timePeriod !== MONTHLY) {
    Legend = LegendWarning;
  }

  const rendererParams = useCallback(
    ({ colDef, data: variable, node }) => {
      return {
        'data-testid': `${variable.name}-${colDef.colId}`,
        'onFillRightClick': ({ month, displayFormula }) => {
          fillRight([
            {
              itemId: variable.id,
              displayFormula,
              month,
              fillRight: true,
              rowIndex: node.rowIndex,
              rowPinned: node.rowPinned,
            },
          ]);
        },
      };
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement */
    [scenarioId, updateBulkValues],
  );

  const iconClassRules = useIconClassRules(timePeriod);

  const isEditable = useCallback(
    ({ data }) => {
      if (
        !data ||
        data.name === ADJUSTMENTS_TO_ASSETS ||
        (data.isAdditional && !data.isEditable)
      )
        return false;
      return (
        interactive &&
        hasWritePermission &&
        timePeriod === MONTHLY &&
        data.type === ITEM &&
        data.id !== PLACEHOLDER_ID
      );
    },
    [hasWritePermission, timePeriod, interactive],
  );
  return (
    <>
      {interactive && (
        <SpreadsheetToolbar Legend={Legend}>
          <div className="SpreadsheetToolbar_ControlGroup">
            Options: <OptionsToggle spreadsheetId={SPREADSHEET_ID} />
          </div>
          {hasComparison && (
            <>
              <Checkbox
                id="variance-toggle"
                checked={showVarianceAmount}
                className="SpreadsheetToolbar_ControlGroup"
                onChange={() =>
                  setShowVarianceAmount((prevState) => !prevState)
                }
              >
                {varianceText.SHOW_VARIANCE_AMOUNT}
              </Checkbox>
              {isShowPercentVarianceEnabled && (
                <Checkbox
                  id="variance-toggle"
                  checked={showVariancePercentage}
                  className="SpreadsheetToolbar_ControlGroup"
                  onChange={() =>
                    setShowVariancePercentge((prevState) => !prevState)
                  }
                >
                  {varianceText.SHOW_VARIANCE_PERCENTAGE}
                </Checkbox>
              )}
            </>
          )}
        </SpreadsheetToolbar>
      )}
      <div
        className={classNames(
          'ReportsGrid',
          unsavedEntity && 'ReportsGrid-hasUnsaved',
        )}
      >
        <MonthlySpreadsheet
          {...GRID_OPTIONS}
          ref={apiRef}
          columnDefs={colDefs}
          data-testid={SPREADSHEET_ID}
          data={gridData}
          editable={isEditable}
          editor={FormulaEditor}
          editorParams={editorParams}
          cellClassRules={iconClassRules}
          rendererParams={rendererParams}
          loading={isLoading}
          onModelUpdated={handleModelUpdate}
          onMonthValueChange={handleMonthValueChange}
          pinnedBottomRowData={addlItems}
          processCellFromClipboard={handleAgGridPaste}
          onRangeSelectionChanged={handleRangeSelection}
          valueGetter={valueGetter}
          rendererSelector={formulaCircularRefCellRenderSelector}
          onCellEditingStopped={onCellEditing}
          enableComparison
          showVarianceAmount={showVarianceAmount}
          showVariancePercentage={showVariancePercentage}
          isInteractive={interactive}
          hideZeroRowsByDefault={hideZeroRows}
          suppressNoRowsOverlay={!interactive}
          {...dragHandlers}
        />
        {cellCount > 0 && interactive && (
          <SpreadsheetStatusBar
            cellCount={cellCount}
            cellSum={cellSum}
            cellUnit={cellUnit}
          >
            {!!selectedCells.length && hasWritePermission && (
              <button
                type="button"
                onClick={handleReset}
                className="ResetContainer"
              >
                <ResetIcon className="ResetIcon" />
                Reset
              </button>
            )}
          </SpreadsheetStatusBar>
        )}
      </div>
      <FormulaGuideModal
        formulaKey={reportName}
        open={showHelp}
        data-testid="user-reports-guide-modal"
        onClose={() => setShowHelp(false)}
      />
      <SectionDelete
        open={!!rowToDelete}
        onCancel={() => setRowToDelete(null)}
        currentRecord={rowToDelete}
        onDelete={() => {
          setReportData((prevState) =>
            prevState.filter((row) => row.id !== rowToDelete.id),
          );
          const parentRow = apiRef.current.api.getRowNode(
            rowToDelete.hierarchy.at(PARENT_IDX),
          );
          apiRef.current.api.redrawRows({ rowNodes: [parentRow] });
          setRowToDelete(null);
        }}
      />
    </>
  );
};

export default UserReportsGrid;
