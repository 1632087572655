// @ts-check
import { useMemo } from 'react';
import { Series } from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import { JSX_OPTIONS } from '@/components/Charts/chartDefaults';
import { getComparisonPatternFill } from '@/components/Charts/colors';
import { chartTypes } from '@/components/Charts/constants';

/**
 * @typedef {{
 *   x: number;
 *   y: number;
 * }} Point
 */

/**
 * @typedef {Object} DateChartSeriesProps
 * @property {string} color Color for the series
 * @property {Point[]} data Data to populate the series
 * @property {(value: number) => string} [formatter] Formats the values in the
 *   series for labels and tooltips
 * @property {number} index Index of the series within the chart
 * @property {boolean} [isComparison] Whether the series represents a comparison
 *   scenario
 * @property {string} metric Name of the metric represented by the series, for
 *   exporting purposes
 * @property {Object} scenario Scenario of the data represented by the series
 * @property {'area' | 'line'} [type] Type of the series
 */

const DASH = 'Dash';
const SOLID = 'Solid';

/**
 * Renders a DateChart series for the given scenario
 *
 * @example
 *   <DateChart>
 *     <DateChart.Series
 *       data={data}
 *       index={0}
 *       metric="Total Foo"
 *       scenario={scenario}
 *     />
 *   </DateChart>;
 *
 * @type {(props: DateChartSeriesProps) => React.ReactElement}
 */
function DateChartSeries({
  color,
  data,
  formatter,
  index,
  isComparison,
  metric,
  scenario,
  type = chartTypes.LINE,
  ...props
}) {
  const isArea = type === chartTypes.AREA;
  const seriesColor = color ?? scenario?.color;

  const fillColor = useMemo(() => {
    if (!isComparison) return seriesColor;

    const backgroundColor = isArea
      ? Highcharts.color(seriesColor).brighten(0.1).get()
      : seriesColor;
    return getComparisonPatternFill(backgroundColor);
  }, [isArea, isComparison, seriesColor]);

  const custom = useMemo(() => ({ scenario }), [scenario]);
  const isComparisonLineSeries = isComparison && type === chartTypes.LINE;

  const dataLabels = useMemo(
    () =>
      formatter
        ? {
            /** @type {Highcharts.DataLabelsFormatterCallbackFunction} */
            formatter() {
              return formatter(this.y);
            },
          }
        : {},
    [formatter],
  );

  return (
    <Series
      type={type}
      color={
        ![chartTypes.AREA, chartTypes.LINE].includes(type)
          ? fillColor
          : seriesColor
      }
      data={data}
      dataLabels={dataLabels}
      fillColor={fillColor}
      jsxOptions={JSX_OPTIONS}
      index={index}
      name={metric}
      stack={scenario?.name}
      custom={custom}
      xAxis={isArea && isComparison ? 1 : 0}
      dashStyle={isComparisonLineSeries ? DASH : SOLID}
      className={isComparisonLineSeries ? 'DateChart_ComparisonLineSeries' : ''}
      {...props}
    />
  );
}
DateChartSeries.displayName = 'DateChart.Series';

export default DateChartSeries;
