import { PropTypes } from 'prop-types';
import { classNames } from '@/helpers';

const CardWrapper = ({ children, className }) => {
  return (
    <div className={classNames('Panel', 'Panel-cardLegacy', className)}>
      {children}
    </div>
  );
};

CardWrapper.propTypes = {
  /** Content of CardWrapper */
  children: PropTypes.node.isRequired,
  /** Class(es) to apply to the element */
  className: PropTypes.string,
};

export default CardWrapper;
