// @ts-check
import FormField from '@/components/common/FormField';
import { getCurrentMonthTimestamp } from '@/helpers/dateFormatter';
import './GridInput.scss';

/**
 * @typedef {{
 *   month: string;
 *   name: string;
 *   value: string;
 * }} MonthInfo
 */

/**
 * @typedef {{
 *   value: string | number;
 *   year: number;
 *   month: string;
 *   id?: number;
 * }} UpdateValueParams
 */

/**
 * @typedef {{
 *   month: MonthInfo;
 *   year: number;
 *   customId?: number;
 *   updateValue: (params: UpdateValueParams) => void;
 *   valueKey: string;
 * }} GridInputProps
 */

/**
 * Grid input for editing the forecast method: 'Custom revenue amount over
 * time'.
 *
 * @type {(props: GridInputProps) => React.ReactElement}
 */
const GridInput = ({
  month,
  year,
  updateValue,
  customId,
  valueKey,
  ...props
}) => {
  const isBefore = () => {
    return new Date(month.month).getTime() < getCurrentMonthTimestamp();
  };

  const handleChange = ({ target }) => {
    if (Number(target.value) < 0) {
      return;
    }
    const formattedValue =
      target.value.trim() === '' ? '' : Math.trunc(target.value);
    updateValue({
      value: formattedValue,
      year,
      month: month.value,
      id: customId,
    });
  };

  const id = `custom-rev-${month.value}`;

  return (
    <>
      <label className="GridInput_Label" htmlFor={id}>
        {month.name}
      </label>
      <FormField
        type="number"
        id={id}
        className="GridInput_FormField"
        value={month[valueKey]}
        disabled={isBefore()}
        onChange={handleChange}
        {...props}
      />
    </>
  );
};

export default GridInput;
