import { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormLabel from '@/components/common/FormLabel';
import FormulaField from '@/components/common/FormulaField';
import FormulaValidationMessage from '@/components/common/FormulaValidationMessage';
import FormulaValidationMessageWithLink from '@/components/common/FormulaValidationMessageWithLink';
import { findCurrencySymbol } from '@/constants/currencies';
import VALID_FORMULA_MSG from '@/constants/formulas';
import { isNumber } from '@/helpers';
import { isEmptyOrNull } from '@/helpers/validators';
import validateCustomFormula from '@/services/formula.service';
import './AdvancedRevenueFormulaField.scss';

/**
 * A field for entering a custom formula that fetches its own options form
 * autocompleting variables
 *
 * @example
 *   <AdvancedRevenueFormulaField
 *     id="foo"
 *     scenarioId=12345
 *     value="Expense.Marketing.Total * 1/10"
 *     onValidation={() => setIsValid(true/false)}
 *     onChange={(newValue) => doSomethingWith(newValue)}
 *     text="Field text"
 *     tooltip="Tooltip text here"
 *     optional=true,
 *     showAsCurrency=true,
 *     allowNegativeValues=true,
 *     initialValidationMsg='Valid Formula',
 *   />
 */
const AdvancedRevenueFormulaField = ({
  id,
  scenarioId,
  value,
  onValidation,
  onChange,
  text,
  tooltip,
  optional,
  showAsCurrency,
  allowNegativeValues = true,
  initialValidationMsg = '',
  showVariablesLink = false,
}) => {
  const prefixRef = useRef(findCurrencySymbol());
  const [validationMsg, setValidationMsg] = useState(
    value && initialValidationMsg,
  );
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <div className="Formula_Label">
        <FormLabel
          htmlFor={id}
          text={text}
          tooltip={tooltip}
          optional={optional}
        />
      </div>

      <FormulaField
        id={id}
        value={
          isNumber(value) && !isFocused
            ? `${showAsCurrency ? prefixRef.current : ''}${value}`
            : value
        }
        onChange={onChange}
        onBlur={async () => {
          switch (true) {
            case !value && !optional:
              setValidationMsg('Field is required');
              onValidation(false);
              break;
            case optional && value === '': {
              setValidationMsg('');
              onValidation(true);
              break;
            }
            case isNumber(value): {
              const isInvalid = !allowNegativeValues && value < 0;
              setValidationMsg(
                isInvalid ? "Value can't be negative" : VALID_FORMULA_MSG,
              );
              onValidation(!isInvalid);
              break;
            }
            default: {
              if (!isEmptyOrNull(value)) {
                const {
                  data: { data },
                } = await validateCustomFormula(scenarioId, value);
                setValidationMsg(data?.valid ? VALID_FORMULA_MSG : data.error);
                onValidation(data?.valid);
              }
              break;
            }
          }
          setIsFocused(false);
        }}
        onFocus={() => {
          setValidationMsg('');
          onValidation(false);
          setIsFocused(true);
        }}
      />
      {showVariablesLink ? (
        <FormulaValidationMessageWithLink message={validationMsg} />
      ) : (
        <FormulaValidationMessage message={validationMsg} />
      )}
    </>
  );
};

const mapStateToProps = ({ scenario }) => {
  return {
    scenarioId: scenario.scenarioId,
  };
};

AdvancedRevenueFormulaField.propTypes = {
  /** Unique ID for the field */
  id: PropTypes.string,
  /** The scenarioId to fetch variables */
  scenarioId: PropTypes.number,
  /** The value of the input */
  value: PropTypes.string,
  /**
   * Event handler for field changes
   *
   * @param {Object} event
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Event handler for field changes
   *
   * @param {Object} event
   */
  onValidation: PropTypes.func.isRequired,
  /** The header text */
  text: PropTypes.string,
  /** helper tooltip */
  tooltip: PropTypes.string,
  /** Is the field optional */
  optional: PropTypes.bool,
  /** Show with currency symbol */
  showAsCurrency: PropTypes.bool,
  /** Are negative values allowed */
  allowNegativeValues: PropTypes.bool,

  /** Validation message on initial render */
  initialValidationMsg: PropTypes.string,
  /** Show link to Variables section below field */
  showVariablesLink: PropTypes.bool,
};

export default connect(mapStateToProps)(AdvancedRevenueFormulaField);
