import PropTypes from 'prop-types';
import { classNames } from '@/helpers';

/**
 * @typedef {(props: {
 *   'id'?: string;
 *   'data-testid'?: string;
 *   'className'?: string;
 *   'children'?: React.ReactNode;
 * }) => React.ReactElement} ColHead
 */

/**
 * The table heading component
 *
 * @example
 *   <Table>
 *     <thead>
 *       <tr>
 *         <Table.ColHead>Some heading</Table.ColHead>
 *       </tr>
 *     </thead>
 *     <tbody>
 *       <tr>...</tr>
 *     </tbody>
 *   </Table>;
 *
 * @type {ColHead}
 */
const ColHead = ({ id, className, 'data-testid': dataTestId, children }) => {
  return (
    <th
      id={id}
      className={classNames('Table_ColHead', className)}
      data-testid={dataTestId}
    >
      {children}
    </th>
  );
};

ColHead.propTypes = {
  /** Unique ID for selecting the element in unit/integration tests */
  'data-testid': PropTypes.string,
  /** Unique ID for the element */
  'id': PropTypes.string,
  /** Class(es) to apply to the element */
  'className': PropTypes.string,
  /** Node(s) to display inside the element */
  'children': PropTypes.node,
};

export default ColHead;
