import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

/* eslint-disable-next-line no-restricted-imports -- predates description requirement */
import { Link } from 'react-router-dom';
import CrossIcon from '@bill/cashflow.assets/cross';
import MailIcon from '@bill/cashflow.assets/mail';
import {
  forgotPasswordAction,
  handleDataChange,
  setForgotPasswordResponse,
} from '@/actions/signUp';
import Button from '@/components/common/Button';
import FormField from '@/components/common/FormField';
import Modal from '@/components/common/Modal';
import Landing from '@/containers/Landing';
import { history } from '@/store';
import './ForgotPasswordForm.scss';

const ForgotPasswordForm = ({
  email,
  // eslint-disable-next-line no-shadow -- predates description requirement
  handleDataChange,
  forgotPasswordResponse,
  // eslint-disable-next-line no-shadow -- predates description requirement
  setForgotPasswordResponse,
  forgotPassword,
}) => {
  const [hasEmailError, setHasEmailError] = useState(true);
  const submitPassword = (e) => {
    e.preventDefault();
    forgotPassword(email);
  };

  useEffect(() => {
    document.title = 'Forgot Password';
  }, []);

  const { success, error } = forgotPasswordResponse;

  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    setForgotPasswordResponse({});
    history.push('/');
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping -- predates description requirement
  const validateEmail = (e) => {
    const re =
      // eslint-disable-next-line no-useless-escape -- predates description requirement
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = re.test(String(e).toLowerCase());
    setHasEmailError(!isValid);
    return !isValid && 'Please enter a valid email!';
  };
  return (
    <Landing>
      <form className="landing-Form">
        <h1 className="Panel_Title">Forgot Password</h1>

        <p className="pb-2 text-secondary">
          Please enter your registered email and we will send a password reset
          link.
        </p>
        <div className="landing-Form_Group">
          <label htmlFor="email" className="landing-Form_Label">
            Email Address
          </label>
          <FormField
            type="email"
            id="email"
            data-testid="email"
            name="email"
            value={email}
            onChange={(e) => handleDataChange(e.target.name, e.target.value)}
            validate={validateEmail}
          />
        </div>
        <Button
          type="submit"
          data-testid="forgotPasswdSubmit"
          disabled={hasEmailError}
          onClick={(e) => submitPassword(e)}
        >
          Submit
        </Button>
        <Link to="/" className="Cancel">
          Cancel
        </Link>
      </form>

      {(success || error) && (
        <Modal
          open={show}
          onClose={handleClose}
          data-testid="forgot-password-confirmation"
        >
          <div className="ForgotPassword_Header">
            <CrossIcon
              className="ForgotPassword_CrossIcon"
              data-testid="close-forgot-password-confirmation"
              onClick={handleClose}
            />
          </div>
          <div className="ForgotPassword_Content">
            <MailIcon className="MailIcon" />
            <p className="ForgotPassword_Text">
              If an account with this email exists, you will receive
              instructions to reset your password within a few minutes.
            </p>
            <a href={`mailto:${email}`} className="ForgotPassword_Link">
              {email}
            </a>
          </div>
        </Modal>
      )}
    </Landing>
  );
};

const mapStateToProps = (state) => ({
  email: state.signUp.email,
  forgotPasswordResponse: state.signUp.forgotPasswordResponse,
});

export default connect(mapStateToProps, {
  handleDataChange,
  setForgotPasswordResponse,
  forgotPassword: forgotPasswordAction,
})(ForgotPasswordForm);
