import { useState, useRef, useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates restricting useSelector
import { useSelector } from 'react-redux';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import InfoTooltip from '@/components/common/InfoTooltip';
import { INVALID_CAC_PAYBACK_TOOLTIP_TEXT } from '@/constants/charts';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import { isEmptyOrNull } from '@/helpers/validators';
import useDashboardPath from '@/hooks/useDashboardPath';
import {
  getMetricValue,
  getTimePeriodLabel,
  getLabeledMetric,
} from '@/pages/Dashboard/helpers';
import CacPaybackChart from './charts/CacPaybackChart';

const CHART_TITLE = 'CAC Payback';

const AdditionalInfo = ({ currentMonthValue }) => {
  return isEmptyOrNull(currentMonthValue) ? (
    <InfoTooltip data-testid="new-mrr-tooltip">
      {INVALID_CAC_PAYBACK_TOOLTIP_TEXT}
    </InfoTooltip>
  ) : (
    <div className="ChartHeader_KPIValue">
      <span className="ChartHeader_Timespan">
        {getTimePeriodLabel(currentMonthValue)}
      </span>
    </div>
  );
};

function CacPaybackExpanded() {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState({});
  const chartRef = useRef();
  const dashboardPath = useDashboardPath();
  const kpiValue = getMetricValue(total.currentMonthValue);

  const { startDate, endDate } = useSelector(({ shared }) => shared);
  const onQueryStateChange = useCallback(({ data, isLoading }) => {
    setLoading(isLoading);
    setTotal({
      currentMonthValue: data.currentMonthValue,
      currentMonth: data.currentMonth,
    });
  }, []);

  return (
    <section className="Panel Panel-toEdge">
      <ChartHeader back={dashboardPath} title={CHART_TITLE}>
        <div className="ChartHeader_KPIValue">
          {loading ? (
            ''
          ) : (
            <>
              {kpiValue}{' '}
              <AdditionalInfo currentMonthValue={total.currentMonthValue} />
            </>
          )}
        </div>
        <div className="ChartHeader_KPI">
          <br />
          {formatRangeWithShortYear(startDate, endDate)}
        </div>
      </ChartHeader>
      <ChartToolbar>
        <ChartControls
          chartRef={chartRef}
          title={CHART_TITLE}
          metricValue={getLabeledMetric(kpiValue)}
          label={formatRangeWithShortYear(startDate, endDate)}
          data-testid="cac-payback-chart-export"
        />
      </ChartToolbar>
      <CacPaybackChart
        className="ExpandedView_ChartWrapper"
        exportBtn={chartRef}
        onQueryStateChange={onQueryStateChange}
      />
    </section>
  );
}

export default CacPaybackExpanded;
