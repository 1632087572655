import {
  ADJUSTMENTS_TO_CASH,
  BEGINNING_CASH,
  ACTUAL_CASH_COLLECTIONS,
  ACTUAL_CASH_PAYMENTS,
  ACTUAL_ENDING_CASH,
  FINANCING,
} from '@/constants/actuals';

const SORTED_CASH_ACTUALS = [
  BEGINNING_CASH,
  ACTUAL_CASH_COLLECTIONS,
  ACTUAL_CASH_PAYMENTS,
  FINANCING,
  ADJUSTMENTS_TO_CASH,
  ACTUAL_ENDING_CASH,
];

/**
 * Sort cash rows in an arbitrary order
 *
 * @param {Array} data An array of cash objects
 * @returns {Array} sorted rows
 */
const sortCashRows = (data) => {
  return data.sort(
    (a, b) =>
      SORTED_CASH_ACTUALS.indexOf(a.id) - SORTED_CASH_ACTUALS.indexOf(b.id),
  );
};

export default sortCashRows;
