// @ts-check
import { useQuery } from '@tanstack/react-query';
import { getNonOnboardedUserToken } from '@/services/onboarding.service';

/**
 * Fetches a company's onboarding status in FP&A
 *
 * @type {(props?: {
 *   enabled?: boolean;
 * }) => import('@tanstack/react-query').UseQueryResult<
 *   import('@/types/services/backend').NonOnboardedUser
 * >}
 */
const useNonOnboardedUserToken = ({ enabled } = { enabled: true }) => {
  return useQuery({
    queryKey: ['getNonOnboardedUserToken'],
    queryFn: async () => {
      const { data } = await getNonOnboardedUserToken();
      return data.data;
    },
    staleTime: Infinity,
    enabled,
  });
};

export default useNonOnboardedUserToken;
