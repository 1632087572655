export const EMPLOYMENT_TYPE = Object.freeze({
  fte: 'Full-time Employee',
  pte: 'Part-time Employee',
  contractor: 'Contractor',
  other: 'Other',
});
export const employeeFormModes = {
  CREATE: 'create',
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
};
export const ADD_EMPLOYEE = 'Add Employee';
export const EDIT_EMPLOYEE = 'Edit Employee';
export const DUPLICATE_EMPLOYEE = 'Duplicate Employee';
export const FOUNDER = 'FOUNDER';
export const salaryIntervals = {
  MONTHLY: '/mo.',
  ANNUALLY: '/yr.',
};
export const forecastMethods = {
  NEW_HEAD_COUNT: 'NewHeadcount',
  TOTAL_HEAD_COUNT: 'TotalHeadcount',
};

export const COMMISSION_FORMULA = 'commissionFormula';
export const BONUS_FORMULA = 'bonusFormula';
export const SET_DEPARTMENT = 'setDepartment';
export const SET_JOB_TITLE = 'setJobTitle';
export const SET_ROLE_TYPE = 'setRoleType';
export const SET_SALARY = 'setSalary';
export const SET_START_DATE = 'setStartDate';
export const SET_NO_END_DATE_CHECK_BOX = 'setNoEndDate';
export const SET_END_DATE = 'setEndDate';
export const SET_FORECAST_METHOD = 'setForecastMethod';
export const SET_COMPENSATION_TYPE = 'SET_COMPENSATION_TYPE';
export const SET_COMPENSATION_UNIT = 'SET_COMPENSATION_UNIT';
export const SET_COMPENSATION_FORMULA = 'SET_COMPENSATION_FORMULA';
export const SET_API_ERROR_MESSAGE = 'SET_API_ERROR_MESSAGE';
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const TERM_DATE = 'termDate';
export const NAME = 'name';
export const SALARY_AMOUNT = 'annualSalary';
export const SALARY_FORMULA = 'salaryFormula';
export const VIEW_FORMULA = 'viewFormula';
export const SALARY_VARIABLE_ID = 'salaryVariableId';
export const PAID_VIA_HRIS = 'paidViaHris';
export const ROLE_TYPE = 'employmentType';
export const BENEFITS_AND_TAXES = 'benefitsAndTaxes';
export const LOAD_MULTIPLIER = 'loadMultiplier';
export const TITLE_ID = 'titleId';
export const OVERRIDE_GLOBAL_LOAD_MULTIPLIER = 'overrideGlobalLoadMultiplier';

export const units = {
  CURRENCY: 'Currency',
  FORMULA: 'Formula',
  NUMBER: 'Number',
  PERCENTAGE: 'Percentage',
};

export const CUSTOM_TITLE_TOOLTIP = `You can’t delete a job title used in a custom
variable, revenue stream, or another employee.`;

export const INITIAL_STATE = {
  annualSalary: '',
  departmentId: null,
  titleId: null,
  employmentType: EMPLOYMENT_TYPE.fte,
  endDate: null,
  salaryFormula: '',
  salaryVariableId: null,
  startDate: null,
  compensationFormula: '',
  compensationType: BONUS_FORMULA,
  overrideGlobalLoadMultiplier: false,
  unit: units.PERCENTAGE,
  name: '',
  paidViaHris: false,
};

export const CATEGORY_COLUMN = 'Category';
