import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import ModalConfirmation from '@/components/common/ModalConfirmation';
import { getVariableDependencies } from '@/services/variable.service';
import VariableDependencySection from './VariableDependencySection';
import './Dependents.scss';

const DEPENDENCY_TEXT =
  'This variable is currently being used in the following areas:';

function VariableDependencyModal({
  onFinish,
  scenarioId,
  variableId,
  text,
  dependencies,
  errors,
  title = 'Trace Custom Variables',
}) {
  const [loadingDependencies, setLoadingDependencies] = useState(
    Object.keys(dependencies ?? {}).length < 1,
  );
  const [dependencyMap, setDependencyMap] = useState(dependencies);
  const [dependenciesError, setDependenciesError] = useState('');
  const [modalTitle, setModalTitle] = useState('Retrieving Dependencies');
  const hasDependencies = Object.keys(dependencyMap ?? {}).length > 0;

  const getDependencies = async () => {
    try {
      const {
        data: {
          data: { dependenciesMap },
        },
      } = await getVariableDependencies(scenarioId, variableId);
      setDependencyMap(dependenciesMap);
      setModalTitle(title);
    } catch (e) {
      setDependenciesError(e.response?.data?.error?.errorMessage || e.message);
      setModalTitle('Error Retrieving Dependencies');
    } finally {
      setLoadingDependencies(false);
    }
  };

  let modalText = 'No dependencies found';

  if (errors) {
    modalText = errors;
  } else if (dependenciesError) {
    modalText = dependenciesError;
  } else if (text) {
    modalText = text;
  } else if (hasDependencies) {
    modalText = DEPENDENCY_TEXT;
  }

  useEffect(() => {
    if (dependencies) {
      setModalTitle(title);
    } else {
      getDependencies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  }, []);

  return (
    <ModalConfirmation
      id="variable-dependency-modal"
      onAction={onFinish}
      title={modalTitle}
      actionBtnTxt="OK"
    >
      {loadingDependencies ? (
        <LoadingSpinner />
      ) : (
        <>
          <p className="DependencySection_InfoText">{modalText}</p>
          <div>
            {hasDependencies &&
              Object.keys(dependencyMap).map((section) => {
                return (
                  <VariableDependencySection
                    key={section}
                    section={section}
                    dependencies={dependencyMap[section]}
                  />
                );
              })}
          </div>
        </>
      )}
    </ModalConfirmation>
  );
}

function mapStateToProps({ scenario }) {
  return {
    scenarioId: scenario.scenarioId,
  };
}

export default connect(mapStateToProps)(VariableDependencyModal);
