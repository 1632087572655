// ts-check
import { ASC, DESC } from '@/constants/tables';
import { classNames } from '@/helpers';

/**
 * @template [T=unknown] Default is `unknown`
 * @typedef {api: import('ag-grid-community').GridOptions<T>,
 * column: import('ag-grid-community').Column,
 * columnApi: import('ag-grid-community').ColumnApi,
 * data-testid: string} FilterSortField
 */

/**
 * Renders a sort filter icon for grid
 *
 * @example
 *   <FilterSortField column={column} columnApi={columnApi} api={api} />;
 *
 * @type {(params: FilterSortFieldParams) => React.ReactElement}
 */
export default function FilterSortField({
  api,
  column,
  columnApi,
  'data-testid': dataTestId,
}) {
  const currentSort = column.getSort();

  const handleSort = () => {
    let sort = ASC;
    if (currentSort === ASC) {
      sort = DESC;
    } else if (currentSort === DESC) {
      sort = 'none';
    }
    columnApi.applyColumnState({
      state: [{ colId: column.colId, sort }],
      defaultState: { sort: null },
    });
    api.onSortChanged();
  };

  return (
    <button
      type="button"
      className={classNames(
        'FilterRenderer_SortButton',
        currentSort === ASC && 'FilterRenderer_SortButton-asc',
        currentSort === DESC && 'FilterRenderer_SortButton-desc',
      )}
      data-testid={dataTestId}
      onClick={handleSort}
      aria-hidden="true"
    />
  );
}
