// @ts-check
import { useCallback, useState, useRef } from 'react';
// eslint-disable-next-line no-restricted-imports -- predates requirement
import { useDispatch } from 'react-redux';
import { subscribeToCashActualsValuesAction } from '@/actions/actuals';
import {
  subscribeToAdjustmentUpdatesAction,
  subscribeToCashAccountActualsUpdated,
  subscribeToNetCashFlowUpdatesAction,
} from '@/actions/fpaLite';
import CashInOutGrid from '@/components/CashInOut/CashInOutGrid';
import PageHeader from '@/components/Layouts/PageHeader';
import ExportableReportButton from '@/components/Reports/ExportableReport/ExportableReportButton';
import TableExportButton from '@/components/TableExportButton';
import Tabs from '@/components/common/Tabs/Tabs';
import TabsPanel from '@/components/common/Tabs/TabsPanel';
import { cashInOutMetrics, SPREADSHEET_ID } from '@/constants/cashInOut';
import { CASH_IN_OUT } from '@/constants/pages';
import { exportableReportTypes } from '@/constants/reports';
import { classNames } from '@/helpers';
import {
  updateCashAccountActualsUpdates,
  updateCashFlowAndAdjustmentMetrics,
  updateCashGridMetric,
} from '@/helpers/cashGrid';
import isBillDomain from '@/helpers/isBillDomain';
import useCashInOutGridData from '@/hooks/useCashInOutGridData';
import useScenarioId from '@/hooks/useScenarioId';
import useWsSubscription from '@/hooks/useWsSubscription';
import './CashInOutContainer.scss';

/**
 * @typedef {{
 *   name: string;
 *   details: import('@/types/services/backend').ExpenseGroupActualDetails[];
 *   family: (typeof import('@/constants/actuals').actualsFamily)['EXPENSE'];
 * }} ExternalDetails
 */

const ExportExcelButton = ({ onClick }) => {
  return (
    <TableExportButton
      data-testid={`${SPREADSHEET_ID}-export-xlsx`}
      onClick={onClick}
    />
  );
};

const CashInOutContainer = () => {
  /** @type {import('@/store').AppDispatch} */
  const dispatch = useDispatch();
  const scenarioId = useScenarioId();
  const [showVarianceAmount, setShowVarianceAmount] = useState(true);
  const [showVariancePercentage, setShowVariancePercentage] = useState(false);
  /**
   * @type {React.MutableRefObject<
   *   | null
   *   | import('ag-grid-react').AgGridReact<
   *       import('@/types/services/backend').CashInOutGrid[]
   *     >
   * >}
   */
  const gridApi = useRef(null);

  const { isLoading, rowData, shouldRenderAccountNumber } =
    useCashInOutGridData();

  const applyGridUpdates = useCallback(
    (updatedData, node) => {
      const { api } = gridApi.current;

      api.applyTransactionAsync({ update: updatedData }, () => {
        if (node) {
          api.refreshCells({ force: true, rowNodes: [node] });
        }
      });
    },
    [gridApi],
  );

  const handleCashGridUpdateWebSocket = useCallback(
    (payload) => {
      if (rowData) {
        const { api } = gridApi.current;
        const [updatedGridData, id] = updateCashGridMetric({
          payload,
          rowData,
        });
        if (updatedGridData) {
          applyGridUpdates([updatedGridData], api.getRowNode(id));
        }
      }
    },
    [rowData, applyGridUpdates],
  );

  const handleNetCashFlowAndAdjustmentUpdateWebSocket = useCallback(
    (payload, type) => {
      if (rowData) {
        const { api } = gridApi.current;
        const [updateNetCashFlowData, id] = updateCashFlowAndAdjustmentMetrics({
          payload,
          rowData,
          type,
        });
        applyGridUpdates([updateNetCashFlowData], api.getRowNode(id));
      }
    },
    [rowData, applyGridUpdates],
  );

  const handleCashAccountActualsUpdated = useCallback(
    () =>
      dispatch(
        subscribeToCashAccountActualsUpdated(scenarioId, (payload) => {
          if (rowData) {
            const { api } = gridApi.current;
            const mappedData = updateCashAccountActualsUpdates(
              rowData,
              payload,
            );
            applyGridUpdates(mappedData, api.getRowNode(payload.cashAccountId));
          }
        }),
      ),
    [dispatch, rowData, applyGridUpdates, scenarioId],
  );

  useWsSubscription(
    () =>
      rowData &&
      dispatch(
        subscribeToCashActualsValuesAction(
          scenarioId,
          handleCashGridUpdateWebSocket,
        ),
      ),
    [scenarioId, rowData, handleCashGridUpdateWebSocket],
  );

  useWsSubscription(rowData && handleCashAccountActualsUpdated, [
    rowData,
    handleCashAccountActualsUpdated,
  ]);

  useWsSubscription(
    () =>
      rowData &&
      dispatch(
        subscribeToNetCashFlowUpdatesAction(scenarioId, (payload) =>
          handleNetCashFlowAndAdjustmentUpdateWebSocket(
            payload,
            cashInOutMetrics.ACTUAL_NET_CASHFLOW,
          ),
        ),
      ),
    [scenarioId, rowData, handleNetCashFlowAndAdjustmentUpdateWebSocket],
  );

  useWsSubscription(
    () =>
      rowData &&
      dispatch(
        subscribeToAdjustmentUpdatesAction(scenarioId, (payload) =>
          handleNetCashFlowAndAdjustmentUpdateWebSocket(
            payload,
            cashInOutMetrics.ACTUAL_ADJUSTMENTS,
          ),
        ),
      ),
    [scenarioId, rowData, handleNetCashFlowAndAdjustmentUpdateWebSocket],
  );

  const handleExcelExport = () => {
    gridApi.current?.api?.exportDataAsExcel();
  };

  return (
    <>
      <PageHeader
        page={CASH_IN_OUT}
        addBtnSlot={
          <ExportableReportButton type={exportableReportTypes.CASH_REPORT} />
        }
      />
      <section
        className={classNames(
          'Panel Panel-toEdge CashInOutSpreadsheetSection',
          isBillDomain() && 'CashInOutSpreadsheetSection-planning',
        )}
      >
        <Tabs controls={<ExportExcelButton onClick={handleExcelExport} />}>
          <TabsPanel path={CASH_IN_OUT} label="Cash Inflow & Outflow">
            <CashInOutGrid
              gridApi={gridApi}
              showVarianceAmount={showVarianceAmount}
              setShowVarianceAmount={setShowVarianceAmount}
              showVariancePercentage={showVariancePercentage}
              setShowVariancePercentage={setShowVariancePercentage}
              rowData={rowData}
              isLoading={isLoading}
              shouldRenderAccountNumber={shouldRenderAccountNumber}
            />
          </TabsPanel>
        </Tabs>
      </section>
    </>
  );
};

export default CashInOutContainer;
