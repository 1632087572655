import { useEffect } from 'react';
import { connect } from 'react-redux';
import RevenueEmptyIcon from '@bill/cashflow.assets/revenue-empty';
import { subscribeToActualsUpdateAction } from '@/actions/actuals';
import ChartTooltip from '@/components/Charts/ChartTooltip';
import DateChart from '@/components/Charts/DateChart';
import EmptyData from '@/components/common/EmptyData';
import { actualsFamily } from '@/constants/actuals';
import mapMonthlyData from '@/helpers/mapMonthlyData';
import useWsSubscription from '@/hooks/useWsSubscription';
import {
  CHART_TITLE,
  TOTAL_REVENUE_METRICS,
} from '@/pages/Dashboard/constants/totalRevenue';
import getBaseScenario from '@/selectors/getBaseScenario';
import useTotalMrrQuery from './useTotalMrrQuery';

function reducer({ mrrData, totalRevenue }) {
  return {
    ...mapMonthlyData(mrrData, 'totalMonthlyRevenue'),
    totalRevenue,
  };
}

/**
 * Renders a line chart showing total revenue
 *
 * @example
 *   <TotalRevenueChart exportBtn={exportBtn} />;
 */
function TotalRevenueChart({
  className,
  exportBtn,
  plotOptions,
  axisStyles,
  onQueryStateChange,
  tooltipOptions,
  scenarioId,
  subscribeToActualsUpdate,
  productsRevenueList,
  revenueStreamsList,
  selectedScenario,
  startDate,
  endDate,
  timePeriod,
}) {
  const scenarioQueries = useTotalMrrQuery(reducer, [
    selectedScenario?.useExternalsForTotalRevenue,
    productsRevenueList,
    revenueStreamsList,
  ]);
  const [base] = scenarioQueries;

  // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
  useEffect(
    () => {
      if (scenarioQueries.length) onQueryStateChange?.(scenarioQueries);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- predates description requirement
    [scenarioQueries],
  );

  useWsSubscription(
    () =>
      subscribeToActualsUpdate(scenarioId, actualsFamily.REVENUE, () =>
        base.refetch({ cancelRefresh: true }),
      ),
    [base, scenarioId],
  );

  const hasRevenue = scenarioQueries.some(
    ({ data, isLoading }) => isLoading || data.data?.length,
  );

  return (
    <>
      {hasRevenue ? (
        <DateChart
          className={className}
          data-testid="totalRevenueChart"
          tooltip={
            <ChartTooltip
              metrics={TOTAL_REVENUE_METRICS}
              endDate={endDate}
              timePeriod={timePeriod}
              {...tooltipOptions}
            />
          }
          loading={scenarioQueries.every(({ isLoading }) => isLoading)}
          plotOptions={plotOptions}
          axisStyles={axisStyles}
          ref={exportBtn}
          startDate={startDate}
          endDate={endDate}
          timePeriod={timePeriod}
        >
          {scenarioQueries.map(
            ({ data, scenario }, idx) =>
              data && (
                <DateChart.Series
                  key={scenario.scenarioId}
                  data={data.data}
                  index={idx}
                  metric={CHART_TITLE}
                  scenario={scenario}
                  isComparison={idx === 1}
                />
              ),
          )}
        </DateChart>
      ) : (
        <EmptyData Icon={RevenueEmptyIcon}>
          Add Product & Revenue Streams (Drivers) for this chart to populate.
        </EmptyData>
      )}
    </>
  );
}

function mapStateToProps({ scenario, revenues, shared }) {
  const { scenarioId } = scenario;
  const { revenueStreamsList, productsRevenueList } = revenues;
  return {
    selectedScenario: getBaseScenario({ scenario }),
    revenueStreamsList,
    productsRevenueList,
    scenarioId,
    startDate: shared.startDate,
    endDate: shared.endDate,
    timePeriod: shared.timePeriod,
  };
}

export default connect(mapStateToProps, {
  subscribeToActualsUpdate: subscribeToActualsUpdateAction,
})(TotalRevenueChart);
