import FormLabel from '@/components/common/FormLabel';
import FormRadio from '@/components/common/FormRadio';
import { classNames } from '@/helpers';
import './PaidViaHris.scss';

export default ({ paidViaHris, onChange, isDisabled }) => {
  return (
    <div
      className={classNames(
        'PaidViaHris',
        'Form_Group',
        isDisabled && 'PaidViaHris-disabled',
      )}
    >
      <FormLabel htmlFor="paidViaHris" text="Paid via HRIS?" />
      <p className="Form_Desc">
        Do you want to utilize data from your HRIS / payroll platform for
        actuals? "Yes" is the default for most HRIS platforms. Selecting no will
        keep forecasted values for actuals.
      </p>
      <FormRadio
        id="paid-via-hris-yes"
        name="paid-via-hris"
        label="Yes"
        checked={paidViaHris}
        value="true"
        onChange={onChange}
        disabled={isDisabled}
      />
      <FormRadio
        id="paid-via-hris-no"
        name="paid-via-hris"
        label="No"
        checked={!paidViaHris}
        value="false"
        onChange={onChange}
        disabled={isDisabled}
      />
    </div>
  );
};
