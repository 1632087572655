import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { AnimatePresence, m as motion, useSpring } from 'framer-motion';
import PropTypes from 'prop-types';
import NavLink from '@/components/common/NavLink';
import TooltipTextOverflow from '@/components/common/TooltipTextOverflow';
import { childrenOf } from '@/helpers';
import useElementSize from '@/hooks/useElementSize';
import TabsPanel from './TabsPanel';
import './Tabs.scss';

const ANIM_TRANSITION = window.Cypress
  ? { duration: 0 }
  : {
      type: 'spring',
      mass: 0.2,
    };

const SPRING_SETTINGS = {
  type: 'spring',
  mass: 0.25,
  stiffness: 165,
};

/**
 * Wraps a series of tabbed panels and facilitates navigation between them
 *
 * @example
 *   <Tabs>
 *    <Tabs.Panel path="/foo" label="Foo">
 *      ...
 *    </Tabs.Panel>
 *    <Tabs.Panel path="/bar" label="Bar">
 *      ...
 *    </Tabs.panel>
 *   </Tabs>
 */
function Tabs({ children, controls, fallback }) {
  const { pathname } = useLocation();
  const tabs = useRef(new Map());
  const sliderLeft = useSpring(0, SPRING_SETTINGS);
  const sliderRight = useSpring(0, SPRING_SETTINGS);

  const [container, setContainer] = useState(null);
  const callbackRef = useCallback((node) => {
    if (node !== null) {
      setContainer(node);
    }
  }, []);

  const offsetResizeEntry = useElementSize({ current: container });
  const offsetParent = useMemo(
    () => offsetResizeEntry.target?.getBoundingClientRect(),
    [offsetResizeEntry],
  );

  // When a tab is selected, slide the underline from the former tab
  useEffect(() => {
    if (!offsetParent) return;

    const activeTab = tabs.current.get(pathname);
    if (!activeTab) {
      fallback?.();
      return;
    }

    const { left, right } = activeTab.getBoundingClientRect();
    sliderLeft.set(left - offsetParent.left);
    sliderRight.set(offsetParent.right - right);
  }, [offsetParent, pathname, sliderLeft, sliderRight, children, fallback]);

  const handleArrowKeys = ({ key }) => {
    const tabMap = tabs.current;
    const paths = [...tabMap.keys()];
    const selectedIdx = paths.findIndex((path) => pathname === path);
    if (key === 'ArrowRight') {
      const newFocusIdx = selectedIdx < paths.length - 1 ? selectedIdx + 1 : 0;
      tabMap.get(paths[newFocusIdx]).click();
    } else if (key === 'ArrowLeft') {
      const newFocusIdx = selectedIdx > 0 ? selectedIdx - 1 : paths.length - 1;
      tabMap.get(paths[newFocusIdx]).click();
    }
  };

  return (
    <>
      <div className="Tabs" ref={callbackRef}>
        <ul
          className="Tabs_List"
          role="tablist"
          aria-orientation="horizontal"
          onKeyUp={handleArrowKeys}
        >
          {React.Children.toArray(children).map(
            ({ props: { path, label } }, _, currentArray) => (
              <li
                key={path}
                style={{ maxWidth: `${100 / currentArray.length}%` }}
              >
                <NavLink
                  ref={(el) => tabs.current.set(path, el)}
                  exact
                  to={path}
                  className="Tabs_Link"
                  role="tab"
                  aria-controls={path}
                  aria-selected={pathname === path}
                  data-testid={`${path}-tab`}
                  scrollToTop={false}
                >
                  <TooltipTextOverflow
                    label={label}
                    data-testid={`tooltip-tab-${label}`}
                  />
                </NavLink>
              </li>
            ),
          )}
        </ul>
        <motion.div
          className="Tabs_Slider"
          style={{
            left: sliderLeft,
            right: sliderRight,
          }}
          aria-hidden="true"
        />
        <AnimatePresence>
          {controls && (
            <motion.div
              className="Tabs_Controls"
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={ANIM_TRANSITION}
            >
              {controls}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence exitBeforeEnter>
        <Switch key="switch">
          <>{children}</>
        </Switch>
      </AnimatePresence>
    </>
  );
}

Tabs.propTypes = {
  /** One or more Tab.Panels */
  children: childrenOf([TabsPanel]).isRequired,
  /** Optional controls to place to the right of the tabs */
  controls: PropTypes.node,
  /** Optional routing function if active tab is not found */
  fallback: PropTypes.func,
};

export default Tabs;
