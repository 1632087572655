// @ts-check
import { useState, useRef, useCallback } from 'react';
import CashInOutExpandedSpreadsheet from '@/components/Charts/CashInExpandedSpreadsheet';
import ChartControls from '@/components/Charts/ChartControls';
import ChartHeader from '@/components/Charts/ChartHeader';
import ChartLegend from '@/components/Charts/ChartLegend';
import ChartToolbar from '@/components/Charts/ChartToolbar';
import ComponentLoader from '@/components/common/ComponentLoader';
import { CASH_OUT_TYPE } from '@/constants/cashInOut';
import { AWAITING_DATA } from '@/constants/charts';
import { formatRangeWithShortYear } from '@/helpers/dateFormatter';
import formatMonetary from '@/helpers/formatMonetary';
import useDashboardPath from '@/hooks/useDashboardPath';
import useTypedSelector from '@/hooks/useTypedSelector';
import CashOutChart from '@/pages/Dashboard/charts/CashOutChart';
import { CHART_TITLE } from '@/pages/Dashboard/constants/cashOut';
import { getMetricValue } from './helpers';

const SPREADSHEET_ID = 'cashOut-chart-table';

function CashOutChartExpanded() {
  const { startDate, endDate } = useTypedSelector(({ shared }) => shared);
  const [cashOutLoading, setCashOutLoading] = useState(true);
  const [cashOutTotal, setCashOutTotal] = useState(0);
  /** @type {ReturnType<typeof useState<Highcharts.Chart>>} */
  const [cashOutChart, setCashOutChart] = useState();
  const dateChartRef = useRef();
  const dashboardPath = useDashboardPath();
  const kpiValue = getMetricValue(cashOutTotal, formatMonetary);
  const onQueryStateChange = useCallback(([base]) => {
    setCashOutLoading(base.isLoading);
    setCashOutTotal(base.data.overallTotal);
  }, []);

  return (
    <>
      <section className="Panel Panel-toEdge">
        <ChartHeader back={dashboardPath} title={CHART_TITLE}>
          <div className="ChartHeader_KPIValue">
            {cashOutLoading ? AWAITING_DATA : kpiValue}
          </div>
          <div className="ChartHeader_KPI">
            <br />
            {formatRangeWithShortYear(startDate, endDate)}
          </div>
        </ChartHeader>
        <ComponentLoader loadingComponent="arpa" paddingTop="0%" />
        <ChartToolbar className="Chart_Toolbar-withLegend">
          <ChartLegend
            chart={cashOutChart}
            data-testid="cashOutAccounts-legend"
          />
          <ChartControls
            data-testid="cashout-chart-detail"
            chartRef={dateChartRef}
            title={CHART_TITLE}
            metricValue={formatMonetary(cashOutTotal)}
            label={formatRangeWithShortYear(startDate, endDate)}
          />
        </ChartToolbar>
        <div className="ExpandedView_ChartWrapper">
          <CashOutChart
            onChartCreated={setCashOutChart}
            exportBtn={dateChartRef}
            onQueryStateChange={onQueryStateChange}
          />
        </div>
      </section>
      <CashInOutExpandedSpreadsheet
        title={CHART_TITLE}
        filter={CASH_OUT_TYPE}
        data-testid={SPREADSHEET_ID}
      />
    </>
  );
}

export default CashOutChartExpanded;
