import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { LOGIN_PATH } from '@/constants/pages';
import getBillLoginUrl from '@/helpers/getBillLoginUrl';
import isBillDomain from '@/helpers/isBillDomain';
import useLogout from '@/hooks/useLogout';
import useTypedSelector from '@/hooks/useTypedSelector';

const Logout = () => {
  const logout = useLogout();

  const { statefulRoute } = useTypedSelector(({ auth }) => auth);

  useEffect(() => {
    logout();
    /**
     * Pendo caches user identifiers and provides no way to clear them on
     * logout. This can lead to situations where one user's data is mixed with
     * another's, such as signing up a customer on their behalf.
     *
     * @todo Find a way to do this without a hard refresh blame Jeremy
     */
    window.location.reload();
  }, [logout]);

  if (isBillDomain()) {
    window.location.href = getBillLoginUrl(statefulRoute?.fullPath);
    return null;
  }
  return <Redirect to={{ pathname: LOGIN_PATH }} />;
};

export default Logout;
